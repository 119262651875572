import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import { SystemSize } from '~/theme';

export type Props = {
  size?: SystemSize;
};

const LimitedIcon: React.FCC<Props> = ({ dataTestId, size }) => (
  <Container data-testid={dataTestId} $size={size ?? 'l'}>
    <Icon name="arrow" />
  </Container>
);

const systemSizeToFontSize: Record<SystemSize, SystemSize> = {
  xxxs: 'xxs',
  xxs: 'xxs',
  xs: 'xxs',
  s: 'xxs',
  base: 'base',
  m: 'base',
  l: 'base',
  xl: 'm',
  xxl: 'l',
  xxxl: 'l',
  xxxxl: 'l',
};

const Container = styled.div<{ $size: SystemSize }>(
  ({ theme, $size }) => css`
    position: absolute;
    top: -${theme.space('s')};
    right: -${theme.space('s')};
    width: ${theme.space($size)};
    height: ${theme.space($size)};
    font-size: ${theme.space(systemSizeToFontSize[$size])};

    background-color: ${theme.color('accent')};
    color: ${theme.color('accent', 'text')};
    border-radius: ${theme.getTokens().border.radius.full};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
);

export default LimitedIcon;
