import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading4 } from '~/components/atom/Typography';

import StepOrIconComponent from './StepOrIconComponent';

export type Instruction = {
  step: number;
  link: string;
  title: string;
  description: React.ReactNode;
};

export type Props = {
  instruction: Instruction;
};

const InstructionBlock: React.FCC<Props> = ({
  dataTestId,
  instruction,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <Container
      data-testid={dataTestId}
      onClick={() => window.open(instruction.link, '_blank')?.focus()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      <JustificationWrapper
        justification="start"
        align="center"
        padding={['l']}
      >
        <StepOrIconComponent step={instruction.step} isHovered={isHovered} />
        <div>
          <Heading4
            color={{ group: 'primary' }}
            margin={['xxs', null, null, null]}
          >
            {instruction.title}
          </Heading4>
          <Body margin={[null]}>{instruction.description}</Body>
        </div>
      </JustificationWrapper>
    </Container>
  );
};

const Container = styled.div<{}>`
  cursor: pointer;
`;

const JustificationWrapper = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    transition: all 0.5s ease;
    border-radius: ${theme.getTokens().border.radius.base};

    &:hover {
      background-color: ${theme.color('white')};
      box-shadow: ${theme.boxShadow('base')};
    }
  `,
);

export default InstructionBlock;
