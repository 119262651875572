import React from 'react';
import Div from '~/components/atom/Div';
import { Variant, Label } from '~/components/atom/Typography';

export type Props = {
  dataTestId?: string;
  label: string;
};

const InputWithLabel: React.FCC<Props> = ({ children, label, ...rest }) => (
  <Div width="100%" {...rest}>
    <Label variant={Variant.primary}>{label}</Label>
    {children}
  </Div>
);

export default InputWithLabel;
