import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';

type Props = { disabled?: boolean; onClick: () => void };

const ShareIcon: React.FC<Props> = ({ disabled, onClick }) => (
  <StyledIcon
    $disabled={disabled}
    name="share"
    strokeWidth={2}
    onClick={onClick}
  />
);

const StyledIcon = styled(Icon)<{ $disabled?: boolean }>(
  ({ theme, $disabled = false }) => css`
    svg {
      stroke: ${$disabled ? theme.color('tertiary') : theme.color('primary')};
    }

    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    transition: all 0.2s ease;

    ${$disabled && `pointer-events: none`};

    &:hover {
      transform: scale(1.2);
    }
  `,
);

export default ShareIcon;
