import React from 'react';
import { Handle, Position } from 'reactflow';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading5, Variant } from '~/components/atom/Typography';
import {
  FlowAction,
  type FlowV2_TemplateStringFragment,
} from '~/graphql/types';
import type { ClientFlowActionContactDetails } from '~/graphql/types.client';
import { issuesByActionId } from '~/components/page/Automation/v2/state/flowIssues';
import getCardHeading from '../../../../utils/getCardHeading';
import Issue from '../../../Issues/InCard';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import TemplateString from '../TemplateString';
import getActiveField from '../../../../../UpdateAction/components/ContactDetails/utils/getActiveField';
import isTemplateString from '~/typeguards/isTemplateString';

export type Props = Omit<
  CardProps<ClientFlowActionContactDetails>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const text = {
  name: 'Naam',
  phone: 'Telefoonnummer',
  unsubscribeEmail: 'Uitschrijven van e-mails',

  boolean: {
    true: 'Ja',
    false: 'Nee',
  },
};

const ContactDetailsCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, data, ...props }) => {
    const { field } = data.action;

    const issues = useRecoilValue(issuesByActionId(data.id));

    const activeFieldKey = getActiveField(data.action);
    const activeFieldValue = field[activeFieldKey];

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.ContactDetails}
          heading={getCardHeading(FlowAction.ContactDetails)}
          selected={selected}
          disabled={disabled}
          data={data}
          dataTestId="ContactDetailsCard"
        >
          <JustificationContainer direction="column">
            <WithMargins>
              <JustificationContainer align="center">
                <Heading5 margin={[null]} inline variant={Variant.primary}>
                  {text[activeFieldKey]}:&nbsp;
                </Heading5>
                {!isTemplateString(activeFieldValue) ? (
                  <div>{text.boolean[`${activeFieldValue as boolean}`]}</div>
                ) : (
                  <TemplateString
                    templateString={
                      activeFieldValue as FlowV2_TemplateStringFragment
                    }
                    actionId={data.action.id}
                  />
                )}
              </JustificationContainer>
            </WithMargins>

            {issues &&
              issues.map(issue => <Issue key={issue.message} {...issue} />)}
          </JustificationContainer>
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

const WithMargins = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

export default ContactDetailsCard;
