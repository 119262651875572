import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { keys } from 'ramda';
import Input from '~/components/molecule/Input';
import {
  WizardStepProps,
  type OutputFieldImage,
  type OutputFieldInvisible,
  type OutputFieldItem,
} from '~/components/organism/Wizard/context/WizardContext';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import useApp from '~/hooks/useApp';
import Loading from '~/components/atom/Loading';
import ErrorScreen from '~/components/page/ErrorScreen';
import {
  StellaxAiReportType,
  useUpdateAppStatusMutation,
  type AppStatusFields_AppStatus_StellaxAi_Fragment,
  type AppStatus_StellaxAi__Input,
  type DhImage,
} from '~/graphql/types';
import type { OutputMap } from '../../..';
import JustificationContainer from '~/components/atom/JustificationContainer';
import ImageInput from '~/components/organism/ImageInput';
import { Body } from '~/components/atom/Typography';
import { fieldMapping } from '~/components/page/Apps/StellaxAI/components/AppDetails/constants';
import useErrorReporter from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';
import hasValue from '~/util/hasValue';
import useCurrentUser from '~/hooks/useCurrentUser';

export const id = 'StellaxInstall';
export const title = 'Stellax installatie';

export type OutputType = {
  type: typeof id;
  logo: OutputFieldImage<DhImage>;
  name: OutputFieldItem;
  email: OutputFieldItem;
  reportType: OutputFieldInvisible<StellaxAiReportType>;
  website: OutputFieldItem;
};

const text = {
  description:
    'Stel hieronder de bedrijfsgegevens en het logo in die worden weergegeven in het rapport.',
  categories: {
    logo: 'Logo',
    name: 'Bedrijfsnaam',
    email: 'Email',
    reportType: 'Rapport type',
    website: 'Website',
  },
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const { email: userEmail } = useCurrentUser();
  const {
    id: accountId,
    name: accountName,
    AccountSettings,
  } = useCurrentAccount();

  const reporter = useErrorReporter();
  const prevStepOutput = outputMap[id] as OutputType;
  const [currentOutput, setCurrentOutput] = useState({
    ...prevStepOutput,
  });
  const { app, loading } = useApp(step.metadata?.typename);

  const [updateAppStatus] = useUpdateAppStatusMutation();
  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const updatedOutput = outputMap[id] as OutputType;

      const { data, errors } = await updateAppStatus({
        variables: {
          accountId,
          update: {
            AppStatus_StellaxAi: outputToInput(updatedOutput),
          },
        },
      });

      if (!data && errors?.length) {
        reporter.captureException(
          new Error(
            `${cleanedFilename(
              __filename,
            )} | Could not updateAppStatus for AppStatus_Stellax ${JSON.stringify(
              errors,
            )}`,
          ),
        );

        return outputMap[id];
      }

      return updatedOutput;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateAppStatus, accountId],
  );

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );

  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    // No changes done yet so we can update the local state
    if (app && !loading && !step.isTouched) {
      setCurrentOutput(prev => ({
        ...prev,
        ...appStatusToOutput(
          app.appStatus as AppStatusFields_AppStatus_StellaxAi_Fragment,
          currentOutput,
          {
            email: userEmail,
            name: accountName,
            website: AccountSettings?.url,
            logo: AccountSettings?.logo,
          },
        ),
      }));
    }
  }, [
    AccountSettings,
    accountName,
    app,
    currentOutput,
    loading,
    outputMap,
    step.isTouched,
    userEmail,
  ]);

  useEffect(() => {
    const requiredFieldKeys = keys(fieldMapping).filter(
      key => fieldMapping[key].required,
    );

    const isFormValid = requiredFieldKeys.every(
      key => fieldMapping[key].validation(currentOutput[key].value) === true,
    );

    if (isFormValid) {
      api.free(currentOutput);
    } else {
      api.lock(currentOutput);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOutput, loading]);

  if (loading) return <Loading />;
  if (!app) {
    return <ErrorScreen />;
  }

  return (
    <>
      <Body margin={[null, null, 'base', null]}>{text.description}</Body>
      <JustificationContainer direction="column" width="100%">
        <JustificationContainer width="100%" gap="m">
          <Input
            width="100%"
            name="name"
            label={{ text: fieldMapping.name.label }}
            required={fieldMapping.name.required}
            value={currentOutput.name.value}
            type="text"
            validation={[fieldMapping.name.validation]}
            onChange={event => {
              const val = event?.target?.value;
              if (val != null) {
                setCurrentOutput(prev => ({
                  ...prev,
                  name: {
                    category: 'Naam',
                    label: val,
                    type: 'item',
                    value: val,
                  },
                }));
              }
            }}
          />
          <Input
            width="100%"
            name="email"
            label={{ text: fieldMapping.email.label }}
            required={fieldMapping.email.required}
            value={currentOutput.email.value}
            type="text"
            validation={[fieldMapping.email.validation]}
            onChange={event => {
              const val = event?.target?.value;
              if (val != null) {
                setCurrentOutput(prev => ({
                  ...prev,
                  email: {
                    category: 'Email',
                    label: val,
                    type: 'item',
                    value: val,
                  },
                }));
              }
            }}
          />
        </JustificationContainer>
        <JustificationContainer width="100%" margin={['m', null, null, null]}>
          <Input
            width="100%"
            name="website"
            label={{ text: fieldMapping.website.label }}
            required={fieldMapping.website.required}
            value={currentOutput.website.value}
            type="text"
            validation={[fieldMapping.website.validation]}
            onChange={event => {
              const val = event?.target?.value;
              if (val != null) {
                setCurrentOutput(prev => ({
                  ...prev,
                  website: {
                    category: 'Website',
                    label: val,
                    type: 'item',
                    value: val,
                  },
                }));
              }
            }}
          />
        </JustificationContainer>
      </JustificationContainer>
      <JustificationContainer direction="column" gap="m" width="100%">
        <ImageInput
          deletable={false}
          required={fieldMapping.logo.required}
          onChange={value => {
            if (value) {
              setCurrentOutput(prev => ({
                ...prev,
                logo: {
                  category: 'Logo',
                  label: 'Logo',
                  type: 'image',
                  value: {
                    __typename: 'DHImage',
                    ...value,
                  },
                },
              }));
            }
          }}
          s3Key={currentOutput.logo.value?.s3key}
          initialUrl={currentOutput.logo.value?.url}
          title={fieldMapping.logo.label}
          filename="logo"
        />
      </JustificationContainer>
    </>
  );
};

const appStatusToOutput = (
  appStatus: AppStatusFields_AppStatus_StellaxAi_Fragment,
  prevOutput: OutputType,
  accountData?: {
    website?: string | null;
    name: string;
    email: string;
    logo?: DhImage | null;
  },
): OutputType => {
  if (!appStatus) return prevOutput;
  return {
    ...prevOutput,
    type: id,
    logo: {
      category: text.categories.logo,
      type: 'image',
      label: text.categories.logo,
      value: accountData?.logo?.url
        ? accountData.logo
        : (appStatus.logo as DhImage),
    },
    website: {
      type: 'item',
      icon: 'link',
      category: text.categories.website,
      label:
        (hasValue(appStatus.website)
          ? appStatus.website
          : accountData?.website) ?? '',
      value:
        (hasValue(appStatus.website)
          ? appStatus.website
          : accountData?.website) ?? '',
    },
    email: {
      type: 'item',
      category: text.categories.email,
      label:
        (hasValue(appStatus.email) ? appStatus.email : accountData?.email) ??
        '',
      value:
        (hasValue(appStatus.email) ? appStatus.email : accountData?.email) ??
        '',
    },
    name: {
      type: 'item',
      category: text.categories.name,
      label:
        (hasValue(appStatus.name) ? appStatus.name : accountData?.name) ?? '',
      value:
        (hasValue(appStatus.name) ? appStatus.name : accountData?.name) ?? '',
    },

    reportType: {
      type: 'invisible',
      value: appStatus.reportType ?? StellaxAiReportType.B,
    },
  };
};

const outputToInput = (
  output: NonNullable<OutputType>,
): AppStatus_StellaxAi__Input =>
  ({
    enabled: true,
    reportType: output.reportType.value,
    email: output.email.value,
    name: output.name.value,
    website: output.website.value,
    logo: { s3key: output.logo.value?.s3key as string },
  }) as AppStatus_StellaxAi__Input;

export default {
  id,
  title,
};
