import { atom, selector } from 'recoil';
import { FormattedToastMessage } from '~/util/formatToastMessage';

const toasts = atom<Array<FormattedToastMessage>>({
  key: 'toasts',
  default: [],
});

export const allMessages = selector<Array<FormattedToastMessage>>({
  key: 'allMessages',
  get: ({ get }) => get(toasts),
  set: ({ get, set }, newMessage: Array<FormattedToastMessage>) => {
    const messages = get(allMessages);
    set(toasts, messages.concat(newMessage));
  },
});

export default toasts;
