import React, { useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { dissocPath, mergeDeepRight, omit, pick, uniq } from 'ramda';
import NewSaveBar from '~/components/organism/NewSaveBar';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import { UserRole, type GetAccountQuery } from '~/graphql/types';
import {
  brandSettingsCurrent,
  brandSettingsInitial,
  defaultColors,
  type BrandSettings,
} from '../../state';
import getDifferenceCount from '../../utils/getDifferenceCount';
import useUpdateMutations from '../../hooks/useUpdateMutations';
import getValidationMessages from '../../utils/getValidationMessages';
import AccountBlock from '../AccountBlock';
import type { SaveBarMessage } from '~/components/organism/NewSaveBar/components/MessagesContainer';
import usePermissions from '~/hooks/usePermissions';
import EmailSettingsBlock from '../EmailSettingsBlock';
import type { DeepPartial } from 'utility-types';

export type Props = {
  accountData: GetAccountQuery | undefined;
};

const text = {
  notAuthorized: 'Alleen accountbeheerders kunnen wijzigingen aanbrengen.',
};

const nonAdminMessage: SaveBarMessage = {
  key: 'notAuthorized',
  message: text.notAuthorized,
  type: 'warning',
};

const BrandBlocks: React.FCC<Props> = ({ accountData }) => {
  const hasFlows = usePermissions(['root.automation']).allowed;
  const viewingModeProps = useViewingModeProps();
  const [initialChanges, setInitialChanges] =
    useRecoilState(brandSettingsInitial);
  const [currentChanges, setCurrentChanges] =
    useRecoilState(brandSettingsCurrent);

  const resetInitial = useResetRecoilState(brandSettingsInitial);
  const resetCurrent = useResetRecoilState(brandSettingsCurrent);

  const mayEdit = accountData?.getAccount?.relation.role === UserRole.Admin;
  const accountSettings = accountData?.getAccount?.AccountSettings;

  const initialValues = {
    unsubscribeLinkText: null,
    logo: accountSettings?.logo || null,
    colors: accountSettings?.colors || defaultColors,
    url: accountSettings?.url || null,
    style: {
      __typename: 'AccountStyle' as const,
      fontFamily: accountSettings?.style?.fontFamily || null,
    },
  };

  useEffect(() => {
    setInitialChanges(initialValues);
    setCurrentChanges(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      resetInitial();
      resetCurrent();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { changedPaths, differenceCount } = getDifferenceCount({
    initial: initialChanges,
    updated: currentChanges,
  });

  const uniqueTopLevelKeys = uniq(
    changedPaths.map(arr => arr[0] as keyof BrandSettings),
  );

  const { onUpdate, loading: updateLoading } = useUpdateMutations({
    flowsAllowed: hasFlows,
    changedFields: pick(uniqueTopLevelKeys, currentChanges),
    // logo.url is not allowed in update input
    prevData: dissocPath(['settings', 'logo', 'url'], {
      settings: accountData?.getAccount?.AccountSettings,
    }),
  });

  const onSave = async () =>
    await onUpdate({
      onReset: () => setInitialChanges(currentChanges),
    });

  const onCancel = () => setCurrentChanges(initialChanges);

  const disabled = !mayEdit || updateLoading || differenceCount === 0;
  const messages =
    mayEdit === false
      ? [nonAdminMessage]
      : differenceCount !== 0
        ? getValidationMessages({
            changes: currentChanges,
            flowsAllowed: hasFlows,
          })
        : undefined;

  const onUpdateField = (update: DeepPartial<BrandSettings>) => {
    setCurrentChanges(prev => mergeDeepRight(prev, update) as BrandSettings);
  };

  return (
    <JustificationContainer direction="column" gap="xl">
      <NewSaveBar
        onCancel={onCancel}
        onSave={onSave}
        messages={messages}
        loading={updateLoading}
        disabled={disabled}
        changes={differenceCount}
      />
      <AccountBlock
        values={omit(['unsubscribeLinkText'], currentChanges)}
        onUpdate={onUpdateField}
        readOnly={!mayEdit}
        {...viewingModeProps}
      />
      <EmailSettingsBlock
        onUpdate={onUpdateField}
        readOnly={!mayEdit}
        fontFamily={currentChanges.style?.fontFamily || null}
        hasFlows={hasFlows}
      />
    </JustificationContainer>
  );
};

export default BrandBlocks;
