import React, { useCallback, useEffect } from 'react';
import { AppType, NvmAppType, useStartNvmOauthMutation } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useNativePopup from '~/hooks/useNativePopup';
import {
  nvmOAuthSuccessPath,
  nvmSuccessMessage,
} from '~/components/page/Apps/BBWaardecheck/components/NvmOAuthSuccess';
import { FailedApps, title } from '../..';
import useErrorReporter from '~/hooks/useErrorReporter';
import { prop, uniqBy } from 'ramda';
import type { AppsAndItemsToEnable } from '~/components/organism/WizardSteps/OnboardingWizardSteps/SelectAppsForWidget';
import type { AppStatus__typename } from '~/graphql/types.client';

/**
 * Status of oAuth flow in 2 scenarios
 */
export enum OAuthState {
  /**
   * With oAuth flow: 1. pending 2. running 3. completed
   */
  Pending = 'pending',
  Running = 'running',
  Completed = 'completed',

  /**
   * Without oAuth flow: 1. not-required
   */
  NotRequired = 'not-required',
}

export type Props = {
  appsToEnable: AppsAndItemsToEnable | null;
  oAuthState: OAuthState;
  setOAuthState: React.Dispatch<React.SetStateAction<OAuthState>>;
  setFailedApps: React.Dispatch<React.SetStateAction<Array<FailedApps>>>;
  handleAppAfterEnabling: (val: {
    appStatusType: AppStatus__typename;
    appType: AppType;
  }) => Promise<void>;
};

export const bbWcAppStatusKey = 'AppStatus_BBWaardecheck';
const closePopupTimer = 3000;

const ProcessOAuthComponent: React.FCC<Props> = ({
  appsToEnable,
  setOAuthState,
  handleAppAfterEnabling,
  setFailedApps,
}) => {
  const errorReporter = useErrorReporter();
  const { id: accountId } = useCurrentAccount();
  const [startNvmOAuth] = useStartNvmOauthMutation();

  const onClosePopup = useCallback(() => {
    setOAuthState(OAuthState.Completed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { open, close } = useNativePopup({
    onClose: onClosePopup,
  });

  // Listen to the message from popup window
  useEffect(() => {
    let timer;
    const callbackFn = async event => {
      if (typeof event.data !== 'string') return;

      // handle success case
      if (event.data === nvmSuccessMessage) {
        setOAuthState(OAuthState.Completed);

        // remove it from failed apps
        setFailedApps(prev =>
          prev.filter(app => app.typename !== bbWcAppStatusKey),
        );

        await handleAppAfterEnabling({
          appType: AppType.BbWaardecheck,
          appStatusType: 'AppStatus_BBWaardecheck',
        });

        // Close popup automatically after 3s
        timer = setTimeout(() => close(), closePopupTimer);
      }
    };

    window.addEventListener('message', callbackFn);

    return () => {
      window.removeEventListener('message', callbackFn);
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleAppAfterEnabling]);

  // Start oAuth flow
  useEffect(() => {
    // already set it to failed here, only remove it if the whole flow is successfully completed
    const newEntry: FailedApps = {
      typename: bbWcAppStatusKey,
      widgetItems: appsToEnable ? appsToEnable[bbWcAppStatusKey] : [],
    };

    setFailedApps(prev => uniqBy(prop('typename'), [newEntry, ...prev]));

    setOAuthState(OAuthState.Running);

    const handleOAuth = async () => {
      const { data, errors } = await startNvmOAuth({
        variables: {
          accountId,
          participatingOffices: 1,
          callbackUrl: `${origin}/-/apps/${nvmOAuthSuccessPath}`,
          appType: NvmAppType.BbWaardecheck,
        },
      });

      if (data) open({ url: data.startNVMOauth.redirectUrl });

      if (errors && errors.length > 0) {
        // set it to completed so we can continue enabling other apps
        setOAuthState(OAuthState.Completed);

        errorReporter.captureMessage(
          `Error enabling BB_Waardecheck app in ${title} wizard step: ${JSON.stringify(
            errors,
          )}`,
          'error',
        );
      }
    };

    void handleOAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ProcessOAuthComponent;
