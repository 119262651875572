import React, { useCallback } from 'react';
import { EdgeProps, getSmoothStepPath } from 'reactflow';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { actionsSelector } from '~/components/page/Automation/v2/state';
import AnimatedPath from '../AnimatedPath';
import AddBlockButton from '../AddBlockButton';
import { getActionTemplate } from '../../../../constants/actionTemplates';
import useBuilderContext from '../../../../hooks/useBuilderContext';
import onUpdateActions from '../../utils/onUpdateActions';
import { ClientFlowAction } from '~/graphql/types.client';
import lastAddedActionId from '~/components/page/Automation/v2/state/lastAddedActionId';
import interactions, {
  shouldDisable,
} from '~/components/page/Automation/v2/state/interactions';
import {
  DISABLED_EDGE_COLOR,
  MARKER_END_DISABLED,
} from '../../../MarkerDefinitions';
import { EDGE_BORDER_RADIUS } from '../BaseEdge';

export enum Portal {
  Source = 'source',
  Target = 'target',
}

export type PortalEdgeData = {
  /** Source (parent) action id */
  sourceId: string;

  /** Target (child) action id */
  targetId: string;

  /** The edges that are shown only when cards are highlighted */
  isShownOnHighlight?: boolean;

  /** Color of portal edge */
  color: string;

  /** Type of portal */
  type?: Portal;
} | null;

export type Props = EdgeProps<PortalEdgeData> & {};

const PortalEdge: React.FCC<Props> = React.memo(
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    markerEnd,
  }) => {
    const { flowBlueprintId, accountId } = useBuilderContext();
    const edgePath = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      borderRadius: EDGE_BORDER_RADIUS,
    });

    const [actions, setActions] = useRecoilState(
      actionsSelector({
        accountId,
        flowBlueprintId,
      }),
    );

    const setLastAddedActionId = useSetRecoilState(lastAddedActionId);
    const currentInteraction = useRecoilValue(interactions);

    const onAddAction = useCallback(
      (actionType: ClientFlowAction['actionType']) => {
        const actionTemplate = getActionTemplate({
          flowBlueprintId,
          accountId,
          actionType,
        });

        const targetAction = actions.find(a => a.id === data?.targetId);
        const primaryParentId =
          targetAction && 'parentIds' in targetAction
            ? targetAction.parentIds?.[0]
            : null;

        if (!primaryParentId) return;

        const source =
          data?.type === 'source' ? data?.sourceId : primaryParentId;

        if (actionTemplate !== null && data != null) {
          setActions(actions =>
            onUpdateActions(actions, {
              source,
              target: data?.targetId,
              subject: actionTemplate,
              keepPrevParentsOfTarget: true,
            }),
          );
          setLastAddedActionId(actionTemplate.id);
        }
      },
      [
        accountId,
        data,
        flowBlueprintId,
        setActions,
        setLastAddedActionId,
        actions,
      ],
    );

    const disabled = useRecoilValue(shouldDisable);

    const actionsHighlighted = currentInteraction?.type === 'highlightActions';
    const connectionActive = currentInteraction?.type === 'connectAction';
    const copyActive = currentInteraction?.type === 'copyAction';
    const shownWhenHighlighted = data?.isShownOnHighlight;

    const selfHighlighted =
      actionsHighlighted &&
      shownWhenHighlighted &&
      currentInteraction?.highlightedActions?.[0] === data?.sourceId &&
      currentInteraction?.highlightedActions?.[1] === data?.targetId;

    const invisible = shownWhenHighlighted && !selfHighlighted;
    const edgeDisabled =
      (actionsHighlighted && !selfHighlighted) ||
      connectionActive ||
      copyActive;

    if (invisible) return null;

    return (
      <>
        <AnimatedPath
          id={id}
          style={{
            ...style,
            stroke: edgeDisabled ? DISABLED_EDGE_COLOR : data?.color,
            strokeWidth: selfHighlighted ? 3 : 2,
            pointerEvents: 'none',
          }}
          className="react-flow__edge-path"
          d={edgePath[0]}
          markerEnd={!edgeDisabled ? markerEnd : MARKER_END_DISABLED}
        />

        {!disabled && (
          <AddBlockButton
            id={id}
            targetX={targetX}
            targetY={targetY}
            onAddAction={onAddAction}
          />
        )}
      </>
    );
  },
);

export default PortalEdge;
