import React from 'react';
import { Props } from '..';

const ARX: React.FCC<Props> = ({ color, ...props }) => (
  <svg
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.1046 35.2998C47.3046 41.2998 41.8046 47.5998 36.4046 47.5998C31.0046 47.5998 26.4046 40.7998 29.3046 35.2998L36.4046 23L44.1046 35.2998Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.5046 35.3999L46.5046 53.1999L53.7046 52.0999L50.9046 64.7999L61.4046 46.6999L54.5046 47.5999L57.5046 35.3999Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6046 15C15.6046 15 9.10459 17.7 10.1046 24.3C10.7046 28.4 14.3046 31.5 18.1046 31.7C22.8046 32 29.0046 27.4 27.6046 19.2C26.0046 9.9 15.9046 8 15.9046 8C15.9046 8 20.9046 13.5 20.5046 17.2C20.3046 19.5 18.3046 20.6 16.5046 19.9C13.6046 18.9 15.6046 15 15.6046 15Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ARX;
