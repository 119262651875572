import { Props } from '..';
import React from 'react';

const Hypotheekbond: React.FCC<Props> = ({ ...props }) => (
  <svg
    width="1.2em"
    height="1.2em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: '-2px', marginLeft: '1px' }}
    {...props}
  >
    <path
      d="M20.4172 10.6994L19.7438 10.0458L10.0046 19.5L5.3999 15.0331L6.36231 14.0979L10.0014 17.6315L18.7803 9.10948L18.129 8.47614L20.6999 8.10001L20.4172 10.6994Z"
      fill="#94C11F"
    />
    <path d="M17.6997 20.7H19.1997V12.3L17.6997 13.6577V20.7Z" fill="#183459" />
    <path
      d="M11.2575 3.3L3.2998 11.209V20.7H4.66116V11.7657L11.2575 5.20961L16.1374 10.0603L17.0998 9.10761L11.2575 3.3Z"
      fill="#183459"
    />
  </svg>
);

export default Hypotheekbond;
