import React, { useRef } from 'react';
import { useSlate } from 'slate-react';
import styled from 'styled-components';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { EdgeDetectionContext } from '../../hooks/useEdgeContext';
import Divider from '~/components/atom/Divider';
import Buttons, { type ToolbarComponent } from './components/Buttons';

export type Props = {
  readOnly?: boolean;

  /** Custom elements in the editor */
  customElements: Array<ELEMENTS>;

  /** Is the editor in full screen mode */
  isFullScreen: boolean;

  /** Buttons to show */
  buttons: Array<ToolbarComponent>;

  /** Used in full-screen button */
  onChangeFullScreen: (value: boolean) => void;
};

const StaticToolbar: React.FCC<Props> = ({
  dataTestId,
  customElements = [],
  buttons,
  readOnly = false,
  isFullScreen,
  onChangeFullScreen,
  ...rest
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const editor = useSlate();

  const toolbarRect = containerRef.current?.getBoundingClientRect();

  return (
    <EdgeDetectionContext.Provider
      value={{
        right: toolbarRect?.right,
      }}
    >
      <Container
        data-testid={dataTestId}
        {...rest}
        onClick={e => {
          if (readOnly) e.preventDefault();
        }}
        style={{ pointerEvents: readOnly ? 'none' : 'auto' }}
        ref={containerRef}
      >
        <Buttons
          buttons={buttons}
          editor={editor}
          customElements={customElements}
          isFullScreen={isFullScreen}
          onChangeFullScreen={onChangeFullScreen}
        />
      </Container>

      {isFullScreen && <Divider margin={['s', null]} />}
    </EdgeDetectionContext.Provider>
  );
};

const Container = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  min-height: 20px;
`;

export default StaticToolbar;
