import React from 'react';
import styled, { css } from 'styled-components';
import { StrapiLayout } from '~/graphql/types';

export type Props = Omit<StrapiLayout, '__typename'> & {
  dataTestId?: string;
};

const StrapiLayout: React.FCC<Props> = ({ children, css }) => (
  <Container css={css}>{children}</Container>
);

const Container = styled.div(
  () => css`
    position: relative;
    width: 100%;
  `,
);

export default StrapiLayout;
