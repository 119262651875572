import { Props } from '..';
import React from 'react';

const DoubleChevron: React.FCC<Props> = ({
  color = 'currentColor',
  ...props
}) => (
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15.3587 10.5137H22.5507" stroke={color} strokeLinecap="round" />
    <path d="M18.9549 6.91772V14.1097" stroke={color} strokeLinecap="round" />
    <path
      d="M12.687 8.39398L9.98997 3L7.29298 8.39398L1 9.29298L5.49499 13.788L4.59599 20.0809L9.98997 17.384L15.384 20.0809L14.485 14.687"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DoubleChevron;
