import type { FlowPath } from '../getFieldsByPath';
import type { Flow___Pointer } from '~/graphql/types';
import { assertNever } from '~/util/assertion';

/**
 * Returns the pointer for a given path
 */
const getPointerForPath = (path: FlowPath): Flow___Pointer => ({
  __typename: 'Flow___Pointer',
  path: path.reduce((prev, curr) => {
    switch (curr.__typename) {
      case 'FlowData___Directory':
        if (!curr.instance) return prev;
        return [...prev, ...curr.instance.instance.key];
      case 'FlowData___InstanceField':
        return [...prev, curr.key];
      case 'FlowData___InstanceCondition':
        throw Error(`Cannot have instance condition in pointer`);
      case 'FlowData___SubjectField':
        throw Error(`Cannot have subject field in pointer`);
      default:
        return assertNever(curr);
    }
  }, [] as Array<string>),
});
export default getPointerForPath;
