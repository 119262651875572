import { filter, isEmpty, keys } from 'ramda';
import { ActivityFieldsFragment } from '~/graphql/types';
import type { GroupedActivities } from '~/state/activities';

/**
 * Removes the activity and its date group if it is the last activity on the date
 * @param {GroupedActivities} groupedActivities - Grouped activities
 * @param {ActivityFieldsFragment} deletedActivity - Activity to be removed
 * keywords: deleteActivity
 */
const removeActivityFromList = ({
  groupedActivities,
  deletedActivity,
}: {
  groupedActivities: GroupedActivities;
  deletedActivity: ActivityFieldsFragment;
}): GroupedActivities =>
  keys(groupedActivities).reduce((acc, date) => {
    const events = filter(
      item => item.id !== deletedActivity?.id,
      groupedActivities[date] ?? [],
    );

    if (isEmpty(events)) return acc;

    acc[date] = events;

    return acc;
  }, {} as GroupedActivities);

export default removeActivityFromList;
