import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer, {
  type Props as JustificationContainerProps,
} from '~/components/atom/JustificationContainer';

type Props = JustificationContainerProps;
const MidSectionContainer: React.FCC<Props> = ({ children, ...rest }) => (
  <Container
    direction="column"
    align="center"
    justification="space-around"
    {...rest}
  >
    {children}
    <Spacer />
  </Container>
);

const Spacer = styled.div<{}>`
  flex-grow: 1;
`;

const Container = styled(JustificationContainer)<{}>`
  flex-grow: 1;
  align-content: center;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.space('l')} ${theme.space('xxxxl')};
    color: ${theme.color('text')};
  `};

  > * {
    width: 100%;
  }
`;

export default MidSectionContainer;
