import {
  FlowData___DirectoryFragment,
  FlowData___FlowInstanceFragment,
} from '~/graphql/types';

type GenerateInstanceV2 = {
  instanceType: { type: string; typeId?: string };
  instanceKey: Array<string>;
  dirs: Array<Pick<FlowData___DirectoryFragment, 'key' | 'label' | 'weight'>>;
};
const generateInstanceV2 = ({
  instanceType,
  instanceKey,
  dirs,
}: GenerateInstanceV2): FlowData___FlowInstanceFragment => ({
  __typename: 'FlowData___FlowInstance',
  dir: dirs.map(dir => ({ ...dir, __typename: 'FlowData___Directory' })),
  instanceType: {
    __typename: 'Flow___SubjectId',
    ...instanceType,
  },
  key: instanceKey,
});

export default generateInstanceV2;
