import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { EmailTracking } from '~/graphql/types';
import StatisticsRow from '../StatisticsRow';
import { isNil } from 'ramda';

export type Props = {
  dataTestId?: string;
  tracking: EmailTracking;
};

const text = {
  opened: 'Geopend',
  clicked: 'Links geklikt',
};

const EmailStatistics: React.FC<Props> = ({
  dataTestId,
  tracking,
  ...rest
}) => {
  const numberOfOpens = tracking.opened ? tracking.opened : null;
  const numberOfClicks = tracking.clicked ? tracking.clicked.total : null;

  if (isNil(numberOfOpens) && isNil(numberOfClicks)) return null;

  return (
    <>
      <JustificationContainer
        {...rest}
        width="100%"
        gap="m"
        padding={['xxxs', null]}
        dataTestId={dataTestId}
      >
        {!isNil(numberOfOpens) && (
          <StatisticsRow numberOfActions={numberOfOpens} label={text.opened} />
        )}
        {!isNil(numberOfClicks) && (
          <StatisticsRow
            numberOfActions={numberOfClicks}
            label={text.clicked}
          />
        )}
      </JustificationContainer>
    </>
  );
};

export default EmailStatistics;
