import type {
  FormBuilder_NodeFragment,
  FormBuilder_OptionList,
} from '~/graphql/types';
import isSelectTypeBlock from '../typeguards/isSelectTypeBlock';

export type OptionListMap = Record<string, Array<string>>;
const getOptionListsMap = (
  nodes: Array<FormBuilder_NodeFragment>,
  optionLists: Array<FormBuilder_OptionList>,
): OptionListMap => {
  if (optionLists.length === 0 || nodes.length === 0) return {};

  return optionLists.reduce((acc, optionList) => {
    acc[optionList.id] = [...(acc[optionList.id] ?? [])];

    for (const node of nodes) {
      if (node.__typename === 'FormBuilder_ScreenNode') {
        const selectTypeBlocks = node.blocks.filter(isSelectTypeBlock);

        for (const block of selectTypeBlocks) {
          if (optionList.id === block.optionListId) {
            acc[optionList.id].push(node.id);
          }
        }
      }
    }

    return acc;
  }, {} as OptionListMap);
};

export default getOptionListsMap;
