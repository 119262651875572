import { ApolloQueryResult } from '@apollo/client';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { navigate } from '@gatsbyjs/reach-router';
import React, { useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import DHRouter from '~/components/atom/DHRouter';
import Button from '~/components/atom/Button';
import Divider from '~/components/atom/Divider';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import Link from '~/components/molecule/Link';
import { Body } from '~/components/atom/Typography';
import {
  AppStatusRealworksFieldsFragment,
  Exact,
  GetAppStatusRealworksQuery,
} from '~/graphql/types';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import AddTokenContainerWizard from './components/AddTokenContainerWizard';
import TokenContainer from './components/TokenContainer';

import TEST_ID from './index.testid';

type Props = {
  appStatus: AppStatusRealworksFieldsFragment;
  refreshAppStatus: (
    variables?:
      | Partial<
          Exact<{
            accountId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetAppStatusRealworksQuery>>;
} & RouteComponentProps;

const Connections: React.FCC<Props> = ({ appStatus, refreshAppStatus }) => {
  useEffect(() => {
    if (appStatus.tokens.length === 0) {
      void navigate(`/-/apps/realworks/wizard/`, { replace: true });
    }
    // We only want to check this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokenTransitions = useTransition(appStatus.tokens, {
    from: {
      opacity: 0,
      transform: 'translate(0, 40px)',
    },
    enter: {
      opacity: 1,
      transform: 'translate(0, 0px)',
    },
    leave: {
      opacity: 0,
      transform: 'translate(0, 40px)',
      height: 0,
    },
    trail: 125,
    keys: tokenContainer => tokenContainer.id,
  });

  return (
    <AppDetailsContainer
      header="Koppelingen overzicht"
      icon="link"
      loading={false}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body>
        Met een API koppeling voeg je Realworks contacten toe in DatHuis of
        stuur je leads door naar Realworks. Kies de Relaties API om contacten
        uit Realworks in DatHuis toe te voegen. Gebruik de Wonen API om leads
        door te sturen naar Realworks.
      </Body>

      <TipBanner id="realworks-connections-tip" margin={['m', null]}>
        <Body margin={[null]}>
          Je kunt meer lezen over hoe je de koppeling moet instellen in onze{' '}
          <Link to="https://help.dathuis.nl/">kennisbank</Link>. Kom je er niet
          uit?{' '}
          <ChatLink
            text="Ik heb hulp nodig"
            linkText="Start een chat met ons."
          />
        </Body>
      </TipBanner>

      {tokenTransitions((style, tokenContainer) => (
        // @ts-ignore
        <animated.div style={style}>
          <TokenContainer
            key={tokenContainer.id}
            {...tokenContainer}
            refreshAppStatus={refreshAppStatus}
          />
          <Divider />
        </animated.div>
      ))}

      <Button
        ghost
        appearance="secondary"
        label="Nog een koppeling toevoegen"
        icon="plus"
        size="medium"
        style={{ width: '100%', justifyContent: 'flex-start' }}
        onClick={() => navigate(`/-/apps/realworks/wizard/`)}
        dataTestId={TEST_ID.ADD_TOKEN_CONTAINER}
      />
      <DHRouter>
        <AddTokenContainerWizard
          path="/wizard/*"
          onClose={() => {
            void refreshAppStatus().then(() => {
              void navigate('/-/apps/realworks');
            });
          }}
          refreshAppStatus={refreshAppStatus}
        />
      </DHRouter>
    </AppDetailsContainer>
  );
};

export default Connections;
