import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  IMAGE: `${cleanedFilename(__filename)}-image`,
  HEADING: `${cleanedFilename(__filename)}-heading`,
  ESTIMATION_LABEL: `${cleanedFilename(__filename)}-estimation-label`,
  ESTIMATION_DISCLAIMER: `${cleanedFilename(__filename)}-estimation-disclaimer`,
  CONVERSION_MESSSAGE_HEADING: `${cleanedFilename(
    __filename,
  )}-conversion-message-heading`,
  CONVERSION_MESSSAGE_BODY_MORE: `${cleanedFilename(
    __filename,
  )}-conversion-message-body-more`,
  CONVERSION_MESSSAGE_BODY_LESS: `${cleanedFilename(
    __filename,
  )}-conversion-message-body-less`,
  CONVERSION_MESSSAGE_FOOTER: `${cleanedFilename(
    __filename,
  )}-conversion-message-footer`,
  CONVERSION_MESSSAGE_PRIMARY_BUTTON: `${cleanedFilename(
    __filename,
  )}-conversion-message-primary-button`,
  PROPERTY_PRIMARY_BUTTON: `${cleanedFilename(
    __filename,
  )}-property-primary-button`,
  PROPERTY_SALE_PRICE: `${cleanedFilename(__filename)}-property-sale-price`,
  HIGHER_SALE_PRICE: `${cleanedFilename(__filename)}-higher-sale-price`,
  LOWER_SALE_PRICE: `${cleanedFilename(__filename)}-lower-sale-price`,
};
