import { isNil } from 'ramda';
import { useRef, useEffect, useMemo } from 'react';

/**
 * Creates DOM element to be used as React root.
 */
const createElement = (id: string): HTMLElement => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

/**
 * Appends element as last child of body.
 */
const addRootElement = (rootElem: Element) => {
  if (document.body.lastElementChild)
    document.body.insertBefore(
      rootElem,
      document.body.lastElementChild.nextElementSibling,
    );
};

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements
 * so there is no need to ensure the parent target already
 * exists.
 */
const usePortal = (id: string = 'modal-portal'): HTMLElement => {
  const rootElemRef = useRef<HTMLElement | null>(null);

  const getRootElem = useMemo(
    () => () => {
      if (isNil(rootElemRef.current)) {
        const inner = createElement(`${id}-inner`);
        rootElemRef.current = inner;
      }

      return rootElemRef.current;
    },
    [id],
  );

  useEffect(
    function setupElement() {
      // Look for existing target dom element to append to
      const existingParent = document.querySelector(`#${id}-root`);

      // if the parent already exists, remove and recreate it
      // so we can ensure its added to the end of the document
      if (existingParent) {
        existingParent.remove();
      }

      // Create fresh parent element
      const parentElem = createElement(`${id}-root`);

      // Add parent to end of document
      addRootElement(parentElem);

      const rootEl = getRootElem();
      // Add the detached element to the parent
      parentElem.appendChild(rootEl);

      return function removeElement() {
        const rootEl = getRootElem();
        const parent = document.querySelector(`#${id}`);

        rootEl?.remove();
        parent?.remove();
      };
    },
    [getRootElem, id],
  );

  return getRootElem();
};

export default usePortal;
