import React from 'react';
import styled, { css } from 'styled-components';
import Button, { Props as ButtonProps } from '~/components/atom/Button';

const FullWidthButton: React.FCC<ButtonProps & { center?: boolean }> = ({
  center = false,
  ...rest
}) => <FullButton {...rest} $center={center} />;

const FullButton = styled(Button)<{ $center: boolean }>(
  ({ theme, $center }) => css`
    width: 100%;
    justify-content: ${$center ? 'center' : 'start'};

    ${theme.mq.lessThan('tabletLandscape')`
      justify-content: center;
    `}
  `,
);

export default FullWidthButton;
