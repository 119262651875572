import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import Button from '~/components/atom/Button';
import Divider from '~/components/atom/Divider';
import icons from '~/components/atom/Icon/components';
import JustificationContainer from '~/components/atom/JustificationContainer';

import Breadcrumbs, {
  Crumb,
  Props as BreadcrumbProps,
} from './components/Breadcrumbs';
import IconOnlyButton from './components/IconOnlyButton';
import arrayToCss from '~/util/arrayToCss';
import TEST_ID from './index.testid';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';

const text = {
  emptyBreadcrumbLabel: 'Selecteer categorie',
};

export type Action = {
  /**
   * Key needs to be unique for React
   */
  key: string;

  /**
   * What happens when the user clicks an action
   */
  onClick: () => void;

  disabled?: boolean;

  /**
   * What icon should we show?
   */
  icon: keyof typeof icons;

  /**
   * Switches between icon only and different button appearances
   */
  appearance: 'icon' | 'primary' | 'secondary' | 'danger';

  dataTestId?: string;
};

type PointerPosition = {
  location?: 'top' | 'bottom';
  offset?: number | null;
};

export type Props = {
  children?: React.ReactNode;

  /**
   * Used to offset the "triangle indicator" to point to a parent
   * value will be applied as `px`
   * Eliding this triggers a fallback of 24px
   */
  // pointerOffset?: number | null;

  /**
   * Determines the position of the pointing triangle
   * off set is counted from left when on top or bottom
   *
   * Eliding this triggers a fallback of offset: ${DEFAULT_OFFSET} and location: 'bottom'
   */
  pointerPosition?: PointerPosition;

  overflowY?: 'visible' | 'scroll' | 'hidden';
  maxHeightInPx?: number;

  /**
   * The breadcrumbs
   */
  crumbs: Array<Crumb>;
  /**
   * When a crumb gets clicked
   */
  onBreadcrumbClick: BreadcrumbProps['onClick'];

  /**
   * Any button you want to show in the header
   */
  actions: Array<Action | null>;

  /**
   * Is shown when crumbs is an empty array
   */
  helpLink?: React.ReactNode;

  /**
   * Is shown when passed, helpLink is empty and breadcrumbs array is empty
   */
  emptyBreadcrumbLabel?: React.ReactNode;
};

export const DEFAULT_OFFSET = 24;
export const DEFAULT_MAX_HEIGHT = 185;

const Selector = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      dataTestId,
      crumbs,
      onBreadcrumbClick,
      overflowY = 'scroll',
      maxHeightInPx = DEFAULT_MAX_HEIGHT,
      pointerPosition = {
        location: 'bottom',
        offset: DEFAULT_OFFSET,
      },
      actions,
      children,
      helpLink,
      emptyBreadcrumbLabel = text.emptyBreadcrumbLabel,
      ...rest
    },
    ref,
  ) => {
    const filteredActions = actions.filter(
      action => action !== null,
    ) as Array<Action>;

    return (
      <Container
        $pointerPosition={pointerPosition}
        data-testid={dataTestId ?? TEST_ID.CONTAINER}
        ref={ref}
        {...rest}
        onClick={e => e.stopPropagation()}
      >
        <TopContainer
          justification="space-between"
          align="center"
          padding={['s', 'l']}
        >
          {crumbs.length === 1 && crumbs[0].key === 'root' ? (
            // We need this span to ensure there are always 2 children to the JustificationContainer
            // so the flex layout works
            <span>{helpLink ?? emptyBreadcrumbLabel}</span>
          ) : (
            <Breadcrumbs crumbs={crumbs} onClick={onBreadcrumbClick} />
          )}
          <ActionContainer>
            {filteredActions.map(
              ({ appearance, disabled, icon, onClick, dataTestId }, index) =>
                appearance === 'icon' ? (
                  <IconOnlyButton
                    icon={icon}
                    onClick={onClick}
                    key={index}
                    dataTestId={dataTestId || TEST_ID.ICON_ONLY_BUTTON}
                  />
                ) : (
                  <Button
                    icon={icon}
                    disabled={disabled}
                    onClick={onClick}
                    appearance={appearance}
                    key={index}
                    margin={
                      index === actions.length - 1
                        ? [null]
                        : [null, 'xxs', null, null]
                    }
                    dataTestId={dataTestId}
                  />
                ),
            )}
          </ActionContainer>
        </TopContainer>
        <StyledDivider margin={[null, 'l']} />
        <Inner overflowY={overflowY} maxHeightInPx={maxHeightInPx}>
          {children}
        </Inner>
      </Container>
    );
  },
);

const topStyles = (offset: number) => css`
  ${({ theme }) => css`
    top: -${theme.remToPxRaw(theme.space('l')) / 2 + 0.6}px;
    left: ${offset - 1}px;

    border: ${theme.getTokens().border.width.s} solid ${theme.color('tertiary')};
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
  `}
`;

const bottomStyles = (offset: number) => css`
  ${({ theme }) => css`
    bottom: -${theme.remToPxRaw(theme.space('l')) / 2 + 0.6}px;
    left: ${offset - 1}px;

    border: ${theme.getTokens().border.width.s} solid ${theme.color('tertiary')};
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
  `}
`;

const Container = styled.div<{
  $pointerPosition: PointerPosition;
}>(({ theme, $pointerPosition }) => {
  const distance =
    Math.sqrt(
      2 *
        theme.remToPxRaw(theme.space('l')) *
        theme.remToPxRaw(theme.space('l')),
    ) / 2;

  return css`
    display: flex;
    flex-direction: column;
    max-height: calc(100% - ${distance}px);
    width: 100%;
    position: relative;
    font-size: ${theme.fs('s')};
    background-color: ${theme.color('white')};
    border: ${theme.getTokens().border.width.s} solid ${theme.color('tertiary')};
    z-index: ${theme.z('dropdown')};
    border-radius: ${theme.getTokens().border.radius.s};
    user-select: none;

    &:after {
      content: '';

      position: absolute;
      width: ${theme.space('l')};

      ${$pointerPosition.location === 'top'
        ? topStyles($pointerPosition.offset ?? DEFAULT_OFFSET)
        : bottomStyles($pointerPosition.offset ?? DEFAULT_OFFSET)}

      height: ${theme.space('l')};

      background-color: ${theme.color('white')};

      box-sizing: border-box;

      /**
    * We made a square and we rotate it 45 degrees so it appears as a triangle.
    */
      transform: translate3d(-50%, 0, 0px) rotate(45deg);

      /* -1 so it falls behind its parent */
      z-index: -1;
    }
  `;
});

const StyledDivider = styled(Divider)`
  width: auto;
  max-width: 100%;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const overflowStyles = (
  theme: DefaultTheme,
  overflowY: 'hidden' | 'visible' | 'scroll',
  maxHeightInPx: number,
) => css`
  max-height: ${maxHeightInPx}px;
  overflow-y: ${overflowY};
  padding: ${arrayToCss(['s', 'l'], theme)};

  ${scrollBarStyles}
`;

const Inner = styled.ul<{
  overflowY: NonNullable<Props['overflowY']>;
  maxHeightInPx: NonNullable<Props['maxHeightInPx']>;
}>(
  ({ theme, overflowY, maxHeightInPx }) => css`
    list-style: none;
    margin: 0;
    ${overflowStyles(theme, overflowY, maxHeightInPx)}
  `,
);

const TopContainer = styled(JustificationContainer)`
  max-width: 100%;
`;

export default Selector;
