import type { OptionOf } from '~/components/molecule/Dropdown';

const getDateRangeOptions = (): Array<OptionOf<number | null>> => [
  {
    key: 'all-time',
    label: 'Altijd',
    payload: null,
  },
  {
    key: 'last-90-days',
    label: 'Afgelopen 90 dagen',
    payload: 90,
  },
  {
    key: 'last-two-week',
    label: 'Afgelopen 14 dagen',
    payload: 14,
  },
  {
    key: 'last-week',
    label: 'Afgelopen week',
    payload: 7,
  },
];
export default getDateRangeOptions;
