import { ClientFlowAction } from '~/graphql/types.client';
import getDirectParentIdsOfAction from '../getDirectParentIdsOfAction';
import getPossiblePaths from './utils/getPossiblePaths';

/**
 * Returns common actions that exist in all possible paths
 * @param {Array<ClientFlowAction>} actions - Actions state
 * @param {ClientFlowAction} subjectAction - Get parents of this action
 * keywords: common actions, common parents
 */
export const getCommonParentsInAllPaths = ({
  actions,
  subjectAction,
}: {
  actions: Array<ClientFlowAction>;
  subjectAction: ClientFlowAction;
}): Array<ClientFlowAction> => {
  const primaryPath = getDirectParentIdsOfAction(actions, subjectAction);

  const possiblePaths = getPossiblePaths({ actions, action: subjectAction });

  const commonActions: Array<string> | undefined = possiblePaths
    .shift()
    ?.reduce((prev: Array<string>, actionId: string) => {
      if (
        primaryPath.includes(actionId) &&
        possiblePaths.every(arr => arr.includes(actionId))
      )
        prev.push(actionId);
      return prev;
    }, []);

  return actions.filter(({ id }) => commonActions?.includes(id));
};

export default getCommonParentsInAllPaths;
