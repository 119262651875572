import type { ContactHasFundaViewingRequestEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

const asHasFundaViewingRequestEventFilterInput = (
  filter: ContactHasFundaViewingRequestEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => ({
  App: {
    Funda: {
      ViewingRequest: { has: asHasFilterInput(filter.has) },
    },
  },
});

export default asHasFundaViewingRequestEventFilterInput;
