import React from 'react';
import styled from 'styled-components';

import Overlay from '~/components/organism/ModalsV2/Overlay';
import Modal from '~/components/organism/ModalsV2/Modal';
import TaskModalContent, { ModalProps } from './components/TaskModalContent';

const TaskModal: React.FC<ModalProps> = ({
  onClose,
  initialTaskDetails,
  account,
  selectedInFilterOfficeId,
  selectedInFilterUserId,
}) => (
  <Overlay root="task-modal" onClose={() => onClose()}>
    <Modal onClose={() => onClose()}>
      <Container>
        <TaskModalContent
          selectedInFilterOfficeId={selectedInFilterOfficeId}
          selectedInFilterUserId={selectedInFilterUserId}
          account={account}
          onClose={onClose}
          initialTaskDetails={initialTaskDetails}
        />
      </Container>
    </Modal>
  </Overlay>
);

const Container = styled.div<{}>`
  height: 100vh;
`;

export default TaskModal;
