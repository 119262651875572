import React from 'react';
import { Link } from '~/components/molecule/Link';
import Logo from '~/components/atom/Logo';
import styled, { css } from 'styled-components';
import VideoJS, { VideoMime } from '~/components/molecule/VideoJS';

const PromoSection: React.FC<{}> = () => (
  <Container>
    <PromoVideo
      className="video-background"
      options={{
        autoplay: true,
        muted: true,
        controls: false,
        fluid: false,
        responsive: true,
        loop: true,
        sources: [
          {
            type: VideoMime.MP4,
            src: '/static/video/promo1.mp4',
          },
        ],
      }}
    />

    <LogoImage>
      <Link to={'/-/'}>
        <Logo name="dathuis" secondary />
      </Link>
    </LogoImage>
  </Container>
);

export const Container = styled.aside<{}>`
  min-height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 0;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  transition: width 0.1s linear;
  position: relative;

  ${({ theme }) => css`
    background: ${theme.color('primary')};
    color: ${theme.color('white')};
    box-shadow: ${theme.getTokens().boxShadow.modal};

    ${theme.mq.greaterThan('tablet')`
    flex: 1 0 50%;
  `}
  `};

  & > * {
    text-align: center;
  }
`;

export const LogoImage = styled.div<{}>(
  ({ theme }) => css`
    position: absolute;

    flex: 0;
    margin-bottom: auto;

    & svg {
      width: 300px;
      fill: ${theme.color('white')};
    }
  `,
);

const PromoVideo = styled(VideoJS)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: ${theme.color('primary', 'dark')};
      opacity: 0.5;
    }

    .vjs-tech {
      object-fit: cover; /* Ensures the video covers the container */
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
    }

    /* Overwrites video dimensions calculated by video-js library */
    .video-js {
      width: 50vw;
      height: 100vh;
    }
  `,
);

export default React.memo(PromoSection);
