import { Nav } from '~/components/template/MasterDetail/components/Master';

export const navBarItems: Array<Nav> = [
  {
    type: 'link',
    to: '',
    icon: 'gear',
    name: 'Algemeen',
  },
  { type: 'link', to: '/design', icon: 'layout', name: 'Ontwerp' },
  { type: 'separator' },
  { type: 'heading', title: "Pagina's & teksten" },
  {
    type: 'link',
    to: '/address',
    icon: 'map-pin',
    name: '1. Adres',
  },
  {
    type: 'link',
    to: '/propertyType',
    icon: 'home',
    name: '2. Type Huis',
  },
  {
    type: 'link',
    to: '/why',
    icon: 'bar-chart',
    name: '3. Reden waardecheck',
  },
  {
    type: 'link',
    to: '/when',
    icon: 'truck',
    name: '4. Verhuisintentie',
  },
  {
    type: 'link',
    to: '/comparison',
    icon: 'chevrons-right',
    name: '5.1. Swipen',
  },
  {
    type: 'link',
    to: '/valuationFailure',
    icon: 'x-circle',
    name: '5.2. Geen matches',
  },
  {
    type: 'link',
    to: '/appraisalRequest',
    icon: 'file',
    name: '5.3. Waardebepaling',
  },
  {
    type: 'link',
    to: '/appraisalRequestSuccess',
    icon: 'clock',
    name: '5.4. Waardebepaling aangevraagd',
  },
  {
    type: 'link',
    to: '/valuationSuccess',
    icon: 'mail',
    name: '6. Contactgegevens',
  },
  {
    type: 'link',
    to: '/report',
    icon: 'file-text',
    name: '7.1. Rapport',
  },
  {
    type: 'link',
    to: '/about',
    icon: 'info',
    name: '7.2. Over ons',
  },
];
