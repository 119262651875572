// An IBAN is validated by converting it into an integer and performing a basic mod-97 operation
// If the IBAN is valid, the remainder equals 1. - https://en.wikipedia.org/wiki/International_Bank_Account_Number#Validating_the_IBAN

// IBAN validation check in javascript - https://stackoverflow.com/questions/21928083/iban-validation-check

const mod97 = (digits: string): number | string => {
  let checksum: number | string = digits.slice(0, 2);

  for (let offset = 2; offset < digits.length; offset += 7) {
    const fragment = String(checksum) + digits.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  return checksum;
};

export default mod97;
