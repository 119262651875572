import { reporter } from '~/hooks/useErrorReporter';
import Validation from '~/util/Validation';

export type NylasAuthPath = '/auth/v1/' | '/auth/v2/';

/**
 * Returns the link to sign in via Nylas in settings pages
 * @param {string} state
 * @param {string} email
 * keywords: nylaslink
 */
const getNylasLink = ({
  state,
  email,
  redirectPath,
}: {
  state: string;
  email?: string;
  redirectPath: NylasAuthPath;
}): string => {
  let link = `https://api.nylas.com/oauth/authorize?client_id=${process.env.NYLAS_CLIENT_ID}&response_type=code&scopes=${['email.read_only', 'email.send', 'contacts.read_only'].join(',')}`;

  if (email && Validation.Email.isValid(email)) {
    link = link + `&login_hint=${email}`;
  }

  const redirectUrl = `${process.env.BASE_URL}/-/external/nylas${redirectPath}`;
  link = link + `&redirect_uri=${redirectUrl}`;

  if (!Validation.String.isNonEmptyString(state)) {
    reporter.captureException(
      new Error(
        'Error while trying to build Nylas link because the state is not a string.',
      ),
    );
    return '';
  }

  if (state.length > 255) {
    reporter.captureException(
      new Error(
        `Error while trying to build Nylas link because the state: ${state} is too long. Max length is 255 characters.`,
      ),
    );
    return '';
  }

  link = link + `&state=${state}`;

  return link;
};

export default getNylasLink;
