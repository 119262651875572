import { WizardStep } from '~/components/organism/Wizard/context/WizardContext';
import {
  title as genericWidgetConfigurationTitle,
  id as genericWidgetConfigurationId,
} from '../../GenericSteps/GenericWidgetConfiguration';
import {
  title as enableFlowsTitle,
  id as enableFlowsId,
} from '../../Apps/components/EnableFlowsForAppStatus';
import {
  title as overviewTitle,
  id as overviewId,
} from '../../Apps/components/AppStatusOverview';
import { WizardFlow } from '~/graphql/types.client';
import { NotificationWizardIds } from '~/graphql/types';

type ConfiguredWizardFlowMap = {
  [key in NotificationWizardIds]: {
    header: string;
    steps: Array<WizardStep>;
  };
};
const trustooTypename = 'AppStatus_Trustoo';
const configuredWizardFlowMap: ConfiguredWizardFlowMap = {
  [NotificationWizardIds.PromptTrustooWidget]: {
    header: 'Trustoo',
    steps: [
      {
        title: genericWidgetConfigurationTitle,
        id: genericWidgetConfigurationId,
        metadata: { typename: trustooTypename },
      },
      {
        title: enableFlowsTitle,
        id: enableFlowsId,
        metadata: { typename: trustooTypename },
      },
      {
        title: overviewTitle,
        id: overviewId,
        metadata: { typename: trustooTypename },
      },
    ],
  },
};

const getConfiguredWizardFlow = (
  id: NotificationWizardIds,
): WizardFlow | null => {
  const wizardFlow = configuredWizardFlowMap[id];

  if (wizardFlow) {
    return { ...wizardFlow, id };
  }
  return null;
};

export default getConfiguredWizardFlow;
