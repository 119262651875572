import type { Node_Condition_String__Input } from '~/graphql/types';
import type { StringFilter } from './types.flow';

import Validation from '~/util/Validation';
import cleanedFilename from '~/util/cleanedFilename';

const asStringFilterInput = (
  filter: StringFilter,
): Node_Condition_String__Input => {
  if ('eq' in filter && Validation.String.isNonEmptyString(filter.eq)) {
    return {
      negate: filter.negate,
      eq: filter.eq,
    };
  }

  if ('sw' in filter && Validation.String.isNonEmptyString(filter.sw)) {
    return {
      negate: filter.negate,
      sw: filter.sw,
    };
  }

  throw Error(
    `${cleanedFilename(
      __filename,
    )} | Should not occur | eq and sw both non strings (${JSON.stringify(
      filter,
      null,
      2,
    )})`,
  );
};

export default asStringFilterInput;
