/* React flow classNames can be found here
 *  https://reactflow.dev/docs/theming/
 */

export const REACT_FLOW_NODES_LAYER = '.react-flow__nodes';
export const REACT_FLOW_MINI_MAP = '.react-flow__minimap';
export const REACT_FLOW_PANE = '.react-flow__pane';
export const REACT_FLOW_EDGE_PATH = '.react-flow__edge-path';
export const REACT_FLOW = '.react-flow';
export const REACT_FLOW_RENDERER = '.react-flow__renderer';
export const REACT_FLOW_ZOOM_PANE = '.react-flow__zoompane';
export const REACT_FLOW_SELECTION_PANE = '.react-flow__selectionpane';
export const REACT_FLOW_SELECTION = '.react-flow__selection';
export const REACT_FLOW_EDGES = '.react-flow__edges';
export const REACT_FLOW_EDGE = '.react-flow__edge';
export const REACT_FLOW_EDGE_TEXT = '.react-flow__edge-text';
export const REACT_FLOW_EDGE_TEXT_BACKGROUND = '.react-flow__edge-textbg';
export const REACT_FLOW_CONNECTION = '.react-flow__connection';
export const REACT_FLOW_CONNECTION_PATH = '.react-flow__connection-path';
export const REACT_FLOW_NODE = '.react-flow__node';
export const REACT_FLOW_NODE_SELECTION = '.react-flow__nodesselection';
export const REACT_FLOW_NODE_SELECTION_RECT =
  '.react-flow__nodesselection-rect';
export const REACT_FLOW_HANDLE = '.react-flow__handle';
export const REACT_FLOW_HANDLE_BOTTOM = '.react-flow__handle-bottom';
export const REACT_FLOW_HANDLE_TOP = '.react-flow__handle-top';
export const REACT_FLOW_HANDLE_LEFT = '.react-flow__handle-left';
export const REACT_FLOW_HANDLE_RIGHT = '.react-flow__handle-right';
export const REACT_FLOW_HANDLE_CONNECTING = '.react-flow__handle-connecting';
export const REACT_FLOW_HANDLE_VALID = '.react-flow__handle-valid';
export const REACT_FLOW_BACKGROUND = '.react-flow__background';
export const REACT_FLOW_CONTROLS = '.react-flow__controls';
