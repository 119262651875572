import React from 'react';
import styled, { css } from 'styled-components';

import { SizeMap } from '~/theme/System/tokens/sizes';
import { Code } from '~/components/atom/Typography';

export type Props = {
  children: JSX.Element | string;
  /** Custom padding */
  padding?: keyof SizeMap;
};

const CodeBlock: React.FCC<Props> = ({ dataTestId, children, ...rest }) => (
  <Container data-testid={dataTestId} {...rest}>
    <StyledCode>{children}</StyledCode>
  </Container>
);

const Container = styled.pre<{ padding?: Props['padding'] }>(
  ({ theme, padding }) => css`
    background-color: ${theme.color('tertiary')};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space(padding || 's')};
    margin: 5px 0;
    display: inline-block;
    white-space: pre-wrap;
    width: 100%;
    overflow-x: scroll;
  `,
);

const StyledCode = styled(Code)<{}>(
  ({ theme }) => css`
    margin: 0;
    font-family: ${theme.getTokens().type.fontFamily.monospace};
    font-size: ${theme.fs('base')};
    background-color: transparent;
    width: 100%;
  `,
);

export default CodeBlock;
