import { Helmet as MetaTags } from 'react-helmet';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  FlowData___ActionFragment,
  FlowV2_ActionFragment,
  useGetFlowDataQuery,
} from '~/graphql/types';
import flowInstance from '../../state/flowInstance';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import BuilderContext, { InitialFlow } from '../Builder/context';
import Builder from '../Builder';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { flowNameSelector } from '../../state/flowSettings';
import { actionsSelector } from '../../state';
import flowChanges from '../../state/flowChanges';
import { equals, isNil } from 'ramda';
import { ClientFlowAction } from '~/graphql/types.client';
import 'reactflow/dist/base.css';
import generateMapsCombined from '../UpdateAction/components/Selector/utils/generateMapsCombined';
import createPageTitle from '~/util/createPageTitle';

export type Props = {
  dataTestId?: string;
  initialFlow: InitialFlow;
  flowId: string;
};

const BuilderWithContext: React.FCC<Props> = React.memo(
  ({ initialFlow, flowId }) => {
    const account = useCurrentAccount();
    const [instance, setFlowInstance] = useRecoilState(flowInstance);

    const { data: flowData, loading: flowDataLoading } = useGetFlowDataQuery({
      variables: {
        accountId: account.id,
      },
    });

    const flowName = useRecoilValue(flowNameSelector);
    const actions = useRecoilValue(
      actionsSelector({
        accountId: account.id,
        flowBlueprintId: flowId,
      }),
    );
    const setFlowHasChanges = useSetRecoilState(flowChanges);

    useEffect(() => {
      if (flowData && flowData.getFlowData) {
        setFlowInstance(flowData.getFlowData.instance);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowData]);

    useEffect(() => {
      // Reflect changes when a portal is deleted/added or an action is deleted/added
      setFlowHasChanges(prev => ({
        ...prev,
        actionCount: !equals(
          generateActionChangeMap(actions),
          generateActionChangeMap(initialFlow.actions),
        ),
      }));
    }, [actions, setFlowHasChanges, initialFlow.actions]);

    if (!flowId || flowDataLoading || !flowData?.getFlowData)
      return (
        <>
          <MetaTags>
            <title>{createPageTitle('Laden...')}</title>
          </MetaTags>
          <DatHuisLoading />
        </>
      );

    const {
      subjectMap,
      conditionMap,
      directoryMap,
      primitiveInputMap,
      instanceMap,
      subjectToConditionMap,
    } = generateMapsCombined(flowData.getFlowData, instance);

    const availableActions =
      flowData.getFlowData.availableActions.filter(
        (action): action is FlowData___ActionFragment => !isNil(action),
      ) ?? [];

    return (
      <BuilderContext.Provider
        value={{
          flowBlueprintId: flowId,
          opts: {
            subjectMap,
            conditionMap,
            subjectToConditionMap,
            directoryMap,
          },
          instanceMap,
          primitiveInputMap,
          primitiveInput: flowData.getFlowData.primitiveInput,
          primitiveListInput: flowData.getFlowData.primitiveListInput,
          superSubjects: flowData.getFlowData.superSubjects,
          subjects: flowData.getFlowData.subjects,
          instances: flowData.getFlowData.instance,
          accountId: account.id,
          availableActions,
          initialFlow,
        }}
      >
        <Container>
          <MetaTags>
            <title>{createPageTitle(flowName)}</title>
          </MetaTags>
          <BuilderContainer>
            <Builder />
          </BuilderContainer>
        </Container>
      </BuilderContext.Provider>
    );
  },
);

const BuilderContainer = styled.main`
  height: 100%;
`;

const Container = styled.div`
  height: 100%;
`;

const generateActionChangeMap = (
  actions: Array<ClientFlowAction | FlowV2_ActionFragment>,
): { [id: string]: boolean } =>
  actions.reduce((prev, a) => {
    prev[a.id] = 'parentIds' in a ? a.parentIds : null;
    return prev;
  }, {});

export default BuilderWithContext;
