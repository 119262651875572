import {
  ExtractedApp_PremiumValuationReport,
  ExtractedApp_ValuationReport,
} from '~/graphql/types.client';
import { assertNever } from '~/util/assertion';
import cleanedFilename from '~/util/cleanedFilename';
import { ApolloError } from '@apollo/client';
import {
  DeleteAppPremiumValuationReportMutationVariables,
  DeleteAppValuationReportMutationVariables,
  useDeleteAppPremiumValuationReportMutation,
  useDeleteAppValuationReportMutation,
} from '~/graphql/types';
type Args = {
  reportToDelete:
    | ExtractedApp_PremiumValuationReport
    | ExtractedApp_ValuationReport
    | null;
  onError: (mutationError: ApolloError) => void;
};

const noop = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  args: {
    variables:
      | DeleteAppValuationReportMutationVariables
      | DeleteAppPremiumValuationReportMutationVariables;
  },
) => Promise.resolve();

const useDeleteApp = ({
  reportToDelete,
  onError,
}: Args): [
  (
    | typeof noop
    | typeof deleteAppValuationReport
    | typeof deleteAppPremiumValuationReport
  ),
  { loading: boolean; error: ApolloError | undefined },
] => {
  const [
    deleteAppValuationReport,
    {
      loading: deleteAppValuationReportLoading,
      error: deleteAppValuationReportError,
    },
  ] = useDeleteAppValuationReportMutation({ onError });

  const [
    deleteAppPremiumValuationReport,
    {
      loading: deleteAppPremiumValuationReportLoading,
      error: deleteAppPremiumValuationReportError,
    },
  ] = useDeleteAppPremiumValuationReportMutation({ onError });

  if (reportToDelete == null)
    return [noop, { loading: false, error: undefined }];

  switch (reportToDelete.__typename) {
    case 'App_PremiumValuationReport':
      return [
        deleteAppPremiumValuationReport,
        {
          loading: deleteAppPremiumValuationReportLoading,
          error: deleteAppPremiumValuationReportError,
        },
      ];
    case 'App_ValuationReport':
      return [
        deleteAppValuationReport,
        {
          loading: deleteAppValuationReportLoading,
          error: deleteAppValuationReportError,
        },
      ];
    default:
      return assertNever(reportToDelete, cleanedFilename(__filename));
  }
};

export default useDeleteApp;
