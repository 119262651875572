/**
 * A basic hashing function. Converts all letters to integers, ignores all non letter & non digit characters
 * Returns the sum of all the integers in the end
 *
 * @param {string} str - A string with letters, numbers, signs etc. - not case sensitive
 * keywords: hash function, hash, id to number, generateNumber, stringToNumbe, idtonumber
 */
const hash = (str: string): number =>
  str.split('').reduce((prev, el) => {
    const int = parseInt(el, 36);
    if (isNaN(int)) return prev;

    return prev + int;
  }, 0);

export default hash;
