import React from 'react';
import styled, { css } from 'styled-components';
import HelpButton from '~/components/molecule/HelpButton';
import { Label, Variant } from '~/components/atom/Typography';
import { SystemFontWeight } from '~/theme/System/tokens';

export type Props = {
  /** Renders a help link next to the label */
  help?: string;
  /** The label to render */
  label?: string | null;
  /** An error to render */
  error?: string;
  /** Text for the reset button */
  reset?: string;
  /** Callback once the reset button has been clicked */
  onReset?: () => void;
  /** Id of the label */
  id?: string;
  /** Font weight for the label */
  fontWeight?: SystemFontWeight;
};

const InputLabel: React.FCC<Props> = ({
  dataTestId,
  label,
  help,
  children,
  error,
  reset,
  onReset,
  id,
  fontWeight = 'semiBold',
  ...rest
}) => {
  const helpComponent = help ? <Help link={{ link: help }} /> : null;
  const resetComponent = reset ? (
    <Reset
      onClick={() => {
        if (onReset) onReset();
      }}
    >
      {reset}
    </Reset>
  ) : null;

  return (
    <Container id={id} data-testid={dataTestId} {...rest}>
      <StyledLabel variant={Variant.primary} fontWeight={fontWeight}>
        {label ?? ''}
        {helpComponent}
        {resetComponent}
      </StyledLabel>
      {error ? <Error>{error}</Error> : null}
      {children}
    </Container>
  );
};

const Help = styled(HelpButton)<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('xxs')};
    vertical-align: top;
  `,
);

const Error = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);

const Reset = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('info')};
    flex-grow: 1;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
    font-size: ${theme.fs('base')};
    font-weight: ${theme.fw('regular')};

    &:hover {
      color: ${theme.color('info', 'dark')};
    }
  `,
);

const StyledLabel = styled(Label)(
  () => css`
    & > span {
      display: flex;
    }
  `,
);

const Container = styled.label<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('xxxs')};
    display: block;
    width: 100%;
  `,
);

export default InputLabel;
