import { selector } from 'recoil';
import type { FormBuilder } from '~/graphql/types';
import isRecoilDefaultValue from '~/typeguards/isRecoilDefaultValue';
import { formState } from '.';

export const layoutState = selector<FormBuilder['style']['layout']>({
  key: 'formBuilder/layoutState',
  get: ({ get }) => {
    const form = get(formState);

    return (
      form?.style.layout ?? {
        __typename: 'FormBuilder_Style_PageLayout_Default',
        backgroundColor: '#fff',
      }
    );
  },

  set: ({ get, set }, layout) => {
    if (isRecoilDefaultValue(layout)) return;

    const prevForm = get(formState);
    if (!prevForm) return;

    set(formState, {
      ...prevForm,
      style: {
        ...prevForm.style,
        layout: {
          ...prevForm.style.layout,
          ...layout,
        },
      },
    });
  },
});
