import type { FlowPointerFields } from '../getFieldsByPath';
import type { Flow___Pointer, Flow___SubjectId } from '~/graphql/types';
import getInstance, { InstanceMap } from '../getInstance';
import getSubject, {
  Flow___SubjectIdentifier,
  isEqualSubject,
  SubjectMap,
} from '../getSubject';
import hydrateField from '../hydrateField';
import { DirectoryMap, hydrateDirectories } from '../getDirectory';
import { assertNever } from '~/util/assertion';

/**
 * Returns the path for a given pointer
 *
 * 1. Resolve the directories
 * 2. Add all following instance fields
 * 3. Optionally - Check if the resulting field is of the correct subject
 */
const getPathForPointer = (
  { path }: Flow___Pointer,
  {
    validTypes,
    instanceMap,
    subjectMap,
    directoryMap,
  }: {
    validTypes?: Array<Flow___SubjectId>;
    instanceMap: InstanceMap;
    directoryMap: DirectoryMap;
    subjectMap: SubjectMap;
  },
):
  | {
      result: Array<FlowPointerFields>;
      fullResult: Array<FlowPointerFields>;
      resultingSubjectId: Flow___SubjectIdentifier;
      error?: undefined;
    }
  | { error: string } => {
  const result: Array<FlowPointerFields> = [];

  const [flowActionId, variableName, ...instanceFieldKeys] = path;
  const instance = getInstance({ flowActionId, variableName }, instanceMap);

  if (!instance) {
    return {
      error: `Could not find instance for ${flowActionId} ${variableName}`,
    };
  }

  /** 1. We found an instance, add the directories */
  const hydratedDirs = hydrateDirectories(instance.dir, { directoryMap });
  if (!hydratedDirs) return { error: 'Missing dir!' };

  result.push(...hydratedDirs);

  let subjectId = instance.instanceType;

  /** 2. Now move forward to them field things */
  for (
    let fieldKeyIndex = 0;
    fieldKeyIndex < instanceFieldKeys.length;
    ++fieldKeyIndex
  ) {
    const isFirst = fieldKeyIndex === 0;
    const fieldKey = instanceFieldKeys[fieldKeyIndex];

    const subject = getSubject(subjectId, subjectMap);
    if (!subject) {
      return {
        error: `Could not find subject ${subjectId.type} ${subjectId.typeId}`,
      };
    }

    const field = subject.instanceFields.find(field => field.key === fieldKey);
    if (!field) {
      return {
        error: `Could not find instanceField ${fieldKey} in subject ${subjectId.type} ${subjectId.typeId}`,
      };
    }

    subjectId = field.type;

    if (!isFirst) {
      result.push(hydrateField(field, subject));
      continue;
    }

    result.push(hydrateField(field, subject, instance));
  }

  const last = result.pop();
  if (!last) return { error: `Missing instance field` };

  const resultingSubject = (():
    | Flow___SubjectIdentifier
    | { error: string } => {
    switch (last.__typename) {
      case 'FlowData___Directory': {
        if (!last.instance) {
          return { error: 'Pointer must point towards an instance field' };
        }

        const { type, typeId } = last.instance.subject;
        return { type, typeId };
      }
      case 'FlowData___InstanceField': {
        const { type, typeId } = last.type;
        return { type, typeId };
      }

      default:
        return assertNever(last);
    }
  })();

  if ('error' in resultingSubject) return { error: resultingSubject.error };

  /** 3. Validate if the last field is of the expected subject */
  if (
    validTypes &&
    !validTypes.some(type => isEqualSubject(resultingSubject, type))
  ) {
    return {
      error: `Pointer points to an invalid subject ${resultingSubject.type}:${
        resultingSubject.typeId
      } vs valid [${validTypes
        .map(({ type, typeId }) => `${type}:${typeId}`)
        .join(', ')}]`,
    };
  }

  return {
    result,
    fullResult: [...result, last],
    resultingSubjectId: resultingSubject,
  };
};
export default getPathForPointer;
