import { DeleteEntityErrorReason } from '~/graphql/types';
import { ApolloError } from '@apollo/client';

import cleanedFilename from '~/util/cleanedFilename';
import Catalog from '~/Catalog';
import ErrorTypes, { errorTypeFromMutationError } from '~/ErrorTypes';

const text = {
  entitiesAreLockedError:
    'Kan niet verwijderen omdat er al een verwijderingsverzoek is ingediend. Probeer het later nog eens',
  receivingEntityBeingDeletedError:
    'Kan niet verwijderen omdat er al een verwijderingsverzoek is ingediend. Probeer het later nog eens.',
  officeIsLastForUserError:
    'Verwijderen uit vestiging niet mogelijk. Een gebruiker dient aan tenminste één vestiging gekoppeld te zijn.',
  isLastAccountAdminError:
    'Gebruiker kan niet verwijderd worden omdat hij of zij de laatste accountbeheerder is. Voeg een andere accountbeheerder toe aan het account om deze gebruiker te verwijderen.',
};

export const convertBackendErrorMessage = (
  lockedByUserName: string,
  deletingEntityName: string,
  reason: DeleteEntityErrorReason,
): string => {
  let errorText;

  switch (reason) {
    case DeleteEntityErrorReason.BeingDeleted:
      errorText = `Kan ${deletingEntityName} niet verwijderen omdat er al een verwijderingsverzoek is ingediend door ${lockedByUserName}. Probeer het later nog eens.`;
      break;
    case DeleteEntityErrorReason.ReceivingData:
      errorText = `Kan ${deletingEntityName} niet verwijderen omdat er op het moment data naar wordt gemigreerd door ${lockedByUserName}. Probeer het later nog eens.`;
      break;
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Error reason ${reason} does not exist`,
      );
  }

  return errorText;
};

export const getErrorMessageForModal = (mutationError: ApolloError): string => {
  const errorTypes = errorTypeFromMutationError(mutationError);
  let errorMessage = Catalog.genericUnknownErrorMessage;

  if (errorTypes == null) {
    return errorMessage;
  }

  if (errorTypes.includes(ErrorTypes.entitiesAreLockedError)) {
    errorMessage = text.entitiesAreLockedError;
  }

  if (errorTypes.includes(ErrorTypes.receivingEntityBeingDeletedError)) {
    errorMessage = text.receivingEntityBeingDeletedError;
  }

  if (errorTypes.includes(ErrorTypes.officeIsLastForUserError)) {
    errorMessage = text.officeIsLastForUserError;
  }

  if (errorTypes.includes(ErrorTypes.isLastAccountAdminError)) {
    errorMessage = text.isLastAccountAdminError;
  }

  return errorMessage;
};
