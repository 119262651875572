import { unnest } from 'ramda';
import { useEffect, useState } from 'react';
import errorMessagesForAccountIssues from '~/contexts/util/errorMessagesForAccountIssues';
import errorMessagesForMailboxes from '~/contexts/util/errorMessagesForMailboxes';
import errorMessagesForSubscription from '~/contexts/util/errorMessagesForSubscription';
import { NotificationListItem, useGetPlansQuery } from '~/graphql/types';
import errorMessagesForWarningItems from './utils/errorMessagesForWarningItems';
import useSessionHydration from '../useSessionHydration';
import useOfficeLookup from '../useOfficeLookup';
import getSignupId from '~/components/page/Settings/Subscription/components/ChangeOrUpdateSubscription/utils/getSignupId';

export enum WARNING_TYPE {
  ERROR = 'Error',
  INFO = 'Info',
  WARNING = 'Warning',
}

export type Warning = {
  item: NotificationListItem;
  hash: string;
};

export type Warnings = Array<Warning>;

const useEntityWarnings = () => {
  const [{ account, mailboxes, warningList }] = useSessionHydration();
  const { data } = useGetPlansQuery({
    variables: {
      accountId: account.id,
      signupId: getSignupId(),
    },
  });
  const officeLookup = useOfficeLookup();

  const [warnings, setWarnings] = useState<Warnings>([]);

  useEffect(() => {
    const mailboxWarnings = errorMessagesForMailboxes(
      mailboxes ?? [],
      officeLookup,
    );
    const subscriptionWarnings = data?.getPlans
      ? errorMessagesForSubscription(data.getPlans)
      : [];
    const accountWarnings =
      account && account.issues
        ? errorMessagesForAccountIssues(account.issues)
        : [];
    const warningListWarnings = errorMessagesForWarningItems(warningList ?? []);

    // Make sure all warnings have a hash before this
    const currentWarnings = unnest([
      mailboxWarnings,
      subscriptionWarnings,
      accountWarnings,
      warningListWarnings,
    ]);

    setWarnings(currentWarnings);
    // Adding officeLookup in the dependency array causes an infinite loop so don't.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, mailboxes, warningList, data?.getPlans]);

  return warnings;
};

export default useEntityWarnings;
