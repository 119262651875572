import {
  PermissionFragment,
  PermissionId,
  Reason_Strapi,
} from '~/graphql/types';
import usePermissionMap, { PermissionMap } from '../usePermissionMap';
import { isNil, sort } from 'ramda';

export type Allowed = { allowed: true; reason: null };
export type Denied = {
  allowed: false;
  reason: Reason_Strapi;
};

const DEFAULT: Allowed = { allowed: true, reason: null };
/**
 * Returns the first permission denied entry in the ID chain
 * unless allowed then no reason will be given
 *
 * @param ids Array<PermissionId> - All permissions you'd want to match
 * @returns { allowed: boolean, reason: unknown }
 */
const usePermissions = (ids: Array<PermissionId>): Allowed | Denied => {
  const permissionMap = usePermissionMap();
  if (ids.length === 0) return DEFAULT;

  const permissions = ids.map(id => lookUpPermissionRec(id, permissionMap));
  const mostExactMatch = sort(
    (a, b) => b.id.split('.').length - a.id.split('.').length,
    permissions.filter(
      ({ __typename }) => __typename !== 'Permission_AccessGranted',
    ),
  )[0];

  if (
    mostExactMatch &&
    mostExactMatch.__typename === 'Permission_AccessDenied_Plan'
  ) {
    return {
      allowed: false,
      reason: mostExactMatch.reason,
    };
  }
  // We couldn't find any AccessDenied so we return true
  return DEFAULT;
};

const lookUpPermissionRec = (
  id: string,
  permissionMap: PermissionMap,
): PermissionFragment => {
  const permission = permissionMap[id];
  if (isNil(permission)) {
    const splitId = id.split('.');
    splitId.pop();
    const oneLvlUp = splitId.join('.');

    return lookUpPermissionRec(oneLvlUp, permissionMap);
  }

  return permission;
};

export default usePermissions;
