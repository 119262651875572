import React from 'react';
import ImageUploadComponent from '~/components/organism/ImageUploadComponent';
import {
  FormBuilder_Ui_Alignment,
  type FormBuilder_ScreenNode_Block,
  type FormData_Ui_Image,
} from '~/graphql/types';
import useImageUpload from '~/hooks/useImageUpload';
import BlockLayout from '../BlockLayout';
import LocalesInput from '../LocalesInput';
import Input from '~/components/molecule/Input';
import BlockAlignment from '../BlockAlignment';

export type Props = {
  onChange: (block: FormBuilder_ScreenNode_Block) => void;
  block: FormData_Ui_Image;
  nodeId: string;
};

const Image: React.FCC<Props> = ({ onChange, nodeId, block }) => {
  const logoImage = useImageUpload({
    initialUrl: block.image.url,
    s3Key: block.image.s3key,
    onUpload: ({ s3Key, url }) => {
      if (s3Key && url) {
        onChange({
          ...block,
          image: {
            s3key: s3Key,
            url,
            __typename: 'DHImage',
          },
        });
      }
    },
  });

  return (
    <BlockLayout blockKey={block.key} nodeId={nodeId}>
      <ImageUploadComponent
        uploading={logoImage.uploading}
        imageUrl={logoImage.s3Key != null ? logoImage.url : null}
        onUpload={file => {
          void logoImage.upload({
            file: file[0],
            filename: 'default_theme_logo_image',
          });
        }}
        direction="column"
      />
      <LocalesInput
        label={{ NL: 'Terugvaltekst', EN: 'Terugvaltekst' }}
        block={block}
        onChange={onChange}
        subject="alt"
        blockKey={block.key}
        nodeId={nodeId}
      />
      <BlockAlignment
        alignment={block.alignment ?? FormBuilder_Ui_Alignment.Left}
        onChange={alignment => {
          onChange({
            ...block,
            alignment,
          });
        }}
      />
      <Input
        label={{ text: 'Breedte (px)' }}
        width="100%"
        onChange={event => {
          const parsed = parseInt(event.target.value);
          onChange({
            ...block,
            width: isNaN(parsed) ? 0 : parsed,
          });
        }}
        value={block.width ?? 0}
      />
    </BlockLayout>
  );
};

export default Image;
