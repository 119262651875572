import type {
  FormBuilder_ScreenNodeFragment,
  FormData_Ui_Image,
  FormData_Ui_RichText,
  FormData_Ui_Typography,
} from '~/graphql/types';

export type UIBlockType =
  | FormData_Ui_Image
  | FormData_Ui_RichText
  | FormData_Ui_Typography;

const isUITypeBlock = (
  block: FormBuilder_ScreenNodeFragment['blocks'][number]['__typename'],
): boolean => {
  switch (block) {
    case 'FormData_UI_Image':
    case 'FormData_UI_RichText':
    case 'FormData_UI_Typography':
      return true;

    default: {
      return false;
    }
  }
};

export default isUITypeBlock;
