import type { ArgumentId, ConditionOpts } from '../../../..';
import type { ConditionLabelType } from '../../../../utils/getLabelForRep';

import React, { MouseEvent, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import useMountedRef from '~/hooks/useMountedRef';
import isActiveCondition from '../../../../utils/isActiveConditionGroup';
import isActiveConditionArgument from '../../../../utils/isActiveConditionArgument';
import TEST_ID_CONDITION_EDITOR from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/index.testid';
import RepresentationLabel from '~/components/page/Automation/v2/components/Builder/components/RepresentationLabel';
import reactNodeToString from '~/util/reactNodeToString';

export type Props = {
  dataTestId?: string;
  /** Argument Id for this element */
  argumentId: ArgumentId;
  /** Label to display */
  label: string | React.ReactNode;
  /** Condition opts */
  opts: ConditionOpts;
  /** The type of element */
  type: ConditionLabelType | { type: 'empty'; hasError?: boolean };
};

const ConditionButtonV2: React.FC<Props> = ({
  argumentId,
  label,
  type,
  opts: { activeSelector, setActiveSelector },
}) => {
  const [ref, setRef] = useMountedRef<HTMLButtonElement>();
  const activeCondition = isActiveCondition({
    activeSelector,
    argumentId: argumentId,
  });
  const activeConditionArgument = isActiveConditionArgument({
    activeSelector,
    argumentId: argumentId,
  });

  useEffect(() => {
    if (!ref) return;
    if (!activeSelector) return;

    const { inputRef } = activeSelector;

    if (inputRef === ref) return;
    if (!activeCondition) return;
    if (argumentId.argumentIdx != null && !activeConditionArgument) return;

    setActiveSelector({ ...argumentId, inputRef: ref });
  }, [
    ref,
    activeSelector,
    setActiveSelector,
    argumentId,
    activeCondition,
    activeConditionArgument,
  ]);

  const elementArgs = {
    'data-testid': TEST_ID_CONDITION_EDITOR.COLLAPSED_ROW,
    $active: activeConditionArgument,
    $hasError: type.hasError,
    ref: setRef,
    onClick: (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setActiveSelector({ ...argumentId, inputRef: ref });
    },
  };

  switch (type.type) {
    case 'empty':
      elementArgs.$hasError = !activeConditionArgument;

      return <EmptyElement {...elementArgs}>{label}</EmptyElement>;
    case 'input':
      return (
        <InputElement {...elementArgs} title={reactNodeToString(label)}>
          <RepresentationLabel label={label} />
        </InputElement>
      );
    case 'argument':
      elementArgs.$hasError =
        elementArgs.$hasError || (!activeConditionArgument && label === '""');

      return (
        <ArgumentElement {...elementArgs} title={reactNodeToString(label)}>
          <RepresentationLabel label={label} />
        </ArgumentElement>
      );
    case 'text':
      return <TextElement>{label}</TextElement>;
  }
};

const TextElement = styled.span(() => css``);

const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  2% {
    transform: scale(1.05);
  }
  4% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

const ElementBase = styled.button<{ $active: boolean; $hasError?: boolean }>(
  ({ theme, $active, $hasError }) => css`
    all: unset;
    white-space: nowrap;
    cursor: pointer;
    font-weight: ${theme.fw('regular')};
    padding: 6px ${theme.space('xs')};
    display: block;
    position: relative;
    transform: scale(1);
    -webkit-font-smoothing: subpixel-antialiased;

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;
      opacity: 0.7;
      transition:
        opacity 300ms,
        transform 300ms,
        height 300ms;
    }

    ${$active &&
    css`
      &::after {
        opacity: 1;
        height: 0.2em;
        transform: translate3d(0, 0.3em, 0);
      }
    `};

    &:hover::after,
    &:focus::after {
      opacity: 1;
      height: 0.2em;
      transform: translate3d(0, 0.3em, 0);
    }

    ${$hasError &&
    css`
      color: ${theme.color('danger')};
      animation: ${bounce} 10s linear infinite;
      animation-direction: alternate-reverse;
      font-style: italic;
      background-color: ${theme.color('danger', 'translucent')};
      border-radius: ${theme.getTokens().border.radius.base};

      /** Also set the colours for the children such as label */
      & > * {
        color: ${theme.color('danger')};
        background-color: ${theme.color('danger', 'translucent')};
      }
    `};
  `,
);

const EmptyElement = styled(ElementBase)(
  ({ theme }) => css`
    &::after {
      background-color: ${theme.color('danger')};
    }
  `,
);

const InputElement = styled(ElementBase)(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight('medium')};

    &::after {
      opacity: 0.3;
    }
  `,
);

const ArgumentElement = styled(ElementBase)(
  ({ theme, $hasError }) => css`
    &::after {
      background-color: ${$hasError
        ? theme.color('danger')
        : theme.color('primary', 'light')};
    }
  `,
);

export default ConditionButtonV2;
