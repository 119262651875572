import React from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import Div from '~/components/atom/Div';
import { pointerSelector } from '~/components/page/Forms/components/Builder/state/nodesAndEvents';

type Props = {
  pointer: [string, string];
};
const PointerRenderer: React.FCC<Props> = ({ pointer }) => {
  const pointerValue = useRecoilValue(pointerSelector(pointer));

  return <Container>{pointerValue}</Container>;
};

const Container = styled(Div)(
  ({ theme }) => css`
    border: 1px solid ${theme.color('secondary', 'light')};
    color: ${theme.color('secondary', 'light')};
    padding: ${theme.space('xxs')};
    border-radius: ${theme.getTokens().border.radius.s};
    margin-bottom: ${theme.space('xs')};
  `,
);

export default PointerRenderer;
