import React, { useEffect } from 'react';
import { isNil } from 'ramda';
import sanitize from 'sanitize-html';

import PluginsEditor, {
  Props as EditorProps,
} from '~/components/organism/PluginsEditor/';
import parseAndDeserialize from '~/components/organism/PluginsEditor//utils/parseAndDeserialize';
import serialize from '~/components/organism/PluginsEditor//utils/serialize';
import useEditorStates from '~/components/organism/PluginsEditor//hooks/useEditorStates';
import ELEMENTS from '~/components/organism/PluginsEditor//components/elements/elementsEnum';
import { defaultSanitizeOptions } from '~/components/organism/PluginsEditor//utils/flows/convertTemplateStringToSlate';
import replaceTags from './utils/replaceTags';
import { EMPTY_EDITOR_VALUE } from '~/components/organism/PluginsEditor/constants';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';
import TextEditorContainer from '~/components/atom/TextEditorContainer';

export type Props = Omit<EditorProps, 'value' | 'onChange'> & {
  /** Editor value as html string */
  value: string;

  /** Error state  */
  error?: boolean;

  /** Disabled state  */
  disabled?: boolean;

  /** Sets the editor's value to the empty editor value, e.g. after submitting the form */
  shouldReset?: boolean;

  /** onChange function of form field */
  onChange: (change: string) => void;
};

const Editor: React.FCC<Props> = ({
  dataTestId,
  error = false,
  disabled,
  value: initialValue,
  onChange: _onChange,
  shouldReset,
  ...rest
}) => {
  /**
   * Moving from Quill to Slate:
   *
   * In Slate the base block should be a div element. This is important especially
   * when creating empty lines.
   *
   * Here we convert p elements into div elements in order to keep the spaces as they are.
   */
  const modifiedHtml = replaceTags(initialValue);

  const sanitized = sanitize(modifiedHtml, defaultSanitizeOptions);

  const textFragment = parseAndDeserialize({
    html: sanitized ?? '',
    customElements: [],
  });

  const initial = isNil(initialValue)
    ? EMPTY_EDITOR_VALUE
    : ([{ type: ELEMENTS.DIV, children: textFragment }] as EditorValue);

  const { key, value, onChange, hasChanges, resetEditor } = useEditorStates({
    initialValue: initial,
  });

  useEffect(() => {
    if (hasChanges) {
      const serialized = serialize({ fragment: value, customElements: [] });
      _onChange(serialized);
    }
  }, [_onChange, hasChanges, value]);

  useEffect(() => {
    if (hasChanges && shouldReset) {
      resetEditor({ value: EMPTY_EDITOR_VALUE });
    }
  }, [resetEditor, hasChanges, shouldReset]);

  return (
    <TextEditorContainer
      dataTestId={dataTestId}
      $disabled={!!disabled}
      $appearance={error ? 'danger' : undefined}
      $width="100%"
    >
      <PluginsEditor
        {...rest}
        key={key}
        value={value}
        onChange={onChange}
        hideToolbar
        minHeight={100}
        editableProps={{ style: { padding: '0px' } }}
      />
    </TextEditorContainer>
  );
};

export default Editor;
