import React from 'react';
import styled, { css } from 'styled-components';
import useCurrentBreakpoint, {
  BreakpointKeys,
} from '~/hooks/useCurrentBreakpoint';
import { SystemSize } from '~/theme/System/tokens/sizes';

import sortEveryNth from './utils/sortEveryNth';

export type Props = {
  gap?: SystemSize;
  children: React.ReactNode;
};

const MasonryGrid: React.FCC<Props> = ({
  dataTestId,
  children,
  gap = 'xxl',
  ...rest
}) => {
  const blocks = React.Children.toArray(children);
  const currentBreakpoint = useCurrentBreakpoint();
  const blockSize = getBlockSize(currentBreakpoint);

  const columnCount = Math.min(blocks.length, blockSize);

  return (
    <Container
      data-testid={dataTestId}
      $columnCount={columnCount}
      $gap={gap}
      {...rest}
    >
      {sortEveryNth(blocks, blockSize).map((block, index) => (
        <React.Fragment key={index}>{block}</React.Fragment>
      ))}
    </Container>
  );
};

const Container = styled.div<{
  $columnCount: number;
  $gap?: SystemSize;
}>(
  ({ theme, $columnCount, $gap = 'xxl' }) => css`
    column-gap: ${theme.space($gap)};
    column-count: ${$columnCount};
    width: 100%;
  `,
);

const getBlockSize = (currentBp: BreakpointKeys) => {
  switch (currentBp) {
    case 'desktopBig':
    case 'desktop':
    case 'tabletLandscape':
    case 'tablet':
      return 2;
    case 'mobile':
    default:
      return 1;
  }
};

export default MasonryGrid;
