import useSessionHydration from '../useSessionHydration';
import type { AccountStyle } from '~/graphql/types';

const useBrandStyle = (): AccountStyle | null => {
  const [data] = useSessionHydration();

  return data.account.AccountSettings?.style || null;
};

export default useBrandStyle;
