import { ArgumentId } from '../..';
import isActiveConditionSubExpression from '../isActiveConditionSubExpression';

const isActiveConditionGroup = ({
  activeSelector,
  argumentId,
}: {
  activeSelector?: ArgumentId | null;
  argumentId: ArgumentId;
}): boolean =>
  activeSelector != null &&
  isActiveConditionSubExpression({
    activeSelector,
    argumentId,
  }) &&
  activeSelector.conditionGroupIdx === argumentId.conditionGroupIdx;

export default isActiveConditionGroup;
