import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import { Body, Variant, Heading4 } from '~/components/atom/Typography';
import Link from '~/components/molecule/Link';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Field } from 'react-final-form';
import { widgetHelpLink } from '../../constants';
import type { onChangeArgs } from '../WidgetContent';
import Input from '~/components/molecule/Input';
import WidgetScript from '~/components/molecule/WidgetScript';

export type Props = {
  onChange: (args: onChangeArgs<'settings'>) => void;
  googleAnalytics4?: string | null;
} & RouteComponentProps;

const text = {
  title: 'Algemeen',
  body: (
    <>
      De widget wordt op jouw website geplaatst en toont informatieproducten en
      diensten. Activeer Apps via het menu bovenaan, om deze toe te voegen aan
      de widget.
      <br />
      <br />
      Publiceer de widget, door onderstaande code toe te voegen aan je website.
      <br />
      <br />
      <Link to={widgetHelpLink} target="_blank">
        Bekijk hier de installatie en integratie instructies.
      </Link>
    </>
  ),

  ga4Label: 'Google Analytics 4',
  ga4Description: 'Geef uw GA 4-ID op.',
  gaHelpLinkText: 'Zie dit Helpartikel voor meer informatie',
};

const Settings: React.FCC<Props> = ({
  dataTestId,
  onChange,
  googleAnalytics4,
  ...rest
}) => (
  <AppDetailsContainer
    {...rest}
    data-testid={dataTestId}
    header={text.title}
    icon="gear"
    loading={false}
  >
    <Body>{text.body}</Body>

    <WidgetScript />

    <JustificationContainer
      direction="column"
      width="100%"
      margin={['base', null]}
    >
      <Heading4 variant={Variant.primary}>{text.ga4Label}</Heading4>
      <Body>
        {text.ga4Description}{' '}
        <Link
          to="https://help.dathuis.nl/nl/articles/7854772-tracking-met-google-analytics-4"
          target="_blank"
        >
          {text.gaHelpLinkText}
        </Link>
      </Body>
      <Field name="settings.googleAnalytics4">
        {({ meta: { submitting } }) => (
          <Input
            value={googleAnalytics4}
            width="100%"
            size="large"
            placeholder="G-..."
            dataTestId={TEST_ID.GOOGLE_ANALYTICS}
            disabled={submitting}
            onChange={event => {
              onChange({
                pageKey: 'settings',
                fieldKey: 'googleAnalytics4',
                newValue: event.target.value,
              });
            }}
          />
        )}
      </Field>
    </JustificationContainer>
  </AppDetailsContainer>
);

export default Settings;
