/**
 *
 * Provides the validation methods for password validation.
 */

import { isString } from 'lodash';

const MINIMUM_PASSWORD_LENGTH = 12;

const COGNITO_SPECIAL_CHARACTERS =
  /\^|\$|\*|\.|\[|\]|{|}|\(|\)|\?|-|"|!|@|#|%|&|\/|\\|,|>|<|'|:|;|\||_|~|`/;

const containsUpperCaseLetter = (password: string) => {
  if (password == null) return false;

  return password.toLowerCase() !== password;
};

const containsLowerCaseLetter = (password: string) => {
  if (password == null) return false;

  return password.toUpperCase() !== password;
};

const containsSpecialCharacter = (password: string) => {
  if (password == null) return false;

  return COGNITO_SPECIAL_CHARACTERS.test(password);
};

const containsNumber = (password: string) => {
  if (password == null) return false;

  return /\d/.test(password);
};

const satisfiesLength = (password: string): boolean =>
  password.length >= MINIMUM_PASSWORD_LENGTH;

const isValid = (password: string) => {
  if (password == null) return false;

  if (!isString(password)) return false;
  if (!satisfiesLength(password)) return false;
  if (!containsNumber(password)) return false;
  if (!containsSpecialCharacter(password)) return false;
  if (!containsLowerCaseLetter(password)) return false;
  if (!containsUpperCaseLetter(password)) return false;

  return true;
};

export default {
  MINIMUM_PASSWORD_LENGTH,
  satisfiesLength,
  containsLowerCaseLetter,
  containsUpperCaseLetter,
  containsSpecialCharacter,
  containsNumber,
  isValid,
};
