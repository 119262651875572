import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { v4 as uuid } from 'uuid';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { VariableElement } from '~/components/organism/PluginsEditor/types';

const insertVariable = editor => {
  const dhVariable: VariableElement = {
    type: ELEMENTS.VARIABLE,
    variableName: '...',
    variableType: undefined,
    /** Add as pending because we do not know tha name of the variable yet */
    pending: true,
    mappingId: uuid().replace(/-/g, ''),
    children: [{ text: '' }],
  };

  Transforms.insertNodes(editor, dhVariable);
  ReactEditor.focus(editor);
};

export default insertVariable;
