import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import {
  useGetAppVboWaardecheckQuery,
  useUpdateAppVboWaardecheckMutation,
  useGetAppVboWaardecheckRouteAvailabilityLazyQuery,
} from '~/graphql/types';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import { useRecoilState, useSetRecoilState } from 'recoil';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ErrorScreen from '~/components/page/ErrorScreen';
import cleanedFilename from '~/util/cleanedFilename';
import WaardecheckTemplate, {
  StateId,
  WaardecheckApp,
  getImageStash,
} from '../components/WaardecheckTemplate';
import transformToInput from '../components/WaardecheckTemplate/utils/transformToInput';
import useErrorReporter from '~/hooks/useErrorReporter';
import useApp from '~/hooks/useApp';
import {
  appWaardecheckImageStash,
  appWaardecheckState,
} from '../components/WaardecheckTemplate/state';
import NotFound from '../../404';

const text = {
  appTitle: 'Waardecheck - Powered by Matrixian',
  updateError: 'Opslaan niet gelukt door foutieve waarden in het formulier',
};

type Props = RouteComponentProps & {};

const VBOWaardecheck: React.FCC<Props> = ({ uri = '' }) => {
  const reporter = useErrorReporter();
  const { id: accountId } = useCurrentAccount();
  const {
    app,
    loading: appStatusLoading,
    error: appStatusError,
  } = useApp('AppStatus_VBOWaardecheck');

  const [, setOriginal] = useRecoilState(appWaardecheckState(StateId.original));

  const [updated, setUpdated] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const addToast = useAddToast();

  const setImageStash = useSetRecoilState(appWaardecheckImageStash);

  const { data, error, loading, updateQuery } = useGetAppVboWaardecheckQuery({
    variables: { accountId },
    onCompleted: ({ getAppVBOWaardecheck }) => {
      if (!getAppVBOWaardecheck) return;

      const inputFormatted = transformToInput(getAppVBOWaardecheck);

      setUpdated(inputFormatted);
      setOriginal(inputFormatted);
      setImageStash(getImageStash(getAppVBOWaardecheck));
    },
  });

  const [updateAppVboWaardecheck, { loading: updating }] =
    useUpdateAppVboWaardecheckMutation();

  const [
    getRouteAvailability,
    {
      loading: getRouteAvailabilityLoading,
      data: getRouteAvailabilityData,
      variables: getRouteAvailabilityDataVariables,
    },
  ] = useGetAppVboWaardecheckRouteAvailabilityLazyQuery();

  if (appStatusLoading) return <DatHuisLoading />;
  if (!app?.appStatus.enabled) return <NotFound />;

  if (error || appStatusError) {
    reporter.captureException(
      new Error(
        `${cleanedFilename(__filename)} | An error occured (${JSON.stringify(
          error || appStatusError,
        )})`,
      ),
    );

    return <ErrorScreen />;
  }

  if (!data || loading || !updated) return <DatHuisLoading />;

  return (
    <WaardecheckTemplate
      uri={uri}
      appTitle={text.appTitle}
      appType={WaardecheckApp.VBO}
      queryData={data.getAppVBOWaardecheck}
      queryLoading={loading}
      mutationLoading={updating}
      onSave={async () => {
        const { errors, data } = await updateAppVboWaardecheck({
          variables: {
            accountId,
            update: updated,
          },
        });

        if (errors || !data) {
          reporter.captureException(
            new Error(JSON.stringify(errors || 'No Data')),
            'fatal',
          );
          addToast([formatToastMessage(text.updateError, 'danger')]);
          return;
        }

        const { updateAppVBOWaardecheck } = data;

        updateQuery(data => ({
          ...data,
          getAppVBOWaardecheck: updateAppVBOWaardecheck,
        }));

        return updateAppVBOWaardecheck;
      }}
      routeAvailability={{
        loading: getRouteAvailabilityLoading,
        data: getRouteAvailabilityData,
        variables: getRouteAvailabilityDataVariables,
      }}
      getRouteAvailability={async route =>
        getRouteAvailability({
          variables: {
            accountId,
            route,
          },
        })
      }
    />
  );
};

export default VBOWaardecheck;
