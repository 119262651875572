import React from 'react';
import InputLabel, { Props as LabelProps } from '~/components/atom/InputLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';
import ColorPicker from '~/components/molecule/ColorPicker';

export type Props = LabelProps & {
  value?: string;
  onChange?: (color: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
};

const ColorInput: React.FCC<Props> = React.memo(
  ({
    dataTestId,
    error,
    label,
    size = 'medium',
    value,
    onChange,
    disabled,
    readOnly,
    ...rest
  }) => (
    <JustificationContainer direction="column" width="100%">
      <InputLabel error={error} label={label} {...rest} size={size} />
      <ColorPicker
        initialColor={value || '#fff'}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        dataTestId={dataTestId}
      />
    </JustificationContainer>
  ),
);

export default ColorInput;
