import type {
  Node_NodeContainer_Leaf_ZapierMetadata__Input,
  Node_NodeContainer__Input,
} from '~/graphql/types';
import type { Filter } from './types.flow';

import asContactLeafInput from './Contact/asContactLeafInput';
import cleanedFilename from '~/util/cleanedFilename';
import { FILTER_TYPE } from './Contact/constants';
import asActivityLeafInput from './Contact/asActivityLeafInput';
import asZapierLeafInput from './Contact/asZapierLeafInput';

const asLeafInput = (filter: Filter): Node_NodeContainer__Input | null => {
  switch (filter.type) {
    case FILTER_TYPE.CONTACT: {
      const subFilter = asContactLeafInput(filter.subFilter);

      if (subFilter == null) {
        return null;
      } else {
        return {
          Leaf: {
            Contact: subFilter,
          },
        };
      }
    }

    /** If you add another activity filter type you must add this case in getFilterTypeForLeafInput in FilterSelectionModal.tsx */
    case FILTER_TYPE.FUNDA:
    case FILTER_TYPE.ACTIVITY:
    case FILTER_TYPE.BBWAARDECHECK:
    case FILTER_TYPE.VBOWAARDECHECK: {
      const subFilter = asActivityLeafInput(filter.subFilter);

      if (subFilter == null) {
        return null;
      } else {
        return {
          Leaf: {
            Activity: subFilter,
          },
        };
      }
    }

    case FILTER_TYPE.ZAPIER: {
      const subFilter = asZapierLeafInput(filter.subFilter);

      if (subFilter == null) {
        return null;
      } else {
        return {
          Leaf: {
            ZapierMetadata:
              subFilter as Node_NodeContainer_Leaf_ZapierMetadata__Input,
          },
        };
      }
    }

    default: {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | asLeafInput not supported for filter (${JSON.stringify(
          filter,
          null,
          2,
        )})`,
      );
    }
  }
};

export default asLeafInput;
