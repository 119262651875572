import type { FormBuilder_NodeFragment } from '~/graphql/types';
import getStartNode from '../getStartNode';

type NodeMap = Record<string, FormBuilder_NodeFragment>;

/**
 * Sorts the nodes on `defaultNext`
 * @param {Array<FormBuilder_NodeFragment>} nodes
 * keywords:
 */
const sortNodes = ({
  nodes,
}: {
  nodes: Array<FormBuilder_NodeFragment>;
}): Array<FormBuilder_NodeFragment> => {
  const startNode = getStartNode(nodes);

  const nodeMap = nodes.reduce((acc, node) => {
    acc[node.id] = node;
    return acc;
  }, {} as NodeMap);

  // Recursive function to traverse nodes
  const sortItems = ({
    node,
    nodeMap,
    result = [],
  }: {
    node: FormBuilder_NodeFragment | null;
    nodeMap: NodeMap;
    result?: Array<FormBuilder_NodeFragment>;
  }) => {
    if (!node) return result;
    result.push(node);

    const nextNodeId = node.defaultNext ? node.defaultNext.targetNodeId : null;

    return sortItems({
      node: nextNodeId ? nodeMap[nextNodeId] : null,
      nodeMap,
      result,
    });
  };

  return sortItems({ node: startNode, nodeMap });
};

export default sortNodes;
