import { useCallback, useEffect, useRef, useState } from 'react';
import uploadS3Image from '~/util/uploadS3Image';
import { v4 as uuidv4 } from 'uuid';
import useCurrentUser from '../useCurrentUser';
import useAddToast from '../useAddToast';
import getErrorMessage from './utils/getErrorMessage';

type UploadImage = (args: { file: File; filename?: string }) => Promise<void>;
type ClearImage = () => void;

type Args = {
  /** Default image url */
  initialUrl?: string | null;
  /** Default s3Key */
  s3Key?: string | null;
  /** Callback once a new image has been uploaded */
  onUpload?: (args: { url: string; s3Key: string }) => void;
  /** Callback once a image has been cleared */
  onClear?: () => void;
  /** Callback once an error happened */
  onError?: (error: string | Error) => void;
};

type Return = {
  /** Url of the uploaded file */
  url: string | null;
  /** S3Key of the uploaded file */
  s3Key: string | null;
  /** An error happened while uploading the file */
  error: string | Error | null;
  /** True if an upload is in process */
  uploading: boolean;
  /**
   * Upload a file to the user storage. The filename will
   * be a random uuid if not given.
   *
   * Most of the time you do not want to give a filename to prevent collisions.
   */
  upload: UploadImage;
  /**
   * Clears the url / s3Key / error. This does not actually removes the
   * file from the storage (the backend cleans all files uploaded after a certain period)
   */
  clear: ClearImage;
};

type State = {
  url: string | null;
  s3Key: string | null;
  error: Error | null;
  uploading: boolean;
};

const defer = (time: number) =>
  new Promise(resolve => {
    setTimeout(resolve, time);
  });
const useImageUpload = ({
  initialUrl,
  s3Key: defaultS3Key,
  onUpload,
  onClear,
  onError,
}: Args): Return => {
  const me = useCurrentUser();
  const [state, setState] = useState<State>({
    url: initialUrl ?? null,
    s3Key: defaultS3Key ?? null,
    error: null,
    uploading: false,
  });

  const addToast = useAddToast();

  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  useEffect(() => {
    /**
     * Allows us to reset by resetting the s3Key from the outside.
     */
    if (defaultS3Key === state.s3Key) return;

    setState(prev => ({
      ...prev,
      url: initialUrl ?? prev.url ?? null,
      s3Key: defaultS3Key ?? null,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUrl, defaultS3Key]);

  const upload: UploadImage = async ({ file, filename }) => {
    setState(prev => ({
      ...prev,
      uploading: true,
      error: null,
    }));

    let s3Key: string;
    let _url: string;

    try {
      const { path, permanentLink } = await uploadS3Image({
        file,
        uploadImageName: `${me.id}/${
          filename ? `${filename}-${uuidv4()}` : uuidv4()
        }`,
      });

      s3Key = path;
      _url = permanentLink;
    } catch (e) {
      if (!isMounted.current) return;

      addToast([getErrorMessage(e.message)]);
      setState(prev => ({
        ...prev,
        error: e,
        uploading: false,
      }));

      if (onError) onError(e);
      return;
    }

    if (!isMounted.current) return;

    const update: State = {
      s3Key,
      url: _url,
      uploading: false,
      error: null,
    };
    await defer(400).then(() => {
      setState(update);
      if (onUpload) onUpload({ s3Key, url: _url });
    });
  };

  const clear = useCallback<ClearImage>(() => {
    if (!isMounted.current) return;
    if (state.uploading) return;

    setState(() => ({
      url: null,
      s3Key: null,
      error: null,
      uploading: false,
    }));

    if (onClear) onClear();
  }, [state.uploading, onClear]);

  return {
    ...state,
    upload,
    clear,
  };
};

export default useImageUpload;
