/**
 *
 * Loading animation, adjusted slightly from the puff version on
 * https://samherbert.net/svg-loaders/
 */
import { Props } from '..';
import React from 'react';
import { useTheme } from 'styled-components';

const Loading: React.FC<Props> = ({ color, ...props }: Props) => {
  const theme = useTheme();
  const _color = color ?? theme.color('primary', 'light');

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="-6 -6 50 50"
      stroke={_color}
      {...props}
    >
      <g fill="none" fillRule="evenodd" strokeWidth={2}>
        <circle cx={17} cy={17} r={1}>
          <animate
            attributeName="r"
            begin="0s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="0s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx={19} cy={19} r={1}>
          <animate
            attributeName="r"
            begin="-0.9s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="-0.9s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  );
};

export default Loading;
