import React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import {
  SpanElement,
  type HeadingSixElement,
} from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import serializeHeading from '../../../utils/serialize/serializeHeading';

export type Props = RenderElementProps & {
  element: SpanElement;
};

const HeadingSix: React.FCC<Props> = ({ attributes, element, children }) => (
  <Container {...attributes} {...element.attributes}>
    {children}
  </Container>
);

const Container = styled.h6<{}>`
  margin: 0;
`;

export default {
  renderComponent: props => <HeadingSix {...props} />,
  nodeName: 'H6',
  deserialize: () => ({
    type: ELEMENTS.H6,
  }),
  serialize: (node: HeadingSixElement, children) =>
    serializeHeading('h6', children),
};
