import React, { useState, useEffect, useCallback, useRef } from 'react';
import { convertToFullTimeString, asFullTimeField } from '~/util/time';
import { format } from '~/util/date';
import { SelectedOption, Option } from '~/components/molecule/Dropdown';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import Input, { Props as InputProps } from '../molecule/Input';
import type { IconType } from '../atom/Icon';

type Props = Omit<InputProps, 'icon' | 'onChange'> & {
  onChange: (value: string | null) => void;
};

const TimePicker: React.FC<Props> = ({ value, onChange }) => {
  const openerRef = useRef<HTMLDivElement | null>(null);
  const [dropdownListOpen, setDropdownListOpen] = useState(false);

  const selectOption = (selectedOption: SelectedOption) => {
    onChange(
      selectedOption.option.label
        ? (selectedOption.option.label as string)
        : null,
    );
    setDropdownListOpen(false);
  };

  const handleInput = (value: string) => {
    onChange(sanitize(value));
    setDropdownListOpen(false);
  };

  const hideList = () => setDropdownListOpen(false);
  const showList = () => setDropdownListOpen(true);

  const ensureFullTimefield = useCallback(() => {
    const fullValue = asFullTimeField(value as string);
    if (fullValue !== value) onChange(fullValue);
  }, [value, onChange]);

  useEffect(() => {
    ensureFullTimefield();
  }, [ensureFullTimefield]);

  const options = timeOptions();
  let selectedOptionIdx: number | undefined = undefined;

  const currentTimeDate = format(new Date(), 'H:mm');
  const timeValue = value || getRoundedTime(currentTimeDate);

  options.forEach((item, index) => {
    if (item.key === timeValue) {
      selectedOptionIdx = index;
    }
  });

  return (
    <div>
      <Input
        value={value}
        width="100%"
        placeholder="hh:mm"
        onFocus={showList}
        onBlur={ensureFullTimefield}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInput(event.target.value)
        }
        icon={{ name: 'clock' as IconType }}
        inputContainerRef={openerRef}
      />

      <DropdownListContainer
        dropdownListOpen={dropdownListOpen}
        selectedOptionIdx={selectedOptionIdx}
        options={options}
        onChange={selectOption}
        onClose={hideList}
        openerRef={openerRef}
        onClickOutside={() => hideList()}
      />
    </div>
  );
};

const timeOptions = (): Array<Option> => {
  const times: Array<Option> = [];
  for (let h = 0; h < 24; h++) {
    for (let q = 0; q < 4; q++) {
      const time = convertToFullTimeString(h, q * 15);
      times.push({
        label: time,
        payload: null,
        key: time,
      });
    }
  }
  return times;
};

export const getRoundedTime = (timeValue: string): string => {
  const minsIndex = timeValue.indexOf(':') + 1;
  const hours = timeValue.slice(0, minsIndex - 1);
  const mins = parseInt(timeValue.slice(minsIndex));

  const roundedMins = mins / 30 > 1 ? '30' : '00';
  return `${hours}:${roundedMins}`;
};

const sanitize = (input: string): string => input.replace(/[^\d:]/g, '');

export default TimePicker;
