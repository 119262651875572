import React from 'react';
import PluginsEditor from '~/components/organism/PluginsEditor';
import withSimpleInsert from '~/components/organism/PluginsEditor/plugins/withSimpleInsert';
import serializeAllElements from '~/components/organism/PluginsEditor/utils/serialize';
import { RICH_TEXT_EDITOR_BUTTONS, SUPPORTED_ELEMENTS } from './constants';
import deserializeContent from './utils/deserializeContent';

export type Props = {
  /** Markdown string */
  value: string;

  /** Update the form state */
  onChange: (value: string) => void;
};

/**
 * A simplified version of our editor. Does not allow copy pasting or inserting html.
 */
const RichTextEditor: React.FCC<Props> = ({ value = '', onChange }) => {
  const editorValue = deserializeContent(value);

  return (
    <PluginsEditor
      value={editorValue}
      onChange={value => {
        const serialized = serializeAllElements({
          fragment: value,
          customElements: SUPPORTED_ELEMENTS,
        });

        onChange(serialized);
      }}
      plugins={[
        {
          name: 'withSimpleInsert',
          fn: e =>
            withSimpleInsert({
              editor: e,
            }),
        },
      ]}
      toolbarButtons={RICH_TEXT_EDITOR_BUTTONS}
      minHeight={200}
      customElements={SUPPORTED_ELEMENTS}
    />
  );
};

export default RichTextEditor;
