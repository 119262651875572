import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';

export default () => (
  <Container
    data-testid="app-footer"
    align="center"
    justification="center"
    gap="m"
  >
    <TextBlock>&copy; DatHuis B.V. {new Date().getFullYear()}</TextBlock>
    <TextBlock>
      <Icon name="phone" />
      <a href="tel:+31202223571">020 222 3571</a>
    </TextBlock>
  </Container>
);

export const FOOTER_HEIGHT = 40;

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    z-index: ${theme.getTokens().zIndex.footer};
    min-height: ${FOOTER_HEIGHT}px;
  `,
);

const TextBlock = styled.span<{}>`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.space('xs')};

    font-size: ${theme.fontSize('s')};
    color: ${theme.color('primary')};

    a,
    a:visited {
      text-decoration: none;
      color: ${theme.color('primary')};
    }

    svg {
      color: ${theme.color('secondary')};
    }
  `};
`;
