import type { ValidationFunction } from '~/util/getValidationErrors';
import type { OutputType } from '../../../../../organism/WizardSteps/Apps/StellaxAi/AppDetails';
import { is } from 'ramda';
import Email from '~/util/Validation/Email';
import { isNonEmptyString } from '~/util/Validation/String';
import webSiteURLValidation from '~/util/websiteURLValidation';

export const fieldMapping: Record<
  Exclude<keyof OutputType, 'type'>,
  {
    label: string;
    required: true;
    validation: ValidationFunction;
  }
> = {
  email: {
    label: 'Email',
    required: true,
    validation: val =>
      is(String, val) && Email.isValid(val) ? true : 'Email is verplicht',
  },
  logo: {
    label: 'Logo',
    required: true,
    validation: val =>
      val && typeof val === 'object' && 's3key' in val
        ? true
        : 'Logo is verplicht',
  },
  name: {
    label: 'Bedrijfsnaam',
    required: true,
    validation: val =>
      isNonEmptyString(val) ? true : 'Bedrijfsnaam is verplicht',
  },
  reportType: {
    label: 'Type rapport',
    required: true,
    validation: () => true,
  },
  website: {
    label: 'Website',
    required: true,
    validation: webSiteURLValidation,
  },
};
