import React, { useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Crumb } from '~/components/organism/Selector/components/Breadcrumbs';
import { ContactFiltersSubjectFragment } from '~/graphql/types';

import { clone } from 'ramda';

import PortalledSelector from './components/PortalledSelector';
import getLabelForRepresentation from '../../util/contactFilter/getLabelForRepresentation';
import { isEmptyObject } from '~/util/object';
import { Leaf } from '../FilterGroupV2';
import { OptionTypeMap } from '../../util/contactFilter/getOptionType';
import {
  CommandMap,
  TypeIdToCommandsMap,
} from '../../util/contactFilter/getCommand';
import { TypeMap } from '../../util/contactFilter/getType';
import { CurrentCommand } from '../FilterActionSelector';
import { SubjectMap } from '../../util/contactFilter/getSubject';
import { TopicMap } from '../../util/contactFilter/getTopic';
import { InnerContactFilterFieldOutput } from '../../util/contactFilter/getInnerFieldsByPath';
import FilterRepresentation from '~/components/molecule/FilterRepresentation';
import { ElementSize } from '~/hooks/useSize';
import CommandInputComponent from './components/CommandInputComponent';
import { SelectedInnerField } from '../FilterInnerSelector';

type Representation =
  | {
      error?: undefined;
      result: string;
    }
  | {
      error: string;
    };

const CommandRepresentation: React.FCC<{
  isCommandSelected: boolean;
  representation: Representation;
  pointerOffset?: number;
  setRepresentations: React.Dispatch<
    React.SetStateAction<Array<Representation>>
  >;

  lastSelectedField: $GetElementType<SelectedInnerField> | undefined;
  buttonRefs: React.MutableRefObject<{
    [key: string]: HTMLButtonElement | null;
  }>;
  commandIdx: number;
  currentCommand: CurrentCommand;
  setCurrentCommand: React.Dispatch<React.SetStateAction<CurrentCommand>>;

  leafs: Array<Leaf>;
  setLeafs: React.Dispatch<React.SetStateAction<Array<Leaf>>>;

  subjectMap: SubjectMap;
  topicMap: TopicMap;
  typeMap: TypeMap;
  optionTypeMap: OptionTypeMap;

  commandMap: CommandMap;
  typeIdToCommandsMap: TypeIdToCommandsMap;

  leaf: Leaf;
  currentLeafIndex: number;

  subject: ContactFiltersSubjectFragment | null;
  onFieldSelect: (field: InnerContactFilterFieldOutput) => void;
  currentFields: {
    result: Array<InnerContactFilterFieldOutput>;
    error?: undefined;
  };
  selectedField: SelectedInnerField;
  onBreadcrumbClick: (crumb: Crumb, index: number) => void;

  debouncedActionSelectorSize: ElementSize;
  setPortalledSelectorRef: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<HTMLDivElement | null>>
  >;
}> = ({
  setPortalledSelectorRef,
  debouncedActionSelectorSize,
  representation,
  commandIdx,
  buttonRefs,
  isCommandSelected,
  lastSelectedField,
  currentFields,
  selectedField,
  currentCommand,
  setLeafs,
  setCurrentCommand,

  onFieldSelect,
  commandMap,
  setRepresentations,
  typeMap,
  subjectMap,
  optionTypeMap,
  topicMap,
  leaf,
  currentLeafIndex,
  onBreadcrumbClick,
  subject,
}) => {
  useEffect(() => {
    if (leaf.instanceAttribute == null) return;

    setRepresentations(
      leaf.instanceAttribute.commands.map(command =>
        getLabelForRepresentation(
          {
            command,
            subjectId: subject?.subjectId,
            eventId: subject?.eventId ?? undefined,
          },
          { subjectMap, commandMap, topicMap, typeMap, optionTypeMap },
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaf.instanceAttribute?.commands?.length]);

  const onCommandSave = () => {
    if (currentCommand == null) return;

    setLeafs(prev => {
      const clonedPrev = clone(prev);
      if (
        isEmptyObject(
          clonedPrev[currentLeafIndex].instanceAttribute?.commands[
            currentCommand.commandIdx
          ],
        )
      ) {
        clonedPrev[currentLeafIndex].instanceAttribute?.commands.splice(
          currentCommand.commandIdx,
          1,
        );
      }
      return clonedPrev;
    });
    setCurrentCommand(null);
    if (leaf.instanceAttribute == null) return;
    setRepresentations(
      leaf.instanceAttribute.commands.map(command =>
        getLabelForRepresentation(
          {
            command,
            subjectId: subject?.subjectId,
            eventId: subject?.eventId ?? undefined,
          },
          { subjectMap, commandMap, topicMap, typeMap, optionTypeMap },
        ),
      ),
    );
  };

  const command = useMemo(
    () =>
      isCommandSelected &&
      currentCommand &&
      lastSelectedField?.__typename === 'ContactFiltersCommand'
        ? leaf.instanceAttribute?.commands[currentCommand.commandIdx]
            .commandId === lastSelectedField.commandId
          ? {
              ...leaf.instanceAttribute.commands[currentCommand.commandIdx],
              commandId: lastSelectedField.commandId,
            }
          : {
              commandId: lastSelectedField.commandId,
            }
        : null,
    [
      currentCommand,
      isCommandSelected,
      lastSelectedField,
      leaf.instanceAttribute?.commands,
    ],
  );

  if (isCommandSelected && currentCommand == null) return null;

  return (
    <CommandRepresentationContainer>
      <FilterRepresentation
        maxWidth="40rem"
        ref={element => {
          buttonRefs.current[commandIdx] = element as HTMLButtonElement | null;
        }}
        disabled={currentCommand != null && !isCommandSelected}
        active={isCommandSelected}
        error={representation.error ?? null}
        label={representation.error == null ? representation.result : null}
        onClick={e => {
          e.stopPropagation();
          setCurrentCommand({
            commandIdx,
          });
        }}
        onRemove={() => {
          setLeafs(prev => {
            const clonedPrev = clone(prev);

            clonedPrev[currentLeafIndex].instanceAttribute?.commands.splice(
              commandIdx,
              1,
            );

            setCurrentCommand(null);

            return clonedPrev;
          });
        }}
      />

      {isCommandSelected && currentCommand && (
        <PortalledSelector
          setPortalledSelectorRef={setPortalledSelectorRef}
          debouncedActionSelectorSize={debouncedActionSelectorSize}
          buttonRefs={buttonRefs}
          maxHeightInPx={
            lastSelectedField?.__typename === 'ContactFiltersCommand'
              ? 400
              : undefined
          }
          buttonRefKey={`${currentCommand.commandIdx}`}
          overflowY={
            lastSelectedField?.__typename === 'ContactFiltersCommand'
              ? 'visible'
              : 'scroll'
          }
          lastSelectedField={lastSelectedField}
          onClickOutside={onCommandSave}
          actions={[
            {
              key: 'trash',
              icon: 'trashcan',
              onClick: () => {
                setLeafs(prev => {
                  const clonedPrev = clone(prev);

                  clonedPrev[
                    currentLeafIndex
                  ].instanceAttribute?.commands.splice(
                    currentCommand.commandIdx,
                    1,
                  );

                  setCurrentCommand(null);

                  return clonedPrev;
                });
              },
              appearance: 'danger',
            },
            currentFields.result.length === 0
              ? {
                  key: 'save',
                  icon: 'check',
                  onClick: onCommandSave,
                  appearance: 'secondary',
                }
              : null,
          ]}
          crumbs={[
            {
              label: '...',
              key: 'root',
            },
            ...selectedField.map(field => ({
              label: field.label,
              key: getKeyForField(field),
            })),
          ]}
          onBreadcrumbClick={onBreadcrumbClick}
        >
          {command && (
            <CommandInputComponent
              key={currentCommand.commandIdx}
              shouldShowValidationError={
                representation.error != null ? true : false
              }
              command={command}
              currentCommand={currentCommand}
              commandMap={commandMap}
              typeMap={typeMap}
              optionTypeMap={optionTypeMap}
              leafIndex={currentLeafIndex}
              selectedField={selectedField}
              setLeafs={setLeafs}
            />
          )}
          {lastSelectedField?.__typename !== 'ContactFiltersCommand' &&
            currentFields.result.map(
              field =>
                field && (
                  <ListItem
                    key={getKeyForField(field)}
                    onClick={() => onFieldSelect(field)}
                    data-objectid={getKeyForField(field)}
                  >
                    {field.label}
                  </ListItem>
                ),
            )}
        </PortalledSelector>
      )}
    </CommandRepresentationContainer>
  );
};

const CommandRepresentationContainer = styled.div<{}>`
  width: 100%;
  display: flex;

  position: 'relative';
  ${({ theme }) => css`
    gap: ${theme.space('xxxs')};
  `}
`;

const ListItem = styled.li(
  ({ theme }) => css`
    padding: ${theme.space('xxs')};
    cursor: pointer;
    color: ${theme.color('text')};
    border-radius: ${theme.getTokens().border.radius.base};

    transition: all 0.3s ease-out;

    &:hover {
      background-color: ${theme.color('tertiary', 'light')};
      color: ${theme.color('tertiary', 'text')};
    }
  `,
);

const getKeyForField = (field: $GetElementType<SelectedInnerField>) =>
  field.__typename === 'ContactFiltersCommand' ? field.commandId : field.key;

export default CommandRepresentation;
