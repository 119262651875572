import React from 'react';
import { AppStatusLockedReason } from '~/graphql/types';
import AppEnabled from './screens/AppEnabled';
import AppDisabled from './screens/AppDisabled';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import { ExtendedAppConfig } from '~/hooks/useApps';
import usePrevious from '~/hooks/usePrevious';
import useUserRights from '~/hooks/useUserRights';
import type { Option } from '~/components/molecule/Dropdown';

export type AppSetupModalProps = {
  /** App config data */
  app: ExtendedAppConfig;

  /** If the user has flow rights and there are flow templates for the app */
  hasFlows: boolean;

  /** Enable or disable app */
  onChangeAppState: (options?: { selectedProduct: Option }) => Promise<void>;

  /** Starts the oAuth flow. On error is called if the oAuth mutation returns an error */
  onOAuthCall?: (params: { onError: () => void }) => Promise<void>;

  /** Close modal */
  onClose: () => void;

  /** Refetch apps after success */
  refetch: () => void;
};

export type Props = AppSetupModalProps & RouteComponentProps;

const AppSetupModal: React.FCC<Props> = ({
  app,
  hasFlows,
  onClose,
  onChangeAppState,
  onOAuthCall,
  refetch,
}) => {
  const { update: mayUpdate } = useUserRights({ category: 'Apps' });

  const lockedState = app.appStatus.locked || null;

  const disabled =
    !mayUpdate ||
    lockedState === AppStatusLockedReason.Disabling ||
    app.visibility === 'preview';

  const props = {
    app,
    disabled,
    hasFlows,
    onClose,
    onChangeAppState,
    refetch,
  };

  // In order to delay `AppEnabled` modal to be opened right after appStatus is changed
  // When app is just being enabled, we want to show setup wizard first
  const prevEnabled = usePrevious(app.appStatus.enabled);

  if (app.appStatus.enabled && prevEnabled) {
    return <AppEnabled {...props} />;
  }

  return <AppDisabled {...props} onOAuthCall={onOAuthCall} />;
};

export default AppSetupModal;
