import React from 'react';
import styled, { css } from 'styled-components';
import getLabelParts from '~/components/page/Automation/v2/util/getLabelParts';
import { isNil } from 'ramda';
import isActionLabel from '../../utils/isActionLabel';

export type Props = {
  dataTestId?: string;

  /**
   * A string that might include both labels and text
   * E.g. [[A1]] Some text [[B5]] another text
   */
  str: string | null;

  /**
   * Makes the label bold
   */
  highlightLabel?: boolean;
};

/**
 * Returns the label to be used in the breadcrumbs
 */
const ActionLabelAsText: React.FCC<Props> = ({
  str,
  highlightLabel = false,
}) => {
  const parts = getLabelParts(str);

  if (isNil(parts)) return null;

  return (
    <>
      {parts.map((part, idx) => {
        if (isActionLabel(part)) {
          const cleanLabel = part.slice(2, -2);

          return (
            <LabelContainer $highlighted={highlightLabel} key={idx + part}>
              {cleanLabel}&nbsp;-&nbsp;
            </LabelContainer>
          );
        }

        return part;
      })}
    </>
  );
};

const LabelContainer = styled.span<{ $highlighted: boolean }>(
  ({ theme, $highlighted }) => css`
    font-weight: ${$highlighted ? theme.fw('semiBold') : theme.fw('regular')};
  `,
);

export default ActionLabelAsText;
