import type { OptionOf } from '~/components/molecule/Dropdown';
import { SortFieldAdvanced } from '~/graphql/types';

const getDateRangeOptions = (): Array<OptionOf<SortFieldAdvanced>> => [
  {
    key: 'last-week',
    label: 'Afgelopen week',
    payload: SortFieldAdvanced.SevenDayChange,
  },
  {
    key: 'last-two-week',
    label: 'Afgelopen 3 dagen',
    payload: SortFieldAdvanced.ThreeDayChange,
  },
  {
    key: 'one-day-change',
    label: 'Gisteren',
    payload: SortFieldAdvanced.OneDayChange,
  },
];
export default getDateRangeOptions;
