import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import AssignBlock, { OnChangeProps } from '~/components/organism/AssignBlock';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading5, Variant } from '~/components/atom/Typography';

export type Props = {
  onSave: (update: OnChangeProps) => void;
  loading?: boolean;
} & OnChangeProps;

const AssignContactWithSave: React.FCC<Props> = ({
  dataTestId,
  userId,
  officeId,
  onSave,
  loading = false,
  ...rest
}) => {
  const [update, setUpdate] = useState<OnChangeProps>({ userId, officeId });
  return (
    <>
      <Heading5 margin={[null]} variant={Variant.primary}>
        Contacten toewijzen aan:
      </Heading5>
      <JustificationContainer
        justification="space-between"
        align="center"
        data-testid={dataTestId}
        {...rest}
      >
        <Inner>
          <AssignBlock
            userId={update.userId}
            officeId={update.officeId}
            onChange={change => setUpdate(change)}
          />
        </Inner>
        <Button
          size="medium"
          label="Klaar"
          onClick={() => onSave(update)}
          appearance="secondary"
          icon="check"
          loading={loading}
          disabled={update.userId === userId && update.officeId === officeId}
        />
      </JustificationContainer>
    </>
  );
};

const Inner = styled.div<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('s')};
    width: 100%;
  `,
);

export default AssignContactWithSave;
