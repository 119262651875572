import type {
  ContactFiltersLeafCommands__Input,
  ContactFiltersSubjectField_ContactFiltersSubjectFieldType_Fragment,
} from '~/graphql/types';

import getSubject, { SubjectMap } from '../getSubject';
import getCommand, { CommandMap } from '../getCommand';
import { TopicMap } from '../getTopic';
import { isNil } from 'ramda';
import { getPathForCommand } from '../getPathForLeaf';
import { TypeMap } from '../getType';
import renderHandlebarsTemplate from '~/util/handlebars';
import { OptionTypeMap } from '../getOptionType';
import formatValue from '../formatValue';

const text = {
  brokenFilter: 'Deze filter is niet meer beschikbaar',
};

const getLabelForRepresentation = (
  {
    command,
    subjectId,
    eventId,
  }: {
    command?: Partial<ContactFiltersLeafCommands__Input> | null;
    subjectId?: string;
    eventId?: string;
  },
  {
    subjectMap,
    commandMap,
    topicMap,
    typeMap,
    optionTypeMap,
  }: {
    subjectMap: SubjectMap;
    commandMap: CommandMap;
    topicMap: TopicMap;
    typeMap: TypeMap;
    optionTypeMap: OptionTypeMap;
  },
): { error?: undefined; result: string } | { error: string } => {
  if (
    command?.commandId == null &&
    ((command?.path?.length != null && command?.path?.length > 0) ||
      (command?.args?.length != null && command.args.length > 0))
  )
    return { error: text.brokenFilter };

  if (isNil(command) || command?.commandId == null) {
    return {
      result: '...',
    };
  }

  const commandBlock = getCommand(command.commandId, commandMap);
  if (!commandBlock) return { error: `Missing command ${command.commandId}` };

  const { representation: representationBlock, args: commandBlockArgs } =
    commandBlock;

  const subject = getSubject(
    { subjectId: subjectId ?? '', eventId },
    subjectMap,
  );
  if (!subject) {
    return { error: text.brokenFilter };
  }

  const path = getPathForCommand(
    {
      ...command,
      path: command.path ?? [],
      commandId: command.commandId ?? '',
    },
    subject,
    {
      commandMap,
      topicMap,
    },
  );

  if (path.error != null) {
    return { error: text.brokenFilter };
  }

  const field = path.result[
    path.result.length - 1
  ] as ContactFiltersSubjectField_ContactFiltersSubjectFieldType_Fragment;

  for (const block of representationBlock) {
    if (block.arg == null) continue;

    const { typeId } = commandBlockArgs[block.arg];
    const typeBlock = typeMap[typeId] ?? optionTypeMap[typeId];

    const { inputField } = typeBlock;
    if (command.args == null || command.args[block.arg] == null) {
      return {
        error: '@TODO You must fill in all arguments',
      };
    }

    const arg = command.args[block.arg];

    const { invalidInputDescription } = formatValue(
      arg ? arg[inputField] : undefined,
      typeBlock,
    );

    if (invalidInputDescription) {
      return {
        error: invalidInputDescription,
      };
    }
  }
  const representation = representationBlock
    .map(block => {
      const rep = block.rep.replace(`{{{label}}}`, field.label);
      if (block.arg == null) return rep;
      if (command.args == null) return '...';

      const { typeId } = commandBlockArgs[block.arg];

      const typeBlock = typeMap[typeId] ?? optionTypeMap[typeId];
      const {
        inputField,
        representation: typeRepresentation,
        __typename,
      } = typeBlock;

      const arg = command.args[block.arg];
      const typeLabel =
        __typename === 'ContactFiltersType'
          ? renderHandlebarsTemplate(typeRepresentation, {
              arg: arg[inputField],
            })
          : renderHandlebarsTemplate(typeRepresentation, {
              arg: optionTypeMap[typeId].options.find(
                option => option.value === arg[inputField],
              )?.label,
            });
      return renderHandlebarsTemplate(rep, { arg: typeLabel });
    })
    .join(' ');

  return {
    result: representation,
  };
};

export default getLabelForRepresentation;
