import React from 'react';
import { Props } from '..';

const Quote: React.FCC<Props> = ({ color = 'currentColor', ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="-4 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9 12.2999H10.7C10.1 12.2999 9.5 11.7999 9.5 11.1999V8.9999C9.5 8.3999 10 7.8999 10.7 7.8999H11.9C12.5 7.8999 13.1 8.3999 13.1 8.9999V10.0999V11.1999C13.1 11.7999 12.5 12.2999 11.9 12.2999Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1 10.1997C13.1 10.1997 13.1 12.1997 13.1 13.2997C13.1 15.2997 10.6 16.0997 10.6 16.0997"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.30002 12.2999H4.10002C3.50002 12.2999 2.90002 11.7999 2.90002 11.1999V8.9999C2.90002 8.3999 3.40002 7.8999 4.10002 7.8999H5.30002C6.00002 7.8999 6.50002 8.3999 6.50002 8.9999V10.0999V11.1999C6.50002 11.7999 6.00002 12.2999 5.30002 12.2999Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 10.1997C6.5 10.1997 6.5 12.1997 6.5 13.2997C6.5 15.2997 4 16.0997 4 16.0997"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Quote;
