import { atom } from 'recoil';

/**
 * Validation errors to show in the save bar and on the nav bar
 */
export type ErrorsByPath = {
  '/': string | null;
  '/design': string | null;
};

export const defaultErrorsByPath = atom<ErrorsByPath>({
  key: 'appValuationReportErrorsByPath',
  default: { '/': null, '/design': null },
});

/**
 * Keeps the temporary image links until we save the changes and get the permanent links
 */
export type ImageStash = {
  logoImage: string | null;
  backgroundImage: string | null;
};

export const defaultValueReportImageStash = atom<ImageStash>({
  key: 'valueReportImageStash',
  default: { logoImage: null, backgroundImage: null },
});
