import React from 'react';
import styled, { css } from 'styled-components';

import type { WidgetDescriptionItem, WidgetItemGroup } from '~/graphql/types';
import type { SelectedGroups } from '../..';
import type { AppsHashMap, ExtendedAppConfig } from '~/hooks/useApps';
import getPriceWithInterval from '~/util/getPriceWithInterval';
import { Heading3 } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { appTypeToTypename } from './utils/getWidgetGroupsMapOutput';
import AppsGroupTile from '~/components/organism/AppsGroupTile';
import getAppPriceBySetupFlow from '~/components/page/Apps/StellaxAI/utils/getAppPriceBySetupFlow';
import type { OutputMap } from '~/components/organism/WizardSteps';
import useDHFlag from '~/hooks/useDHFlag';

const text = {
  selectApps: 'Selecteer apps',
};

const collectItemIcons = (
  items: Array<WidgetDescriptionItem>,
): Array<string> => {
  const logos = items
    .map(item => item.icon.src as string)
    .filter(src => src !== undefined);

  return logos;
};

export type Props = {
  onSelectGroup: (group) => void;
  groups: Array<WidgetItemGroup>;
  selectedGroupsMap?: SelectedGroups;
  apps: AppsHashMap;
  outputMap: OutputMap;
};

const WidgetGroupsScreen: React.FCC<Props> = ({
  dataTestId,
  onSelectGroup,
  groups,
  selectedGroupsMap,
  apps,
  outputMap,
  ...rest
}) => {
  const hasStellax = useDHFlag('stellax');

  return (
    <>
      <HeaderContainer width="100%" align="center" justification="center">
        <Heading3 size="m" margin={[null, null, 'base', null]}>
          {text.selectApps}
        </Heading3>
      </HeaderContainer>
      <GridContainer data-testid={dataTestId} {...rest}>
        {groups.map(group => {
          const itemIcons = collectItemIcons(group.items);
          const appType = appTypeToTypename(group.type);
          const app: ExtendedAppConfig | null = apps[appType];

          if (appType === 'AppStatus_StellaxAi' && !hasStellax) return null;

          const price = app
            ? getPriceWithInterval(
                getAppPriceBySetupFlow({
                  app,
                  outputMap,
                }),
              )
            : null;

          const pricePrefix = app && Array.isArray(app.price) ? 'vanaf ' : '';

          return (
            <AppsGroupTile
              selectedAmount={
                selectedGroupsMap ? selectedGroupsMap[group.type].length : 0
              }
              onClick={() => onSelectGroup(group)}
              title={group.name}
              description={group.description}
              key={group.name}
              itemIcons={itemIcons}
              price={`${pricePrefix}${price}`}
            />
          );
        })}
      </GridContainer>
    </>
  );
};

const GridContainer = styled.div<{}>(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: ${theme.space('base')};
    width: 100%;
  `,
);

const HeaderContainer = styled(JustificationContainer)`
  position: relative;
`;

export default WidgetGroupsScreen;
