import { atom } from 'recoil';

type ActiveSegment = {
  segmentId: string | null;
  filterId: string | null;
};

const activeFilter = atom<ActiveSegment>({
  key: 'activeFilterOnLeftFilterBar',
  default: { segmentId: null, filterId: null },
});

export default activeFilter;
