import { unnest } from 'ramda';
import { Text } from 'slate';
import { jsx } from 'slate-hyperscript';

/**
 * Deserializes only text nodes and given custom elements.
 * Used in withHtml plugin when copy pasting from external editor in simple editor
 *
 * @param {Node} el - Element to deserialize
 * keywords: deserialize plain text
 */
const deserializeAsPlainText = (el: Node): Array<Text> | Text => {
  if (el.nodeType === 3) {
    return jsx('text', {}, el.textContent);
  }

  const parent = el;
  const children = unnest(
    Array.from(parent.childNodes).map(child => deserializeAsPlainText(child)),
  );

  return children;
};
export default deserializeAsPlainText;
