import Validation from './Validation/';

/**
 * Replace all
 *
 * @param {string} string The string to change
 */
export const dashSeparatedLowercase = (string: string): string =>
  string.trim().replace(/\W+/g, '-').toLowerCase();

export const lowercaseFirstLetter = (string: string): string => {
  if (!string) return string;
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const stringSlicer = (
  value: string | null | undefined,
  maxLength: number,
): string => {
  if (value == null || !Validation.String.isString(value)) {
    return '';
  }

  if (value.length > maxLength) {
    return `${value.substr(0, maxLength)}...`;
  }

  return value;
};

const nonNumberRegex = /[^\d]/g;
export const onlyDigits = (string: string): string =>
  string.replace(nonNumberRegex, '');

export const sortByAlphabet =
  (nameToSort: string) =>
  (a: $Object, b: $Object): number => {
    const nameA = a[nameToSort].toLowerCase(),
      nameB = b[nameToSort].toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

export const asNonEmptyStringOrNull = (
  string: string | null | undefined,
): string | null => {
  if (!string) return null;

  return string;
};

export const capitalizeFirstLetter = (string: string): string => {
  if (string === '') return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const matchAll = (
  string: string,
  regex: RegExp,
  getFunc?: (match: Array<string>) => string,
): Array<string> => {
  const matches: Array<string> = [];

  let match;
  while ((match = regex.exec(string)) !== null) {
    if (match != null) {
      const toPush = getFunc ? getFunc(match) : match[0];

      matches.push(toPush);
    }
  }

  return matches;
};
