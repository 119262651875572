import { isEmpty } from 'ramda';
import React, { useEffect } from 'react';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import {
  useGetAppBbWaardecheckRouteAvailabilityLazyQuery,
  useGetAppVboWaardecheckRouteAvailabilityLazyQuery,
} from '~/graphql/types';
import { AppStatus__typename } from '~/graphql/types.client';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useDebounce from '~/hooks/useDebounce';
import useWizardStep from '~/hooks/useWizardStep';
import ControlledInput from '~/components/page/Apps/components/ControlledInput';
import useGetAppWaardeCheckQuery from '../hooks/useGetAppWaardeCheckQuery';
import TEST_ID from './index.testid';
import { URL_STATUS_INDICATORS } from '~/styles/indicators';

export const id = 'WaardecheckGeneral';
export const title = 'Kies een URL';

export type OutputType = {
  type: typeof id;
  route: OutputFieldInvisible<string | null>;
};

const text = {
  error: {
    routeUnavailable: 'Deze route is niet beschikbaar',
  },
};

const useGetAppWaardecheckRouteAvailabilityLazyQuery = (
  typename?: AppStatus__typename,
) => {
  if (!typename) {
    throw new Error('Missing typename in metadata');
  }

  const useHook =
    typename === 'AppStatus_VBOWaardecheck'
      ? useGetAppVboWaardecheckRouteAvailabilityLazyQuery
      : useGetAppBbWaardecheckRouteAvailabilityLazyQuery;

  return useHook();
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const { id: accountId } = useCurrentAccount();
  const [, api] = useWizardStep(step.id);
  const currentOutput = outputMap[id] as OutputType;
  const useQueryHook = useGetAppWaardeCheckQuery(step.metadata?.typename);
  const errors: Array<string> = [];

  const { data: queryData, loading } = useQueryHook({
    fetchPolicy: 'network-only',
    variables: { accountId },
  });
  const data = (() => {
    if (queryData) {
      if ('getAppVBOWaardecheck' in queryData) {
        return queryData.getAppVBOWaardecheck;
      }
      if ('getAppBBWaardecheck' in queryData) {
        return queryData.getAppBBWaardecheck;
      }
    }
    return undefined;
  })();

  const [
    getRouteAvailability,
    { loading: routeLoading, data: routeData, variables: routeVariables },
  ] = useGetAppWaardecheckRouteAvailabilityLazyQuery(step.metadata?.typename);

  const debouncedRoute = useDebounce(currentOutput?.route ?? undefined, 1000);
  let statusIndex: number | undefined = 0;

  const isCheckingRoute =
    debouncedRoute !== currentOutput.route || routeLoading;

  if (
    !loading &&
    currentOutput.route &&
    currentOutput?.route?.value === data?.route
  ) {
    statusIndex = 0;
  }

  if (
    !isCheckingRoute &&
    routeData != null &&
    debouncedRoute !== null &&
    routeVariables?.route === debouncedRoute?.value
  ) {
    statusIndex = routeData.isAvailable ? 1 : 2;

    if (statusIndex === 2) {
      errors.push(text.error.routeUnavailable);
    }
  }
  useEffect(() => {
    if (statusIndex === 1) {
      api.free(currentOutput);
    } else {
      api.lock(currentOutput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOutput, statusIndex]);

  useEffect(() => {
    if (debouncedRoute?.value == null || isEmpty(debouncedRoute)) return;

    void getRouteAvailability({
      variables: {
        accountId,
        route: debouncedRoute.value,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, debouncedRoute]);

  useEffect(() => {
    if (data && currentOutput.route.value === null) {
      api.lock({
        ...currentOutput,
        route: { value: data.route, type: 'invisible' },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading || !data?.route) return <DatHuisLoading />;

  return (
    <ControlledInput
      id="route"
      statuses={
        !isEmpty(currentOutput?.route) ? URL_STATUS_INDICATORS : undefined
      }
      statusIndex={statusIndex}
      prefix="https://mijnwaardecheck.nl/"
      label="Gewenste adres"
      errors={errors}
      transform={[
        value => value?.toLowerCase() ?? null,
        (val, prev) => {
          if (!/^([a-z0-9]|-)+$/.test(val ?? '') && val != null) {
            return prev;
          }

          return val;
        },
      ]}
      value={currentOutput.route?.value}
      onChange={(value, { hasErrors }) => {
        if (hasErrors) {
          return api.lock({
            ...currentOutput,
            route: { value, type: 'invisible' },
          });
        }

        api.free({
          ...currentOutput,
          route: { value, type: 'invisible' },
        });
      }}
      validation={[
        (value: string) => {
          // Initial or empty value
          if (value === null) {
            return true;
          }
          if (/^([a-z0-9]|-)+$/.test(value ?? '')) {
            return true;
          }

          return 'Vul een waarde in. Alleen letters, cijfers en het streepje(-) zijn toegestaan';
        },
      ]}
      dataTestId={TEST_ID.ROUTE}
    />
  );
};

export default {
  id,
  title,
};
