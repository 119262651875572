import React, { useEffect, useState } from 'react';
import CustomMarkdown from '~/components/template/AppSetupModal/components/CustomMarkdown';

import { AppsAndItemsToEnable } from '~/components/organism/WizardSteps/OnboardingWizardSteps/SelectAppsForWidget';
import { AppConfig } from '~/components/page/Apps/types';
import getPriceWithInterval from '~/util/getPriceWithInterval';
import CollapsibleBlockWithCheckbox from '~/components/organism/CollapsibleBlockWithCheckbox';
import type { OutputMap } from '~/components/organism/WizardSteps';
import getAppPriceBySetupFlow from '~/components/page/Apps/StellaxAI/utils/getAppPriceBySetupFlow';
import type { ExtendedAppConfig } from '~/hooks/useApps';

export type Props = {
  app: ExtendedAppConfig;
  confirmedAppsToEnable: AppsAndItemsToEnable | null;
  onSelectApp: (app: AppConfig) => void;
  outputMap: OutputMap;
};

const text = {
  noTermsOfUse: 'Er zijn geen gebruiksvoorwaarden beschikbaar',
};

const CollapsibleAppBlock: React.FC<Props> = ({
  app,
  onSelectApp,
  confirmedAppsToEnable,
  outputMap,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (checked) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    // Checks if the app was selected using `selectAll`
    if (confirmedAppsToEnable) {
      Object.keys(confirmedAppsToEnable).find(key => key === app.__typename)
        ? setChecked(true)
        : setChecked(false);
    }
    if (confirmedAppsToEnable === null) {
      setChecked(false);
    }
  }, [app.__typename, confirmedAppsToEnable]);

  const termsOfUse = app.termsOfUse === '' ? text.noTermsOfUse : app.termsOfUse;

  return (
    <CollapsibleBlockWithCheckbox
      onChange={() => {
        setChecked(prev => !prev);
        onSelectApp(app);
      }}
      checkboxValue={checked}
      isOpen={isOpen}
      label={`${app.name} - ${getPriceWithInterval(getAppPriceBySetupFlow({ app, outputMap }))}`}
      onToggle={() => setIsOpen(prev => !prev)}
    >
      <CustomMarkdown value={termsOfUse} />
    </CollapsibleBlockWithCheckbox>
  );
};

export default CollapsibleAppBlock;
