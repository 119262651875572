import React from 'react';
import Catalog from '~/Catalog';
import Dropdown from '~/components/molecule/Dropdown';
import { ChangeableEmailProps } from '../..';
import NamedEmailLabel from '../NamedEmailLabel';

export type Props = {
  disabled?: boolean;
  shouldValidate?: boolean;
  changeableEmail: ChangeableEmailProps;
};

const text = {
  requiredError: Catalog.requiredField,
};

const InputComponent: React.FCC<Props> = ({
  dataTestId,
  shouldValidate,
  disabled,
  changeableEmail,
}) => {
  if (changeableEmail.readOnly && changeableEmail.namedEmail) {
    const { namedEmail } = changeableEmail;

    return <NamedEmailLabel name={namedEmail.name} email={namedEmail.email} />;
  } else {
    const { selectedIdx, options, onChange } = changeableEmail;
    return (
      <Dropdown
        dataTestId={dataTestId}
        selectedOptionIdx={selectedIdx}
        options={options}
        onChange={onChange}
        disabled={disabled}
        error={
          shouldValidate && selectedIdx == null ? text.requiredError : null
        }
      />
    );
  }
};

export default InputComponent;
