import React, { useEffect } from 'react';

import {
  useGetMyTasksQuery,
  useMutatedTaskSubscription,
} from '~/graphql/types';
import AppErrorScreen, {
  UnauthorizedAppErrorScreen,
} from '~/components/template/AppErrorScreen';
import ErrorTypes from '~/ErrorTypes';
import { getErrorType } from '~/util/errorHandling';
import { TASK_LIST_LIMIT } from '../..';
import baseQueryVariablesForListType from '../../utils/baseQueryVariablesForListType';
import type { TaskGetterChildProps } from '../TaskWithSubscriptionGetter';
import { TaskListType } from '../../types';
import isDeleted from '../../utils/isDeleted';
import mergeListWithLatestVersion from '~/util/mergeListWithLatestVersion';
import { useRecoilState, useRecoilValue } from 'recoil';
import { flatten, prop, uniqBy, values } from 'ramda';
import groupedTasks, { deletedTaskIds } from '~/components/page/Tasks/state';
import generateActivityV2 from '~/components/template/EventTimelineV2/utils/generateActivityV2';
import { ActivityV2 } from '~/components/template/EventTimelineV2/types';
import removeActivityFromList from '~/components/template/EventTimelineV2/utils/removeActivityFromList';
import processMyTasks from '../../utils/processMyTasks';
import useCurrentUser from '~/hooks/useCurrentUser';
import Loading from '~/components/atom/Loading';

type Props = {
  accountId: string;
  filteredUserId: string;
  selectedListType: TaskListType;
  children: (props: TaskGetterChildProps) => JSX.Element;
};

const GetMyTasks: React.FC<Props> = ({
  accountId,
  filteredUserId,
  selectedListType,
  children,
}) => {
  const [tasks, setTasks] = useRecoilState(groupedTasks);
  const deletedTasks = useRecoilValue(deletedTaskIds);
  const me = useCurrentUser();

  const listTypeQueryVariables =
    baseQueryVariablesForListType(selectedListType);

  const variables = {
    ...listTypeQueryVariables,
    accountId,
    limit: TASK_LIST_LIMIT,
    unassigned: true,
  };

  const { data, loading, error, fetchMore } = useGetMyTasksQuery({
    variables,
  });

  useEffect(() => {
    if (data?.getMyTasks.items) {
      const items = data.getMyTasks.items.map(generateActivityV2);

      setTasks(prev => {
        const prevValues = values(prev).filter(
          (v): v is Array<ActivityV2> => v !== undefined,
        );

        const prevTasks: Array<ActivityV2> = flatten(prevValues ?? []);
        const newTasks = items.filter(activity => !isDeleted(activity));

        const mergedWithLatestVersion = mergeListWithLatestVersion<ActivityV2>(
          prevTasks,
          newTasks,
        );

        const formattedTasks = processMyTasks({
          tasks: mergedWithLatestVersion,
          me: me.id,
          selectedListType,
        });

        return formattedTasks;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getMyTasks.items, filteredUserId, selectedListType]);

  const { data: mutatedTaskData } = useMutatedTaskSubscription({
    variables: {
      accountId,
    },
    onData: ({ data }) => {
      const mutatedTask = data.data?.mutatedTask;

      if (mutatedTask?.id) {
        const newTask = generateActivityV2(mutatedTask);

        const isDeletedBeforeSubscription = deletedTasks.includes(newTask.id);

        if (isDeleted(newTask) || isDeletedBeforeSubscription) {
          const newTasks = removeActivityFromList({
            groupedActivities: tasks,
            deletedActivity: newTask,
          });

          setTasks(newTasks);
          return;
        }

        setTasks(prev => {
          const allTasks: Array<ActivityV2> = flatten(
            values({ ...prev }).filter(
              (v): v is Array<ActivityV2> => v !== undefined,
            ),
          );
          const sameTask = allTasks.find(task => task.id === newTask.id);
          const updatedTasks = sameTask
            ? allTasks.map(task => {
                if (task.id === sameTask.id) {
                  return newTask;
                }
                return task;
              })
            : [...allTasks, newTask];

          const formattedTasks = processMyTasks({
            tasks: updatedTasks,
            me: me.id,
            selectedListType,
          });

          return formattedTasks;
        });
      }
    },
  });

  if (loading) return <Loading />;
  if (error && getErrorType(error) === ErrorTypes.unauthorisedError)
    return <UnauthorizedAppErrorScreen />;

  if (!data || error) {
    return <AppErrorScreen />;
  }

  const { nextToken } = data.getMyTasks;
  const loadMore = () =>
    fetchMore({
      variables: {
        ...variables,
        nextToken: nextToken || null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const mutatedTask = mutatedTaskData?.mutatedTask;

        if (fetchMoreResult?.getMyTasks) {
          return {
            __typename: 'Query',
            getMyTasks: {
              ...fetchMoreResult?.getMyTasks,
              items: uniqBy(prop('id'), [
                ...(mutatedTask ? [mutatedTask] : []),
                ...previousResult.getMyTasks.items,
                ...fetchMoreResult.getMyTasks.items,
              ]),
            },
          };
        }

        return previousResult;
      },
    });

  return children({
    loadMore,
    hasMore: Boolean(nextToken),
  });
};

export default GetMyTasks;
