import React from 'react';
import { splitAt } from 'ramda';
import { assertNever } from '~/util/assertNever';
import type { FlowAction } from '~/graphql/types';
import type { RelativeMaps } from '~/components/page/Automation/v2/components/Builder/utils/getRelativeMaps';
import type { Flow___ConditionPartial } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2';
import getFlowDataForCondition from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/utils/getFlowDataForCondition';
import getLabelForRep from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/utils/getLabelForRep';
import RepresentationLabel from '../../../../../RepresentationLabel';
import { messages } from '~/components/page/Automation/v2/components/Builder/getIssuesForConditionExpression';
import styled, { css } from 'styled-components';

type Props = {
  condition: Flow___ConditionPartial | null;
  layout: 'complete' | 'onlyArguments';
  isLast: boolean;
  type: 'trigger' | 'condition';
  actionType: FlowAction;
  maps: RelativeMaps;
};

const Condition: React.FC<Props> = ({
  condition,
  layout,
  type,
  maps,
  actionType,
  isLast,
}) => {
  if (!condition) return <p>{messages.emptyCondition}</p>;

  const flowDataRaw = getFlowDataForCondition({
    condition,
    maps,
  });

  if (!flowDataRaw) return <p>{messages.missingInvalidOrMissingArg} </p>;

  const { flowData, hasArguments, firstArgumentIndex, lastArgumentIndex } =
    flowDataRaw;

  const representations = (() => {
    switch (layout) {
      case 'complete':
        return flowData.representation;
      case 'onlyArguments': {
        if (!hasArguments) return [];

        return flowData.representation.slice(firstArgumentIndex);
      }

      default:
        return assertNever(layout);
    }
  })();

  const representationsWithLabels = representations.map(representation => ({
    representation,
    label: getLabelForRep(
      {
        representation,
        condition,
        ctx: {
          action: actionType,
          type,
        },
      },
      maps,
    ),
  }));

  const representationElements = representationsWithLabels
    .map(({ representation: { variable }, label: labelData }, repIndex) => {
      const key = `${variable ?? ''}-${repIndex}`;
      const isArgument = (variable ?? 0) > 0;
      const hasError = labelData.error != null;
      const label = hasError ? labelData.error : labelData.result;

      if (variable == null) {
        if (!label) return null;
        /**
         * No variable - only text.
         * If we render an input variable here (is always 0) just show it as text.
         */

        return <span key={key}>{label}</span>;
      }

      if (!isArgument && layout === 'onlyArguments') return null;

      return (
        <Argument key={repIndex} $hasError={hasError}>
          <RepresentationLabel label={label} />
        </Argument>
      );
    })
    .filter(arg => !!arg);

  const {
    beforeConditionArguments,
    conditionArguments,
    afterConditionArguments,
  } = (() => {
    if (
      !hasArguments ||
      firstArgumentIndex == null ||
      lastArgumentIndex == null
    ) {
      return {
        beforeConditionArguments: representationElements,
        conditionArguments: [],
        afterConditionArguments: [],
      };
    }

    const [beforeConditionArguments, rest] = splitAt(
      layout === 'onlyArguments' ? 0 : firstArgumentIndex,
      representationElements,
    );

    const [conditionArguments, afterConditionArguments] = splitAt(
      lastArgumentIndex - firstArgumentIndex,
      rest,
    );

    return {
      beforeConditionArguments,
      conditionArguments,
      afterConditionArguments,
    };
  })();

  return (
    <>
      {beforeConditionArguments}
      {conditionArguments}
      {isLast && afterConditionArguments}
    </>
  );
};

const Argument = styled.span<{ $hasError: boolean }>(
  ({ theme, $hasError }) => css`
    font-weight: ${theme.fw('semiBold')};

    ${$hasError &&
    css`
      color: ${theme.color('danger')};
      background-color: ${theme.color('danger', 'translucent')};
      border-radius: ${theme.getTokens().border.radius.s};
      padding: 0 ${theme.space('xxs')};
    `}
  `,
);

export default Condition;
