import React, { useCallback, useState } from 'react';

import Catalog from '~/Catalog';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import { useUpdateContactMutation } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useConfirmModal, { type ModalLabels } from '~/hooks/useConfirmModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';

const text = {
  toggleCheckboxLabel: 'Ontvangt marketingmails',
  contactUnsubscribed: 'Contact uitgeschreven',
  contactSubscribed: 'Contact ingeschreven',

  subscribeModalTitle: 'Contact inschrijven',
  unsubscribeModalTitle: 'Contact uitschrijven',

  confirmUnsubscribe: 'Contact uitschrijven',
  confirmSubscribe: 'Contact inschrijven',

  subscribeModalMessage:
    'Een ingeschreven contact kan door flows gestuurde marketing e-mails ontvangen. Het contact dient hiervoor toestemming te hebben gegeven.',
  unsubscribeModalMessage:
    'Een uitgeschreven contact ontvangt geen marketing-e-mails vanuit flows. Het blijft mogelijk om dit contact reguliere e-mails te sturen.',
};

const unsubscribeLabels: ModalLabels = {
  title: text.unsubscribeModalTitle,
  message: text.unsubscribeModalMessage,
};

const subscribeLabels: ModalLabels = {
  title: text.subscribeModalTitle,
  message: text.subscribeModalMessage,
};

export type Props = {
  contactId: string;
  blockMarketing: boolean | null | undefined;
};

const BlockMarketingToggle: React.FCC<Props> = ({
  blockMarketing,
  contactId,
}) => {
  const { id: accountId } = useCurrentAccount();

  const addToast = useAddToast();

  const [updateContact, { loading }] = useUpdateContactMutation();

  const [internalBlockMarketing, setInternalBlockMarketing] = useState(
    blockMarketing ?? false,
  );

  const onConfirmBlockMarketing = useCallback(
    async () =>
      updateContact({
        variables: {
          accountId: accountId,
          id: contactId,
          update: {
            blockMarketing: !internalBlockMarketing,
          },
        },
      }).then(result => {
        if ((result.data && !result.errors) || result.errors?.length === 0) {
          setInternalBlockMarketing(
            result.data?.updateContact.blockMarketing ?? false,
          );
          return addToast([
            formatToastMessage(
              result.data?.updateContact.blockMarketing
                ? text.contactUnsubscribed
                : text.contactSubscribed,
              'success',
            ),
          ]);
        }

        return addToast([
          formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
        ]);
      }),
    [accountId, addToast, contactId, internalBlockMarketing, updateContact],
  );
  const {
    setShowModal: setShowBlockMarketingModal,
    modal: blockMarketingModal,
  } = useConfirmModal({
    labels: blockMarketing ? subscribeLabels : unsubscribeLabels,
    buttons: [
      {
        label: blockMarketing ? text.confirmSubscribe : text.confirmUnsubscribe,
        onClick: onConfirmBlockMarketing,
        loading,
      },
    ],
  });

  return (
    <>
      {blockMarketingModal}
      <ToggleCheckbox
        value={!internalBlockMarketing}
        label={text.toggleCheckboxLabel}
        onChange={() => setShowBlockMarketingModal(true)}
        containerProps={{
          direction: 'row',
          width: '100%',
          justification: 'space-between',
          align: 'center',
          gap: 'base',
        }}
        disabled={loading}
        loading={loading}
      />
    </>
  );
};

export default BlockMarketingToggle;
