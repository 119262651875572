import { atom } from 'recoil';

type State = {
  openedOnEdge: boolean;
  edgeId: string | null;
  targetX: number | null;
  targetY: number | null;
};

const addBlockMenu = atom<State>({
  key: 'addBlockMenu',
  default: {
    openedOnEdge: false,
    edgeId: null,
    targetX: null,
    targetY: null,
  },
});

export default addBlockMenu;
