type Args = {
  total: number;
  value: number;
};

const getPercentage = ({ total, value }: Args): number => {
  //we don't want progress bar to be 100% if both the values are 0
  if (total <= 0 || value <= 0) return 0;

  return (value / total) * 100;
};

export default getPercentage;
