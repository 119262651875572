import React from 'react';
import { Props } from '..';

const Duurzaamheidsprofiel: React.FCC<Props> = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-28.285 -21.66 163.004 160.829"
    width="1.3em"
    height="1.3em"
  >
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b5cf5c"
        d="M 45.69 0.041 C 48.643 -1.277 51.216 -1.708 53.896 -0.063 C 54.079 0.013 55.637 1.509 56.5 3.5 C 51.968 3.818 47.634 3.485 43.5 2.5 C 44.167 1.5 44.677 0.955 45.69 0.041 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b7d061"
        d="M 83.5,13.5 C 78.681,11.0986 74.681,7.76529 71.5,3.5C 75.076,1.27243 79.076,0.272427 83.5,0.5C 83.5,4.83333 83.5,9.16667 83.5,13.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#adcb4a"
        d="M 43.5,2.5 C 47.6344,3.48489 51.9677,3.81822 56.5,3.5C 57.3543,5.03503 57.3543,6.36837 56.5,7.5C 51.8333,7.5 47.1667,7.5 42.5,7.5C 42.2155,5.58533 42.5489,3.91866 43.5,2.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b2ce56"
        d="M 71.5,3.5 C 74.681,7.76529 78.681,11.0986 83.5,13.5C 83.2525,16.8539 82.2525,19.8539 80.5,22.5C 74.1897,18.1846 68.1897,13.5179 62.5,8.5C 65.0078,6.07642 68.0078,4.40975 71.5,3.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#a2c633"
        d="M 42.5,7.5 C 47.1667,7.5 51.8333,7.5 56.5,7.5C 56.0693,11.568 53.736,13.568 49.5,13.5C 45.3198,13.5853 42.9865,11.5853 42.5,7.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#adcb4b"
        d="M 62.5,8.5 C 68.1897,13.5179 74.1897,18.1846 80.5,22.5C 79.8305,25.8489 78.1639,28.5156 75.5,30.5C 68.8333,25.5 62.1667,20.5 55.5,15.5C 57.5,12.8333 59.8333,10.5 62.5,8.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#96be21"
        d="M 37.5,16.5 C 34.3566,18.2417 31.3566,20.2417 28.5,22.5C 28.5,19.8333 28.5,17.1667 28.5,14.5C 31.8291,14.2153 34.8291,14.8819 37.5,16.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#91b722"
        d="M 37.5,16.5 C 39.153,16.6596 40.4863,17.3263 41.5,18.5C 38.0711,22.2624 34.0711,25.2624 29.5,27.5C 28.6993,25.9582 28.366,24.2915 28.5,22.5C 31.3566,20.2417 34.3566,18.2417 37.5,16.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#8cb124"
        d="M 41.5,18.5 C 42.8717,19.5116 44.205,20.5116 45.5,21.5C 41.3713,25.8718 36.7046,29.5385 31.5,32.5C 30.5592,30.9505 29.8925,29.2838 29.5,27.5C 34.0711,25.2624 38.0711,22.2624 41.5,18.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#a6c83d"
        d="M 55.5,15.5 C 62.1667,20.5 68.8333,25.5 75.5,30.5C 74.1886,33.8114 71.8553,36.1448 68.5,37.5C 68.5858,36.5041 68.2524,35.6708 67.5,35C 62.2698,30.9231 56.9364,27.0897 51.5,23.5C 52.3084,20.5498 53.6418,17.8831 55.5,15.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#88ab26"
        d="M 45.5,21.5 C 46.4884,22.795 47.4884,24.1283 48.5,25.5C 44.1117,29.2214 39.4451,32.5547 34.5,35.5C 33.1667,34.8333 32.1667,33.8333 31.5,32.5C 36.7046,29.5385 41.3713,25.8718 45.5,21.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#a3c735"
        d="M 51.5,23.5 C 56.9364,27.0897 62.2698,30.9231 67.5,35C 68.2524,35.6708 68.5858,36.5041 68.5,37.5C 67.7095,38.4013 66.7095,39.0679 65.5,39.5C 61.375,36.5474 57.375,33.3807 53.5,30C 52.255,29.2086 50.9217,28.7086 49.5,28.5C 49.5,28.1667 49.5,27.8333 49.5,27.5C 50.1667,26.1667 50.8333,24.8333 51.5,23.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b7d062"
        d="M 14.5,28.5 C 11.5797,33.4269 8.24635,38.0935 4.5,42.5C -3.51848,30.0785 -0.185146,25.4118 14.5,28.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#83a527"
        d="M 48.5,25.5 C 48.8333,26.1667 49.1667,26.8333 49.5,27.5C 49.5,27.8333 49.5,28.1667 49.5,28.5C 49.5,29.5 49.5,30.5 49.5,31.5C 45.2934,33.4354 41.6267,36.1021 38.5,39.5C 36.8333,38.5 35.5,37.1667 34.5,35.5C 39.4451,32.5547 44.1117,29.2214 48.5,25.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#9ec42c"
        d="M 49.5,28.5 C 50.9217,28.7086 52.255,29.2086 53.5,30C 57.375,33.3807 61.375,36.5474 65.5,39.5C 61.1404,42.8362 56.1404,44.1695 50.5,43.5C 49.6403,39.5662 49.307,35.5662 49.5,31.5C 49.5,30.5 49.5,29.5 49.5,28.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#92b922"
        d="M 93.5,41.5 C 90.4756,37.4815 87.4756,33.4815 84.5,29.5C 88.7352,28.564 93.0685,28.2307 97.5,28.5C 98.138,33.5292 96.8047,37.8626 93.5,41.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#8cb124"
        d="M 84.5,29.5 C 87.4756,33.4815 90.4756,37.4815 93.5,41.5C 92.8763,43.754 91.543,45.4206 89.5,46.5C 89.6495,45.448 89.4828,44.448 89,43.5C 85.4681,39.6842 81.9681,36.0175 78.5,32.5C 80.1815,30.9925 82.1815,29.9925 84.5,29.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b2ce56"
        d="M 14.5,28.5 C 17.6727,29.0417 20.6727,30.0417 23.5,31.5C 19.2237,38.1091 14.2237,44.1091 8.5,49.5C 6.41203,47.6644 5.0787,45.3311 4.5,42.5C 8.24635,38.0935 11.5797,33.4269 14.5,28.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#aecc4e"
        d="M 23.5,31.5 C 25.3684,32.2632 27.0351,33.2632 28.5,34.5C 23.1667,40.8333 17.8333,47.1667 12.5,53.5C 10.8333,52.5 9.5,51.1667 8.5,49.5C 14.2237,44.1091 19.2237,38.1091 23.5,31.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#7e9e28"
        d="M 49.5,31.5 C 49.307,35.5662 49.6403,39.5662 50.5,43.5C 45.8667,43.8397 41.8667,42.5064 38.5,39.5C 41.6267,36.1021 45.2934,33.4354 49.5,31.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#88ab26"
        d="M 78.5,32.5 C 81.9681,36.0175 85.4681,39.6842 89,43.5C 89.4828,44.448 89.6495,45.448 89.5,46.5C 88.3951,47.6005 87.0618,48.2671 85.5,48.5C 82.9021,43.6559 79.5688,39.3225 75.5,35.5C 76.1667,34.1667 77.1667,33.1667 78.5,32.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#83a427"
        d="M 75.5,35.5 C 79.5688,39.3225 82.9021,43.6559 85.5,48.5C 85.5,48.8333 85.5,49.1667 85.5,49.5C 84.8333,49.5 84.1667,49.5 83.5,49.5C 81.1667,49.5 78.8333,49.5 76.5,49.5C 77.7387,48.788 78.2387,47.788 78,46.5C 75.9171,44.244 73.7504,42.244 71.5,40.5C 72.0798,38.2512 73.4131,36.5846 75.5,35.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#a9c943"
        d="M 28.5,34.5 C 30.5398,36.177 32.5398,37.8437 34.5,39.5C 29.7409,46.0151 24.7409,52.3485 19.5,58.5C 16.6298,57.5667 14.2964,55.9 12.5,53.5C 17.8333,47.1667 23.1667,40.8333 28.5,34.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#7e9f28"
        d="M 71.5,40.5 C 73.7504,42.244 75.9171,44.244 78,46.5C 78.2387,47.788 77.7387,48.788 76.5,49.5C 73.7628,49.4013 71.0961,49.7347 68.5,50.5C 68.0237,46.6215 69.0237,43.2882 71.5,40.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#adcb4b"
        d="M 104.5,42.5 C 105.833,42.5 107.167,42.5 108.5,42.5C 108.5,47.1667 108.5,51.8333 108.5,56.5C 107.167,56.5 105.833,56.5 104.5,56.5C 104.5,51.8333 104.5,47.1667 104.5,42.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#a3c737"
        d="M 34.5,39.5 C 36.5224,41.5313 38.1891,43.8646 39.5,46.5C 36.213,50.959 32.713,55.2923 29,59.5C 28.5304,60.5768 28.6971,61.5768 29.5,62.5C 28.5,62.5 27.5,62.5 26.5,62.5C 24.036,61.4335 21.7026,60.1002 19.5,58.5C 24.7409,52.3485 29.7409,46.0151 34.5,39.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#9fc52c"
        d="M 104.5,42.5 C 104.5,47.1667 104.5,51.8333 104.5,56.5C 100.432,56.0693 98.432,53.736 98.5,49.5C 98.4147,45.3198 100.415,42.9865 104.5,42.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b7d060"
        d="M 108.5,42.5 C 113.376,45.3612 114.542,49.3612 112,54.5C 110.887,55.305 109.721,55.9717 108.5,56.5C 108.5,51.8333 108.5,47.1667 108.5,42.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#9dc429"
        d="M 39.5,46.5 C 42.8362,50.8596 44.1695,55.8596 43.5,61.5C 39.5483,62.2721 35.5483,62.6054 31.5,62.5C 30.8333,62.5 30.1667,62.5 29.5,62.5C 28.6971,61.5768 28.5304,60.5768 29,59.5C 32.713,55.2923 36.213,50.959 39.5,46.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#9ec42c"
        d="M 76.5,49.5 C 78.8333,49.5 81.1667,49.5 83.5,49.5C 80.0776,54.6776 76.411,59.6776 72.5,64.5C 69.374,60.4439 68.0407,55.7772 68.5,50.5C 71.0961,49.7347 73.7628,49.4013 76.5,49.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#a4c739"
        d="M 83.5,49.5 C 84.1667,49.5 84.8333,49.5 85.5,49.5C 87.964,50.5665 90.2974,51.8998 92.5,53.5C 87.5,59.5 82.5,65.5 77.5,71.5C 74.6769,70.0077 73.0102,67.6743 72.5,64.5C 76.411,59.6776 80.0776,54.6776 83.5,49.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#a8c941"
        d="M 4.5,55.5 C 5.83333,55.5 7.16667,55.5 8.5,55.5C 8.5,60.1667 8.5,64.8333 8.5,69.5C 7.16667,69.5 5.83333,69.5 4.5,69.5C 4.5,64.8333 4.5,60.1667 4.5,55.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#aaca45"
        d="M 92.5,53.5 C 95.3702,54.4333 97.7036,56.1 99.5,58.5C 94.5,65.1667 89.5,71.8333 84.5,78.5C 81.8333,76.5 79.5,74.1667 77.5,71.5C 82.5,65.5 87.5,59.5 92.5,53.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b2ce57"
        d="M 4.5,55.5 C 4.5,60.1667 4.5,64.8333 4.5,69.5C 3.50838,69.6716 2.84171,69.3382 2.5,68.5C 2.5,64.5 2.5,60.5 2.5,56.5C 2.84171,55.6618 3.50838,55.3284 4.5,55.5 Z"
      />
    </g>
    <g transform="matrix(1.119565, 0, 0, 1.443489, -25.564663, -22.588951)">
      <path
        fill="#b8d163"
        d="M 2.5 56.5 C 2.5 60.5 2.5 64.5 2.5 68.5 C 1.5 67.833 0.5 67.167 -0.5 66.5 C -3.058 64.417 -3.088 60.588 -0.5 57.5 C 0.391 56.89 1.391 56.557 2.5 56.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#9ec429"
        d="M 8.5,55.5 C 11.8529,56.5124 13.5195,58.8458 13.5,62.5C 13.531,66.1219 11.8643,68.4552 8.5,69.5C 8.5,64.8333 8.5,60.1667 8.5,55.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#aecc4e"
        d="M 99.5,58.5 C 101.167,59.5 102.5,60.8333 103.5,62.5C 98.4821,68.1897 93.8154,74.1897 89.5,80.5C 87.7162,80.1075 86.0495,79.4408 84.5,78.5C 89.5,71.8333 94.5,65.1667 99.5,58.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#83a527"
        d="M 26.5,62.5 C 27.5,62.5 28.5,62.5 29.5,62.5C 30.1667,62.5 30.8333,62.5 31.5,62.5C 33.4354,66.7066 36.1021,70.3733 39.5,73.5C 38.5977,75.3407 37.2644,76.674 35.5,77.5C 32.1667,72.8333 28.8333,68.1667 25.5,63.5C 26.1667,63.5 26.5,63.1667 26.5,62.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#7e9e28"
        d="M 43.5,61.5 C 43.9313,66.207 42.598,70.207 39.5,73.5C 36.1021,70.3733 33.4354,66.7066 31.5,62.5C 35.5483,62.6054 39.5483,62.2721 43.5,61.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#88ab26"
        d="M 25.5,63.5 C 28.8333,68.1667 32.1667,72.8333 35.5,77.5C 34.7377,79.2624 33.4044,80.2624 31.5,80.5C 28.5263,75.8509 25.5263,71.1843 22.5,66.5C 23.1667,65.1667 24.1667,64.1667 25.5,63.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#8cb124"
        d="M 22.5,66.5 C 25.5263,71.1843 28.5263,75.8509 31.5,80.5C 30.4863,81.6737 29.153,82.3404 27.5,82.5C 24.9749,78.1038 21.9749,74.1038 18.5,70.5C 19.4023,68.6593 20.7356,67.326 22.5,66.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b3ce58"
        d="M 103.5,62.5 C 105.95,64.6291 107.617,67.2957 108.5,70.5C 105.085,74.5758 102.085,78.9092 99.5,83.5C 95.8379,83.2766 92.5046,82.2766 89.5,80.5C 93.8154,74.1897 98.4821,68.1897 103.5,62.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#7d9e28"
        d="M 61.5,68.5 C 66.207,68.0687 70.207,69.402 73.5,72.5C 70.5242,75.3021 67.3575,77.9688 64,80.5C 63.8019,78.6183 63.3019,76.9516 62.5,75.5C 62.702,73.0504 62.3686,70.717 61.5,68.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#91b722"
        d="M 18.5,70.5 C 21.9749,74.1038 24.9749,78.1038 27.5,82.5C 25.6208,83.3414 23.6208,83.6747 21.5,83.5C 19.984,80.4669 17.984,77.8003 15.5,75.5C 16.2632,73.6316 17.2632,71.9649 18.5,70.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#9ec42b"
        d="M 61.5,68.5 C 62.3686,70.717 62.702,73.0504 62.5,75.5C 62.5,78.8333 62.5,82.1667 62.5,85.5C 62.1667,85.5 61.8333,85.5 61.5,85.5C 56.4734,81.1467 51.14,77.1467 45.5,73.5C 50.0948,69.8592 55.4282,68.1926 61.5,68.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b7d061"
        d="M 108.5,70.5 C 110.575,74.4742 111.575,78.8075 111.5,83.5C 107.5,83.5 103.5,83.5 99.5,83.5C 102.085,78.9092 105.085,74.5758 108.5,70.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#83a527"
        d="M 73.5,72.5 C 75.1667,73.5 76.5,74.8333 77.5,76.5C 73.0935,80.2464 68.4269,83.5797 63.5,86.5C 63.5,85.8333 63.1667,85.5 62.5,85.5C 62.5,82.1667 62.5,78.8333 62.5,75.5C 63.3019,76.9516 63.8019,78.6183 64,80.5C 67.3575,77.9688 70.5242,75.3021 73.5,72.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#a4c739"
        d="M 45.5,73.5 C 51.14,77.1467 56.4734,81.1467 61.5,85.5C 61.0598,88.3798 60.0598,91.0465 58.5,93.5C 51.9458,88.7299 45.6125,83.7299 39.5,78.5C 40.865,76.1402 42.865,74.4735 45.5,73.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#96be21"
        d="M 15.5,75.5 C 17.984,77.8003 19.984,80.4669 21.5,83.5C 19.1667,83.5 16.8333,83.5 14.5,83.5C 14.5751,80.7938 14.9084,78.1272 15.5,75.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#88ab26"
        d="M 77.5,76.5 C 79.2624,77.2623 80.2624,78.5956 80.5,80.5C 75.8127,83.5308 71.1461,86.5308 66.5,89.5C 65.1667,88.8333 64.1667,87.8333 63.5,86.5C 68.4269,83.5797 73.0935,80.2464 77.5,76.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#8cb124"
        d="M 80.5,80.5 C 81.6737,81.5137 82.3404,82.847 82.5,84.5C 78.5,87.5 74.5,90.5 70.5,93.5C 68.8333,92.5 67.5,91.1667 66.5,89.5C 71.1461,86.5308 75.8127,83.5308 80.5,80.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#aaca45"
        d="M 39.5,78.5 C 45.6125,83.7299 51.9458,88.7299 58.5,93.5C 57.2993,95.9095 55.6326,97.9095 53.5,99.5C 46.8624,94.843 40.1957,90.1764 33.5,85.5C 34.7805,82.5526 36.7805,80.2193 39.5,78.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#92b922"
        d="M 82.5,84.5 C 83.436,88.7352 83.7693,93.0685 83.5,97.5C 78.5879,97.7497 74.2546,96.4163 70.5,93.5C 74.5,90.5 78.5,87.5 82.5,84.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b0cd52"
        d="M 33.5,85.5 C 40.1957,90.1764 46.8624,94.843 53.5,99.5C 51.4509,102.55 48.7843,104.883 45.5,106.5C 40.8908,101.529 35.5575,97.5293 29.5,94.5C 30.0749,91.0884 31.4082,88.0884 33.5,85.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b4cf5a"
        d="M 29.5,94.5 C 35.5575,97.5293 40.8908,101.529 45.5,106.5C 44.4863,107.674 43.153,108.34 41.5,108.5C 37.7465,104.452 33.4131,101.118 28.5,98.5C 28.4326,97.0413 28.7659,95.7079 29.5,94.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#9fc52e"
        d="M 69.5,103.5 C 64.8333,103.5 60.1667,103.5 55.5,103.5C 56.5124,100.147 58.8458,98.4805 62.5,98.5C 66.1219,98.469 68.4552,100.136 69.5,103.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b8d163"
        d="M 28.5,98.5 C 33.4131,101.118 37.7465,104.452 41.5,108.5C 37.8352,110.58 33.8352,111.413 29.5,111C 28.5363,106.934 28.203,102.767 28.5,98.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#aaca46"
        d="M 55.5,103.5 C 60.1667,103.5 64.8333,103.5 69.5,103.5C 69.5,104.833 69.5,106.167 69.5,107.5C 64.8333,107.5 60.1667,107.5 55.5,107.5C 55.5,106.167 55.5,104.833 55.5,103.5 Z"
      />
    </g>
    <g transform="matrix(1.421254, 0, 0, 1.399979, -26.318886, -20.076881)">
      <path
        fill="#b5cf5c"
        d="M 55.5 107.5 C 60.167 107.5 64.833 107.5 69.5 107.5 C 68.925 109.411 67.925 111.078 66.5 112.5 C 63.598 114.165 60.931 114.165 58.5 112.5 C 56.75 111.341 55.75 109.674 55.5 107.5 Z"
      />
    </g>
  </svg>
);

export default Duurzaamheidsprofiel;
