import actionLogger from '~/util/actionLogger';
import { assertNever } from '~/util/assertNever';
import {
  WizardReducer,
  StateStep,
  initialState,
} from '../../context/WizardContext';
import { convertStepToStateStep } from '../../utils/wizardStepsToStateSteps';
import updateStepInPlace from './utils/updateStepInPlace';
import { isNil, pluck } from 'ramda';

const wizardReducer: WizardReducer = (state, action) => {
  actionLogger(action);
  switch (action.type) {
    case 'next': {
      const currentStep = state.steps[state.currentStep];

      if (currentStep && currentStep.subSteps) {
        // When subStep === null but subSteps are defined we move to 0
        if (state.currentSubStep === null) {
          return {
            ...state,
            currentStep: state.currentStep,
            currentSubStep: 0,
          };
        }
        // We check if we need to transition to the next step or next sub step
        if (state.currentSubStep + 1 < currentStep.subSteps.length) {
          return {
            ...state,
            currentStep: state.currentStep,
            currentSubStep: state.currentSubStep + 1,
          };
        }
      }
      return {
        ...state,
        currentStep: state.currentStep + 1,
        currentSubStep: null,
      };
    }
    case 'previous': {
      // When we currentSubStep is available we should stay on the current step
      // because we're navigating sub steps
      const currentStepIndex =
        state.currentSubStep !== null
          ? state.currentStep
          : state.currentStep - 1;

      const currentStep = state.steps[currentStepIndex];

      if (currentStep && currentStep.subSteps) {
        // When subStep === null but subSteps are defined we move to last index
        if (state.currentSubStep === null) {
          const lastSubStepIndex = currentStep.subSteps.length - 1;

          return {
            ...state,
            currentStep: currentStepIndex,
            currentSubStep: lastSubStepIndex,
          };
        }

        // We check if we need to transition to the previous step or previous sub step
        if (state.currentSubStep - 1 >= 0) {
          return {
            ...state,
            currentStep: state.currentStep,
            currentSubStep: state.currentSubStep - 1,
          };
        }
      }

      return {
        ...state,
        currentStep: currentStepIndex,
        currentSubStep: null,
      };
    }
    case 'addStep': {
      const newSteps = [...state.steps];
      if (!isNil(action.payload.addAfter)) {
        const index = state.steps.findIndex(
          s => s.id === action.payload.addAfter,
        );

        if (index === -1) {
          newSteps.push(action.payload.step);
        } else {
          newSteps.splice(index + 1, 0, action.payload.step);
        }
      } else {
        newSteps.push(action.payload.step);
      }

      return { ...state, steps: newSteps };
    }
    case 'show': {
      return {
        ...state,
        ...action.payload,
        show: true,
        // header: action.payload.header,
        // steps: action.payload.steps ?? [],
        // id: action.payload.id ?? state.id,
      };
    }
    case 'hide': {
      return { ...state, show: false };
    }

    // Step actions
    case 'freeStep': {
      const _steps = updateStepInPlace(
        { isFree: true, isTouched: true },
        action.payload.step,
        state.steps,
      );

      const _outputMap = {
        ...state.outputMap,
        [action.payload.step.id]: action.payload.output,
      };

      return { ...state, steps: _steps, outputMap: _outputMap };
    }

    case 'lockStep': {
      const _steps = updateStepInPlace(
        { isFree: false, isTouched: true },
        action.payload.step,
        state.steps,
      );

      const _outputMap = {
        ...state.outputMap,
        [action.payload.step.id]: action.payload.output,
      };

      return { ...state, steps: _steps, outputMap: _outputMap };
    }

    case 'freeGoBack': {
      const _steps = updateStepInPlace(
        { canGoBack: true, isTouched: true },
        action.payload.step,
        state.steps,
      );

      return { ...state, steps: _steps };
    }

    case 'lockGoBack': {
      const _steps = updateStepInPlace(
        { canGoBack: false, isTouched: true },
        action.payload.step,
        state.steps,
      );

      return { ...state, steps: _steps };
    }

    case 'freeSkip': {
      const _steps = updateStepInPlace(
        { skippable: true, isTouched: true },
        action.payload.step,
        state.steps,
      );

      return { ...state, steps: _steps };
    }

    case 'lockSkip': {
      const _steps = updateStepInPlace(
        { skippable: false, isTouched: true },
        action.payload.step,
        state.steps,
      );

      return { ...state, steps: _steps };
    }

    case 'addSubStep': {
      const currentSubSteps =
        action.payload.step.subSteps ?? ([] as Array<StateStep>);

      // If we already have the step in sub step array, don't add it.
      if (pluck('id', currentSubSteps).includes(action.payload.subStep.id))
        return state;

      const newSubStep = convertStepToStateStep(action.payload.subStep);
      const _steps = updateStepInPlace(
        { subSteps: currentSubSteps.concat(newSubStep) },
        action.payload.step,
        state.steps,
      );
      return { ...state, steps: _steps };
    }

    case 'removeSubStep': {
      const currentSubSteps =
        action.payload.step.subSteps ?? ([] as Array<StateStep>);
      const subStepIds = pluck('id', currentSubSteps);

      // If the step is not in the list, we don't need to proceed so we return early.
      if (!subStepIds.includes(action.payload.subStep.id)) {
        return state;
      }

      const _steps = updateStepInPlace(
        {
          subSteps: currentSubSteps.filter(
            ({ id }) => id !== action.payload.subStep.id,
          ),
        },
        action.payload.step,
        state.steps,
      );
      return { ...state, steps: _steps };
    }

    case 'updateStep': {
      const _steps = updateStepInPlace(
        { options: action.payload.options, isTouched: true },
        action.payload.step,
        state.steps,
      );

      return { ...state, steps: _steps };
    }

    // Utility actions
    case 'restoreState': {
      return action.payload;
    }
    case 'clear': {
      return initialState;
    }

    case 'setOutput': {
      return {
        ...state,
        outputMap: action.payload,
      };
    }

    default: {
      return assertNever(action, 'wizardReducer');
    }
  }
};

export default wizardReducer;
