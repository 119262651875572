import React, { useCallback, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css, useTheme } from 'styled-components';
import getBackgroundColor from '../../utils/getBackgroundColor';
import { getSpinAnimation } from '~/styles/animations';
import { sizes, type Size } from '../../constants';

export type Props = {
  size: Size;
  containerWidth: number;
  currentState: 'checked' | 'unchecked' | 'loading';
  loading: boolean | undefined;
};

const Circle: React.FCC<Props> = ({
  size,
  loading,
  containerWidth,
  currentState,
}) => {
  const theme = useTheme();

  const circleWidth = theme.remToPxRaw(theme.space(sizes[size].circle));
  const horizontalPadding =
    theme.remToPxRaw(theme.space(sizes[size].container.padding)) / 2;

  const positionLeft = useCallback(() => {
    switch (currentState) {
      case 'loading':
        return containerWidth / 2 - circleWidth / 2;
      case 'checked':
        return containerWidth - circleWidth - horizontalPadding;
      case 'unchecked':
        return horizontalPadding;
      default:
        return 0;
    }
  }, [circleWidth, horizontalPadding, currentState, containerWidth])();

  const circleRef = useRef(null);
  const style = useSpring({
    left: `${positionLeft}px`,
  });

  return (
    <Container style={style} $size={size} $loading={loading} ref={circleRef} />
  );
};

const Container = styled(animated.div)<{ $size: string; $loading?: boolean }>(
  ({ theme, $size, $loading }) => css`
    top: calc(50% - calc(${theme.space(sizes[$size].circle)} / 2));
    width: ${theme.space(sizes[$size].circle)};
    height: ${theme.space(sizes[$size].circle)};
    background: ${getBackgroundColor(theme, 'circle')};
    border-radius: ${theme.space(sizes[$size].circle)};
    position: absolute;

    ${$loading &&
    css`
      border: 2px solid ${theme.color('tertiary', 'translucent')};
      box-sizing: border-box;
      border-top-color: ${theme.color('accent', 'light')};
      width: ${theme.space(sizes[$size].circle)};
      height: ${theme.space(sizes[$size].circle)};
      ${getSpinAnimation()};
    `}
  `,
);

export default Circle;
