import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import {
  allFilesAccepted,
  isEventWithFiles,
  _preventDefault,
} from '~/util/event';

export type Props = {
  children: React.ReactNode;
  handleDrop: (value: any) => void;
  uploading: boolean;
  acceptedFileTypes: Array<string>;
  style?: React.CSSProperties;
};

const text = {
  drop: 'Neerzetten',
  invalidFileError:
    'Bestandstype is niet geldig, probeer een JPG, JPEG, PNG, GIF of SVG.',
};

const DragAndDropContainer: React.FCC<Props> = ({
  children,
  handleDrop,
  uploading = false,
  acceptedFileTypes,
  ...rest
}) => {
  const dropRef = useRef();
  const [dragging, setDragging] = useState<boolean>(false);
  const dragCounter = useRef<number>(0);
  const addToast = useAddToast();

  const handleDragIn = useCallback(
    e => {
      _preventDefault(e);
      dragCounter.current++;

      if (e.dataTransfer && isEventWithFiles(e) && !uploading) {
        setDragging(true);
      }
    },
    [uploading],
  );

  const handleDragOut = useCallback(e => {
    _preventDefault(e);
    if (dragCounter.current > 0) {
      dragCounter.current = 0;
      return;
    }
    setDragging(false);
  }, []);

  const _handleDrop = useCallback(
    e => {
      _preventDefault(e);
      setDragging(false);
      const files = e.dataTransfer.files;

      if (
        files &&
        files.length > 0 &&
        allFilesAccepted(files, acceptedFileTypes)
      ) {
        handleDrop(files);
        dragCounter.current = 0;
        return;
      }

      addToast([formatToastMessage(text.invalidFileError, 'danger')]);
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDrop, acceptedFileTypes],
  );

  return (
    <Container
      ref={dropRef.current}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={e => _preventDefault(e)}
      onDrop={_handleDrop}
      data-testid="drag-drop-container"
      {...rest}
    >
      {dragging && (
        <OnDragContainer>
          <InsideContainer />
        </OnDragContainer>
      )}
      {children}
    </Container>
  );
};

const Container = styled.div<{}>`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const OnDragContainer = styled.div<{}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${({ theme }) => css`
    background-color: ${theme.color('primary', 'translucent')};
    border: ${theme.getTokens().border.width.s} dashed
      ${theme.color('tertiary', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};
    z-index: ${theme.z('top')};
  `}
`;

const InsideContainer = styled.div<{}>`
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.color('info', 'dark')};
    font-size: ${theme.fs('xl')};
  `}
`;
export default DragAndDropContainer;
