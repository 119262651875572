import React from 'react';
import styled, { css } from 'styled-components';
import { ErrorBoundary } from '@sentry/react';
import { clone, lensPath, set } from 'ramda';

import TEST_ID from './index.testid';
import FilterGroupV2, {
  FilterGroupV2Type,
} from '~/components/organism/Filters/components/FilterGroupV2';
import { ConnectorOperator, ContactFilters__Input } from '~/graphql/types';

import Divider from '~/components/atom/Divider';
import TextButton from '~/components/atom/TextButton';
import { generateSubjectMap } from '~/components/organism/Filters/util/contactFilter/getSubject';
import { generateCommandMap } from '~/components/organism/Filters/util/contactFilter/getCommand';
import { generateTopicMap } from '~/components/organism/Filters/util/contactFilter/getTopic';
import { generateTypeMap } from '~/components/organism/Filters/util/contactFilter/getType';
import { generateOptionTypeMap } from '~/components/organism/Filters/util/contactFilter/getOptionType';
import { FilterOptions } from '~/hooks/useContactFilterOptionsV2';

import BrokenFilterBar from './components/BrokenFilterBar';
import { Heading4, Variant } from '~/components/atom/Typography';
import Dropdown from '~/components/molecule/Dropdown';
import {
  ButtonsContainer,
  FiltersContainer,
  GroupContainer,
  LineElement,
} from './commonStyles';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  filtersTitle: 'Filters',
  addGroup: 'Groep toevoegen',
  deleteFilters: 'Alle filters wissen',
};

type Props = {
  filters: ContactFilters__Input;
  onAddFilterGroup: () => void;
  onChange: (newFilters: ContactFilters__Input) => void;
  loading?: boolean;
  filterOptions: FilterOptions;
};

export const emptyFilterGroupV2 = (): { Node: FilterGroupV2Type } => ({
  Node: {
    connector: ConnectorOperator.And,
    negate: false,
    nodes: [],
  },
});

const operatorOptions = [
  {
    label: 'en',
    payload: ConnectorOperator.And,
    key: ConnectorOperator.And,
  },
  {
    label: 'of',
    payload: ConnectorOperator.Or,
    key: ConnectorOperator.Or,
  },
];

const ContactListFilterBar: React.FCC<Props> = ({
  filters,
  onAddFilterGroup,
  filterOptions,
  onChange,
}) => {
  const shouldShowGroupConnector = filters.nodes.length > 1 ? true : false;
  const { subjects, topics, commands, types, optionTypes } = filterOptions;

  const subjectMap = generateSubjectMap(subjects);

  const { commandMap, typeIdToCommandsMap } = generateCommandMap(commands);
  const { typeMap } = generateTypeMap(types);
  const { optionTypeMap } = generateOptionTypeMap(optionTypes);
  const topicMap = generateTopicMap(topics);

  const hasEmptyFilterGroup =
    filters.nodes.find(
      node => node.Node == null || node.Node.nodes.length == 0,
    ) != null;

  return (
    <ErrorBoundary
      fallback={boundaryProps => (
        <BrokenFilterBar
          filters={filters}
          onChange={onChange}
          boundaryProps={boundaryProps}
        />
      )}
    >
      <JustificationContainer
        dataTestId={TEST_ID.CONTAINER}
        direction="column"
        width="100%"
        backgroundColor={{ group: 'white' }}
        padding={['s', 'base', null, 'base']}
        border={{
          radius: 'm',
          width: 's',
          color: { group: 'tertiary', variant: 'light' },
        }}
        boxShadow="card"
      >
        <JustificationContainer width="100%" align="center">
          <Heading4 margin={[null]} variant={Variant.primary}>
            {text.filtersTitle}
          </Heading4>
          <ButtonsContainer>
            <TextButton
              disabled={hasEmptyFilterGroup ? true : false}
              label={text.addGroup}
              icon="plus"
              onClick={e => {
                e.currentTarget.blur();
                onAddFilterGroup();
              }}
            />
            <TextButton
              label={text.deleteFilters}
              icon="delete"
              onClick={e => {
                e.currentTarget.blur();
                return onChange({ ...filters, nodes: [emptyFilterGroupV2()] });
              }}
            />
          </ButtonsContainer>
        </JustificationContainer>
        <Divider margin={['xxxs', null]} />

        <div style={{ position: 'relative' }}>
          <FiltersContainer>
            {filters.nodes.map(
              (node, idx) =>
                node.Node && (
                  <GroupContainer key={JSON.stringify({ ...node, __idx: idx })}>
                    <FilterGroupV2
                      filterGroupIndex={idx}
                      filterGroup={node.Node}
                      subjectMap={subjectMap}
                      commandMap={commandMap}
                      topicMap={topicMap}
                      typeMap={typeMap}
                      optionTypeMap={optionTypeMap}
                      typeIdToCommandsMap={typeIdToCommandsMap}
                      filterOptions={filterOptions}
                      onConfirm={newLeafs => {
                        const clonedNodes = clone(filters.nodes);
                        // If there is an empty filter group except the first one, remove it
                        if (idx != 0 && newLeafs.nodes.length === 0) {
                          clonedNodes.splice(idx, 1);
                          return onChange({
                            ...filters,
                            nodes: clonedNodes,
                          });
                        }
                        return onChange({
                          ...filters,
                          nodes: set(
                            lensPath([idx]),
                            { Node: newLeafs },
                            clonedNodes,
                          ),
                        });
                      }}
                    />
                    {shouldShowGroupConnector && <LineElement />}
                  </GroupContainer>
                ),
            )}
          </FiltersContainer>
          {shouldShowGroupConnector && (
            <GroupConnectorContainer>
              <Dropdown
                appearance="outline"
                size="small"
                options={operatorOptions}
                selectedOptionIdx={operatorOptions.findIndex(
                  option => option.payload === filters.connector,
                )}
                onChange={selectedOption =>
                  onChange({
                    ...filters,
                    connector: selectedOption.option.payload,
                  })
                }
              />
            </GroupConnectorContainer>
          )}
        </div>
      </JustificationContainer>
    </ErrorBoundary>
  );
};

const GroupConnectorContainer = styled.div<{}>(
  ({ theme }) => css`
    position: absolute;
    top: calc(50% - 1.5rem);
    right: 0;
    max-width: 5rem;
    width: 100%;
    background: ${theme.color('white')};
  `,
);

export default ContactListFilterBar;
