import { isNil, pluck, prop, uniqBy } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddEventDiagram from '~/components/molecule/Diagrams/AddZapierEvent';
import Link from '~/components/molecule/Link';

import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import { Body, Variant, Heading4 } from '~/components/atom/Typography';
import type {
  OutputFieldList,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import type { OutputMap } from '~/components/organism/WizardSteps';
import {
  useInsertZapierEventMutation,
  useGetZapierEventsLazyQuery,
  useUpdateAppStatusMutation,
  AppStatus_Zapier_Metadata,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useApp from '~/hooks/useApp';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import formatToastMessage from '~/util/formatToastMessage';
import zapierTriggerTemplates from '../AddZapierTrigger/components/TriggerTemplatesContainer/zapierTriggerTemplates';
import EventTemplatesContainer from './components/EventTemplatesContainer';
import type { EventTemplates } from './components/EventTemplatesContainer/eventTemplates';
import cleanedFilename from '~/util/cleanedFilename';
import useErrorReporter from '~/hooks/useErrorReporter';

const text = {
  header: 'Inkomende koppelingen',
  body: 'Om gegevens van Zapier naar DatHuis te sturen, dien je een inkomende koppeling in te stellen. Deze gegevens worden in het contact opgeslagen en worden gebruikt in een flow of doorgestuurd naar jouw CRM.',
  exampleHeader:
    'Een voorbeeld hoe je gegevens via een Zap in DatHuis ontvangt',

  chooseConnectionHeader: 'Kies inkomende koppelingen',
  chooseConnectionBody: (
    <>
      Met een inkomende koppeling bepaal je welke gegevens je kan ontvangen
      vanuit Zapier. We hebben we een aantal inkomende koppelingen voor je
      klaargezet. Deze zijn gemaakt voor de meest voorkomende toepassingen. Na
      het afronden van deze wizard is het mogelijk om zelf een koppeling te
      maken.
    </>
  ),
  tip: (
    <>
      Lees hoe je de koppeling moet instellen in onze{' '}
      <Link to="https://help.dathuis.nl/">kennisbank</Link>. Kom je er niet uit?
      &nbsp;
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
};

export const id = 'AddZapierEvent';
export const title = text.header;

const eventsOutputCategory = 'Inkomende koppelingen';

export type OutputType = {
  type: typeof id;
  selectedEvents: OutputFieldList<EventTemplates[0]>;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const reporter = useErrorReporter();
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();

  const [selectedEvents, setSelectedEvents] = useState<EventTemplates>(
    'selectedEvents' in outputMap[id] &&
      outputMap[id].selectedEvents.value.length
      ? outputMap[id].selectedEvents.value ?? []
      : [],
  );

  const { app } = useApp('AppStatus_Zapier');

  const zapierMetadata = (
    app?.appStatus && 'metadata' in app?.appStatus
      ? app?.appStatus.metadata
      : undefined
  ) as AppStatus_Zapier_Metadata | undefined;

  const previouslyEnabledTemplates = useMemo(
    () => zapierMetadata?.setup.addEvents ?? [],
    [zapierMetadata],
  );

  const [updateAppStatus] = useUpdateAppStatusMutation();
  const [insertZapierEvent] = useInsertZapierEventMutation();
  const [getEventsLazy] = useGetZapierEventsLazyQuery({
    variables: {
      accountId,
    },
  });

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      if ('selectedEvents' in outputMap.AddZapierEvent) {
        const data = await Promise.all([
          ...outputMap.AddZapierEvent.selectedEvents.value.map(
            ({ name, fields, insertedId }, index) => {
              if (!isNil(insertedId)) {
                return Promise.resolve(); // resolve with empty promise;
              }
              return insertZapierEvent({
                variables: {
                  accountId,
                  name: name,
                  fields: fields,
                },
              }).then(({ data, errors }) => {
                if (errors && errors.length > 0) {
                  reporter.captureException(
                    new Error(
                      `${cleanedFilename(
                        __filename,
                      )} | An error occured in insertZapierEvent`,
                    ),
                  );
                  addToast([
                    formatToastMessage(
                      `Het toevoegen van "${name}" inkomende koppeling is mislukt.`,
                      'danger',
                    ),
                  ]);
                }

                const _updatedEvents = [
                  ...('selectedEvents' in outputMap.AddZapierEvent
                    ? outputMap.AddZapierEvent.selectedEvents.value
                    : []),
                ];
                _updatedEvents[index].insertedId = data?.insertZapierEvent.id;
                setSelectedEvents(_updatedEvents);

                return { data };
              });
            },
          ),
          updateAppStatus({
            variables: {
              accountId,
              update: {
                AppStatus_Zapier: {
                  enabled: true,
                  metadata: {
                    setup: {
                      completed: false,
                      addEvents: pluck(
                        'id',
                        outputMap.AddZapierEvent.selectedEvents.value,
                      ),
                    },
                  },
                },
              },
            },
          }).catch(() => {
            reporter.captureException(
              new Error(
                `${cleanedFilename(
                  __filename,
                )} | An error occured in updateAppStatus after insertZapierEvent`,
              ),
            );
          }),
        ]);

        const successResult = data.filter(
          x => x && !isNil(x.data) && 'insertZapierEvent' in x.data,
        );

        if (successResult.length > 0) {
          addToast([
            formatToastMessage(
              `${successResult.length} inkomende koppeling(en) succesvol toegevoegd.`,
              'success',
            ),
          ]);
        }
      }
      // Update internal Apollo cache with newly added events
      void getEventsLazy();

      return outputMap[id];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, addToast, getEventsLazy, insertZapierEvent, updateAppStatus],
  );

  const stepOptions = useMemo(() => ({ onBeforeNext }), [onBeforeNext]);
  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    api.free({
      type: 'AddZapierEvent',
      selectedEvents: {
        category: eventsOutputCategory,
        type: 'list',
        value: selectedEvents.map(event => ({
          ...event,
          label: event.name,
        })),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvents]);

  useEffect(() => {
    setSelectedEvents(prev => {
      const _previouslyEnabledTemplates = zapierTriggerTemplates.filter(
        ({ id }) => previouslyEnabledTemplates.includes(id),
      );

      return uniqBy(prop('id'), [...prev, ..._previouslyEnabledTemplates]);
    });
  }, [previouslyEnabledTemplates]);

  return (
    <>
      <Body margin={[null]}>{text.body}</Body>

      <Heading4 margin={['xl', null, 'm', null]} variant={Variant.primary}>
        {text.exampleHeader}
      </Heading4>

      <AddEventDiagram />

      <Heading4 margin={['xl', null, 'm', null]} variant={Variant.primary}>
        {text.chooseConnectionHeader}
      </Heading4>

      <Body>{text.chooseConnectionBody}</Body>

      <EventTemplatesContainer
        selectedTemplates={selectedEvents}
        onChange={events => setSelectedEvents(events)}
      />
      <TipBanner id="add-event-tip">{text.tip}</TipBanner>
    </>
  );
};

export default {
  id,
  title,
};
