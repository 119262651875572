import React from 'react';
import styled, { css } from 'styled-components';
import Divider from '~/components/atom/Divider';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { WorkingAreaType } from '../../../MapsContainer/components/Draw';
import TextButton from '~/components/atom/TextButton';

const text = {
  inclusive: 'Werkgebied toevoegen',
  exclusive: 'Werkgebied uitsluiten',
};

export type Props = {
  onStartSelection: (type: WorkingAreaType) => void;
};

const AddAreaButtons: React.FCC<Props> = ({ onStartSelection }) => (
  <JustificationContainer align="stretch">
    <StyledTextButton
      label={text.inclusive}
      onClick={() => onStartSelection('inclusive')}
      appearance="success"
      icon="shield"
      size="medium"
      padding={['m']}
    />
    <StyledDividerContainer vertical margin={[null]} />
    <StyledTextButton
      label={text.exclusive}
      onClick={() => onStartSelection('exclusive')}
      icon="shield-off"
      appearance="danger"
      size="medium"
      padding={['m']}
    />
  </JustificationContainer>
);

const StyledTextButton = styled(TextButton)<{
  appearance: 'danger' | 'success';
}>(
  ({ appearance, theme }) => css`
    &:hover {
      border-radius: unset;
      background-color: ${theme.color(appearance, 'translucent')};
    }
  `,
);

const StyledDividerContainer = styled(Divider)`
  // allow it to get its height from stretching
  height: auto;
`;

export default AddAreaButtons;
