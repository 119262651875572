import { BillingDetails_New } from '~/graphql/types';
import { BillingFormData } from '../convertToFormData';
import cleanedFilename from '~/util/cleanedFilename';

const convertToBillingDetails = (
  formData: BillingFormData,
): BillingDetails_New => {
  const {
    name,
    email,
    addressLine1,
    addressLine2,
    addressPostcode,
    addressCity,
  } = formData;

  if (name == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Validation should have caught that name == null`,
    );
  }
  if (email == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Validation should have caught that email == null`,
    );
  }

  return {
    name,
    email,
    address: {
      line1: addressLine1,
      line2: addressLine2,
      postcode: addressPostcode,
      city: addressCity,
    },
  };
};

export default convertToBillingDetails;
