import React from 'react';
import { Props } from '..';

const NumberedList: React.FCC<Props> = ({
  color = 'currentColor',
  ...props
}) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 4.5H21"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 12H21"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 19.5H21"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.90002 2V7"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.40005 10.7C3.40005 10.7 3.70005 9.5 4.80005 9.5C5.80005 9.5 6.20005 10 6.20005 11C6.20005 11.8 3.30005 14.5 3.30005 14.5H6.60005"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.6 17.6C4 17.1 4.5 17 5 17C6.1 17.1 6.5 17.8 6.5 18.3C6.6 19.5 5.3 19.4 5.3 19.4C5.3 19.4 6.6 19.4 6.6 20.6C6.5 21.2 5.8 22 5 22C4.4 22 3.9 21.7 3.5 21.2"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NumberedList;
