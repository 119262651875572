import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';

const text = {
  checkAvailability: 'Beschikbaarheid controleren',
  available: 'Beschikbaar',
  notAvailable: 'Niet beschikbaar',
};

/**
 * Label that shows the availability of the url.
 */
const Indicator = styled.span<{ appearance: 'success' | 'danger' | 'primary' }>(
  ({ theme, appearance }) => css`
    background-color: ${theme.color(appearance)};
    color: ${theme.color('white')};
    padding: ${theme.space('xxs')};
    transform: translateY(-${theme.space('xxs')});
    border-radius: ${theme.getTokens().border.radius.m};
    display: flex;
    align-items: center;

    & > span {
      margin-right: ${theme.space('xxs')};
    }
  `,
);

/** Expected as an array in Input component. This shall be refactored in the future. */
export const URL_STATUS_INDICATORS = [
  <Indicator key="checking" appearance={'primary'}>
    <Icon name="spinner" /> {text.checkAvailability}
  </Indicator>,
  <Indicator key="available" appearance={'success'}>
    <Icon name="check" strokeWidth={2.5} /> {text.available}
  </Indicator>,
  <Indicator key="taken" appearance={'danger'}>
    <Icon name="close" strokeWidth={2.5} /> {text.notAvailable}
  </Indicator>,
];
