import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import { animated, useSpring } from 'react-spring';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import Catalog from '~/Catalog';

import TEST_ID from './index.testid';

import StandardInvoices from './components/StandardInvoices';
import CreditInvoices from './components/CreditInvoices';
import { Heading3 } from '~/components/atom/Typography';
import { ANIMATION_CONFIG } from '~/styles/constants';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import createPageTitle from '~/util/createPageTitle';

const INVOICES_LIST_LIMIT = 6;

const text = {
  pageTitle: Catalog.invoicesLabel,
  title: Catalog.invoicesLabel,
  creditTitle: 'Creditfacturen',
};
const Invoices: React.FC<RouteComponentProps> = () => {
  const animation = useSpring(ANIMATION_CONFIG);

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{createPageTitle(text.pageTitle)}</title>
      </MetaTags>
      <animated.section style={animation} data-testid={TEST_ID.CONTAINER}>
        <Heading3>{text.title}</Heading3>
        <StandardInvoices limit={INVOICES_LIST_LIMIT} />
        <Heading3>{text.creditTitle}</Heading3>
        <CreditInvoices limit={INVOICES_LIST_LIMIT} />
      </animated.section>
    </ContentContainerDefault>
  );
};

export default Invoices;
