import { filter, isEmpty, propEq } from 'ramda';
import { OfficeRelationship } from '~/graphql/types';
import useSessionHydration from '../useSessionHydration';

/** There cannot be officeless users so there will always be an office relationship for a given user */

type Args = {
  officeId: string;
  userId: string;
};
const useUserRelationToOffice = ({
  officeId,
  userId,
}: Args): OfficeRelationship | null => {
  const [{ relations }] = useSessionHydration();

  if (isEmpty(officeId) || isEmpty(userId) || isEmpty(relations)) return null;

  const isOfficeRelationship = propEq('OfficeRelationship', '__typename');
  const officeRelations = filter(
    isOfficeRelationship,
    relations,
  ) as Array<OfficeRelationship>;

  const filteredRelations = officeRelations.filter(
    relation => relation.userId === userId && relation.officeId === officeId,
  );

  return filteredRelations[0] ?? null;
};

export default useUserRelationToOffice;
