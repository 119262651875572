import { sortBy, groupBy, reverse } from 'ramda';
import { formattedDateWithFullMonth } from '~/util/date';
import type { ActivityV2 } from '~/components/template/EventTimelineV2/types';
import type { GroupedActivities } from '../../state';
import type { TaskListType } from '../../types';

const getSortingDate = (
  task: ActivityV2,
  taskListType: TaskListType,
): string => {
  const getDate = (dateField: string) => task[dateField] || task.sortingDate;

  const dueDate = getDate('dueDate');
  const closedDate = getDate('closedDate');
  const deletedDate = getDate('deletedDate');

  return taskListType === 'Open'
    ? dueDate
    : taskListType === 'Closed'
      ? closedDate
      : deletedDate;
};

const sortTasks = (
  tasks: Array<ActivityV2>,
  taskListType: TaskListType,
): Array<ActivityV2> =>
  sortBy(task => getSortingDate(task, taskListType), tasks);

const groupTasks = (
  tasks: Array<ActivityV2>,
  taskListType: TaskListType,
): GroupedActivities =>
  groupBy(
    task =>
      formattedDateWithFullMonth(new Date(getSortingDate(task, taskListType))),
    taskListType === 'Closed' || taskListType === 'Deleted'
      ? reverse(tasks)
      : tasks,
  );

/**
 *
 * @param (Array of ActivityV2) - tasks
 * @param (TaskListType) - selectedListType
 * @returns (GroupedActivities)
 */
const sortAndGroupTasks = (
  tasks: Array<ActivityV2>,
  selectedListType: TaskListType,
): GroupedActivities => {
  const sortedTasks = sortTasks(tasks, selectedListType);

  return groupTasks(sortedTasks, selectedListType);
};

export default sortAndGroupTasks;
