import React from 'react';

import { FieldProps } from 'react-final-form';

import Field from './Field';
import passthrough from '~/util/passthroughFunction';

export default ({ name, parse, ...rest }: FieldProps<any, any>) => (
  <Field
    name={name}
    {...rest}
    parse={parse ? passthrough(parseEmail, parse) : parseEmail}
    type="email"
  />
);

const parseEmail = (value: string | null): string | null => {
  if (!value) return null;

  return value;
};
