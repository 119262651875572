import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import MasterDetail from '~/components/template/MasterDetail';
import TEST_ID_MASTER_DETAIL from '~/components/template/MasterDetail/index.testid';
import Connections from './components/Connections';
import Synchronization from './components/Synchronization';
import { useGetAppStatusRealworksQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import NotFound from '~/components/page/404';
import Toolbar from '~/components/molecule/Toolbar';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body } from '~/components/atom/Typography';
import StatusIndicator from '~/components/atom/StatusIndicator';
import TEST_ID from './index.testid';
import useApp from '~/hooks/useApp';
import createPageTitle from '~/util/createPageTitle';

type Props = RouteComponentProps & {};

// const AGREED_POLLING_INTERVAL = 2000;

const Realworks: React.FCC<Props> = ({ uri = '' }) => {
  const { id: accountId } = useCurrentAccount();
  const { data, loading, refetch, updateQuery } = useGetAppStatusRealworksQuery(
    {
      variables: {
        accountId,
      },
    },
  );

  const { app } = useApp('AppStatus_Realworks');
  if (loading) return null;
  if (!data || !app?.appStatus.enabled) return <NotFound />;

  const isSyncActive = data.getAppStatusRealworks.relatieSync.enabled;

  return (
    <>
      <MetaTags>
        <title>{createPageTitle('Realworks')}</title>
      </MetaTags>
      <ContentContainerDefault
        breadcrumbs={[
          {
            to: '/-/apps',
            label: 'Apps',
          },
          { label: 'Realworks' },
        ]}
      >
        <JustificationContainer
          justification="center"
          align="center"
          margin={[null, null, 'l', null]}
        >
          <Toolbar>
            <div />
            <JustificationContainer
              justification="end"
              align="center"
              margin={['base']}
            >
              <Body margin={[null]} dataTestId={TEST_ID.SYNC_STATUS}>
                {isSyncActive
                  ? 'Synchronisatie actief'
                  : 'Synchronisatie nog niet actief'}
              </Body>
              &nbsp; &nbsp;
              <StatusIndicator status={isSyncActive ? 'active' : 'inactive'} />
            </JustificationContainer>
          </Toolbar>
        </JustificationContainer>
        <MasterDetail
          basePath={uri}
          dataTestId={TEST_ID_MASTER_DETAIL.CONTAINER}
          navbar={[
            {
              type: 'link',
              to: '',
              icon: 'link',
              name: 'Koppelingen',
            },
            {
              type: 'link',
              to: '/synchronization',
              icon: 'reload',
              name: 'Synchronisatie',
            },
          ]}
        >
          <Connections
            path="/*"
            appStatus={data.getAppStatusRealworks}
            refreshAppStatus={refetch}
          />
          <Synchronization
            path="/synchronization"
            appStatus={data.getAppStatusRealworks}
            updateQuery={updateQuery}
          />
        </MasterDetail>
      </ContentContainerDefault>
    </>
  );
};

export default Realworks;
