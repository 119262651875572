import {
  AppStatus_Trustoo__Input,
  AppStatus__Input,
  InsertedTemplateId,
  StellaxAiReportType,
  WidgetDescriptionItem,
  WidgetSettingsAppConfigurationItem__Input,
  type AppStatus_StellaxAi__Input,
} from '~/graphql/types';
import getMetadataForAppStatus from '../getMetadataForAppStatus';
import { ExtendedAppConfig } from '~/hooks/useApps';
import type { OutputMap } from '~/components/organism/WizardSteps';
import type { OutputType as OnboardingStellaxAiSelectProductOutput } from '~/components/organism/WizardSteps/OnboardingWizardSteps/OnboardingStellaxAiSelectProduct';

const widgetItemsToInput = (
  items: Array<WidgetDescriptionItem>,
): Array<WidgetSettingsAppConfigurationItem__Input> =>
  items.map(item => ({ id: item.id, enabled: true }));

type Args = {
  app: ExtendedAppConfig;
  widgetItems: Array<WidgetDescriptionItem>;
  addedFlows?: Array<InsertedTemplateId>;
  outputMap: OutputMap;
};

const getUpdateForAppStatus = ({
  app,
  widgetItems,
  addedFlows,
  outputMap,
}: Args): AppStatus__Input => {
  if (app.__typename === 'AppStatus_Trustoo') {
    const update: AppStatus_Trustoo__Input = {
      enabled: true,
      tokens: [],
      widgetAppConfigurationItems: widgetItemsToInput(widgetItems ?? []),
      metadata: getMetadataForAppStatus({
        appStatus: app.appStatus,
        addedFlows: addedFlows ?? [],
      }),
    };
    return { AppStatus_Trustoo: update };
  }

  if (app.__typename === 'AppStatus_StellaxAi') {
    const output =
      outputMap.OnboardingStellaxAiSelectProduct as OnboardingStellaxAiSelectProductOutput;

    const selectedProduct = output.reportType.value ?? StellaxAiReportType.B;

    const update: AppStatus_StellaxAi__Input = {
      enabled: true,
      reportType: selectedProduct,
      metadata: getMetadataForAppStatus({
        appStatus: app.appStatus,
        addedFlows: addedFlows ?? [],
      }),
    };
    return { AppStatus_StellaxAi: update };
  }

  const update = {
    enabled: true,
    metadata: getMetadataForAppStatus({
      appStatus: app.appStatus,
      addedFlows: addedFlows ?? [],
    }),
    widgetAppConfigurationItems: widgetItemsToInput(widgetItems ?? []),
  };
  return { [app.__typename]: update };
};

export default getUpdateForAppStatus;
