import type {
  FormBuilder_ScreenNode_BlockFragment,
  FormData_Select_Dropdown,
  FormData_Select_MultiButton,
  FormData_Select_Radio,
} from '~/graphql/types';

export type SelectBlockType =
  | FormData_Select_Dropdown
  | FormData_Select_MultiButton
  | FormData_Select_Radio;

const isSelectTypeBlock = (
  block: FormBuilder_ScreenNode_BlockFragment,
): block is SelectBlockType => {
  switch (block.__typename) {
    case 'FormData_Select_Dropdown':
    case 'FormData_Select_MultiButton':
    case 'FormData_Select_Radio': {
      return true;
    }

    case 'FormData_Input_Email':
    case 'FormData_Input_Integer':
    case 'FormData_Input_Multiline':
    case 'FormData_Input_Text':
    case 'FormData_Input_Contact_Name':
    case 'FormData_Input_Contact_Email':
    case 'FormData_Input_Contact_Phone':
    default: {
      return false;
    }
  }
};

export default isSelectTypeBlock;
