import React from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import TEST_ID from './index.testid';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { AppValuationReportGeneral_Update } from '~/graphql/types';
import Input from '~/components/molecule/Input';
import InputWithLabel from '../../components/InputWithLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  title: 'Analytics',
  google: 'Google analytics (verouderd)',
  facebook: 'Facebook pixel',
};

export type Props = RouteComponentProps & {
  dataTestId?: string;
  loading: boolean;
  updated?: AppValuationReportGeneral_Update | null;
  onUpdate: (value: AppValuationReportGeneral_Update) => void;
};

const Analytics: React.FCC<Props> = ({ updated, loading, onUpdate }) => {
  if (!updated) return null;

  const { googleAnalytics, facebookPixel } = updated;

  return (
    <AppDetailsContainer
      header={text.title}
      icon="file-text"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <JustificationContainer direction="column" gap="base">
        <InputWithLabel label={text.google}>
          <Input
            placeholder="UA-000000-1"
            name="googleAnalytics"
            value={googleAnalytics}
            onChange={e => onUpdate({ googleAnalytics: e.target.value })}
          />
        </InputWithLabel>

        <InputWithLabel label={text.facebook}>
          <Input
            placeholder="UA-000000-1"
            name="facebookPixel"
            value={facebookPixel}
            onChange={e => onUpdate({ facebookPixel: e.target.value })}
          />
        </InputWithLabel>
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default Analytics;
