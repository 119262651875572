import { ensureStringIsTaskListType } from '../taskListType';
import {
  getLocalStorageItem,
  TASK_LIST_FILTER_OFFICE_ID,
  TASK_LIST_FILTER_USER_ID,
  TASK_LIST_FILTER_LIST_TYPE,
} from '~/util/localStorageKeys';
import { TaskListType } from '../../types';
import type { SessionHydrationOfficeFieldsFragment } from '~/graphql/types';

type ReturnType = {
  filteredListType: TaskListType;
  filteredUserId: string | null;
  filteredOfficeId: string | null;
};

const getTaskFilters = (
  mainOffice: SessionHydrationOfficeFieldsFragment | null,
): ReturnType => {
  const storedOfficeId = getLocalStorageItem(TASK_LIST_FILTER_OFFICE_ID);
  const storedUserId = getLocalStorageItem(TASK_LIST_FILTER_USER_ID);
  const storedListType = getLocalStorageItem(TASK_LIST_FILTER_LIST_TYPE);

  const defaultListType = 'Open';
  const defaultOfficeId = mainOffice == null ? null : mainOffice.id;
  const defaultUserId = null;

  return {
    filteredListType:
      storedListType == null
        ? defaultListType
        : ensureStringIsTaskListType(storedListType) || defaultListType,
    filteredOfficeId:
      storedOfficeId === undefined ? defaultOfficeId : storedOfficeId,
    filteredUserId: storedUserId === undefined ? defaultUserId : storedUserId,
  };
};

export default getTaskFilters;
