import React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { ListItemElement } from '~/components/organism/PluginsEditor/types';
import serialize from './serialize';

export type Props = RenderElementProps & {
  element: ListItemElement;
};

const ListItem: React.FCC<Props> = ({
  dataTestId,
  attributes,
  element,
  children,
}) => (
  <Container data-testid={dataTestId} {...attributes} {...element.attributes}>
    {children}
  </Container>
);

const Container = styled.li<{}>``;

export default {
  nodeName: 'LI',
  renderComponent: props => <ListItem {...props} />,
  deserialize: () => ({
    type: ELEMENTS.LI,
  }),
  serialize,
};
