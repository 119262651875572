import {
  FixedToAddress,
  type FlowV2_ToAddress,
  type SessionHydrationOfficeFieldsFragment,
} from '~/graphql/types';
import type { ToAddressOption } from '../convertToDropdownOption';
import convertToDropdownOption from '../convertToDropdownOption';
import sortByFilterLabel from '~/components/molecule/MultiSelectInput/utils/sortByFilterLabel';
import type { ExpandedUsers } from '~/hooks/useUsers';

const fixedTargets: Array<FlowV2_ToAddress> = [
  { target: FixedToAddress.UserOwner, __typename: 'FlowV2_ToAddress' },
  { target: FixedToAddress.OfficeOwner, __typename: 'FlowV2_ToAddress' },
];

/**
 *
 * @param {ExpandedUsers} activeUsers - Users that are active in the account
 * @param {Array<SessionHydrationOfficeFieldsFragment>} offices - Offices in the account
 *
 * keywords: sendnotificationtargets
 */
const getEmailTargetOptions = ({
  activeUsers,
  offices,
}: {
  activeUsers: ExpandedUsers;
  offices: Array<SessionHydrationOfficeFieldsFragment>;
}): Array<ToAddressOption> => {
  const options: Array<FlowV2_ToAddress> = [];

  if (activeUsers.length > 0) {
    activeUsers.forEach(({ id }) => {
      options.push({ userId: id, __typename: 'FlowV2_ToAddress' });
    });
  }

  if (offices.length > 0) {
    offices.forEach(({ id }) => {
      options.push({ officeId: id, __typename: 'FlowV2_ToAddress' });
    });
  }

  const fixedTargetOptions = fixedTargets.map(option =>
    convertToDropdownOption({
      toAddress: option,
      offices,
      users: activeUsers,
    }),
  );

  const dropdownOptions = options.map(option =>
    convertToDropdownOption({
      toAddress: option,
      offices,
      users: activeUsers,
    }),
  );

  return [...fixedTargetOptions, ...sortByFilterLabel(dropdownOptions)].filter(
    a => a != null,
  ) as Array<ToAddressOption>;
};
export default getEmailTargetOptions;
