/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './index.testid';
import { Heading3 } from '~/components/atom/Typography';
import ExpandableEmailSyncForm from './components/ExpandableEmailSyncFrom';
import getNylasLink, {
  NylasAuthPath,
} from '~/components/page/External/Nylas/components/NylasAuthoriseEndpointV1/utils/getNylasLink';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/components/NylasAuthoriseEndpointV1/types';
import useNativePopup from '~/hooks/useNativePopup';
import SyncStatusBar from './components/SyncStatusBar';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { EMAIL_SYNC_TIMEOUT } from './constants';

const text = {
  realworks: 'Realworks e-mailadres',
  other: 'Ander e-mailadres',
};

type Props = {
  /** Where this component is from */
  entityType: SynchroniseEntityType;

  /** The id of the entity (account, office, user) */
  entityId: string;

  /** Always need the accountId to send to the server if it is not account */
  accountId: string;

  /** Any e-mail address that should be used as placeholder */
  email?: string;

  /** Disables both of the sync types */
  disabled?: boolean;

  /** Redirect path for Nylas */
  redirectPath: NylasAuthPath;

  /** State string for Nylas */
  stateStr: string;

  /**
   * This indicates an error state. If something went wrong on the backend
   * and we ended up with more than one mailbox per entity, this flag is used
   * to disable the standard polling mechanism to prevent inconsistent or hanging states.
   */
  hasMultipleMailboxes?: boolean;

  /** Start polling the query that returns the synchronised mailbox. Could be getAccount, getUser etc. */
  startPolling: (pollInterval: number) => void;

  /** Stop polling the same query */
  stopPolling: () => void;

  /** Is called after the sync was successful */
  refetch: () => void;
};

const POLLING_INTERVAL = 2000;

const ChooseEmailSyncType: React.FCC<Props> = ({
  entityId,
  entityType,
  accountId,
  email,
  disabled,
  redirectPath,
  stateStr,
  hasMultipleMailboxes,
  refetch,
  startPolling,
  stopPolling,
  ...rest
}) => {
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [isOpen, setOpen] = useState(false);

  const pollingTimeout = useRef<number | null>(null);

  const onStartPolling = useCallback(
    timeout => {
      // Start polling after popup window has opened, stop it when the popup window is closed or sync has timed out
      startPolling(POLLING_INTERVAL);
      setOpen(true);
      setIsPolling(true);

      if (pollingTimeout.current) {
        clearTimeout(pollingTimeout.current);
      }

      // If the user forgot to close the popup, stop polling after 15 minutes and show the button to restart polling
      pollingTimeout.current = global.window.setTimeout(() => {
        console.warn('Polling timed out');
        stopPolling();
        setIsPolling(false);
      }, timeout);
    },
    [startPolling, stopPolling],
  );

  const _onClose = useCallback(() => {
    stopPolling();
    setIsPolling(false);
  }, [stopPolling]);

  const _onOpen = useCallback(() => {
    if (!hasMultipleMailboxes) onStartPolling(EMAIL_SYNC_TIMEOUT);
  }, [onStartPolling, hasMultipleMailboxes]);

  const { open } = useNativePopup({
    url: getNylasLink({
      state: stateStr,
      email,
      redirectPath,
    }),
    onOpen: _onOpen,
    onClose: _onClose,
  });

  useEffect(
    () => () => {
      stopPolling();
      if (pollingTimeout.current) clearTimeout(pollingTimeout.current);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Container $disabled={disabled} data-testid={TEST_ID.CONTAINER} {...rest}>
      <ExpandableEmailSyncForm
        accountId={accountId}
        entityId={entityId}
        entityType={entityType}
        refetch={refetch}
        dataTestId={TEST_ID.EXPANDABLE_EMAIL_SYNC_FORM}
      />

      <OtherEmailContainer padding={['base']}>
        <LinkContainer
          data-testid={TEST_ID.SYNC_NYLAS_LINK}
          onClick={() => open()}
        >
          <Heading3 margin={[null]} size="base">
            {text.other}
          </Heading3>
        </LinkContainer>
      </OtherEmailContainer>

      {!hasMultipleMailboxes && (
        <SyncStatusBar
          isOpen={isOpen}
          isPolling={isPolling}
          onStartPolling={onStartPolling}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{ $disabled?: boolean }>(
  ({ $disabled }) => css`
    width: 100%;

    ${$disabled &&
    css`
      opacity: 30%;
      pointer-events: none;
    `}
  `,
);

const OtherEmailContainer = styled(JustificationContainer)(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    background-color: ${theme.color('primary', 'translucent')};
  `,
);

const LinkContainer = styled.a<{}>(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: underline;
    cursor: pointer;

    &:hover * {
      color: ${theme.color('primary', 'light')};
    }
  `,
);

export default ChooseEmailSyncType;
