import React, { useEffect, useState } from 'react';
import type { DeepPartial } from 'utility-types';
import type { BrandSettings } from '../../../../state';
import { Body, Heading3 } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { fontFamilyOptions } from './constants';
import Dropdown from '~/components/molecule/Dropdown';
import TextPreview from '~/components/atom/TextPreview';
import { isNil } from 'ramda';
import TEST_ID from './index.testid';

const text = {
  heading: 'Lettertype wijzigen',
  label: 'Lettertype kiezen',
  placeholder: 'Selecteer een lettertype om toe te voegen',
  explanationLine1: `Dit lettertype wordt toegepast in e-mails en handtekeningen.`,
  explanationLine2: `Let op: Heb je het lettertype ingesteld en is dit niet goed toegepast? Ga naar de e-mail of handtekening, wijzig deze en sla opnieuw op.`,
};

export type Props = {
  /** Value of the font family field */
  value: string | undefined | null;

  /** Makes the input disabled */
  readOnly: boolean;

  /** Callback to update the field */
  onUpdate: (args: DeepPartial<BrandSettings>) => void;
};

const FontFamilyBlock: React.FCC<Props> = ({ value, readOnly, onUpdate }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    if (!value) return;
    setSelectedOption(value);
  }, [value]);

  useEffect(() => {
    onUpdate({
      style: { fontFamily: selectedOption },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <JustificationContainer direction="column" margin={['l', null]}>
      <Heading3 size="m">{text.heading}</Heading3>
      <Body>{text.explanationLine1}</Body>
      <Body>{text.explanationLine2}</Body>

      <JustificationContainer
        margin={['base', null, null, null]}
        gap="l"
        justification="space-between"
      >
        <Dropdown
          options={fontFamilyOptions}
          onChange={({ option }) => {
            setSelectedOption(option.payload.value);
          }}
          disabled={readOnly}
          selectedOptionIdx={fontFamilyOptions.findIndex(
            o => o.payload.value === selectedOption,
          )}
          label={text.label}
          width="500px"
          dataTestId={TEST_ID.FONT_FAMILY_INPUT}
        />
        <TextPreview
          style={{ fontFamily: value || 'unset' }}
          disabled={isNil(selectedOption)}
          width="80%"
        />
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default FontFamilyBlock;
