import styled, { css } from 'styled-components';

export const ListContainer = styled.ul<{}>(
  ({ theme }) => css`
    width: 100%;
    padding: 0;
    margin: 0 ${theme.space('xxs')} 0 0;
  `,
);

export const ShowMoreContainer = styled.span<{}>`
  text-decoration: underline;
  display: block;
  width: 100%;
  cursor: pointer;
  ${({ theme }) => css`
    margin: ${theme.space('m')} ${theme.space('xxxs')};
    color: ${theme.color('primary', 'light')};

    &:hover {
      color: ${theme.color('primary')};
    }
  `};
`;

export const FilterBlockContainer = styled.div<{}>`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  ${({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `};
`;

export const LabelContainer = styled.span<{}>(
  ({ theme }) => css`
    flex-basis: 100%;
    padding: ${theme.space('xs')} ${theme.space('xxs')};
  `,
);
