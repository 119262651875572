import type { ClientFlowAction } from '~/graphql/types.client';
import generateActionId from '../generateActionId';
import { FlowAction } from '~/graphql/types';
import { isEmpty, isNil } from 'ramda';

/**
 * Takes an existing action, replaces the id and resets fields like Statistics, childIds etc.
 * @param {ClientFlowAction | undefined | null } action - Action that will be cloned
 * keywords: copyAction, copiedaction, newactionfromcopiedaction
 */
const resetAndCloneAction = ({
  action,
  source,
}: {
  // Action to be cloned
  action: ClientFlowAction | undefined | null;

  // Parent id to assign as the cloned action's parent
  source: string;
}): ClientFlowAction | null => {
  if (isNil(action) || isEmpty(action)) return null;

  const newActionId = generateActionId();

  const newAction = {
    ...action,
    id: newActionId,
    _v: 1,
    parentIds: [source],
  };

  if (newAction.actionType === FlowAction.IfElse) {
    newAction.trueChildId = null;
    newAction.falseChildId = null;
  }

  if ('Statistics' in newAction) {
    delete newAction.Statistics;
  }

  if ('label' in newAction) {
    delete newAction.label;
  }

  return newAction;
};
export default resetAndCloneAction;
