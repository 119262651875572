import { Position } from 'reactflow';
import { ClientFlowAction } from '~/graphql/types.client';
import getDirectParentIdsOfAction from '../../../getDirectParentIdsOfAction';
import { reporter } from '~/hooks/useErrorReporter';

type Params = {
  sourceAction: ClientFlowAction;
  targetAction: ClientFlowAction;
  actions: Array<ClientFlowAction>;
};

/**
 * Determines the card handle position depending on where the source portal is or if the target action is an IfElse child
 * keywords: target portal, handle, position
 */
const getTargetPortalHandlePosition = ({
  actions,
  sourceAction,
  targetAction,
}: Params): Position => {
  const sourceActionParents = getDirectParentIdsOfAction(actions, sourceAction);
  const targetActionParents = getDirectParentIdsOfAction(actions, targetAction);

  // if it is direct child of an IfElse action, position it depending on true or false child
  const directParentIdOfTarget =
    'parentIds' in targetAction ? targetAction.parentIds[0] : null;
  const directParent = actions.find(
    a =>
      a.__typename === 'FlowV2_Action_IfElse' &&
      a.id === directParentIdOfTarget,
  );

  const isDirectTrueChild =
    directParent &&
    'trueChildId' in directParent &&
    directParent.trueChildId === targetAction.id;

  const isDirectFalseChild =
    directParent &&
    'falseChildId' in directParent &&
    directParent.falseChildId === targetAction.id;

  if (isDirectTrueChild) return Position.Right;
  if (isDirectFalseChild) return Position.Left;

  // Otherwise position it depending on where the source action is
  for (const parentId of targetActionParents) {
    if (sourceActionParents.includes(parentId)) {
      const commonParent = actions.find(({ id }) => id === parentId);

      if (commonParent?.__typename === 'FlowV2_Action_IfElse') {
        const isSourceOnTrueSide = commonParent?.trueChildId
          ? [sourceAction.id, ...sourceActionParents].includes(
              commonParent.trueChildId,
            )
          : false;

        if (isSourceOnTrueSide) return Position.Right;

        return Position.Left;
      }
    }
  }

  reporter.captureMessage(
    `Something went wrong in getTargetPortalHandlePosition 
      actions: ${JSON.stringify(actions, null, 2)}
      sourceAction: ${JSON.stringify(sourceAction, null, 2)} 
      targetAction: ${JSON.stringify(targetAction, null, 2)}
  `,
    'debug',
  );

  return Position.Left;
};

export default getTargetPortalHandlePosition;
