import React from 'react';
import styled, { css } from 'styled-components';
import { IconType } from '~/components/atom/Icon';
import { Heading4, Variant } from '~/components/atom/Typography';
import ListedItem from '../ListedItem';
import getPathFromUuid from '~/util/getPathFromUUID';
import { isEmpty } from 'ramda';

export type Item = Array<{
  /**
   * What should be used as text inside the link
   */
  label: string;

  /**
   * ID of the entity
   */
  value: string;

  /**
   * Optional icon, when not provided no icon will be shown
   */
  icon?: IconType;

  insertedId?: string;
}>;

export type Props = {
  category: React.ReactNode;
  values: Item;
};

const text = {
  ctaLabel: 'Bekijk nu',
};

const OutputItem: React.FCC<Props> = ({ category, values = [] }) => {
  const cleanedValues = values.filter(value => !isEmpty(value.label));

  return (
    <>
      {cleanedValues.length > 0 && (
        <div style={{ width: '100%' }}>
          <Heading4 margin={[null]} variant={Variant.primary}>
            {category}
          </Heading4>

          <ListContainer>
            {cleanedValues.map(({ label, value, icon, insertedId }) => (
              <ListedItem
                key={`${label}-${value}`}
                label={label}
                to={insertedId ? getPathFromUuid(insertedId) : null}
                callToAction={text.ctaLabel}
                icon={icon}
              />
            ))}
          </ListContainer>
        </div>
      )}
    </>
  );
};

const ListContainer = styled.ul(
  ({ theme }) => css`
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: ${theme.space('m')};
  `,
);

export default OutputItem;
