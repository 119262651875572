import type { ContactCreatedFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Contact_Details__Input } from '~/graphql/types';

import asDateFilterInput from '../../Primitive/Date/asDateFilterInput';

const asCreatedFilterInput = (
  filter: ContactCreatedFilter,
): Node_NodeContainer_Leaf_Contact_Details__Input => {
  // When dates come from stored filters or query params, we cannot parse the dates.
  // this ensures all components that expect date to be a Date object don't break.
  if (typeof filter.assignedFilter.date === 'string') {
    filter.assignedFilter.date = new Date(filter.assignedFilter.date);
  }

  return {
    created: asDateFilterInput(filter.assignedFilter),
  };
};

export default asCreatedFilterInput;
