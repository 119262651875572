import React from 'react';
import ConversionPage from '~/components/template/ConversionPage';
import { PermissionId } from '~/graphql/types';
import usePermissions from '~/hooks/usePermissions';

export type Props = {};

const withPermissionCheck = <ComponentProps extends object>(
  WrappedComponent: React.ComponentType<ComponentProps>,
  dependencies: Array<PermissionId>,
  AlternativeComponent?: React.ComponentType<ComponentProps>,
) => {
  const ConversionOrActual: React.FC<ComponentProps> = props => {
    const { allowed, reason } = usePermissions(dependencies);

    if (!allowed && reason.__typename === 'Reason_Strapi') {
      if (AlternativeComponent) return <AlternativeComponent {...props} />;
      return <ConversionPage slug={reason.id} />;
    }

    return <WrappedComponent {...props} />;
  };

  return ConversionOrActual;
};

export default withPermissionCheck;
