import type { ConditionExpression } from '../../../ConditionEditorV2';
import { equals } from 'ramda';

const isMetadataRequired = ({
  before,
  after,
}: {
  before?: ConditionExpression | null;
  after: ConditionExpression;
}): boolean => {
  if (!before) return false;
  return !equals(before, after);
};

export default isMetadataRequired;
