import type { FormBuilder_Node } from '~/graphql/types';
import type { Issue } from '../../state/issues';
import getOutputBlocksCount from '../getOutputBlocksCount';

const text = {
  missingSubmitEvent:
    'Er worden geen gegevens verzameld van dit formulier. Voeg een verzendpagina toe om gegevens te verzamelen.',
  missingInputBlocks:
    'Je hebt geen invoer en ontvangt dus geen gegevens van de verzendpagina. Verwijder de verzendpagina of voeg invoer toe aan een pagina.',
};

/**
 * Returns issues specific to basic form
 */
const getIssuesForBasicForm = ({
  nodes,
  isBasicForm,
  hasSubmitScreen,
}: {
  nodes: Array<FormBuilder_Node>;
  isBasicForm: boolean;
  hasSubmitScreen: boolean;
}): Array<Issue> => {
  if (!isBasicForm) return [];

  const outputBlocksCount = getOutputBlocksCount(nodes);
  const hasOutputBlocks = outputBlocksCount > 0;

  const issues: Array<Issue> = [];

  if (hasOutputBlocks && !hasSubmitScreen) {
    issues.push({
      level: 'warning',
      message: text.missingSubmitEvent,
    });
  }

  if (!hasOutputBlocks && hasSubmitScreen) {
    issues.push({
      level: 'error',
      message: text.missingInputBlocks,
    });
  }

  return issues;
};

export default getIssuesForBasicForm;
