import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading1 } from '~/components/atom/Typography';

import useCurrentUser from '~/hooks/useCurrentUser';
import UserImage from '../UserImage';

const MAX_CHAR_LENGTH = 15;
const Header: React.FCC<{}> = () => {
  const currentUser = useCurrentUser();
  const src = currentUser && currentUser.img ? currentUser.img.medium : null;
  return (
    <JustificationContainer padding={['s', 's', null, 's']} align="center">
      <div>
        <UserImage userImage={src} />
      </div>
      <Heading1
        color={{ group: 'secondary', variant: 'light' }}
        size="m"
        fontWeight="extraLight"
        skewed={false}
        margin={[null]}
      >
        {currentUser.name.substring(0, MAX_CHAR_LENGTH)}
        {currentUser.name.length > MAX_CHAR_LENGTH && <>&hellip;</>}
      </Heading1>
    </JustificationContainer>
  );
};

export default Header;
