import {
  RouteComponentProps,
  useLocation,
  WindowLocation,
} from '@gatsbyjs/reach-router';

import React from 'react';
import StartEmailSyncComponent from '../StartEmailSyncComponent';
import { SynchroniseEntityType } from '../NylasAuthoriseEndpointV1/types';
import FullPageInformation from '~/components/template/FullPageInformation';
import PassThroughComponent from '../PassThroughComponent';
import { syncErrorMessage } from '../NylasAuthoriseEndpointV1';
import Validation from '~/util/Validation';

const text = {
  invalidStateTitle: 'Oeps!',
  close: 'Sluiten',
};

export type Props = {};

const NylasAuthoriseEndpointV2: React.FCC<RouteComponentProps> = ({}) => {
  const location = useLocation() as WindowLocation<{
    code: string;
    stateString: string;
  }>;

  if (!location.state) return <PassThroughComponent location={location} />;

  const { code, stateString } = location.state;

  const props = {
    explanation: syncErrorMessage,
    title: text.invalidStateTitle,
    button: { onClick: () => window.close(), label: text.close },
  };

  if (
    !Validation.String.isNonEmptyString(stateString) ||
    !Validation.String.isNonEmptyString(code)
  ) {
    return <FullPageInformation {...props} />;
  }

  const accountId = decodeURIComponent(stateString);

  if (!accountId) {
    return <FullPageInformation {...props} />;
  }

  return (
    <StartEmailSyncComponent
      state={{
        accountId,
        entityId: accountId,
        entityType: SynchroniseEntityType.Account,
      }}
      code={code}
    />
  );
};

export default NylasAuthoriseEndpointV2;
