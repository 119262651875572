import type {
  FormBuilder_Node,
  FormBuilder_ScreenNode,
  FormBuilder_ScreenNode_Block,
} from '~/graphql/types';

type ScreenNodeBlocksLookup = {
  [key in FormBuilder_ScreenNode_Block['__typename']]?: {
    nodeIds: Array<string>;
  };
};

/**
 * Returns the amount of times a block type is used and which nodes it is used in throughout the form
 * @param {Array<FormBuilder_Node>} nodes - Nodes in the form
 */
const getScreenNodeBlocksUsageLookup = ({
  nodes,
}: {
  nodes: Array<FormBuilder_Node>;
}): ScreenNodeBlocksLookup =>
  nodes
    .filter(
      (node): node is FormBuilder_ScreenNode =>
        node.__typename === 'FormBuilder_ScreenNode',
    )
    .flatMap(node => node.blocks.map(b => ({ ...b, nodeId: node.id })))
    .reduce((acc, block) => {
      const { __typename: blockType, nodeId } = block;
      const existingEntry = acc[blockType] || { nodeIds: [] };

      acc[blockType] = {
        nodeIds: [...existingEntry.nodeIds, nodeId],
      };

      return acc;
    }, {});

export default getScreenNodeBlocksUsageLookup;
