import React from 'react';
import styled, { css } from 'styled-components';

import ImageUploadTextButton from '~/components/organism/ImageUploadTextButton';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import { acceptedFileTypes } from '../..';
import insertImageAction from '../../../../elements/Image/insertAction';
import DragAndDropContainer from '~/components/organism/DragAndDropContainer';

export type Props = {
  editor: DHEditor;
  userId: string;
  onClose: () => void;
};

const UploadFileContainer: React.FCC<Props> = ({
  dataTestId,
  editor,
  onClose,
  userId,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <StyledDragDropContainer
      handleDrop={files =>
        insertImageAction(editor, files, userId, () => onClose())
      }
      uploading={false}
      acceptedFileTypes={acceptedFileTypes}
    >
      <ImageUploadTextButton
        onUpload={files => {
          if (files) {
            onClose();
            insertImageAction(editor, files, userId);
          }
        }}
        uploading={false}
        size="small"
      />
    </StyledDragDropContainer>
  </Container>
);

const Container = styled.div<{}>``;

const StyledDragDropContainer = styled(DragAndDropContainer)<{}>(
  ({ theme }) => css`
    border: ${theme.getTokens().border.width.s} dashed
      ${theme.color('tertiary', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 150px;
  `,
);

export default UploadFileContainer;
