import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  LOGO_INPUT: `${cleanedFilename(__filename)}-logo-input`,
  HIGHLIGHT_IMAGE_INPUT: `${cleanedFilename(__filename)}-highlight-image-input`,
  WEBSITE_URL_INPUT: `${cleanedFilename(__filename)}-website-url-input`,

  PRIMARY_BACKGROUND_COLOR_INPUT: `${cleanedFilename(
    __filename,
  )}-primary-background-color-input`,
  PRIMARY_COLOR_INPUT: `${cleanedFilename(__filename)}-primary-color-input`,

  SECONDARY_BACKGROUND_COLOR_INPUT: `${cleanedFilename(
    __filename,
  )}-secondary-background-color-input`,
  SECONDARY_COLOR_INPUT: `${cleanedFilename(__filename)}-secondary-color-input`,
};
