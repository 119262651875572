import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import { AppBbWaardecheckReport } from '~/graphql/types';
import InputGroupDivider from '~/components/page/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import { StateId, AppWaardecheckFieldsFragment } from '../..';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppSectionHeader from '~/components/page/Apps/components/AppSectionHeader';
import WaardecheckIntl from '../../intl';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import TEST_ID from './index.testid';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import IntlInputComponent from '../../components/IntlInputComponent';
import MainPreview from '../../components/MainPreview';
import PreviewContainer from '../../components/PreviewContainer';
import ImageInput from '../../components/ImageInput';
import { Page } from '../../components/Preview';
import Explanation from '../../components/Explanation';
import { appWaardecheckState } from '../../state';

export type Props = RouteComponentProps & {
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
};

const Report: React.FCC<Props> = ({ data, defaultValues, loading }) => {
  const original = defaultValues.report;
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const updateValue = (value: DeepPartial<AppBbWaardecheckReport>) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        report: value,
      }) as any as typeof prev;
    });
  };

  if (original == null || updated == null) return null;

  return (
    <AppDetailsContainer
      header="Rapport"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Explanation>
        Op deze pagina wordt de waardecheck weergegeven. Personaliseer de
        achtergrondfoto en teksten.
      </Explanation>
      <MainPreview data={updated} page={Page['/report']} />
      <AppEntryItemHeader>{WaardecheckIntl.mainImage.title}</AppEntryItemHeader>
      <Description>{WaardecheckIntl.mainImage.description}</Description>
      <ImageInput
        initialUrl={data.report.image?.url}
        filename={data.__typename + 'report'}
        s3Key={updated?.report.image?.s3key}
        onChange={image => updateValue({ image })}
        dataTestId={TEST_ID.IMAGE}
      />
      <InputGroupDivider large />
      <AppSectionHeader>{WaardecheckIntl.section.text}</AppSectionHeader>
      <Description>{WaardecheckIntl.section.textDescription}</Description>
      <IntlInputComponent
        title="Titel van de waardecheck"
        defaultValue={original.heading}
        value={updated.report.heading}
        onChange={heading => updateValue({ heading })}
        dataTestId={TEST_ID.HEADING}
        description={WaardecheckIntl.variables.propertyAdress}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Titel van de waarde"
        defaultValue={original.estimationLabel}
        value={updated.report.estimationLabel}
        onChange={estimationLabel => updateValue({ estimationLabel })}
        dataTestId={TEST_ID.ESTIMATION_LABEL}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Disclaimer waardecheck"
        defaultValue={original.estimationDisclaimer}
        value={updated.report.estimationDisclaimer}
        onChange={estimationDisclaimer => updateValue({ estimationDisclaimer })}
        dataTestId={TEST_ID.ESTIMATION_DISCLAIMER}
      />

      <InputGroupDivider />
      <AppSectionHeader>Referentieobject</AppSectionHeader>
      <IntlInputComponent
        title="Koopsom weergave"
        defaultValue={original.conversionMessageHeading}
        value={updated.report.conversionMessageHeading}
        onChange={conversionMessageHeading =>
          updateValue({ conversionMessageHeading })
        }
        dataTestId={TEST_ID.CONVERSION_MESSSAGE_HEADING}
        description={WaardecheckIntl.variables.salePrice}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Bij hogere koopsom dan waardecheck"
        defaultValue={original.conversionMessageBodyMore}
        value={updated.report.conversionMessageBodyMore}
        onChange={conversionMessageBodyMore =>
          updateValue({ conversionMessageBodyMore })
        }
        dataTestId={TEST_ID.CONVERSION_MESSSAGE_BODY_MORE}
        description={WaardecheckIntl.variables.salePriceDifference}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Bij lagere koopsom dan waardecheck"
        defaultValue={original.conversionMessageBodyLess}
        value={updated.report.conversionMessageBodyLess}
        onChange={conversionMessageBodyLess =>
          updateValue({ conversionMessageBodyLess })
        }
        dataTestId={TEST_ID.CONVERSION_MESSSAGE_BODY_LESS}
        description={WaardecheckIntl.variables.salePriceDifference}
      />
      <InputGroupDivider />

      <IntlInputComponent
        title="Conversie button"
        defaultValue={original.propertyPrimaryButton}
        value={updated.report.propertyPrimaryButton}
        onChange={propertyPrimaryButton =>
          updateValue({ propertyPrimaryButton })
        }
        dataTestId={TEST_ID.PROPERTY_PRIMARY_BUTTON}
      />

      <InputGroupDivider />
      <AppSectionHeader>Achterkant kaart referentieobject</AppSectionHeader>
      <PreviewContainer
        data={updated}
        previews={[
          {
            device: 'desktop',
            story: {
              id: 'components-propertycard--backface-story',
              page: Page['/report'],
              height: 150,
            },
          },
        ]}
      />
      <IntlInputComponent
        title="Service van de vastgoedprofessional"
        defaultValue={original.conversionMessageFooter}
        value={updated.report.conversionMessageFooter}
        onChange={conversionMessageFooter =>
          updateValue({ conversionMessageFooter })
        }
        dataTestId={TEST_ID.CONVERSION_MESSSAGE_FOOTER}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Aanvraag versturen button"
        defaultValue={original.conversionMessagePrimaryButton}
        value={updated.report.conversionMessagePrimaryButton}
        onChange={conversionMessagePrimaryButton =>
          updateValue({ conversionMessagePrimaryButton })
        }
        dataTestId={TEST_ID.CONVERSION_MESSSAGE_PRIMARY_BUTTON}
      />

      <InputGroupDivider />
      <IntlInputComponent
        title="Koopsom weergave"
        defaultValue={original.propertySalePrice}
        value={updated.report.propertySalePrice}
        onChange={propertySalePrice => updateValue({ propertySalePrice })}
        dataTestId={TEST_ID.PROPERTY_SALE_PRICE}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Vraag aan vastgoedprofessional bij hogere koopsom"
        defaultValue={original.higherSalePrice}
        value={updated.report.higherSalePrice}
        onChange={higherSalePrice => updateValue({ higherSalePrice })}
        dataTestId={TEST_ID.HIGHER_SALE_PRICE}
        description={WaardecheckIntl.variables.salePriceDifference}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Vraag aan vastgoedprofessional bij lagere koopsom"
        defaultValue={original.lowerSalePrice}
        value={updated.report.lowerSalePrice}
        onChange={lowerSalePrice => updateValue({ lowerSalePrice })}
        dataTestId={TEST_ID.LOWER_SALE_PRICE}
        description={WaardecheckIntl.variables.salePriceDifference}
      />
    </AppDetailsContainer>
  );
};

export default Report;
