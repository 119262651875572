import type { Node_Condition_Id__Input } from '~/graphql/types';
import type { IDFilter } from './types.flow';

const asIDFilterInput = (filter: IDFilter): Node_Condition_Id__Input => {
  const { id, negate } = filter;

  return {
    negate,
    id,
  };
};

export default asIDFilterInput;
