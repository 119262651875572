import React from 'react';
import AppSetupModal, {
  AppSetupModalProps,
} from '~/components/template/AppSetupModal';
import { NvmAppType, useStartNvmOauthMutation } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useErrorReporter from '~/hooks/useErrorReporter';
import useNativePopup from '~/hooks/useNativePopup';
import { nvmOAuthSuccessPath } from '~/components/page/Apps/BBWaardecheck/components/NvmOAuthSuccess';
import formatToastMessage from '~/util/formatToastMessage';

const text = {
  errorMessage:
    'Er is iets misgegaan bij het starten van NVM login. Probeer het later nog eens.',
};

type Props = AppSetupModalProps & {};

const AppSetupModalWithOAuthFlow: React.FCC<Props> = ({ ...rest }) => {
  const errorReporter = useErrorReporter();
  const account = useCurrentAccount();
  const addToast = useAddToast();

  const [startNvmOAuth, { error }] = useStartNvmOauthMutation();

  const { open } = useNativePopup({
    onClose: () => {
      // refetch app statuses so that the app modal gets updated
      rest.refetch();
    },
  });

  const onOAuthCall = async ({ onError }) => {
    // This will be replaced with startOAuthForAppType mutation
    const { data } = await startNvmOAuth({
      variables: {
        accountId: account.id,
        participatingOffices: 1,
        // Redirect back into the setup modal so the user can click "finish installation"
        callbackUrl: `${origin}/-/apps/${nvmOAuthSuccessPath}`,
        appType: NvmAppType.BbWaardecheck,
      },
    });

    if (error || !data) {
      errorReporter.captureException(
        new Error(JSON.stringify(error || 'No Data')),
        'fatal',
      );

      addToast([formatToastMessage(text.errorMessage, 'danger')]);

      return onError();
    }

    open({ url: data.startNVMOauth.redirectUrl });
  };

  return <AppSetupModal {...rest} onOAuthCall={onOAuthCall} />;
};

export default AppSetupModalWithOAuthFlow;
