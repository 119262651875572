/**
 * Tags are only allowed lowercase letters/'-'
 */

import StringValidation from './String';

/**
 * Uses same regex as backend.
 * Make sure it consistent if changing it
 */
const TAG_REGEX = /^([a-z0-9]|-)+$/;
const NOT_ALLOWED_IN_TAG_REGEX = /[^a-z0-9|-]/g;

export const isValid = (tag: any) => {
  if (!StringValidation.isNonEmptyString(tag)) return false;

  return TAG_REGEX.test(tag);
};

/** Function that takes the string and removes any invalid chars that are not allowed in a tag */
export const format = (string: string): string =>
  string.toLocaleLowerCase().replace(NOT_ALLOWED_IN_TAG_REGEX, '');

export default {
  isValid,
  format,
};
