import { VariableElement } from '~/components/organism/PluginsEditor/types';

/**
 * serializes the VariableElement into html
 * @param {VariableElement} node - VariableElement
 */
const serialize = (node: VariableElement): string => {
  if (!node.mappingId || node.pending) return '';

  return `{{${node.mappingId}}}`;
};

export default serialize;
