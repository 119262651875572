import { atom } from 'recoil';
import type { FormBuilder_Node, FormBuilder_ScreenNode } from '~/graphql/types';

export type CopyScreenNodeState = {
  copiedNode: FormBuilder_ScreenNode;
  // Used to reset nodes to the state before copying started
  nodesBeforeCopy: Array<FormBuilder_Node>;
};

export const copyScreenNodeState = atom<CopyScreenNodeState | null>({
  key: 'formBuilder/copyScreenNodeState',
  default: null,
});
