import { append, clone, isNil } from 'ramda';
import { Element, Text, Path } from 'slate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { CustomElement, type EditorValue } from '../../types';

/**
 * Validates elements by the given rules
 * @param {EditorValue} arr - The editor value in Slate format
 * @param {Path} prevPath - Path of the element e.g. [0,3,1...]
 * keywords: validate, editor
 */

export enum Errors {
  PendingImage = 'pendingImageExists',
  LoadingImage = 'loadingImageExists',
}

export enum Severity {
  Danger = 'danger',
  Warning = 'warning',
}

const validateElement = (
  el: CustomElement,
): Omit<EditorError, 'path'> | null => {
  if (el.type === ELEMENTS.DH_IMAGE || el.type === ELEMENTS.IMAGE) {
    if (el.pending) {
      return {
        errorId: el.type === ELEMENTS.DH_IMAGE ? el.inlineId : null,
        errorType: Errors.PendingImage,
        severity: Severity.Danger,
      };
    }

    if (el.loading) {
      return {
        errorId: el.type === ELEMENTS.DH_IMAGE ? el.inlineId : null,
        errorType: Errors.LoadingImage,
        severity: Severity.Warning,
      };
    }

    return null;
  }

  return null;
};

export type EditorError = {
  path: Path;
  errorType?: Errors;
  errorId?: string | null;
  severity?: Severity;
};

const getEditorErrors = (
  arr: EditorValue,
  prevPath: Path = [],
): Array<{ path: Path }> =>
  arr.reduce((prevErrors, node, index) => {
    const path = index;
    let errors: Array<EditorError> = clone(prevErrors);
    const newPath = append(path, prevPath);

    if (Element.isElement(node)) {
      const elError = validateElement(node);

      if (!isNil(elError)) {
        errors.push({
          path: newPath,
          errorId: elError?.errorId,
          errorType: elError?.errorType,
        });
      }
    }

    if (!Text.isText(node) && node.children) {
      const childErrors = getEditorErrors(node.children, newPath);

      if (childErrors.length > 0) errors = [...errors, ...childErrors];
    }

    return errors;
  }, []);

export default getEditorErrors;
