import React, { useState } from 'react';
import { animated, UseTransitionProps, useTransition } from 'react-spring';

import {
  UpdateProfilePictureMutationVariables,
  useUpdateProfilePictureMutation,
} from '~/graphql/types';
import { ImgDefinition } from '~/graphql/types.client.flow';
import uploadS3Image from '~/util/uploadS3Image';
import AvatarWithUploader from '../../../AvatarWithUploader';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import JustificationContainer from '~/components/atom/JustificationContainer';

import EditableText from '../../../EditableText';
import CopyTextBlock from '~/components/molecule/CopyTextBlock';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import { ANIMATION_CONFIG } from '~/styles/constants';
import styled, { css } from 'styled-components';

type Props = {
  // should be editable or read-only
  edit?: boolean | null | undefined;
  user:
    | { img?: ImgDefinition | null | undefined; name: string; id: string }
    | null
    | undefined;
  refetchUser: () => void;
  onImageUploadError: () => void;
  updateMyName: (name: string) => void;
};

const text = {
  userIdLabel: 'User ID',
};

const UserProfileHeader: React.FCC<Props> = ({
  edit,
  user,
  refetchUser,
  onImageUploadError,
  updateMyName,
}) => {
  const viewingModeProps = useViewingModeProps();
  const account = useCurrentAccount();
  const [loading, setLoading] = useState(false);
  const [updateProfilePicture] = useUpdateProfilePictureMutation({});

  const imageUrl = user && user.img ? user.img.medium : '';
  const userName = user ? user.name : '';

  const transitionProps: UseTransitionProps = {
    from: ANIMATION_CONFIG.from,
    enter: ANIMATION_CONFIG.to,
    leave: ANIMATION_CONFIG.from,
    config: ANIMATION_CONFIG.config,
    trail: 125,
  };
  // Use transition instead of useSpring here because updates may cause a rerender/trigger of animation.
  const transitions = useTransition(true, transitionProps);

  const onUploadImageDone = (
    identityId: string | null | undefined,
    filename: string,
  ) => {
    if (!identityId) return;

    const variables: UpdateProfilePictureMutationVariables = {
      accountId: account.id,
      identityId,
      filename,
    };

    return updateProfilePicture({ variables }).then(() => {
      setLoading(false);
      refetchUser();
    });
  };

  const onUploadImage = async (file: File) => {
    try {
      setLoading(true);
      const { identityId, filename } = await uploadS3Image({
        file,
      });

      return onUploadImageDone(identityId, filename);
    } catch {
      setLoading(false);
      onImageUploadError();
    }
  };

  return (
    <>
      {transitions(
        (style, item) =>
          item && (
            <TitleContainer as={animated.div} style={style}>
              <AvatarWithUploader
                illustration="user"
                edit={edit}
                imageUrl={imageUrl}
                onUpload={files => onUploadImage(files[0])}
                loading={loading}
              />
              <JustificationContainer direction="column" gap="xxs">
                <EditableText
                  readonly={!edit}
                  onSave={updateMyName}
                  text={userName}
                  blurred={viewingModeProps['data-redacted']}
                />
                {user?.id && (
                  <CopyTextBlock
                    label={text.userIdLabel}
                    textToCopy={user.id}
                  />
                )}
              </JustificationContainer>
            </TitleContainer>
          ),
      )}
    </>
  );
};

const TitleContainer = styled.div<{}>`
  order: 2;
  width: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    ${theme.mq.greaterThan('tabletLandscape')`
    width: 540px;
    order: 1;
  `}
  `};
`;

export default UserProfileHeader;
