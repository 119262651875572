import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Input from '~/components/molecule/Input';
import Link from '~/components/molecule/Link';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import { Body } from '~/components/atom/Typography';
import {
  OutputFieldItem,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import { OutputMap } from '~/components/organism/WizardSteps';
import { useUpdateAppStatusMutation } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import formatToastMessage from '~/util/formatToastMessage';
import { isNonEmptyString } from '~/util/Validation/String';
import TEST_ID from './index.testid';
import useApp from '~/hooks/useApp';
import Loading from '~/components/atom/Loading';
import ErrorScreen from '~/components/page/ErrorScreen';
import useErrorReporter from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';
import hasValue from '~/util/hasValue';
import HelpBlock from '~/components/page/Apps/Contaqt/components/HelpBlock';
import useContaqtSignUpLink from '~/components/page/Apps/Contaqt/hooks/useContaqtSignUpLink';
import isDuplicatedToken from '~/components/page/Apps/Realworks/utils/isDuplicatedToken';
import { CONTAQT_HELP_LINK } from '~/components/page/Apps/Contaqt/appConfig';

export const id = 'AddContaqtKoppeling';
export const title = 'Contaqt koppelen';

export type OutputType = {
  type: typeof id;
  contaqtToken: OutputFieldItem;
};

export const text = {
  category: 'Token toegevoegd',
  description:
    'Na het activeren van deze koppeling, worden in de nieuwsbrieven van Contaqt automatisch op relevante plekken links toegevoegd naar de tools in de webwinkel widget op jouw website. Zo wordt de nieuwsbrief actiegericht en ontvang jij waardevolle leads.',
  inputLabel: 'Contaqt API token invoeren',
  inputPlaceholder: '12Ab345C78dE9FG',
  formHeader: 'Contaqt koppeling toevoegen',
  emptyFieldError: 'Veld mag niet leeg zijn',
  duplicatedErrorMessage:
    'Dit token is al in gebruik, probeer een ander token.',
  errorMessage:
    'Er is iets misgegaan bij het toevoegen van de token. Zorg ervoor dat je een valide token invoert.',
  successfullyAdded: 'Token is succesvol opgeslagen.',
  tip: (
    <>
      Kom je er niet uit? Lees de volledige instructie in{' '}
      <Link to={CONTAQT_HELP_LINK} target="_blank">
        onze kennisbank
      </Link>{' '}
      of <ChatLink linkText="start een chat met ons." />
    </>
  ),
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const { id: accountId } = useCurrentAccount();
  const linkToContaqt = useContaqtSignUpLink();

  const reporter = useErrorReporter();

  const addToast = useAddToast();
  const [currentOutput, setCurrentOutput] = useState(
    outputMap[id] as OutputType,
  );
  const { app, loading } = useApp(step.metadata?.typename);
  const tokenId = currentOutput.contaqtToken.value;

  const [updateAppStatus] = useUpdateAppStatusMutation();
  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const updatedOutput = outputMap[id] as OutputType;

      const token = updatedOutput.contaqtToken.value;

      if (token) {
        const { data, errors } = await updateAppStatus({
          variables: {
            accountId,
            update: {
              AppStatus_Contaqt: {
                enabled: true,
                token: token,
                metadata: {
                  setup: {
                    addedToken: token,
                    completed: false,
                  },
                },
              },
            },
          },
        });

        if (!data && errors?.length) {
          let errorMessage: string;

          if (isDuplicatedToken(errors)) {
            errorMessage = text.duplicatedErrorMessage;
          } else {
            errorMessage = text.errorMessage;
          }

          reporter.captureException(
            new Error(
              `${cleanedFilename(
                __filename,
              )} | Could not updateAppStatus for AppStatus_Contaqt ${JSON.stringify(
                errors,
              )}`,
            ),
          );

          throw new Error(errorMessage);
        }

        if (data) {
          addToast([formatToastMessage(text.successfullyAdded, 'success')]);

          const output: OutputType = {
            ...updatedOutput,
            contaqtToken: {
              category: text.category,
              type: 'item',
              label: token,
              value: token,
            },
          };

          return output;
        }
      }

      return updatedOutput;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateAppStatus, accountId],
  );

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );

  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    if (hasValue(tokenId) && !loading) {
      api.free(currentOutput);
    } else {
      api.lock(currentOutput);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOutput, loading]);

  if (loading) return <Loading />;
  if (!app) {
    return <ErrorScreen />;
  }

  return (
    <>
      <Body margin={[null, null, 'base', null]}>{text.description}</Body>
      <Body>
        <HelpBlock signUpLink={linkToContaqt} />
      </Body>

      <TipBanner
        id="contaqt-add-token"
        headerText="Hulp nodig?"
        margin={['base', null, 'l', null]}
      >
        {text.tip}
      </TipBanner>

      <StyledInput
        dataTestId={TEST_ID.ADD_TOKEN_INPUT}
        label={{ text: text.inputLabel }}
        placeholder={text.inputPlaceholder}
        value={tokenId}
        onChange={e => {
          const value = e.target.value;
          setCurrentOutput(prevOutput => ({
            ...prevOutput,
            contaqtToken: {
              value: value,
              type: 'item',
              category: text.category,
              label: value,
            },
          }));
        }}
        validation={[
          (value: string) =>
            isNonEmptyString(value) ? true : text.emptyFieldError,
        ]}
        name="token"
      />
    </>
  );
};

const StyledInput = styled(Input)<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `,
);

export default {
  id,
  title,
};
