import React from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { useGetUserQuery } from '~/graphql/types';

import useCurrentAccount from '~/hooks/useCurrentAccount';

import {
  getOfficesList,
  OfficeInList,
} from '~/components/page/Settings/Profile/components/FormContainer';
import UserDetailsComponent from './UserDetailsComponent';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import Loading from '~/components/atom/Loading';
import AppErrorScreen from '~/components/template/AppErrorScreen';

type Props = RouteComponentProps<{ userId: string }>;

const UserDetails: React.FCC<Props> = ({ userId = '' }) => {
  const account = useCurrentAccount();

  const { data, networkStatus, refetch } = useGetUserQuery({
    variables: {
      accountId: account.id,
      userId,
    },
  });

  if (isInitialLoadStatus(networkStatus)) return <Loading />;
  if (!data) return <AppErrorScreen />;

  const user = data.getUser;
  if (!user) return <AppErrorScreen />;

  const { email, phone } = user;

  const reducedOffices = getOfficesList(user.Offices);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { officeIds, ...initialOfficeData } = reducedOffices;

  const initialValue = {
    email,
    phone,
    ...initialOfficeData,
  };

  const signature = user.Signature ?? null;

  return (
    <UserDetailsComponent
      user={{ name: user.name, id: user.id, img: user.img }}
      officesList={user.Offices.map(convertToOfficeInList)}
      initialValue={initialValue}
      refetch={refetch}
      signature={signature}
      notificationSettings={data?.getUser?.NotificationSettings}
    />
  );
};

const convertToOfficeInList = (office): OfficeInList => {
  const { address, email, id, name, phone, img, relation } = office;

  return {
    address,
    email,
    id,
    name,
    phone,
    img,
    relation,
  };
};

export default UserDetails;
