import { selector } from 'recoil';
import type { FormBuilder } from '~/graphql/types';
import isRecoilDefaultValue from '~/typeguards/isRecoilDefaultValue';
import { formState } from '.';

const defaultThemeState: FormBuilder['style']['theme'] = {
  __typename: 'FormBuilder_Style_Theme',
  colors: {
    __typename: 'WidgetSettingsStyles',
    primary: {
      __typename: 'WidgetSettingsStyleConfigColorPair',
      color: '#fff',
      background: '#fff',
    },
    secondary: {
      __typename: 'WidgetSettingsStyleConfigColorPair',
      background: '#fff',
      color: '#fff',
    },
  },
};

export const themeState = selector<FormBuilder['style']['theme']>({
  key: 'formBuilder/themeState',
  get: ({ get }) => {
    const form = get(formState);

    return form?.style.theme ?? defaultThemeState;
  },

  set: ({ get, set }, theme) => {
    if (isRecoilDefaultValue(theme)) return;

    const prevForm = get(formState);
    if (!prevForm) return;

    set(formState, {
      ...prevForm,
      style: {
        ...prevForm.style,
        theme: {
          ...prevForm.style.theme,
          ...theme,
        },
      },
    });
  },
});
