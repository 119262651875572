import React from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { useGetWidgetSettingsQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Loading from '~/components/atom/Loading';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import withErrorBoundary from '~/ErrorBoundary';
import WidgetContent from './components/WidgetContent';

type Props = RouteComponentProps;
const Widget: React.FCC<Props> = () => {
  const account = useCurrentAccount();

  const { data, loading, error } = useGetWidgetSettingsQuery({
    variables: { accountId: account.id },
  });

  if (loading) {
    return <Loading />;
  }

  if (!data || error) {
    return <AppErrorScreen />;
  }

  return <WidgetContent account={account} data={data.getWidgetSettings} />;
};

export default withErrorBoundary<Props>(Widget);
