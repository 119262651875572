/** svg from https://pers.funda.nl/media_kits/216317/ */
import { Props } from '..';
import React from 'react';

const FundaLogo: React.FCC<Props> = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 425.11 150.45"
    width="100%"
    height="100%"
    style={{ margin: '0px' }}
    {...props}
  >
    <path
      fill="#77c5ef"
      d="M425.11,150.3l-26-92.43h0C388.49,21.4,353.78,0,321.73,0H0L25.64,92.59v.15C36.3,129.52,71,150.1,101.29,150.45ZM47.81,86.39c-.07-.2-12.18-44.1-17.52-63.35H321.7c21.69,0,47.46,14.6,55.23,41.16.08.29,15.51,56,17.51,63.22H102.09C80,127.42,55.32,112.08,47.81,86.39Z"
    />
    <path
      fill="#5a291c"
      d="M47.81,86.39c-.07-.2-12.18-44.1-17.52-63.35H321.7c21.69,0,47.46,14.6,55.23,41.16.08.29,15.51,56,17.51,63.22H102.09C80,127.42,55.32,112.08,47.81,86.39Z"
    />
    <path
      fill="#fff"
      d="M94.54,107.14h10.21v-43h11.88V56.36H104.75V49.65c0-3.1,1.11-4.2,4.41-4.2h7.47V35.83h-8.27c-7.91,0-13.82,4.31-13.82,13.82v6.71H82.85l2.22,7.82h9.47Z"
    />
    <path
      fill="#fff"
      d="M129.51,90.11c0,11.52,9,17.62,17,17.62a16.21,16.21,0,0,0,13.12-6h.2v5.41h10.21V56.36H159.86V86.61c0,6.91-4.31,10.91-10,10.91s-10.11-4-10.11-10.91V56.36H129.51Z"
    />
    <path
      fill="#fff"
      d="M188.15,107.14h10.22V76.9c0-6.92,4.3-10.92,10.12-10.92s10,4,10,10.92v30.24h10.21V73.39c0-11.52-9-17.63-16.92-17.63a16.28,16.28,0,0,0-13.23,6h-.19V56.36H188.15Z"
    />
    <path
      fill="#fff"
      d="M274,107.14h10.22V35.83H274V61.77c-3.8-3.61-7.9-6-13.11-6a16.59,16.59,0,0,0-13.62,6.61c-2.8,3.61-3.61,6.91-3.61,19.33s.81,15.82,3.61,19.43a16.55,16.55,0,0,0,13.62,6.6,17.86,17.86,0,0,0,13.11-5.9ZM264,66c9.53,0,10,8.41,10,15.72s-.49,15.82-10,15.82-10.1-7.91-10.1-15.82S254.38,66,264,66Z"
    />
    <path
      fill="#fff"
      d="M328.7,107.14h10.21V72.49c0-12.52-8.6-16.73-21.12-16.73-7.61,0-13.23,2-17.13,7.41l8,6.11c1.8-2.9,4.31-3.9,9.62-3.9,7.3,0,10.41,1.7,10.41,6.51V77.2H314.58c-10.91,0-16.52,6.9-16.52,15,0,8.81,6.6,15.51,17.92,15.51,6.91,0,10.42-1.59,12.52-5.1h.2Zm0-17.83c0,7.91-2.9,8.81-11.42,8.81-6.91,0-9.62-3-9.62-6.41,0-3.7,2.81-6.11,8.82-6.11H328.7Z"
    />
  </svg>
);

export default FundaLogo;
