import React, { useEffect, useMemo, useState } from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import qs from 'query-string';
import { Helmet as MetaTags } from 'react-helmet';
import { FlowAction, useImportFlowQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import BuilderWithContext from '../BuilderWithContext';
import { useRecoilState, useSetRecoilState } from 'recoil';
import flowActions from '../../state';
import getFlowActionsToClientActions from '../../util/getFlowActionsToClientActions';
import { withRouterTransitionContext } from '~/contexts/RouterTransitionContext';
import interactions from '../../state/interactions';
import { EMPTY_TEMPLATE_ID } from '../FlowTemplates';
import { ClientFlowAction } from '~/graphql/types.client';
import { isNonEmptyString } from '~/util/Validation/String';
import createPageTitle from '~/util/createPageTitle';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Catalog from '~/Catalog';
import flowChanges from '../../state/flowChanges';

export type Props = RouteComponentProps<{ token: string }>;

const CreateFlowBuilder: React.FCC<Props> = ({ token }) => {
  const account = useCurrentAccount();

  const { name, description } = useMemo(
    () => qs.parse(global.window?.location?.search || ''),
    [],
  );

  const initialDescription = isNonEmptyString(description) ? description : null;
  const initialName = isNonEmptyString(name) ? name : null;

  const { data, loading, error } = useImportFlowQuery({
    variables: {
      accountId: account.id,
      token: token as string,
    },
    fetchPolicy: 'network-only',
  });
  const [, setActions] = useRecoilState(flowActions);
  const setFlowHasChanges = useSetRecoilState(flowChanges);

  const setActiveInteraction = useSetRecoilState(interactions);
  const [initialActions, setInitialActions] = useState<Array<ClientFlowAction>>(
    [],
  );

  useEffect(() => {
    if (data?.copyFlow) {
      setFlowHasChanges(prev => ({ ...prev, flowName: true }));
      const templateActions = data?.copyFlow.Actions;

      //If it is an empty template, there will be only one action - Start action, then we want to overwrite it with an empty condition list
      if (token === EMPTY_TEMPLATE_ID && templateActions.length === 1) {
        const startAction = templateActions.find(
          action => action.actionType === FlowAction.Start,
        );

        const actions = startAction
          ? [
              {
                ...startAction,
                conditionList: {
                  __typename: 'FlowV2_ConditionList' as const,
                  exp: '([0])',
                  conditions: [],
                },
              },
            ]
          : [];

        const clientActions = getFlowActionsToClientActions(actions);

        setInitialActions(clientActions);
        setActions(clientActions);

        setActiveInteraction({
          type: 'updateAction',
          action: clientActions[0],
        });
      } else {
        const clientActions = getFlowActionsToClientActions(
          templateActions || [],
        );
        setInitialActions(clientActions);
        setActions(clientActions);
      }
    }
  }, [
    data?.copyFlow,
    setActions,
    setActiveInteraction,
    setFlowHasChanges,
    token,
  ]);

  if (error) {
    return (
      <JustificationContainer
        width="100%"
        padding={['xl']}
        justification="center"
        align="center"
      >
        <AppErrorScreen
          setBackgroundColor={false}
          message={Catalog.genericUnknownErrorMessage}
        />
      </JustificationContainer>
    );
  }

  if (loading || !data?.copyFlow)
    return (
      <>
        <MetaTags>
          <title>{createPageTitle('Laden...')}</title>
        </MetaTags>
        <DatHuisLoading />
      </>
    );

  return (
    <BuilderWithContext
      flowId={data?.copyFlow.id}
      initialFlow={{
        flowName: initialName ?? data?.copyFlow.name,
        flowDescription: initialDescription ?? data?.copyFlow.description,
        enabled: data?.copyFlow.enabled,
        maximumFlowRun: data?.copyFlow.maximumFlowRun,
        actions: initialActions,
      }}
    />
  );
};

export default withRouterTransitionContext(CreateFlowBuilder);
