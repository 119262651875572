import React from 'react';

import { Helmet as MetaTags } from 'react-helmet';

import FullPageInformation from '~/components/template/FullPageInformation';
import { navigate } from '@gatsbyjs/reach-router';
import createPageTitle from '~/util/createPageTitle';

export const text = {
  pageTitle: 'Oeps!',
  title: 'Geen data gevonden',
  explanation:
    'Er is iets misgegaan bij het ophalen van de data van het account. Waarschijnlijk kan er geen verbinding gemaakt worden met de server. Probeer het nog een keer',
  buttonText: 'Terug naar dashboard',
};
type Props = {
  /** Alternate title, otherwise default will be used */
  title?: string;

  /** Alternate explanation, otherwise default will be used */
  explanation?: string;
};

const ErrorScreen: React.FCC<Props> = ({ explanation, title }) => (
  <>
    <MetaTags>
      <title>{createPageTitle(text.pageTitle)}</title>
    </MetaTags>
    <FullPageInformation
      dataTestId={'full-page-error-screen'}
      title={title || text.title}
      explanation={explanation || text.explanation}
      button={{ onClick: () => void navigate('/-/'), label: text.buttonText }}
    />
  </>
);

export default ErrorScreen;
