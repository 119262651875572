import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  IMAGE: `${cleanedFilename(__filename)}-image`,
  HEADING: `${cleanedFilename(__filename)}-heading`,
  BODY: `${cleanedFilename(__filename)}-body`,
  PRIMARY_BUTTON: `${cleanedFilename(__filename)}-primary-button`,
  SECONDARY_BUTTON: `${cleanedFilename(__filename)}-secondary-button`,
};
