import React from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './index.testid';

export type Props = {
  filteredItemsCount: number;
  totalContactsInAccount: number;
  selectedContactAmount: number;
};

const text = {
  contactCreatedToastTemplate: (name: string): string =>
    `Nieuw contact "${name}" toegevoegd!`,
  contactAmount: (amount: number) =>
    `${amount} ${amount === 1 ? 'contact' : 'contacten'}`,
};

const TableTotalAmountContainer: React.FCC<Props> = ({
  filteredItemsCount,
  totalContactsInAccount,
  selectedContactAmount,
}) => (
  <Container data-testid={TEST_ID.CONTAINER}>
    Toont {filteredItemsCount} van&nbsp;
    {text.contactAmount(totalContactsInAccount)} |
    <FilteredContactsText data-testid={TEST_ID.SELECTED_ITEMS_TEXT}>
      &nbsp;
      {text.contactAmount(selectedContactAmount)}
      &nbsp;geselecteerd
    </FilteredContactsText>
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('medium')};
    margin: ${theme.space('l')} 0;
  `,
);

const FilteredContactsText = styled.span<{}>``;

export default TableTotalAmountContainer;
