/**
 *
 * Provides fake storage over AWS Storage
 */

// import {
//   INVALID_PARAMETER,
//   INVALID_PARAMETER_FORGOT_PASS,
//   USER_DOES_NOT_EXIST,
//   INCORRECT_USERNAME_OR_PASSWORD,
//   USER_UNCONFIRMED,
//   USERNAME_EXISTS,
//   REGISTRATION_OK,
//   LOGIN_OK,
//   VERIFICATION_NON_EXISTENT_USER,
//   VERIFICATION_INVALID_TOKEN,
//   VALIDATION_ALREADY_VALIDATED,
//   NEW_PASSWORD_REQUIRED,
// } from './CognitoResponse';
import { delayedResolve, delayedReject } from './FakeAuth';
import avatar from './assets/avatar';
import uploadedImage from './assets/uploadedImage';

const Storage = {
  get: (key: string, options?: any): Promise<string | Object> => {
    // const url = 'http://localhost:4000/assets/images/avatar.png';
    // used options here only to calm down Flow
    const url = options ? avatar : avatar;

    if (key) return delayedResolve(url);
    else return delayedReject('error');
  },

  put: (key: string, object: any, options?: any): Promise<Object> => {
    // used options here only to calm down Flow
    const url = options || object ? uploadedImage : uploadedImage;

    if (key) return delayedResolve(url);
    else return delayedReject('error');
  },
};

export default Storage;
