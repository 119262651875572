import { isValidElement } from 'react';

/**
 * Returns the content of a React node as string
 * Credits to: https://github.com/sunknudsen/react-node-to-string/blob/master/src/index.ts
 *
 * @param {React.ReactNode | string} reactNode
 * keywords: nodestring, reacttext
 */
const reactNodeToString = function (
  reactNode: React.ReactNode | string,
): string {
  let string = '';
  if (typeof reactNode === 'string') {
    string = reactNode;
  } else if (typeof reactNode === 'number') {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach(function (child) {
      string += reactNodeToString(child);
    });
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props.children);
  }

  return string;
};

export default reactNodeToString;
