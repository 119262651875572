import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  DESCRIPTION: `${cleanedFilename(__filename)}-description`,
  NAME: `${cleanedFilename(__filename)}-name`,
  CHECKBOX: `${cleanedFilename(__filename)}-checkbox`,
  ASIDE_HEADER: `${cleanedFilename(__filename)}-aside-header`,
  DIALOG: `${cleanedFilename(__filename)}-dialog`,
};
