import React, { useState, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import useCurrentUser from '~/hooks/useCurrentUser';
import BaseButton from '../components/Base';
import JustificationContainer from '~/components/atom/JustificationContainer';
import ResponsivePopup, {
  BoxShadowContainer,
} from '~/components/molecule/ResponsivePopup';
import Icon from '~/components/atom/Icon';
import { tooltipText } from '../text';
import TEST_ID from './index.testid';
import UploadFileContainer from './components/UploadFileContainer';
import InsertUrlContainer from './components/InsertUrlContainer';
import { DHEditor } from '~/components/organism/PluginsEditor/types';

export type Props = {
  editor: DHEditor;
};

export const acceptedFileTypes = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
];

enum UploadOptions {
  File = 'file',
  Url = 'url',
}

const InsertImageButton: React.FCC<Props> = ({ editor, dataTestId }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [activeTab, setActiveTab] = useState<UploadOptions>(UploadOptions.File);

  const { id: userId } = useCurrentUser();

  const onClose = useCallback(() => {
    setShowPopup(false);
    setActiveTab(UploadOptions.File);
  }, []);

  const baseButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Container>
      <BaseButton
        onClick={event => {
          event.preventDefault();
          if (!showPopup) {
            setShowPopup(true);
          } else {
            onClose();
          }
        }}
        icon="image"
        active={showPopup}
        tooltipMessage={showPopup ? '' : tooltipText.insertImage}
        dataTestId={dataTestId}
        ref={baseButtonRef}
      />
      <ResponsivePopup
        onClickOutside={() => {
          setShowPopup(false);
          setActiveTab(UploadOptions.File);
        }}
        dataTestId={TEST_ID.CONTAINER}
        isVisible={showPopup}
        elementToPlaceUnder={baseButtonRef.current}
      >
        <BoxShadowContainer>
          <JustificationContainer margin={[null, null, 's']}>
            <TabButton
              name="image"
              onClick={() => setActiveTab(UploadOptions.File)}
              data-testid={TEST_ID.FILE_TAB}
              $active={activeTab === UploadOptions.File}
            />
            <TabButton
              name="link"
              onClick={() => setActiveTab(UploadOptions.Url)}
              data-testid={TEST_ID.LINK_TAB}
              $active={activeTab === UploadOptions.Url}
            />
          </JustificationContainer>

          {activeTab === UploadOptions.File && (
            <UploadFileContainer
              editor={editor}
              onClose={onClose}
              userId={userId}
            />
          )}

          {activeTab === UploadOptions.Url && (
            <InsertUrlContainer
              editor={editor}
              onClose={onClose}
              userId={userId}
            />
          )}
        </BoxShadowContainer>
      </ResponsivePopup>
    </Container>
  );
};

const Container = styled.div<{}>`
  position: relative;
`;

const TabButton = styled(Icon)<{ $active: boolean }>(
  ({ theme, $active }) => css`
    margin-right: ${theme.space('xs')};
    color: ${$active
      ? theme.color('primary', 'light')
      : theme.color('tertiary')};

    cursor: pointer;
    &:hover {
      color: ${theme.color('primary', 'light')};
    }
    transition: all 0.3s ease;
  `,
);

export default InsertImageButton;
