import { isNil, groupBy } from 'ramda';
import React, { useEffect, useReducer, useState } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { FlowV2_Action_SendNotificationFragment } from '~/graphql/types';
import { Props as FormProps } from '../ActionForm';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import TextEditorContainer from '~/components/atom/TextEditorContainer';
import TemplateStringInput from '~/components/organism/TemplateStringInput';
import EmailAttachments from '../SendEmailPlain/components/EmailAttachments';
import InputLabel from '~/components/atom/InputLabel';
import PluginsEditor from '~/components/organism/PluginsEditor';
import MultiSelectInput from '~/components/molecule/MultiSelectInput';
import useEmailFileAttachments from '../../hooks/useEmailFileAttachments';
import useSendNotificationTargetOptions from './hooks/useSendNotificationTargetOptions';
import TEST_ID from './index.testid';
import { reducer, type State } from './reducer';
import useDHEditorInFlowBuilder from '../../hooks/useDHEditorInFlowBuilder';
import usePollingForOfficeEmail from './hooks/usePollingForOfficeEmail';
import { NOTIFICATION_EDITOR_BUTTONS } from './constants';
import { Body } from '~/components/atom/Typography';

export type Props = FormProps & {
  action: FlowV2_Action_SendNotificationFragment;
};

const text = {
  emailTypeLabel: 'Type',
  subjectLabel: 'Onderwerp',
  addAttachmentButtonLabel: 'uit een stap',
  or: 'of',
  to: 'Aan:',
  placeholder: 'Kies ontvangers',
};

const SendNotification: React.FCC<Props> = ({ action, onChange }) => {
  const { allOptions, selectedOptions } = useSendNotificationTargetOptions({
    action,
  });

  const initials: State = {
    to: action.to,
    toOptions: selectedOptions,
    subject: action.subject,
    body: action.body,
    attachments: action.attachments,
  };

  const [state, dispatch] = useReducer(reducer, initials);
  const [error, setError] = useState<string | null>(null);

  usePollingForOfficeEmail({
    selectedOptions: state.toOptions,
    onUpdate: officesWithEmails => {
      dispatch({
        type: 'updateOfficeEmailState',
        payload: { officeEmails: officesWithEmails },
      });
    },
  });

  const groupedAttachments =
    state.attachments.length > 0
      ? groupBy(attachment => {
          if (attachment?.inlineId) return 'image';
          return 'file';
        }, state.attachments)
      : { file: [], image: [] };

  useEffect(() => {
    onChange({ ...action, ...state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const { value: bodyValue, onChange: onBodyChange } = useDHEditorInFlowBuilder(
    {
      actionId: action.id,
      html: initials.body.template || '',
      mappings: initials.body.mappings,
      customElements: [ELEMENTS.VARIABLE],
      updaterFn: updatedValue => {
        dispatch({ type: 'updateBodyValue', payload: updatedValue });
      },
    },
  );

  const { value: subjectValue, onChange: onSubjectChange } =
    useDHEditorInFlowBuilder({
      actionId: action.id,
      html: initials.subject.template || '',
      mappings: initials.subject.mappings,
      updaterFn: updatedValue => {
        dispatch({ type: 'updateSubjectValue', payload: updatedValue });
      },
    });

  const {
    insertPointerAttachment,
    updatePointerAttachment,
    insertFileAttachment,
    onDelete,
    fileAttachments,
  } = useEmailFileAttachments({
    initialfileAttachments: groupedAttachments.file,
    setError,
  });

  useEffect(() => {
    setError(null);
    dispatch({ type: 'updateAttachments', payload: fileAttachments });
  }, [fileAttachments]);

  return (
    <>
      <InputLabel label={text.to} />
      <MultiSelectInput
        options={allOptions}
        selectedOptions={state.toOptions}
        onSelected={option =>
          dispatch({ type: 'addTargetOption', payload: option })
        }
        onRemoved={option =>
          dispatch({ type: 'removeTargetOption', payload: option })
        }
        maxSelectedOptionAmount={5}
        placeholder={text.placeholder}
        icon={{
          name: 'search',
          color: { group: 'primary', variant: 'light' },
        }}
        dataTestId={TEST_ID.TARGET_INPUT_FIELD}
      />

      <JustificationContainer
        direction="column"
        margin={['base', null]}
        width="100%"
      >
        <InputLabel label={text.subjectLabel} />
        <TemplateStringInput
          $key={0}
          value={subjectValue}
          onChange={onSubjectChange}
          dataTestId={TEST_ID.SUBJECT_FIELD}
        />
      </JustificationContainer>

      <JustificationContainer margin={['base', null]}>
        <TextEditorContainer>
          <PluginsEditor
            key={10000}
            customElements={[ELEMENTS.VARIABLE]}
            value={bodyValue}
            onChange={onBodyChange}
            minHeight={150}
            dataTestId={TEST_ID.BODY_FIELD}
            toolbarButtons={NOTIFICATION_EDITOR_BUTTONS}
          />
        </TextEditorContainer>
      </JustificationContainer>

      <EmailAttachments
        action={action}
        attachments={fileAttachments}
        onPointerInsert={insertPointerAttachment}
        onPointerUpdate={updatePointerAttachment}
        onFileUpload={insertFileAttachment}
        onError={error => setError(error)}
        onDelete={onDelete}
      />

      {!isNil(error) && <Body color={{ group: 'danger' }}>{error}</Body>}
    </>
  );
};

export default SendNotification;
