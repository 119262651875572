import type { PermissionId } from '~/graphql/types';
import { type Props as IconProps } from '../../../atom/Icon';

export const SIDE_MIN_WIDTH = '4rem';
export const SIDE_MAX_WIDTH = '15rem';

export const navigationConfig: Array<{
  icon: IconProps;
  label: React.ReactNode;
  to: string;
  permissionId?: PermissionId;
}> = [
  {
    icon: {
      name: 'desktop',
      skewed: true,
    },
    to: '/-/',
    label: 'Dashboard',
  },
  {
    icon: {
      name: 'check-square',
      skewed: true,
    },
    to: '/-/tasks',
    label: 'Taken',
  },
  {
    icon: {
      name: 'users',
      skewed: true,
    },
    to: '/-/contacts',
    label: 'Contacten',
  },
  {
    icon: {
      name: 'branches',
      skewed: true,
    },
    to: '/-/automation/flows',
    label: 'Automation',
    permissionId: 'root.automation',
  },
  {
    icon: {
      name: 'copy',
      skewed: true,
    },
    to: '/-/apps',
    label: 'Apps',
  },
  {
    icon: {
      name: 'file-text',
      skewed: true,
    },
    to: '/-/forms',
    label: 'Formulieren',
  },
  {
    icon: {
      name: 'email-sign',
      skewed: true,
    },
    to: '/-/widget',
    label: 'Webwinkel widget',
  },
];
