import { SessionHydrationOfficeFieldsFragment } from '~/graphql/types';
import useOfficeLookup from '../useOfficeLookup';
import useUsers from '../useUsers';

const useOffices = ({
  userIds = [],
  onlyExistingOffices = true,
}: {
  userIds?: Array<string | null>;
  onlyExistingOffices?: boolean;
}): Array<SessionHydrationOfficeFieldsFragment> => {
  const offices = useOfficeLookup(onlyExistingOffices);
  const users = useUsers();

  if (!offices) return [];
  const arrayOfOfficesFromLookup = Object.values(offices);
  if (userIds.length === 0) return arrayOfOfficesFromLookup;

  const filteredUsers = users.filter(user => userIds.includes(user.id));

  const officesToUsers = arrayOfOfficesFromLookup.filter(office =>
    filteredUsers.find(user => {
      const userOfficeIds = user.officeIds.map(
        userOffice => userOffice.officeId,
      );
      return userOfficeIds.includes(office.id);
    }),
  );
  return officesToUsers;
};

export default useOffices;
