import {
  OutputField,
  OutputFieldAccordions,
  OutputFieldList,
} from '~/components/organism/Wizard/context/WizardContext';
import { OutputMap } from '~/components/organism/WizardSteps';
import { ImageList } from '../../Apps/components/AppStatusOverview/components/OutputImageGrid';
import { ColorList } from '../../Apps/components/AppStatusOverview/components/OutputColorGrid';
import { Items } from '../../Apps/components/AppStatusOverview/components/OutputList';
import { Item } from '../../Apps/components/AppStatusOverview/components/OutputItem';
import { Plain } from '../../Apps/components/AppStatusOverview/components/OutputPlain';

export type WithCategory<T> = {
  category: React.ReactNode | string;
  values: T;
};

export type ReturnValue = {
  images: Array<WithCategory<ImageList>>;
  colors: Array<WithCategory<ColorList>>;
  lists: Array<WithCategory<Items>>;
  items: Array<WithCategory<Item>>;
  plains: Array<WithCategory<Plain>>;
  accordions: Accordions;
};

type Accordions = Array<
  WithCategory<
    Array<{
      label: string;
      children: ReturnValue;
    }>
  >
>;

const getKeyForType = (
  type: Exclude<OutputField<any>['type'], 'invisible'>,
): keyof ReturnValue | null => {
  switch (type) {
    case 'plain':
      return 'plains';
    case 'color':
      return 'colors';
    case 'image':
      return 'images';
    case 'list':
      return 'lists';
    case 'item':
      return 'items';
    case 'accordions':
      return 'accordions';
    default:
      return null;
  }
};

const getOutputsForOverview = (
  outputMap: OutputMap,
  stepIDs: Array<string> = [],
): ReturnValue => {
  const accBase: ReturnValue = {
    colors: [],
    images: [],
    items: [],
    lists: [],
    plains: [],
    accordions: [],
  };

  const processEntry = (entry: OutputField<any>, acc: ReturnValue) => {
    if (entry.type === 'invisible') return;

    const accKey = getKeyForType(entry.type);
    if (!accKey) return;

    const categoryIndex = getCategoryIndex(acc[accKey], entry);

    acc[accKey][categoryIndex] = acc[accKey][categoryIndex] ?? {
      category: entry.category,
      values: [],
    };

    if (isAccordion(entry) && accKey === 'accordions') {
      entry.values.forEach(value => {
        const children: ReturnValue = {
          colors: [],
          images: [],
          items: [],
          lists: [],
          plains: [],
          accordions: [],
        };

        value.children.forEach(child => {
          if (!child || child.type === 'invisible') return;
          processEntry(child, children);
        });

        acc[accKey][categoryIndex].values.push({
          label: value.label,
          children,
        });
      });
    } else {
      if (
        !isListOutput(entry) &&
        !isAccordion(entry) &&
        accKey !== 'accordions'
      ) {
        acc[accKey][categoryIndex].values.push({
          label: entry.label ?? '',
          value: entry.value,
          icon: 'icon' in entry ? entry.icon : undefined,
        });
      }

      if (isListOutput(entry) && accKey === 'lists') {
        acc[accKey][categoryIndex].values.push({
          value: entry.value,
        });
      }
    }
  };

  const processStep = (stepId: string, acc: ReturnValue) => {
    const currentOutput = outputMap[stepId];
    if (!currentOutput) return;

    Object.keys(currentOutput).forEach(entryKey => {
      if (entryKey === 'type') return;

      const entry: OutputField<any> = currentOutput[entryKey];
      if (entry) processEntry(entry, acc);
    });
  };

  stepIDs.forEach(stepId => processStep(stepId, accBase));

  return accBase;
};

const isListOutput = (
  field: OutputField<any>,
): field is OutputFieldList<any> => {
  if (field.type === 'list') return true;
  return false;
};

const isAccordion = (
  field: OutputField<any>,
): field is OutputFieldAccordions<any> => {
  if (field.type === 'accordions') return true;
  return false;
};

const getCategoryIndex = (categoryArray: Array<any>, entry: any): number => {
  const categoryIndex = categoryArray.findIndex(
    categoryItem => categoryItem && categoryItem.category === entry.category,
  );

  return categoryIndex !== -1 ? categoryIndex : categoryArray.length;
};

export default getOutputsForOverview;
