import { useApolloClient } from '@apollo/client';
import Amplify from '~/amplify';
import { useCallback, useContext } from 'react';
import useIntercom from '../useIntercom';
import { AuthContext } from '~/Root/Auth';
import type { SignOutOpts } from '@aws-amplify/auth/lib-esm/types';
import useErrorReporter from '../useErrorReporter';
import useAddToast from '../useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Catalog from '~/Catalog';

type LogoutFn = (opts?: SignOutOpts) => Promise<void>;

const useLogout = (): LogoutFn => {
  const client = useApolloClient();
  const intercom = useIntercom();
  const reporter = useErrorReporter();
  const addToast = useAddToast();

  const { ensureLogin } = useContext(AuthContext);

  const logout = useCallback(
    async (opts?: SignOutOpts) => {
      try {
        await Amplify.Auth.signOut(opts);
        await client.clearStore();

        intercom.logOff();
        ensureLogin();
      } catch (error) {
        reporter.captureException(error);
        return addToast([
          formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
        ]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, intercom, ensureLogin],
  );

  return logout;
};

export default useLogout;
