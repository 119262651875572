import { isEmpty, isNil, mergeRight } from 'ramda';
import {
  SessionHydrationOfficeFieldsFragment,
  DeletedOfficeToAccountRelationship,
} from '~/graphql/types';
import useSessionHydration from '../useSessionHydration';

export type OfficeLookupTable = {
  [officeId: string]: SessionHydrationOfficeFieldsFragment;
};

const useOfficeLookup = (
  onlyExistingOffices: boolean = false,
): OfficeLookupTable | null => {
  const [{ offices, deletedRelations }] = useSessionHydration();

  if (isEmpty(offices) || isNil(offices)) return null;

  const existingOfficesLookupTable: OfficeLookupTable = {};
  for (const office of offices) {
    if (!office.deleted) existingOfficesLookupTable[office.id] = office;
  }

  const deletedOfficesLookupTable: OfficeLookupTable = {};
  const deletedOfficeRelations = deletedRelations.filter(
    relation => relation.__typename === 'DeletedOfficeToAccountRelationship',
  ) as Array<DeletedOfficeToAccountRelationship>;

  for (const deletedRelation of deletedOfficeRelations) {
    deletedOfficesLookupTable[deletedRelation.officeId] = {
      __typename: 'Office',
      name: deletedRelation.name,
      id: deletedRelation.officeId,
      deleted: true,
    };
  }

  if (onlyExistingOffices) return existingOfficesLookupTable;

  const allOfficesLookup = mergeRight(
    existingOfficesLookupTable,
    deletedOfficesLookupTable,
  );

  return allOfficesLookup;
};

export default useOfficeLookup;
