import { useRecoilValue } from 'recoil';
import useIsBasicForm from '../useIsBasicForm';
import { nodesSubmitAndAfterState } from '../../state/submitScreen';
import { contactInputBlocksUsed } from '../../state/contactInputBlocks';
import {
  BLOCK_OPTIONS,
  type ScreenNodeBlock_FE,
} from '../../components/InteractionHandler/components/EditNodeScreen/constants';
import type { FormBuilder_ScreenNode_Block } from '~/graphql/types';
import isUITypeBlock from '../../utils/typeguards/isUITypeBlock';
import type { OptionBase, OptionOf } from '~/components/molecule/Dropdown';
import isContactBlock from '../../utils/typeguards/isContactBlock';
import isBlockWithOutput from '../../utils/typeguards/isBlockWithOutput';

type ComponentOption = OptionOf<ScreenNodeBlock_FE>;
type ComponentOptions = {
  formOptions: Array<ComponentOption>;
  contactOptions: Array<ComponentOption>;
  uiOptions: Array<ComponentOption>;
};

type ReturnType = {
  options: Array<ComponentOption>;
  components: ComponentOptions;
};

const useBlockOptions = ({ nodeId }: { nodeId: string }): ReturnType => {
  const contactInputsUsed = useRecoilValue(contactInputBlocksUsed);
  const isBasicForm = useIsBasicForm();
  const nodesSubmitAndAfter = useRecoilValue(nodesSubmitAndAfterState);
  const onlyHasUIBlocks = isBasicForm && nodesSubmitAndAfter.includes(nodeId);

  const optionDisabled = 'DISABLED' as OptionBase['type'];
  const _options = BLOCK_OPTIONS.map(option => {
    if (
      option.key === 'FormData_Input_Contact_Email' &&
      contactInputsUsed.Contact_Email
    )
      return { ...option, type: optionDisabled };

    if (
      option.key === 'FormData_Input_Contact_Phone' &&
      contactInputsUsed.Contact_Phone
    )
      return { ...option, type: optionDisabled };

    if (
      option.key === 'FormData_Input_Contact_Name' &&
      contactInputsUsed.Contact_Name
    )
      return { ...option, type: optionDisabled };

    return option;
  });

  const options: Array<ComponentOption> = onlyHasUIBlocks
    ? _options.filter(
        option =>
          !isBlockWithOutput({
            __typename: option.key,
          } as FormBuilder_ScreenNode_Block),
      )
    : _options;

  const components = options.reduce(
    (acc, curr: ComponentOption) => {
      const blockType = curr.key as FormBuilder_ScreenNode_Block['__typename'];

      if (isContactBlock(blockType)) {
        acc.contactOptions.push(curr);
        return acc;
      }
      if (isUITypeBlock(blockType)) {
        acc.uiOptions.push(curr);
        return acc;
      }

      acc.formOptions.push(curr);
      return acc;
    },
    {
      formOptions: [],
      contactOptions: [],
      uiOptions: [],
    } as ComponentOptions,
  );

  return { options, components };
};

export default useBlockOptions;
