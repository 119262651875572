import type { Feature, GeoJsonProperties, Geometry } from 'geojson';
import type { GeoJsonFeature } from '~/graphql/types';
import getClientGeometry from '../getClientGeometry';
import { v4 as uuidv4 } from 'uuid';

// Export for the tests
export type Args = {
  feature: GeoJsonFeature;
  inclusive: boolean;
  officeId: string;
  readOnly?: boolean;
};

const featureToClientFeature = ({
  feature,
  inclusive,
  officeId,
  readOnly = false,
}: Args): Feature<Geometry, GeoJsonProperties> => ({
  ...feature,
  type: 'Feature',
  id: `${officeId}-${uuidv4()}`,
  geometry: getClientGeometry(feature.geometry),
  properties: {
    inclusive,
    readOnly,
  },
});

export default featureToClientFeature;
