import { ImmutableHtmlElement } from '~/components/organism/PluginsEditor/types';

/**
 * From: https://stackoverflow.com/a/6234804/12475047
 */
const escapeHtml = (unsafe: string): string =>
  unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

/**
 * Why do we need dh-innerhtml attribute?
 * We want an untouched string to be able to display `dangerouslySetInnerHtml` in the ImmutableHtmlElement.
 * If we pass it as children then all the children will be deserialized in Slate as if they are normal elements.
 * We do not want that. ImmutableHtmlElement shall have no children.
 *
 * When we receive the html string from the backend:
 * We find the <dhimmutable> tag. We only care about its `dh-innerhtml` attribute.
 * We use it to render `dangerouslySetInnerHtml`. We set empty children to the element.
 *
 * When the changes are saved:
 * We send the untouched html string to the backend using the `dh-innerhtml` attribute
 * but this time we also need to insert it inside the <dhimmutable> tag so that the elements
 * show up in the email client.
 *
 */
const serialize = (node: ImmutableHtmlElement): string => {
  const innerHtml = node.dhInnerHtml || '';

  // We need to escape HTML special characters inside double quotes
  const escapedInnerHtml = escapeHtml(innerHtml);

  return `<dhimmutable dh-innerhtml="${escapedInnerHtml}">${innerHtml}</dhimmutable>`;
};
export default serialize;
