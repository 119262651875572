import { isString } from 'lodash';
import Catalog from '~/Catalog';
import Validation from '~/util/Validation';
import { FormData } from '../..';

/**
 * Validates form values for Realworks email sync
 */
const validateFormValues = ({ email, password }: FormData) => {
  const errors: {
    password: string | undefined;
    email: string | undefined;
  } = {
    password: undefined,
    email: undefined,
  };

  if (Validation.String.isEmpty(email)) {
    errors.email = Catalog.noEmail;
  } else if (!Validation.Email.isValid(email)) {
    errors.email = Catalog.invalidEmail;
  }

  const validPassword =
    password !== null && isString(password) && password.length > 0;

  if (!validPassword) {
    errors.password = Catalog.noPassword;
  }

  return errors;
};

export default validateFormValues;
