import type { Node_Condition_Date__Input } from '~/graphql/types';
import type { DateFilter } from './types.flow';

import { OPERATOR } from './constants';
import { startOfDate, endOfDate } from '~/util/date';
import cleanedFilename from '~/util/cleanedFilename';

const asAssignedToFilterInput = (
  filter: DateFilter,
): Node_Condition_Date__Input => {
  const { negate, operator, date } = filter;

  const startDate = startOfDate(date);
  const endDate = endOfDate(date);

  const baseFilterInput = {
    negate,
  };

  switch (operator) {
    case OPERATOR.EQUALS:
      return {
        ...baseFilterInput,
        gte: startDate,
        lte: endDate,
      };
    case OPERATOR.GREATER_THAN_EQUAL:
      return {
        ...baseFilterInput,
        gte: startDate,
      };
    case OPERATOR.LESS_THAN_EQUAL:
      return {
        ...baseFilterInput,
        lte: endDate,
      };

    // Not used right now, but usefull when adding date ranges in near futures
    case OPERATOR.GREATER_THAN:
      return {
        ...baseFilterInput,
        gt: startDate,
      };
    case OPERATOR.LESS_THAN:
      return {
        ...baseFilterInput,
        lt: startDate,
      };
    default:
      throw new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Operator type ${operator} does not exist`,
      );
  }
};

export default asAssignedToFilterInput;
