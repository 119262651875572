import { ArgumentId } from '../..';

const isActiveConditionSubExpression = ({
  activeSelector,
  argumentId: { conditionSubExpressionIdx },
}: {
  activeSelector?: ArgumentId | null;
  argumentId: { conditionSubExpressionIdx: number };
}): boolean =>
  activeSelector != null &&
  activeSelector.conditionSubExpressionIdx === conditionSubExpressionIdx;

export default isActiveConditionSubExpression;
