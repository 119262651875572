import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import ContentContainerDefault, {
  MAX_CONTAINER_WIDTH,
} from '~/components/molecule/ContentContainer';
import { Heading2 } from '~/components/atom/Typography';
import Loading from '~/components/atom/Loading';
import ErrorScreen from '~/components/page/ErrorScreen';
import useApp from '~/hooks/useApp';
import TokenPage from './components/TokenPage';
import MasterDetail from '~/components/template/MasterDetail';
import createPageTitle from '~/util/createPageTitle';

type Props = RouteComponentProps & {};

export const text = {
  appTitle: 'Contaqt - Nieuwsbrief',
};

const Contaqt: React.FCC<Props> = () => {
  const { app, loading, updateQuery } = useApp('AppStatus_Contaqt');

  if (loading) return <Loading />;
  if (!app || !app?.appStatus.enabled) {
    return <ErrorScreen />;
  }

  return (
    <ContentContainerDefault
      maxContentWidth={MAX_CONTAINER_WIDTH}
      breadcrumbs={[
        {
          to: '/-/apps',
          label: 'Apps',
        },
        { label: text.appTitle },
      ]}
    >
      <MetaTags>
        <title>{createPageTitle(text.appTitle)}</title>
      </MetaTags>
      <Heading2 color={{ group: 'primary' }} margin={[null, null, 'xl', null]}>
        {text.appTitle}
      </Heading2>

      <MasterDetail
        basePath={'/-/'}
        navbar={[
          {
            type: 'link',
            to: 'apps/contaqt',
            icon: 'key',
            name: 'Koppeling aanpassen',
          },
        ]}
      >
        <TokenPage app={app} path="/" default updateQuery={updateQuery} />
      </MasterDetail>
    </ContentContainerDefault>
  );
};

export default Contaqt;
