import React from 'react';

const text = {
  unknown: 'Onbekend',
  dayLabel: {
    singular: 'dag',
    plural: 'dagen',
  },
};

export const asPriceString = (
  price: number | null | undefined,
): React.ReactNode => <span>{asPriceStringValue(price)}</span>;

export const asPriceStringValue = (
  price: number | null | undefined,
): string => {
  if (price == null) return text.unknown;

  const baseString = price.toString();
  const length = baseString.length;
  if (length === 0) return text.unknown;

  const priceString = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // \u20AC = euro
  return `\u20AC ${priceString}`;
};

export const asAreaString = (
  area: number | null | undefined,
): React.ReactNode => {
  if (area == null) return <span>{text.unknown}</span>;

  return (
    <span>
      {area.toString()} m<sup>2</sup>
    </span>
  );
};

export const asNumberOfDaysString = (numberOfDays: number): string =>
  numberOfDays === 1
    ? `${numberOfDays} ${text.dayLabel.singular}`
    : `${numberOfDays} ${text.dayLabel.plural}`;

export const generateConcatenatedNonNullString = (
  strings: Array<string | undefined | null>,
  delimeter: string,
) => {
  const resultString: Array<string> = [];
  strings.map(string => {
    if (string != null && string !== '') resultString.push(string);
  });

  return resultString.join(delimeter);
};
