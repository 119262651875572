import { useCallback } from 'react';
import { useReactFlow } from 'reactflow';
import { DEFAULT_WIDTH } from '../../components/nodeTypes/constants';
import useViewportSize from '~/hooks/useViewportSize';

const LEFT_PADDING = 300;

/**
 * @returns a function that expects a node's x & y position to pan to it
 */

const usePanTo = () => {
  const { width, height } = useViewportSize();
  const { fitBounds } = useReactFlow();

  const panTo = useCallback(
    ({ x, y, zoom = 0.5 }: { x: number; y: number; zoom?: number }) => {
      fitBounds(
        {
          x: x > 0 ? x - LEFT_PADDING - DEFAULT_WIDTH / 3 : -LEFT_PADDING,
          y: y,
          width: width / 2 + LEFT_PADDING,
          height: height / 2,
        },
        { duration: 1000, padding: zoom },
      );
    },
    [width, height, fitBounds],
  );

  return panTo;
};

export default usePanTo;
