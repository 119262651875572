import { selector } from 'recoil';
import {
  FormBuilderMode,
  type FormBuilder_Event,
  type FormBuilder_EventNode_Mapping,
  type FormBuilder_NodeFragment,
} from '~/graphql/types';
import { formState, formModeSelector } from '.';
import { eventsState } from './nodesAndEvents';
import { isNil } from 'ramda';
import getNewEventTemplate from '../utils/getNewEventTemplate';
import { submitScreenState } from './submitScreen';
import deleteNode from '../utils/deleteNode';
import generateEventFields from '../utils/autoGeneratedEventUtils/generateEventFields';
import generateMappingsForSubmitEventNode from '../utils/autoGeneratedEventUtils/generateMappingsForSubmitEventNode';

/**
 * This is updated by using setAutoGeneratedEvent({}). Do not mind the empty object as parameter,
 * it is not doing anything. Make sure to call this setter on every block insert, delete, update
 * and screen insert and delete.
 */
export const autoGeneratedEvent = selector<FormBuilder_Event | {}>({
  key: 'formBuilder/autoGeneratedEvent',
  get: ({ get }) => {
    const events = get(eventsState);
    const formMode = get(formModeSelector);
    if (formMode !== FormBuilderMode.Basic) return {};

    return events[0];
  },

  set: ({ get, set }) => {
    const formMode = get(formModeSelector);
    const prevForm = get(formState);

    if (
      !prevForm ||
      !prevForm.nodes ||
      !prevForm.events ||
      formMode !== FormBuilderMode.Basic
    )
      return;

    // If the submit screen node is deleted, delete the created EventNode and Event for it as well
    const submitScreen = get(submitScreenState);
    if (isNil(submitScreen)) {
      const eventNode = prevForm.nodes.find(
        n => n.__typename === 'FormBuilder_EventNode',
      );
      if (eventNode) {
        const updatedNodes = deleteNode({
          nodes: prevForm.nodes,
          nodeId: eventNode.id,
        });
        set(formState, {
          ...prevForm,
          events: [],
          nodes: updatedNodes,
        });
      }
      return;
    }

    const event = { ...(prevForm.events[0] || getNewEventTemplate()) };

    const { generatedEventFields, eventKeyLookup } = generateEventFields({
      nodes: prevForm.nodes,
      event,
    });

    // Update the submit event with the created fields
    const updatedEvents = [
      {
        ...event,
        fields: [...generatedEventFields],
      },
    ];

    // Generate mappings for the submit event node using the created event fields
    const generatedMappings: Array<FormBuilder_EventNode_Mapping> =
      generateMappingsForSubmitEventNode({
        nodes: prevForm.nodes,
        eventKeyLookup,
      });

    // Update mapping for the submit event node using the generated mappings
    const updatedNodes: Array<FormBuilder_NodeFragment> = [
      ...prevForm.nodes.map(node => {
        if (node.__typename === 'FormBuilder_ScreenNode') return node;

        return {
          ...node,
          formBuilderEventId: event.id,
          mapping: generatedMappings,
        };
      }),
    ];

    set(formState, {
      ...prevForm,
      events: updatedEvents,
      nodes: updatedNodes,
    });
  },
});
