import {
  BasePrimitive,
  Flow___PrimitiveArgument,
} from '~/graphql/types.client';

/**
 * Return the base primitive value for PrimitiveArgument
 * @param {Flow___PrimitiveArgument | null} argument
 * keywords: Primitive argument, base primitive
 */
const getValueForArgument = (
  argument: Flow___PrimitiveArgument | null,
): BasePrimitive => {
  if (argument === null) return null;

  switch (argument.__typename) {
    case 'Flow___Argument_AWSDateTime':
      return argument.value_date;
    case 'Flow___Argument_Boolean':
      return argument.value_boolean;
    case 'Flow___Argument_Float':
      return argument.value_float;
    case 'Flow___Argument_Integer':
      return argument.value_integer;
    case 'Flow___Argument_String':
      return argument.value_string || null;

    default:
      return null;
  }
};

export default getValueForArgument;
