import React from 'react';
import Div from '~/components/atom/Div';
import { IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading3, Variant } from '~/components/atom/Typography';
import OutputAccordion from '~/components/organism/OutputAccordion';
import OutputItem from '~/components/organism/WizardSteps/Apps/components/AppStatusOverview/components/OutputItem';
import OutputList from '~/components/organism/WizardSteps/Apps/components/AppStatusOverview/components/OutputList';
import OutputPlain from '~/components/organism/WizardSteps/Apps/components/AppStatusOverview/components/OutputPlain';
import { ReturnValue } from '~/components/organism/WizardSteps/utils/getOutputsForOverview';
import type { SystemColorPalette } from '~/theme';

export type Props = {
  dataTestId?: string;

  /**
   * Changes background color and chevron color of Accordion containers
   */
  variant?: keyof SystemColorPalette;

  /**
   * Values to display in collapsible part
   */
  values: Array<{
    label: string;
    children: ReturnValue;
  }>;

  /**
   * Header of the Accordion group e.g. Enabled apps
   */
  header: React.ReactNode;

  /**
   * Icon of each accordion output header
   */
  icon?: IconType;

  /**
   * Description of the group
   */
  description: string;
};

const AccordionOutputGroup: React.FC<Props> = ({
  header,
  description,
  values,
  variant = 'primary',
  icon,
}) => (
  <Div width="100%">
    <Heading3 size="m" variant={Variant.primary}>
      {header}
    </Heading3>
    <Body>{description}</Body>

    {values &&
      values.length > 0 &&
      values.map(value => {
        const { items, lists, plains } = value.children;

        return (
          <OutputAccordion
            header={{ icon, label: value.label }}
            key={value.label}
            variant={variant}
          >
            <JustificationContainer gap="m" direction="column">
              {plains.map((value, index) => (
                <OutputPlain key={index} {...value} />
              ))}
              {items?.map((grid, index) => (
                <OutputItem key={index} {...grid} />
              ))}
              {lists?.map((grid, index) => (
                <OutputList key={index} {...grid} />
              ))}
            </JustificationContainer>
          </OutputAccordion>
        );
      })}
  </Div>
);

export default AccordionOutputGroup;
