import React from 'react';
import Dropdown, { OptionOf } from '~/components/molecule/Dropdown';
import { Label } from '~/components/atom/Typography';
import { GetOverviewStatisticsQueryVariables } from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  today: Date;
  dateRanges: Array<
    OptionOf<{
      start: GetOverviewStatisticsQueryVariables['start'];
      end: GetOverviewStatisticsQueryVariables['end'];
    }>
  >;
  selectedDateRangeIndex: number;
  onDateRangeChange: (dateRangeIndex: number) => void;
};

const DropdownGroup: React.FCC<Props> = ({
  dataTestId,
  dateRanges,
  selectedDateRangeIndex,
  onDateRangeChange,
}) => (
  <JustificationContainer
    dataTestId={dataTestId}
    justification="start"
    align="center"
    gap="m"
  >
    <Label
      margin={[null]}
      whiteSpace="nowrap"
      color={{ group: 'tertiary', variant: 'dark' }}
      size="base"
    >
      Toon statistieken voor
    </Label>
    <Dropdown
      options={dateRanges}
      selectedOptionIdx={selectedDateRangeIndex}
      onChange={({ selectedOptionIdx }) => {
        onDateRangeChange(selectedOptionIdx);
      }}
    />
  </JustificationContainer>
);

export default DropdownGroup;
