import React from 'react';
import { EdgeProps, getSmoothStepPath } from 'reactflow';
import { useRecoilValue } from 'recoil';

import AnimatedPath from '../AnimatedPath';
import { ClientFlowAction } from '~/graphql/types.client';
import { shouldDisable } from '~/components/page/Automation/v2/state/interactions';
import { MARKER_END_DISABLED } from '../../../MarkerDefinitions';
import InsertCopiedActionContainer from '../InsertCopiedActionContainer';
import usePasteAction from '~/components/page/Automation/v2/components/Builder/hooks/usePasteAction';
import type { HandlerId } from '../../../nodeTypes/components/IfElseCard/types';

export type EdgeData = {
  action: ClientFlowAction;
  parentNode: ClientFlowAction;
  id: string;
  parentId: string;
};

export const EDGE_BORDER_RADIUS = 20;

export type Props = EdgeProps<EdgeData>;

const BaseEdge: React.FCC<Props> = React.memo(
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    source,
    target,
    markerEnd,
    sourceHandleId,
  }) => {
    const edgePath = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      borderRadius: EDGE_BORDER_RADIUS,
    });

    const disabled = useRecoilValue(shouldDisable);

    const { onInsertAction } = usePasteAction({
      source,
      target,
      sourceKey: sourceHandleId as HandlerId,
    });

    return (
      <>
        <AnimatedPath
          id={id}
          style={{
            ...style,
            strokeWidth: 2,
            pointerEvents: 'none',
            opacity: disabled ? 0.3 : 1,
            transition: 'opacity 0.5s ease-out',
          }}
          className="react-flow__edge-path"
          d={edgePath[0]}
          markerEnd={disabled ? MARKER_END_DISABLED : markerEnd}
        />
        <InsertCopiedActionContainer
          onInsertAction={onInsertAction}
          sourceX={sourceX}
          sourceY={sourceY}
          targetY={targetY}
          dataTestId={`insertActionContainer-${source}`}
        />
      </>
    );
  },
);

export default BaseEdge;
