import hasValue from '~/util/hasValue';
import { OutputType } from '../..';
import {
  GetWidgetSettingsQuery,
  WidgetSettingsLogo,
  WidgetSettingsStyleConfigColorPair,
  WidgetSettings__Input,
} from '~/graphql/types';
import serializeInput from '../serializeInput';

type ReturnType = {
  widgetSettingsInput: WidgetSettings__Input;
  logo: WidgetSettingsLogo | null | undefined;
  colors: {
    primary: Omit<WidgetSettingsStyleConfigColorPair, '__typename'>;
    secondary: Omit<WidgetSettingsStyleConfigColorPair, '__typename'>;
  };
};

const settingsToInput = (
  widgetSettingsData: GetWidgetSettingsQuery['getWidgetSettings'],
  output: OutputType,
): ReturnType => {
  const logo = hasValue(output.widgetLogo.value)
    ? output.widgetLogo.value
    : null;

  const highlightImage = hasValue(output.widgetImage.value)
    ? output.widgetImage.value
    : null;

  const primaryBackground = output.primaryBackgroundColor.value;
  const primaryColor = output.primaryColor.value;
  const secondaryBackground = output.secondaryBackgroundColor.value;
  const secondaryColor = output.secondaryColor.value;

  const update: GetWidgetSettingsQuery['getWidgetSettings'] = {
    ...widgetSettingsData,
    logo: logo,
    highlightImage,
    style: {
      __typename: 'WidgetSettingsStyles',
      primary: {
        __typename: 'WidgetSettingsStyleConfigColorPair',
        background: primaryBackground,
        color: primaryColor,
      },
      secondary: {
        __typename: 'WidgetSettingsStyleConfigColorPair',
        background: secondaryBackground,
        color: secondaryColor,
      },
    },
  };

  const widgetSettingsInput = serializeInput(
    update,
    [],
    logo?.s3key,
    highlightImage?.s3key,
  );

  return {
    widgetSettingsInput,
    logo,
    colors: {
      primary: {
        background: primaryBackground,
        color: primaryColor,
      },
      secondary: {
        background: secondaryBackground,
        color: secondaryColor,
      },
    },
  };
};

export default settingsToInput;
