import React from 'react';
import { FORM_ERROR } from 'final-form';
import Amplify from '~/amplify';
import { FormApi } from 'final-form';
import { Form, PasswordField } from '~/components/organism/Forms';
import Validation from '~/util/Validation';
import Button from '~/components/atom/Button';
import Catalog from '~/Catalog';
import ErrorTypes, { errortypeFromCognitoError } from '~/ErrorTypes';
import PasswordInputGroup from '~/components/page/Auth/components/PasswordInputGroup';
import TEST_ID from './index.testid';
import Drawer from '~/components/organism/Drawer';
import FormUtils from '~/components/organism/FormUtils';
import { Body } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import usePasswordValidation from '~/hooks/usePasswordValidation';
import useLogout from '~/hooks/useLogout';
import withDelayedLoading, {
  type WithDelayedLoading,
} from '~/hocs/withDelayedLoading';

type FormData = {
  oldPassword: string | null;
  password: string | null;
  passwordRepeat: string | null;
};

type Props = {
  toggleSidebar: (value: boolean) => void;
  active: boolean;
};

const text = {
  screenTitle: 'Wijzig wachtwoord',
  wrongOldPassword: Catalog.wrongOldPassword,
  serverError: Catalog.genericUnknownErrorMessage,
  oldPassword: Catalog.oldPasswordLabel,
  newPassword: Catalog.newPasswordLabel,
  repeatPassword: Catalog.newPasswordRepeatLabel,
  emptyOldPassword: Catalog.noOldPassword,
  emptyPasswordError: Catalog.noPassword,
  emptyRepeatPassword: Catalog.noPasswordRepeat,
  passwordRepeatError: Catalog.passwordsDoNotMatch,
  unvalid: Catalog.invalidPassword,
  limitExceeded:
    'Er is te vaak een verkeerd wachtwoord ingevoerd. Uit veiligheidsredenen wordt het wijzigen van het wachtwoord tijdelijk geblokkeerd. Probeer het later nog eens.',
};

const ProfileSidebar: React.FC<WithDelayedLoading & Props> = ({
  toggleSidebar,
  active,
  setLoading,
  removeLoading,
}) => {
  const { validatePassword, passwordConditions } = usePasswordValidation();
  const logout = useLogout();

  const validate = ({ oldPassword, password, passwordRepeat }: FormData) => {
    const errors: {
      oldPassword: string | undefined;
      password: string | undefined;
      passwordRepeat: string | undefined;
    } = {
      oldPassword: undefined,
      password: undefined,
      passwordRepeat: undefined,
    };

    const didEnterOldPassword = Validation.String.isNonEmptyString(oldPassword);

    if (!didEnterOldPassword) {
      errors.oldPassword = text.emptyOldPassword;
    }

    const passwordErrors = validatePassword({ password, passwordRepeat });
    return { ...errors, ...passwordErrors };
  };

  const onFormSubmit = async (
    formData: FormData,
    form: FormApi<FormData>,
    callback,
  ) => {
    setLoading();
    const user = await Amplify.Auth.currentAuthenticatedUser();
    try {
      await Amplify.Auth.changePassword(
        user,
        formData.oldPassword || '',
        formData.password || '',
      );

      void logout({ global: true });
    } catch (err) {
      removeLoading();

      const errortype = errortypeFromCognitoError(err);
      if (errortype === ErrorTypes.notAuthorized) {
        callback({ [FORM_ERROR]: text.wrongOldPassword });
        return;
      } else if (errortype === ErrorTypes.limitExceeded) {
        callback({ [FORM_ERROR]: text.limitExceeded });
        return;
      } else if (errortype === ErrorTypes.invalidPassword) {
        callback({ [FORM_ERROR]: text.wrongOldPassword });
        return;
      }
      callback({
        [FORM_ERROR]: text.serverError,
      });
      return;
    }
  };

  const closeBtnHandler = () => {
    toggleSidebar(false);
  };

  const title = text.screenTitle;

  return (
    <React.Fragment>
      <Drawer active={active} hideSidebar={closeBtnHandler} title={title}>
        <Form
          initialValues={{
            oldPassword: null,
            password: null,
            passwordRepeat: null,
          }}
          onSubmit={onFormSubmit}
          validate={validate}
        >
          {({
            handleSubmit,
            submitError,
            submitting,
            pristine,
            hasValidationErrors,
          }) => (
            <form
              id="change-pass-form"
              onSubmit={handleSubmit}
              data-testid={TEST_ID.CHANGE_PASS_FORM}
            >
              {submitError ? (
                <Body
                  size="base"
                  margin={[null]}
                  color={{ group: 'danger', variant: 'light' }}
                  data-testid={TEST_ID.CHANGE_PASS_ERROR_MESSAGE}
                >
                  {submitError}
                </Body>
              ) : null}
              <JustificationContainer width="100%" margin={['m', null]}>
                <PasswordField name="oldPassword">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      {...input}
                      width="100%"
                      size="large"
                      label={text.oldPassword}
                      type="password"
                      disabled={submitting}
                      externalErrors={
                        error && touched
                          ? [FormUtils.showError(error, touched)]
                          : undefined
                      }
                      dataTestId={TEST_ID.FORM_OLD_PASS}
                    />
                  )}
                </PasswordField>
              </JustificationContainer>

              <PasswordInputGroup
                passwordText={text.newPassword}
                repeatText={text.repeatPassword}
                passwordConditions={passwordConditions}
                submitting={submitting}
              />
              <JustificationContainer align="center" justification="end">
                <Button
                  size="medium"
                  loading={submitting}
                  appearance="secondary"
                  type="submit"
                  disabled={submitting || pristine || hasValidationErrors}
                  dataTestId={TEST_ID.SUBMIT_PASSWORD_BUTTON}
                  label="Wijzig"
                />
              </JustificationContainer>
            </form>
          )}
        </Form>
      </Drawer>
    </React.Fragment>
  );
};

export default withDelayedLoading<Props>(ProfileSidebar);
