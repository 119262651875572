import { useLocation } from '@gatsbyjs/reach-router';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import {
  AppBbWaardecheck__Input,
  AppType,
  AppVboWaardecheck__Input,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useDebounce from '~/hooks/useDebounce';

import { WaardecheckApp } from '../..';
import mergeWithDefault from './utils/mergeWithDefault';
import { appWaardecheckImageStash } from '../../state';

export type Device = 'mobile' | 'tablet' | 'desktop';
export enum Page {
  '/' = '/',
  '/property-type' = '/property-type',
  '/why' = '/why',
  '/when' = '/when',
  '/swiping' = '/swiping',
  '/success' = '/success',
  '/failure' = '/failure',
  '/appraisal-request' = '/appraisal-request',
  '/appraisal-request-success' = '/appraisal-request-success',
  '/report' = '/report',
}

/**
 * Breakpoints, they are mirrored in bbwc.
 */
const Breakpoints = {
  // Windows adds a scrollbar which we should ignore
  // mobile: 599, <- Breakpoint on the bbwc app
  mobile: 589,
  tablet: 720,
  tabletLandscape: 900,
  desktop: 1200,
};

export type Props = {
  device: Device;
  data: AppBbWaardecheck__Input | AppVboWaardecheck__Input;
  page: Page;
  width?: number;
  height?: number;
  appType?: AppType;
};

const Preview: React.FCC<Props> = ({
  device,
  data: oData,
  page,
  height: containerHeight,
  width: containerWidth,
  appType: passedAppType,
  ...rest
}) => {
  const { name: accountName } = useCurrentAccount();
  const { pathname } = useLocation();
  const imageStash = useRecoilValue(appWaardecheckImageStash);
  const previewContainer = useRef<HTMLDivElement>(null);
  const deviceWidth = Breakpoints[device];
  const appType =
    passedAppType ?? pathname.includes('matrixian')
      ? WaardecheckApp.VBO
      : WaardecheckApp.BB;
  const data = useDebounce(oData, 500);

  const [{ initialState, contentConfig, styleConfig }, setConfig] = useState({
    initialState: encodeURIComponent(
      JSON.stringify({
        route: page,
        previewMode: true,
      }),
    ),
    contentConfig: encodeURIComponent(
      JSON.stringify(
        mergeWithDefault({
          input: data,
          imageStash,
          page,
          accountName,
          appType,
        }),
      ),
    ),
    styleConfig: encodeURIComponent(
      JSON.stringify({
        primary: data.general.primary,
        secondary: data.general.secondary,
        tertiary: data.general.tertiary,
        quaternary: data.general.quaternary,
      }),
    ),
  });

  useLayoutEffect(() => {
    const initialStateJSON = JSON.stringify({
      route: page,
      previewMode: true,
    });
    const initialState = encodeURIComponent(initialStateJSON);

    const contentConfigJSON = JSON.stringify(
      mergeWithDefault({ input: data, imageStash, page, accountName, appType }),
    );

    const contentConfig = encodeURIComponent(contentConfigJSON);

    const styleConfigJSON = JSON.stringify({
      primary: data.general.primary,
      secondary: data.general.secondary,
      tertiary: data.general.tertiary,
      quaternary: data.general.quaternary,
    });
    const styleConfig = encodeURIComponent(styleConfigJSON);

    setConfig({
      initialState,
      contentConfig,
      styleConfig,
    });
  }, [data, page, imageStash, accountName, appType]);

  const [size, setSize] = useState({ width: 1, height: 1 });
  useEffect(() => {
    setSize({
      height: previewContainer.current?.offsetHeight ?? 1,
      width: previewContainer.current?.offsetWidth ?? 1,
    });
    previewContainer.current?.offsetWidth;
  }, [previewContainer]);

  const scale = size.width / deviceWidth;
  const height = size.height * (1 / scale);
  const url = `${process.env.BBWC_URL}/?appType=${appType}&initialStateId=0&initialState=${initialState}&styleConfig=${styleConfig}&contentConfig=${contentConfig}`;

  return (
    <Container
      $height={containerHeight}
      $width={containerWidth}
      ref={previewContainer}
      {...rest}
    >
      <PreviewContainer
        height={height}
        width={deviceWidth}
        style={{ transform: `scale(${scale})` }}
        src={url}
      />
    </Container>
  );
};

const PreviewContainer = styled.iframe<{}>(
  () => css`
    transform: scale(0.1);
    transform-origin: 0 0;
    border: none;
  `,
);

const Container = styled.div<{ $height?: number; $width?: number }>(
  ({ theme, $height, $width }) => css`
    box-shadow: ${theme.boxShadow('around')};
    border-top-left-radius: ${theme.getTokens().border.radius.base};
    border-top-right-radius: ${theme.getTokens().border.radius.base};
    display: inline-block;
    height: ${$height ? `${$height}px` : '100%'};
    width: ${$width ? `${$width}px` : '100%'};
    overflow: hidden;
    background-color: ${theme.color('white')};
    margin-top: ${theme.space('l')};
  `,
);

export default Preview;
