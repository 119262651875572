import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import TEST_ID from './index.testid';
import DatHuisLoading from '../DatHuisLoading';

const Loading: React.FC = props => {
  const [delayLoader, setDelayLoader] = useState<boolean>(false);

  const showLoader = () => {
    setDelayLoader(true);
  };

  useEffect(() => {
    const timer: ReturnType<typeof setTimeout> = setTimeout(showLoader, 300);

    return () => {
      if (timer) clearTimeout(timer);
    };
  });

  if (!delayLoader) {
    return null;
  }

  return (
    <InPositionContainer data-testid={TEST_ID.CONTAINER}>
      <DatHuisLoading {...props} />
    </InPositionContainer>
  );
};

const InPositionContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 50px;
`;

export default Loading;
