import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const TiaraData: React.FCC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  style,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width={style?.width ?? '100%'}
      height={style?.height ?? '100%'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M35.6 26.2001L25.6 36.1001H28.5V46.0001H43.5V36.1001H46.4L36.5 26.2001C36.2 25.9001 35.8 25.9001 35.6 26.2001Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.5 37.1001H33.6V46.0001H38.5V37.1001Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.1 32.6002V26.2002H40.1V29.6002"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 29.1C17.9646 29.1 22.7999 24.2647 22.7999 18.3C22.7999 12.3353 17.9646 7.5 12 7.5C6.03528 7.5 1.19995 12.3353 1.19995 18.3C1.19995 24.2647 6.03528 29.1 12 29.1Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36 15.2C39.9212 15.2 43.1 12.0212 43.1 8.1C43.1 4.17878 39.9212 1 36 1C32.0788 1 28.9 4.17878 28.9 8.1C28.9 12.0212 32.0788 15.2 36 15.2Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59 24.0001C62.148 24.0001 64.7 21.4481 64.7 18.3001C64.7 15.1521 62.148 12.6001 59 12.6001C55.852 12.6001 53.3 15.1521 53.3 18.3001C53.3 21.4481 55.852 24.0001 59 24.0001Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4 53.6002C14.2718 53.6002 16.6 51.2721 16.6 48.4002C16.6 45.5283 14.2718 43.2002 11.4 43.2002C8.52807 43.2002 6.19995 45.5283 6.19995 48.4002C6.19995 51.2721 8.52807 53.6002 11.4 53.6002Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36 71.2002C41.2467 71.2002 45.5 66.9469 45.5 61.7002C45.5 56.4535 41.2467 52.2002 36 52.2002C30.7533 52.2002 26.5 56.4535 26.5 61.7002C26.5 66.9469 30.7533 71.2002 36 71.2002Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.9 54.5999C67.8212 54.5999 71 51.4211 71 47.4999C71 43.5787 67.8212 40.3999 63.9 40.3999C59.9788 40.3999 56.8 43.5787 56.8 47.4999C56.8 51.4211 59.9788 54.5999 63.9 54.5999Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 46L25 42"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 4"
        />
        <path
          d="M36 52.1998V48.7998"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 4"
        />
        <path
          d="M57.1 45.2999L48.1 42.3999"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 4"
        />
        <path
          d="M54.6 21.8999L46.6 28.6999"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 4"
        />
        <path
          d="M36 16.1001V21.6001"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 4"
        />
        <path
          d="M21 25L27 29"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="2 4"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100%" height="100%" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default TiaraData;
