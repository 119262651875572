import { useRecoilValue } from 'recoil';
import useBuilderContext from '~/components/page/Automation/v2/components/Builder/hooks/useBuilderContext';
import flowActions, { actionById } from '~/components/page/Automation/v2/state';
import getRelativeMaps, { RelativeMaps } from '../../utils/getRelativeMaps';

type UseRelativeMapsArgs = {
  /** The action until which the instances need to be collected */
  actionId: string;
};

const useRelativeMaps = ({ actionId }: UseRelativeMapsArgs): RelativeMaps => {
  const { opts, instanceMap, subjects, instances, primitiveInputMap } =
    useBuilderContext();
  const { subjectMap, conditionMap, subjectToConditionMap, directoryMap } =
    opts;

  const action = useRecoilValue(actionById(actionId));
  const actions = useRecoilValue(flowActions);

  return getRelativeMaps({
    action,
    actions,
    maps: {
      conditionMap,
      directoryMap,
      instanceMap,
      primitiveInputMap,
      subjectMap,
      subjectToConditionMap,
      instances,
      subjects,
    },
  });
};

export default useRelativeMaps;
