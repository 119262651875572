import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4 } from '~/components/atom/Typography';

import StepOrIconComponent from './StepOrIconComponent';

export type Instruction = {
  step: number;
  link: string;
  title: string;
  description: React.ReactNode;
};

export type Props = {
  instruction: Instruction;
};

const InstructionBlock: React.FCC<Props> = ({
  dataTestId,
  instruction,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <Container
      data-testid={dataTestId}
      onClick={() => window.open(instruction.link, '_blank')?.focus()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      <JustificationWrapper
        justification="start"
        align="center"
        padding={['l']}
      >
        <StepOrIconComponent step={instruction.step} isHovered={isHovered} />
        <div>
          <Heading>{instruction.title}</Heading>
          <Description>{instruction.description}</Description>
        </div>
      </JustificationWrapper>
    </Container>
  );
};

const Container = styled.div<{}>`
  cursor: pointer;
`;

const JustificationWrapper = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    transition: all 0.5s ease;
    border-radius: ${theme.getTokens().border.radius.base};

    &:hover {
      background-color: ${theme.color('white')};
      box-shadow: ${theme.boxShadow('base')};
    }
  `,
);

const Heading = styled(Heading4)<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary')};

    ${theme.mq.lessThan('mobile')`
      margin-top: ${theme.space('xxs')};
    `}
  `,
);

const Description = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('text')};
  `,
);

export default InstructionBlock;
