import { round } from 'lodash';
import { Units } from '../..';
import getPercentage from '../getPercentage';

type Args = {
  unit: Units;
  total: number;
  value: number;
};

const getStatisticsValue = ({ unit, total, value }: Args): string => {
  const percentage = getPercentage({ total, value });

  if (unit === Units.Times) return `${total} x`;

  if (unit === Units.Percent)
    return percentage === 0 ? '—' : `${round(percentage)}%`;

  return `${value <= 0 ? '—' : value} contact(en)`;
};

export default getStatisticsValue;
