import styled, { css } from 'styled-components';

export const ASIDE_WIDTH = '430px';

/** Common attributes to be used in aside. Aligned to the right side */
export const BaseAside = styled.aside<{}>(
  ({ theme }) => css`
    padding: ${theme.space('m')};
    position: absolute;
    top: ${theme.space('s')};
    right: ${theme.space('s')};
    width: ${ASIDE_WIDTH};
    background-color: ${theme.color('white')};
    // Leave the box-shadow because it makes it more visible in the map
    box-shadow: ${theme.boxShadow('around')};
    border-radius: ${theme.getTokens().border.radius.m};
    z-index: ${theme.getTokens().zIndex.dropdown};
  `,
);

/** Used for the add working area buttons and search input */
export const LeftAsideContainer = styled(BaseAside)<{}>(
  ({ theme }) => css`
    bottom: ${theme.space('xxxxl')};
    top: unset;
    padding: 0;
    width: auto;
    right: calc(${ASIDE_WIDTH} + ${theme.space('l')});

    ${theme.mq.lessThan('tabletLandscape')`
        right: ${theme.space('l')};
        bottom: calc(${theme.space('xxxxl')} + ${theme.space('m')});
      `}
  `,
);
