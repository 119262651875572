import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Form, Field } from 'react-final-form';

import {
  useUpdateTaskMutation,
  useInsertActivityMutation,
  InsertActivityMutationVariables,
  TaskStatus,
  type InsertActivityMutation,
} from '~/graphql/types';

import { Task } from '~/components/page/Tasks/types';

import Catalog from '~/Catalog';

import TEST_ID from './index.testid';
import { ActivityTabProps } from '../..';
import {
  HandledActivityFieldsFragment,
  extractHandledActivities,
} from '~/graphql/types.client';
import { FormApi } from 'final-form';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import CaretDropdownButton from '~/components/molecule/CaretDropdownButton';
import FormUtils from '~/components/organism/FormUtils';
import { DateField } from '~/components/organism/Forms';
import SelectGroup, {
  SelectedOption as SelectGroupSelectedOption,
} from '~/components/molecule/SelectGroup';

import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useErrorReporter from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';
import { convertDateToServerDateString } from '~/util/date';
import formatToastMessage from '~/util/formatToastMessage';
import { currentTimeHourMinuteString } from '~/util/time';
import type { MutationFunction } from '@apollo/client';
import TimePicker from '~/components/organism/TimePicker';
import Textarea from '~/components/molecule/Textarea';

const text = {
  fields: {
    description: 'Omschrijf je activiteit hier...',
    date: 'Datum',
    type: 'Type',
  },
  validation: {
    required: Catalog.requiredField,
    date: Catalog.invalidDate,
  },
  buttons: {
    submit: 'Opslaan',
    submitAndComplete: 'Opslaan & deze taak voltooien',
    submitAndOpen: 'Opslaan & deze taak heropenen',
  },
  genericErrorMessage: Catalog.genericUnknownErrorMessageShort,
};
enum EventType {
  Note = 'NOTE',
  PhoneCall = 'PHONECALL',
}

type InsertActivityMutationFunction = MutationFunction<
  InsertActivityMutation,
  InsertActivityMutationVariables
>;

type FormType = {
  eventType?: EventType;
  dueDateDate?: string | null;
  dueDateTime?: string | null;
  description?: string | null;
};

type Props = ActivityTabProps & {
  /** taskDetails data with fields for InsertActivity mutation. If none given only the create button will be shown */
  taskDetails?: Task;

  /** Callback to close task modal */
  handleCloseModal?: () => void;

  /** Contact id for UpdateTask mutation */
  contactId: string;

  /** Called when adding the log was a success and all other sideeffects are handled */
  onSuccess: (newActivity: HandledActivityFieldsFragment) => void;
};

const formInitialValues = {
  eventType: EventType.PhoneCall,
  dueDateDate: new Date().toISOString(),
  dueDateTime: currentTimeHourMinuteString(),
  description: '',
};

const LogActivityTab: React.FC<Props> = ({
  handleCloseModal,
  taskDetails,
  contactId,
  onSuccess,
}) => {
  const [updateTask, { loading }] = useUpdateTaskMutation();

  const [insertActivity, { loading: insertActivityLoading }] =
    useInsertActivityMutation();
  const account = useCurrentAccount();
  const reporter = useErrorReporter();
  const addToast = useAddToast();

  const [shouldValidate, setShouldValidate] = useState<boolean>(false);

  const validateFn = (fields: FormType) => {
    const errors: {
      description: undefined | string;
      dueDateDate: undefined | string;
      dueDateTime: undefined | string;
      eventType: undefined | string;
    } = {
      description: undefined,
      dueDateDate: undefined,
      dueDateTime: undefined,
      eventType: undefined,
    };

    if (!fields.eventType) errors.eventType = text.validation.required;

    if (!fields.description) errors.description = text.validation.required;

    if (!fields.dueDateDate) errors.dueDateDate = text.validation.required;

    if (!fields.dueDateTime) errors.dueDateTime = text.validation.required;

    return errors;
  };

  const getButtonComponent = (
    disabled: boolean,
    loading: boolean,
    preSubmitCheck: () => void,
    handleSubmit: () => any,
  ) => {
    const baseButtonOnClickAction = () => {
      preSubmitCheck();
      handleSubmit();
    };

    if (taskDetails) {
      const taskIsOpen = taskDetails.status === TaskStatus.Open;

      return (
        <CaretDropdownButton
          appearance="secondary"
          disabled={disabled}
          loading={loading}
          dataTestId={TEST_ID.INSERT_ACTIVITY_BUTTON}
          dropdownOptions={[
            {
              label: taskIsOpen
                ? text.buttons.submitAndComplete
                : text.buttons.submitAndOpen,
              onClickAction: () => {
                preSubmitCheck();
                handleSubmit();
                if (taskDetails) {
                  const { status, id } = taskDetails;
                  const newStatus =
                    status === TaskStatus.Open
                      ? TaskStatus.Closed
                      : TaskStatus.Open;

                  void updateTask({
                    variables: {
                      accountId: account.id,
                      id,
                      update: {
                        status: newStatus,
                      },
                    },
                  }).then(() => {
                    handleCloseModal && handleCloseModal();
                  });
                }
              },
            },
          ]}
          mainButtonOption={{
            label: text.buttons.submit,
            onClickAction: baseButtonOnClickAction,
          }}
        />
      );
    } else {
      return (
        <Button
          size="medium"
          appearance="secondary"
          disabled={disabled}
          loading={loading}
          data-testid={TEST_ID.INSERT_ACTIVITY_BUTTON}
          onClick={baseButtonOnClickAction}
          label={text.buttons.submit}
        />
      );
    }
  };

  const handleSubmit = (
    formData: FormType,
    insertActivity: InsertActivityMutationFunction,
    form: FormApi,
  ) => {
    if (!contactId || !account) return;

    const loggedDate = convertDateToServerDateString(
      formData.dueDateDate,
      formData.dueDateTime,
    );
    if (!loggedDate) return;
    const relatedTaskId = taskDetails ? taskDetails.id : null;
    const activity = {
      contactId,
      relatedTaskId,
      description: formData.description || '',
      loggedDate,
    };

    let variables: InsertActivityMutationVariables | null = null;
    if (formData.eventType === EventType.Note) {
      variables = {
        accountId: account.id,
        note: activity,
      };
    } else if (formData.eventType === EventType.PhoneCall) {
      variables = {
        accountId: account.id,
        call: activity,
      };
    } else {
      return reporter.captureMessage(
        `${cleanedFilename(
          __filename,
        )}>>handleSubmit | Should not occur | Unknown eventType (${
          formData.eventType
        })`,
        'error',
      );
    }

    return insertActivity({
      variables,
    }).then(mutationResult => {
      if (mutationResult && mutationResult.data) {
        const { data } = mutationResult;
        const { insertActivity } = data;

        const handledActivities = extractHandledActivities([insertActivity]);

        onSuccess(handledActivities[0]);
        form.reset({ ...formInitialValues, eventType: formData.eventType });
        setShouldValidate(false);
      } else {
        return addToast([
          formatToastMessage(text.genericErrorMessage, 'danger'),
        ]);
      }
    });
  };

  return (
    <Form
      validate={validateFn}
      onSubmit={(formData, form) =>
        handleSubmit(formData, insertActivity, form)
      }
      initialValues={formInitialValues}
    >
      {({ handleSubmit, submitting, errors }) => {
        const disabledForm = submitting || loading || insertActivityLoading;

        const preSubmitCheck = () => {
          const _shouldValidate = errors && Object.keys(errors).length > 0;

          if (_shouldValidate) {
            setShouldValidate(_shouldValidate);
          }
        };
        return (
          <Container>
            <form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <DeadlineAndSelectorContainer
                align="end"
                justification="space-between"
                gap="s"
                width="100%"
              >
                <Field name="eventType">
                  {({ input: { onChange, value } }) => (
                    <SelectGroup
                      disabled={disabledForm}
                      selectedIndex={options.findIndex(
                        option => option.value === value,
                      )}
                      onChange={(selectedOption: SelectGroupSelectedOption) => {
                        const { option } = selectedOption;
                        if (option) {
                          onChange(option.value);
                        }
                      }}
                      options={options}
                      dataTestId={TEST_ID.TYPE_FIELD}
                    />
                  )}
                </Field>

                <JustificationContainer align="end" gap="s" justification="end">
                  <Field name="dueDateDate">
                    {({ input: { value, onChange }, meta: { error } }) => {
                      const touched =
                        formInitialValues.dueDateDate !== value &&
                        shouldValidate;

                      return (
                        <DateField
                          error={FormUtils.showError(error, touched)}
                          inputComponentProps={{
                            disabled: disabledForm,
                          }}
                          data-error={error}
                          value={value}
                          onChange={onChange}
                          name="dueDateDate"
                          data-testid={TEST_ID.DUE_DATE_DATE}
                        />
                      );
                    }}
                  </Field>
                  <Field name="dueDateTime">
                    {({ input: { onChange, value }, meta: { error } }) => (
                      <TimePicker
                        value={value}
                        disabled={disabledForm}
                        externalErrors={
                          FormUtils.showError(error, shouldValidate)
                            ? [FormUtils.showError(error, shouldValidate)]
                            : []
                        }
                        name="dueDateTime"
                        data-error={error}
                        onChange={onChange}
                        dataTestId={TEST_ID.DUE_DATE_TIME}
                      />
                    )}
                  </Field>
                </JustificationContainer>
              </DeadlineAndSelectorContainer>

              <JustificationContainer margin={['base', null]}>
                <Field name="description">
                  {({ input, meta: { error } }) => {
                    const hasError = Boolean(
                      FormUtils.showError(error, shouldValidate),
                    );

                    return (
                      <FieldContainer>
                        <Textarea
                          disabled={disabledForm}
                          error={hasError ? error : null}
                          data-error={hasError}
                          data-testid={TEST_ID.DESCRIPTION_TEXTAREA_FIELD}
                          placeholder={text.fields.description}
                          {...input}
                        />
                      </FieldContainer>
                    );
                  }}
                </Field>
              </JustificationContainer>
              <JustificationContainer
                padding={[null, null, null, 'm']}
                align="center"
                justification="end"
              >
                {getButtonComponent(
                  disabledForm,
                  loading || insertActivityLoading,
                  preSubmitCheck,
                  handleSubmit,
                )}
              </JustificationContainer>
            </form>
          </Container>
        );
      }}
    </Form>
  );
};

const DeadlineAndSelectorContainer = styled(JustificationContainer)(
  ({ theme }) => css`
    ${theme.mq.lessThan('desktop')`
      flex-wrap: wrap;
    `}
  `,
);

const options = [
  {
    value: EventType.PhoneCall,
    label: 'Telefoongesprek',
    dataTestId: TEST_ID.PHONE_CALL_OPTION,
  },
  {
    value: EventType.Note,
    label: 'Notitie',
    dataTestId: TEST_ID.NOTE_OPTION,
  },
];

const FieldContainer = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Container = styled.div<{}>`
  height: 100%;
`;

export default LogActivityTab;
