import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,

  ASSIGNED_OFFICE_DROPDOWN: `${cleanedFilename(
    __filename,
  )}-assigned-office-dropdown`,
  ASSIGNED_USER_DROPDOWN: `${cleanedFilename(
    __filename,
  )}-assigned-user-dropdown`,
};
