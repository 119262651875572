import React from 'react';

import Form from '~/components/organism/Forms/Form';
import styled from 'styled-components';
import EmailNotificationsForm from './components/EmailNotificationsForm';
import { type NotificationSettingsFieldsFragment } from '~/graphql/types';
import { asEmailNotificationsFormData } from './util';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import FormSaveBar from '../SaveBar/components/FormSaveBar';

export type EmailNotificationsFormData = {
  dailyDigest: boolean;

  taskNewUnassigned: boolean;
  taskGotAssignedToMe: boolean;
  taskGotAssignedToMyOffices: boolean;
  app: boolean;
  form: boolean;

  contactGotAssignedToMe: boolean;
  contactGotAssignedToMyOffices: boolean;
};

export type Props = {
  onFormSubmit: (newFormData: EmailNotificationsFormData) => void;
  notificationSettings: NotificationSettingsFieldsFragment;
};

const EmailNotificationsBlock: React.FCC<Props> = ({
  dataTestId,
  onFormSubmit,
  notificationSettings,
  ...rest
}) => (
  <AnimatedBlock>
    <Form
      onSubmit={onFormSubmit}
      initialValues={asEmailNotificationsFormData(notificationSettings)}
      data-testid={dataTestId}
    >
      {({ handleSubmit }) => (
        <StyledForm
          id="email-notifications-form"
          onSubmit={handleSubmit}
          data-testid="email-notifications-form"
          {...rest}
        >
          <EmailNotificationsForm />
          <FormSaveBar inBlockComponent />
        </StyledForm>
      )}
    </Form>
  </AnimatedBlock>
);

export default EmailNotificationsBlock;

const StyledForm = styled.form<{}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
