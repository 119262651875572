import cleanedFilename from '~/util/cleanedFilename';

export default {
  BODY: `${cleanedFilename(__filename)}-body`,
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  ERROR_MESSAGE: `${cleanedFilename(__filename)}-error-message`,
  SEND_BUTTON: `${cleanedFilename(__filename)}-send-button`,
  NO_SYNCED_EMAIL_CONTAINER: `${cleanedFilename(
    __filename,
  )}-no-synced-email-container`,
};
