import React from 'react';
import { useRecoilValue } from 'recoil';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import { formState as formStateAtom } from '~/components/page/Forms/components/Builder/state';
import { localeToIntlKey, localeToLabel } from '../../constants';
import {
  FormBuilder_Locale,
  FormBuilder_ScreenNode_Block,
} from '~/graphql/types';
import { isEmpty, isNil } from 'ramda';
import useErrorReporter from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';
import type { SystemSize } from '~/theme';
import { issuesByPath } from '~/components/page/Forms/components/Builder/state/issues';
import LocaleIcon from '~/components/atom/Icon/components/LocaleIcon';

export type Props = {
  label?: Record<FormBuilder_Locale, string>;
  onChange: (next: FormBuilder_ScreenNode_Block) => void;
  block: FormBuilder_ScreenNode_Block;
  /** Name of the field which is being updated in the input */
  subject: string;
  margin?: Array<SystemSize | null>;

  nodeId: string;
  blockKey: string;
};

const LocalesInput: React.FCC<Props> = ({
  dataTestId,
  label,
  onChange,
  block,
  subject,
  margin = [null, null, null, null],
  nodeId,
  blockKey,
  ...rest
}) => {
  const issues = useRecoilValue(issuesByPath([nodeId, blockKey]));
  const reporter = useErrorReporter();
  const formState = useRecoilValue(formStateAtom);

  if (isEmpty(subject)) {
    reporter.captureMessage(
      `${cleanedFilename(__filename)}>> Empty 'subject' was provided`,
      'error',
    );
    return null;
  }

  if (!block[subject]) {
    reporter.captureMessage(
      `${cleanedFilename(__filename)}>> ${subject} does not exist in the block ${block.__typename}`,
      'error',
    );
    return null;
  }

  const issueMap = issues.reduce(
    (acc, curr) => {
      if (curr?.localeKey && isNil(curr.optionKey) && !isNil(curr.message)) {
        acc[curr.localeKey] = curr.message;
      }

      return acc;
    },
    { [FormBuilder_Locale.En]: null, [FormBuilder_Locale.Nl]: null } as Record<
      FormBuilder_Locale,
      string | null
    >,
  );

  return (
    <JustificationContainer
      dataTestId={dataTestId}
      direction="column"
      width="100%"
      gap="s"
      margin={margin}
      {...rest}
    >
      {formState?.style.availableLocale.map(locale => {
        const labelText = label ? label[locale] : localeToLabel[locale];

        return (
          <Input
            label={{
              text: (
                <>
                  {labelText} <LocaleIcon locale={locale} />
                </>
              ),
            }}
            key={locale}
            externalErrors={(() => {
              const currentLocaleError = issueMap[locale];

              if (isNil(currentLocaleError)) return undefined;
              return [currentLocaleError];
            })()}
            width="100%"
            onChange={event => {
              onChange({
                ...block,
                [subject]: {
                  ...block[subject],
                  [localeToIntlKey[locale]]: event.target.value,
                },
              });
            }}
            value={block[subject][localeToIntlKey[locale]]}
          />
        );
      })}
    </JustificationContainer>
  );
};

export default LocalesInput;
