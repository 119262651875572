import type { ClientFlowAction } from '~/graphql/types.client';
import { uniqBy } from 'ramda';
import generateConditionInstanceKey from '~/components/page/Automation/v2/state/flowInstance/utils/generateConditionInstanceKey';
import generateInstance, {
  type InstanceWithPointer,
} from '~/components/page/Automation/v2/state/flowInstance/utils/generateInstance';
import sortActionsOnParentIds from '~/components/page/Automation/v2/state/flowInstance/utils/sortActionsOnParentIds';
import getLabelledActions from '~/components/page/Automation/v2/util/getLabelledActions';
import getCommonParentsInAllPaths from '../utils/getCommonParentsInAllPaths';
import getSubject, {
  SubjectMap,
} from '../../UpdateAction/components/Selector/utils/getSubject';
import getTriggers from '../../UpdateAction/components/ConditionEditorV2/utils/getTriggers';

/**
 * Returns all parent instances for a given action.
 */
const getParentInstances = ({
  action,
  allActions,
  subjectMap,
}: {
  action?: ClientFlowAction;
  allActions: Array<ClientFlowAction>;
  subjectMap: SubjectMap;
}): Array<InstanceWithPointer> => {
  if (!action) return [];

  const labelledActions = getLabelledActions(allActions);
  const parentActions = getCommonParentsInAllPaths({
    subjectAction: action,
    actions: labelledActions,
  });

  const sortedParentActions = sortActionsOnParentIds({
    allActions,
    subjectAction: action,
    actionsToSort: parentActions,
  });

  const instances: Array<InstanceWithPointer> = [];

  for (const parentAction of sortedParentActions) {
    switch (parentAction.__typename) {
      case 'FlowV2_Action_Realworks_SendContact':
      case 'FlowV2_Action_Zapier_Trigger':
      case 'FlowV2_Action_Contact_Details':
      case 'FlowV2_Action_Contact_AddTag':
      case 'FlowV2_Action_Contact_DeleteTag':
      case 'FlowV2_Action_IfElse':
      case 'FlowV2_Action_Contact_Assign':
        break;
      case 'FlowV2_Action_Task_Create':
        const subject = getSubject({ type: 'FlowSubject_Task' }, subjectMap);

        if (!subject) break;
        instances.push(generateInstance(subject, 'item', parentAction));
        break;
      case 'FlowV2_Action_SendEmail_Plain': {
        const subject = getSubject({ type: 'FlowSubject_Email' }, subjectMap);
        if (!subject) break;

        instances.push(generateInstance(subject, 'item', parentAction));
        break;
      }

      case 'FlowV2_Action_Start':
      case 'FlowV2_Action_Wait':
        const triggers = getTriggers(parentAction.condition);

        triggers.forEach(trigger => {
          if (trigger?.__typename === 'Flow___SubjectFieldCondition') {
            const subject = getSubject(
              {
                type: trigger.type,
                typeId: trigger.typeId,
              },
              subjectMap,
            );

            const creates = subject?.fields.find(({ key }) => {
              if (trigger?.__typename === 'Flow___SubjectFieldCondition') {
                return trigger.field === key;
              }

              return false;
            })?.creates;

            if (creates != null) {
              const createsSubject = getSubject(creates, subjectMap);

              if (createsSubject) {
                const instance = generateInstance(
                  createsSubject,
                  generateConditionInstanceKey(createsSubject),
                  parentAction,
                );

                instances.push(instance);
              }
            }
          }
        });
        break;

      default:
        break;
    }
  }

  return uniqBy(instance => instance.instance.key.join(','), instances);
};

export default getParentInstances;
