import React from 'react';
import { WithNavigationFrame as NotFound } from '~/components/page/404';
import Nylas from './Nylas';
import DHRouter from '~/components/atom/DHRouter';

import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/components/page/ErrorScreen';
import Catalog from '~/Catalog';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';

type Props = RouteComponentProps;
const External: React.FCC<Props> = () => (
  <DHRouter>
    <Nylas path={'nylas/*'} />
    <NotFound default />
  </DHRouter>
);

export default withErrorBoundary<Props>(
  External,
  <ErrorScreen explanation={Catalog.genericUnknownErrorMessage} />,
);
