import React from 'react';
import styled, { css } from 'styled-components';

import Validation from '~/util/Validation';
import TEST_ID from './index.testid';
import Icon from '~/components/atom/Icon';
import type { Props as IllustrationProps } from '~/components/atom/Illustration';
import Illustration from '~/components/atom/Illustration';
import { Body, Label } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  placeholderText: (
    <>
      Je hebt nog geen
      <br /> bestand geupload
    </>
  ),
  change: (
    <>
      wijzig
      <br />
      afbeelding
    </>
  ),
};

type Props = {
  illustration?: IllustrationProps;
  src?: string | null;
  simpleImage?: boolean;
  imagePlaceholderText?: string;

  /**
   * Is the current avatar editable, if not don't show labels
   */
  edit?: boolean;
};

const ImageOrPlaceholder: React.FCC<Props> = ({
  illustration,
  src,
  simpleImage,
  imagePlaceholderText,
  edit = false,
}) => {
  if (src && Validation.String.isNonEmptyString(src))
    return (
      <ImageContainer direction="column" align="center" justification="center">
        <Image src={src} data-testid={TEST_ID.IMG_ELEMENT} />
      </ImageContainer>
    );

  return (
    <>
      {!simpleImage && illustration ? (
        <PlaceholderContainer>
          <JustificationContainer
            direction="column"
            align="center"
            justification="center"
          >
            <Illustration {...illustration} size="xxxxl" />
            {edit === true && (
              <UploadLabel
                skewed
                margin={[null]}
                align="center"
                size="s"
                fontWeight="regular"
              >
                {text.change}
              </UploadLabel>
            )}
          </JustificationContainer>
        </PlaceholderContainer>
      ) : (
        <ImageIconContainer data-testid={TEST_ID.IMAGE_PLACHOLDER}>
          <Icon name="image" skewed />
          <Label
            margin={[null]}
            fontWeight="regular"
            align="center"
            size="base"
            whiteSpace="nowrap"
            skewed
          >
            {imagePlaceholderText ? imagePlaceholderText : text.placeholderText}
          </Label>
        </ImageIconContainer>
      )}
    </>
  );
};

const Image = styled.img<{}>`
  width: 102%;
  height: 102%;
  object-fit: contain;
  border-radius: ${({ theme }) => theme.getTokens().border.radius.base};
  background-color: ${({ theme }) => theme.color('tertiary')};
  position: absolute;

  /* Bleed over the edge just slightly for nicely filled element */
  top: -1%;
  right: -1%;
  bottom: -1%;
  left: -1%;
`;

const UploadLabel = styled(Body)(
  ({ theme }) => css`
    transform: translateY(-${theme.space('xxs')}) skewY(-5deg);
  `,
);

const PlaceholderContainer = styled(JustificationContainer)`
  & > div {
    transition: all 0.2s ease-out;
  }
  /* Target the first child div only (JustificationContainer) */
  &:hover > div {
    transform: scale(1.1);
  }
`;

const ImageContainer = styled(JustificationContainer)`
  ${Image} {
    transition: all 0.2s ease-out;
  }
  &:hover ${Image} {
    transform: scale(1.1);
  }
`;

const ImageIconContainer = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('tertiary', 'light')};
    color: ${theme.color('text', 'light')};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: ${theme.space('xl')};
    border-radius: ${theme.getTokens().border.radius.base};

    & svg {
      font-size: ${theme.fs('xl')};
    }
  `,
);

export default ImageOrPlaceholder;
