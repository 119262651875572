import React, { useRef, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';
import { ZapierField, ZapierFieldType } from '~/graphql/types';
import useClickOutside from '~/hooks/useClickOutside';

import alterById from '~/util/alterById';
import { isNonEmptyString } from '~/util/Validation/String';
import FieldEditingSection from '../FieldEditingSection';
import { v1 as uuidv1 } from 'uuid';
import { ErrorObj, KEY_REGEX } from '../..';
import TEST_ID from './index.testid';
import { text } from '../../index';

export type ZapierFields = Array<ZapierField & { id?: string }>;

export type Props = {
  fields?: ZapierFields;
  onFieldsChange: (fields: ZapierFields) => void;
  duplicateKeys: Array<string>;
  errors: Array<ErrorObj>;
};

const FieldsContainer: React.FCC<Props> = ({
  dataTestId,
  fields = [],
  onFieldsChange,
  duplicateKeys,
  errors,
  ...rest
}) => {
  const containerRef = useRef(null);
  const onClickOutside = useCallback(() => setActiveFieldId(null), []);
  useClickOutside(containerRef, onClickOutside);

  const [activeFieldId, setActiveFieldId] = useState<string | null>(null);

  const onActiveField = useCallback(
    (id: string | null, value: string | boolean, field: keyof ZapierField) => {
      if (!id) return;
      const updated = alterById(id, field, value, fields);
      onFieldsChange(updated);
    },
    [fields, onFieldsChange],
  );

  const onNewField = useCallback(() => {
    const id = uuidv1();

    onFieldsChange([
      ...fields,
      {
        __typename: 'ZapierField',
        key: `nieuwe_variabele_${fields.length + 1}`,
        label: `Nieuwe variabele_${fields.length + 1}`,
        type: ZapierFieldType.String,
        id,
      },
    ]);

    setActiveFieldId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const activeFieldPredicate = field => field.id === activeFieldId;
  const activeFieldIndex = fields.findIndex(activeFieldPredicate);

  return (
    <div ref={containerRef}>
      <Heading4 variant={Variant.primary}>Velden</Heading4>
      <Container justification="center" data-testid={dataTestId} {...rest}>
        <FieldsWrapper>
          <StyledButton
            label="Nieuw veld toevoegen"
            icon="plus"
            appearance="secondary"
            size="medium"
            onClick={onNewField}
            dataTestId={TEST_ID.NEW_FIELD_BUTTON}
          />
          {fields.map(({ label, key, id }, index) => (
            <FieldLabel
              data-testid={TEST_ID.FIELDS_LIST}
              data-objectid={`field-${index}`}
              key={id}
              isActive={id === activeFieldId}
              onClick={() => id && setActiveFieldId(id)}
              hasError={
                !isNonEmptyString(label) ||
                !isKeyValid(key) ||
                duplicateKeys.includes(key)
              }
            >
              {label || text.emptyInputError}
            </FieldLabel>
          ))}
        </FieldsWrapper>

        <FieldEditingSection
          onChange={(newValue, key) =>
            onActiveField(activeFieldId, newValue, key)
          }
          field={fields[activeFieldIndex]}
          errors={errors[activeFieldIndex]}
          onDelete={() =>
            onFieldsChange(fields.filter(field => field.id !== activeFieldId))
          }
        />
      </Container>
    </div>
  );
};

const isKeyValid = value => {
  if (value.length === 0) return false;
  return KEY_REGEX.test(value);
};

const Container = styled(JustificationContainer)<{}>`
  min-height: 400px;

  ${({ theme }) => css`
    padding: ${theme.space('base')};
    background-color: ${theme.color('primary', 'translucent')};
    border-radius: ${theme.getTokens().border.radius.base};
  `}
`;

const StyledButton = styled(Button)<{}>`
  display: flex;
  justify-content: start;
`;

const FieldsWrapper = styled.div<{}>`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const FieldLabel = styled.div<{
  hasError: boolean;
  isActive: boolean;
}>`
  cursor: pointer;

  ${({ theme, hasError, isActive }) => css`
    color: ${theme.color(hasError ? 'danger' : isActive ? 'white' : 'primary')};
    padding: ${theme.space('base')};
    border-radius: ${theme.getTokens().border.radius.base};
    background-color: ${isActive ? theme.color('primary') : 'none'};
    margin-top: ${theme.space('xxs')};

    &:hover {
      color: ${theme.color(hasError ? 'danger' : 'white')};
      background-color: ${theme.color('primary')};
    }
  `}
`;

export default FieldsContainer;
