import styled, { css } from 'styled-components';
import React from 'react';
import Icon from '~/components/atom/Icon';
import { linkStyles } from '~/components/molecule/Link';
import { last, take } from 'ramda';
import TEST_ID from './index.testid';

export type Crumb = {
  key: string;
  disabled?: boolean;
  label: React.ReactNode;
};

export type Props = {
  crumbs: Array<Crumb>;
  onClick: (crumb: Crumb, index: number) => void;
};

const MAX_CRUMBS = 3;

const Breadcrumbs: React.FCC<Props> = ({ crumbs, onClick }) => {
  if (crumbs.length <= MAX_CRUMBS) {
    return (
      <Container data-testid={TEST_ID.CONTAINER}>
        {crumbs.map(({ key, disabled, label }, index) => (
          <React.Fragment key={key}>
            <Label
              $disabled={disabled}
              onClick={() => onClick({ key, label }, index)}
              data-objectid={key}
              $hasEllipsis={
                index === crumbs.length - 1 ||
                // @ts-ignore TS doesn't know this is there but it is. Its a React private value
                (label?.props?.str?.length ?? 0) > 15
              }
            >
              {label}
            </Label>
            <ChevronElement name="chevron" />
          </React.Fragment>
        ))}
      </Container>
    );
  }

  const firstSet = take(MAX_CRUMBS - 1, crumbs);
  const lastCrumb = last(crumbs);

  return (
    <Container data-testid={TEST_ID.CONTAINER}>
      {firstSet?.map(({ key, disabled, label }, index) => (
        <React.Fragment key={key}>
          <Label
            $disabled={disabled}
            onClick={() => onClick({ key, label }, index)}
            data-objectid={key}
          >
            {label}
          </Label>
          <ChevronElement name="chevron" />
        </React.Fragment>
      ))}

      {lastCrumb && (
        <>
          <Separator>...</Separator>
          <ChevronElement name="chevron" />

          <Label
            $disabled={lastCrumb.disabled}
            onClick={() =>
              onClick(
                { key: lastCrumb.key, label: lastCrumb.label },
                crumbs.length - 1,
              )
            }
            $hasEllipsis
          >
            {lastCrumb.label}
          </Label>
          <ChevronElement name="chevron" />
        </>
      )}
    </Container>
  );
};

const Container = styled.div<{}>`
  display: flex;
  /** Leave some space for close button */
  max-width: 95%;
`;

const Label = styled.span<{ $disabled?: boolean; $hasEllipsis?: boolean }>`
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  ${linkStyles}
  ${({ theme, $disabled }) => $disabled && `color: ${theme.color('tertiary')};`}
  ${({ $hasEllipsis }) =>
    $hasEllipsis &&
    css`
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

const ChevronElement = styled(Icon)<{}>(
  ({ theme }) => css`
    transform: rotate(-90deg);
    margin: 0 ${theme.space('s')};
  `,
);

const Separator = styled.span`
  display: inline-block;
`;

export default Breadcrumbs;
