import { add, isNil, subtract } from 'ramda';
import type { OnChangeFunction, Option } from '~/components/molecule/Dropdown';
import getOptionType from '~/components/molecule/Dropdown/utils/getOptionType';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';

type Params = {
  selectedOptionIdx?: number | null;
  options: Array<Option>;
  onClose?: () => void;
  onSelect: (value: number) => void;
  onChange: OnChangeFunction;
};

const useKeyBindings = ({
  onClose,
  onSelect,
  selectedOptionIdx,
  options,
  onChange,
}: Params) => {
  const upDownPress = (e: KeyboardEvent, key) => {
    const maxSelectedOptionIdx = options.length - 1;
    e.preventDefault();

    if (key === 'up') {
      const disabledOptionAbove =
        getOptionType(options, selectedOptionIdx && selectedOptionIdx - 1) ===
        'DISABLED';

      const topLimitSteps = Math.max(
        0,
        subtract(selectedOptionIdx ?? 0, disabledOptionAbove ? 2 : 1),
      );

      return onSelect(topLimitSteps);
    } else {
      const disabledOptionBelow =
        getOptionType(options, selectedOptionIdx && selectedOptionIdx + 1) ===
        'DISABLED';

      const bottomLimitSteps = Math.min(
        maxSelectedOptionIdx,
        add(selectedOptionIdx ?? 0, disabledOptionBelow ? 2 : 1),
      );

      return onSelect(bottomLimitSteps);
    }
  };

  const enterPress = () => {
    if (!isNil(selectedOptionIdx))
      onChange({
        option: options[selectedOptionIdx],
        selectedOptionIdx,
      });
  };

  useGlobalKeyBinding({
    keys: ['up', 'down'],
    callback: (e: KeyboardEvent, key) => upDownPress(e, key),
    enabled: true,
  });

  useGlobalKeyBinding({
    keys: 'enter',
    callback: enterPress,
    enabled: true,
  });

  useGlobalKeyBinding({
    keys: 'escape',
    callback: onClose ? () => onClose() : () => {},
    enabled: true,
  });
};

export default useKeyBindings;
