import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  BILLING_CYCLE_DROPDOWN: `${cleanedFilename(
    __filename,
  )}-billing-cycle-dropdown`,
  PLAN_CYCLE_LABEL: `${cleanedFilename(__filename)}-plan-cycle-label`,
  TOTAL_PRICE: `${cleanedFilename(__filename)}-total-price`,
  SHOW_COUPON_BUTTON: `${cleanedFilename(__filename)}-show-coupon-button`,
};
