const firstGoToFlow = {
  step: 1,
  link: '/automation',
  title: 'Bouw een flow',
  description:
    'Ga naar Automation en bouw een nieuwe flow met een “Stuur naar Zapier” actie. Selecteer de juiste uitgaande koppeling.',
};

const thenGoToZapier = {
  step: 2,
  link: 'https://zapier.com/app/login',
  title: 'Bouw een zap',
  description:
    'Ga naar Zapier en voeg een zap toe. Selecteer DatHuis als trigger en kies dezelfde uitgaande koppeling die je hebt gebruikt in de flow. In de zap stel je zelf de gewenste vervolgacties in.',
};

const firstGoToZapier = {
  step: 1,
  link: 'https://zapier.com/app/login',
  title: 'Bouw een zap',
  description:
    'Ga naar Zapier en voeg een zap toe. Voeg een actie toe en selecteer DatHuis. Kies een inkomende koppeling zoals ‘Nieuw contact’. Hiermee wordt een nieuw contact in DatHuis aangemaakt.',
};

const thenGoToFlow = {
  step: 2,
  link: '/automation',
  title: 'Bouw een flow',
  description:
    'Ga naar Automation en bouw een nieuwe flow. Selecteer Zapier als trigger en kies dezelfde inkomende koppeling die je hebt gebruikt in de zap.',
};

export const firstVariantOfInstructions = [firstGoToFlow, thenGoToZapier];
export const secondVariantOfInstructions = [firstGoToZapier, thenGoToFlow];
