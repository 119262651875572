import type { ContactValuationRequestCreatedEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asDateFilterInput from '../../Primitive/Date/asDateFilterInput';

const asCreatedValuationRequestEventFilterInput = (
  filter: ContactValuationRequestCreatedEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => {
  // When dates come from stored filters or query params, we cannot parse the dates.
  // this ensures all components that expect date to be a Date object don't break.
  if (typeof filter.assignedFilter.date === 'string') {
    filter.assignedFilter.date = new Date(filter.assignedFilter.date);
  }

  return {
    App: {
      ValuationRequest: {
        createdDate: asDateFilterInput(filter.assignedFilter),
      },
    },
  };
};

export default asCreatedValuationRequestEventFilterInput;
