import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  size?: 'small' | 'base';
  active?: boolean;
  label: string;
};

/**
 * Label with the blue background A1,B2 etc.
 */
const ActionLabel: React.FCC<Props> = ({
  dataTestId,
  active,
  size = 'base',
  label,
  ...rest
}) => (
  <Container data-testid={dataTestId} $active={active} $size={size} {...rest}>
    {label}
  </Container>
);

const Container = styled.span<{ $size?: Props['size']; $active?: boolean }>(
  ({ theme, $active, $size }) => css`
    color: ${theme.color($active ? 'white' : 'primary')};
    background-color: ${theme.color('primary', 'translucent')};
    margin-right: ${theme.space('xxs')};
    border-radius: ${theme.getTokens().border.radius.s};
    font-size: ${theme.fontSize($size === 'small' ? 'xxs' : 's')};
    font-weight: ${theme.fw('semiBold')};
    padding: ${theme.remToPxRaw(theme.space('xxs')) / 2 + 'px'}
      ${theme.space('xxs')};
  `,
);

export default ActionLabel;
