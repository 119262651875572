import React from 'react';
import { Code } from '~/components/atom/Typography';

const PROPERTY_ADDRESS = '{{PROPERTY_ADDRESS}}';
const SALE_PRICE = '{{SALE_PRICE}}';
const SALE_PRICE_DIFFERENCE = '{{SALE_PRICE_DIFFERENCE}}';

const BBWaardecheckIntl = {
  section: {
    preview: 'Preview',
    text: 'Teksten',
    textDescription: 'Personaliseer de waardecheck met je eigen tekst.',
  },
  mainImage: {
    title: 'Achtergrondfoto',
    description:
      'Upload een achtergrond, anders wordt de achtergrondfoto uit Ontwerp toegepast.',
  },
  heading: 'Kop',
  subHeading: 'Onderschrift',
  button: 'Button',
  disclaimer: 'disclaimer',
  variables: {
    propertyAdress: (
      <>
        Gebruik <Code>{PROPERTY_ADDRESS}</Code> in de tekst om het adres van het
        te waarderen object te tonen.
      </>
    ),
    salePrice: (
      <>
        Gebruik <Code>{SALE_PRICE}</Code> in de tekst om de koopsom van het
        referentieobject te tonen.
      </>
    ),
    salePriceDifference: (
      <>
        Gebruik <Code>{SALE_PRICE_DIFFERENCE}</Code> in de tekst om het verschil
        tussen de koopsom van het referentieobject en de afgegeven waarde te
        tonen.
      </>
    ),
  },
};

export default BBWaardecheckIntl;
