import React, { useState } from 'react';
import RadioButton from '~/components/molecule/RadioButton';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import { PathType } from '../../../nodeTypes/components/IfElseCard/types';
import type { DialogDeleteIfElseAction } from '~/components/page/Automation/v2/state/dialogs';
import TrueOrFalseLabel from '../../../TrueOrFalseLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body } from '~/components/atom/Typography';
import RadioButtonWithCustomLabel from '~/components/molecule/RadioButtonWithCustomLabel';

const text = {
  confirmModalHeader: 'Bevestig hoe deze actie wordt verwijderd',
  description:
    "Zowel 'waar' als 'niet waar' bevat acties. Welk pad je wil behouden?",
  deleteAll: 'Verwijder beide',
  confirm: 'Bevestigen',
  cancel: 'Annuleer',
};

export type Props = {
  dialog: DialogDeleteIfElseAction;
};

const DeleteIfElseDialog: React.FCC<Props> = ({ dialog }) => {
  const [pathType, setPathType] = useState<PathType | null>();
  const onSelectTruePath = () => setPathType(PathType.True);
  const onSelectFalsePath = () => setPathType(PathType.False);

  return (
    <Overlay onClose={dialog.onCancel}>
      <Dialog
        dataTestId="delete-ifelse-dialog"
        header={text.confirmModalHeader}
        body={
          <JustificationContainer direction="column" gap="s">
            <Body>{text.description}</Body>
            <RadioButtonWithCustomLabel
              margin={['base']}
              checked={pathType === PathType.True}
              onChange={onSelectTruePath}
              label={
                <TrueOrFalseLabel
                  onClick={onSelectTruePath}
                  type="true"
                  minWidth="120px"
                />
              }
            />
            <RadioButtonWithCustomLabel
              margin={['base']}
              checked={pathType === PathType.False}
              onChange={onSelectFalsePath}
              label={
                <TrueOrFalseLabel
                  onClick={onSelectFalsePath}
                  type="false"
                  minWidth="120px"
                />
              }
            />
            <RadioButton
              margin={['xxs', 'base', 'base', null]}
              checked={pathType === null}
              onChange={() => setPathType(null)}
              label={text.deleteAll}
            />
          </JustificationContainer>
        }
        buttons={[
          {
            dataTestId: 'modal-confirm-button',
            label: text.confirm,
            disabled: pathType === undefined,
            onClick: () => {
              if (pathType !== undefined) {
                dialog.onConfirm(pathType);
              }
            },
          },
        ]}
        onClose={dialog.onCancel}
      />
    </Overlay>
  );
};

export default DeleteIfElseDialog;
