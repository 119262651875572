import { Mailboxes } from '~/graphql/types.client.flow';

import { MAILBOX_TYPE } from '~/util/constants';

/**
 * Needed for flow to not complain about the weird typing. Is a problem with our flow typing.
 * Hopefully time will allow us to go typescript and not have to do things like this.
 */
const convertMailboxes = (mailboxes: Array<any>): Mailboxes => {
  const converted: Mailboxes = [];

  mailboxes.forEach(mailbox => {
    switch (mailbox.__typename) {
      case MAILBOX_TYPE.AccountMailbox:
      case MAILBOX_TYPE.OfficeMailbox:
      case MAILBOX_TYPE.UserMailbox: {
        converted.push(mailbox);

        break;
      }
      case MAILBOX_TYPE.AccountMailboxAlias:
      case MAILBOX_TYPE.OfficeMailboxAlias:
      case MAILBOX_TYPE.UserMailboxAlias: {
        converted.push(mailbox);

        break;
      }
    }
  });

  return mailboxes;
};

export default convertMailboxes;
