import React from 'react';
import { navigate, type RouteComponentProps } from '@gatsbyjs/reach-router';
import withErrorBoundary from '~/ErrorBoundary';
import FormsOverview from './components/Overview';
import NotFound from '../404';
import DHRouter from '~/components/atom/DHRouter';
import FormBuilderPage from './components/Builder';
import Wizard from './components/Wizard';
import ShareFormSettings from './components/ShareFormSettings';
import ImportForm from './components/ImportForm';
import ExportFormSubmissions from './components/ExportFormSubmissions';

type Props = RouteComponentProps;
const Forms: React.FCC<Props> = () => (
  <DHRouter>
    <FormsOverview path="/" />
    <Wizard
      path="/wizard/*"
      onClose={() => {
        void navigate('/-/forms');
      }}
    />
    <ExportFormSubmissions
      path="/export/:id"
      onClose={() => {
        void navigate('/-/forms');
      }}
    />
    <ShareFormSettings
      path="/sharing/:id"
      onClose={() => {
        void navigate('/-/forms');
      }}
    />
    <ImportForm
      path="/import"
      onClose={() => {
        void navigate('/-/forms');
      }}
    />
    <FormBuilderPage path="/:id" />

    <NotFound default />
  </DHRouter>
);

export default withErrorBoundary<Props>(Forms);
