/**
 *
 * Example return:
 * https://devsfa.dathuis.nl/external/nylas/auth/v1?state=User%7Cu---747d204b-f25c-4db7-b495-93ae6f052bb5%7Ca---cfe9654c-a9d9-4db7-bd01-b78ba3a083ab&code=rltRsUZQEcUscIoW1cNHMXAApuamlW#
 *
 * as localhost (for testing):
 * http://localhost:8000/external/nylas/auth/v1?state=User%7Cu---747d204b-f25c-4db7-b495-93ae6f052bb5%7Ca---cfe9654c-a9d9-4db7-bd01-b78ba3a083ab&code=rltRsUZQEcUscIoW1cNHMXAApuamlW#
 */

import React from 'react';

import Validation from '~/util/Validation';
import {
  RouteComponentProps,
  useLocation,
  WindowLocation,
} from '@gatsbyjs/reach-router';
import StartEmailSyncComponent from '../StartEmailSyncComponent';
import mutationVariablesForState from './utils/mutationVariablesForState';
import parseStateString from './utils/parseStateString';
import FullPageInformation from '~/components/template/FullPageInformation';
import PassThroughComponent from '../PassThroughComponent';

// Used in both v1 and v2 pages
export const syncErrorMessage =
  'Er is iets misgegaan bij het synchroniseren van het e-mail account. Sluit deze venster en probeer het nog eens.';

const text = {
  invalidStateTitle: 'Oeps!',
  close: 'Sluiten',
};

const NylasAuthoriseEndpointV1: React.FCC<RouteComponentProps> = () => {
  const location = useLocation() as WindowLocation<{
    code: string;
    stateString: string;
  }>;

  if (!location.state) return <PassThroughComponent location={location} />;

  const { code, stateString } = location.state;

  const props = {
    explanation: syncErrorMessage,
    title: text.invalidStateTitle,
    button: { onClick: () => window.close(), label: text.close },
  };

  if (
    !Validation.String.isNonEmptyString(stateString) ||
    !Validation.String.isNonEmptyString(code)
  ) {
    return <FullPageInformation {...props} />;
  }

  const state = parseStateString(stateString);

  if (state === null) {
    return <FullPageInformation {...props} />;
  }

  const mutationVariables = mutationVariablesForState(state, code);

  if (mutationVariables === null) {
    return <FullPageInformation {...props} />;
  }

  return <StartEmailSyncComponent state={state} code={code} />;
};

export default NylasAuthoriseEndpointV1;
