import { ClientFlowAction } from '~/graphql/types.client';
import { Reporter } from '~/hooks/useErrorReporter';

const parseEventData = (
  eventData: string | undefined,
  reporter: Reporter,
): ClientFlowAction | null => {
  if (!eventData) return null;
  try {
    return JSON.parse(eventData);
  } catch (error) {
    reporter.captureException(error);
    return null;
  }
};

export default parseEventData;
