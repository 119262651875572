import { atom, selectorFamily } from 'recoil';
import { ActivityV2 } from '~/components/template/EventTimelineV2/types';

export type GroupedActivities = Partial<Record<string, Array<ActivityV2>>>;
type ActivityMap = Record<string, GroupedActivities>;
const activities = atom<ActivityMap>({
  key: 'activities',
  default: {},
});

export const activitiesByContactId = selectorFamily({
  key: 'activitiesByContactId',
  set:
    (contactId: string | null) =>
    ({ set, get }, newValue: GroupedActivities) => {
      if (contactId === null) return {};

      const activityMap = get(activities);
      return set(activities, {
        ...activityMap,
        [contactId]: newValue,
      });
    },
  get:
    (contactId: string | null) =>
    ({ get }): GroupedActivities => {
      if (contactId === null) return {};

      const activityMap = get(activities);
      return activityMap[contactId] ?? {};
    },
});

export default activities;

/**
 * IDs of deleted activities used for filtering when updating the activities.
 */
export const deletedActivities = atom<Array<string>>({
  key: 'deletedActivities',
  default: [],
});
