import { useEffect } from 'react';
import useDebouncedState from '../useDebouncedState';

type ViewportType = {
  width: number;
  height: number;
};

/**
 * @returns The size of the viewport, by default debounced with a delay of 200.
 */
const useViewportSize = (opts?: { delay: number }): ViewportType => {
  const [viewportSize, setViewportSize] = useDebouncedState(
    {
      width: global.window.innerWidth,
      height: global.window.innerHeight,
    },
    { delay: opts?.delay ?? 200 },
  );

  useEffect(() => {
    const innerWidth = () => {
      setViewportSize({
        width: global.window.innerWidth,
        height: global.window.innerHeight,
      });
    };

    global.window.addEventListener('resize', innerWidth);

    return () => global.window.removeEventListener('resize', innerWidth);
  }, [setViewportSize]);

  return viewportSize;
};

export default useViewportSize;
