import type { FormBuilder_ScreenNodeFragment } from '~/graphql/types';
import isSelectTypeBlock, { type SelectBlockType } from '../isSelectTypeBlock';
import isInputTypeBlock, { type InputBlockType } from '../isInputTypeBlock';

export type BlockWithOutput = SelectBlockType | InputBlockType;

/**
 * Checks if the given block is an input or select block
 * @param {FormBuilder_ScreenNodeFragment['blocks'][number]} block
 */
const isBlockWithOutput = (
  block: FormBuilder_ScreenNodeFragment['blocks'][number],
): block is BlockWithOutput =>
  isSelectTypeBlock(block) || isInputTypeBlock(block);

export default isBlockWithOutput;
