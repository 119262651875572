import React, { useEffect, useState } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { useFormState } from 'react-final-form';
import { useTransition } from 'react-spring';
import illustrations from '~/components/atom/Illustration/components';
import { GroupLookup } from '~/components/organism/ToggleAccordion';
import {
  WidgetSettingsAppearance,
  WidgetSettingsPinned,
  WidgetSettingsPositionV2,
  type GetWidgetSettingsQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import loadWidget from '../../utils/loadWidget';
import Loader from './components/Loader';
import { reporter } from '~/hooks/useErrorReporter';
import { isNil } from 'ramda';
import parseJSONSafe from '~/util/parseJSONSafe';
import { PinnedAppsMap } from '../../state/pinnedApps';
import indexToWeight from '../../utils/indexToWeight';
import { ANIMATION_CONFIG } from '~/styles/constants';
import type { WidgetValues } from '../WidgetContent';

export type Props = {
  defaultConfiguration: GetWidgetSettingsQuery['getWidgetSettings']['defaultConfigValues'];
  initialSettings: WidgetValues;
  groupLookup: GroupLookup;
  pinnedApps: PinnedAppsMap;
  initiallyPinnedApps: PinnedAppsMap;
};

const WidgetPreview: React.FCC<Props> = React.memo(
  ({
    initialSettings,
    groupLookup,
    initiallyPinnedApps,
    pinnedApps,
    defaultConfiguration,
  }) => {
    const theme = useTheme();
    const account = useCurrentAccount();
    const [widgetApi, setWidgetApi] = useState<null | Function>(null);

    const transitions = useTransition(widgetApi === null, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: ANIMATION_CONFIG.config,
    });

    useFormState({
      onChange: ({ values }) => {
        if (widgetApi === null) return;

        const windowConfig = settingsToWindowConfig({
          defaultConfiguration,
          accountId: account.id,
          settings: values as WidgetValues,
          appsLookup: values.apps.appsGroupLookup ?? groupLookup,
          pinnedApps: values.apps.pinnedApps ?? pinnedApps,
          theme,
        });

        widgetApi('clearDismiss');
        widgetApi('__overwriteConfig', windowConfig);
      },
    });

    // Handle dismissal separately
    useEffect(() => {
      if (widgetApi !== null) {
        // Delay this call until after the dismiss command is called in UW useDismiss hook
        setTimeout(() => {
          widgetApi('clearDismiss');
        }, 1000);
      }

      return () => {
        // dismiss when navigating away from the page
        if (widgetApi !== null) {
          widgetApi('dismiss');
        }
      };
    }, [widgetApi]);

    useEffect(() => {
      // Enable logging for widget.
      // @ts-ignore
      // window.DH_DEBUG = 'true';
      if (widgetApi !== null) return;

      const initialWindowConfig = settingsToWindowConfig({
        defaultConfiguration,
        accountId: account.id,
        settings: initialSettings,
        appsLookup: groupLookup,
        pinnedApps: initiallyPinnedApps,
        theme,
      });
      const inner = async () => {
        const widgetApi = await loadWidget(account.id, initialWindowConfig);

        setWidgetApi(() => widgetApi);
        const windowConfig = settingsToWindowConfig({
          defaultConfiguration,
          accountId: account.id,
          settings: initialSettings,
          appsLookup: groupLookup,
          pinnedApps: initiallyPinnedApps,
          theme,
        });
        widgetApi('__overwriteConfig', windowConfig);
      };

      // We only need to call this once when widgetApi is null
      void inner();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      account.id,
      initialSettings,
      groupLookup,
      initiallyPinnedApps,
      widgetApi,
    ]);

    return transitions((style, item) =>
      item ? <Loader style={style} initialSettings={initialSettings} /> : null,
    );
  },
);

const getPinnedFields = (pinnedItem?: WidgetSettingsPinned) => {
  const pinned = {
    enabled: pinnedItem ? true : false,
    weight: pinnedItem ? indexToWeight(pinnedItem.weight) : 0,
  };

  return pinned;
};

export type StyleConfigColorPair = {
  background: string;
  color: string;
};

export type StyleConfig = {
  primary: StyleConfigColorPair;
  secondary: StyleConfigColorPair;
};

export enum Appearance {
  Small = 'Small',
  Large = 'Large',
}

export type Positions = {
  desktop: WidgetSettingsPositionV2;
  mobile: WidgetSettingsPositionV2;
};

export type Appearances = {
  desktop: WidgetSettingsAppearance;
  mobile: WidgetSettingsAppearance;
};

export type Locale = 'nl' | 'en';

export type ContentString = Record<Locale, string>;

export type WindowIframeApp = {
  type: 'iframe';
  appUrl: string;
  appParams: object;
  slug: string;
  title: ContentString;
  description: ContentString;
  illustration: keyof typeof illustrations;
  imageUrl?: string;
  highlighted?: boolean;
  hidden?: boolean;
};

export type WindowApp = WindowIframeApp;
export type WindowConfig = {
  accountId: string;
  positions: Positions;
  appearances: Appearances;
  logo: {
    src: string;
  };
  style: StyleConfig;
  zIndex: number;
  apps?: Array<WindowApp>;
};

type Args = {
  accountId: string;
  settings: WidgetValues;
  appsLookup: GroupLookup;
  pinnedApps?: PinnedAppsMap;
  defaultConfiguration: Props['defaultConfiguration'];
  theme: DefaultTheme;
};
const settingsToWindowConfig = ({
  accountId,
  settings,
  appsLookup,
  pinnedApps,
  defaultConfiguration,
  theme,
}: Args): WindowConfig => {
  const windowConfig: WindowConfig = {
    accountId,
    logo: {
      src: settings.design.logo?.url || '',
    },
    positions: {
      desktop: settings.design.positions.desktop,
      mobile: settings.design.positions.mobile,
    },
    appearances: {
      desktop: settings.design.appearances.desktop,
      mobile: settings.design.appearances.mobile,
    },
    // Make sure it is always below the ImpersonationContainer
    zIndex: theme.getTokens().zIndex.top,
    style: {
      primary: {
        background: settings.design.style.primary.background,
        color: settings.design.style.primary.color,
      },
      secondary: {
        background: settings.design.style.secondary.background,
        color: settings.design.style.secondary.color,
      },
    },
    // Widget url (window.__DH_CONFIG) is never invalidated on dev so it will always have the initial apps value that was set
    // Therefore never take the apps from there, use getWidgetSettings data to set selected apps to preview
    apps: [],
  };

  if (appsLookup) {
    const enabledApps = Object.keys(appsLookup).reduce((acc, key) => {
      const app = appsLookup[key];

      if (app.mainChecked === false) return acc;

      if (isNil(app.items) && app.appConfiguration) {
        const parsed = parseJSONSafe(app.appConfiguration);
        const imageUrl =
          settings.design.highlightImage?.url ??
          defaultConfiguration.highlightImage;
        const pinnedApp = pinnedApps ? pinnedApps[key] : undefined;

        acc.push({
          ...parsed,
          image: { src: imageUrl },
          pinned: getPinnedFields(pinnedApp),
          hidden: app.hidden,
        });
      }

      app.items?.forEach(curr => {
        if (curr.checked && curr.appConfiguration) {
          try {
            const parsed = parseJSONSafe(curr.appConfiguration);
            const imageUrl =
              settings.design.highlightImage?.url ??
              defaultConfiguration.highlightImage;
            const pinnedItem = pinnedApps ? pinnedApps[curr.id] : undefined;

            acc.push({
              ...parsed,
              image: { src: imageUrl },
              pinned: getPinnedFields(pinnedItem),
              hidden: curr.hidden,
            });
          } catch (error) {
            reporter.captureException(error);
          }
        }
      });
      return acc;
    }, [] as Array<any>);

    windowConfig.apps = enabledApps;
  }

  return windowConfig;
};

export default WidgetPreview;
