import { AppType } from '~/graphql/types';
import { AppConfig } from '../types';

const description = `
## Omschrijving
KadastraleKaart.com is dé gratis online kadastrale kaart van Nederland voor zowel zakelijke als particuliere gebruikers. Ook biedt KadastraleKaart.com tegen betaling rapporten aan. Met deze app voeg je een webwinkel toe aan jouw website, waarmee je deze rapporten kan verkopen. Hiermee genereer je nieuwe leads en verdien je een aantrekkelijke commissie. 

Kies zelf welke rapporten je wil aanbieden:

**Rapport** (Verkoopprijs incl. btw)
1. Bestemmingsplan en vergunningen - € 9,95
2. Kadastrale kaart met luchtfotohistorie - € 5,95
3. Kadastrale kaart met maatvoering - € 59,95
4. Kadastrale kaart rapport - € 4,95

Voor ieder verkocht rapport verdien je een commissie van 10% op de verkoopprijs. Zodra er een rapport is gekocht, ontvang je in DatHuis de contactgegevens van de lead. Daarna wordt  automatisch de commissie in je wallet bijgeschreven.

Heb je vragen over KadastraleKaart.com en haar producten? Neem dan contact op via [https://kadastralekaart.com/support](https://kadastralekaart.com/support).

`;

const termsOfUse = `
KadastraleKaart.com is onderdeel van GeoGap B.V. en wordt mogelijk gemaakt door: Kadaster, PDOK, Google en het CBS. Aan de kadastergegevens die op deze website worden weergegeven kunnen geen rechten worden ontleend. KadastraleKaart.com is niet bij machte om aanpassingen te doen aan de brondata van het Kadaster en is niet aansprakelijk voor eventuele onvolkomenheden en onjuistheden. 

De gegevens worden automatisch bijgewerkt via het Kadaster, dus uw klanten vinden altijd recente perceelinformatie. Bij vragen, opmerkingen of tips kunt u terecht bij de [veelgestelde vragen](https://kadastralekaart.com/veelgestelde-vragen) en de [support-pagina](https://kadastralekaart.com/support).

Door het activeren van deze app geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van KadastraleKaart.com (Geogap B.V). Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Op de dienstverlening van KadastraleKaart.com zijn de voorwaarden van Geogap B.V. van toepassing. Meer informatie vind je op [https://kadastralekaart.com](https://kadastralekaart.com).

`;

export const geogap: AppConfig = {
  __typename: 'AppStatus_GeoGap',
  appType: AppType.GeoGap,
  type: 'activate_only',
  slug: 'geogap',
  name: 'KadastraleKaart.com - Webwinkel',
  tags: ['Webwinkel', 'Kadaster', 'Leadgeneratie'],
  provider: 'GeoGap B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/GeoGap.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/GeoGap.svg',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/8707520-over-de-kadastralekaart-com-webwinkel-app',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'default',
};
