import type { AttachmentWithKey } from '~/components/page/Automation/v2/components/UpdateAction/hooks/useEmailFileAttachments';
import type { Client_FlowV2_EmailAttachment } from '../../../types';

/**
 * Passes uniq keys to the attachments that helps to edit a specific attachment.
 * If it is File attachment the key is s3key of the file. If it is a Pointer attachment
 * we create a key from the pointer path
 *
 * @param {Array<Attachment>} attachments - Attachments
 * keywords: attachments with key
 */
const passKeysToAttachments = (
  attachments: Array<Client_FlowV2_EmailAttachment>,
): Array<AttachmentWithKey> =>
  attachments.map(attachment => {
    let key = '';

    if (attachment.file.__typename === 'Flow___Argument_File') {
      key = attachment.file.value_file.s3key;
    }

    if (attachment.file.__typename === 'Flow___Argument_Pointer') {
      key = attachment.file.pointer.path.toString();
    }

    return {
      ...attachment,
      key,
    };
  });

export default passKeysToAttachments;
