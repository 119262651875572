import type { Theme } from '~/theme';

const getColorForLeadscore = (theme: Theme, leadScoreToDisplay: number) => {
  const tokens = theme.getTokens();

  if (leadScoreToDisplay === 99) return tokens.colors.leadScore['eq99'];
  if (leadScoreToDisplay >= 90) return tokens.colors.leadScore['gte90'];
  if (leadScoreToDisplay >= 70) return tokens.colors.leadScore['gte70'];
  if (leadScoreToDisplay >= 50) return tokens.colors.leadScore['gte50'];
  if (leadScoreToDisplay >= 30) return tokens.colors.leadScore['gte30'];
  return tokens.colors.leadScore['lt30'];
};
export default getColorForLeadscore;
