import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import AsideHeader from '../AsideHeader';
import { animated, useSpring } from 'react-spring';

import TEST_ID from './index.testid';
import useMeasure from '~/hooks/useMeasure';
import { useRecoilState } from 'recoil';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Button from '~/components/atom/Button';
import { eventsState } from '../../../../state/nodesAndEvents';
import EventEntry from './components/EventEntry';
import getNewEventTemplate from '../../../../utils/getNewEventTemplate';

type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const text = {
  heading: 'Doorzoekbare velden',
  newEvent: 'Nieuwe event template',
};

const Events: React.FCC<Props> = ({
  dataTestId,
  expanded = false,
  onToggleExpand,
}) => {
  const { ref, bounds } = useMeasure();
  const theme = useTheme();
  const [events, setEvents] = useRecoilState(eventsState);

  const spring = useSpring({
    height: expanded ? bounds.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  return (
    <Container data-testid={dataTestId}>
      <AsideHeader
        dataTestId={TEST_ID.ASIDE_HEADER}
        heading={text.heading}
        icon="clock"
        onClick={onToggleExpand}
        expanded={expanded}
      />
      <OverflowContainer style={spring}>
        <Inner ref={ref}>
          <JustificationContainer direction="column" gap="m">
            <EventList>
              {events.map(event => (
                <EventEntry key={event.id} event={event} />
              ))}
            </EventList>
          </JustificationContainer>
          <Button
            label={text.newEvent}
            icon="plus"
            onClick={() => {
              setEvents(prev => [...prev, getNewEventTemplate()]);
            }}
          />
        </Inner>
      </OverflowContainer>
    </Container>
  );
};

const Container = styled.aside(
  ({ theme }) => css`
    z-index: ${theme.z('top')};
    user-select: none;
    width: 100%;
  `,
);

const Inner = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
  `,
);

const OverflowContainer = styled(animated.div)`
  overflow-x: hidden;
`;

const EventList = styled.ul(
  ({ theme }) => css`
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.space('m')};
  `,
);

export default Events;
