import { selector } from 'recoil';
import { formState, initialFormAtom } from '.';
import { equals } from 'ramda';

type ChangesState = null | {
  hasChanges: boolean;
};

export const changesState = selector<ChangesState>({
  key: 'formBuilder/changesState',
  get: ({ get }) => {
    const initialForm = get(initialFormAtom);
    const currentForm = get(formState);

    if (!initialForm || !currentForm) return null;

    const hasChanges = !equals(initialForm, currentForm);

    return { hasChanges };
  },
});
