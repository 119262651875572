import React from 'react';
import ProgressiveImage, { Props as ImageProps } from '../ProgressiveImage';

export type Props = React.ImgHTMLAttributes<HTMLImageElement> &
  Omit<ImageProps, 'lowQualitySrc' | 'highQualitySrc'> & {
    src: string;
    width: number;
  };

const lowQualityWidth: number = 80;

const ProgressiveImgIx: React.FCC<Props> = ({ src, width, ...props }) => {
  if (src.includes('?')) {
    return (
      <ProgressiveImage
        lowQualitySrc={src}
        highQualitySrc={src}
        srcset={`${src} 1x`}
        width={width}
        {...props}
      />
    );
  }

  const lowQualitySrc = `${src}?auto=compressed&fit=clip&w=${lowQualityWidth}`;
  const highQualitySrc = `${src}?auto=compressed&fit=clip&w=${width}`;
  const srcset = `
    ${highQualitySrc} 1x,
    ${src}?auto=compressed&fit=clip&w=${width * 2} 2x,
    ${src}?auto=compressed&fit=clip&w=${width * 3} 3x,
    ${src}?auto=compressed&fit=clip&w=${width * 4} 4x
  `;

  return (
    <ProgressiveImage
      lowQualitySrc={lowQualitySrc}
      highQualitySrc={highQualitySrc}
      srcset={srcset}
      width={width}
      {...props}
    />
  );
};

export default ProgressiveImgIx;
