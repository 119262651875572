import { AppStatus__typename } from '~/graphql/types.client';

const getTypenameForWaardecheckContainer = (
  typename: AppStatus__typename,
): 'AppBBWaardecheckContainer' | 'AppVBOWaardecheckContainer' =>
  typename === 'AppStatus_BBWaardecheck'
    ? 'AppBBWaardecheckContainer'
    : 'AppVBOWaardecheckContainer';

export default getTypenameForWaardecheckContainer;
