import React from 'react';
import Loading from '~/components/atom/Loading';
import { convertServerLeadScoreToFrontendLeadScore } from '~/components/page/Contacts/util/composeContactListData';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { useGetContactInTaskQuery } from '~/graphql/types';
import ContactDetails from '../../../ContactDetails';

type Props = {
  contactId: string;
};

const ContactInTask: React.FC<Props> = ({ contactId }) => {
  const account = useCurrentAccount();
  const { data, loading } = useGetContactInTaskQuery({
    variables: { accountId: account.id, contactId: contactId },
  });
  if (loading) return <Loading />;

  if (!data) return null;

  const contact = data.getContact;

  if (contact == null) return null;

  const { LeadScore, ...rest } = contact;
  return (
    <ContactDetails
      contact={{
        ...rest,
        leadScore: convertServerLeadScoreToFrontendLeadScore(LeadScore),
      }}
    />
  );
};

export default ContactInTask;
