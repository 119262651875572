import { atom } from 'recoil';

/** Value is true if it has changed */
export type FlowChanges = {
  flowName: boolean;
  flowDescription: boolean;
  maximumFlowRun: boolean;
  enabled: boolean;

  /** Keeps track of updated action fields in Action forms  */
  actions: { [key: string]: boolean };

  /** Keeps track of all actions that are added/deleted */
  actionCount: boolean;
};

export const defaultChangesState: FlowChanges = {
  flowName: false,
  flowDescription: false,
  maximumFlowRun: false,
  enabled: false,
  actionCount: false,
  actions: {},
};

const flowChanges = atom<FlowChanges>({
  key: 'flowChanges',
  default: defaultChangesState,
});

export default flowChanges;
