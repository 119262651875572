const triggerResetTempPassword = async (email: string) => {
  const resetEndpoint = `${process.env.AWS_COGNITO_HTTPAPI_URL as string}/resendPassword?email=${encodeURIComponent(email)}`;
  const response = await fetch(resetEndpoint);

  // Regardless of the status just return the text
  if (response.status === 200 || response.status === 400) {
    return response.text();
  }

  return response.text();
};

export default triggerResetTempPassword;
