import {
  TableHeaderDefinitionCellType,
  TableCell,
  NumberRenderFunction,
} from '../../types.flow';
import { BaseHeaderCellOptions } from './base';

import { asBaseOptions } from './base';

type NumberHeaderCellOptions = BaseHeaderCellOptions & {
  renderFunction?: NumberRenderFunction;
};
const asNumberTableHeaderCell = ({
  renderFunction,
  ...rest
}: NumberHeaderCellOptions): TableHeaderDefinitionCellType<number> => {
  const renderFn =
    renderFunction == null
      ? (value: number, _tableCell: TableCell<number>) => value.toString()
      : renderFunction;

  const options = {
    ...asBaseOptions({ ...rest }),
    Cell: (tableCell: TableCell<number>) =>
      renderFn(tableCell.cell.value, tableCell),
  };

  return options;
};

export default asNumberTableHeaderCell;
