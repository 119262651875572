import React from 'react';
import { EdgeLabelRenderer } from 'reactflow';
import { useRecoilValue } from 'recoil';
import styled, { css, useTheme } from 'styled-components';
import Icon from '~/components/atom/Icon';
import interactions from '~/components/page/Automation/v2/state/interactions';

const text = {
  insertAction: 'Actie invoegen',
};

export type Props = {
  dataTestId: string;

  // Source action's offset x
  sourceX: number;

  // Source action's offset y
  sourceY: number;

  // Target action's offset y
  targetY: number;

  // Callback to insert the copy pasted action
  onInsertAction: () => void;
};

const InsertCopiedActionContainer: React.FCC<Props> = React.memo(
  ({ onInsertAction, sourceX, sourceY, targetY, dataTestId }) => {
    const theme = useTheme();

    const currentInteraction = useRecoilValue(interactions);

    if (currentInteraction?.type !== 'copyAction') return null;

    // place it in the middle of both actions
    const offsetY = (targetY - sourceY) / 2;

    return (
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${sourceX}px,${sourceY + offsetY}px)`,
            fontSize: theme.fontSize('s'),
            // Everything inside EdgeLabelRenderer has no pointer events by default
            // When we have an interactive element, we need to set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <Container onClick={onInsertAction} data-testid={dataTestId}>
            <Icon name="clipboard" strokeWidth={3} />
            {text.insertAction}
          </Container>
        </div>
      </EdgeLabelRenderer>
    );
  },
);

const Container = styled.button<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    cursor: pointer;
    color: ${theme.color('primary', 'light')};
    background-color: ${theme.color('white')};
    border: 2px dashed ${theme.color('primary', 'light')};
    border-radius: ${theme.getTokens().border.radius.s};
    padding: ${theme.space('s')} ${theme.space('base')};
    gap: ${theme.space('xs')};
    font-weight: ${theme.fontWeight('semiBold')};
    transition: all 0.3s ease-out;

    &:hover {
      transform: scale(1.3);
    }
  `,
);

export default InsertCopiedActionContainer;
