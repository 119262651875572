import React from 'react';
import styled, { css } from 'styled-components';
import { CARD_Z } from '../BaseEventCard';
import { ActivityV2 } from '../../types';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = Pick<ActivityV2, 'icon'> & {
  dataTestId?: string;
};

const TimelineWrapper: React.FCC<Props> = ({ icon, children }) => (
  <JustificationContainer gap="xl">
    <Icon
      justification="center"
      align="center"
      padding={['xxs']}
      border={{ radius: 'full' }}
      $icon={icon.url}
      backgroundColor={{ group: 'white' }}
    />

    {children}
  </JustificationContainer>
);

const Icon = styled(JustificationContainer)<{ $icon?: string }>(
  ({ theme, $icon }) => css`
    flex: 1 0 ${theme.space('xxxl')};
    width: ${theme.space('xxxl')};
    height: ${theme.space('xxxl')};
    background-image: url(${$icon});
    background-size: cover;
    background-position: center;

    box-shadow: ${theme.boxShadow('modal')};
    z-index: ${CARD_Z - 1};

    &:after {
      content: '';
      border-top: 2px dashed ${theme.color('tertiary', 'light')};
      position: absolute;
      width: ${theme.space('xxxl')};
      left: ${theme.space('xxxl')};
      z-index: ${CARD_Z - 2};
    }
  `,
);

export default TimelineWrapper;
