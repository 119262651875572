import React from 'react';
import { useRecoilValue } from 'recoil';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { issuesByPath } from '~/components/page/Forms/components/Builder/state/issues';
import Issue from '../../../../../AsideContainer/components/Issues/components/Issue';
import { isNil } from 'ramda';

export type Props = {
  blockKey: string;
  nodeId: string;
};

const BlockLayout: React.FCC<Props> = ({
  dataTestId,
  children,
  nodeId,
  blockKey,
  ...rest
}) => {
  const issues = useRecoilValue(issuesByPath([nodeId, blockKey]));

  return (
    <JustificationContainer
      dataTestId={dataTestId}
      width="100%"
      direction="column"
      padding={['s']}
      backgroundColor={
        issues.length === 0
          ? { group: 'white', variant: 'base' }
          : { group: 'danger', variant: 'translucent' }
      }
      border={{
        width: 's',
        radius: 'base',
        color: { group: issues.length === 0 ? 'white' : 'danger' },
      }}
      {...rest}
    >
      <JustificationContainer width="100%" direction="column" gap="m">
        {children}
        {!isNil(issues[0]) && <Issue {...issues[0]} showLink={false} />}
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default BlockLayout;
