import { equals } from 'ramda';

const countWidgetChanges = (initialObject: any, currentObject: any): number => {
  let changes = 0;

  const compareValues = (initialValue: any, currentValue: any): void => {
    // Check if both values are objects
    if (typeof initialValue === 'object' && typeof currentValue === 'object') {
      // Recursively compare nested objects
      changes += countWidgetChanges(initialValue, currentValue);
    } else if (initialValue !== currentValue) {
      // Compare non-object values
      changes++;
    }
  };

  const handleSpecialFields = (key: string): void => {
    const initialValue = initialObject[key];
    const currentValue = currentObject[key];

    // Check if both values are objects
    if (typeof initialValue === 'object' && typeof currentValue === 'object') {
      // Compare the objects as a whole
      if (!equals(initialValue, currentValue)) {
        changes++; // Count as one change
      }
    } else {
      // Compare non-object values
      if (initialValue !== currentValue) {
        changes++; // Count as one change
      }
    }
  };
  // Iterate over the keys of the initial object
  for (const key in initialObject) {
    // Check if the key exists in the current object
    if (currentObject?.hasOwnProperty(key)) {
      // Special handling for logo and highlightImage fields
      if (key === 'logo' || key === 'highlightImage') {
        handleSpecialFields(key);
      } else {
        // Compare the values of the corresponding keys
        compareValues(initialObject[key], currentObject[key]);
      }
    } else {
      // Key doesn't exist in the current object
      changes++;
    }
  }

  // Check for any additional keys in the current object
  for (const key in currentObject) {
    if (!initialObject?.hasOwnProperty(key)) {
      changes++;
    }
  }

  return changes;
};

export default countWidgetChanges;
