import cleanedFilename from '~/util/cleanedFilename';

export default {
  ANSWER_EMAIL_BUTTON: `${cleanedFilename(__filename)}-answer-email-button`,
  CLOSE_ANSWER_BLOCK: `${cleanedFilename(__filename)}-close-answer-button`,
  MESSAGE_BLOCK: `${cleanedFilename(__filename)}-message-block`,
  SHOW_FULL_MESSAGE_BUTTON: `${cleanedFilename(__filename)}-show-full-message`,
  HIDE_FULL_MESSAGE_BUTTON: `${cleanedFilename(__filename)}-hide-full-message`,
  FULL_MESSAGE_BLOCK: `${cleanedFilename(__filename)}-full-message-block`,
};
