import type {
  BrandSettings,
  BrandSettingsField,
} from '~/hooks/useBrandSettings';
import type { SelectedBrandSettings } from '../..';

// Export for testing purposes
export type Args = {
  brandSettings: BrandSettings;
  selectedValues: Set<BrandSettingsField>;
};

const getSelectedBrandSettings = ({
  brandSettings,
  selectedValues,
}: Args): SelectedBrandSettings => {
  const selectedBrandSettings: SelectedBrandSettings = Array.from(
    selectedValues,
  ).reduce((acc, curr) => {
    if (curr === 'colors') acc.colors = brandSettings.colors;
    if (curr === 'url') acc.url = brandSettings.url;
    if (curr === 'logo') acc.logo = brandSettings.logo;

    return acc;
  }, {} as SelectedBrandSettings);

  return selectedBrandSettings;
};

export default getSelectedBrandSettings;
