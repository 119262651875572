/**
 * This extension was ported from https://github.com/ReyhaneMasumi/mapbox-gl-draw-cut-polygon-mode/blob/main/demo/src/App.js
 * Originally this was plain JavaScript and after making it a TypeScript file everything went red.
 * I can't find the correct types for this shape, but it works. So ts-ignore the hell out of it.
 *
 * Modify at your own risk
 */
import type { MapboxMap } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import type { WorkingAreaType } from '..';

export type DrawBarButton = {
  on: keyof HTMLElementEventMap;
  action: () => void;
  classes?: Array<string>;
  content: HTMLElement;
  title?: string;
};

export type ConstructorProps = {
  props: ConstructorParameters<typeof MapboxDraw>[0];
  buttons?: Array<DrawBarButton>;
};
class ExtendedMapboxDraw extends MapboxDraw {
  private _newButtons: Array<DrawBarButton>;
  private _container?: HTMLElement;
  parentOnAdd: (map: MapboxMap) => HTMLElement;
  /** Adds or removes the feature from the selected features */
  toggleSelection: (featureId: string | number) => void;
  /** Starts selection state inside the map */
  startSelection: (drawType: WorkingAreaType) => void;
  /** Completes or discards the selection depending on the amount of selected coordinates */
  stopSelection: () => void;
  /** Cancels current select, trashing the current points */
  cancelSelection: () => void;

  constructor({ buttons, props }: ConstructorProps) {
    super(props);
    this._newButtons = buttons || [];
    this.parentOnAdd = this.onAdd;

    // @ts-expect-error the only way to override this method from MapboxDraw. We have to delete and re-add it below
    delete this.onAdd;
  }
  onAdd(map: MapboxMap) {
    // the default buttons
    this._container = this.parentOnAdd(map);
    // adding each new button to Draw Bar
    this._newButtons.forEach(button => {
      const elButton = document.createElement('button');
      elButton.className = 'mapbox-gl-draw_ctrl-draw-btn';

      if (button.title) {
        elButton.title = button.title;
      }

      if (button.classes) {
        button.classes.forEach(buttonClass => {
          elButton.classList.add(buttonClass);
        });
      }
      elButton.appendChild(button.content);
      elButton.addEventListener(button.on, button.action);

      this._container?.appendChild(elButton);
    });
    return this._container;
  }
}

export default ExtendedMapboxDraw;
