import React from 'react';
import styled from 'styled-components';

import {
  TableColumn,
  TableHeaderCellType,
} from '~/components/bad/DataTables/types.flow';

type Props = {
  column: TableColumn | TableHeaderCellType<any>;
};
const ColumnResizer: React.FCC<Props> = ({ column }) => (
  <Resizer
    {...(column.getResizerProps ? column.getResizerProps() : {})}
    isResizing={column.isResizing}
    onClick={e => {
      e.nativeEvent.stopImmediatePropagation();
    }}
  />
);

const Resizer = styled.div<{ isResizing?: boolean | null }>`
  width: 5px;
  height: 100%;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  /* prevents from scrolling while dragging on touch devices */
  touch-action: none;
  z-index: 1;
`;

export default ColumnResizer;
