import { SubscriptionClient } from 'subscriptions-transport-ws';
import { v4 as uuid4 } from 'uuid';
import convertObjectToBase64 from '~/util/converters/convertObjectToBase64';

// @ts-ignore
export default class AppSyncSubscriptionClient extends SubscriptionClient {
  authFunction;
  originalUrl;

  constructor(url, args, authFunction) {
    super(url, args);
    this.authFunction = authFunction;
    this.originalUrl = url;
  }

  connect = async () => {
    const authInfo = await this.authFunction();

    /** @see https://docs.aws.amazon.com/appsync/latest/devguide/real-time-websocket-client.html */
    const url = `${this.originalUrl}?header=${convertObjectToBase64(
      authInfo,
    )}&payload=${convertObjectToBase64({})}`;

    // @ts-ignore
    this.url = url;

    // @ts-ignore
    super.connect();
  };

  generateOperationId() {
    return uuid4();
  }

  processReceivedData(receivedData) {
    try {
      const parsedMessage = JSON.parse(receivedData);
      if (parsedMessage?.type === 'start_ack') return;
    } catch (e) {
      throw new Error('Message must be JSON-parsable. Got: ' + receivedData);
    }

    // @ts-ignore
    super.processReceivedData(receivedData);
  }
}
