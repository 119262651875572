import useUsers from '../useUsers';
import { filter, flatten, propEq } from 'ramda';
import useOffices from '../useOffices';
import useSessionHydration from '../useSessionHydration';
import type { OfficeRelationship } from '~/graphql/types';
import { isNonEmptyString } from '~/util/Validation/String';

export type OfficeRelationTable = {
  [userId: string]: { [officeId: string]: boolean };
};

/**
 * Returns all office relations for a user in a given office
 */
const useOfficeRelationsForUsers = ({ officeId }): OfficeRelationTable => {
  const [{ relations }] = useSessionHydration();
  const users = useUsers({ officeIds: [officeId] });
  const offices = useOffices({});
  const userIds = users.map(({ id }) => id);

  const officeRelationsFromSessionHydration = filter(
    propEq('OfficeRelationship', '__typename'),
    relations,
  ) as Array<OfficeRelationship>;

  const officeRelationsLookup: OfficeRelationTable = {};

  userIds.forEach(userId => {
    officeRelationsLookup[userId] = {};

    const userRelationToOffices = offices.map(office =>
      officeRelationsFromSessionHydration.filter(
        relation =>
          relation.userId === userId && relation.officeId === office.id,
      ),
    );

    const officeIdsForUser = flatten(userRelationToOffices).map(
      o => o.officeId,
    );

    officeIdsForUser.forEach(officeId => {
      if (isNonEmptyString(userId) && isNonEmptyString(officeId))
        officeRelationsLookup[userId][officeId] = true;
    });
  });

  return officeRelationsLookup;
};

export default useOfficeRelationsForUsers;
