import React from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/components/page/ErrorScreen';
import Catalog from '~/Catalog';
import { WithNavigationFrame as NotFound } from '~/components/page/404';
import FlowList from './FlowList';
import FlowBuilderV2 from '../v2/index';
import DHRouter from '~/components/atom/DHRouter';
import withPermissionCheck from '~/hocs/withPermissionCheck';
import ShareFlowSettings from './components/ShareFlowSettings';
import ImportFlow from './components/ImportFlow';

type Props = RouteComponentProps;
const Flows: React.FC<Props> = () => (
  <DHRouter>
    <FlowList path="/" />
    <FlowBuilderV2.Create path="/builder-v2/create/:token" />
    <FlowBuilderV2.Update path="/builder-v2/update/:id" />
    <ShareFlowSettings
      path="/sharing/:id"
      onClose={() => {
        void navigate('/-/automation/flows');
      }}
    />
    <ImportFlow
      path="/import"
      onClose={() => {
        void navigate('/-/automation/flows');
      }}
    />
    <NotFound default />
  </DHRouter>
);

export default withPermissionCheck(
  withErrorBoundary<Props>(
    Flows,
    <ErrorScreen explanation={Catalog.genericUnknownErrorMessage} />,
  ),
  ['root.automation'],
);
