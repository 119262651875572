import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Heading4, Variant } from '~/components/atom/Typography';
import { QuickSegmentFragment } from '~/graphql/types';
import {
  FilterBlockContainer,
  LabelContainer,
  ListContainer,
  ShowMoreContainer,
} from '../../commonComponents';
import { ListItem } from '../Segments/components/SegmentRow';
import activeFilter from '../Segments/state/activeFilter';
import TEST_ID from './index.testid';

export type Props = {
  items: Array<QuickSegmentFragment>;
  title: string;
  itemCountToDisplay?: number;
  onItemSelect: (filters: QuickSegmentFragment['filters']) => void;
};

const text = {
  showMore: 'Toon meer',
  showLess: 'Toon minder',
};

const FilterBlock: React.FCC<Props> = ({
  dataTestId,
  items,
  title,
  onItemSelect,
  itemCountToDisplay = 10,
}) => {
  const [showAllItems, setShowAllItems] = useState<boolean>(false);
  const [{ filterId: activeId }, setActiveFilter] =
    useRecoilState(activeFilter);

  const handleShowMoreItems = () => {
    setShowAllItems(!showAllItems);
  };

  const itemsToDisplay = showAllItems
    ? items
    : items.slice(0, itemCountToDisplay);

  return (
    <FilterBlockContainer data-testid={dataTestId}>
      <Heading4 variant={Variant.primary} data-testid={TEST_ID.ITEM_TITLE}>
        {title}
      </Heading4>

      <ListContainer>
        {itemsToDisplay.map((itemToDisplay, index) => (
          <ListItem
            key={`${itemToDisplay.name}-${index}`}
            data-testid={TEST_ID.ITEM_CLICK_HANDLER}
            onClick={() => {
              setActiveFilter({
                segmentId: null,
                filterId: itemToDisplay.name,
              });

              onItemSelect(itemToDisplay.filters);
            }}
            $hasFocus={activeId === itemToDisplay.name}
          >
            <LabelContainer>{itemToDisplay.name}</LabelContainer>
          </ListItem>
        ))}
      </ListContainer>

      {items.length > itemCountToDisplay && (
        <ShowMoreContainer
          onClick={() => handleShowMoreItems()}
          data-testid={TEST_ID.ITEM_SHOW_MORE}
        >
          {showAllItems ? text.showLess : text.showMore}
        </ShowMoreContainer>
      )}
    </FilterBlockContainer>
  );
};
export default FilterBlock;
