import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ChangeableEmailProps } from '~/components/organism/SendEmailBlock/components/MessageHeader';
import RowLabel from './RowLabel';
import TEST_ID from './SenderRow.testid';
import Link from '~/components/molecule/Link';
import useEmailConfigLink from '~/hooks/useEmailConfigLink';
import { isNil } from 'ramda';
import { NamedEmail } from '~/util/namedEmailFormatter';
import InputComponent from './InputComponent';

const text = {
  senderLabel: 'Van:',
  notSyncedCorrectly: (link: string) => (
    <>
      Er is een probleem met dit e-mailadres. De eigenaar van het e-mailadres
      kan het{' '}
      <Link dataTestId={TEST_ID.EMAIL_SOURCE_LINK} to={link}>
        hier
      </Link>{' '}
      repareren
    </>
  ),
};

type Props = {
  isEmailSynced: boolean | null;
  changeableEmail: ChangeableEmailProps;
  disabled?: boolean;
  shouldValidate: boolean;
};

const SenderRow: React.FCC<Props> = ({
  changeableEmail,
  disabled,
  shouldValidate,
  isEmailSynced = null,
}): React.ReactElement => {
  const [senderOption, setSender] = useState<NamedEmail | null>(null);
  useEffect(() => {
    if (changeableEmail.readOnly && changeableEmail.namedEmail) {
      const { namedEmail } = changeableEmail;

      setSender(namedEmail);
    } else {
      const { selectedIdx, options } = changeableEmail;
      const senderOption =
        !isNil(selectedIdx) && selectedIdx >= 0
          ? options[selectedIdx].payload
          : null;
      setSender(senderOption);
    }
  }, [changeableEmail]);

  const emailConfigLink = useEmailConfigLink({ senderOption });

  return (
    <>
      <SenderLabel>{text.senderLabel}</SenderLabel>

      <SenderInput>
        <InputComponent
          changeableEmail={changeableEmail}
          disabled={disabled}
          shouldValidate={shouldValidate}
          dataTestId={TEST_ID.DROPDOWN}
        />
      </SenderInput>
      {isEmailSynced === false && (
        <ErrorLabel data-testid={TEST_ID.EMAIL_ERROR_LABEL}>
          {text.notSyncedCorrectly(emailConfigLink)}
        </ErrorLabel>
      )}
    </>
  );
};

const SenderLabel = styled(RowLabel)<{}>`
  grid-row: sender-start / sender-end;
  grid-column: label-start / label-end;
`;

const ErrorLabel = styled.div(
  ({ theme }) => css`
    grid-column: input-start / button-end;
    color: ${theme.color('danger')};

    a {
      color: ${theme.color('danger')};
    }
  `,
);

const SenderInput = styled.div<{}>`
  grid-row: sender-start / sender-end;
  grid-column: input-start / button-end;
`;

export default SenderRow;
