import type { FlowPathEntry, GetFieldsByPathOpts } from '..';
import { isEqualSubject } from '../../getSubject';
import generateNextForDirectory from '../generateNextForDirectory';
import generateNextForInstanceField from '../generateNextForInstanceField';

const pruneEmptyFields = (
  field: FlowPathEntry,
  opts: GetFieldsByPathOpts & {
    usedSubjectMap: Record<string, number | undefined>;
    usedInstanceField?: string;
  },
): boolean =>
  pruneEmptyFieldsImpl(field, {
    ...opts,
    checkedSubjectMap: {},
  });

const pruneEmptyFieldsImpl = (
  field: FlowPathEntry,
  opts: GetFieldsByPathOpts & {
    usedSubjectMap: Record<string, number | undefined>;
    usedInstanceField?: string;
    checkedSubjectMap: {
      [type: string]: { [typeId: string]: boolean | undefined } | undefined;
    };
  },
): boolean => {
  const { checkedSubjectMap, limitToInstanceSubjects, usedSubjectMap } = opts;

  switch (field.__typename) {
    case 'FlowData___InstanceCondition':
    case 'FlowData___SubjectField':
      return true;
    case 'FlowData___InstanceField':
      if (checkedSubjectMap?.[field.type.type]?.[field.type.typeId ?? '']) {
        return false;
      }

      let innerCheckedSubjectMap = checkedSubjectMap[field.type.type];
      if (innerCheckedSubjectMap == null) {
        checkedSubjectMap[field.type.type] = innerCheckedSubjectMap = {};
      }

      innerCheckedSubjectMap[field.type.typeId ?? ''] = true;

      if (
        limitToInstanceSubjects &&
        limitToInstanceSubjects.some(sId => isEqualSubject(sId, field.type))
      ) {
        return true;
      }

      const subjectCounter = usedSubjectMap[field.parent.type] ?? 0;

      const nextUsedSubjectMap = {
        ...usedSubjectMap,
        [field.parent.type]: subjectCounter + 1,
      };

      const result = generateNextForInstanceField(field, {
        ...opts,
        usedSubjectMap: nextUsedSubjectMap,
      });
      if (result.error != null) return false;

      for (const instanceField of result.result) {
        const found = pruneEmptyFieldsImpl(instanceField, {
          ...opts,
          usedSubjectMap: nextUsedSubjectMap,
          checkedSubjectMap,
        });
        if (found) return true;
      }

      return false;
    case 'FlowData___Directory':
      const fields = generateNextForDirectory(field, opts);

      for (const nextField of fields) {
        switch (nextField.__typename) {
          case 'FlowData___InstanceCondition':
          case 'FlowData___SubjectField':
            return true;
          case 'FlowData___Directory': {
            const found = pruneEmptyFieldsImpl(nextField, {
              ...opts,
              checkedSubjectMap,
            });
            if (found) return true;
            continue;
          }
          case 'FlowData___InstanceField': {
            const found = pruneEmptyFieldsImpl(nextField, {
              ...opts,
              checkedSubjectMap,
            });
            if (found) return true;
            continue;
          }
        }
      }

      return false;
  }
};

export default pruneEmptyFields;
