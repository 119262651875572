/**
 * returns percentage of the given total amount
 * @param {number} currentValue - The current value should be partial of total
 * @param {number} totalValue - The total amount
 * keywords: percentage, getPercentage, percent
 */
const getPercentage = (currentValue: number, totalValue: number) => {
  const result = (100 * currentValue) / totalValue;
  if (isNaN(result)) return 0;
  return result;
};

export default getPercentage;
