import React, { useEffect } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  OutputFieldList,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import useWizardStep from '~/hooks/useWizardStep';
import getOutputsForOverview from '../../utils/getOutputsForOverview';
import { OutputMap } from '../..';
import { isEmpty } from 'ramda';
import AccordionOutputGroup from './components/AccordionOutputGroup';
import { isString } from 'lodash';
import {
  ENABLED_TITLE,
  FAILED_TITLE,
  id as loadingScreenId,
  OutputType as LoadingScreenOutputType,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/LoadingScreen';
import InfoBlock from '~/components/molecule/InfoBlock';
import useWizard from '~/hooks/useWizard';
import { animated, useSpring } from 'react-spring';
import { ANIMATION_CONFIG } from '~/styles/constants';

export const id = 'EnabledAppsOverview';
export const title = 'Overzicht';

const text = {
  noOverview:
    'Momenteel is er geen overzicht beschikbaar. Ga door met de wizard.',
  description: {
    [ENABLED_TITLE]:
      'De volgende apps zijn succesvol geactiveerd en worden zichtbaar in jouw website widget.',
    [FAILED_TITLE]:
      'Deze apps zijn niet geactiveerd. Na het voltooien van deze wizard kan je de app alsnog activeren vanuit het menu "Apps".',
  },
  enabledAppsCategory: 'Geactiveerde apps',
};

export type OutputType = {
  type: typeof id;
  enabledApps: OutputFieldList<{ label: string }>;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const [, api] = useWizardStep(step.id);
  const wizardApi = useWizard();
  const animation = useSpring(ANIMATION_CONFIG);

  const loadingScreenOutput = outputMap[
    loadingScreenId
  ] as LoadingScreenOutputType;

  useEffect(() => {
    api.lockGoBack();

    if (loadingScreenOutput.enabledAppsOutput.values.length === 0) {
      api.free({
        type: 'EnabledAppsOverview',
        enabledApps: {
          type: 'list',
          category: text.enabledAppsCategory,
          value: [],
        },
      });
      return wizardApi.next();
    }

    api.free({
      type: 'EnabledAppsOverview',
      enabledApps: {
        type: 'list',
        category: text.enabledAppsCategory,
        value: loadingScreenOutput.enabledAppsOutput.values.map(value => ({
          label: value.label,
        })),
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingScreenOutput]);

  // We only need the output of LoadingScreen here
  const result =
    loadingScreenOutput && !isEmpty(loadingScreenOutput)
      ? getOutputsForOverview({ [id]: loadingScreenOutput } as OutputMap, [id])
      : null;

  // We don't want to show the failed apps group of it is empty
  const accordionGroups = result?.accordions.filter(a => {
    if (a.values.length === 0) return a.category === ENABLED_TITLE;
    return true;
  });

  if (!accordionGroups || !accordionGroups.length)
    return (
      <InfoBlock
        headerText={text.noOverview}
        icon={{ name: 'triangle', backgroundColor: { group: 'warning' } }}
      />
    );

  return (
    <animated.section style={animation}>
      <JustificationContainer direction="column" gap="l">
        {accordionGroups.map(({ category, values }) => {
          const _category = isString(category) ? category : '';

          return (
            <AccordionOutputGroup
              values={values}
              header={category}
              icon={category === FAILED_TITLE ? 'exclamation' : 'check'}
              description={text.description[_category]}
              key={_category}
              variant={category === FAILED_TITLE ? 'danger' : 'success'}
            />
          );
        })}
      </JustificationContainer>
    </animated.section>
  );
};

export default {
  id,
  title,
};
