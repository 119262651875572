import React from 'react';
import { Handle, Position } from 'reactflow';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';

import getCardHeading from '../../../../utils/getCardHeading';
import ConditionList from '../ConditionList';
import { ClientFlowActionStart } from '~/graphql/types.client';
import { FlowAction } from '~/graphql/types';
import useRelativeMaps from '../../../../hooks/useRelativeMaps';

export type Props = Omit<
  CardProps<ClientFlowActionStart>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const TriggerCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, ...props }) => {
    const maps = useRelativeMaps({ actionId: props.data.action.id });

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.Start}
          heading={getCardHeading(FlowAction.Start)}
          selected={selected}
          disabled={disabled}
        >
          <ConditionList
            actionType={FlowAction.Start}
            condition={props.data.action.condition}
            maps={maps}
            actionId={props.data.action.id}
          />
        </CardTemplate>
        <Handle
          type="source"
          position={Position.Bottom}
          style={handleStyle}
          isConnectable={false}
        />
      </>
    );
  },
);

export default TriggerCard;
