import useSessionHydration from '../useSessionHydration';
import useCurrentAccount from '../useCurrentAccount';
import useOfficeLookup from '../useOfficeLookup';
import useUserLookup from '../useUserLookup';
import {
  SignatureFields_Signature_Account_Fragment,
  SignatureFields_Signature_Office_Fragment,
  SignatureFields_Signature_User_Fragment,
} from '~/graphql/types';
import { assertNever } from '~/util/assertNever';

type BaseSignature =
  | SignatureFields_Signature_Account_Fragment
  | SignatureFields_Signature_Office_Fragment
  | SignatureFields_Signature_User_Fragment;

export type ExtendedSignature = BaseSignature & {
  label: string;
};

const useSignatures = () => {
  const account = useCurrentAccount();

  const [{ signaturesV2 }] = useSessionHydration();

  const availableSignatures: Array<ExtendedSignature> = [];

  const offices = useOfficeLookup();
  const users = useUserLookup(['User']);

  signaturesV2.forEach(signature => {
    switch (signature.__typename) {
      case 'Signature_Account': {
        availableSignatures.push({
          ...signature,
          label: account.name,
        });
        break;
      }

      case 'Signature_Office': {
        const office = offices ? offices[signature.officeId] : null;
        if (office) {
          availableSignatures.push({
            ...signature,
            label: office.name,
          });
        }
        break;
      }

      case 'Signature_User': {
        const user = users ? users[signature.userId] : null;
        if (user && user.__typename === 'User') {
          availableSignatures.push({
            ...signature,
            label: user.name,
          });
        }
        break;
      }

      default:
        assertNever(signature);
    }
  });

  return availableSignatures;
};

export default useSignatures;
