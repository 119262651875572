import React, { useEffect, useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import styled, { css } from 'styled-components';
import {
  format,
  convertDateToServerDateString,
  convertServerDateStringToDate,
  DATE_FNS_FORMAT,
} from '~/util/date';

import Input, { Props as InputProps } from '~/components/molecule/Input';
import { DayPicker, type SelectSingleEventHandler } from 'react-day-picker';
import useOnClickOutside from '~/hooks/useOnClickOutside';
import { isSameDay } from 'date-fns';
import { nl } from 'date-fns/locale';
import { capitalizeFirstLetter } from '~/util/string';

type Props = {
  /** The label to give to the input */
  label: string;

  /** The date value as ISO string */
  value: string;

  /** Called when the date is changed, gives the date as an ISO string */
  onChange: (val: string | null) => any;

  /** Props to pass to the inputComponent */
  inputComponentProps?: InputProps;
};

const DatePicker: React.FCC<Props> = ({
  dataTestId,
  onChange,
  value = new Date().toISOString(),
  inputComponentProps,
  label,
  ...rest
}) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<Date>(
    convertServerDateStringToDate(value) ?? new Date(),
  );
  const [focussed, setFocussed] = useState(false);

  useOnClickOutside(pickerRef, () => {
    setFocussed(() => false);
  });

  const onDateSelect: SelectSingleEventHandler = date => {
    if (date) {
      setFocussed(() => false);
      setSelected(date);
    }
  };

  useEffect(() => {
    if (onChange && !isSameDay(selected, value)) {
      onChange(convertDateToServerDateString(selected));
    }
  }, [selected, onChange, value]);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Input
        label={{ text: label }}
        placeholder={format(new Date(), DATE_FNS_FORMAT)}
        value={format(selected, DATE_FNS_FORMAT)}
        onClick={() => {
          setFocussed(() => true);
        }}
        onFocus={() => {
          setFocussed(() => true);
        }}
        icon={{
          name: 'calendar',
          color: {
            group: 'primary',
          },
        }}
        {...inputComponentProps}
        width="100%"
      />

      {focussed && (
        // Move the container away from any edge, all usage comes very close to the right.
        <div ref={pickerRef} style={{ transform: 'translateX(-4rem)' }}>
          <DayPicker
            locale={{
              ...nl,
              localize: {
                ...nl.localize,
                // Capitilize month, only ENG capitilized by default
                month: monthIndex => {
                  const monthNames = nl.localize.month;
                  const monthName = monthNames(monthIndex);
                  return capitalizeFirstLetter(monthName);
                },
              },
            }}
            initialFocus={true}
            mode="single"
            defaultMonth={selected}
            selected={selected}
            onSelect={onDateSelect}
          />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('text')};
    position: relative;
    z-index: 1;

    .rdp {
      --rdp-accent-color: ${theme.color('primary')};
      --rdp-background-color: ${theme.color('primary', 'translucent')};
      background-color: ${theme.color('white')};
      margin: 0;
      position: absolute;
      z-index: ${theme.z('top')};

      .rdp-month {
        padding: ${theme.space('m')};
        box-shadow: ${theme.boxShadow('around')};
      }
    }
  `,
);

export default DatePicker;
