import React, { useState, useEffect } from 'react';

import useIntercom from '~/hooks/useIntercom';
import withErrorBoundary from '~/ErrorBoundary';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import styled, { css } from 'styled-components';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useCurrentUser from './hooks/useCurrentUser';
import useMainOffice from './hooks/useMainOffice';

/**
 * Error and Intercom wrapper
 */
const Wrapper: React.FCC<{}> = ({ children }) => {
  const intercom = useIntercom();
  const currentUser = useCurrentUser();
  const mainOffice = useMainOffice(currentUser.id);

  intercom.boot({
    user: {
      id: currentUser.id,
      email: currentUser.email,
      name: currentUser.name,
      phone: currentUser.phone,
      mainOffice: mainOffice?.name || 'Unknown office',
      first_admin: undefined,
      signed_up_at: undefined,
    },
  });

  return <>{children}</>;
};

const LoadingComponent: React.FCC<{}> = () => {
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    let didCancel = false;
    const timer = setTimeout(() => {
      if (!didCancel) {
        setShowReload(true);
      }
    }, 10000);

    return () => {
      didCancel = true;
      clearTimeout(timer);
    };
  }, []);

  return (
    <InPositionContainer>
      <JustificationContainer
        align="center"
        justification="center"
        direction="column"
      >
        <DatHuisLoading />
        {showReload && (
          <Button
            onClick={() => global.window.location.reload()}
            label="Herlaad de pagina"
          />
        )}
      </JustificationContainer>
    </InPositionContainer>
  );
};

const InPositionContainer = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 50px;
    max-width: ${theme.getTokens().grid.inner};
    margin: 0 auto;
  `,
);

export default withErrorBoundary(Wrapper, <LoadingComponent />);
