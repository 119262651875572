import styled, { css } from 'styled-components';

import { animated } from 'react-spring';
import { HTMLAttributes } from 'react';

export type Props = {
  $active?: boolean;
  style?: any;
} & Omit<HTMLAttributes<HTMLTableRowElement>, 'style'>;

const ChartLabelContainer = styled(animated.tr)<Props>`
  transition: box-shadow 0.2s ease-out;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.base};

    & > :first-child {
      border-bottom-left-radius: ${theme.getTokens().border.radius.base};
      border-top-left-radius: ${theme.getTokens().border.radius.base};
    }

    & > :last-child {
      border-bottom-right-radius: ${theme.getTokens().border.radius.base};
      border-top-right-radius: ${theme.getTokens().border.radius.base};
    }
    z-index: ${theme.z('top')};
  `}

  ${({ $active, theme }) => {
    if ($active)
      return css`
        box-shadow: ${theme.boxShadow('base')};
        background-color: ${theme.color('white')};
      `;

    return null;
  }};
` as React.FCC<Props>;

export default ChartLabelContainer;
