import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import convertHtmlToSlateFragment from '~/components/organism/PluginsEditor/utils/signatures/convertHtmlToSlateFragment';
import removeWhiteSpaceInHtml from './util/removeWhiteSpaceInHtml';
import { Attachment } from '~/graphql/types';

const insertHtml = ({
  editor,
  html,
  customElements,
  markAsPendingImage = true,
  attachments = [],
  withExtraSpace = true,
}: {
  editor: DHEditor;
  html: string;
  customElements: Array<ELEMENTS>;
  markAsPendingImage?: boolean;
  withExtraSpace?: boolean;
  attachments?: Array<Attachment>;
}) => {
  const whiteSpaceRemoved = removeWhiteSpaceInHtml(html);

  const fragment = convertHtmlToSlateFragment({
    html: whiteSpaceRemoved,
    attachments,
    markAsPendingImage,
    customElements,
  });

  ReactEditor.focus(editor);

  if (withExtraSpace) {
    /**
     *  Add an empty line before and after the html to allow focusing on the editor
     *  also makes it easy to delete a table and have a clean empty line
     * */
    Transforms.insertFragment(editor, [
      /** Do not use the same object ref for same elements because under the hood there's a
       * WeakMap that relies on a unique object reference. If you encounter "Two chilren with
       * the same key" error of React this is probably why it is happening. */
      {
        type: ELEMENTS.DIV,
        children: [{ text: '' }],
      },
      ...fragment,
      {
        type: ELEMENTS.DIV,
        children: [{ text: '' }],
      },
    ]);
  } else {
    Transforms.insertFragment(editor, fragment);
  }

  Transforms.move(editor, {
    distance: 1,
    unit: 'offset',
  });
  ReactEditor.focus(editor);
};

export default insertHtml;
