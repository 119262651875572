import { ClientFlowAction } from '~/graphql/types.client';

/**
 * Removes connection between portal actions
 * @param {Array<ClientFlowAction>} actions - Actions
 * @param {string} sourceActionId - source action id
 * @param {string} targetActionId - target action id
 * keywords: remove portals, remove connection
 */
const onDeletePortalConnection = ({
  actions,
  targetActionId,
  sourceActionId,
}: {
  actions: Array<ClientFlowAction>;
  targetActionId: string;
  sourceActionId: string;
}): Array<ClientFlowAction> => {
  const mutableActions = [...actions];
  const targetActionIndex = mutableActions.findIndex(
    action => action.id === targetActionId,
  );
  const targetAction = mutableActions[targetActionIndex];

  const newTargetAction =
    'parentIds' in targetAction
      ? {
          ...targetAction,
          parentIds: targetAction?.parentIds.filter(
            id => id !== sourceActionId,
          ),
        }
      : targetAction;

  mutableActions[targetActionIndex] = newTargetAction;

  const sourceActionIndex = mutableActions.findIndex(
    action => action.id === sourceActionId,
  );
  const sourceAction = mutableActions[sourceActionIndex];

  let newSourceAction: ClientFlowAction = { ...sourceAction };
  if (sourceAction.__typename === 'FlowV2_Action_IfElse') {
    if (sourceAction.trueChildId === targetActionId) {
      newSourceAction = {
        ...sourceAction,
        trueChildId: null,
      };
    }

    if (sourceAction.falseChildId === targetActionId) {
      newSourceAction = {
        ...sourceAction,
        falseChildId: null,
      };
    }
  }

  mutableActions[sourceActionIndex] = newSourceAction;

  return mutableActions;
};

export default onDeletePortalConnection;
