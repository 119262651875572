import React from 'react';
import styled, { css } from 'styled-components';
import Label from '~/components/template/EventTimelineV2/components/Block/components/Data/components/Label';
import { Body } from '~/components/atom/Typography';
import { TextBlock } from '~/graphql/types';
import { BLUR_STYLES } from '../styles';

export type Props = Omit<TextBlock, '__typename' | 'value'> & {
  dataTestId?: string;
  value: React.ReactNode;
};

const Text: React.FC<Props> = ({ label, value, css, blurred }) => (
  <Container css={css} $blurred={blurred || false}>
    {label && <Label>{label}</Label>}
    <Body size="s">{value}</Body>
  </Container>
);

const Container = styled.div<{ $blurred: boolean }>(
  ({ theme, $blurred }) => css`
    margin: ${theme.space('xxxs')} 0;

    ${$blurred && BLUR_STYLES};
  `,
);

export default Text;
