import React from 'react';
import { Props } from '..';

const ChevronStart: React.FCC<Props> = ({
  color = 'currentColor',
  ...props
}) => (
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 18L13 12L7 6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 6L17 18"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronStart;
