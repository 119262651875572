import React, { useState } from 'react';
import BrandSettingsModal, {
  Props as BrandSettingsModalProps,
} from '~/components/template/BrandSettingsModal';
import CallToActionBlock from '../CallToActionBlock';
import TEST_ID from './index.testid';

const text = {
  header: 'Pas de instellingen van je huisstijl toe in deze app',
  buttonLabel: 'Toepassen',
};
export type Props = {
  subjects: BrandSettingsModalProps['subjects'];
  onConfirm: BrandSettingsModalProps['onConfirm'];
};

const BrandSettingsCTA: React.FCC<Props> = ({ subjects, onConfirm }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <CallToActionBlock
        dataTestId={TEST_ID.BRAND_SETTINGS_CTA}
        align="center"
        header={text.header}
        icon={{ name: 'star', background: 'accent' }}
        button={{
          dataTestId: TEST_ID.BRAND_SETTINGS_CTA_BUTTON,
          appearance: 'accent',
          label: text.buttonLabel,
          icon: 'star',
          onClick: () => setShowModal(true),
        }}
      />
      {showModal && (
        <BrandSettingsModal
          subjects={subjects}
          onConfirm={values => {
            onConfirm(values);
            setShowModal(false);
          }}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default BrandSettingsCTA;
