/**
 * General number validations
 */

import { isNumber as lodashIsNumber } from 'lodash';

export const isNumber = (value: any): value is number =>
  lodashIsNumber(value) && !isNaN(value);

export const isInteger = (value: any): value is number =>
  isNumber(value) && Number.isInteger(value);
export default {
  isNumber,
  isInteger,
};
