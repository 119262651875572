import React from 'react';
import styled from 'styled-components';

type Props = {
  errorMessage: string | null;
};
const ErrorMessage: React.FCC<Props> = ({ errorMessage, ...rest }) => {
  if (errorMessage == null) return null;

  return (
    <Spacer {...rest}>
      {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
    </Spacer>
  );
};

const Spacer = styled.div<{}>`
  flex-grow: 1;

  display: flex;
  align-items: flex-end;
`;

const ErrorMsg = styled.div<{}>`
  ${({ theme }) => `
    color: ${theme.color('danger')};
  `};
`;

export default ErrorMessage;
