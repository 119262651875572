import type { ContactHasVBOWaardecheckAppraisalRequestEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

const asHasVBOWaardecheckAppraisalRequestEventFilterInput = (
  filter: ContactHasVBOWaardecheckAppraisalRequestEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => ({
  App: {
    VBOWaardecheck: {
      AppraisalRequest: {
        has: asHasFilterInput(filter.has),
      },
    },
  },
});

export default asHasVBOWaardecheckAppraisalRequestEventFilterInput;
