import React, { useRef, useState, type CSSProperties } from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import { formState } from '~/components/page/Forms/components/Builder/state';
import type { FormBuilder_ScreenNode } from '~/graphql/types';
import formatNode from './utils/formatNode';
import formatLayout from './utils/formatLayout';
import formatTheme from './utils/formatTheme';
import JustificationContainer from '~/components/atom/JustificationContainer';
import formatLocale from './utils/formatLocale';

type Props = {
  node: FormBuilder_ScreenNode;
  width?: string;
  height?: string;
  style?: CSSProperties;
};

const ScreenPreview: React.FCC<Props> = ({
  node,
  width = '800px',
  height = '800px',
  style,
}) => {
  const [loaded, setLoaded] = useState(false);
  const frameRef = useRef<HTMLIFrameElement>(null);
  const form = useRecoilValue(formState);

  if (!form) return null;

  if (frameRef.current && loaded === true) {
    frameRef.current.contentWindow?.postMessage(
      JSON.stringify({
        type: 'layout',
        payload: formatLayout(form.style.layout),
      }),
      '*',
    );

    frameRef.current.contentWindow?.postMessage(
      JSON.stringify({
        type: 'locale',
        payload: formatLocale(form.style.availableLocale),
      }),
      '*',
    );

    frameRef.current.contentWindow?.postMessage(
      JSON.stringify({
        type: 'nextNode',
        payload: formatNode(node, form.optionLists),
      }),
      '*',
    );

    frameRef.current.contentWindow?.postMessage(
      JSON.stringify({
        type: 'theme',
        payload: formatTheme(form.style.theme),
      }),
      '*',
    );
  }

  return (
    <Container
      width={width}
      height={height}
      justification="center"
      align="center"
      style={style ?? {}}
    >
      <PreviewFrame
        // PROD Cloudfront URL of AppShell
        src={`https://d20r886yjkdo9k.cloudfront.net/preview/${form.id}`}
        width="100%"
        height="100%"
        ref={frameRef}
        onLoad={() => setLoaded(true)}
      />
    </Container>
  );
};

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    background-color: ${theme.color('tertiary', 'light')};
    position: relative;
    user-select: none;
    flex-shrink: 0;

    * > * {
      opacity: 0.4;
    }
  `,
);

const PreviewFrame = styled.iframe(
  ({}) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: none;
    outline: none;
  `,
);

export default ScreenPreview;
