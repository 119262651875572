import { AppType } from '~/graphql/types';
import type { AppConfig } from '../types';
import { CONTAQT_URL } from './hooks/useContaqtSignUpLink';

const description = `## Omschrijving
Contaqt maakt al jaren kwalitatief hoogstaande nieuwsbrieven voor financieel adviseurs. Dagelijks maken ruim 400 kantoren gebruik van de nieuwsbrieven van Contaqt. Sinds kort is Contaqt ook beschikbaar voor makelaars in vastgoed. 

Zodra je van Contaqt gebruik maakt wordt elke maand een goed gevulde nieuwsbrief naar jouw relaties verstuurd. Uiteraard in jouw huisstijl en uit jouw naam.

- Maandelijkse nieuwsbrief
- Zelf extra nieuwsbrieven maken
- Website plugin met nieuwsartikelen
- Zichtbaarheid via social media posts

In combinatie met DatHuis worden automatisch extra triggers toegevoegd aan de nieuwsartikelen. Hierdoor wordt de DatHuis widget vaker geopend en dat levert jou dus meer leads op en uiteindelijk meer omzet! Kortom, maak van elk nieuwsartikel een leadgenerator 💪🏻

Ben je nog geen klant van Contaqt? Krijg de eerste maand voor €1 in plaats van €59. Ook betaal je geen kosten voor de onboarding van €199! [Maak nu van deze aanbieding gebruik](${CONTAQT_URL}) of lees [meer over Contaqt](https://www.contaqt.nl/).
`;

const termsOfUse = `Door het activeren van deze app geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van Contaqt (uniekwerk B.V). Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Op de dienstverlening van Contaqt zijn de voorwaarden van uniekwerk B.V van toepassing. Meer informatie vind je op [https://www.contaqt.nl](https://www.contaqt.nl).`;

export const CONTAQT_HELP_LINK =
  'https://help.dathuis.nl/nl/articles/8676267-over-de-contaqt-nieuwsbrieven-app';

export const contaqt: AppConfig = {
  __typename: 'AppStatus_Contaqt',
  appType: AppType.Contaqt,
  type: 'with_config',
  slug: 'contaqt',
  name: 'Contaqt - Nieuwsbrief',
  tags: ['Nieuwsbrieven', 'Klantloyaliteit', 'Leadgeneratie'],
  provider: 'Contaqt',
  logo: {
    src: 'https://dathuis-stash.imgix.net/contaqt.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/contaqt.svg',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: CONTAQT_HELP_LINK,
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'custom',
};
