import type { ConditionIssue } from '~/components/page/Automation/v2/state/flowIssues';
import generateConditionArgumentId from '../../../../UpdateAction/utils/generateConditionArgumentId';

/**
 * Generate the condition issue
 */
const generateConditionIssue = ({
  actionId,
  conditionSubExpressionIdx,
  conditionGroupIdx,
  conditionIdx,
  argumentIdx,
  ...rest
}: Omit<ConditionIssue, 'type' | 'id'> & {
  actionId: string;
}): ConditionIssue => ({
  type: 'ConditionIssue',
  id: generateConditionArgumentId({
    actionId,
    conditionSubExpressionIdx,
    conditionGroupIdx,
    conditionIdx,
    argumentIdx,
  }),
  actionId,
  conditionSubExpressionIdx,
  conditionGroupIdx,
  conditionIdx,
  argumentIdx,
  ...rest,
});

export default generateConditionIssue;
