import { isNil } from 'ramda';
import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import { Step } from '../..';

export type Props = {
  steps: Array<Step>;
  currentStep: number;
  currentSubStep?: number | null;
};

const Mobile: React.FCC<Props> = ({
  dataTestId,
  currentStep,
  currentSubStep,
  steps,
  ...rest
}) => {
  const style = useSpring({
    from: { opacity: 0, scale: 1, transform: 'translateY(-20px)' },
    to: { opacity: 1, scale: 1, transform: 'translateY(0px)' },
    config: {
      tension: 220,
      friction: 10,
    },
    reset: true,
  });

  const activeStep = currentStep + 1;
  const totalSteps = steps.length;

  const step = steps[currentStep];
  const activeStepToShow = activeStep < totalSteps ? activeStep : totalSteps;
  const withSubSteps = step && !isNil(step.subSteps) && !isNil(currentSubStep);
  const subStepToShow = withSubSteps
    ? `${activeStepToShow}.${currentSubStep + 1}`
    : undefined;
  const stepToShow = subStepToShow ? subStepToShow : activeStepToShow;

  return (
    <Container data-testid={dataTestId} {...rest}>
      Stap
      <CurrentStepContainer>
        <animated.span style={style}>{stepToShow}</animated.span>
      </CurrentStepContainer>
      van&nbsp;{totalSteps}
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.color('primary', 'dark')};
    font-weight: ${theme.fw('semiBold')};
    font-size: ${theme.fs('m')};

    margin: ${theme.space('xl')} 0;
  `}
`;

const CurrentStepContainer = styled(animated.span)<{}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.color('white')};
    background-color: ${theme.color('primary', 'dark')};
    border-radius: ${theme.getTokens().border.radius.full};
    margin: 0 ${theme.space('xxs')};

    width: ${theme.space('xl')};
    height: ${theme.space('xl')};
  `}
`;

export default Mobile;
