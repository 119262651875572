import React from 'react';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import FlowSettings from './components/FlowSettings';
import FontFamilyBlock from './components/FontFamilyBlock';
import TEST_ID from './index.testid';
import type { DeepPartial } from 'utility-types';
import type { BrandSettings } from '../../state';

const text = {
  emailSettings: 'Email instellingen',
};

export type Props = {
  readOnly?: boolean;
  fontFamily: string | null;
  hasFlows: boolean;
  onUpdate: (update: DeepPartial<BrandSettings>) => void;
};

const EmailSettingsBlock: React.FCC<Props> = ({
  readOnly = false,
  fontFamily,
  hasFlows,
  onUpdate,
  ...rest
}) => (
  <AnimatedBlock
    title={text.emailSettings}
    manualLayout
    dataTestId={TEST_ID.CONTAINER}
    {...rest}
  >
    <FontFamilyBlock
      value={fontFamily}
      onUpdate={onUpdate}
      readOnly={readOnly}
    />

    {hasFlows && <FlowSettings onUpdate={onUpdate} readOnly={readOnly} />}
  </AnimatedBlock>
);

export default EmailSettingsBlock;
