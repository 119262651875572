import React, {
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  type MutableRefObject,
} from 'react';
import { OnChangeFunction, Option } from '../..';
import DropdownList from '../DropdownList';
import DropdownPositioner from './components/DropdownPositioner';
import useOutsideClick from '~/hooks/useClickOutside';
import isCursorInBoundary from '~/util/isCursorInBoundary';
import { isNil } from 'ramda';
import getClosestScrollableParent from '~/util/getClosestScrollableParent';
import useKeyBindings from './hooks/useKeyBindings';

export type Props = {
  options: Array<Option>;
  selectedOptionIdx?: number | null;
  dropdownListOpen: boolean;

  /** Select the first option by default */
  withDefaultFirstOption?: boolean;

  /** Loading state shown in the dropdown list */
  loading?: boolean;
  dropdownWidth?: number;

  /** Goes to the container */
  className?: string;

  /**
   * The component that opened the dropdown list. A button or a dropdown etc.
   */
  openerRef: MutableRefObject<HTMLElement | null>;

  /** Called when option is selected with keybindings or option click */
  onChange: OnChangeFunction;

  /** Called on escape key press */
  onClose?: () => void;

  /** Called when click is outside the list */
  onClickOutside?: (e?: MouseEvent) => void;
};

const DropdownListContainer: React.FCC<Omit<Props, 'dropdownListOpen'>> = ({
  dataTestId,
  options,
  withDefaultFirstOption,
  loading,
  dropdownWidth,
  openerRef,
  onChange,
  onClose,
  onClickOutside,
  ...rest
}) => {
  const [openerRect, setOpenerRect] = useState<DOMRect | null>(null);

  const selected =
    rest.selectedOptionIdx && rest.selectedOptionIdx >= 0
      ? rest.selectedOptionIdx
      : withDefaultFirstOption
        ? 0
        : null;

  const [selectedOptionIdx, setSelectedOptionIdx] = useState<number | null>(
    selected,
  );

  const listRef = useRef<HTMLUListElement>(null);

  const [listSize, setListSize] = useState({
    width: 0,
    height: 0,
  });

  const rect = openerRef.current?.getBoundingClientRect();
  const listRect = listRef.current?.getBoundingClientRect();
  const scrollableParent = getClosestScrollableParent(openerRef.current);

  const rectMemo = useMemo(
    () => rect,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rect?.width, rect?.height, rect?.top, rect?.bottom],
  );
  const listRectMemo = useMemo(
    () => listRect,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listRect?.width, listRect?.height],
  );

  useLayoutEffect(() => {
    const handleResize = () => {
      setOpenerRect(rect || null);

      if (listRect)
        setListSize({ width: listRect.width, height: listRect.height });
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);

    if (scrollableParent)
      scrollableParent.addEventListener('scroll', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);

      if (scrollableParent)
        scrollableParent.removeEventListener('scroll', handleResize);
    };
    // Pass memoized rects otherwise they cause infinite rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openerRef, scrollableParent, rectMemo, listRectMemo]);

  useOutsideClick(listRef, e => {
    if (isNil(e) || isNil(openerRect) || isNil(onClickOutside)) return;

    const isClickOnOpener = isCursorInBoundary({
      event: e,
      boundary: openerRect,
    });
    if (isClickOnOpener) return;
    onClickOutside(e);
  });

  useKeyBindings({
    onClose,
    onSelect: optionIdx => setSelectedOptionIdx(optionIdx),
    selectedOptionIdx,
    options,
    onChange,
  });

  return (
    <>
      <DropdownPositioner
        openerRect={openerRect}
        listSize={listSize}
        dataTestId={dataTestId}
      >
        <DropdownList
          dropdownWidth={dropdownWidth || 0}
          loading={loading}
          options={options}
          onChange={onChange}
          actuallySelected={rest.selectedOptionIdx}
          selectedOptionIdx={selectedOptionIdx}
          onClickOutside={onClickOutside}
          listRef={listRef}
        />
      </DropdownPositioner>
    </>
  );
};

const DropdownListFacade: React.FCC<Props> = props => {
  if (!props.dropdownListOpen) return null;

  return <DropdownListContainer {...props} />;
};

export default DropdownListFacade;
