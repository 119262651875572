import React, { useEffect, useState } from 'react';
import { head } from 'ramda';
import useAccounts from '~/hooks/useAccounts';
import {
  getLocalStorageItem,
  SELECTED_ACCOUNT_STORAGE_KEY,
} from '~/util/localStorageKeys';
import { Label } from '~/components/atom/Typography';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import styled, { css, keyframes, useTheme } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import getInitialsFromAccountName from './utils/getInitialsFromAccountName';
import { animated, useTransition } from 'react-spring';
import useBrandSettings from '~/hooks/useBrandSettings';
import Icon from '~/components/atom/Icon';
import getContrastingColor from '~/util/getContrastingColor';
import useMeasure from '~/hooks/useMeasure';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';

export type Props = {
  dataTestId?: string;
  expanded?: boolean;
};

export const AccountSwitcher: React.FC<Props> = ({ expanded = false }) => {
  const theme = useTheme();
  const { bounds, ref } = useMeasure();

  const [showOptions, setShowOptions] = useState(false);
  const selectedAccountIdFromStorage = getLocalStorageItem(
    SELECTED_ACCOUNT_STORAGE_KEY,
  );
  const currentAccount = useCurrentAccount();
  const brandSettings = useBrandSettings();

  const selectedAccountId = selectedAccountIdFromStorage ?? currentAccount.id;
  const accounts = useAccounts();

  const transitions = useTransition(expanded, {
    from: { transform: `translateX(-40px)`, opacity: 0 },
    enter: { transform: `translateX(0px)`, opacity: 1 },
    leave: { transform: `translateX(-40px)`, opacity: 0 },
  });

  useEffect(() => {
    // When expanded gets updated to false, trigger showOptions to be false aka close the options.
    if (expanded === false) {
      setShowOptions(false);
    }
  }, [expanded]);

  if (accounts.length === 1) return null;

  const selectedAccount =
    accounts.find(account => account.id === selectedAccountId) ??
    head(accounts);

  if (!selectedAccount) return null;

  const onSwitchAccount = (chosenAccountId: string) => {
    if (chosenAccountId === selectedAccount?.id) return;

    global.localStorage.setItem(SELECTED_ACCOUNT_STORAGE_KEY, chosenAccountId);
    // Force reload
    return (global.window.location.href = '/-/');
  };

  return (
    <TriggerComponent
      $expanded={expanded}
      onClick={() => {
        setShowOptions(prev => !prev);
      }}
    >
      <JustificationContainer
        direction="column"
        width="100%"
        gap="m"
        style={{
          minHeight: showOptions ? `${bounds.height}px` : '0px',
          transition: 'all 0.2s ease-out',
        }}
        ref={ref}
      >
        <JustificationContainer
          width="100%"
          gap="m"
          align="center"
          height="100%"
          style={{ overflow: 'hidden' }}
        >
          <AccountIconLike
            align="center"
            justification="center"
            $brandPrimary={
              brandSettings?.colors?.primary.background ?? theme.color('white')
            }
          >
            {getInitialsFromAccountName(selectedAccount.name)}
          </AccountIconLike>
          {transitions(
            (style, item) =>
              item && (
                <animated.div style={{ ...style, width: '100%' }}>
                  <JustificationContainer
                    width="100%"
                    justification="space-between"
                    align="center"
                  >
                    <AutoScrollLabel
                      $nameTooLong={selectedAccount.name.length > 20}
                      margin={[null]}
                      color={{ group: 'primary', variant: 'text' }}
                      whiteSpace="nowrap"
                      fontWeight="extraLight"
                      size="base"
                    >
                      {selectedAccount.name}
                    </AutoScrollLabel>
                    <Icon name="chevron" />
                  </JustificationContainer>
                </animated.div>
              ),
          )}
        </JustificationContainer>
        {showOptions && (
          <OptionList>
            {accounts
              .filter(({ id }) => id !== selectedAccountId)
              .map(({ name, id, AccountSettings }) => (
                <OptionItem
                  key={id}
                  onClick={event => {
                    event.stopPropagation();
                    onSwitchAccount(id);
                  }}
                >
                  <AccountIconLike
                    align="center"
                    justification="center"
                    $brandPrimary={
                      AccountSettings?.colors?.primary.background ??
                      theme.color('white')
                    }
                  >
                    {getInitialsFromAccountName(name)}
                  </AccountIconLike>
                  <AutoScrollLabel
                    $nameTooLong={name.length > 20}
                    margin={[null]}
                    color={{ group: 'primary', variant: 'text' }}
                    whiteSpace="nowrap"
                    fontWeight="extraLight"
                    size="base"
                  >
                    {name}
                  </AutoScrollLabel>
                </OptionItem>
              ))}
          </OptionList>
        )}
      </JustificationContainer>
    </TriggerComponent>
  );
};

const TriggerComponent = styled.button<{ $expanded: boolean }>(
  ({ $expanded, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${$expanded ? '100%' : theme.space('xxl')};
    height: auto;

    background: transparent;
    border: none;
    outline: none;
    border-radius: ${theme.getTokens().border.radius.base};
    cursor: pointer;

    padding: ${theme.space('s')} ${theme.spaceBetween('xxs', 's')};

    color: ${theme.color('primary', 'text')};
    font-weight: ${theme.fontWeight('extraLight')};
    text-decoration: none;

    transition: all 0.2s ease-out;

    &:hover,
    &[data-active] {
      color: ${theme.color('primary', 'text')};
      background: ${theme.color('primary', 'light')};
    }
  `,
);

const AccountIconLike = styled(JustificationContainer)<{
  $brandPrimary: string;
}>(
  ({ theme, $brandPrimary }) => css`
    width: ${theme.space('xl')};
    height: ${theme.space('xl')};
    font-size: ${theme.fontSize('base')};
    flex-shrink: 0;
    border-radius: ${theme.getTokens().border.radius.full};
    background-color: ${$brandPrimary};
    color: ${getContrastingColor($brandPrimary, theme)};

    border: 1px solid;
  `,
);

const OptionList = styled.ul(
  ({ theme }) => css`
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    display: flex;
    gap: ${theme.space('m')};
    flex-direction: column;
    width: 100%;
    max-height: 30vh;
    overflow-y: scroll;

    ${scrollBarStyles}
  `,
);

const OptionItem = styled.li(
  ({ theme }) => css`
    width: 100%;
    height: 100%;

    display: flex;
    gap: ${theme.space('m')};
    align-items: center;
  `,
);

const marquee = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const AutoScrollLabel = styled(Label)<{ $nameTooLong: boolean }>(
  ({ $nameTooLong, theme }) => css`
    overflow: hidden;

    span {
      width: 100%;
      display: inline-block;
    }

    &:hover span {
      /* Scroll to show whole name */
      animation: ${$nameTooLong ? marquee : 'unset'} 4s linear infinite;
      color: ${theme.color('secondary')};
    }
  `,
);

export default AccountSwitcher;
