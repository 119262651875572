import type { NavLink } from '~/components/template/MasterDetail/components/Master';

export type Args = {
  accountId: string;
  preventDefaultOpening?: boolean;
};
export const widgetScript = ({
  accountId,
  preventDefaultOpening = false,
}: Args): string => `<script>
(function(d,a,t,h,u,i,s){d["DatHuisObject"]=u;d[u]=d[u]||function(){(d[u].q=d[u].q||[]).push(arguments)};d[u].l=1*new Date;se=a.createElement(t);fs=a.getElementsByTagName(t)[0];se["async"]=1;se.src=h;fs.parentNode.insertBefore(se,fs)})(window,document,"script","https://btstrp.dathuis.nl/assets/widget.js","dhuw");
dhuw('init', { accountId: '${accountId}'${
  preventDefaultOpening ? ', preventDefaultOpening: true' : ''
} });
</script>`;

export type WidgetPages = 'settings' | 'design' | 'apps' | 'linkBuilder';

export const widgetRoutes: Record<WidgetPages, string> = {
  settings: 'widget',
  design: 'widget/design',
  apps: 'widget/apps',
  linkBuilder: 'widget/link-builder',
};

export const widgetHelpLink =
  'http://help.dathuis.nl/nl/articles/6133047-widget-installatie-en-integratie';

const text = {
  settings: 'Algemeen',
  design: 'Ontwerp',
  apps: 'Apps',
};

export const navLinks: Array<NavLink> = [
  {
    type: 'link',
    to: widgetRoutes.settings,
    icon: 'gear',
    name: text.settings,
  },
  {
    type: 'link',
    to: widgetRoutes.design,
    icon: 'layout',
    name: text.design,
  },
  {
    type: 'link',
    to: widgetRoutes.apps,
    icon: 'grid',
    name: text.apps,
  },
];
