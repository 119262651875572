import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

export type Props = {
  children: Array<ReactElement>;
  onChange?: (index: number) => void;
};

const Tabs: React.FCC<Props> = ({
  dataTestId,
  children,
  onChange,
  ...rest
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [markerConfig, setMarkerConfig] = useState({ left: 0, width: 0 });
  const activeStepHeaderRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (activeStepHeaderRef?.current) {
      setMarkerConfig({
        left: activeStepHeaderRef.current.offsetLeft,
        width: activeStepHeaderRef.current.clientWidth,
      });
    }
  }, [activeTab, activeStepHeaderRef]);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <HeaderContainer role="tablist">
        {React.Children.map(children, (child, index) => {
          if (child && typeof child === 'object' && 'props' in child) {
            const { label }: { label: string } = child.props;
            const active = index === activeTab;

            if (label) {
              return (
                <Header
                  $active={active}
                  ref={active ? activeStepHeaderRef : null}
                  key={label}
                  onClick={() => {
                    setActiveTab(index);
                    if (onChange) onChange(index);
                  }}
                  role="tab"
                  tabIndex={index}
                  id={`tab_${index}_header`}
                  aria-selected={active}
                  aria-controls={`tab_${index}_body`}
                >
                  {label}
                </Header>
              );
            }
          }

          return null;
        })}
        <ActiveMarker
          style={{
            transform: `translate3d(${markerConfig.left}px,0, 0)`,
            width: markerConfig.width + 'px',
          }}
        />
      </HeaderContainer>
      <>
        {React.Children.map(children, (child, index) => {
          if (index === activeTab)
            return (
              <Tab
                role="tabpanel"
                tabIndex={index}
                id={`tab_${index}_body`}
                aria-labelledby={`tab_${index}_header`}
              >
                {/* @ts-ignore */}
                {child.props.children}
              </Tab>
            );

          return null;
        })}
      </>
    </Container>
  );
};

const Container = styled.div<{}>`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div<{}>(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    border-bottom: 1px solid ${theme.color('tertiary')};
    margin-bottom: ${theme.space('base')};
  `,
);

const Header = styled.button<{ $active: boolean }>(
  ({ theme, $active }) => css`
    margin-left: ${theme.space('s')};
    padding: ${theme.space('s')};
    background-color: transparent;
    font-weight: ${theme.fontWeight('medium')};
    border: none;
    display: flex;
    transition: all 0.3s ease;

    cursor: pointer;

    ${$active &&
    css`
      color: ${theme.color('secondary')};
    `}

    &:focus {
      color: ${theme.color('secondary')};
    }

    &:first-of-type {
      margin-left: 0;
    }
  `,
);

const ActiveMarker = styled.div<{}>(
  ({ theme }) => css`
    height: 5px;
    width: 0px;
    background-color: ${theme.color('secondary')};
    position: absolute;
    bottom: -1px;
    display: block;
    transition: all 0.3s ease;
  `,
);

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const Tab = styled.div<{}>`
  opacity: 0;
  animation: ${fadeIn} 725ms ease-out forwards;
`;

export default Tabs;
