import cleanedFilename from '~/util/cleanedFilename';

export default {
  ASSIGNED_OFFICE_DROPDOWN: `${cleanedFilename(
    __filename,
  )}-assigned-office-dropdown`,
  ASSIGNED_USER_DROPDOWN: `${cleanedFilename(
    __filename,
  )}-assigned-user-dropdown`,
};
