import { Form as ReactFinalForm } from 'react-final-form';

/**
 * We do not accept undefined as a possible value for the forms.
 * If there is no value for the field then it is null, but it should always be explicit.
 *
 * In the react-final-form ecosystem we do not want any field being undefined or empty string.
 */

export default ReactFinalForm;
