import React from 'react';

import {
  TableHeaderDefinitionCellType,
  TableCell,
  SortTypeFunction,
  DateRenderFunction,
} from '../../types.flow';
import { BaseHeaderCellOptions, SortOptions } from './base';

import { safeFormattedDate } from '~/util/date';
import { getRowValueByColumnID, compareBasic, asBaseOptions } from './base';

const defaultDateRenderFunction = (value: Date, _tableCell: TableCell<Date>) =>
  value ? <span>{safeFormattedDate(value)}</span> : '';
const defaultDateSortFunction: SortTypeFunction = (rowA, rowB, columnId) => {
  let a = getRowValueByColumnID(rowA, columnId);
  let b = getRowValueByColumnID(rowB, columnId);

  if (a == null) return -1;
  if (b == null) return 1;

  a = a.getTime();
  b = b.getTime();

  return compareBasic(a, b);
};
type DateHeaderCellOptions = BaseHeaderCellOptions & {
  renderFunction?: DateRenderFunction;
  sorting?: SortOptions;
};
const asDateTableHeaderCell = ({
  renderFunction,
  sorting,
  ...rest
}: DateHeaderCellOptions): TableHeaderDefinitionCellType<Date> => {
  const renderFn =
    renderFunction == null ? defaultDateRenderFunction : renderFunction;

  const sortOptions = sorting;
  if (sortOptions != null && sortOptions.sortFunction == null) {
    sortOptions.sortFunction = defaultDateSortFunction;
  }

  return {
    ...asBaseOptions({
      ...rest,
      sorting: sortOptions,
    }),
    Cell: (tableCell: TableCell<Date>) =>
      renderFn(tableCell.cell.value, tableCell),
  };
};

export default asDateTableHeaderCell;
