import React from 'react';
import ConversionPage from '~/components/template/ConversionPage';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import { RouteComponentProps } from '@gatsbyjs/reach-router';

export type Props = RouteComponentProps & {
  slug: string;
  onComplete?: () => void;
  onClose?: () => void;
};

const StrapiModal: React.FC<Props> = ({ slug, onClose }) => (
  <Overlay
    onClose={() => {
      if (onClose) {
        onClose();
      }
    }}
  >
    <Modal onClose={onClose}>
      <ConversionPage slug={slug} />
    </Modal>
  </Overlay>
);

export default StrapiModal;
