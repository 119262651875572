import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import TopSectionContainer from '../components/TopSectionContainer';
import MidSectionContainer from '../components/MidSectionContainer';
import BottomSectionContainer from '../components/BottomSectionContainer';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { submitCancellationForm } from '~/util/hubspotForms';
import TEST_ID from './index.testid';
import Catalog from '~/Catalog';
import ErrorMessage from '../components/ErrorMessage';
import useCurrentUser from '~/hooks/useCurrentUser';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { useCancelSubscriptionMutation } from '~/graphql/types';
import { Body } from '~/components/atom/Typography';
import RadioButton from '~/components/molecule/RadioButton';
import Textarea from '~/components/molecule/Textarea';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

const text = {
  header: 'Opzeggen van je abonnement',
  nextStepButtonLabel: 'Opzeggen',
  cancelError: Catalog.genericUnknownErrorMessageShort,
  cancelRow1Text: 'We vinden het erg jammer dat je weggaat!',
  cancelRow2Text:
    'Om ons product continu te blijven verbeteren zijn we benieuwd waarom je het abonnement stop zet.',
};
type ReasonOption = {
  label: string;
  payload: string;
};
const OTHER_REASON_OPTION: ReasonOption = {
  label: 'Overig',
  payload: 'Overig',
};
const REASON_OPTIONS: Array<ReasonOption> = [
  {
    label: 'Ik maak er te weinig gebruik van',
    payload: 'Ik maak er te weinig gebruik van',
  },
  {
    label: 'Ik vind het te duur',
    payload: 'Ik vind het te duur',
  },
  {
    label: 'Ik heb een beter alternatief',
    payload: 'Ik heb een beter alternatief',
  },
  OTHER_REASON_OPTION,
];
type Props = {
  onSubscriptionCancelled: () => void;
  onGoBack: () => void;
};
const CancelSubscriptionStep: React.FCC<Props> = ({
  onSubscriptionCancelled,
  onGoBack,
}) => {
  const addToast = useAddToast();
  const account = useCurrentAccount();
  const me = useCurrentUser();
  const [reason, setReason] = useState(REASON_OPTIONS[0].payload);
  const [reasonExplanation, setReasonExplanation] = useState<string | null>(
    null,
  );
  const [cancelSubscription, { loading, error }] =
    useCancelSubscriptionMutation();

  return (
    <Container direction="column" data-testid={TEST_ID.CONTAINER}>
      <TopSectionContainer headerText={text.header} withBorder />
      <MidSectionContainer gap="base">
        <Body>{text.cancelRow1Text}</Body>
        <Body>{text.cancelRow2Text}</Body>
        {REASON_OPTIONS.map((option, idx) => (
          <RadioButton
            key={`reason-${idx}`}
            label={option.label}
            checked={option.payload === reason}
            onChange={() => {
              setReason(option.payload);
            }}
            name="reason"
          />
        ))}
        {reason === OTHER_REASON_OPTION.payload && (
          <StyledTextarea
            name="reason-explanation"
            value={reasonExplanation}
            onChange={setReasonExplanation}
          />
        )}
        <ErrorMessage errorMessage={error == null ? null : text.cancelError} />
      </MidSectionContainer>
      <BottomSectionContainer
        accentButton
        loading={loading}
        nextStepButton={text.nextStepButtonLabel}
        onContinue={() =>
          cancelSubscription({
            variables: { accountId: account.id },
          }).then(({ data, errors }) => {
            if (errors) {
              return addToast([
                formatToastMessage(
                  Catalog.genericUnknownErrorMessage,
                  'danger',
                ),
              ]);
            }

            if (data) {
              submitCancellationForm(me.email, reason, reasonExplanation || '');
              onSubscriptionCancelled();
              addToast([
                formatToastMessage(Catalog.genericSuccessMessage, 'success'),
              ]);
            }
          })
        }
        onGoBack={onGoBack}
      />
    </Container>
  );
};

const StyledTextarea = styled(Textarea)<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('s')};
  `};
`;

const Container = styled(JustificationContainer)<{}>`
  width: 100%;
`;

export default CancelSubscriptionStep;
