import React from 'react';
import InfoBlock from '~/components/molecule/InfoBlock';

export type Props = {};

const ApiKeySuccess: React.FCC<Props> = () => (
  <InfoBlock headerText="API key succesvol ingevoerd" margin={['xl', null]}>
    Zapier is nu gekoppeld aan jouw DatHuis account!
  </InfoBlock>
);

export default ApiKeySuccess;
