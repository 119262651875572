import React, { useCallback, useEffect, useMemo } from 'react';
import { WizardStepProps } from '~/components/organism/Wizard/context/WizardContext';
import useWizardStep from '~/hooks/useWizardStep';
import useWizard from '~/hooks/useWizard';
import { pluck } from 'ramda';
import OutputImageGrid from '../../Apps/components/AppStatusOverview/components/OutputImageGrid';
import OutputColorGrid from '../../Apps/components/AppStatusOverview/components/OutputColorGrid';
import OutputItem from '../../Apps/components/AppStatusOverview/components/OutputItem';
import OutputList from '../../Apps/components/AppStatusOverview/components/OutputList';
import getOutputsForOverview from '../../utils/getOutputsForOverview';
import OutputPlain from '../../Apps/components/AppStatusOverview/components/OutputPlain';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { useUpdateAccountMutation } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { navigate } from '@gatsbyjs/reach-router';
import Loading from '~/components/atom/Loading';
import removeTypenames from '~/util/removeTypenames';
import useErrorReporter from '~/hooks/useErrorReporter';
import { Body } from '~/components/atom/Typography';
import ConfettiExplosion from 'react-confetti-explosion';
import { useTheme } from 'styled-components';
import useFireTrackingEvent from '~/hooks/useFireTrackingEvent';

const text = {
  body: (
    <>
      Gefeliciteerd met het inrichten van je DatHuis account! Hieronder vind je
      een samenvatting van de keuzes die je hebt gemaakt. Deze kan je altijd in
      je account aanpassen.
      <br />
      <br />
      Tijd om aan de slag te gaan! Heb je vragen? Stel ze direct via de chat
      rechtsonder, of stuur ons een e-mail op{' '}
      <a href="mailto:contact@dathuis.nl" target="_blank" rel="noreferrer">
        contact@dathuis.nl
      </a>{' '}
      We staan je graag te woord.
    </>
  ),
};

export const id = 'OnboardingOverviewStep';
export const title = 'Samenvatting';

export type OutputType = {
  type: typeof id;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const fireTrackingEvent = useFireTrackingEvent();
  const account = useCurrentAccount();
  const [updateAccount, { loading }] = useUpdateAccountMutation();
  const errorReporter = useErrorReporter();
  const theme = useTheme();

  const wizardAPI = useWizard();
  const stepsInWizard = wizardAPI.getSteps();
  const stepIDs = pluck('id', stepsInWizard);
  const result = getOutputsForOverview(outputMap, stepIDs);
  const { images, lists, colors, items, plains } = result;

  const logoS3Key = account.AccountSettings?.logo?.s3key;

  useEffect(() => {
    // Because user will have couple of choices where to go from this step (apps page, flows, etc),
    // we should complete the wizard at the moment component is rendered
    const completeWizard = async () => {
      try {
        await updateAccount({
          variables: {
            accountId: account.id,
            update: {
              name: account.name,
              settings: {
                ...removeTypenames(account.AccountSettings ?? {}),
                ...(logoS3Key ? { logo: { s3key: logoS3Key } } : {}),
                metadata: { setup: { completed: true } },
              },
            },
          },
        });

        fireTrackingEvent({
          event: 'completeOnboardingWizard',
        });
      } catch (error) {
        errorReporter.captureException(
          new Error(
            `Errors on updateAccount in ${id} wizard step: ${JSON.stringify(
              error,
            )}`,
          ),
          'fatal',
        );
      }
    };
    void completeWizard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBeforeNext = useCallback(async outputMap => {
    const currentOutput: OutputType = outputMap[id];
    location.reload();
    await navigate('/-/');

    return currentOutput;
  }, []);

  const stepOptions = useMemo(() => ({ onBeforeNext }), [onBeforeNext]);

  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    api.free({ type: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;

  return (
    <JustificationContainer width="100%" align="center" direction="column">
      <ConfettiExplosion
        zIndex={theme.getTokens().zIndex.top}
        duration={4500}
      />
      <Body>{text.body}</Body>

      <JustificationContainer
        gap="m"
        direction="column"
        margin={['base', null, null, null]}
        width="100%"
      >
        {plains.map((value, index) => (
          <OutputPlain key={index} {...value} />
        ))}
        {images.map((grid, index) => (
          <OutputImageGrid key={index} {...grid} />
        ))}
        {colors.map((grid, index) => (
          <OutputColorGrid key={index} {...grid} />
        ))}
        {items.map((grid, index) => (
          <OutputItem key={index} {...grid} />
        ))}
        {lists.map((grid, index) => (
          <OutputList key={index} {...grid} />
        ))}
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default {
  id,
  title,
};
