import {
  BLOCK_ALIGN_BUTTONS,
  FONT_BASE_BUTTONS,
  FONT_STYLE_BUTTONS,
  LIST_BUTTONS,
  TOOLBAR_DIVIDER,
} from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons/buttonDefinitions';
import type { ToolbarComponent } from '../PluginsEditor/components/StaticToolbar/components/Buttons';

export enum SIGNATURE {
  ATTACHMENT = 'Attachment',
  DHFILE = 'DHFile',
}

export const SIGNATURE_EDITOR_BUTTONS: Array<ToolbarComponent> = [
  ...FONT_BASE_BUTTONS,
  TOOLBAR_DIVIDER,
  ...LIST_BUTTONS,
  TOOLBAR_DIVIDER,
  ...BLOCK_ALIGN_BUTTONS,
  TOOLBAR_DIVIDER,
  ...FONT_STYLE_BUTTONS,
  TOOLBAR_DIVIDER,
  { type: 'button', name: 'image' },
  { type: 'button', name: 'link' },
  { type: 'button', name: 'insert-html' },
  { type: 'button', name: 'blockquote' },
  TOOLBAR_DIVIDER,
  { type: 'button', name: 'undo' },
  { type: 'button', name: 'redo' },
  { type: 'button', name: 'full-screen' },
  { type: 'button', name: 'debug' },
];
