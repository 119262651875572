import {
  AccountRelationship,
  UserStatus,
  OfficeRelationship,
  DeletedUserToAccountRelationship,
  UserRole,
  Maybe,
  UserImage,
} from '~/graphql/types';
import getUserName from '~/util/getUserName';
import useSessionHydration from '../useSessionHydration';
import useUserLookup, { filterType } from '../useUserLookup';

type Filter = {
  statuses?: Array<UserStatus>;
  officeIds?: Array<string | null | undefined>;
};

type OfficeRelationsToUser = Array<{
  mainOffice: boolean;
  officeId: string | null | undefined;
}>;

export type ExpandedUser = {
  accountId: string | undefined;
  name: string;
  officeIds: OfficeRelationsToUser;
  role: UserRole | undefined;
  status: UserStatus | null | undefined;
  __typename: 'User' | 'PendingUser' | 'DeletedUser';
  email?: string;
  id: string;
  img?: Maybe<{ __typename: 'UserImage' } & Pick<UserImage, 'medium'>>;
  phone?: string;
  lastInvitationTime?: Maybe<string> | undefined;
};

export type ExpandedUsers = Array<ExpandedUser>;

export const filterByStatuses = (
  statuses: Array<UserStatus>,
  users: ExpandedUsers,
): ExpandedUsers | [] => {
  if (statuses.length === 0) return users;

  return users.filter(user => statuses.some(status => status === user.status));
};

export const filterByOfficeIds = (
  officeIds: Array<string | null | undefined>,
  users: ExpandedUsers,
): ExpandedUsers | [] => {
  if (officeIds.length === 0) return users;

  return users.filter(user =>
    user.officeIds.some(
      office => office.officeId && officeIds.includes(office.officeId),
    ),
  );
};

const useUsers = (filter?: Filter): ExpandedUsers => {
  const _filter = {
    statuses: filter?.statuses ?? [],
    officeIds: filter?.officeIds ?? [],
  };
  const [{ relations, deletedRelations }] = useSessionHydration();

  const users = useUserLookup();

  if (!users || !relations) return [];

  const arrayOfUsersFromLookup = Object.values(users);

  const officeRelations = filterType(
    'OfficeRelationship',
    relations,
  ) as Array<OfficeRelationship>;

  const accountRelations = filterType(
    'AccountRelationship',
    relations,
  ) as Array<AccountRelationship>;

  const deletedUsersRelations = filterType(
    'DeletedUserToAccountRelationship',
    deletedRelations,
  ) as Array<DeletedUserToAccountRelationship>;

  const allUsers = arrayOfUsersFromLookup.map(user => {
    const accountRelation = accountRelations.find(
      relation => relation.userId === user.id,
    );

    const officeRelationsToUser = officeRelations
      .filter(relation => relation.userId === user.id)
      .map(user => ({ mainOffice: user.mainOffice, officeId: user.officeId }));

    const deletedRelation = deletedUsersRelations.find(
      relation => relation.userId === user.id,
    );

    return {
      ...user,
      name: getUserName(user),
      id: user.id,
      role: accountRelation?.role ?? deletedRelation?.role,
      status: accountRelation?.status ?? null,
      accountId: accountRelation?.accountId ?? deletedRelation?.accountId,
      officeIds: officeRelationsToUser,
    };
  });

  const usersInOffices = filterByOfficeIds(_filter.officeIds, allUsers);
  return filterByStatuses(_filter.statuses, usersInOffices);
};

export default useUsers;
