import type { ContactValuationRequestFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

export const asValuationRequestFilterInput = (
  filter: ContactValuationRequestFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => ({
  App: {
    ValuationRequest: {
      has: asHasFilterInput(filter.has),
    },
  },
});
