import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './index.testid';

type LineTab = {
  key: string;
  label: string;
  dataTestId: string;
  accentedLabel?: string | null;
};
type Props = {
  selectedKey: string | null;
  tabs: Array<LineTab>;
  onSelect: (key: string) => void;
};
const LineTabs: React.FCC<Props> = ({ tabs, selectedKey, onSelect }) => (
  <Container data-testid={TEST_ID.CONTAINER} data-selectedkey={selectedKey}>
    {tabs.map(tab => {
      let accentedLabel: ReactElement | null = null;
      if (tab.accentedLabel != null) {
        accentedLabel = <AccentedLabel>{tab.accentedLabel}</AccentedLabel>;
      }

      return (
        <TabHeader
          key={tab.key}
          selected={tab.key === selectedKey}
          onClick={() => {
            if (tab.key !== selectedKey) {
              onSelect(tab.key);
            }
          }}
          data-testid={tab.dataTestId}
        >
          {tab.label}
          {accentedLabel}
        </TabHeader>
      );
    })}
  </Container>
);

type TabHeaderProps = {
  selected: boolean;
};
const TabHeader = styled.div<TabHeaderProps>`
  position: relative;
  cursor: pointer;
  display: inline-block;

  ${({ theme, selected }) => css`
    color: ${selected ? theme.color('secondary') : theme.color('primary')};
    padding: ${theme.space('m')} ${theme.space('s')};
  `};

  ${({ theme, selected }) => {
    if (selected) {
      return css`
        font-weight: ${theme.fw('semiBold')};

        &:after {
          content: '';
          height: ${theme.space('xxxs')};
          width: 100%;
          background-color: ${theme.color('secondary')};
          position: absolute;
          bottom: -1px;
          left: 0;
        }
      `;
    }

    return '';
  }}
`;

const AccentedLabel = styled.div<{}>`
  position: absolute;
  top: 0;
  white-space: nowrap;

  ${({ theme }) => css`
    color: ${theme.color('secondary', 'light')};
    left: calc(100% - ${theme.space('m')});
  `}
`;

const Container = styled.div<{}>`
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color('tertiary')};
    margin-bottom: ${theme.space('base')};
  `};
`;

export default LineTabs;
