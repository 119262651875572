import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import deserialize from '../deserialize';
import normalizeDescendantsToDocumentFragment from '../normalizeDescendantsToDocumentFragment';
import removeLineBreaksFromEmptyLines from './utils/removeLineBreaksFromEmptyLines';
import removeStyleProperties from './utils/removeStyleProperties';
import { pipe } from 'ramda';
import { reporter } from '~/hooks/useErrorReporter';
import type { EditorValue } from '../../types';

/**
 * Parses html, deserializes into Slate format (array of nodes), returns the fragment
 *
 * keywords: html to slate
 */

const parseAndDeserialize = ({
  html,
  markAsPendingImage,
  customElements = [],
}: {
  html: string;
  markAsPendingImage?: boolean;
  customElements?: Array<ELEMENTS>;
}): EditorValue => {
  try {
    const htmlToFragment = pipe(
      removeLineBreaksFromEmptyLines,
      str => new DOMParser().parseFromString(str, 'text/html'),
      doc => removeStyleProperties(doc, ['font-family']),
      doc =>
        deserialize({
          el: doc.body,
          markAsPendingImage,
          customElements,
          initialValue: html,
        }),
      deserialized => normalizeDescendantsToDocumentFragment(deserialized),
    );

    const fragment = htmlToFragment(html);

    if (!fragment || fragment.length === 0) {
      throw new Error(`Fragment is null or empty | fragment: ${fragment}`);
    }

    return fragment;
  } catch (error) {
    reporter.captureException(error, 'fatal');

    return [
      {
        type: ELEMENTS.ERROR_BOUNDARY,
        // We need to pass it children to conform to Slate's element structure
        children: [],
      },
    ];
  }
};

export default parseAndDeserialize;
