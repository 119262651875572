import { createPortal } from 'react-dom';
import useCleanUpTooltipLayerState from '~/hooks/useCleanUpTooltipLayerState';
import usePortal from '~/hooks/usePortal';

type Props = {
  root?: string;
  key?: string;
  children?: React.ReactNode;
};

const Portal: React.FCC<Props> = ({ children, root = 'modal-portal' }) => {
  const target = usePortal(root);

  // Clean up tooltip state on scroll
  useCleanUpTooltipLayerState();

  // We provide root as a key for createPortal
  return createPortal(children, target, root);
};

export default Portal;
