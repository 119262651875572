import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  FORM: `${cleanedFilename(__filename)}-form`,
  TOKEN_INPUT: `${cleanedFilename(__filename)}-token-input`,
  NEXT_BUTTON: `${cleanedFilename(__filename)}-next-button`,
  SKIP_BUTTON: `${cleanedFilename(__filename)}-skip-button`,
  NOTIFICATION_BUTTON: `${cleanedFilename(__filename)}-notification-button`,
};
