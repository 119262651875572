import React, { useEffect } from 'react';

import {
  GetTasksQueryVariables,
  useGetTasksQuery,
  useMutatedTaskSubscription,
} from '~/graphql/types';
import { getErrorType } from '~/util/errorHandling';
import ErrorTypes from '~/ErrorTypes';

import AppErrorScreen, {
  UnauthorizedAppErrorScreen,
} from '~/components/template/AppErrorScreen';
import { TASK_LIST_LIMIT } from '../..';
import baseQueryVariablesForListType from '../../utils/baseQueryVariablesForListType';
import { TaskGetterChildProps } from '../TaskWithSubscriptionGetter';
import { TaskListType } from '../../types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { flatten, prop, uniqBy, values } from 'ramda';
import isDeleted from '../../utils/isDeleted';
import mergeListWithLatestVersion from '~/util/mergeListWithLatestVersion';
import groupedTasks, { deletedTaskIds } from '~/components/page/Tasks/state';
import generateActivityV2 from '~/components/template/EventTimelineV2/utils/generateActivityV2';
import { ActivityV2 } from '~/components/template/EventTimelineV2/types';
import removeActivityFromList from '~/components/template/EventTimelineV2/utils/removeActivityFromList';
import processOfficeTasks from '../../utils/processOfficeTasks';
import Loading from '~/components/atom/Loading';

type Props = {
  accountId: string;
  filteredOfficeId: string;
  filteredUserId: string;
  selectedListType: TaskListType;
  children: (props: TaskGetterChildProps) => JSX.Element;
};

const GetOfficeUserTasks: React.FC<Props> = ({
  accountId,
  filteredOfficeId,
  filteredUserId,
  selectedListType,
  children,
}) => {
  const [tasks, setTasks] = useRecoilState(groupedTasks);
  const deletedTasks = useRecoilValue(deletedTaskIds);

  const listTypeQueryVariables =
    baseQueryVariablesForListType(selectedListType);

  const variables: GetTasksQueryVariables = {
    ...listTypeQueryVariables,
    accountId,
    officeId: filteredOfficeId,
    limit: TASK_LIST_LIMIT,
    unassigned: true,
    userId:
      filteredUserId != null && filteredUserId !== 'no-selection'
        ? filteredUserId
        : undefined,
  };

  const { data, loading, error, fetchMore } = useGetTasksQuery({
    variables,
  });

  useEffect(() => {
    if (data?.getTasks.items) {
      const items = data.getTasks.items.map(generateActivityV2);

      setTasks(prev => {
        const prevTasks = flatten(
          values(prev).filter((v): v is Array<ActivityV2> => v !== undefined),
        );
        const newTasks = items.filter(activity => !isDeleted(activity));

        const mergedWithLatestVersion = mergeListWithLatestVersion<ActivityV2>(
          prevTasks,
          newTasks,
        );

        const formattedTasks = processOfficeTasks({
          userId: filteredUserId,
          officeId: filteredOfficeId,
          tasks: mergedWithLatestVersion,
          selectedListType,
        });

        return formattedTasks;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.getTasks.items,
    filteredOfficeId,
    filteredUserId,
    selectedListType,
  ]);

  const { data: mutatedTaskData } = useMutatedTaskSubscription({
    variables: {
      accountId,
    },
    onData: ({ data }) => {
      const mutatedTask = data.data?.mutatedTask;

      if (mutatedTask?.id) {
        const newTask = generateActivityV2(mutatedTask);

        const isDeletedBeforeSubscription = deletedTasks.includes(newTask.id);

        if (isDeleted(newTask) || isDeletedBeforeSubscription) {
          const newTasks = removeActivityFromList({
            groupedActivities: tasks,
            deletedActivity: newTask,
          });

          setTasks(newTasks);
          return;
        }

        setTasks(prev => {
          const allTasks: Array<ActivityV2> = flatten(
            values({ ...prev }).filter(
              (v): v is Array<ActivityV2> => v !== undefined,
            ),
          );
          const sameTask = allTasks.find(task => task.id === newTask.id);
          const updatedTasks = sameTask
            ? allTasks.map(task => {
                if (task.id === sameTask.id) {
                  return newTask;
                }
                return task;
              })
            : [...allTasks, newTask];

          const formattedTasks = processOfficeTasks({
            tasks: updatedTasks,
            userId: filteredUserId,
            officeId: filteredOfficeId,
            selectedListType,
          });

          return formattedTasks;
        });
      }
    },
  });

  if (loading) return <Loading />;
  if (error && getErrorType(error) === ErrorTypes.unauthorisedError)
    return <UnauthorizedAppErrorScreen />;

  if (!data || error) {
    return <AppErrorScreen />;
  }

  const { nextToken } = data.getTasks;
  const loadMore = () =>
    fetchMore({
      variables: {
        ...variables,
        nextToken: nextToken || null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const mutatedTask = mutatedTaskData?.mutatedTask;

        if (fetchMoreResult?.getTasks) {
          return {
            __typename: 'Query',
            getTasks: {
              ...fetchMoreResult?.getTasks,
              items: uniqBy(prop('id'), [
                ...(mutatedTask ? [mutatedTask] : []),
                ...previousResult.getTasks.items,
                ...fetchMoreResult.getTasks.items,
              ]),
            },
          };
        }

        return previousResult;
      },
    });

  return children({
    loadMore,
    hasMore: Boolean(nextToken),
  });
};

export default GetOfficeUserTasks;
