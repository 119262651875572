import type { FormBuilder_NodeFragment } from '~/graphql/types';

/**
 * Returns the node that starts the chain
 * @param { Array<FormBuilder_Node>} nodes - All nodes
 * keywords: startNode
 */
const getStartNode = (
  nodes: Array<FormBuilder_NodeFragment>,
): FormBuilder_NodeFragment => {
  // Create a set of targetNodeIds to identify the starting node
  const targetNodeIds = new Set(
    nodes.flatMap(node =>
      node.defaultNext ? [node.defaultNext.targetNodeId] : [],
    ),
  );

  // Find the starting node (which is not a targetNodeId of any other node)
  const startNode = nodes.find(
    node => !targetNodeIds.has(node.id),
  ) as FormBuilder_NodeFragment;

  return startNode;
};

export default getStartNode;
