import { v4 as uuid } from 'uuid';

// Define a map of ID prefixes for various entities
export const ID_PREFIX = {
  ACCOUNT: 'a---',
  OFFICE: 'o---',
  USER: 'u---',
  CONTACT: 'c---',
  TASK: 't---',
  TEMPLATE: 'tp--',
  FLOW_BLUEPRINT: 'fbp-',
  FLOW_BLUEPRINT_ACTION: 'fbpa',
  FLOW_INSTANCE: 'fi--',
  TRIGGER: 'tr--',
  TRANSACTION_ID: 'tai-',
  PARTNER_ID: 'pai-',
  EVENT: 'e---',
  APP: 'ap--',
  SCHEDULED_ITEM: 's---',
  ASYNC_TASK: 'at--',
  SEGMENT: 'se--',
  BRAINBAY_WAARDECHECK: 'bbwc',
  ZAPIER_TRIGGER: 'zt--',
  ZAPIER_EVENT: 'ze--',
  FORMBUILDER: 'frm-',
  FORMBUILDER_SCREEN_NODE: 'frms',
  FORMBUILDER_EVENT_NODE: 'frmn',
  FORMBUILDER_OPTION: 'frmo',
  FORMBUILDER_EVENT: 'frme',
} as const;

export type EntityName = keyof typeof ID_PREFIX;

/**
 * Generates a valid backend-usable ID including prefix
 * @param {EntityName} entityName - The name of the entity for which to generate the ID
 * @returns {string} The generated ID with the appropriate prefix
 */
const generateIdForEntity = (entityName: EntityName): string => {
  const generatedId = uuid();
  if (!(entityName in ID_PREFIX)) {
    throw new Error(`Invalid entity name: ${entityName}`);
  }
  return `${ID_PREFIX[entityName]}${generatedId}`;
};

export default generateIdForEntity;
