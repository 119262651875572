import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  TYPE_FIELD: `${cleanedFilename(__filename)}-type-field`,
  TASK_TITLE: `${cleanedFilename(__filename)}-task-title`,
  TASK_TITLE_TEXT: `${cleanedFilename(__filename)}-task-title-text`,
  DUE_DATE_DATE: `${cleanedFilename(__filename)}-dueDateDate`,
  DUE_DATE_TIME: `${cleanedFilename(__filename)}-dueDateTime`,
  USERID_FIELD: `${cleanedFilename(__filename)}-userId-field`,
  OFFICEID_FIELD: `${cleanedFilename(__filename)}-officeId-field`,
  DESCRIPTION_TEXTAREA_FIELD: `${cleanedFilename(
    __filename,
  )}-description-textarea-field`,
  DESCRIPTION_TEXT: `${cleanedFilename(__filename)}-description-text`,
  CHANGE_STATUS_BUTTONS: `${cleanedFilename(__filename)}-change-status-button`,
};
