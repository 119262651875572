/**
 * Take two lists of objects, if the object has the same id and diffent version return the latest version of the object
 * @param {Array<WithId<T>>} arr1 - List 1
 * @param {Array<WithId<T>>} arr2 - List 2
 * keywords: mergeList, version
 */

type WithId<A> = A & { id: string; _v: number };

const mergeListWithLatestVersion = <T>(
  arr1: Array<WithId<T>>,
  arr2: Array<WithId<T>>,
): Array<WithId<T>> =>
  [...arr1, ...arr2].reduce(
    (prev, current) => {
      const idx = prev.findIndex(item => current.id === item.id);

      // if item already exists, return the latest version
      if (idx !== -1) {
        const prevActivity = prev[idx];
        const isLaterVersion = prevActivity._v > current._v;
        prev[idx] = isLaterVersion ? prevActivity : current;
      } else {
        prev.push(current);
      }
      return prev;
    },
    [] as Array<WithId<T>>,
  );

export default mergeListWithLatestVersion;
