import React from 'react';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import type { ActionModalProps } from '../../constants';

export type Props = {
  dataTestId?: string;
} & ActionModalProps;

const UnblockEmail: React.FCC<Props> = ({ onCancel, onComplete, ...rest }) => (
  <ConfirmActionModal
    actionType="UnblockEmail"
    actionInput={{
      UnblockEmail: {},
    }}
    onComplete={onComplete}
    onCancel={onCancel}
    {...rest}
  />
);

export default UnblockEmail;
