import React from 'react';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import GetMyTasks from '../GetMyTasks';
import GetOfficeUserTasks from '../GetOfficeUserTasks';
import { TaskListType } from '../../types';

export type TaskGetterChildProps = {
  loadMore: () => Promise<any>;
  hasMore: boolean;
};
type Props = {
  selectedUserId: string | null;
  selectedOfficeId: string | null;
  selectedListType: TaskListType;
  children: (props: TaskGetterChildProps) => JSX.Element;
};
const TaskWithSubscriptionGetter = ({
  selectedOfficeId,
  selectedUserId,
  selectedListType,
  children,
}: Props) => {
  const isSpecificOfficeSelected = selectedOfficeId != null;
  const isMyTaskList = !isSpecificOfficeSelected;
  const account = useCurrentAccount();

  const baseTaskGetterProps = {
    accountId: account.id,
    filteredUserId: selectedUserId || '',
    selectedListType,
  };
  const withOfficeTaskGetterProps = {
    ...baseTaskGetterProps,
    filteredOfficeId: selectedOfficeId || '',
  };

  if (isMyTaskList) {
    return (
      <GetMyTasks {...baseTaskGetterProps}>
        {props => children(props)}
      </GetMyTasks>
    );
  }

  return (
    <GetOfficeUserTasks {...withOfficeTaskGetterProps}>
      {props => children(props)}
    </GetOfficeUserTasks>
  );
};

export default TaskWithSubscriptionGetter;
