import React from 'react';
import styled, { css } from 'styled-components';
import CopyCode from '~/components/atom/CopyCode';
import GoToPath from '~/components/organism/GoToPath';
import { Heading4, Label, Variant } from '~/components/atom/Typography';

import { text } from '../../index';

export type Props = {
  zapierApiKey: string;
};

const GetApiKey: React.FCC<Props> = ({ zapierApiKey }) => (
  <>
    <CopyCode
      label={
        <Label
          margin={[null]}
          fontWeight="semiBold"
          variant={Variant.primary}
          size="m"
        >
          {text.codeLabel}
        </Label>
      }
      code={zapierApiKey}
    />
    <Heading variant={Variant.primary}>{text.secondStep}</Heading>
    <GoToPath path={text.path} label={text.pathLabel} link={text.link} />
  </>
);

const Heading = styled(Heading4)<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('l')};
    margin-bottom: ${theme.space('s')};
  `,
);

export default GetApiKey;
