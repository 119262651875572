import { atom } from 'recoil';
import type {
  AccountStyle,
  DhImage,
  WidgetSettingsStyles,
} from '~/graphql/types';

export type BrandSettings = {
  unsubscribeLinkText: string | null;
  logo: DhImage | null;
  colors: WidgetSettingsStyles | null;
  url: string | null;
  style: AccountStyle | null;
};

export const defaultColors = {
  __typename: 'WidgetSettingsStyles',
  primary: {
    __typename: 'WidgetSettingsStyleConfigColorPair',
    color: '#fff',
    background: '#fff',
  },
  secondary: {
    __typename: 'WidgetSettingsStyleConfigColorPair',
    color: '#fff',
    background: '#fff',
  },
} as const;

export const defaultChangesState: BrandSettings = {
  unsubscribeLinkText: null,
  logo: null,
  colors: defaultColors,
  url: null,
  style: {
    __typename: 'AccountStyle',
    fontFamily: null,
  },
};

export const brandSettingsCurrent = atom<BrandSettings>({
  key: 'brandSettingsCurrent',
  default: defaultChangesState,
});

export const brandSettingsInitial = atom<BrandSettings>({
  key: 'brandSettingsInitial',
  default: defaultChangesState,
});
