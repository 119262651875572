import { EmailAliasStatus, EmailSyncStatus } from '~/graphql/types';
import { AUTHORISE_LEVEL, MAILBOX_TYPE } from '~/util/constants';
import useSessionHydration from '../useSessionHydration';
import { NamedEmail } from '~/util/namedEmailFormatter';
import useOffices from '../useOffices';
import useUsers from '../useUsers';
import { reporter } from '../useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';
import { Mailbox } from '~/graphql/types.client.flow';

export type SendableMailbox = {
  authoriseLevel: AUTHORISE_LEVEL;
  namedEmail: NamedEmail;
  // Sync status of the primary email
  syncState: EmailSyncStatus | null;
};

/**
 * Returns all available emails (replaced with aliases if they are verified)
 * Emails returned can have all types of EmailSyncStatus
 *
 * This will replace buildAvailableEmailsToSendFrom when we refactor all the class based components
 *
 */
const useAvailableEmailsToSendFrom = (): Array<SendableMailbox> => {
  const [{ mailboxes, me }] = useSessionHydration();

  const myOffices = useOffices({ userIds: [me.id] });
  const officeIds = myOffices.map(office => office.id);

  const users = useUsers();

  const availableEmails: Array<SendableMailbox> = [];

  const primaryMailboxes: Array<Mailbox> = [];
  const aliasMailboxes: Array<Mailbox> = [];

  if (mailboxes && mailboxes.length > 0) {
    for (const mailbox of mailboxes) {
      switch (mailbox.__typename) {
        case 'AccountMailbox':
        case 'OfficeMailbox':
        case 'UserMailbox':
          primaryMailboxes.push(mailbox);
          break;

        case 'AccountMailboxAlias':
        case 'OfficeMailboxAlias':
        case 'UserMailboxAlias':
          aliasMailboxes.push(mailbox);
          break;

        default:
          reporter.captureException(
            new Error(
              `${cleanedFilename(
                __filename,
              )} | Unknown mailbox type (${JSON.stringify(
                mailbox,
                null,
                2,
              )}) encountered`,
            ),
            'warning',
          );
          break;
      }
    }
  }

  primaryMailboxes.forEach(mailbox => {
    const syncState =
      mailbox.email.__typename === 'ConnectedEmail'
        ? mailbox.email.syncState
        : null;

    switch (mailbox.__typename) {
      case MAILBOX_TYPE.AccountMailbox: {
        const primaryEmail = mailbox.email.email;
        const aliasEmail = findAliasEmail(aliasMailboxes, primaryEmail);

        // all account mailboxes are available to everyone
        availableEmails.push({
          authoriseLevel: AUTHORISE_LEVEL.ACCOUNT,
          namedEmail: {
            email: primaryEmail,
            emailToShow: aliasEmail == null ? primaryEmail : aliasEmail,
          },
          syncState,
        });
        break;
      }
      case MAILBOX_TYPE.OfficeMailbox:
        if (officeIds.includes(mailbox.officeId)) {
          const primaryEmail = mailbox.email.email;
          const aliasEmail = findAliasEmail(aliasMailboxes, primaryEmail);

          availableEmails.push({
            authoriseLevel: AUTHORISE_LEVEL.OFFICE,
            namedEmail: {
              name: myOffices.find(o => o.id === mailbox.officeId)?.name,
              email: primaryEmail,
              emailToShow: aliasEmail == null ? primaryEmail : aliasEmail,
            },
            syncState,
          });
        }

        break;
      case MAILBOX_TYPE.UserMailbox:
        if (me.id === mailbox.userId) {
          const primaryEmail = mailbox.email.email;
          const aliasEmail = findAliasEmail(aliasMailboxes, primaryEmail);

          availableEmails.push({
            authoriseLevel: AUTHORISE_LEVEL.USER,
            namedEmail: {
              name: users.find(u => u.id === mailbox.userId)?.name,
              email: primaryEmail,
              emailToShow: aliasEmail == null ? primaryEmail : aliasEmail,
            },
            syncState,
          });
        }

        break;
      default:
        reporter.captureException(
          new Error(
            `${cleanedFilename(
              __filename,
            )} | Unknown mailbox type (${JSON.stringify(
              mailbox,
              null,
              2,
            )}) encountered`,
          ),
          'warning',
        );
        break;
    }
  });

  return availableEmails;
};

const findAliasEmail = (
  aliasMailboxes: Array<Mailbox>,
  primaryEmail: string,
): string | null => {
  let result: string | null = null;

  aliasMailboxes.forEach(mailbox => {
    switch (mailbox.__typename) {
      case MAILBOX_TYPE.OfficeMailboxAlias:
      case MAILBOX_TYPE.UserMailboxAlias:
      case MAILBOX_TYPE.AccountMailboxAlias: {
        if (
          mailbox.email.email === primaryEmail &&
          mailbox.email.status === EmailAliasStatus.Verified
        ) {
          result = mailbox.email.emailAlias;
        }

        break;
      }
    }
  });

  return result;
};

export default useAvailableEmailsToSendFrom;
