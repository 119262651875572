import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import { SystemSize, SystemFontWeight } from '~/theme';
import Icon from '~/components/atom/Icon';
import JustificationContainer, {
  type Justification,
} from '~/components/atom/JustificationContainer';
import EditingSection from './components/EditingSection';
import TEST_ID from './index.testid';
import Typography, {
  Props as TypographyProps,
} from '~/components/atom/Typography';
import { ThemeColor } from '~/theme/System/tokens/colorPalette';
import { useTheme } from 'styled-components';
import type { ValidationFunction } from '~/util/getValidationErrors';

export type Props = {
  text?: string;
  color?: ThemeColor;
  readonly?: boolean;
  validation?: Array<ValidationFunction>;
  size?: SystemSize;
  iconSize?: SystemSize;
  margin?: Array<SystemSize | null>;
  fontWeight?: SystemFontWeight;
  blurred?: boolean;
  placeholder?: string;

  /** Loading state of onSave function */
  loading?: boolean;

  onSave?: (value: string) => void;
  as?: TypographyProps['as'];

  className?: string;

  /**
   * When there is not enough space for the Typography component, this will truncate the text.
   * To be able to use this prop, make sure that the parent has a width set
   */
  truncate?: boolean;

  /**
   * Passes Justification to main container, controls space between icon and typography
   */
  justification?: Justification;

  /**
   * Disables interactions with the field, e.g. when saving
   */
  disabled?: boolean;
};

const EditableText: React.FCC<Props> = ({
  dataTestId,
  text = '',
  color = { group: 'primary', variant: 'base' },
  readonly = false,
  validation = [],
  size = 'xl',
  iconSize = 'l',
  fontWeight = 'semiBold',
  as,
  margin = [],
  loading = false,
  onSave,
  blurred,
  className,
  truncate,
  justification = 'start',
  disabled = false,
  placeholder,
  ...rest
}) => {
  const theme = useTheme();
  const [textValue, setTextValue] = useState<string>(text);
  const [editing, setEditing] = useState<boolean>(false);

  const onCancel = () => {
    if (!readonly) {
      setTextValue(text);
      setEditing(false);
    }
  };

  const _onSave = () => {
    if (!isEmpty(textValue) && !readonly) {
      onSave && onSave(textValue);
      setEditing(false);
    }
  };

  // Update local state if props change
  useEffect(() => {
    if (text !== textValue) {
      setTextValue(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useGlobalKeyBinding({ keys: 'enter', callback: _onSave, enabled: editing });
  useGlobalKeyBinding({ keys: 'escape', callback: onCancel, enabled: editing });

  return (
    <Container
      justification={justification}
      align="center"
      nonResponsive
      data-testid={dataTestId || TEST_ID.CONTAINER}
      margin={margin}
      size={size}
      {...rest}
    >
      {!editing && !loading && (
        <>
          <Typography
            color={color}
            size={size}
            fontWeight={fontWeight}
            margin={[null]}
            as={as}
            dataTestId={TEST_ID.NAME}
            data-blurred={blurred}
            className={className}
            truncate={truncate}
          >
            {textValue}
          </Typography>

          {!readonly && (
            <StyledIcon
              name="pencil"
              color={theme.color(disabled ? 'tertiary' : 'secondary')}
              strokeWidth={2.5}
              iconSize={iconSize}
              onClick={() => setEditing(true)}
              dataTestId={TEST_ID.EDIT_BUTTON}
            />
          )}
        </>
      )}

      {(editing || loading) && (
        <EditingSection
          value={textValue}
          loading={loading}
          onSave={_onSave}
          onCancel={onCancel}
          onChange={event => setTextValue(event?.currentTarget?.value ?? '')}
          validation={validation}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
    </Container>
  );
};

const Container = styled(JustificationContainer)<{
  size?: Props['size'];
}>(
  ({ size, theme }) => css`
    width: 100%;
    /** We pass a fixed height so that when editing section closes the page doesn't get jumpy */
    min-height: 1em;
    font-size: ${theme.fs(size || 'm')};
  `,
);

const StyledIcon = styled(Icon)<{ iconSize: SystemSize }>`
  cursor: pointer;
  ${({ theme, iconSize }) => css`
    margin-left: ${theme.space('s')};
    svg {
      font-size: ${theme.fs(iconSize)};
    }
  `}
`;

export default EditableText;
