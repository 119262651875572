import React from 'react';
import styled, { css } from 'styled-components';

import { Heading4, Variant } from '~/components/atom/Typography';
import OverviewImage, {
  IMAGE_CONTAINER_SIZE_IN_PX,
} from './components/OverviewImage';

export type ImageList = Array<{
  label: string;
  value: {
    s3key?: string;
    url?: string;
  };
}>;

type CleanedImage = {
  label: string;
  value: {
    s3key: string;
    url: string;
  };
};

export type Props = {
  category: React.ReactNode;
  values: ImageList;
};

const OutputImageGrid: React.FCC<Props> = ({ category, values = [] }) => {
  const cleanedValues = values.filter((image): image is CleanedImage =>
    image && image.value && image.value.s3key && image.value.url ? true : false,
  );

  return (
    <>
      {cleanedValues.length > 0 && (
        <div>
          <Heading4 margin={[null]} variant={Variant.primary}>
            {category}
          </Heading4>

          <GridContainer>
            {cleanedValues.map(({ label, value }) => (
              <OverviewImage
                key={`${label}-${value}`}
                label={label}
                src={value.url}
              />
            ))}
          </GridContainer>
        </div>
      )}
    </>
  );
};

const GridContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.space('s')};
    display: grid;
    grid-template-columns: repeat(2, ${IMAGE_CONTAINER_SIZE_IN_PX}px);
    grid-column-gap: ${theme.space('base')};
    grid-row-gap: ${theme.space('base')};
  `,
);

export default OutputImageGrid;
