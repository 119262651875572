import { UserDetailsFormData } from '../..';

export const asUserDetailsFormData = (getUser): UserDetailsFormData => {
  const { name, email, phone, Offices } = getUser;

  const selectedMainOffice = Offices.find(
    office => office.relation != null && office.relation.mainOffice,
  );

  return {
    phone: phone !== undefined ? phone : null,
    name: name !== undefined ? name : null,
    email: email !== undefined ? email : null,
    selectedMainOfficeId:
      selectedMainOffice != undefined ? selectedMainOffice.id : null,
  };
};
