import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { ExternalLink } from '~/components/molecule/Link';
import { Heading6 } from '~/components/atom/Typography';
import { EventCardAttachment, FileType } from '~/graphql/types';

export type Props = {
  dataTestId?: string;
  attachments: Array<EventCardAttachment>;
};

const text = {
  attachments: 'Bijlagen:',
};

const Attachments: React.FC<Props> = ({ dataTestId, attachments, ...rest }) => {
  if (attachments?.length === 0) return null;

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Heading6 margin={[null, null, 'base', null]}>
        {text.attachments}
      </Heading6>

      <JustificationContainer width="100%" wrap="wrap" gap="xs">
        {attachments.map(({ fileName, label, src, fileType }) => {
          const iconName = fileType === FileType.Image ? 'image' : 'file-text';

          return (
            <ExternalLink
              to={src}
              key={`${fileName}-attachment`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ItemContainer
                align="center"
                padding={[null, null, 'xxs', null]}
                wrap="nowrap"
              >
                <Icon name={iconName} margin={[null, 'xs', null, null]} />
                {label}
              </ItemContainer>
            </ExternalLink>
          );
        })}
      </JustificationContainer>
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('base')};
    border-top: 1px solid ${theme.color('tertiary', 'light')};
    width: 100%;
  `,
);

const ItemContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    color: ${theme.color('primary')};
    font-size: ${theme.fontSize('s')};
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

export default Attachments;
