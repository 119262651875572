import React from 'react';
import useConstructAndNotifyErrors from '~/hooks/useConstructAndNotifyErrors';
import { isValidURL } from '~/util/Validation/URL';
import Input, { Props as InputProps } from '../Input';
import type { ValidationFunction } from '~/util/getValidationErrors';

type TransformationFunction = (
  value: string | null,
  previousValue: string | null,
) => string | null;

export const UrlValidation: ValidationFunction = value =>
  value == null || isValidURL(value)
    ? true
    : 'Moet beginnen met http:// of https://';

type OnChangeCallback = (
  value: string | null,
  extra: { errors: Array<string>; hasErrors: boolean },
) => void;

export type Props = Omit<InputProps, 'error' | 'onChange'> & {
  id: string;
  onError?: (error?: string) => void;
  onChange?: OnChangeCallback;
  /**
   * Array of transformation functions applied after
   * after an input change. The onChange function
   * will receive the transformed input.
   */
  transform?: Array<TransformationFunction>;
  /**
   * Array of validation functions, errors produced will be shown
   * accordingly.
   */
  validation?: Array<ValidationFunction>;
  /**
   * Extra errors.
   */
  errors?: Array<string>;
};

const ControlledInput: React.FCC<Props> = ({
  dataTestId,
  onChange: originalOnChange,
  onError,
  id: givenId,
  errors = [],
  validation = [],
  transform = [],
  value,
  ...rest
}) => {
  const { constructAndNotifyErrors, error, id } = useConstructAndNotifyErrors({
    id: givenId,
    errors,
    validation,
    value,
  });

  const inputProps = {
    ...rest,
    error,
  };
  return (
    <Input
      id={id}
      data-testid={dataTestId}
      value={value}
      onChange={nextValue => {
        const transformedValue = transform.reduce(
          (result, transformFunction) =>
            transformFunction(result, value ?? null),
          nextValue,
        );

        if (transformedValue === value) return;

        const { errors, error, errorsChanged } =
          constructAndNotifyErrors(transformedValue);

        if (onError && errorsChanged) onError(error);

        if (originalOnChange) {
          originalOnChange(transformedValue, {
            errors,
            hasErrors: errors.length !== 0,
          });
        }
      }}
      {...inputProps}
    />
  );
};

export default ControlledInput;
