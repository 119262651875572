export const headerProps = (props: any, { column }: any) =>
  getStyles(props, column.align);

export const cellProps = (props: any, { cell }: any) =>
  getStyles(props, cell.column.align);

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'center',
      display: 'flex',
    },
  },
];
