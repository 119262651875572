import StyleToJS from 'style-to-js';
import { styleToJSOptions } from '../../../utils/deserialize/utils/convertDOMAttrsToReactProps/utils';
import type { CSSProperties } from 'react';

/**
 * Safely removes a property from style attribute, returns it as string
 * @param {string} style - Style string 'display:inline; color: red;'
 * @param {Array<string>} propsToRemove - properties to remove in React shape ['fontSize', 'color']
 */
const removePropertiesFromStyleString = (
  style: string,
  propsToRemove: Array<keyof CSSProperties>,
): string => {
  // Convert CSS string to an object compatible with React's style prop
  const styleObject = StyleToJS(style, styleToJSOptions);

  propsToRemove.forEach(key => {
    delete styleObject[key];
  });

  const modifiedCssString = Object.entries(styleObject)
    .map(([key, value]) => `${key}: ${value};`)
    .join(' ');

  return modifiedCssString.trim();
};

export default removePropertiesFromStyleString;
