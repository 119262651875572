import { clone, isEmpty, mergeDeepRight, pick } from 'ramda';
import { useState } from 'react';
import {
  useUpdateAccountMutation,
  useUpdateFlowSettingsMutation,
  type AccountSettingsFieldsFragment,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import removeTypenames from '~/util/removeTypenames';
import type { BrandSettings } from '../../state';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Catalog from '~/Catalog';

/** Reset the initial recoil state to the latest state */
type ResetFn = () => void;

const text = {
  mutationError: Catalog.genericUnknownErrorMessage,
  success: Catalog.genericSuccessMessage,
};

/**
 * Handles update mutations within the Brand settings page.
 *
 * @returns onUpdate function that accepts an additional onReset callback to reset the initial state after a successful update etc.
 * @returns loading state of the mutation calls. It is loading until all mutations are finished.
 */
const useUpdateMutations = ({
  changedFields,
  prevData,
  flowsAllowed,
}: {
  changedFields: BrandSettings;
  prevData: { settings: AccountSettingsFieldsFragment };
  flowsAllowed: boolean;
}): {
  onUpdate: (params: { onReset: ResetFn }) => Promise<void>;

  /** Loading is true until all mutations are completed. */
  loading: boolean;
} => {
  const addToast = useAddToast();

  const { id: accountId } = useCurrentAccount();
  const [loading, setLoading] = useState(false);

  const [updateFlowSettings] = useUpdateFlowSettingsMutation();
  const [updateAccount] = useUpdateAccountMutation();

  const onUpdate = async ({ onReset }: { onReset: ResetFn }) => {
    if (!prevData) return;

    let hasError = false;
    setLoading(true);

    if (flowsAllowed === true && changedFields.unsubscribeLinkText) {
      const { data, errors } = await updateFlowSettings({
        variables: {
          accountId,
          update: { unsubscribeLinkText: changedFields.unsubscribeLinkText },
        },
      });

      if (!data || (errors && errors.length > 0)) {
        hasError = true;
      }
    }

    const updatedFields = clone(
      pick(['colors', 'logo', 'url', 'style'], changedFields),
    );

    const input = {
      ...updatedFields,
      ...(updatedFields.logo
        ? // logo.url is not allowed in update input
          { logo: { s3key: updatedFields.logo.s3key } }
        : {}),
    };

    if (!isEmpty(input)) {
      const { data, errors } = await updateAccount({
        variables: {
          accountId,
          update: removeTypenames(
            mergeDeepRight(prevData, {
              settings: input,
            }),
          ),
        },
      });

      if (!data || (errors && errors.length > 0)) {
        hasError = true;
      }
    }

    setLoading(false);

    if (hasError) {
      return addToast([formatToastMessage(text.mutationError, 'danger')]);
    }

    onReset();
    addToast([formatToastMessage(text.success, 'success')]);
  };

  return { onUpdate, loading };
};

export default useUpdateMutations;
