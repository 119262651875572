import { Issue } from '~/components/page/Automation/v2/state/flowIssues';

const getIssueLevel = (issues: Array<Issue>): 'warning' | 'error' | null => {
  if (issues.length === 0) return null;

  const warning = issues.some(issue => issue.level === 'warning');
  const error = issues.some(issue => issue.level === 'error');

  return error ? 'error' : warning ? 'warning' : null;
};

export default getIssueLevel;
