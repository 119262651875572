import {
  TableHeaderDefinitionCellType,
  TableCell,
  StringRenderFunction,
} from '../../types.flow';
import { BaseHeaderCellOptions } from './base';

import { asBaseOptions } from './base';

type StringHeaderCellOptions = BaseHeaderCellOptions & {
  renderFunction?: StringRenderFunction;
};
const asStringTableHeaderCell = ({
  renderFunction,
  ...rest
}: StringHeaderCellOptions): TableHeaderDefinitionCellType<string> => {
  let options = asBaseOptions({
    ...rest,
  });

  if (renderFunction != null) {
    options = {
      ...options,
      Cell: (tableCell: TableCell<string>) =>
        renderFunction(tableCell.cell.value, tableCell),
    };
  }

  return options;
};

export default asStringTableHeaderCell;
