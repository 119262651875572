import type { ClientFlowAction } from '~/graphql/types.client';
import getChildActions from '../getChildActions';
import { uniq } from 'ramda';

type Args = {
  actions: Array<ClientFlowAction>;
  action: ClientFlowAction;
};

/**
 * Returns an array of direct children for a provided action
 * @param {Array<ClientFlowAction>} actions - Actions
 * @param {ClientFlowAction} action - Action to find direct children for
 * keywords: directChildren, children
 */
const getDirectChildActions = ({
  action: subjectAction,
  actions,
}: Args): Array<ClientFlowAction> => {
  const children = getChildActions(subjectAction, actions);

  const findChildrenRecursive = (
    children: Array<ClientFlowAction>,
    parentId: string,
  ): Array<ClientFlowAction> => {
    // Filter actions to find direct children of the parent action
    const initial = children.filter(
      child =>
        child.__typename !== 'FlowV2_Action_Start' &&
        child.parentIds[0] === parentId,
    );

    if (initial.length === 0) {
      return [];
    }

    // Recursively find further children for each direct child
    const further = initial.map(child =>
      findChildrenRecursive(children, child.id),
    );

    // Concatenate direct children with further children found recursively and make sure they are uniq
    return uniq(initial.concat(...further));
  };

  const directChildren = findChildrenRecursive(children, subjectAction.id);

  return directChildren;
};

export default getDirectChildActions;
