import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { TableCell } from '~/components/bad/DataTables/types.flow';
import { cellProps } from '../util/propGetters';
import TEST_ID from './ActionColumnCell.testid';
import Icon from '~/components/atom/Icon';

import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import type { Option } from '~/components/molecule/Dropdown';

export type ActionListOption = {
  label: string;
  onClick: (rowId: string) => void;
  key: string;
  type?: 'DELETE' | null;
};
type Props = {
  cell: TableCell<any>;
  options: Array<ActionListOption>;
  loading: boolean;
};
const ActionColumnCell: React.FCC<Props> = React.memo(
  ({ cell, options, loading }) => {
    const dotMenuRef = useRef<HTMLElement | null>(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownOptions: Array<Option> = options.map(option => ({
      label: option.label,
      key: option.key,
      payload: option.onClick,
      type: option.type === 'DELETE' ? 'DANGER' : null,
    }));

    const tdCellProps = cell.getCellProps(cellProps);

    return (
      <StyledTd
        {...tdCellProps}
        onMouseDown={e => {
          // prevents parent Link element's focus event from firing
          e.stopPropagation();
        }}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();

          if (!loading) {
            setShowDropdown(!showDropdown);
          }
        }}
        disabled={loading}
        data-testid={TEST_ID.MENU_BUTTON}
      >
        <CellContainer>
          <StyledDotMenu name="dot-menu" ref={dotMenuRef} />
          <DropdownContainer>
            <DropdownListContainer
              dropdownListOpen={showDropdown}
              options={dropdownOptions}
              onChange={({ option }) => {
                option.payload();

                setShowDropdown(false);
              }}
              onClickOutside={() => setShowDropdown(false)}
              onClose={() => setShowDropdown(false)}
              openerRef={dotMenuRef}
            />
          </DropdownContainer>
        </CellContainer>
      </StyledTd>
    );
  },
);

const CellContainer = styled.div<{}>`
  /* For the dropdown */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
`;

type StyledTdProps = {
  disabled: boolean;
};
const StyledTd = styled.div<StyledTdProps>`
  cursor: pointer;
  position: relative;

  ${({ theme, disabled }) => {
    if (disabled) {
      return css`
        color: ${theme.color('tertiary', 'dark')};
      `;
    }

    return css`
      &:hover {
        color: ${theme.color('primary', 'light')};
      }
    `;
  }}
`;

const NEGATIVE_OVERFLOW = '-0.8rem';
const StyledDotMenu = styled(Icon)<{}>(
  ({ theme }) => css`
    font-size: ${theme.space('m')};
    position: absolute;
    top: ${NEGATIVE_OVERFLOW};
    right: ${NEGATIVE_OVERFLOW};
    bottom: ${NEGATIVE_OVERFLOW};
    left: 0;

    display: flex;
    justify-content: center;
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 0.3s ease-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  `,
);

const DropdownContainer = styled.div<{}>(
  ({ theme }) => css`
    position: relative;
    width: ${theme.space('base')};
    height: ${theme.space('base')};
    margin-top: ${theme.space('base')};
  `,
);

export default ActionColumnCell;
