const DEFAULT_TITLE = 'DatHuis';

/**
 * Generates a full page title by appending the provided page title to the default title.
 *
 * @param {string} [pageTitle] - The specific title of the current page. Optional.
 * @returns {string} The complete page title in the format "DEFAULT_TITLE | pageTitle" or just "DEFAULT_TITLE" if no pageTitle is provided.
 */
const createPageTitle = (pageTitle?: string): string =>
  pageTitle ? `${DEFAULT_TITLE} | ${pageTitle}` : DEFAULT_TITLE;

export default createPageTitle;
