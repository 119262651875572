import type { Client_FlowV2_EmailAttachment } from '../../components/types';

/**
 * Checks if the new attachment is already added to pointer attachments
 */
const checkIsPointerAttachmentDuplicate = (
  attachments: Array<Client_FlowV2_EmailAttachment>,
  newAttachment: Client_FlowV2_EmailAttachment,
): boolean =>
  attachments.some(attachment => {
    if (
      attachment.file.__typename === 'Flow___Argument_Pointer' &&
      newAttachment.file.__typename === 'Flow___Argument_Pointer'
    ) {
      return (
        attachment.file?.pointer?.path.toString() ===
        newAttachment.file?.pointer?.path.toString()
      );
    }
    return false;
  });

export default checkIsPointerAttachmentDuplicate;
