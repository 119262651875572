import type { ContactFiltersCommandFragment } from '~/graphql/types';

import { sort } from 'ramda';

export type CommandMap = {
  [commandId in string]: ContactFiltersCommandFragment;
};

export type TypeIdToCommandsMap = {
  [typeId in string]: Array<ContactFiltersCommandFragment>;
};

export const generateCommandMap = (
  commands: Array<ContactFiltersCommandFragment>,
): { commandMap: CommandMap; typeIdToCommandsMap: TypeIdToCommandsMap } => {
  const typeIdToCommandsMap: TypeIdToCommandsMap = {};

  const sortedCommands = sort((a, b) => a.weight - b.weight, commands);

  const commandMap = sortedCommands.reduce((prev, curr) => {
    prev[curr.commandId] = curr;

    if (typeIdToCommandsMap[curr.typeId] == null) {
      typeIdToCommandsMap[curr.typeId] = [];
    }

    typeIdToCommandsMap[curr.typeId].push(curr);

    return prev;
  }, {} as CommandMap);

  return {
    commandMap,
    typeIdToCommandsMap,
  };
};

export const getCommand = (
  commandId: string,
  commandMap: CommandMap,
): ContactFiltersCommandFragment | undefined => commandMap[commandId];

export const getCommands = (
  typeId: string,
  typeIdToCommandsMap: TypeIdToCommandsMap,
): Array<ContactFiltersCommandFragment> => typeIdToCommandsMap[typeId] ?? [];

export default getCommand;
