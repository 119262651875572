import type { ContactFiltersTopicFragment } from '~/graphql/types';

export type TopicMap = {
  [topicId in string]: ContactFiltersTopicFragment;
};

/**
 * Generate the subject map
 */
export const generateTopicMap = (
  topics: Array<ContactFiltersTopicFragment>,
): TopicMap =>
  topics.reduce((prev, curr) => {
    prev[curr.topicId] = curr;
    return prev;
  }, {} as TopicMap);

/**
 * Get a subject, if it exists
 */
export const getTopic = (
  topicId: string,
  topicMap: TopicMap,
): ContactFiltersTopicFragment | undefined => topicMap[topicId];

export default getTopic;
