import React from 'react';
import styled, { css } from 'styled-components';

import { InvoiceStatus } from '~/graphql/types';
import { TableHeaderDefinitionCellType } from '~/components/bad/DataTables/types.flow';

import TableHeaderCell from '~/components/bad/DataTables/util/TableHeaderCell';
import { safeFormattedDate, isToday } from '~/util/date';
import { asPriceElement } from '~/util/money';
import cleanedFilename from '~/util/cleanedFilename';

import TEST_ID from './invoiceListColumns.testid';
import Icon from '~/components/atom/Icon';
import { reporter } from '~/hooks/useErrorReporter';

const text = {
  viewHeader: 'Bekijk',
};

const invoiceListColumns = (
  isCredit: boolean = false,
): Array<TableHeaderDefinitionCellType<any>> => {
  const columns: Array<TableHeaderDefinitionCellType<any>> = [
    TableHeaderCell.string({
      label: 'Nummer',
      dataProperty: 'invoiceNumber',
      isResizable: false,
      width: 150,
      dataTestId: TEST_ID.INVOICE_NUMBER,
    }),
    TableHeaderCell.date({
      label: 'Datum',
      dataProperty: 'dueDate',
      renderFunction: value => (
        <CreatedDateLabel isToday={isToday(value)}>
          {safeFormattedDate(value)}
        </CreatedDateLabel>
      ),

      isResizable: false,
      width: 100,
      maxWidth: 100,
      dataTestId: TEST_ID.DUE_DATE,
    }),
    TableHeaderCell.number({
      label: 'Bedrag',
      dataProperty: 'amount',
      renderFunction: value => asPriceElement(value),
      isResizable: false,
      width: 100,
      maxWidth: 100,
      dataTestId: TEST_ID.AMOUNT,
    }),
    TableHeaderCell.string({
      label: 'Status',
      dataProperty: 'status',
      isResizable: false,
      hideTooltip: true,
      dataTestId: TEST_ID.STATUS,
      renderFunction: renderStatus(isCredit),
      width: 100,
    }),
    TableHeaderCell.link({
      label: text.viewHeader,
      dataProperty: 'hostedInvoiceUrl',
      isResizable: false,
      width: 75,
      dataTestId: TEST_ID.HOSTED_INVOICE_URL,
    }),
    TableHeaderCell.link({
      label: 'Pdf',
      dataProperty: 'invoicePdf',
      width: 100,
      dataTestId: TEST_ID.INVOICE_PDF,
    }),
  ];

  return columns.filter(({ Header }) => {
    if (Header === text.viewHeader && isCredit === true) {
      return false;
    }
    return true;
  });
};

const renderStatus =
  (isCredit = false) =>
  (value: InvoiceStatus) => {
    if (isCredit) {
      switch (value) {
        case InvoiceStatus.Paid:
          return (
            <IconContainer>
              <PaidIcon name="check" /> Uitbetaald
            </IconContainer>
          );
        case InvoiceStatus.PaymentFailed:
          return (
            <IconContainer>
              <FailedIcon name="close" /> Betaling mislukt
            </IconContainer>
          );
        case InvoiceStatus.Open:
          return <>Wordt verwerkt</>;
        default:
          reporter.captureException(
            new Error(
              `${cleanedFilename(
                __filename,
              )} | Unknown Invoice status (${value})`,
            ),
          );
          return null;
      }
    }

    switch (value) {
      case InvoiceStatus.Paid:
        return (
          <IconContainer>
            <PaidIcon name="check" /> Betaald
          </IconContainer>
        );
      case InvoiceStatus.PaymentFailed:
        return (
          <IconContainer>
            <FailedIcon name="close" /> Mislukt
          </IconContainer>
        );
      case InvoiceStatus.Open:
        return <>Open</>;
      default:
        reporter.captureException(
          new Error(
            `${cleanedFilename(
              __filename,
            )} | Unknown Invoice status (${value})`,
          ),
        );
        return null;
    }
  };

const PaidIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('success')};
  `};
`;
const FailedIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('danger')};
  `};
`;
const IconContainer = styled.span<{}>`
  ${() => css`
    display: flex;
    align-items: center;
    & span :first-of-type {
      margin-right: 3px;
    }
  `};
`;

type CreatedDateLabelProps = {
  isToday: boolean;
};
const CreatedDateLabel = styled.span<CreatedDateLabelProps>`
  ${({ theme, isToday }) => {
    if (isToday) {
      return css`
        color: ${theme.color('success')};
      `;
    }

    return '';
  }};
`;

export default invoiceListColumns;
