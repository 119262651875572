import React from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import TEST_ID from './index.testid';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { AppValuationReportGeneral_Update } from '~/graphql/types';
import InputWithLabel from '../../components/InputWithLabel';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  title: 'Privacy',
  cookieStatement: 'Link cookiestatement',
  privacyStatement: 'Link privacystatement',
};

export type Props = RouteComponentProps & {
  dataTestId?: string;
  loading: boolean;
  updated?: AppValuationReportGeneral_Update | null;
  onUpdate: (value: AppValuationReportGeneral_Update) => void;
};

const Privacy: React.FCC<Props> = ({ updated, loading, onUpdate }) => {
  if (!updated) return null;

  const { privacyPolicy, cookiePolicy } = updated;

  return (
    <AppDetailsContainer
      header={text.title}
      icon="file-text"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <JustificationContainer direction="column" gap="base">
        <InputWithLabel label={text.cookieStatement}>
          <Input
            placeholder={text.cookieStatement}
            name="here"
            value={cookiePolicy}
            onChange={e => onUpdate({ cookiePolicy: e.target.value })}
          />
        </InputWithLabel>

        <InputWithLabel label={text.privacyStatement}>
          <Input
            placeholder={text.privacyStatement}
            name="here"
            value={privacyPolicy}
            onChange={e => onUpdate({ privacyPolicy: e.target.value })}
          />
        </InputWithLabel>
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default Privacy;
