import type { Client_WorkingAreas } from '../getClientWorkingAreas';

type ReturnType = {
  inclusive: Client_WorkingAreas;
  exclusive: Client_WorkingAreas;
};

const getCurrentWorkingAreas = (
  clientFeatures: Client_WorkingAreas,
): ReturnType => {
  const features = clientFeatures.reduce(
    (acc, curr) => {
      if (curr.feature.properties) {
        const featureKey = curr.feature.properties?.inclusive
          ? 'inclusive'
          : 'exclusive';

        if (featureKey === 'exclusive') {
          acc.exclusive.push(curr);
        } else {
          acc.inclusive.push(curr);
        }
      }
      return acc;
    },
    {
      exclusive: [],
      inclusive: [],
    } as ReturnType,
  );

  return {
    inclusive: features.inclusive,
    exclusive: features.exclusive,
  };
};

export default getCurrentWorkingAreas;
