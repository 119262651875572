import * as React from 'react';
import { Props } from '..';

// https://www.iconfinder.com/iconsets/waving-world-flags
const ENGFlag: React.FC<Props> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="18"
    viewBox="0 0 26 18"
    fill="none"
  >
    <path
      d="M0 0.900615C8.66666 4.02044 17.3333 -2.21921 26 0.900615C26 6.10032 26 11.3 26 16.4997C17.3333 13.3799 8.66666 19.6195 0 16.4997C0 11.3 0 6.10028 0 0.900615Z"
      fill="white"
    />
    <path
      d="M2.9219 1.62437C5.55872 3.58875 8.19555 4.88148 10.8324 6.02973C10.8324 4.44645 10.8324 2.86317 10.8324 1.27989C8.19555 1.71492 5.55872 2.00547 2.9219 1.62437ZM15.1668 0.521429C15.1668 2.10503 15.1668 3.68861 15.1668 5.27218C17.8042 3.25348 20.4415 1.37922 23.0789 0.176949C20.4415 -0.204349 17.8042 0.0863019 15.1668 0.521429ZM0 2.64171C0 3.79663 0 4.95151 0 6.10643C1.92337 6.7988 3.84672 7.03019 5.77009 7.00524C3.84674 5.8753 1.92337 4.489 0 2.64171ZM26 2.64265C24.0769 3.10497 22.1538 4.02814 20.2307 5.20761C22.1538 5.18273 24.0769 5.41415 26 6.10643C26 4.95183 26 3.79724 26 2.64265ZM0 11.2929C0 12.4484 0 13.604 0 14.7595C1.92413 14.2966 3.84826 13.3724 5.77239 12.1917C3.84826 12.2169 1.92416 11.9856 0 11.2929ZM20.2283 10.3941C22.1522 11.5242 24.0761 12.9108 26 14.7586C26 13.6033 26 12.4481 26 11.2929C24.0761 10.6004 22.1522 10.369 20.2283 10.3941ZM15.1668 11.3697C15.1668 12.9533 15.1668 14.5369 15.1668 16.1205C17.8037 15.6855 20.4405 15.3948 23.0773 15.7758C20.4405 13.8112 17.8037 12.5183 15.1668 11.3697ZM10.8324 12.1292C8.19607 14.1474 5.55976 16.0212 2.92345 17.2237C5.55976 17.6045 8.19607 17.3139 10.8324 16.879C10.8324 15.2957 10.8324 13.7124 10.8324 12.1292Z"
      fill="#00247D"
    />
    <path
      d="M11.6838 1.13521C11.6838 3.20859 11.6838 5.28197 11.6838 7.35531C7.78917 8.03474 3.89459 8.52282 0 7.12083C0 8.17378 0 9.22676 0 10.2797C3.89459 11.6817 7.78917 11.1936 11.6838 10.5142C11.6838 12.5876 11.6838 14.6609 11.6838 16.7343C12.5612 16.5812 13.4387 16.4184 14.3162 16.2653C14.3162 14.192 14.3162 12.1186 14.3162 10.0452C18.2108 9.36581 22.1054 8.87773 26 10.2797C26 9.22676 26 8.17378 26 7.12083C22.1054 5.71885 18.2108 6.20693 14.3162 6.88635C14.3162 4.81298 14.3162 2.7396 14.3162 0.66626C13.4387 0.819325 12.5612 0.982115 11.6838 1.13521Z"
      fill="#CF142B"
    />
    <path
      d="M0 0.900559C0 1.28654 0 1.67256 0 2.05854C2.24684 4.21664 4.49364 5.74567 6.74048 6.97185C7.39254 6.9361 8.04458 6.87489 8.69661 6.79612C5.80644 5.41001 2.91627 3.67938 0.0260988 0.909961C0.0173982 0.906806 0.0087277 0.903682 0 0.900559ZM24.0546 0.349609C21.1647 1.47669 18.2748 3.48869 15.3849 5.69158C16.0367 5.5861 16.6885 5.49034 17.3403 5.41228C20.2268 3.33331 23.1134 1.60103 26 0.906999C26 0.904852 26 0.902706 26 0.900559C25.3515 0.667123 24.7031 0.486087 24.0546 0.349609ZM17.308 10.6026C20.1979 11.9894 23.0878 13.7211 25.9777 16.4916C25.9851 16.4943 25.9926 16.497 26 16.4996C26 16.1124 26 15.7252 26 15.3379C23.7547 13.1813 21.5094 11.6529 19.2641 10.4272C18.6121 10.4628 17.9601 10.5239 17.308 10.6026ZM8.65361 11.996C5.76905 14.0733 2.88456 15.8039 0 16.4978C0 16.4984 0 16.499 0 16.4996C0.651027 16.734 1.30204 16.9155 1.95304 17.0522C4.83836 15.9251 7.72365 13.9164 10.609 11.717C9.95718 11.8224 9.3054 11.918 8.65361 11.996Z"
      fill="#CF142B"
    />
  </svg>
);

export default ENGFlag;
