import React from 'react';
import styled, { css } from 'styled-components';
import illustrations from '~/components/atom/Illustration/components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading3, Heading5, Variant } from '~/components/atom/Typography';
import Illustration from '~/components/atom/Illustration';
import { WidgetSettingsStyles } from '~/graphql/types';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

export type Props = {
  dataTestId?: string;
  header: React.ReactNode;
  description?: React.ReactNode;
  illustration?: keyof typeof illustrations;
  colors?: Omit<WidgetSettingsStyles, '__typename'>;
} & React.HtmlHTMLAttributes<HTMLDivElement>;

const ILLUSTRATION_SIZE = 64;
const HighlightedHeader: React.FC<Props> = ({
  dataTestId,
  header,
  description,
  illustration,
  colors,
  ...rest
}) => (
  <Container
    $background={colors?.primary.background}
    data-testid={dataTestId}
    {...rest}
  >
    <JustificationContainer align="center">
      <StyledIllustration
        color={colors?.primary.color}
        name={illustration || 'valuation-report'}
      />
      <JustificationContainer
        direction="column"
        margin={[null, null, null, 'base']}
      >
        <StyledHeading3
          $color={colors?.primary.color}
          variant={Variant.primary}
        >
          {header}
        </StyledHeading3>
        {description && (
          <StyledHeading5
            $color={colors?.primary.color}
            fontWeight="regular"
            margin={[null]}
          >
            {description}
          </StyledHeading5>
        )}
      </JustificationContainer>
    </JustificationContainer>
  </Container>
);

const StyledHeading3 = styled(Heading3)<{ $color?: string }>(
  ({ $color, theme }) => css`
    color: ${$color};
    font-size: ${FONT_SIZE_OF_18}px;
    line-height: ${theme.lineHeight('base')};
  `,
);
const StyledHeading5 = styled(Heading5)<{ $color?: string }>(
  ({ $color, theme }) => css`
    color: ${$color};
    line-height: ${theme.lineHeight('base')};
  `,
);

const Container = styled.div<{ $background?: string }>(
  ({ theme, $background }) => css`
    background-color: ${$background};
    padding: ${theme.space('m')};
    border-radius: ${theme.getTokens().border.radius.base}
      ${theme.getTokens().border.radius.base} 0 0;
    height: 111px;
  `,
);

const StyledIllustration = styled(Illustration)<{}>(
  ({}) => css`
    svg {
      height: ${ILLUSTRATION_SIZE}px;
      width: ${ILLUSTRATION_SIZE}px;
    }
  `,
);

export default HighlightedHeader;
