import React from 'react';
import ImageUploadComponent from '~/components/organism/ImageUploadComponent';
import useImageUpload from '~/hooks/useImageUpload';
import Div from '~/components/atom/Div';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';

export type Props = {
  initialUrl?: string | null;
  title?: string;
  description?: string;
  s3Key?: string;
  filename: string;
  readOnly?: boolean;
  onChange: (arg?: { s3key: string; url: string }) => void;
  deletable?: boolean;
  required?: boolean;
};

const ImageInput: React.FCC<Props> = ({
  dataTestId,
  initialUrl,
  title,
  s3Key,
  filename,
  readOnly,
  onChange,
  deletable = true,
  required = false,
}) => {
  const fallbackImage = useImageUpload({
    initialUrl,
    s3Key,
    onUpload: ({ s3Key, url }) => {
      if (onChange) onChange({ s3key: s3Key, url });
    },
    onClear: () => onChange && onChange(),
  });

  return (
    <Div width="100%">
      {title && (
        <AppEntryItemHeader required={required}>{title}</AppEntryItemHeader>
      )}
      <ImageUploadComponent
        onDelete={deletable ? () => fallbackImage.clear() : undefined}
        uploading={fallbackImage.uploading}
        imageUrl={fallbackImage.s3Key != null ? fallbackImage.url : null}
        onUpload={file =>
          void fallbackImage.upload({ file: file[0], filename })
        }
        dataTestId={dataTestId}
        disabled={readOnly}
      />
    </Div>
  );
};

export default ImageInput;
