import { LinkElement } from '~/components/organism/PluginsEditor/types';
import updateAttrsAndConvertToString from '~/components/organism/PluginsEditor/utils/serialize/updateAttrsAndConvertToString';

/**
 * serializes the LinkElement into html element
 * @param {LinkElement} node -LinkElement
 */
const serialize = (node: LinkElement, children: string): string => {
  const attrs = updateAttrsAndConvertToString(
    {
      style: {
        display: 'inline',
      },
      href: node.url,
      target: '_blank',
    },
    node.attrsToPassThrough,
  );

  return `<a${attrs ? ` ${attrs}` : ''}>${children}</a>`;
};

export default serialize;
