import { omit } from 'ramda';
import { SetterOrUpdater } from 'recoil';
import { ClientFlowAction } from '~/graphql/types.client';
import { IssueMap } from '~/components/page/Automation/v2/state/flowIssues';
import deleteAction from './utils/deleteAction';

const onDeleteAction = (
  subjectAction: Exclude<
    ClientFlowAction,
    { __typename: 'FlowV2_Action_IfElse' }
  >,
  setActions: SetterOrUpdater<Array<ClientFlowAction>>,
  setIssues: SetterOrUpdater<IssueMap>,
  setLastDeletedActionId: (parentId: string | null) => void,
): void => {
  let lastDeletedActionId: string | null = null;

  setActions(actions => {
    const { actions: updatedActions, nextActionId } = deleteAction({
      actions,
      subjectAction,
    });

    lastDeletedActionId = nextActionId;

    return updatedActions;
  });
  setIssues(prev => omit([subjectAction.id], prev));
  setLastDeletedActionId(lastDeletedActionId);
};

export default onDeleteAction;
