import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading3 } from '~/components/atom/Typography';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import {
  type BrandSettings,
  type BrandSettingsField,
} from '~/hooks/useBrandSettings';
import BrandSettingsContent from './components/BrandSettingsContent';

const text = {
  title: 'Huisstijl toepassen',
  description: (
    <>
      Pas je huisstijl toe in deze app. Klopt de huisstijl niet, of wil je deze
      wijzigen?{' '}
      <a target="_blank" href="/-/settings/brand" rel="noreferrer noopener">
        Pas de huisstijl instellingen hier aan.
      </a>{' '}
      Vervolgens kan je deze hier toepassen.
    </>
  ),
};
export type SelectedBrandSettings = {
  logo?: BrandSettings['logo'];
  url?: BrandSettings['url'];
  colors?: BrandSettings['colors'];
};

export type Props = {
  subjects: $NonEmptyArray<BrandSettingsField>;
  onClose: () => void;
  onConfirm: (values: SelectedBrandSettings) => void;
};

const MODAL_MAX_WIDTH = '500px';
const BrandSettingsModal: React.FCC<Props> = ({ onClose, ...rest }) => (
  <Overlay onClose={onClose}>
    <Modal onClose={onClose} maxWidth={MODAL_MAX_WIDTH}>
      <JustificationContainer
        direction="column"
        padding={['xxl', 'l', 'l', 'l']}
        width="100%"
      >
        <Heading3>{text.title}</Heading3>
        <Body margin={[null, null, 'l', null]}>{text.description}</Body>
        <BrandSettingsContent {...rest} onClose={onClose} />
      </JustificationContainer>
    </Modal>
  </Overlay>
);

export default BrandSettingsModal;
