import { type PasswordConditions } from '~/components/page/Auth/components/PasswordRules';
import { useState } from 'react';
import Catalog from '~/Catalog';
import Validation from '~/util/Validation';

const text = {
  noPassword: Catalog.noPassword,
  invalidPassword: Catalog.invalidPassword,
  noPasswordRepeat: Catalog.noPasswordRepeat,
  passwordsDoNotMatch: Catalog.passwordsDoNotMatch,
};

export type PasswordArgs = {
  password?: string | null;
  passwordRepeat?: string | null;
};

export type PasswordErrors = {
  password: string | undefined;
  passwordRepeat: string | undefined;
};

type ReturnType = {
  passwordConditions: PasswordConditions;
  validatePassword: (args: PasswordArgs) => PasswordErrors;
};

const usePasswordValidation = (): ReturnType => {
  const errors: PasswordErrors = {
    password: undefined,
    passwordRepeat: undefined,
  };

  const [passwordConditions, setPasswordConditions] =
    useState<PasswordConditions>({
      charNumberValid: false,
      uppercaseValid: false,
      lowercaseValid: false,
      specialCharValid: false,
      numberValid: false,
    });

  const validate = ({ password, passwordRepeat }: PasswordArgs) => {
    const didEnterPassword = Validation.String.isNonEmptyString(password);
    const didRepeatPassword =
      Validation.String.isNonEmptyString(passwordRepeat);

    if (!didEnterPassword) {
      errors.password = text.noPassword;
    }
    if (!didRepeatPassword) {
      errors.passwordRepeat = text.noPasswordRepeat;
    }

    if (didEnterPassword && didRepeatPassword) {
      if (password !== passwordRepeat) {
        errors.passwordRepeat = text.passwordsDoNotMatch;
      } else {
        errors.passwordRepeat = undefined;
      }
    }

    const charNumberValid =
      password && Validation.Password.satisfiesLength(password) ? true : false;
    const uppercaseValid =
      password && Validation.Password.containsUpperCaseLetter(password)
        ? true
        : false;
    const lowercaseValid =
      password && Validation.Password.containsLowerCaseLetter(password)
        ? true
        : false;
    const specialCharValid =
      password && Validation.Password.containsSpecialCharacter(password)
        ? true
        : false;
    const numberValid =
      password && Validation.Password.containsNumber(password) ? true : false;

    if (
      charNumberValid !== passwordConditions.charNumberValid ||
      uppercaseValid !== passwordConditions.uppercaseValid ||
      specialCharValid !== passwordConditions.specialCharValid ||
      lowercaseValid !== passwordConditions.lowercaseValid ||
      numberValid !== passwordConditions.numberValid
    ) {
      setPasswordConditions({
        charNumberValid,
        uppercaseValid,
        specialCharValid,
        lowercaseValid,
        numberValid,
      });
    }

    if (
      !charNumberValid ||
      !uppercaseValid ||
      !lowercaseValid ||
      !specialCharValid ||
      !numberValid
    ) {
      errors.password = text.invalidPassword;
    }

    return errors;
  };

  return {
    passwordConditions,
    validatePassword: validate,
  };
};

export default usePasswordValidation;
