import { startOfDay } from 'date-fns';
import {
  ContactFiltersInputAppearances,
  ContactFiltersOptionsInputAppearances,
  ContactFiltersOptionTypeFragment,
  ContactFiltersTypeFragment,
} from '~/graphql/types';
import Validation from '~/util/Validation';
import { isNonEmptyString } from '~/util/Validation/String';
import { isDateObjectAndValid } from '~/util/date';

const text = {
  invalidTag: 'Ongeldige tag',
  invalidContactId: 'Ongeldige contact id',
  invalidPostcode: 'Ongeldige postcode',
  invalidText: 'Ongeldige tekst',
  invalidInteger: 'Ongeldig nummer',
  invalidEmail: 'Ongeldige e-mail',
  invalidPhone: 'Ongeldig telefoonnummer',
  invalidSelection: 'Ongeldige selectie',
  invalidDate: 'Ongeldige datum',
  invalidFilter: 'Ongeldig filter',
};

const formatValue = (
  value: string | number | boolean | null | undefined,
  input: ContactFiltersTypeFragment | ContactFiltersOptionTypeFragment,
) => {
  switch (input.inputAppearance) {
    case ContactFiltersInputAppearances.ContactId: {
      const isValid = Validation.ContactId.isValid(value);
      return {
        formattedValue: value,
        invalidInputDescription: isValid ? null : text.invalidContactId,
      };
    }

    case ContactFiltersInputAppearances.Tag: {
      const formattedValue = isNonEmptyString(value)
        ? Validation.Tag.format(value)
        : null;

      const isValid = Validation.Tag.isValid(formattedValue);
      return {
        formattedValue,
        invalidInputDescription: isValid ? null : text.invalidTag,
      };
    }
    case ContactFiltersInputAppearances.Postcode: {
      const formattedValue = isNonEmptyString(value)
        ? Validation.Postcode.parsePostalCode(value)
        : value;
      const isValid = Validation.Postcode.isValid(formattedValue);
      return {
        formattedValue,
        invalidInputDescription: isValid ? null : text.invalidPostcode,
      };
    }
    case ContactFiltersInputAppearances.Text: {
      const isValid = Validation.String.isString(value);
      return {
        formattedValue: value,
        invalidInputDescription: isValid ? null : text.invalidText,
      };
    }
    case ContactFiltersInputAppearances.Integer: {
      if (Validation.Number.isInteger(value)) {
        return {
          formattedValue: value,
          invalidInputDescription: null,
        };
      }

      if (value == null || !Validation.String.isString(value)) {
        return {
          formattedValue: value?.toString(),
          invalidInputDescription: text.invalidInteger,
        };
      }

      const formattedValue = parseInt(value, 10);
      const isValid = Validation.Number.isNumber(formattedValue);
      return {
        formattedValue,
        invalidInputDescription: isValid ? null : text.invalidInteger,
      };
    }
    case ContactFiltersInputAppearances.Boolean: {
      return {
        formattedValue: !!value,
        invalidInputDescription: null,
      };
    }
    case ContactFiltersInputAppearances.Email: {
      if (value != null && !Validation.String.isString(value)) {
        return {
          formattedValue: value.toString(),
          invalidInputDescription: text.invalidEmail,
        };
      }
      const isValid = Validation.Email.isValid(value);
      return {
        formattedValue: value?.toLowerCase(),
        invalidInputDescription: isValid ? null : text.invalidEmail,
      };
    }
    case ContactFiltersInputAppearances.Phone: {
      const isValid = isNonEmptyString(value);

      return {
        formattedValue: value,
        invalidInputDescription: isValid ? null : text.invalidPhone,
      };
    }

    case ContactFiltersOptionsInputAppearances.List: {
      const selectedOptionIdx = input.options.findIndex(
        option => option.value === value,
      );
      const isSelectedOptionValid =
        value !== undefined && selectedOptionIdx === -1 ? false : true;

      return {
        formattedValue: value,
        invalidInputDescription: isSelectedOptionValid
          ? null
          : text.invalidSelection,
      };
    }

    case ContactFiltersInputAppearances.Date: {
      if (
        value == null ||
        !Validation.String.isString(value) ||
        !isDateObjectAndValid(new Date(value))
      ) {
        return {
          formattedValue: value,
          invalidInputDescription: text.invalidDate,
        };
      }

      const asDate =
        value !== null && Validation.String.isString(value)
          ? new Date(value)
          : value;

      return {
        formattedValue: startOfDay(asDate).toISOString(),
        invalidInputDescription: null,
      };
    }
    default:
      return {
        formattedValue: value,
        invalidInputDescription: null,
      };
  }
};

export default formatValue;
