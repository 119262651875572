import { useEffect } from 'react';

/**
 * Based on: https://stackoverflow.com/questions/53090432/react-hooks-right-way-to-clear-timeouts-and-intervals
 *
 * functionToCall - the function to call after the delay
 * delay - after how many milliseconds
 */
const useTimeout = (functionToCall: Function, delay: number) => {
  useEffect(() => {
    const timer = setTimeout(functionToCall, delay);

    // returning a function causes it to be called on unmount: https://reactjs.org/docs/hooks-effect.html#example-using-hooks-1
    return () => {
      clearTimeout(timer);
    };
  });
};

export default useTimeout;
