import convertTemplateStringToSlateFragment from '~/components/organism/PluginsEditor/utils/flows/convertTemplateStringToSlate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import useRelativeMaps from '../useRelativeMaps';
import { Element, Text } from 'slate';
import { uniqBy, unnest } from 'ramda';
import type { ClientFlowActionSendEmailPlain } from '~/graphql/types.client';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';

type LinkData = {
  url: string;
  text: string;
};

/**
 * Returns an array of Links that are added in the DefaultTextEditor
 * @param {ClientFlowActionSendEmailPlain} action - ClientFlowActionSendEmailPlain
 * keywords: get links from Send Email Plain action
 */
const useLinksFromSendEmailAction = (
  action: ClientFlowActionSendEmailPlain,
): Array<LinkData> => {
  const maps = useRelativeMaps({ actionId: action.id });

  const initialBodyValue = convertTemplateStringToSlateFragment({
    html: action.body.template || '',
    mappings: action.body.mappings,
    attachments: action.attachments,
    ...maps,
    customElements: [ELEMENTS.IMAGE, ELEMENTS.DH_IMAGE],
  });

  return getAllLinks(initialBodyValue);
};

const getAllLinks = (value: EditorValue) =>
  uniqBy(item => item.url, getLinks(value));

const getLinks = (nodes: EditorValue): Array<LinkData> =>
  nodes.reduce((prev, node) => {
    if (Element.isElement(node) && node.type === ELEMENTS.LINK) {
      return [
        ...prev,
        {
          text: node.children[0].text,
          url: node.url,
        },
      ];
    }

    if (!Text.isText(node) && node.children) {
      const childNode = unnest(getLinks(node.children));

      if (childNode.length > 0) return [...prev, ...childNode];
    }

    return prev;
  }, [] as Array<LinkData>);

export default useLinksFromSendEmailAction;
