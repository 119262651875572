import { useCallback, useEffect, useRef, useState } from 'react';
import useAddToast from '../useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import useErrorReporter from '../useErrorReporter';

const text = {
  popupAlreadyOpenWarning: 'Er is al een pop-up venster geopend.',
  popupBlockedError:
    'Er is iets misgegaan bij het openen van het pop-up venster. Probeer het opnieuw. Als het blijft gebeuren, neem contact met ons op.',
};

// Use to test the popup window without going through the whole flow
// const testUrl = '/-/external/nylas/auth/v1';

/**
 * 
 * Here we cannot use isNil or any other curried functions. Which might attempt to access properties of the `Window` of the popup.
 * Accessing **ANY** property on the `Window` besides `closed`, `close`, `blur` and `focus` because that will break browser security protocols.

 * https://github.com/ramda/ramda/blob/master/source/isNil.js#L21
 * https://github.com/ramda/ramda/blob/master/source/internal/_curry1.js#L14C35-L14C49
 * https://github.com/ramda/ramda/blob/master/source/internal/_isPlaceholder.js#L4
 * https://stackoverflow.com/a/58618249
*/

type Args = {
  /*
   * The URL we open. Either pass url here or within `open()` function. `openUrl` takes precedence over `url`
   */
  url?: string;

  /**
   * the method which gets called when the popup gets closed
   */
  onClose?: () => void;

  /**
   * the method which gets called when the popup gets opened
   */
  onOpen?: () => void;

  /**
   * Overwrites popup window's default size and position
   */
  features?: {
    width?: number;
    height?: number;
    left?: number;
    top?: number;
  };
};

type OpenOptions = {
  url: string;
};

type ReturnType = {
  /** Opens the popup window with `openUrl` if it is passed otherwise uses `url` to open it */
  open: (opts?: { url: string }) => void;

  /** Callback to close the popup window */
  close: () => void;

  /** Is the popup currently open */
  isOpen: boolean;
};

const useNativePopup = ({
  onOpen,
  onClose,
  url,
  features,
}: Args): ReturnType => {
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const addToast = useAddToast();
  const errorReporter = useErrorReporter();

  const width = 600;
  const height = 600;
  const left = (screen.width - width) / 2;
  const defaultFeatures = { width, height, top: 0, left: -left };

  const featuresStr = Object.entries({
    ...defaultFeatures,
    ...(features ?? {}),
  })
    .map(([key, value]) => `${key}=${value}`)
    .join(',');

  const [popupState, setPopupState] = useState<Window | null>(null);

  const open = (opts?: OpenOptions) => {
    try {
      if (popupState !== null && !popupState.closed) {
        return popupState.focus();
      }

      const popup = window.open(opts?.url ?? url, '_blank', featuresStr);
      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        return addToast([formatToastMessage(text.popupBlockedError, 'danger')]);
      }

      setPopupState(popup);

      if (onOpen) onOpen();
    } catch (error) {
      errorReporter.captureException(
        new Error(
          `Error happened in useNativePopup in 'open' fn: ${JSON.stringify(
            error,
          )}`,
        ),
        'warning',
      );
    }
  };

  const close = useCallback(() => {
    try {
      if (popupState) popupState.close();
    } catch (error) {
      errorReporter.captureException(
        new Error(
          `Error happened in useNativePopup in 'close' fn: ${JSON.stringify(
            error,
          )}`,
        ),
        'warning',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupState]);

  useEffect(() => {
    try {
      if (popupState) {
        timerRef.current = setInterval(() => {
          if (popupState && popupState.closed) {
            clearInterval(timerRef.current);
            setPopupState(null);
            if (onClose) onClose();
          }
        }, 500);
      }
    } catch (error) {
      errorReporter.captureException(
        new Error(
          `Error happened in useNativePopup in 'useEffect': ${JSON.stringify(
            error,
          )}`,
        ),
        'warning',
      );
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, popupState]);

  const initialPath = window?.location?.pathname;

  useEffect(
    () => () => {
      // Ensure that the popup remains open if the parent component unmounts for any other reason than navigation away.
      // Scenarios when transitioning to the success state displays a different component other than the parent component
      const pathHasChanged = window?.location?.pathname !== initialPath;

      if (popupState && pathHasChanged) popupState.close();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [popupState],
  );

  return {
    open,
    close,
    isOpen: popupState !== null,
  };
};

export default useNativePopup;
