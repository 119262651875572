/**
 * Returns the image url from the image stash if the s3key for that image exists
 */

const saveGet =
  (stash: { [s3Key: string]: string }) =>
  (key?: string): string | undefined =>
    key ? stash[key] : undefined;

export default saveGet;
