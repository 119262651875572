import { v4 as uuid } from 'uuid';
import isSelectTypeBlock from '~/components/page/Forms/components/Builder/utils/typeguards/isSelectTypeBlock';
import type { FormBuilder_ScreenNode } from '~/graphql/types';
import generateIdForEntity from '~/util/generateIdForEntity';
import type { OptionListForBlockLookup } from '../getDuplicatedOptionLists';
import { isNil } from 'ramda';
import cleanedFilename from '~/util/cleanedFilename';
import { reporter } from '~/hooks/useErrorReporter';

/**
 * Copies an existing node, updates the ids and keys to make them unique
 * @param {FormBuilder_ScreenNode} node - Node to be copied
 * @param {OptionListForBlockLookup} optionListForBlockLookup - Lookup to add optionListIds to Select blocks
 */
const copyScreenNode = ({
  node,
  optionListForBlockLookup,
  isBasicForm,
}: {
  node: FormBuilder_ScreenNode;
  optionListForBlockLookup: OptionListForBlockLookup | null;
  isBasicForm: boolean;
}): FormBuilder_ScreenNode => {
  if (!node) {
    reporter.captureException(
      new Error(
        `${cleanedFilename(__filename)} | Should not occur | No node is passed`,
      ),
    );
    // no need to pass error messages, it will be handled in the parent
    throw new Error();
  }

  return {
    ...node,
    id: generateIdForEntity('FORMBUILDER_SCREEN_NODE'),
    name: `Kopie van ${node.name}`,
    blocks: node.blocks.map(block => {
      if (isBasicForm && isSelectTypeBlock(block)) {
        if (isNil(optionListForBlockLookup)) {
          reporter.captureException(
            new Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | Option list needs a lookup table`,
            ),
          );
          throw new Error();
        }

        if (isNil(optionListForBlockLookup[block.optionListId])) {
          reporter.captureException(
            new Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | Select block does not have an option list id`,
            ),
          );
          throw new Error();
        }

        return {
          ...block,
          key: uuid(),
          optionListId:
            optionListForBlockLookup[block.optionListId].newOptionListId,
        };
      }

      return { ...block, key: uuid() };
    }),
  };
};

export default copyScreenNode;
