import { v4 as uuid } from 'uuid';
import React from 'react';
import {
  FormBuilder_PrimitiveType,
  type FormBuilder_Event,
  type FormBuilder_Event_Field,
} from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';

import Button from '~/components/atom/Button';
import styled, { css } from 'styled-components';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import type { EditProps } from '../..';
import {
  useRecoilState,
  useSetRecoilState,
  type SetterOrUpdater,
} from 'recoil';
import { interactionState, type EditEventInteraction } from '../../../../state';
import { eventById, nodesSelector } from '../../../../state/nodesAndEvents';
import NoEventSelected from './components/NoEventSelected';
import EditableText from '~/components/organism/EditableText';
import FieldEditor from './components/FieldEditor';
import TipBanner from '~/components/organism/TipBanner';
import TextButton from '~/components/atom/TextButton';
import Icon from '~/components/atom/Icon';

export type Props = EditProps<any>;

const text = {
  name: 'Nieuwe event template',
  explainEvents: `Definieer de velden die u wilt ontvangen in het contactpagina-evenement.`,
  explainEventsHeader: 'Wat zijn gebeurtenissen?',
};

const EditEvent: React.FCC<Props> = () => {
  const [interaction, setInteraction] = useRecoilState(interactionState) as [
    EditEventInteraction,
    SetterOrUpdater<EditEventInteraction | null>,
  ];

  const [event, setEvent] = useRecoilState(
    eventById(interaction?.subjectId ?? ''),
  );
  const setNodesState = useSetRecoilState(nodesSelector);

  const eventId = interaction?.subjectId;

  if (!eventId) return <NoEventSelected />;

  const onDeleteField = (field: FormBuilder_Event_Field) => {
    setEvent((prev: FormBuilder_Event) => ({
      ...prev,
      fields: prev?.fields.filter(currField => currField.key !== field.key),
    }));

    // Delete all of the mapping items in Event nodes related to the deleted event template field
    setNodesState(prev =>
      prev.map(node => {
        if (node.__typename === 'FormBuilder_ScreenNode') return node;

        return {
          ...node,
          mapping: node.mapping.filter(m => m.key !== field.key),
        };
      }),
    );
  };

  return (
    <Container padding={['xl']} width="100%" gap="m" direction="column">
      <JustificationContainer align="center" gap="m">
        <Icon name="clock" size="l" />
        <EditableText
          as="h1"
          text={event?.name ?? text.name}
          onSave={nextName => {
            setEvent(prev => {
              if (!prev) return prev;

              return { ...prev, name: nextName };
            });
          }}
        />
      </JustificationContainer>
      <TipBanner
        headerText={text.explainEventsHeader}
        id="explain-events-event-editor"
        width="100%"
      >
        {text.explainEvents}
      </TipBanner>
      <JustificationContainer
        direction="column"
        gap="l"
        height="100%"
        width="100%"
      >
        {event?.fields.map(field => (
          <FieldEditor
            key={field.key}
            field={field}
            eventId={eventId}
            onDelete={onDeleteField}
          />
        ))}

        <JustificationContainer margin={[null, null, 'xl', null]}>
          <Button
            label="Nieuw veld toevoegen"
            icon="plus"
            onClick={() => {
              setEvent(prev => {
                if (prev) {
                  return {
                    ...prev,
                    fields: [
                      ...prev.fields,
                      {
                        __typename: 'FormBuilder_Event_Field',
                        key: uuid(),
                        name: '',
                        type: {
                          __typename: 'FormBuilder_Event_Field_Type_Primitive',
                          type: FormBuilder_PrimitiveType.String,
                        },
                      },
                    ],
                  };
                }
                return prev;
              });
            }}
          />
        </JustificationContainer>
        <JustificationContainer
          align="center"
          justification="space-between"
          width="100%"
        >
          <TextButton
            padding={[null]}
            label="Annuleren"
            appearance="danger"
            onClick={() => setInteraction(null)}
          />
          <Button
            label="Opslaan en sluiten"
            appearance="secondary"
            onClick={() => setInteraction(null)}
          />
        </JustificationContainer>
      </JustificationContainer>
    </Container>
  );
};

const Container = styled(JustificationContainer)<{}>`
  ${({ theme }) => css`
    border-left: 1px solid ${theme.color('tertiary', 'light')};
    flex-shrink: 0;

    overflow-y: scroll;
    ${scrollBarStyles}
  `};
`;

export default EditEvent;
