import { useMemo } from 'react';
import useSessionHydration from '../useSessionHydration';
import { LimitFragment, LimitId } from '~/graphql/types';

export type LimitMap = Record<LimitId, LimitFragment>;
const useLimitsMap = () => {
  const [{ limits }] = useSessionHydration();

  const limitMap = useMemo(
    () =>
      (limits ?? []).reduce((acc, curr) => {
        acc[curr.id as LimitId] = curr;
        return acc;
      }, {} as LimitMap),
    [limits],
  );

  return limitMap;
};

export default useLimitsMap;
