import React, { Component } from 'react';

import RouterTransitionContext from './RouterTransitionContext';

type Props = {
  children: React.ReactNode;
};

type State = {
  canLeave: boolean;
  leavePageConfirmDialogOpen: boolean;
  nextRoute: string | null | undefined;
};

export default class RouterTransitionContextProvider extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      canLeave: true,
      leavePageConfirmDialogOpen: false,
      nextRoute: null,
    };
  }

  leaveHandler = (confirm: boolean, cb?: () => void) => {
    const getStateObj = (prevState: State): State => {
      if (confirm) {
        return {
          canLeave: confirm,
          leavePageConfirmDialogOpen: false,
          nextRoute: null,
        };
      } else {
        return {
          leavePageConfirmDialogOpen: prevState.leavePageConfirmDialogOpen,
          nextRoute: prevState.nextRoute,
          canLeave: confirm,
        };
      }
    };

    if (typeof cb === 'function') {
      this.setState(getStateObj, cb);
    } else {
      this.setState(getStateObj);
    }
  };

  setNextRoute = (nextRoute: string) => {
    this.setState({
      nextRoute: nextRoute,
    });
  };

  setLeavePageConfirmDialogOpen = (
    leavePageConfirmDialogOpen: boolean,
    cb?: () => void,
  ) => {
    this.setState({ leavePageConfirmDialogOpen }, cb);
  };

  render() {
    const { leavePageConfirmDialogOpen, canLeave, nextRoute } = this.state;
    const { children } = this.props;

    return (
      <RouterTransitionContext.Provider
        value={{
          canLeave,
          leavePageConfirmDialogOpen,
          nextRoute,
          setLeavePageConfirmDialogOpen: this.setLeavePageConfirmDialogOpen,
          leaveHandler: this.leaveHandler,
          setNextRoute: this.setNextRoute,
        }}
      >
        {children}
      </RouterTransitionContext.Provider>
    );
  }
}
