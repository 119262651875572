import React, { useState } from 'react';
import InputWithButtons from '../InputWithButtons';
import type { Size } from '~/styles/constants';
import DeletableItems from '../DeletableItems';
import type { IconType } from '~/components/atom/Icon';
import getValidationErrors, {
  type ValidationFunction,
} from '~/util/getValidationErrors';
import { isEmpty } from 'ramda';

type InputValue = string;

const text = {
  add: 'Toevoegen',
};

export type Props = {
  selectedValues: Array<InputValue>;

  /** Button props */
  addButton?: {
    label: string;
    icon?: IconType;
  };

  /** Sets both input and DeletableItem size */
  size?: Size;

  /** Label of the input */
  label: string;

  /** Is the input disabled */
  disabled?: boolean;

  /** Input validation */
  validation?: Array<ValidationFunction>;

  /** Used to enforce a specific format for the value */
  formatFunction?: (value: InputValue) => InputValue;

  /** Callback to add a new value to the list */
  onAdded: (value: InputValue) => void;

  /** Callback to remove a value from the list */
  onRemoved: (value: InputValue) => void;

  /**
   * Makes it controlled from the parent component.
   * Must be passed together with onChange prop
   */
  inputValue?: InputValue;

  /**
   * Makes it controlled from the parent component.
   * Must be passed together with inputValue prop
   */
  onChange?: (value: InputValue) => void;
};

const MultiValueInput: React.FCC<Props> = ({
  selectedValues,
  addButton,
  onChange,
  onRemoved,
  onAdded,
  formatFunction,
  disabled,
  validation,
  size = 'medium',
  label,
  inputValue,
  ...rest
}) => {
  const [currentValue, setCurrentValue] = useState<string>('');

  const _value = inputValue ? inputValue : currentValue;
  const _onChange = onChange ? onChange : setCurrentValue;

  const onAdd = () => {
    if (selectedValues.includes(_value)) {
      _onChange('');
      return;
    }

    const errors = validation ? getValidationErrors(validation, _value) : [];

    if (errors.length === 0) {
      onAdded(_value);
      _onChange('');
    }
  };

  return (
    <>
      <DeletableItems
        size={size}
        selectedValues={selectedValues}
        onRemoved={onRemoved}
      />
      <InputWithButtons
        width="100%"
        label={{ text: label }}
        onKeyDown={e => {
          if (e.key === 'Enter' && _value !== '') {
            e.preventDefault();
            onAdd();
          }
        }}
        onChange={e => {
          const target = e.target as HTMLInputElement;

          _onChange(
            formatFunction ? formatFunction(target.value) : target.value,
          );
        }}
        buttonActions={[
          {
            onClick: onAdd,
            icon: addButton?.icon || 'plus',
            label: addButton?.label || text.add,
            dataTestId: 'add-item-button',
            disabled: isEmpty(_value),
            appearance: 'success',
          },
        ]}
        value={_value}
        disabled={disabled}
        validation={validation}
        size={size}
        {...rest}
      />
    </>
  );
};

export default MultiValueInput;
