import React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import { SpanElement } from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import serialize from './serialize';
import styleToJS from 'style-to-js';
import { styleToJSOptions } from '~/components/organism/PluginsEditor/utils/deserialize/utils/convertDOMAttrsToReactProps/utils';

export type Props = RenderElementProps & {
  element: SpanElement;
};

const Span: React.FCC<Props> = ({ attributes, element, children }) => (
  <Container
    {...attributes}
    {...element.attributes}
    style={{
      ...(element.attributes?.style ?? {}),
      display: 'inline',
      color: element.color ?? element.attributes?.style?.color,
      backgroundColor:
        element.backgroundColor ?? element.attributes?.style?.backgroundColor,
      fontSize: element.fontSize ?? element.attributes?.style?.fontSize,
    }}
  >
    {children}
  </Container>
);

const Container = styled.span<{}>``;

export default {
  renderComponent: props => <Span {...props} />,
  nodeName: 'SPAN',
  deserialize: el => {
    const styleAttr = styleToJS(el.getAttribute('style'), styleToJSOptions);

    return {
      type: ELEMENTS.SPAN,
      color: styleAttr?.color,
      backgroundColor: styleAttr?.backgroundColor,
      fontSize: styleAttr?.fontSize,
    };
  },
  serialize,
};
