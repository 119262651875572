import React, { useState } from 'react';
import Button from '~/components/atom/Button';
import Catalog from '~/Catalog';
import TEST_ID from './index.testid';
import { Highlight } from '~/components/atom/Typography';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { useDeleteUserFromOfficeMutation } from '~/graphql/types';
import getUserName from '~/util/getUserName';
import useUser from '~/hooks/useUser';
import useUserLookup from '~/hooks/useUserLookup';
import useSessionHydration from '~/hooks/useSessionHydration';
import TextButton from '~/components/atom/TextButton';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import {
  getErrorMessageForModal,
  convertBackendErrorMessage,
} from '../../../utils';
import { DELETE_ENTITY_TYPE, DELETION_TYPE } from '../../../utils/constants';
import hasValidOffice from '../../../utils/hasValidOffice';
import hasValidUser from '../../../utils/hasValidUser';
import BottomButtonRow from '../components/BottomButtonRow';
import {
  ModalContainer,
  ListContainer,
  SubHeaderContainer,
  FromContainer,
  ToContainer,
  ErrorMessageContainer,
} from '../components/DeleteEntityModal';
import ListItemCard, {
  type ListItemForDeletion,
  type MigrationItem,
} from '../components/ListItemCard';
import TopSectionContainer from '../components/TopSectionContainer';

const text = {
  headerText: (
    <>
      Gebruiker verwijderen uit een <Highlight>vestiging</Highlight>
    </>
  ),
  cancelButtonLabel: 'Annuleren',
  successButtonLabel: 'Toewijzen en verwijderen',
  description:
    'Wijs contacten en openstaande taken toe aan een andere gebruiker',
  mutationError:
    'Er is iets mis gegaan bij het toewijzen van contacten en openstaande taken. Probeer het later nog eens.',
};

type Props = {
  list: Array<ListItemForDeletion>;

  /** When the modal is closed */
  onClose: () => void;

  /** When the cancel button is pressed */
  onCancel: () => void;

  /** To refetch GetUser query */
  refetch?: () => Promise<any>;

  /** If passed from users/userId it is user.id otherwise me.id */
  userId: string;
};
const DeleteUserFromOfficeModal: React.FCC<Props> = ({
  list,
  onClose,
  onCancel,
  refetch,
  userId,
}) => {
  const [, refetchSessionHydration] = useSessionHydration();

  const account = useCurrentAccount();
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const user = useUser(userId);

  const userLookUpTable = useUserLookup();

  const [migrationValues, setMigrationValues] = useState<Array<MigrationItem>>(
    list.map(listItem => ({
      sourceUserId: userId,
      sourceOfficeId: listItem.id,
      targetOfficeId: null,
      targetUserId: null,
      listItem,
    })),
  );

  const [deleteUserFromOffice, { error: mutationError }] =
    useDeleteUserFromOfficeMutation();

  const handleSubmit = async () => {
    const hasValidationError = migrationValues.some(
      item => !hasValidOffice(item) || !hasValidUser(item),
    );

    if (!hasValidationError) {
      for (const item of migrationValues) {
        const result = await deleteUserFromOffice({
          variables: {
            accountId: account.id,
            userId: userId,
            officeId: item.sourceOfficeId,
            transferResourcesTo: {
              officeId: item.targetOfficeId,
              userId: item.targetUserId,
            },
          },
        });

        if (mutationError != null) {
          setErrorMessage(getErrorMessageForModal(mutationError));
          return;
        }

        if (result == null || result.data == null) {
          setErrorMessage(Catalog.genericUnknownErrorMessage);
          return;
        }

        const { error, success } = result.data.deleteUserFromOffice;

        if (error == null && success === false) {
          setErrorMessage(Catalog.genericUnknownErrorMessage);
          return;
        }

        if (error != null) {
          const { lockedByUserId, reason } = error;
          const userLockedByUserName =
            userLookUpTable && userLookUpTable[lockedByUserId];
          const lockedByUserName = getUserName(userLockedByUserName) || '';
          const deletingUserName = getUserName(user);

          const errorMessage = convertBackendErrorMessage(
            lockedByUserName,
            deletingUserName ? deletingUserName : '',
            reason,
          );

          setErrorMessage(errorMessage ? errorMessage : null);
          return;
        } else if (success) {
          setErrorMessage(null);
        }
      }

      if (refetch) await refetch();

      await refetchSessionHydration();
      onClose();
    } else {
      setShowValidation(true);
    }
  };

  return (
    <Overlay onClose={onClose}>
      <Modal maxWidth="50vw" onClose={onClose}>
        <ModalContainer data-testid={TEST_ID.CONTAINER}>
          <TopSectionContainer
            headerText={text.headerText}
            withBorder
            descriptionText={text.description}
          />

          <ListContainer>
            <SubHeaderContainer>
              <FromContainer>
                <span>Taken en contacten</span>
              </FromContainer>
              <ToContainer>
                <span>Toewijzen</span>
              </ToContainer>
            </SubHeaderContainer>

            {migrationValues.map((migrationValue, idx) => (
              <ListItemCard
                key={migrationValue.sourceOfficeId}
                item={migrationValue.listItem}
                isLastItem={
                  idx === list.length - 1 || migrationValues.length === 1
                }
                migrationValue={migrationValue}
                setMigrationValue={data => {
                  setMigrationValues(
                    migrationValues.map(item =>
                      item.sourceOfficeId === migrationValue.sourceOfficeId
                        ? data
                        : item,
                    ),
                  );
                }}
                showValidation={showValidation}
                entityId={userId}
                entityType={DELETE_ENTITY_TYPE.USER}
                deletionType={DELETION_TYPE.DELETE_USER_FROM_OFFICE}
              />
            ))}

            {errorMessage && (
              <ErrorMessageContainer data-testid={TEST_ID.ERROR_MESSAGE}>
                {errorMessage}
              </ErrorMessageContainer>
            )}
          </ListContainer>

          <BottomButtonRow>
            <TextButton
              size="medium"
              data-testid={TEST_ID.CANCEL}
              appearance="danger"
              onClick={onCancel}
              label={text.cancelButtonLabel}
            />
            <Button
              size="medium"
              data-testid={TEST_ID.SUCCESS}
              appearance="danger"
              onClick={handleSubmit}
              label={text.successButtonLabel}
            />
          </BottomButtonRow>
        </ModalContainer>
      </Modal>
    </Overlay>
  );
};

export default DeleteUserFromOfficeModal;
