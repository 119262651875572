import React, { useEffect, useState } from 'react';
import Dropdown from '~/components/molecule/Dropdown';
import useEntityValidationForInputs from '~/components/page/Automation/v2/components/Builder/hooks/useEntityValidationForInputs';
import useUserOptions from '~/hooks/useUserOptions';
import { isNil } from 'ramda';
import type { Client_Feature } from '../MapsContainer';

const text = {
  userDropdownLabel: 'Toewijzen aan',
  selectUser: 'Selecteer gebruiker',
};

export type Props = {
  onUpdateWorkingArea: (args: {
    // Feature to update
    featureId: string;

    // Value to update
    updatedValue: { userId: string | null } | { feature: Client_Feature };
  }) => void;

  workingArea: {
    userId?: string | null;
    feature: Client_Feature;
  };

  officeId: string;
};

const AssignUserDropdown: React.FCC<Props> = ({
  onUpdateWorkingArea,
  workingArea,
  officeId,
}) => {
  const [userId, setUserId] = useState<string | null>(null);

  // Also update it when user selection is cancelled
  useEffect(() => {
    setUserId(workingArea.userId as string | null);
  }, [workingArea.userId]);

  const userOptions = useUserOptions({
    withAllUsersOption: true,
    officeId,
  });

  const { userError } = useEntityValidationForInputs({
    userOptions,
    userId,
    officeId,
  });

  return (
    <Dropdown
      label={!isNil(userError) ? text.selectUser : text.userDropdownLabel}
      options={userOptions}
      onChange={e => {
        const selectedUserId = e.option.payload?.id;
        onUpdateWorkingArea({
          featureId: workingArea.feature.id as string,
          updatedValue: { userId: selectedUserId },
        });
      }}
      selectedOptionIdx={userOptions.findIndex(
        selectedUser => selectedUser.payload?.id == workingArea.userId,
      )}
      error={userError}
    />
  );
};

export default AssignUserDropdown;
