import type { SelectedBrandSettings } from '~/components/template/BrandSettingsModal';
import hasValue from '~/util/hasValue';

export type ReturnType = {
  logoImage: {
    url: string;
    s3key: string;
  } | null;
  colors: {
    mainColor: String;
    headerTextColor: String;
    accentColor: String;
    backgroundColor: String;
  } | null;
};

const brandValuesToValueReportValuesMap = {
  primaryBackground: 'mainColor',
  primaryColor: 'headerTextColor',
  secondaryBackground: 'backgroundColor',
  secondaryColor: 'accentColor',
};

const brandValuesToValueReportValues = (
  values: SelectedBrandSettings,
): ReturnType => {
  const updatedValues: ReturnType = { logoImage: null, colors: null };
  if (hasValue(values.colors)) {
    updatedValues['colors'] = Object.keys(values.colors).reduce(
      (acc, color) => {
        const colorObj = values.colors![color];
        const backgroundKey =
          brandValuesToValueReportValuesMap[`${color}Background`];
        const colorKey = brandValuesToValueReportValuesMap[`${color}Color`];

        if (backgroundKey) {
          acc![backgroundKey] = colorObj.background;
        }

        if (colorKey) {
          acc![colorKey] = colorObj.color;
        }

        return acc;
      },
      {} as ReturnType['colors'],
    );
  }

  if (
    hasValue(values.logo) &&
    typeof values.logo === 'object' &&
    's3key' in values.logo
  ) {
    updatedValues['logoImage'] = values.logo;
  }

  return updatedValues;
};

export default brandValuesToValueReportValues;
