import type { FormBuilder_Style_Theme } from '~/graphql/types';
import removeTypenames from '~/util/removeTypenames';

type ColorPair = {
  background: string; // Hex color
  color: string; // Hex color
};

export type BrandSettings = {
  type: 'theme';
  logo: {
    url?: string;
    image?: {
      src: string;
    };
  };
  colors: {
    primary: ColorPair;
    secondary: ColorPair;
  };
};

const formatTheme = (input: FormBuilder_Style_Theme): BrandSettings => ({
  logo: {
    // Url is required (an error will be shown otherwise) if logo is uploaded, but if logo is NOT uploaded, url is undefined
    url: input.logoLink ?? undefined,
    image: input.logo?.s3key
      ? {
          src: input.logo?.url,
        }
      : undefined,
  },

  colors: {
    primary: removeTypenames(input.colors.primary),
    secondary: removeTypenames(input.colors.secondary),
  },
  type: 'theme',
});

export default formatTheme;
