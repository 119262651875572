import { title as connectTitle } from './AddTrustooKoppeling';
import { WizardFlow } from '~/graphql/types.client';
import {
  title as genericWidgetConfigurationTitle,
  id as genericWidgetConfigurationId,
} from '../../GenericSteps/GenericWidgetConfiguration';
import { StepId } from '../..';

const typename = 'AppStatus_Trustoo';
const trustooEnablementFlow: WizardFlow = {
  id: 'enable-trustoo',
  steps: [
    {
      title: genericWidgetConfigurationTitle,
      id: genericWidgetConfigurationId as StepId,
      metadata: { typename },
    },
    {
      title: connectTitle,
      id: 'AddTrustooKoppeling',
      metadata: { typename },
    },
  ],
};

export default trustooEnablementFlow;
