import { useMemo } from 'react';
import useSessionHydration from '../useSessionHydration';
import { PermissionFragment, PermissionId } from '~/graphql/types';

export type PermissionMap = Record<PermissionId, PermissionFragment>;
const usePermissionMap = () => {
  const [{ permissions }] = useSessionHydration();

  const permissionMap = useMemo(
    () =>
      (
        permissions ?? [{ __typename: 'Permission_AccessGranted', id: 'root' }]
      ).reduce((acc, curr) => {
        acc[curr.id as PermissionId] = curr;
        return acc;
      }, {} as PermissionMap),
    [permissions],
  );

  return permissionMap;
};

export default usePermissionMap;
