import { title as generalTitle } from '../../WaardecheckGeneral';
import { title as designTitle } from '../../WaardecheckDesign';
import type { WizardFlow } from '~/graphql/types.client';

type WaardecheckTypename =
  | 'AppStatus_BBWaardecheck'
  | 'AppStatus_VBOWaardecheck';

const getWaardecheckEnablementFlow = (
  typename: WaardecheckTypename,
): WizardFlow => {
  const enableSteps: WizardFlow = {
    id:
      typename === 'AppStatus_BBWaardecheck'
        ? 'enable-bb-waardecheck'
        : 'enable-vbo-waardecheck',
    steps: [
      {
        title: generalTitle,
        id: 'WaardecheckGeneral',
        metadata: { typename },
      },
      {
        title: designTitle,
        id: 'WaardecheckDesign',
        metadata: { typename },
      },
    ],
  };

  return enableSteps;
};

export default getWaardecheckEnablementFlow;
