import { GenericHtmlElement } from '~/components/organism/PluginsEditor/types';
import convertAttrsObjToAttrsString from '~/components/organism/PluginsEditor/utils/serialize/convertAttrsObjToAttrsString';

/**
 * serializes the GenericHtmlElement (any element we don't have in the elements directory) into html element
 * @param {GenericHtmlElement} node - any element we don't have in the elements directory
 */
const serialize = (node: GenericHtmlElement, children: string): string => {
  const attrs = convertAttrsObjToAttrsString(node.attrsToPassThrough);
  return `<${node.name}${attrs ? ` ${attrs}` : ''}>${children}</${node.name}>`;
};

export default serialize;
