import type { SelectedBrandSettings } from '~/components/template/BrandSettingsModal';
import type { ColorProperty } from '../..';

const brandValuesToHypotheekbondValuesMap = {
  primaryBackground: 'backgroundColor',
  primaryColor: 'primaryColor',
  secondaryBackground: 'buttonColor',
  secondaryColor: 'secondaryColor',
};

type ReturnType = Record<ColorProperty, string>;

const brandValuesToHypotheekbondValues = (
  values: SelectedBrandSettings['colors'],
): ReturnType | null => {
  if (!values) return null;

  return Object.keys(values).reduce((transformedValues, color) => {
    const colorObj = values[color];
    const backgroundKey =
      brandValuesToHypotheekbondValuesMap[`${color}Background`];
    const colorKey = brandValuesToHypotheekbondValuesMap[`${color}Color`];

    if (backgroundKey) {
      transformedValues[backgroundKey] = colorObj.background;
    }

    if (colorKey) {
      transformedValues[colorKey] = colorObj.color;
    }

    return transformedValues;
  }, {} as ReturnType);
};

export default brandValuesToHypotheekbondValues;
