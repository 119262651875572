import { isNil } from 'ramda';
import { useContext, useEffect } from 'react';
import WizardContext, {
  StateStep,
  StepOptions,
  WizardStep,
} from '~/components/organism/Wizard/context/WizardContext';
import { StepId, StepOutput } from '~/components/organism/WizardSteps';
import getStepById from './utils/getStepById';

type WizardStepAPI = {
  /**
   * Adds additional sub steps to the current step
   * @param step WizardStep - The step you want to add
   */
  addSubStep: (step: WizardStep) => void;

  /**
   * Removes the provided step
   * @param step
   */
  removeSubStep: (step: WizardStep) => void;

  free: (output: StepOutput) => void;
  lock: (output: StepOutput) => void;
  lockGoBack: () => void;
  freeGoBack: () => void;
  lockSkip: () => void;
  freeSkip: () => void;
};

const useWizardStep = (
  stepId: StepId,
  // IMPORTANT!: Always memoize options when you are passing it because it is used as a dependency in the useEffect
  options?: StepOptions,
): [StateStep, WizardStepAPI] => {
  const wizardContext = useContext(WizardContext);
  const { state, dispatch } = wizardContext;
  const step = getStepById(stepId, state.steps);

  if (isNil(step)) {
    throw new Error(`Unable to find step with ID: ${stepId}`);
  }

  useEffect(() => {
    if (options) {
      dispatch({ type: 'updateStep', payload: { step, options } });
    }
    // options is added in order to get the latest version of onBeforeNext
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const api: WizardStepAPI = {
    addSubStep: subStep =>
      dispatch({
        type: 'addSubStep',
        payload: {
          step,
          subStep,
        },
      }),

    removeSubStep: subStep =>
      dispatch({
        type: 'removeSubStep',
        payload: {
          step,
          subStep,
        },
      }),

    free: output => dispatch({ type: 'freeStep', payload: { output, step } }),
    lock: output => dispatch({ type: 'lockStep', payload: { step, output } }),
    lockGoBack: () => dispatch({ type: 'lockGoBack', payload: { step } }),
    freeGoBack: () => dispatch({ type: 'freeGoBack', payload: { step } }),
    lockSkip: () => dispatch({ type: 'lockSkip', payload: { step } }),
    freeSkip: () => dispatch({ type: 'freeSkip', payload: { step } }),
  };

  return [step, api];
};

export default useWizardStep;
