import { findIndex, propEq, update, equals } from 'ramda';
import { useCallback, useState } from 'react';
import checkIsPointerAttachmentDuplicate from '../../utils/checkIsPointerAttachmentDuplicate';
import passKeysToAttachments from '../../components/SendEmailPlain/utils/passKeysToAttachments';
import type { Client_FlowV2_EmailAttachment } from '../../components/types';

/** A key added to be able to update the correct attachment */
export type AttachmentWithKey = Client_FlowV2_EmailAttachment & { key: string };

type ReturnedValues = {
  insertPointerAttachment: (attachment: Client_FlowV2_EmailAttachment) => void;
  insertFileAttachment: (attachment: Client_FlowV2_EmailAttachment) => void;
  onDelete: (file: Client_FlowV2_EmailAttachment['file']) => void;
  updatePointerAttachment: (p: {
    key: string;
    value: AttachmentWithKey;
  }) => void;

  /** Attachments to update the action with */
  fileAttachments: Array<Client_FlowV2_EmailAttachment>;
};

const text = {
  duplicatedError: 'Bijlagen al toegevoegd',
};

const useEmailFileAttachments = ({
  initialfileAttachments,
  setError,
}: {
  initialfileAttachments?: Array<Client_FlowV2_EmailAttachment>;
  setError: (value: string | null) => void;
}): ReturnedValues => {
  const [fileAttachments, setFileAttachments] = useState<
    Array<Client_FlowV2_EmailAttachment>
  >(initialfileAttachments || []);

  const attachmentsWithKey = passKeysToAttachments(fileAttachments);

  const insertPointerAttachment = useCallback(
    (newAttachment: Client_FlowV2_EmailAttachment) => {
      if (newAttachment.file.__typename !== 'Flow___Argument_Pointer') return;

      const isDuplicatePointerAttachment = checkIsPointerAttachmentDuplicate(
        fileAttachments,
        newAttachment,
      );

      if (isDuplicatePointerAttachment) {
        setError(text.duplicatedError);
        return;
      } else {
        setFileAttachments(prev => [...prev, newAttachment]);
        setError(null);
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileAttachments],
  );

  const updatePointerAttachment = useCallback(
    ({ key, value }: { key: string; value: AttachmentWithKey }) => {
      const isDuplicatePointerAttachment = checkIsPointerAttachmentDuplicate(
        attachmentsWithKey,
        value,
      );

      if (isDuplicatePointerAttachment) {
        return setError(text.duplicatedError);
      }

      // @ts-ignore
      const index = findIndex(propEq('key', key), attachmentsWithKey);

      setFileAttachments(prev => update(index, value, prev));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachmentsWithKey],
  );

  const insertFileAttachment = useCallback(
    (newAttachment: Client_FlowV2_EmailAttachment) => {
      if (!newAttachment) return;
      setFileAttachments(prev => [...prev, newAttachment]);
    },
    [],
  );

  const onDelete = useCallback(
    (file: Client_FlowV2_EmailAttachment['file']) => {
      const isBrokenAttachment =
        file.__typename === 'Flow___Argument_File' &&
        file.value_file.s3key === '';

      if (isBrokenAttachment) {
        setFileAttachments(prev =>
          prev.filter(
            attachment =>
              attachment.file.__typename === 'Flow___Argument_Pointer' ||
              (attachment.file.__typename === 'Flow___Argument_File' &&
                attachment.file.value_file.filename !==
                  file.value_file.filename),
          ),
        );
        return;
      }

      setFileAttachments(prev =>
        prev.filter(attachment => !equals(attachment.file, file)),
      );
    },
    [],
  );

  return {
    insertPointerAttachment,
    updatePointerAttachment,
    insertFileAttachment,
    onDelete,
    fileAttachments,
  };
};

export default useEmailFileAttachments;
