import { StateStep } from '~/components/organism/Wizard/context/WizardContext';
import { StepId } from '~/components/organism/WizardSteps';
import getStepIndexes from '../getStepIndexes';

/**
 * Returns a step or substep by ID
 * @param {string} ID - The ID of the step you need
 * @param {Array<StateStep>} steps - Collection of steps you want to get it from
 */
const getStepById = (id: StepId, steps: Array<StateStep>): StateStep | null => {
  const [sIndex, subStepIndex] = getStepIndexes(id, steps);

  if (sIndex === null) return null;

  const mainStep = steps[sIndex];

  if (mainStep != null && subStepIndex !== null && mainStep.subSteps?.length) {
    return mainStep.subSteps[subStepIndex];
  }
  return mainStep;
};
export default getStepById;
