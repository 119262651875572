import { ReducerAction } from '~/components/organism/Wizard/context/WizardContext';
import { isProduction } from '..';

const actionLogger = (action: ReducerAction<string, any>) => {
  // Don't print in Jest
  if (process.env.JEST_WORKER_ID !== undefined) return;

  if (isProduction()) return;
  // eslint-disable-next-line no-console
  console.log(
    '%c%s%c%s %c%s %c%O',

    'color: red',
    'action: ',

    'font-weight: bold',
    `"${action.type}"`,

    'font-weight: normal',
    'with payload:',

    'color: grey',
    action.payload,
  );
};

export default actionLogger;
