import type { FlowPath } from '../../getFieldsByPath';
import type { Flow___InstanceConditionPartial } from '../../../../ConditionEditorV2';
import type { InstanceMap } from '../../getInstance';
import type { SubjectMap } from '../../getSubject';
import type { DirectoryMap } from '../../getDirectory';
import { getCondition, ConditionMap } from '../../getConditions';
import getPathForPointer from '../../getPathForPointer';

const getPathForInstanceCondition = (
  condition: Flow___InstanceConditionPartial,
  opts: {
    instanceMap: InstanceMap;
    subjectMap: SubjectMap;
    conditionMap: ConditionMap;
    directoryMap: DirectoryMap;
  },
):
  | {
      result: FlowPath;
      fullResult: FlowPath;
      error?: undefined;
    }
  | { error: string } => {
  const conditionData = getCondition(condition.type, opts.conditionMap);
  if (!conditionData) {
    return { error: `Could not find condition with type ${condition.type}` };
  }

  const path = getPathForPointer(condition.input, {
    ...opts,
    validTypes: conditionData.input,
  });

  if (path.error != null) return path;
  return { result: path.fullResult, fullResult: path.fullResult };
};
export default getPathForInstanceCondition;
