import { useLocation } from '@gatsbyjs/reach-router';
import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { appWaardecheckErrorSelector } from '~/components/page/Apps/components/WaardecheckTemplate/state';
import getValidationErrors, {
  type ValidationFunction,
} from '~/util/getValidationErrors';

export type Args = {
  id: string;
  /**
   * Array of validation functions, errors produced will be shown
   * accordingly.
   */
  validation?: Array<ValidationFunction>;
  /**
   * Extra errors.
   */
  errors?: Array<string>;
  value: string | undefined | null;
};

const useConstructAndNotifyErrors = ({
  id: givenId,
  errors = [],
  validation = [],
  value,
}: Args) => {
  const { pathname } = useLocation();
  const [allErrors, setAllErrors] = useRecoilState(appWaardecheckErrorSelector);
  const id = `${pathname}___${givenId}`;
  const { error } = allErrors[id] ?? {};

  const constructAndNotifyErrors = useCallback(
    (value?: string | null) => {
      const validationErrors = getValidationErrors(validation, value ?? null);

      const nextErrors = [...validationErrors, ...errors];
      const nextError = nextErrors.join(', ');
      const errorsChanged = error !== nextError;

      if (errorsChanged) {
        setAllErrors(prev => {
          if (!nextError) {
            /** Clear them errors */
            const nextState = { ...prev };
            delete nextState[id];
            return nextState;
          }

          return {
            ...prev,
            [id]: { error: nextError, link: `${pathname}#${id}` },
          };
        });
      }

      return { errors: nextErrors, error: nextError, errorsChanged };
    },
    [errors, validation, error, id, pathname, setAllErrors],
  );

  useEffect(() => {
    /**
     * If the outside errors change I want to re-validate
     * making sure that we get rid of errors.
     */
    constructAndNotifyErrors(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.join(';')]);

  return {
    constructAndNotifyErrors,
    error,
    id,
  } as const;
};

export default useConstructAndNotifyErrors;
