import { reporter } from '~/hooks/useErrorReporter';
import { NylasState, SynchroniseEntityType } from '../../types';

/**
 * Parse the state string for v1
 * @param {string} stateString - State string from nylas
 * keywords: nylasstate
 */
const parseStateString = (stateString: string): NylasState | null => {
  const tokens = decodeURIComponent(stateString).split('|');

  if (tokens.length !== 3) {
    reporter.captureException(
      new Error(`Invalid Nylas state string given: ${stateString}`),
    );

    return null;
  }

  const entityType = tokens[0];
  if (!isSynchroniseEntityType(entityType)) {
    reporter.captureException(
      new Error(`Invalid synchronise entity type given: ${stateString}`),
    );

    return null;
  }

  return {
    entityType,
    entityId: tokens[1],
    accountId: tokens[2],
  };
};

const isSynchroniseEntityType = (
  value: string,
): value is SynchroniseEntityType =>
  value === SynchroniseEntityType.Account ||
  value === SynchroniseEntityType.Office ||
  value === SynchroniseEntityType.User;

export default parseStateString;
