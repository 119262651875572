import React from 'react';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import { StepProps } from '../Connect';
import ChatComponent from './components/ChatComponent';
import Instructions from './components/Instructions';
import {
  firstVariantOfInstructions,
  secondVariantOfInstructions,
} from './instructionsText';
import TEST_ID from './index.testid';
import { Body } from '~/components/atom/Typography';

const text = {
  header: 'Volgende stappen',
  pageDescription:
    'Het inrichten van de Zapier koppeling is gelukt! Om je Zapier koppeling aan het werk te zetten, moet je nog een aantal stappen uitvoeren in zowel Zapier als in DatHuis. We leggen het kort uit.',
  firstInstructionHeader: 'Wil je contacten in Zapier verwerken?',
  secondInstructionHeader:
    'Wil je contacten vanuit Zapier in DatHuis ontvangen?',
  chatHeader:
    'Op zoek naar ideeen hoe je met Zapier jouw marketing een boost geeft?',
  chatLinkText: 'Start een chat met ons!',
  buttonLabel: 'Afronden',
  exitButtonLabel: 'Afbreken',
};

const FinalStep: React.FCC<StepProps> = ({
  onClose,
  disableClosing = false,
}) => (
  <AppDetailsContainer
    header={text.header}
    icon="list"
    dataTestId={TEST_ID.CONTAINER}
  >
    <Body margin={[null]}>{text.pageDescription}</Body>
    <Instructions
      header={text.firstInstructionHeader}
      instructions={firstVariantOfInstructions}
    />
    <Instructions
      header={text.secondInstructionHeader}
      instructions={secondVariantOfInstructions}
    />
    <ChatComponent header={text.chatHeader} linkText={text.chatLinkText} />
    <JustificationContainer justification="space-between" align="center">
      {!disableClosing && (
        <TextButton
          label={text.exitButtonLabel}
          onClick={onClose}
          padding={[null]}
          appearance="danger"
          type="button"
          dataTestId="exit-wizard-button"
        />
      )}
      <Button
        label={text.buttonLabel}
        icon="arrowRight"
        size="medium"
        onClick={onClose}
        dataTestId={TEST_ID.COMPLETE_BUTTON}
      />
    </JustificationContainer>
  </AppDetailsContainer>
);

export default FinalStep;
