import React from 'react';
import { Props } from '..';

const Bank: React.FCC<Props> = ({
  color = 'currentColor',
  strokeWidth = 1,
  ...props
}) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.73714 1.72877L10.1578 2.03596H9.2639C9.17989 2.00432 9.09037 1.98781 8.9995 1.98781C8.84041 1.98781 8.68545 2.0384 8.557 2.13226L7.86854 2.63535L1.75528 7.09606L0.564746 7.96476C0.480227 7.68301 0.554212 7.35347 0.841552 7.14399L0.841723 7.14386L8.26272 1.72886L8.26286 1.72877C8.47687 1.5725 8.73501 1.48828 9 1.48828C9.26499 1.48828 9.52313 1.5725 9.73714 1.72877ZM10.532 2.30787L17.1582 7.14384L17.453 6.73996L17.1583 7.14385C17.4458 7.35366 17.5197 7.68312 17.4353 7.96476L16.2447 7.09606L9.84826 2.4287L10.2721 2.11941L10.532 2.30917V2.30787ZM15.95 7.99996H17.4238C17.3261 8.27741 17.0721 8.49996 16.717 8.49996H16.5H16V8.99996V14.8V15.1061L16.2727 15.2453C16.6422 15.4339 16.9523 15.7209 17.1689 16.0746C17.3855 16.4284 17.5001 16.8351 17.5 17.2499V17.25V18.75C17.5 18.8163 17.4737 18.8799 17.4268 18.9267C17.3799 18.9736 17.3163 19 17.25 19H0.75C0.612576 19 0.5 18.8873 0.5 18.75V17.25V17.2499C0.499923 16.8351 0.614504 16.4284 0.831084 16.0746C1.04766 15.7209 1.35782 15.4339 1.72727 15.2453L2 15.1061V14.8V8.99996V8.49996H1.5H1.283C0.927798 8.49996 0.673927 8.27737 0.576217 7.99996H2.05H15.95ZM15.5 8.99996V8.49996H15H13H12.5V8.99996V14.5V15H13H15H15.5V14.5V8.99996ZM12 8.99996V8.49996H11.5H9.75H9.25V8.99996V14.5V15H9.75H11.5H12V14.5V8.99996ZM8.75 8.99996V8.49996H8.25H6.5H6V8.99996V14.5V15H6.5H8.25H8.75V14.5V8.99996ZM16.5 18.5H17V18V17.25C17 16.2838 16.2161 15.5 15.25 15.5H2.75C1.78386 15.5 1 16.2838 1 17.25V18V18.5H1.5H16.5ZM2.5 14.5V15H3H5H5.5V14.5V8.99996V8.49996H5H3H2.5V8.99996V14.5ZM9.5 5.24996C9.5 5.38257 9.44732 5.50975 9.35355 5.60351C9.25978 5.69728 9.13261 5.74996 9 5.74996C8.86739 5.74996 8.74022 5.69728 8.64645 5.60351C8.55268 5.50975 8.5 5.38257 8.5 5.24996C8.5 5.11735 8.55268 4.99018 8.64645 4.89641C8.74022 4.80264 8.86739 4.74996 9 4.74996C9.13261 4.74996 9.25978 4.80264 9.35355 4.89641C9.44732 4.99018 9.5 5.11735 9.5 5.24996Z"
      fill={color}
      stroke={color}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default Bank;
