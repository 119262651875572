import type {
  FlowData___FlowInstanceFragment,
  FlowData___SubjectFragment,
  Flow___SubjectId,
} from '~/graphql/types';
import type { FlowField } from '../getFieldsByPath';
import generateSubjectId from '../generateSubjectId';

type FieldParentIdentifier = {
  parent: Flow___SubjectId;
  instance?: FlowData___FlowInstanceFragment;
};

export type HydratedField<T extends FlowField = FlowField> = T &
  FieldParentIdentifier;

/**
 * Adds the parent Subject to a field.
 */
const hydrateField = <T extends FlowField>(
  field: T,
  parent: FlowData___SubjectFragment,
  instance?: FlowData___FlowInstanceFragment,
): HydratedField<T> => ({
  ...field,
  parent: generateSubjectId(parent),
  instance,
});

export default hydrateField;
