import { Attachment } from '~/graphql/types';
import {
  INLINE_IMAGE_CONTENT_LENGTH_ATTR,
  INLINE_IMAGE_CONTENT_TYPE_ATTR,
  INLINE_IMAGE_FILENAME_ATTR,
  INLINE_IMAGE_FROM_BACKEND_REGEX,
  INLINE_IMAGE_ID_ATTR,
  INLINE_IMAGE_S3KEY_ATTR,
  SRC_REGEX,
} from '../../../constants';

/**
 *
 * @param {string} str - Html string
 * @param {Array<Attachment>} attachment - Signature attachments
 * keywords: convert image inline id into image attributes
 */

const convertAttachments = ({
  str,
  attachments,
}: {
  str: string;
  attachments: Array<Attachment>;
}): string => {
  const res =
    attachments && attachments.length > 0
      ? str.replace(INLINE_IMAGE_FROM_BACKEND_REGEX, matchedValue =>
          matchedValue.replace(SRC_REGEX, (matchedSrc): string => {
            const src = getAttributeValue('src', matchedSrc);

            if (src == null) {
              // mark it as hasError to show the error state in the Image element
              return 'hasError="true"';
            }
            // [[inlineId:<lookingfor>]], so ignore first 11 chars
            const inlineId = src.substring(11, src.length - 2);

            const attachment = attachments.find(
              attachment => attachment.inlineId === inlineId,
            );

            if (attachment == null) {
              return 'hasError="true"';
            }

            const url = attachment?.url;

            if (url == null) {
              return 'hasError="true"';
            }

            const file = attachment?.file;

            if (file.__typename !== 'DHFile') {
              return 'hasError="true"';
            }

            const { contentLength, s3key, filename, contentType } = file;

            if (
              inlineId == null ||
              filename == null ||
              s3key == null ||
              contentLength == null
            ) {
              return 'hasError="true"';
            }

            return `src="${url}" ${INLINE_IMAGE_ID_ATTR}="${inlineId}" ${INLINE_IMAGE_S3KEY_ATTR}="${s3key}" ${INLINE_IMAGE_FILENAME_ATTR}="${filename}" ${INLINE_IMAGE_CONTENT_TYPE_ATTR}="${
              contentType || 'null'
            }" ${INLINE_IMAGE_CONTENT_LENGTH_ATTR}="${contentLength}"`;
          }),
        )
      : str;

  return res;
};

/**
 * Carried over from src/components/HTMLEditor/util/variableHTML.ts so that it's easy to delete old Html editor.
 * Remove this comment once we move over to Flows V2
 */
const getAttributeGrabRegex = attr => new RegExp(`${attr}=".*?"`);

const getAttributeValue = (attr: string, html: string): string | null => {
  const reg = getAttributeGrabRegex(attr);

  const fromHtml = html.match(reg);

  if (fromHtml == null || fromHtml[0] == null) return null;

  // attrname="<whatweneed>"
  return fromHtml[0].slice(attr.length + 2, fromHtml[0].length - 1);
};

export default convertAttachments;
