import { isEmpty } from 'ramda';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import APIBlock from '~/components/organism/APIBlock';
import { Body } from '~/components/atom/Typography';
import {
  OutputFieldItem,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import {
  useUpdateRealworksAppStatusMutation,
  RealworksTokenStatus,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useOffice from '~/hooks/useOffice';
import useUser from '~/hooks/useUser';
import useWizardStep from '~/hooks/useWizardStep';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import {
  apiStatusToDescription,
  apiStatusToVariant,
  BlockContainer,
} from '~/components/page/Apps/Realworks/components/Connections/components/TokenContainer';
import isDuplicatedToken from '~/components/page/Apps/Realworks/utils/isDuplicatedToken';
import formatToastMessage from '~/util/formatToastMessage';
import { isNonEmptyString } from '~/util/Validation/String';
import TEST_ID from './index.testid';

export const text = {
  header: 'Activatie verwerken',
  toastErrorMessage:
    'Er is iets fout gegaan bij het toevoegen van de tokens, sluit de wizard en begin opnieuw.',
  toastFailedToUpdateAppStatus:
    'Er is iets mis gegaan bij het verwerken van de aanvraag, probeer het later opnieuw',
  toastSuccessMessage: 'Koppelingen succesvol toegevoegd.',
  body: 'Gefeliciteerd! De koppeling is ingesteld en wordt door ons verwerkt. Dit kan enkele uren tot een dag duren. Zodra de koppeling is geactiveerd ontvang je een e-mail.',
  warning:
    'Let op: Gebruik je de Relaties API om contacten te importeren? Vergeet dan niet in te stellen welke relatiekenmerken worden gesynchroniseerd.',
  subheader: 'Gekoppelde APIs',
  duplicateErrorMessage:
    'Deze token is al in gebruik. Probeer een andere token.',

  relatieToken: 'Relatie token',
  wonenToken: 'Wonen token',
};

export const id = 'RealworksActivationPending';
export const title = text.header;

export type OutputType = {
  type: typeof id;
  relatieToken?: OutputFieldItem;
  wonenToken?: OutputFieldItem;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const [updateRealworksAppStatus] = useUpdateRealworksAppStatusMutation({});

  const relatieToken =
    ('relatieToken' in outputMap.RealworksImportContacts
      ? outputMap.RealworksImportContacts.relatieToken?.value
      : undefined) || undefined;
  const wonenToken =
    ('wonenToken' in outputMap.RealworksExportContacts
      ? outputMap.RealworksExportContacts.wonenToken?.value
      : undefined) || undefined;

  const containerInfo =
    'addTokenContainerInput' in outputMap.RealworksSetup
      ? outputMap.RealworksSetup.addTokenContainerInput.value
      : null;

  const onBeforeNext = useCallback(async () => {
    if (containerInfo === null || isEmpty(containerInfo))
      return Promise.resolve({ type: id } as OutputType);

    const resp = await updateRealworksAppStatus({
      variables: {
        accountId,
        addTokenContainer: {
          name: containerInfo.name,
          officeId: containerInfo.officeId,
          userId: containerInfo.userId,
          relatie: relatieToken,
          wonen: wonenToken,
        },
      },
    }).then(async ({ errors, data }) => {
      if ((errors && errors.length !== 0) || data == null) {
        if (isDuplicatedToken(errors)) {
          addToast([formatToastMessage(text.duplicateErrorMessage, 'danger')]);
          return;
        } else {
          addToast([formatToastMessage(text.toastErrorMessage, 'danger')]);
          return;
        }
      }

      addToast([formatToastMessage(text.toastSuccessMessage, 'success')]);

      return data;
    });

    const addedToken =
      resp != null ? resp.updateRealworksAppStatus.tokens[0] : null;

    if (addedToken == null) {
      const fallbackOutput: OutputType = {
        type: id,
      };

      return fallbackOutput;
    }

    const output: OutputType = {
      type: id,
      relatieToken: addedToken.relatie?.token
        ? {
            category: `Tokens toegevoegd voor: ${addedToken.name}`,
            label: `${text.relatieToken} ${addedToken.relatie?.token}`,
            type: 'item',
            value: addedToken.relatie.token,
          }
        : undefined,
      wonenToken: addedToken.wonen?.token
        ? {
            category: `Tokens toegevoegd voor: ${addedToken.name}`,
            label: `${text.wonenToken} ${addedToken.wonen?.token}`,
            type: 'item',
            value: addedToken.wonen?.token,
          }
        : undefined,
    };

    return output;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, containerInfo, relatieToken, wonenToken]);

  const stepOptions = useMemo(() => ({ onBeforeNext }), [onBeforeNext]);

  const [, api] = useWizardStep(step.id, stepOptions);

  const relatieTokenStatus = isNonEmptyString(relatieToken)
    ? RealworksTokenStatus.Pending
    : 'skipped';
  const wonenTokenStatus = isNonEmptyString(wonenToken)
    ? RealworksTokenStatus.Pending
    : 'skipped';

  const office = useOffice(containerInfo?.officeId);
  const user = useUser(containerInfo?.userId);

  const officeText = office ? `Vestiging ${office.name}` : '';
  const userText =
    user && user.__typename === 'User' ? `/ ${user.name}` : '/ Alle gebruikers';

  useEffect(() => {
    api.free({ type: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Body>{text.body}</Body>
      <StyledAppEntryItemHeader>{text.warning}</StyledAppEntryItemHeader>

      <br />
      <AppEntryItemHeader dataTestId={TEST_ID.OFFICE_USER_CONTAINER}>
        {officeText} {userText}
      </AppEntryItemHeader>

      <SubHeader>{text.subheader}</SubHeader>

      <BlockContainer>
        <APIBlock
          heading="Relaties"
          description={apiStatusToDescription(relatieTokenStatus)}
          variant={apiStatusToVariant(relatieTokenStatus)}
          token={
            ('relatieToken' in outputMap.RealworksImportContacts
              ? outputMap.RealworksImportContacts.relatieToken?.value
              : undefined) || undefined
          }
          dataTestId={TEST_ID.RELATIE_API_BLOCK}
        />
        <APIBlock
          heading="Wonen"
          description={apiStatusToDescription(wonenTokenStatus)}
          variant={apiStatusToVariant(wonenTokenStatus)}
          token={
            ('wonenToken' in outputMap.RealworksExportContacts
              ? outputMap.RealworksExportContacts.wonenToken?.value
              : undefined) || undefined
          }
          dataTestId={TEST_ID.WONEN_API_BLOCK}
        />
      </BlockContainer>
    </>
  );
};

const SubHeader = styled.div<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight('semiBold')};
    margin-bottom: ${theme.space('s')};
  `,
);

const StyledAppEntryItemHeader = styled(AppEntryItemHeader)<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('m')};
    margin-bottom: 0;
  `,
);

export default {
  id,
  title,
};
