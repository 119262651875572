import { isNil } from 'ramda';
import { type NotificationSettingsFieldsFragment } from '~/graphql/types';
import { EmailNotificationsFormData } from '..';

export const asEmailNotificationsFormData = (
  notificationSettings: NotificationSettingsFieldsFragment,
): EmailNotificationsFormData => {
  const {
    dailyDigest,
    task: { newUnassigned, gotAssignedToMyOffices, gotAssignedToMe },
    app,
    form,
  } = notificationSettings;

  return {
    dailyDigest: !isNil(dailyDigest) ? dailyDigest : true,

    // TASK
    taskNewUnassigned: !isNil(newUnassigned) ? newUnassigned : true,
    taskGotAssignedToMyOffices: !isNil(gotAssignedToMyOffices)
      ? gotAssignedToMyOffices
      : true,
    taskGotAssignedToMe: !isNil(gotAssignedToMe) ? gotAssignedToMe : true,

    app: !isNil(app) ? app : true,
    form: !isNil(form) ? form : true,

    /** These fields are not implemented on the backend, only added to call the update mutation successfully */
    contactGotAssignedToMyOffices: false,
    contactGotAssignedToMe: false,
  };
};

export const emailNotificationsFormDataToMutation = (
  formNewData: EmailNotificationsFormData,
) => {
  const {
    dailyDigest,
    taskGotAssignedToMe,
    taskNewUnassigned,
    taskGotAssignedToMyOffices,
    app,
    form,
  } = formNewData;

  return {
    notificationSettings: {
      dailyDigest,
      task: {
        newUnassigned: taskNewUnassigned,
        gotAssignedToMyOffices: taskGotAssignedToMyOffices,
        gotAssignedToMe: taskGotAssignedToMe,
      },
      app,
      form,

      contact: {
        gotAssignedToMyOffices: false,
        gotAssignedToMe: false,
      },
    },
  };
};
