import { uniq } from 'ramda';
import {
  type GeoJsonFeature__Input,
  type WorkingAreas__Input,
} from '~/graphql/types';
import getGeometryFromClientGeometry from '../getGeometryFromClientGeometry';
import type { Client_WorkingAreas } from '..//getClientWorkingAreas';

export type WorkingAreaInput = {
  inclusive: Client_WorkingAreas;
  exclusive: Client_WorkingAreas;
};

const featuresToInput = (
  features: Client_WorkingAreas,
): WorkingAreas__Input => {
  const input = features.reduce(
    (acc, curr) => {
      if (curr.feature.properties) {
        const inputKey: keyof WorkingAreas__Input = curr.feature.properties
          .inclusive
          ? 'inclusive'
          : 'exclusive';

        const geometry = getGeometryFromClientGeometry(curr.feature.geometry);
        if (geometry) {
          const currFeature: GeoJsonFeature__Input = {
            geometry,
          };

          if (inputKey === 'exclusive') {
            acc.exclusive.push({ feature: currFeature });
          } else {
            acc.inclusive.push({ feature: currFeature, userId: curr.userId });
          }
        }
      }
      return acc;
    },
    {
      exclusive: [],
      inclusive: [],
    } as WorkingAreas__Input,
  );

  return {
    exclusive: uniq(input.exclusive),
    inclusive: uniq(input.inclusive),
  };
};

export default featuresToInput;
