import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import type {
  FormBuilder_Event,
  FormBuilder_EventNode,
  Maybe,
} from '~/graphql/types';
import type { EditProps } from '../..';
import Dropdown, { type OptionOf } from '~/components/molecule/Dropdown';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import EditableText from '~/components/organism/EditableText';
import { Heading3, Heading4 } from '~/components/atom/Typography';
import { isNil } from 'ramda';
import FieldMapperRow from './components/FieldMapperRow';
import TipBanner from '~/components/organism/TipBanner';
import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import { interactionState } from '../../../../state';
import { eventsState, nodeById } from '../../../../state/nodesAndEvents';
import BaseEventCard from '~/components/template/EventTimelineV2/components/BaseEventCard';
import getNewEventTemplate from '../../../../utils/getNewEventTemplate';

const text = {
  content: 'Edit Event node',
  fields: 'Velden',
  eventLabel:
    'Selecteer een event template om velden aan je event toe te voegen',
  editEventTemplate: 'Wijzig event template',
  noEventSelected: 'Selecteer een event om waarde aan toe te kennen',
  tipHeader: 'Een waarde toewijzen aan een veld',
  tipBody:
    "Selecteer een waarde uit een van de vorige pagina's. Deze waarde zal gebruikt worden wanneer het event aangemaakt wordt. Deze velden zijn te gebruiken in de Contact filters en Flow condities.",
};

export type EventNodeFormState = FormBuilder_EventNode;

type Props = EditProps<EventNodeFormState>;

const EditNodeEvent: React.FCC<Props> = ({ id, onSave, onCancel }) => {
  const [events, setEvents] = useRecoilState(eventsState);
  const setInteraction = useSetRecoilState(interactionState);
  const node = useRecoilValue(nodeById(id)) as Maybe<FormBuilder_EventNode>;
  const [formState, setFormState] = useState<EventNodeFormState>({
    id,
    __typename: 'FormBuilder_EventNode',
    formBuilderEventId: node?.formBuilderEventId ?? '',
    name: node?.name ?? '',
    mapping: node?.mapping ?? [],
    defaultNext: node?.defaultNext,
  });

  const options = getOptionsFromEvents(events);
  const selectedOptionIndex = options.findIndex(
    ({ payload }) => payload === formState.formBuilderEventId,
  );
  const selectedEvent = events[selectedOptionIndex];

  // If all searchable fields are deleted, make sure there is one when we open this modal
  useEffect(() => {
    if (options.length === 0) {
      setEvents(prev => [...prev, getNewEventTemplate()]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length]);

  return (
    <Container>
      <JustificationContainer
        direction="column"
        gap="m"
        padding={['xl']}
        style={{ flexGrow: 1 }}
      >
        <InnerContainer direction="column" width="100%" height="100%">
          <JustificationContainer
            direction="column"
            gap="m"
            width="100%"
            margin={[null, null, 'm', null]}
          >
            <EditableText
              text={formState.name}
              onSave={updatedName =>
                setFormState(prev => ({ ...prev, name: updatedName }))
              }
              as="h1"
            />
          </JustificationContainer>
          <JustificationContainer direction="column" gap="m" width="100%">
            <TipBanner
              id="field-mapping"
              headerText={text.tipHeader}
              dismissible={false}
              margin={[null]}
            >
              {text.tipBody}
            </TipBanner>
            <JustificationContainer width="100%" gap="s" align="end">
              <Dropdown
                label={text.eventLabel}
                options={options}
                selectedOptionIdx={selectedOptionIndex}
                onChange={option => {
                  setFormState(prev => ({
                    ...prev,
                    formBuilderEventId: option.option.payload,
                  }));
                }}
              />
              <Button
                icon="clock"
                label={text.editEventTemplate}
                size="medium"
                onClick={() => {
                  setInteraction({
                    interactionType: 'edit-event',
                    subjectId: node?.formBuilderEventId ?? '',
                  });
                }}
              />
            </JustificationContainer>

            {isNil(selectedEvent) && (
              <JustificationContainer
                width="100%"
                align="center"
                justification="center"
              >
                <Heading3 margin={['xl', null]}>
                  {text.noEventSelected}
                </Heading3>
              </JustificationContainer>
            )}

            {!isNil(selectedEvent) && (
              <StyledBaseEventCard
                _v={1}
                __typename="Event_Contact_Generic"
                icon={{ __typename: 'Image', url: '' }}
                accountId=""
                sortingDate=""
                createdDate=""
                header={{ text: node?.name || 'Onbekend' }}
                body={
                  <JustificationContainer
                    padding={['base']}
                    direction="column"
                    width="100%"
                  >
                    <Heading4>{text.fields}</Heading4>
                    {selectedEvent.fields.map(field => (
                      <FieldMapperRow
                        nodeId={id}
                        key={field.key}
                        field={field}
                        eventId={id}
                        pointer={
                          formState.mapping.find(({ key }) => key === field.key)
                            ?.pointer
                        }
                        onSelect={(pointer: [string, string]) => {
                          setFormState(prev => {
                            const filteredMapping = prev.mapping.filter(
                              ({ key }) => key !== field.key,
                            );

                            return {
                              ...prev,
                              mapping: [
                                ...filteredMapping,
                                {
                                  __typename: 'FormBuilder_EventNode_Mapping',
                                  key: field.key,
                                  pointer,
                                },
                              ],
                            };
                          });
                        }}
                      />
                    ))}
                  </JustificationContainer>
                }
              />
            )}
          </JustificationContainer>
        </InnerContainer>
        <JustificationContainer
          width="100%"
          justification="space-between"
          align="center"
        >
          <TextButton
            label="Afbreken"
            appearance="danger"
            padding={[null]}
            onClick={onCancel}
          />
          <Button label="Opslaan" onClick={() => onSave(formState)} />
        </JustificationContainer>
      </JustificationContainer>
    </Container>
  );
};

const getOptionsFromEvents = (
  events: Array<FormBuilder_Event>,
): Array<OptionOf<FormBuilder_Event['id']>> =>
  events.map(event => ({
    key: event.id,
    label: event.name,
    payload: event.id,
  }));

const InnerContainer = styled(JustificationContainer)(
  () => css`
    height: 100%;
    max-height: 800px;
    width: 100%;
    flex: 1 0 100%;

    overflow-y: scroll;
    ${scrollBarStyles}
  `,
);

const Container = styled.div<{}>``;

const StyledBaseEventCard = styled(BaseEventCard)<{}>(
  ({ theme }) => css`
    overflow: unset;
    width: 70%;
    margin: ${theme.space('base')} auto;
  `,
);

export default EditNodeEvent;
