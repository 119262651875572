import React from 'react';
import MarkerDefinition from '~/components/atom/MarkerDefinition';

export type Props = {
  dataTestId?: string;
};

const BASE_EDGE_COLOR = '#b1b1b7';

const MarkerDefinitions: React.FCC<Props> = React.memo(() => (
  <svg>
    <defs>
      <MarkerDefinition id="arrow" color={BASE_EDGE_COLOR} />
    </defs>
  </svg>
));

export default MarkerDefinitions;
