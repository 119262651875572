import { Editor, Transforms } from 'slate';
import cleanedFilename from '~/util/cleanedFilename';
import { reporter } from '~/hooks/useErrorReporter';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { getElementWithType, getFullRange } from '../..';
import { EMPTY_EDITOR_VALUE } from '../../../constants';

const resetEditor = (editor: DHEditor, customElements?: Array<ELEMENTS>) => {
  const sigEl = getElementWithType({
    editor,
    type: ELEMENTS.SIGNATURE,
    at: getFullRange(editor),
  });

  Editor.withoutNormalizing(editor, () => {
    // loop delete all
    editor.children.map(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = EMPTY_EDITOR_VALUE;

    // if there is a signature element in the editor do not remove it & keep its content
    if (customElements?.includes(ELEMENTS.SIGNATURE)) {
      if (!sigEl) {
        reporter.captureException(
          new Error(
            `Signature element is not found in ${cleanedFilename(__filename)}`,
          ),
          'warning',
        );
        return;
      }

      editor.children = [
        ...EMPTY_EDITOR_VALUE,
        sigEl?.element,
        ...EMPTY_EDITOR_VALUE,
      ];
    }

    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point };
  });
};

export default resetEditor;
