import React from 'react';
import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  src: string;
  alt?: string;

  /** Restrict the image within the container to max width, px value */
  maxWidth?: number;
  withoutShadow?: boolean;
  maxHeight?: number;
} & HTMLAttributes<HTMLImageElement>;

const Screenshot: React.FCC<Props> = ({
  dataTestId,
  src,
  alt = 'Screenshot',
  maxWidth,
  withoutShadow = false,
  maxHeight,
  ...rest
}) => (
  <JustificationContainer
    justification="center"
    align="center"
    dataTestId={dataTestId}
  >
    <Img
      src={src}
      alt={alt}
      $maxWidth={maxWidth}
      $withoutShadow={withoutShadow}
      $maxHeight={maxHeight}
      {...rest}
    />
  </JustificationContainer>
);

const Img = styled.img<{
  $maxWidth?: number;
  $withoutShadow: boolean;
  $maxHeight?: number;
}>(
  ({ theme, $maxWidth, $withoutShadow, $maxHeight }) => css`
    width: 100%;
    border-radius: ${theme.getTokens().border.radius.base};
    box-shadow: ${$withoutShadow
      ? 'unset'
      : theme.getTokens().boxShadow.around};
    max-width: ${$maxWidth ? `${$maxWidth}px` : 'auto'};
    max-height: ${$maxHeight ? `${$maxHeight}px` : 'auto'};
    height: 100%;
    object-fit: contain;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc(calc(9 / 16) * 100%);
    }
  `,
);

export default Screenshot;
