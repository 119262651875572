/* eslint-disable no-console */
import React from 'react';
import { FlowAction } from '~/graphql/types';
import ConditionEditor from '../ConditionEditorV2';
import { Props as FormProps } from '../ActionForm';
import { ClientFlowActionIfElse } from '~/graphql/types.client';
import { Body } from '~/components/atom/Typography';
import TrueOrFalseLabel from '../../../Builder/components/TrueOrFalseLabel';

export type Props = FormProps & {
  dataTestId?: string;
  action: ClientFlowActionIfElse;
  outerContainer: HTMLElement | null;
};

const IfElseAction: React.FCC<Props> = ({
  action,
  outerContainer,
  opts,
  onChange,
}) => (
  <div>
    <Body margin={[null, null, 'm', null]}>
      Voeg één of meer condities toe, waarop de flow controleert. Op basis van
      deze condities gaat de flow verder in&nbsp;
      <TrueOrFalseLabel type="true" />
      &nbsp;of&nbsp;
      <TrueOrFalseLabel type="false" />.
    </Body>
    <ConditionEditor
      actionId={action.id}
      action={FlowAction.IfElse}
      hasTriggers={false}
      conditionExpression={action.condition}
      opts={{
        ...opts,
        text: {
          newCondition: '',
          emptyCondition: 'Selecteer een conditie...',
          newTrigger: 'Voeg een conditie toe',
          emptyTrigger: 'Selecteer een conditie...',
          addValue: '+ Waarde toevoegen',
        },
      }}
      outerContainer={outerContainer}
      onChange={nextConditionExpression =>
        onChange({
          ...action,
          condition: nextConditionExpression,
        })
      }
    />
  </div>
);

export default IfElseAction;
