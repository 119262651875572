import React from 'react';
import TEST_ID from './index.testid';
import { Body } from '~/components/atom/Typography';

type Props = {
  children: string;
};
const ErrorLabel = ({ children }: Props) => (
  <Body data-testid={TEST_ID.CONTAINER} color={{ group: 'danger' }}>
    {children}
  </Body>
);

export default ErrorLabel;
