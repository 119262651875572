/**
 * Returns true if the given coordinate has valid long lat values
 * @param { [number, number]} coordinate - Coordinate
 */
const isValidCoordinate = (coordinate: [number, number]): boolean => {
  // Convention is that the first value is longitude and the second value is latitude
  const long = coordinate[0];
  const lat = coordinate[1];

  return long < 180 && long > -180 && lat < 90 && lat > -90;
};

export default isValidCoordinate;
