import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import Catalog from '~/Catalog';
import { useGetAccountQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import BrandBlocks from './components/BrandBlocks';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import createPageTitle from '~/util/createPageTitle';

export type Props = RouteComponentProps;

const text = {
  pageTitle: Catalog.brandLabel,
};

const Brand: React.FCC<Props> = ({}) => {
  const { id: accountId } = useCurrentAccount();

  const {
    data: accountData,
    loading: accountLoading,
    error: accountError,
  } = useGetAccountQuery({
    variables: { accountId },
  });

  if (accountLoading) {
    return (
      <ContentContainerDefault>
        <MetaTags>
          <title>{createPageTitle(text.pageTitle)}</title>
        </MetaTags>

        <DatHuisLoading />
      </ContentContainerDefault>
    );
  }

  if (accountError) {
    return (
      <ContentContainerDefault>
        <MetaTags>
          <title>{createPageTitle(text.pageTitle)}</title>
        </MetaTags>

        <AppErrorScreen />
      </ContentContainerDefault>
    );
  }

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{createPageTitle(text.pageTitle)}</title>
      </MetaTags>
      <OverviewListHeader title={text.pageTitle} />
      <BrandBlocks accountData={accountData} />
    </ContentContainerDefault>
  );
};

export default Brand;
