import type { SaveBarMessage } from '~/components/organism/NewSaveBar/components/MessagesContainer';
import type { Props as MasterProps } from '~/components/template/MasterDetail/index';

const getIssuesForMasterDetail = (
  errors: Array<SaveBarMessage<{ path: string }>>,
): MasterProps['issues'] =>
  errors.reduce((acc, error) => {
    const { path } = error;
    if (!acc[path]) {
      acc[path] = 1;
    } else {
      acc[path]++;
    }
    return acc;
  }, {});

export default getIssuesForMasterDetail;
