type BorderRadiusMap = {
  s: string;
  base: string;
  m: string;
  full: string;
};

type BorderWidthMap = {
  s: string;
  base: string;
};

export type BorderMap = {
  width: BorderWidthMap;
  radius: BorderRadiusMap;
};

const border: BorderMap = {
  width: {
    s: '1px',
    base: '2px',
  },
  radius: {
    s: '3px',
    base: '5px',
    m: '10px',
    full: '50%',
  },
};

export type BorderArgument = keyof BorderMap;
export type BorderRadius = keyof BorderRadiusMap;
export type BorderWidth = keyof BorderWidthMap;
export type SystemBorder = typeof border;
export default border;
