import React from 'react';
import BlockLayout from '../BlockLayout';
import { useRecoilValue } from 'recoil';
import { formState as formStateAtom } from '~/components/page/Forms/components/Builder/state';
import {
  FormBuilder_Ui_Alignment,
  type FormBuilder_ScreenNode_Block,
  type FormData_Ui_RichText,
} from '~/graphql/types';
import { isNil } from 'ramda';

import Divider from '~/components/atom/Divider';
import InputLabel from '~/components/atom/InputLabel';
import RichTextEditor from './components/RichTextEditor';
import BlockAlignment from '../BlockAlignment';
import LocaleIcon from '~/components/atom/Icon/components/LocaleIcon';
import { localeToIntlKey } from '../../constants';

const text = {
  nl: 'Opgemaakte paragraaf',
  en: 'Formatted paragraph',
};

export type Props = {
  /** Update block */
  onChange: (block: FormBuilder_ScreenNode_Block) => void;

  block: FormData_Ui_RichText;
  nodeId: string;
};

const RichText: React.FCC<Props> = ({ onChange, nodeId, block }) => {
  const formState = useRecoilValue(formStateAtom);

  if (isNil(formState?.style.availableLocale)) return null;

  return (
    <BlockLayout nodeId={nodeId} blockKey={block.key}>
      {formState.style.availableLocale.map((locale, index) => {
        const localeKey = localeToIntlKey[locale];

        return (
          <>
            <InputLabel
              label={
                <>
                  {text[localeKey]} <LocaleIcon locale={locale} />
                </>
              }
            />
            <RichTextEditor
              key={`${block.key}-${localeKey}`}
              value={block.content[localeKey] || ''}
              onChange={updatedValue => {
                onChange({
                  ...block,
                  key: block.key,
                  content: {
                    ...block.content,
                    [localeKey]: updatedValue,
                  },
                });
              }}
            />

            <BlockAlignment
              alignment={block.alignment ?? FormBuilder_Ui_Alignment.Left}
              onChange={alignment => {
                onChange({
                  ...block,
                  alignment,
                });
              }}
            />

            {index !== formState.style.availableLocale.length - 1 && (
              <Divider />
            )}
          </>
        );
      })}
    </BlockLayout>
  );
};

export default RichText;
