import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionContactDeleteTag } from '~/graphql/types.client';

/**
 *
 * @param {ClientFlowActionContactDeleteTag} action - ContactDeleteTag client action
 * keywords:
 */
const serializeContactDeleteTagAction = (
  action: ClientFlowActionContactDeleteTag,
): FlowV2_Action__Input => {
  const { id, accountId, flowBlueprintId, parentIds, name } = action;

  return {
    Contact: {
      DeleteTag: { id, accountId, flowBlueprintId, parentIds, name },
    },
  };
};

export default serializeContactDeleteTagAction;
