import React from 'react';
import styled, { css } from 'styled-components';
import Sweep from './components/Sweep';
import Header from './components/Header';
import useMeasure from '~/hooks/useMeasure';
import { animated, useSpring } from 'react-spring';
import useMenuOptions from './hooks/useMenuOptions';
import Link from '~/components/molecule/Link';
import Icon from '~/components/atom/Icon';
import { navigate } from '@gatsbyjs/reach-router';

type Props = {
  onNavigate: () => void;
};

const SettingsMenu: React.FCC<Props> = ({ onNavigate }) => {
  const menuOptions = useMenuOptions();
  const { bounds, ref } = useMeasure<HTMLUListElement>();
  const [styles] = useSpring(() => ({
    from: {
      transform: 'translateX(-40px)',
      opacity: 0,
    },
    to: {
      transform: 'translateX(0px)',
      opacity: 1,
    },
    config: { tension: 170, friction: 26 },
  }));

  return (
    <Container style={{ ...styles, minHeight: bounds.height }}>
      <Sweep />
      <Header />
      <ItemsContainer ref={ref}>
        {menuOptions.map(({ key, label, icon, payload }) => (
          <ListItem
            key={key}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              onNavigate();
              return navigate(payload);
            }}
          >
            {icon && <Icon name={icon.name} size="m" />}
            <Link to={payload}>{label}</Link>
          </ListItem>
        ))}
      </ItemsContainer>
    </Container>
  );
};

const MAX_WIDTH = 250;
const DISTANCE_FROM_SIDE = 24;

const Container = styled(animated.div)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('primary', 'dark')};
    color: ${theme.color('primary', 'text')};
    position: absolute;
    width: ${MAX_WIDTH}px;
    right: -${MAX_WIDTH + DISTANCE_FROM_SIDE}px;
    bottom: -1rem;
    border-radius: ${theme.getTokens().border.radius.base};
    box-shadow: ${theme.boxShadow('sleek')};
  `,
);

const ItemsContainer = styled.ul(
  ({ theme }) => css`
    padding: ${theme.space('m')} 0;
    margin: 0;
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${theme.space('xxxs')};
  `,
);

const ListItem = styled.li(
  ({ theme }) => css`
    position: relative;
    display: flex;
    gap: ${theme.space('base')};

    font-size: ${theme.fontSize('m')};
    padding: ${theme.space('xxs')} 0 ${theme.space('xxs')} ${theme.space('s')};

    cursor: pointer;

    a,
    a:visited {
      width: 100%;
      height: 100%;
      color: ${theme.color('primary', 'text')};
      text-decoration: none;

      transition: all 0.2s ease-out;
    }

    svg {
      transition: all 0.2s ease-out;
    }

    &:before {
      content: '';
      border-left: 4px solid transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      transition: all 0.2s ease-out;
    }

    &:hover {
      &:before {
        border-left-color: ${theme.color('secondary')};
      }

      a,
      a:visited {
        color: ${theme.color('secondary')};
      }

      svg {
        color: ${theme.color('secondary', 'light')};
      }
    }
  `,
);

export default SettingsMenu;
