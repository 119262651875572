import { OfficeInList } from '../../components/FormContainer';

export const convertOfficesList = (user): Array<OfficeInList> => {
  if (user == null) {
    return [];
  }

  return user.Offices.map(office => {
    const { address, email, id, name, phone, relation } = office;

    return {
      email,
      id,
      name,
      phone,
      relation,
      address: address == null ? null : address,
    };
  });
};
