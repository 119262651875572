import { FormApi } from 'final-form';
import { isEmpty, omit } from 'ramda';
import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import styled, { css } from 'styled-components';
import Catalog from '~/Catalog';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import Field from '~/components/organism/Forms/Field';
import Form from '~/components/organism/Forms/Form';
import FormUtils from '~/components/organism/FormUtils';
import IBANInput from '~/components/molecule/IBANInput';
import forceBEFormat from '~/components/molecule/IBANInput/utils/forceBEFormat';
import Input from '~/components/molecule/Input';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import { Body } from '~/components/atom/Typography';
import {
  useGetWalletSettingsQuery,
  useUpdateWalletSettingsMutation,
  WalletSettings__Input,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import leftPad from '~/util/leftPad';
import WalletBalance from './components/WalletBalance';

import TEST_ID from './index.testid';
import useUserRights from '~/hooks/useUserRights';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import validateWalletFields from './utils/validateWalletFields';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import createPageTitle from '~/util/createPageTitle';
import FormSaveBar from '~/components/organism/SaveBar/components/FormSaveBar';

const text = {
  pageTitle: Catalog.walletLabel,
  title: Catalog.walletLabel,
  accountSettingsLabel: 'Instellingen uitbetaling',
  description:
    'Pas hier je rekeninggegevens aan. Deze worden gebruikt voor uitbetaling van je wallet.',

  balanceLabel: 'Saldo',

  totalKickback: 'totale omzet',

  accountNameLabel: 'Naam van rekeninghouder',
  emailLabel: 'Facturatie email',
  ibanLabel: 'IBAN',

  success: 'Wijzigingen opgeslagen',
  error: 'Er is iets misgegaan bij het opslaan van wijzigingen',
};

const Wallet: React.FC<RouteComponentProps> = () => {
  const addToast = useAddToast();
  const viewingModeProps = useViewingModeProps();

  const { update: mayUpdate } = useUserRights({ category: 'Wallet' });
  const { id: accountId } = useCurrentAccount();
  const { data, loading } = useGetWalletSettingsQuery({
    variables: {
      accountId,
    },
  });
  const [updateWalletSetting, { loading: updateLoading }] =
    useUpdateWalletSettingsMutation();

  if (loading || !data) return <DatHuisLoading />;

  const disabled = !mayUpdate || loading || updateLoading;

  const onSubmit = async (
    formValues: Record<string, any>,
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>,
  ) => {
    const update: WalletSettings__Input = {};

    if (
      formValues.iban?.length > 0 &&
      formValues.iban.substr(formValues.iban.length - 4) !==
        data.getWalletSettings.ibanLast4
    ) {
      update.iban = forceBEFormat(formValues.iban);
    }

    if (formValues.accountName !== data.getWalletSettings.accountName) {
      update.accountName = formValues.accountName;
    }

    if (formValues.email !== data.getWalletSettings.email) {
      update.email = formValues.email;
    }

    if (isEmpty(update)) {
      return form.restart(formValues);
    }

    await updateWalletSetting({
      variables: {
        accountId,
        update,
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        addToast([formatToastMessage(text.error, 'danger')]);
        return;
      }

      if (data) {
        addToast([formatToastMessage(text.success, 'success')]);
        form.restart({
          ...omit(['__typename'], data.updateWalletSettings),
          iban: leftPad(18, data.updateWalletSettings.ibanLast4),
        });
      }
    });
  };

  return (
    <ContentContainerDefault maxContentWidth="1300px">
      <MetaTags>
        <title>{createPageTitle(text.pageTitle)}</title>
      </MetaTags>
      <OverviewListHeader title={text.title} />
      <GridContainer>
        <AnimatedBlock title={text.accountSettingsLabel}>
          <br />
          <Body>{text.description}</Body>
          <br />
          <Form
            onSubmit={onSubmit}
            validate={validateWalletFields}
            initialValues={{
              ...omit(['__typename'], data.getWalletSettings),
              iban: leftPad(18, data.getWalletSettings.ibanLast4) || null,
            }}
          >
            {({ form, submitError, valid }) => (
              <form
                onSubmit={event => {
                  if (event) event.preventDefault();
                  return form.submit();
                }}
              >
                {submitError ? (
                  <div data-testid={TEST_ID.ERROR_MESSAGE}>{submitError}</div>
                ) : null}
                <Field name="accountName">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      label={{ text: text.accountNameLabel }}
                      large
                      type="text"
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [error]
                          : undefined
                      }
                      disabled={disabled}
                      {...viewingModeProps}
                      {...input}
                    />
                  )}
                </Field>
                <br />
                <Field name="email">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      label={{ text: text.emailLabel }}
                      large
                      type="email"
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [error]
                          : undefined
                      }
                      disabled={disabled}
                      {...viewingModeProps}
                      {...input}
                    />
                  )}
                </Field>
                <br />
                <Field name="iban">
                  {({ input, meta: { initial, error, active } }) => (
                    <IBANInput
                      label={text.ibanLabel}
                      large
                      type="text"
                      disabled={disabled}
                      {...input}
                      previousValue={initial}
                      value={input.value === initial ? null : input.value}
                      externalErrors={
                        FormUtils.showError(error, active) ? [error] : undefined
                      }
                      showValidation={active}
                      {...viewingModeProps}
                    />
                  )}
                </Field>
                <br />
                <FormSaveBar inBlockComponent disableSave={!valid} />
              </form>
            )}
          </Form>
        </AnimatedBlock>
        <BalanceDetailBlock title={text.balanceLabel}>
          <WalletBalance label={text.totalKickback} />
        </BalanceDetailBlock>
      </GridContainer>
    </ContentContainerDefault>
  );
};

const GridContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: ${theme.space('xxl')};
  `,
);

const BalanceDetailBlock = styled(AnimatedBlock)`
  max-width: unset;
`;

export default Wallet;
