import React, { useEffect } from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import { isNil, map, omit, pluck } from 'ramda';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import Link from '~/components/molecule/Link';
import {
  useDeleteZapierTriggerMutation,
  useGetZapierApiKeysQuery,
  useGetZapierTriggersQuery,
  useInsertZapierTriggerMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import OverviewTemplate from '../OverviewTemplate';
import { LINK_PREFIX } from '../Wizard';
import TEST_ID from './index.testid';

export type Props = {} & RouteComponentProps;

const text = {
  header: 'Uitgaande koppelingen',
  body: (
    <>
      Met een uitgaande koppeling bepaal je welke data je verstuurt naar Zapier.
      Standaard worden de NAWTE en tags van het contact verstuurd.
      <br />
      <br />
      In een uitgaande koppeling kan je extra velden toevoegen om te versturen
      met een flow.
      <br />
      <br />
      Voeg een uitgaande koppeling toe{' '}
      <Link
        dataTestId={TEST_ID.TRIGGERS_WIZARD_LINK}
        to={`${LINK_PREFIX}/add-zapier-trigger`}
      >
        vanuit een template.
      </Link>
    </>
  ),
  noConnections: 'Je hebt nog geen uitgaande koppelingen voor Zapier.',
  tableHeader: 'Uitgaande koppelingen',
  addButtonLabel: 'Koppeling toevoegen',
  tableHeaders: {
    name: 'Koppeling naam',
    fields: 'Velden',
    flows: 'In flows',
    zaps: 'In zaps',
  },
  tip: (
    <>
      Lees hoe je de koppeling moet instellen in onze{' '}
      <Link to="https://help.dathuis.nl/">kennisbank</Link>. Kom je er niet uit?
      &nbsp;
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
  failedToDelete:
    'Helaas is het verwijderen van de koppeling niet gelukt, probeer het later opnieuw.',
  failedToCopy:
    'Helaas is het kopiëren van de koppeling niet gelukt, probeer het later opnieuw.',
};

const Triggers: React.FCC<Props> = ({}) => {
  const addToast = useAddToast();
  const { id: accountId } = useCurrentAccount();
  const { data, updateQuery, loading } = useGetZapierTriggersQuery({
    variables: {
      accountId,
    },
  });
  const [deleteTrigger, { loading: deleteLoading }] =
    useDeleteZapierTriggerMutation();
  const [insertTrigger, { loading: insertLoading }] =
    useInsertZapierTriggerMutation();

  const {
    data: apiKeysData,
    loading: apiKeysLoading,
    error: apiKeysError,
  } = useGetZapierApiKeysQuery({
    variables: { accountId },
  });

  useEffect(() => {
    const isConnectedKey = apiKeysData?.getZapierApiKeys.some(
      key => !isNil(key.lastConnectedAt),
    );

    if (!apiKeysLoading && !apiKeysError && !isConnectedKey) {
      void navigate(`${LINK_PREFIX}/`);
    }
  }, [apiKeysData?.getZapierApiKeys, apiKeysLoading, apiKeysError]);

  const rows = isNil(data)
    ? []
    : data.getZapierTriggers.map(({ name, fields, id }) => ({
        id,
        name,
        fields: pluck('label', fields).join(', '),
      }));

  const onFailure = (msg: string) => {
    addToast([formatToastMessage(msg, 'danger')]);
  };

  return (
    <OverviewTemplate
      data={rows}
      text={text}
      tipId="triggers-tip"
      loading={loading || insertLoading || deleteLoading}
      onNew={() => navigate('/-/apps/zapier/triggers/new')}
      onEdit={id => navigate(`/-/apps/zapier/triggers/${id}`)}
      onCopy={rowId => {
        const seed = data?.getZapierTriggers.find(({ id }) => id === rowId);

        if (!seed) {
          return addToast([formatToastMessage(text.failedToCopy, 'danger')]);
        }

        return insertTrigger({
          variables: {
            accountId,
            name: `Kopie ${seed.name}`,
            fields: map(omit(['__typename']), seed.fields),
          },
        }).then(({ data: update, errors }) => {
          if (isNil(update) || errors?.length) {
            return onFailure(text.failedToCopy);
          }

          return updateQuery(prev => ({
            ...prev,
            getZapierTriggers: [
              ...(data?.getZapierTriggers ?? []),
              update.insertZapierTrigger,
            ],
          }));
        });
      }}
      onDelete={rowId =>
        deleteTrigger({ variables: { accountId, id: rowId } }).then(
          ({ data: update, errors }) => {
            if (isNil(update) || errors?.length) {
              return onFailure(text.failedToCopy);
            }
            return updateQuery(prev => ({
              ...prev,
              getZapierTriggers: prev.getZapierTriggers.filter(
                ({ id }) => id !== rowId,
              ),
            }));
          },
        )
      }
    />
  );
};

export default Triggers;
