import { flatten, pluck } from 'ramda';
import { ClientFlowAction } from '~/graphql/types.client';
import { reporter } from '~/hooks/useErrorReporter';
import getChildActions from '~/components/page/Automation/v2/components/Builder/utils/getChildActions';
import { PathType } from '../../../../../IfElseCard/types';
import getDirectChildActionsIds from '~/components/page/Automation/v2/components/Builder/utils/getDirectChildActionIds';

type Args = {
  actions: Array<ClientFlowAction>;
  subjectAction: ClientFlowAction;
  options: { pathType: PathType | null };
};
/**
 * Delete IfElse action and reassign all the ids
 * @param {Array<ClientFlowAction>} actions - Actions
 * @param {ClientFlowAction} subjectAction - Action to delete
 * @param {{ pathType: PathType | null }} options - Path to save in the action, if null, save none
 * keywords: delete if else action
 */
const deleteIfElseAction = ({
  actions,
  subjectAction,
  options,
}: Args): {
  actions: Array<ClientFlowAction>;
  deletedActionId: string | null;
  childActionsToDelete: Array<string>;
} => {
  if (subjectAction.__typename !== 'FlowV2_Action_IfElse')
    return { actions, deletedActionId: null, childActionsToDelete: [] };
  let parentId: string | null = null;

  const childActionIdsToDelete: Array<string> = [];
  let childIdToSave: string | null = null;
  let childIdToDelete: string | null = null;

  if (options.pathType === PathType.True) {
    childIdToSave = subjectAction.trueChildId || null;
    childIdToDelete = subjectAction.falseChildId || null;
  }

  if (options.pathType === PathType.False) {
    childIdToSave = subjectAction.falseChildId || null;
    childIdToDelete = subjectAction.trueChildId || null;
  }

  const mutableActions = [...actions];

  // Delete parent's action child if parent is an ifElse action
  for (const id of subjectAction.parentIds) {
    const parentIdx = mutableActions.findIndex(a => a.id === id);
    if (parentIdx >= 0) {
      const parentAction = mutableActions[parentIdx];
      // Which child is being deleted from the parent IfElse action
      const ifElseKey =
        parentAction.__typename === 'FlowV2_Action_IfElse' &&
        parentAction.trueChildId === subjectAction.id
          ? 'trueChildId'
          : 'falseChildId';

      const updatedParentAction: ClientFlowAction =
        parentAction.__typename === 'FlowV2_Action_IfElse'
          ? {
              ...parentAction,
              [ifElseKey]: childIdToSave,
            }
          : parentAction;

      parentId = updatedParentAction.id;
      mutableActions[parentIdx] = updatedParentAction;
    }
  }

  const childActions = getChildActions(subjectAction, mutableActions);

  // Both true and false children
  const allChildIds = pluck('id', childActions);

  if (options.pathType !== null) {
    if (childIdToDelete) {
      const childIndexToDelete = mutableActions.findIndex(
        a => a.id === childIdToDelete,
      );

      if (childIndexToDelete >= 0) {
        const childToDelete = mutableActions[childIndexToDelete];
        // Get all direct child actions of the childToDelete and add them to an array for deletion
        const childrenToDeleteIds = getDirectChildActionsIds({
          actions: mutableActions,
          action: childToDelete,
        });

        const actionsToDelete = flatten([childrenToDeleteIds, childIdToDelete]);
        childActionIdsToDelete.push(...actionsToDelete);
      }
    }
  } else {
    // This block of code identifies child actions of the IfElse action
    // that are not connected to any other actions apart from the IfElse action itself.
    // These child actions are marked for deletion.
    childActions.forEach(a => {
      if (a.__typename === 'FlowV2_Action_Start') return;

      const secondaryParents = a.parentIds.filter(
        parentId =>
          !allChildIds.includes(parentId) && parentId !== subjectAction.id,
      );
      if (secondaryParents.length === 0) childActionIdsToDelete.push(a.id);
    });
  }

  const actionsToUpdate = childActions
    // Filter and update child actions that are not marked for deletion
    // by removing the parent IDs associated with deleted actions.
    .reduce((acc, a) => {
      if (
        a.__typename === 'FlowV2_Action_Start' ||
        childActionIdsToDelete.includes(a.id)
      ) {
        return acc;
      }

      const filteredParentIds = a.parentIds?.filter(
        parentId =>
          !childActionIdsToDelete.includes(parentId) &&
          parentId !== subjectAction.id,
      );

      if (a.id === childIdToSave) {
        acc.push({
          ...a,
          parentIds: [subjectAction.parentIds[0], ...filteredParentIds],
        });
      } else {
        acc.push({
          ...a,
          parentIds: filteredParentIds,
        });
      }

      return acc;
    }, [] as Array<ClientFlowAction>)
    // Update the child IDs of IfElse actions if child is marked for deletion by setting the child IDs to null
    .map(a => {
      if (a.__typename === 'FlowV2_Action_IfElse') {
        const removeTrueChild =
          a.trueChildId && childActionIdsToDelete.includes(a.trueChildId);
        const removeFalseChild =
          a.falseChildId && childActionIdsToDelete.includes(a.falseChildId);

        return {
          ...a,
          trueChildId: removeTrueChild ? null : a.trueChildId,
          falseChildId: removeFalseChild ? null : a.falseChildId,
        };
      } else return a;
    });

  const updatedChildrenAmount = actionsToUpdate.length;
  const deletedChildrenAmount = childActionIdsToDelete.length;
  const allChildrenAmount = allChildIds.length;

  if (updatedChildrenAmount + deletedChildrenAmount !== allChildrenAmount) {
    const err = new Error(
      `Something went wrong while deleting IfElse action ${subjectAction.id}
      Updated children amount: '${updatedChildrenAmount}' |
      Deleted children amount: '${deletedChildrenAmount}'|
      Total children amount: '${allChildrenAmount}' |
      Actions: ${JSON.stringify(actions, null, 2)}
      `,
    );
    reporter.captureException(err, 'fatal');

    throw err;
  }

  const nextActions = mutableActions
    .filter(({ id }) => id !== subjectAction.id && !allChildIds.includes(id))
    .concat(actionsToUpdate);

  return {
    actions: nextActions,
    deletedActionId: parentId,
    childActionsToDelete: childActionIdsToDelete,
  };
};

export default deleteIfElseAction;
