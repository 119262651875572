import type { ActivityV2 } from '~/components/template/EventTimelineV2/types';
import type { TaskListType } from '../../types';
import sortAndGroupTasks from '../sortAndGroupTasks';
import inListType from '../inListType';
import type { GroupedActivities } from '../../state';

type Args = {
  tasks: Array<ActivityV2>;
  selectedListType: TaskListType;
  userId?: string | null;
  officeId: string;
};

/** Taking following parameters
 * @param (Array of ActivityV2) - tasks
 * @param (TaskListType) - selectedListType
 *
 * this util filters the tasks and then grouped them by 'dueDate' for opened tasks
 * or by 'closedDate' for closed tasks
 */
const processOfficeTasks = ({
  tasks,
  userId,
  officeId,
  selectedListType,
}: Args): GroupedActivities => {
  const filtered = tasks.reduce<Array<ActivityV2>>((filtered, task) => {
    if (
      isForOffice(task, officeId) &&
      isForUser(task, userId) &&
      inListType(task, selectedListType)
    ) {
      filtered.push(task);
    }

    return filtered;
  }, []);

  const sortedAndGroupedTasks = sortAndGroupTasks(filtered, selectedListType);

  return sortedAndGroupedTasks;
};

const isForUser = (
  task: ActivityV2,
  filteredUserId: string | null | undefined,
): boolean =>
  !('userId' in task) ||
  !filteredUserId ||
  ('userId' in task && task.userId === filteredUserId);

const isForOffice = (
  task: ActivityV2,
  filteredOfficeId: string | null | undefined,
): boolean =>
  !('officeId' in task) ||
  !filteredOfficeId ||
  ('officeId' in task && task.officeId === filteredOfficeId);

export default processOfficeTasks;
