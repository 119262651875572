export enum Operator {
  EQUALS = 'eq',
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  GREATER_THAN_EQUAL = 'gte',
  LESS_THAN_EQUAL = 'lte',
}

export type NumberFilter = {
  negate: boolean;
  operator: Operator;
  value: number;
};
