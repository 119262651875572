import { equals } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { Option } from '~/components/molecule/Dropdown';
import SelectBlock from '~/components/molecule/SelectBlock';

export type Props = {
  options: Array<Option>;
  selectedOption?: Option | null;
  onSelect: (option: Option) => void;
  wrap?: boolean;
};

const SelectProduct: React.FCC<Props> = ({
  dataTestId,
  options,
  selectedOption,
  onSelect,
  wrap = true,
}) => (
  <JustificationContainer
    dataTestId={dataTestId}
    gap="m"
    wrap={wrap === true ? 'wrap' : 'nowrap'}
    margin={[null, null, 'm', null]}
    width="100%"
  >
    {options.map(option => (
      <WithoutMarginSelectBlock
        key={option.key}
        type="radio"
        checkboxTitle={option.label}
        description={option.description}
        checked={equals(selectedOption?.payload, option.payload)}
        onClick={() => onSelect(option)}
      />
    ))}
  </JustificationContainer>
);

const WithoutMarginSelectBlock = styled(SelectBlock)`
  margin: 0;
`;

export default SelectProduct;
