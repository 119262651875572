import React from 'react';

export const asPriceElement = (
  amount: number,
  hideCents?: boolean,
  locale?: string,
): React.ReactNode => <>{asPriceString(amount, hideCents, locale)}</>;

/**
 * Converts amount in cents to euro
 *
 * Use useMoneyFormatter hook where possible
 */
export const asPriceString = (
  amount: number,
  hideCents?: boolean,
  locale?: string,
): string => {
  const toCalculateWith = Math.abs(amount);
  const euros = Math.floor(toCalculateWith / 100);
  const cents = toCalculateWith % 100;
  const negativeString = amount < 0 ? '- ' : '';

  if (hideCents === true) {
    return `${negativeString}\u20AC ${euros.toLocaleString(locale || 'nl-NL')}`;
  }

  // \u20AC = euro
  return `${negativeString}\u20AC ${euros.toLocaleString(
    locale || 'nl-NL',
  )},${cents.toString().padStart(2, '0')}`;
};

// This does not remove the last 2 digits
export const moneyFormatter = (
  number: number,
  locale: string = 'nl-NL',
): string =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
