import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  GO_TO_APP_SETTINGS_BUTTON: `${cleanedFilename(
    __filename,
  )}-go-to-app-settings-button`,
  DEACTIVATE_BUTTON: `${cleanedFilename(__filename)}-deactivate-button`,
  CONTINUE_WIZARD_BUTTON: `${cleanedFilename(
    __filename,
  )}-continue-wizard-button`,
};
