import React from 'react';
import styled, { css } from 'styled-components';
import Button, { Props as ButtonProps } from '~/components/atom/Button';

export type Props = {
  buttons: Array<ButtonProps>;
};

const UnauthNav: React.FCC<Props> = ({ dataTestId, buttons, ...rest }) => (
  <Container data-testid={dataTestId} {...rest}>
    {buttons.map((buttonProps, index) => (
      <Button key={index} {...buttonProps} />
    ))}
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.space('xxl')};
    right: ${theme.space('xxl')};
  `,
);

export default UnauthNav;
