import {
  text,
  Opts,
  GetLabelForRepReturnType,
  getPathRepresentation,
} from '..';
import type { FlowAction, Flow___ArgumentFragment } from '~/graphql/types';
import getPathForPointer from '../../../../Selector/utils/getPathForPointer';
import type { PrimitiveInput } from '../../../../Selector/utils/getPrimitiveInput';
import { assertNever } from '~/util/assertNever';
import type { Flow___ConditionPartial } from '../../..';
import { linkSelectorText } from '../../../components/SelectorContainerV2/components/LinkSelector';
import renderHandlebarsTemplate from '~/util/handlebars';

export type RenderArgumentContext = {
  action: FlowAction;
  type?: 'condition' | 'trigger';
};

type Args = {
  condition: Flow___ConditionPartial;
  argument: Flow___ArgumentFragment;
  variable: number;
  rep: string;
  primitiveInput?: PrimitiveInput;
  ctx: RenderArgumentContext;
};
const getRenderedArgument = (
  { condition, argument, variable, rep, primitiveInput, ctx }: Args,
  { instanceMap, subjectMap, directoryMap }: Opts,
) => {
  switch (argument.__typename) {
    case 'Flow___Argument_Pointer': {
      const path = getPathForPointer(argument.pointer, {
        instanceMap,
        subjectMap,
        directoryMap,
      });

      if (path.error != null) {
        return {
          error: text.invalidVariable,
        };
      }

      return {
        result: renderHandlebarsTemplate(rep, {
          [variable]: getPathRepresentation(path.fullResult),
          ctx,
        }),
        type: { type: 'argument', __typename: argument.__typename },
      };
    }
    case 'Flow___Argument_AWSDateTime':
      return fillVariable({
        primitiveInput,
        rep,
        value: argument.value_date,
        variable,
        ctx,
        argument,
      });
    case 'Flow___Argument_Boolean':
      return fillVariable({
        primitiveInput,
        rep,
        value: argument.value_boolean,
        variable,
        ctx,
        argument,
      });
    case 'Flow___Argument_Float':
      return fillVariable({
        primitiveInput,
        rep,
        value: argument.value_float,
        variable,
        ctx,
        argument,
      });
    case 'Flow___Argument_Integer':
      return fillVariable({
        primitiveInput,
        rep,
        value: argument.value_integer,
        variable,
        ctx,
        argument,
      });
    case 'Flow___Argument_String':
      return fillVariable({
        primitiveInput,
        rep,
        value: argument.value_string,
        variable,
        condition,
        ctx,
        argument,
      });
    case 'Flow___Argument_File':
      return fillVariable({
        primitiveInput,
        rep,
        value: argument.value_file.filename,
        variable,
        ctx,
        argument,
      });

    default:
      return assertNever(argument);
  }
};

type FillVariableArgs = {
  rep: string;
  variable: number;
  value: number | string | boolean | null | undefined;
  primitiveInput: PrimitiveInput | undefined;
  condition?: Flow___ConditionPartial;
  ctx: RenderArgumentContext;
  argument: Flow___ArgumentFragment;
};
const fillVariable = ({
  rep,
  variable,
  value,
  primitiveInput,
  condition,
  ctx,
  argument,
}: FillVariableArgs): GetLabelForRepReturnType => {
  if (primitiveInput?.__typename === 'FlowData___PrimitiveListInput') {
    // This was a list input, find the label
    const selected = primitiveInput.options.find(option => {
      switch (option.__typename) {
        case 'FlowData___PrimitiveListInputOption_Boolean':
          return option.value_boolean === value;
        case 'FlowData___PrimitiveListInputOption_Int':
          return option.value_integer === value;
        case 'FlowData___PrimitiveListInputOption_String':
          return option.value_string == value;
        default:
          return assertNever(option);
      }
    });

    if (!selected)
      return {
        error: text.invalidVariable,
        type: { type: 'argument', __typename: argument.__typename },
      };

    return {
      result: renderHandlebarsTemplate(rep, {
        [variable]: `"${selected.label ?? ''}"`,
        ctx,
      }),
      type: { type: 'argument', __typename: argument.__typename },
    };
  }

  /** Custom text */
  if (
    condition?.type === 'Condition_Email_LinkClicked' &&
    variable === 1 &&
    !value
  ) {
    return {
      result: renderHandlebarsTemplate(rep, {
        [variable]: `"${linkSelectorText.anyLink}"`,
        ctx,
      }),
      type: { type: 'argument', __typename: argument.__typename },
    };
  }

  const label = () => {
    switch (argument.__typename) {
      case 'Flow___Argument_Pointer':
      case 'Flow___Argument_String':
        return `"${value ?? ''}"`;
      case 'Flow___Argument_Integer':
      case 'Flow___Argument_Float':
        return `${value ?? ''}`;
      case 'Flow___Argument_Boolean':
        return `${value ? 'Ja' : 'Nee'}`;
      case 'Flow___Argument_AWSDateTime':
      case 'Flow___Argument_File':
        return `${value ?? ''}`;
    }
  };

  return {
    result: renderHandlebarsTemplate(rep, {
      [variable]: label,
      ctx,
    }),
    type: { type: 'argument', __typename: argument.__typename },
  };
};

export default getRenderedArgument;
