import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  SAVE_BUTTON: `${cleanedFilename(__filename)}-save-button`,
  SAVE_BAR_CHANGES: `${cleanedFilename(__filename)}-save-bar-changes`,
  CANCEL_BUTTON: `${cleanedFilename(__filename)}-cancel-button`,
  GO_BACK_BUTTON: `${cleanedFilename(__filename)}-go_back-button`,
  CHANGE_COUNT_CONTAINER: `${cleanedFilename(
    __filename,
  )}-change-count-container`,
};
