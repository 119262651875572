import type {
  GetWidgetSettingsQuery,
  WidgetSettingsAppConfiguration__Input,
  WidgetSettings__Input,
} from '~/graphql/types';

const serializeInput = (
  update: GetWidgetSettingsQuery['getWidgetSettings'],
  appsConfigurationInput: Array<WidgetSettingsAppConfiguration__Input>,
  logoS3Key?: string,
  imageS3Key?: string,
): WidgetSettings__Input => {
  const input: WidgetSettings__Input = {
    googleAnalytics4: update.googleAnalytics4 ?? null,
    positions: {
      desktop: update.positions.desktop,
      mobile: update.positions.mobile,
    },
    appearances: {
      desktop: update.appearances.desktop,
      mobile: update.appearances.mobile,
    },
    style: {
      primary: {
        background: update.style.primary.background,
        color: update.style.primary.color,
      },
      secondary: {
        background: update.style.secondary.background,
        color: update.style.secondary.color,
      },
    },
    zIndex: update.zIndex,
    highlightImage: imageS3Key
      ? {
          s3key: imageS3Key,
        }
      : null,
    logo: logoS3Key ? { s3key: logoS3Key } : null,
    appsConfiguration: appsConfigurationInput,
  };

  return input;
};

export default serializeInput;
