import React, { useEffect } from 'react';
import Loading from '~/components/atom/Loading';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import useLogout from '~/hooks/useLogout';

const Logout: React.FCC<RouteComponentProps> = () => {
  const logout = useLogout();

  useEffect(() => {
    void logout();
  }, [logout]);

  return <Loading />;
};

export default Logout;
