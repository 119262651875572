import { useContext } from 'react';
import ViewingMode from '~/contexts/ViewingMode';
import { ImpersonationViewingMode } from '~/graphql/types';

const useViewingMode = (): ImpersonationViewingMode => {
  const { viewingMode } = useContext(ViewingMode);

  return viewingMode;
};

export default useViewingMode;
