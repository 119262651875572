import type { SelectedBrandSettings } from '~/components/template/BrandSettingsModal';
import type {
  AppBbWaardecheckContainer__Input,
  AppVboWaardecheckContainer__Input,
} from '~/graphql/types';
import hasValue from '~/util/hasValue';

type ReturnType = {
  logoImage?: {
    s3key: string;
    url: string;
  } | null;
  colors: {
    primary:
      | AppBbWaardecheckContainer__Input
      | AppVboWaardecheckContainer__Input;
    secondary:
      | AppBbWaardecheckContainer__Input
      | AppVboWaardecheckContainer__Input;
  } | null;
};

const brandValuesToWaardeheckValues = (
  values: SelectedBrandSettings,
): ReturnType => {
  const updatedValues: ReturnType = {
    logoImage: null,
    colors: null,
  };

  if (hasValue(values.colors)) {
    updatedValues['colors'] = Object.keys(values.colors).reduce(
      (acc, curr) => {
        if (acc && !acc[curr]) {
          acc![curr] = values.colors![curr];
        }

        return acc;
      },
      {} as ReturnType['colors'],
    );
  }

  if (
    hasValue(values.logo) &&
    typeof values.logo === 'object' &&
    's3key' in values.logo
  ) {
    updatedValues['logoImage'] = values.logo;
  }

  return updatedValues;
};

export default brandValuesToWaardeheckValues;
