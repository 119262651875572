import md5 from 'md5';
import { isString, isObject, isArray } from 'lodash';

const DELIMITER = '#';

export const generateHash = (node: React.ReactNode): string => {
  if (!isObject(node)) return md5(node + '');
  if (isArray(node)) return md5(node.map(generateHash).join(DELIMITER));
  if (!('type' in node)) return md5('');

  let representation = '';
  const { type, props } = node;

  if (isString(type)) {
    /** type might also be a fragment in which case it is a Symbol */
    representation = isString(type) ? type + DELIMITER : DELIMITER;
  }

  if (props.children) {
    representation += generateHash(props.children);
  }

  if (props.href) {
    representation += props.href + DELIMITER;
  }

  if (props.src) {
    representation += props.src + DELIMITER;
  }

  if (props.dangerouslySetInnerHTML?.__html) {
    representation += props.dangerouslySetInnerHTML?.__html + DELIMITER;
  }

  return md5(representation);
};
