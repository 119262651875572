import type {
  FlowData___SubjectFragment,
  Flow___SubjectId,
} from '~/graphql/types';

const generateSubjectId = (
  subject: FlowData___SubjectFragment,
): Flow___SubjectId => ({
  type: subject.type,
  typeId: subject.typeId,
  __typename: 'Flow___SubjectId',
});

export default generateSubjectId;
