import React from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { AppValuationReportAddressPage_Update } from '~/graphql/types';
import TextAreaWithLabel from '../../components/TextAreaWithLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  label: 'Adres',
  mainText: 'Koptekst',
  subText: 'Subtekst',
  whyText: 'Waarom tekst',
  whenText: 'Wanneer tekst',
};

export type Props = RouteComponentProps & {
  dataTestId?: string;
  loading: boolean;
  updated?: AppValuationReportAddressPage_Update | null;
  onUpdate: (value: AppValuationReportAddressPage_Update) => void;
};

const Address: React.FCC<Props> = ({ updated, loading, onUpdate }) => {
  if (!updated) return null;

  const { mainHeader, subHeader, why, when } = updated;

  return (
    <AppDetailsContainer
      header="Adres"
      icon="map-pin"
      loading={loading}
      dataTestId="address-page"
    >
      <JustificationContainer direction="column" gap="base">
        <TextAreaWithLabel
          value={mainHeader?.text}
          label={text.mainText}
          onUpdate={value => onUpdate({ mainHeader: { text: value } })}
          dataTestId="text-edit-mainHeader-text"
        />
        <TextAreaWithLabel
          value={subHeader?.text}
          label={text.subText}
          onUpdate={value => onUpdate({ subHeader: { text: value } })}
          dataTestId="text-edit-subHeader-text"
        />
        <TextAreaWithLabel
          value={when?.text}
          label={text.whenText}
          onUpdate={value => onUpdate({ when: { text: value } })}
          dataTestId="text-edit-when-text"
        />
        <TextAreaWithLabel
          value={why?.text}
          label={text.whyText}
          onUpdate={value => onUpdate({ why: { text: value } })}
          dataTestId="text-edit-why-text"
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default Address;
