import type { DirectoryEntry, DirectoryMap } from '../getDirectory';
import { OuterContactFilterField } from '../getOuterFieldsByPath';

const generateNextForDirectory = (
  directory: DirectoryEntry | DirectoryMap,
): Array<OuterContactFilterField> => {
  const result: Array<OuterContactFilterField> = [];
  const dirEntries = Object.values(directory.children);

  result.push(
    ...directory.subjects.map(({ __typename, eventId, subjectId, label }) => ({
      __typename,
      eventId,
      subjectId,
      label,
    })),
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const { children, subjects, ...rest } of dirEntries) {
    result.push(rest);
  }

  return result;
};

export default generateNextForDirectory;
