import { useState } from 'react';

import type {
  OnChangeFunctionOf,
  OptionOf,
  SelectedOptionOf,
} from '~/components/molecule/Dropdown';

type ReturnProps<T> = [
  number | null,
  OnChangeFunctionOf<T>,
  T | null,
  (idx: number | null) => void,
];

/**
 *
 * A Hook to help use the Dropdown with a specific payload. It will give the user:
 *  [0] - the currently selected index to send to the dropdown
 *  [1] - the onChange function to send to the dropdown
 *  [2] - the currently selected payload
 *  [3] - a function to specifically set a new selected idx
 *
 *  @param {Array<OptionOf<T>>} options - Array of dropdown options with typed payloads.
 *  @param {number | null} [initialSelectedIndex] - Optional initial index for the selected option.
 *  @param {(payload: T) => void} [onSelectedChange] - Optional callback called with the new payload on selection change.
 *
 *  @returns {[number | null, OnChangeFunctionOf<T>, T | null, (idx: number | null) => void]}
 *    - selected index, change handler, selected payload, and setter for selected index.
 */
const useDropdown = <T>(
  options: Array<OptionOf<T>>,
  initialSelectedIndex?: number | null,
  onSelectedChange?: (payload: T) => void,
): ReturnProps<T> => {
  const [selectedIdx, setSelectedIdxValue] = useState<number | null>(
    initialSelectedIndex != null &&
      initialSelectedIndex >= 0 &&
      initialSelectedIndex < options.length
      ? initialSelectedIndex
      : null,
  );

  const selectedPayload: T | null =
    selectedIdx != null && selectedIdx >= 0 && selectedIdx < options.length
      ? options[selectedIdx].payload
      : null;

  const setSelectedIdx = (idx: number | null) => {
    // Check if idx is within bounds before updating the selected index
    if (idx != null && idx >= 0 && idx < options.length) {
      setSelectedIdxValue(idx);

      if (onSelectedChange) {
        onSelectedChange(options[idx].payload);
      }
    } else {
      setSelectedIdxValue(null);
    }
  };

  const onChange = (selectedOption: SelectedOptionOf<T>) => {
    const { selectedOptionIdx } = selectedOption;

    // Check if selectedOptionIdx is within bounds before updating the selected index
    if (
      selectedOptionIdx !== selectedIdx &&
      selectedOptionIdx >= 0 &&
      selectedOptionIdx < options.length
    ) {
      setSelectedIdx(selectedOptionIdx);
    }
  };

  return [selectedIdx, onChange, selectedPayload, setSelectedIdx];
};

export const asDropdownOption = <T extends { name: string; id: string }>(
  item: T,
): OptionOf<T> => ({
  label: item.name,
  key: item.id,
  payload: item,
});

export default useDropdown;
