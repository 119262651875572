import { Option } from '~/components/molecule/SelectGroup';

export const pinOptions: Array<Option> = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
];
