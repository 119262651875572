import React from 'react';
import { animated, SpringValue } from 'react-spring';
import styled from 'styled-components';

export type Props = {
  /**
   * This is the input range that the interpolation expects. It needs to be the same length
   * as the output range otherwise the path will not be drawn if there is no output for a specifix index.
   */
  blobRange: Array<number>;
  x: SpringValue<number>;
  color?: string;
};

const Blobs: React.FCC<Props> = ({
  dataTestId,
  blobRange,
  x,
  color = 'white',
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <svg
      width="400"
      height="440"
      viewBox="-100 -100 200 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <animated.path
        d={x.to({
          range: blobRange,
          output: [
            'M44.7,-76.2C59,-69.3,72.2,-59.3,80.7,-46.1C89.2,-32.9,92.9,-16.4,92.6,-0.2C92.2,16.1,87.9,32.1,79.5,45.6C71.2,59,58.9,69.8,44.9,76.9C31,84.1,15.5,87.6,0,87.5C-15.4,87.4,-30.8,83.8,-44.6,76.6C-58.4,69.3,-70.4,58.5,-79.2,45.1C-87.9,31.8,-93.3,15.9,-92.9,0.2C-92.5,-15.4,-86.3,-30.9,-77.7,-44.4C-69,-57.9,-57.9,-69.4,-44.5,-76.8C-31.2,-84.2,-15.6,-87.4,-0.2,-87.1C15.3,-86.8,30.5,-83,44.7,-76.2Z',
            'M44.1,-75.9C58.5,-68,72.5,-59,81.6,-46.2C90.6,-33.3,94.8,-16.7,93.6,-0.7C92.4,15.3,85.8,30.5,77.3,44.3C68.8,58,58.2,70.3,45,78.1C31.8,85.8,15.9,89.2,-0.1,89.4C-16.2,89.6,-32.3,86.8,-45,78.7C-57.7,70.6,-67,57.4,-74.3,43.4C-81.5,29.4,-86.6,14.7,-87.3,-0.4C-88,-15.5,-84.2,-31,-76.9,-44.8C-69.6,-58.7,-58.9,-71,-45.4,-79.4C-32,-87.8,-16,-92.3,-0.6,-91.3C14.8,-90.3,29.7,-83.7,44.1,-75.9Z',
            'M46,-80.9C59.3,-71.9,69.8,-59.1,76.9,-45C84,-30.9,87.7,-15.4,87.8,0.1C87.9,15.6,84.4,31.1,77.2,45C70,59,59.1,71.3,45.7,78.9C32.2,86.5,16.1,89.3,-0.1,89.6C-16.4,89.8,-32.8,87.5,-46.3,80C-59.9,72.4,-70.7,59.7,-78,45.5C-85.3,31.3,-89.2,15.7,-89.5,-0.2C-89.7,-16,-86.4,-32,-78.9,-45.8C-71.3,-59.7,-59.7,-71.4,-45.8,-80.1C-32,-88.7,-16,-94.3,0.2,-94.6C16.3,-94.9,32.6,-89.8,46,-80.9Z',
            'M45.9,-80.2C59.4,-71.7,70.1,-59.2,78.2,-45.2C86.3,-31.1,91.8,-15.6,92.3,0.3C92.7,16.1,88.2,32.2,79.8,45.6C71.3,59,58.8,69.7,44.9,76.4C30.9,83.2,15.5,86,-0.5,86.9C-16.6,87.9,-33.1,86.9,-47.3,80.3C-61.4,73.7,-73.2,61.4,-81.2,47C-89.3,32.7,-93.6,16.3,-93.5,0C-93.5,-16.3,-89,-32.5,-80,-45.2C-71,-57.9,-57.5,-67,-43.4,-75.2C-29.3,-83.4,-14.7,-90.6,0.8,-91.9C16.2,-93.2,32.4,-88.7,45.9,-80.2Z',
            'M43.5,-75.9C56.9,-67.5,68.8,-57,76.4,-44C84,-31,87.3,-15.5,86.7,-0.3C86.1,14.8,81.6,29.6,74.2,43.1C66.8,56.5,56.7,68.5,43.9,75.8C31,83,15.5,85.4,-0.2,85.7C-15.9,86.1,-31.8,84.4,-44.7,77.2C-57.7,70.1,-67.5,57.4,-75.3,43.6C-83,29.8,-88.6,14.9,-89.7,-0.6C-90.8,-16.2,-87.4,-32.3,-79.8,-46.4C-72.3,-60.5,-60.5,-72.5,-46.5,-80.6C-32.5,-88.6,-16.3,-92.6,-0.6,-91.5C15,-90.4,30,-84.2,43.5,-75.9Z',
            'M44.4,-75.9C58.2,-69,70.3,-58.3,78.8,-45C87.3,-31.7,92.2,-15.9,91.8,-0.2C91.4,15.4,85.7,30.8,76.9,43.6C68.2,56.5,56.3,66.8,43,74.7C29.7,82.5,14.8,88,0,88C-14.8,88,-29.7,82.6,-43.8,75.1C-57.9,67.7,-71.2,58.3,-79.6,45.4C-88,32.6,-91.4,16.3,-89.9,0.8C-88.5,-14.6,-82.3,-29.3,-74.6,-43.3C-66.8,-57.2,-57.6,-70.5,-45,-78C-32.3,-85.5,-16.1,-87.1,-0.4,-86.4C15.3,-85.7,30.7,-82.7,44.4,-75.9Z',
          ],
        })}
        fill={color}
      />
    </svg>
  </Container>
);

const Container = styled.div<{}>`
  position: absolute;
  top: 0;
`;

export default Blobs;
