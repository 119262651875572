import { ActionListOption } from '~/components/bad/DataTables/components/ActionColumnCell';
import { TableHeaderDefinitionCellType } from '../../types.flow';

import cleanedFilename from '~/util/cleanedFilename';

export const ACTION_COLUMN_ACCESSOR = 'action-column';
type ActionHeaderCellOptions = {
  dataTestId?: string;
  minWidth?: number;
};
const ACTION_COLUMN_WIDTH = 20;
const asActionTableHeaderCell = ({
  dataTestId,
  minWidth,
}: ActionHeaderCellOptions): TableHeaderDefinitionCellType<
  Array<ActionListOption>
> => ({
  Cell: () => {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} Should not occur! Action rendering should be handled by the table`,
    );
  },
  Header: '',
  accessor: ACTION_COLUMN_ACCESSOR,
  dataTestId,
  disableResizing: true,
  disableSortBy: true,
  minWidth: minWidth || ACTION_COLUMN_WIDTH,
  maxWidth: ACTION_COLUMN_WIDTH,
  width: ACTION_COLUMN_WIDTH,
  hideTooltip: true,
});

export default asActionTableHeaderCell;
