import { WebSocketLink } from '@apollo/client/link/ws';
import { reporter } from '~/hooks/useErrorReporter';
import AppSyncSubscriptionClient from '../AppSyncSubscriptionClient';
import subscriptionMessage from './middleware/subscriptionMessage';

/** AppSync has a max timeout of 5 minutes */
const APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS = 5 * 60 * 1000;

/** If we are not using the subscription for WEBSOCKET_INACTIVITY_TIMEOUT, turn it off */
const WEBSOCKET_INACTIVITY_TIMEOUT = 1 * 60 * 1000;

const createSubscriptionWebsocketLink = async ({
  appSyncApiUrl,
  getJWTToken,
}): Promise<WebSocketLink | null> => {
  try {
    const appSyncApiHost = new URL(appSyncApiUrl).host;
    const token = await getJWTToken();
    const getAppSyncAuthorizationInfo = async () => ({
      host: appSyncApiHost,
      Authorization: token,
    });

    const appSyncSubscriptionClient = new AppSyncSubscriptionClient(
      `wss://${appSyncApiHost.replace(
        'appsync-api',
        'appsync-realtime-api',
      )}/graphql`,
      {
        timeout: APPSYNC_MAX_CONNECTION_TIMEOUT_MILLISECONDS,
        inactivityTimeout: WEBSOCKET_INACTIVITY_TIMEOUT,
        reconnect: true,
        lazy: true,
      },
      // We want to avoid expired authorization information being used but SubscriptionClient synchronously
      // instantiates websockets (on connection/reconnection) so the best we can do is schedule an async refresh
      // and suffer failed connection attempts until a fresh token has been retrieved
      getAppSyncAuthorizationInfo,
    );

    return new WebSocketLink(
      appSyncSubscriptionClient.use([
        subscriptionMessage(getAppSyncAuthorizationInfo),
      ]),
    );
  } catch (error) {
    reporter.captureException(error);
    return null;
  }
};

export default createSubscriptionWebsocketLink;
