import { navigate } from '@gatsbyjs/reach-router';
import React from 'react';
import CommandPalette from 'react-cmdk';
import Loading from '~/components/atom/Loading';
import { useGetFormsQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { pageSelector, type PalettePage } from '../../state';
import { useSetRecoilState } from 'recoil';
import Icon from '~/components/atom/Icon';
import { useTheme } from 'styled-components';

const PAGE_ID: PalettePage = 'open-forms';

const FormsPage: React.FC<{}> = () => {
  const theme = useTheme();
  const setPage = useSetRecoilState(pageSelector);
  const account = useCurrentAccount();
  const { data, loading } = useGetFormsQuery({
    variables: {
      accountId: account.id,
    },
  });

  if (loading) {
    return (
      <CommandPalette.Page id={PAGE_ID}>
        <Loading />
      </CommandPalette.Page>
    );
  }

  return (
    <CommandPalette.Page id={PAGE_ID} onEscape={() => setPage('root')}>
      <CommandPalette.List key={PAGE_ID} heading="Formulier openen">
        {data?.getForms.map(({ id, name, ...rest }, index) => (
          <CommandPalette.ListItem
            key={id}
            index={index}
            icon={() => (
              <Icon name="file-text" color={theme.color('secondary')} />
            )}
            onClick={() => {
              setPage('root');
              void navigate(`/-/forms/${id}`);
            }}
            showType={false}
            {...rest}
          >
            {name}
          </CommandPalette.ListItem>
        ))}
      </CommandPalette.List>
    </CommandPalette.Page>
  );
};

export default FormsPage;
