import React from 'react';
import styled, { css } from 'styled-components';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import { Heading4, Variant } from '~/components/atom/Typography';

export type Props = {
  header: string;
  linkText: string;
};

const ChatComponent: React.FCC<Props> = ({
  dataTestId,
  header,
  linkText,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <Heading4 variant={Variant.primary}>{header}</Heading4>
    <ChatLink linkText={linkText} />
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin: ${theme.space('l')} 0;
  `,
);

export default ChatComponent;
