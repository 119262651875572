import React from 'react';
import OpenTask from '../OpenTask';
import ClosedTask from '../ClosedTask';
import {
  ActivityFields_Task_Contact_Fragment,
  TaskStatus,
} from '~/graphql/types';
import { safeFormattedDateTime } from '~/util/date';

export type Props = Omit<ActivityFields_Task_Contact_Fragment, '__typename'> & {
  dataTestId?: string;
};

const TaskBody: React.FC<Props> = ({ ...rest }) => {
  const {
    description,
    dueDate,
    officeId,
    userId,
    status,
    closedBy,
    closedDate,
  } = rest;

  if (status === TaskStatus.Closed) {
    return <ClosedTask closedBy={closedBy} closedDate={closedDate} />;
  }

  const formattedDueDate = safeFormattedDateTime(new Date(dueDate)) ?? null;

  return (
    <OpenTask
      description={description}
      dueDate={formattedDueDate}
      officeId={officeId}
      userId={userId}
      contactName={rest.Contact?.name}
    />
  );
};

export default TaskBody;
