import type { Props } from '../..';

import React from 'react';
import styled, { css } from 'styled-components';
import Input from '~/components/molecule/Input';
import {
  ContactFiltersInputAppearances,
  ContactFiltersOptionsInputAppearances,
} from '~/graphql/types';
import DatePicker from '~/components/organism/DatePicker';
import Dropdown from '~/components/molecule/Dropdown';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import { reporter } from '~/hooks/useErrorReporter';

type InputProps = {
  input: Props['input'];
  value: Props['value'];
  inputErrors: Array<string>;
  onChange: (event: any) => void;
};
const InputForAppearance: React.FCC<InputProps> = ({
  value,
  input,
  inputErrors,
  onChange,
}) => {
  const asText = (value: Props['value']) => value?.toString() ?? '';
  switch (input.inputAppearance) {
    case ContactFiltersInputAppearances.Text:
      return (
        <StyledInput
          label={{ text: input.label }}
          onChange={event => onChange(event.target.value)}
          externalErrors={inputErrors}
          value={asText(value)}
          type="text"
        />
      );
    case ContactFiltersInputAppearances.Tag:
      return (
        <StyledInput
          label={{ text: input.label }}
          onChange={event => onChange(event.target.value)}
          externalErrors={inputErrors}
          value={asText(value)}
          type="text"
        />
      );
    case ContactFiltersInputAppearances.ContactId:
      return (
        <StyledInput
          label={{ text: input.label }}
          onChange={event => onChange(event.target.value)}
          externalErrors={inputErrors}
          value={asText(value)}
          type="text"
        />
      );
    case ContactFiltersInputAppearances.Date: {
      return (
        <div>
          <DatePicker
            label="Datum"
            value={asText(value)}
            onChange={event => onChange(event)}
          />
        </div>
      );
    }

    case ContactFiltersInputAppearances.Email:
      return (
        <StyledInput
          label={{ text: input.label }}
          onChange={event => onChange(event.target.value)}
          externalErrors={inputErrors}
          value={asText(value)}
          type="email"
        />
      );
    case ContactFiltersInputAppearances.Integer:
      return (
        <StyledInput
          label={{ text: input.label }}
          onChange={event => onChange(event.target.value)}
          externalErrors={inputErrors}
          value={parseInt(value?.toString() ?? '', 10)}
          type="number"
        />
      );

    case ContactFiltersInputAppearances.Phone:
      return (
        <StyledInput
          label={{ text: input.label }}
          onChange={event => onChange(event.target.value)}
          externalErrors={inputErrors}
          value={asText(value)}
          type="phone"
        />
      );

    case ContactFiltersInputAppearances.Postcode:
      return (
        <StyledInput
          label={{ text: input.label }}
          onChange={event => onChange(event.target.value)}
          externalErrors={inputErrors}
          value={asText(value)}
          type="postcode"
        />
      );

    case ContactFiltersInputAppearances.Boolean: {
      const formattedValue = !!value;
      return (
        <ToggleCheckbox
          label={
            value ? (
              <div>
                Nee/<SelectedBooleanLabel>Ja</SelectedBooleanLabel>
              </div>
            ) : (
              <div>
                <SelectedBooleanLabel>Nee</SelectedBooleanLabel>/Ja
              </div>
            )
          }
          value={formattedValue}
          onChange={() => onChange(!formattedValue)}
        />
      );
    }
    case ContactFiltersOptionsInputAppearances.List: {
      const selectedOptionIdx = input.options.findIndex(
        option => option.value === value,
      );
      return (
        <Dropdown
          error={inputErrors?.length > 0 ? inputErrors[0] : undefined}
          options={input.options.map(({ label, value }) => ({
            key: value,
            label,
            payload: value,
          }))}
          label={input.label}
          selectedOptionIdx={selectedOptionIdx}
          onChange={({ option }) => {
            onChange(option.payload);
          }}
        />
      );
    }

    default: {
      reporter.captureMessage(
        `No UI for this input type: ${JSON.stringify(input, null, 2)}`,
        'fatal',
      );
      return null;
    }
  }
};

const StyledInput = styled(Input)<{}>`
  width: 100%;
`;
const SelectedBooleanLabel = styled.span<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    font-size: ${theme.fs('l')};
    color: ${theme.color('primary')};
  `,
);

export default InputForAppearance;
