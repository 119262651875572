import type { FormBuilder_ScreenNode_Block } from '~/graphql/types';

// typeguards
const isContactBlock = (
  typename: FormBuilder_ScreenNode_Block['__typename'],
) => {
  switch (typename) {
    case 'FormData_Input_Contact_Email':
    case 'FormData_Input_Contact_Name':
    case 'FormData_Input_Contact_Phone': {
      return true;
    }

    default: {
      return false;
    }
  }
};

export default isContactBlock;
