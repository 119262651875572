import styled, { css } from 'styled-components';
import React from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  eventsState,
  nodesSelector,
} from '~/components/page/Forms/components/Builder/state/nodesAndEvents';
import useConfirmModal from '~/hooks/useConfirmModal';
import type { FormBuilder_Event } from '~/graphql/types';
import { interactionState } from '~/components/page/Forms/components/Builder/state';
import EntryCard from '~/components/molecule/EntryCard';
import isEventUsed from './utils/isEventUsed';

const text = {
  content: 'Content',
  deleteWarningLabels: {
    title: 'Pas op!',
    message:
      'Deze gebeurtenis wordt momenteel nog gebruikt in dit formulier. Door het verwijderen van deze gebeurtenis is het formulier niet meer valide.',
    buttonConfirmTitle: 'Verwijder',
  },
};
type Props = { event: FormBuilder_Event };
const EventEntry: React.FCC<Props> = ({ event }) => {
  const [nodes, setNodesState] = useRecoilState(nodesSelector);
  const setEvents = useSetRecoilState(eventsState);
  const setInteraction = useSetRecoilState(interactionState);

  const onEditEvent = (eventId: string) => {
    setInteraction({
      interactionType: 'edit-event',
      subjectId: eventId,
    });
  };

  const onDelete = () => {
    setEvents(prev => prev.filter(({ id }) => id !== event.id));

    // Remove deleted event template from all the event nodes that use it
    setNodesState(prev =>
      prev.map(node => {
        if (
          node.__typename === 'FormBuilder_EventNode' &&
          node.formBuilderEventId === event.id
        ) {
          return { ...node, formBuilderEventId: '' };
        }
        return node;
      }),
    );
  };

  const { setShowModal, modal } = useConfirmModal({
    level: 'danger',
    labels: text.deleteWarningLabels,
    buttons: [
      {
        label: text.deleteWarningLabels.buttonConfirmTitle,
        onClick: onDelete,
      },
    ],
  });

  return (
    <>
      {modal}
      <Container key={event.id}>
        <EntryCard
          sentiment="positive"
          onClick={() => onEditEvent(event.id)}
          title={event.name}
          buttons={[
            {
              icon: 'pencil',
              appearance: 'primary',
              onClick: e => {
                e.stopPropagation();
                onEditEvent(event.id);
              },
            },
            {
              icon: 'delete',
              appearance: 'danger',
              onClick: e => {
                e.stopPropagation();

                // If the event is still used by any node, we show confirm modal
                if (isEventUsed(nodes, event.id)) {
                  return setShowModal(true);
                }
                // Event is no longer used so can just be removed
                return onDelete();
              },
            },
          ]}
        />
      </Container>
    </>
  );
};

const Container = styled.li(() => css``);

export default EventEntry;
