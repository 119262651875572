import React from 'react';

import StripeWrapper from '~/hocs/withStripeWrapper/components/StripeWrapper';

export type Props = {};

const withStripeWrapper = <ComponentProps extends object>(
  WrappedComponent: React.ComponentType<ComponentProps>,
) => {
  const WithStripeElements: React.FC<ComponentProps> = props => (
    <StripeWrapper>
      <WrappedComponent {...props} />
    </StripeWrapper>
  );

  return WithStripeElements;
};

export default withStripeWrapper;
