import React from 'react';
import FoldableCardBlock from '../FoldableCardBlock';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useConfirmModal, { type ModalLabels } from '~/hooks/useConfirmModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { useMarkContactForDeletionMutation } from '~/graphql/types';
import FullWidthButton from '~/components/atom/FullWidthButton';
import markedForDeletionContactList from '../../util/markedForDeletionContactList';
import { navigate } from '@gatsbyjs/reach-router';

type Props = {
  contactId: string;
  blockMarketing?: boolean | null;
};

const text = {
  title: 'Gevarenzone',
  deleteContactLabel: 'Contact verwijderen',
  deleteContactHeader: 'Contact verwijderen?',
  deleteContactMessage:
    'Weet je zeker dat je contact wilt verwijderen? Dit kan niet ongedaan gemaakt worden.',
  deleteConfirmButtonLabel: 'Ja dit contact verwijderen!',
};

const deleteContactLabels: ModalLabels = {
  title: text.deleteContactHeader,
  message: text.deleteContactMessage,
};

const ContactDangerZone: React.FC<Props> = ({ contactId }) => {
  const account = useCurrentAccount();

  const [markContactForDeletion] = useMarkContactForDeletionMutation({});

  const onConfirmBlockDelete = async () => {
    const { data, errors } = await markContactForDeletion({
      variables: {
        accountId: account.id,
        id: contactId,
      },
    });

    if (!errors && data) {
      markedForDeletionContactList.writeMarkedForDeletionContact(contactId);

      return navigate('/-/contacts');
    }
  };

  const { modal: deleteConfirmModal, setShowModal: setShowDeleteConfirmModal } =
    useConfirmModal({
      labels: deleteContactLabels,
      buttons: [
        { label: text.deleteConfirmButtonLabel, onClick: onConfirmBlockDelete },
      ],
      level: 'danger',
    });

  return (
    <>
      {deleteConfirmModal}

      <FoldableCardBlock
        icon="alert-octagon"
        title={text.title}
        dataTestId={TEST_ID.CONTAINER}
      >
        <JustificationContainer
          width="100%"
          direction="column"
          gap="l"
          padding={['s', null]}
        >
          <JustificationContainer direction="column" width="100%">
            <FullWidthButton
              center
              label={text.deleteContactLabel}
              icon="trashcan"
              appearance="danger"
              ghost
              onClick={() => setShowDeleteConfirmModal(true)}
            />
          </JustificationContainer>
        </JustificationContainer>
      </FoldableCardBlock>
    </>
  );
};

export default ContactDangerZone;
