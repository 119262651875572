import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';

import { Heading1 } from '~/components/atom/Typography';
import { UnalignedButton } from '~/components/atom/Button';
import { isNil } from 'ramda';
import isPopupWindow from '~/util/isPopupWindow';
import { CALCULATED_MIN_HEIGHT } from '~/components/organism/NavigationFrame/constants';

const text = {
  close: 'Sluiten',
};

type Props = RouteComponentProps & {
  /** Error title to render. Last word is highlighted */
  title: string;

  /** The explanation underneath the title */
  explanation: string;

  /** Adds a button */
  button?: {
    label: string;
    onClick: () => void;
  };

  /** Data test id to show */
};
const FullPageInformation: React.FCC<Props> = ({
  explanation,
  button,
  title,
  dataTestId,
}) => {
  // highlighting last word works for all situations atm, if that is not the case later rewrite it
  const lastSpace = title.lastIndexOf(' ');
  const highlightedPart = lastSpace > 0 ? title.slice(lastSpace + 1) : '';

  const explanationTokens = explanation.split('\n');

  // if the window is a popup we do not want to allow the users to navigate anywhere
  const isPopup = isPopupWindow();

  let goToButtonComponent: ReactElement | null = null;
  if (!isNil(button)) {
    goToButtonComponent = (
      <UnalignedButton
        appearance="primary"
        size="medium"
        onClick={isPopup ? () => window.close() : () => button.onClick()}
        data-testid={'go-to-link-button'}
        label={isPopup ? text.close : button.label}
      />
    );
  }

  return (
    <Container data-testid={dataTestId || 'full-info-page'}>
      <TextContainer>
        <Heading1
          margin={[null]}
          dataTestId="full-page-title"
          highlight={highlightedPart}
          whiteSpace="nowrap"
        >
          {title}
        </Heading1>
        <SmallDescriptionText data-testid="full-page-explanation">
          {explanationTokens.map((explanationString, index) => (
            <p key={`explanation-index-${index}`}>{explanationString}</p>
          ))}
        </SmallDescriptionText>

        {goToButtonComponent}
      </TextContainer>
    </Container>
  );
};

// The text is not long, so don't make it span a whole page. 420 gave the best view for dutch
const MAX_ERROR_TEXT_WIDTH = '450px';
const SmallDescriptionText = styled.div<{}>`
  max-width: ${MAX_ERROR_TEXT_WIDTH};
  text-align: center;

  ${({ theme }) => css`
    margin: ${theme.space('m')} 0;
  `};
`;

const TextContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div<{}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${CALCULATED_MIN_HEIGHT};
`;

export default FullPageInformation;
