import type { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { type WorkingAreas } from '~/graphql/types';
import featureToClientFeature from '../featureToClientFeature';

export type Client_WorkingArea = {
  userId?: string | null;
  feature: Feature<Geometry, GeoJsonProperties>;
};

export type Client_WorkingAreas = Array<Client_WorkingArea>;
type Args = {
  workingAreas: WorkingAreas;
  officeId: string;
  readOnly?: boolean;
};

const getClientWorkingAreas = ({
  workingAreas,
  officeId,
  readOnly,
}: Args): Client_WorkingAreas => {
  const clientFeatures = [
    ...workingAreas.inclusive.map(area => ({
      userId: area.userId,
      feature: featureToClientFeature({
        feature: area.feature,
        inclusive: true,
        officeId,
        readOnly,
      }),
    })),
    ...workingAreas.exclusive.map(area => ({
      feature: featureToClientFeature({
        feature: area.feature,
        inclusive: false,
        officeId,
        readOnly,
      }),
    })),
  ];

  return clientFeatures;
};

export default getClientWorkingAreas;
