import hbsHelpers from './helpers';
import handlebars from 'handlebars';

const renderHandlebarsTemplate = (
  template: any,
  input: any,
  options: CompileOptions = { strict: false, noEscape: true },
) => {
  // register ALL helpers
  handlebars.registerHelper(hbsHelpers);

  return handlebars.compile(template, options)(input);
};

export default renderHandlebarsTemplate;
