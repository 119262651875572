import React from 'react';
import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router';
import DHRouter from '~/components/atom/DHRouter';
import { navigate } from '@gatsbyjs/reach-router';
import { withOverlay } from '~/components/organism/ModalsV2/Overlay';
import StepList from '~/components/organism/StepList';
import WizardLayout from '~/components/molecule/WizardLayout';
import AddEvent from './components/AddEvent';
import AddTrigger from './components/AddTrigger';
import FinalWizardStep from './components/FinalStep';
import Connect from './components/Connect';
import pathnameToStep from '~/util/pathnameToStep';

export type Props = {
  onClose: () => void;
} & RouteComponentProps;

export const LINK_PREFIX = '/-/apps/zapier/wizard';

const Wizard: React.FCC<
  // withOverlay will be overwriting some of the types
  Omit<Props, 'onClose'> & {
    onClose: () => false | void | undefined;
  }
> = ({ onClose }) => {
  const location = useLocation();

  const steps = [
    {
      id: 'connect-to-zapier',
      label: 'Verbinden met Zapier',
      disabled: false,
    },
    {
      id: 'add-zapier-trigger',
      label: 'Uitgaande koppelingen',
      disabled: false,
    },
    {
      id: 'add-zapier-event',
      label: 'Inkomende koppelingen',
      disabled: false,
    },
    {
      id: 'next-steps',
      label: 'Volgende stappen',
      disabled: false,
    },
  ];

  return (
    <WizardLayout header="Zapier koppeling">
      <StepList
        currentStep={pathnameToStep(
          location.pathname,
          steps,
          'connect-to-zapier',
        )}
        steps={steps}
      />
      <DHRouter>
        <Connect
          path="/"
          onClose={() => void navigate('/-/apps/setup/zapier')}
        />
        <AddTrigger
          disableClosing
          path="/add-zapier-trigger"
          onClose={onClose}
        />
        <AddEvent disableClosing path="/add-zapier-event" onClose={onClose} />
        <FinalWizardStep path="/next-steps" onClose={onClose} />
      </DHRouter>
    </WizardLayout>
  );
};

export default withOverlay<Props>(Wizard);
