/**
 * Calculates the size of the images and html and lets us know if it is too large
 */
const isOverMaxEmailSize = (
  html: string,
  imageLengths: Array<number>,
): boolean => {
  // https://stackoverflow.com/questions/5515869/string-length-in-bytes-in-javascript

  // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
  const m = encodeURIComponent(html).match(/%[89ABab]/g);
  const textSizeInUtf8Bytes = html.length + (m ? m.length : 0);

  const imagesSize = imageLengths.reduce((a, b) => a + b, 0);

  return textSizeInUtf8Bytes + imagesSize > 20000000;
};

export default isOverMaxEmailSize;
