import React from 'react';
import { isNil } from 'ramda';
import { useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import { Step as StepType } from '../..';
import Step from '../Step';

export type Props = {
  /** All steps including the disabled ones */
  steps: Array<StepType>;
  /** Index of currently active step, 0-based. Can only be one of the enabled steps. */
  currentStep: number | null;

  currentSubStep?: number | null;

  mainStepIndex?: number;
};

const Desktop: React.FCC<Props> = React.memo(
  ({
    dataTestId,
    steps,
    currentStep,
    currentSubStep,
    mainStepIndex,
    ...rest
  }) => {
    const transition = useTransition(steps, {
      config: { mass: 1, tension: 200, friction: 20 },
      from: { opacity: 0, transform: 'translateY(-30px)' },
      enter: { opacity: 1, transform: 'translateY(0px)' },
      delay: 100,
      keys: item => item.id,
    });

    return (
      <Container data-testid={dataTestId} {...rest} aria-hidden="true">
        {transition((style, item, _, index) => {
          const isOpen = currentStep === index;
          const isLastItem = steps.length - 1 === index;

          return (
            <RelativeWrapper>
              <Step
                isLastItem={isLastItem}
                currentStep={currentStep}
                mainStepIndex={mainStepIndex}
                item={item}
                index={index}
                key={item.id}
                style={style}
              />
              {!isNil(item.subSteps) &&
                item.subSteps.length !== 0 &&
                isOpen && (
                  <SubStepsContainer>
                    <Desktop
                      currentStep={currentSubStep ?? null}
                      steps={item.subSteps}
                      mainStepIndex={index}
                    />
                  </SubStepsContainer>
                )}
            </RelativeWrapper>
          );
        })}
      </Container>
    );
  },
);

const SubStepsContainer = styled.div(
  ({ theme }) => css`
    padding-left: ${theme.space('xxl')};
  `,
);

export const RelativeWrapper = styled.div`
  position: relative;
`;

const STEP_LIST_WIDTH = 270;
const Container = styled.ul<{}>(
  ({ theme }) => css`
    padding: 0;
    margin: 0;
    margin-right: ${theme.space('s')};
    text-decoration: none;
    width: ${STEP_LIST_WIDTH}px;
  `,
);

export default Desktop;
