import { ApolloError } from '@apollo/client';
import ErrorMessages from '~/ErrorMessages';
import ErrorTypes, { errorTypeFromMutationError } from '~/ErrorTypes';
import { getErrorMessages } from '~/util/errorHandling';
import { FormData } from '../..';
import Catalog from '~/Catalog';

const text = {
  errorMsg: Catalog.genericUnknownErrorMessage,
  invalidCredentials:
    'De combinatie van e-mailadres en wachtwoord is niet geldig.',
  alreadySyncedExplanation: (email: string | null) =>
    `Het e-mail account ${email || ''} is al gesynchroniseerd in het systeem.`,
};

/**
 * Returns corresponding error message for the mutation error
 */
const getSubmitError = (
  values: FormData,
  error?: ApolloError,
): string | null => {
  if (!error) return null;

  if (
    getErrorMessages(error).some(err =>
      err.startsWith(ErrorMessages.emailAlreadySynchronised),
    )
  ) {
    return text.alreadySyncedExplanation(values.email);
  } else if (
    errorTypeFromMutationError(error) ===
    ErrorTypes.customProviderSyncInvalidCredentials
  ) {
    return text.invalidCredentials;
  } else {
    return text.errorMsg;
  }
};

export default getSubmitError;
