import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  SAVE_BUTTON: `${cleanedFilename(__filename)}-save-button`,
  DEACTIVATE_BUTTON: `${cleanedFilename(__filename)}-deactivate-button`,
  FULL_OPTION: `${cleanedFilename(__filename)}-full-option`,
  LIMITED_TO_KENMERKEN_OPTION: `${cleanedFilename(
    __filename,
  )}-limited-to-kenmerken-option`,
};
