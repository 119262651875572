import React, { useEffect } from 'react';
import 'react-cmdk/dist/cmdk.css';
import CommandPalette, {
  filterItems,
  getItemIndex,
  type JsonStructure,
} from 'react-cmdk';
import { navigate } from '@gatsbyjs/reach-router';
import useNavigationItems from './hooks/useNavigationItems';
import useContactsItems from './hooks/useContactsItems';
import useTasksItems from './hooks/useTasksItems';
import useFlowsItems from './hooks/useFlowsItems';
import useFormsItems from './hooks/useFormsItems';
import useSettingsItems from './hooks/useSettingsItems';
import { isNil } from 'ramda';
import { useTheme } from 'styled-components';
import Icon from '~/components/atom/Icon';
import FlowsPage from './pages/FlowsPage';
import ContactSearchPage from './pages/ContactSearchPage';
import { paletteState } from './state';
import { useRecoilState } from 'recoil';
import FormsPage from './pages/FormsPage';

export type Props = {};
const CommandPaletteProvider: React.FCC<Props> = () => {
  const theme = useTheme();
  const [{ open, search, page }, setPaletteState] =
    useRecoilState(paletteState);
  const navigationItems = useNavigationItems();
  const contactsItems = useContactsItems();
  const tasksItems = useTasksItems();
  const flowsItems = useFlowsItems();
  const formsItems = useFormsItems();
  const settingsItems = useSettingsItems();

  const items: Array<JsonStructure[number] | null> = [
    navigationItems,
    contactsItems,
    tasksItems,
    flowsItems,
    formsItems,
    settingsItems,

    {
      heading: 'Utility',
      id: 'utils',
      items: [
        {
          id: 'log-out',
          children: 'Log out',
          icon: () => <Icon name="sign-out" color={theme.color('secondary')} />,
          showType: false,
          onClick: () => {
            void navigate('/logout');
          },
        },
      ],
    },
  ];

  const ensuredItems = items.filter(
    (x: any): x is JsonStructure[number] => !isNil(x),
  );

  const filteredItems = filterItems(ensuredItems, search, {
    filterOnListHeading: false,
  });

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        (navigator?.platform?.toLowerCase().includes('mac')
          ? e.metaKey
          : e.ctrlKey) &&
        e.key === 'k'
      ) {
        e.preventDefault();
        e.stopPropagation();

        setPaletteState(prev => ({ ...prev, open: !prev.open }));
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <CommandPalette
      onChangeSearch={val =>
        setPaletteState(prev => ({ ...prev, search: val }))
      }
      onChangeOpen={val =>
        setPaletteState(prev => ({ ...prev, open: val, page: 'root' }))
      }
      search={search}
      isOpen={open}
      page={page}
      css={`
        z-index: 99999;
      `}
    >
      <CommandPalette.Page id="root">
        {filteredItems.length ? (
          filteredItems.map(list => (
            <CommandPalette.List key={list.id} heading={list.heading}>
              {list.items.map(({ id, ...rest }) => (
                <CommandPalette.ListItem
                  key={id}
                  index={getItemIndex(filteredItems, id)}
                  showType={false}
                  icon={() => (
                    <Icon name="branches" color={theme.color('secondary')} />
                  )}
                  {...rest}
                />
              ))}
            </CommandPalette.List>
          ))
        ) : (
          <CommandPalette.FreeSearchAction />
        )}
      </CommandPalette.Page>
      <FlowsPage />
      <ContactSearchPage />
      <FormsPage />
    </CommandPalette>
  );
};

export default CommandPaletteProvider;
