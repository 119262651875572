import React from 'react';
import styled, { css } from 'styled-components';
import CallToActionBlock, {
  CTAIcon,
} from '~/components/organism/CallToActionBlock';
import { NotificationLevel } from '~/graphql/types';

type Props = {
  cta?: {
    label: string;
    onClick: () => void;
  };
  level: NotificationLevel;
  header?: string;
  htmlBody: string;
  onClose: () => void;
  key: string;
};

const getIconForWarning = (level: NotificationLevel): CTAIcon => {
  switch (level) {
    case NotificationLevel.Error:
      return { name: 'error', background: 'danger' };
    case NotificationLevel.Warning:
      return { name: 'triangle', background: 'warning' };
    case NotificationLevel.Info:
    default:
      return { name: 'info', background: 'info' };
  }
};

const WarningCTA: React.FCC<Props> = ({
  cta,
  level,
  header,
  htmlBody,
  onClose,
}) => {
  const icon = getIconForWarning(level);

  return (
    <StyledCTABlock
      button={
        cta
          ? {
              icon: 'arrowRight',
              label: cta.label,
              appearance: 'secondary',
              onClick: cta.onClick,
            }
          : undefined
      }
      header={header}
      onClose={onClose}
      align="center"
      padding={['base', 'm']}
      icon={icon}
    >
      <Body
        dangerouslySetInnerHTML={{
          __html: htmlBody,
        }}
      />
    </StyledCTABlock>
  );
};

const Body = styled.div(
  ({ theme }) => css`
    font-size: ${theme.fontSize('base')};
    font-weight: ${theme.fontWeight('regular')};
  `,
);

const StyledCTABlock = styled(CallToActionBlock)(
  ({ theme }) => css`
    width: 100%;
    a {
      color: ${theme.color('white')};
    }
  `,
);

export default WarningCTA;
