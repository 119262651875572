import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import useResizeObserver from '@react-hook/resize-observer';
import useDebouncedState from '~/hooks/useDebouncedState';
import useClick from '~/hooks/useClick';
import useMountedRef from '~/hooks/useMountedRef';

export type Props = {
  dataTestId?: string;

  /** Button or container clicked to open the Selector */
  parentRef: HTMLElement | null;

  /** A container's ref that can limit the positioning of Selector */
  rowContainerRef: HTMLElement | null;

  /** Function to close the selector */
  onClose: () => void;

  /** Children that receive the positioning props */
  children?: React.ReactNode;
};

const MAX_WIDTH = 600;

const PositionWrapperV2: React.FC<Props> = ({
  parentRef: relativeToElementRef,
  rowContainerRef: withinContainerRef,
  children,
  dataTestId,
  onClose,
}) => {
  const [ownContainerRef, setOwnContainerRef] = useMountedRef<HTMLDivElement>();
  useClick(() => onClose());

  const selectorRef = useRef<HTMLDivElement>(null);
  const [pointerOffset, setPointerOffset] = useState(23);
  const [pointerLocation, setPointerLocation] = useState<
    'top' | 'bottom' | null
  >(null);

  const [renderKey, setRenderKey] = useState(0);

  /** Triggers a re-render - important to handle resize events  */
  const [renderId, setRenderId] = useDebouncedState(0, { delay: 200 });

  const [maxWidth, setMaxWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const [leftOffset, setLeftOffset] = useState<number | null>(null);
  const [moveSelector, setMoveSelector] = useState<number | null>(null);

  useResizeObserver(withinContainerRef, () => {
    setRenderId(Date.now());
  });

  useEffect(() => {
    /** Do not run on initial render - abusing the height variable here */
    if (height === null) return;

    /**
     * In order to ensure that the underlying layout is
     * refreshed after a change in variable, we
     * adjust the used render key here.
     *
     * Every time the relativeToElementRef changes, we assume a change in
     * underlying data and enforce a re-render.
     */
    setRenderKey(renderKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relativeToElementRef]);

  useEffect(() => {
    if (!withinContainerRef) return;
    if (!relativeToElementRef) return;
    if (!ownContainerRef) return;

    const withinContainerBB = withinContainerRef.getBoundingClientRect();
    const relativeToElementBB = relativeToElementRef.getBoundingClientRect();

    const getPositionedParents = (
      element: HTMLElement | null,
    ): Array<HTMLElement> => {
      if (!element) return [];

      const next = element.parentElement;

      if (!next) return [];
      if (next === withinContainerRef) return [];

      if (getComputedStyle(next).position !== 'static') {
        return [next, ...getPositionedParents(next)];
      }

      return getPositionedParents(next);
    };

    /**
     * If we move our own container, this is the element
     * it gets layed out relative to.
     */
    const allParents = getPositionedParents(ownContainerRef);
    const [directParent] = allParents;
    const offsets = allParents.reverse().reduce(
      ({ last, x, y }, item) => {
        const current = item.getBoundingClientRect();
        return {
          x: x + (last.x - current.x),
          y: y + (last.y - current.y),
          last: current,
        };
      },
      { last: withinContainerRef.getBoundingClientRect(), x: 0, y: 0 },
    );

    const relativeElementToWithinContainer = {
      top: relativeToElementBB.top - withinContainerBB.top,
      bottom: withinContainerBB.bottom - relativeToElementBB.bottom,
      left: relativeToElementBB.left - withinContainerBB.left,
      right: withinContainerBB.right - relativeToElementBB.right,
    };

    const space = {
      aboveRelativeElement: relativeToElementBB.top - withinContainerBB.top,
      belowRelativeElement:
        withinContainerBB.bottom - relativeToElementBB.bottom,
      leftRelativeElement: relativeToElementBB.left - withinContainerBB.left,
      rightRelativeElement: withinContainerBB.right - relativeToElementBB.right,
    };

    const renderBelow = space.aboveRelativeElement < space.belowRelativeElement;

    if (renderBelow) {
      setPointerLocation('top');
      setHeight(Math.ceil(space.belowRelativeElement - 10));
      setMoveSelector(
        relativeElementToWithinContainer.top +
          relativeToElementBB.height +
          offsets.y,
      );
    } else {
      setPointerLocation('bottom');
      setHeight(Math.ceil(space.aboveRelativeElement - 10));
      setMoveSelector(
        directParent.getBoundingClientRect().height -
          offsets.y +
          -space.aboveRelativeElement,
      );
    }

    /** The element cannot be wider than the absolute bounding box element */
    const expectedWidth =
      withinContainerBB.width < MAX_WIDTH ? withinContainerBB.width : MAX_WIDTH;

    /** Centered below the relative element */
    const idealOffset =
      offsets.x +
      relativeElementToWithinContainer.left -
      expectedWidth / 2 +
      relativeToElementBB.width / 2;

    /**
     * If the element would go out of the absoluteBoundingBox right bounds
     * calculate the correction necessary.
     *
     * We assume a min offset of 20px on the left, this prevents issues where
     * e.g. a scrollbar is visible.
     */
    const rightOffset = Math.max(
      idealOffset - offsets.x + expectedWidth - (withinContainerBB.width - 20),
      0,
    );

    /**
     * If the element would go out of the absoluteBoundingBox left bounds
     * (it is negative) we set it to absolute left - 0px.
     *
     * We assume a minimum left distance of 10px.
     */
    const leftOffset = Math.max(idealOffset - rightOffset, offsets.x + 10);
    const pointerOffset =
      space.leftRelativeElement -
      leftOffset +
      offsets.x +
      relativeToElementBB.width / 2;

    setLeftOffset(leftOffset);
    setMaxWidth(expectedWidth);
    setPointerOffset(pointerOffset);
  }, [relativeToElementRef, withinContainerRef, ownContainerRef, renderId]);

  if (!relativeToElementRef) {
    return <></>;
  }

  return (
    <RenderReferenceContainer
      id="RenderReferenceContainer"
      ref={setOwnContainerRef}
      style={{ maxWidth: maxWidth ?? undefined, left: leftOffset ?? undefined }}
      $openingDirection={pointerLocation}
      $moveSelector={moveSelector}
      $height={height}
    >
      {ownContainerRef && pointerLocation && (
        <Container
          data-testid={dataTestId}
          $openingDirection={pointerLocation}
          $height={height}
        >
          <React.Fragment key={renderKey}>
            {React.Children.map(
              children,
              child =>
                React.isValidElement(child) &&
                React.cloneElement(child, {
                  ...child.props,
                  selectorRef,
                  pointerLocation,
                  pointerOffset,
                  maxHeightInPx: height,
                }),
            )}
          </React.Fragment>
        </Container>
      )}
    </RenderReferenceContainer>
  );
};

const appear = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`;

/**
 * Rendered first to get the ref etc.
 */
const RenderReferenceContainer = styled.div<{
  $openingDirection: 'top' | 'bottom' | null;
  $moveSelector: number | null;
  $height: number | null;
}>(
  ({ $height, $moveSelector, $openingDirection, theme }) => css`
    position: absolute;
    height: ${$height}px;
    width: ${MAX_WIDTH}px;
    z-index: ${theme.getTokens().zIndex.top};
    display: flex;

    ${() => {
      if ($moveSelector === null) return;

      switch ($openingDirection) {
        case 'bottom':
          return css`
            flex-direction: column-reverse;
            bottom: ${$moveSelector}px;
            transform-origin: bottom;
          `;

        case 'top':
          return css`
            top: ${$moveSelector}px;
            transform-origin: top;
          `;
        default:
          return;
      }
    }};
  `,
);

const Container = styled.div<{
  $openingDirection: 'top' | 'bottom' | null;
  $height: number | null;
}>(
  ({ $openingDirection, $height }) => css`
    height: ${$height}px;
    width: ${MAX_WIDTH}px;
    line-height: normal;
    display: flex;
    flex-direction: column;
    animation-name: ${appear};
    animation-duration: 200ms;
    animation-timing-function: ease-out;

    ${() => {
      switch ($openingDirection) {
        case 'bottom':
          return css`
            flex-direction: column-reverse;
          `;

        default:
          return;
      }
    }};
  `,
);

export default PositionWrapperV2;
