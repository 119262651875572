import type { ContactHasCallEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

const asHasCallEventFilterInput = (
  filter: ContactHasCallEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => ({
  Call: {
    has: asHasFilterInput(filter.has),
  },
});

export default asHasCallEventFilterInput;
