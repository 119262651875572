import React from 'react';
import { animated } from 'react-spring';
import styled, { css, RuleSet, keyframes } from 'styled-components';
import { Loader as FeatherLoader } from 'react-feather';
import {
  WidgetSettingsAppearance,
  WidgetSettingsPositionV2,
} from '~/graphql/types';
import { Props } from '../..';
import { Heading5 } from '~/components/atom/Typography';

const Loader: React.FCC<{
  initialSettings: Props['initialSettings'];
  style: any;
}> = ({ initialSettings, style }) => (
  <Container $initialSettings={initialSettings} style={style}>
    <Inner>
      <FeatherLoader
        color={initialSettings.design.style.primary.color}
        width="2rem"
      />
      <Heading5
        style={{ color: initialSettings.design.style.primary.color }}
        margin={[null]}
      >
        De widget wordt geladen...
      </Heading5>
    </Inner>
  </Container>
);

const Container = styled(animated.div)<{
  $initialSettings: Props['initialSettings'];
  style: any;
}>(
  ({
    $initialSettings: {
      design: { style, zIndex, positions, appearances },
    },
    theme,
  }) => css`
    padding: ${theme.space('l')};
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: ${theme.getTokens().border.radius.s};
    /* z-index as configured by the user +1 so it will be on top of the widget */
    z-index: ${zIndex + 1};
    background-color: ${style.primary.background};
    position: fixed;
    ${positionMap[positions.desktop]};
    ${appearanceMap[appearances.desktop]};
  `,
);

const spin = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const Inner = styled.div`
  width: 100%;
  text-align: center;

  svg {
    animation: ${spin} linear 2s infinite;
  }
`;

const positionMap: Record<WidgetSettingsPositionV2, RuleSet<object>> = {
  [WidgetSettingsPositionV2.BottomLeft]: css`
    bottom: 1rem;
    left: 1rem;
  `,
  [WidgetSettingsPositionV2.BottomRight]: css`
    bottom: 1rem;
    right: 1rem;
  `,

  [WidgetSettingsPositionV2.CenterLeft]: css`
    top: 40%;
    left: 1rem;
  `,
  [WidgetSettingsPositionV2.CenterRight]: css`
    top: 40%;
    right: 1rem;
  `,

  [WidgetSettingsPositionV2.TopLeft]: css`
    top: 1rem;
    left: 1rem;
  `,
  [WidgetSettingsPositionV2.TopRight]: css`
    top: 1rem;
    right: 1rem;
  `,
};

const appearanceMap: Record<WidgetSettingsAppearance, RuleSet<object>> = {
  // Sizes taken from computed styles in console
  [WidgetSettingsAppearance.Small]: css`
    width: 186px;
    height: 267px;
  `,
  [WidgetSettingsAppearance.Large]: css`
    width: 320px;
    height: 215px;
  `,
};

export default Loader;
