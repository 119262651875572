import { map, propEq, curry, when, assoc } from 'ramda';

/**
 * Sets or overrides the value of the key in a specific child object.
 * Returns a new array with the manipulated value, one level deep manipulation.
 * @param {id: string | number, key: string, value, items: Array<any>} array
 * keywords: Update object, manipulate object, alter object values
 */

interface GenericFn {
  <T>(id: string | number, key: string, value: any, items: Array<T>): Array<T>;
}

// @ts-ignore
const alterById: GenericFn = curry((id, key, value, items) =>
  map(when(propEq(id, 'id'), assoc(key, value)), items),
);

export default alterById;
