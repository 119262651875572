import Color from 'color';

/**
 * Function to calculate a contrasting text color based on a given background color.
 * @param backgroundColor - The background color in any CSS color format (hex, rgb, etc.)
 * @returns The contrasting color, either black (#000000) or white (#FFFFFF)
 */
const getContrastingColor = (backgroundColor: string, theme): string => {
  const bgColor = Color(backgroundColor);

  // Calculate the luminance (perceived brightness) of the background color
  const luminance = bgColor.luminosity();

  // If the luminance is higher than 0.5, the color is light, so return black; otherwise, return white
  return luminance > 0.5 ? theme.color('primary') : '#FFFFFF';
};
export default getContrastingColor;
