import { pipe, map, when, is, omit } from 'ramda';

// __typename is removed from all of the nested objects
export type WithoutTypename<T> =
  T extends Array<infer U>
    ? Array<WithoutTypename<U>>
    : T extends object
      ? { [K in Exclude<keyof T, '__typename'>]: WithoutTypename<T[K]> }
      : T;

/**
 * Removes all the nested __typename properties and returns the same structure
 * @param {Array<any> | Object} o - An array or object
 * keywords:
 */
const removeTypenames = <T extends Array<any> | object>(o: T) =>
  pipe(
    when(n => !Array.isArray(n), omit(['__typename'])),
    map(when(is(Object), removeTypenames)),
  )(o) as WithoutTypename<T>;

export default removeTypenames;
