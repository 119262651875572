import cleanedFilename from '~/util/cleanedFilename';

export default {
  REGISTER_FORM: `${cleanedFilename(__filename)}-register-form`,
  REGISTER_ERROR_MESSAGE: `${cleanedFilename(
    __filename,
  )}-register-error-message`,
  SUBMIT_PASSWORD_BUTTON: `${cleanedFilename(
    __filename,
  )}-submit-password-button`,
  HAS_WAARDERAPPORT_BUTTON: `${cleanedFilename(
    __filename,
  )}-has-waarderapport-button`,
  TERMS_AND_CONDITIONS_CHECKBOX: `${cleanedFilename(
    __filename,
  )}-terms-and-conditions-checkbox`,
};
