const pathMap = {
  u: '/-/settings/users/{ID}',
  o: '/-/settings/offices/{ID}',
  ap: '/-/apps/value-report/{ID}',
  zt: '/-/apps/zapier/triggers/{ID}',
  ze: '/-/apps/zapier/events/{ID}',
  t: '/-/tasks',
  c: '/-/contacts/{ID}',
  fbp: '/-/automation/flows/builder-v2/update/{ID}',
};

/**
 * Generates the path to the entity based on the type of the UUID
 * @param {string} uuid - The UUId of the entity
 * keywords: path, uuid, magic
 */
const getPathFromUuid = (uuid: string): string | null => {
  const identifier = uuid.substring(0, 4).replace(/-/g, '');

  const path = pathMap[identifier];
  if (!path) return null;

  return path.replace('{ID}', uuid);
};
export default getPathFromUuid;
