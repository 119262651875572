import React, { useState } from 'react';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import CopyCode from '~/components/atom/CopyCode';
import JustificationContainer from '~/components/atom/JustificationContainer';
import VideoPlayer from '~/components/organism/StrapiDynamicZone/components/VideoPlayer';
import getMediaURLPrefix from '~/components/organism/StrapiDynamicZone/utils/getMediaURLPrefix';
import { Label, Variant } from '~/components/atom/Typography';
import { VideoMime } from '~/components/molecule/VideoJS';
import { widgetScript } from '~/components/page/Widget/constants';

const instructionLink = '/uploads/20231206_Dat_Huis_blur_fb1d5ae4b0.mp4';
const text = {
  header: 'Plaats de widget op een Realworks website',
  scriptFromTheVideo: 'Het script uit de video',
};

export type Props = {
  dataTestId?: string;
  accountId: string;
};

const ScriptForRealworksCustomerBlock: React.FCC<Props> = ({
  dataTestId,
  accountId,
  ...rest
}) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  return (
    <CollapsibleChevronBlock
      isOpen={isExpanded}
      data-testid={dataTestId}
      header={
        <Label size="m" margin={[null]} variant={Variant.primary}>
          {text.header}
        </Label>
      }
      onToggle={() => setExpanded(prev => !prev)}
      {...rest}
    >
      <JustificationContainer width="100%" direction="column" gap="base">
        <VideoPlayer
          src={getMediaURLPrefix() + instructionLink}
          autoPlay={false}
          controls={true}
          loop={false}
          muted={true}
          mime={VideoMime.MP4}
        />
        <CopyCode
          label={
            <Label
              margin={[null]}
              fontWeight="semiBold"
              variant={Variant.primary}
              size="base"
            >
              {text.scriptFromTheVideo}
            </Label>
          }
          code={widgetScript({ accountId })}
        />
      </JustificationContainer>
    </CollapsibleChevronBlock>
  );
};

export default ScriptForRealworksCustomerBlock;
