import React from 'react';
import type { OptionOf } from '~/components/molecule/Dropdown';

export type FontFamilyOption = OptionOf<{
  genericName: string;
  familyName: string;
  value: string | null;
}>;

export const fontFamilyOptions: Array<FontFamilyOption> = [
  {
    payload: {
      familyName: 'Arial',
      genericName: 'sans-serif',
      value: `Arial, sans-serif`,
    },
    label: (
      <span style={{ fontFamily: 'Arial, sans-serif' }}>Arial, sans-serif</span>
    ),
    key: 'arial',
  },
  {
    payload: {
      familyName: 'Arial Black',
      genericName: 'sans-serif',
      value: `'Arial Black', sans-serif`,
    },
    label: (
      <span style={{ fontFamily: 'Arial Black, sans-serif' }}>
        Arial Black, sans-serif
      </span>
    ),
    key: 'arial-black',
  },
  {
    payload: {
      familyName: 'Times New Roman',
      genericName: 'serif',
      value: `'Times New Roman', serif`,
    },
    label: (
      <span style={{ fontFamily: 'Times New Roman' }}>
        Times New Roman, serif
      </span>
    ),
    key: 'times-new-roman',
  },
  {
    payload: {
      familyName: 'Helvetica',
      genericName: 'sans-serif',
      value: `Helvetica, sans-serif`,
    },
    label: (
      <span style={{ fontFamily: 'Helvetica, sans-serif' }}>
        Helvetica, sans-serif
      </span>
    ),
    key: 'helvetica',
  },
  {
    payload: {
      familyName: 'Georgia',
      genericName: 'serif',
      value: `Georgia, serif`,
    },
    label: <span style={{ fontFamily: 'Georgia, serif' }}>Georgia, serif</span>,
    key: 'georgia',
  },
  {
    payload: {
      familyName: 'Courier',
      genericName: 'monospace',
      value: `Courier, monospace`,
    },
    label: (
      <span style={{ fontFamily: 'Courier, monospace' }}>
        Courier, monospace
      </span>
    ),
    key: 'courier',
  },
  {
    payload: {
      familyName: 'Unset',
      genericName: 'sans-serif',
      value: null,
    },
    label: <span>Geen lettertype toepassen</span>,
    key: 'none',
  },
];
