import React, { useState } from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { isNil } from 'ramda';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { FlowTemplates as FlowTemplatesType } from '~/graphql/types';
import FlowTemplates from '../../../FlowTemplates';

const text = {
  header: 'Kies een template',
  description:
    'Selecteer een template en pas deze aan je eigen voorkeuren aan.',
  cancelButton: 'Annuleren',
  nextButton: 'Volgende',
};

export type Props = {
  onClose: () => false | void | undefined;
  disableClosing?: boolean;
  onSelect: (template: FlowTemplatesType) => void;
  template: FlowTemplatesType | null;
} & RouteComponentProps;

const SelectTemplate: React.FCC<Props> = ({
  onClose,
  onSelect,
  disableClosing = false,
  template,
}) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<FlowTemplatesType | null>(template);

  return (
    <AppDetailsContainer
      dataTestId={TEST_ID.CONTAINER}
      header={text.header}
      icon="copy"
      pageDescription={text.description}
    >
      <FlowTemplates
        onSelect={template => setSelectedTemplate(template)}
        template={selectedTemplate}
      />
      <JustificationContainer justification="space-between" align="center">
        {!disableClosing && (
          <TextButton
            size="medium"
            label={text.cancelButton}
            onClick={onClose}
            padding={[null]}
            appearance="danger"
            dataTestId={TEST_ID.EXIT_WIZARD_BUTTON}
          />
        )}

        <Button
          icon="arrowRight"
          appearance="secondary"
          label={text.nextButton}
          disabled={isNil(selectedTemplate)}
          onClick={() => selectedTemplate && onSelect(selectedTemplate)}
          size="medium"
          dataTestId={TEST_ID.NEXT_BUTTON}
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default SelectTemplate;
