import {} from 'react';
import { Account, useGetMyAccountsQuery } from '~/graphql/types';

const useAccounts = (): Array<Omit<Account, 'Users' | 'Mailboxes'>> => {
  const { data, loading, error } = useGetMyAccountsQuery({});

  if (loading || error || !data || !data.getMyAccounts) return [];

  return data.getMyAccounts;
};

export default useAccounts;
