import { deepEquals, isPureObject } from '../object';
import { flatten } from 'ramda';

/**
 *
 * A utility function for deep comparison between two objects to identify differences.
 * It recursively navigates through all levels of the objects to find differences in values,
 * including those within nested objects. The comparison does not consider the order of keys
 * and only compares objects and null values (arrays are not compared if their values have changed).
 *
 * Note: This function performs a deep comparison on objects and a strict comparison on non-object values.
 *
 * @param obj1 The first object to compare.
 * @param obj2 The second object to compare.
 * @returns An object with three properties:
 *  - differenceCount: a number representing the total count of differences found.
 *  - dataIsNotEqual: a boolean flag indicating if any difference has been detected.
 *  - changedPaths: an array of paths representing the paths to keys that have been changed
 *
 */
const findDifference = (
  obj1: $Object,
  obj2: $Object,
): {
  /** Total count of differences found */
  differenceCount: number;

  /** True if any difference has been detected */
  dataIsNotEqual: boolean;

  /** Paths for changed keys. In { x:1, y: { z: 1 } } the path returned for `z` would be ['y', 'z'] */
  changedPaths: Array<Array<string>>;
} => {
  let differenceCount = 0;
  let dataIsNotEqual = false;
  const changedPaths: Array<Array<string>> = [];

  for (const key in obj1) {
    if (
      !isPureObject(obj1[key]) ||
      !isPureObject(obj2[key]) ||
      obj1[key] === null
    ) {
      const notEqual = obj1[key] !== obj2[key];
      differenceCount = notEqual ? differenceCount + 1 : differenceCount;

      if (notEqual) {
        dataIsNotEqual = notEqual;
        changedPaths.push([key]);
      }
    } else {
      const equal = deepEquals(obj1[key], obj2[key]);

      if (!equal) {
        for (const deepKey in obj1[key]) {
          if (
            isPureObject(obj1[key][deepKey]) &&
            isPureObject(obj2[key][deepKey]) &&
            obj1[key][deepKey] !== null
          ) {
            const deepDiff = findDifference(
              obj1[key][deepKey],
              obj2[key][deepKey],
            );

            differenceCount += deepDiff.differenceCount;

            if (deepDiff.changedPaths)
              deepDiff.changedPaths.forEach(path => {
                changedPaths.push([key, deepKey, ...flatten(path)]);
              });
          } else {
            if (obj1[key][deepKey] !== obj2[key][deepKey]) {
              differenceCount += 1;
              changedPaths.push([key, deepKey]);
            }
          }
        }

        dataIsNotEqual = true;
      }
    }
  }

  return {
    differenceCount,
    dataIsNotEqual,
    changedPaths,
  };
};

export default findDifference;
