import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Reason_Strapi } from '~/graphql/types';
import { SystemColorPalette } from '~/theme';
import StrapiModal from '~/components/molecule/StrapiModal';
import { linkStyles } from '~/components/molecule/Link';

export type Props = {
  label: React.ReactNode;
  reason: Reason_Strapi;
  color?: keyof SystemColorPalette;
};

const StrapiLink: React.FCC<Props> = ({
  dataTestId,
  label,
  reason,
  color,
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => setShowModal(false);

  return (
    <>
      {showModal && (
        <StrapiModal
          slug={reason.id}
          onComplete={hideModal}
          onClose={hideModal}
        />
      )}
      <Container data-testid={dataTestId} {...rest}>
        <ClickTarget
          $color={color ?? 'primary'}
          onClick={() => setShowModal(true)}
        >
          {label}
        </ClickTarget>
      </Container>
    </>
  );
};

const Container = styled.div<{}>``;

const ClickTarget = styled.a<{ $color: keyof SystemColorPalette }>(
  ({ $color, theme }) => css`
    ${linkStyles}
    color: ${theme.color($color, 'light')};
    &:hover {
      color: ${theme.color($color, 'light')};
    }
  `,
);

export default StrapiLink;
