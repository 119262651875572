import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionContactAssign } from '~/graphql/types.client';
import removeTypenames from '~/util/removeTypenames';

/**
 *
 * @param {ClientFlowActionContactAssign} action - ContactAssign client action
 * keywords:
 */
const serializeContactAssignAction = (
  action: ClientFlowActionContactAssign,
): FlowV2_Action__Input => {
  const {
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    overwriteAssignee,
    office,
    user,
    setByWorkingArea,
  } = action;

  return {
    Contact: {
      Assign: removeTypenames({
        id,
        accountId,
        flowBlueprintId,
        parentIds,
        office,
        user,
        overwriteAssignee,
        setByWorkingArea,
      }),
    },
  };
};
export default serializeContactAssignAction;
