import { isNil } from 'ramda';

const text = {
  free: 'Gratis',
  month: 'per maand',
  year: 'per jaar',
};

type Interval = {
  /** Positive number. Every 1,2,.. month/year */
  amount: number;

  /** Month or year */
  duration: 'month' | 'year';
};

export type PriceWithInterval = {
  /**  Which intervals they will be charged */
  interval: Interval;

  /** Amount in cents */
  amount: number;

  /** Currency for formatting: 'EUR' */
  currency: string;
};

/**
 * Returns price text
 * @param {PriceWithInterval | null} price - price with interval, amount is in cents
 * keywords: price format, cents to text
 */
const getPriceWithInterval = (price: PriceWithInterval | null): string => {
  if (isNil(price) || price.amount === 0) return text.free;

  const numberFormatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: price.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  // For now we don't have a case of every 2 months etc. so we leave it
  const intervalText = text[price.interval.duration] ?? '';

  return `${numberFormatter
    .formatToParts(price.amount / 100)
    .map(part => {
      // Turn 30,4 into 30,40-
      if (part.type === 'fraction' && part.value.length === 1)
        return `${part.value}0`;
      return part.value;
    })
    .join('')} ${intervalText}`;
};

export default getPriceWithInterval;
