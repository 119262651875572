import { User } from '~/hooks/useUserLookup';
import isActiveUser from '~/typeguards/isActiveUser';
import isDeletedUser from '~/typeguards/isDeletedUser';

const getUserName = (user: User | null): string => {
  if (!user) {
    throw new Error('No user provided to `getUserName`');
  }
  if (isActiveUser(user) || isDeletedUser(user)) return user.name;

  return user.email;
};

export default getUserName;
