import type { WindowLocation } from '@gatsbyjs/reach-router';
import type { Credentials, WindowState } from '../..';

/**
 * Extracts the email and password parameters from the url:
 * https://devsfa.dathuis.nl/login?email=user@dathuis.nl&password=Test123.
 *
 * Using the "magic link" our users can login without needing to enter their email and password.
 * We send them this link within an email when a new user has signed up etc.
 *
 * @param {WindowLocation<WindowState>} location - Window location with the added state
 * keywords: loginmagiclink
 */
const getParamsFromMagicLink = (
  location: WindowLocation<WindowState>,
): Credentials => {
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const password = params.get('password');

  return {
    email,
    password,
  };
};

export default getParamsFromMagicLink;
