import { title as setupTitle } from './RealworksSetup';
import { title as relatieTitle } from './RealworksImportContacts';
import { title as wonenTitle } from './RealworksExportContacts';
import { title as activatePendingTitle } from './RealworksActivationPending';
import { WizardFlow } from '~/graphql/types.client';

const typename = 'AppStatus_Realworks';
const realworksEnableSteps: WizardFlow = {
  id: 'enable-realworks',
  steps: [
    { title: setupTitle, id: 'RealworksSetup', metadata: { typename } },
    {
      title: relatieTitle,
      id: 'RealworksImportContacts',
      metadata: { typename },
    },
    {
      title: wonenTitle,
      id: 'RealworksExportContacts',
      metadata: { typename },
    },
    {
      title: activatePendingTitle,
      id: 'RealworksActivationPending',
      metadata: { typename },
    },
  ],
};

export default realworksEnableSteps;
