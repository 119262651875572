type Position = {
  newX: number;
  newY: number;
};

export type ListSize = {
  width: number;
  height: number;
};

/** Allow some space around so that the list is a bit further away from the edge */
export const SPACE_AROUND = 15;

/**
 * Calculates a container's position to ensure it's within the viewport.
 * @param {DOMRect} openerRect - Bounding rect of the component that triggered the opening (e.g., button, input, dropdown).
 * @param {ListSize} listSize - Dimensions of the opening list.
 * @returns {Position} - Coordinates (newX, newY) for the list's position.
 */
const calculateListPosition = ({
  openerRect,
  listSize = { width: 0, height: 0 },
}: {
  openerRect: DOMRect | null;
  listSize: ListSize;
}): Position => {
  if (!openerRect) return { newX: -500, newY: -500 };

  const { clientWidth, clientHeight } = document.documentElement || {};
  const windowWidth = window.innerWidth || clientWidth;
  const windowHeight = window.innerHeight || clientHeight;

  // Handle possible negative scroll values
  const scrollPosition = Math.max(
    window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop,
    0,
  );

  const initialX = openerRect.left;
  const initialY = openerRect.bottom;

  const listWidth = listSize.width;
  const listHeight = listSize.height;

  let newX = initialX;
  let newY = initialY + scrollPosition;

  // Check horizontal boundaries
  if (newX <= 0) {
    newX = SPACE_AROUND;
  } else if (initialX + listWidth > windowWidth) {
    newX = windowWidth - listWidth - SPACE_AROUND;
  }

  const offsetTop = openerRect.top + scrollPosition;
  const offsetBottom = window.innerHeight - openerRect.bottom;
  const overflowsAtBottom = initialY + listHeight > windowHeight;

  // Check vertical boundaries
  if (newY <= 0) {
    newY = SPACE_AROUND;
  } else if (overflowsAtBottom) {
    if (offsetTop > offsetBottom) {
      // open it above the opener component
      const excessAbove = Math.max(listHeight - offsetTop, 0);
      const addition = excessAbove ? SPACE_AROUND + excessAbove : 0;

      newY = newY - listHeight - openerRect.height + addition;
    } else {
      // open it below the opener component
      const excessBelow = newY + listHeight - windowHeight;
      newY = newY - excessBelow - SPACE_AROUND;
    }
  }

  return {
    newX,
    newY,
  };
};

export default calculateListPosition;
