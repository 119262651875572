import React from 'react';
import useIsBasicForm from '../../hooks/useIsBasicForm';

export type Props = {};

/**
 * Returns the component if the form is not basic form
 */
const InAdvancedForm: React.FCC<Props> = ({ children }) => {
  const isBasicForm = useIsBasicForm();

  if (isBasicForm) return null;

  return <>{children}</>;
};

export default InAdvancedForm;
