import React from 'react';
import styled, { css } from 'styled-components';

import { safeFormattedDate, safeFormattedTime } from '~/util/date';
import TEST_ID from './index.testid';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import { iconForTypeInHeader } from '~/util/taskTypeOptions';
import { TaskType } from '~/graphql/types';

const text = {
  contactLabel: 'Contact',
  dateLabel: 'Deadline',
  assignedLabel: 'Toegewezen',
  noUserValue: 'Alle gebruikers',
  noOfficeValue: 'Alle vestigingen',
};
type Props = {
  /** The date the task is due */
  dueDate: Date | null;
  contactName: string | null;

  /** The name of the office this task is assigned to */
  officeName: string | null;

  /** The name of the user this task is assigned to */
  userName: string | null;

  taskType?: TaskType;
};

const TaskInfoBlock: React.FCC<Props> = ({
  dueDate,
  contactName,
  officeName,
  userName,
  taskType,
}) => {
  const viewingModeProps = useViewingModeProps();
  const dateValue = safeFormattedDate(dueDate);
  const timeValue = safeFormattedTime(dueDate);

  return (
    <Container>
      {taskType && (
        <InfoContainer>{iconForTypeInHeader(taskType)}</InfoContainer>
      )}

      {contactName && (
        <InfoContainer>
          <HeaderLabel>{text.contactLabel}</HeaderLabel>
          <ContactHeader>
            <TextValue {...viewingModeProps}>{contactName}</TextValue>
          </ContactHeader>
        </InfoContainer>
      )}

      <InfoContainer>
        <HeaderLabel>{text.dateLabel}</HeaderLabel>
        <DateContainer data-testid={TEST_ID.DUE_DATE_INFORMATION}>
          <TextValue>{dateValue}</TextValue>&nbsp;
          <TextValue>{timeValue}</TextValue>
        </DateContainer>
      </InfoContainer>

      <InfoContainer>
        <HeaderLabel>{text.assignedLabel}</HeaderLabel>
        <AssignedContainer>
          <AssignedValue
            data-testid={TEST_ID.TASK_ASSIGNED_OFFICE}
            noData={!officeName}
          >
            {officeName || text.noOfficeValue}
          </AssignedValue>
          &nbsp;-&nbsp;
          <AssignedValue
            data-testid={TEST_ID.TASK_ASSIGNED_USER}
            noData={!userName}
          >
            {userName || text.noUserValue}
          </AssignedValue>
        </AssignedContainer>
      </InfoContainer>
    </Container>
  );
};

const TextValue = styled.div<{}>`
  display: inline-block;

  ${({ theme }) => css`
    color: ${theme.color('info')};
  `};
`;

const Container = styled.div<{}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const InfoContainer = styled.div<{}>`
  ${({ theme }) => css`
    margin-right: ${theme.space('m')};
  `};
`;

const HeaderLabel = styled.div<{}>``;

const ContactHeader = styled.div<{}>`
  display: flex;
  align-items: center;
`;

const DateContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    grid-column-gap: ${theme.space('s')};
  `};
`;

const AssignedContainer = styled.div<{}>`
  display: flex;
  align-items: center;
`;

type AssignedDataType = {
  noData?: boolean;
};

const AssignedValue = styled(TextValue)<AssignedDataType>`
  ${({ theme, noData }) => {
    if (noData) {
      return css`
        color: ${theme.color('danger')};
      `;
    }

    return null;
  }};
`;

export default TaskInfoBlock;
