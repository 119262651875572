import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Body } from '~/components/atom/Typography';
import {
  OutputFieldPlain,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import { OutputMap } from '~/components/organism/WizardSteps';
import {
  UpdateWalletSettingsMutationVariables,
  useUpdateWalletSettingsMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import IBANInput, { ibanErrors } from '~/components/molecule/IBANInput';
import Input from '~/components/molecule/Input';
import { isEmpty, isNil } from 'ramda';
import forceBEFormat from '~/components/molecule/IBANInput/utils/forceBEFormat';
import leftPad from '~/util/leftPad';
import useErrorReporter from '~/hooks/useErrorReporter';
import validateWalletFields from '~/components/page/Settings/Wallet/utils/validateWalletFields';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useCurrentUser from '~/hooks/useCurrentUser';

export const id = 'WalletSettings';
export const title = 'Instellingen uitbetaling';

const text = {
  accountNameLabel: 'Naam van rekeninghouder',
  emailLabel: 'Facturatie email',
  ibanLabel: 'IBAN',

  category: 'Instellingen uitbetaling',

  success: 'Wijzigingen opgeslagen',
  error: 'Er is iets misgegaan bij het opslaan van wijzigingen',
  body: 'Door apps van dienstverleners te activeren, kan je met DatHuis leadvergoedingen verdienen. Deze worden gestort in jouw Wallet en periodiek automatisch uitbetaald. Stel hier het rekeningnummer in waarop je uitbetaald wil krijgen.',
};

export type OutputType = {
  type: typeof id;
  accountName: OutputFieldPlain<string>;
  walletEmail: OutputFieldPlain<string>;
  ibanMasked: OutputFieldPlain<string>;
};

type FieldName = 'email' | 'accountName' | 'iban';

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const errorReporter = useErrorReporter();
  const { email: userEmail } = useCurrentUser();
  const { id: accountId, name: accountName } = useCurrentAccount();

  const currentOutput = outputMap[id] as OutputType;

  const defaultEmail = userEmail ?? '';
  const defaultAccountName = accountName ?? '';

  const [values, setValues] = useState({
    accountName: currentOutput.accountName?.value || defaultAccountName,
    email: currentOutput.walletEmail?.value || defaultEmail,
    iban: '',
  });

  const [touched, setTouched] = useState({
    accountName: false,
    email: false,
    iban: false,
  });

  const onChange = (fieldName: FieldName, newValue: string) => {
    setValues(prev => ({ ...prev, [fieldName]: newValue }));
  };

  const onTouched = (fieldName: FieldName, value: boolean) =>
    setTouched(prev => ({ ...prev, [fieldName]: value }));

  const [updateWalletSetting, { loading: updateLoading }] =
    useUpdateWalletSettingsMutation();

  const validationErrors = useMemo(
    () => validateWalletFields(values),
    [values],
  );
  const valid =
    Object.values(validationErrors).filter(a => !isNil(a)).length === 0;

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const currentOutput = outputMap[id] as OutputType;

      const update: UpdateWalletSettingsMutationVariables['update'] = {};

      if (values.iban && values.iban.length > 0) {
        if (validationErrors.iban) {
          onTouched('iban', true);
          throw new Error(ibanErrors.invalid);
        }

        update.iban = forceBEFormat(values.iban);
      }

      if (
        values.accountName &&
        values.accountName !== currentOutput.accountName.value
      ) {
        update.accountName = values.accountName;
      }

      if (values.email && values.email !== currentOutput.walletEmail.value) {
        update.email = values.email;
      }

      if (isEmpty(update)) return Promise.resolve(currentOutput);

      const { data, errors } = await updateWalletSetting({
        variables: {
          accountId,
          update,
        },
      });

      if (errors && errors.length > 0) {
        errorReporter.captureException(
          new Error(
            `Error in updateWalletSettings in Onboarding wizard: ${JSON.stringify(
              errors,
            )}`,
          ),
          'debug',
        );

        throw new Error(text.error);
      }

      if (data) {
        const output: OutputType = {
          ...currentOutput,
          accountName: {
            type: 'plain',
            category: text.category,
            label: text.accountNameLabel,
            value: values.accountName,
          },
          walletEmail: {
            type: 'plain',
            category: text.category,
            label: text.emailLabel,
            value: values.email,
          },
          ibanMasked: {
            type: 'plain',
            category: text.category,
            label: text.ibanLabel,
            value: leftPad(18, data.updateWalletSettings.ibanLast4),
          },
        };
        return output;
      }

      return currentOutput;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, values, validationErrors],
  );
  const stepOptions = useMemo(() => ({ onBeforeNext }), [onBeforeNext]);
  const [, api] = useWizardStep(step.id, stepOptions);

  const _disabled = updateLoading;

  useEffect(() => {
    if (!updateLoading) {
      api.freeSkip();
    }
    if (valid && !updateLoading) {
      api.free(currentOutput);
    } else {
      // if they have already saved an iban, they can make a partial update so enable the next button
      if (
        currentOutput.ibanMasked.value &&
        !touched.iban &&
        !validationErrors.accountName &&
        !validationErrors.email
      ) {
        api.free(currentOutput);
      } else {
        api.lock(currentOutput);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valid,
    updateLoading,
    currentOutput.ibanMasked,
    validationErrors.email,
    validationErrors.accountName,
  ]);

  return (
    <>
      <Body margin={[null]}>{text.body}</Body>
      <JustificationContainer
        direction="column"
        gap="m"
        padding={['l', null]}
        width="100%"
      >
        <Input
          label={{ text: text.accountNameLabel }}
          type="text"
          value={values.accountName}
          externalErrors={
            validationErrors.accountName && touched.accountName
              ? [validationErrors.accountName]
              : undefined
          }
          disabled={_disabled}
          onChange={e => onChange('accountName', e.target.value)}
          onFocus={() => onTouched('accountName', true)}
          width="100%"
        />

        <Input
          label={{ text: text.emailLabel }}
          type="email"
          value={values.email}
          externalErrors={
            validationErrors.email && touched.email
              ? [validationErrors.email]
              : undefined
          }
          disabled={_disabled}
          onChange={e => onChange('email', e.target.value)}
          onFocus={() => onTouched('email', true)}
          width="100%"
        />

        <IBANInput
          label={text.ibanLabel}
          disabled={_disabled}
          previousValue={currentOutput.ibanMasked.value}
          value={values.iban}
          externalErrors={
            validationErrors.iban && touched.iban
              ? [validationErrors.iban]
              : undefined
          }
          showValidation
          onChange={(_e, value) => onChange('iban', value)}
          onFocus={() => onTouched('iban', true)}
          onBlur={() => {
            // allow blur if there is value saved
            if (currentOutput.ibanMasked.value) onTouched('iban', false);
          }}
        />
      </JustificationContainer>
    </>
  );
};

export default {
  id,
  title,
};
