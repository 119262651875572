import { isNil } from 'ramda';
import type { DefaultTheme } from 'styled-components';
import type { IconType } from '~/components/atom/Icon';
import type { ClientFlowAction } from '~/graphql/types.client';

import { Issue } from '~/components/page/Automation/v2/state/flowIssues';

type CardValue = {
  color: string;
  icon: IconType;
};

type CardValueMap = (
  theme: DefaultTheme,
) => Record<ClientFlowAction['actionType'], CardValue>;

const getValues: CardValueMap = (theme: DefaultTheme) => ({
  Start: {
    color: theme.color('info'),
    icon: 'star',
  },
  Wait: {
    color: '#AC86B3',
    icon: 'clock',
  },
  IfElse: {
    color: theme.color('accent', 'base'),
    icon: 'branches',
  },
  SendEmail_Plain: {
    color: theme.color('success'),
    icon: 'mail',
  },
  SendNotification: {
    color: theme.color('success'),
    icon: 'bell',
  },
  Contact_DeleteTag: {
    color: theme.color('success'),
    icon: 'tag',
  },
  Contact_AddTag: {
    color: theme.color('success'),
    icon: 'tag',
  },
  Task_Create: {
    color: theme.color('success'),
    icon: 'list',
  },
  Zapier_Trigger: {
    color: theme.color('success'),
    icon: 'send',
  },
  Realworks_SendContact: {
    color: theme.color('success'),
    icon: 'send',
  },
  Contact_Assign: {
    color: theme.color('success'),
    icon: 'user',
  },
  Contact_Details: {
    color: theme.color('success'),
    icon: 'user',
  },
});

type CardIssuesMap = Record<string, CardValue>;

const getIssueValues: (theme: DefaultTheme) => CardIssuesMap = theme => ({
  error: {
    color: theme.color('danger'),
    icon: 'exclamation',
  },
  warning: {
    color: theme.color('warning'),
    icon: 'exclamation',
  },
});

const getDefaultValues: (theme: DefaultTheme) => {
  color: string;
  icon: IconType;
} = theme => ({
  color: theme.color('secondary'),
  icon: 'question-mark',
});

type Args = {
  actionType: ClientFlowAction['actionType'];
  issueLevel: Issue['level'] | null;
  theme: DefaultTheme;
};

export const getCardValues = ({
  actionType,
  issueLevel,
  theme,
}: Args): CardValue => {
  if (!isNil(issueLevel) && !isNil(getIssueValues(theme)[issueLevel])) {
    return getIssueValues(theme)[issueLevel];
  }

  return getValues(theme)[actionType] || getDefaultValues(theme);
};
