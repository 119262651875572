import { ZapierFieldType, ZapierField__Input } from '~/graphql/types';

type ZapierEventTemplate = {
  insertedId?: string;
  id: string;
  name: string;
  description?: string;
  fields: Array<ZapierField__Input>;
};

export type EventTemplates = Array<ZapierEventTemplate>;

const eventTemplates: EventTemplates = [
  {
    id: 'facebook_lead_ads',
    name: 'Facebook lead ads',
    fields: [
      {
        label: 'FullName',
        key: 'fullname',
        type: ZapierFieldType.String,
      },
      {
        label: 'Email',
        key: 'email',
        type: ZapierFieldType.String,
      },
      {
        label: 'Phone',
        key: 'phone',
        type: ZapierFieldType.String,
      },
      {
        label: 'FormId',
        key: 'form_id',
        type: ZapierFieldType.String,
      },
      {
        label: 'AdId',
        key: 'ad_id',
        type: ZapierFieldType.String,
      },
      {
        label: 'AdGroupId',
        key: 'ad_group_id',
        type: ZapierFieldType.String,
      },
    ],
  },
  {
    id: 'instagram_lead_ads',
    name: 'Instagram lead ads',
    fields: [
      {
        label: 'FullName',
        key: 'fullname',
        type: ZapierFieldType.String,
      },
      {
        label: 'Email',
        key: 'email',
        type: ZapierFieldType.String,
      },
      {
        label: 'Phone',
        key: 'phone',
        type: ZapierFieldType.String,
      },
      {
        label: 'FormId',
        key: 'form_id',
        type: ZapierFieldType.String,
      },
      {
        label: 'AdId',
        key: 'ad_id',
        type: ZapierFieldType.String,
      },
      {
        label: 'AdGroupId',
        key: 'ad_group_id',
        type: ZapierFieldType.String,
      },
    ],
  },
  {
    id: 'typeform',
    name: 'Typeform',
    fields: [
      {
        label: 'FirstName',
        key: 'first_name',
        type: ZapierFieldType.String,
      },
      {
        label: 'LastName',
        key: 'last_name',
        type: ZapierFieldType.String,
      },
      {
        label: 'FullName',
        key: 'full_name',
        type: ZapierFieldType.String,
      },
      {
        label: 'Message',
        key: 'message',
        type: ZapierFieldType.String,
      },
    ],
  },
];

export default eventTemplates;
