import { isNil } from 'ramda';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  type: 'true' | 'false';

  /** Used to make the width the same for both labels */
  minWidth?: string;

  /** If the label is clickable */
  onClick?: () => void;
};

const text = {
  trueChild: 'Waar',
  falseChild: 'Niet waar',
};

const TrueOrFalseLabel: React.FCC<Props> = ({ type, minWidth, ...rest }) => {
  const iconName = type === 'true' ? 'check' : 'error';
  const iconColor = type === 'true' ? 'success' : 'danger';
  const labelText = type === 'true' ? text.trueChild : text.falseChild;

  const theme = useTheme();

  return (
    <Container
      gap="xs"
      padding={['xxs', 'xs']}
      align="center"
      border={{
        width: 's',
        radius: 's',
        color: { group: 'tertiary', variant: 'light' },
      }}
      $minWidth={minWidth}
      $hasClick={!isNil(rest.onClick)}
      {...rest}
    >
      <Icon name={iconName} color={theme.color(iconColor)} /> {labelText}
    </Container>
  );
};

const Container = styled(JustificationContainer)<{
  $minWidth?: string;
  $hasClick: boolean;
}>(
  ({ theme, $minWidth, $hasClick }) => css`
    display: inline-flex;
    font-size: ${theme.fontSize('base')};
    min-width: ${$minWidth || 'unset'};
    cursor: ${$hasClick ? 'pointer' : 'default'};
  `,
);

export default TrueOrFalseLabel;
