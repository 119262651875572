import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  value: string;
};

const DateLabel: React.FC<Props> = ({ value }) => (
  <Outer>
    <Container
      onClick={() => {
        // When the button is clicked we'll scroll to the top of that day
        const element = document.getElementById(value);
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }}
    >
      {value}
    </Container>
  </Outer>
);

const Outer = styled.a(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: ${theme.space('base')};
    z-index: ${theme.getTokens().zIndex.footer + 1};
  `,
);

const Container = styled.div(
  ({ theme }) => css`
    cursor: pointer;
    margin-bottom: 1rem;
    display: inline-block;
    color: ${theme.color('primary')};
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('s')} ${theme.space('m')};
  `,
);

export default DateLabel;
