import React from 'react';
import { ReactFlowProvider } from 'reactflow';

function withReactFlowProvider<P extends object>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<P> {
  const WithReactFlowProvider: React.FC<P> = props => (
    <ReactFlowProvider>
      <WrappedComponent {...(props as P)} />
    </ReactFlowProvider>
  );

  return WithReactFlowProvider;
}

export default withReactFlowProvider;
