import { useCallback, useContext, useMemo, useRef } from 'react';
import { pluck } from 'ramda';
import WizardContext, {
  StateStep,
  StateStepWithSubSteps,
  WizardStep,
} from '~/components/organism/Wizard/context/WizardContext';
import db from '~/components/organism/Wizard/db';
import useCurrentAccount from '../useCurrentAccount';
import { OutputMap, StepId } from '~/components/organism/WizardSteps';

export type WizardAPI = {
  /**
   * Returns a list of all current wizard steps
   */
  getSteps: () => Array<StateStep>;

  /**
   * Used to retrieve the current step
   * @returns WizardStep - The current step
   */
  getCurrentStep: () => StateStep | null;

  /**
   * Only adds steps to the end of the current journey
   * @param step WizardStep - The step you want to add
   * @param addAfter StepId - Add the step after this step otherwise it will be added at the end
   */
  addStep: (val: { step: WizardStep; addAfter?: StepId }) => void;

  /**
   * Adds multiple steps at once
   * Same as addStep but for bulk steps
   * @param steps Array<WizardStep> - The steps you want to add
   * @param addAfer StepId - Add the steps after this step
   */
  addSteps: (val: { steps: Array<WizardStep>; addAfter?: StepId }) => void;

  /**
   * Go to next step or sub step if any
   */
  next: () => void;

  /**
   * Go to previous step or sub step if any
   */
  previous: () => void;

  /**
   * Used to check if there is a next step
   */
  hasNext: () => boolean;

  /**
   * Used to check if there is a previous step
   */
  hasPrevious: () => boolean;

  /**
   * Shows the wizard
   */
  show: (options?: {
    id: string;
    header?: string;
    steps?: Array<WizardStep>;
    canClose?: boolean;
  }) => void;

  /**
   * Hides the wizard
   */
  hide: () => void;

  /**
   * Clears all current and stored steps
   * Used to start a clean flow
   * Argument for `id` is used to clear the pair in IndexedDB
   */
  clear: (id?: string) => Promise<void>;

  setOutput: (outputMap: OutputMap) => void;
};

const useWizard = (): WizardAPI => {
  const wizardContext = useContext(WizardContext);
  const { id: accountId } = useCurrentAccount();
  const pending = useRef(false);

  if (!wizardContext) {
    throw new Error(
      'Cannot consume the `useWizard` API when component is not contained within a WizardContext',
    );
  }

  const { state, dispatch } = wizardContext;

  const onNavigate = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const api = useMemo(
    (): WizardAPI => ({
      clear: async id => {
        pending.current = true;
        const _id = id ?? wizardContext.id;

        if (db.isOpen()) {
          await db.states.where({ id: _id, accountId }).delete();
        }

        dispatch({ type: 'clear', payload: {} });
        pending.current = false;
      },

      show: async function show(options) {
        if (pending.current === true) {
          return setTimeout(() => {
            this.show(options);
          }, 100);
        }

        const steps = options?.steps ?? wizardContext.state.steps;

        try {
          pending.current = true;
          const storedState = await db.states.get({
            id: options ? options.id : wizardContext.id,
            accountId,
          });

          return dispatch({
            type: 'show',
            payload: {
              id: options ? options.id : wizardContext.id,
              ...storedState,
              ...options,
              steps,
            },
          });
        } catch (error) {
          throw error;
        } finally {
          pending.current = false;
        }
      },
      hide: () => dispatch({ type: 'hide', payload: {} }),
      getSteps: () => state.steps,
      getCurrentStep: () => {
        if (state.currentSubStep === null) {
          return state.steps[state.currentStep];
        }

        const mainStep = state.steps[
          state.currentStep
        ] as StateStepWithSubSteps;

        // It could be that the state hasn't restored yet so this lookup has the potential to fail
        // in that case we should just return undefined.
        if (!mainStep || !mainStep.subSteps) return null;

        return mainStep.subSteps[state.currentSubStep];
      },
      addStep: ({ step, addAfter }) => {
        if (!pluck('id', state.steps).includes(step.id)) {
          dispatch({ type: 'addStep', payload: { step, addAfter } });
        }
      },
      addSteps: function addSteps({ steps, addAfter }) {
        for (const step of steps) {
          this.addStep({ step, addAfter });
        }
      },
      next: () => {
        dispatch({ type: 'next', payload: {} });
        onNavigate();
      },
      previous: () => {
        dispatch({ type: 'previous', payload: {} });
        onNavigate();
      },

      hasNext: () => {
        const step = state.steps[state.currentStep];

        if (step.subSteps && step.subSteps.length !== 0) {
          return state.currentSubStep === null
            ? true
            : step.subSteps.length > state.currentSubStep + 1 ||
                state.steps.length > state.currentStep + 1;
        }

        return state.steps.length > state.currentStep + 1;
      },
      hasPrevious: () => {
        const step = state.steps[state.currentStep];

        if (step.subSteps && step.subSteps.length !== 0) {
          return state.currentSubStep === null
            ? state.currentStep - 1 >= 0
            : state.currentSubStep === 0 && state.currentStep - 1 >= 0;
        }

        return state.currentStep - 1 >= 0;
      },
      setOutput: outputMap => {
        dispatch({ type: 'setOutput', payload: outputMap });
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      accountId,
      dispatch,
      state.currentStep,
      state.currentSubStep,
      state.steps,
      wizardContext.id,
    ],
  );

  return api;
};

export default useWizard;
