import { FlowData___PrimitiveListInputOption } from '~/graphql/types';
import { BasePrimitive } from '~/graphql/types.client';

/**
 * Return base primitive value for primitive list input
 * @param {FlowData___PrimitiveListInputOption | null,} argument
 * keywords: primitive list input value
 */
const getValueForPrimitiveListInput = (
  argument: FlowData___PrimitiveListInputOption | null,
): BasePrimitive => {
  if (argument == null) return null;

  switch (argument.__typename) {
    case 'FlowData___PrimitiveListInputOption_Boolean':
      return argument.value_boolean;
    case 'FlowData___PrimitiveListInputOption_Int':
      return argument.value_integer;
    case 'FlowData___PrimitiveListInputOption_String':
      return argument.value_string || null;

    default:
      return null;
  }
};

export default getValueForPrimitiveListInput;
