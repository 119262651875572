import { AppType } from '~/graphql/types';
import type { AppConfig } from '../types';

const description = `
## Omschrijving
Zapier is het grootste integratie platform ter wereld. Met deze app haal je de kracht van 3500 koppelingen in huis.

Hoe gebruik je Zapier? Ontvang bijvoorbeeld leads vanuit Facebook, Typeform or landingspagina’s in DatHuis, of vergroot je bereik naar bestaande contacten. Stuur een attentie naar nieuwe huiseigenaren of retarget bezichtigers in een eigen Facebook advertentiecampagne. Kortom, verrijk je lead database met nieuwe interacties en krijg zicht op wie, wanneer wil verhuizen. De mogelijkheden zijn eindeloos.

## Installatie

Activeer deze app en loop door de installatiewizard. Heb je hulp nodig? Laat het ons weten via de chat. We helpen je graag.`;

const termsOfUse =
  'Door het activeren van deze app geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het Zapier platform. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Voor het gebruik van het Zapier platform zijn de gebruiksvoorwaarden van Zapier van toepassing.';

export const zapier: AppConfig = {
  __typename: 'AppStatus_Zapier',
  appType: AppType.Zapier,
  type: 'with_config',
  slug: 'zapier',
  name: 'Zapier',
  tags: ['Koppeling'],
  provider: 'DatHuis B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/zapier.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/zapier.svg',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/5487601-over-de-zapier-app',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'custom',
};
