import React from 'react';
import { Flow___ConditionPartial } from '../..';
import { InstanceMap } from '../../../Selector/utils/getInstance';
import { Props as SelectorProps } from '../../../Selector';

import DefaultSelectorContainer from './components/Default';
import LinkSelector from './components/LinkSelector';
import PositionWrapperV2, {
  Props as PositionWrapperProps,
} from '../PositionWrapperV2';

export type SelectorContainerProps = {
  dataTestId?: string;
  condition: Flow___ConditionPartial | null;
  instanceMap: InstanceMap;
  variable?: number;
} & SelectorProps;

const SelectorContainer: React.FCC<SelectorContainerProps> = props => {
  const { condition, ...rest } = props;

  if (condition?.__typename === 'Flow___InstanceCondition') {
    switch (condition.type) {
      case 'Condition_Email_LinkClicked': {
        if (rest.variable === 1) {
          return <LinkSelector {...rest} condition={condition} />;
        }

        break;
      }
    }
  }

  return <DefaultSelectorContainer {...props} />;
};

export type PositionedSelectorProps = SelectorContainerProps &
  PositionWrapperProps;

const PositionedSelectorContainerV2: React.FCC<
  PositionedSelectorProps
> = props => (
  <PositionWrapperV2
    onClose={props.onClose}
    rowContainerRef={props.rowContainerRef}
    parentRef={props.parentRef}
  >
    <SelectorContainer {...props} />
  </PositionWrapperV2>
);

export default PositionedSelectorContainerV2;
