import {
  TableHeaderDefinitionCellType,
  TableCell,
  BooleanRenderFunction,
} from '../../types.flow';
import { BaseHeaderCellOptions } from './base';

import { asBaseOptions } from './base';

type BooleanHeaderCellOptions = BaseHeaderCellOptions & {
  renderFunction?: BooleanRenderFunction;
};
const asBooleanTableHeaderCell = ({
  renderFunction,
  ...rest
}: BooleanHeaderCellOptions): TableHeaderDefinitionCellType<boolean> => {
  const renderFn =
    renderFunction == null
      ? (value: boolean) => value.toString()
      : renderFunction;

  const options = {
    ...asBaseOptions({ ...rest }),
    Cell: (tableCell: TableCell<boolean>) =>
      renderFn(tableCell.cell.value, tableCell),
  };

  return options;
};

export default asBooleanTableHeaderCell;
