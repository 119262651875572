import React, { useState } from 'react';
import styled from 'styled-components';
import InsertHTMLModal from '../../InsertHTMLModal';
import BaseButton from '../components/Base';
import { tooltipText } from '../text';
import insertHtml from '~/components/organism/PluginsEditor/commands/modify/html';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';

export type Props = {
  editor: DHEditor;
  customElements: Array<ELEMENTS>;
};

const InsertHtmlButton: React.FCC<Props> = ({
  dataTestId,
  editor,
  customElements,
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <BaseButton
        icon="html-tag"
        onClick={() => setShowModal(true)}
        tooltipMessage={tooltipText.insertHtml}
      />

      {showModal && (
        <InsertHTMLModal
          onClose={() => setShowModal(false)}
          onSubmit={html => {
            insertHtml({ editor, html, customElements });
            setShowModal(false);
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{}>``;

export default InsertHtmlButton;
