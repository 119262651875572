import { SessionHydrationOfficeFieldsFragment } from '~/graphql/types';
import useOffice from '../useOffice';
import useUsers from '../useUsers';

const useMainOffice = (
  userId: string | null,
): SessionHydrationOfficeFieldsFragment | null => {
  const users = useUsers();
  const mainOfficeId = users
    .find(user => user.id === userId)
    ?.officeIds.find(office => office.mainOffice === true)?.officeId;

  const mainOffice = useOffice(mainOfficeId);

  if (!mainOffice) return null;

  return mainOffice;
};

export default useMainOffice;
