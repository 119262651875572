import React from 'react';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import type { ActionModalProps } from '../../constants';

export type Props = {
  dataTestId?: string;
} & ActionModalProps;

const UnsubscribeEmail: React.FCC<Props> = ({
  onCancel,
  onComplete,
  ...rest
}) => (
  <ConfirmActionModal
    actionType="UnsubscribeEmail"
    actionInput={{
      UnsubscribeEmail: {},
    }}
    onComplete={onComplete}
    onCancel={onCancel}
    {...rest}
  />
);

export default UnsubscribeEmail;
