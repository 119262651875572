import React, { type CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '../JustificationContainer';
import Div, { Props as DivProps } from '../Div';
import InputLabel from '../InputLabel';

const DEFAULT_TEXT = `Lorem ipsum dolor sit amet. Ea dolorem nihil et omnis voluptatum ut neque aliquam. In quisquam quia et perferendis eveniet et consequatur impedit. Qui unde minima eos possimus dolor ea mollitia consequatur.
  Ut repellat internos et rerum iure sit recusandae soluta sed rerum commodi et dolorum deleniti et debitis officiis. 33 adipisci neque ut consequatur deserunt ex similique eligendi eos delectus laudantium. Sed galisum eligendi sed placeat harum ut inventore eligendi est accusantium iusto. Vel totam praesentium non inventore doloribus id consequatur inventore.`;

const _text = {
  label: 'Voorbeeld',
};

export type Props = DivProps & {
  text?: string;
  style?: CSSProperties;
  disabled?: boolean;
};

const TextPreview: React.FCC<Props> = ({
  text,
  style,
  disabled = false,
  ...rest
}) => (
  <Div {...rest}>
    <InputLabel label={_text.label} />
    <JustificationContainer
      align="center"
      justification="center"
      backgroundColor={{ group: 'white', variant: 'dark' }}
      padding={['l']}
      border={{ radius: 's' }}
    >
      <StyledDiv
        backgroundColor={{ group: 'white' }}
        padding={['l']}
        width="70%"
        style={style || {}}
        $disabled={disabled}
      >
        {text || DEFAULT_TEXT}
      </StyledDiv>
    </JustificationContainer>
  </Div>
);

const StyledDiv = styled(Div)<{ $disabled: boolean }>(
  ({ theme, $disabled }) => css`
    box-shadow: ${theme.boxShadow('around')};
    height: 300px;
    overflow: hidden;

    ${$disabled &&
    css`
      filter: blur(0.1em);
    `}
  `,
);

export default TextPreview;
