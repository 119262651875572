import useUserLookup, { User } from '../useUserLookup';

const useUser = (userId: string | null | undefined): User | null => {
  const users = useUserLookup();

  if (!userId || !users || !users[userId]) return null;
  return users[userId];
};

export default useUser;
