import { selector } from 'recoil';
import {
  FormBuilder_Event_MetaField,
  type FormBuilder_ScreenNode_Block,
} from '~/graphql/types';
import { formState } from '.';
import { unnest } from 'ramda';

const getContactInputBlockOfType = (
  screenNodes,
  type:
    | 'FormData_Input_Contact_Email'
    | 'FormData_Input_Contact_Name'
    | 'FormData_Input_Contact_Phone',
): FormBuilder_ScreenNode_Block =>
  unnest(screenNodes.map(node => node.blocks)).find(b => b.__typename === type);

/**
 * Keeps track of Contact input blocks that are used in all screen nodes
 *
 * These blocks can only be added once on a certain path. We currently have only 1 path,
 * when we move to having multiple paths we need to revisit this.
 */
export const contactInputBlocksUsed = selector<{
  [key in FormBuilder_Event_MetaField]: string | null;
}>({
  key: 'formBuilder/contactInputBlocksUsed',
  get: ({ get }) => {
    const form = get(formState);
    if (!form)
      return {
        [FormBuilder_Event_MetaField.ContactEmail]: null,
        [FormBuilder_Event_MetaField.ContactPhone]: null,
        [FormBuilder_Event_MetaField.ContactName]: null,
      };

    const screenNodes = form.nodes.filter(
      n => n.__typename === 'FormBuilder_ScreenNode',
    );

    const emailInputBlock = getContactInputBlockOfType(
      screenNodes,
      'FormData_Input_Contact_Email',
    );
    const phoneInputBlock = getContactInputBlockOfType(
      screenNodes,
      'FormData_Input_Contact_Phone',
    );
    const nameInputBlock = getContactInputBlockOfType(
      screenNodes,
      'FormData_Input_Contact_Name',
    );

    return {
      [FormBuilder_Event_MetaField.ContactEmail]: emailInputBlock?.key || null,
      [FormBuilder_Event_MetaField.ContactPhone]: phoneInputBlock?.key || null,
      [FormBuilder_Event_MetaField.ContactName]: nameInputBlock?.key || null,
    };
  },
});
