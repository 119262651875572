import { Element, Node, Transforms, Text, Editor } from 'slate';
import { DHEditor } from '../types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { isElementOfType } from '../commands';
import { LIST_TYPES } from '../commands/modify/list';

/**
 * Here we have the general normalizing rules that mostly fix some edge cases
 */
const withNormalizer = (editor: DHEditor): DHEditor => {
  const { normalizeNode, deleteFragment, insertText } = editor;

  editor.insertText = text => {
    if (!editor.selection) return;

    const parent = Editor.parent(editor, editor.selection, { edge: 'end' });

    // Keep styles of the previous text if the cursor is at the end of a span element
    if (isElementOfType(parent[0], ELEMENTS.SPAN)) {
      Transforms.insertText(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    // Make sure first element in the editor is always a div in case it becomes a generic html element
    if (
      path.length === 1 &&
      path[0] === 0 &&
      isElementOfType(node, ELEMENTS.GENERIC_HTML_ELEMENT)
    ) {
      const prevElAttrs = Object.keys(node);

      // unset previous attributes of the generic html element and make it an empty div
      Transforms.unsetNodes(editor, prevElAttrs);
      Transforms.setNodes(
        editor,
        {
          type: ELEMENTS.DIV,
        },
        { at: path },
      );
    }

    /**  If there is an empty link or a span with no children, remove it */
    if (
      Element.isElement(node) &&
      (node.type === ELEMENTS.LINK || node.type === ELEMENTS.SPAN) &&
      Node.children(editor, path)
    ) {
      for (const [child] of Node.children(editor, path)) {
        if (
          Text.isText(child) &&
          child.text.length === 0 &&
          node.children.length === 1
        ) {
          Transforms.removeNodes(editor, { at: path });
          return;
        }
      }
    }

    if (Element.isElement(node)) {
      const hasAtLeastOneBlockSibling = Array.from(
        Node.children(editor, path),
      ).some(([child]) => Editor.isBlock(editor, child));

      for (const [child, childPath] of Node.children(editor, path)) {
        /**
         * Wrap text nodes in a div element if they are siblings of block nodes so that
         * they don't get normalized(removed) by slate
         */
        if (Text.isText(child) && hasAtLeastOneBlockSibling) {
          Editor.withoutNormalizing(editor, () => {
            Transforms.wrapNodes(
              editor,
              { type: ELEMENTS.DIV, children: [] },
              { match: n => Text.isText(n), at: childPath },
            );
          });

          return;
        }

        /** Remove unnecessary empty text nodes in element nodes */
        // if (
        //   Text.isText(child) &&
        //   child.text.length === 0 &&
        //   node.children.length > 2
        // ) {
        //   Transforms.unwrapNodes(editor, { at: childPath });
        //   return;
        // }
      }
    }

    /** If by any chance the editor value becomes empty array insert a div element so that it does not crash */
    if (editor.children.length === 0) {
      Transforms.insertNodes(editor, {
        type: ELEMENTS.DIV,
        children: [{ text: '' }],
      });
      return;
    }

    return normalizeNode(entry);
  };

  editor.deleteFragment = (...args) => {
    deleteFragment(...args);

    /**
     * If there is an LI element without a UL/OL parent convert it to DIV
     * Fixes: https://github.com/ianstormtaylor/slate/issues/3469
     */
    const listItems = Editor.nodes(editor, {
      match: n => isElementOfType(n, ELEMENTS.LI),
    });

    for (const listItem of listItems) {
      const liPath = listItem[1];
      const parent = Editor.parent(editor, liPath);
      if (
        parent &&
        Element.isElement(parent[0]) &&
        !LIST_TYPES.includes(parent[0].type)
      ) {
        Transforms.setNodes(
          editor,
          { type: ELEMENTS.DIV },
          {
            at: liPath,
            match: n => isElementOfType(n, ELEMENTS.LI),
          },
        );
      }
    }
  };

  return editor;
};

export default withNormalizer;
