import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { FormBuilder_Ui_Alignment } from '~/graphql/types';
import Dropdown, { type OptionOf } from '~/components/molecule/Dropdown';

export type Props = {
  alignment: FormBuilder_Ui_Alignment;
  onChange: (alignment: FormBuilder_Ui_Alignment) => void;
};

export const alignmentOptions: Array<OptionOf<FormBuilder_Ui_Alignment>> = [
  { key: 'left', label: 'Links', payload: FormBuilder_Ui_Alignment.Left },
  { key: 'center', label: 'Midden', payload: FormBuilder_Ui_Alignment.Center },
  { key: 'right', label: 'Rechts', payload: FormBuilder_Ui_Alignment.Right },
];

const BlockAlignment: React.FCC<Props> = ({
  dataTestId,
  onChange,
  alignment,
  ...rest
}) => (
  <JustificationContainer dataTestId={dataTestId} width="100%" {...rest}>
    <Dropdown
      label="Uitlijning"
      options={alignmentOptions}
      selectedOptionIdx={alignmentOptions.findIndex(
        ({ payload }) => payload === alignment,
      )}
      onChange={({ option }) => onChange(option.payload)}
    />
  </JustificationContainer>
);

export default BlockAlignment;
