type Args = {
  actionId: string;
  conditionSubExpressionIdx: number;
  conditionGroupIdx: number;
  conditionIdx?: number;
  argumentIdx?: number;
};
/**
 * Generates a unique id for a given condition / argument
 */
const generateConditionArgumentId = ({
  actionId,
  conditionSubExpressionIdx,
  conditionGroupIdx,
  conditionIdx,
  argumentIdx,
}: Args): string =>
  `${actionId}#${conditionSubExpressionIdx}#${conditionGroupIdx}` +
  (conditionIdx != null
    ? `#${conditionIdx}` + (argumentIdx != null ? `#${argumentIdx}` : '')
    : '');

export default generateConditionArgumentId;
