import {
  FlowData___FlowInstanceFragment,
  FlowData___SubjectFragment,
  type FlowV2_TemplateStringFragment,
} from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import getCardLabel from '~/components/page/Automation/v2/components/Builder/components/nodeTypes/components/CardTemplate/utils/getCardLabel';
import getCardHeading from '~/components/page/Automation/v2/components/Builder/utils/getCardHeading';

export type InstanceWithPointer = {
  instance: FlowData___FlowInstanceFragment;
  extraDescription?: FlowV2_TemplateStringFragment;
};

const generateInstance = (
  subject: FlowData___SubjectFragment,
  key: string,
  action: ClientFlowAction,
  name?: string,
): InstanceWithPointer => {
  // Brackets will be replaced later on with the span tag
  const label = action.label ? `[[${getCardLabel(action.label)}]]` : '';

  let extraDescription: FlowV2_TemplateStringFragment | undefined = undefined;

  if (action.__typename === 'FlowV2_Action_SendEmail_Plain') {
    extraDescription = action.subject;
  }

  if (action.__typename === 'FlowV2_Action_Task_Create') {
    extraDescription = action.title;
  }

  return {
    // This will be used to add extra description to the label of the first item in the dir array
    extraDescription,
    instance: {
      __typename: 'FlowData___FlowInstance',
      dir: [
        {
          key: action.id,
          label: name || `${label}${getCardHeading(action.actionType)}`,
          weight: Number.MAX_SAFE_INTEGER,
          __typename: 'FlowData___Directory',
        },
        {
          key,
          label: subject.label,
          weight: Number.MAX_SAFE_INTEGER,
          __typename: 'FlowData___Directory',
        },
      ],
      instanceType: {
        __typename: 'Flow___SubjectId',
        type: subject.type,
        typeId: subject.typeId,
      },
      key: [action.id, key],
    },
  };
};

export default generateInstance;
