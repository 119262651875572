/**
 * Here we have general commands that can be used in many places
 */

import {
  Element,
  Transforms,
  Node,
  Editor,
  Path,
  Range,
  BaseRange,
  Location,
} from 'slate';
import { ReactEditor } from 'slate-react';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import {
  CustomElement,
  DHEditor,
} from '~/components/organism/PluginsEditor/types';

export const deleteNodeAtPath = (
  editor: DHEditor,
  path?: Location,
  mode?: 'highest' | 'lowest',
) => {
  Transforms.removeNodes(editor, { at: path, mode });
  ReactEditor.focus(editor);
};

export const isElementOfType = (el: Node, type: ELEMENTS) =>
  Element.isElement(el) && el.type === type;

export const getStartPoint = (editor: DHEditor): Path => {
  const [, firstElementPath] = Editor.first(editor, [0]);
  return firstElementPath;
};

export const getEndPoint = (editor: DHEditor): Path => {
  const [, lastElementPath] = Editor.last(editor, [editor.children.length - 1]);
  return lastElementPath;
};

export const getFullRange = (editor: DHEditor): BaseRange => {
  const firstElementPath = getStartPoint(editor);
  const lastElementPath = getEndPoint(editor);

  return Editor.range(editor, firstElementPath, lastElementPath);
};

export const isAllSelected = (editor: DHEditor) => {
  const fullRange = getFullRange(editor);
  return editor.selection ? Range.equals(editor.selection, fullRange) : false;
};

export const getElementWithType = ({
  editor,
  type,
  mode,
  at,
}: {
  editor: DHEditor;
  type: ELEMENTS;
  mode?: 'highest' | 'lowest' | 'all';
  // If not passed, returns currently active (selected) element
  at?: Location;
}): { element: CustomElement; path: Path } | null => {
  if (!at && !editor.selection) return null;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: at ?? getUnhungRange(editor),
      match: n => !Editor.isEditor(n) && isElementOfType(n, type),
      mode,
    }),
  );

  if (!match?.[0]) return null;

  if (!Element.isElement(match?.[0])) return null;

  return { element: match?.[0], path: match?.[1] };
};

/**
 *
 * @param editor DHEditor
 * @returns The selected element that is not a Div and Span element.
 * Div and span's are not important elements to focus on so we leave them out
 */
export const getSelectedElement = (
  editor: DHEditor,
  mode: 'highest' | 'lowest' | 'all' = 'lowest',
): { element: CustomElement; path: Path } | null => {
  if (!editor.selection) return null;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: getUnhungRange(editor),
      match: n =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        n.type !== ELEMENTS.DIV &&
        n.type !== ELEMENTS.SPAN,
      mode,
    }),
  );

  if (!match || !match[0]) return null;

  if (Element.isElement(match[0])) return { element: match[0], path: match[1] };

  return null;
};

/**
 * From Slate's slack discussions:
 *
 * A range is hanging if it starts at the end of a node or ends at the start of a node. See: https://github.com/ianstormtaylor/slate/issues/3683
 *
 * A "hanging" selection means it starts at the end of a node OR ends at the start of another node.
 * The selection technically includes the “hanging” node as well although no contents from that node is included.
 * If you want whatever operation to not include the hanging node you need to unhang it
 */
export const getUnhungRange = (editor: DHEditor): BaseRange | undefined => {
  if (!editor.selection) return;

  const emptyEditorRange: Range = {
    anchor: { path: [0, 0], offset: 0 },
    focus: { path: [0, 0], offset: 0 },
  };
  const isEmptyRange = Range.equals(editor.selection, emptyEditorRange);

  if (isEmptyRange) return;

  return Editor.unhangRange(editor, editor.selection);
};
