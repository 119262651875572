import React from 'react';
import styled, { css } from 'styled-components';
import { SystemColorPalette } from '~/theme';

export type Status = 'active' | 'inactive' | 'error' | 'warning';

export type Props = {
  status: Status;
};

const StatusIndicator: React.FCC<Props> = ({ dataTestId, status, ...rest }) => (
  <Container data-testid={dataTestId} $status={status} {...rest} />
);

type ContainerProps = {
  $status: Props['status'];
  $hasMargin?: boolean;
};
const Container = styled.div<ContainerProps>(
  ({ theme, $status }) => css`
    display: block;
    background-color: ${theme.color(getStatusColor($status))};
    border-radius: ${theme.getTokens().border.radius.full};
    width: 1em;
    height: 1em;
  `,
);

const getStatusColor = (status: Props['status']): keyof SystemColorPalette => {
  switch (status) {
    case 'active':
      return 'success';
    case 'error':
      return 'danger';
    case 'warning':
      return 'warning';
    case 'inactive':
    default:
      return 'tertiary';
  }
};

export default StatusIndicator;
