import React from 'react';

import { useTheme } from 'styled-components';
import Icon, { type IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useTooltipLayer from '~/hooks/useTooltipLayer';
import type { SystemColorPalette } from '~/theme';

export type Props = {
  dataTestId?: string;
  label: React.ReactNode | string;
  tooltip: {
    message: string;
  };
  icon: {
    name: IconType;
    color: keyof SystemColorPalette;
  };
};

const CellWithTooltip: React.FCC<Props> = ({ label, tooltip, icon }) => {
  const theme = useTheme();
  const tooltipProps = useTooltipLayer({ tooltipMessage: tooltip.message });

  return (
    <JustificationContainer align="center" gap="xxs">
      <Icon
        name={icon.name || 'exclamation'}
        color={theme.color(icon.color || 'primary')}
        strokeWidth={2.5}
        {...tooltipProps}
      />

      {label}
    </JustificationContainer>
  );
};

export default CellWithTooltip;
