import { uniqBy, unnest } from 'ramda';
import { Element, Node, Text } from 'slate';
import { Attachment__Input } from '~/graphql/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';

/**
 * Loops through all the nodes in the editor, finds dh-image elements and returns them as input attachments
 * @param {Array<Node>} nodes - Slate nodes
 * keywords: editor convert inline images to attachments
 */
const getImages = (nodes: Array<Node>): Array<Attachment__Input> => {
  if (!nodes || nodes.length === 0) return [];

  const res = nodes.reduce((acc, node) => {
    if (Element.isElement(node) && node.type === ELEMENTS.DH_IMAGE) {
      const {
        s3key,
        contentLength,
        contentType,
        filename,
        inlineId,
        pending,
        hasError,
      } = node;

      if (
        !s3key ||
        !inlineId ||
        !filename ||
        !contentLength ||
        pending ||
        hasError ||
        !contentType?.startsWith('image')
      ) {
        return acc;
      }

      return [
        ...acc,
        {
          file: {
            contentLength,
            contentType,
            filename,
            s3key,
          },
          inlineId,
        },
      ];
    }

    /** Get images in nested elements */
    if (!Text.isText(node) && node.children) {
      const childNode = unnest(getImages(node.children));

      if (childNode.length > 0) return [...acc, ...childNode];
    }

    return acc;
  }, []);

  return res;
};

/** Make sure that there are no duplicate attachments */
const getImagesAsAttachmentInputs = value =>
  uniqBy(item => item.inlineId, getImages(value));

export default getImagesAsAttachmentInputs;
