import React from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import ImageInput from '~/components/organism/ImageInput';
import { Field } from 'react-final-form';
import ColorInput from '~/components/molecule/ColorInput';
import Dropdown from '~/components/molecule/Dropdown';
import { Heading4, Variant } from '~/components/atom/Typography';
import SelectGroup from '~/components/molecule/SelectGroup';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import validateZIndex from '../../utils/validateZIndex';
import type { WidgetValues, onChangeArgs } from '../WidgetContent';
import { positionOptionTexts, widgetAppearanceOptions } from './constants';
import Input from '~/components/molecule/Input';

const text = {
  title: 'Ontwerp',
  logoLabel: 'Logo',
  highlightImageLabel: 'Kantoorfoto',
  primaryColor: 'Primaire tekstkleur',
  primaryBackgroundColor: 'Primaire kleur',
  secondaryColor: 'Secondaire tekstkleur',
  secondaryBackgroundColor: 'Secondaire kleur',
  zIndexLabel: 'Z-Index',
  positionLabel: 'Positie',
  appearanceLabel: 'Grootte',
  desktop: 'Desktop',
  mobile: 'Mobiel',
};

export type Props = WidgetValues['design'] & {
  version: number;
  onChange: (args: onChangeArgs<'design'>) => void;
} & RouteComponentProps;

const Design: React.FCC<Props> = ({
  dataTestId,
  logo,
  highlightImage,
  positions,
  appearances,
  style,
  version,
  onChange,
  ...rest
}) => (
  <AppDetailsContainer
    data-testid={dataTestId}
    {...rest}
    header={text.title}
    icon="layout"
  >
    <JustificationContainer width="100%" direction="column" gap="base">
      <JustificationContainer width="100%" data-testid={TEST_ID.LOGO_CONTAINER}>
        <Field name="design.logo">
          {({ input }) => (
            <ImageInput
              initialUrl={logo?.url}
              onChange={value => {
                onChange({
                  pageKey: 'design',
                  fieldKey: 'logo',
                  newValue: value
                    ? {
                        __typename: 'WidgetSettingsLogo',
                        s3key: value?.s3key || '',
                        url: value?.url || '',
                      }
                    : null,
                });
                input.onChange(value);
              }}
              s3Key={logo?.s3key}
              title={text.logoLabel}
              filename="logo"
              dataTestId={TEST_ID.LOGO_INPUT}
              key={'logo' + version}
            />
          )}
        </Field>
      </JustificationContainer>
      <JustificationContainer
        width="100%"
        data-testid={TEST_ID.HIGHLIGHT_IMAGE_CONTAINER}
      >
        <Field name="design.highlightImage">
          {({ input }) => (
            <ImageInput
              initialUrl={highlightImage?.url}
              onChange={value => {
                onChange({
                  pageKey: 'design',
                  fieldKey: 'highlightImage',
                  newValue: value
                    ? {
                        __typename: 'DHImage',
                        s3key: value?.s3key || '',
                        url: value?.url || '',
                      }
                    : null,
                });
                input.onChange(value);
              }}
              s3Key={highlightImage?.s3key}
              title={text.highlightImageLabel}
              filename="highlightImage"
              dataTestId={TEST_ID.HIGHLIGHT_IMAGE_INPUT}
              key={'highlightImage' + version}
            />
          )}
        </Field>
      </JustificationContainer>
      <JustificationContainer gap="base" width="100%">
        <Field name="design.style.primary.background">
          {({ input, meta: { submitting } }) => (
            <ColorInput
              value={input.value}
              onChange={value => {
                input.onChange(value);
                onChange({
                  pageKey: 'design',
                  fieldKey: 'style',
                  newValue: {
                    ...style,
                    primary: {
                      ...style.primary,
                      background: value,
                    },
                  },
                });
              }}
              label={text.primaryBackgroundColor}
              disabled={submitting}
              dataTestId={TEST_ID.PRIMARY_BACKGROUND_COLOR_INPUT}
            />
          )}
        </Field>
        <Field name="design.style.primary.color">
          {({ input, meta: { submitting } }) => (
            <ColorInput
              value={input.value}
              onChange={value => {
                input.onChange(value);
                onChange({
                  pageKey: 'design',
                  fieldKey: 'style',
                  newValue: {
                    ...style,
                    primary: {
                      ...style.primary,
                      color: value,
                    },
                  },
                });
              }}
              label={text.primaryColor}
              disabled={submitting}
              dataTestId={TEST_ID.PRIMARY_COLOR_INPUT}
            />
          )}
        </Field>
      </JustificationContainer>
      <JustificationContainer gap="base" width="100%">
        <Field name="design.style.secondary.background">
          {({ input, meta: { submitting } }) => (
            <ColorInput
              value={input.value}
              onChange={value => {
                input.onChange(value);
                onChange({
                  pageKey: 'design',
                  fieldKey: 'style',
                  newValue: {
                    ...style,
                    secondary: {
                      ...style.secondary,
                      background: value,
                    },
                  },
                });
              }}
              label={text.secondaryBackgroundColor}
              disabled={submitting}
              dataTestId={TEST_ID.SECONDARY_BACKGROUND_COLOR_INPUT}
            />
          )}
        </Field>
        <Field name="design.style.secondary.color">
          {({ input, meta: { submitting } }) => (
            <ColorInput
              value={input.value}
              onChange={value => {
                input.onChange(value);
                onChange({
                  pageKey: 'design',
                  fieldKey: 'style',
                  newValue: {
                    ...style,
                    secondary: {
                      ...style.secondary,
                      color: value,
                    },
                  },
                });
              }}
              label={text.secondaryColor}
              disabled={submitting}
              dataTestId={TEST_ID.SECONDARY_COLOR_INPUT}
            />
          )}
        </Field>
      </JustificationContainer>
      <JustificationContainer width="100%">
        <Field name="design.zIndex">
          {({ input, meta: { submitting } }) => (
            <Input
              value={input.value}
              validation={[validateZIndex]}
              width="100%"
              size="large"
              label={{ text: text.zIndexLabel }}
              disabled={submitting}
              type="number"
              dataTestId={TEST_ID.Z_INDEX}
              onChange={event => {
                event.preventDefault();
                input.onChange(event.target.value);

                onChange({
                  pageKey: 'design',
                  fieldKey: 'zIndex',
                  // We intentially allow it to be a string here to prevent 0 appear when user clears the form
                  // Also this will allow to validate field dynamically in widgetIssues state
                  newValue: event.target.value as unknown as number,
                });
              }}
            />
          )}
        </Field>
      </JustificationContainer>

      <Heading4 variant={Variant.primary} margin={[null]}>
        {text.desktop}
      </Heading4>
      <JustificationContainer width="100%" gap="base">
        <Field name="design.positions.desktop">
          {({ input, submitting }) => (
            <Dropdown
              size="large"
              label={text.positionLabel}
              dataTestId={TEST_ID.WIDGET_POSITION_DESKTOP}
              options={Object.keys(positionOptionTexts).map(key => ({
                key,
                label: positionOptionTexts[key],
                payload: key,
              }))}
              selectedOptionIdx={Object.keys(positionOptionTexts).findIndex(
                key => key === input.value,
              )}
              disabled={submitting}
              onChange={({ option }) => {
                input.onChange(option.payload);

                onChange({
                  pageKey: 'design',
                  fieldKey: 'positions',
                  newValue: {
                    ...positions,
                    desktop: option.payload,
                  },
                });
              }}
            />
          )}
        </Field>
        <Field name="design.appearances.desktop">
          {({ input, submitting }) => (
            <SelectGroup
              size="large"
              dataTestId={TEST_ID.APPEARANCE_DESKTOP}
              label={text.appearanceLabel}
              options={widgetAppearanceOptions}
              selectedIndex={widgetAppearanceOptions.findIndex(
                option => option.value === input.value,
              )}
              disabled={submitting}
              onChange={value => {
                input.onChange(value.option?.value);
                onChange({
                  pageKey: 'design',
                  fieldKey: 'appearances',
                  newValue: {
                    ...appearances,
                    desktop: value.option?.value,
                  },
                });
              }}
            />
          )}
        </Field>
      </JustificationContainer>

      <Heading4 variant={Variant.primary} margin={[null]}>
        {text.mobile}
      </Heading4>
      <JustificationContainer width="100%" gap="base">
        <Field name="design.positions.mobile">
          {({ input, submitting }) => (
            <Dropdown
              size="large"
              label={text.positionLabel}
              dataTestId={TEST_ID.WIDGET_POSITION_MOBILE}
              options={Object.keys(positionOptionTexts).map(key => ({
                key,
                label: positionOptionTexts[key],
                payload: key,
              }))}
              selectedOptionIdx={Object.keys(positionOptionTexts).findIndex(
                key => key === input.value,
              )}
              disabled={submitting}
              onChange={({ option }) => {
                input.onChange(option.payload);
                onChange({
                  pageKey: 'design',
                  fieldKey: 'positions',
                  newValue: {
                    ...positions,
                    mobile: option.payload,
                  },
                });
              }}
            />
          )}
        </Field>
        <Field name="design.appearances.mobile">
          {({ input, submitting }) => (
            <SelectGroup
              size="large"
              dataTestId={TEST_ID.APPEARANCE_MOBILE}
              label={text.appearanceLabel}
              options={widgetAppearanceOptions}
              selectedIndex={widgetAppearanceOptions.findIndex(
                option => option.value === input.value,
              )}
              disabled={submitting}
              onChange={value => {
                input.onChange(value.option?.value);
                onChange({
                  pageKey: 'design',
                  fieldKey: 'appearances',
                  newValue: {
                    ...appearances,
                    mobile: value.option?.value,
                  },
                });
              }}
            />
          )}
        </Field>
      </JustificationContainer>
    </JustificationContainer>
  </AppDetailsContainer>
);

export default Design;
