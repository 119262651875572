import React, { useState } from 'react';
import {
  BarChart as RechartBarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import styled, { useTheme } from 'styled-components';
import getDataPointColor from '~/util/getDataPointColor';
import ChartTooltip from '../Tooltip';
import Tick from '../components/Tick';
import FakeCursor from '../components/FakeCursor';
import useLocale from '~/hooks/useLocale';
import { Theme } from '~/theme';
import { ChartDataPoint } from '..';
import TEST_ID from './index.testid';
import { isNil, omit } from 'ramda';

export type Props = {
  data: Array<ChartDataPoint<number>>;
  /** To format the value in ChartDataPoint */
  formatOptions: Intl.NumberFormatOptions & {
    // Not actually supported by Safari 14.1.x
    signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
  };
  tooltipLabel?: string;
};

const BarChart: React.FCC<Props> = ({
  data,
  dataTestId,
  tooltipLabel,
  formatOptions,
}) => {
  const locale = useLocale();
  const [activeGroup, setActiveGroup] = useState<number | null>(null);
  const safeFormatOptions = omit(['signDisplay'], formatOptions);
  const numberFormatter = new Intl.NumberFormat(locale, safeFormatOptions);

  const theme = useTheme() as Theme;

  return (
    <Container data-testid={`${dataTestId}-${TEST_ID.CONTAINER}`}>
      <ResponsiveContainer width={'100%'}>
        <RechartBarChart
          width={500}
          height={300}
          data={data}
          layout="vertical"
          margin={{ top: 0, right: 16, bottom: 50, left: 120 }}
        >
          <CartesianGrid strokeDasharray="0" horizontal={false} />
          <YAxis
            dataKey="name"
            type="category"
            orientation="left"
            axisLine={false}
            tickLine={false}
            padding={{ top: 0 }}
            onMouseLeave={() => setActiveGroup(null)}
            // @ts-ignore
            onMouseEnter={({ index }) => setActiveGroup(index)}
            tick={<Tick<number> data={data} />}
          />
          <XAxis
            type="number"
            orientation="top"
            axisLine={false}
            tickLine={false}
          />
          <Tooltip
            cursor={<FakeCursor setActiveGroup={setActiveGroup} />}
            content={<ChartTooltip omitLabel={tooltipLabel === undefined} />}
            formatter={value => {
              if (typeof value !== 'number') return value;

              const formattedValue = numberFormatter.format(value);
              if (
                !isNil(formatOptions.signDisplay) &&
                formatOptions.signDisplay === 'exceptZero' &&
                value > 0
              ) {
                return `+${formattedValue}`;
              }

              return formattedValue;
            }}
            isAnimationActive={false}
          />

          <Bar dataKey={'value'} radius={10} barSize={8} name={tooltipLabel}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${entry.id}`}
                style={{
                  fill: getBarFill(entry, index, activeGroup, theme),
                }}
              />
            ))}
          </Bar>
        </RechartBarChart>
      </ResponsiveContainer>
    </Container>
  );
};

const getBarFill = (
  _entry: ChartDataPoint<number>,
  index: number,
  activeGroup: number | null,
  theme: Theme,
) => {
  if (activeGroup !== null) {
    return activeGroup === index
      ? getDataPointColor(index, theme)
      : theme.color('tertiary', 'light');
  }

  return getDataPointColor(index, theme);
};

const Container = styled.div<{}>`
  width: 100%;
  max-width: 620px;
  height: 350px;

  svg.recharts-surface {
    overflow: visible;
  }
`;

export default BarChart;
