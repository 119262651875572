import { useMemo } from 'react';
import ScreenNode from './components/ScreenNode';
import EventNode from './components/EventNode';
import type { NodeProps } from 'reactflow';
import type { CommonNodeData } from '..';
import BoundingNode from './components//BoundingNode';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';

// Ratio 16:9 which is most common browser ratio
// https://ux.stackexchange.com/questions/74549/whats-the-most-common-aspect-ratio-in-terms-of-web-design
// https://ratiobuddy.com/
export const DEFAULT_NODE_WIDTH = 480;
export const DEFAULT_NODE_HEIGHT = 270;

export type NodeTypes = {
  screenNode: React.FC<NodeProps<CommonNodeData>>;
  eventNode: React.FC<NodeProps<CommonNodeData>>;
  boundingNode: React.FC<NodeProps<{}>>;
  submitScreen: React.FC<NodeProps<CommonNodeData>>;
};

export type UserDefinableNodeType = {
  screenNode: React.FC<NodeProps<CommonNodeData>>;
  eventNode: React.FC<NodeProps<CommonNodeData>>;
  submitScreen: React.FC<NodeProps<CommonNodeData>>;
};

export const nodeTypeLabels: Record<keyof UserDefinableNodeType, string> = {
  eventNode: 'Nieuw Event',
  screenNode: 'Nieuwe pagina',
  submitScreen: 'Verzendpagina',
};
export const nodeTypeColorCoding: Record<
  keyof UserDefinableNodeType,
  ThemeColor
> = {
  eventNode: {
    group: 'success',
  },
  screenNode: {
    group: 'primary',
  },
  submitScreen: {
    group: 'accent',
  },
};

const useNodeTypes = (): NodeTypes => {
  const nodeTypes: NodeTypes = useMemo(
    () => ({
      screenNode: ScreenNode,
      eventNode: EventNode,
      submitScreen: ScreenNode,
      boundingNode: BoundingNode,
    }),
    [],
  );

  return nodeTypes;
};

export default useNodeTypes;
