import {} from 'react';
import useViewingMode from '../useViewingMode';
import { ImpersonationViewingMode } from '~/graphql/types';

export type ViewingModeProps = {
  'data-redacted': boolean;
};

const useViewingModeProps = (): ViewingModeProps => {
  const viewingMode = useViewingMode();

  const props: ViewingModeProps = {
    'data-redacted': viewingMode === ImpersonationViewingMode.Restricted,
  };

  return props;
};

export default useViewingModeProps;
