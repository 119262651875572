import React from 'react';
import styled, { css } from 'styled-components';
import { Label, Props as TypographyProps, Variant } from '../Typography';

export type Props = TypographyProps;

const HighlightedLabel: React.FCC<Props> = ({ children, ...rest }) => (
  <Container fontWeight="regular" variant={Variant.primary} {...rest}>
    {children}
  </Container>
);

const Container = styled(Label)(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.m};
    background: ${theme.color('secondary', 'light')};
    padding: calc(${theme.space('xs')} / 2) ${theme.space('xxs')};
    width: max-content;
    color: ${theme.color('primary')};
  `,
);

export default HighlightedLabel;
