import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ActivityFields_Event_Contact_Email_Fragment,
  EventContactEmail_New,
  TaskStatus,
  useInsertEmailMutation,
} from '~/graphql/types';
import { NamedEmail } from '~/util/namedEmailFormatter';
import TaskModalContext from '~/components/page/Tasks/context/TaskModalContext';
import EmailInputFields from './components/EmailInputFields';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Catalog from '~/Catalog';
import getInitialEmailValue from './utils/getInitialEmailValue';
import convertAttachments from '~/components/organism/PluginsEditor//utils/signatures/convertAttachments';
import { getLocalStorageItem, getSignatureKey } from '~/util/localStorageKeys';
import useSignatures from '~/hooks/useSignatures';
import useCurrentUser from '~/hooks/useCurrentUser';
import { useRecoilState } from 'recoil';
import { GetFullEmailMessage } from '../FullEmailMessage';
import defaultSignatureKey from '~/components/molecule/SignatureDropdownList/state';
import { getErrorType } from '~/util/errorHandling';

const text = {
  invalidValues:
    'Er staan een of meerdere ongeldige waarden in de e-mail. Probeer het opnieuw.',
  replyMessageText: (
    date: Date | null,
    email: string,
    name: string | null | undefined,
  ) => {
    const endName = name == null ? `(${email})` : `${name} &lt;${email}&gt;`;

    if (date) {
      return `Op ${date.toLocaleString()} heeft ${endName} het volgende geschreven:`;
    } else {
      return `${endName} heeft het volgende geschreven:`;
    }
  },
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
};

export type EmailProps = {
  readOnly?: boolean;
  namedEmail?: NamedEmail;
};

export type EmailListProps = {
  readOnly?: boolean;
  namedEmailList?: Array<NamedEmail>;
};

export type TextProps = {
  readOnly?: boolean;
  text?: string;
};

export type PassthroughProps = {
  sender?: EmailProps;
  receiver: NamedEmail;
  cc?: EmailListProps;
  bcc?: EmailListProps;
  subject?: TextProps;
  body?: TextProps;
};
type Props = PassthroughProps & {
  /** Event id to pass if it is rendered in an event card */
  eventId?: string;

  /** called when the e-mail has been sent successfully */
  onSuccess?: (
    newActivity: ActivityFields_Event_Contact_Email_Fragment,
  ) => void;

  /** Needed for the mutation */
  contactId: string;

  /** Function for close modal if its no timeline */
  handleCloseModal?: () => void;

  /** If the component should be in the loading state */
  loading?: boolean;

  /** Shows error message. Usually a mutation error */
  error?: boolean;

  /** If replying to an email give the activity id, from and when the mail was received of that e-mail. The component will grab the full message and add it to the body */
  replyTo?: {
    eventId: string;
    from: NamedEmail;
    receivedAt: Date | null;
  };

  /** Function to set if needed to change task status or close a modal */
  setAfterSendActions: (arg0: {
    allowUpdateStatus: boolean;
    allowClose: boolean;
  }) => void;
};
const SendEmailBlock: React.FCC<Props> = ({
  onSuccess,
  handleCloseModal,
  contactId,
  replyTo,
  body,
  setAfterSendActions,
  loading = false,
  error = false,
  ...rest
}) => {
  const [hasError, setError] = useState(false);
  const account = useCurrentAccount();
  const me = useCurrentUser();
  const addToast = useAddToast();

  const [insertEmail, { loading: insertEmailLoading }] =
    useInsertEmailMutation();

  const signatures = useSignatures();

  const [signatureKey, setSignatureKey] = useRecoilState(defaultSignatureKey);

  const signatureInLocalStorage = getLocalStorageItem(
    getSignatureKey(account.id),
  );

  const userSignatureId = `${account.id}#${me.id}`;

  useEffect(() => {
    setSignatureKey(signatureInLocalStorage ?? userSignatureId);
  }, [userSignatureId, setSignatureKey, signatureInLocalStorage]);

  const getFullBody = useCallback(
    (appendBody?: string) => {
      const signatureInLocalStorage = getLocalStorageItem(
        getSignatureKey(account.id),
      );

      const defaultSignature = signatures.find(
        ({ id }) => id === (signatureInLocalStorage ?? userSignatureId),
      );
      const signature =
        defaultSignature && defaultSignature.html
          ? convertAttachments({
              str: defaultSignature.html,
              attachments: defaultSignature.attachments,
            })
          : '';

      return getInitialEmailValue({
        replyBlock: appendBody || '',
        signature,
      });
    },
    /**
     * signatureKey is added as an extra dependency to persist the last selected signature after resetting the editor value.
     * We ensure that getFullBody always has the latest signature value.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account.id, userSignatureId, signatures, signatureKey],
  );

  const onSendPressed = useCallback(
    (sendEmailBlockVariables: EmailInputFieldsVariables) => {
      setError(false);

      const variables = {
        accountId: account.id,
        email: {
          ...sendEmailBlockVariables,
          contactId,
        },
      };

      return insertEmail({
        variables,
      }).then(({ errors, data }) => {
        if (errors && errors.length > 0) {
          setError(true);

          let errorMessage = text.genericErrorMessage;
          const errorType = getErrorType(errors[0]);

          if (errorType === 'DHValidationError') {
            errorMessage = text.invalidValues;
          }

          return addToast([formatToastMessage(errorMessage, 'danger')]);
        }
        if (data && onSuccess) {
          return onSuccess(
            data.insertActivity as ActivityFields_Event_Contact_Email_Fragment,
          );
        }
        return;
      });
    },
    [account.id, addToast, contactId, insertEmail, onSuccess],
  );

  const { taskDetails } = useContext(TaskModalContext);

  const isCompleted = taskDetails
    ? taskDetails.status === TaskStatus.Closed
    : false;

  const emailProps = {
    hasError: error || hasError,
    canClose: handleCloseModal != null,
    loading: insertEmailLoading || loading,
    isCompleted,
    setAfterSendActions,
    onSendPressed,
  };

  if (replyTo) {
    return (
      <GetFullEmailMessage
        eventId={replyTo.eventId}
        renderProp={fullBody => (
          <EmailInputFields
            {...rest}
            body={{
              readOnly: body?.readOnly,
              text: getFullBody(
                `${text.replyMessageText(
                  replyTo.receivedAt,
                  replyTo.from.email,
                  replyTo.from.name,
                )}<blockquote>${fullBody}</blockquote>`,
              ),
            }}
            {...emailProps}
          />
        )}
      />
    );
  }

  return (
    <EmailInputFields
      {...rest}
      {...emailProps}
      body={{ readOnly: body?.readOnly, text: getFullBody('') }}
    />
  );
};

export type EmailInputFieldsVariables = Omit<
  EventContactEmail_New,
  'contactId'
>;

export default SendEmailBlock;
