import React, { useEffect, useState } from 'react';
import {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import useWizardStep from '~/hooks/useWizardStep';
import { Body } from '~/components/atom/Typography';
import styled, { css } from 'styled-components';
import {
  WidgetDescriptionItem,
  WidgetItemGroup,
  useGetWidgetItemGroupsQuery,
} from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Loading from '~/components/atom/Loading';
import ErrorScreen from '~/components/page/ErrorScreen';
import WidgetGroupsScreen from './screens/WidgetGroupsScreen';
import WidgetItemsScreen from './screens/WidgetItemsScreen';
import getWidgetGroupsMapOutput from './screens/WidgetGroupsScreen/utils/getWidgetGroupsMapOutput';
import { AppConfig } from '~/components/page/Apps/types';
import useApps from '~/hooks/useApps';
import { isEmpty } from 'ramda';
import { title as stellaxTitle } from '../OnboardingStellaxAiSelectProduct';

export const id = 'SelectAppsForWidget';
export const title = 'Widget apps selecteren';

const text = {
  body: (
    <>
      Zorg voor optimale leadgeneratie en service op je website.
      <br />
      <br />
      Maak hier een selectie van de apps die jij op je website wil tonen. Denk
      bijvoorbeeld aan het toevoegen van een waardecheck of hypotheekrekentools,
      waarmee je leads genereert.
      <br />
      <br />
      Voeg ook diensten toe waarmee je service verleent, zoals
      Nutsvoorzieningen, Energielabel, Bouwkundige keuring of een van de
      woondiensten via Trustoo.
    </>
  ),
};

type ExtendedWidgetItem = WidgetDescriptionItem & {
  type: WidgetItemGroup['type'];
};

export type SelectedGroups = {
  [key in WidgetItemGroup['type']]: Array<
    WidgetDescriptionItem | ExtendedWidgetItem
  >;
};

export type AppsAndItemsToEnable = {
  [key in AppConfig['__typename']]: Array<WidgetDescriptionItem>;
};

export type OutputType = {
  type: typeof id;
  appsAndItemsToEnable: OutputFieldInvisible<AppsAndItemsToEnable | undefined>;
  widgetItemGroups: OutputFieldInvisible<Array<WidgetItemGroup>>;
  selectedGroupsMap: OutputFieldInvisible<SelectedGroups | undefined>;
};

export const Component: React.FCC<WizardStepProps> = ({ outputMap, step }) => {
  const [, api] = useWizardStep(step.id);
  const { id: accountId } = useCurrentAccount();
  const currentOutput = outputMap[id] as OutputType;

  const [appScreen, setAppScreen] = useState<'group' | 'items'>('group');
  const [groupToShow, setGroupToShow] = useState<WidgetItemGroup | null>(null);

  // Selected items in formatted groups
  const [selectedGroupsMap, setSelectedGroupsMap] = useState<
    SelectedGroups | undefined
  >(currentOutput.selectedGroupsMap.value);

  const [groups, setGroups] = useState<Array<WidgetItemGroup>>(
    currentOutput.widgetItemGroups.value,
  );

  const { data, error, loading } = useGetWidgetItemGroupsQuery({
    variables: { accountId, permittedOnly: true },
  });

  const { apps, loading: appStatusLoading, error: appStatusError } = useApps();

  useEffect(() => {
    if (data && groups.length === 0) setGroups(data.getWidgetItemGroups);
  }, [data, groups.length]);

  useEffect(() => {
    // Create a map to put selected items into
    if (data && !selectedGroupsMap && groups.length) {
      const groupsMap = groups.reduce((acc, curr) => {
        if (!acc[curr.type]) {
          acc[curr.type] = [];
        }
        return acc;
      }, {} as SelectedGroups);

      setSelectedGroupsMap(groupsMap);
    }
  }, [data, groups, selectedGroupsMap]);

  useEffect(() => {
    const selectedAppsToEnable = getWidgetGroupsMapOutput(selectedGroupsMap);
    const output: OutputType = {
      type: id,
      selectedGroupsMap: {
        type: 'invisible',
        value: selectedGroupsMap,
      },
      appsAndItemsToEnable: {
        type: 'invisible',
        value: selectedAppsToEnable,
      },
      widgetItemGroups: {
        type: 'invisible',
        value: groups,
      },
    };

    // Disable go next button if the user is not on the main screen (groups screen)
    // Otherwise they will go next for sure and forget about other apps we have (Anastasia got this feedback)
    if (loading || appScreen === 'items') {
      api.lock(output);
      api.lockGoBack();
    } else {
      // Step ready to go from mount so free it up
      api.free(output);
      if (
        output.appsAndItemsToEnable.value &&
        'AppStatus_StellaxAi' in output.appsAndItemsToEnable.value
      ) {
        api.free({
          ...output,
          appsAndItemsToEnable: {
            ...output.appsAndItemsToEnable,
            value: {
              ...output.appsAndItemsToEnable.value,
              AppStatus_StellaxAi: [
                // Because we have 2 report types and FE cannot know which one was selected at this point,
                // we use a generic "Selected report" string instead of the value provided by BE.
                {
                  ...output.appsAndItemsToEnable.value.AppStatus_StellaxAi[0],
                  name: 'Geselecteerde rapport',
                },
              ],
            },
          },
        });
        api.addSubStep({
          id: 'OnboardingStellaxAiSelectProduct',
          title: stellaxTitle,
        });
      } else {
        // In the case the user added this previously we just always attempt to remove it
        // the internal reducer will return early if no work is needed so this is pretty safe.
        api.removeSubStep({
          id: 'OnboardingStellaxAiSelectProduct',
          title: stellaxTitle,
        });
      }

      api.freeGoBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupsMap, groups, loading, appScreen]);

  if (loading || appStatusLoading) return <Loading />;
  if (!data || error || appStatusError || isEmpty(apps)) return <ErrorScreen />;

  return (
    <>
      <Body>{text.body}</Body>
      <AppsSelectContainer
        margin={['base', null]}
        padding={['m']}
        direction="column"
      >
        {appScreen === 'group' && (
          <WidgetGroupsScreen
            apps={apps}
            outputMap={outputMap}
            selectedGroupsMap={selectedGroupsMap}
            groups={groups}
            onSelectGroup={(group: WidgetItemGroup) => {
              if (group.items.length === 1 && selectedGroupsMap) {
                const isSelected = selectedGroupsMap[group.type].length === 1;

                setSelectedGroupsMap((prevState: SelectedGroups) => ({
                  ...prevState,
                  [group.type]: isSelected ? [] : [group.items[0]],
                }));
              } else {
                setGroupToShow(group);
                // Show selected items or prefill with default selection
                const selectedItems =
                  selectedGroupsMap && selectedGroupsMap[group.type].length > 0
                    ? selectedGroupsMap[group.type]
                    : group.items.filter(item => item.enabled === true);
                setSelectedGroupsMap(prev => ({
                  ...prev,
                  [group.type]: selectedItems,
                }));
                setAppScreen('items');
              }
            }}
          />
        )}
        {appScreen === 'items' && groupToShow && selectedGroupsMap && (
          <WidgetItemsScreen
            onGoBack={() => {
              setAppScreen('group');
              setGroupToShow(null);
            }}
            groupToShow={groupToShow}
            selectedGroupsMap={selectedGroupsMap}
            setSelectedGroupsMap={setSelectedGroupsMap}
          />
        )}
      </AppsSelectContainer>
    </>
  );
};

const AppsSelectContainer = styled(JustificationContainer)(
  ({ theme }) => css`
    background: ${theme.color('tertiary', 'translucent')};
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

export default {
  id,
  title,
};
