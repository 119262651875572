import {
  GetCouponQuery as GetCouponQueryResult,
  CouponDuration,
} from '~/graphql/types';

import cleanedFilename from '~/util/cleanedFilename';
import { reporter } from '~/hooks/useErrorReporter';

export enum COUPON_TYPE {
  FIXED_AMOUNT_COUPON = 'FIXED_AMOUNT_COUPON',
  PERCENT_AMOUNT_COUPON = 'PERCENT_AMOUNT_COUPON',
}

export type BaseCoupon = {
  id: string;
  name: string;
  durationString: string | null; // if forever no explanation is needed
  calculateOff: (amount: number) => number;
};
export type FixedAmountCoupon = BaseCoupon & {
  type: COUPON_TYPE.FIXED_AMOUNT_COUPON;
  amountOff: number;
};
export type PercentageCoupon = BaseCoupon & {
  type: COUPON_TYPE.PERCENT_AMOUNT_COUPON;
  percentOff: number;
};
export type Coupon = FixedAmountCoupon | PercentageCoupon;

const convertToCoupon = (data: GetCouponQueryResult): Coupon | null => {
  const { getCoupon } = data;

  if (getCoupon == null) {
    reporter.captureException(
      new Error(
        `${cleanedFilename(__filename)} | Should not occur | getCoupon == null`,
      ),
    );
    return null;
  }

  const {
    amountOff,
    canBeApplied,
    name,
    coupon,
    duration,
    durationInMonth,
    percentOff,
  } = getCoupon;

  if (canBeApplied == false) {
    return null;
  }

  let durationString: string | null = null;
  switch (duration) {
    case CouponDuration.Forever:
      durationString = null;
      break;
    case CouponDuration.Once:
      durationString = 'Toegepast eenmalig op de eerste factuur.';
      break;
    case CouponDuration.Repeating:
      if (durationInMonth == null) {
        reporter.captureException(
          new Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | durationInMonth == null in ${JSON.stringify(
              getCoupon,
              null,
              2,
            )}`,
          ),
        );
        durationString = null;
      } else {
        durationString = `Toegepast gedurende ${durationInMonth} mnd bij maandabonnement of eenmalig bij jaarabonnement.`;
      }

      break;
    default:
      reporter.captureException(
        new Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | invalid duration in ${JSON.stringify(
            getCoupon,
            null,
            2,
          )}`,
        ),
      );
      durationString = null;
  }

  const basicCoupon = {
    id: coupon,
    name,
    durationString,
  };

  if (amountOff != null) {
    return {
      ...basicCoupon,
      type: COUPON_TYPE.FIXED_AMOUNT_COUPON,
      amountOff,
      calculateOff: _amount => amountOff,
    };
  } else if (percentOff != null) {
    return {
      ...basicCoupon,
      type: COUPON_TYPE.PERCENT_AMOUNT_COUPON,
      percentOff,
      calculateOff: amount => Math.round(amount * (percentOff / 100)),
    };
  } else {
    reporter.captureException(
      new Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | amountOff and parentOff are both null, at least on should have been set!`,
      ),
    );

    return null;
  }
};

export default convertToCoupon;
