/**
 *
 * Provides our client side postcode validation
 */

import StringValidation from './String';

/**
 * Uses same regex as backend.
 * Make sure it consistent if changing it
 */
const POSTCODE_REGEX = /^\d{4}[A-Z]{2}$/;
const STARTS_WITH_VALID_POSTCODE = /^\d{0,4}[A-Z]{0,2}$/;

export const isValid = (postcode: any) => {
  if (!StringValidation.isString(postcode)) return false;

  return POSTCODE_REGEX.test(postcode);
};

export const startsWithValidPostcode = (string: string): boolean => {
  if (!StringValidation.isString(string)) return false;

  return STARTS_WITH_VALID_POSTCODE.test(string);
};

export const parsePostalCode = (value: string | null): string | null => {
  if (value == null) return null;

  const re = /([0-9]{1,4})([a-zA-Z]{1,2})?/;

  try {
    const [, digits = '', letters = ''] = value.match(re) ?? [];
    return `${digits}${letters.toUpperCase()}` || null;
  } catch {
    return null;
  }
};

export default {
  isValid,
  startsWithValidPostcode,
  parsePostalCode,
};
