import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { ComponentUiStreamer } from '~/components/template/ConversionPage/types';
import { Heading3 } from '~/components/atom/Typography';
import styled from 'styled-components';

export type Props = ComponentUiStreamer & {};

const Streamer: React.FCC<Props> = ({ dataTestId, ...props }) => (
  <JustificationContainer
    gap="l"
    justification="center"
    align="center"
    dataTestId={dataTestId}
  >
    <Inner>
      <Heading3>{props.body}</Heading3>
    </Inner>
  </JustificationContainer>
);

const Inner = styled.div`
  max-width: 720px;
`;

export default Streamer;
