import React from 'react';
import { useTheme } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label } from '~/components/atom/Typography';

export type Props = { shared: boolean; onClick: () => void };

const ShareIndicator: React.FC<Props> = ({ shared, onClick }) => {
  const theme = useTheme();
  return (
    <JustificationContainer
      width="100%"
      justification="start"
      align="center"
      gap="s"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }}
    >
      <Icon
        name="share"
        color={theme.color(shared ? 'secondary' : 'tertiary')}
        size="m"
      />
      <Label size="base" margin={[null]} textDecoration="underline">
        {shared ? 'Instellingen' : 'Delen'}
      </Label>
    </JustificationContainer>
  );
};

export default ShareIndicator;
