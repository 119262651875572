import React from 'react';
import styleToJS from 'style-to-js';

/**
 * Taken from html-react-parser https://github.com/remarkablemark/html-react-parser
 */

export const styleToJSOptions = { reactCompat: true };

/**
 * Sets style prop in props object.
 *
 * @param {null|undefined|string} style
 * @param {object} props
 */
export function setStyleProp(style, props) {
  if (style === null || style === undefined) {
    return;
  }
  try {
    props.style = styleToJS(style, styleToJSOptions);
  } catch (err) {
    props.style = {};
  }
}

/**
 * @constant {boolean}
 * @see {@link https://reactjs.org/blog/2017/09/08/dom-attributes-in-react-16.html}
 */

export const PRESERVE_CUSTOM_ATTRIBUTES =
  Number(React.version.split('.')[0]) >= 16;
