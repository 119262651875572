const blobToDataURL = (blob: Blob): Promise<string> =>
  new Promise(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      if (typeof event.target?.result === 'string') {
        resolve(event.target.result);
      }
    };
    fileReader.readAsDataURL(blob);
  });

export default blobToDataURL;
