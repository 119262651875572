import { isNil } from 'ramda';
import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Button from '~/components/atom/Button';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';

import TEST_ID from './index.testid';
import Toolbar from '~/components/molecule/Toolbar';
import Link from '~/components/molecule/Link';
import { Body, Variant } from '~/components/atom/Typography';
import RouterTransitionContext, {
  withRouterTransitionContext,
} from '~/contexts/RouterTransitionContext';
import type { Size } from '~/styles/constants';
import type { SaveBarMessage } from './components/MessagesContainer';
import MessagesContainer from './components/MessagesContainer';
import type { SystemSize } from '~/theme';

export type Props = {
  /** Set it to true if we want to navigate back */
  withGoBackLink?: boolean;

  /** Pass the link where to navigate */
  goBackLink?: string;

  /** If changes count, Cancel button will be shown, navigation back not allowed */
  changes?: number;

  /** If errors, navigation back not allowed, Save button disabled */
  messages?: Array<SaveBarMessage>;

  /** What to do when the user clicks save */
  onSave: () => void;

  /** What to do when the user clicks cancel */
  onCancel: () => void;

  /** Loading state */
  loading?: boolean;

  /** Size of the buttons and text on the left */
  size?: Size;

  /** Disables the save button */
  disabled?: boolean;
  margin?: Array<SystemSize | null>;
};

const NewSaveBar: React.FCC<Props> = ({
  dataTestId,
  goBackLink,
  changes,
  messages = [],
  onSave,
  onCancel,
  withGoBackLink,
  disabled,
  loading = false,
  size = 'medium',
  margin = [null],
  ...rest
}) => {
  const hasChanges = !isNil(changes) && changes !== 0;
  const hasMessages = messages.length !== 0;
  const hasValidBackLink = withGoBackLink && !isNil(goBackLink);
  const { leaveHandler } = useContext(RouterTransitionContext);

  useEffect(() => {
    if (hasChanges) {
      leaveHandler(false);
    }

    return () => {
      leaveHandler(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanges]);

  return (
    <Toolbar dataTestId={dataTestId} margin={margin} {...rest}>
      {!hasChanges && !hasMessages && hasValidBackLink && (
        <GoBackLinkContainer
          justification="start"
          align="center"
          margin={[null, null, null, 'xxs']}
          dataTestId={TEST_ID.GO_BACK_BUTTON}
        >
          <StyledLink to={goBackLink}>
            <StyledIcon name="arrowLeft" strokeWidth={3} />
            Terug naar overzicht
          </StyledLink>
        </GoBackLinkContainer>
      )}
      <JustificationContainer
        justification="end"
        align="center"
        dataTestId={TEST_ID.SAVE_BAR_CHANGES}
        width="100%"
      >
        {!hasMessages && hasChanges && (
          <JustificationContainer
            align="center"
            width="100%"
            margin={[null, 'm', null, 'xxs']}
            data-testid={TEST_ID.CHANGE_COUNT_CONTAINER}
          >
            <Body
              margin={[null]}
              variant={Variant.secondary}
              size={size === 'small' ? 's' : 'base'}
            >
              Je hebt {changes} niet opgeslagen aanpassingen.
            </Body>
          </JustificationContainer>
        )}

        {hasMessages && <MessagesContainer messages={messages} size={size} />}

        <ButtonsContainer>
          {hasChanges && (
            <Button
              appearance="danger"
              label="Afbreken"
              ghost
              size={size}
              onClick={onCancel}
              disabled={loading}
              dataTestId={TEST_ID.CANCEL_BUTTON}
            />
          )}

          <StyledButton
            label="Aanpassingen opslaan"
            icon="save"
            size={size}
            appearance="secondary"
            disabled={disabled || hasMessages}
            onClick={onSave}
            dataTestId={TEST_ID.SAVE_BUTTON}
            loading={loading}
          />
        </ButtonsContainer>
      </JustificationContainer>
    </Toolbar>
  );
};

const StyledIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('xxs')};
    color: ${theme.color('primary', 'light')};
  `,
);

const StyledLink = styled(Link)<{}>(
  ({ theme }) => css`
    text-decoration: none;
    padding-top: ${theme.space('xxxs')};
    font-weight: ${theme.fw('semiBold')};
    display: flex;
  `,
);

const GoBackLinkContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    width: 100%;

    &:hover {
      color: ${theme.color('primary')};

      svg {
        color: ${theme.color('primary')};
      }
    }
  `,
);

const StyledButton = styled(Button)<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('m')};
    width: max-content;
  `,
);

const ButtonsContainer = styled.div<{}>`
  display: flex;
`;

export default withRouterTransitionContext(NewSaveBar);
