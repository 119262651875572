import React, { type CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';

export type Props = {
  onClick?: () => void;
  style?: CSSProperties;
  name: string;
};

const BrowserFrame: React.FCC<Props> = ({
  dataTestId,
  children,
  name,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <JustificationContainer
      align="center"
      backgroundColor={{ group: 'white' }}
      padding={['s']}
      gap="base"
    >
      <Icon strokeWidth={3} name="desktop" />
      <Heading4 margin={['xxxs', null, null, null]} variant={Variant.primary}>
        {name}
      </Heading4>
    </JustificationContainer>
    <ChildContainer>{children}</ChildContainer>
  </Container>
);

/**
 * All "fixed colours" in this file have been snapped from https://browserframe.com/
 */

const Container = styled.div<{}>(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    overflow: hidden;
    box-shadow: ${theme.boxShadow('modal')};
  `,
);

const ChildContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    position: relative;
    overflow: hidden;
  `,
);

export default BrowserFrame;
