import React from 'react';
import FlagProvider from '@unleash/proxy-client-react';
import GlobalStyle from '~/theme/GlobalStyle';
import theme from '~/theme';
import { ThemeProvider } from 'styled-components';
import { RecoilRoot } from 'recoil';
import AppSyncProvider from './AppSync';
import { ErrorBoundary } from '@sentry/react';

import RouterTransitionContextProvider from '~/contexts/RouterTransitionContextProvider';
import LocaleContext from '~/contexts/LocaleContext';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import 'normalize.css';

import { isProduction } from '~/util';

type Props = {
  children: React.ReactNode;
  path?: string;
  default?: boolean;
};

const Root: React.FCC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <ErrorBoundary fallback={<AppErrorScreen />}>
      {/* @ts-ignore */}
      <FlagProvider
        config={{
          url: process.env.UNLEASH_URL as string,
          clientKey: process.env.UNLEASH_SECRET as string,
          appName: 'sfa-app',
          // env.STAGE_NAME for production is set to `prod` however that does not match the name of the Gitlab environment
          environment: isProduction() ? 'production' : process.env.STAGE_NAME,
        }}
      >
        <AppSyncProvider>
          <RecoilRoot>
            <LocaleContext.Provider value={{ locale: 'nl-NL' }}>
              <RouterTransitionContextProvider>
                <GlobalStyle theme={theme} />
                {children}
              </RouterTransitionContextProvider>
            </LocaleContext.Provider>
          </RecoilRoot>
        </AppSyncProvider>
      </FlagProvider>
    </ErrorBoundary>
  </ThemeProvider>
);

export default Root;
