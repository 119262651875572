import { atom, selector } from 'recoil';
import type { SaveBarMessage } from '~/components/organism/NewSaveBar/components/MessagesContainer';
import { widgetRoutes } from '../../constants';
import type { WidgetValues } from '../../components/WidgetContent';
import validateZIndex from '../../utils/validateZIndex';

const currentWidgetState = atom<WidgetValues | null>({
  key: 'currentWidgetState',
  default: null,
});

const externalIssuesState = atom<Array<SaveBarMessage & { path: string }>>({
  key: 'externalIssuesState',
  default: [],
});

export const widgetIssues = selector({
  key: 'widgetIssuesSelector',
  get: ({ get }) => {
    const widgetValues = get(currentWidgetState);
    const issues: Array<SaveBarMessage<{ path: string }>> = [];

    if (widgetValues) {
      const validatedZIndex = validateZIndex(widgetValues.design.zIndex);
      if (validatedZIndex !== true) {
        issues.push({
          message: validatedZIndex,
          key: 'zIndex',
          path: widgetRoutes.design,
          type: 'danger',
        });
      }
    }

    const externalIssues = get(externalIssuesState);

    return [...issues, ...externalIssues];
  },
  set: ({ set }, newIssues) => {
    set(externalIssuesState, newIssues);
  },
});
export default currentWidgetState;
