import React, { useRef, useState } from 'react';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import BaseButton from '../Base';

import Icon, { IconType } from '~/components/atom/Icon';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import type { Option } from '~/components/molecule/Dropdown';

export type Props = {
  options?: Array<Option>;
  icon: IconType;
  selectedOption?: string | number;
  tooltipMessage?: string;
  editor: DHEditor;
  /**
   * If you want to keep the selection in the editor when you click the options button.
   * Must be false in hovering toolbars otherwise dropdown list cannot be clicked
   * */
  persistFocus?: boolean;
};

const OptionsButton: React.FCC<Props> = ({
  dataTestId,
  icon,
  options = [],
  selectedOption,
  tooltipMessage,
  persistFocus = false,
  editor,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <Container>
      <BaseButton
        icon={icon}
        onClick={() => {
          setShowOptions(prev => !prev);
          if (persistFocus) ReactEditor.focus(editor);
        }}
        active={showOptions}
        tooltipMessage={showOptions ? '' : tooltipMessage}
        dataTestId={dataTestId}
        ref={buttonRef}
      >
        <Icon name="chevron" flipX={showOptions} strokeWidth={2.5} />
      </BaseButton>

      <DropdownListContainer
        options={options}
        dropdownListOpen={showOptions}
        selectedOptionIdx={
          options?.findIndex(option => option.payload === selectedOption) || 0
        }
        onChange={({ option }) => {
          if (option.onClickAction) {
            option.onClickAction(option.payload);
          }

          setShowOptions(false);
        }}
        onClickOutside={() => setShowOptions(false)}
        onClose={() => setShowOptions(false)}
        loading={false}
        openerRef={buttonRef}
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  position: relative;
`;

export const OptionLabel = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    /* Pass margin to icons on the left in the options button */
    & > :first-child {
      margin-right: ${theme.space('xxs')};
    }
  `,
);

export default OptionsButton;
