import React from 'react';
import { Handle, Position } from 'reactflow';
import styled, { css, useTheme } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { FlowAction } from '~/graphql/types';

import { ClientFlowActionIfElse } from '~/graphql/types.client';
import getCardHeading from '../../../../utils/getCardHeading';
import { StatisticsProps, Units } from '../CardStatistics';
import StatisticsItem from '../CardStatistics/components/StatisticsItem';
import CardTemplate, { handleStyle, Props as CardProps } from '../CardTemplate';
import ConditionList from '../ConditionList';
import useRelativeMaps from '../../../../hooks/useRelativeMaps';
import { HandlerId } from './types';

export type Props = Omit<
  CardProps<ClientFlowActionIfElse>,
  'heading' | 'actionType'
> & {
  dataTestId?: string;
};

const IfElseCard: React.FCC<Props> = React.memo(
  ({ selected, disabled, ...props }) => {
    const theme = useTheme();
    const stats = props.data.action.Statistics;
    const trueBranchStat = stats?.trueBranch ?? 0;
    const falseBranchStat = stats?.falseBranch ?? 0;
    const maps = useRelativeMaps({ actionId: props.data.action.id });
    const totalStat = trueBranchStat + falseBranchStat;

    const statistics: Array<StatisticsProps> = [
      {
        color: theme.color('success'),
        heading: 'Waar',
        value: trueBranchStat,
        unit: Units.Leads,
        icon: 'check',
        total: totalStat,
      },
      {
        color: theme.color('danger'),
        heading: 'Niet waar',
        value: falseBranchStat,
        unit: Units.Leads,
        total: totalStat,
        icon: 'error',
      },
    ];

    return (
      <>
        <Handle
          type="target"
          position={Position.Top}
          style={handleStyle}
          isConnectable={false}
        />
        <CardTemplate
          {...props}
          actionType={FlowAction.IfElse}
          heading={getCardHeading(FlowAction.IfElse)}
          selected={selected}
          disabled={disabled}
          dataTestId="IfElseCard"
        >
          <JustificationContainer
            justification="start"
            margin={[null, null, 'xxxl', null]}
          >
            <ConditionList
              actionType={FlowAction.IfElse}
              condition={props.data.action.condition}
              maps={maps}
              actionId={props.data.action.id}
            />
          </JustificationContainer>
          <br />
          <Statistics>
            {statistics.map(stat => (
              <StatisticsItemContainer key={stat.heading}>
                <StatisticsItem {...stat} />
              </StatisticsItemContainer>
            ))}
          </Statistics>
        </CardTemplate>
        <Handle
          type="source"
          id={HandlerId.trueChild}
          position={Position.Bottom}
          style={{ background: 'transparent', left: '20%' }}
          isConnectable={false}
        />
        <Handle
          type="source"
          id={HandlerId.falseChild}
          position={Position.Bottom}
          style={{ background: 'transparent', left: '80%' }}
          isConnectable={false}
        />
      </>
    );
  },
);

const StatisticsItemContainer = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing('m')};
    width: 100%;
    border-radius: ${theme.getTokens().border.radius.base};
    background: ${theme.color('white')};
    box-shadow: ${theme.getTokens().boxShadow.s};
  `,
);

const Statistics = styled.div(
  ({ theme }) => css`
    max-width: 420px;
    width: 100%;
    position: absolute;
    left: 18px;
    bottom: -22px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${theme.spacing('m')};
  `,
);

export default IfElseCard;
