import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ascend, descend, isNil, prop, sort } from 'ramda';
import { useTable, useSortBy, useFlexLayout } from 'react-table';
import { Header, Body as TableBody } from '~/components/bad/DataTables/styling';
import { HeaderCell, RowCell } from '~/components/bad/DataTables';
import TEST_ID from './index.testid';
import { columns } from './constants';
import { SortDirection } from '~/graphql/types';
import { ACTION_COLUMN_ACCESSOR } from '~/components/bad/DataTables/util/TableHeaderCell/action';
import useConfirmModal from '~/hooks/useConfirmModal';
import ActionColumnCell from '~/components/bad/DataTables/components/ActionColumnCell';
import Row from '~/components/bad/DataTables/styling/Row';
import useErrorReporter from '~/hooks/useErrorReporter';

export type Row = {
  name: string;
  fields: string;
};

export type Props = {
  data: Array<Row>;
  onDelete: (id: string) => void;
  onCopy: (id: string) => void;
  onEdit: (id: string) => void;
  loading: boolean;
};

const defaultColumn = {
  minWidth: 20,
  width: 120,
  maxWidth: 200,
};
const defaultSortColumn = 'name';

const confirmDeleteModalText = {
  title: 'Koppeling verwijderen',
  message: 'Weet je zeker dat je deze koppeling wilt verwijderen?',
  buttonConfirmTitle: 'Verwijderen',
};

const confirmCopyModalText = {
  title: 'Koppeling kopiëren?',
  message: 'Weet je zeker dat je deze koppeling wilt kopiëren?',
  buttonConfirmTitle: 'Kopiëren',
};

const Table: React.FCC<Props> = ({
  data,
  onDelete,
  onCopy,
  onEdit,
  loading,
  ...rest
}) => {
  const errorReporter = useErrorReporter();
  const [sortedItems, setSortedItems] = useState<Array<Row>>(
    sort(descend(prop(defaultSortColumn)), data),
  );

  const onSort = (dataProperty: keyof Row, sortDirection: SortDirection) => {
    const direction = sortDirection === SortDirection.Desc ? descend : ascend;
    const sortFn = direction<Row>(prop(dataProperty));
    const sorted = sort(sortFn, data);
    setSortedItems(sorted);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns(onSort),
        data: sortedItems,
        defaultColumn,
        disableMultiSort: true,
        autoResetSortBy: false,
        autoResetHiddenColumns: false,
        getRowId: ({ id }) => id,
        manualSortBy: true,
        disableSortRemove: true,
        initialState: {
          sortBy: [{ id: defaultSortColumn, desc: true }],
        },
      },
      useFlexLayout,
      useSortBy,
    );

  useEffect(() => {
    if (data.length !== sortedItems.length) {
      setSortedItems(sort(descend(prop(defaultSortColumn)), data));
    }
  }, [data, sortedItems]);

  const [itemToDelete, setItemToCopy] = useState<string | null>(null);
  const { setShowModal: showDeleteConfirmModal, modal: deleteConfirmModal } =
    useConfirmModal({
      level: 'danger',
      labels: confirmDeleteModalText,
      buttons: [
        {
          appearance: 'danger',
          onClick: () => {
            if (isNil(itemToDelete)) {
              return errorReporter.captureException(
                new Error('itemToDelete is null in Zapier'),
              );
            }
            onDelete(itemToDelete);
          },
          label: confirmDeleteModalText.buttonConfirmTitle,
        },
      ],
    });

  const [itemToCopy, setItemToDelete] = useState<string | null>(null);
  const { setShowModal: showCopyConfirmModal, modal: copyConfirmModal } =
    useConfirmModal({
      labels: confirmCopyModalText,
      buttons: [
        {
          appearance: 'primary',
          onClick: () => {
            if (isNil(itemToCopy)) {
              return errorReporter.captureException(
                new Error('itemToCopy is null in Zapier'),
              );
            }
            onCopy(itemToCopy);
          },
          label: confirmCopyModalText.buttonConfirmTitle,
        },
      ],
    });

  return (
    <Container {...getTableProps()} {...rest} data-testid={TEST_ID.CONTAINER}>
      {deleteConfirmModal}
      {copyConfirmModal}

      <Header.Large>
        {headerGroups.map(headerGroup => {
          const headerGroupProps = { ...headerGroup.getHeaderGroupProps() };
          return (
            <StyledHeaderTr
              key={headerGroupProps.key}
              data-testid={TEST_ID.HEADER_ROW}
            >
              {headerGroup.headers.map((column, idx) => {
                const isLastColumn = idx === headerGroup.headers.length - 1;
                const headerCellKey = `header-${idx}`;

                return (
                  <HeaderCell
                    key={headerCellKey}
                    column={column}
                    isLastColumn={isLastColumn}
                  />
                );
              })}
            </StyledHeaderTr>
          );
        })}
      </Header.Large>
      <TableBody.Standard.Outer>
        <TableBody.Standard.Inner {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);

            const rowProps = { ...row.getRowProps() };
            const eventId = row.id;

            return (
              <Row
                {...rowProps}
                key={rowProps.key}
                data-objectid={eventId}
                dataTestId={TEST_ID.LIST_ITEM}
                to={
                  eventId.startsWith('ze')
                    ? `/-/apps/zapier/events/${eventId}`
                    : `/-/apps/zapier/triggers/${eventId}`
                }
              >
                {row.cells.map((cell, idx) => {
                  const isLastColumn = idx === row.cells.length - 1;

                  if (cell.column.id === ACTION_COLUMN_ACCESSOR) {
                    return (
                      <ActionColumnCell
                        key={'event-action-column-cell'}
                        loading={loading}
                        options={[
                          {
                            label: 'Aanpassen',
                            onClick: () => onEdit(cell.row.id),
                            key: 'edit',
                          },
                          {
                            label: 'Kopiëren',
                            onClick: () => {
                              setItemToDelete(cell.row.id);
                              showCopyConfirmModal(true);
                            },
                            key: 'copy',
                          },
                          {
                            label: 'Verwijderen',
                            onClick: () => {
                              setItemToCopy(cell.row.id);
                              showDeleteConfirmModal(true);
                            },
                            key: 'delete',
                            type: 'DELETE',
                          },
                        ]}
                        cell={cell}
                      />
                    );
                  }

                  return (
                    <RowCell
                      key={`cell-${idx}`}
                      cell={cell}
                      isLastColumn={isLastColumn}
                    />
                  );
                })}
              </Row>
            );
          })}
        </TableBody.Standard.Inner>
      </TableBody.Standard.Outer>
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
`;

const StyledHeaderTr = styled.div<{}>`
  white-space: nowrap;
  display: flex;
`;

export default Table;
