import React, {
  forwardRef,
  useCallback,
  useRef,
  type MutableRefObject,
} from 'react';
import styled, { css } from 'styled-components';
import {
  type FormBuilder_Locale,
  type FormBuilder_ScreenNode,
  type FormBuilder_ScreenNode_Block,
  type Maybe,
} from '~/graphql/types';
import DraggableBlockWrapper from './components/DraggableBlockWrapper';
import { DND_SOURCES } from '../../constants';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { useRecoilState, useRecoilValue, type SetterOrUpdater } from 'recoil';
import AddNewBlock from './components/AddNewBlock';
import { type Issue } from '~/components/page/Forms/components/Builder/state/issues';
import { isNil } from 'ramda';
import { useDrop } from 'react-dnd';
import { ItemType, type DnDItemProps } from '~/components/atom/DraggableItem';
import { nodeById } from '~/components/page/Forms/components/Builder/state/nodesAndEvents';
import EmbeddedPreview from './components/EmbeddedPreview';
import { optionListsState } from '~/components/page/Forms/components/Builder/state/optionList';

type Props = {
  nodeId: string;
  issues: Array<Issue | null>;
  currentLocale: FormBuilder_Locale;
  focusedBlock: FormBuilder_ScreenNode_Block | null;
  onDelete: (block: FormBuilder_ScreenNode_Block, index: number) => void;
  onBlockFocus: (block: Maybe<FormBuilder_ScreenNode_Block> | null) => void;
};

const BlocksArea = forwardRef<HTMLDivElement, Props>(
  (
    { nodeId, focusedBlock, issues, onBlockFocus, onDelete, currentLocale },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement | null>(
      null,
    ) as MutableRefObject<HTMLDivElement | null>;
    const optionLists = useRecoilValue(optionListsState);

    const [node, setNodeState] = useRecoilState(nodeById(nodeId)) as [
      Maybe<FormBuilder_ScreenNode>,
      SetterOrUpdater<FormBuilder_ScreenNode | null>,
    ];

    const moveBlock = useCallback((dragIndex: number, hoverIndex: number) => {
      setNodeState(prev => {
        if (!prev) return prev;
        const updatedBlocks = [...prev.blocks];
        const [draggedItem] = updatedBlocks.splice(dragIndex, 1);
        updatedBlocks.splice(hoverIndex, 0, draggedItem);
        return { ...prev, blocks: updatedBlocks };
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMoveBlock = (index: number, direction: 'up' | 'down') => {
      const newIndex = direction === 'up' ? index - 1 : index + 1;
      moveBlock(index, newIndex);

      // Scroll to the active block
      if (containerRef.current) {
        // The children include both blocks and AddNewBlock components, so multiply by 2
        const activeBlock = containerRef.current.children[newIndex * 2];
        if (activeBlock) {
          activeBlock.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    };

    const [, drop] = useDrop({
      accept: ItemType,
      hover: (item: DnDItemProps, monitor) => {
        if (item.source !== DND_SOURCES.blocksArea) return;
        const container = containerRef.current;
        if (!container) return;

        const containerRect = container.getBoundingClientRect();
        const clientOffset = monitor.getClientOffset();

        if (clientOffset) {
          // Scrolling when touches the edges
          const { y } = clientOffset;
          const scrollThreshold = 50;
          const scrollSpeed = 5;

          if (y - containerRect.top < scrollThreshold) {
            container.scrollTop -= scrollSpeed;
          } else if (containerRect.bottom - y < scrollThreshold) {
            container.scrollTop += scrollSpeed;
          }

          // Reodering blocks
          const dragItemIndex = item.index;
          const hoverIndex = node?.blocks.findIndex((_, index) => {
            const hoverBoundingRect =
              container.children[index * 2].getBoundingClientRect();
            const hoverMiddleY =
              (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            return (
              hoverClientY > hoverMiddleY &&
              hoverClientY < hoverBoundingRect.bottom - hoverBoundingRect.top
            );
          });

          if (hoverIndex === -1 || dragItemIndex === hoverIndex) return;

          moveBlock(dragItemIndex, hoverIndex ?? -1);
          item.index = hoverIndex ?? -1;
        }
      },
    });

    const onAddNewBlock = (block: FormBuilder_ScreenNode_Block) =>
      onBlockFocus(block);

    const setRef = useCallback(
      (el: HTMLDivElement | null) => {
        containerRef.current = el;
        if (typeof ref === 'function') {
          ref(el);
        } else if (ref) {
          ref.current = el;
        }
        drop(el);
      },
      [drop, ref],
    );

    if (!node) return;

    return (
      <JustificationContainer
        ref={setRef}
        width="100%"
        direction="column"
        backgroundColor={{ group: 'primary', variant: 'translucent' }}
        padding={[null, 'base']}
        border={{ radius: 'base' }}
        style={{ overflowY: 'auto' }}
        height="100vh"
      >
        <AddNewBlock nodeId={node.id} onBlockFocus={onAddNewBlock} index={0} />
        {node?.blocks.map((block, index) => {
          const blockIssues = issues.find(
            issue => issue?.blockKey === block.key,
          );

          const isTheLastBlock = node.blocks.length - 1 === index;
          const isTheFirstBlock = index === 0;

          return (
            <React.Fragment key={block.key}>
              <DraggableBlockWrapper
                block={block}
                index={index}
                data-block-key={block.key}
                hasIssues={!isNil(blockIssues)}
                focused={focusedBlock?.key === block.key}
                onDelete={() => onDelete(block, index)}
                onClick={() => onBlockFocus(block)}
                onArrowDown={
                  isTheLastBlock
                    ? undefined
                    : () => handleMoveBlock(index, 'down')
                }
                onArrowUp={
                  isTheFirstBlock
                    ? undefined
                    : () => handleMoveBlock(index, 'up')
                }
              >
                <ReadOnlyContainer>
                  <EmbeddedPreview
                    block={block}
                    optionLists={optionLists}
                    currentLocale={currentLocale}
                  />
                </ReadOnlyContainer>
              </DraggableBlockWrapper>
              <AddNewBlock
                nodeId={node.id}
                onBlockFocus={onAddNewBlock}
                index={index + 1}
              />
            </React.Fragment>
          );
        })}
      </JustificationContainer>
    );
  },
);

const ReadOnlyContainer = styled.div<{}>(
  () => css`
    pointer-events: none;
    width: 100%;
  `,
);

export default BlocksArea;
