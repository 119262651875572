import { title as connectTitle } from './ConnectZapier';
import { title as addEventTitle } from './AddZapierEvent';
import { title as addTriggerTitle } from './AddZapierTrigger';
import { title as nextStepsTitle } from './ZapierNextSteps';
import { WizardFlow } from '~/graphql/types.client';

const typename = 'AppStatus_Zapier';
const zapierEnableSteps: WizardFlow = {
  id: 'enable-zapier',
  steps: [
    { title: connectTitle, id: 'ConnectZapier', metadata: { typename } },
    { title: addTriggerTitle, id: 'AddZapierTrigger', metadata: { typename } },
    { title: addEventTitle, id: 'AddZapierEvent', metadata: { typename } },
    { title: nextStepsTitle, id: 'ZapierNextSteps', metadata: { typename } },
  ],
};

export default zapierEnableSteps;
