import { ConnectorOperator } from '~/graphql/types';

export type ExpressionGroup = {
  connector: ConnectorOperator;
  conditionSubExpression: Array<ConditionSubExpression>;
};

type ConditionSubExpression = {
  connector: ConnectorOperator;
  conditionGroup: Array<ConditionGroup>;
};

type ConditionGroup = {
  connector: ConnectorOperator;
  indices: Array<number>;
};

const extractExpressionV2 = (expRaw: string): ExpressionGroup => {
  const exp = retrofitConditionGroups(expRaw);
  const mainConnector = extractMainConnector(exp);

  const result: ExpressionGroup = {
    connector: mainConnector,
    conditionSubExpression: [],
  };

  extractSubConditions(exp).forEach(subExpressionExp => {
    const connectorArray = /\](.*?)\[/g.exec(subExpressionExp);
    const connector =
      (connectorArray?.[1] ?? '').trim() === '||'
        ? ConnectorOperator.Or
        : ConnectorOperator.And;

    const conditionSubExpression: ConditionSubExpression = {
      connector,
      conditionGroup: [],
    };

    extractConditionGroups(subExpressionExp).forEach(conditionGroupExp => {
      const connector = conditionGroupExp.includes('||')
        ? ConnectorOperator.Or
        : ConnectorOperator.And;

      const indices =
        conditionGroupExp.match(/\d+/g)?.map(i => parseInt(i, 10)) ?? [];

      conditionSubExpression.conditionGroup.push({
        connector,
        indices,
      });
    });

    result.conditionSubExpression.push(conditionSubExpression);
  });

  return result;
};

const extractMainConnector = (exp: string): ConnectorOperator => {
  const mainConnectorArray = /\)(.*?)\(/g.exec(exp);
  return (mainConnectorArray?.[1] ?? '').trim() === '||'
    ? ConnectorOperator.Or
    : ConnectorOperator.And;
};

const extractSubConditions = (exp: string): Array<string> => {
  const regExp = /\((.*?)\)/g;
  const result: Array<string> = [];

  let subExpressionCaptureGroup: RegExpExecArray | null;
  while ((subExpressionCaptureGroup = regExp.exec(exp)) != null) {
    result.push(subExpressionCaptureGroup[1]);
  }

  return result;
};

const extractConditionGroups = (exp: string): Array<string> => {
  const regExp = /\[(.*?)\]/g;
  const result: Array<string> = [];

  let subExpressionCaptureGroup: RegExpExecArray | null;
  while ((subExpressionCaptureGroup = regExp.exec(exp)) != null) {
    result.push(subExpressionCaptureGroup[1]);
  }

  return result;
};

export const generateExpression = (expressionGroup: ExpressionGroup): string =>
  `(${expressionGroup.conditionSubExpression
    .map(
      subExpression =>
        `[${subExpression.conditionGroup
          .map(conditionGroup =>
            conditionGroup.indices.join(
              ` ${getBooleanOperator(conditionGroup.connector)} `,
            ),
          )
          .join(`] ${getBooleanOperator(subExpression.connector)} [`)}]`,
    )
    .join(`) ${getBooleanOperator(expressionGroup.connector)} (`)})`;

export const retrofitConditionGroups = (expRaw: string): string => {
  const exp = expRaw
    .replace(/\s*/g, '')
    .replace(/&&/g, ' && ')
    .replace(/\|\|/g, ' || ')
    .trim();
  const mainConnector = extractMainConnector(exp);

  return `(${extractSubConditions(exp)
    .map(subConditionExpression => {
      if (/^\[.*\]$/.test(subConditionExpression)) {
        return subConditionExpression;
      }

      const tokenSplit = subConditionExpression.split(' ');

      let insideConditionGroup = false;
      const fixedTokens = tokenSplit.map(token => {
        if (token === '&&') return token;
        if (token === '||') return token;

        if (insideConditionGroup) {
          if (token.endsWith(']')) insideConditionGroup = false;
          return token;
        }

        if (token.startsWith('[')) {
          insideConditionGroup = true;
          return token;
        }

        return `[${token}]`;
      });

      return fixedTokens.join(' ');
    })
    .join(`) ${getBooleanOperator(mainConnector)} (`)})`;
};

export const getBooleanOperator = (operator: ConnectorOperator) =>
  operator === ConnectorOperator.Or ? '||' : '&&';

export default extractExpressionV2;
