import { omit } from 'ramda';
import {
  AppStatus_Contaqt__Input,
  AppStatus_Realworks__Input,
  AppStatus_Trustoo__Input,
  AppStatus__Input,
  WidgetSettingsAppConfigurationItem,
} from '~/graphql/types';
import { AppStatus } from '~/graphql/types.client';
import { reporter } from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';
import getWidgetAppConfigurationUpdate from '~/util/getWidgetAppConfigurationUpdate';
import removeTypenames from '~/util/removeTypenames';

type Args = {
  completed: boolean;
  currentAppStatus: AppStatus;
  updatedItems?: Array<WidgetSettingsAppConfigurationItem>;
};

const getUpdateForAppStatus = ({
  completed,
  currentAppStatus,
  updatedItems,
}: Args): AppStatus__Input => {
  const currentMetadata =
    'metadata' in currentAppStatus
      ? currentAppStatus.metadata
      : {
          setup: {
            completed,
          },
        };

  const setup = {
    ...removeTypenames(currentMetadata?.setup ?? {}),
    completed,
  };

  if (currentAppStatus.__typename === 'AppStatus_Realworks') {
    const update: AppStatus_Realworks__Input = {
      enabled: true,
      usageAgreement: true,
      metadata: {
        ...removeTypenames(currentMetadata ?? {}),
        setup,
      },
    };
    return { AppStatus_Realworks: update };
  }

  if (currentAppStatus.__typename === 'AppStatus_Trustoo') {
    const tokens =
      'tokens' in currentAppStatus
        ? currentAppStatus.tokens.map(token =>
            omit(['__typename', 'TokenInfo'], token),
          )
        : [];

    const update: AppStatus_Trustoo__Input = {
      ...getWidgetAppConfigurationUpdate(currentAppStatus, updatedItems),
      enabled: true,
      tokens,
      metadata: {
        ...removeTypenames(currentMetadata ?? {}),
        setup,
      },
    };
    return { AppStatus_Trustoo: update };
  }

  if (currentAppStatus.__typename === 'AppStatus_Contaqt') {
    const token = 'token' in currentAppStatus ? currentAppStatus.token : null;
    const addedToken =
      currentAppStatus.metadata &&
      'addedToken' in currentAppStatus.metadata.setup
        ? currentAppStatus.metadata.setup.addedToken
        : null;

    if (addedToken === null) {
      reporter.captureException(
        new Error(
          `Could not find token for ${
            currentAppStatus.__typename
          } in ${cleanedFilename(__filename)}`,
        ),
        'error',
      );
      // still return the update so that we can set the rest of the metadata
    }

    const update: AppStatus_Contaqt__Input = {
      enabled: true,
      metadata: {
        setup: {
          addedFlows: currentAppStatus.metadata?.setup.addedFlows,
          addedToken: addedToken || null,
          completed,
        },
      },
      token,
    };

    return {
      AppStatus_Contaqt: update,
    };
  }

  const update = {
    ...getWidgetAppConfigurationUpdate(currentAppStatus, updatedItems),
    enabled: true,
    metadata: {
      ...removeTypenames(currentMetadata ?? {}),
      setup,
    },
  };
  return {
    [currentAppStatus.__typename]: update,
  };
};

export default getUpdateForAppStatus;
