import { useCallback, useMemo } from 'react';
import { Resetter, useResetRecoilState } from 'recoil';
import { formState, interactionState, menuState } from '../../state';
import { copyScreenNodeState } from '../../state/copyScreenNode';

/**
 * Resets all recoil atoms related to form builder state
 */
const useResetFormBuilder = (): Resetter => {
  const resetFormState = useResetRecoilState(formState);
  const resetInteractionState = useResetRecoilState(interactionState);
  const resetMenuState = useResetRecoilState(menuState);
  const resetCopyScreenNodeState = useResetRecoilState(copyScreenNodeState);

  const resetFns = useMemo(
    () => [
      resetFormState,
      resetInteractionState,
      resetMenuState,
      resetCopyScreenNodeState,
    ],
    [
      resetFormState,
      resetInteractionState,
      resetMenuState,
      resetCopyScreenNodeState,
    ],
  );

  const resetAll = useCallback(() => {
    resetFns.forEach(fn => fn());
  }, [resetFns]);

  return resetAll;
};

export default useResetFormBuilder;
