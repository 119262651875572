/**
 * True if the code is live in production
 */
export const isProduction = () => process.env.STAGE_NAME === 'prod';

export const isLiveStage = (): boolean =>
  process.env.STAGE_NAME !== undefined && isProduction();

export const isCI = !!process.env.CI;

/**
 * Sleeps the time in ms.
 */
export const sleep = (time: number): Promise<void> =>
  new Promise(r => setTimeout(r, time));
