import { TEMPLATE_STRING_VARIABLE_REGEX } from '~/components/organism/PluginsEditor/constants';
import isPointerArgument from '../../../../util/isPointerArgument';
import getPathForPointer from '../../../UpdateAction/components/Selector/utils/getPathForPointer';
import type { DirectoryMap } from '../../../UpdateAction/components/Selector/utils/getDirectory';
import type { InstanceMap } from '../../../UpdateAction/components/Selector/utils/getInstance';
import type { SubjectMap } from '../../../UpdateAction/components/Selector/utils/getSubject';
import { getPathRepresentation } from '../../../UpdateAction/components/ConditionEditorV2/utils/getLabelForRep';
import type { FlowV2_TemplateStringFragment } from '~/graphql/types';

/**
 * Converts a pointer into a readable string
 */
const convertTemplateStringToLabel = ({
  templateString,
  mappings,
  instanceMap,
  directoryMap,
  subjectMap,
}: {
  templateString: string;
  mappings: FlowV2_TemplateStringFragment['mappings'];
  instanceMap: InstanceMap;
  directoryMap: DirectoryMap;
  subjectMap: SubjectMap;
}): string => {
  const newString = templateString.replace(
    TEMPLATE_STRING_VARIABLE_REGEX,
    matchedVariable => {
      const mappingId = matchedVariable.substring(
        2,
        matchedVariable.length - 2,
      );

      const templateStringVariable = mappings.find(
        mapping => mapping.mappingId === mappingId,
      );

      if (templateStringVariable == null) {
        return '';
      }

      const argument = templateStringVariable.mapping;
      if (isPointerArgument(argument)) {
        const path = getPathForPointer(argument.pointer, {
          subjectMap,
          instanceMap,
          directoryMap,
        });

        if ('fullResult' in path && path.fullResult) {
          // Convert card labels from [[A1]] to `A1 - `
          const rep = getPathRepresentation(path.fullResult)
            .split('[[')
            .join('')
            .split(']]')
            .join(' - ');

          return rep;
        }
      }

      return '';
    },
  );

  return newString;
};

export default convertTemplateStringToLabel;
