import { defaultRemarkReactComponents } from '~/components/molecule/Markdown';
import { Body } from '~/components/atom/Typography';

const getMarkdownOptions = () => ({
  remarkReactComponents: {
    ...defaultRemarkReactComponents,
    p: Body,
  },
});

export default getMarkdownOptions;
