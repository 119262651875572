import {
  FlowOutputObject,
  GenericFlowOutputObject,
  EmailFlowOutputObject,
} from './types.flow';
import { FLOW_OUTPUT_TYPE } from '~/util/constants';

const contactOutputObject: FlowOutputObject = {
  name: 'contact',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputContact,
  objectLabel: 'Contact',
  objectSelectLabel: 'Selecteer contact',
  fields: [
    {
      label: 'Naam',
      name: 'name',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Telefoonnummer',
      name: 'phone',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'E-mailadres',
      name: 'email',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Woonadres > Straat + huisnummer + toevoeging',
      name: 'address',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Woonadres > Stad',
      name: 'address.city',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Woonadres > Postcode',
      name: 'address.postcode',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Woonadres > Straatnaam',
      name: 'address.street',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
  ],
};
const emailOutputObject: FlowOutputObject = {
  name: 'email',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventEmail,
  objectLabel: 'E-mail',
  objectSelectLabel: 'Selecteer e-mail',
  fields: [
    {
      label: 'Onderwerp',
      name: 'subject',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Uitschrijflink',
      name: 'unsubscribeLink',
      outputType: FLOW_OUTPUT_TYPE.Link,
    },
  ],
  links: [],
};
const valuationReportOutputObject: FlowOutputObject = {
  name: 'eventContactAppValuationReport',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppValuationReport,
  objectLabel: 'Waarderapport',
  objectSelectLabel: 'Selecteer waarderapport',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputNumber,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.addition',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Waarde',
      name: 'valuation.predicted',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputNumber,
    },
    {
      label: 'Contactverzoek',
      name: 'contactRequested',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputBoolean,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Waarderapport pdf',
      name: 'pdfReport',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputFile,
    },
  ],
};
const valuationRequestOutputObject: FlowOutputObject = {
  name: 'eventContactAppValuationRequest',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppValuationRequest,
  objectLabel: 'Waarderapport - waardebepaling',
  objectSelectLabel: 'Selecteer waardebepaling',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputNumber,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.addition',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
  ],
};

const fundaContactRequestOutputObject: FlowOutputObject = {
  name: 'eventContactAppFundaContactRequest',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppFundaContactRequest,
  objectLabel: 'Funda contactverzoek',
  objectSelectLabel: 'Selecteer Funda contactverzoek',
  fields: [
    {
      label: 'Adres uit Funda verzoek',
      name: 'subjectAddress',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
  ],
};
const fundaViewingRequestOutputObject: FlowOutputObject = {
  name: 'eventContactAppFundaViewingRequest',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppFundaViewingRequest,
  objectLabel: 'Funda bezichtigingsverzoek',
  objectSelectLabel: 'Selecteer Funda bezichtigingsverzoek',
  fields: [
    {
      label: 'Voorkeursdag',
      name: 'day',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Voorkeursdagdeel',
      name: 'timeOfDay',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Adres uit Funda verzoek',
      name: 'subjectAddress',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
  ],
};
const fundaBrochureRequestOutputObject: FlowOutputObject = {
  name: 'eventContactAppFundaBrochureRequest',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppFundaBrochureRequest,
  objectLabel: 'Funda brochureverzoek',
  objectSelectLabel: 'Selecteer Funda brochureverzoek',
  fields: [
    {
      label: 'Adres uit Funda verzoek',
      name: 'subjectAddress',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
  ],
};

const bbWaardecheckReportOutputObject: FlowOutputObject = {
  name: 'eventContactAppBBWaardecheckReport',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppBbWaardecheckReport,
  objectLabel: 'Brainbay waardecheck',
  objectSelectLabel: 'Selecteer brainbay waardecheck',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputNumber,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.houseNumberAddition',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Waarde',
      name: 'valuationPrice',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputNumber,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Waardecheck pdf',
      name: 'pdfReport',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputFile,
    },
  ],
};

const bbWaardecheckAppraisalRequestOutputObject: FlowOutputObject = {
  name: 'eventContactAppBBWaardecheckAppraisalRequest',
  outputType:
    FLOW_OUTPUT_TYPE.FlowOutputEventContactAppBbWaardecheckAppraisalRequest,
  objectLabel: 'Brainbay waardecheck - waardebepaling',
  objectSelectLabel: 'Selecteer brainbay waardecheck - waardebepaling',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputNumber,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.houseNumberAddition',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
  ],
};

const bbWaardecheckContactRequestOutputObject: FlowOutputObject = {
  name: 'eventContactAppBBWaardecheckContactRequest',
  outputType:
    FLOW_OUTPUT_TYPE.FlowOutputEventContactAppBbWaardecheckContactRequest,
  objectLabel: 'Brainbay contactverzoek',
  objectSelectLabel: 'Selecteer brainbay contactverzoek',
  fields: [],
};

const zapierOutputObject: FlowOutputObject = {
  name: 'eventContactAppZapier',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppZapier,
  objectLabel: 'Zapier event',
  objectSelectLabel: 'Selecteer Zapier event',
  fields: [],
};

const vboWaardecheckReportOutputObject: FlowOutputObject = {
  name: 'eventContactAppVBOWaardecheckReport',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppVboWaardecheckReport,
  objectLabel: 'Matrixian Waardecheck',
  objectSelectLabel: 'Selecteer Matrixian Waardecheck',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputNumber,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.houseNumberAddition',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Waarde',
      name: 'valuationPrice',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputNumber,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Waardecheck pdf',
      name: 'pdfReport',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputFile,
    },
  ],
};

const vboWaardecheckAppraisalRequestOutputObject: FlowOutputObject = {
  name: 'eventContactAppVBOWaardecheckAppraisalRequest',
  outputType:
    FLOW_OUTPUT_TYPE.FlowOutputEventContactAppVboWaardecheckAppraisalRequest,
  objectLabel: 'Matrixian Waardecheck - waardebepaling',
  objectSelectLabel: 'Selecteer Matrixian Waardecheck - waardebepaling',
  fields: [
    {
      label: 'Aangevraagd adres > Straat + huisnummer + toevoeging',
      name: 'property.address',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Stad',
      name: 'property.city',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Postcode',
      name: 'property.postcode',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Straatnaam',
      name: 'property.street',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Aangevraagd adres > Huisnummer',
      name: 'property.houseNumber',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputNumber,
    },
    {
      label: 'Aangevraagd adres > Huisnummer toevoeging',
      name: 'property.houseNumberAddition',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Reden aanvraag',
      name: 'why',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      label: 'Verhuisintentie',
      name: 'when',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
  ],
};

const vboWaardecheckContactRequestOutputObject: FlowOutputObject = {
  name: 'eventContactAppVBOWaardecheckContactRequest',
  outputType:
    FLOW_OUTPUT_TYPE.FlowOutputEventContactAppVboWaardecheckContactRequest,
  objectLabel: 'Matrixian Waardecheck contactverzoek',
  objectSelectLabel: 'Selecteer Matrixian contactverzoek',
  fields: [],
};

const hypotheekbondOutputObject: FlowOutputObject = {
  name: 'eventContactAppHypotheekbond',
  outputType: FLOW_OUTPUT_TYPE.FlowOutputEventContactAppHypotheekbond,
  objectLabel: 'Hypotheekbond event',
  objectSelectLabel: 'Selecteer Hypotheekbond event',
  fields: [
    {
      name: 'name',
      label: 'Naam',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      name: 'phone',
      label: 'Telefoonnummer',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
    {
      name: 'email',
      label: 'E-mailadres',
      outputType: FLOW_OUTPUT_TYPE.FlowOutputString,
    },
  ],
};

export const FLOW_OUTPUT_OBJECT: {
  CONTACT: GenericFlowOutputObject;
  EMAIL: EmailFlowOutputObject;
  APP_VALUATIONREPORT: GenericFlowOutputObject;
  APP_VALUATIONREQUEST: GenericFlowOutputObject;
  APP_FUNDA_CONTACTREQUEST: GenericFlowOutputObject;
  APP_FUNDA_VIEWINGREQUEST: GenericFlowOutputObject;
  APP_FUNDA_BROCHUREREQUEST: GenericFlowOutputObject;
  APP_BBWAARDECHECK_REPORT: GenericFlowOutputObject;
  APP_BBWAARDECHECK_APPRAISALREQUEST: GenericFlowOutputObject;
  APP_BBWAARDECHECK_CONTACTREQUEST: GenericFlowOutputObject;
  APP_ZAPIER: GenericFlowOutputObject;
  APP_VBOWAARDECHECK_REPORT: GenericFlowOutputObject;
  APP_VBOWAARDECHECK_APPRAISALREQUEST: GenericFlowOutputObject;
  APP_VBOWAARDECHECK_CONTACTREQUEST: GenericFlowOutputObject;
  APP_HYPOTHEEKBOND: GenericFlowOutputObject;
} = Object.freeze({
  CONTACT: contactOutputObject,
  EMAIL: emailOutputObject,
  APP_VALUATIONREPORT: valuationReportOutputObject,
  APP_VALUATIONREQUEST: valuationRequestOutputObject,
  APP_FUNDA_CONTACTREQUEST: fundaContactRequestOutputObject,
  APP_FUNDA_VIEWINGREQUEST: fundaViewingRequestOutputObject,
  APP_FUNDA_BROCHUREREQUEST: fundaBrochureRequestOutputObject,
  APP_BBWAARDECHECK_REPORT: bbWaardecheckReportOutputObject,
  APP_BBWAARDECHECK_APPRAISALREQUEST: bbWaardecheckAppraisalRequestOutputObject,
  APP_BBWAARDECHECK_CONTACTREQUEST: bbWaardecheckContactRequestOutputObject,
  APP_ZAPIER: zapierOutputObject,
  APP_VBOWAARDECHECK_REPORT: vboWaardecheckReportOutputObject,
  APP_VBOWAARDECHECK_APPRAISALREQUEST:
    vboWaardecheckAppraisalRequestOutputObject,
  APP_VBOWAARDECHECK_CONTACTREQUEST: vboWaardecheckContactRequestOutputObject,
  APP_HYPOTHEEKBOND: hypotheekbondOutputObject,
});

export const GLOBAL_VARIABLE_NAME = 'global';
export const GLOBAL_VARIABLE_STASH_ITEM = {
  variableName: GLOBAL_VARIABLE_NAME,
  variableLabel: 'Globaal',
  outputObjects: [FLOW_OUTPUT_OBJECT.CONTACT],
};
