/**
 *
 * General string validations
 */

import { isString } from 'lodash';
import { isNil, isEmpty as _isEmpty } from 'ramda';

export const isLowerCaseString = (value: any): boolean =>
  isString(value) && value.toLowerCase() === value;

export const isUpperCaseString = (value: any): boolean =>
  isString(value) && value.toUpperCase() === value;

export const isNonEmptyString = (value: any): value is string =>
  isString(value) && value.length > 0;

export const isEmpty = (value: any): boolean => isNil(value) || _isEmpty(value);

export const onlyOneIsNonEmptyString = (
  ...values: Array<Object | string | number | null>
): boolean => {
  let foundNonEmptyString = false;
  for (let i = 0; i < values.length; ++i) {
    const current = values[i];
    const isNonEmptyStr = isNonEmptyString(current);
    if (foundNonEmptyString && isNonEmptyStr) return false;
    if (isNonEmptyStr) foundNonEmptyString = true;
  }

  return foundNonEmptyString;
};

export const isSlug = (value: any): boolean =>
  isLowerCaseString(value) &&
  /([-_]*[a-zA-Z0-9]+([-_]*[a-zA-Z0-9]+)*)/gm.test(value) &&
  value.replace(' ', '') === value;

export default {
  isString,
  isLowerCaseString,
  isUpperCaseString,
  isNonEmptyString,
  isEmpty,
  onlyOneIsNonEmptyString,
  isSlug,
};
