import React from 'react';
import styled, { css } from 'styled-components';
import Link from '~/components/molecule/Link';
import useIntercom from '~/hooks/useIntercom';
import { ThemeColor } from '~/theme/System/tokens/colorPalette';

const chatText = {
  linkText: 'Start een chat met ons.',
};

export type Props = {
  //Text that will be sent to chat when click the link
  text?: string;

  linkText?: string;

  color?: ThemeColor;
};

const ChatLink: React.FCC<Props> = ({
  dataTestId,
  text,
  linkText = chatText.linkText,
  color = { group: 'primary', variant: 'light' },
  ...rest
}) => {
  const intercom = useIntercom();

  return (
    <StyledLink
      to="#"
      $color={color}
      data-testid={dataTestId}
      onClick={() => intercom.openChat(text)}
      {...rest}
    >
      {linkText}
    </StyledLink>
  );
};

const StyledLink = styled(Link)<{ $color: ThemeColor }>(
  ({ theme, $color }) => css`
    color: ${theme.color($color.group, $color.variant)};

    &:hover {
      color: ${theme.color($color.group, 'dark')};
    }
  `,
);

export default ChatLink;
