import React from 'react';
import { Redirect } from '@gatsbyjs/reach-router';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';

import withErrorBoundary from '~/ErrorBoundary';
import DHRouter from '~/components/atom/DHRouter';
import serializeFiltersV2 from './util/serializeFiltersV2';
import ContactList from './components/ContactList';
import Contact from './components/Contact';
import Loading from '~/components/atom/Loading';
import useContactFiltersV2 from '~/hooks/useContactFiltersV2';

type Props = RouteComponentProps;

const Contacts: React.FCC<Props> = () => {
  const [loading, storedFiltersV2] = useContactFiltersV2();

  if (loading) return <Loading />;

  return (
    <DHRouter height="auto">
      <ContactList path="/page/:currentPage" storedFilters={storedFiltersV2} />

      <Redirect
        from="/"
        to={`page/1?f=${serializeFiltersV2(storedFiltersV2)}`}
        noThrow
      />

      <Redirect
        from="/page"
        to={`/-/contacts/1?f=${serializeFiltersV2(storedFiltersV2)}`}
        noThrow
      />

      <Contact path="/:contactId" />
    </DHRouter>
  );
};

export default withErrorBoundary(Contacts);
