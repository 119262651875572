import { atom } from 'recoil';
import { WidgetSettingsPinned } from '~/graphql/types';

export type PinnedAppsMap = {
  [id: string]: WidgetSettingsPinned;
};

export type PinnedApp = {
  id: string;
  pinned: WidgetSettingsPinned;
};

export const pinnedAppsState = atom<PinnedAppsMap | null>({
  key: 'pinnedApps',
  default: null,
});

export default pinnedAppsState;
