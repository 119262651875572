import {
  FlowData___FlowInstanceFragment,
  GetFlowDataQuery,
} from '~/graphql/types';
import type { RelativeMaps } from '~/components/page/Automation/v2/components/Builder/utils/getRelativeMaps';
import { generateConditionMap } from '../getConditions';
import { generateDirectoryMap } from '../getDirectory';
import { generateInstanceMap } from '../getInstance';
import { generatePrimitiveInputMap } from '../getPrimitiveInput';
import { generateSubjectMap } from '../getSubject';

const generateMapsCombined = (
  flowData: GetFlowDataQuery['getFlowData'],
  instance?: Array<FlowData___FlowInstanceFragment>,
): RelativeMaps => {
  const { subjects, instance: instances } = flowData;
  const subjectMap = generateSubjectMap(subjects);
  const { conditionMap, subjectToConditionMap } = generateConditionMap(
    flowData.condition ?? [],
  );
  const directoryMap = generateDirectoryMap(subjects, instances, subjectMap);

  const primitiveInputMap = generatePrimitiveInputMap([
    ...flowData.primitiveInput,
    ...flowData.primitiveListInput,
  ]);

  const instanceMap = generateInstanceMap(instance ?? flowData.instance);

  return {
    instanceMap,
    subjectMap,
    directoryMap,
    primitiveInputMap,
    conditionMap,
    subjectToConditionMap,
    instances,
    subjects,
  };
};

export default generateMapsCombined;
