import { SortDirection } from '~/graphql/types';

const getNextSortDirection = (
  isSorted: boolean,
  isSortedDesc: boolean,
): SortDirection | null => {
  if (!isSorted) {
    return SortDirection.Asc;
  }

  if (isSortedDesc) {
    return SortDirection.Asc;
  } else {
    return SortDirection.Desc;
  }
};

export default getNextSortDirection;
