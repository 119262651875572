import { Flow___ConditionPartial } from '../../../ConditionEditorV2';
import { getCondition, ConditionMap } from '../getConditions';
import getSubject, {
  SubjectMap,
  Flow___SubjectIdentifier,
} from '../getSubject';

/**
 * Get the subject identifier of a given argument
 */
const getArgumentSubjectIdentifier = (
  {
    condition,
    argumentIndex,
  }: {
    condition: Flow___ConditionPartial;
    argumentIndex: number;
  },
  {
    subjectMap,
    conditionMap,
  }: {
    conditionMap: ConditionMap;
    subjectMap: SubjectMap;
  },
): Flow___SubjectIdentifier | null => {
  switch (condition.__typename) {
    case 'Flow___InstanceCondition': {
      const conditionData = getCondition(condition.type, conditionMap);

      return conditionData?.args[argumentIndex] ?? null;
    }
    case 'Flow___SubjectFieldCondition': {
      const subject = getSubject(
        { type: condition.type, typeId: condition.typeId },
        subjectMap,
      );
      const field = subject?.fields.find(
        field => field.key === condition.field,
      );

      return field?.args[argumentIndex] ?? null;
    }
  }
};
export default getArgumentSubjectIdentifier;
