import React from 'react';
import { Props } from '..';

const RealworksLogo: React.FCC<Props> = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    width="100%"
    height="100%"
    version="1.1"
    viewBox="0 0 64 64"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#A2C8EC"
      d="M32,0C14.3,0,0,14.3,0,32s14.3,32,32,32h32V32C64,14.3,49.7,0,32,0z M32,57.2C18.1,57.2,6.8,45.9,6.8,32 S18.1,6.8,32,6.8S57.2,18.1,57.2,32S45.9,57.2,32,57.2z"
    />
    <path
      fill="#0055A3"
      d="M41.7,36.7c-1.8-1.8-3.4-3.2-5.1-3.8c8.2-0.9,9.7-5.2,9.7-8.7c0-1.9-0.7-8.5-12.2-8.5c-9.7,0-18.7,0-18.7,0   v30.9h7.7V33.8c0,0,2.2,0,3.6,0c2.3,0,4.1,1.6,6,3.7c1.9,2.1,7.7,9.1,7.7,9.1h9.1C49.6,46.7,43.5,38.5,41.7,36.7z M23.2,28.9V21h9.7 c3.3,0,5.8,1.7,5.8,3.7s-1.5,4.2-5.8,4.2H23.2z"
    />
  </svg>
);

export default RealworksLogo;
