import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  LOGO_IMAGE_UPLOADER: `${cleanedFilename(__filename)}-logo-image-uploader`,
  BACKGROUND_IMAGE_UPLOADER: `${cleanedFilename(
    __filename,
  )}-background-image-uploader`,
  PRIMARY_COLOR_SELECT: `${cleanedFilename(__filename)}-primary-colour-select`,
  SECONDARY_COLOR_SELECT: `${cleanedFilename(
    __filename,
  )}-secondary-colour-select`,
  TERTIARY_COLOR_SELECT: `${cleanedFilename(
    __filename,
  )}-tertiary-colour-select`,
  QUATERNARY_COLOR_SELECT: `${cleanedFilename(
    __filename,
  )}-quaternary-colour-select`,
};
