export type PathViewRights = {
  mayViewSubscription: boolean;
  mayViewInvoices: boolean;
  mayViewAutomation: boolean;
};

const canSeeRoute = (
  { mayViewSubscription, mayViewInvoices, mayViewAutomation }: PathViewRights,
  link: string,
): boolean => {
  if (link === '/-/settings/subscription') return mayViewSubscription;
  if (link === '/-/settings/invoices') return mayViewInvoices;
  if (link === '/-/settings/automation') {
    return mayViewAutomation;
  }

  return true;
};
export default canSeeRoute;
