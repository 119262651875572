import type { ContactHasVBOWaardecheckContactRequestEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

const asHasVBOWaardecheckContactRequestEventFilterInput = (
  filter: ContactHasVBOWaardecheckContactRequestEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => ({
  App: {
    VBOWaardecheck: {
      ContactRequest: {
        has: asHasFilterInput(filter.has),
      },
    },
  },
});

export default asHasVBOWaardecheckContactRequestEventFilterInput;
