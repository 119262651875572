import React from 'react';
import styled from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Markdown from '~/components/molecule/Markdown';
import Screenshot from '~/components/molecule/Screenshot';
import getMediaURLPrefix from '../../utils/getMediaURLPrefix';
import StrapiButtons from '../StrapiButtons';
import { ComponentUiTwoColumn } from '~/components/template/ConversionPage/types';
import getMarkdownOptions from '../../utils/getMarkdownOptions';
import { Small } from '~/components/atom/Typography';

export type Props = ComponentUiTwoColumn & {};

const TwoColumn: React.FCC<Props> = ({ dataTestId, ...props }) => (
  <JustificationContainer
    gap="xxxl"
    justification="space-between"
    dataTestId={dataTestId}
  >
    {props.imageLeft === true && (
      <EvenlySpaced>
        <Screenshot
          withoutShadow={!props.Image.withShadow}
          alt={props.Image.alt}
          src={`${getMediaURLPrefix()}${props.Image.image.data.attributes.url}`}
        />
      </EvenlySpaced>
    )}
    <EvenlySpaced>
      {props.small && <Small margin={[null]}>{props.small}</Small>}
      <Markdown value={props.body} options={getMarkdownOptions()} />
      {props.buttons.data.length !== 0 && (
        <StrapiButtons buttons={props.buttons} />
      )}
    </EvenlySpaced>
    {props.imageLeft === false && (
      <EvenlySpaced>
        <Screenshot
          withoutShadow={!props.Image.withShadow}
          alt={props.Image.alt}
          src={`${getMediaURLPrefix()}${props.Image.image.data.attributes.url}`}
          maxHeight={props.Image.image.data.attributes.height}
        />
      </EvenlySpaced>
    )}
  </JustificationContainer>
);

const EvenlySpaced = styled.div`
  flex: 1 1 0px;
`;

export default TwoColumn;
