import {
  AppBbWaardecheckFieldsFragment,
  WidgetPosition,
} from '~/graphql/types';

export type Args = {
  accountName: string;
};

export type AppBBWaardecheckDefaultData = Omit<
  AppBbWaardecheckFieldsFragment,
  '__typename' | 'id' | 'accountId' | 'route'
>;

const defaultData = ({ accountName }: Args): AppBBWaardecheckDefaultData => ({
  general: {
    __typename: 'AppBBWaardecheckGeneral',
    background: {
      __typename: 'AppBBWaardecheckContainer',
      background: '#75b8c8',
      color: '#aaaaaa',
    },
    primary: {
      __typename: 'AppBBWaardecheckContainer',
      background: '#ee6055',
      color: '#ffffff',
    },
    secondary: {
      __typename: 'AppBBWaardecheckContainer',
      background: '#2b4162',
      color: '#ffffff',
    },
    tertiary: {
      __typename: 'AppBBWaardecheckContainer',
      background: '#ee6055',
      color: '#ffffff',
    },
    quaternary: {
      __typename: 'AppBBWaardecheckContainer',
      background: '#2b4162',
      color: '#ffffff',
    },
    logoImage: null,
    logoLink: {
      __typename: 'AppBBWaardecheckHref',
      href: { __typename: 'AppBBWaardecheckUrl', nl: null, en: null },
    },
    fallbackImage: null,
    cookieStatement: {
      __typename: 'AppBBWaardecheckHref',
      href: { __typename: 'AppBBWaardecheckUrl', nl: null, en: null },
    },
    privacyStatement: {
      __typename: 'AppBBWaardecheckHref',
      href: { __typename: 'AppBBWaardecheckUrl', nl: null, en: null },
    },
    googleAnalytics: null,
    facebookPixel: null,
    currentLocationLabel: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Hier woon je nu',
      en: 'You live here now',
    },
    html: 'Hello world',
  },
  address: {
    __typename: 'AppBBWaardecheckAddress',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Wat is mijn huis waard?',
      en: 'What is the value of my property?',
    },
    subHeading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Voer je adres in om de waardecheck te ontvangen',
      en: 'Enter your address to receive a property valuation',
    },
    primaryButton: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Volgende',
      en: 'Next',
    },
  },
  propertyType: {
    __typename: 'AppBBWaardecheckPropertyType',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Wat voor type woning is het?',
      en: 'What type of home is it?',
    },
  },
  why: {
    __typename: 'AppBBWaardecheckWhy',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Waarom doe je een waardecheck? Ik wil..',
      en: 'Why are you requesting this home valuation? I want..',
    },
  },
  when: {
    __typename: 'AppBBWaardecheckWhen',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Wanneer wil je verhuizen?',
      en: 'When would you like to move?',
    },
  },
  comparison: {
    __typename: 'AppBBWaardecheckComparison',
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Is deze woning vergelijkbaar?',
      en: 'Is this a comparable home?',
    },
  },
  valuationFailure: {
    __typename: 'AppBBWaardecheckValuationFailure',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Sorry, we kunnen geen waardecheck opstellen..',
      en: 'Sorry, we cannot calculate a home valuation..',
    },
    body: {
      __typename: 'AppBBWaardecheckText',
      nl: 'We hebben te weinig vergelijkbare woningen voor een betrouwbare waardecheck.',
      en: 'We do not have enough comparable homes for a reliable home valuation.',
    },
    primaryButton: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Gratis waardebepaling aanvragen',
      en: 'Request a free home valuation',
    },
    secondaryButton: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Of, bekijk de vergelijkbare woningen nogmaals',
      en: 'Or, do the the comparability check again',
    },
  },
  valuationSuccess: {
    __typename: 'AppBBWaardecheckValuationSuccess',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Waar wil het waardecheck rapport ontvangen?',
      en: 'Where can we send the home valuation report?',
    },
    disclaimer: {
      __typename: 'AppBBWaardecheckText',
      nl: `Door het versturen van dit formulier geef ik ${accountName} toestemming mij per e-mail het waarderapport toe te sturen en tot drie keer per e-mail en/of per telefoon contact met mij op te nemen met meer informatie over gerelateerde dienstverlening bij de koop of verkoop van mijn huis.`,
      en: `By submitting this form I allow ${accountName} to e-mail me the home valuation report and to contact me up to three times - either by phone or email -  regarding this home valuation or related broker services when buying or selling a home.`,
    },
    primaryButton: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Versturen',
      en: 'Submit',
    },
  },
  appraisalRequest: {
    __typename: 'AppBBWaardecheckAppraisalRequest',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Gratis waardebepaling aanvragen voor {{PROPERTY_ADDRESS}}',
      en: 'Request a free appraisal for {{PROPERTY_ADDRESS}}',
    },
    disclaimer: {
      __typename: 'AppBBWaardecheckText',
      nl: `Door het versturen van dit formulier geef ik ${accountName} toestemming contact met mij op te nemen per telefoon of email voor het inplannen van een waardebepaling.`,
      en: `By submitting this form I allow ${accountName} to contact me either by phone or email with the purpose of scheduling an appointment for a home appraisal.`,
    },
    primaryButton: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Verstuur je aanvraag',
      en: 'Submit request',
    },
  },
  appraisalRequestSuccess: {
    __typename: 'AppBBWaardecheckAppraisalRequestSuccess',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Bedankt voor je aanvraag!',
      en: 'Thank you!',
    },
    body: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Wij nemen zo snel mogelijk contact met je op om de gratis waardebepaling in te plannen.',
      en: 'We will contact you as soon as possible to schedule a free home valuation.',
    },
    primaryButton: {
      __typename: 'AppBBWaardecheckLink',
      text: {
        __typename: 'AppBBWaardecheckText',
        nl: 'Sluit deze pagina',
        en: 'Close this page',
      },
      href: {
        __typename: 'AppBBWaardecheckUrl',
        nl: 'https://dathuis.nl',
        en: null,
      },
    },
  },
  report: {
    __typename: 'AppBBWaardecheckReport',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'De waardecheck voor {{PROPERTY_ADDRESS}}',
      en: 'The home valuation for {{PROPERTY_ADDRESS}}',
    },
    estimationLabel: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Onze inschatting van jouw woning',
      en: 'Our estimation of your home',
    },
    estimationDisclaimer: {
      __typename: 'AppBBWaardecheckText',
      nl: 'De afgegeven waarde betreft een indicatiewaarde, berekend door brainbay. Aan deze waardecheck kunnen geen rechten worden ontleend. Neem contact op met de vastgoedprofessional om een nauwkeuriger waardering voor deze woning te ontvangen.',
      en: 'The provided valuation is an indicative value, calculated by brainbay. No rights can be derived from this home valuation report. Contact the real estate professional in order to receive a more accurate valuation for this home.',
    },
    conversionMessageHeading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Verkocht voor {{SALE_PRICE}}',
      en: 'Sold for {{SALE_PRICE}}',
    },
    conversionMessageBodyLess: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Waarom werd deze woning voor {{SALE_PRICE_DIFFERENCE}} minder verkocht dan de afgegeven woningwaarde?',
      en: 'Why was this house sold for {{SALE_PRICE_DIFFERENCE}} less than the provided home valuation?',
    },
    conversionMessageBodyMore: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Waarom werd deze woning voor {{SALE_PRICE_DIFFERENCE}} meer verkocht dan de afgegeven woningwaarde?',
      en: 'Why was this house sold for {{SALE_PRICE_DIFFERENCE}} more than the provided home valuation?',
    },
    conversionMessageFooter: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Onze vastgoedprofessionals verklaren je graag het verschil',
      en: 'Our real estate professionals will gladly explain the difference',
    },
    conversionMessagePrimaryButton: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Bel mij terug',
      en: 'Call me back',
    },
    propertyPrimaryButton: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Verklaar verschil',
      en: 'Explain difference',
    },
    propertySalePrice: {
      __typename: 'AppBBWaardecheckText',
      nl: 'Verkocht voor',
      en: 'Sold for',
    },
    higherSalePrice: {
      __typename: 'AppBBWaardecheckText',
      nl: '{{SALE_PRICE_DIFFERENCE}} meer dan de waarde van jouw woning',
      en: '{{SALE_PRICE_DIFFERENCE}} more than the estimation of your property',
    },
    lowerSalePrice: {
      __typename: 'AppBBWaardecheckText',
      nl: '{{SALE_PRICE_DIFFERENCE}} minder dan de waarde van jouw woning',
      en: '{{SALE_PRICE_DIFFERENCE}} less than the estimation of your property',
    },
  },
  about: {
    __typename: 'AppBBWaardecheckAbout',
    image: null,
    heading: {
      __typename: 'AppBBWaardecheckText',
      nl: 'De klant staat altijd centraal',
      en: 'The customer is always number one',
    },
    body: {
      __typename: 'AppBBWaardecheckText',
      nl: `${accountName} is een gespecialiseerd vastgoedprofessional. Dagelijks brengen wij als vastgoedprofessional, vraag- en aanbod samen in onze regio. In onze dienstverlening staat de tevredenheid van onze klanten centraal.`,
      en: `${accountName} is highly specilized real estate professional. Our professionals are a driving force in matching supply and demand in our region. Customer satisfaction is central in everything we do.`,
    },
    primaryButton: {
      __typename: 'AppBBWaardecheckLink',
      text: {
        __typename: 'AppBBWaardecheckText',
        nl: 'Bezoek onze website',
        en: 'Visit our homepage',
      },
      href: {
        __typename: 'AppBBWaardecheckUrl',
        nl: 'https://dathuis.nl',
        en: null,
      },
    },
  },
  widget: {
    __typename: 'AppBBWaardecheckWidget',
    image: {
      __typename: 'AppBBWaardecheckImage',
      url: 'https://dathuis-stash.imgix.net/dev_fallback_image.jpg?w=300&auto=format',
      s3key: '',
    },
    heading: {
      __typename: 'AppBBWaardecheckText',
      en: 'What is my home worth?',
      nl: 'Wat is mijn huis waard?',
    },
    body: {
      __typename: 'AppBBWaardecheckText',
      en: 'Easily calculate the estimated value of your house!',
      nl: 'Bereken eenvoudig de geschatte waarde van uw huis!',
    },
    button: {
      __typename: 'AppBBWaardecheckText',
      en: 'Calculate now',
      nl: 'Bereken nu',
    },
    buttonColor: {
      __typename: 'AppBBWaardecheckContainer',
      background: '#00A3C3',
      color: '#fff',
    },
    position: WidgetPosition.BottomRight,
    showImageOnMobile: true,
  },
});

export default defaultData;
