import React from 'react';
import styled from 'styled-components';
import { FormBuilder_Locale } from '~/graphql/types';
import Icon, { type IconType } from '../..';

type Props = { locale: FormBuilder_Locale };

const LocaleIcon: React.FC<Props> = ({ locale }) => {
  const name = getIconForLocale(locale);

  return <StyledIcon name={name} />;
};

const StyledIcon = styled(Icon)`
  margin-left: 3px;
  vertical-align: middle;
  margin-bottom: 2px;
`;

export const getIconForLocale = (locale: FormBuilder_Locale): IconType => {
  switch (locale) {
    case FormBuilder_Locale.En:
      return 'ENG-flag';

    case FormBuilder_Locale.Nl:
    default:
      return 'NL-flag';
  }
};

export default LocaleIcon;
