import type { ContactFiltersLeaf__Input } from '~/graphql/types';

import getSubject, { SubjectMap } from '../getSubject';
import { CommandMap } from '../getCommand';
import { TopicMap } from '../getTopic';
import { isNil } from 'ramda';
import getPathForLeaf from '../getPathForLeaf';
import getLabelForRepresentation from '../getLabelForRepresentation';
import { TypeMap } from '../getType';
import renderHandlebarsTemplate from '~/util/handlebars';
import { OptionTypeMap } from '../getOptionType';

const text = {
  brokenFilter: 'Deze filter is niet meer beschikbaar',
  invalidFilter: 'Ongeldig filter',
};
const getLabelForLeafRepresentation = (
  {
    leaf,
    leafRepresentation,
  }: {
    leaf: Partial<ContactFiltersLeaf__Input>;
    leafRepresentation: string;
  },
  {
    subjectMap,
    commandMap,
    topicMap,
    typeMap,
    optionTypeMap,
  }: {
    subjectMap: SubjectMap;
    commandMap: CommandMap;
    topicMap: TopicMap;
    typeMap: TypeMap;
    optionTypeMap: OptionTypeMap;
  },
):
  | { error?: undefined; result: { label: string; moreFilters: number | null } }
  | { error: string } => {
  if (isNil(leaf.attribute) && isNil(leaf.instanceAttribute))
    return {
      result: { label: `...`, moreFilters: null },
    };

  const path = getPathForLeaf(leaf, {
    commandMap,
    topicMap,
    subjectMap,
  });

  if (path.error != null || path.result.basePath.error != null) {
    return {
      error: text.brokenFilter,
    };
  }

  if (leaf.instanceAttribute != null) {
    const commandsForTemplate: Array<{ label: string }> = [];
    const subject = getSubject(leaf.instanceAttribute, subjectMap);

    for (const [index, command] of leaf.instanceAttribute.commands.entries()) {
      const commandRepresentation = getLabelForRepresentation(
        {
          command,
          subjectId: leaf.instanceAttribute.subjectId,
          eventId: leaf.instanceAttribute.eventId ?? undefined,
        },
        { subjectMap, commandMap, topicMap, typeMap, optionTypeMap },
      );

      if (commandRepresentation.error != null)
        return { error: text.invalidFilter };

      // Display only one command representation, rest will be shown as "X more filters"
      if (index === 0) {
        const commandLabel = commandRepresentation.result;
        commandsForTemplate.push({ label: commandLabel });
      }
    }
    try {
      const label = renderHandlebarsTemplate(leafRepresentation, {
        label: subject?.label,
        ctx: {
          negate: leaf.instanceAttribute.negate,
          connector: leaf.instanceAttribute.connector,
        },
        commands: commandsForTemplate,
      });
      return {
        result: {
          label,
          moreFilters:
            leaf.instanceAttribute.commands.length > 1
              ? leaf.instanceAttribute.commands.length - 1
              : null,
        },
      };
    } catch (err) {
      return { error: text.brokenFilter };
    }
  }
  if (leaf.attribute != null) {
    const subject = getSubject(leaf.attribute, subjectMap);
    const action = subject?.actions.find(
      act => act.key === leaf.attribute?.actionKey,
    );

    try {
      const label = renderHandlebarsTemplate(action?.representation, {
        label: subject?.label,
      });
      return { result: { label, moreFilters: null } };
    } catch (err) {
      return { error: text.brokenFilter };
    }
  }

  return { result: { label: '...', moreFilters: null } };
};

export default getLabelForLeafRepresentation;
