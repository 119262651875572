import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import icons from '~/components/atom/Icon/components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';

export type Props = {
  dataTestId?: string;
  heading: React.ReactNode;
  icon: keyof typeof icons;
  /** Shows `chevron` icon when defined */
  expanded?: boolean;
  onClick?: () => void;
};

const AsideHeader: React.FCC<Props> = ({
  dataTestId,
  heading,
  icon,
  onClick,
  expanded,
  ...rest
}) => (
  <Container
    dataTestId={dataTestId}
    align="center"
    justification="space-between"
    onClick={onClick}
    nonResponsive
    width="100%"
    {...rest}
  >
    <JustificationContainer align="center">
      <ScaledIcon name={icon} margin={[null, 's', null, null]} />
      <Heading4 margin={['xxxs', null, null, null]} variant={Variant.primary}>
        {heading}
      </Heading4>
    </JustificationContainer>
    {!isNil(expanded) && (
      <Icon name="chevron-right" clockwise={expanded ? 90 : 0} />
    )}
  </Container>
);

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    cursor: pointer;
    padding: ${theme.space('m')};
  `,
);

const ScaledIcon = styled(Icon)(
  ({ theme }) => css`
    font-size: ${theme.fs('l')};
  `,
);

export default AsideHeader;
