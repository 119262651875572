import { title as hypotheekColorSettingsTitle } from './HypotheekColorSettings';
import { WizardFlow } from '~/graphql/types.client';

const typename = 'AppStatus_Hypotheekbond';
const hypotheekbondEnableSteps: WizardFlow = {
  id: 'enable-hypotheekbond',
  steps: [
    {
      title: hypotheekColorSettingsTitle,
      id: 'HypotheekColorSettings',
      metadata: { typename },
    },
  ],
};

export default hypotheekbondEnableSteps;
