import type { ContactHasFundaBrochureRequestEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

const asHasFundaBrochureRequestEventFilterInput = (
  filter: ContactHasFundaBrochureRequestEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => ({
  App: {
    Funda: {
      BrochureRequest: { has: asHasFilterInput(filter.has) },
    },
  },
});

export default asHasFundaBrochureRequestEventFilterInput;
