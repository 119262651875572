import React, { useState } from 'react';
import { FORM_ERROR } from 'final-form';
import { Link, RouteComponentProps } from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import { navigate } from '@gatsbyjs/reach-router';
import Amplify from '~/amplify';

import ErrorTypes from '~/ErrorTypes';
import TEST_ID from './index.testid';
import Catalog from '~/Catalog';
import { reporter } from '~/hooks/useErrorReporter';
import validate from './utils/validate';
import Button from '~/components/atom/Button';
import { Body, Heading1 } from '~/components/atom/Typography';
import FormUtils from '~/components/organism/FormUtils';
import { EmailField, Form } from '~/components/organism/Forms';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AuthFormWrapper from '../components/AuthFormWrapper';
import triggerResetTempPassword from '../utils/triggerResetTempPassword';
import Validation from '~/util/Validation';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '~/util/localStorageKeys';
import UnauthNav from '~/components/organism/UnauthNav';
import createPageTitle from '~/util/createPageTitle';

export type FormData = {
  email: string | null;
};

type Props = RouteComponentProps;
const text = {
  emailLabel: Catalog.emailLabel,

  userNotFound: 'Er is nog geen account met dit e-mailadres aangemaakt.',
  explanation:
    'Om je wachtwoord te resetten, hebben we je e-mailadres nodig. Je ontvangt een code waarmee je je wachtwoord kan resetten.',
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
  pageTitle: 'Reset wachtwoord',
  sendEmailButton: 'Verstuur reset e-mail',
  noAccount: 'Nog geen account?',
  registerHere: 'Registreer je hier',
  incompleteUser: 'Dit account is nog niet geverifieerd.',
  inactiveUser:
    'Een reset van uw wachtwoord is niet mogelijk. Controleer of u een uitnodiging hebt ontvangen om uw account te activeren. Klik anders op de onderstaande knop om een ​​ander tijdelijk wachtwoord te ontvangen.',
  inactiveUserPasswordSent:
    'Een reset van uw wachtwoord is niet mogelijk. We hebben u de afgelopen 24 uur al een tijdelijk wachtwoord gestuurd. Als u geen e-mail hebt ontvangen, neem dan contact met ons op via contact@dathuis.nl of via 020-222 35 71',
  sendTempPasswordInfo:
    'De e-mail met het tijdelijke wachtwoord wordt verzonden als de laatste reset-e-mail meer dan 24 uur geleden is verzonden en je e-mailadres in ons systeem staat. Controleer je mailbox. Als je geen e-mail ontvangt, neem dan contact met ons op via contact@dathuis.nl of via 020-222 35 71',
  sendTempPasswordLabel: 'Stuur tijdelijk wachtwoord',
};

const ForgotPassword: React.FCC<Props> = () => {
  const [showSendTempPassword, setShowSendTempPassword] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [infoMsg, setInfoMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const tempPasswordHasBeenSent = () => {
    const lastResetTimestamp = getLocalStorageItem('lastResetTimestamp');

    if (lastResetTimestamp) {
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - parseInt(lastResetTimestamp, 10);
      const _24h = 24 * 60 * 60 * 1000;
      if (timeDifference < _24h) {
        setShowSendTempPassword(false);
        return true;
      }
    }
    return false;
  };

  const onFormSubmit = ({ email }: { email: string }) => {
    const loweredEmail = email.toLowerCase();
    tempPasswordHasBeenSent();

    return Amplify.Auth.forgotPassword(loweredEmail)
      .then(() => {
        void navigate('/setup-newpassword', {
          state: { email: loweredEmail },
        });
      })
      .catch(err => {
        if (
          err.__type === ErrorTypes.notAuthorized ||
          err.code === ErrorTypes.notAuthorized
        ) {
          const validEmail = Validation.Email.isValid(email);
          const hasBeenSent = tempPasswordHasBeenSent();
          if (!hasBeenSent) {
            if (validEmail) {
              setEmail(loweredEmail);
              setShowSendTempPassword(true);
            }
            return { [FORM_ERROR]: text.inactiveUser };
          }

          return { [FORM_ERROR]: text.inactiveUserPasswordSent };
        }

        if (
          err.__type === 'UserNotFoundException' ||
          err.code === 'UserNotFoundException'
        ) {
          return { [FORM_ERROR]: text.userNotFound };
        }

        if (
          err.__type === 'InvalidParameterException' ||
          err.code === 'InvalidParameterException'
        ) {
          return { [FORM_ERROR]: text.incompleteUser };
        }

        reporter.captureException(err.message);
        return {
          [FORM_ERROR]: text.genericErrorMessage,
        };
      });
  };

  const handleInputChange = (inputEmail: string) => {
    setEmail(inputEmail);
    setShowSendTempPassword(false);
    setInfoMsg(null);
  };

  const handleResendClick = async form => {
    if (email) {
      setLoading(true);
      setInfoMsg(null);
      form.restart();
      await triggerResetTempPassword(email);
      setInfoMsg(text.sendTempPasswordInfo);
      setLocalStorageItem(
        'lastResetTimestamp',
        new Date().getTime().toString(),
      );
      setLoading(false);
      setShowSendTempPassword(false);
    }
  };

  const title = text.pageTitle;

  return (
    <>
      <MetaTags>
        <title>{createPageTitle(title)}</title>
        <meta name="description" content="Vraag een nieuw wachtwoord aan" />
      </MetaTags>

      <UnauthNav
        buttons={[
          {
            label: 'Inloggen',
            onClick: () => navigate('/login'),
            appearance: 'primary',
            size: 'large',
          },
        ]}
      />
      <AuthFormWrapper>
        <Heading1
          lineHeight="base"
          margin={['l', null]}
          color={{ group: 'accent' }}
          size="xxxxl"
          skewed
        >
          {title}
        </Heading1>

        <p>{text.explanation}</p>
        <Form
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          validate={validate}
        >
          {({ handleSubmit, submitError, submitting, pristine, form }) => (
            <form
              id="forgotPassword"
              onSubmit={handleSubmit}
              data-testid={TEST_ID.FORM}
            >
              {submitError ? (
                <Body
                  size="base"
                  margin={[null]}
                  color={{ group: 'danger', variant: 'light' }}
                  data-testid={TEST_ID.ERROR_MESSAGE}
                >
                  {submitError}
                </Body>
              ) : null}
              {infoMsg && (
                <Body size="base" margin={[null]} color={{ group: 'info' }}>
                  {infoMsg}
                </Body>
              )}
              <JustificationContainer margin={['m', null]}>
                <EmailField name="email">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      size="large"
                      label={{
                        text: text.emailLabel,
                        color: { group: 'white' },
                      }}
                      error={
                        error && touched
                          ? [FormUtils.showError(error, touched)]
                          : undefined
                      }
                      disabled={submitting}
                      {...input}
                      onChange={e => {
                        input.onChange(e);
                        handleInputChange(e.target.value);
                      }}
                    />
                  )}
                </EmailField>
              </JustificationContainer>
              <JustificationContainer
                align="center"
                justification="space-between"
              >
                <Body align="center" size="base" margin={[null]}>
                  {`${text.noAccount} `}
                  <Link to={`/register/${location.search}`}>
                    {text.registerHere}
                  </Link>
                </Body>

                {showSendTempPassword ? (
                  <JustificationContainer margin={['m', null]}>
                    <Button
                      label={text.sendTempPasswordLabel}
                      onClick={() => handleResendClick(form)}
                      size="large"
                      loading={loading}
                    />
                  </JustificationContainer>
                ) : (
                  <Button
                    size="large"
                    appearance="primary"
                    type="submit"
                    disabled={submitting || pristine}
                    label={text.sendEmailButton}
                    dataTestId={TEST_ID.SUBMIT_BUTTON}
                  />
                )}
              </JustificationContainer>
            </form>
          )}
        </Form>
      </AuthFormWrapper>
    </>
  );
};

const initialValues: FormData = {
  email: null,
};

export default ForgotPassword;
