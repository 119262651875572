import { GetPlansQuery, NotificationLevel } from '~/graphql/types';

import { convertServerDateStringToDate, safeFormattedDate } from '~/util/date';
import TEST_ID from './index.testid';
import { Warning } from '~/hooks/useEntityWarnings';
import { generateHash } from '~/util/generateHash';
import { isNil } from 'ramda';

const errorMessagesForSubscription = (
  getPlans: GetPlansQuery['getPlans'],
): Array<Warning> => {
  if (getPlans == null) return [];

  const result: Array<Warning> = [];

  if (
    !isNil(getPlans.next) &&
    !isNil(getPlans.next.changeDate) &&
    isNil(getPlans.next.plan)
  ) {
    const converted = convertServerDateStringToDate(getPlans.next.changeDate);

    if (converted) result.push(willBeCancelledMessage(converted));
  }

  return result;
};

const willBeCancelledMessage = (onDate: Date): Warning => {
  const error = `Het abonnement van dit account zal op ${safeFormattedDate(
    onDate,
  )} aflopen. <a
    href="/-/settings/subscription/"
    data-testid="${
      TEST_ID.WILL_BE_CANCELLED_LINK
    }"> Klik hier </a> om je abonnement te heractiveren.`;

  return {
    item: {
      __typename: 'NotificationListItem',
      level: NotificationLevel.Info,
      body: {
        __typename: 'NotificationMessage',
        htmlBody: error,
      },
    },
    hash: generateHash(error),
  };
};

export default errorMessagesForSubscription;
