import React from 'react';
import DHRouter from '~/components/atom/DHRouter';

import { WithNavigationFrame as NotFound } from '~/components/page/404';

import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/components/page/ErrorScreen';
import Catalog from '~/Catalog';
import AccountsList from './AccountsList';
import AccountPage from './AccountDetails';

type Props = RouteComponentProps;
const Accounts: React.FCC<Props> = withErrorBoundary<Props>(
  () => (
    <DHRouter>
      <AccountsList path={'/*'} />
      <AccountPage path="/:accountId" />
      <NotFound default />
    </DHRouter>
  ),
  <ErrorScreen explanation={Catalog.genericUnknownErrorMessage} />,
);

export default Accounts;
