import type { FlowData___SubjectFragment } from '~/graphql/types';

export type Flow___SubjectIdentifier = { type: string; typeId?: string | null };

export type SubjectMap = {
  [type in string]: {
    [typeId in string]: FlowData___SubjectFragment;
  };
};

/**
 * Generate the subject map
 */
export const generateSubjectMap = (
  subjects: Array<FlowData___SubjectFragment>,
): SubjectMap =>
  subjects.reduce((prev, curr) => {
    if (prev[curr.type] == null) prev[curr.type] = {};

    prev[curr.type][curr.typeId ?? ''] = curr;

    return prev;
  }, {} as SubjectMap);

/**
 * Get a subject, if it exists
 */
export const getSubject = (
  { type, typeId }: Flow___SubjectIdentifier,
  subjectMap: SubjectMap,
): FlowData___SubjectFragment | undefined => subjectMap[type]?.[typeId ?? ''];

/**
 * Check if two subjects are considered equal
 */
export const isEqualSubject = (
  idA: Flow___SubjectIdentifier,
  idB: Flow___SubjectIdentifier,
): boolean => idA.type === idB.type && idA.typeId == idB.typeId;

export default getSubject;
