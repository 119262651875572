import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const Box: React.FCC<Props> = ({
  color = 'currentColor',
  secondaryColor,
  style,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width={style?.width ?? '100%'}
      height={style?.height ?? '100%'}
      viewBox="0 0 76 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.5 47.9997V23.9997C63.4989 22.9475 63.2212 21.9141 62.6946 21.0032C62.1681 20.0923 61.4112 19.3358 60.5 18.8097L39.5 6.80971C38.5879 6.2831 37.5532 6.00586 36.5 6.00586C35.4468 6.00586 34.4121 6.2831 33.5 6.80971L12.5 18.8097C11.5888 19.3358 10.8319 20.0923 10.3054 21.0032C9.77883 21.9141 9.50108 22.9475 9.5 23.9997V47.9997C9.50108 49.0519 9.77883 50.0853 10.3054 50.9962C10.8319 51.9072 11.5888 52.6636 12.5 53.1897L33.5 65.1897C34.4121 65.7163 35.4468 65.9936 36.5 65.9936C37.5532 65.9936 38.5879 65.7163 39.5 65.1897L60.5 53.1897C61.4112 52.6636 62.1681 51.9072 62.6946 50.9962C63.2212 50.0853 63.4989 49.0519 63.5 47.9997Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3096 20.8799L36.4996 36.0299L62.6896 20.8799"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5 66.24V36"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {secondaryColor && (
        <>
          <circle cx="63.5" cy="18" r="12" fill={secondaryColor} />
          <path
            d="M63.5058 23.168C65.9698 23.168 67.7058 21.11 67.7058 18.184C67.7058 15.272 65.9698 13.214 63.5058 13.214C61.0278 13.214 59.2778 15.272 59.2778 18.184C59.2778 21.11 61.0278 23.168 63.5058 23.168ZM63.5058 21.572C62.0778 21.572 61.0698 20.172 61.0698 18.184C61.0698 16.196 62.0778 14.81 63.5058 14.81C64.9198 14.81 65.9278 16.196 65.9278 18.184C65.9278 20.172 64.9198 21.572 63.5058 21.572Z"
            fill="white"
          />
        </>
      )}
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default Box;
