import { AppType } from '~/graphql/types';
import { AppConfig } from '../types';

const description = `
## Omschrijving
Met de Funda Bezichtigingen app kan je verzoeken van Funda automatisch verwerken. Voor inkomende verzoeken worden automatisch nieuwe contacten aangemaakt. In de tijdlijn van het contact wordt het verzoek getoond.

In de flow builder kan je gebruik maken van triggers om op basis van het type verzoek een flow te starten. Hierbij kan je onderscheid maken tussen drie verschillende verzoeken:

1. Een contactverzoek
2. Een bezichtigingsverzoek
3. Een brochureverzoek

Gebruik een flow om bijvoorbeeld automatisch een bezichtigingsverzoek te bevestigen, een brochure terug te sturen, of om de koper door te sturen naar je eigen website waar de bezichtiging kan worden ingepland.


## Installatie
Om deze app te gebruiken dien je het e-mailadres waar bezichtingsverzoeken op worden ontvangen te koppelen aan je account of vestiging.`;

export const funda: AppConfig = {
  __typename: 'AppStatus_Funda',
  appType: AppType.Funda,
  type: 'activate_only',
  slug: 'funda',
  name: 'Funda Bezichtigingen',
  tags: ['Leadgeneratie', 'Koppeling'],
  provider: 'DatHuis B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/funda.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/funda.svg',
  },
  price: null,
  description,
  termsOfUse: '',
  compatibility: 'Beschikbaar voor alle gebruikers van Funda',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/4099884-over-de-funda-app',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'default',
};
