import React from 'react';
import styled from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Markdown from '~/components/molecule/Markdown';
import getMediaURLPrefix from '../../utils/getMediaURLPrefix';
import StrapiButtons from '../StrapiButtons';

import VideoPlayer from '../VideoPlayer';
import getMarkdownOptions from '../../utils/getMarkdownOptions';
import { ComponentUiTwoColumnVideo } from '~/components/template/ConversionPage/types';

export type Props = ComponentUiTwoColumnVideo & {};

const TwoColumnVideo: React.FCC<Props> = ({ dataTestId, ...props }) => (
  <JustificationContainer
    gap="xxxl"
    justification="space-between"
    dataTestId={dataTestId}
  >
    {props.videoLeft === true && (
      <EvenlySpaced>
        <VideoPlayer
          autoPlay={props.Video.autoPlay}
          controls={props.Video.controls}
          loop={props.Video.loop}
          muted={props.Video.muted}
          src={`${getMediaURLPrefix()}${props.Video.video.data.attributes.url}`}
          mime={props.Video.video.data.attributes.mime}
        />
      </EvenlySpaced>
    )}
    <EvenlySpaced>
      <Markdown value={props.body} options={getMarkdownOptions()} />
      {props.buttons.data.length && <StrapiButtons buttons={props.buttons} />}
    </EvenlySpaced>
    {props.videoLeft === false && (
      <EvenlySpaced>
        <VideoPlayer
          autoPlay={props.Video.autoPlay}
          controls={props.Video.controls}
          loop={props.Video.loop}
          muted={props.Video.muted}
          src={`${getMediaURLPrefix()}${props.Video.video.data.attributes.url}`}
          mime={props.Video.video.data.attributes.mime}
        />
      </EvenlySpaced>
    )}
  </JustificationContainer>
);

const EvenlySpaced = styled.div`
  flex: 1 1 0px;
`;

export default TwoColumnVideo;
