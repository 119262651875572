import React from 'react';
import { animated, useSpring } from 'react-spring';

export type Props = {
  /**
   * Starting point of the animation
   */
  from?: 'top' | 'right' | 'bottom' | 'left';
};

/**
 * Animated container sliding in from the 'from' given.
 */
const SpringContainer: React.FCC<Props> = ({
  children,
  from = 'left',
  ...rest
}) => {
  const transformStart =
    from === 'top'
      ? 'translate3d(0,-40px,0)'
      : from === 'right'
        ? 'translate3d(40px,0,0)'
        : from === 'bottom'
          ? 'translate3d(0,40px,0)'
          : 'translate3d(-40px,0,0)';

  const animation = useSpring({
    from: { opacity: 0, transform: transformStart },
    to: { opacity: 1, transform: 'translate3d(0,0,0)' },
    config: {
      mass: 1,
      tension: 110,
      friction: 14,
    },
  });

  return (
    <animated.div style={animation} {...rest}>
      {children}
    </animated.div>
  );
};

export default SpringContainer;
