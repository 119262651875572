import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionTaskCreate } from '~/graphql/types.client';
import removeTypenames from '~/util/removeTypenames';

/**
 *
 * @param {ClientFlowActionTaskCreate} action - TaskCreate client action
 * keywords:
 */
const serializeTaskCreateAction = (
  action: ClientFlowActionTaskCreate,
): FlowV2_Action__Input => {
  const {
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    title,
    description,
    assignToContactOwner,
    user,
    assignedToOffice,
    type,
  } = action;

  return {
    Task: {
      Create: removeTypenames({
        id,
        accountId,
        flowBlueprintId,
        parentIds,
        title,
        description,
        assignToContactOwner,
        user,
        office: assignedToOffice,
        type,
      }),
    },
  };
};
export default serializeTaskCreateAction;
