import { title, id } from './AppDetails';
import { WizardFlow } from '~/graphql/types.client';

const typename = 'AppStatus_StellaxAi';
const stellaxEnablementFlow: WizardFlow = {
  id: 'enable-stellax-ai',
  outsideStepDependencies: ['OnboardingStellaxAiSelectProduct'],
  steps: [
    {
      title,
      id,
      metadata: { typename },
    },
  ],
};

export default stellaxEnablementFlow;
