import React from 'react';
import styled, { css } from 'styled-components';
import SelectBlock from '~/components/molecule/SelectBlock';
import MasonryGrid from '~/components/organism/MasonryGrid';

import eventTemplates, { EventTemplates } from './eventTemplates';
import TEST_ID from './index.testid';

export type Props = {
  selectedTemplates: EventTemplates;
  onChange: (templates: EventTemplates) => void;
};

const EventTemplatesContainer: React.FCC<Props> = ({
  dataTestId,
  onChange,
  selectedTemplates = [],
  ...rest
}) => (
  <MasonryGridContainer data-testid={dataTestId} {...rest}>
    {eventTemplates.map(template => {
      const isTriggerSelected = selectedTemplates.some(
        selected => selected.id === template.id,
      );

      return (
        <SelectBlock
          type="checkbox"
          checked={isTriggerSelected}
          data-checked={isTriggerSelected}
          checkboxTitle={template.name}
          description={template.description}
          dataTestId={TEST_ID.TEMPLATE}
          key={template.id}
          onClick={() => {
            if (isTriggerSelected) {
              onChange(
                selectedTemplates.filter(
                  selectedTrigger => selectedTrigger.id !== template.id,
                ),
              );
            } else {
              onChange([...selectedTemplates, template]);
            }
          }}
        />
      );
    })}
  </MasonryGridContainer>
);

const MasonryGridContainer = styled(MasonryGrid)<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
    margin-bottom: ${theme.space('base')};
    column-gap: ${theme.space('l')};
  `}
`;

export default EventTemplatesContainer;
