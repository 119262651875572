/**
 * A hook to handle the pagination when given next tokens for grabbing data. It will give the user:
 * [0] - currentPage -> The current page to show
 *
 * It expects:
 *  limit - the number of items on a page
 */

import { useState } from 'react';

type ReturnProps = [
  number,
  () => void,
  () => void,
  (items: Array<any> | null | undefined) => Array<any>,
  number,
];

const useInfinitePagination = (limit: number): ReturnProps => {
  const [currentPage, setCurrentPageValue] = useState(1);
  const [knownPages, setKnownPages] = useState(1);

  const setCurrentPage = (page: number) => {
    setCurrentPageValue(page);
    if (knownPages < page) {
      setKnownPages(page);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const grabItems = <T>(items: Array<T> | null | undefined): Array<T> => {
    if (items == null) return [];

    const totalLength = items.length;
    let desiredStartIndex = currentPage === 1 ? 0 : (currentPage - 1) * limit;
    let desiredEndIndex = currentPage * limit;
    const highestIndex = totalLength;

    if (desiredStartIndex > highestIndex) {
      desiredStartIndex = 0;
      desiredEndIndex = Math.min(limit, highestIndex);
    } else if (desiredEndIndex > highestIndex) {
      desiredEndIndex = highestIndex;
    }

    return items.slice(desiredStartIndex, desiredEndIndex);
  };

  return [currentPage, goToPreviousPage, goToNextPage, grabItems, knownPages];
};

export default useInfinitePagination;
