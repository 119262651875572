import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const User: React.FCC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  style,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width={style?.width ?? '100%'}
      height={style?.height ?? '100%'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.5493 53.8486V48.9486C55.5493 43.5486 49.6717 39.5629 45.687 39.9115C43.8938 40.0684 39.8094 45.4257 35.8246 45.7743C31.8399 46.1229 27.9547 41.4628 25.9623 41.6372C21.7783 42.0032 16.1 46.9 16.1 52.4V57.3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9242 12.9659C40.8056 12.5389 44.6907 16.399 44.6907 22.899C44.6907 29.399 40.8056 35.9389 35.9242 36.3659C31.0429 36.793 27.1577 30.8329 27.1577 24.4329C27.1577 18.0329 31.0429 13.393 35.9242 12.9659Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default User;
