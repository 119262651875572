import { Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

/**
 * True if the given graphql operation is a subscription, false if not
 */
const isSubscriptionOperation = ({ query }: Operation): boolean => {
  const definition = getMainDefinition(query);

  if (!('operation' in definition)) return false;

  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
};

export default isSubscriptionOperation;
