import React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { OrderedListElement } from '~/components/organism/PluginsEditor/types';
import serialize from './serialize';

export type Props = RenderElementProps & {
  element: OrderedListElement;
};

const OrderedList: React.FCC<Props> = ({
  dataTestId,
  attributes,
  element,
  children,
}) => (
  <Container data-testid={dataTestId} {...attributes} {...element.attributes}>
    {children}
  </Container>
);

const Container = styled.ol<{}>`
  margin: 0;
`;

export default {
  nodeName: 'OL',
  renderComponent: props => <OrderedList {...props} />,
  deserialize: () => ({ type: ELEMENTS.OL }),
  serialize,
};
