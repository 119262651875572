import { atom, selectorFamily } from 'recoil';
import { HandledFlowAction } from '~/graphql/types.client';

type BaseIssue = {
  level: 'error' | 'warning' | null;
  actionId: string | null;
  actionType: HandledFlowAction | null;
  message: string;
};

export type ConditionIssue = BaseIssue & {
  type: 'ConditionIssue';
  id: string;
  level: 'warning' | 'error';
  conditionSubExpressionIdx: number;
  conditionGroupIdx: number;
  conditionIdx?: number;
  argumentIdx?: number;
};

export type ValidationIssue = BaseIssue & {
  type: 'ValidationIssue';
  key: string;
  level: 'warning' | 'error';
};

export type BadVariableIssue = BaseIssue & {
  type: 'BadVariableIssue';
  level: 'error';
};

export type BrokenFileIssue = BaseIssue & {
  type: 'BrokenFileIssue';
  level: 'error';
};

export type MissingInlineImageIssue = BaseIssue & {
  type: 'MissingInlineImageIssue';
  level: 'error';
};

export type UnavailableActionIssue = BaseIssue & {
  type: 'UnavailableActionIssue';
  level: 'error';
  pathToApp: string | null;
};

export type ZapierTriggerOutOfBoundsIssue = BaseIssue & {
  type: 'ZapierTriggerOutOfBounds';
  level: 'error';
};

export type Issue =
  | ConditionIssue
  | ValidationIssue
  | UnavailableActionIssue
  | BadVariableIssue
  | BrokenFileIssue
  | MissingInlineImageIssue
  | ZapierTriggerOutOfBoundsIssue;

export type IssueMap = Record<string, Array<Issue>>;

type IssueActionMap = {
  hasIssues: boolean;
} & {
  [key: number]: {
    hasIssues: boolean;
  } & {
    [key: number]: {
      hasIssues: boolean;
    };
  } & {
    [key: number]: {
      hasIssues: boolean;
    };
  };
};

type IssueMapByActionId_Type = {
  issues: Array<Issue>;
  map: IssueActionMap;
  hasIssues: (arg: {
    conditionSubExpressionIdx: number;
    conditionGroupIdx?: number;
    conditionIdx?: number;
    argumentIdx?: number;
  }) => boolean;
};

const flowIssues = atom<IssueMap>({
  key: 'flowIssues',
  default: {},
});

export const issuesByActionId = selectorFamily<Array<Issue>, string>({
  key: 'issuesByActionId',
  get:
    (actionId: string) =>
    ({ get }) => {
      const issues = get(flowIssues);

      return issues[actionId] ?? [];
    },
});

export const issueMapByActionId = selectorFamily<
  IssueMapByActionId_Type,
  string
>({
  key: 'issueMapByActionId',
  get:
    (actionId: string) =>
    ({ get }) => {
      const issues = get(flowIssues);
      const issuesByAction = issues[actionId] ?? [];

      const issueMap = issuesByAction.reduce(
        (acc, issue) => {
          if (issue.type !== 'ConditionIssue') return acc;

          acc.hasIssues = true;
          if (!acc[issue.conditionSubExpressionIdx]) {
            acc[issue.conditionSubExpressionIdx] = {
              hasIssues: true,
            };
          }

          if (!acc[issue.conditionSubExpressionIdx][issue.conditionGroupIdx]) {
            acc[issue.conditionSubExpressionIdx][issue.conditionGroupIdx] = {
              hasIssues: true,
            };
          }

          if (
            issue.conditionIdx != null &&
            !acc[issue.conditionSubExpressionIdx][issue.conditionGroupIdx][
              issue.conditionIdx
            ]
          ) {
            acc[issue.conditionSubExpressionIdx][issue.conditionGroupIdx][
              issue.conditionIdx
            ] = {
              hasIssues: true,
            };
          }

          if (
            issue.conditionIdx != null &&
            issue.argumentIdx != null &&
            !acc[issue.conditionSubExpressionIdx][issue.conditionGroupIdx][
              issue.conditionIdx
            ][issue.argumentIdx]
          ) {
            acc[issue.conditionSubExpressionIdx][issue.conditionGroupIdx][
              issue.conditionIdx
            ][issue.argumentIdx] = {
              hasIssues: true,
            };
          }

          return acc;
        },
        { hasIssues: false } as IssueActionMap,
      );

      return {
        issues: issuesByAction,
        map: issueMap,
        hasIssues: ({
          conditionSubExpressionIdx,
          conditionGroupIdx,
          conditionIdx,
          argumentIdx,
        }) => {
          if (!issueMap.hasIssues) return false;

          let current = issueMap[conditionSubExpressionIdx];
          if (!current?.hasIssues) return false;

          if (conditionGroupIdx == null) return false;
          current = current[conditionGroupIdx];
          if (!current?.hasIssues) return false;

          if (conditionIdx == null) return false;
          current = current[conditionIdx];
          if (!current?.hasIssues) return false;

          if (argumentIdx == null) return false;
          current = current[argumentIdx];
          if (!current?.hasIssues) return false;

          return false;
        },
      };
    },
});

export default flowIssues;
