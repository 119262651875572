import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  OFFICE_FIELD: `${cleanedFilename(__filename)}-office-field`,
  USER_FIELD: `${cleanedFilename(__filename)}-user-field`,
  IMPORT_BUTTON: `${cleanedFilename(__filename)}-import-button`,
  TAGS_FOR_EXISTING_CONTACTS_CHECKBOX: `${cleanedFilename(
    __filename,
  )}-tags-for-existing-contacts-checkbox`,
};
