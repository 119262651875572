import { reporter } from '~/hooks/useErrorReporter';

/**
 * Assertion to be used for exhaustive type checking in switch case statements.
 *
 * E.g.:
 * switch(relation.__typename) {
 *   case 'AccountRelationship':
 *     // Some handling
 *     break;
 *     case 'OfficeRelationship':
 *       // Some handling
 *       break;
 *
 *   default:
 *     assertNever(relation) <- If __typename has more cases than AccountRelationship and OfficeRelationship this will give a linting error.
 * }
 */
export const assertNever = (x: never, filename: string = ''): never => {
  throw new Error(`${filename} | Unknown object ${JSON.stringify(x, null, 2)}`);
};

export const assertType = <T>(_x: T): void => {};

export const assertNeverWithoutThrowing = (
  x: never,
  filename: string = '',
): void => {
  reporter.captureException(
    new Error(`${filename} | Unknown object ${JSON.stringify(x, null, 2)}`),
  );
};
