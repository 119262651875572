import type { RefObject } from 'react';
import { useEffect, useCallback } from 'react';
import isCursorInBoundary from '~/util/isCursorInBoundary';

/**
 * Checks whether a click registered outside the element
 *
 * keywords: click,outside,element
 */
const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: (e) => any,
) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(e.target as Node)) {
        if (
          isCursorInBoundary({
            boundary: ref.current.getBoundingClientRect(),
            event: e,
          })
        )
          return;
        callback(e);
      }
    },
    [callback, ref],
  );

  useEffect(() => {
    // Passing true catches event in capture phase so that useOutsideClick works when clicking inside the modals as well.
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [handleClick]);

  return handleClick;
};

export default useOutsideClick;
