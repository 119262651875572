import { EdgeTypes } from 'reactflow';
import BaseEdge from './components/BaseEdge';
import DropEdge from './components/DropEdge';
import IfElseEdge from './components/IfElseEdge';
import PortalEdge from './components/PortalEdge';

// Any custom edge should be defined here.
const edgeTypes: EdgeTypes = {
  ifElse: IfElseEdge,
  dropEdge: DropEdge,
  portalEdge: PortalEdge,
  baseEdge: BaseEdge,
};

export default edgeTypes;
