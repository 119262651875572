import { BouncedEmail_Reasons } from '~/graphql/types';

export const CONTACT_LIST_LIMIT = 20;
export const QUERY_PARAMS_OPTIONS = {
  stripUnknownKeys: false,
  queryStringOptions: {
    skipNull: true,
    skipEmptyString: true,
  },
};

export const bouncedReasons: { [key in BouncedEmail_Reasons]: string } = {
  [BouncedEmail_Reasons.FullInbox]:
    'E-mail kan niet worden afgeleverd, daarom hebben we de ontvanger geblokkeerd.',
  [BouncedEmail_Reasons.Hard]:
    'E-mail kan niet worden afgeleverd, daarom hebben we de ontvanger geblokkeerd.',
  [BouncedEmail_Reasons.Soft]:
    'E-mail kan niet worden afgeleverd, daarom hebben we de ontvanger geblokkeerd.',
};

export const bouncedReasonsEmailEvent: {
  [key in BouncedEmail_Reasons]: string;
} = {
  [BouncedEmail_Reasons.FullInbox]: 'Deze e-mail kon niet worden afgeleverd.',
  [BouncedEmail_Reasons.Hard]: 'Deze e-mail kon niet worden afgeleverd.',
  [BouncedEmail_Reasons.Soft]: 'Deze e-mail kon niet worden afgeleverd.',
};
