import { isEmpty } from 'ramda';
import { SIGNATURE_CONTAINER_ID } from '~/components/organism/PluginsEditor/plugins/withSignature';

const getSignatureHtml = (signature: string): string =>
  `<dhsignature><div id="${SIGNATURE_CONTAINER_ID}">${
    signature ?? ''
  }</div></dhsignature>`;

type Params = {
  /** The previous email sent to be inserted into reply part */
  replyBlock?: string;

  /** Signature to add after the main part and before the previous email part */
  signature: string;
};

/**
 * Returns initial html for the Email to be sent
 *
 * keywords: emailValue, emailEditor, initialValue
 */
const getInitialEmailValue = ({ replyBlock, signature }: Params): string => {
  let initialHtml = `<div></div>${getSignatureHtml(signature)}`;

  if (replyBlock && !isEmpty(replyBlock)) {
    initialHtml += `<div></div><div></div><div>${replyBlock}</div>`;
  }

  return initialHtml;
};

export default getInitialEmailValue;
