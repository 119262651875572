import type { ContactTagFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Contact_Details__Input } from '~/graphql/types';

import asStringFilterInput from '../../Primitive/String/asStringFilterInput';
import Validation from '~/util/Validation';

const asContactTagFilterInput = (
  filter: ContactTagFilter,
): Node_NodeContainer_Leaf_Contact_Details__Input | null => {
  const { stringPrimitiveFilter } = filter;

  const tag =
    'eq' in stringPrimitiveFilter
      ? stringPrimitiveFilter.eq
      : stringPrimitiveFilter.sw;

  if (!Validation.Tag.isValid(tag)) {
    return null;
  }

  return {
    tag: asStringFilterInput(filter.stringPrimitiveFilter),
  };
};

export default asContactTagFilterInput;
