import { isEmpty } from 'ramda';
import { BlockElement } from '~/components/organism/PluginsEditor/types';
import getUpdatedBlockAttrs from '~/components/organism/PluginsEditor/utils/serialize/getUpdatedBlockAttrs';
import { convertStyleStrToObj } from '~/components/organism/PluginsEditor/utils/serialize/updateAttrsAndConvertToString';

const VISIBLE_STYLE_ATTRIBUTES = ['border', 'width', 'height', 'padding'];

/**
 * serializes the BlockElement into html element
 * @param {BlockElement} node - Block(div) element
 */
const serialize = (node: BlockElement, children: string): string => {
  const attrs = getUpdatedBlockAttrs(node);

  const passThroughStyleObj = node.attrsToPassThrough
    ? convertStyleStrToObj(node.attrsToPassThrough.style)
    : null;

  const content = children.trim();

  if (isEmpty(content)) {
    const mergedStyleObjects = {
      ...node.attributes?.style,
      ...passThroughStyleObj,
    };

    const styleKeys = Object.keys(mergedStyleObjects);
    const hasNoVisibleStyling = !styleKeys.some(item =>
      VISIBLE_STYLE_ATTRIBUTES.includes(item),
    );
    if (hasNoVisibleStyling) {
      return '<div><br/></div>';
    }

    // if no height is set add br tag because the linebreak is probably expected by the user
    if (!styleKeys.find(k => k === 'height')) {
      return `<div${attrs ? ` ${attrs}` : ''}><br/></div>`;
    }
  }

  return `<div${attrs ? ` ${attrs}` : ''}>${children}</div>`;
};

export default serialize;
