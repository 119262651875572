import type { Client_FlowV2_EmailAttachment } from '../../../types';

const getAllContentLength = (
  attachments: Array<Client_FlowV2_EmailAttachment>,
): number =>
  attachments?.reduce((acc, { file }) => {
    if (
      file &&
      file.__typename === 'Flow___Argument_File' &&
      file.value_file.contentLength
    ) {
      return acc + file.value_file.contentLength;
    }
    return acc;
  }, 0);

export default getAllContentLength;
