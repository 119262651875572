import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useUserOptions from '~/hooks/useUserOptions';
import InputGroup from '~/components/page/Apps/components/InputGroup';

import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Dropdown from '~/components/molecule/Dropdown';

export type OnChangeProps = {
  userId?: string | null;
  officeId?: string | null;
};

export type Props = {
  /** The selected userId */
  userId?: string | null;
  /** The selected officeId */
  officeId?: string | null;
  /** Fires whenever a reassign happens */
  onChange: ({ userId, officeId }: OnChangeProps) => void;
};

const AssignBlock: React.FCC<Props> = ({
  onChange,
  userId,
  officeId: passedOfficeId,
}) => {
  const officeOptions = useOfficeOptions({
    userId: userId ?? undefined,
  });

  const officeId: string | null =
    passedOfficeId ??
    officeOptions.find(option => option.payload !== null)?.payload?.id ??
    null;

  useEffect(() => {
    if (officeId !== passedOfficeId) onChange({ officeId, userId });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userOptions = useUserOptions({ officeId, withAllUsersOption: true });

  const selectedOfficeIdx = officeOptions.findIndex(
    ({ payload }) => payload?.id == officeId,
  );
  const selectedUserIdx = userOptions.findIndex(
    ({ payload }) => payload?.id == userId,
  );

  return (
    <JustificationContainer justification="space-between" align="center">
      <StyledInputGroup>
        <Dropdown
          dataTestId={TEST_ID.ASSIGNED_OFFICE_DROPDOWN}
          options={officeOptions}
          selectedOptionIdx={selectedOfficeIdx}
          onChange={({ option }) => {
            const nextOfficeId = option.payload?.id ?? null;
            onChange({ officeId: nextOfficeId, userId: null });
          }}
        />
      </StyledInputGroup>
      <StyledInputGroup>
        <Dropdown
          dataTestId={TEST_ID.ASSIGNED_USER_DROPDOWN}
          options={userOptions}
          selectedOptionIdx={selectedUserIdx}
          onChange={({ option }) => {
            const nextUserId = option.payload?.id ?? null;
            onChange({ officeId, userId: nextUserId });
          }}
        />
      </StyledInputGroup>
    </JustificationContainer>
  );
};

const StyledInputGroup = styled(InputGroup)<{}>(
  ({ theme }) => css`
    width: 100%;

    &:first-child {
      margin-right: ${theme.space('s')};

      ${theme.mq.lessThan('mobile')`
        margin-right: 0;
      `}
    }
  `,
);

export default AssignBlock;
