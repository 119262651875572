import { useCallback } from 'react';
import {
  ConnectorOperator,
  UserStatus,
  useGetContactsLazyQuery,
} from '~/graphql/types';
import asNodeInput from '~/components/organism/Filters/asNodeInput';
import type { OptionOf } from '~/components/molecule/Dropdown';
import namedEmailFormatter, {
  type NamedEmail,
} from '~/util/namedEmailFormatter';
import useUsers from '~/hooks/useUsers';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import sortByFilterLabel from '~/components/molecule/MultiSelectInput/utils/sortByFilterLabel';

export type EmailOption = OptionOf<{
  namedEmail: NamedEmail;

  /** Used to filter the option */
  filterLabel: string;

  /** Used to display the selected option */
  tagLabel: string | React.ReactNode;
}>;

const useGetEmailOptions = ({
  selectedOptions,
}: {
  /** Selected options */
  selectedOptions: Array<EmailOption>;

  /** Adds name in the label before the email */
  showName?: boolean;
}): {
  onAsyncSearch: (searchValue: string) => Promise<Array<EmailOption> | void>;
  loading: boolean;
} => {
  const { id: accountId } = useCurrentAccount();

  const [getContacts, { loading }] = useGetContactsLazyQuery();

  const allActiveUsers = useUsers({ statuses: [UserStatus.Active] });

  const getUsersList = (searchedValue: string) => {
    const userOptions: Array<EmailOption> = [];

    allActiveUsers.forEach(user => {
      const { name, email, id } = user;

      if (!email) return;

      const checkEmail = email.toLowerCase().includes(searchedValue);
      const checkName = name.toLowerCase().includes(searchedValue);

      if (checkEmail || checkName) {
        const showedLabel = `${name} (${email})`;

        userOptions.push({
          label: showedLabel,
          key: id,
          payload: {
            namedEmail: { email: email, name: name },
            filterLabel: showedLabel,
            tagLabel: showedLabel,
          },
        });
      }
    });

    return userOptions;
  };

  const onAsyncSearch = useCallback(
    async searchedValue => {
      const userOptions = getUsersList(searchedValue);

      const { data } = await getContacts({
        variables: {
          nodes: asNodeInput({
            operator: ConnectorOperator.And,
            filters: [],
          }),
          accountId,
          query: searchedValue,
          limit: 10,
        },
      });

      if (data) {
        const dataFromQuery = data.getContacts.items.map(item => {
          const namedEmail = {
            email: item.email,
            name: item.name,
          };
          const label = namedEmailFormatter(namedEmail);
          return {
            label,
            key: item.id,
            payload: {
              namedEmail,
              filterLabel: item.name,
              tagLabel: label,
            },
          };
        });

        const mergedList = [...dataFromQuery, ...userOptions].filter(item => {
          const payload = item ? item.payload : null;
          const emailItem = payload ? payload.namedEmail.email : '';
          const alreadyInList = selectedOptions.some(
            selectedItem => selectedItem.payload.namedEmail.email === emailItem,
          );

          if (!alreadyInList) {
            return true;
          }

          return false;
        });

        return sortByFilterLabel(mergedList) as Array<EmailOption>;
      }

      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, selectedOptions],
  );

  return { onAsyncSearch, loading };
};

export default useGetEmailOptions;
