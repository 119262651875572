import { css, type RuleSet } from 'styled-components';

/**
 * Calculate the component's height given the font-size, padding, border
 * Assumes padding top and bottom are the same.
 * Assumes border width for top and bottom are the same.
 *
 * @param {string} paddingY - Padding top or bottom of the component
 * @param {string} fontSize - Font size set for the component
 * @param {string} borderWidth - Border width for the component
 * keywords: maxHeight, calculateComponentHeight
 */
const getMaxHeight = ({
  paddingY,
  fontSize,
  borderWidth,
}): RuleSet<object> => css`
  /** Padding top + padding bottom + font size + border top + border bottom */
  max-height: calc(
    calc(${paddingY} * 2) + ${fontSize} + calc(${borderWidth} * 2)
  );
`;

export default getMaxHeight;
