import React from 'react';
import styled, { css } from 'styled-components';
import { memoize } from 'lodash';

import type { TableHeaderDefinitionCellType } from '~/components/bad/DataTables/types.flow';
import { SortFieldAdvanced } from '~/graphql/types';
import TEST_ID from './contactListColumns.testid';
import TableHeaderCell from '~/components/bad/DataTables/util/TableHeaderCell';
import { formattedDateWithMonth, isToday } from '~/util/date';
import TagColumnCell from '../components/TagColumnCell';
import cleanedFilename from '~/util/cleanedFilename';
import LeadScoreDisplayer from '~/components/molecule/LeadScoreDisplayer';
import { SortToggleFunction } from '../components/ContactList';
import CellWithTooltip from '~/components/bad/DataTables/components/CellWithTooltip';

const text = {
  bouncedEmailMessage: 'E-mail kan niet worden afgeleverd',
};

const contactListColumns = (
  sortToggle: SortToggleFunction,
  // We need to add this parameter to be able to use memoize
  _key: string,
): Array<TableHeaderDefinitionCellType<any>> => [
  TableHeaderCell.checkbox({
    dataTestId: TEST_ID.CHECKBOX,
  }),
  TableHeaderCell.custom({
    label: 'Naam',
    dataProperty: 'name',
    isResizable: false,
    width: 110,
    dataTestId: TEST_ID.NAME,
    renderFunction: value => {
      if (!value.emailStatus) return <>{value.name}</>;

      const message = (() => {
        if (value.emailStatus.status === 'Bounced')
          return text.bouncedEmailMessage;
        return '';
      })();

      return (
        <CellWithTooltip
          label={value.name}
          tooltip={{ message }}
          icon={{ name: 'alert-octagon', color: 'danger' }}
        />
      );
    },
  }),
  TableHeaderCell.string({
    label: 'Meest recente activiteit',
    dataProperty: 'leadActivity',
    isResizable: false,
    width: 170,
    dataTestId: TEST_ID.LEAD_ACTIVITY,
  }),
  TableHeaderCell.date({
    label: 'Datum activiteit',
    dataProperty: 'lastLeadActivity',
    sorting: {
      onSort: sortDirection =>
        sortToggle(getSortFieldName('lastLeadActivity'), sortDirection),
    },
    renderFunction: value => (
      <CreatedDateLabel isToday={isToday(value)}>
        {formattedDateWithMonth(value, {
          showTodayAsLabel: true,
          showYesterdayAsLabel: true,
        })}
      </CreatedDateLabel>
    ),
    isResizable: false,
    width: 100,
    dataTestId: TEST_ID.LAST_LEAD_ACTIVITY,
  }),
  TableHeaderCell.string({
    label: 'Adres',
    dataProperty: 'address',
    isResizable: false,
    width: 165,
    dataTestId: TEST_ID.ADDRESS,
  }),
  TableHeaderCell.custom({
    label: 'Tags',
    dataProperty: 'tags',
    width: 104,
    isResizable: false,
    renderFunction: (value: Array<string>, { cell }) => (
      <TagColumnCell width={cell.column.width || 0} tagList={value} />
    ),
    dataTestId: TEST_ID.TAGS,
  }),
  TableHeaderCell.number({
    label: 'Score',
    dataProperty: 'leadScore',
    width: 47,
    isResizable: false,
    sorting: {
      onSort: sortDirection =>
        sortToggle(getSortFieldName('leadScore'), sortDirection),
    },
    dataTestId: TEST_ID.LEAD_SCORE,
    renderFunction: value => (
      <StyledLeadScoreDisplater leadScore={value ? value : 0} />
    ),
  }),
  TableHeaderCell.date({
    label: 'Aangemaakt',
    dataProperty: 'createdDate',
    renderFunction: value => (
      <CreatedDateLabel isToday={isToday(value)}>
        {formattedDateWithMonth(value, {
          showTodayAsLabel: true,
          showYesterdayAsLabel: true,
        })}
      </CreatedDateLabel>
    ),
    sorting: {
      onSort: sortDirection =>
        sortToggle(getSortFieldName('createdDate'), sortDirection),
    },
    isResizable: false,
    width: 85,
    dataTestId: TEST_ID.CREATED,
  }),
  TableHeaderCell.action({
    dataTestId: TEST_ID.ACTION,
  }),
];

type CreatedDateLabelProps = {
  isToday: boolean;
};
const CreatedDateLabel = styled.span<CreatedDateLabelProps>(
  ({ theme, isToday }) => {
    if (isToday) {
      return css`
        color: ${theme.color('success')};
      `;
    }
    return null;
  },
);

export const getSortFieldName = (accessor: string): SortFieldAdvanced => {
  switch (accessor) {
    case 'createdDate':
      return SortFieldAdvanced.Created;
    case 'leadScore':
      return SortFieldAdvanced.LeadScore;
    case 'lastLeadActivity':
      return SortFieldAdvanced.LastLeadActivity;
    default:
      throw Error(
        `${cleanedFilename(
          __filename,
        )} >> getSortFieldName | Should not occur | Can not request sortFieldName for accessor ${accessor}`,
      );
  }
};

const memoizedContactListColumns = memoize(
  contactListColumns,
  (_func, key) => key,
);

const StyledLeadScoreDisplater = styled(LeadScoreDisplayer)(
  ({ theme }) => css`
    padding-left: ${theme.space('xxs')};
  `,
);

export default memoizedContactListColumns;
