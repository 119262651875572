import React from 'react';
import { EdgeProps, getBezierPath } from 'reactflow';
import { ClientFlowAction } from '~/graphql/types.client';
import AnimatedPath from '~/components/page/Automation/v2/components/Builder/components/edgeTypes/components/AnimatedPath';

export type EdgeData = {
  action: ClientFlowAction;
  parentNode: ClientFlowAction;
  id: string;
  parentId: string;
};

export type Props = EdgeProps<EdgeData> & {};

const BaseEdge: React.FCC<Props> = React.memo(
  ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
  }) => {
    const [edgePath] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    return (
      <AnimatedPath
        id={id}
        style={{
          ...style,
          strokeWidth: 1.5,
          pointerEvents: 'none',
          transition: 'opacity 0.5s ease-out',
          transitionDelay: '2s',
        }}
        className="react-flow__edge-path"
        d={`${edgePath}`}
        markerEnd={markerEnd}
      />
    );
  },
);

export default BaseEdge;
