import DesignSystem from './System';
import colorPalette, { SystemColorPalette } from './System/tokens/colorPalette';

import {
  zIndex,
  border,
  fontWeight,
  lineHeight,
  breakpoints,
  sizes,
  grid,
  boxShadow,
  gradient,
  type SystemBreakpointMap,
  type SystemZIndexMap,
  type SystemSizeMap,
  type SystemBorder,
  type SystemLineHeightMap,
  type SystemFontWeightMap,
  type SystemGridMap,
  type SystemBoxShadowMap,
  type SystemGradientMap,
  type SystemLeadScoreMap,
  leadScore,
} from './System/tokens';

export type {
  SizeMap,
  SystemSize,
  SystemSizeMap,
  SystemColorPalette,
  SystemFontWeight,
} from './System/tokens';

export type SystemType = {
  baseFontSize: string;
  fontFamily: { sans: string; monospace: string };
  fontWeight: SystemFontWeightMap;
  sizes: SystemSizeMap;
  lineHeight: SystemLineHeightMap;
};

export type SystemTokens = {
  type: SystemType;
  breakpoints: SystemBreakpointMap;
  colors: {
    colorPalette: SystemColorPalette;
    chart: Array<string>;
    portals: Array<string>;
    gradient: SystemGradientMap;
    leadScore: SystemLeadScoreMap;
  };
  zIndex: SystemZIndexMap;
  spacing: { scale: SystemSizeMap };
  border: SystemBorder;
  grid: SystemGridMap;
  boxShadow: SystemBoxShadowMap;
};

const tokens: SystemTokens = {
  type: {
    baseFontSize: '14px',
    sizes,
    fontFamily: {
      sans: '"Wotfard","Helvetica Neue","Helvetica","Arial",sans-serif',
      monospace: '"Fira Code", monospace',
    },
    fontWeight,
    lineHeight,
  },
  colors: {
    colorPalette,
    chart: [
      '#1E2647',
      '#3763D4',
      '#B6D3FF',
      '#EAEFF5',
      '#FFD6A8',
      '#FFB464',
      '#FF6900',
      '#A2040E',
      '#642901',
    ],
    portals: ['#3763D4', '#FF6900', '#B6D3FF', '#FFB464', '#642901'],
    gradient,
    leadScore,
  },
  spacing: {
    scale: sizes,
  },
  border,
  zIndex,
  breakpoints,
  grid,
  boxShadow,
};

const theme = new DesignSystem(tokens);
export type Theme = typeof theme;
export type WithTheme = { theme: Theme };

export default theme;
