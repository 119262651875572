import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading5 } from '~/components/atom/Typography';

type Props = { numberOfActions: number; label: string };

const StatisticsRow: React.FC<Props> = ({ numberOfActions, label }) => (
  <JustificationContainer align="center" gap="xxs">
    <StatisticsNumber
      align="center"
      justification="center"
      padding={['xxxs', 'xxs']}
    >
      <NumberOfActions>{numberOfActions}</NumberOfActions>
    </StatisticsNumber>
    <Heading margin={[null]} fontWeight="regular">
      {label}
    </Heading>
  </JustificationContainer>
);

const Heading = styled(Heading5)(
  ({ theme }) => css`
    margin-top: ${theme.space('xxxs')};
  `,
);

const NumberOfActions = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.space('xxxs')};
  `,
);

const StatisticsNumber = styled(JustificationContainer)(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('success', 'light')};
    color: ${theme.color('white')};
  `,
);

export default StatisticsRow;
