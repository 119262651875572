/**
 * Replace p elements with div elements in the html
 *
 * @param {string} value - Html string
 * keywords: ptag, quilltoslate
 */

const replaceTags = (html: string) => {
  // Create a temporary element to parse the HTML content
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Find all <p> tags inside the temporary element
  const pTags = tempElement.querySelectorAll('p');

  // Replace each <p> tag with a <div> tag
  pTags.forEach(pTag => {
    const divTag = document.createElement('div');
    divTag.textContent = pTag.textContent;

    // Copy over all attributes from the <p> tag to the <div> tag
    for (let i = 0; i < pTag.attributes.length; i++) {
      const attribute = pTag.attributes[i];
      divTag.setAttribute(attribute.name, attribute.value);
    }

    if (pTag.parentNode) pTag.parentNode.replaceChild(divTag, pTag);
  });

  // Return the modified HTML content
  return tempElement.innerHTML;
};

export default replaceTags;
