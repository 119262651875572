import type {
  FlowData___PrimitiveInputFragment,
  FlowData___PrimitiveListInputFragment,
} from '~/graphql/types';

export type PrimitiveInputMap = {
  [type in string]: PrimitiveInput | undefined;
};

export type PrimitiveInput =
  | FlowData___PrimitiveInputFragment
  | FlowData___PrimitiveListInputFragment;

/**
 * Generate the primitive input map
 */
export const generatePrimitiveInputMap = (
  inputs: Array<
    FlowData___PrimitiveInputFragment | FlowData___PrimitiveListInputFragment
  >,
): PrimitiveInputMap => {
  const primitiveInput = inputs.reduce<PrimitiveInputMap>((prev, input) => {
    prev[input.type] = input;
    return prev;
  }, {});

  return primitiveInput;
};

const getPrimitiveInput = (
  type: string,
  primitiveInputMap: PrimitiveInputMap,
): PrimitiveInput | undefined => primitiveInputMap[type];

export default getPrimitiveInput;
