import {
  FormBuilder_PrimitiveType,
  type FormBuilder_Event_Field,
  FormBuilder_Event_MetaField,
  type FormBuilder_ScreenNode_Block,
} from '~/graphql/types';
import { reporter } from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';

/**
 *
 * @param {FormBuilder_ScreenNode_Block} block - Generate fields for auto generated event field
 */
const getFields = (
  block: FormBuilder_ScreenNode_Block,
): {
  type: FormBuilder_Event_Field['type'];
  metaField?: FormBuilder_Event_MetaField | null;
} | null => {
  switch (block.__typename) {
    case 'FormData_Input_Text':
    case 'FormData_Input_Multiline':
      return {
        type: {
          __typename: 'FormBuilder_Event_Field_Type_Primitive',
          type: FormBuilder_PrimitiveType.String,
        },
        metaField: null,
      };

    case 'FormData_Input_Integer':
      return {
        type: {
          __typename: 'FormBuilder_Event_Field_Type_Primitive',
          type: FormBuilder_PrimitiveType.Number,
        },
        metaField: null,
      };

    case 'FormData_Input_Email':
      return {
        type: {
          __typename: 'FormBuilder_Event_Field_Type_Primitive',
          type: FormBuilder_PrimitiveType.Email,
        },
        metaField: null,
      };

    case 'FormData_Input_Contact_Email':
      return {
        metaField: FormBuilder_Event_MetaField.ContactEmail,
        type: {
          __typename: 'FormBuilder_Event_Field_Type_Primitive',
          type: FormBuilder_PrimitiveType.Email,
        },
      };
    case 'FormData_Input_Contact_Name':
      return {
        metaField: FormBuilder_Event_MetaField.ContactName,
        type: {
          __typename: 'FormBuilder_Event_Field_Type_Primitive',
          type: FormBuilder_PrimitiveType.String,
        },
      };
    case 'FormData_Input_Contact_Phone':
      return {
        metaField: FormBuilder_Event_MetaField.ContactPhone,
        type: {
          __typename: 'FormBuilder_Event_Field_Type_Primitive',
          type: FormBuilder_PrimitiveType.String,
        },
      };

    case 'FormData_Select_Dropdown':
    case 'FormData_Select_Radio':
    case 'FormData_Select_MultiButton':
      return {
        type: {
          __typename: 'FormBuilder_Event_Field_Type_Option',
          id: block.optionListId,
        },
        metaField: null,
      };

    default:
      reporter.captureMessage(
        `${cleanedFilename(__filename)}>> Block is not input or select ${block.__typename}`,
        'error',
      );
      return null;
  }
};

export default getFields;
