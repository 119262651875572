import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  ADD_ATTACHMENT_BUTTON: `${cleanedFilename(__filename)}-add-attachment-button`,
  FILE_ATTACHMENTS_CONTAINER: `${cleanedFilename(
    __filename,
  )}-file-attachments-container`,
  INSERTED_FILE_ATTACHMENT: `${cleanedFilename(
    __filename,
  )}-inserted-file-attachment`,
  INSERTED_POINTER_ATTACHMENT: `${cleanedFilename(
    __filename,
  )}-inserted-pointer-attachment`,
  DELETE_POINTER_ATTACHMENT_BUTTON: `${cleanedFilename(
    __filename,
  )}-delete-pointer-attachment-button`,
};
