import React from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { AppValuationReportComparablePropertiesPage_Update } from '~/graphql/types';
import TextAreaWithLabel from '../../components/TextAreaWithLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  title: 'Swipen',
  mainText: 'Koptekst',
  subText: 'Subtekst',
};

export type Props = RouteComponentProps & {
  dataTestId?: string;
  loading: boolean;
  updated?: AppValuationReportComparablePropertiesPage_Update | null;
  onUpdate: (value: AppValuationReportComparablePropertiesPage_Update) => void;
};

const Swiping: React.FCC<Props> = ({ updated, loading, onUpdate }) => {
  if (!updated) return null;

  const { mainHeader, subHeader } = updated;

  return (
    <AppDetailsContainer
      header={text.title}
      icon="grid"
      loading={loading}
      dataTestId="comparableObjects-page"
    >
      <JustificationContainer direction="column" gap="base">
        <TextAreaWithLabel
          value={mainHeader?.text}
          label={text.mainText}
          onUpdate={value => onUpdate({ mainHeader: { text: value } })}
          dataTestId="text-edit-mainHeader-text"
        />
        <TextAreaWithLabel
          value={subHeader?.text}
          label={text.subText}
          onUpdate={value => onUpdate({ subHeader: { text: value } })}
          dataTestId="text-edit-subHeader-text"
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default Swiping;
