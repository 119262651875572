import React, { useState, useEffect } from 'react';

import {
  LockContactSearchResultsByFilterV2MutationVariables,
  DoContactAction__Input,
  useDoContactActionMutation,
  useLockContactSearchResultsByFilterV2Mutation,
  LockedContactSearchResultsKey,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import LockedContactsMessage from '../LockedContactsMessage';
import { actionsCopy, type ContactAction } from '../../constants';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import Overlay from '~/components/organism/ModalsV2/Overlay';

const text = {
  confirm: 'Bevestigen',
};

export type Props = {
  /** Variables to pass into LockContactSearchResultsByFilterMutationV2 */
  lockContactsVariables: LockContactSearchResultsByFilterV2MutationVariables;

  /** The amount when you first select the contacts */
  selectedContactAmount: number;

  /** Action input to pass into 'action' field of doContactAction mutation */
  actionInput: DoContactAction__Input;

  /** If the submit button in the modal is disabled */
  disabled?: boolean;

  /** Callback to call when doContactAction mutation is completed */
  onComplete: () => void;

  /** Callback to close the modal */
  onCancel: () => void;

  /** Takes lockContactSearchResultsByFilterV2 loading state as prop, this can be used to disable the components in children  */
  children?: ((loading?: boolean) => React.ReactNode) | React.ReactNode;

  actionType: ContactAction;
};

const ConfirmActionModal: React.FCC<Props> = ({
  lockContactsVariables,
  onComplete,
  onCancel,
  disabled = false,
  children,
  actionInput,
  selectedContactAmount,
  actionType,
}) => {
  const addToast = useAddToast();
  const account = useCurrentAccount();

  const [doContactAction, { loading: doContactActionLoading }] =
    useDoContactActionMutation();

  const [lockContactSearchResultsByFilterV2, { loading: lockLoading }] =
    useLockContactSearchResultsByFilterV2Mutation();

  const [lockedContactSearchResultsKey, setLockedContactSearchResultsKey] =
    useState<LockedContactSearchResultsKey | null>(null);

  useEffect(() => {
    void lockContacts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lockContacts = async () => {
    const { data, errors } = await lockContactSearchResultsByFilterV2({
      variables: lockContactsVariables,
    });

    if (errors && errors.length > 0) {
      addToast([
        formatToastMessage(
          actionsCopy[actionType].lockContactsErrorMsg,
          'danger',
        ),
      ]);
      return;
    }

    if (data) {
      const { key, total } = data.lockContactSearchResultsByFilterV2;

      setLockedContactSearchResultsKey({
        __typename: 'LockedContactSearchResultsKey',
        key,
        total,
      });
    }
  };

  const loading = lockedContactSearchResultsKey == null || lockLoading;

  const confirmDisabled = disabled || loading;

  if (
    !lockedContactSearchResultsKey ||
    lockedContactSearchResultsKey?.total === 0
  ) {
    return null;
  }

  return (
    <Overlay root="confirm-action-modal" onClose={() => onCancel()}>
      <Dialog
        level={
          actionType === 'Delete' || actionType === 'UnsubscribeEmail'
            ? 'danger'
            : 'warning'
        }
        header={actionsCopy[actionType].header}
        body={
          <>
            <LockedContactsMessage
              message={actionsCopy[actionType].lockedContacts(
                lockedContactSearchResultsKey?.total ?? 0,
              )}
              lockedAmount={lockedContactSearchResultsKey?.total ?? 0}
              loading={loading}
              selectedContactAmount={selectedContactAmount}
            />

            {children && typeof children === 'function'
              ? children(loading)
              : null}
          </>
        }
        buttons={[
          {
            label: text.confirm,
            loading: doContactActionLoading,
            onClick: async () => {
              if (confirmDisabled) {
                return;
              }

              const { data, errors } = await doContactAction({
                variables: {
                  accountId: account.id,
                  action: actionInput,
                  key: lockedContactSearchResultsKey.key,
                },
              });

              if (errors && errors.length > 0) {
                addToast([
                  formatToastMessage(
                    actionsCopy[actionType].doActionErrorMsg,
                    'danger',
                  ),
                ]);
                onCancel();
                return;
              }

              if (data) {
                addToast([
                  formatToastMessage(
                    actionsCopy[actionType].doActionSuccessMsg,
                    'success',
                  ),
                ]);
                onComplete();
                return;
              }
            },
          },
        ]}
        onClose={onCancel}
      />
    </Overlay>
  );
};

export default ConfirmActionModal;
