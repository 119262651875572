import { AppType, StellaxAiReportType } from '~/graphql/types';
import type { AppConfig } from '../types';
import type { OptionOf } from '~/components/molecule/Dropdown';
import type { PriceWithInterval } from '~/util/getPriceWithInterval';
import getPriceWithInterval from '~/util/getPriceWithInterval';

const description = `## Omschrijving
In de huidige vastgoedmarkt is het een veelgehoorde vraag. Moet ik mijn belegging verkopen of verhuren?

Deze app geeft het antwoord! Je haalt een unieke leadgeneratietool in huis voor het aanbieden van een duidelijk verhuurrapport op jouw website. Het rapport bevat de verwachte huuropbrengst en het aantal WWS punten. Financieel adviseurs kunnen ook een investeringsanalyse tonen. De rapporten zijn opgesteld door Stellax, een software- en dataleverancier voor vastgoedprofessionals.

De app stelt je in staat om moeiteloos in contact te komen met nieuwe klanten voor verhuur, verkoop en financiering. Dit is hét moment om in een veranderende markt je relaties en websitebezoekers om te zetten naar nieuwe klanten.

Je hebt keuze uit twee type rapporten, één met de huurprijs en WWS punten geschikt voor makelaars. Het tweede rapport biedt daarnaast inzicht in de leegwaarde en presenteert een bruto/netto rendement en cash-flow berekening, geschikt voor financieel adviseurs. Het rapport wordt in jouw huisstijl opgesteld, kan ongelimiteerd (fair-use) worden opgevraagd en is per maand opzegbaar.


**Type rapport** (Tarief p/m (excl. btw))

- Huurprijs en WWS-punten check - € 50,- ([voorbeeld](https://dhstash.s3.eu-west-1.amazonaws.com/app/stellax/Kastelenstraat_213-3_1082EE_Amsterdam_standaard.pdf))
- Huurprijs, WWS-punten check, leegwaarde en rendement- en cashflow analyse - € 80,- ([voorbeeld](https://dhstash.s3.eu-west-1.amazonaws.com/app/stellax/Kastelenstraat_213-3_1082EE_Amsterdam_uitgebreid.pdf))
`;

const termsOfUse = `Deze app wordt aangeboden door DatHuis BV in samenwerking met Stellax BV.

Door het activeren van deze app ga je akkoord met de voorwaarden van Stellax en geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van Stellax. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Op de dienstverlening van Stellax B.V. zijn hun voorwaarden van toepassing. Deze vind je op
[https://stellax.ai/terms_of_use](https://stellax.ai/terms_of_use).`;

export const APP_HELP_LINK =
  'https://help.dathuis.nl/nl/articles/9145218-over-de-stellax-ai-property-scan-app';

const PRICES: Array<PriceWithInterval> = [
  {
    amount: 5000,
    currency: 'EUR',
    interval: {
      amount: 1,
      duration: 'month',
    },
  },
  {
    amount: 8000,
    currency: 'EUR',
    interval: {
      amount: 1,
      duration: 'month',
    },
  },
];

export const PRODUCT_OPTIONS: Array<OptionOf<StellaxAiReportType>> = [
  {
    label: `Basisrapport - ${getPriceWithInterval(PRICES[0])}`,
    description: `Bevat huurprijs en WWS-punten check geschikt voor makelaars`,
    payload: StellaxAiReportType.B,
    key: 'productB',
  },
  {
    label: `Uitgebreid rapport - ${getPriceWithInterval(PRICES[1])}`,
    description: `Basisrapport plus leegwaarde en rendementsberekening geschikt voor makelaars en financieel adviseurs`,
    payload: StellaxAiReportType.D,
    key: 'productD',
  },
];

export const stellax: AppConfig = {
  __typename: 'AppStatus_StellaxAi',
  appType: AppType.StellaxAi,
  type: 'with_config',
  slug: 'stellax-ai',
  name: 'Stellax - Huuranalyse & WWS-check',
  tags: ['Verhuur', 'Verkoop', 'Vastgoedbeheer', 'Leadgeneratie'],
  provider: 'Stellax B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/stellax_ai_logo.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/stellax_ai_logo.svg',
  },
  price: PRICES,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: APP_HELP_LINK,
  },
  setupFlow: 'selectProduct',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'custom',
  productOptions: PRODUCT_OPTIONS,
};
