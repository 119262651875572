import type { Theme } from '~/theme';
import { Appearance } from '.';

type AppearanceState = 'main' | 'error' | 'disabled';
type Styles = {
  color: string;
  background: string;
  border: string;
};

type DropdownAppearance = {
  [key in Appearance]: {
    [key in AppearanceState]: {
      base: Styles;
      hover?: Styles;
    };
  } & {
    icon: {
      base: string;
      hover?: string;
    };
  };
};

const getDropdownAppearances: (theme: Theme) => DropdownAppearance = theme => {
  const border = theme.getTokens().border.width.s;
  const primaryLight = theme.color('primary', 'light');
  const primary = theme.color('primary');
  const white = theme.color('white');
  const danger = theme.color('danger');
  const dangerDark = theme.color('danger', 'dark');
  const disabled = theme.color('tertiary', 'dark');
  const secondaryDark = theme.color('secondary', 'dark');

  const transparentBorder = `${border} solid transparent`;
  const errorBorder = `${border} solid ${danger}`;
  const hoverErrorBorder = `${border} solid ${dangerDark}`;
  const disabledBorder = `${border} solid ${disabled}`;
  const text = theme.color('text');
  const whiteDark = theme.color('white', 'dark');

  const secondary = theme.color('secondary');

  return {
    outline: {
      main: {
        base: {
          color: primaryLight,
          background: white,
          border: `${border} solid ${primaryLight}`,
        },
        hover: {
          color: white,
          background: primaryLight,
          border: `${border} solid ${primaryLight}`,
        },
      },
      error: {
        base: {
          color: danger,
          background: white,
          border: errorBorder,
        },
        hover: {
          color: white,
          background: dangerDark,
          border: hoverErrorBorder,
        },
      },
      disabled: {
        base: { background: white, color: disabled, border: disabledBorder },
      },
      icon: {
        base: secondary,
      },
    },
    borderless: {
      main: {
        base: {
          color: primaryLight,
          background: 'transparent',
          border: transparentBorder,
        },
        hover: {
          color: primary,
          background: 'transparent',
          border: transparentBorder,
        },
      },
      error: {
        base: {
          color: danger,
          background: 'transparent',
          border: transparentBorder,
        },
        hover: {
          color: dangerDark,
          background: 'transparent',
          border: transparentBorder,
        },
      },
      disabled: {
        base: {
          background: 'transparent',
          color: disabled,
          border: transparentBorder,
        },
      },
      icon: {
        base: secondary,
      },
    },
    filled: {
      main: {
        base: {
          color: white,
          background: secondary,
          border: `${border} solid ${secondary}`,
        },
        hover: {
          color: white,
          background: secondaryDark,
          border: `${border} solid ${secondaryDark}`,
        },
      },
      error: {
        base: {
          color: white,
          background: danger,
          border: errorBorder,
        },
        hover: {
          color: white,
          background: dangerDark,
          border: hoverErrorBorder,
        },
      },
      disabled: {
        base: {
          color: white,
          background: disabled,
          border: disabledBorder,
        },
      },
      icon: {
        base: white,
      },
    },
    default: {
      main: {
        base: {
          color: text,
          background: white,
          border: `${border} solid ${disabled}`,
        },
        hover: {
          color: text,
          background: white,
          border: `${border} solid ${primaryLight}`,
        },
      },
      error: {
        base: {
          color: danger,
          background: white,
          border: errorBorder,
        },
        hover: {
          color: dangerDark,
          background: white,
          border: hoverErrorBorder,
        },
      },
      disabled: {
        base: {
          color: disabled,
          background: whiteDark,
          border: disabledBorder,
        },
      },
      icon: {
        base: secondary,
      },
    },
    accent: {
      main: {
        base: {
          color: secondary,
          background: white,
          border: `${border} solid ${secondary}`,
        },
        hover: {
          color: white,
          background: secondary,
          border: `${border} solid ${secondary}`,
        },
      },
      error: {
        base: {
          color: danger,
          background: white,
          border: errorBorder,
        },
        hover: {
          color: dangerDark,
          background: white,
          border: hoverErrorBorder,
        },
      },
      disabled: {
        base: {
          color: disabled,
          background: whiteDark,
          border: disabledBorder,
        },
      },
      icon: {
        base: secondary,
        hover: white,
      },
    },
  };
};

export default getDropdownAppearances;
