import { isString } from 'lodash';

import { ApolloError } from '@apollo/client';
import { reporter } from './hooks/useErrorReporter';

enum ErrorTypes {
  // Account blocked errors
  accountLockedError = 'DHAccountLockedError',
  accountPausedError = 'DHAccountPausedError',
  accountCancelledError = 'DHAccountCancelledError',

  // Not found error
  notFoundError = 'DHNotFoundError',

  // Unauthorised
  unauthorisedError = 'DHUnauthorisedError',

  // Contact specific error
  contactExists = 'DHContactExists',
  invalidContactId = 'DHInvalidContactIdError',
  invalidContactFilters = 'DHContactFiltersError',

  // Email errors
  emailNotSynced = 'DHEmailNotSyncedYetError',

  // Cognito errors
  notAuthorized = 'NotAuthorizedException',
  limitExceeded = 'LimitExceededException',
  invalidPassword = 'InvalidParameterException',
  expiredCodeException = 'ExpiredCodeException',

  customProviderSyncInvalidCredentials = 'DHError',
  invalidFlowBlueprintId = 'DHInvalidFlowBlueprintIdError',

  // Deletion errors
  deletedUserError = 'DHUserDeletedError',
  receivingEntityBeingDeletedError = 'DHReceivingEntityBeingDeleted',
  entitiesAreLockedError = 'DHEntitiesAreLocked',
  officeIsLastForUserError = 'DHOfficeIsLastForUser',
  isLastAccountAdminError = 'DHUserIsLastActiveAccountAdminError',

  // Impersonation errors
  impersonationIsOngoingError = 'DHImpersonationIsOngoingError',

  refreshRequiredError = 'DHRefreshRequiredError',
}

// Cognito is not very consistent where it places errors. So grab it from the place that exists
export const errortypeFromCognitoError = (err: $Object): string | null => {
  if (isString(err.code)) {
    return err.code;
  } else if (isString(err.type)) {
    return err.type;
  } else if (isString(err.__type)) {
    return err.type;
  } else {
    reporter.captureException(
      new Error('Could not get the cognito error type'),
      'fatal',
      {
        errorInfo: err,
      },
    );
    return null;
  }
};

export const errorTypeFromMutationError = (
  mutationError?: ApolloError | null,
): string | null => {
  if (!mutationError || !mutationError.graphQLErrors) {
    return null;
  }

  if (mutationError.graphQLErrors.length === 0) {
    return null;
  }

  // Backend ensures we always have an errorType
  // @ts-ignore
  return mutationError.graphQLErrors[0].errorType;
};

export default ErrorTypes;
