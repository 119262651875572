import React, { useState, useContext } from 'react';
import SendEmailBlock from '~/components/organism/SendEmailBlock';
import {
  TaskListTaskFieldsFragment,
  TaskStatus,
  useUpdateTaskMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TaskModalContext from '~/components/page/Tasks/context/TaskModalContext';
import { ActivityTabProps } from '../..';
import useUpdateTimelineFn from '~/hooks/useUpdateTimelineFn';

export type SendMessageTabContact = {
  name: string;
  email: string;
  id: string;
};

type Props = ActivityTabProps & {
  handleCloseModal?: () => void;
  contact: SendMessageTabContact;

  /** Called when sending was a success and all other sideeffects are handled */
  onSuccess: (newEmailActivity: TaskListTaskFieldsFragment) => void;
};

const SendMessageTab: React.FCC<Props> = ({ contact, handleCloseModal }) => {
  const account = useCurrentAccount();
  const { taskDetails } = useContext(TaskModalContext);
  const [hasError, setHasError] = useState<boolean>(false);
  const [allowUpdateStatus, setAllowUpdateStatus] = useState<boolean>(false);
  const [allowClose, setAllowClose] = useState<boolean>(false);

  const updateTimelineFn = useUpdateTimelineFn(contact.id);

  const [updateTask, { loading }] = useUpdateTaskMutation();

  const setAfterSendActions = ({
    allowUpdateStatus,
    allowClose,
  }: {
    allowUpdateStatus: boolean;
    allowClose: boolean;
  }) => {
    setAllowClose(allowClose);
    setAllowUpdateStatus(allowUpdateStatus);
  };

  const onTaskUpdate = () => {
    if (allowUpdateStatus) {
      if (taskDetails) {
        const newStatus =
          taskDetails.status === TaskStatus.Open
            ? TaskStatus.Closed
            : TaskStatus.Open;
        void updateTask({
          variables: {
            accountId: account.id,
            id: taskDetails.id,
            update: {
              status: newStatus,
            },
          },
        }).then(({ data, errors }) => {
          if (errors && errors.length > 0) {
            return setHasError(true);
          }

          if (data) {
            if (allowClose && typeof handleCloseModal === 'function') {
              handleCloseModal();
            }
            setHasError(false);
            return;
          }

          return;
        });
      }
    } else if (!allowUpdateStatus && allowClose && handleCloseModal != null) {
      handleCloseModal();
    }
  };

  return (
    <SendEmailBlock
      handleCloseModal={handleCloseModal}
      receiver={{
        email: contact.email,
        name: contact.name,
      }}
      loading={loading}
      error={hasError}
      setAfterSendActions={setAfterSendActions}
      onSuccess={newActivity => {
        onTaskUpdate();
        updateTimelineFn(newActivity);
      }}
      contactId={contact.id}
    />
  );
};

SendMessageTab.defaultProps = {
  label: 'Verstuur e-mail',
  datatestId: 'send-message',
};

export default SendMessageTab;
