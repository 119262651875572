import { isNil, keys } from 'ramda';
import { GetEntityActivityOverviewQuery } from '~/graphql/types';

const activitiesConfig = {
  newContacts: {
    icon: 'user-plus',
    description: 'Nieuwe contacten vandaag',
    label: 'contact(en)',
    link: '/-/contacts',
    value: 0,
  },
  openTasks: {
    icon: 'check-square',
    description: 'Open taken',
    label: 'taken',
    link: '/-/tasks',
    value: 0,
  },
};

const getActivities = (data: GetEntityActivityOverviewQuery | undefined) => {
  if (!data?.getEntityActivityOverview) return activitiesConfig;

  return keys(activitiesConfig).reduce((activities, currentActivityKey) => {
    if (!isNil(data.getEntityActivityOverview[currentActivityKey])) {
      activities[currentActivityKey] = {
        ...activitiesConfig[currentActivityKey],
        value: data.getEntityActivityOverview[currentActivityKey],
      };
    }
    return activities;
  }, {});
};

export default getActivities;
