import {
  AppStatus_Hypotheekbond_Metadata__Input,
  AppStatus_Simple_Metadata__Input,
  AppStatus_Trustoo_Metadata__Input,
  AppStatus_Zapier_Metadata__Input,
  InsertedTemplateId,
} from '~/graphql/types';
import { AppStatus } from '~/graphql/types.client';

export type AppTypeMetadataInput =
  | AppStatus_Zapier_Metadata__Input
  | AppStatus_Hypotheekbond_Metadata__Input
  | AppStatus_Trustoo_Metadata__Input
  | AppStatus_Simple_Metadata__Input
  | undefined;

type Args = {
  appStatus: AppStatus;
  addedFlows: Array<InsertedTemplateId>;
};

const getMetadataForAppStatus = ({
  addedFlows,
}: Args): AppTypeMetadataInput => {
  const metadata: AppTypeMetadataInput = {
    setup: {
      completed: true,
      addedFlows: addedFlows.map(flow => flow.templateId),
    },
  };

  return metadata;
};

export default getMetadataForAppStatus;
