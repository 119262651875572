/**
 * Reply icon
 * Made by Adrian
 */

import React from 'react';
import { Props } from '..';

const Reply: React.FCC<Props> = ({ color = 'currentColor', ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.8 15.6L3 10.8L7.8 6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 10.7998H16.2C18.9 10.7998 21 12.8998 21 15.5998V17.9998"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Reply;
