import type {
  FormBuilder_Node_DefaultNext,
  FormBuilder_NodeFragment,
} from '~/graphql/types';
import type { CreateType } from '../../components/AddNodeMenu';
import { SUBMIT_SCREEN_TITLE } from '../../components/InteractionHandler/components/EditNodeScreen/constants';
import { TRAILING_NODE_ID } from '../../components/Canvas/constants';

/**
 * Creates a node
 * @param {string} newNodeId
 * @param {string} targetId
 * @param {CreateType} createType
 * keywords:
 */
const getNewNodeByCreateType = ({
  createType,
  targetId,
  newNodeId,
  name,
}: {
  createType: CreateType;
  targetId: string;
  newNodeId: string;
  name?: string;
}): FormBuilder_NodeFragment | null => {
  const isLastNode = targetId === TRAILING_NODE_ID;
  const defaultNext: FormBuilder_Node_DefaultNext | null = isLastNode
    ? null
    : {
        __typename: 'FormBuilder_Node_DefaultNext',
        targetNodeId: targetId,
      };

  switch (createType) {
    case 'event':
      return {
        __typename: 'FormBuilder_EventNode',
        formBuilderEventId: '',
        id: newNodeId,
        mapping: [],
        name: 'Nieuw Event',
        defaultNext,
      };

    case 'screen':
      return {
        __typename: 'FormBuilder_ScreenNode',
        id: newNodeId,
        name: name ?? 'Nieuwe Pagina',
        blocks: [],
        defaultNext,
      };

    case 'submitScreen':
      return {
        __typename: 'FormBuilder_ScreenNode',
        id: newNodeId,
        name: SUBMIT_SCREEN_TITLE,
        blocks: [],
        defaultNext,
      };

    default:
      return null;
  }
};

export default getNewNodeByCreateType;
