import { BillingDetailsFieldsFragment } from '~/graphql/types';

export type BillingFormData = {
  name: string | null | undefined;
  email: string | null | undefined;
  addressLine1: string | null | undefined;
  addressLine2: string | null | undefined;
  addressPostcode: string | null | undefined;
  addressCity: string | null | undefined;
};

const convertToFormData = (
  billingDetails: BillingDetailsFieldsFragment | null,
): BillingFormData => {
  const { name, email, address } = billingDetails || {};

  let addressFields: {
    addressLine1: string | null;
    addressLine2: string | null;
    addressPostcode: string | null;
    addressCity: string | null;
  } = {
    addressLine1: null,
    addressLine2: null,
    addressPostcode: null,
    addressCity: null,
  };
  if (address != null) {
    const { line1, line2, postcode, city } = address;
    addressFields = {
      addressLine1: line1 ?? null,
      addressLine2: line2 ?? null,
      addressPostcode: postcode ?? null,
      addressCity: city ?? null,
    };
  }
  const formData: BillingFormData = {
    name: name || null,
    email: email || null,
    ...addressFields,
  };

  return formData;
};

export default convertToFormData;
