import type {
  ContactFiltersSubjectDirectoryFragment,
  ContactFiltersSubjectFragment,
} from '~/graphql/types';

import { sort } from 'ramda';
export type DirectoryEntry = ContactFiltersSubjectDirectoryFragment & {
  children: { [key in string]: DirectoryEntry };
  subjects: Array<ContactFiltersSubjectFragment>;
};
export type DirectoryMap = {
  children: { [key in string]: DirectoryEntry };
  subjects: Array<ContactFiltersSubjectFragment>;
};

/**
 * Generate the subject map
 */
export const generateDirectoryMap = (
  subjects: Array<ContactFiltersSubjectFragment>,
): DirectoryMap => {
  const directoryMap: DirectoryMap = {
    children: {},
    subjects: [],
  };

  const sortedSubjects = sort((a, b) => a.weight - b.weight, subjects);

  for (const subject of sortedSubjects) {
    const subjectDirectory = subject.dir ?? [];
    let currentDir: DirectoryMap | DirectoryEntry = directoryMap;

    for (
      let directoryIndex = 0;
      directoryIndex < subjectDirectory.length;
      ++directoryIndex
    ) {
      const dir = subjectDirectory[directoryIndex];

      if (currentDir.children[dir.key] == null) {
        currentDir.children[dir.key] = {
          ...dir,
          children: {},
          subjects: [],
        };
      }

      currentDir = currentDir.children[dir.key];
    }

    currentDir.subjects.push(subject);
  }

  return directoryMap;
};

export const getDirectory = (
  key: string,
  entry: DirectoryEntry | DirectoryMap,
): DirectoryEntry | undefined => entry.children[key];

export default getDirectory;
