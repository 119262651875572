import gql from 'graphql-tag';

export default gql`
  query GetCoupon($accountId: ID!, $coupon: ID!) {
    getCoupon(accountId: $accountId, coupon: $coupon) {
      coupon
      name
      amountOff
      percentOff
      durationInMonth
      duration
      canBeApplied
    }
  }
`;
