import React from 'react';

import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import { InvoiceItemFields_BasicInvoiceItem_Fragment } from '~/graphql/types';
import { asPriceElement } from '~/util/money';

export type Props = {
  item: InvoiceItemFields_BasicInvoiceItem_Fragment;
};

const BasicInvoiceItem: React.FCC<Props> = ({ item }) => {
  // When all items amount to 0 we don't need to render the row
  if (item.amount === 0) return null;

  return (
    <JustificationContainer justification="space-between" width="100%">
      <Heading4 variant={Variant.primary} margin={[null]}>
        {item.name}
      </Heading4>
      <Body size="base" margin={[null]} fontWeight="semiBold">
        {asPriceElement(item.amount)}
      </Body>
    </JustificationContainer>
  );
};

export default BasicInvoiceItem;
