import React from 'react';
import styled from 'styled-components';
import PluginsEditor, {
  Props as PluginsEditorProps,
  type EditorPlugins,
} from '~/components/organism/PluginsEditor';
import useWithImages from '~/components/organism/PluginsEditor//plugins/useWithImages';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import withHtml from '~/components/organism/PluginsEditor//plugins/withHtml';
import type { EditorValue } from '../PluginsEditor/types';

export type Props = Omit<PluginsEditorProps, 'plugins' | 'children'> & {
  /** Editor value in slate format */
  value: EditorValue;

  /** Function to change editor value */
  onChange: (value: EditorValue) => void;

  /** If you want to add more elements to the editor */
  customElements?: Array<ELEMENTS>;

  /** Additional plugins to add other functionality to the editor */
  plugins?: EditorPlugins;

  /** Makes the editor readOnly */
  readOnly?: boolean;

  /** Classname for outside container */
  className?: string;
};

/**
 * Adds withHtml and withImages plugins to the PluginsEditor.
 */
const DefaultTextEditor: React.FCC<Props> = ({
  dataTestId,
  value,
  onChange,
  customElements = [],
  plugins = [],
  children,
  className,
  ...rest
}) => {
  const withImages = useWithImages();

  const extraElements = [...customElements, ELEMENTS.DH_IMAGE, ELEMENTS.IMAGE];
  return (
    <Container data-testid={dataTestId} className={className}>
      <PluginsEditor
        plugins={[
          {
            name: 'withHtml',
            fn: e => withHtml({ editor: e, customElements: extraElements }),
          },
          {
            name: 'withImages',
            fn: withImages,
          },
          ...plugins,
        ]}
        customElements={extraElements}
        value={value}
        onChange={onChange}
        minHeight={150}
        {...rest}
      />
      {children}
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
`;

export default DefaultTextEditor;
