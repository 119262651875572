import { AppConfig } from './types';
import { arxGroep } from './ARXGroep/appConfig';
import { matrixian } from './VBOWaardecheck/appConfig';
import { bbwc } from './BBWaardecheck/appConfig';
import { funda } from './Funda/appConfig';
import { homekeur } from './Homekeur/appConfig';
import { hypotheekbond } from './Hypotheekbond/appConfig';
import { realworks } from './Realworks/appConfig';
import { susReport } from './SustainabilityReport/appConfig';
import { trustoo } from './Trustoo/appConfig';
import { valuationReport } from './ValueReport/appConfig';
import { zapier } from './Zapier/appConfig';
import { contaqt } from './Contaqt/appConfig';
import { geogap } from './Geogap/appConfig';
import { stellax } from './StellaxAI/appConfig';

const apps: Array<AppConfig> = [
  arxGroep,
  matrixian,
  bbwc,
  contaqt,
  stellax,
  funda,
  geogap,
  homekeur,
  hypotheekbond,
  realworks,
  susReport,
  trustoo,
  valuationReport,
  zapier,
];

export default apps;
