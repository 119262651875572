import React, { useEffect, useReducer } from 'react';
import styled, { css } from 'styled-components';
import Dropdown from '~/components/molecule/Dropdown';
import type { OptionOf } from '~/components/molecule/Dropdown';
import { FlowV2_Action_Contact_DetailsFragment } from '~/graphql/types';
import { Props as FormProps } from '../ActionForm';
import TEST_ID from './index.testid';
import { reducer, type FieldName, createInitialState } from './reducer';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TemplateStringField from './component/TemplateStringField';

type OptionT = OptionOf<'name' | 'phone' | 'unsubscribeEmail'>;
const text = {
  field: 'Veld',
};

const options: Array<OptionT> = [
  {
    label: 'Naam',
    key: 'name',
    payload: 'name',
  },
  {
    label: 'Telefoonnummer',
    key: 'phone',
    payload: 'phone',
  },
  {
    label: 'Uitschrijven van e-mails',
    key: 'unsubscribeEmail',
    payload: 'unsubscribeEmail',
  },
];

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_Contact_DetailsFragment;
};

const ContactDetails: React.FCC<Props> = ({ action, onChange }) => {
  const initials = {
    field: action.field,
  };

  const [state, dispatch] = useReducer(
    reducer,
    {
      action,
      selectedField: 'name',
    },
    createInitialState,
  );

  useEffect(() => {
    onChange({ ...state.action });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.action]);

  return (
    <Container>
      <JustificationContainer direction="column" width="100%">
        <Dropdown
          size="large"
          label={text.field}
          options={options}
          selectedOptionIdx={options.findIndex(
            option => option.key === state.selectedField,
          )}
          onChange={({ option }) => {
            dispatch({
              type: 'selectField',
              payload: option.payload as FieldName,
            });
          }}
          dataTestId={TEST_ID.DROPDOWN}
        />
      </JustificationContainer>
      {state.selectedField !== 'unsubscribeEmail' && (
        <JustificationContainer direction="column">
          {state.selectedField === 'phone' && (
            <TemplateStringField
              actionId={action.id}
              html={initials.field.phone?.template}
              mappings={initials.field.phone?.mappings}
              updaterFn={value => {
                dispatch({
                  type: 'updatePhone',
                  payload: value,
                });
              }}
              dataTestId={TEST_ID.PHONE_INPUT}
            />
          )}

          {state.selectedField === 'name' && (
            <TemplateStringField
              actionId={action.id}
              html={initials.field.name?.template}
              mappings={initials.field.name?.mappings}
              updaterFn={value => {
                dispatch({
                  type: 'updateName',
                  payload: value,
                });
              }}
              dataTestId={TEST_ID.NAME_INPUT}
            />
          )}
        </JustificationContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    gap: ${theme.space('m')};
    margin: ${theme.space('base')} 0;
    width: 100%;
    align-items: end;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    /* When there's only one child */
    & > :only-child {
      /* Span all columns */
      grid-column: 1 / -1;
    }

    & > :nth-child(2) {
      /* Second child takes 2 fractions */
      grid-column: 2 / span 2;
    }

    /* When there are two children */
    & > :first-child:not(:only-child) {
      /* First child takes 1 fraction */
      grid-column: 1 / span 1;
    }
  `,
);

export default ContactDetails;
