import styled from 'styled-components';

const RowLabel = styled.div<{}>`
  align-self: center;

  ${({ theme }) => `
    font-size: ${theme.fontSize('s')};
    color: ${theme.color('text')};
  `};
`;

export default RowLabel;
