import { isNil, propEq } from 'ramda';
import { Account, GetMyAccountsQueryResult } from '~/graphql/types';
import { SELECTED_ACCOUNT_STORAGE_KEY } from '../localStorageKeys';

const getCurrentAccount = (
  data: GetMyAccountsQueryResult['data'],
): Pick<Account, 'id' | 'name' | 'status'> | null => {
  if (!data || data.getMyAccounts.length === 0) return null;
  const accounts = data.getMyAccounts;
  const selectedAccountId = global.localStorage.getItem(
    SELECTED_ACCOUNT_STORAGE_KEY,
  );

  const activeAccounts = accounts;

  if (activeAccounts.length <= 1 || isNil(selectedAccountId))
    return activeAccounts[0];
  return (
    activeAccounts.find(propEq(selectedAccountId, 'id')) || activeAccounts[0]
  );
};

export default getCurrentAccount;
