import { useCallback, useEffect } from 'react';

/**
 * Registers clicks in the document
 *
 * keywords: click,outside,element
 */
const useClick = (callback: (ev: MouseEvent) => any) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = useCallback((ev: MouseEvent) => callback(ev), []);

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};

export default useClick;
