export const text = {
  initialHeaderName: 'Uitgaande koppeling',
  description:
    'Standaard worden de NAWTE en tags van het contact verstuurd naar Zapier. Wil je meer data versturen? Voeg dan hier de extra velden toe.',
  goBackLink: '/-/apps/zapier',
  children: null,
  modalHeader: 'Aanpassingen annuleren?',
  modalBody:
    'Are you sure you want to cancel all your changes? They cannot be returned.',
  deleteModalBody:
    'Weet je zeker dat je deze koppeling wilt verwijderen? Dit kan niet ongedaan worden.',
  noTriggerFound: 'Geen uitgaande koppeling gevonden met de opgegeven id',
};

export const breadcrumbs = [
  {
    to: '/-/apps',
    label: 'Apps',
  },
  { to: '/-/apps/zapier', label: 'Zapier' },
];
