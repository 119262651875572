type TagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

/**
 * serializes Heading elements into html element
 * @param {TagName} tagName - Lowercased tag name
 */
const serializeHeading = (tagName: TagName, children: string): string => {
  if (children.length === 0) return '';

  return `<${tagName}>${children}</${tagName}>`;
};

export default serializeHeading;
