import React from 'react';
import { Props } from '..';

const Browser: React.FCC<Props> = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 511.99 511.99"
    width="1em"
    height="1em"
    {...props}
  >
    <path fill={'#f95632'} d="M137.088 40.357h292.326V84.21H137.088z" />
    <path fill={'#63d1db'} d="M107.338 154.116h292.326v87.706H107.338z" />
    <path
      d="M419.443 448.918H116.797V43.974h302.646v404.944zm-292.334-10.312h282.025V54.289H127.109v384.317z"
      fill="#2e3133"
    />
    <path fill="#12282c" d="M116.797 154.116h10.317v87.706h-10.317z" />
    <g fill="#2e3133">
      <path d="M158.497 114.008h35.612v10.317h-35.612zM219.725 114.008h35.604v10.317h-35.604zM280.909 114.008h35.615v10.317h-35.615zM342.144 114.008h35.607v10.317h-35.607z" />
    </g>
    <g fill="#fff">
      <path d="M158.497 65.901h10.317v10.317h-10.317zM179.103 65.901h10.317v10.317h-10.317zM199.731 65.901h10.317v10.317h-10.317z" />
    </g>
    <g fill="#c1ced4">
      <path d="M237.517 351.722h61.179v61.184h-61.179zM316.57 351.722h61.174v61.184H316.57zM158.497 272.695h61.187v61.174h-61.187zM237.517 272.695h61.179v61.174h-61.179z" />
    </g>
    <g fill="#2e3133">
      <path d="M209.485 351.62h10.317v10.317h-10.317zM201.321 361.921h-8.154V351.61h8.154v10.311zm-16.31 0h-8.154V351.61h8.154v10.311zM158.39 351.62h10.317v10.317H158.39zM168.701 394.543H158.39v-8.159h10.312v8.159zm0-16.309H158.39v-8.159h10.312v8.159zM158.39 402.692h10.317v10.317H158.39zM201.318 413.009h-8.154V402.7h8.154v10.309zm-16.309 0h-8.154V402.7h8.154v10.309zM209.485 402.692h10.317v10.317h-10.317zM219.786 394.541h-10.312v-8.159h10.312v8.159zm0-16.31h-10.312v-8.159h10.312v8.159zM367.539 272.567h10.317v10.317h-10.317zM359.386 282.892h-8.156V272.58h8.156v10.312zm-16.313 0h-8.151V272.58h8.151v10.312zM316.442 272.567h10.317v10.317h-10.317zM326.766 315.508h-10.312v-8.159h10.312v8.159zm0-16.309h-10.312v-8.156h10.312v8.156zM316.442 323.665h10.317v10.317h-10.317zM359.38 333.974h-8.151v-10.312h8.151v10.312zm-16.307 0h-8.159v-10.312h8.159v10.312zM367.539 323.665h10.317v10.317h-10.317zM377.848 315.508h-10.312v-8.156h10.312v8.156zm0-16.307h-10.312v-8.159h10.312v8.159zM389.069 298.116h50.46v10.317h-50.46z" />
    </g>
    <path fill={'#f95632'} d="M450.816 272.695h61.174v61.179h-61.174z" />
    <path fill="#2e3133" d="M96.727 377.143h50.46v10.317h-50.46z" />
    <path fill={'#63d1db'} d="M24.238 351.722h61.179v61.184H24.238z" />
    <path fill={'#f95632'} d="M13.599 202.935h132.662v87.04H13.599z" />
    <path fill="#fff" d="M69.35 234.226l21.159 12.219-21.159 12.224z" />
    <path
      d="M466.644 195.045H361.917v-62.413h104.727v62.413zm-94.417-10.309h84.106v-41.792h-84.106v41.792z"
      fill="#2e3133"
    />
    <path fill="#c1ced4" d="M367.078 422.583h102.285v49.05H367.078z" />
    <g fill="#2e3133">
      <path d="M16.333 132.686h55.849v10.317H16.333zM16.333 115.117h67.2v10.317h-67.2zM0 97.565h54.4v10.317H0zM437.376 425.911h55.109v10.317h-55.109zM452.736 408.349h39.736v10.317h-39.736z" />
    </g>
    <path
      fill="#2d110a"
      d="M137.083 54.289h272.049V84.21h10.311V43.974h-282.36z"
    />
    <path
      fill="#12282c"
      d="M399.665 184.736h-27.438v-30.615h-10.31v40.924h37.748z"
    />
    <g fill="#08090a">
      <path d="M409.139 184.733h10.317v10.317h-10.317zM409.139 132.632h10.317v10.317h-10.317zM116.797 377.143h10.317v10.317h-10.317zM409.139 298.116h10.317v10.317h-10.317z" />
    </g>
    <g fill="#23282a">
      <path d="M419.443 422.596h-10.311v16.01h-42.059v10.312h52.37zM437.376 425.911h31.982v10.317h-31.982z" />
    </g>
    <path fill="#61462b" d="M107.338 202.935h38.925v38.892h-38.925z" />
    <path fill="#2d110a" d="M116.797 202.935h10.317v87.04h-10.317z" />
    <path fill="#120d09" d="M116.797 202.935h10.317v38.892h-10.317z" />
  </svg>
);

export default Browser;
