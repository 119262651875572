import React from 'react';
import { Props } from '..';

const MultiChoice: React.FCC<Props> = ({ color = 'currentColor' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="14"
    viewBox="0 0 23 14"
    fill="none"
  >
    <path
      d="M22 5H9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 1H9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 9H9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 13H9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_15326_21171)">
      <path
        d="M2.25 2.75L3 3.5L5.5 1"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 3V4.75C5.25 4.88261 5.19732 5.00979 5.10355 5.10355C5.00979 5.19732 4.88261 5.25 4.75 5.25H1.25C1.11739 5.25 0.990215 5.19732 0.896447 5.10355C0.802678 5.00979 0.75 4.88261 0.75 4.75V1.25C0.75 1.11739 0.802678 0.990215 0.896447 0.896447C0.990215 0.802678 1.11739 0.75 1.25 0.75H4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g clipPath="url(#clip1_15326_21171)">
      <path
        d="M2.25 10.75L3 11.5L5.5 9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 11V12.75C5.25 12.8826 5.19732 13.0098 5.10355 13.1036C5.00979 13.1973 4.88261 13.25 4.75 13.25H1.25C1.11739 13.25 0.990215 13.1973 0.896447 13.1036C0.802678 13.0098 0.75 12.8826 0.75 12.75V9.25C0.75 9.11739 0.802678 8.99021 0.896447 8.89645C0.990215 8.80268 1.11739 8.75 1.25 8.75H4"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15326_21171">
        <rect width="6" height="6" fill="white" />
      </clipPath>
      <clipPath id="clip1_15326_21171">
        <rect width="6" height="6" fill="white" transform="translate(0 8)" />
      </clipPath>
    </defs>
  </svg>
);

export default MultiChoice;
