import React from 'react';
import Icon, { type IconType } from '~/components/atom/Icon';

type Props = {
  icon?: IconType | null;
  loading?: boolean;
  dropdownListOpen?: boolean;
};
const IconFacade: React.FC<Props> = ({
  loading,
  icon = 'chevron',
  dropdownListOpen,
}) => {
  if (loading) {
    return <Icon name="spinner" />;
  }
  if (!icon) {
    return null;
  }
  if (icon === 'chevron') {
    return <Icon name="chevron" strokeWidth={3} flipX={dropdownListOpen} />;
  }

  return <Icon name={icon} />;
};

export default IconFacade;
