import React from 'react';
import Catalog from '~/Catalog';
import {
  useGetBillingDetailsQuery,
  useUpdateBillingDetailsMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import convertToBillingDetails from './utils/convertToBillingDetails';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import {
  Field,
  EmailField,
  PostalCodeField,
} from '~/components/organism/Forms';
import { Form } from 'react-final-form';
import convertToFormData from './utils/convertToFormData';
import validate from './utils/validate';
import Input from '~/components/molecule/Input';
import FormUtils from '~/components/organism/FormUtils';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { Heading3, Variant } from '~/components/atom/Typography';
import withModalOverlay from '~/hocs/withModalOverlay';
import TEST_ID from './index.testid';

export type Props = {
  onComplete: () => void;
  onClose?: () => void;
};

export const MODAL_WIDTH = '890px';

const text = {
  title: 'Wijzig facturatiegegevens',
  nameLabel: 'Naam rekeninghouder',
  emailLabel: 'Facturatie e-mailadres',
  line1Label: 'Adresregel 1',
  line2Label: 'Adresregel 2',
  postcodeLabel: Catalog.postalCodeLabel,
  invalidPostcode: Catalog.invalidPostalCode,
  cityLabel: Catalog.cityLabel,
  submitLabel: 'Opslaan',
  cancel: 'Afbreken',
  updateBillingDetailsErrorMessage: Catalog.genericUnknownErrorMessageShort,
};

const UpdateBillingDetails: React.FC<Props> = ({ onComplete, onClose }) => {
  const account = useCurrentAccount();
  const { data, networkStatus, error, refetch } = useGetBillingDetailsQuery({
    variables: {
      accountId: account.id,
    },
  });
  const [updateBilling, { loading }] = useUpdateBillingDetailsMutation({
    onCompleted: onComplete,
  });

  if (isInitialLoadStatus(networkStatus)) {
    // If there are no overages we do not want to show anything, so also don't show loading
    return <DatHuisLoading />;
  }

  if (error || !data?.getBillingDetails) {
    return <AppErrorScreen inline setBackgroundColor={false} />;
  }

  const initialValues = convertToFormData(data.getBillingDetails);

  return (
    <JustificationContainer
      padding={['xl']}
      direction="column"
      align="center"
      dataTestId={TEST_ID.CONTAINER}
    >
      <Heading3 variant={Variant.primary}>{text.title}</Heading3>
      <Form
        onSubmit={fields => {
          const newBillingDetails = convertToBillingDetails(fields);

          void updateBilling({
            variables: {
              accountId: account.id,
              billingDetails: newBillingDetails,
            },
          }).then(() => refetch());
        }}
        initialValues={initialValues}
        validate={validate}
      >
        {form => {
          const { handleSubmit, submitting, pristine } = form;

          return (
            <form
              onSubmit={handleSubmit}
              data-testid="add-contact-form"
              style={{ width: '100%' }}
            >
              <JustificationContainer direction="column" gap="m" width="100%">
                <Field name="name">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      label={{ text: text.nameLabel }}
                      type="text"
                      externalErrors={
                        error && touched
                          ? [FormUtils.showError(error, touched)]
                          : undefined
                      }
                      {...input}
                      disabled={loading}
                    />
                  )}
                </Field>
                <EmailField name="email">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      label={{ text: text.emailLabel }}
                      type="email"
                      externalErrors={
                        error && touched
                          ? [FormUtils.showError(error, touched)]
                          : undefined
                      }
                      {...input}
                      disabled={loading}
                    />
                  )}
                </EmailField>

                <Field name="addressLine1">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      label={{ text: text.line1Label }}
                      type="text"
                      externalErrors={
                        error && touched
                          ? [FormUtils.showError(error, touched)]
                          : undefined
                      }
                      {...input}
                      disabled={loading}
                    />
                  )}
                </Field>

                <Field name="addressLine2">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      label={{ text: text.line2Label }}
                      type="text"
                      externalErrors={
                        error && touched
                          ? [FormUtils.showError(error, touched)]
                          : undefined
                      }
                      {...input}
                      disabled={loading}
                    />
                  )}
                </Field>

                <JustificationContainer gap="m" width="100%">
                  <PostalCodeField name="addressPostcode">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        width="100%"
                        label={{ text: text.postcodeLabel }}
                        type="text"
                        externalErrors={
                          error && touched
                            ? [FormUtils.showError(error, touched)]
                            : undefined
                        }
                        {...input}
                        disabled={loading}
                      />
                    )}
                  </PostalCodeField>
                  <Field name="addressCity">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        width="100%"
                        label={{ text: text.cityLabel }}
                        type="text"
                        externalErrors={
                          error && touched
                            ? [FormUtils.showError(error, touched)]
                            : undefined
                        }
                        {...input}
                        disabled={loading}
                      />
                    )}
                  </Field>
                </JustificationContainer>

                <JustificationContainer
                  width="100%"
                  justification="space-between"
                >
                  <TextButton
                    label={text.cancel}
                    onClick={onClose}
                    appearance="danger"
                  />
                  <Button
                    size="medium"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={submitting || pristine || loading}
                    label={text.submitLabel}
                    appearance="primary"
                    dataTestId={TEST_ID.SUBMIT_BUTTON}
                  />
                </JustificationContainer>
              </JustificationContainer>
            </form>
          );
        }}
      </Form>
    </JustificationContainer>
  );
};

export default withModalOverlay(UpdateBillingDetails, {
  maxWidth: MODAL_WIDTH,
});
