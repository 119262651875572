import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionContactDetails } from '~/graphql/types.client';
import removeTypenames from '~/util/removeTypenames';
import getActiveField from '~/components/page/Automation/v2/components/UpdateAction/components/ContactDetails/utils/getActiveField';

/**
 *
 * @param {ClientFlowActionContactDetails} action - ContactDetails client action
 */
const serializeContactDetailsAction = (
  action: ClientFlowActionContactDetails,
): FlowV2_Action__Input => {
  const { id, accountId, flowBlueprintId, parentIds, field } = action;

  const activeField = getActiveField(action);
  const updatedField = { [activeField]: field[activeField] };

  return {
    Contact: {
      Details: {
        id,
        accountId,
        flowBlueprintId,
        parentIds,
        field: removeTypenames(updatedField),
      },
    },
  };
};
export default serializeContactDetailsAction;
