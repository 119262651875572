/**
 *
 * Provides our client side email validation
 */

import StringValidation from './String';

/**
 * Uses the regex defined here:
 * @see https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
 *
 * Interesting discussion going on here:
 * @see https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
 *
 * This is likely not the "final word" as this regex allows "peter@aol.d" or "peter@aol.123"
 * as a valid email. While this is technicaly true, there is no 1 letter toplevel domain
 * accepted atm and is therefore likely a typo.
 */
// const EMAIL_REGEX = /^[a-z0-9.!$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/;
const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.(?:[a-zA-Z0-9](?:[a-zA-Z0-9-\.]{0,61}[a-zA-Z0-9])?)+$/;

export const isValid = (email: string | null | undefined) => {
  if (email === null || !StringValidation.isString(email)) return false;

  return EMAIL_REGEX.test(email);
};

export default {
  isValid,
};
