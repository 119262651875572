import React from 'react';
import type { JsonStructure, JsonStructureItem } from 'react-cmdk';
import { navigate } from '@gatsbyjs/reach-router';
import { AuthContext } from '~/Root/Auth';
import { useContext } from 'react';
import Icon from '~/components/atom/Icon';
import { useTheme } from 'styled-components';

const useNavigationItems = (): JsonStructure[number] => {
  const { isDHAdmin } = useContext(AuthContext);
  const theme = useTheme();
  const iconColor = theme.color('secondary');

  const items: Array<JsonStructureItem | undefined> = [
    isDHAdmin
      ? {
          id: 'admin-accounts',
          children: '[Admin] Accounts',
          icon: () => <Icon name="users" color={iconColor} />,
          onClick: () => {
            void navigate('/-/accounts');
          },
          showType: false,
          keywords: ['admin', 'accounts'],
        }
      : undefined,
    {
      id: 'dashboard',
      children: 'Dashboard',
      icon: () => <Icon name="desktop" color={iconColor} />,
      onClick: () => {
        void navigate('/-/');
      },
      showType: false,
      keywords: ['home', 'start', 'dashboard'],
    },
    {
      id: 'apps',
      children: 'Apps',
      icon: () => <Icon name="copy" color={iconColor} />,
      onClick: () => {
        void navigate('/-/apps');
      },
      showType: false,
    },
  ];

  return {
    heading: 'Navigeren',
    id: 'navigate',
    items: items.filter((x: any): x is JsonStructureItem => x !== undefined),
  };
};

export default useNavigationItems;
