import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const Users: React.FCC<Props> = ({
  color = 'currentColor',
  secondaryColor,
  style,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width={style?.width ?? '100%'}
      height={style?.height ?? '100%'}
      viewBox="0 0 76 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1001 57.3V51.7001C40.1001 46.8001 30.5001 42.8 30.5001 42.8L25.9001 46.6C23.5001 48.6 21.4001 48.7 18.7001 46.6L14.1001 42.8C14.1001 42.8 5.6001 46.8001 5.6001 51.7001V57.3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5001 54.8V51.7001C67.5001 46.8001 63.5001 42.8 58.6001 42.8H56.7001L53.5001 45.6C50.3001 48.8 46.3001 48.7001 43.2001 45.4001C43.2001 45.4001 41.1001 43.5 39.2001 43.5C37.3001 43.5 35.1001 45.3 35.1001 45.3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 40.9C5.5 40.9 10.4 39.9 10.9 29C11.5 16.1 17.5 14.7 22.1 14.7C26.5 14.7 30.2 18.8 30.8 24.2C30.8 24.2 24.5 25.8 22.2 19.6C22.2 19.6 21.7 24.9 15.2 24.2"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9 27.8C29.4 36.4 25.1 38.7001 21.9 38.7001C18.1 38.7001 14.8 33.6 14.8 28.8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.8999 27.8C29.8999 27.8 30.3999 31.9001 31.9999 34.7001C33.6999 37.7001 36.0999 38.8 36.0999 38.8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.6 21.9C56.3 23.3 56.7001 25 56.7001 27C56.7001 33 53 38.9 48.5 38.9C44 38.9 40.3 32.9 40.3 27C40.3 21.1 43.6 16.7 48.6 17.7C52 18.3 54.2 17.7 55.5 16.7C56.4 15.9 56.8 19.8 55 21.3C50.8 24.8 45.4001 22 45.4001 22"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {secondaryColor && (
        <>
          <circle cx="63.5" cy="18" r="12" fill={secondaryColor} />
          <path
            d="M63.5058 23.168C65.9698 23.168 67.7058 21.11 67.7058 18.184C67.7058 15.272 65.9698 13.214 63.5058 13.214C61.0278 13.214 59.2778 15.272 59.2778 18.184C59.2778 21.11 61.0278 23.168 63.5058 23.168ZM63.5058 21.572C62.0778 21.572 61.0698 20.172 61.0698 18.184C61.0698 16.196 62.0778 14.81 63.5058 14.81C64.9198 14.81 65.9278 16.196 65.9278 18.184C65.9278 20.172 64.9198 21.572 63.5058 21.572Z"
            fill="white"
          />
        </>
      )}
    </svg>
  </Container>
);
const Container = styled.div<{}>``;

export default Users;
