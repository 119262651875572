import { useLocation } from '@gatsbyjs/reach-router';
import { useLayoutEffect } from 'react';
import type { SignupUtmInput } from '~/graphql/types';
import {
  UTM_STORAGE_PREFIX,
  getLocalStorageItem,
  setLocalStorageItem,
} from '~/util/localStorageKeys';
import {
  parameterKeys,
  type StoredUTMParam,
} from '../../util/getStoredUTMParams';
import { isAfter, startOfDate } from '~/util/date';
import { addWeeks } from 'date-fns';
import { isNil } from 'ramda';

const useStoreUtmParams = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);
    const utm = parameterKeys.reduce((acc, key) => {
      acc[key] = params.get(`utm_${key}`);
      return acc;
    }, {} as SignupUtmInput);

    for (const key of parameterKeys) {
      const value = utm[key];
      const storageKey = `${UTM_STORAGE_PREFIX}${key}`;
      const previouslyStoredItem = getLocalStorageItem(storageKey);
      const parsedPreviouslyStoredItem: StoredUTMParam | null =
        previouslyStoredItem ? JSON.parse(previouslyStoredItem) : null;
      const allowedToWriteValue: boolean = isNil(parsedPreviouslyStoredItem)
        ? true
        : isAfter(
            new Date(),
            new Date(parsedPreviouslyStoredItem.expirationDate),
          );

      // As discussed only the first entry counts
      // so if we have previously stored UTM params
      // we don't store new ones but when that value is outdated we overwrite it
      if (value && allowedToWriteValue) {
        const expirationDate = startOfDate(addWeeks(new Date(), 3));

        // Only needed for typescript, since we pass in the date it'll never be null
        if (expirationDate) {
          const item: StoredUTMParam = { value, expirationDate };
          setLocalStorageItem(storageKey, JSON.stringify(item));
        }
      }
    }
  }, [location.search]);
};

export default useStoreUtmParams;
