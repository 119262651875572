import React from 'react';

import { type AppStatus__Input } from '~/graphql/types';
import { AppsHashMap } from '~/hooks/useApps';
import { AppStatus__typename } from '~/graphql/types.client';
import AppModal from './components/AppModal';

export type Props = {
  apps: AppsHashMap;
  updateAppStatus: (args: {
    type: AppStatus__typename;
    update: AppStatus__Input;
  }) => Promise<void>;
  onClose: () => void;
  refetch: () => Promise<any>;
};

const AppModals: React.FCC<Props> = ({
  updateAppStatus,
  apps,
  onClose,
  refetch,
}) => (
  <>
    {Object.keys(apps).map(appKey => (
      <AppModal
        key={appKey}
        app={apps[appKey]}
        updateAppStatus={updateAppStatus}
        onClose={onClose}
        path={`setup/${apps[appKey].slug}`}
        refetch={refetch}
      />
    ))}
  </>
);

export default AppModals;
