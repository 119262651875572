import type { ContactLeadscoreFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Contact_Details__Input } from '~/graphql/types';

import asNumberFilterInput from '../../Primitive/Number/asNumberFilterInput';

const asLeadscoreFilterInput = (
  filter: ContactLeadscoreFilter,
): Node_NodeContainer_Leaf_Contact_Details__Input => {
  if (typeof filter.assignedFilter.value === 'string') {
    filter.assignedFilter.value = parseInt(filter.assignedFilter.value);
  }

  return {
    leadScore: asNumberFilterInput(filter.assignedFilter),
  };
};

export default asLeadscoreFilterInput;
