import {} from 'react';
import {
  UTM_STORAGE_PREFIX,
  getLocalStorageItem,
} from '~/util/localStorageKeys';
import type { SignupUtmInput } from '~/graphql/types';
import { isBefore } from '../date';

export const parameterKeys: Array<keyof SignupUtmInput> = [
  'campaign',
  'medium',
  'source',
];

export type StoredUTMParam = { value: string; expirationDate: string };

const getStoredUTMParams = () => {
  const utm = parameterKeys.reduce((acc, key) => {
    const stored = getLocalStorageItem(`${UTM_STORAGE_PREFIX}${key}`);
    const parsed: StoredUTMParam | null = stored ? JSON.parse(stored) : null;

    // Values are only valid for a set amount of time.
    // we store the expirationDate with the value so we can check if the value is still valid
    // if the value has expired we don't set it on the return type
    if (parsed && isBefore(new Date(), new Date(parsed.expirationDate))) {
      acc[key] = parsed.value;
    }

    return acc;
  }, {} as SignupUtmInput);

  return utm;
};

export default getStoredUTMParams;
