import React from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import {
  AppValuationReportUserDataPage_Update,
  AppValuationReport_Update,
} from '~/graphql/types';
import TextAndColorInput from '../../components/TextAndColorInput';
import TextAreaWithLabel from '../../components/TextAreaWithLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  title: 'Contactgegevens',
  mainText: 'Koptekst',
  subText: 'Subtekst',
  formSubtext: 'Formulier subtekst',
  formSubTextColor: 'Kleur formulier subtekst',
};

export type Props = RouteComponentProps & {
  dataTestId?: string;
  loading: boolean;
  updated?: AppValuationReportUserDataPage_Update | null;
  onUpdate: (value: AppValuationReportUserDataPage_Update) => void;
};

type Update = keyof AppValuationReport_Update['userData'];

const RequestUserDetails: React.FCC<Props> = ({
  updated,
  loading,
  onUpdate,
}) => {
  if (!updated) return null;

  const { mainHeader, subHeader, formSubtext } = updated;

  return (
    <AppDetailsContainer
      header={text.title}
      icon="file-text"
      loading={loading}
      dataTestId="requestUserDetails-page"
    >
      <JustificationContainer direction="column" gap="base">
        <TextAreaWithLabel
          value={mainHeader?.text}
          label={text.mainText}
          onUpdate={value => onUpdate({ mainHeader: { text: value } })}
          dataTestId="text-edit-mainHeader-text"
        />
        <TextAreaWithLabel
          value={subHeader?.text}
          label={text.subText}
          onUpdate={value => onUpdate({ subHeader: { text: value } })}
          dataTestId="text-edit-subHeader-text"
        />
        <TextAndColorInput
          text={{
            label: text.formSubtext,
            value: formSubtext?.text,
            testId: 'text-edit-formSubtext-text',
          }}
          color={{
            label: text.formSubTextColor,
            value: formSubtext?.color,
            testId: 'picker_formSubtext-color',
          }}
          onUpdate={(value: Update) => onUpdate({ formSubtext: value })}
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default RequestUserDetails;
