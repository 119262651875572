/**
 * Removes all whitespace, new lines, tabs etc. between html tags
 * @param {string} str - html string
 * keywords: trim html, remove whitespace
 */
const removeWhiteSpaceInHtml = (str: string): string =>
  /**
   * Regex from https://jaketrent.com/post/remove-whitespace-html-javascript
   *  It does not remove the whitespace around the text if it is between
   * two opening tags e.g. '<div>   Text <div>Other text</div></div>'
   */
  str
    // remove newline / carriage return
    .replace(/\n/g, '')
    // remove whitespace (space and tabs) before tags
    .replace(/[\t ]+\</g, '<')
    // remove whitespace between tags
    .replace(/\>[\t ]+\</g, '><')
    // remove whitespace after tags
    .replace(/\>[\t ]+$/g, '>')
    // remove all spaces between closing tags and opening tags
    .replace(/\>[\n\t\s]*\</g, '><');

export default removeWhiteSpaceInHtml;
