import React, { useState } from 'react';
import { isEmpty } from 'ramda';

import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import CopyCode from '~/components/atom/CopyCode';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading2, Label, Variant } from '~/components/atom/Typography';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import CodeEditor from '~/components/organism/CodeEditor';
import SelectableRow from '~/components/organism/ToggleAccordion/components/SelectableRow';
import Input from '~/components/molecule/Input';
import Button from '~/components/atom/Button';
import { groups, paths } from './constants';
import Dropdown from '~/components/molecule/Dropdown';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';

const valueToOutput = (
  value: string,
  path: string,
  filter?: Record<string, boolean>,
): string => {
  const lines = value.split('\n');
  const filterLines = filter
    ? Object.keys(filter).filter(id => filter[id])
    : [];

  if (filterLines.length !== 0) {
    const dhuwParams = {
      filter: filterLines,
      appParams: lines.join('&'),
    };
    return `?dhuwParams=${encodeURIComponent(JSON.stringify(dhuwParams))}`;
  }

  const dhuwParams = {
    path,
    appParams: lines.join('&'),
  };

  return `?dhuwParams=${encodeURIComponent(JSON.stringify(dhuwParams))}`;
};

type Props = RouteComponentProps;

const text = {
  title: 'Links maken',
  body: 'Op deze pagina kun je deep links maken om waardes in apps in te vullen. Graag hulp hierbij? Neem contact op met ons support team!',
  codeEditorLabel: 'Parameters (gescheiden door nieuwe regels)',
  groupsLabel: 'Groepen (wanneer filter gekozen is zal path genegeerd worden)',
};

const URLBuilder: React.FC<Props> = () => {
  const [useDropdown, setUseDropdown] = useState<boolean>(true);
  const [path, setPath] = useState('/apps/kidwk');
  const [selectedDropdownOption, setSelectedDropdownOption] =
    useState<number>(0);
  const [filter, setFilter] = useState(
    groups.reduce(
      (acc, curr) => {
        acc[curr.id] = false;

        return acc;
      },
      {} as Record<string, boolean>,
    ),
  );
  const [value, setValue] = useState('');
  const [output, setOutput] = useState('');

  const disablePathInput =
    Object.keys(filter).filter(id => filter[id]).length !== 0;

  return (
    <AppDetailsContainer header={text.title} icon="link" loading={false}>
      <Body>{text.body}</Body>
      <br />
      <JustificationContainer gap="m" width="100%">
        <JustificationContainer
          direction="column"
          width="100%"
          padding={['base']}
          border={{ radius: 'base' }}
          backgroundColor={{ group: 'tertiary', variant: 'light' }}
        >
          <Heading2>Input</Heading2>
          <div style={{ width: '100%' }}>
            <JustificationContainer align="center" gap="xs" width="100%">
              <label>Choose from prebuild values</label>
              <ToggleCheckbox
                size="small"
                value={useDropdown}
                onChange={() => setUseDropdown(prev => !prev)}
              />
            </JustificationContainer>
            &nbsp;
            {useDropdown ? (
              <Dropdown
                label="Path"
                options={paths.map(path => ({
                  key: path,
                  label: path,
                  payload: path,
                }))}
                selectedOptionIdx={selectedDropdownOption}
                disabled={disablePathInput}
                onChange={({ selectedOptionIdx }) => {
                  setSelectedDropdownOption(selectedOptionIdx);
                }}
              />
            ) : (
              <Input
                label={{ text: 'Path' }}
                type="text"
                disabled={disablePathInput}
                onChange={e => setPath(e.target.value)}
                value={path}
              />
            )}
            <Body margin={['base', null]} fontWeight="semiBold">
              OR
            </Body>
            <Label size="base" variant={Variant.primary}>
              {text.groupsLabel}
            </Label>
            <div>
              {groups.map(({ name, id }) => (
                <SelectableRow
                  key={id}
                  disabled={false}
                  onChange={value => {
                    setFilter(prev => ({ ...prev, [id]: value }));
                  }}
                  checkboxTitle="Include"
                  row={{
                    id,
                    name,
                    checked: filter[id] ?? false,
                  }}
                  selectable={false}
                />
              ))}
            </div>
            <br />
            <Label size="base" variant={Variant.primary}>
              {text.codeEditorLabel}
            </Label>
            <CodeEditor
              onChange={value => {
                setValue(value);
              }}
              value={value}
              height="200px"
              placeholder={`Example:
purchase_price=250000
zip_code=35235
                `}
            />
          </div>
        </JustificationContainer>
        <JustificationContainer
          direction="column"
          width="100%"
          style={{ maxWidth: '480px' }}
          backgroundColor={{ group: 'tertiary', variant: 'light' }}
          padding={['base']}
          border={{ radius: 'base' }}
        >
          <Heading2>Output</Heading2>
          <CopyCode
            style={{ width: '100%' }}
            code={
              isEmpty(output)
                ? 'No output yet, select some options and hit the button'
                : output
            }
            label="URL parameters"
          />
          <Button
            onClick={() =>
              setOutput(
                valueToOutput(
                  value,
                  useDropdown ? paths[selectedDropdownOption] : path,
                  filter,
                ),
              )
            }
            label="Turn into query params"
          />
        </JustificationContainer>
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default URLBuilder;
