import React, { useState, useEffect } from 'react';

type Props = {};

export type WithDelayedLoading = {
  loading?: boolean;
  removeLoading: () => void;
  setLoading: () => void;
};

const withDelayedLoading =
  <ComponentProps extends Props>(
    WrappedComponent: React.ComponentType<ComponentProps>,
  ): React.FC<ComponentProps> =>
  (props: ComponentProps) => {
    const [loading, setLoadingState] = useState(false);
    let buttonTimer: ReturnType<typeof setTimeout> | null = null;

    const setLoading = () => {
      buttonTimer = setTimeout(() => {
        setLoadingState(true);
      }, 200);
    };

    const removeLoading = () => {
      setLoadingState(false);
      if (buttonTimer) clearTimeout(buttonTimer);
    };

    useEffect(
      () => () => {
        if (buttonTimer) clearTimeout(buttonTimer);
      },
      [buttonTimer],
    );

    return (
      <WrappedComponent
        {...props}
        loading={loading}
        setLoading={setLoading}
        removeLoading={removeLoading}
      />
    );
  };

export default withDelayedLoading;
