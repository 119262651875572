import { useState, useRef, useEffect } from 'react';

type ReturnProps = [boolean, () => void, () => void];

const useDelayedLoading = (): ReturnProps => {
  const [loading, setLoading] = useState(false);
  const loadingTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const startLoading = () => {
    loadingTimer.current = setTimeout(() => setLoading(true), 300);
  };

  const stopLoading = () => {
    setLoading(false);

    if (loadingTimer.current) clearTimeout(loadingTimer.current);
  };

  useEffect(() => () => {
    if (loadingTimer.current) clearTimeout(loadingTimer.current);
  });

  return [loading, startLoading, stopLoading];
};

export default useDelayedLoading;
