import React from 'react';
import styled, { css } from 'styled-components';
import CodeEditor from '~/components/organism/CodeEditor';

import useConstructAndNotifyErrors from '~/hooks/useConstructAndNotifyErrors';

type ValidationFunction = (value: string | null) => string | true;
type TransformationFunction = (
  value: string | null,
  previousValue: string | null,
) => string | null;

type OnChangeCallback = (
  value: string | null,
  extra: { errors: Array<string>; hasErrors: boolean },
) => void;

export type Props = {
  id: string;
  onError?: (error?: string) => void;
  onChange?: OnChangeCallback;

  /**
   * Editor props
   */
  value: string;
  width?: string | undefined;
  height?: string | undefined;

  /**
   * Array of transformation functions applied after
   * an input change. The onChange function
   * will receive the transformed input.
   */
  transform?: Array<TransformationFunction>;
  /**
   * Array of validation functions, errors produced will be shown
   * accordingly.
   */
  validation?: Array<ValidationFunction>;
  /**
   * Extra errors.
   */
  errors?: Array<string>;
};

const ControlledHTMLInput: React.FCC<Props> = ({
  dataTestId,
  onChange: originalOnChange,
  onError,
  id: givenId,
  errors = [],
  validation = [],
  transform = [],
  value,
  ...rest
}) => {
  const { constructAndNotifyErrors, error, id } = useConstructAndNotifyErrors({
    id: givenId,
    errors,
    validation,
    value,
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 100%',
      }}
    >
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <CodeEditor
        id={id}
        data-testid={dataTestId}
        value={value}
        onChange={nextValue => {
          const transformedValue = transform.reduce(
            (result, transformFunction) =>
              transformFunction(result, value ?? null),
            nextValue,
          );

          if (transformedValue === value) return;

          const { errors, error, errorsChanged } =
            constructAndNotifyErrors(transformedValue);

          if (onError && errorsChanged) onError(error);

          if (originalOnChange) {
            originalOnChange(transformedValue, {
              errors,
              hasErrors: errors.length !== 0,
            });
          }
        }}
        {...rest}
      />
    </div>
  );
};

const ErrorMessage = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);

export default ControlledHTMLInput;
