import {
  GetAppStatusRealworksQuery,
  RealworksTokenStatus,
} from '~/graphql/types';
import type { Option } from '~/components/molecule/Dropdown';

const getActiveTokenOptions = (
  realworksStatus: NonNullable<
    GetAppStatusRealworksQuery['getAppStatusRealworks']
  >,
) => {
  const tokenOptions: Array<Option> = [
    { key: 'Closest', label: 'Eigenaar van het contact', payload: undefined },
  ];
  if (!realworksStatus?.enabled) return tokenOptions;
  const activeTokens = realworksStatus.tokens.filter(
    token => token.wonen?.status === RealworksTokenStatus.Active,
  );

  return [
    ...tokenOptions,
    ...activeTokens.map(activeToken => ({
      key: activeToken.id,
      label: activeToken.name,
      payload: activeToken.id,
    })),
  ];
};

export default getActiveTokenOptions;
