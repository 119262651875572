import type {
  FlowData___InstanceConditionFragment,
  FlowData___InstanceField,
} from '~/graphql/types';
import { cloneDeep } from 'lodash';
import filterFields from '../filterFields';
import getConditions from '../../getConditions';
import getSubject from '../../getSubject';
import hydrateField, { HydratedField } from '../../hydrateField';
import { GetFieldsByPathOpts } from '..';

type Result = Array<FlowData___InstanceConditionFragment | HydratedField>;
const generateNextForInstanceField = (
  field: HydratedField<FlowData___InstanceField>,
  opts: GetFieldsByPathOpts & {
    usedSubjectMap: Record<string, number | undefined>;
  },
): { error: string } | { error?: undefined; result: Result } => {
  const { subjectMap, subjectToConditionMap, conditionType } = opts;

  const subject = getSubject(field.parent, subjectMap);
  if (!subject) {
    return {
      error: `Missing ${field.parent.type} - ${field.parent.typeId}`,
    };
  }

  const found = subject.instanceFields.find(({ key }) => key === field.key);
  if (!found) {
    return {
      error: `Missing Field ${field.key}`,
    };
  }

  const fieldSubject = getSubject(field.type, subjectMap);

  if (!fieldSubject) {
    return { error: `Missing ${field.type.type} - ${field.type.typeId}` };
  }

  const result: Result = [];

  result.push(
    ...getConditions(
      {
        type: fieldSubject.type,
        typeId: fieldSubject.typeId,
      },
      subjectToConditionMap,
    ),
  );

  if (conditionType === 'trigger' && field.subInstanceFieldsTrigger !== true) {
    return { result: cloneDeep(filterFields(result, opts)) };
  }

  for (const field of fieldSubject.instanceFields) {
    result.push(hydrateField(field, fieldSubject));
  }

  return { result: cloneDeep(filterFields(result, opts)) };
};

export default generateNextForInstanceField;
