import type { ContactLoggedDateCallEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asDateFilterInput from '../../Primitive/Date/asDateFilterInput';

const asLoggedDateCallEventFilterInput = (
  filter: ContactLoggedDateCallEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => {
  if (typeof filter.loggedDate.date === 'string') {
    filter.loggedDate.date = new Date(filter.loggedDate.date);
  }

  return {
    Call: {
      loggedDate: asDateFilterInput(filter.loggedDate),
    },
  };
};

export default asLoggedDateCallEventFilterInput;
