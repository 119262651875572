import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React, { useEffect } from 'react';
import {
  AppBbWaardecheck__Input,
  AppVboWaardecheck__Input,
  GetAppBbWaardecheckRouteAvailabilityQuery,
  GetAppBbWaardecheckRouteAvailabilityQueryVariables,
  GetAppVboWaardecheckRouteAvailabilityQuery,
  GetAppVboWaardecheckRouteAvailabilityQueryVariables,
} from '~/graphql/types';
import Input from '~/components/page/Apps/components/Input';
import InputGroup from '~/components/page/Apps/components/InputGroup';
import InputGroupDivider from '~/components/page/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import { AppWaardecheckFieldsFragment, StateId } from '../..';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import ControlledInput from '~/components/page/Apps/components/ControlledInput';
import useDebounce from '~/hooks/useDebounce';
import TEST_ID from './index.testid';
import { isEmpty } from '~/util/Validation/String';
import ControlledHTMLInput from '~/components/page/Apps/components/ControlledHTMLInput';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import HrefInput from '../../components/HrefInput';
import MainPreview from '../../components/MainPreview';
import { Page } from '../../components/Preview';
import { appWaardecheckState } from '../../state';
import { URL_STATUS_INDICATORS } from '~/styles/indicators';

export type Props = RouteComponentProps & {
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
  routeAvailability: {
    data?:
      | GetAppBbWaardecheckRouteAvailabilityQuery
      | GetAppVboWaardecheckRouteAvailabilityQuery;
    variables?:
      | GetAppBbWaardecheckRouteAvailabilityQueryVariables
      | GetAppVboWaardecheckRouteAvailabilityQueryVariables;
    loading: boolean;
  };
  getRouteAvailability: (route: string) => void;
};

const text = {
  header: 'Algemeen',
  error: {
    routeUnavailable: 'Deze route is niet beschikbaar',
  },
  statuses: {
    checking: 'Beschikbaarheid controleren',
    available: 'Beschikbaar',
    taken: 'Niet beschikbaar',
  },
  privacyStatement: {
    title: 'Privacy statement',
    description:
      'Een verwijzing naar de privacyverklaring wordt toegevoegd bij de stap “Contactgegevens”.',
  },
  cookieStatement: {
    title: 'Cookie statement',
    description:
      'Een verwijzing naar de cookieverklaring wordt toegevoegd in de cookienotificatie.',
  },
  route: {
    label: 'Gewenste adres',
    reset: 'Bekijk live',
    validationError:
      'Vul een waarde in. Alleen letters, cijfers en het streepje(-) zijn toegestaan',
  },
  tracking: {
    header: 'Tracking',
    description: (
      <>
        Het gedrag van bezoekers wordt bijgehouden en kan worden gebruikt voor
        het bijhouden van de conversie of het optimaliseren van
        advertentiecampagnes. In de kennisbank vind je de gebruikshandleiding
        voor{' '}
        <a
          href="https://help.dathuis.nl/nl/articles/8077988-tracking-van-de-waardecheck-powered-by-brainbay-op-mijnwaardecheck-nl-met-google-analytics-4"
          target="_blank"
          rel="noreferrer"
        >
          Google
        </a>{' '}
        en{' '}
        <a
          href="https://help.dathuis.nl/nl/articles/5067270-tracking-via-de-facebook-pixel"
          target="_blank"
          rel="noreferrer"
        >
          Facebook
        </a>
        .
      </>
    ),
    gaLabel: 'Google Analytics (verouderd)',
    ga4Label: 'Google Analytics 4',
    fbLabel: 'Facebook Pixel',
  },
  customHtml: {
    header: 'Custom HTML',
    description: (
      <>
        ⚠️ Wanneer je de keuze maakt om eigen HTML toe te voegen aan de app
        kunnen wij niet meer de kwaliteit van de applicatie garanderen. Gebruik
        deze functionaliteit alleen als je begrijpt wat je doet! Wanneer je
        eigen code toevoegt die tracking scripts bevat, zullen deze niet worden
        vastgelegd door de cookie banner.
      </>
    ),
    validationError:
      'De HTML heeft te veel characters, wij staan maar 20.000 characters toe.',
  },
};

const HTML_CHAR_LIMIT = 20000;

type InputType =
  | AppBbWaardecheck__Input['general']
  | AppVboWaardecheck__Input['general'];
const Settings: React.FCC<Props> = ({
  data,
  loading,
  routeAvailability,
  getRouteAvailability,
}) => {
  const { id: accountId } = useCurrentAccount();
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  /** Update the form */
  const updateValue = (value: DeepPartial<InputType>) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        general: value,
      }) as any as typeof prev;
    });
  };

  /** Check the route availability */
  const debouncedRoute = useDebounce(updated?.route, 1000);

  const {
    loading: routeLoading,
    data: routeData,
    variables: routeVariables,
  } = routeAvailability;

  useEffect(() => {
    if (debouncedRoute == null || isEmpty(debouncedRoute)) return;

    getRouteAvailability(debouncedRoute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, debouncedRoute]);

  if (updated == null) return null;
  const { general } = updated;

  /**
   * Extra errors
   */
  const errors: Array<string> = [];

  /**
   * Show the appropriate label if the route is currently changing.
   */
  let statusIndex: number | undefined = undefined;
  if (updated.route !== data.route) {
    const isCheckingRoute = debouncedRoute !== updated.route || routeLoading;

    statusIndex = 0;

    if (
      !isCheckingRoute &&
      routeData != null &&
      routeVariables?.route === debouncedRoute
    ) {
      statusIndex = routeData.isAvailable ? 1 : 2;
      if (statusIndex === 2) errors.push(text.error.routeUnavailable);
    }
  }

  return (
    <AppDetailsContainer header={text.header} icon="gear" loading={loading}>
      <InputGroup>
        <ControlledInput
          id="route"
          statuses={
            !isEmpty(updated?.route) ? URL_STATUS_INDICATORS : undefined
          }
          statusIndex={statusIndex}
          prefix="https://mijnwaardecheck.nl/"
          label={text.route.label}
          reset={text.route.reset}
          onReset={() => {
            window.open(
              `https://mijnwaardecheck.nl/${data.route}`,
              '_blank',
              'noopener,noreferrer',
            );
          }}
          errors={errors}
          transform={[
            value => value?.toLowerCase() ?? null,
            (val, prev) => {
              if (!/^([a-z0-9]|-)+$/.test(val ?? '') && val != null) {
                return prev;
              }

              return val;
            },
          ]}
          value={updated.route}
          validation={[
            (value: string) => {
              if (/^([a-z0-9]|-)+$/.test(value ?? '')) return true;

              return text.route.validationError;
            },
          ]}
          onChange={async route => {
            setUpdatedValue(prev => {
              if (!prev) return prev;

              return {
                ...prev,
                route: route || '',
              };
            });
          }}
          dataTestId={TEST_ID.ROUTE}
        />
      </InputGroup>
      <MainPreview data={updated} page={Page['/']} />

      <InputGroupDivider />
      <InputGroup>
        <HrefInput
          id="general.privacyStatement"
          title={text.privacyStatement.title}
          description={text.privacyStatement.description}
          value={general.privacyStatement}
          onChange={privacyStatement => {
            updateValue({ privacyStatement });
          }}
          dataTestId={TEST_ID.PRIVACY_STATEMENT}
        />
      </InputGroup>
      <InputGroupDivider />
      <InputGroup>
        <HrefInput
          id="general.cookieStatement"
          title={text.cookieStatement.title}
          description={text.cookieStatement.description}
          value={general.cookieStatement}
          onChange={cookieStatement => {
            updateValue({ cookieStatement });
          }}
          dataTestId={TEST_ID.COOKIE_STATEMENT}
        />
      </InputGroup>
      <InputGroupDivider />
      <AppEntryItemHeader>{text.tracking.header}</AppEntryItemHeader>
      <Description>{text.tracking.description}</Description>
      <InputGroup>
        <Input
          disabled
          label={text.tracking.gaLabel}
          value={general.googleAnalytics}
          placeholder="GA-..."
          onChange={googleAnalytics => {
            updateValue({ googleAnalytics });
          }}
          dataTestId={TEST_ID.GOOGLE_ANALYTICS}
        />
        <Input
          label={text.tracking.ga4Label}
          value={general.googleAnalytics4}
          placeholder="G-..."
          onChange={googleAnalytics4 => {
            updateValue({ googleAnalytics4 });
          }}
          dataTestId={TEST_ID.GOOGLE_ANALYTICS_4}
        />
        <Input
          label={text.tracking.fbLabel}
          placeholder="1234..."
          value={general.facebookPixel}
          onChange={facebookPixel => {
            updateValue({
              facebookPixel,
            });
          }}
          dataTestId={TEST_ID.FACEBOOK_PIXEL}
        />
      </InputGroup>
      <InputGroupDivider />
      <AppEntryItemHeader>{text.customHtml.header}</AppEntryItemHeader>

      <Description>{text.customHtml.description}</Description>
      <InputGroup>
        <ControlledHTMLInput
          id="general.html"
          dataTestId={TEST_ID.CUSTOM_HTML}
          errors={errors}
          onChange={value => {
            // We're not allowed to send empty string values for HTML so when the value is an empty string we will set `null`
            const html = isEmpty(value) ? null : value;
            updateValue({ html });
          }}
          value={general.html ?? ''}
          height="200px"
          validation={[
            value =>
              value === null || value.length <= HTML_CHAR_LIMIT
                ? true
                : text.customHtml.validationError,
          ]}
        />
      </InputGroup>
    </AppDetailsContainer>
  );
};

export default Settings;
