import React from 'react';
import styled, { css } from 'styled-components';
import HighlightedLabel from '~/components/atom/HighlightedLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';
import ProgressiveImgIx from '~/components/atom/ProgressiveImgIx';
import { Heading3 } from '~/components/atom/Typography';

export type Props = {
  name: string;
  logo: {
    src: string;
  };
  tags?: Array<string>;
};

const LOGO_SIZE = 80;
const HeaderContainer: React.FCC<Props> = ({
  dataTestId,
  name,
  logo,
  tags,
}) => {
  const hasTags = tags && tags.length > 0;

  return (
    <Container>
      <JustificationContainer data-testid={dataTestId} align="center">
        <LogoContainer align="center" justification="center">
          <ProgressiveImgIx src={logo.src} width={LOGO_SIZE} alt="App logo" />
        </LogoContainer>

        <Heading3 color={{ group: 'primary' }} margin={[null]}>
          {name}
        </Heading3>
      </JustificationContainer>

      {hasTags && (
        <JustificationContainer margin={['base', null, null, null]} gap="xxs">
          {tags?.map(tag => (
            <HighlightedLabel key={tag}>{tag}</HighlightedLabel>
          ))}
        </JustificationContainer>
      )}
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('l')};
  `,
);

const LogoContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    padding: ${theme.space('xxs')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('tertiary', 'light')};
    border-radius: ${theme.getTokens().border.radius.s};
    margin-right: ${theme.space('m')};
    width: ${LOGO_SIZE}px;
    height: ${LOGO_SIZE}px;
  `,
);

export default HeaderContainer;
