import { useEffect } from 'react';
import { useKeyPress } from 'reactflow';
import { useRecoilState } from 'recoil';
import { copyScreenNodeState } from '../../state/copyScreenNode';
import { isNil } from 'ramda';

const useBuilderBindings = () => {
  const [copyState, setCopyScreenNodeState] =
    useRecoilState(copyScreenNodeState);

  const escKeyPressed = useKeyPress('Escape');

  useEffect(() => {
    if (escKeyPressed) {
      if (!isNil(copyState)) setCopyScreenNodeState(null);
    }
  }, [copyState, escKeyPressed, setCopyScreenNodeState]);
};

export default useBuilderBindings;
