import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import AppErrorScreen from '~/components/template/AppErrorScreen';

const withErrorBoundary = <ComponentProps extends object>(
  WrappedComponent: React.ComponentType<ComponentProps>,
  Fallback?: any,
) => {
  const ComponentWithBoundary: React.FC<ComponentProps> = props => (
    <ErrorBoundary fallback={Fallback ? Fallback : <AppErrorScreen />}>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );

  return ComponentWithBoundary;
};

export default withErrorBoundary;
