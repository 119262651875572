import React from 'react';

import { ReactEditor } from 'slate-react';
import {
  DHEditor,
  LinkElement,
} from '~/components/organism/PluginsEditor/types';
import { Editor, Node, Path, Transforms } from 'slate';
import { insertLink } from '~/components/organism/PluginsEditor/commands/modify/link';
import {
  deleteNodeAtPath,
  isElementOfType,
} from '~/components/organism/PluginsEditor/commands';
import TEST_ID from './index.testid';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import Button from '~/components/atom/Button';
import EditUrlBlock from '~/components/organism/PluginsEditor/components/Buttons/InsertLink/components/EditUrlBlock';

export type Props = {
  element: LinkElement;
  editor: DHEditor;
  path: Path;
};

const LinkToolbar: React.FCC<Props> = ({ element, path, editor }) => {
  const urlText = Editor.string(editor, path, { voids: true });

  return (
    <EditUrlBlock
      onSave={({ url, text }) => {
        // We cannot edit the children of a node directly so we delete it and re-insert it
        deleteNodeAtPath(editor, path);
        insertLink(editor, url, text);

        ReactEditor.blur(editor);
      }}
      urlValue={element.url}
      textValue={urlText || ''}
      submitText="Link bijwerken"
    >
      <Button
        ghost
        icon="trashcan"
        appearance="danger"
        onClick={() => {
          const parentEl = Node.parent(editor, path);

          // If parent element is not a div, wrap link element with a div
          if (!isElementOfType(parentEl, ELEMENTS.DIV)) {
            Transforms.wrapNodes(
              editor,
              { type: ELEMENTS.DIV, children: [] },
              {
                at: path,
                match: n => isElementOfType(n, ELEMENTS.LINK),
              },
            );
          }

          // Unwrap link element so that we keep all the children and only remove the link element
          Transforms.unwrapNodes(editor, {
            at: path,
            match: n => isElementOfType(n, ELEMENTS.LINK),
          });
        }}
        dataTestId={TEST_ID.DELETE_BUTTON}
      />
    </EditUrlBlock>
  );
};

export default LinkToolbar;
