import { uniqBy, unnest } from 'ramda';
import { Element, Node, Text } from 'slate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { Attachment } from '~/graphql/types';

/**
 * Loops through all the nodes in the editor, finds dh-image elements and returns them as attachments
 * @param {Array<Node>} nodes - Slate nodes
 * keywords: imagesToAttachments
 */
const getImages = (nodes: Array<Node>): Array<Attachment> => {
  if (!nodes || nodes.length === 0) return [];

  const res = nodes.reduce((acc, node) => {
    if (Element.isElement(node) && node.type === ELEMENTS.DH_IMAGE) {
      const {
        s3key,
        contentLength,
        contentType,
        filename,
        inlineId,
        pending,
        hasError,
      } = node;

      if (
        !s3key ||
        !inlineId ||
        !filename ||
        !contentLength ||
        pending ||
        hasError ||
        !contentType?.startsWith('image') ||
        !node.url
      ) {
        return acc;
      }

      return [
        ...acc,
        {
          file: {
            __typename: 'DHFile',
            contentLength,
            contentType,
            filename,
            s3key,
          } as const,
          inlineId,
          __typename: 'Attachment',
          url: node.url,
        } as const,
      ];
    }

    /** Get images in nested elements */
    if (!Text.isText(node) && node.children) {
      const childNode = unnest(getImages(node.children));

      if (childNode.length > 0) return [...acc, ...childNode];
    }

    return acc;
  }, []);

  return res;
};
const getImagesAsAttachments = value =>
  uniqBy(item => item.inlineId, getImages(value));

export default getImagesAsAttachments;
