import React from 'react';
import { Task } from '~/components/page/Tasks/types';

export type TaskModalContextProps = {
  taskDetails: Task | null | undefined;
  isNew: boolean;
  updateTaskDetails: (taskDetails: $Object) => void;
  closeModal: () => void;
  accountId: string | null | undefined;
};

const TaskModalContext = React.createContext<TaskModalContextProps>({
  taskDetails: null,
  isNew: false,
  updateTaskDetails: () => {},
  closeModal: () => {},
  accountId: null,
});

export default TaskModalContext;
