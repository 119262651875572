import type {
  FILTER_CONTACT,
  FILTER_ASSIGNED_TO,
  FILTER_SOURCED_BY,
  FILTER_EVENT_VALUATION_REPORT,
  FILTER_ACTIVITY,
  FILTER_FUNDA,
  FILTER_BBWAARDECHECK,
  FILTER_ZAPIER,
  FILTER_VBOWAARDECHECK,
} from './types.flow';
import type { FILTER_CONTACT_ASSIGNED_TO_USER } from './AssignedToUser/types.flow';
import type { FILTER_CONTACT_ASSIGNED_TO_OFFICE } from './AssignedToOffice/types.flow';
import type { FILTER_CONTACT_CREATED } from './Created/types.flow';
import type { FILTER_CONTACT_LEAD_SCORE } from './Leadscore/types.flow';
import type {
  FILTER_CONTACT_SOURCE__APP_TYPE,
  FILTER_CONTACT_SOURCE__USER,
} from './ContactSource/types.flow';

import { Type } from './ContactSource/types.flow';
import type {
  FILTER_CONTACT_HAS_TAG,
  FILTER_CONTACT_DOES_NOT_HAVE_TAG,
} from './Tag/types.flow';
import type { FILTER_CONTACT_BLOCK_MARKETING } from './BlockMarketing/types.flow';
import type { FILTER_EMPTY } from './Empty/types.flow';
import type {
  FILTER_CONTACT_POSTCODE,
  FILTER_CONTACT_CITY,
} from './Address/types.flow';
import type { FILTER_VALUATION_REPORT } from './ValuationReport/types.flow';
import type { FILTER_VALUATION_REQUEST } from './ValuationRequest/types.flow';
import type { FILTER_CONTACT_HAS_CALL_EVENT } from './HasCallEvent/types.flow';
import type { FILTER_CONTACT_HAS_NOTE_EVENT } from './HasNoteEvent/types.flow';
import type { FILTER_CONTACT_LOGGED_DATE_CALL_EVENT } from './LoggedDateCallEvent/types.flow';
import type { FILTER_CONTACT_LOGGED_DATE_NOTE_EVENT } from './LoggedDateNoteEvent/types.flow';
import type { FILTER_CONTACT_HAS_FUNDA_BROCHURE_REQUEST_EVENT } from './HasFundaBrochureRequestEvent/types.flow';
import type { FILTER_CONTACT_HAS_FUNDA_VIEWING_REQUEST_EVENT } from './HasFundaViewingRequestEvent/types.flow';
import { FILTER_CONTACT_HAS_FUNDA_CONTACT_REQUEST_EVENT } from './HasFundaContactRequestEvent/types.flow';

import type { FILTER_CONTACT_CREATED_FUNDA_BROCHURE_REQUEST_EVENT } from './CreatedFundaBrochureRequestEvent/types.flow';
import type { FILTER_CONTACT_CREATED_FUNDA_VIEWING_REQUEST_EVENT } from './CreatedFundaViewingRequestEvent/types.flow';
import { FILTER_CONTACT_CREATED_FUNDA_CONTACT_REQUEST_EVENT } from './CreatedFundaContactRequestEvent/types.flow';
import { FILTER_CONTACT_HAS_BBWAARDECHECK_REPORT_EVENT } from './HasBBWaardecheckReportEvent/types.flow';
import { FILTER_CONTACT_HAS_BBWAARDECHECK_CONTACT_REQUEST_EVENT } from './HasBBWaardecheckContactRequestEvent/types.flow';
import { FILTER_CONTACT_HAS_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT } from './HasBBWaardecheckAppraisalRequestEvent/types.flow';
import { FILTER_CONTACT_CREATED_BBWAARDECHECK_REPORT_EVENT } from './CreatedBBWaardecheckReportEvent/types.flow';
import { FILTER_CONTACT_CREATED_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT } from './CreatedBBWaardecheckAppraisalRequestEvent/types.flow';
import { FILTER_CONTACT_CREATED_BBWAARDECHECK_CONTACT_REQUEST_EVENT } from './CreatedBBWaardecheckContactRequestEvent/types.flow';
import { FILTER_CONTACT_VALUATION_REPORT_CREATED_EVENT } from './CreatedValuationReportEvent/types.flow';
import { FILTER_CONTACT_VALUATION_REQUEST_CREATED_EVENT } from './CreatedValuationRequestEvent/types.flow';
import { FILTER_CONTACT_HAS_VBOWAARDECHECK_REPORT_EVENT } from './HasVBOWaardecheckReportEvent/types.flow';
import { FILTER_CONTACT_HAS_VBOWAARDECHECK_CONTACT_REQUEST_EVENT } from './HasVBOWaardecheckContactRequestEvent/types.flow';
import { FILTER_CONTACT_HAS_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT } from './HasVBOWaardecheckAppraisalRequestEvent/types.flow';
import { FILTER_CONTACT_CREATED_VBOWAARDECHECK_REPORT_EVENT } from './CreatedVBOWaardecheckReportEvent/types.flow';
import { FILTER_CONTACT_CREATED_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT } from './CreatedVBOWaardecheckAppraisalRequestEvent/types.flow';
import { FILTER_CONTACT_CREATED_VBOWAARDECHECK_CONTACT_REQUEST_EVENT } from './CreatedVBOWaardecheckContactRequestEvent/types.flow';

export const FILTER_TYPE: {
  CONTACT: FILTER_CONTACT;
  ASSIGNED_TO: FILTER_ASSIGNED_TO;
  SOURCED_BY: FILTER_SOURCED_BY;
  EVENT_VALUATION_REPORT: FILTER_EVENT_VALUATION_REPORT;
  ACTIVITY: FILTER_ACTIVITY;
  FUNDA: FILTER_FUNDA;
  BBWAARDECHECK: FILTER_BBWAARDECHECK;
  ZAPIER: FILTER_ZAPIER;
  VBOWAARDECHECK: FILTER_VBOWAARDECHECK;
} = {
  CONTACT: 'FILTER_CONTACT',
  ASSIGNED_TO: 'FILTER_ASSIGNED_TO',
  SOURCED_BY: 'FILTER_SOURCED_BY',
  EVENT_VALUATION_REPORT: 'FILTER_EVENT_VALUATION_REPORT',
  ACTIVITY: 'FILTER_ACTIVITY',
  FUNDA: 'FILTER_FUNDA',
  BBWAARDECHECK: 'FILTER_BBWAARDECHECK',
  ZAPIER: 'FILTER_ZAPIER',
  VBOWAARDECHECK: 'FILTER_VBOWAARDECHECK',
};

export const FILTER_SUB_TYPE: {
  ASSIGNED_TO_USER: FILTER_CONTACT_ASSIGNED_TO_USER;
  ASSIGNED_TO_OFFICE: FILTER_CONTACT_ASSIGNED_TO_OFFICE;
  SOURCED_BY_APP_TYPE: FILTER_CONTACT_SOURCE__APP_TYPE;
  SOURCED_BY_USER: FILTER_CONTACT_SOURCE__USER;
  CREATED: FILTER_CONTACT_CREATED;
  HAS_TAG: FILTER_CONTACT_HAS_TAG;
  DOES_NOT_HAVE_TAG: FILTER_CONTACT_DOES_NOT_HAVE_TAG;
  BLOCK_MARKETING: FILTER_CONTACT_BLOCK_MARKETING;
  EMPTY: FILTER_EMPTY;
  LEAD_SCORE: FILTER_CONTACT_LEAD_SCORE;
  POSTCODE: FILTER_CONTACT_POSTCODE;
  CITY: FILTER_CONTACT_CITY;
  VALUATION_REPORT: FILTER_VALUATION_REPORT;
  CREATED_VALUATION_REPORT_EVENT: FILTER_CONTACT_VALUATION_REPORT_CREATED_EVENT;
  CREATED_VALUATION_REQUEST_EVENT: FILTER_CONTACT_VALUATION_REQUEST_CREATED_EVENT;
  VALUATION_REQUEST: FILTER_VALUATION_REQUEST;
  HAS_CALL_EVENT: FILTER_CONTACT_HAS_CALL_EVENT;
  HAS_NOTE_EVENT: FILTER_CONTACT_HAS_NOTE_EVENT;
  LOGGED_DATE_CALL_EVENT: FILTER_CONTACT_LOGGED_DATE_CALL_EVENT;
  LOGGED_DATE_NOTE_EVENT: FILTER_CONTACT_LOGGED_DATE_NOTE_EVENT;
  HAS_FUNDA_BROCHURE_REQUEST_EVENT: FILTER_CONTACT_HAS_FUNDA_BROCHURE_REQUEST_EVENT;
  HAS_FUNDA_VIEWING_REQUEST_EVENT: FILTER_CONTACT_HAS_FUNDA_VIEWING_REQUEST_EVENT;
  HAS_FUNDA_CONTACT_REQUEST_EVENT: FILTER_CONTACT_HAS_FUNDA_CONTACT_REQUEST_EVENT;
  CREATED_FUNDA_BROCHURE_REQUEST_EVENT: FILTER_CONTACT_CREATED_FUNDA_BROCHURE_REQUEST_EVENT;
  CREATED_FUNDA_VIEWING_REQUEST_EVENT: FILTER_CONTACT_CREATED_FUNDA_VIEWING_REQUEST_EVENT;
  CREATED_FUNDA_CONTACT_REQUEST_EVENT: FILTER_CONTACT_CREATED_FUNDA_CONTACT_REQUEST_EVENT;
  HAS_BBWAARDECHECK_REPORT_EVENT: FILTER_CONTACT_HAS_BBWAARDECHECK_REPORT_EVENT;
  HAS_BBWAARDECHECK_CONTACT_REQUEST_EVENT: FILTER_CONTACT_HAS_BBWAARDECHECK_CONTACT_REQUEST_EVENT;
  HAS_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT: FILTER_CONTACT_HAS_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT;
  CREATED_BBWAARDECHECK_REPORT_EVENT: FILTER_CONTACT_CREATED_BBWAARDECHECK_REPORT_EVENT;
  CREATED_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT: FILTER_CONTACT_CREATED_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT;
  CREATED_BBWAARDECHECK_CONTACT_REQUEST_EVENT: FILTER_CONTACT_CREATED_BBWAARDECHECK_CONTACT_REQUEST_EVENT;
  ZAPIER_METADATA: 'ZAPIER_METADATA';
  HAS_VBOWAARDECHECK_REPORT_EVENT: FILTER_CONTACT_HAS_VBOWAARDECHECK_REPORT_EVENT;
  HAS_VBOWAARDECHECK_CONTACT_REQUEST_EVENT: FILTER_CONTACT_HAS_VBOWAARDECHECK_CONTACT_REQUEST_EVENT;
  HAS_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT: FILTER_CONTACT_HAS_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT;
  CREATED_VBOWAARDECHECK_REPORT_EVENT: FILTER_CONTACT_CREATED_VBOWAARDECHECK_REPORT_EVENT;
  CREATED_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT: FILTER_CONTACT_CREATED_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT;
  CREATED_VBOWAARDECHECK_CONTACT_REQUEST_EVENT: FILTER_CONTACT_CREATED_VBOWAARDECHECK_CONTACT_REQUEST_EVENT;
} = {
  ASSIGNED_TO_USER: 'FILTER_CONTACT_ASSIGNED_TO_USER',
  ASSIGNED_TO_OFFICE: 'FILTER_CONTACT_ASSIGNED_TO_OFFICE',
  SOURCED_BY_APP_TYPE: Type.FILTER_CONTACT_SOURCE__APP_TYPE,
  SOURCED_BY_USER: Type.FILTER_CONTACT_SOURCE__USER,
  CREATED: 'FILTER_CONTACT_CREATED',
  HAS_TAG: 'FILTER_CONTACT_HAS_TAG',
  DOES_NOT_HAVE_TAG: 'FILTER_CONTACT_DOES_NOT_HAVE_TAG',
  BLOCK_MARKETING: 'FILTER_CONTACT_BLOCK_MARKETING',
  EMPTY: 'FILTER_EMPTY',
  LEAD_SCORE: 'FILTER_CONTACT_LEAD_SCORE',
  POSTCODE: 'FILTER_CONTACT_POSTCODE',
  CITY: 'FILTER_CONTACT_CITY',
  VALUATION_REPORT: 'FILTER_VALUATION_REPORT',
  CREATED_VALUATION_REPORT_EVENT:
    'FILTER_CONTACT_VALUATION_REPORT_CREATED_EVENT',
  VALUATION_REQUEST: 'FILTER_VALUATION_REQUEST',
  CREATED_VALUATION_REQUEST_EVENT:
    'FILTER_CONTACT_VALUATION_REQUEST_CREATED_EVENT',
  HAS_CALL_EVENT: 'FILTER_CONTACT_HAS_CALL_EVENT',
  HAS_NOTE_EVENT: 'FILTER_CONTACT_HAS_NOTE_EVENT',
  LOGGED_DATE_CALL_EVENT: 'FILTER_CONTACT_LOGGED_DATE_CALL_EVENT',
  LOGGED_DATE_NOTE_EVENT: 'FILTER_CONTACT_LOGGED_DATE_NOTE_EVENT',
  HAS_FUNDA_BROCHURE_REQUEST_EVENT:
    'FILTER_CONTACT_HAS_FUNDA_BROCHURE_REQUEST_EVENT',
  HAS_FUNDA_VIEWING_REQUEST_EVENT:
    'FILTER_CONTACT_HAS_FUNDA_VIEWING_REQUEST_EVENT',
  HAS_FUNDA_CONTACT_REQUEST_EVENT:
    'FILTER_CONTACT_HAS_FUNDA_CONTACT_REQUEST_EVENT',
  CREATED_FUNDA_BROCHURE_REQUEST_EVENT:
    'FILTER_CONTACT_CREATED_FUNDA_BROCHURE_REQUEST_EVENT',
  CREATED_FUNDA_VIEWING_REQUEST_EVENT:
    'FILTER_CONTACT_CREATED_FUNDA_VIEWING_REQUEST_EVENT',
  CREATED_FUNDA_CONTACT_REQUEST_EVENT:
    'FILTER_CONTACT_CREATED_FUNDA_CONTACT_REQUEST_EVENT',
  HAS_BBWAARDECHECK_REPORT_EVENT:
    'FILTER_CONTACT_HAS_BBWAARDECHECK_REPORT_EVENT',
  HAS_BBWAARDECHECK_CONTACT_REQUEST_EVENT:
    'FILTER_CONTACT_HAS_BBWAARDECHECK_CONTACT_REQUEST_EVENT',
  HAS_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT:
    'FILTER_CONTACT_HAS_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT',
  CREATED_BBWAARDECHECK_REPORT_EVENT:
    'FILTER_CONTACT_CREATED_BBWAARDECHECK_REPORT_EVENT',
  CREATED_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT:
    'FILTER_CONTACT_CREATED_BBWAARDECHECK_APPRAISAL_REQUEST_EVENT',
  CREATED_BBWAARDECHECK_CONTACT_REQUEST_EVENT:
    'FILTER_CONTACT_CREATED_BBWAARDECHECK_CONTACT_REQUEST_EVENT',
  ZAPIER_METADATA: 'ZAPIER_METADATA',
  HAS_VBOWAARDECHECK_REPORT_EVENT:
    'FILTER_CONTACT_HAS_VBOWAARDECHECK_REPORT_EVENT',
  HAS_VBOWAARDECHECK_CONTACT_REQUEST_EVENT:
    'FILTER_CONTACT_HAS_VBOWAARDECHECK_CONTACT_REQUEST_EVENT',
  HAS_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT:
    'FILTER_CONTACT_HAS_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT',
  CREATED_VBOWAARDECHECK_REPORT_EVENT:
    'FILTER_CONTACT_CREATED_VBOWAARDECHECK_REPORT_EVENT',
  CREATED_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT:
    'FILTER_CONTACT_CREATED_VBOWAARDECHECK_APPRAISAL_REQUEST_EVENT',
  CREATED_VBOWAARDECHECK_CONTACT_REQUEST_EVENT:
    'FILTER_CONTACT_CREATED_VBOWAARDECHECK_CONTACT_REQUEST_EVENT',
};
