import React from 'react';
import { navigate } from '@gatsbyjs/reach-router';
import { useLocation } from '@gatsbyjs/reach-router';
import {
  DELETE_ENTITY_TYPE,
  DELETION_TYPE,
} from '~/components/page/Settings/utils/constants';
import { Highlight } from '~/components/atom/Typography';
import useIsMounted from '~/hooks/useIsMounted';
import useCurrentUser from '~/hooks/useCurrentUser';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useSessionHydration from '~/hooks/useSessionHydration';
import { useDeleteUserFromAccountMutation } from '~/graphql/types';
import DeleteEntityModal from '../components/DeleteEntityModal';
import type { ListItemForDeletion } from '../components/ListItemCard';

const text = {
  modalHeader: (
    <>
      Gebruiker verwijderen uit je <Highlight>account</Highlight>
    </>
  ),
  modalDescription:
    'Wijs contacten en openstaande taken toe aan een andere gebruiker of vestiging',
};

type Props = {
  list: Array<ListItemForDeletion>;

  /** When the modal is closed */
  onClose: () => void;

  /** When the cancel button is pressed */
  onCancel: () => void;

  /** Id of user to delete */
  userId: string;
};
const DeleteUserFromAccountModal: React.FCC<Props> = ({
  list,
  onClose,
  onCancel,
  userId,
}) => {
  const isMounted = useIsMounted();
  const location = useLocation();
  const [, refetchSessionHydration] = useSessionHydration();
  const me = useCurrentUser();
  const account = useCurrentAccount();

  const [deleteUserFromAccount, { error }] = useDeleteUserFromAccountMutation();

  return (
    <DeleteEntityModal
      list={list}
      modalHeader={text.modalHeader}
      modalDescription={text.modalDescription}
      onClose={onClose}
      onCancel={onCancel}
      entityId={userId}
      entityType={DELETE_ENTITY_TYPE.USER}
      onSuccess={() => {
        if (!isMounted()) return;

        // On profile page
        if (userId === me.id) {
          void navigate('/logout', {
            state: { isUserDeleted: true },
          });
          return;
        } else {
          onClose();

          // If we are not deleting from office users page
          if (!location.pathname.includes('/-/settings/offices/')) {
            void navigate('/-/settings/users');
          }

          return refetchSessionHydration();
        }
      }}
      onDelete={changeSet =>
        deleteUserFromAccount({
          variables: {
            accountId: account.id,
            userId: userId,
            changeSet,
          },
        }).then(result => {
          if (result == null || result.data == null) {
            return null;
          }

          return result.data.deleteUserFromAccount;
        })
      }
      mutationError={error}
      deletionType={DELETION_TYPE.DELETE_USER_FROM_ACCOUNT}
    />
  );
};

export default DeleteUserFromAccountModal;
