import React from 'react';

import Button, { Props as ButtonProps } from '~/components/atom/Button';
import useTooltipLayer from '~/hooks/useTooltipLayer';

type Props = ButtonProps & {
  tooltip: {
    message: React.ReactNode;
  } | null;
};

/**
 * Adds a tooltip to the Button component
 */
const ButtonWithTooltip: React.FCC<Props> = ({ tooltip, ...rest }) => {
  const tooltipProps = useTooltipLayer({ tooltipMessage: tooltip?.message });

  if (tooltip === null) {
    return <Button {...rest} />;
  }

  return (
    <div {...tooltipProps}>
      <Button {...rest} />
    </div>
  );
};

export default ButtonWithTooltip;
