import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { sub } from 'date-fns';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import Button from '~/components/atom/Button';
import Divider from '~/components/atom/Divider';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { Body, Heading2, Label, Variant } from '~/components/atom/Typography';
import AnimatedCheckbox from '~/components/molecule/AnimatedCheckbox';
import DatePicker from '~/components/organism/DatePicker';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import { useExportFormSubmissionsMutation } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useFireTrackingEvent from '~/hooks/useFireTrackingEvent';
import useSessionHydration from '~/hooks/useSessionHydration';
import { getStartOfToday, isValidDateRange } from '~/util/date';
import formatToastMessage from '~/util/formatToastMessage';
import removeNullFromObj from '~/util/removeNullFromObj';

export type Props = {
  onClose: () => void;
} & RouteComponentProps<{ id: string }>;

const text = {
  header: 'Formulierinzendingen exporteren',
  tipHeader: 'Hulp nodig?',
  tipBody: (
    <>
      Kom je er niet uit? Neem dan contact met ons op{' '}
      <ChatLink linkText="via de chat" />.
    </>
  ),
  description: (
    <>
      Selecteer een datumbereik of kies voor het ontvangen van alle
      formulierinzendingen. De export wordt per e-mail verzonden.
    </>
  ),
  exportLabel: 'Exporteren',
  errorExportingForm:
    'Er is iets fout gegaan tijdens het exporteren van de formulierinzendingen, probeer het later opnieuw',

  successToastMessage: 'De export is verzonden',
  additionalCloseButton: 'Sluiten',

  invalidDateRangeStart: 'Deze startdatum is later dan de einddatum',
  invalidDateRangeEnd: 'Deze einddatum is eerder dan de startdatum',

  startDateLabel: 'Startdatum',
  endDateLabel: 'Einddatum',

  disableDateRange: 'Alle inzendingen exporteren',

  labelRowFirst: 'Exporteer inzendingen',
  // ------------ Date input
  labelRowMiddle: 'tot',
  // ------------ Date input
};

const ExportFormSubmissions: React.FCC<Props> = ({
  dataTestId,
  id,
  onClose,
  ...rest
}) => {
  const [{ impersonatedBy }] = useSessionHydration();
  const account = useCurrentAccount();
  const fireEvent = useFireTrackingEvent();
  const [exportFormSubmissions, { loading }] = useExportFormSubmissionsMutation(
    {
      variables: {
        accountId: account.id,
        formBuilderId: id as string,
      },
    },
  );

  const initialDateRange = {
    endDate: getStartOfToday().toISOString(),
    startDate: sub(getStartOfToday(), { months: 1 }).toISOString(),
  };

  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>(initialDateRange);
  const [disabledDateRange, setDisabledDateRange] = useState(false);
  const [validationError, setValidationError] = useState<boolean>(false);

  const addToast = useAddToast();

  const onExport = async () => {
    const optionalVariables = disabledDateRange
      ? {}
      : removeNullFromObj(dateRange);

    const { data, errors } = await exportFormSubmissions({
      variables: {
        accountId: account.id,
        formBuilderId: id as string,
        ...optionalVariables,
      },
    });

    // Notify GA so we can check how this feature is used IRL
    fireEvent({
      event: 'exportFormData',
      endDate: !isNil(dateRange.endDate),
      startDate: !isNil(dateRange.startDate),
      isImpersonating: !isNil(impersonatedBy),
    });

    if (errors && errors.length !== 0) {
      addToast([formatToastMessage(text.errorExportingForm, 'danger')]);
    }

    if (data && data.exportFormSubmissions.success === true) {
      onClose();
      addToast([formatToastMessage(text.successToastMessage, 'success')]);
    }
  };

  useEffect(() => {
    setValidationError(
      !isValidDateRange({
        endDate: dateRange.endDate,
        startDate: dateRange.startDate,
      }),
    );
  }, [dateRange]);

  return (
    <Overlay onClose={onClose}>
      <Modal maxWidth="600px">
        <JustificationContainer
          direction="column"
          padding={['xl']}
          width="100%"
          data-testid={dataTestId}
          {...rest}
        >
          <JustificationContainer>
            <Heading2>{text.header}</Heading2>
          </JustificationContainer>
          <Body margin={[null]}>{text.description}</Body>
          <JustificationContainer
            height="320px"
            width="100%"
            direction="column"
            gap="base"
            align="start"
          >
            <Label
              size="m"
              margin={['s', null, null, null]}
              style={{ whiteSpace: 'nowrap' }}
            >
              {text.labelRowFirst}
            </Label>
            <JustificationContainer
              width="100%"
              gap="m"
              backgroundColor={{
                group: 'tertiary',
                variant: 'light',
              }}
              border={{ radius: 'base' }}
              padding={['s']}
              direction="column"
            >
              <JustificationContainer align="center" gap="s">
                <DatePicker
                  inputComponentProps={{
                    disabled: disabledDateRange || loading,
                    externalErrors: validationError
                      ? [text.invalidDateRangeStart]
                      : [],
                  }}
                  label={text.startDateLabel}
                  value={dateRange.startDate ?? ''}
                  onChange={val =>
                    setDateRange(prev => ({ ...prev, startDate: val! }))
                  }
                />
                <Label
                  size="base"
                  variant={Variant.primary}
                  margin={['s', null, null, null]}
                >
                  {text.labelRowMiddle}
                </Label>
                <DatePicker
                  inputComponentProps={{
                    disabled: disabledDateRange || loading,
                    externalErrors: validationError
                      ? [text.invalidDateRangeEnd]
                      : [],
                  }}
                  label={text.endDateLabel}
                  value={dateRange.endDate ?? ''}
                  onChange={val =>
                    setDateRange(prev => ({ ...prev, endDate: val! }))
                  }
                />
              </JustificationContainer>
              <Divider
                width="100%"
                margin={[null]}
                color={{ group: 'tertiary', variant: 'base' }}
              />
              <AnimatedCheckbox
                selectable={true}
                label={
                  <Label variant={Variant.primary} size="base" margin={[null]}>
                    {text.disableDateRange}
                  </Label>
                }
                value={disabledDateRange}
                disabled={loading}
                onChange={() => {
                  const nextVal = !disabledDateRange;
                  setDisabledDateRange(nextVal);
                  if (nextVal === true) {
                    setValidationError(false);
                  }
                  if (nextVal === false) {
                    setDateRange(initialDateRange);
                  }
                }}
              />
            </JustificationContainer>
            <TipBanner
              id="share-form-tip"
              headerText={text.tipHeader}
              dismissible={false}
              width="100%"
              margin={['xxs', null]}
            >
              <Body size="base" margin={[null]}>
                {text.tipBody}
              </Body>
            </TipBanner>
          </JustificationContainer>
          <JustificationContainer
            justification="space-between"
            width="100%"
            margin={['xxxl', null, null, null]}
          >
            <TextButton
              label={text.additionalCloseButton}
              appearance="danger"
              onClick={onClose}
              disabled={loading}
            />
            <Button
              label={text.exportLabel}
              appearance="primary"
              onClick={onExport}
              icon="check"
              disabled={validationError}
              loading={loading}
            />
          </JustificationContainer>
        </JustificationContainer>
      </Modal>
    </Overlay>
  );
};

export default ExportFormSubmissions;
