import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import Icon, { IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label, Variant } from '~/components/atom/Typography';
import { linkStyles } from '~/components/molecule/Link';

export type Props = {
  icon?: IconType;
  label: React.ReactNode;
  callToAction?: React.ReactNode;
  to?: string | null;
};

const ListedItem: React.FCC<Props> = ({
  dataTestId,
  label,
  callToAction = 'Personaliseer nu',
  icon,
  to,
  ...rest
}) => (
  <Container
    data-testid={dataTestId}
    href={to ?? undefined}
    target={to ? '_blank' : '_self'}
    {...rest}
  >
    <JustificationContainer gap="s">
      {icon && <Icon name={icon} />}
      <Label size="base" variant={Variant.primary} margin={[null]}>
        {label}
      </Label>
    </JustificationContainer>
    {to && <span>{callToAction}</span>}
  </Container>
);

const Container = styled.a<{}>(
  ({ theme, href }) => css`
    ${isNil(href) ? '' : linkStyles}

    padding: ${theme.space('m')};
    box-shadow: ${theme.boxShadow('around')};
    border-radius: ${theme.getTokens().border.radius.base};
    display: flex;
    justify-content: space-between;
    text-decoration: none;
  `,
);

export default ListedItem;
