import type { Node_Condition_Assigned__Input } from '~/graphql/types';
import type { AssignedToFilter } from './types.flow';

const asAssignedToFilterInput = (
  filter: AssignedToFilter,
): Node_Condition_Assigned__Input | null => {
  const { assignedTo, negate } = filter;

  if (assignedTo == null) {
    return null;
  }

  return {
    assignedTo,
    negate,
  };
};

export default asAssignedToFilterInput;
