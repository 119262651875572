import type { ContactFiltersSubjectFragment } from '~/graphql/types';

export type ContactFiltersSubjectIdentifier = {
  subjectId: string;
  eventId?: string | null;
};

export type SubjectMap = {
  [subjectId in string]: {
    [eventId in string]: ContactFiltersSubjectFragment;
  };
};

/**
 * Generate the subject map
 */
export const generateSubjectMap = (
  subjects: Array<ContactFiltersSubjectFragment>,
): SubjectMap =>
  subjects.reduce((prev, curr) => {
    if (prev[curr.subjectId] == null) prev[curr.subjectId] = {};

    prev[curr.subjectId][curr.eventId ?? ''] = curr;

    return prev;
  }, {} as SubjectMap);

/**
 * Get a subject, if it exists
 */
export const getSubject = (
  { subjectId, eventId }: ContactFiltersSubjectIdentifier,
  subjectMap: SubjectMap,
): ContactFiltersSubjectFragment | undefined =>
  subjectMap[subjectId]?.[eventId ?? ''];

/**
 * Check if two subjects are considered equal
 */
export const isEqualSubject = (
  idA: ContactFiltersSubjectIdentifier,
  idB: ContactFiltersSubjectIdentifier,
): boolean => idA.subjectId === idB.subjectId && idA.eventId == idB.eventId;

export default getSubject;
