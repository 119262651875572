import React, { useRef, type CSSProperties } from 'react';
import { useDrag } from 'react-dnd';

export const ItemType = 'DRAGGABLE_ITEM';

export type DnDItemProps = {
  itemId: string;
  index: number;
  source?: string;
};

export type Props = {
  /** Id of the item to drag */
  itemId: string;
  /** Index of the item to drag */
  index: number;
  /** Source of the item (where the dnd is coming from) */
  source?: string;
  style?: CSSProperties;
};

const DraggableItem: React.FCC<Props> = ({
  itemId,
  index,
  source,
  children,
  style,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging, handlerId }, drag] = useDrag({
    type: ItemType,
    item: (): DnDItemProps => ({ itemId, index, source }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  drag(ref);

  return (
    <div ref={ref} style={{ opacity, ...style }} data-handler-id={handlerId}>
      {children}
    </div>
  );
};

export default DraggableItem;
