import React, { useEffect, useState } from 'react';
import {
  useGetActivitiesForContactQuery,
  useMutatedActivitySubscription,
} from '~/graphql/types';

import styled, { css } from 'styled-components';
import groupActivities from './utils/groupActivities';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { uniqBy, prop, isNil, flatten, values } from 'ramda';
import { sleep } from '~/util';
import { useRecoilState, useRecoilValue } from 'recoil';
import { activitiesByContactId, deletedActivities } from '~/state/activities';
import TaskModal from '~/components/page/Tasks/components/TaskModal';
import { Task } from '~/components/page/Tasks/types';
import generateActivityV2 from './utils/generateActivityV2';
import { ActivityV2 } from './types';
import getLatestActivities from './utils/getLatestActivities';
import mergeListWithLatestVersion from '~/util/mergeListWithLatestVersion';
import removeActivityFromList from './utils/removeActivityFromList';
import InfiniteScroll from '~/components/molecule/InfiniteScroll';
import isDeleted from '~/components/template/EventTimelineV2/utils/isDeleted';
import CardGroup from './components/CardGroup';

export type EventTimelineContact = {
  id: string;
  name: string;
  email: string;
};

export type BaseProps = {
  dataTestId?: string;

  /** Contact that activities belong to */
  contact: EventTimelineContact;

  /** Makes the timeline cards disabled (apart from Date labels). Used in Task modal's event timeline */
  disabled?: boolean;
};

type Props = BaseProps & { onOpenTaskModal?: (task: Task | null) => void };
export const FETCH_LIMIT = 20;

const EventTimelineV2: React.FC<Props> = ({
  onOpenTaskModal,
  contact,
  disabled,
}) => {
  const contactId = contact.id;
  const { id: accountId } = useCurrentAccount();
  const [groupedActivities, setGroupedActivities] = useRecoilState(
    activitiesByContactId(contact.id),
  );
  const deletedActivitiesArr = useRecoilValue(deletedActivities);

  const {
    data: initialActivityData,
    loading,
    fetchMore,
  } = useGetActivitiesForContactQuery({
    variables: {
      accountId,
      contactId,
      limit: FETCH_LIMIT,
      nextToken: null,
    },
  });

  useEffect(() => {
    // query data gets updated on updateTask call before the updateTask result is returned
    if (initialActivityData?.getActivitiesForContact.items) {
      const items =
        initialActivityData?.getActivitiesForContact.items.map(
          generateActivityV2,
        );

      setGroupedActivities(prev => {
        const prevActivities = flatten(
          values(prev).filter((v): v is Array<ActivityV2> => !isNil(v)),
        );
        const newActivities = items.filter(activity => !isDeleted(activity));

        const mergedWithLatestVersion = mergeListWithLatestVersion<ActivityV2>(
          prevActivities,
          newActivities,
        );

        return groupActivities(mergedWithLatestVersion);
      });
    }
  }, [initialActivityData, setGroupedActivities]);

  const { data: mutatedActivityData } = useMutatedActivitySubscription({
    variables: {
      accountId,
      contactId,
    },
    onData: ({ data }) => {
      const mutatedActivity = data.data?.mutatedActivity;

      if (mutatedActivity?.id) {
        const newActivity = mutatedActivity;

        const isDeletedBeforeSubscription = deletedActivitiesArr.includes(
          newActivity.id,
        );

        if (isDeleted(newActivity) || isDeletedBeforeSubscription) {
          const newActivities = removeActivityFromList({
            groupedActivities,
            deletedActivity: newActivity,
          });

          setGroupedActivities(newActivities);
          return;
        }

        setGroupedActivities(prev => {
          const latestActivities = getLatestActivities({
            prevActivities: prev,
            newActivity,
          });

          return groupActivities(latestActivities);
        });
      }
    },
  });

  return (
    <Container $disabled={disabled}>
      <InfiniteScroll
        initialLoad={loading}
        fetchMoreFn={async () => {
          if (isNil(initialActivityData?.getActivitiesForContact.nextToken))
            return;

          await fetchMore({
            variables: {
              nextToken: initialActivityData?.getActivitiesForContact.nextToken,
              limit: FETCH_LIMIT,
            },

            // concatenate old and new entries
            updateQuery: (previousResult, { fetchMoreResult }) => {
              const mutatedActivity = mutatedActivityData?.mutatedActivity;

              if (fetchMoreResult?.getActivitiesForContact) {
                return {
                  __typename: 'Query',
                  getActivitiesForContact: {
                    ...fetchMoreResult?.getActivitiesForContact,
                    items: uniqBy(prop('id'), [
                      ...(mutatedActivity ? [mutatedActivity] : []),
                      ...previousResult.getActivitiesForContact.items,
                      ...fetchMoreResult.getActivitiesForContact.items,
                    ]),
                  },
                };
              }

              return previousResult;
            },
          });

          await sleep(1000);
        }}
        hasMore={!isNil(initialActivityData?.getActivitiesForContact.nextToken)}
      >
        {Object.keys(groupedActivities).map(date => (
          <CardGroup
            onOpenTaskModal={onOpenTaskModal}
            date={date}
            items={groupedActivities[date] ?? []}
            contact={contact}
            key={date}
          />
        ))}
      </InfiniteScroll>
    </Container>
  );
};

const Container = styled.div<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => css`
    width: 100%;
    position: relative;

    &:before {
      content: '';
      position: absolute;

      /* Account for the height of the FIRST date label */
      top: 100px;
      left: ${theme.space('l')};
      /** Account for the height of the LAST icon */
      bottom: 100px;
      border-left: 2px dashed ${theme.color('tertiary', 'light')};
    }

    ${$disabled &&
    css`
      pointer-events: none;
      opacity: 50%;
      cursor: not-allowed;
    `};
  `,
);

export const EventTimelineWithTaskModal: React.FC<BaseProps> = props => {
  const account = useCurrentAccount();
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false);
  const [taskDetails, setTaskDetails] = useState<Task | null>(null);
  const onOpenTaskModal = (task: Task | null) => {
    if (task) {
      setTaskDetails(task);
      setShowTaskModal(true);
    }
  };
  return (
    <>
      {showTaskModal && (
        <TaskModal
          account={account}
          initialTaskDetails={taskDetails}
          onClose={() => {
            setShowTaskModal(false);
            setTaskDetails(null);
          }}
          selectedInFilterOfficeId={null}
          selectedInFilterUserId={null}
        />
      )}
      <EventTimelineV2 {...props} onOpenTaskModal={onOpenTaskModal} />
    </>
  );
};

export default EventTimelineV2;
