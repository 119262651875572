/**
 *
 * Taken from: Juliette
 */
import { Props } from '..';
import React from 'react';

const Funda = (props: Props) => (
  <svg
    version="1.1"
    id="Laag_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    width="100%"
    height="100%"
    {...props}
  >
    <g id="Funda">
      <g
        id="Time-line-events-Funda"
        transform="translate(-85.000000, -19.000000)"
      >
        <g id="icon-timeline_funda" transform="translate(85.000000, 19.000000)">
          <circle id="Oval" fill="#5A291C" cx="15" cy="15" r="15" />
          <circle
            id="Oval_1_"
            cx="15"
            cy="15"
            r="13"
            style={{
              fill: 'none',
              stroke: '#77C5EF',
              strokeWidth: '4',
            }}
          />
          <g>
            <path
              fill="#FFFFFF"
              d="M13.1,15.5H11V13h2.1v-2c0-1,0.3-1.8,0.9-2.4s1.4-0.9,2.3-0.9c0.8,0,1.4,0,1.9,0.1v2.2h-1.3
					c-0.5,0-0.8,0.1-1,0.3c-0.1,0.2-0.2,0.5-0.2,0.9V13H18l-0.3,2.5h-2v6.3h-2.6V15.5z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Funda;
