import React from 'react';
import { EmailAliasType } from './EmailAlias';
import cleanedFilename from '~/util/cleanedFilename';
import TEST_ID from './EmailAliasDeleteButton.testid';
import Button from '~/components/atom/Button';
import { useDeleteEmailAliasMutation } from '~/graphql/types';
import useConfirmModal from '~/hooks/useConfirmModal';

const text = {
  deleteErrorTitle: 'Oeps!',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van de alias. Probeer het nog eens.',
};
type Props = {
  accountId: string;
  emailAlias: EmailAliasType | null;
  onSuccessfulDelete: () => void;
  disabled: boolean;
  loading?: boolean;
};
const EmailAliasDeleteButton: React.FCC<Props> = ({
  emailAlias,
  onSuccessfulDelete,
  accountId,
  disabled,
  loading,
}) => {
  const { modal, setShowModal } = useConfirmModal({
    labels: { title: text.deleteErrorTitle, message: text.deleteErrorMessage },
    buttons: [],
    level: 'danger',
  });
  const { state, email } = emailAlias || { state: null, email: null };

  const [deleteEmailAlias, { loading: mutationLoading }] =
    useDeleteEmailAliasMutation();

  return (
    <>
      <Button
        ghost
        appearance="danger"
        icon="trashcan"
        size="medium"
        dataTestId={TEST_ID.DELETE_BUTTON}
        disabled={disabled || state == null || email == null || loading}
        loading={loading || mutationLoading}
        onClick={() => {
          if (email == null) {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | email is null even though the button should then be disabled!`,
            );
          }
          deleteEmailAlias({
            variables: { emailAlias: email, accountId },
          })
            .then(mutationResult => {
              if (mutationResult && mutationResult.data) {
                const { success } = mutationResult.data.deleteEmailAlias;

                if (success) {
                  onSuccessfulDelete();
                } else {
                  setShowModal(true);
                }
              } else {
                setShowModal(true);
              }
            })
            .catch(() => {
              setShowModal(true);
            });
        }}
      />
      {modal}
    </>
  );
};

export default EmailAliasDeleteButton;
