import { Ref } from 'react';
import { SpringValue, useSpring } from 'react-spring';
import useMeasure from '../useMeasure';

type ReturnType = {
  innerRef: Ref<HTMLDivElement>;
  outer: {
    opacity: SpringValue<number>;
    height: SpringValue<number>;
  };
  inner: { y: SpringValue<number> };
};

/**
 *
 * @param isOpen is the collapsible children shown
 * @returns animation styles to make a block open & close smoothly
 */
const useCollapseAnimation = ({ isOpen }: { isOpen: boolean }): ReturnType => {
  const { ref, bounds } = useMeasure();

  const { opacity } = useSpring({
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
    config: {
      tension: 2000,
      clamp: true,
    },
  });

  const { height, y } = useSpring({
    from: { height: 0, y: 0 },
    to: {
      height: isOpen ? bounds.height + 5 : 0,
      y: isOpen ? 0 : 20,
    },
  });

  return {
    innerRef: ref,
    outer: {
      opacity,
      height,
    },
    inner: { y },
  };
};

export default useCollapseAnimation;
