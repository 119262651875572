import React, { useState } from 'react';
import { withTypes } from 'react-final-form';
import { Task } from '~/components/page/Tasks/types';
import { FormType } from '../CreateNewTaskTab';
import { convertDateToServerDateString, safeFormattedTime } from '~/util/date';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ActualForm from './components/ActualForm';
import text from './text';
import { useUpdateTaskMutation } from '~/graphql/types';
import { ActivityTabProps } from '../..';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

export type Props = ActivityTabProps & {
  /** Contact id for UpdateTask mutation */
  contactId?: string | null;

  /** taskDetails data with fields for InsertTask mutation */
  taskDetails: Task;

  /** Callback to close task modal */
  handleCloseModal: () => void;
};

const { Form } = withTypes<FormType>();

const DescriptionTab: React.FCC<Props> = ({
  contactId,
  taskDetails,
  handleCloseModal,
}) => {
  const account = useCurrentAccount();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { type, dueDate, officeId, userId, title, description } = taskDetails;
  const dateValue = dueDate ? dueDate.toISOString() : null;
  const timeValue = safeFormattedTime(dueDate);
  const addToast = useAddToast();

  const [updateTask, { loading }] = useUpdateTaskMutation();

  const initialValues = {
    title,
    type,
    description,
    dueDate,
    dueDateDate: dateValue,
    dueDateTime: timeValue,
    userId,
    officeId,
  };

  const handleSubmitFunction = (formData: FormType) => {
    if (!contactId) return;

    const dueDate = convertDateToServerDateString(
      formData.dueDateDate,
      formData.dueDateTime,
    );

    if (dueDate === null) return;

    return updateTask({
      variables: {
        accountId: account.id,
        id: taskDetails.id,
        update: {
          title: formData.title,
          userId: formData.userId,
          officeId: formData.officeId,
          type: formData.type,
          description: formData.description || null,
          dueDate,
        },
      },
    }).then(({ data, errors }) => {
      if (errors && errors.length > 0) {
        return addToast([formatToastMessage(text.errorMessage, 'danger')]);
      }

      if (data) {
        setIsEdit(false);
        return;
      }
      return;
    });
  };

  const validate = (fields: FormType) => {
    const errors: {
      title?: string;
      description?: string;
      dueDateDate?: string;
      dueDateTime?: string;
      officeId?: string;
    } = {};

    if (!fields.title) errors.title = text.validation.required;
    if (!fields.dueDateDate) errors.dueDateDate = text.validation.required;
    if (!fields.dueDateTime) errors.dueDateTime = text.validation.required;
    if (!fields.officeId) errors.officeId = text.validation.required;

    return errors;
  };

  return (
    <Form
      validate={fields => validate(fields)}
      onSubmit={handleSubmitFunction}
      initialValues={{
        ...initialValues,
        officeId,
        userId,
      }}
    >
      {formProp => (
        <ActualForm
          updateTask={updateTask}
          contactId={contactId}
          loading={loading}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          handleCloseModal={handleCloseModal}
          taskDetails={taskDetails}
          formProp={formProp}
          dueDateDate={initialValues.dueDateDate}
        />
      )}
    </Form>
  );
};

export default DescriptionTab;
