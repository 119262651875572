import { SNOOZED_WARNINGS, getLocalStorageItem } from '~/util/localStorageKeys';
import SnoozedWarningType from '../types';

const getSnoozedWarnings = (): SnoozedWarningType => {
  const snoozedWarnings = getLocalStorageItem(SNOOZED_WARNINGS);

  if (!snoozedWarnings) return {};

  return JSON.parse(snoozedWarnings);
};

export default getSnoozedWarnings;
