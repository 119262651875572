import Validation from '~/util/Validation';
import { BillingFormData } from '../convertToFormData';
import Catalog from '~/Catalog';

const text = {
  noName: Catalog.requiredField,
  noEmail: Catalog.requiredField,
  invalidEmail: Catalog.invalidEmail,
  // invalidPostcode: Catalog.invalidPostalCode,
};

const validate = (fields: BillingFormData) => {
  const errors: BillingFormData = {
    name: undefined,
    email: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    addressPostcode: undefined,
    addressCity: undefined,
  };

  if (Validation.String.isEmpty(fields.name)) {
    errors.name = text.noName;
  }

  if (Validation.String.isEmpty(fields.email)) {
    errors.email = text.noEmail;
  } else {
    if (!Validation.Email.isValid(fields.email)) {
      errors.email = text.invalidEmail;
    }
  }

  return errors;
};

export default validate;
