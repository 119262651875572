import { isNil, groupBy, uniq } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Dropdown, {
  type SelectedOptionOf,
} from '~/components/molecule/Dropdown';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  EmailSignature,
  FlowV2_Action_SendEmail_PlainFragment,
  FromAddress,
} from '~/graphql/types';
import { Props as FormProps } from '../ActionForm';
import { AUTHORISE_LEVEL } from '~/util/constants';
import EmailAttachments from './components/EmailAttachments';
import TEST_ID from './index.testid';
import {
  fromAddressOptions,
  signatureOptions,
  transactionalOptions,
  TransactionalOptions,
  AddressOptions,
  SignatureOptions,
  CUSTOM_HTML_PRAGMA,
  EMAIL_EDITOR_BUTTONS,
} from './constants';
import DefaultTextEditor from '~/components/organism/DefaultTextEditor';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import getImagesAsFlowAttachments from '~/components/organism/PluginsEditor/utils/flows/getImagesAsFlowAttachments';
import TextEditorContainer from '~/components/atom/TextEditorContainer';
import ErrorLabel from '../ErrorLabel';
import TemplateStringInput from '~/components/organism/TemplateStringInput';
import useEmailFileAttachments from '../../hooks/useEmailFileAttachments';
import type { Client_FlowV2_EmailAttachment } from '../types';
import InputLabel from '~/components/atom/InputLabel';
import useDHEditorInFlowBuilder from '../../hooks/useDHEditorInFlowBuilder';
import SelectGroup from '~/components/molecule/SelectGroup';
import InputLabelWithHelpLink from '~/components/molecule/InputLabelWithHelpLink';
import useAvailableEmailsToSendFrom from '~/hooks/useAvailableEmailsToSendFrom';
import CustomHtmlSwitcher from './components/CustomHtmlSwitcher';
import Textarea from '~/components/molecule/Textarea';

export type Props = FormProps & {
  action: FlowV2_Action_SendEmail_PlainFragment;
};

type State = Omit<
  FlowV2_Action_SendEmail_PlainFragment,
  | '__typename'
  | 'id'
  | '_v'
  | 'accountId'
  | 'parentIds'
  | 'flowBlueprintId'
  | 'Statistics'
  | 'actionType'
> & {
  customHtml: string | null;
};

const text = {
  fromAddressLabel: 'Van',
  addressHelpLink:
    'https://help.dathuis.nl/nl/articles/4247794-automatisch-e-mail-sturen-vanuit-de-eigenaar-van-het-contact',
  emailTypeLabel: 'Type',
  typeHelpLink:
    'https://help.dathuis.nl/nl/articles/3959108-wat-is-het-verschil-tussen-een-marketing-en-transactionele-e-mail',
  subjectLabel: 'Onderwerp',
  signatureLabel: 'Handtekening',
  signatureWarningMessage:
    'Zorg ervoor dat alle gebruikers een handtekening hebben ingesteld als je deze optie selecteert',
  addAttachmentButtonLabel: 'uit een stap',
  or: 'of',
  customHtmlLabel: 'Aangepaste HTML',
};

const SendEmailPlain: React.FCC<Props> = ({ action, onChange }) => {
  const hasCustomHtmlInitially =
    action.body.template && action.body.template.startsWith(CUSTOM_HTML_PRAGMA);

  const initials: State = {
    from:
      action.from === undefined ? FromAddress.ClosestToContact : action.from,
    isTransactional: action.isTransactional,
    subject: action.subject,
    body: action.body,
    attachments: action.attachments,
    signature:
      action.signature === undefined
        ? EmailSignature.FromAddressEntity
        : action.signature,
    customHtml: hasCustomHtmlInitially
      ? action.body.template
        ? // do not display the prefix in the textarea
          action.body.template.replace(CUSTOM_HTML_PRAGMA, '')
        : ''
      : null,
  };

  const availableEmails = useAvailableEmailsToSendFrom();

  const [actionDetails, setActionDetails] = useState<State>(initials);

  const [error, setError] = useState<string | null>(null);

  const {
    from: fromAddress,
    isTransactional,
    attachments,
    signature,
  } = actionDetails;

  const groupedAttachments =
    attachments.length > 0
      ? groupBy(attachment => {
          if (attachment?.inlineId) return 'image';
          return 'file';
        }, actionDetails.attachments)
      : { file: [], image: [] };

  const [imageAttachments, setImageAttachments] = useState<
    Array<Client_FlowV2_EmailAttachment>
  >(groupedAttachments.image || []);

  const accountEmail = availableEmails.find(
    email => email.authoriseLevel === AUTHORISE_LEVEL.ACCOUNT,
  );

  let emailText: string = '';
  if (accountEmail != null) {
    const { emailToShow } = accountEmail.namedEmail;

    emailText = `- ${emailToShow ? emailToShow : ''}`;
  }

  const fromOptions = fromAddressOptions(emailText);

  const onHandleChange = useCallback(
    (
      key: string,
      value: SelectedOptionOf<
        TransactionalOptions | AddressOptions | SignatureOptions
      >,
    ) => {
      setActionDetails(prev => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  useEffect(() => {
    onChange({ ...action, ...actionDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  const { value: subjectValue, onChange: onSubjectChange } =
    useDHEditorInFlowBuilder({
      actionId: action.id,
      html: initials.subject.template || '',
      mappings: initials.subject.mappings,
      updaterFn: updatedValue => {
        setActionDetails(prev => ({
          ...prev,
          subjectValue: updatedValue,
        }));
      },
    });

  const {
    value: bodyValue,
    onChange: onBodyChange,
    resetEditor: onBodyReset,
  } = useDHEditorInFlowBuilder({
    actionId: action.id,
    html: initials.body.template || '',
    mappings: initials.body.mappings,
    attachments: groupedAttachments.image,
    customElements: [ELEMENTS.IMAGE, ELEMENTS.DH_IMAGE, ELEMENTS.VARIABLE],
    updaterFn: updatedValue => {
      const updatedImageAttachments = getImagesAsFlowAttachments(updatedValue);

      setImageAttachments(updatedImageAttachments);
      setActionDetails(prev => ({
        ...prev,
        bodyValue: updatedValue,
      }));
    },
  });

  const {
    insertPointerAttachment,
    updatePointerAttachment,
    insertFileAttachment,
    onDelete,
    fileAttachments,
  } = useEmailFileAttachments({
    initialfileAttachments: groupedAttachments.file,
    setError,
  });

  useEffect(() => {
    setError(null);
    setActionDetails(prev => ({
      ...prev,
      attachments: uniq([
        ...(imageAttachments || []),
        ...(fileAttachments || []),
      ]),
    }));
  }, [fileAttachments, imageAttachments]);

  const hasCustomHtml = !isNil(actionDetails.customHtml);

  return (
    <>
      <JustificationContainer
        justification="start"
        direction="column"
        width="100%"
      >
        <JustificationContainer justification="start" width="100%">
          <Container>
            <InputLabelWithHelpLink
              label={text.fromAddressLabel}
              help={{ link: text.addressHelpLink }}
            />

            <Dropdown
              dataTestId={TEST_ID.FROM_ADDRESS_DROPDOWN}
              options={fromOptions}
              onChange={e => onHandleChange('from', e.option.payload)}
              selectedOptionIdx={fromOptions.findIndex(
                selectedOption =>
                  selectedOption.payload ===
                  (fromAddress === undefined
                    ? FromAddress.ClosestToContact
                    : fromAddress),
              )}
            />
          </Container>
          <div>
            <InputLabelWithHelpLink
              label={text.emailTypeLabel}
              help={{ link: text.typeHelpLink }}
            />
            <SelectGroup
              dataTestId={TEST_ID.IS_TRANSACTIONAL_SWITCHER}
              options={transactionalOptions}
              onChange={e => onHandleChange('isTransactional', e.option?.value)}
              selectedIndex={transactionalOptions.findIndex(
                selectedOption => selectedOption.value === isTransactional,
              )}
            />
          </div>
        </JustificationContainer>
      </JustificationContainer>

      <JustificationContainer
        direction="column"
        margin={['base', null]}
        width="100%"
      >
        <InputLabel label={text.subjectLabel} />
        <TemplateStringInput
          $key={0}
          value={subjectValue}
          onChange={onSubjectChange}
          dataTestId={TEST_ID.SUBJECT}
        />
      </JustificationContainer>

      <JustificationContainer margin={['base', null]}>
        <JustificationContainer
          direction="column"
          width="100%"
          gap="s"
          align="end"
        >
          {hasCustomHtml ? (
            <JustificationContainer direction="column" width="100%">
              <InputLabel label={text.customHtmlLabel} />
              <Textarea
                name="HTML"
                value={actionDetails.customHtml || ''}
                onChange={value => {
                  setActionDetails(prev => ({
                    ...prev,
                    customHtml: value ?? '',
                  }));
                }}
              />
            </JustificationContainer>
          ) : (
            <TextEditorContainer>
              <DefaultTextEditor
                key={10000}
                value={bodyValue}
                onChange={onBodyChange}
                customElements={[ELEMENTS.VARIABLE]}
                dataTestId={TEST_ID.BODY}
                applyBrandSettingsFontFamily
                toolbarButtons={EMAIL_EDITOR_BUTTONS}
              />
            </TextEditorContainer>
          )}

          <CustomHtmlSwitcher
            hasCustomHtml={hasCustomHtml}
            updateActionDetails={value =>
              setActionDetails(prev => ({ ...prev, ...value }))
            }
            onBodyReset={onBodyReset}
          />
        </JustificationContainer>
      </JustificationContainer>

      <SignatureContainer>
        <InputLabel label={text.signatureLabel} />
        <Dropdown
          dataTestId={TEST_ID.SIGNATURE_DROPDOWN}
          options={signatureOptions}
          onChange={e => onHandleChange('signature', e.option.payload)}
          selectedOptionIdx={signatureOptions.findIndex(
            selectedOption =>
              selectedOption.payload ===
              (signature === undefined
                ? EmailSignature.FromAddressEntity
                : signature),
          )}
        />
        {actionDetails.signature === EmailSignature.ClosestEntity && (
          <WarningContainer>{text.signatureWarningMessage}</WarningContainer>
        )}
      </SignatureContainer>

      <EmailAttachments
        action={action}
        attachments={fileAttachments}
        onPointerInsert={insertPointerAttachment}
        onPointerUpdate={updatePointerAttachment}
        onFileUpload={insertFileAttachment}
        onError={error => setError(error)}
        onDelete={onDelete}
      />

      {!isNil(error) && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    width: 100%;
    margin-right: ${theme.space('l')};
  `,
);

const SignatureContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
  `,
);

const WarningContainer = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('warning')};
    margin-top: ${theme.space('xxs')};
    font-size: ${theme.fs('s')};
  `,
);

export default SendEmailPlain;
