import { AppStatusLockedReason } from '~/graphql/types';
import type { AppStatus } from '~/graphql/types.client';

export type AppStatusIndicator =
  | 'incomplete'
  | 'enabled'
  | 'disabled'
  | 'expired'
  | AppStatusLockedReason.Disabling;
const getAppStatusIndicator = (
  appStatus: AppStatus | undefined,
): AppStatusIndicator => {
  if (!appStatus) return 'disabled';
  if (appStatus.locked) return appStatus.locked;

  if (
    appStatus.enabled === true &&
    'metadata' in appStatus &&
    appStatus.metadata?.setup
  ) {
    return appStatus.metadata?.setup.completed === true
      ? 'enabled'
      : 'incomplete';
  }

  return appStatus.enabled ? 'enabled' : 'disabled';
};

export default getAppStatusIndicator;
