import type { FormBuilder_NodeFragment } from '~/graphql/types';

export const getPreviousNodes = (
  nodes: Array<FormBuilder_NodeFragment>,
  targetNodeId: string,
): Array<FormBuilder_NodeFragment> => {
  const collectAncestors = (
    nodeId: string,
    ancestors: Array<FormBuilder_NodeFragment> = [],
  ): Array<FormBuilder_NodeFragment> => {
    const parentNode = nodes.find(node => {
      if (
        node.__typename === 'FormBuilder_ScreenNode' ||
        node.__typename === 'FormBuilder_EventNode'
      ) {
        return node.defaultNext && node.defaultNext.targetNodeId === nodeId;
      }
      return false;
    });

    if (parentNode) {
      ancestors.push(parentNode);
      return collectAncestors(parentNode.id, ancestors);
    }

    return ancestors;
  };

  return collectAncestors(targetNodeId);
};

export default getPreviousNodes;
