import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  TASK_TITLE: `${cleanedFilename(__filename)}-task-title`,
  DUE_DATE_DATE: `${cleanedFilename(__filename)}-duedatedate`,
  DUE_DATE_TIME: `${cleanedFilename(__filename)}-duedatetime`,
  DESCRIPTION_TEXTAREA_FIELD: `${cleanedFilename(
    __filename,
  )}-description-textarea-field`,
  OFFICEID_FIELD: `${cleanedFilename(__filename)}-officeId-field`,
  USERID_FIELD: `${cleanedFilename(__filename)}-userId-field`,
  ADD_NEW_TASK_AND_CLOSE_BUTTON: `${cleanedFilename(
    __filename,
  )}-add-new-task-and-close-button`,
  TYPE_FIELD: `${cleanedFilename(__filename)}-type-field`,
};
