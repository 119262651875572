import {
  title as enableFlowsTitle,
  id as enableFlowsId,
} from '../../Apps/components/EnableFlowsForAppStatus';
import {
  title as genericWidgetConfigurationTitle,
  id as genericWidgetConfigurationId,
} from '../../GenericSteps/GenericWidgetConfiguration';
import {
  title as overviewTitle,
  id as overviewId,
} from '../../Apps/components/AppStatusOverview';
import { WizardStep } from '~/components/organism/Wizard/context/WizardContext';
import { StepId } from '../..';
import { AppStatus__typename } from '~/graphql/types.client';

/**
 * Returns EnableFlowsForAppStatus and AppStatusOverview steps for Wizard
 * @param {AppStatus__typename} typename
 * keywords: wizard, steps, AppStatusOverview, EnableFlowsForAppStatus
 */

type Args = {
  typename: AppStatus__typename;
  withWidget?: boolean;
  withFlows?: boolean;
};
const getCommonWizardSteps = ({
  typename,
  withWidget,
  withFlows,
}: Args): Array<WizardStep> => {
  const steps: Array<WizardStep> = [];

  if (withFlows) {
    steps.push({
      title: enableFlowsTitle,
      id: enableFlowsId as StepId,
      metadata: { typename },
    });
  }

  steps.push({
    title: overviewTitle,
    id: overviewId as StepId,
    metadata: { typename },
  });

  if (withWidget) {
    return [
      {
        title: genericWidgetConfigurationTitle,
        id: genericWidgetConfigurationId as StepId,
        metadata: { typename },
      },
      ...steps,
    ];
  }

  return steps;
};
export default getCommonWizardSteps;
