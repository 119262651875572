export type StringFilter =
  | {
      negate: boolean;
      [Operator.STARTS_WITH]: string;
    }
  | {
      negate: boolean;
      [Operator.EQUALS]: string;
    };

export enum Operator {
  STARTS_WITH = 'sw',
  EQUALS = 'eq',
}
