import { isNil } from 'ramda';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { validImageTypes } from '~/util/uploadS3Image';
import AvatarWithUploader from '../AvatarWithUploader';
import DragAndDropContainer from '../DragAndDropContainer';
import ImageUploadTextButton from '../ImageUploadTextButton';
import ProgressBar from '~/components/molecule/ProgressBar';

const text = {
  uploading: 'Uploaden...',
  info: 'Je beeld mag maximaal 5mb groot zijn. Geaccepteerde bestanden zijn JPG, JPEG, PNG, GIF en SVG.',
};

export type Props = {
  imageUrl: string | null | undefined;
  onUpload: (files: FileList) => void;
  /** When onDelete is not provided, it means the image is mandatory and can only be reuploaded */
  onDelete?: () => void;
  imagePlaceholderText?: string;
  uploading: boolean;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  disabled?: boolean;
  imagePreview?: boolean;
};

const ImageUploadComponent: React.FCC<Props> = ({
  dataTestId,
  imageUrl,
  imagePlaceholderText,
  uploading,
  onDelete,
  onUpload,
  direction = 'row',
  disabled = false,
  imagePreview = true,
}) => {
  const _onUpload = useCallback(
    files => {
      if (!uploading) {
        onUpload(files);
      }
    },
    [uploading, onUpload],
  );

  const showDeleteButton = onDelete && !uploading && !isNil(imageUrl);

  return (
    <DragAndDropContainer
      handleDrop={files => _onUpload(files)}
      uploading={uploading}
      acceptedFileTypes={validImageTypes}
    >
      <Container $direction={direction}>
        <TextContainer>
          <ImageUploadTextButton
            onUpload={_onUpload}
            uploading={uploading}
            disabled={disabled}
          />

          {uploading ? <ProgressBar loading={uploading} /> : text.info}
        </TextContainer>

        {imagePreview && (
          <StyledAvatarWithUploader
            onUpload={_onUpload}
            onDelete={onDelete}
            simpleImage
            edit={!uploading && !disabled}
            dataTestId={dataTestId}
            imageUrl={imageUrl}
            showDeleteButton={showDeleteButton}
            imagePlaceholderText={
              uploading ? text.uploading : imagePlaceholderText
            }
            style={{
              maxWidth: 'unset',
              width: '100%',
              height: '130px',
            }}
          />
        )}
      </Container>
    </DragAndDropContainer>
  );
};

const Container = styled.div<{ $direction?: Props['direction'] }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ $direction }) => $direction};
  justify-content: space-between;

  ${({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid ${theme.color('tertiary')};
    padding: ${theme.space('s')};
    border-radius: ${theme.getTokens().border.radius.base};

    ${theme.mq.lessThan('tabletLandscape')`
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
      padding-bottom: ${theme.space('xl')};
    `}

    &:hover {
      border: ${theme.getTokens().border.width.s} solid ${theme.color('text')};
    }
  `};
`;

const TextContainer = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => css`
    padding: ${theme.space('l')};
  `};
`;

const StyledAvatarWithUploader = styled(AvatarWithUploader)<{}>`
  flex-basis: 200px;
  margin-right: 0;
`;

export default ImageUploadComponent;
