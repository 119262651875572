import styled, { DefaultTheme, css } from 'styled-components';
import React from 'react';
import { Link } from '~/components/molecule/Link';
import Icon from '~/components/atom/Icon';
import { isNil } from 'ramda';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import { useLocation } from '@gatsbyjs/reach-router';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type BreadcrumbType = {
  /** Makes the breadcrumb a link */
  to?: string;

  /** The label to display */
  label: string;
};

type Props = {
  breadcrumbs: Array<BreadcrumbType>;
};
const Breadcrumbs: React.FCC<Props> = ({ breadcrumbs }) => {
  const viewingModeProps = useViewingModeProps();
  const location = useLocation();

  const isOnContacts = location.pathname.includes('contacts');

  return (
    <JustificationContainer
      data-testid="breadcrumb-container"
      gap="s"
      justification="start"
      align="center"
    >
      {breadcrumbs.map(({ to, label }, index) => (
        <React.Fragment key={label + index}>
          {!isNil(to) ? (
            <BreadcrumbLink
              key={label}
              to={to}
              dataTestId="breadcrumb-item-link"
              $isActive={false}
            >
              {label}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbText
              key={label}
              data-testid="breadcrumb-item-text"
              $isActive={true}
              data-redacted={isOnContacts && viewingModeProps['data-redacted']}
            >
              {label}
            </BreadcrumbText>
          )}

          {index !== breadcrumbs.length - 1 && (
            <ChevronElement name="chevron-right" key={`chevron-${index}`} />
          )}
        </React.Fragment>
      ))}
    </JustificationContainer>
  );
};

const activeStyle = (theme: DefaultTheme) => css`
  font-weight: ${theme.fw('semiBold')};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const breadcrumbStyles = css`
  white-space: nowrap;
`;

type BreadcrumbLinkType = { $isActive: boolean };
const BreadcrumbLink = styled(Link)<BreadcrumbLinkType>(
  ({ $isActive, theme }) => css`
    color: ${theme.color('tertiary')};
    transition: all 0.2s ease-out;

    ${breadcrumbStyles}
    ${$isActive === true ? activeStyle(theme) : ''}
  `,
);

type BreadcrumbTextType = { $isActive: boolean };
const BreadcrumbText = styled.span<BreadcrumbTextType>(
  ({ $isActive, theme }) => css`
    ${breadcrumbStyles}
    ${$isActive === true ? activeStyle(theme) : ''}
  `,
);

const ChevronElement = styled(Icon)<{}>(
  ({ theme }) => css`
    color: ${theme.color('tertiary')};
  `,
);

export default Breadcrumbs;
