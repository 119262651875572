import React from 'react';
import CallToActionBlock from '~/components/organism/CallToActionBlock';

const text = {
  skipThisStep: 'Sla deze stap over',
};

export type Props = {
  skipCurrentStep: () => void;
  description: string;
};

const RealworksCallToActionBlock: React.FCC<Props> = ({
  dataTestId,
  description,
  skipCurrentStep,
}) => (
  <CallToActionBlock
    description={description}
    button={{
      label: text.skipThisStep,
      appearance: 'primary',
      icon: 'arrowRight',
      onClick: skipCurrentStep,
    }}
    icon={{ name: 'triangle', background: 'warning' }}
    margin={['l', null, null]}
    padding={['m']}
    dataTestId={dataTestId}
    align="center"
  />
);

export default RealworksCallToActionBlock;
