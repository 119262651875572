import { Node } from 'reactflow';
import getDirectParentIds from '../getDirectParentIds';

/**
 * Returns parent ids of a node, from closest parent up until Start action
 * @param {Array<Node>} nodes - all nodes
 * @param {Node} node - child node
 * keywords: parent id, action
 */
const getDirectParentId = (element: Node): string =>
  element?.data?.parentId || element?.data?.action?.parentIds?.[0];

const getDirectParentIdsOfNode = (
  nodes: Array<Node>,
  node: Node,
): Array<string> =>
  getDirectParentIds({
    items: nodes,
    item: node,
    getParentId: getDirectParentId,
  });

export default getDirectParentIdsOfNode;
