import { FlowV2_Update_Action, FlowV2_Update_Metadata } from '~/graphql/types';
import type { ClientFlowAction } from '~/graphql/types.client';

/**
 * Return metadata for all wait actions that will be deleted
 * keywords: setMetadata
 */
const assignFlowMetadata = ({
  prevMetadata,
  initialActions,
  deletedWaitActions,
  deletedActionIds,
}: {
  prevMetadata: Array<FlowV2_Update_Metadata>;
  deletedWaitActions: Array<string>;
  initialActions: Array<ClientFlowAction>;
  deletedActionIds: Array<string>;
}): Array<FlowV2_Update_Metadata> => {
  const filteredPrevious: Array<FlowV2_Update_Metadata> = prevMetadata.filter(
    act => !deletedWaitActions.includes(act.flowBlueprintActionId),
  );

  const deleted: Array<FlowV2_Update_Metadata> = deletedWaitActions.map(id => ({
    flowBlueprintActionId: id,
    action: FlowV2_Update_Action.Drop,
  }));

  // check parents of previously assigned actions, change metadata value if it has any deleted parents
  const prev = filteredPrevious.map(p => {
    const waitAction = initialActions.find(
      a => a.id === p.flowBlueprintActionId,
    );

    if (
      waitAction &&
      'parentIds' in waitAction &&
      waitAction.parentIds.some(r => deletedActionIds.includes(r))
    ) {
      return {
        ...p,
        action: FlowV2_Update_Action.Drop,
      };
    } else return p;
  });

  return [...prev, ...deleted];
};
export default assignFlowMetadata;
