import React from 'react';
import styled from 'styled-components';

type Props = {};

const NoResultSection: React.FCC<Props> = ({ children, ...rest }) => (
  <NoResultSectionElement data-testid="no-result-block" {...rest}>
    {children}
  </NoResultSectionElement>
);

const NoResultSectionElement = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: auto;
  margin-bottom: auto;
  height: 100%;

  color: rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 2em;
  font-size: 1.5em;
`;

export default NoResultSection;
