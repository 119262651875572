import React, { useState, useCallback } from 'react';
import { map, filter, propEq, assoc } from 'ramda';
import styled, { css } from 'styled-components';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import SelectableRowWithInput from '~/components/molecule/CollapsibleBlock/components/SelectableRowWithInput';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useCurrentBreakpoint from '~/hooks/useCurrentBreakpoint';
import { Kenmerk } from '../RealworksSelectableAccordion';
import alterById from '~/util/alterById/index';
import AnimatedCheckbox from '~/components/molecule/AnimatedCheckbox';

type Field = 'check' | 'tag';

export type Props = {
  /** Group name */
  name: string;
  /** All kenmerken within this group */
  kenmerken: Array<Kenmerk>;
  selectable: boolean;
  onChange: (args: { kenmerkList: Array<Kenmerk> }) => void;
};

const KenmerkGroup: React.FCC<Props> = ({
  dataTestId,
  kenmerken,
  name,
  selectable,
  onChange: onParentChange,
  ...rest
}) => {
  const currentBp = useCurrentBreakpoint();
  const checkedAmount: number = filter(
    propEq(true, 'checked'),
    kenmerken,
  ).length;

  const isGroupChecked = checkedAmount === kenmerken.length;
  const partiallyChecked = !isGroupChecked && checkedAmount > 0;

  const [contentOpen, setContentOpen] = useState<boolean>(true);

  const onChange = useCallback(
    (id: number, value: string | boolean | null, field: Field) => {
      const updated = alterById(id, field, value, kenmerken);
      onParentChange({ kenmerkList: updated });
    },
    [kenmerken, onParentChange],
  );

  const markAllCheckboxes = useCallback(
    (value: boolean) =>
      onParentChange({ kenmerkList: map(assoc('checked', value), kenmerken) }),
    [kenmerken, onParentChange],
  );

  return (
    <CollapsibleChevronBlock
      data-testid={dataTestId}
      header={
        <AnimatedCheckbox
          label={<Label>{name}</Label>}
          name={`${name}-group-checkbox`}
          value={isGroupChecked || partiallyChecked}
          minus={partiallyChecked}
          selectable={selectable}
          onChange={() => {
            setContentOpen(true);
            markAllCheckboxes(
              partiallyChecked ? isGroupChecked : !isGroupChecked,
            );
          }}
        />
      }
      headerClickable={false}
      variant="primary"
      isOpen={contentOpen}
      onToggle={() => setContentOpen(!contentOpen)}
      {...rest}
    >
      <ChildrenContainer>
        {currentBp !== 'mobile' && (
          <JustificationContainer justification="space-between">
            <Small>Kenmerk</Small> <Small>Tag</Small>
          </JustificationContainer>
        )}

        {kenmerken.map(({ description, id, checked, tag }) => (
          <SelectableRowWithInput
            row={{
              description,
              id,
              checked,
              tag,
            }}
            key={`key-${id}`}
            checkboxTitle="Kenmerk"
            inputTitle="Tag"
            onChange={(value, field: Field) => onChange(id, value, field)}
            selectable={selectable}
          />
        ))}
      </ChildrenContainer>
    </CollapsibleChevronBlock>
  );
};

const Label = styled.span<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
  `,
);

const Small = styled.div<{}>`
  ${({ theme }) => css`
    font-size: ${theme.fs('s')};
    font-weight: ${theme.fw('semiBold')};
    width: 50%;
  `}
`;

const ChildrenContainer = styled.div<{}>`
  ${({ theme }) => css`
    padding: ${theme.space('m')} 0 ${theme.space('base')} ${theme.space('xxl')};

    ${theme.mq.lessThan('mobile')`
      padding: ${theme.space('s')};
    `}
  `}
`;

export default KenmerkGroup;
