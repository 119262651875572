import { createContext } from 'react';
import { ImpersonationViewingMode } from '~/graphql/types';

export type IViewingModeProps = {
  viewingMode: ImpersonationViewingMode;
};

const ViewingMode = createContext<IViewingModeProps>({
  viewingMode: ImpersonationViewingMode.Complete,
});

export default ViewingMode;
