import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  MARKETING_OPTION: `${cleanedFilename(__filename)}-marketing-option`,
  TRANSACTIONAL_OPTION: `${cleanedFilename(__filename)}-transactional-option`,
  FROM_ADDRESS_DROPDOWN: `${cleanedFilename(__filename)}-from-address-dropdown`,
  SIGNATURE_DROPDOWN: `${cleanedFilename(__filename)}-signature-dropdown`,
  IS_TRANSACTIONAL_SWITCHER: `${cleanedFilename(
    __filename,
  )}-is-transactional-switcher`,
  SUBJECT: `${cleanedFilename(__filename)}-subject`,
  BODY: `${cleanedFilename(__filename)}-body`,
};
