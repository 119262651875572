import { Link } from '@gatsbyjs/reach-router';
import React from 'react';
import styled, { css } from 'styled-components';
import DatHuisEmblemXBNDL from '~/components/atom/Logo/components/DatHuisEmblemXBNDL';

import WarningsContainer from '~/components/organism/WarningsContainer';
import { INSET_CONTAINER_WIDTH, TRIANGLE_HEIGHT } from '../../constants';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Props = {};

const Top: React.FCC<Props> = () => (
  <Container>
    <Triangle />
    <LogoContainer to="/-/">
      <DatHuisEmblemXBNDL />
    </LogoContainer>
    <Inner align="center" justification="center">
      <SizeRestriction>
        <WarningsContainer />
      </SizeRestriction>
    </Inner>
  </Container>
);

const Container = styled.div(
  ({ theme }) => css`
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    z-index: ${theme.z('top')};
    user-select: none;
    pointer-events: none;
  `,
);

const Inner = styled(JustificationContainer)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.space('base')};
    left: 0;
    right: 0;
    width: 100%;
  `,
);

const SizeRestriction = styled.div`
  max-width: ${INSET_CONTAINER_WIDTH}px;
  width: 100%;
`;

const Triangle = styled.div(
  ({ theme }) => css`
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: ${TRIANGLE_HEIGHT} 70vw 0 0;
    border-color: ${theme.color('primary', 'dark')} transparent transparent
      transparent;
    transform: rotate(0deg);
  `,
);

const LOGO_CONTAINER_SIZE = 70;
const LogoContainer = styled(Link)(
  ({ theme }) => css`
    position: absolute;
    width: ${LOGO_CONTAINER_SIZE}px;
    top: calc(${TRIANGLE_HEIGHT} - ${LOGO_CONTAINER_SIZE / 1.9}px);
    left: ${LOGO_CONTAINER_SIZE}px;
    z-index: ${theme.z('top')};
    pointer-events: all;
  `,
);

export default Top;
