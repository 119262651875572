import React from 'react';
import styled from 'styled-components';
import MarkButton, { MarkStyles } from '../../../Buttons/Mark';
import BlockButton from '../../../Buttons/Block';
import BaseButton from '../../../Buttons/components/Base';
import OptionsButton from '../../../Buttons/components/Options';
import {
  getAlignmentOptions,
  getFontSizeOptions,
} from '../../../Buttons/components/Options/buttonOptions';
import { toggleIndent } from '~/components/organism/PluginsEditor/commands/modify/block';
import { tooltipText } from '../../../Buttons/text';
import ColorButton from '../../../Buttons/Color';
import ELEMENTS from '../../../elements/elementsEnum';
import InsertImageButton from '../../../Buttons/InsertImage';
import InsertVariableButton from '../../../Buttons/InsertVariable';
import { HistoryEditor } from 'slate-history';
import EditHtmlButton from '../../../Buttons/EditHtml';
import Button from '~/components/atom/Button';
import TEST_ID from './index.testid';
import InsertLinkButton from '../../../Buttons/InsertLink';
import InsertHtmlButton from '../../../Buttons/InsertHtml';
import getTypographyOptions from '../../../Buttons/components/Options/buttonOptions/typography';
import type { DHEditor } from '~/components/organism/PluginsEditor/types';

type ToolbarButton = { type: 'button'; name: ButtonName };
type ToolbarDivider = { type: 'divider' };
export type ToolbarComponent = ToolbarButton | ToolbarDivider;

export type ButtonName =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'ordered-list'
  | 'unordered-list'
  | 'indent'
  | 'outdent'
  | 'align'
  | 'color'
  | 'background-color'
  | 'font-size'
  | 'font-family'
  | 'image'
  | 'variable'
  | 'link'
  | 'blockquote'
  | 'insert-html'
  | 'undo'
  | 'redo'
  | 'full-screen'
  | 'debug'
  | 'typography'
  | '|';

const text = {
  exitFullscreen: 'Volledig scherm verlaten',
  fontFamilyExplanation:
    'Ga naar Huisstijl in het menu om het lettertype te wijzigen',
};

export type Props = {
  editor: DHEditor;

  /** Custom elements in the editor */
  customElements: Array<ELEMENTS>;

  /** Is the editor in full screen mode */
  isFullScreen: boolean;

  /** Buttons to show */
  buttons: Array<ToolbarComponent>;

  /** Used in full-screen button */
  onChangeFullScreen: (value: boolean) => void;
};

const Buttons: React.FCC<Props> = ({
  buttons = [],
  editor,
  customElements,
  isFullScreen,
  onChangeFullScreen,
}) => (
  <>
    {buttons.map((button, index) => {
      if (button.type === 'divider') {
        return <CustomDivider key={`divider-${index}`} />;
      }

      switch (button.name) {
        case 'bold':
          return (
            <MarkButton
              icon="bold"
              format={MarkStyles.STRONG}
              editor={editor}
              dataTestId={TEST_ID.BOLD_BUTTON}
            />
          );

        case 'italic':
          return (
            <MarkButton
              icon="italic"
              format={MarkStyles.EM}
              editor={editor}
              dataTestId={TEST_ID.ITALIC_BUTTON}
            />
          );

        case 'underline':
          return (
            <MarkButton
              icon="underline"
              format={MarkStyles.U}
              editor={editor}
              dataTestId={TEST_ID.UNDERLINE_BUTTON}
            />
          );

        case 'ordered-list':
          return (
            <BlockButton
              icon="numbered-list"
              editor={editor}
              format={ELEMENTS.OL}
              dataTestId={TEST_ID.OL_BUTTON}
            />
          );

        case 'unordered-list':
          return (
            <BlockButton
              icon="list"
              editor={editor}
              format={ELEMENTS.UL}
              dataTestId={TEST_ID.UL_BUTTON}
            />
          );

        case 'typography':
          const typographyOptions = getTypographyOptions(editor);

          return (
            <OptionsButton
              icon="type"
              options={typographyOptions}
              tooltipMessage={tooltipText.typography}
              dataTestId={TEST_ID.TYPOGRAPHY_BUTTON}
              editor={editor}
            />
          );

        case 'indent':
          return (
            <BaseButton
              icon="indent"
              onClick={() => toggleIndent(editor, true)}
              tooltipMessage={tooltipText.indent}
              dataTestId={TEST_ID.INDENT_BUTTON}
            />
          );

        case 'outdent':
          return (
            <BaseButton
              icon="outdent"
              onClick={() => toggleIndent(editor, false)}
              tooltipMessage={tooltipText.outdent}
              dataTestId={TEST_ID.OUTDENT_BUTTON}
            />
          );

        case 'align':
          const alignmentOptions = getAlignmentOptions(editor);

          return (
            <OptionsButton
              icon="align-left"
              options={alignmentOptions}
              tooltipMessage={tooltipText.alignment}
              dataTestId={TEST_ID.ALIGN_BUTTON}
              persistFocus
              editor={editor}
            />
          );
        case 'color':
          return (
            <ColorButton
              format="color"
              icon="text-color"
              editor={editor}
              dataTestId={TEST_ID.COLOR_BUTTON}
            />
          );

        case 'background-color':
          return (
            <ColorButton
              format="backgroundColor"
              icon="text-background"
              editor={editor}
              dataTestId={TEST_ID.BACKGROUND_COLOR_BUTTON}
            />
          );

        case 'font-size':
          const fontSizeOptions = getFontSizeOptions(editor);

          return (
            <OptionsButton
              icon="font-size"
              options={fontSizeOptions}
              tooltipMessage={tooltipText.fontSize}
              dataTestId={TEST_ID.FONT_SIZE_BUTTON}
              persistFocus
              editor={editor}
            />
          );

        case 'font-family':
          return (
            <BaseButton
              icon="type"
              onClick={() => {}}
              tooltipMessage={text.fontFamilyExplanation}
              disabled
            />
          );

        case 'image':
          if (!customElements.includes(ELEMENTS.DH_IMAGE)) return null;

          return (
            <InsertImageButton
              dataTestId={TEST_ID.INSERT_IMAGE_BUTTON}
              editor={editor}
            />
          );

        case 'link':
          return (
            <InsertLinkButton
              dataTestId={TEST_ID.INSERT_LINK_BUTTON}
              editor={editor}
            />
          );

        case 'blockquote':
          return (
            <BlockButton
              icon="quote"
              editor={editor}
              format={ELEMENTS.BLOCKQUOTE}
              dataTestId={TEST_ID.BLOCKQUOTE_BUTTON}
            />
          );

        case 'variable':
          if (!customElements.includes(ELEMENTS.VARIABLE)) return null;

          return (
            <InsertVariableButton dataTestId={TEST_ID.INSERT_VARIABLE_BUTTON} />
          );

        case 'insert-html':
          return (
            <InsertHtmlButton
              dataTestId={TEST_ID.INSERT_HTML_BUTTON}
              editor={editor}
              customElements={customElements}
            />
          );

        case 'undo':
          return (
            <BaseButton
              icon="undo"
              onClick={() => HistoryEditor.undo(editor)}
              disabled={editor.history.undos.length === 0}
              tooltipMessage={tooltipText.undo}
              dataTestId={TEST_ID.UNDO_BUTTON}
            />
          );

        case 'redo':
          return (
            <BaseButton
              icon="redo"
              onClick={() => HistoryEditor.redo(editor)}
              disabled={editor.history.redos.length === 0}
              tooltipMessage={tooltipText.redo}
              dataTestId={TEST_ID.REDO_BUTTON}
            />
          );

        case 'full-screen':
          if (isFullScreen) {
            return (
              <Button
                label={text.exitFullscreen}
                onClick={() => onChangeFullScreen(false)}
                ghost
                margin={[null, 's']}
              />
            );
          } else {
            return (
              <BaseButton
                icon="maximize"
                onClick={() => onChangeFullScreen(true)}
                tooltipMessage={tooltipText.fullscreen}
                dataTestId={TEST_ID.REDO_BUTTON}
              />
            );
          }

        case 'debug':
          return (
            <EditHtmlButton editor={editor} customElements={customElements} />
          );

        default:
          return null;
      }
    })}
  </>
);

const CustomDivider = styled.div<{}>`
  width: 1px;
  height: 2.2em;
  margin: 0 5px;
  align-self: center;
  background-color: ${({ theme }) => theme.color('tertiary', 'light')};
`;

export default Buttons;
