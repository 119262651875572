import React from 'react';
import { isEmpty, isNil } from 'ramda';
import type { OptionOf } from '~/components/molecule/Dropdown';
import type {
  FlowV2_ToAddress,
  SessionHydrationOfficeFieldsFragment,
} from '~/graphql/types';
import styled, { css } from 'styled-components';
import type { ExpandedUsers } from '~/hooks/useUsers';
import { reporter } from '~/hooks/useErrorReporter';

export type ToAddressOption = OptionOf<{
  value: FlowV2_ToAddress;
  filterLabel: string;
  tagLabel: string | React.ReactNode;
}>;

const text = {
  userOwner: 'Eigenaar gebruiker',
  officeOwner: 'Eigenaar vestiging',
  noEmail: 'heeft geen e-mailadres',
  officeOwnerWarning: 'Zorg ervoor dat de vestiging een e-mailadres heeft.',
};

type Params = {
  /** To field */
  toAddress: FlowV2_ToAddress;

  /** Offices in account */
  offices: Array<SessionHydrationOfficeFieldsFragment>;

  /** Active users in account */
  users: ExpandedUsers;
};

/**
 * Creates a dropdown option to be used in the MultiSelectInput
 *
 * @param {string} toAddress - FlowV2_ToAddress
 * @param { Array<SessionHydrationOfficeFieldsFragment>} offices - Offices in account
 * @param {ExpandedUsers} users - Users in account
 *
 */
const convertToDropdownOption = ({
  toAddress,
  offices,
  users,
}: Params): ToAddressOption | null => {
  if (!toAddress) {
    reporter.captureMessage(
      `toAddress is missing in convertToDropdownOption: ${JSON.stringify(
        toAddress,
        null,
        2,
      )}`,
      'error',
    );
    return null;
  }

  const id = toAddress.target || toAddress.userId || toAddress.officeId;

  if (!id) {
    reporter.captureMessage(
      `Id is missing in toAddress of convertToDropdownOption: ${JSON.stringify(
        toAddress,
        null,
        2,
      )}`,
      'error',
    );
    return null;
  }

  if (toAddress.officeId) {
    const office = offices.find(office => office.id === toAddress.officeId);

    if (office) {
      const filterLabel = `${office.name} ${office.email || ''}`;
      const officeLink = `${process.env.BASE_URL}/-/settings/offices/${office.id}`;

      const noEmail = isNil(office.email) || isEmpty(office.email);

      if (noEmail) {
        const label = (
          <span>
            <CustomLink
              href={officeLink}
              target={'_blank'}
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              {office.name}
            </CustomLink>
            &nbsp; {text.noEmail}
          </span>
        );

        return {
          key: id || '',
          payload: {
            value: toAddress,
            filterLabel,
            tagLabel: label,
          },
          label,
          isValid: false,
          icon: { name: 'alert-octagon', color: { group: 'danger' } },
        };
      }

      const label = `${office.name} (${office.email || ''})`;

      return {
        key: id || '',
        payload: {
          value: toAddress,
          filterLabel: `${office.name} ${office.email || ''}`,
          tagLabel: label,
        },
        label,
      };
    }
  }

  if (toAddress.userId) {
    const user = users.find(user => user.id === toAddress.userId);
    if (user) {
      const label = `${user.name} (${user.email})`;
      const filterLabel = `${user.name} ${user.email}`;
      return {
        key: id || '',
        payload: {
          value: toAddress,
          filterLabel,
          tagLabel: label,
        },
        label,
      };
    }
  }

  if (toAddress.target) {
    if (toAddress.target === 'User_Owner') {
      const label = text.userOwner;
      return {
        key: id,
        payload: {
          value: toAddress,
          filterLabel: label,
          tagLabel: label,
        },
        label,
      };
    }

    if (toAddress.target === 'Office_Owner') {
      const label = text.officeOwner;
      return {
        key: id,
        payload: {
          value: toAddress,
          filterLabel: label,
          tagLabel: label,
        },
        label,
        icon: { name: 'exclamation', color: { group: 'warning' } },
        tooltipMessage: <span>{text.officeOwnerWarning}</span>,
      };
    }
  }

  return null;
};

/**
 *
 * Reach router's Link cannot open links on new tabs so we use a custom anchor tag.
 *
 * See:
 * https://github.com/reach/router/issues/310
 * https://github.com/gatsbyjs/gatsby/issues/13825
 *
 */
const CustomLink = styled.a<{}>(
  ({ theme }) => css`
    color: inherit;

    &:hover {
      text-shadow:
        0 0 0.65px ${theme.color('tertiary')},
        0 0 0.65px ${theme.color('tertiary')};
    }
  `,
);

export default convertToDropdownOption;
