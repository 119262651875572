import type { FormBuilder_Style_PageLayout_Default } from '~/graphql/types';

export type PageLayoutDefault = {
  type: 'default';
  props: {
    backgroundColor: string;
    backgroundImage?: {
      src: string;
    };
  };
};

const formatLayout = (
  input: FormBuilder_Style_PageLayout_Default,
): PageLayoutDefault => ({
  props: {
    backgroundColor: input.backgroundColor,
    backgroundImage: input.backgroundImage
      ? { src: input.backgroundImage.url }
      : undefined,
  },
  type: 'default',
});

export default formatLayout;
