import React, { useMemo } from 'react';
import styled from 'styled-components';
import Loading from '~/components/organism/Charts/DonutChart/components/Loading/';
import {
  cleanInflatedContactFilters,
  inflateContactFilters,
} from '~/components/organism/Filters/util/contactFilter/filterTranslationHelpers';
import {
  ContactFiltersFlattenedFragment,
  ContactFilters__Input,
  useGetQuickSegmentsQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import FilterBlock from '../FilterBlock';

export type Props = {
  /** Is used to select segment filters */
  onFilterChangeV2: (params: ContactFilters__Input) => void;
};

const QuickSegments: React.FCC<Props> = ({
  dataTestId,
  onFilterChangeV2,
  ...rest
}) => {
  const account = useCurrentAccount();
  const { data, loading } = useGetQuickSegmentsQuery({
    variables: { accountId: account.id },
  });

  const onItemSelect = useMemo(
    () => (filters: Array<ContactFiltersFlattenedFragment>) => {
      const inflatedFilters = cleanInflatedContactFilters(
        inflateContactFilters({
          contactFilters: filters,
        }),
      ) as ContactFilters__Input;

      onFilterChangeV2(inflatedFilters);
    },
    [onFilterChangeV2],
  );

  if (!data || loading) return <Loading />;

  const quickSegments = data.getQuickSegments;

  return (
    <Container data-testid={dataTestId} {...rest}>
      {quickSegments &&
        quickSegments.map(({ category, segments }) => (
          <FilterBlock
            key={category}
            items={segments}
            title={category}
            onItemSelect={onItemSelect}
          />
        ))}
    </Container>
  );
};

const Container = styled.div<{}>``;

export default QuickSegments;
