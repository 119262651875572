import { map, omit } from 'ramda';
import { uniqBy, flatten, union } from 'lodash';

/**
 * Return the union of the given arrays comparing using the first function
 *
 * Based on: https://stackoverflow.com/questions/13319150/union-of-array-of-objects-in-javascript
 */
export const collectionUnion = (
  arrays: Array<Array<any>>,
  getComparisonFor: (a: any) => boolean,
): Array<any> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  uniqBy(flatten(arrays), getComparisonFor);

/**
 * Map the array with given function. If the given function returns null or undefined, ignore it.
 */
export const mapNotNull = <T, Y>(
  array: Array<T>,
  mapFunction: (item: T, index: number) => Y | null | undefined,
): Array<Y> => {
  const filtered: Array<Y> = [];
  array.forEach((item: T, index) => {
    const result = mapFunction(item, index);
    if (result !== null && result !== undefined) filtered.push(result);
  });

  return filtered;
};

/**
 * Return the max date from the given function in the given array.
 * Return null if no date is ever gotten or the list is empty.
 */
export const maxDate = <T, Y extends Date>(
  array: Array<T>,
  maxValueFunction: (item: T) => Y | null | undefined,
): Y | null => {
  let maxValue: Y | null = null;
  array.forEach(item => {
    const value = maxValueFunction(item);
    if (value !== null && value !== undefined) {
      if (maxValue === null || value > maxValue) {
        maxValue = value;
      }
    }
  });
  return maxValue;
};

/**
 * Return the min date from the given function in the given array.
 * Return null if no date is ever gotten or the list is empty.
 */
export const minDate = <T, Y extends Date>(
  array: Array<T>,
  minValueFunction: (item: T) => Y | null | undefined,
): Y | null => {
  let minValue: Y | null = null;
  array.forEach(item => {
    const value = minValueFunction(item);
    if (value != null) {
      if (minValue === null || value < minValue) {
        minValue = value;
      }
    }
  });
  return minValue;
};

/**
 * Return the element with the lowest value of the given function.
 * Returns null if the list is empty
 */
export const minElement = <T>(
  array: Array<T>,
  minValueFunction: (item: T) => number | null,
): { element: T | null; index: number | null } => {
  let minElement: T | null = null;
  let minIndex: number | null = null;
  let minValue: number | null = null;

  array.forEach((item, idx) => {
    const value = minValueFunction(item);
    if (value != null && (minValue == null || value < minValue)) {
      minElement = item;
      minValue = value;
      minIndex = idx;
    }
  });

  return { element: minElement, index: minIndex };
};

/**
 * Return if the given function returns true for any element in the array
 *
 * Returns false if the array is empty
 */
export const anySatisfy = <T>(
  array: Array<T>,
  toCheckFunction: (item: T) => boolean,
): boolean => {
  const foundItemIndex = array.findIndex(
    item => toCheckFunction(item) === true,
  );

  return foundItemIndex >= 0;
};

/**
 * Return a new array without the _first_ element satisfying the given findFunction
 *
 * If none satisfy a copied array of the given one will be returned
 */
export const copyWithout = <T>(
  array: Array<T>,
  findFunction: (item: T) => boolean,
): Array<T> => {
  const foundIndex = array.findIndex(findFunction);

  if (foundIndex < 0) return [...array];

  return [
    ...array.slice(0, foundIndex),
    ...array.slice(foundIndex + 1, array.length),
  ];
};

/**
 * Return a new array without the _first_ element satisfying the given findFunction
 *
 * If none satisfy a copied array of the given one will be returned
 */
export const copyWithoutIndex = <T>(
  array: Array<T>,
  index: number,
): Array<T> => {
  if (index < 0 || index > array.length - 1) return [...array];

  return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
};

/**
 * Returns the array where any element that returns the same value on the getComparisonValue function is removed
 */
export const deduplicated = <T>(
  array: Array<T>,
  getComparisonValue: (a: T) => any,
): Array<T> => uniqBy(array, getComparisonValue);

/**
 * Returns a merged array where any items that have the same id will return the one with the highest version number.
 * Useful for merging subscriptions and infinite scrolling queries.
 *
 * Will be sorted as they come in (so [...firstArray, ...secondArray])
 */
export const mergeWithLatestVersion = <T extends { id: string; _v: number }>(
  firstArray: Array<T>,
  secondArray: Array<T>,
): Array<T> => {
  const returnArray = [...firstArray];

  secondArray.forEach(item => {
    const foundIdx = returnArray.findIndex(retItem => retItem.id === item.id);

    if (foundIdx >= 0) {
      if (returnArray[foundIdx]._v < item._v) {
        returnArray[foundIdx] = item;
      }
    } else {
      returnArray.push(item);
    }
  });

  return returnArray;
};

/**
 * Returns true if the two arrays have the same strings, regardless of order
 */
export const areStringArraysEqual = (
  arrayOne: Array<string>,
  arrayTwo: Array<string>,
): boolean => {
  if (arrayOne.length !== arrayTwo.length) return false;

  return union(arrayOne, arrayTwo).length === arrayOne.length;
};

/** Returns the sum of all items in the array */
export const sumArr = (arr: Array<number>): number =>
  arr.reduce((acc, curr) => acc + curr, 0);

export const omitKeys = (arr: Array<any>, keys: Array<string>) =>
  map(omit(keys), arr);
