import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import {
  BillingCycle,
  PlanFeatureCaveat,
  PlanOptionFragment,
  SelectedPlanFragment,
} from '~/graphql/types';
import { Body, Heading3 } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';

import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import TEST_ID from './index.testid';
import { CaveatIndexContainer } from '~/components/organism/PriceCard/components/Features';
import { pluck } from 'ramda';
import { billingCycleOptions } from '../PlanOverviewStep';
import LineTabs from '~/components/molecule/LineTabs';
import PriceCard from '~/components/organism/PriceCard';
import HighlightedLabel from '~/components/atom/HighlightedLabel';
import Catalog from '~/Catalog';

export type Props = {
  header?: string;
  onClose?: () => void;
  /** The subscription that is currently active */
  currentPlan?: SelectedPlanFragment | null;
  onContinue: (
    chosenPlan: PlanOptionFragment,
    chosenBillingCycle: BillingCycle,
  ) => void;

  planOptions: Array<PlanOptionFragment>;
  caveats: Array<PlanFeatureCaveat>;

  /** If null the option will not be shown to go to the unsubscribe flow */
  onGoToUnsubscribe: null | (() => void);
};

const text = {
  info: 'Ieder abonnement heeft een limiet aan gebruikers, vestigingen en contacten. Na het overschrijden van deze limieten, worden extra kosten in rekening gebracht. Gemaakte kosten worden naar rato in rekening gebracht en maandelijks gefactureerd aan het begin van de volgende facturatiecyclus. Alle bedragen zijn excl. BTW.',
  header: 'Wijzig abonnement',
  discountMessage: 'Bespaar ruim 20% met een jaarabonnement!',
  cancelButton: 'Abonnement opzeggen',
};

const ChoosePlanStep: React.FC<Props> = ({
  onClose,
  header,
  currentPlan,
  onContinue,
  onGoToUnsubscribe,
  planOptions,
  caveats,
  ...rest
}) => {
  const [plan, setPlan] = useState<PlanOptionFragment | null>(
    planOptions.find(({ id }) => id === currentPlan?.id) ?? null,
  );
  const [highlightedCaveat, setHighlightedCaveat] = useState<null | number>(
    null,
  );
  const [billingCycle, setBillingCycle] = useState<BillingCycle>(
    currentPlan?.billingCycle ?? BillingCycle.Yearly,
  );

  const onSelect = (option: PlanOptionFragment) => {
    setPlan(option);

    setTimeout(() => {
      onContinue(option, billingCycle);
    }, 500);
  };

  const isCurrentAvailable = currentPlan
    ? pluck('id', planOptions).includes(currentPlan.id)
    : // When no currentPlan all options are valid
      true;
  return (
    <Container
      {...rest}
      padding={['xl']}
      direction="column"
      align="center"
      dataTestId={TEST_ID.CONTAINER}
    >
      <Heading3 color={{ group: 'primary' }}>{header ?? text.header}</Heading3>
      <LineTabs
        selectedKey={billingCycle}
        tabs={billingCycleOptions.map(option => ({
          key: option.value,
          label: option.label,
          dataTestId: option.value,
        }))}
        onSelect={billingCycle => {
          setBillingCycle(billingCycle as BillingCycle);
        }}
      />

      {billingCycle !== BillingCycle.Yearly && (
        <DiscountLabel size="base" fontWeight="medium" align="center">
          {text.discountMessage}
        </DiscountLabel>
      )}

      <JustificationContainer
        width="100%"
        direction="row"
        gap="l"
        justification="center"
        margin={['base', null, null, null]}
        align="stretch"
      >
        {planOptions.map((option, index) => {
          const isCurrentPlan = currentPlan?.id === option.id;

          // Dedupe all features from previous tiers
          const features = option.features.filter(feature => {
            const prevTier = index === 0 ? null : index - 1;
            if (prevTier !== null) {
              const prevFeatures = pluck(
                'text',
                planOptions[prevTier].features,
              );

              return prevFeatures.includes(feature.text) ? false : true;
            }
            return true;
          });

          return (
            <PriceCard
              key={`priceCard-${option.id}`}
              plan={option}
              features={features}
              previousTier={planOptions[index - 1]}
              billingCycle={billingCycle}
              isSelected={option.id === plan?.id}
              isCurrentPlan={isCurrentPlan}
              isCurrentBillingCycle={currentPlan?.billingCycle === billingCycle}
              onSelect={() => {
                onSelect(option);
              }}
              dataTestId={option.id}
              highlightedCaveat={highlightedCaveat}
            />
          );
        })}
        {/*
         * When current.id is in availablePlans -> Select the current from available plans
         * if current.id is not in availablePlans -> Render separate immutable plan (additionally)
         */}
        {!isCurrentAvailable && currentPlan && (
          <PriceCard
            plan={{
              ...currentPlan,
              recommended: false,
              requiresBilling: true,
              __typename: 'PlanOption',
            }}
            billingCycle={billingCycle}
            isCurrentPlan={true}
            disabled={true}
            features={currentPlan.features}
            onSelect={() => {}}
            dataTestId={'current-plan'}
            highlightedCaveat={highlightedCaveat}
          />
        )}
      </JustificationContainer>

      <Body margin={['l', null, 'm', null]} size="xs">
        {text.info}
      </Body>

      <JustificationContainer
        width="100%"
        justification="start"
        margin={[null, null, null, null]}
      >
        <CaveatContainer>
          {caveats.map(({ __typename, text }, index) => (
            <Caveat
              $highlight={highlightedCaveat === index}
              key={__typename + index}
              onMouseOver={() => setHighlightedCaveat(() => index)}
              onMouseLeave={() => setHighlightedCaveat(() => null)}
            >
              <CaveatIndexContainer>{index + 1}</CaveatIndexContainer>
              <Body margin={[null]} size="xs">
                {text}
              </Body>
            </Caveat>
          ))}
        </CaveatContainer>
      </JustificationContainer>

      <JustificationContainer
        width="100%"
        justification="space-between"
        margin={['base', null, null, null]}
      >
        {onGoToUnsubscribe && (
          <Button
            ghost
            size="medium"
            label={text.cancelButton}
            onClick={onGoToUnsubscribe}
            appearance="danger"
            dataTestId={TEST_ID.GO_TO_CANCEL_STEP}
          />
        )}
        {onClose && (
          <TextButton
            onClick={onClose}
            label={Catalog.cancel}
            size="medium"
            appearance="danger"
          />
        )}
      </JustificationContainer>
    </Container>
  );
};

const DiscountLabel = styled(HighlightedLabel)(
  ({ theme }) => css`
    border-radius: 20px;
    padding: ${theme.space('s')} ${theme.space('m')};
    font-weight: ${theme.fontWeight('semiBold')};
    width: 100%;
  `,
);

const CaveatContainer = styled.ul(
  () => css`
    padding: 0;
    margin: 0;
    list-style: none;
  `,
);

const Caveat = styled.li<{ $highlight: boolean }>(
  ({ $highlight, theme }) => css`
    position: relative;
    text-decoration: ${$highlight ? 'underline' : 'none'};
    font-weight: ${$highlight ? theme.fw('semiBold') : theme.fw('regular')};
    transition: all 0.2s ease-out;
    cursor: pointer;

    span {
      margin-left: ${theme.space('base')};
    }
  `,
);

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    background: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

export default ChoosePlanStep;
