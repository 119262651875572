import type { ContactBlockMarketingFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Contact_Details__Input } from '~/graphql/types';

import asBooleanFilterInput from '~/components/organism/Filters/Primitive/Boolean/asBooleanFilterInput';

const asContactTagFilterInput = (
  filter: ContactBlockMarketingFilter,
): Node_NodeContainer_Leaf_Contact_Details__Input | null => ({
  blockMarketing: asBooleanFilterInput(filter.assignedFilter),
});

export default asContactTagFilterInput;
