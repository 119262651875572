import * as Sentry from '@sentry/react';

import {
  LocationProvider,
  Redirect,
  RouteComponentProps,
  useLocation,
} from '@gatsbyjs/reach-router';
import ReactGA from 'react-ga4';
import React from 'react';
import { createRoot } from 'react-dom/client';
import DHRouter from './components/atom/DHRouter';
import AccountSelectionWrapper from './components/page/AccountSelectionWrapper';
import Root from '~/Root';
import Auth from '~/Root/Auth';
import ForgotPassword from './components/page/Auth/ForgotPassword/index';
import Login from './components/page/Auth/Login';
import Register from './components/page/Auth/Register';
import Verify from './components/page/Auth/Verify/index';
import useAnalytics from './hooks/useAnalytics';
import SetupUserDetails from './components/page/Auth/SetupUserDetails';
import Logout from './components/page/Auth/Logout';
import SetupNewPassword from './components/page/Auth/SetupNewPassword';
import AuthWrapperHOC from './components/page/Auth/components/AuthWrapperHOC';
import DatHuisLoading from './components/atom/DatHuisLoading';
import MarkerIoProvider from './contexts/MarkerIOContext';

const MyApp: React.FCC<RouteComponentProps> = () => {
  useAnalytics();

  return (
    <Root>
      <Auth>
        <AuthWrapperHOC>
          <MarkerIoProvider>
            <DHRouter basepath="/" height="auto" primary>
              <Login path="login" />
              <Logout path="logout" />
              <ForgotPassword path="forgot-password" />
              <Register path="register" />
              <SetupNewPassword path="setup-newpassword" />
              <SetupUserDetails path="setup-user-details" />
              <Verify path="verify" />
              <AccountSelectionWrapper path="/-/*" />
              <Redirector default />
            </DHRouter>
          </MarkerIoProvider>
        </AuthWrapperHOC>
      </Auth>
    </Root>
  );
};

const Redirector: React.FC<{ default?: boolean }> = () => {
  const location = useLocation();
  return (
    <>
      <DatHuisLoading />
      <Redirect default noThrow from="/*" to={`/-${location.pathname}`} />
    </>
  );
};

Sentry.init({
  dsn: 'https://9ac9ae134dcd45f7b61c5cffdef618c2@o1029640.ingest.sentry.io/5996656',
  environment:
    process.env.STAGE_NAME === 'prod'
      ? 'production'
      : process.env.STAGE_NAME || 'dev',
  release: process.env.APP_VERSION || 'n/a',
  denyUrls: ['localhost'],
  enabled: process.env.STAGE_NAME === 'prod',
});

const container = document.getElementById('root');

if (process.env.GA_ID) {
  ReactGA.initialize(process.env.GA_ID);
}

const root = createRoot(container!);
root.render(
  <LocationProvider>
    <MyApp path="/" />
  </LocationProvider>,
);
