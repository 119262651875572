import { reporter } from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';
import { TaskListType } from '../../types';

export const ensureStringIsTaskListType = (
  str: string,
): TaskListType | null => {
  const converted = str.toUpperCase();

  if (converted === 'Open'.toUpperCase()) {
    return 'Open';
  } else if (converted === 'Closed'.toUpperCase()) {
    return 'Closed';
  } else if (converted === 'Deleted'.toUpperCase()) {
    return 'Deleted';
  }

  reporter.captureException(
    new Error(
      `${cleanedFilename(
        __filename,
      )} | should not occur | converted (${converted}) is not a list type`,
    ),
  );

  return null;
};
