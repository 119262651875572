import { NylasState } from '../../types';

/**
 * Build the state string for v1
 * @param {NylasState} state - State object of Nylas v1
 * keywords: nylasstate
 */
const buildStateString = (state: NylasState): string =>
  encodeURIComponent(
    `${state.entityType}|${state.entityId}|${state.accountId}`,
  );

export default buildStateString;
