import { RefObject, useEffect } from 'react';

const useOnClickOutside = (
  ref: RefObject<HTMLDivElement>,
  handler: (event: MouseEvent) => void,
  /** Allows to pass another element that can be clickable outside of the first ref */
  relatedRef?: RefObject<HTMLElement>,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      if (
        relatedRef != null &&
        (!relatedRef.current ||
          relatedRef.current.contains(event.target as Node))
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, relatedRef, handler]);
};
export default useOnClickOutside;
