import { print } from 'graphql/language/printer';
import { isString } from 'lodash';

const subscriptionMessage = (
  getAppSyncAuthorizationInfo: () => Promise<{
    host: string;
    Authorization: string;
  }>,
) => ({
  applyMiddleware: async (options, next) => {
    const query = isString(options.query)
      ? options.query
      : print(options.query);

    // AppSync expects GraphQL operation to be defined as a JSON-encoded object in a "data" property
    options.data = JSON.stringify({
      query,
      variables: options.variables,
    });

    const authorization = await getAppSyncAuthorizationInfo();

    // AppSync only permits authorized operations
    options.extensions = { authorization };

    // AppSync does not care about these properties
    delete options.operationName;
    delete options.variables;
    // delete options.query;
    // Not deleting "query" property as SubscriptionClient validation requires it

    next();
  },
});

export default subscriptionMessage;
