import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';

type Props = {
  name: string;
  value: any;
};

const ExternalField: React.FCC<Props> = ({ name, value }) => {
  const { change } = useForm();
  useEffect(() => {
    // Update the form state when the object changes
    change(name, value);
  }, [change, name, value]);

  return null;
};

export default ExternalField;
