import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { ApolloError } from '@apollo/client';
import { isNil } from 'ramda';
import { isLiveStage } from '~/util';
import useCurrentUser from '../useCurrentUser';
import useCurrentAccount from '../useCurrentAccount';
/**
 * We define our own typing for Reporter so we can potentially switch it in the future without needing to update the usage.
 */
export type Reporter = {
  /**
   * Report Error to tracking system
   */
  captureException: (
    error: Error | ApolloError,
    severity?: Sentry.SeverityLevel | undefined,
    captureContext?: unknown | ((scope: Sentry.Scope) => Sentry.Scope),
  ) => void;
  /**
   * Report only as string/message to tracking system
   */
  captureMessage: (message: string, severity: Sentry.SeverityLevel) => void;
};

// When not in a live stage we want the App to crash hard on errors
// so devs and QA can easily tell when stuff is not correct instead of graceful failure in production
const staticReporter: Reporter = {
  captureException: (error, __severity) => {
    // eslint-disable-next-line no-console
    console.error(error);
  },
  captureMessage: (message, __severity) => {
    // eslint-disable-next-line no-console
    console.error(new Error(message));
  },
};

export const setScope = (
  severity?: Sentry.SeverityLevel,
  scope?: Sentry.Scope,
): Sentry.Scope | ((scope: Sentry.Scope) => Sentry.Scope) => {
  const setupScope = (scope: Sentry.Scope) => {
    if (severity) {
      scope.setLevel(severity);
    }

    return scope;
  };

  if (!scope) return setupScope;
  return setupScope(scope);
};

const isScope = (x: any): x is Sentry.Scope => '_notifyingListeners' in x;

export const reporter: Reporter = {
  captureException: (error, severity, captureContext) =>
    Sentry.captureException(error, scope => {
      if (isScope(scope)) {
        setScope(severity, scope);
      }
      setScope(severity);

      if (captureContext && typeof captureContext === 'function') {
        captureContext(scope);
      }
      if (typeof captureContext === 'object' && captureContext !== null) {
        Object.keys(captureContext).forEach(key => {
          scope.setContext(key, captureContext[key]);
        });
      }

      return scope;
    }),
  captureMessage: (error, severity) =>
    Sentry.captureMessage(error, setScope(severity)),
};

const useErrorReporter = (): Reporter => {
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();

  useEffect(() => {
    if (!isNil(currentUser)) {
      Sentry.setUser({
        id: currentUser.id,
        username: currentUser.name,
        // Sentry also accepts IP address and email but we don't want to send these over.
      });
    }

    if (!isNil(currentAccount)) {
      Sentry.setExtras({
        accountId: currentAccount.id,
        accountName: currentAccount.name,
      });
    }
  }, [currentUser, currentAccount]);

  if (!isLiveStage()) return staticReporter;

  return reporter;
};

export default useErrorReporter;
