/**
 *
 * Office component
 * Made by Adrian?
 */
import { Props } from '..';
import React from 'react';

const Office: React.FCC<Props> = ({ color = 'currentColor', ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 19.2002H21.9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 8.3999H3V19.1999H21V8.3999Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 4.7998H2V8.3998H22V4.7998Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.8 11.6001H6.5V19.2001H9.8V11.6001Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 11.6001H12.8V16.2001H17.5V11.6001Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Office;
