import { NotificationLevel } from '~/graphql/types';
import type { Mailbox } from '~/graphql/types.client.flow';
import { Warning } from '~/hooks/useEntityWarnings';
import type { OfficeLookupTable } from '~/hooks/useOfficeLookup';
import { isErrorEmailSyncStatus, MAILBOX_TYPE } from '~/util/constants';
import { generateHash } from '~/util/generateHash';

const errorMessagesForMailboxes = (
  mailboxes: Array<Mailbox>,
  officeLookup: OfficeLookupTable | null,
): Array<Warning> => {
  const result: Array<Warning> = [];

  mailboxes.forEach(mailbox => {
    switch (mailbox.__typename) {
      case MAILBOX_TYPE.UserMailbox:
        if (isErrorEmailSyncStatus(mailbox.email.syncState)) {
          const error = `Wij kunnen geen verbinding maken met ${mailbox.email.email} in je profiel. <a href="/-/settings/profile">Klik hier</a> om het e-mailadres opnieuw in te stellen.`;
          result.push({
            item: {
              __typename: 'NotificationListItem',
              level: NotificationLevel.Error,
              body: {
                __typename: 'NotificationMessage',
                htmlBody: error,
              },
            },
            hash: generateHash(error),
          });
        }

        break;
      case MAILBOX_TYPE.OfficeMailbox:
        if (isErrorEmailSyncStatus(mailbox.email.syncState)) {
          const error = `Wij kunnen geen verbinding maken met ${
            mailbox.email.email
          } van
           vestiging ${officeLookup?.[mailbox.officeId]?.name}.
           <a href="${`/-/settings/offices/${mailbox.officeId}`}">Klik hier</a>
           om het e-mailadres opnieuw in te stellen, of neem contact op met
           de beheerder van deze vestiging.`;

          result.push({
            item: {
              __typename: 'NotificationListItem',
              level: NotificationLevel.Error,
              body: {
                __typename: 'NotificationMessage',
                htmlBody: error,
              },
            },
            hash: generateHash(error),
          });
        }

        break;
      case MAILBOX_TYPE.AccountMailbox:
        if (isErrorEmailSyncStatus(mailbox.email.syncState)) {
          const error = `Wij kunnen geen verbinding maken met ${mailbox.email.email}
          gekoppeld aan het account. Let op! Flows kunnen geen e-mail
          versturen. <a href="/-/settings/account">Klik hier</a> om het
          emailadres opnieuw in te stellen, of neem contact op met de
          beheerder van dit account.`;

          result.push({
            item: {
              __typename: 'NotificationListItem',
              level: NotificationLevel.Error,
              body: {
                __typename: 'NotificationMessage',
                htmlBody: error,
              },
            },
            hash: generateHash(error),
          });
        }

        break;
    }
  });

  return result;
};

export default errorMessagesForMailboxes;
