import React from 'react';
import styled, { css } from 'styled-components';
import withRequiredMark from '~/hocs/withRequiredMark';

export type Props = {
  required?: boolean;
};

const AppEntryItemHeader: React.FCC<Props> = ({
  dataTestId,
  required = false,
  ...rest
}) => {
  if (required === true)
    return <WithRequiredMark dataTestId={dataTestId} {...rest} />;

  return <Container data-testid={dataTestId} {...rest} />;
};

const WithRequiredMark = withRequiredMark<Props & { dataTestId?: string }>(
  props => <Container data-testid={props.dataTestId} {...props} />,
);

const Container = styled.h3(
  ({ theme }) => css`
    font-size: ${theme.fs('m')};
    margin: ${theme.space('s')} 0;
    color: ${theme.color('text')};
    font-weight: ${theme.fw('semiBold')};
  `,
);

export default AppEntryItemHeader;
