/**
 * A generic function that helps getting parent ids
 * keywords: getParents
 */

interface ActionOrNode {
  id: string;
}
const getDirectParentIds = <T extends ActionOrNode>({
  items,
  item,
  getParentId,
}: {
  items: Array<T>;
  item: T;
  getParentId: (i: T) => string | undefined;
}) => {
  const arr: Array<string> = [];

  const addId = (node?: T) => {
    if (!node) return;

    const parentId = getParentId(node);
    if (!parentId) return;

    arr.push(parentId);

    const parentNode = items.find(n => n.id === parentId);
    addId(parentNode);
  };

  addId(item);

  return arr;
};

export default getDirectParentIds;
