import { isNil } from 'ramda';
import type {
  Node_Condition_Number__Input,
  Node_NodeContainer_Leaf_ZapierMetadata__Input,
} from '~/graphql/types';
import type { ZapierSubFilter } from './types.flow';

import { FILTER_SUB_TYPE } from './constants';
import cleanedFilename from '~/util/cleanedFilename';
import { isNonEmptyString } from '~/util/Validation/String';
import { getSelectedOperation } from '../Primitive/String/utils';
import { reporter } from '~/hooks/useErrorReporter';
import { Operator } from '../Primitive/Number/types.flow';

export const getOperatorFromFilter = (
  filter: Node_Condition_Number__Input,
): Operator => {
  if (!filter) return Operator.GREATER_THAN_EQUAL;

  const isEq = 'eq' in filter && filter.eq !== null;
  const isGt = 'gt' in filter && filter.gt !== null;
  const isGte = 'gte' in filter && filter.gte !== null;
  const isLt = 'lt' in filter && filter.lt !== null;
  const isLte = 'lte' in filter && filter.lte !== null;

  if (isEq) return Operator.EQUALS;
  if (isGt) return Operator.GREATER_THAN;
  if (isGte) return Operator.GREATER_THAN_EQUAL;

  if (isLt) return Operator.LESS_THAN_EQUAL;
  if (isLte) return Operator.LESS_THAN_EQUAL;

  return Operator.GREATER_THAN_EQUAL;
};
const asZapierLeafInput = (
  filter: ZapierSubFilter,
): Node_NodeContainer_Leaf_ZapierMetadata__Input | null => {
  let subFilter: Node_NodeContainer_Leaf_ZapierMetadata__Input | null = null;
  if (!filter.type) return null;

  switch (filter.type) {
    case FILTER_SUB_TYPE.EMPTY: {
      break;
    }

    case FILTER_SUB_TYPE.ZAPIER_METADATA: {
      const fieldType = getFieldTypeFromFilter(filter);
      if (isNil(fieldType)) break;
      if (fieldType === null) break;

      const property = getFieldPropertyFromFieldType(fieldType);
      const value = filter.value[property];

      const operation =
        fieldType === 'string'
          ? getSelectedOperation(value)
          : getOperatorFromFilter(value);
      const safeValue =
        fieldType === 'string'
          ? isNonEmptyString(value[operation])
            ? value
            : { ...value, [operation]: null }
          : value;

      subFilter = {
        zapierEventId: filter.key,
        key: filter.field,
        [fieldType]: safeValue,
      };

      break;
    }

    default: {
      reporter.captureException(
        new Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | filter (${filter}) not implemented`,
        ),
      );

      // do not break on a wrong input, just ignore it
      return null;
    }
  }

  if (isNil(subFilter)) {
    return null;
  }

  return subFilter;
};

export type FieldType = 'string' | 'number' | 'boolean';
export const getFieldTypeFromFilter = (
  filter: ZapierSubFilter,
): FieldType | null => {
  if (!filter || isNil(filter.value)) return null;
  if ('stringPrimitiveFilter' in filter.value) return 'string';
  if ('numberPrimitiveFilter' in filter.value) return 'number';
  if ('booleanPrimitiveFilter' in filter.value) return 'boolean';

  return null;
};

export type FieldProperty =
  | 'stringPrimitiveFilter'
  | 'numberPrimitiveFilter'
  | 'booleanPrimitiveFilter';
export const getFieldPropertyFromFieldType = (
  fieldType: FieldType,
): FieldProperty => {
  if (fieldType === 'string') return 'stringPrimitiveFilter';
  if (fieldType === 'number') return 'numberPrimitiveFilter';
  if (fieldType === 'boolean') return 'booleanPrimitiveFilter';

  return 'stringPrimitiveFilter';
};

export default asZapierLeafInput;
