import React from 'react';

import PaginationButton from './components/PaginationButton';
import range from '~/util/range';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  firstPage: 'Eerste pagina',
  lastPage: 'Laatste pagina',
  nextPage: 'Volgende pagina',
  previousPage: 'Vorige pagina',
};

type Props = {
  totalItems: number;
  currentPage?: number | string;
  itemsPerPage?: number;
  onSelect: (selectedPage: number) => void;
};

const Pagination: React.FCC<Props> = ({
  totalItems,
  currentPage = 1,
  itemsPerPage = 10,
  onSelect,
}) => {
  if (!totalItems) return null;
  const _currentPage = parseInt(currentPage.toString());

  const pageNeighbors = 2;

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startPage = Math.max(1, _currentPage - pageNeighbors);
  const endPage = Math.min(totalPages, _currentPage + pageNeighbors);

  const hasLeftSpill = startPage > 1;
  const hasRightSpill = totalPages > endPage;

  const fetchPageNumbers = () => {
    if (totalPages > 5) {
      if (_currentPage > totalPages) {
        return range(1, 5);
      }

      const pages = range(startPage, endPage);

      // handle: ... < {6 7} [8] {9 10} (10)
      if (hasLeftSpill && !hasRightSpill) {
        const extraPages = range(endPage - 4, startPage - 1);
        return [...extraPages, ...pages];
      }

      // handle: (1) {1 2} [3] {4 5} > ...
      if (!hasLeftSpill && hasRightSpill) {
        const extraPages = range(_currentPage + 3, startPage + 4);
        return [...pages, ...extraPages];
      }

      // handle: ... < {4 5} [6] {7 8} > ...
      return [...pages];
    }

    return range(1, totalPages);
  };

  const pages = fetchPageNumbers();

  const firstPageSelected = _currentPage === 1;
  const lastPageSelected = _currentPage === totalPages;
  const onePageToShow = totalPages === 1;
  const exceedsFivePages = totalPages > 5;

  return (
    <JustificationContainer
      width="100%"
      align="center"
      justification="space-between"
      margin={[null, null, 'xl', null]}
    >
      <JustificationContainer align="center" justification="center" gap="s">
        {exceedsFivePages && (
          <PaginationButton
            disabled={firstPageSelected}
            label={text.firstPage}
            size="medium"
            icon="chevron-start"
            onClick={() => {
              if (!firstPageSelected) {
                onSelect(1);
              }
            }}
            dataTestId={TEST_ID.GO_TO_START_BUTTON}
          />
        )}

        <PaginationButton
          disabled={onePageToShow || firstPageSelected}
          dataTestId={TEST_ID.GO_TO_PREVIOUS_BUTTON}
          icon="chevron-left"
          size="medium"
          label={text.previousPage}
          onClick={() => {
            if (!onePageToShow && !firstPageSelected) {
              onSelect(_currentPage - 1);
            }
          }}
        />
      </JustificationContainer>

      <JustificationContainer align="center" justification="center" gap="s">
        {hasLeftSpill && <span>&hellip;</span>}
        {pages.map((number, index) => {
          const isSelected = _currentPage === number || onePageToShow;
          return (
            <PaginationButton
              key={index}
              selected={isSelected}
              dataTestId={TEST_ID.NUMBER_BUTTON(number)}
              data-selected={isSelected}
              size="medium"
              onClick={() => {
                onSelect(number);
              }}
              label={number.toString()}
            />
          );
        })}
        {hasRightSpill && <span>&hellip;</span>}
      </JustificationContainer>

      <JustificationContainer align="center" justification="center" gap="s">
        <PaginationButton
          direction="rtl"
          disabled={lastPageSelected || onePageToShow}
          dataTestId={TEST_ID.GO_TO_NEXT_BUTTON}
          size="medium"
          icon="chevron-right"
          label={text.nextPage}
          onClick={() => {
            if (!lastPageSelected && !onePageToShow) {
              onSelect(_currentPage + 1);
            }
          }}
        />

        {exceedsFivePages && (
          <PaginationButton
            direction="rtl"
            disabled={lastPageSelected}
            dataTestId={TEST_ID.GO_TO_END_BUTTON}
            onClick={() => {
              if (!lastPageSelected) {
                onSelect(totalPages);
              }
            }}
            label={text.lastPage}
            size="medium"
            icon="chevron-end"
          />
        )}
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default Pagination;
