import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import { useGetMyAccountsQuery } from '~/graphql/types';
import MainApp from '~/MainApp';
import Loading from '~/components/atom/Loading';
import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/components/page/ErrorScreen';
import AccountLocked from '~/components/page/AccountStatus/AccountLocked';
import AccountCancelled from '~/components/page/AccountStatus/AccountCancelled';
import ErrorTypes from '~/ErrorTypes';
import { getErrorTypes } from '~/util/errorHandling';
import AccountPaused from '~/components/page/AccountStatus/AccountPaused';
import getCurrentAccount from '~/util/getCurrentAccount';
import DHRouter from '~/components/atom/DHRouter';
import SetupAccount from '../Auth/SetupAccount';
import AuthWrapperHOC from '../Auth/components/AuthWrapperHOC';

type Props = RouteComponentProps;

/**
 * Take care of the authentication and account routing
 */
const AccountSelectionWrapper: React.FCC<Props> = () => {
  const { data, loading, error, refetch } = useGetMyAccountsQuery();

  if (loading) return <Loading />;

  if (error) {
    /**
     * When a user gets deleted while still active inside the app
     * we get a `deletedUserError`. Redirect them to the logout page
     */
    const errorTypes = getErrorTypes(error);
    if (errorTypes.includes(ErrorTypes.deletedUserError)) {
      void navigate('/logout', {
        replace: true,
        state: { isUserDeleted: true },
      });

      return null;
    } else {
      return <ErrorScreen />;
    }
  }

  const account = getCurrentAccount(data);
  if (account == null)
    return (
      <AuthWrapperHOC bypassURLCheck>
        <SetupAccount refetchAccounts={refetch} />
      </AuthWrapperHOC>
    );

  /* Keep Logout on top so it is accessible on SetupAccount as well */
  return (
    <DHRouter basepath="/-">
      <AccountLocked path="/locked" />
      <AccountPaused path="/paused" />
      <AccountCancelled path="/cancelled" />
      <MainApp default accountId={account.id} />
    </DHRouter>
  );
};

export default withErrorBoundary(AccountSelectionWrapper, <ErrorScreen />);
