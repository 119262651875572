import { TaskStatus } from '~/graphql/types';
import { reporter } from '~/hooks/useErrorReporter';
import { TaskListType } from '../../types';

const baseQueryVariablesForListType = (
  listType: TaskListType,
): { status?: TaskStatus; deleted: boolean } => {
  switch (listType) {
    case 'Open':
      return {
        status: TaskStatus.Open,
        deleted: false,
      };
    case 'Closed':
      return {
        status: TaskStatus.Closed,
        deleted: false,
      };
    case 'Deleted':
      return {
        deleted: true,
      };
    default:
      reporter.captureException(new Error(`Invalid list type ${listType}`));
      return {
        status: TaskStatus.Open,
        deleted: false,
      };
  }
};

export default baseQueryVariablesForListType;
