import Catalog from '~/Catalog';

const text = {
  showMore: 'toon meer...',
  showLess: 'toon minder...',
  changeStatusButtonClose: 'Voltooien & sluiten',
  changeStatusButtonOpen: 'Terugzetten & sluiten',
  fields: {
    date: 'Deadline',
    userAndOfficeLabel: 'Verantwoordelijk',
  },
  validation: {
    required: Catalog.requiredField,
    date: 'Invalid date format',
    contact: Catalog.requiredField,
  },
  errorMessage: Catalog.genericUnknownErrorMessageShort,
};

export default text;
