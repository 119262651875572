import { SIGNUP_ID, getLocalStorageItem } from '~/util/localStorageKeys';

/**
 * Retrieves value from local storage, if present it will check if code is still valid if so returns it otherwise it'll be undefined
 * If any errors happen they will be ignored and undefined will be returned
 * @returns string | undefined
 */
const getSignupId = (): string | undefined => {
  const localstorageItem = getLocalStorageItem(SIGNUP_ID);
  if (!localstorageItem) return undefined;

  try {
    const { signupId }: { signupId: string } = JSON.parse(localstorageItem);
    return signupId || undefined;
  } catch {
    return undefined;
  }
};

export default getSignupId;
