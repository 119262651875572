export const text = {
  initialHeaderName: 'Inkomende koppeling',
  description:
    'Hier kun je extra velden aan de inkomende koppeling toevoegen. Standaard zijn de velden NAWTE en tags beschikbaar.',
  goBackLink: '/-/apps/zapier/events',
  children: null,
  modalHeader: 'Aanpassingen annuleren?',
  modalBody:
    'Weet je zeker dat jij je aanpassingen wilt annuleren? Dit kan niet worden terug gedraaid.',
  deleteModalBody:
    'Weet je zeker dat je deze koppeling wilt verwijderen? Dit kan niet ongedaan worden.',
};

export const breadcrumbs = [
  {
    to: '/-/apps',
    label: 'Apps',
  },
  { to: '/-/apps/zapier/events', label: 'Zapier' },
];
