import cleanedFilename from '~/util/cleanedFilename';

export default {
  SUBMIT_PASSWORD_BUTTON: `${cleanedFilename(
    __filename,
  )}-submit-password-button`,
  PASSWORD_INPUT_FIELD: `${cleanedFilename(__filename)}-password-input-field`,
  REPEAT_PASSWORD_INPUT_FIELD: `${cleanedFilename(
    __filename,
  )}-repeat-password-input-field`,
};
