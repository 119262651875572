import Dexie, { Table } from 'dexie';
import { reporter } from '~/hooks/useErrorReporter';
import { WizardState } from '../context/WizardContext';

const DB_KEY = 'wizardDB';

export type StoredState = WizardState & {
  id: string;
  accountId: string;
};

export class WizardDB extends Dexie {
  states!: Table<StoredState>;

  constructor() {
    super(DB_KEY);
    this.version(2).stores({
      states:
        '++id, accountId, currentSubStep, currentStep, steps, show, outputMap',
    });

    void this.open().catch(err => {
      reporter.captureException(err);
    });
  }
}

const db = new WizardDB();
export default db;
