import type { Node_Condition_Boolean__Input } from '~/graphql/types';
import type { BooleanFilter } from './types.flow';

const asBooleanFilterInput = (
  filter: BooleanFilter,
): Node_Condition_Boolean__Input => {
  const { eq, negate } = filter;

  return {
    eq,
    negate,
  };
};

export default asBooleanFilterInput;
