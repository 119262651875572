const fontWeight = {
  thin: 100,
  extraLight: 200,
  regular: 300,
  medium: 400,
  semiBold: 500,
  bold: 600,
};

export type SystemFontWeightMap = typeof fontWeight;
export type SystemFontWeight = keyof SystemFontWeightMap;
export default fontWeight;
