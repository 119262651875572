import type { Flow___ConditionPartial } from '../../../..';
import { clone } from 'ramda';
import { v4 } from 'uuid';

/**
 * Extracts a condition without arguments
 */
const getBaseCondition = (
  condition: Flow___ConditionPartial,
): Flow___ConditionPartial => ({
  ...clone(condition),
  args: condition.args.map(() => null),
  id: v4(),
});

export default getBaseCondition;
