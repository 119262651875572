import { unnest } from 'ramda';
import type {
  FormBuilder_EventNode_Mapping,
  FormBuilder_NodeFragment,
  FormBuilder_ScreenNodeFragment,
} from '~/graphql/types';
import isBlockWithOutput from '../../typeguards/isBlockWithOutput';

/**
 * Iterates over each screen node and adds an event node mapping for each output block
 *
 * @param {Array<FormBuilder_NodeFragment>} nodes - Current nodes
 * @param {{ [blockKey: string]: string }} eventKeyLookup - Lookup to find the matching event key for the mapping
 */
const generateMappingsForSubmitEventNode = ({
  nodes,
  eventKeyLookup,
}: {
  // Current nodes
  nodes: Array<FormBuilder_NodeFragment>;

  // Lookup to find the matching event key for the mapping
  eventKeyLookup: { [blockKey: string]: string };
}): Array<FormBuilder_EventNode_Mapping> =>
  unnest([
    ...nodes
      .filter(node => node.__typename === 'FormBuilder_ScreenNode')
      .map((node: FormBuilder_ScreenNodeFragment) =>
        node.blocks
          .filter(isBlockWithOutput)
          .map(block => {
            const matchingEventFieldKey = eventKeyLookup[block.key];
            if (!matchingEventFieldKey) return null;

            return {
              key: matchingEventFieldKey,
              pointer: [node.id, `${node.id}-${block.key}`],
              __typename: 'FormBuilder_EventNode_Mapping' as const,
            };
          })
          .filter((n): n is FormBuilder_EventNode_Mapping => n !== null),
      ),
  ]);

export default generateMappingsForSubmitEventNode;
