import {
  AppBbWaardecheck__Input,
  AppBbWaardecheckContainer__Input,
  AppBbWaardecheckImage__Input,
  AppBbWaardecheckLink__Input,
  AppBbWaardecheckText__Input,
  AppBbWaardecheckContainer,
  AppBbWaardecheckImage,
  AppBbWaardecheckLink,
  AppBbWaardecheckText,
  AppBbWaardecheckHref,
  AppBbWaardecheckHref__Input,
  AppBbWaardecheckUrl,
  AppBbWaardecheckUrl__Input,
  AppVboWaardecheckImage,
  AppVboWaardecheckLink,
  AppVboWaardecheckHref,
  AppVboWaardecheckText,
  AppVboWaardecheckText__Input,
  AppVboWaardecheckUrl__Input,
  AppVboWaardecheck__Input,
  AppVboWaardecheckUrl,
  AppVboWaardecheckContainer,
  AppVboWaardecheckContainer__Input,
} from '~/graphql/types';
import { AppWaardecheckFieldsFragment } from '../..';

/**
 * Transform the container to input params
 */
export const transformContainer = ({
  background,
  color,
}: AppBbWaardecheckContainer | AppVboWaardecheckContainer):
  | AppBbWaardecheckContainer__Input
  | AppVboWaardecheckContainer__Input => ({
  background,
  color,
});

/**
 * Transform the image to input params
 */
export const transformImage = (
  image?: AppBbWaardecheckImage | AppVboWaardecheckImage | null,
): AppBbWaardecheckImage__Input | undefined =>
  image ? { s3key: image.s3key } : undefined;

/**
 * Transform the link to input params
 */
export const transformLink = (
  link: AppBbWaardecheckLink | AppVboWaardecheckLink,
): AppBbWaardecheckLink__Input => ({
  text: transformText(link.text),
  href: transformUrl(link.href),
});

/**
 * Transform the link to input params
 */
export const transformHref = (
  link: AppBbWaardecheckHref | AppVboWaardecheckHref,
): AppBbWaardecheckHref__Input => ({
  href: transformUrl(link.href),
});

/**
 * Transform the text to input params
 */
export const transformText = ({
  en,
  nl,
}: AppBbWaardecheckText | AppVboWaardecheckText):
  | AppBbWaardecheckText__Input
  | AppVboWaardecheckText__Input => ({
  en: en ?? undefined,
  nl: nl ?? undefined,
});

/**
 * Transform the text to input params
 */
export const transformUrl = ({
  en,
  nl,
}: AppBbWaardecheckUrl | AppVboWaardecheckUrl):
  | AppBbWaardecheckUrl__Input
  | AppVboWaardecheckUrl__Input => ({
  en: en ?? undefined,
  nl: nl ?? undefined,
});

const transformToInput = (
  value: AppWaardecheckFieldsFragment,
): AppBbWaardecheck__Input | AppVboWaardecheck__Input => {
  const {
    route,
    address,
    general,
    about,
    appraisalRequest,
    appraisalRequestSuccess,
    comparison,
    propertyType,
    report,
    valuationFailure,
    valuationSuccess,
    when,
    why,
    widget,
  } = value;

  return {
    route,
    address: {
      image: transformImage(address.image),
      heading: transformText(address.heading),
      primaryButton: transformText(address.primaryButton),
      subHeading: transformText(address.subHeading),
    },
    general: {
      background: transformContainer(general.background),
      primary: transformContainer(general.primary),
      secondary: transformContainer(general.secondary),
      tertiary: transformContainer(general.tertiary),
      quaternary: transformContainer(general.quaternary),
      logoImage: transformImage(general.logoImage),
      fallbackImage: transformImage(general.fallbackImage),
      logoLink: transformHref(general.logoLink),
      cookieStatement: transformHref(general.cookieStatement),
      privacyStatement: transformHref(general.privacyStatement),
      currentLocationLabel: transformText(general.currentLocationLabel),
      googleAnalytics4: general.googleAnalytics4,
      googleAnalytics: general.googleAnalytics,
      facebookPixel: general.facebookPixel,
      html: general.html,
    },
    about: {
      body: transformText(about.body),
      heading: transformText(about.heading),
      image: transformImage(about.image),
      primaryButton: transformLink(about.primaryButton),
    },
    appraisalRequest: {
      heading: transformText(appraisalRequest.heading),
      image: transformImage(appraisalRequest.image),
      primaryButton: transformText(appraisalRequest.primaryButton),
      disclaimer: transformText(appraisalRequest.disclaimer),
    },
    appraisalRequestSuccess: {
      body: transformText(appraisalRequestSuccess.body),
      heading: transformText(appraisalRequestSuccess.heading),
      image: transformImage(appraisalRequestSuccess.image),
      primaryButton: transformLink(appraisalRequestSuccess.primaryButton),
    },
    comparison: { heading: transformText(comparison.heading) },
    propertyType: {
      heading: transformText(propertyType.heading),
      image: transformImage(propertyType.image),
    },
    report: {
      image: transformImage(report.image),
      heading: transformText(report.heading),
      estimationLabel: transformText(report.estimationLabel),
      estimationDisclaimer: transformText(report.estimationDisclaimer),
      conversionMessageHeading: transformText(report.conversionMessageHeading),
      conversionMessageBodyMore: transformText(
        report.conversionMessageBodyMore,
      ),
      conversionMessageBodyLess: transformText(
        report.conversionMessageBodyLess,
      ),
      conversionMessageFooter: transformText(report.conversionMessageFooter),
      conversionMessagePrimaryButton: transformText(
        report.conversionMessagePrimaryButton,
      ),
      propertyPrimaryButton: transformText(report.propertyPrimaryButton),
      propertySalePrice: transformText(report.propertySalePrice),
      higherSalePrice: transformText(report.higherSalePrice),
      lowerSalePrice: transformText(report.lowerSalePrice),
    },
    valuationFailure: {
      heading: transformText(valuationFailure.heading),
      image: transformImage(valuationFailure.image),
      body: transformText(valuationFailure.body),
      primaryButton: transformText(valuationFailure.primaryButton),
      secondaryButton: transformText(valuationFailure.secondaryButton),
    },
    valuationSuccess: {
      heading: transformText(valuationSuccess.heading),
      image: transformImage(valuationSuccess.image),
      primaryButton: transformText(valuationSuccess.primaryButton),
      disclaimer: transformText(valuationSuccess.disclaimer),
    },
    when: {
      heading: transformText(when.heading),
      image: transformImage(when.image),
    },
    why: {
      heading: transformText(why.heading),
      image: transformImage(why.image),
    },
    widget: {
      image: transformImage(widget.image),
      heading: transformText(widget.heading),
      body: transformText(widget.body),
      button: transformText(widget.button),
      buttonColor: transformContainer(widget.buttonColor),
      position: widget.position,
      // @TODO: Remove ?? true after https://app.shortcut.com/dathuis/story/6146/bbwaardecheck-showimageonmobile-is-not-saved
      showImageOnMobile: widget.showImageOnMobile ?? true,
    },
  };
};

export default transformToInput;
