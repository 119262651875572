import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { mapNotNull } from '~/util/array';

/**
 * Determine the error type of the graphql error given from the server
 *
 * Backend ensures the errorType property
 */
export const getErrorType = (
  graphqlError:
    | (GraphQLError & { errorType?: string | null | undefined })
    | ApolloError,
): string | null => {
  if (
    !graphqlError ||
    !('errorType' in graphqlError) ||
    graphqlError.errorType == null
  ) {
    return null;
  }

  return graphqlError.errorType;
};

/**
 * Get a list of error types from the apollo error
 */
export const getErrorTypes = (apolloError?: ApolloError): Array<string> => {
  if (!apolloError || apolloError.graphQLErrors == undefined) return [];

  // @ts-ignore
  return mapNotNull(apolloError.graphQLErrors, graphQLError =>
    // @ts-ignore
    getErrorType(graphQLError),
  );
};

/**
 * Determine the error message of the graphql error given from the server
 */
export const getErrorMessage = (graphqlError: GraphQLError): string | null => {
  if (!graphqlError || graphqlError.message == undefined) return null;

  return graphqlError.message;
};

/**
 * Get a list of error types from the apollo error
 */
export const getErrorMessages = (apolloError?: ApolloError): Array<string> => {
  if (!apolloError || apolloError.graphQLErrors == undefined) return [];

  // @ts-ignore
  return mapNotNull(apolloError.graphQLErrors, graphQLError =>
    getErrorMessage(graphQLError),
  );
};
