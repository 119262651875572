import React from 'react';
import { SortDirection } from '~/graphql/types';

export type Props = {
  direction: SortDirection | null;
  onClick: () => void;
};

const SortToggle: React.FCC<Props> = ({ direction, dataTestId, ...rest }) => (
  <svg
    width="1.1em"
    height="1.1em"
    viewBox="9 4 6 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={dataTestId}
    {...rest}
  >
    <path
      d="M12 14V19"
      stroke={direction === SortDirection.Desc ? '#2676A5' : '#DADFE6'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 16L12 19L9 16"
      stroke={direction === SortDirection.Desc ? '#2676A5' : '#DADFE6'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10L12 5"
      stroke={direction === SortDirection.Asc ? '#2676A5' : '#DADFE6'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 8L12 5L15 8"
      stroke={direction === SortDirection.Asc ? '#2676A5' : '#DADFE6'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SortToggle;
