import React from 'react';
import { FormData } from '../../';
import { Form, Field } from '~/components/organism/Forms';
import FormUtils from '~/components/organism/FormUtils';
import Button from '~/components/atom/Button';
import Validation from '~/util/Validation';
import { Body, Heading1 } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';

type Props = {
  handleSubmit: (fields: FormData) => void;
  validate: (fields: FormData) => Object;
  submitError: string | false;
  loading: boolean;
  initialValues?: FormData;
};
const text = {
  title: 'Account inrichten',
  companyLabel: 'Kantoornaam',
  saveLabel: 'Opslaan',
};

const CompanyDetailsForm: React.FCC<Props> = ({
  handleSubmit,
  validate,
  submitError,
  loading,
  initialValues,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validate={validate}
  >
    {({ handleSubmit, submitting, pristine }) => {
      const formSubmitting = loading || submitting;
      const initialForm =
        pristine &&
        (!initialValues || Validation.String.isEmpty(initialValues.company));

      return (
        <form
          onSubmit={handleSubmit}
          data-testid="step1-form"
          style={{ width: '100%' }}
        >
          <Heading1
            color={{ group: 'accent' }}
            size="xxxxl"
            whiteSpace="nowrap"
          >
            {text.title}
          </Heading1>

          {submitError ? (
            <Body
              size="base"
              margin={[null]}
              color={{ group: 'danger', variant: 'light' }}
            >
              {submitError}
            </Body>
          ) : null}

          <Field name="company">
            {({ input, meta: { error, touched } }) => (
              <Input
                size="large"
                label={{ text: text.companyLabel, color: { group: 'white' } }}
                type="text"
                externalErrors={
                  FormUtils.showError(error, touched)
                    ? [FormUtils.showError(error, touched)]
                    : []
                }
                disabled={formSubmitting}
                {...input}
              />
            )}
          </Field>

          <JustificationContainer
            align="center"
            justification="end"
            margin={['base', null, null, null]}
          >
            <Button
              size="large"
              appearance="primary"
              type="submit"
              disabled={formSubmitting || initialForm}
              data-testid="save-company-details-button"
              label={text.saveLabel}
            />
          </JustificationContainer>
        </form>
      );
    }}
  </Form>
);

export default CompanyDetailsForm;
