import React, { useEffect } from 'react';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import Button from '~/components/atom/Button';
import styled from 'styled-components';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { navigate } from '@gatsbyjs/reach-router';
import useOnboardingWizard from '~/hooks/useOnboardingWizard';
import { isNil } from 'ramda';
import Loading from '~/components/atom/Loading';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Props = {};

export const text = {
  wizardHeader: 'Inrichten account',
  buttonLabel: 'Ga door met onboarden',
};

// User will be redirected to this page if AccountSettings.metadata.setup.completed is false,
// then we immediately open onboarding wizard
const OnboardingWizardPage: React.FC<RouteComponentProps & Props> = () => {
  const account = useCurrentAccount();
  const { showOnboardingWizard } = useOnboardingWizard();

  const hasSubscription = !isNil(account.subscription);

  useEffect(() => {
    if (account.AccountSettings?.metadata?.setup?.completed === true) {
      void navigate('/-/');
      return;
    }

    if (hasSubscription) {
      showOnboardingWizard();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.AccountSettings?.metadata?.setup?.completed, hasSubscription]);

  // There is a moment where Account.status is already Active but subscription is still null,
  // we will be showing loading until subscription is set
  if (!hasSubscription) {
    return <Loading />;
  }

  // In case wizard was closed somehow (should not happen because we removed cancel button and on esc key)
  return (
    <Container align="center" justification="center">
      <Button
        size="large"
        appearance="accent"
        label={text.buttonLabel}
        onClick={showOnboardingWizard}
      />
    </Container>
  );
};

const Container = styled(JustificationContainer)`
  height: 100vh;
`;

export default OnboardingWizardPage;
