import { ClientFlowAction } from '~/graphql/types.client';
import getDirectParentIds from '../getDirectParentIds';

/**
 * Returns all the direct (primary) parent ids of an action
 * @param actions
 * @param action
 * @returns parentIds
 */
const getDirectParentIdsOfAction = (
  actions: Array<ClientFlowAction>,
  action: ClientFlowAction,
): Array<string> =>
  getDirectParentIds({
    items: actions,
    item: action,
    getParentId: a => ('parentIds' in a ? a.parentIds[0] : undefined),
  });

export default getDirectParentIdsOfAction;
