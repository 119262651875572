import React from 'react';
import styled, { css } from 'styled-components';
import type { IconType } from '~/components/atom/Icon';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Props = {
  name: IconType;
  color: string;
};

const SquareIcon: React.FC<Props> = ({ name, color }) => (
  <IconContainer padding={['xxs']} border={{ radius: 's' }} $color={color}>
    <Icon name={name} color="white" />
  </IconContainer>
);

const IconContainer = styled(JustificationContainer)<{ $color: string }>(
  ({ $color }) => css`
    background-color: ${$color};
  `,
);

export default SquareIcon;
