import React from 'react';
import TemplateStringInput from '~/components/organism/TemplateStringInput';
import useDHEditorInFlowBuilder from '../../../../hooks/useDHEditorInFlowBuilder';
import type { FlowV2_ParameterMappingFragment } from '~/graphql/types';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';

export type Props = {
  html: string | null | undefined;
  mappings: Array<FlowV2_ParameterMappingFragment> | undefined;
  actionId: string;
  updaterFn: (value: EditorValue) => void;
};

const TemplateStringField: React.FCC<Props> = ({
  dataTestId,
  html,
  mappings,
  actionId,
  updaterFn,
}) => {
  const { key, value, onChange } = useDHEditorInFlowBuilder({
    actionId,
    html: html || '',
    mappings: mappings || [],
    updaterFn,
  });

  return (
    <TemplateStringInput
      $key={key}
      onChange={onChange}
      value={value}
      dataTestId={dataTestId}
    />
  );
};

export default TemplateStringField;
