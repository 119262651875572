import React from 'react';
import {
  ActivityFields_Task_Contact_Fragment,
  Image,
  UpdateTaskMutationVariables,
  useUpdateTaskMutation,
} from '~/graphql/types';
import BaseEventCard from '../../BaseEventCard';
import asTaskProp from '~/components/page/Tasks/utils/asTaskProp';
import TaskBody from './components/TaskBody';
import BottomBar from './components/BottomBar';
import styled, { css } from 'styled-components';
import { iconForTypeInHeader } from '~/util/taskTypeOptions';
import { Task as TaskDetailProps } from '~/components/page/Tasks/types';
import useUpdateTimelineFn from '~/hooks/useUpdateTimelineFn';
import useConfirmModal from '~/hooks/useConfirmModal';
import useAddToast from '~/hooks/useAddToast';
import Catalog from '~/Catalog';
import formatToastMessage from '~/util/formatToastMessage';

export type Props = ActivityFields_Task_Contact_Fragment & {
  dataTestId?: string;
  onOpenTaskModal?: (task: TaskDetailProps | null) => void;
  header: {
    text: string;
  };
  icon: Image;
};

const text = {
  title: 'De taak verwijderen',
  message: 'Weet je zeker dat je de taak wilt verwijderen?',
  buttonConfirmTitle: 'Verwijderen',
  deleteSuccess: 'Taak is succesvol verwijderd.',
};

const Task: React.FC<Props> = ({ onOpenTaskModal, ...rest }) => {
  const addToast = useAddToast();
  const [updateTask, { loading }] = useUpdateTaskMutation();

  const { setShowModal, modal } = useConfirmModal({
    level: 'danger',
    labels: {
      title: text.title,
      message: text.message,
    },
    buttons: [
      {
        dataTestId: 'modal-confirm-button',
        label: text.buttonConfirmTitle,
        onClick: async e => {
          e.stopPropagation();
          e.preventDefault();

          await onUpdate({
            accountId: rest.accountId,
            id: rest.id,
            update: { deleted: true },
          });
        },
      },
    ],
  });
  const updateTimelineFn = useUpdateTimelineFn(rest.contactId);

  const onUpdate = async (variables: UpdateTaskMutationVariables) =>
    updateTask({
      variables,
    }).then(({ data, errors }) => {
      if (data?.updateTask) {
        updateTimelineFn(data.updateTask);
        return addToast([formatToastMessage(text.deleteSuccess, 'success')]);
      }

      if (errors && errors.length > 0) {
        addToast([
          formatToastMessage(Catalog.genericUnknownErrorMessageShort, 'danger'),
        ]);
      }
    });

  return (
    <>
      {modal}
      <BaseEventCard
        {...rest}
        dataTestId="Task_Card"
        data-objectid={rest.id}
        header={{
          text: rest.header.text,
          icon: iconForTypeInHeader(rest.type),
        }}
        onClick={() => onOpenTaskModal && onOpenTaskModal(asTaskProp(rest))}
        body={
          <Container>
            <TaskBody {...rest} />
          </Container>
        }
        bottomBar={
          <BottomBar
            onDelete={() => setShowModal(true)}
            loading={loading}
            accountId={rest.accountId}
            id={rest.id}
            onUpdate={onUpdate}
            status={rest.status}
          />
        }
      />
    </>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('base')};
  `,
);

export default Task;
