import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ControlledInput from '../../../components/ControlledInput';

export const URL_PREFIX = 'https://waarderapport.';

const text = {
  desiredURL: 'Het domein waar het waarderapport is',
  invalidValue:
    'Helaas, dit is geen geldige URL. Gebruik alleen kleine letters en geen spaties',
};

export type Props = {
  route: string;
  onChange: (route: string) => void;
};

const URLContainer: React.FCC<Props> = ({ route, onChange }) => {
  const [validationError, setValidationError] = useState<string | null>(null);
  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    if (touched === true && (route === '' || route?.includes(' '))) {
      return setValidationError(text.invalidValue);
    }

    return setValidationError(null);
  }, [route, touched]);

  return (
    <Container>
      <Label error={validationError}>
        {validationError ? validationError : text.desiredURL}
      </Label>
      <ControlledInput
        prefix={URL_PREFIX}
        errors={validationError ? [validationError] : []}
        id="route"
        value={route}
        onChange={value => {
          onChange(value?.toLowerCase() || '');
        }}
        onFocus={() => setTouched(true)}
        name="url-link"
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
`;

const Label = styled.label<{ error: string | null }>(
  ({ theme, error }) => css`
    display: block;
    margin: ${theme.space('xxs')} 0 ${theme.space('xxs')} 0;
    color: ${error ? theme.color('danger') : theme.color('text')};
  `,
);

export default URLContainer;
