import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import type { SystemSize } from '~/theme';
import type { SystemBoxShadow } from '~/theme/System/tokens';
import type { BorderRadius, BorderWidth } from '~/theme/System/tokens/border';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';
import arrayToCss from '~/util/arrayToCss';

export type Props = HTMLAttributes<HTMLDivElement> & {
  dataTestId?: string;
  margin?: Array<SystemSize | null>;
  padding?: Array<SystemSize | null>;
  width?: string;
  height?: string;
  backgroundColor?: ThemeColor;
  border?: {
    style?: string;
    radius?: BorderRadius;
    width?: BorderWidth;
    color?: ThemeColor;
  };
  children?: React.ReactNode;
  boxShadow?: SystemBoxShadow;
};

const Div = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      dataTestId,
      children,
      width,
      height,
      margin,
      padding,
      border,
      backgroundColor,
      boxShadow,
      ...rest
    },
    ref,
  ) => (
    <Container
      data-testid={dataTestId}
      $width={width}
      $height={height}
      $margin={margin}
      $padding={padding}
      $border={border}
      $backgroundColor={backgroundColor}
      ref={ref}
      $boxShadow={boxShadow}
      {...rest}
    >
      {children}
    </Container>
  ),
);

type ContainerProps = {
  $width?: Props['width'];
  $height?: Props['height'];
  $padding?: Props['padding'];
  $margin?: Props['margin'];
  $border?: Props['border'];
  $backgroundColor?: Props['backgroundColor'];
  $boxShadow?: Props['boxShadow'];
};

const Container = styled.div<ContainerProps>(
  ({
    theme,
    $width = 'auto',
    $height = 'auto',
    $padding = [],
    $margin = [],
    $backgroundColor,
    $border,
    $boxShadow,
  }) => css`
    width: ${$width};
    height: ${$height};
    padding: ${arrayToCss($padding, theme)};
    margin: ${arrayToCss($margin, theme)};

    background-color: ${$backgroundColor
      ? theme.color($backgroundColor.group, $backgroundColor.variant)
      : 'unset'};

    ${$border?.width &&
    css`
      border: ${theme.getTokens().border.width[$border.width]}
        ${$border.style || 'solid'}
        ${theme.color(
          $border?.color?.group || 'primary',
          $border?.color?.variant || 'base',
        )};
    `}

    ${$boxShadow &&
    css`
      box-shadow: ${theme.boxShadow($boxShadow)};
    `}

    border-radius: ${$border?.radius
      ? theme.getTokens().border.radius[$border.radius]
      : 'unset'};
  `,
);

export default Div;
