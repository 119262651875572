import React from 'react';
import { type ActionModalProps, type ContactAction } from '../../constants';
import { modalMap } from './modalMap';

type Props = { actionType: ContactAction | null } & ActionModalProps;

const ActionModal: React.FC<Props> = ({ actionType, ...rest }) => {
  if (actionType === null) {
    return null;
  }

  const Modal = modalMap[actionType];
  return <Modal {...rest} />;
};

export default ActionModal;
