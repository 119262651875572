import React from 'react';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import type { ActionModalProps } from '../../constants';

export type Props = {
  dataTestId?: string;
} & ActionModalProps;

const SendToContaqt: React.FCC<Props> = ({ onCancel, onComplete, ...rest }) => (
  <ConfirmActionModal
    actionType="SendToContaqt"
    actionInput={{
      SendToContaqt: {},
    }}
    onComplete={() => {
      onComplete();
    }}
    onCancel={onCancel}
    {...rest}
  />
);

export default SendToContaqt;
