import { useFlag } from '@unleash/proxy-client-react';

/**
 * List all flags created inside of Gitlab
 * When adding new once please update this type and also list them in `docs/components/FeatureFlags/index.tsx`
 * @see https://gitlab.dathuis.nl/dh/sfa-app/-/feature_flags
 */
export type Flag =
  | 'is-developer'
  | 'holiday-cheer'
  | 'pride-month'
  | 'homekeur'
  | 'contaqt'
  | 'stellax'
  | 'custom-html'
  | 'form-builder';

const useDHFlag = (flag: Flag): boolean => {
  const enabled = useFlag(flag);

  return enabled;
};

export default useDHFlag;
