import Catalog from '~/Catalog';
import { isValidURL } from '../Validation/URL';
import hasValue from '../hasValue';

const text = {
  invalidUrl: Catalog.invalidURL,
  required: 'Adres van je website is een verplicht veld',
};
const webSiteURLValidation = (value: string) =>
  !hasValue(value) ? text.required : isValidURL(value) ? true : text.invalidUrl;

export default webSiteURLValidation;
