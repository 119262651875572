import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import useEdgeDetectionContext from '~/components/organism/PluginsEditor//hooks/useEdgeContext';
import HidableComponent from '~/components/atom/HidableComponent';
import { ErrorBoundary } from '@sentry/react';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import Catalog from '~/Catalog';
import useRepositionToBeInView from '~/hooks/useRepositionToBeInView';

export type Props = {
  isVisible: boolean;
  onClickOutside: () => void;
  elementToPlaceUnder: HTMLElement | null;
};

const ResponsivePopup: React.FCC<Props> = ({
  dataTestId,
  onClickOutside,
  isVisible,
  elementToPlaceUnder,
  children,
}) => {
  const edge = useEdgeDetectionContext();

  const [componentRef, transform, recalculatePosition] =
    useRepositionToBeInView<HTMLDivElement>({
      element: elementToPlaceUnder,
      placement: 'bottom-right',
    });

  useEffect(() => {
    if (elementToPlaceUnder !== null) {
      recalculatePosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, edge, elementToPlaceUnder]);

  if (isVisible) {
    return (
      <HidableComponent onClickOutside={onClickOutside} allowBubble>
        {ref => {
          const storeAndForwardRef = (r: null | HTMLDivElement) => {
            componentRef.current = r;
            ref.current = r;
          };

          return (
            <PositionContainer
              ref={storeAndForwardRef}
              $translateX={transform.x}
              $translateY={transform.y}
              data-testid={dataTestId}
            >
              <ErrorBoundary
                fallback={
                  <AppErrorScreen
                    message={Catalog.genericUnknownErrorMessage}
                  />
                }
              >
                {children}
              </ErrorBoundary>
            </PositionContainer>
          );
        }}
      </HidableComponent>
    );
  }

  return null;
};

const PositionContainer = styled.div<{
  $translateX: number;
  $translateY: number;
}>`
  position: absolute;
  top: 2.75em;

  ${({ theme, $translateX: translateX, $translateY: translateY }) => css`
    transform: translateX(${translateX}px) translateY(${translateY}px);
    z-index: ${theme.z('top')};
  `}
`;

// Wrap the children with this component if you want to style the element
export const BoxShadowContainer = styled.div<{}>`
  width: 200px;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    background-color: ${theme.color('white')};
    padding: ${theme.space('s')};
    box-shadow: ${theme.boxShadow('base')};
  `}
`;

export default ResponsivePopup;
