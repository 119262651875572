import { memoize } from 'underscore';
import { TableHeaderDefinitionCellType } from '~/components/bad/DataTables/types.flow';
import TableHeaderCell from '~/components/bad/DataTables/util/TableHeaderCell';
import TEST_ID from './index.testid';

const accountUsersListColumns = (
  _key,
): Array<TableHeaderDefinitionCellType<any>> => [
  TableHeaderCell.string({
    label: 'Naam',
    dataProperty: 'name',
    width: 200,
    dataTestId: TEST_ID.NAME,
  }),
  TableHeaderCell.string({
    label: 'Email',
    dataProperty: 'email',
    dataTestId: TEST_ID.EMAIL,
    width: 200,
  }),
  TableHeaderCell.string({
    label: 'Role',
    dataProperty: 'role',
    dataTestId: TEST_ID.ROLE,
    width: 100,
  }),
  TableHeaderCell.action({
    dataTestId: TEST_ID.ACTION,
    minWidth: 80,
  }),
];

const memoizedAccountUsersListColumns = memoize(
  accountUsersListColumns,
  key => key,
);

export default memoizedAccountUsersListColumns;
