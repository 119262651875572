import { RefCallback, useCallback, useState } from 'react';

/**
 * Similar to useRef, but will update once the ref is available / changes.
 *
 * This is especially interesting if you need to make calculations once a ref is
 * available (the component was mounted).
 */
const useMountedRef = <T extends HTMLElement>(): [T | null, RefCallback<T>] => {
  const [ref, setRef] = useState(null);

  const refCallback = useCallback(node => {
    if (!node) return;
    if (ref === node) return;

    setRef(node);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [ref, refCallback];
};

export default useMountedRef;
