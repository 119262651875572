import { equals } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import getEditorErrors, {
  EditorError,
} from '~/components/organism/PluginsEditor/utils/getEditorErrors';
import useDebounce from '~/hooks/useDebounce';
import type { EditorValue } from '../../types';

type ReturnProps = {
  /** Key to pass to the Editor component to remount the editor after saving or cancelling changes */
  key: number;

  /** Value in Slate format to pass to the Editor component */
  value: EditorValue;

  /** Current errors in the editor */
  errors: Array<EditorError>;

  /** State if editor has unsaved changes */
  hasChanges: boolean;

  /**
   * Function to rerender the PluginsEditor, resetting only the editor's internal state (DHEditor),
   * such as clearing the history. This allows keeping the updated value while removing the editing history.
   *
   * Use this function to remove the history after saving changes but still retain the updated value
   * (e.g., in SignatureContainer component).
   */
  updateEditor: () => void;

  /**
   * Function to reset the DHEditor (including history, React states, etc.) and the value of the editor
   * (Array<Descendant>).
   *
   * Use this function when you want to discard all the changes made, such as when canceling changes or
   * after sending a direct email.
   *
   * You can set the value to a different value than the initial value by passing it in the arguments
   */
  resetEditor: (value?: { value: EditorValue }) => void;

  /** Callback to pass to the Editor component to update the value of the editor */
  onChange: (value: EditorValue) => void;
};

type Args = {
  /**
   * Pass a unique key to the editor if you use multiple editors in the same component
   * so that they get updated accordingly
   */
  initialKey?: number;

  /** Value in Slate format */
  initialValue: EditorValue;
};

const useEditorStates = ({
  initialKey = 0,
  initialValue,
}: Args): ReturnProps => {
  const [key, setKey] = useState<number>(initialKey);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [value, setValue] = useState<EditorValue>(initialValue);

  const updateEditor = useCallback(() => {
    setHasChanges(false);
    setKey(k => k + 0.001);
  }, []);

  const resetEditor = useCallback(
    x => {
      const _value = x && x.value ? x.value : initialValue;
      setValue(_value);
      updateEditor();
    },
    [initialValue, updateEditor],
  );

  const onChange = useCallback(
    val => {
      setHasChanges(!equals(val, initialValue));
      setValue(val);
    },
    [initialValue],
  );

  const debouncedValue = useDebounce(value, 300);
  const errors = useMemo(
    () => getEditorErrors(debouncedValue),
    [debouncedValue],
  );

  return useMemo(
    () => ({
      key,
      value,
      hasChanges,
      errors,
      updateEditor,
      resetEditor,
      onChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasChanges, value, key, errors],
  );
};

export default useEditorStates;
