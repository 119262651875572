import React, { useCallback, useMemo } from 'react';
import { useReactFlow } from 'reactflow';
import styled, { css } from 'styled-components';
import ControlButton, {
  Props as ControlProps,
} from './components/ControlButton';

export type Props = {
  dataTestId?: string;
  isFullScreen?: boolean;
  isBasicForm?: boolean;
  infoLink: string;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  toggleMiniMap?: () => void;
  onFullScreen: () => void;
};

const ControlHUD: React.FCC<Props> = React.memo(
  ({
    dataTestId,
    isFullScreen = false,
    infoLink,
    onFullScreen,
    onZoomIn,
    onZoomOut,
    toggleMiniMap,
    ...rest
  }) => {
    const { zoomIn, zoomOut } = useReactFlow();

    const onZoomInHandler = useCallback(() => {
      zoomIn?.();
      onZoomIn?.();
    }, [zoomIn, onZoomIn]);

    const onZoomOutHandler = useCallback(() => {
      zoomOut?.();
      onZoomOut?.();
    }, [zoomOut, onZoomOut]);

    const controls: Array<ControlProps> = useMemo(
      () =>
        [
          {
            icon: 'plus',
            tooltipLabel: 'Zoom in',
            onClick: () => onZoomInHandler(),
          },
          {
            icon: 'minus',
            tooltipLabel: 'Zoom uit',
            onClick: () => onZoomOutHandler(),
          },
          {
            icon: isFullScreen ? 'minimize' : 'maximize',
            tooltipLabel: 'Fullscreen',
            onClick: () => onFullScreen(),
          },
          {
            icon: 'map',
            tooltipLabel: 'Kaart',
            onClick: () => toggleMiniMap && toggleMiniMap(),
          },
          {
            icon: 'question-mark',
            tooltipLabel: 'Help',
            link: infoLink,
          },
        ].filter(item => {
          if (toggleMiniMap === undefined) return item.icon !== 'map';
          return true;
        }) as Array<ControlProps>,
      [
        infoLink,
        isFullScreen,
        onFullScreen,
        onZoomInHandler,
        onZoomOutHandler,
        toggleMiniMap,
      ],
    );

    return (
      <Container data-testid={dataTestId} {...rest}>
        {controls.map(control => (
          <ControlButton key={control.tooltipLabel} {...control} />
        ))}
      </Container>
    );
  },
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('tertiary', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};
    background: ${theme.color('white')};
    width: fit-content;
    display: flex;
  `,
);

export default ControlHUD;
