import React from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import DHRouter from '~/components/atom/DHRouter';

import UserList from './components/UserList';
import UserDetails from './UserDetails';
import withErrorBoundary from '~/ErrorBoundary';

type Props = RouteComponentProps;

const User: React.FCC<Props> = () => (
  <DHRouter>
    <UserList path="/" />
    <UserDetails path="/:userId" />
  </DHRouter>
);

export default withErrorBoundary(User);
