import React from 'react';
import { Props } from '..';

const RemoveStyle: React.FCC<Props> = ({
  color = 'currentColor',
  ...props
}) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.3 11.1997L13.5 12.1997L13.8 12.9997H12.1L6.8 16.0997L6.1 18.2997C5.9 18.7997 6.2 19.3997 6.7 19.5997C7.2 19.7997 7.8 19.4997 8 18.9997L9.3 15.1997H14.6L16 18.9997C16.1 19.3997 16.5 19.6997 16.9 19.6997C17 19.6997 17.1 19.6997 17.2 19.5997C17.7 19.3997 18 18.7997 17.8 18.2997L15.3 11.1997Z"
      fill={color}
    />
    <path
      d="M17.9999 7.0999C17.6999 6.5999 17.0999 6.4999 16.5999 6.6999L14.1999 8.0999L13.0999 5.0999C12.9999 4.6999 12.5999 4.3999 12.1999 4.3999H11.4999C11.0999 4.3999 10.6999 4.6999 10.5999 5.0999L8.39995 11.5999L5.59995 13.3999C4.99995 13.7999 4.89995 14.2999 5.19995 14.7999C5.39995 15.0999 5.59995 15.2999 5.99995 15.2999C6.19995 15.2999 6.29995 15.1999 6.49995 15.0999L17.5999 8.3999C18.0999 8.1999 18.2999 7.4999 17.9999 7.0999ZM11.7999 7.3999L12.3999 9.0999L10.8999 9.9999L11.7999 7.3999Z"
      fill={color}
    />
  </svg>
);

export default RemoveStyle;
