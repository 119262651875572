import { Editor, Transforms, Element, Text } from 'slate';
import {
  CustomElement,
  DHEditor,
} from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { ReactEditor } from 'slate-react';
import { getElementWithType, isElementOfType } from '../..';
import { isBlockActive } from '../block';

export const LIST_TYPES = [ELEMENTS.OL, ELEMENTS.UL];

export const EMPTY_DIV_ELEMENT: CustomElement = {
  type: ELEMENTS.DIV,
  children: [],
};

export const toggleList = (
  editor: DHEditor,
  format: ELEMENTS.OL | ELEMENTS.UL,
) => {
  const isActive = isBlockActive(editor, format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });

  const listBlock = { type: format, children: [] };

  const selectedElements = editor.selection
    ? Array.from(
        Editor.nodes(editor, {
          at: Editor.range(editor, editor.selection),
          match: n => !Editor.isEditor(n) && Element.isElement(n),
          mode: 'highest',
        }),
      )
    : [];

  const hasMultipleElementsSelected = selectedElements.length > 1;

  if (!isActive) {
    if (hasMultipleElementsSelected) {
      Transforms.wrapNodes(
        editor,
        { type: ELEMENTS.LI, children: [] },
        { mode: 'highest' },
      );

      Transforms.wrapNodes(editor, listBlock, { mode: 'highest' });
    } else {
      Transforms.setNodes<Element>(editor, { type: ELEMENTS.LI });

      Transforms.wrapNodes(editor, listBlock);
    }
  } else {
    const selectedEl = getElementWithType({
      editor,
      type: ELEMENTS.LI,
    });
    const selectedLi = selectedEl?.element;

    const hasChildren =
      Element.isElement(selectedLi) &&
      selectedLi.type === ELEMENTS.LI &&
      selectedLi.children.length > 1;

    const hasBlockElementsInLI =
      hasChildren &&
      selectedLi.children.some(
        child =>
          Editor.isBlock(editor, child) || !Editor.isInline(editor, child),
      );

    // unwrap block if there are multiple divs in the list item otherwise set the block to div
    if (hasBlockElementsInLI) {
      Transforms.unwrapNodes(editor, {
        match: n => Element.isElement(n) && n.type === format,
      });
    } else {
      // set ol/ul to div
      Transforms.setNodes(editor, EMPTY_DIV_ELEMENT, {
        match: n =>
          Editor.isBlock(editor, n) && !isElementOfType(n, ELEMENTS.DIV),
      });
    }

    // remove li
    Transforms.unwrapNodes(editor, {
      match: n => isElementOfType(n, ELEMENTS.LI),
    });
  }

  ReactEditor.focus(editor);
};

export const nestList = (
  editor: DHEditor,
  format: ELEMENTS.UL | ELEMENTS.OL,
) => {
  // wrap current li element text with div element
  Transforms.wrapNodes(editor, EMPTY_DIV_ELEMENT, {
    match: n => Text.isText(n),
    mode: 'lowest',
  });

  // insert new li
  Transforms.insertNodes(editor, {
    type: ELEMENTS.LI,
    children: [{ text: '' }],
  });

  // Wrap the new li element with ul or ol
  Transforms.wrapNodes(
    editor,
    { type: format, children: [] },
    { match: n => isElementOfType(n, ELEMENTS.LI) },
  );
};
