import cleanedFilename from '~/util/cleanedFilename';

export default {
  OVERVIEW_HEADER_ADD_BUTTON: `${cleanedFilename(
    __filename,
  )}-overview-header-add-button`,

  OVERVIEW_HEADER_IMPORT_BUTTON: `${cleanedFilename(
    __filename,
  )}-overview-header-import-button`,
  OVERVIEW_HEADER_TITLE: `${cleanedFilename(__filename)}-overview-header-title`,
};
