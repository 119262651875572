import type { ContactAssignedToUserFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Contact_Details__Input } from '~/graphql/types';

import asAssignedToFilterInput from '../../Primitive/AssignedTo/asAssignedToFilterInput';

const asAssignedToUserFilterInput = (
  assignedToFilter: ContactAssignedToUserFilter,
): Node_NodeContainer_Leaf_Contact_Details__Input | null => {
  const assignedFilterInput = asAssignedToFilterInput(
    assignedToFilter.assignedFilter,
  );

  if (assignedFilterInput == null) {
    return null;
  }

  return {
    userId: assignedFilterInput,
  };
};

export default asAssignedToUserFilterInput;
