import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import { assoc, map, isNil } from 'ramda';
import React, { useState, useEffect } from 'react';
import {
  GetZapierEventQuery,
  useDeleteZapierEventMutation,
  useGetZapierEventQuery,
  useGetZapierEventsQuery,
  useUpdateZapierEventMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import Details from '../../../Details';
import { v1 as uuidv1 } from 'uuid';
import { ZapierFields } from '../../../Details/components/FieldsContainer';
import Loading from '~/components/atom/Loading';
import { breadcrumbs, text } from '../text';
import { cleanedFields } from '../../../utils/cleanedFields';
import useIsMounted from '~/hooks/useIsMounted';

export type Props = {} & RouteComponentProps<{ eventId: string }>;

const EditEvent: React.FCC<Props> = ({ dataTestId, eventId, ...rest }) => {
  const isMounted = useIsMounted();
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const [updateEvent, { loading: saveLoading }] =
    useUpdateZapierEventMutation();
  const {
    data,
    loading: eventLoading,
    updateQuery,
  } = useGetZapierEventQuery({
    variables: {
      accountId,
      id: eventId as string,
    },
  });

  const [event, setEvent] = useState<
    GetZapierEventQuery['getZapierEvent'] | undefined
  >();
  const [fieldsWithIds, setFieldsWithId] = useState<ZapierFields>([]);

  useEffect(() => {
    if (!isNil(data) && !isNil(data.getZapierEvent)) {
      setEvent(data.getZapierEvent);
      setFieldsWithId(
        map(field => assoc('id', uuidv1(), field), data.getZapierEvent.fields),
      );
    }
  }, [data]);

  const [deleteEvent, { loading: deleteLoading }] =
    useDeleteZapierEventMutation();

  const { updateQuery: updateAllEvents } = useGetZapierEventsQuery({
    variables: {
      accountId,
    },
  });

  if (!event || eventLoading) return <Loading />;

  const onError = (name: string) =>
    addToast([
      formatToastMessage(
        `Er is iets fout gegaan bij het opslaan van "${name}" inkomende koppeling, probeer het later opnieuw.`,
        'danger',
      ),
    ]);

  const onSave = async (name: string, fields: ZapierFields) => {
    const updatedEvent = {
      ...event,
      name,
      fields: cleanedFields(fields),
    };

    return updateEvent({
      variables: updatedEvent,
    }).then(({ errors }) => {
      if (errors && errors.length > 0) {
        return onError(event.name);
      }

      updateQuery(prev => ({
        ...prev,
        getZapierEvent: {
          ...prev.getZapierEvent,
          ...updatedEvent,
          fields: updatedEvent.fields.map(field => ({
            ...field,
            __typename: 'ZapierField',
          })),
        },
      }));
    });
  };

  const onDelete = async () => {
    await deleteEvent({
      variables: {
        accountId,
        id: event.id,
      },
    }).then(({ data: update, errors }) => {
      if (isNil(update) || errors?.length) {
        return onError(event.name);
      }
      return updateAllEvents(prev => ({
        ...prev,
        getZapierEvents: prev.getZapierEvents.filter(
          ({ id }) => id !== event.id,
        ),
      }));
    });

    if (!isMounted()) return;
    return navigate('/-/apps/zapier/events', { replace: true });
  };

  return (
    <Details
      {...rest}
      dataTestId={dataTestId}
      goBackLink={text.goBackLink}
      headerName={event.name}
      onSave={(name, fields) => onSave(name, fields)}
      description={text.description}
      initialFields={fieldsWithIds}
      breadcrumbs={[...breadcrumbs, { label: event.name }]}
      onDelete={onDelete}
      loading={saveLoading || deleteLoading}
    >
      {text.children}
    </Details>
  );
};

export default EditEvent;
