import generateKeyFromPath from '~/components/page/Automation/v2/util/generateKeyFromPath';
import { assertNever } from '~/util/assertion';
import type { FlowPathEntry, GetFieldsByPathOpts } from '..';
import getSubject, { isEqualSubject } from '../../getSubject';

const filterFields = <T extends FlowPathEntry>(
  fields: Array<T>,
  {
    limitToInstanceSubjects,
    usedSubjectMap,
    subjectMap,
    conditionType,
    action,
    onlyInstances = false,
    usedInstanceField,
  }: GetFieldsByPathOpts & {
    usedSubjectMap: Record<string, number | undefined>;
  },
): Array<T> =>
  fields.filter((field): boolean => {
    switch (field.__typename) {
      case 'FlowData___InstanceField': {
        if (!field.triggers && conditionType === 'trigger') return false;
        if (!field.condition && conditionType === 'condition') return false;
        if (field.never?.includes(action)) return false;
        if (field.only && !field.only.includes(action)) return false;
        if (usedSubjectMap[field.type.type]) return false;
        if (conditionType === 'trigger' && usedSubjectMap[field.parent.type])
          return false;

        if (usedInstanceField) {
          const path = field.instance?.key.concat(field.key);
          if (path && generateKeyFromPath(path) === usedInstanceField)
            return false;
        }

        if (limitToInstanceSubjects != null) {
          for (const subjectIdentifier of limitToInstanceSubjects) {
            if (isEqualSubject(subjectIdentifier, field.type)) return true;
          }

          /**
           * We assume that if a field has more "sub-fields" we should show it to
           * let them go deeper.
           *
           * In essence this could be a dead end (0 fields shown) if none of the "sub-fields"
           * contain a subject of the `limitToInstanceSubjects` type.
           *
           * In order to fix that we added the pruneEmptyFields.
           */
          return (
            getSubject(field.type, subjectMap)?.instanceFields.length !== 0
          );
        }

        return true;
      }
      case 'FlowData___SubjectField': {
        if (onlyInstances || limitToInstanceSubjects != null) return false;
        if (!field.triggers && conditionType === 'trigger') return false;
        if (!field.condition && conditionType === 'condition') return false;
        if (field.never?.includes(action)) return false;
        if (field.only && !field.only.includes(action)) return false;

        return true;
      }
      case 'FlowData___InstanceCondition': {
        if (onlyInstances || limitToInstanceSubjects != null) return false;
        return true;
      }
      case 'FlowData___Directory': {
        return true;
      }
      default:
        return assertNever(field);
    }
  });
export default filterFields;
