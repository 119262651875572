export enum SynchroniseEntityType {
  Account = 'Account',
  Office = 'Office',
  User = 'User',
}

export type NylasState = {
  entityType: SynchroniseEntityType;
  entityId: string;
  accountId: string;
};
