import { StateStep, WizardStep } from '../../context/WizardContext';

export const convertStepToStateStep = (step: WizardStep): StateStep => ({
  ...step,
  isFree: false,
});

/**
 * Converts WizardStep input to StateSteps
 * @param {Array<WizardStep>} steps - The steps you want to convert
 * keywords: wizard step, convert, State step
 */
const wizardStepsToStateSteps = (steps: Array<WizardStep>): Array<StateStep> =>
  steps.map((step): StateStep => {
    if (step.subSteps) {
      const newStep = {
        ...convertStepToStateStep(step),
        subSteps: step.subSteps.map(convertStepToStateStep),
      };

      return newStep;
    }

    return convertStepToStateStep(step);
  });

export default wizardStepsToStateSteps;
