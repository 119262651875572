import { createContext } from 'react';

export type Locale = 'nl-NL' | 'en-GB';

export type LocaleContextProps = {
  locale: Locale;
};

const LocaleContext = createContext<LocaleContextProps>({
  locale: 'nl-NL',
});

export default LocaleContext;
