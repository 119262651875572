import React from 'react';

import { FieldProps } from 'react-final-form';

import Field from './Field';
import passthrough from '~/util/passthroughFunction';
import { onlyDigits } from '~/util/string';

export default ({ name, format, parse, ...rest }: FieldProps<any, any>) => (
  <Field
    name={name}
    {...rest}
    format={format ? passthrough(formatNumber, format) : formatNumber}
    parse={parse ? passthrough(parseNumber, parse) : parseNumber}
  />
);

const formatNumber = (value: number | null): string | null =>
  value == null ? null : value.toString();

const parseNumber = (value: string | null): number | null => {
  if (value == null) return null;

  const cleanedString = onlyDigits(value);
  if (!cleanedString) return null;

  return parseInt(cleanedString);
};
