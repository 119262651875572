type RGB = { r: number; g: number; b: number };

export const hexToRgb = (hex: string): RGB => {
  const bigint = parseInt(hex.slice(1), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
};

export const interpolateColor = (
  color1: RGB,
  color2: RGB,
  factor: number,
): RGB => {
  const result: RGB = {
    r: Math.round(color1.r + factor * (color2.r - color1.r)),
    g: Math.round(color1.g + factor * (color2.g - color1.g)),
    b: Math.round(color1.b + factor * (color2.b - color1.b)),
  };
  return result;
};

export const rgbToHex = ({ r, g, b }: RGB): string =>
  `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

const generateColorScale = (
  colors: Array<string>,
  steps: number,
): Array<string> => {
  const colorScale: Array<string> = [];
  const numColors = colors.length;

  // Loop through each color pair
  for (let i = 0; i < numColors - 1; i++) {
    const color1: RGB = hexToRgb(colors[i]);
    const color2: RGB = hexToRgb(colors[i + 1]);

    // Interpolate between color1 and color2 for 'steps' steps
    for (let j = 0; j < steps; j++) {
      const factor: number = j / (steps - 1);
      const interpolatedColor: RGB = interpolateColor(color1, color2, factor);
      colorScale.push(rgbToHex(interpolatedColor));
    }
  }
  return colorScale;
};

export default generateColorScale;
