import { isNil } from 'ramda';
import { ExtendedSignature } from '~/hooks/useSignatures';

/**
 * Sort signatures as 1. User (own) signature 2. Account signature 3. Main office signature 4. Rest of the office signatures
 * @param {Array<ExtendedSignature>} signatures
 * @param {string} mainOfficeId
 * keywords: sort, signatureoptions
 */
const sortSignatures = ({
  signatures,
  mainOfficeId,
}: {
  signatures: Array<ExtendedSignature>;
  mainOfficeId?: string;
}): Array<ExtendedSignature> =>
  signatures
    .reduce((acc, s) => {
      if (s.__typename === 'Signature_User') {
        acc[0] = s;
      } else if (s.__typename === 'Signature_Account') {
        acc[1] = s;
      } else if (
        s.__typename === 'Signature_Office' &&
        mainOfficeId &&
        s.officeId === mainOfficeId
      ) {
        acc[2] = s;
      } else {
        acc.push(s);
      }
      return acc;
    }, Array(3).fill(null))
    .filter(signature => !isNil(signature));

export default sortSignatures;
