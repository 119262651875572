import type { FormBuilder_NodeFragment } from '~/graphql/types';
import generateIdForEntity from '~/util/generateIdForEntity';
import getNewNodeByCreateType from '../getNewNodeByCreateType';
import type { CreateRelation, CreateType } from '../../components/AddNodeMenu';
import { isNil } from 'ramda';
import { START_NODE_ID } from '../../components/Canvas/constants';

/**
 * Inserts a node and returns the updated nodes
 * @param {Array<FormBuilder_NodeFragment>} prevNodes
 * @param {CreateRelation} relation
 * @param {createType} createType
 */
const insertNode = ({
  createType,
  relation,
  prevNodes,
  name,
  nodeToInsert,
}: {
  /** What kind of node is being inserted */
  createType: CreateType;

  /** Determines where to insert the node */
  relation: CreateRelation;

  /** Existing nodes */
  prevNodes: Array<FormBuilder_NodeFragment>;

  /** Overwrites the name of the newly generated node */
  name?: string;

  /** If passed, it does not create a new node and inserts the given one */
  nodeToInsert?: FormBuilder_NodeFragment;
}): Array<FormBuilder_NodeFragment> => {
  const newNodeId =
    nodeToInsert?.id ??
    generateIdForEntity(
      createType === 'event'
        ? 'FORMBUILDER_EVENT_NODE'
        : 'FORMBUILDER_SCREEN_NODE',
    );
  const sourceNode = prevNodes.find(node => node.id === relation.source);

  if (!sourceNode) {
    if (relation.source === START_NODE_ID) {
      const newNode =
        nodeToInsert ??
        getNewNodeByCreateType({
          createType,
          newNodeId,
          targetId: relation.target,
          name,
        });

      if (newNode) return [...prevNodes, newNode];
    }

    // No-op
    return prevNodes;
  }

  const updatedSource: FormBuilder_NodeFragment = {
    ...sourceNode,
    defaultNext: {
      __typename: 'FormBuilder_Node_DefaultNext',
      targetNodeId: newNodeId,
    },
  };

  const newNode =
    nodeToInsert ??
    getNewNodeByCreateType({
      createType,
      newNodeId,
      targetId: relation.target,
      name,
    });

  // No-op
  if (isNil(newNode)) return prevNodes;

  const updatedNodes = [
    ...prevNodes.filter(({ id }) => id !== relation.source),
    updatedSource,
    newNode,
  ];

  // In basic form we add a separate event node along with the submit screen node
  if (createType === 'submitScreen') {
    const newEventNodeId = generateIdForEntity('FORMBUILDER_EVENT_NODE');
    const newEventNode = getNewNodeByCreateType({
      createType: 'event',
      targetId: newNodeId,
      newNodeId: newEventNodeId,
    });

    // No-op
    if (isNil(newEventNode)) return prevNodes;

    // Event node needs to be added before the submit scren node
    const source: FormBuilder_NodeFragment = {
      ...updatedSource,
      defaultNext: {
        __typename: 'FormBuilder_Node_DefaultNext',
        targetNodeId: newEventNodeId,
      },
    };

    const secondUpdate = [
      ...updatedNodes.filter(({ id }) => id !== source.id),
      source,
      newEventNode,
    ];

    return secondUpdate;
  }

  return updatedNodes;
};
export default insertNode;
