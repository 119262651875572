import { AppType } from '~/graphql/types';
import { AppConfig } from '../types';

const description = `
## Omschrijving
Met deze app koppel je het Realworks CRM aan de marketing automation van DatHuis. Bepaal welke relaties worden opgepakt in DatHuis op basis van Realworks relatiekenmerken. Start vervolgens flows in DatHuis om voor een ideale opvolging van leads en klanten te zorgen.

## Installatie
Activeer deze app en loop door de installatiewizard. Heb je hulp nodig? Laat het ons weten via de chat. We helpen je graag.`;

const termsOfUse =
  'Door het activeren van deze app geef je DatHuis toestemming om gegevens uit de door jouw gekoppelde Realworks API’s te verwerken. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account.';

export const realworks: AppConfig = {
  __typename: 'AppStatus_Realworks',
  appType: AppType.Realworks,
  type: 'with_config',
  slug: 'realworks',
  name: 'Realworks',
  tags: ['Koppeling', 'E-mailcampagne'],
  provider: 'DatHuis B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/realworks.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/realworks.svg',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers van Realworks',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/5447060-over-de-realworks-app',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'custom',
};
