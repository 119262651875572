import React from 'react';
import { Props } from '../';

const ARXGroepLogo: React.FCC<Props> = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Laag_1"
    x="0px"
    y="0px"
    viewBox="0 0 403.21 81.88"
  >
    <g>
      <g>
        <g>
          <path
            fill="#18466B"
            d="M391.67,16.33c0,0.09,0,0.16-0.01,0.22c-0.01,0.06-0.03,0.12-0.04,0.16s-0.04,0.07-0.07,0.09     c-0.03,0.02-0.06,0.03-0.09,0.03h-1.4v4.51c0,0.03-0.01,0.06-0.03,0.08s-0.05,0.04-0.1,0.06c-0.05,0.02-0.11,0.04-0.18,0.04     s-0.17,0.01-0.29,0.01c-0.12,0-0.22,0-0.3-0.01c-0.08-0.01-0.14-0.03-0.19-0.04c-0.05-0.02-0.08-0.04-0.1-0.06     c-0.02-0.02-0.04-0.05-0.04-0.08v-4.51h-1.37c-0.04,0-0.07-0.01-0.1-0.03c-0.03-0.02-0.05-0.05-0.07-0.09     c-0.02-0.04-0.04-0.1-0.05-0.16c-0.01-0.06-0.02-0.14-0.02-0.22c0-0.09,0.01-0.17,0.02-0.24s0.03-0.13,0.05-0.17     c0.02-0.04,0.05-0.07,0.07-0.09c0.03-0.02,0.06-0.03,0.1-0.03h4.01c0.03,0,0.06,0.01,0.09,0.03c0.03,0.02,0.05,0.05,0.07,0.09     s0.04,0.1,0.04,0.17S391.67,16.24,391.67,16.33z M399.41,21.35c0,0.03-0.01,0.06-0.03,0.08s-0.04,0.04-0.08,0.06     c-0.04,0.02-0.1,0.04-0.17,0.04c-0.07,0.01-0.17,0.01-0.28,0.01c-0.12,0-0.21,0-0.28-0.01s-0.13-0.03-0.17-0.04     s-0.07-0.04-0.08-0.06c-0.01-0.02-0.02-0.05-0.02-0.08v-4.43h-0.02l-1.66,4.47c-0.01,0.05-0.07,0.09-0.17,0.12     c-0.1,0.03-0.24,0.04-0.42,0.04c-0.16,0-0.29-0.01-0.38-0.04c-0.09-0.03-0.15-0.07-0.17-0.12l-1.62-4.47h-0.02v4.43     c0,0.03-0.01,0.06-0.02,0.08s-0.05,0.04-0.09,0.06s-0.1,0.04-0.17,0.04c-0.07,0.01-0.16,0.01-0.26,0.01c-0.12,0-0.21,0-0.29-0.01     s-0.13-0.03-0.17-0.04s-0.07-0.04-0.09-0.06s-0.02-0.05-0.02-0.08v-5.09c0-0.15,0.04-0.26,0.13-0.34     c0.09-0.08,0.19-0.12,0.32-0.12h0.76c0.13,0,0.25,0.01,0.34,0.03c0.09,0.02,0.17,0.06,0.24,0.12c0.07,0.05,0.13,0.12,0.18,0.21     c0.05,0.09,0.1,0.2,0.14,0.33l1.25,3.42l1.32-3.42c0.09-0.23,0.19-0.41,0.31-0.52c0.12-0.12,0.3-0.17,0.53-0.17h0.74     c0.13,0,0.24,0.04,0.32,0.12c0.08,0.08,0.12,0.2,0.12,0.34V21.35z"
          />
        </g>
        <g>
          <g>
            <circle fill="#36A9E1" cx="41.28" cy="40.97" r="35.43" />
          </g>
          <g>
            <g>
              <path
                fill="#18466B"
                d="M97.34,34.23h-3.75v2.27c0,2.4-1.95,4.35-4.35,4.35h-0.26V18.1h8.37c5.4,0,8.1,2.63,8.1,7.9       c0,2.66-0.69,4.69-2.06,6.11C102,33.53,99.99,34.23,97.34,34.23z M93.59,30.28h3.72c2.3,0,3.45-1.43,3.45-4.28       c0-1.41-0.28-2.42-0.84-3.03c-0.56-0.61-1.43-0.92-2.61-0.92h-3.72V30.28z"
              />
              <path
                fill="#18466B"
                d="M108.28,40.86V24.13h4.45v1.77c1.87-1.12,3.66-1.84,5.36-2.18v4.52c-1.81,0.38-3.36,0.77-4.65,1.17       l-0.67,0.23v11.21H108.28z"
              />
              <path
                fill="#18466B"
                d="M121.63,26.02c1.18-1.53,3.16-2.29,5.92-2.29c2.77,0,4.74,0.76,5.92,2.29c1.18,1.53,1.77,3.68,1.77,6.44       c0,5.87-2.57,8.8-7.7,8.8c-5.13,0-7.7-2.93-7.7-8.8C119.86,29.69,120.45,27.55,121.63,26.02z M125.1,36.24       c0.46,0.8,1.28,1.21,2.46,1.21c1.18,0,2-0.4,2.46-1.21c0.46-0.8,0.69-2.06,0.69-3.78c0-1.72-0.23-2.97-0.69-3.75       c-0.46-0.78-1.28-1.17-2.46-1.17c-1.18,0-2,0.39-2.46,1.17c-0.46,0.78-0.69,2.03-0.69,3.75       C124.41,34.18,124.64,35.44,125.1,36.24z"
              />
              <path
                fill="#18466B"
                d="M147.11,34.23h-3.75v2.03c0,2.54-2.06,4.59-4.59,4.59h-0.03V18.1h8.37c5.4,0,8.1,2.63,8.1,7.9       c0,2.66-0.69,4.69-2.06,6.11C151.78,33.53,149.76,34.23,147.11,34.23z M143.36,30.28h3.72c2.3,0,3.45-1.43,3.45-4.28       c0-1.41-0.28-2.42-0.84-3.03c-0.56-0.61-1.43-0.92-2.61-0.92h-3.72V30.28z"
              />
              <path
                fill="#18466B"
                d="M158.05,40.86V24.13h4.45v1.77c1.87-1.12,3.66-1.84,5.36-2.18v4.52c-1.81,0.38-3.36,0.77-4.65,1.17       l-0.67,0.23v11.21H158.05z"
              />
              <path
                fill="#18466B"
                d="M171.41,26.02c1.18-1.53,3.16-2.29,5.92-2.29c2.77,0,4.74,0.76,5.92,2.29c1.18,1.53,1.77,3.68,1.77,6.44       c0,5.87-2.57,8.8-7.7,8.8c-5.13,0-7.7-2.93-7.7-8.8C169.63,29.69,170.22,27.55,171.41,26.02z M174.87,36.24       c0.46,0.8,1.28,1.21,2.46,1.21c1.18,0,2-0.4,2.46-1.21c0.46-0.8,0.69-2.06,0.69-3.78c0-1.72-0.23-2.97-0.69-3.75       c-0.46-0.78-1.28-1.17-2.46-1.17c-1.18,0-2,0.39-2.46,1.17c-0.46,0.78-0.69,2.03-0.69,3.75       C174.19,34.18,174.41,35.44,174.87,36.24z"
              />
              <path
                fill="#18466B"
                d="M188.31,47.89V24.13h4.45v0.9c1.45-0.87,2.72-1.31,3.82-1.31c2.25,0,3.92,0.68,4.99,2.03       c1.07,1.35,1.61,3.64,1.61,6.88c0,3.24-0.59,5.48-1.77,6.74c-1.18,1.26-3.12,1.89-5.82,1.89c-0.74,0-1.53-0.07-2.38-0.2       L192.8,41v2.58c0,2.38-1.93,4.31-4.31,4.31H188.31z M195.71,27.74c-0.83,0-1.66,0.17-2.51,0.5l-0.4,0.17v8.77       c1,0.13,1.82,0.2,2.44,0.2c1.29,0,2.18-0.38,2.66-1.14c0.48-0.76,0.72-2.05,0.72-3.88C198.62,29.28,197.65,27.74,195.71,27.74z       "
              />
              <path
                fill="#18466B"
                d="M211.02,36.63c0.52,0.46,1.28,0.69,2.26,0.69c2.08,0,3.93-0.07,5.56-0.2l0.94-0.1l0.07,3.31       c-2.57,0.62-4.89,0.94-6.96,0.94c-2.52,0-4.35-0.67-5.49-2.01c-1.14-1.34-1.71-3.51-1.71-6.53c0-6,2.47-9,7.4-9       c4.86,0,7.3,2.52,7.3,7.56l-0.33,3.11h-9.84C210.22,35.43,210.5,36.17,211.02,36.63z M215.96,30.99c0-1.34-0.21-2.27-0.64-2.79       c-0.42-0.52-1.17-0.79-2.23-0.79c-1.06,0-1.81,0.27-2.24,0.82c-0.44,0.55-0.66,1.47-0.69,2.76H215.96z"
              />
              <path
                fill="#18466B"
                d="M223.59,40.86V24.13h4.45v1.77c1.87-1.12,3.66-1.84,5.36-2.18v4.52c-1.81,0.38-3.36,0.77-4.65,1.17       l-0.67,0.23v11.21H223.59z"
              />
              <path
                fill="#18466B"
                d="M245.31,27.94h-4.12v6.86c0,0.67,0.02,1.14,0.05,1.42c0.03,0.28,0.16,0.52,0.37,0.72       c0.21,0.2,0.54,0.3,0.99,0.3l2.54-0.07l0.2,3.58c-1.5,0.33-2.63,0.5-3.41,0.5c-2.01,0-3.38-0.45-4.12-1.36       c-0.74-0.9-1.1-2.57-1.1-5v-6.96h-1.97v-3.82h1.97v-4.65h4.49v4.65h4.12V27.94z"
              />
              <path
                fill="#18466B"
                d="M246.68,24.13h4.42l3.05,12.92h0.77l3.05-12.92h4.42l-5.89,23.76l0,0c-2.22,0-3.84-2.1-3.27-4.24       l0.73-2.79h-3.15L246.68,24.13z"
              />
              <path
                fill="#18466B"
                d="M273.73,34.23h-3.75v2.27c0,2.41-1.95,4.36-4.36,4.36h-0.26V18.1h8.37c5.4,0,8.1,2.63,8.1,7.9       c0,2.66-0.69,4.69-2.06,6.11C278.4,33.53,276.38,34.23,273.73,34.23z M269.98,30.28h3.72c2.3,0,3.45-1.43,3.45-4.28       c0-1.41-0.28-2.42-0.84-3.03c-0.56-0.61-1.43-0.92-2.61-0.92h-3.72V30.28z"
              />
              <path
                fill="#18466B"
                d="M297.33,29.35v6.96c0.04,0.54,0.13,0.91,0.27,1.12c0.13,0.21,0.41,0.36,0.84,0.45l-0.13,3.38       c-1.14,0-2.05-0.08-2.73-0.23c-0.68-0.16-1.37-0.47-2.06-0.94c-1.61,0.78-3.25,1.17-4.92,1.17c-3.46,0-5.19-1.83-5.19-5.49       c0-1.78,0.48-3.05,1.44-3.8c0.96-0.75,2.43-1.19,4.42-1.32l3.58-0.27v-1.04c0-0.69-0.16-1.17-0.47-1.42       c-0.31-0.26-0.81-0.38-1.51-0.38l-6.29,0.27l-0.13-3.11c2.39-0.65,4.66-0.97,6.81-0.97c2.15,0,3.7,0.45,4.65,1.34       C296.85,25.96,297.33,27.38,297.33,29.35z M289.8,33.77c-1.25,0.11-1.87,0.78-1.87,2.01c0,1.23,0.55,1.84,1.64,1.84       c0.85,0,1.77-0.13,2.78-0.4l0.5-0.13V33.5L289.8,33.77z"
              />
              <path
                fill="#18466B"
                d="M301.61,40.86V24.13h4.45v1.77c1.87-1.12,3.66-1.84,5.36-2.18v4.52c-1.81,0.38-3.36,0.77-4.65,1.17       l-0.67,0.23v11.21H301.61z"
              />
              <path
                fill="#18466B"
                d="M323.33,27.94h-4.12v6.86c0,0.67,0.02,1.14,0.05,1.42c0.03,0.28,0.16,0.52,0.37,0.72       c0.21,0.2,0.54,0.3,0.99,0.3l2.54-0.07l0.2,3.58c-1.5,0.33-2.63,0.5-3.41,0.5c-2.01,0-3.38-0.45-4.12-1.36       c-0.74-0.9-1.1-2.57-1.1-5v-6.96h-1.97v-3.82h1.97v-4.65h4.49v4.65h4.12V27.94z"
              />
              <path
                fill="#18466B"
                d="M330.73,40.86h-4.49V24.13h4.45v0.94c1.52-0.89,2.91-1.34,4.18-1.34c2.23,0,3.77,0.66,4.62,1.97       c0.85,1.32,1.27,3.35,1.27,6.09v9.07h-4.49v-8.94c0-1.43-0.17-2.48-0.5-3.16c-0.33-0.68-1-1.02-2.01-1.02       c-0.92,0-1.8,0.16-2.64,0.47l-0.4,0.13V40.86z"
              />
              <path
                fill="#18466B"
                d="M349.26,36.63c0.52,0.46,1.28,0.69,2.26,0.69c2.08,0,3.93-0.07,5.56-0.2l0.94-0.1l0.07,3.31       c-2.57,0.62-4.89,0.94-6.96,0.94c-2.52,0-4.35-0.67-5.49-2.01c-1.14-1.34-1.71-3.51-1.71-6.53c0-6,2.47-9,7.4-9       c4.86,0,7.3,2.52,7.3,7.56l-0.33,3.11h-9.84C348.46,35.43,348.73,36.17,349.26,36.63z M354.19,30.99       c0-1.34-0.21-2.27-0.64-2.79c-0.42-0.52-1.17-0.79-2.23-0.79c-1.06,0-1.81,0.27-2.24,0.82c-0.43,0.55-0.66,1.47-0.69,2.76       H354.19z"
              />
              <path
                fill="#18466B"
                d="M361.83,40.86V24.13h4.45v1.77c1.87-1.12,3.66-1.84,5.36-2.18v4.52c-1.81,0.38-3.36,0.77-4.65,1.17       l-0.67,0.23v11.21H361.83z"
              />
              <path
                fill="#18466B"
                d="M386.29,28.31c-2.77-0.36-4.7-0.54-5.81-0.54c-1.1,0-1.82,0.1-2.14,0.3c-0.32,0.2-0.49,0.52-0.49,0.95       c0,0.44,0.22,0.74,0.65,0.9c0.44,0.17,1.55,0.41,3.35,0.74c1.8,0.32,3.07,0.85,3.83,1.59c0.76,0.74,1.14,1.93,1.14,3.58       c0,3.61-2.24,5.42-6.73,5.42c-1.47,0-3.26-0.2-5.36-0.6l-1.07-0.2l0.13-3.75c2.77,0.36,4.69,0.54,5.77,0.54       c1.08,0,1.82-0.11,2.21-0.32c0.39-0.21,0.59-0.53,0.59-0.95c0-0.42-0.21-0.74-0.62-0.94c-0.41-0.2-1.48-0.45-3.21-0.74       c-1.73-0.29-3.02-0.78-3.87-1.47c-0.85-0.69-1.27-1.92-1.27-3.7c0-1.77,0.6-3.11,1.81-4.02c1.21-0.9,2.76-1.36,4.65-1.36       c1.32,0,3.11,0.21,5.39,0.64l1.1,0.2L386.29,28.31z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill="#36A9E1"
                  d="M89.04,55.57c0-3.38,2.48-5.1,5.63-5.1s5.63,1.73,5.63,5.1V61c0,3.38-2.48,5.1-5.63,5.1        s-5.63-1.73-5.63-5.1V55.57z M91.63,60.64c0,1.96,0.98,3.33,3.04,3.33c2.05,0,3.04-1.36,3.04-3.33v-4.71        c0-1.96-0.99-3.32-3.04-3.32c-1.95,0-3.04,1.13-3.04,3.02V60.64z"
                />
                <path
                  fill="#36A9E1"
                  d="M105.04,54.71l0.16,1.28c1.17-0.84,2.55-1.5,3.94-1.5c1.97,0,3.13,1.11,3.13,3.17v8.22h-2.52v-7.46        c0-1.03-0.45-1.6-1.54-1.6c-0.79,0-1.84,0.37-2.77,1.03v8.03h-2.52V54.71H105.04z"
                />
                <path
                  fill="#36A9E1"
                  d="M114.66,59.11c0-2.98,2-4.63,4.6-4.63c2.56,0,4.53,1.66,4.53,4.64c0,0.71-0.02,1.37-0.02,1.91h-6.63v0.5        c0,1.56,0.94,2.42,2.74,2.42c1.69,0,2.93-0.31,3.49-0.45l0.21,2.03c-0.56,0.17-1.98,0.56-3.94,0.56        c-2.87,0-4.98-1.61-4.98-4.6V59.11z M117.14,59.46h4.23v-0.44c0-1.63-0.68-2.5-2.11-2.5c-1.41,0-2.12,0.84-2.12,2.46V59.46z"
                />
                <path
                  fill="#36A9E1"
                  d="M135.14,66.1c-2.04,0-3.6-0.39-4.45-0.71l0.37-2.22c0.76,0.25,2.19,0.63,3.7,0.63        c1.79,0,3.01-0.38,3.01-2c0-1.82-1.51-2.04-4.36-3.02c-1.76-0.59-2.57-1.97-2.57-3.95c0-2.44,1.81-4.37,4.88-4.37        c2.03,0,3.3,0.37,3.99,0.58l-0.33,2.24c-0.77-0.25-2.02-0.54-3.38-0.54c-1.65,0-2.66,0.7-2.66,1.97c0,1.2,0.46,1.7,1.84,2.15        l1.79,0.58c2.21,0.72,3.34,1.87,3.34,4.31C140.33,64.59,138.59,66.1,135.14,66.1z"
                />
                <path
                  fill="#36A9E1"
                  d="M141.01,54.71h1.67l0.16-3.12h2.24c0,1.04,0.01,2.07,0.01,3.12h2.6v1.99h-2.56v5.89        c0,1.13,0.56,1.4,1.35,1.4c0.54,0,1.19-0.11,1.44-0.16l0.17,2.01c-0.44,0.12-1.29,0.27-2.2,0.27c-1.83,0-3.12-0.84-3.21-2.8        c-0.08-2.11-0.06-4.39-0.06-6.61h-1.62V54.71z"
                />
                <path
                  fill="#36A9E1"
                  d="M149.57,59.02c0-3.01,2.16-4.54,4.92-4.54s4.92,1.54,4.92,4.54v2.53c0,3.01-2.16,4.54-4.92,4.54        s-4.92-1.54-4.92-4.54V59.02z M152.08,61.54c0,1.54,0.87,2.47,2.41,2.47s2.41-0.93,2.41-2.47v-2.49        c0-1.53-0.87-2.47-2.41-2.47s-2.41,0.94-2.41,2.47V61.54z"
                />
                <path
                  fill="#36A9E1"
                  d="M171.52,61.46c0,3.01-1.99,4.64-4.73,4.64c-0.84,0-1.77-0.19-2.39-0.37v5.17h-2.46V54.71h2.13l0.15,1.13        c0.93-0.72,2.01-1.36,3.45-1.36c2.04,0,3.85,1.58,3.85,4.42V61.46z M164.47,57.61v5.99c0.55,0.2,1.32,0.4,2.1,0.4        c1.75,0,2.43-1.03,2.43-2.56v-2.25c0-1.54-0.59-2.52-2.08-2.52C165.98,56.67,165.11,57.09,164.47,57.61z"
                />
                <path
                  fill="#36A9E1"
                  d="M182.96,66.1c-2.04,0-3.6-0.39-4.45-0.71l0.37-2.22c0.76,0.25,2.19,0.63,3.7,0.63        c1.79,0,3.01-0.38,3.01-2c0-1.82-1.51-2.04-4.36-3.02c-1.76-0.59-2.57-1.97-2.57-3.95c0-2.44,1.81-4.37,4.88-4.37        c2.03,0,3.3,0.37,3.99,0.58l-0.33,2.24c-0.77-0.25-2.02-0.54-3.38-0.54c-1.65,0-2.66,0.7-2.66,1.97c0,1.2,0.46,1.7,1.84,2.15        l1.79,0.58c2.21,0.72,3.34,1.87,3.34,4.31C188.14,64.59,186.4,66.1,182.96,66.1z"
                />
                <path
                  fill="#36A9E1"
                  d="M192.82,49.68v6.2c1.13-0.81,2.45-1.4,3.77-1.4c1.97,0,3.13,1.11,3.13,3.17v8.22h-2.52v-7.46        c0-1.03-0.45-1.6-1.54-1.6c-0.79,0-1.84,0.37-2.77,1.03v8.03h-2.52V49.68H192.82z"
                />
                <path
                  fill="#36A9E1"
                  d="M202.16,59.02c0-3.01,2.16-4.54,4.92-4.54c2.76,0,4.92,1.54,4.92,4.54v2.53c0,3.01-2.16,4.54-4.92,4.54        c-2.76,0-4.92-1.54-4.92-4.54V59.02z M204.67,61.54c0,1.54,0.88,2.47,2.41,2.47c1.54,0,2.41-0.93,2.41-2.47v-2.49        c0-1.53-0.88-2.47-2.41-2.47c-1.54,0-2.41,0.94-2.41,2.47V61.54z"
                />
                <path
                  fill="#36A9E1"
                  d="M224.11,61.46c0,3.01-1.99,4.64-4.73,4.64c-0.84,0-1.77-0.19-2.39-0.37v5.17h-2.46V54.71h2.13l0.15,1.13        c0.93-0.72,2.01-1.36,3.46-1.36c2.04,0,3.85,1.58,3.85,4.42V61.46z M217.06,57.61v5.99c0.55,0.2,1.32,0.4,2.1,0.4        c1.75,0,2.43-1.03,2.43-2.56v-2.25c0-1.54-0.59-2.52-2.08-2.52C218.57,56.67,217.7,57.09,217.06,57.61z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g>
        <path
          fill="none"
          d="M37.44,59.8h0.58c0.1,0,0.2,0.01,0.29,0.04h5.92c0.09-0.02,0.19-0.04,0.29-0.04h0.58    c0.48,0,0.9-0.27,1.12-0.65h-9.93C36.55,59.54,36.96,59.8,37.44,59.8z"
        />
        <path
          fill="none"
          d="M37.01,34.45c-0.7,0-1.27,0.57-1.27,1.27s0.57,1.27,1.27,1.27h1.27v-1.27    C38.28,35.02,37.71,34.45,37.01,34.45z"
        />
        <path
          fill="none"
          d="M46.83,35.72c0-0.7-0.57-1.27-1.27-1.27c-0.7,0-1.27,0.57-1.27,1.27v1.27h1.27    C46.26,36.99,46.83,36.42,46.83,35.72z"
        />
        <path
          fill="none"
          d="M41.28,62.81c0.75,0,1.39-0.5,1.72-1.02h-3.44C39.9,62.32,40.54,62.81,41.28,62.81z"
        />
        <rect x="40.23" y="38.94" fill="none" width="2.1" height="12.4" />
        <rect x="36.14" y="56.22" fill="none" width="10.29" height="0.98" />
        <rect x="36.14" y="53.29" fill="none" width="10.29" height="0.98" />
        <path
          fill="#18466B"
          d="M44.29,51.34h-1.95h-2.1h-1.95h-2.19h-2.6c0.03,0.32,0.05,0.65,0.05,0.97v6.18c0,1.99,1.5,3.64,3.43,3.87    c0.6,1.61,2.25,3.04,4.32,3.04s3.72-1.43,4.32-3.04c1.93-0.24,3.43-1.88,3.43-3.87v-6.18c0-0.33,0.02-0.65,0.05-0.97h-2.6H44.29z     M41.28,62.81c-0.75,0-1.39-0.5-1.72-1.02H43C42.67,62.32,42.03,62.81,41.28,62.81z M45.12,59.8h-0.58c-0.1,0-0.2,0.01-0.29,0.04    h-5.92c-0.09-0.02-0.19-0.04-0.29-0.04h-0.58c-0.48,0-0.9-0.27-1.12-0.65h9.93C46.02,59.54,45.61,59.8,45.12,59.8z M46.43,57.2    H36.14v-0.98h10.29V57.2z M46.43,54.27H36.14v-0.98h10.29V54.27z"
        />
        <path
          fill="#FFFFFF"
          d="M57.13,32.22c-0.69-7.68-7.02-13.88-14.72-14.41c-0.76-0.05-1.5-0.05-2.25,0    c-7.7,0.53-14.03,6.73-14.72,14.41c-0.44,4.97,1.39,9.75,5.03,13.13c1.71,1.59,2.78,3.73,3.02,6h2.6    c-0.25-2.99-1.61-5.83-3.86-7.9c-3.04-2.82-4.58-6.83-4.21-10.99c0.58-6.42,5.87-11.6,12.31-12.05c0.32-0.02,0.63-0.03,0.95-0.03    c0.31,0,0.63,0.01,0.95,0.03c6.44,0.44,11.73,5.62,12.31,12.05c0.37,4.16-1.16,8.16-4.21,10.99c-2.24,2.08-3.61,4.92-3.86,7.9h2.6    c0.24-2.27,1.31-4.41,3.02-6C55.74,41.97,57.57,37.18,57.13,32.22z"
        />
      </g>
      <g>
        <path
          style={{ fill: 'none', stroke: '#F6DB53', strokeMiterlimit: 10 }}
          d="M37.95,51.34l0.18-19.06c0,0-0.03-2.3-2.31-2.3s-2.41,2.3-2.41,2.3s0.03,2.36,2.36,2.36c2.33,0,7.14,0,7.14,0    "
        />
        <path
          style={{ fill: 'none', stroke: '#F6DB53', strokeMiterlimit: 10 }}
          d="M44.62,51.34l-0.18-15.62c0,0,0.03-5.74,2.31-5.74c2.28,0,2.41,2.3,2.41,2.3s-0.03,2.36-2.36,2.36    c-2.33,0-7.14,0-7.14,0"
        />
      </g>
    </g>
  </svg>
);

export default ARXGroepLogo;
