import React from 'react';
import styled, { css } from 'styled-components';

import Icon from '~/components/atom/Icon';
import icons from '~/components/atom/Icon/components';
import TEST_ID from './index.testid';
import Link from '~/components/molecule/Link';
import { Label, Variant } from '~/components/atom/Typography';

export type Props = {
  name: string;
  icon?: keyof typeof icons;
  to: string;
  issues?: number;
  state?: any;
};

const MasterLink: React.FCC<Props> = ({
  name,
  icon,
  issues,
  dataTestId,
  ...rest
}) => {
  const hasIssues = !!issues;

  return (
    <Container data-testid={dataTestId} partiallyActive={false} {...rest}>
      {icon && <StyledIcon name={icon} />}

      <Label
        size="base"
        margin={['xxxs', null, null, 'base']}
        variant={Variant.primary}
      >
        {name}
      </Label>
      {hasIssues && <Issues data-testid={TEST_ID.ISSUES} $issues={issues} />}
    </Container>
  );
};

const Container = styled(Link)<{}>(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 4px solid transparent;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    text-decoration: none;
    color: ${theme.color('primary', 'dark')};

    padding: ${[
      theme.space('xxs'),
      '0',
      theme.space('xxs'),
      theme.space('s'),
    ].join(' ')};

    &[data-active='true'] {
      color: ${theme.color('secondary')};
      border-left: 4px solid ${theme.color('secondary')};

      span {
        color: ${theme.color('secondary')};
      }
    }

    &:hover {
      span {
        color: ${theme.color('secondary')};
      }
    }
  `,
);

const Issues = styled.span<{ $issues?: number }>(
  ({ theme, $issues }) => css`
    position: relative;
    margin-left: ${theme.space('s')};
    color: ${theme.color('white')};
    background-color: ${theme.color('accent')};
    border-radius: 50%;
    text-align: center;
    width: 18px;
    height: 18px;
    font-weight: ${theme.fw('semiBold')};
    font-size: ${theme.fontSize('s')};
    box-shadow: 0px 0px 3px 1px ${theme.color('accent')};

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      content: '${$issues}';
    }
  `,
);

const StyledIcon = styled(Icon)<{}>(
  () => css`
    font-size: 1.5em;
  `,
);

export default MasterLink;
