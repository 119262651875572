import type { ClientFlowAction } from '~/graphql/types.client';
import getDirectChildActions from '../getDirectChildActions';
import { pluck } from 'ramda';

type Args = {
  actions: Array<ClientFlowAction>;
  action: ClientFlowAction;
};

const getDirectChildActionsIds = ({ actions, action }: Args): Array<string> => {
  const children = getDirectChildActions({ action, actions });
  const childrenIds = pluck('id', children);

  return childrenIds;
};

export default getDirectChildActionsIds;
