import React from 'react';
import styled from 'styled-components';
import type { Props } from '..';

const Office: React.FCC<Props> = ({
  dataTestId,
  color = 'currentColor',
  style,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <svg
      width={style?.width ?? '100%'}
      height={style?.height ?? '100%'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 60.1997L70.7336 54.0988"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.0553 22.5957L15.4448 26.936V59.036L65.0553 54.6957V22.5957Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.7786 33.3197L21.0235 34.3481V58.4481L32.7786 57.4197V33.3197Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.2469 13.1039L13.1536 17.8364V27.1364L67.2469 22.4039V13.1039Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.7786 45.4194L29.4911 45.707"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.0743 29.4315L37.7596 31.3838V48.8838L60.0743 46.9315V29.4315Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6447 37.644L56.2888 36.3629"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.48669 35.7949V42.0949C4.48669 45.6949 8.57109 46.6376 8.57109 46.6376C8.57109 46.6376 12.6555 44.9802 12.6555 41.3802V35.0802L4.48669 35.7949Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.98474 35.751L17.5368 34.6528"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1231 17.1391L40.25 8.36567L59.2774 13.801"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

    {/* <svg
      width={style?.width ?? '100%'}
      height={style?.height ?? '100%'}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.5493 53.8486V48.9486C55.5493 43.5486 49.6717 39.5629 45.687 39.9115C43.8938 40.0684 39.8094 45.4257 35.8246 45.7743C31.8399 46.1229 27.9547 41.4628 25.9623 41.6372C21.7783 42.0032 16.1 46.9 16.1 52.4V57.3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9242 12.9659C40.8056 12.5389 44.6907 16.399 44.6907 22.899C44.6907 29.399 40.8056 35.9389 35.9242 36.3659C31.0429 36.793 27.1577 30.8329 27.1577 24.4329C27.1577 18.0329 31.0429 13.393 35.9242 12.9659Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg> */}
  </Container>
);

const Container = styled.div<{}>``;

export default Office;
