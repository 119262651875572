import type {
  ContactFiltersSubjectDirectoryFragment,
  ContactFiltersSubjectFragment,
  ContactFiltersSubjectAttributeActionFragment,
  ContactFiltersSubjectInstanceAttributeActionFragment,
} from '~/graphql/types';
import { cloneDeep } from 'lodash';
import generateNextForDirectory from '../generateNextForDirectory';
import getDirectory, { DirectoryMap } from '../getDirectory';
import getSubject, { SubjectMap } from '../getSubject';
import { assertNever } from '~/util/assertion';

export type OuterContactFilterField =
  | ContactFiltersSubjectDirectoryFragment
  | Pick<
      ContactFiltersSubjectFragment,
      '__typename' | 'eventId' | 'subjectId' | 'label'
    >
  | ContactFiltersSubjectAttributeActionFragment
  | (Pick<
      ContactFiltersSubjectInstanceAttributeActionFragment,
      '__typename' | 'label' | 'representation'
    > &
      Pick<ContactFiltersSubjectFragment, 'eventId' | 'subjectId'>);

export type GetContactFilterFieldsOpts = {
  directoryMap: DirectoryMap;
  subjectMap: SubjectMap;
};
const getOuterFieldsByPath = (
  path: Array<OuterContactFilterField>,
  opts: GetContactFilterFieldsOpts,
):
  | { result: Array<OuterContactFilterField>; error?: undefined }
  | { error: string } => {
  const { directoryMap, subjectMap } = opts;

  /**
   * If we start anew, we straight up loop over the root directory
   */

  if (path.length === 0) {
    return {
      result: cloneDeep(generateNextForDirectory(directoryMap)),
    };
  }

  let currentDir = directoryMap;

  /**
   * Once we left the directories, we cannot have directories again.
   * E.g. Dir - Field - Dir would be invalid
   */
  let hasLeftDirectoryStructure = false;

  /**
   * Check every `path` entry for validity and combine all next fields
   * once we hit the last part of the path.
   */
  for (let pathIndex = 0; pathIndex < path.length; ++pathIndex) {
    const pathPart = path[pathIndex];
    const isLast = pathIndex === path.length - 1;

    switch (pathPart.__typename) {
      case 'ContactFiltersSubjectDirectory': {
        if (hasLeftDirectoryStructure) {
          return { error: 'We have left the directory structure' };
        }

        const nextDirectory = getDirectory(pathPart.key, currentDir);
        if (nextDirectory == null) {
          return { error: `Cannot find directory with key ${pathPart.key}` };
        }

        currentDir = nextDirectory;
        if (!isLast) continue;

        /** We are showing the contents of a directory as the last entry */
        return {
          result: cloneDeep(generateNextForDirectory(currentDir)),
        };
      }
      case 'ContactFiltersSubject': {
        hasLeftDirectoryStructure = true;

        const subject = getSubject(pathPart, subjectMap);
        if (!subject) {
          return {
            error: `Cannot find subject ${pathPart.subjectId}:${pathPart.eventId}`,
          };
        }

        if (!isLast) continue;

        const result: Array<OuterContactFilterField> = [...subject.actions];
        if (subject.instanceAttribute.fields.length !== 0) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { fields, ...rest } = subject.instanceAttribute;
          result.push({
            ...rest,
            subjectId: subject.subjectId,
            eventId: subject.eventId,
          });
        }
        return { result: cloneDeep(result) };
      }
      case 'ContactFiltersSubjectInstanceAttributeAction':
      case 'ContactFiltersSubjectAttributeAction': {
        /**
         * Both of these are end states which should be handled
         * outside. We could return an error if this is
         * given.
         */
        return { result: [] };
      }

      default:
        assertNever(pathPart);
    }
  }

  /**
   * @todo we should send this to bugsnag / sentry. If our
   * code is correct this should not occur. TS gets confused with
   * our `isLast` check.
   */
  return { error: 'Reached end of line without making a filter' };
};

export default getOuterFieldsByPath;
