import { Flow___Argument_Pointer } from '~/graphql/types';

const isPointerArgument = (
  argument: any,
): argument is Flow___Argument_Pointer => {
  if (!argument) return false;
  if (argument.__typename === 'Flow___Argument_Pointer') return true;
  return false;
};

export default isPointerArgument;
