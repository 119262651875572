import React from 'react';

import {
  Field,
  NumberField,
  PostalCodeField,
} from '~/components/organism/Forms';
import FoldableCardBlock from '../FoldableCardBlock';
import TEST_ID from './index.testid';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Props = {
  /** If the form is disabled */
  isFormDisabled: boolean;
};

const text = {
  street: 'Straat',
  houseNumber: 'Huisnummer',
  addition: 'Toevoeging',
  place: 'Woonplaats',
  title: 'Woonadres',
  postcode: 'Postcode',
};

const ContactLivingSituationBlock: React.FCC<Props> = ({ isFormDisabled }) => {
  const viewingModeProps = useViewingModeProps();

  return (
    <FoldableCardBlock
      icon="map-pin"
      title={text.title}
      dataTestId="card-block-living-situation"
    >
      <JustificationContainer direction="column" width="100%" gap="base">
        <Field name="livingAddressStreet">
          {({ input, meta: { error } }) => (
            <Input
              dataTestId={TEST_ID.STREET}
              label={{ text: text.street }}
              type="text"
              width="100%"
              externalErrors={error ? [error] : undefined}
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </Field>

        <NumberField name="livingAddressHouseNumber">
          {({ input, meta: { error } }) => (
            <Input
              dataTestId={TEST_ID.HOUSE_NUMBER}
              label={{ text: text.houseNumber }}
              type="text"
              width="100%"
              externalErrors={error ? [error] : undefined}
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </NumberField>
        <Field name="livingAddressAddition">
          {({ input }) => (
            <Input
              {...input}
              dataTestId={TEST_ID.HOUSE_NUMBER_ADDITION}
              label={{ text: text.addition }}
              type="text"
              width="100%"
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </Field>

        <PostalCodeField name="livingAddressPostcode">
          {({ input, meta: { error } }) => (
            <Input
              dataTestId={TEST_ID.POSTCODE}
              label={{ text: text.postcode }}
              type="text"
              width="100%"
              disabled={isFormDisabled}
              externalErrors={error ? [error] : undefined}
              {...viewingModeProps}
              {...input}
            />
          )}
        </PostalCodeField>

        <Field name="livingAddressCity">
          {({ input, meta: { error } }) => (
            <Input
              dataTestId={TEST_ID.CITY}
              label={{ text: text.place }}
              type="text"
              width="100%"
              externalErrors={error ? [error] : undefined}
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </Field>
      </JustificationContainer>
    </FoldableCardBlock>
  );
};

export default ContactLivingSituationBlock;
