import { StartEmailSyncMutationVariables } from '~/graphql/types';
import { reporter } from '~/hooks/useErrorReporter';
import { NylasState, SynchroniseEntityType } from '../../types';

/**
 * Return mutation variables for startEmailSync mutation
 * @param {NylasState} state - NylasState
 * @param {string} code - Code returned from nylas
 * keywords: startEmailSync
 */
const mutationVariablesForState = (
  state: NylasState,
  code: string,
): StartEmailSyncMutationVariables | null => {
  switch (state.entityType) {
    case SynchroniseEntityType.Account:
      return {
        accountId: state.accountId,
        account: {
          accountId: state.accountId,
          code,
        },
      };
    case SynchroniseEntityType.Office:
      return {
        accountId: state.accountId,
        office: {
          accountId: state.accountId,
          code,
          officeId: state.entityId,
        },
      };

    case SynchroniseEntityType.User:
      return {
        accountId: state.accountId,
        user: {
          accountId: state.accountId,
          code,
        },
      };

    default:
      reporter.captureException(
        new Error(
          `Unknown entity type given to NylasAuthoriseEndpointV1: ${state.entityType}`,
        ),
      );
      return null;
  }
};

export default mutationVariablesForState;
