import { ApolloQueryResult } from '@apollo/client';
import { createContext } from 'react';
import { Exact, GetSessionHydrationQuery } from '~/graphql/types';

export type SessionHydration = {
  data: GetSessionHydrationQuery['getSessionHydration'];
  refetch: (
    variables?:
      | Partial<
          Exact<{
            accountId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetSessionHydrationQuery>>;
};

const SessionHydrationContext = createContext<SessionHydration>({
  data: {},
  refetch: () => Promise.resolve({}),
} as SessionHydration);

export default SessionHydrationContext;
