import styled, { css } from 'styled-components';

const PaginationContainer = styled.div<{}>`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
    & svg {
      font-size: ${theme.fontSize('s')};
    }
  `}
`;
export default PaginationContainer;
