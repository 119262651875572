import { isNil } from 'ramda';
import { FlowAction } from '~/graphql/types';
import { ClientFlowAction } from '~/graphql/types.client';
import String from '~/util/Validation/String';

/**
 * Check if an action is empty, used for showing the CTA prompting the user to update the action.
 * not to be confused with regular validation of each action
 * @param {ClientFlowAction} action - The action you want to check
 * keywords: empty action
 */
const isEmptyAction = (action: ClientFlowAction | null): boolean => {
  if (action === null) return true;

  switch (action.actionType) {
    case FlowAction.ContactAddTag:
    case FlowAction.ContactDeleteTag:
      if (String.isNonEmptyString(action.name)) return false;
      return true;
    case FlowAction.ContactAssign:
      if (String.isNonEmptyString(action.office)) return false;
      return true;
    case FlowAction.ContactDetails:
      if (
        action.field.name &&
        String.isNonEmptyString(action.field.name.template)
      )
        return false;
      if (
        action.field.phone &&
        String.isNonEmptyString(action.field.phone.template)
      )
        return false;
      if (!isNil(action.field.unsubscribeEmail)) return false;

      return true;
    case FlowAction.IfElse:
    case FlowAction.Start:
    case FlowAction.Wait:
      const { conditionSubExpression } = action.condition;

      if (conditionSubExpression.length === 0) return true;
      if (conditionSubExpression.length > 1) return false;

      const [{ conditionGroups }] = conditionSubExpression;
      if (conditionGroups.length !== 1) return false;

      const [{ conditions }] = conditionGroups;
      if (conditions.length > 1) return false;
      if (conditions.length === 0) return true;

      return conditions[0] == null;

    case FlowAction.RealworksSendContact:
      if (String.isNonEmptyString(action.description.template)) return false;
      return true;
    case FlowAction.SendEmailPlain:
      if (String.isNonEmptyString(action.subject.template)) return false;
      if (String.isNonEmptyString(action.body.template)) return false;
      return true;
    case FlowAction.SendNotification:
      if (String.isNonEmptyString(action.subject.template)) return false;
      if (String.isNonEmptyString(action.body.template)) return false;
      return true;
    case FlowAction.TaskCreate:
      if (String.isNonEmptyString(action.title.template)) return false;
      if (String.isNonEmptyString(action.description.template)) return false;
      return true;
    case FlowAction.ZapierTrigger:
      if (String.isNonEmptyString(action.zapierTriggerId)) return false;
      return true;

    default:
      return false;
  }
};
export default isEmptyAction;
