import React from 'react';
import Divider from '~/components/atom/Divider';
import { DividerBlock, LayoutSize } from '~/graphql/types';

export type Props = Omit<DividerBlock, '__typename' | 'id'> & {
  dataTestId?: string;
  margin?: Array<LayoutSize | null | undefined> | undefined | null;
  css?: string | undefined | null;
};

const DividerBlockCmp: React.FC<Props> = ({ css, margin }) => (
  <Divider
    color={{ group: 'tertiary', variant: 'light' }}
    margin={margin ?? undefined}
    css={css ?? undefined}
  />
);

export default DividerBlockCmp;
