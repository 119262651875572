import { gql } from '@apollo/client';

const QUERY = gql`
  query getPage($slug: String) {
    pages(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          title
          dynamicZone {
            __typename
            ... on ComponentUiStreamer {
              id
              body
            }
            ... on ComponentUiSumup {
              id
              title
              points {
                id
                title
                body
              }
            }
            ... on ComponentUiDivider {
              id
              margin
            }
            ... on ComponentUiVideoStreamer {
              id
              autoPlay
              controls
              loop
              muted
              video {
                data {
                  attributes {
                    mime
                    url
                  }
                }
              }
            }
            ... on ComponentUiScreenshot {
              id
              alt
              withShadow
              image {
                data {
                  attributes {
                    url
                    height
                    width
                  }
                }
              }
            }
            ... on ComponentUiCodeBlock {
              id
              code
              label
              tooltip
            }
            ... on ComponentUiTipBanner {
              id
              header
              body
            }
            ... on ComponentUiUpgradePlanCta {
              id
              header
              description
            }
            ... on ComponentUiTwoColumnVideo {
              id
              small
              body
              buttons {
                data {
                  id
                  attributes {
                    button {
                      id
                      label
                      appearance
                      action
                      ghost
                      icon {
                        name
                      }
                    }
                  }
                }
              }
              videoLeft
              Video {
                id
                autoPlay
                controls
                loop
                muted
                video {
                  data {
                    attributes {
                      mime
                      url
                    }
                  }
                }
              }
            }
            ... on ComponentUiTwoColumn {
              id
              body
              small
              buttons {
                data {
                  id
                  attributes {
                    button {
                      id
                      label
                      appearance
                      action
                      ghost
                      icon {
                        name
                      }
                    }
                  }
                }
              }
              imageLeft
              Image {
                alt
                withShadow
                image {
                  data {
                    attributes {
                      url
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default QUERY;
