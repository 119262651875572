import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import { AppBbWaardecheckAbout, AppVboWaardecheckAbout } from '~/graphql/types';
import InputGroupDivider from '~/components/page/Apps/components/InputGroupDivider';

import AppDetailsContainer from '../../../AppDetailsContainer';
import { useRecoilState } from 'recoil';
import { AppWaardecheckFieldsFragment, StateId } from '../..';
import { mergeDeepRight } from 'ramda';
import { DeepPartial } from 'utility-types';
import AppSectionHeader from '~/components/page/Apps/components/AppSectionHeader';
import WaardecheckIntl from '../../intl';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import TEST_ID from './index.testid';
import { WaardecheckDefaultData } from '../../utils/getDefaultData';
import Description from '../../components/Description';
import MainPreview from '../../components/MainPreview';
import { Page } from '../../components/Preview';
import IntlLinkInputComponent from '../../components/IntlLinkInputComponent';
import IntlInputComponent from '../../components/IntlInputComponent';
import ImageInput from '../../components/ImageInput';
import Explanation from '../../components/Explanation';
import { appWaardecheckState } from '../../state';

export type Props = RouteComponentProps & {
  defaultValues: WaardecheckDefaultData;
  data: AppWaardecheckFieldsFragment;
  loading: boolean;
};

const About: React.FCC<Props> = ({
  dataTestId: _dataTestId,
  data,
  defaultValues,
  loading,
}) => {
  const original = defaultValues.about;
  const [updated, setUpdatedValue] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );

  const updateValue = (
    value: DeepPartial<AppBbWaardecheckAbout | AppVboWaardecheckAbout>,
  ) => {
    setUpdatedValue(prev => {
      if (!prev) return prev;

      return mergeDeepRight(prev, {
        about: value,
      }) as any as typeof prev;
    });
  };

  if (updated == null) return null;

  return (
    <AppDetailsContainer
      header="Over ons"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <Explanation>
        De ‘Over ons’ sectie staat onderaan de ‘Rapport’ pagina waar de
        waardecheck wordt weergegeven. Pak deze ruimte om extra informatie over
        jouw kantoor of dienstverlening weer te geven.
      </Explanation>
      <MainPreview data={updated} page={Page['/report']} />
      <AppEntryItemHeader>{WaardecheckIntl.mainImage.title}</AppEntryItemHeader>
      <Description>{WaardecheckIntl.mainImage.description}</Description>
      <ImageInput
        initialUrl={data.about.image?.url}
        filename={data.__typename + 'about'}
        s3Key={updated?.about.image?.s3key}
        onChange={image => updateValue({ image })}
        dataTestId={TEST_ID.IMAGE}
      />

      <InputGroupDivider large />
      <AppSectionHeader>{WaardecheckIntl.section.text}</AppSectionHeader>
      <Description>{WaardecheckIntl.section.textDescription}</Description>
      <IntlInputComponent
        title={WaardecheckIntl.heading}
        defaultValue={original.heading}
        value={updated.about.heading}
        onChange={heading => updateValue({ heading })}
        dataTestId={TEST_ID.HEADING}
      />
      <InputGroupDivider />
      <IntlInputComponent
        title="Toelichting"
        defaultValue={original.body}
        value={updated.about.body}
        onChange={body => updateValue({ body })}
        dataTestId={TEST_ID.BODY}
      />
      <InputGroupDivider />
      <IntlLinkInputComponent
        id="primaryButton"
        title="Call to action button"
        defaultValue={original.primaryButton}
        value={updated.about.primaryButton}
        onChange={primaryButton => updateValue({ primaryButton })}
        dataTestId={TEST_ID.PRIMARY_BUTTON}
      />
    </AppDetailsContainer>
  );
};

export default About;
