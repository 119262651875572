import { GetFlowsV2Query } from '~/graphql/types';

/**
 * Returns flows that can be started for a contact
 * @param {flows} GetFlowsV2Query['getFlowsV2']['items'] - Result from getFlowsV2 query
 * keywords:
 */
const getValidFlows = (flows: GetFlowsV2Query['getFlowsV2']['items']) =>
  flows.filter(({ enabled, StartAction }): boolean => {
    // Only enabled flows
    if (!enabled) return false;
    if (!StartAction) return false;

    const [startCondition] = StartAction.conditionList.conditions;

    switch (startCondition.__typename) {
      case 'Flow___InstanceCondition': {
        return (
          startCondition.input.path[0] === 'global' &&
          startCondition.input.path[1] === 'contact'
        );
      }
      case 'Flow___SubjectFieldCondition': {
        return startCondition.type === 'FlowSubject_Contact';
      }
    }
  });
export default getValidFlows;
