import { isEmpty } from 'lodash';
import { filter, flatten, groupBy, isNil, map, toString, values } from 'ramda';
import {
  AppStatus_Realworks_KenmerkMapping,
  AppStatus_Realworks_KenmerkMapping__Input,
  RealworksKenmerk,
} from '~/graphql/types';
import { isNonEmptyString } from '~/util/Validation/String';
import { format } from '~/util/Validation/Tag';
import { Kenmerk, KenmerkLookup } from '../../RealworksSelectableAccordion';

type Props = {
  /** Our mapping */
  kenmerkMapping: Array<AppStatus_Realworks_KenmerkMapping>;
  /** Coming from Realworks */
  realworksKenmerken: Array<RealworksKenmerk>;
};

type Opts = {
  shouldUseDefaultTags: boolean;
};

export const transformBackendKenmerken = (
  { kenmerkMapping, realworksKenmerken }: Props,
  opts?: Opts,
): KenmerkLookup | null => {
  if (isEmpty(realworksKenmerken)) return null;
  const defaultTags = opts?.shouldUseDefaultTags ?? true;

  const dhKenmerkMapping = !isEmpty(kenmerkMapping)
    ? groupBy(x => toString(x.realworksKenmerkId), kenmerkMapping)
    : null;

  const mergedValues = map(rwKenmerk => {
    const dhKenmerk = !isNil(dhKenmerkMapping)
      ? dhKenmerkMapping?.[rwKenmerk?.realworksKenmerkId]?.[0]
      : null;

    const checked = dhKenmerk?.sync || false;
    const description =
      rwKenmerk?.realworksDescription ?? `${rwKenmerk.realworksKenmerkId}`;

    const tag =
      defaultTags === true &&
      checked === false &&
      (dhKenmerk?.tag == null || dhKenmerk?.tag === '')
        ? format(description)
        : dhKenmerk?.tag || null;

    return {
      id: rwKenmerk.realworksKenmerkId,
      description,
      group: rwKenmerk?.realworksGroup ?? 'Groep zonder omschrijving',
      tag,
      checked,
    };
  }, realworksKenmerken);

  const nonNullValues = filter(x => !isNil(x), mergedValues);

  const lookup = groupBy(x => x.group, nonNullValues as Array<Kenmerk>);

  return lookup as KenmerkLookup;
};

export const transformFrontendKenmerken = (
  kenmerkLookup: KenmerkLookup | null,
): Array<AppStatus_Realworks_KenmerkMapping__Input> => {
  if (!kenmerkLookup) return [];

  const kenmerkMapping = flatten(values(kenmerkLookup));

  const checkedKenmerkMapping = kenmerkMapping.reduce(
    (acc, { id, tag, checked }) => {
      if (checked || isNonEmptyString(tag)) {
        return [
          ...acc,
          {
            realworksKenmerkId: id,
            tag: isNonEmptyString(tag) ? tag : null,
            sync: checked,
          },
        ];
      }
      return acc;
    },
    [],
  );

  return checkedKenmerkMapping;
};
