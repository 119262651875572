import { useMap } from 'react-map-gl';
import React, {
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  type MutableRefObject,
} from 'react';
import MapboxDraw, {
  DrawMode,
  DrawModeChangeEvent,
} from '@mapbox/mapbox-gl-draw';
import { useControl } from 'react-map-gl';
import type { ControlPosition, MapRef } from 'react-map-gl';
import { Feature } from 'geojson';
import type { DrawBarButton } from './ExtendedMapboxDraw';
import ExtendedMapboxDraw from './ExtendedMapboxDraw';
import mapboxgl from 'mapbox-gl';
import { getMapStyles } from '../../constants';
import { useTheme, type DefaultTheme } from 'styled-components';
import type { LoadArgs } from '../..';

type CreateEvent = { features: Array<Feature> };
type UpdateEvent = { features: Array<Feature>; action: string };
type DeleteEvent = { features: Array<Feature> };
type SelectEvent = { features: Array<Feature> };

export type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;
  customButtons?: Array<DrawBarButton>;
  drawTypeRef: MutableRefObject<WorkingAreaType>;
  onCreate?: (event: CreateEvent) => void;
  onUpdate?: (event: UpdateEvent) => void;
  onDelete?: (event: DeleteEvent) => void;
  onSelect?: (event: SelectEvent) => void;
  onModeChange?: (event: DrawModeChangeEvent) => void;
};

export const DrawControl = React.forwardRef<MapboxDraw, DrawControlProps>(
  (
    {
      position,
      customButtons,
      drawTypeRef,
      onCreate,
      onUpdate,
      onDelete,
      onSelect,
      onModeChange,
      ...rest
    },
    ref,
  ) => {
    const drawRef = useControl(
      () =>
        new ExtendedMapboxDraw({
          props: rest,
          buttons: customButtons,
        }),
      ({ map }) => {
        onCreate && map.on('draw.create', onCreate);
        onUpdate && map.on('draw.update', onUpdate);
        onDelete && map.on('draw.delete', onDelete);
        onSelect && map.on('draw.selectionchange', onSelect);
        onModeChange && map.on('draw.modechange', onModeChange);
      },
      ({ map }) => {
        onCreate && map.off('draw.create', onCreate);
        onUpdate && map.off('draw.update', onUpdate);
        onSelect && map.off('draw.selectionchange', onSelect);
        onModeChange && map.off('draw.modechange', onModeChange);
      },
      {
        position,
      },
    );

    // forwarding the Draw Ref external
    useImperativeHandle(
      ref,
      () =>
        ({
          ...drawRef,
          toggleSelection: (featureId: string | number) => {
            const allFeatures = drawRef.getAll().features;
            const selectedIds = drawRef.getSelectedIds();
            const target = drawRef.get(featureId as string);

            const alreadyExists =
              target && selectedIds.includes(featureId as string);
            const updatedFeatureIds = alreadyExists
              ? selectedIds.filter(id => id !== featureId)
              : [...selectedIds, featureId];

            if (target && onSelect) {
              // selection on card
              onSelect({
                features: allFeatures.filter(feature =>
                  updatedFeatureIds.includes(feature.id as string),
                ),
              });

              // selection inside the map
              drawRef.changeMode('simple_select', {
                featureIds: updatedFeatureIds as Array<string>,
              });
            }
          },
          startSelection: (drawType: WorkingAreaType) => {
            drawTypeRef.current = drawType;
            drawRef.changeMode('draw_polygon');
          },
          stopSelection: () => {
            drawRef.changeMode('simple_select');
          },
          cancelSelection: () => {
            drawRef.trash();
          },
        }) as ExtendedMapboxDraw,
      [drawRef, drawTypeRef, onSelect],
    );

    return null;
  },
);

// --------------------------------------------------------------------------------------------------------
export type Props = {
  features?: Array<Feature>;
  onCreate?: (features: Array<Feature>) => void;
  onUpdate?: (features: Array<Feature>) => void;
  onDelete?: (features: Array<Feature>) => void;
  onSelect?: (features: Array<Feature>) => void;

  onLoaded?: (args: LoadArgs) => void;

  buttons?: Array<DrawBarButton>;
};

export type WorkingAreaType = 'inclusive' | 'exclusive';

export const Draw: React.FC<Props> = ({
  features = [],
  buttons = [],
  onLoaded,
  ...props
}) => {
  const { current: map } = useMap();
  const theme = useTheme();
  const [, setDrawMode] = useState<DrawMode>('simple_select');
  const drawTypeRef = useRef<WorkingAreaType>('inclusive');
  const drawRef = useRef<ExtendedMapboxDraw>(null);
  const navigationControls = useRef(
    new mapboxgl.NavigationControl({ showZoom: true, showCompass: true }),
  );

  useEffect(() => {
    const addFeatures = () => {
      const { readOnlyFeatures, editableFeatures } = features.reduce(
        (acc, feature) => {
          if (feature.properties?.readOnly) {
            acc.readOnlyFeatures.push({
              ...feature,
              properties: {
                ...feature.properties,
                areaType: feature.properties.inclusive
                  ? 'inclusive'
                  : 'exclusive',
              },
            });
          } else {
            acc.editableFeatures.push(feature);
          }
          return acc;
        },
        { readOnlyFeatures: [], editableFeatures: [] } as {
          readOnlyFeatures: Array<Feature>;
          editableFeatures: Array<Feature>;
        },
      );

      // Add editable features to the Mapbox Draw
      const inclusiveFeatures = addDrawTypeToFeatures(
        editableFeatures.filter(
          ({ properties }) => properties?.inclusive === true,
        ),
        'inclusive',
        theme,
      );
      const exclusiveFeatures = addDrawTypeToFeatures(
        editableFeatures.filter(
          ({ properties }) => properties?.inclusive === false,
        ),
        'exclusive',
        theme,
      );

      for (const feature of inclusiveFeatures) {
        drawRef.current?.add(feature);
      }
      for (const feature of exclusiveFeatures) {
        drawRef.current?.add(feature);
      }

      // Add read-only features as a GeoJSON layer
      if (map?.getSource('readOnlyFeatures')) {
        (map.getSource('readOnlyFeatures') as mapboxgl.GeoJSONSource).setData({
          type: 'FeatureCollection',
          features: readOnlyFeatures,
        });
      } else {
        const init = async () => {
          map?.getMap().addSource('readOnlyFeatures', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: readOnlyFeatures,
            },
          });

          const inclusiveTexture = await loadImageAsync(
            map,
            '/static/images/inclusive-working-area-pattern.png',
          );
          const exclusiveTexture = await loadImageAsync(
            map,
            '/static/images/exclusive-working-area-pattern.png',
          );

          map?.addImage('inclusiveTexture', inclusiveTexture);
          map?.addImage('exclusiveTexture', exclusiveTexture);

          map?.getMap().addLayer({
            id: 'readOnlyFeaturesFill',
            type: 'fill',
            source: 'readOnlyFeatures',
            paint: {
              'fill-color': [
                'case',
                ['==', ['get', 'areaType'], 'inclusive'],
                theme.color('success'),
                ['==', ['get', 'areaType'], 'exclusive'],
                theme.color('danger'),
                theme.color('tertiary'),
              ],
              'fill-opacity': 0.5,
              'fill-pattern': [
                'case',
                ['==', ['get', 'areaType'], 'inclusive'],
                'inclusiveTexture',
                ['==', ['get', 'areaType'], 'exclusive'],
                'exclusiveTexture',
                'missing',
              ],
            },
            layout: {
              visibility: 'visible',
            },
          });

          // Add the line layer for the borders of the read-only features
          map?.getMap().addLayer({
            id: 'readOnlyFeaturesBorder',
            type: 'line',
            source: 'readOnlyFeatures',
            paint: {
              'line-color': [
                'case',
                ['==', ['get', 'areaType'], 'inclusive'],
                theme.color('success'),
                ['==', ['get', 'areaType'], 'exclusive'],
                theme.color('danger'),
                theme.color('tertiary'),
              ],
              'line-width': 2,
              'line-dasharray': [2, 2],
            },
            layout: {
              visibility: 'visible',
            },
          });
        };
        void init();
      }
    };

    // Init area
    if (map && drawRef.current) {
      if (map.isStyleLoaded()) {
        addFeatures();
      } else {
        // If the map's style is not yet loaded, wait for the 'styledata' event
        // This event is emitted when the map's style has finished loading or changing
        // The 'once' method ensures that the event listener is removed after it's triggered once
        map.once('styledata', addFeatures);
      }

      if (!map.hasControl(navigationControls.current)) {
        map.addControl(navigationControls.current, 'bottom-left');
        onLoaded && onLoaded({ draw: drawRef.current, mapboxMap: map });
      }
    }
  }, [drawRef, features, map, onLoaded, theme]);

  const onCreate: DrawControlProps['onCreate'] = (event: CreateEvent) => {
    // drawType is passed correctly and show in the list but not on the map
    // When the map shape is complete I should update that feature with the user properties kept in the useState above
    if (props.onCreate)
      props.onCreate(
        addDrawTypeToFeatures(event.features, drawTypeRef.current, theme),
      );
  };

  const onUpdate: DrawControlProps['onUpdate'] = (event: UpdateEvent) => {
    if (props.onUpdate)
      props.onUpdate(
        addDrawTypeToFeatures(event.features, drawTypeRef.current, theme),
      );
  };

  const onDelete = (event: DeleteEvent) => {
    if (props.onDelete)
      props.onDelete(
        addDrawTypeToFeatures(event.features, drawTypeRef.current, theme),
      );
  };

  const onSelect = (event: SelectEvent) => {
    if (props.onSelect)
      props.onSelect(
        addDrawTypeToFeatures(event.features, drawTypeRef.current, theme),
      );
  };

  if (!map) {
    return null;
  }

  return (
    <DrawControl
      ref={drawRef}
      drawTypeRef={drawTypeRef}
      position="top-left"
      displayControlsDefault={false}
      styles={getMapStyles(theme)}
      userProperties={true}
      controls={{
        polygon: false,
        trash: false,
      }}
      defaultMode="simple_select"
      modes={{ ...MapboxDraw.modes }}
      onCreate={onCreate}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onSelect={onSelect}
      onModeChange={({ mode }) => setDrawMode(mode)}
      customButtons={buttons}
    />
  );
};

const addDrawTypeToFeatures = (
  features: Array<Feature>,
  drawType: WorkingAreaType,
  theme: DefaultTheme,
): Array<Feature> =>
  features.map(feature => ({
    ...feature,
    properties: {
      ...feature.properties,
      areaColor:
        drawType === 'inclusive'
          ? theme.color('success')
          : theme.color('danger'),
      inclusive:
        (feature.properties && feature.properties?.inclusive) ??
        drawType === 'inclusive',
    },
  }));

const loadImageAsync = (map: MapRef | undefined, url: string) =>
  new Promise<HTMLImageElement | ImageBitmap>((resolve, reject) => {
    if (!map) return reject(new Error('Unable to load image without `map`'));

    map.loadImage(url, (err, image) => {
      if (err) return reject(err);
      if (!image) return reject(new Error('Unable to load image'));

      return resolve(image);
    });
  });
