import React from 'react';
import useIntercom from '~/hooks/useIntercom';
import Button, { Props as ButtonProps } from '~/components/atom/Button';

export type Props = ButtonProps;

const IntercomButton: React.FC<Props> = props => {
  const intercom = useIntercom();

  return <Button {...props} onClick={() => intercom.openChat('Hallo!')} />;
};

export default IntercomButton;
