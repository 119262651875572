/**
 * Our contact search goes via an elastic server, which can have a slight delay after deleting.
 * That means that if we send a new request for contacts before the elastic server has updated we could get a contact marked for deletion back.
 * To avoid that we save marked for deleted contacts in the session storage to be removed from the contact list
 */
import cleanedFilename from '~/util/cleanedFilename';
import { CONTACTS_MARKED_FOR_DELETION } from '~/util/sessionStorageKeys';

const writeMarkedForDeletionContact = (id: string) => {
  const deletedContacts = getMarkedForDeletionContacts();

  global.window.sessionStorage.setItem(
    CONTACTS_MARKED_FOR_DELETION,
    JSON.stringify([...deletedContacts, id]),
  );
};

const getMarkedForDeletionContacts = (): Array<string> => {
  const markedForDeletionContacts = global.window.sessionStorage.getItem(
    CONTACTS_MARKED_FOR_DELETION,
  );

  if (markedForDeletionContacts == null) {
    return [];
  }

  const convertedMarkedForDeletionContacts = JSON.parse(
    markedForDeletionContacts,
  );

  if (!Array.isArray(convertedMarkedForDeletionContacts)) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | convertedMarkedForDeletionContacts (type: ${typeof convertedMarkedForDeletionContacts}) is not null or an array`,
    );
  }

  return convertedMarkedForDeletionContacts;
};

export default {
  writeMarkedForDeletionContact,
  getMarkedForDeletionContacts,
};
