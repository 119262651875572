import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  x?: boolean;
  y?: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
};

const OverflowScrollWrapper = forwardRef<HTMLDivElement, Props>(
  ({ y = false, x = false, style, ...props }, ref) => (
    <ForwardedWrapper
      ref={ref}
      {...props}
      style={{
        overflowX: x ? 'scroll' : 'auto',
        overflowY: y ? 'scroll' : 'auto',
        ...style,
      }}
    />
  ),
);

export const scrollBarStyles = css`
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Wrapper = styled.div`
  overflow: scroll;
  ${scrollBarStyles}
`;

const ForwardedWrapper = forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>((props, ref) => <Wrapper {...props} ref={ref} />);

export default OverflowScrollWrapper;
