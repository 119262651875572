import type { TransitionFrom } from 'react-spring';
import type { IconType } from '~/components/atom/Icon';
import type { SystemSize } from '~/theme';
import type { BaseColor, ThemeColor } from '~/theme/System/tokens/colorPalette';

export type InteractiveAppearance =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'danger';

export type InputAppearance = 'primary' | 'secondary' | 'tertiary' | 'danger';

export type Size = 'small' | 'medium' | 'large';

type SizeProps = {
  fontSize: SystemSize;
  padding: [SystemSize, SystemSize];
};

export type ComponentSizes = {
  [key in Size]: SizeProps;
};

export type ComponentLevel =
  | 'danger'
  | 'neutral'
  | 'info'
  | 'success'
  | 'warning'
  | 'loading';

export const levelToAppearanceMapping: {
  [key in ComponentLevel]: InteractiveAppearance;
} = {
  danger: 'danger',
  success: 'secondary',
  neutral: 'primary',
  warning: 'primary',
  info: 'primary',
  loading: 'tertiary',
};

export const levelToColorMapping: {
  [key in ComponentLevel]: BaseColor;
} = {
  danger: 'danger',
  success: 'success',
  neutral: 'primary',
  info: 'primary',
  warning: 'warning',
  loading: 'tertiary',
};

export const levelToIconNameMapping: {
  [key in ComponentLevel]: IconType;
} = {
  danger: 'slash',
  success: 'check',
  neutral: 'circle',
  info: 'info',
  warning: 'triangle',
  loading: 'spinner',
};

export const componentSizes: ComponentSizes = {
  small: {
    fontSize: 's',
    padding: ['xs', 's'],
  },
  medium: {
    fontSize: 'base',
    padding: ['s', 'm'],
  },
  large: {
    fontSize: 'base',
    padding: ['m', 'm'],
  },
};

// Used for Dropdowns and Inputs
export const inputAppearances: {
  [key in InputAppearance]: ThemeColor;
} = {
  primary: {
    group: 'tertiary',
    variant: 'base',
  },
  secondary: {
    group: 'success',
    variant: 'base',
  },
  tertiary: {
    group: 'primary',
    variant: 'base',
  },
  danger: {
    group: 'danger',
    variant: 'light',
  },
};

// Used for all interactive elements: buttons, deletable items, select group, CTA block, tooltips etc.
export const interactiveAppearances: {
  [key in InteractiveAppearance]: ThemeColor;
} = {
  primary: {
    group: 'secondary',
    variant: 'base',
  },
  secondary: {
    group: 'success',
    variant: 'base',
  },
  tertiary: {
    group: 'primary',
    variant: 'base',
  },
  accent: {
    group: 'accent',
    variant: 'base',
  },
  danger: {
    group: 'danger',
    variant: 'light',
  },
};

// Font size of 18 is taken from the legacy theme. Making it a constant is not the best solution,
// but at least will not hold us from removing legacy theme. It's only used in old components,
// means the constant will be replaced with the theme sizes once we overwrite old components.
export const FONT_SIZE_OF_18 = 18;

// It's only used in old components,
// means the constant will be replaced with the theme sizes once we overwrite old components.
export const APPROXIMATE_HEADER_HEIGHT = 72;

export const ANIMATION_CONFIG = {
  from: {
    opacity: 0,
    transform: 'translateX(-40px)',
  } as TransitionFrom<string>,
  to: {
    opacity: 1,
    transform: 'translateX(0px)',
  } as TransitionFrom<string>,
  config: {
    mass: 1,
    tension: 110,
    friction: 14,
  },
};
