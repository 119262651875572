import { isString } from 'lodash';
import Catalog from '~/Catalog';
import Validation from '~/util/Validation';

const text = {
  noEmail: Catalog.noEmail,
  invalidEmail: Catalog.invalidEmail,
  noPassword: Catalog.noPassword,
};

type FormData = {
  password: string;
  email: string;
};

/**
 * Validate login form
 * @param {string} email - Email
 * @param {string} password - password
 * keywords: loginvalidate
 */
const validate = ({ email, password }: FormData) => {
  const errors: {
    email: string | undefined;
    password: string | undefined;
  } = {
    email: undefined,
    password: undefined,
  };

  if (Validation.String.isEmpty(email)) {
    errors.email = text.noEmail;
  } else if (!Validation.Email.isValid(email)) {
    errors.email = text.invalidEmail;
  }

  const noPassword = isString(password) && password.length > 0;
  if (!noPassword) {
    errors.password = text.noPassword;
  }

  return errors;
};

export default validate;
