type NonNullableObject<T> = {
  [K in keyof T]: T[K] extends object
    ? NonNullableObject<T[K]>
    : T[K] extends null
      ? never
      : T[K];
};

/**
 * Removes all `null` values from an object, returning a new object
 * with only non-null properties, recursively.
 *
 * @template T - The type of the input object.
 * @param {T} obj - The input object from which `null` values should be removed.
 * @returns {NonNullableObject<T>} - A new object with all `null` properties removed.
 *
 * @example
 * // Example usage:
 * const obj = { start: null, end: 1, middle: null, nested: { a: null, b: 2 } };
 * const cleanedObj = removeNullFromObj(obj);
 * console.log(cleanedObj); // Output: { end: 1, nested: { b: 2 } }
 *
 * @remarks
 * This utility is particularly useful when dealing with partial objects where some fields
 * can be `null`. Note that `undefined` values are preserved in the output.
 *
 * @keywords null, remove, clean, omit, nonnull
 */
const removeNullFromObj = <T extends Record<string, any>>(
  obj: T,
): NonNullableObject<T> =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null) {
      acc[key as keyof T] =
        typeof value === 'object' && !Array.isArray(value) && value !== null
          ? removeNullFromObj(value)
          : value;
    }
    return acc;
  }, {} as NonNullableObject<T>);

export default removeNullFromObj;
