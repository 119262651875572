import {} from 'react';
import useCurrentUser from '../useCurrentUser';
import useRelationsForUser from '../useRelationsForUser';
import useCurrentAccount from '../useCurrentAccount';
import authorizationMatrix, { Action, Category } from './authorisationMatrix';
import { AccountStatus } from '~/graphql/types';

type Office = {
  id?: string;
  type: 'office';
};
type Account = {
  type: 'account';
};
type Entity = Office | Account;

type ReturnType = Record<Action, boolean>;

const actions: Array<Action> = ['create', 'view', 'update', 'delete'];

const defaultNoRights = {
  create: false,
  update: false,
  view: false,
  delete: false,
};

/**
 * Returns rights for current user
 */
const useUserRights = ({
  /** Could be a page or a section that the user right applies to */
  category,

  /** By default we return the account role of the user  */
  entity = { type: 'account' },
}: {
  category: Category;
  entity?: Entity;
}): ReturnType => {
  const account = useCurrentAccount();
  const me = useCurrentUser();

  const { accountRelation, officeRelations } = useRelationsForUser(me.id);

  const officeRelation =
    officeRelations && entity?.type === 'office'
      ? officeRelations.find(relation => relation.officeId === entity.id)
      : null;

  const accountRole = accountRelation?.role;
  const officeRole = officeRelation?.role;
  const userRole = entity?.type === 'office' ? officeRole : accountRole;

  // External subscriptions don't have invoices in our app
  // they are send manually by business so we need to make an exception here
  // since we removed the subscription type from the auth matrix
  if (
    account.subscription?.__typename === 'Subscription_External' &&
    category === 'Invoices'
  ) {
    return {
      create: false,
      delete: false,
      update: false,
      view: false,
    };
  }

  if (
    account.status === AccountStatus.Incomplete ||
    account.status === AccountStatus.Deleted ||
    !userRole
  )
    return defaultNoRights;

  return actions.reduce((prev, action) => {
    prev[action] = authorizationMatrix[category][action][entity.type][userRole];

    return prev;
  }, {} as ReturnType);
};

export default useUserRights;
