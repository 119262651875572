import type { ToolbarComponent } from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons';
import {
  BLOCK_ALIGN_BUTTONS,
  FONT_BASE_BUTTONS,
  FONT_STYLE_BUTTONS,
  LIST_BUTTONS,
  MISC_BUTTONS,
  TOOLBAR_DIVIDER,
} from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons/buttonDefinitions';

export const TASK_EDITOR_BUTTONS: Array<ToolbarComponent> = [
  ...FONT_BASE_BUTTONS,
  TOOLBAR_DIVIDER,
  ...LIST_BUTTONS,
  TOOLBAR_DIVIDER,
  ...BLOCK_ALIGN_BUTTONS,
  TOOLBAR_DIVIDER,
  ...FONT_STYLE_BUTTONS,
  TOOLBAR_DIVIDER,
  { type: 'button', name: 'link' },
  { type: 'button', name: 'insert-html' },
  { type: 'button', name: 'blockquote' },
  { type: 'button', name: 'variable' },
  TOOLBAR_DIVIDER,
  ...MISC_BUTTONS,
];
