import React from 'react';
import { Props } from '..';

const Zapier: React.FCC<Props> = ({
  secondaryColor: _secondaryColor,
  ...props
}) => (
  <svg
    x={0}
    y={0}
    width="100%"
    height="100%"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.2 67.6V58.1C28.2 56.7 29.4 55.6 30.9 55.6H41.5C43 55.6 44.2 56.7 44.2 58.1V67.6"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.19995 67.7H66.1999"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.2 62.5L65.9 58.2"
      stroke="#4A4A4A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 4"
    />
    <path
      d="M36.7 49.7V28.2"
      stroke="#4A4A4A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 4"
    />
    <path
      d="M44.3 50.4L51 33.9"
      stroke="#4A4A4A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 3"
    />
    <path
      d="M50.2 54.9L64.3 40.8"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 4"
    />
    <path
      d="M28.1001 50.4L20.6001 31.2"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 4"
    />
    <path
      d="M22.1001 54.9L8.1001 40.8"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 4"
    />
    <path
      d="M21.2 62.5L6.5 58.2"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 4"
    />
    <path
      d="M61.5 33.8L49.1 26.3C48.5 26 48.3 25.2 48.7 24.6L53 17.4C53.3 16.8 54.1 16.6 54.7 17L67.1 24.5C67.7 24.8 67.9 25.6 67.5 26.2L63.2 33.4C62.9 33.9 62.1 34.1 61.5 33.8Z"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.6001 16.9L57.9001 25.8L67.7001 25.6"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.3 24L30.5 25.3C29.8 25.4 29.2 25 29.1 24.3L26.4 9.8C26.3 9.1 26.7 8.5 27.4 8.4L37 6.6C37.7 6.5 38.3 6.9 38.4 7.6L40.6 19.3L37.3 24Z"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.8 24.1L36.2 20.7C36.1 20 36.5 19.4 37.2 19.3L40.5 18.7"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.8999 11.6L35.7999 10.5"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.3999 14.5L34.7999 13.6"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.5001 21.8L41.3001 22.4C42.0001 22.5 42.6001 22.1 42.7001 21.5L43.3001 18.7L45.6001 7C45.7001 6.3 45.3001 5.7 44.7001 5.6L37.9001 4.2L35.1001 3.6C34.4001 3.5 33.8001 3.9 33.7001 4.5L33.1001 7.3"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.7999 25.9L11.9999 30.4C11.3999 30.7 10.6999 30.4 10.3999 29.8L5.39992 18.9C5.09992 18.3 5.39992 17.6 5.99992 17.3L15.7999 12.8C16.3999 12.5 17.0999 12.8 17.3999 13.4L22.3999 24.3C22.5999 24.9 22.3999 25.6 21.7999 25.9Z"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 22.7L19 17.2"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.19995 17.9L7.69995 14.7"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2999 15.5L12.8999 12.4"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Zapier;
