import type { Node_Condition_Number__Input } from '~/graphql/types';
import type { NumberFilter } from './types.flow';

const asNumberFilterInput = ({
  negate,
  operator,
  value,
}: NumberFilter): Node_Condition_Number__Input => ({
  negate,
  [operator]: value,
});

export default asNumberFilterInput;
