const getProgressPercentage = (
  completedAmount: number,
  nonCompletedAmount: number,
): number => {
  if (
    (completedAmount === 0 && nonCompletedAmount === 0) ||
    completedAmount < 0 ||
    nonCompletedAmount < 0
  )
    return 0;

  const totalAmount = completedAmount + nonCompletedAmount;

  return (completedAmount / totalAmount) * 100;
};

export default getProgressPercentage;
