import type { OutputFieldAccordions } from '~/components/organism/Wizard/context/WizardContext';
import type { OutputMap } from '~/components/organism/WizardSteps';
import type {
  EnabledApps,
  FailedApps,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/LoadingScreen';
import type { ReturnValue } from '~/components/organism/WizardSteps/utils/getOutputsForOverview';
import getAppPriceBySetupFlow from '~/components/page/Apps/StellaxAI/utils/getAppPriceBySetupFlow';
import type { AppsHashMap } from '~/hooks/useApps';
import getPriceWithInterval from '~/util/getPriceWithInterval';

type Args = {
  appsMap: AppsHashMap;
  categoryName: string;
  values: Array<EnabledApps> | Array<FailedApps>;
  outputMap: OutputMap;
};

/**
 * Receive the output from previous step and convert it to Accourdion output type
 * @param {AppsHashMap} appsMap - Apps hash map from useApps hook
 * @param {categoryName} categoryName - Name of the group
 * @param {Array<EnabledAppInfo> | Array<FailedAppInfo>} values - Values to be displayed in the accordion children
 * keywords: accordionOutput
 */
const convertOutputToAccordionOutput = ({
  appsMap,
  categoryName,
  values,
  outputMap,
}: Args): OutputFieldAccordions<ReturnValue> => ({
  type: 'accordions',
  category: categoryName,
  values:
    values && values.length > 0
      ? values.map(app => ({
          label: appsMap[app.typename]?.name ?? '',
          children: [
            {
              type: 'plain',
              category: 'Kosten',
              value: getPriceWithInterval(
                appsMap[app.typename]
                  ? getAppPriceBySetupFlow({
                      app: appsMap[app.typename],
                      outputMap,
                    })
                  : null,
              ),
            },
            {
              category: 'Geactiveerde flows',
              type: 'list',
              value:
                'insertedFlows' in app
                  ? app.insertedFlows?.map(flow => ({
                      label: flow.name,
                    }))
                  : undefined,
            },
            ...(app.widgetItems && app.widgetItems.length > 0
              ? app.widgetItems?.map(item => ({
                  type: 'item',
                  category: 'App onderdelen',
                  value: item.id,
                  label: item.name,
                }))
              : []),
          ],
        }))
      : [],
});

export default convertOutputToAccordionOutput;
