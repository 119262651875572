import { ClientFlowAction } from '~/graphql/types.client';

type PossiblePath = { [actionId: string]: null };

/**
 * Return all possible paths from leaf (subject action) to root (Start action)
 * @param {Array<ClientFlowAction>} actions - all actions
 * @param {ClientFlowAction} action - leaf action
 * keywords: getPaths, possible paths
 */
const getPossiblePaths = ({
  actions,
  action,
}: {
  actions: Array<ClientFlowAction>;
  action: ClientFlowAction;
}): Array<Array<string>> => {
  const paths: Array<PossiblePath> = [];

  const getNextPath = (action: ClientFlowAction, prevPath: PossiblePath) => {
    if (action.__typename === 'FlowV2_Action_Start') {
      paths.push({ ...prevPath, [action.id]: null });
      return;
    }

    action.parentIds.forEach(parentId => {
      const nextAction = actions.find(a => a.id === parentId);
      if (nextAction)
        getNextPath(nextAction, { ...prevPath, [action.id]: null });
    });
  };

  getNextPath(action, {});

  return paths.map(path => Object.keys(path));
};

export default getPossiblePaths;
