import ARX from './ARX';
import Automation from './Automation';
import Box from './Box';
import CalendarView from './CalendarView';
import HouseSwap from './HouseSwap';
import HouseTinder from './HouseTinder';
import Hypotheekbond from './Hypotheekbond';
import List from './List';
import Megaphone from './Megaphone';
import Office from './Office';
import ProfilePlaceholder from './ProfilePlaceholder';
import RealworksIntegration from './RealworksIntegration';
import TiaraData from './TiaraData';
import User from './User';
import Users from './Users';
import ValuationReport from './ValuationReport';
import Zapier from './Zapier';
import Trustoo from './Trustoo';

export type Props = {
  color?: string;
  secondaryColor?: string;
  strokeWidth?: number | string;
  style?: React.CSSProperties;
};

const illustrations = {
  'calendar-view': CalendarView,
  'house-swap': HouseSwap,
  'house-tinder': HouseTinder,
  'profile-placeholder': ProfilePlaceholder,
  'realworks-integration': RealworksIntegration,
  'tiara-data': TiaraData,
  'valuation-report': ValuationReport,
  arx: ARX,
  automation: Automation,
  box: Box,
  list: List,
  megaphone: Megaphone,
  office: Office,
  user: User,
  users: Users,
  zapier: Zapier,
  hypotheekbond: Hypotheekbond,
  trustoo: Trustoo,
};

export default illustrations;
