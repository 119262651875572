import React from 'react';
import useAccounts from '~/hooks/useAccounts';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  SELECTED_ACCOUNT_STORAGE_KEY,
} from '~/util/localStorageKeys';
import { navigate } from '@gatsbyjs/reach-router';
import { AccountStatus } from '~/graphql/types';
import Dropdown, {
  SelectedOption,
  type Option,
} from '~/components/molecule/Dropdown';
import type { IconType } from '~/components/atom/Icon';
import useCurrentAccount from '~/hooks/useCurrentAccount';

const text = {
  logout: 'Uitloggen',
};
export type Props = {
  dataTestId?: string;
};

const logoutOption = {
  label: text.logout,
  icon: { name: 'sign-out' as IconType },
  payload: '/logout',
  key: 'nav-link-settings-dropdown-signout',
  styleOptions: {
    lineAbove: true,
  },
};

export const AccountSwitcher: React.FC<Props> = ({}) => {
  const selectedAccountIdFromStorage = getLocalStorageItem(
    SELECTED_ACCOUNT_STORAGE_KEY,
  );
  const currentAccount = useCurrentAccount();

  const selectedAccountId = selectedAccountIdFromStorage ?? currentAccount.id;
  const accounts = useAccounts();

  if (accounts.length === 0) return null;

  const selectedAccount = accounts.find(
    account => account.id === selectedAccountId,
  );

  const options: Array<Option> = [
    ...accounts.map(account => ({
      label: account.name,
      icon: { name: 'briefcase' as IconType },
      payload: account.id,
      key: account.id,
    })),
    logoutOption,
  ];

  const onChange = (selectedOption: SelectedOption) => {
    const { option } = selectedOption;
    if (option.payload === selectedAccount) return;
    if (option.payload === '/logout') {
      if (selectedAccount?.status === AccountStatus.Cancelled) {
        removeLocalStorageItem(SELECTED_ACCOUNT_STORAGE_KEY);
      }

      return navigate(option.payload);
    }

    global.localStorage.setItem(SELECTED_ACCOUNT_STORAGE_KEY, option.payload);

    // Force reload
    return (global.window.location.href = '/-/');
  };

  return (
    <Dropdown
      options={options}
      selectedOptionIdx={options.findIndex(
        option => option.key === selectedAccountId,
      )}
      onChange={onChange}
      dataTestId="global-account-switcher"
    />
  );
};

export default AccountSwitcher;
