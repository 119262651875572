export enum DELETE_ENTITY_TYPE {
  OFFICE = 'OFFICE',
  USER = 'USER',
}

type DELETE_USER_FROM_ACCOUNT = 'DeleteUserFromAccount';
type DELETE_USER_FROM_OFFICE = 'DeleteUserFromOffice';
type DELETE_OFFICE = 'DeleteOffice';

export enum DELETION_TYPE {
  DELETE_USER_FROM_ACCOUNT = 'DeleteUserFromAccount',
  DELETE_USER_FROM_OFFICE = 'DeleteUserFromOffice',
  DELETE_OFFICE = 'DeleteOffice',
}

export type DeletionType =
  | DELETE_USER_FROM_ACCOUNT
  | DELETE_USER_FROM_OFFICE
  | DELETE_OFFICE;
