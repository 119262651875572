import React from 'react';
import { Body } from '~/components/atom/Typography';
import type { SystemSize } from '~/theme';

type Props = {
  margin?: Array<SystemSize | null>;
};

const Explanation: React.FCC<Props> = ({ children, margin = ['xl', null] }) => (
  <Body margin={margin}>{children}</Body>
);

export default Explanation;
