import {
  useGetAppBbWaardecheckQuery,
  useGetAppVboWaardecheckQuery,
} from '~/graphql/types';
import { AppStatus__typename } from '~/graphql/types.client';

const useGetAppWaardeCheckQuery = (typename?: AppStatus__typename) => {
  if (!typename) {
    throw new Error('Missing typename in metadata');
  }

  return typename === 'AppStatus_VBOWaardecheck'
    ? useGetAppVboWaardecheckQuery
    : useGetAppBbWaardecheckQuery;
};

export default useGetAppWaardeCheckQuery;
