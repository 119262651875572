import UUID_REGEX from '~/util/Validation/Uuid';

const ZAPIER_TRIGGER_ID_REGEX = new RegExp(`^zt--${UUID_REGEX}$`);

/**
 * Checks if the given string is a valid Zapier trigger id. Uses the same validation as backend.
 * @param {string} str - Id as string
 * keywords: trigger id validation
 */
const isValidTriggerId = (str: string): boolean =>
  ZAPIER_TRIGGER_ID_REGEX.test(str);

export default isValidTriggerId;
