import { Warning } from '~/hooks/useEntityWarnings';
import { SNOOZED_WARNINGS, setLocalStorageItem } from '~/util/localStorageKeys';
import getSnoozedWarnings from '../getSnoozedWarnings';
import SnoozedWarningType from '../types';

const snoozeWarning = (warning: Warning) => {
  const snoozedWarningHashes: SnoozedWarningType = getSnoozedWarnings();

  const atm = new Date().toISOString();
  snoozedWarningHashes[warning.hash] = atm;

  writeSnoozedWarnings(snoozedWarningHashes);
};

const writeSnoozedWarnings = (warnings: SnoozedWarningType) =>
  setLocalStorageItem(SNOOZED_WARNINGS, JSON.stringify(warnings));

export default snoozeWarning;
