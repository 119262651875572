/**
 * Authentication provider.
 */
import React, { useCallback, useEffect, useState } from 'react';
import type { CognitoUser } from '@aws-amplify/auth';
import amplify from '~/amplify';
import { navigate, useLocation, WindowLocation } from '@gatsbyjs/reach-router';
import ClockSkew from './AppSync/utils/clockscew';

type AuthContextProps = {
  isLoggedIn: boolean;
  ensureLogin: (state?: { from?: string }) => void;
  /**
   * We use the tmp user to recreate the flow
   * of a invited user which has to still setup
   * the user details. We should refactor the login
   * component to handle the user details setup as well.
   *
   * In the same run /login and /setup-user-details should
   * become functional components.
   */
  setTmpUser: (user: CognitoUser) => void;
  tmpUser: CognitoUser | null;
  user: CognitoUser | null;
  /** Checks if the user is DH_CROCS_ADMIN */
  isDHAdmin: boolean;
};
export const AuthContext = React.createContext<AuthContextProps>({
  isLoggedIn: false,
  user: null,
  setTmpUser: () => {},
  tmpUser: null,
  ensureLogin: () => {},
  isDHAdmin: false,
});

type Props = {
  children: React.ReactNode;
};

const Auth: React.FCC<Props> = ({ children }) => {
  const [isValidatingLogin, setValidateLogin] = useState(true);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [tmpUser, setTmpUser] = useState<CognitoUser | null>(null);

  const ensureLogin = useCallback(() => setValidateLogin(true), []);
  const location = useLocation() as WindowLocation<
    { from?: string } | undefined
  >;
  const isLoggedIn = user !== null;

  useEffect(() => {
    let didCancel = false;

    const verifyCredentials = async () => {
      const user: CognitoUser | null =
        await amplify.Auth.currentAuthenticatedUser()
          .then(user => user)
          .catch(() => null);

      if (didCancel) return;

      if (user) {
        ClockSkew.setClockSkew(
          // @ts-ignore
          (user.getSignInUserSession()?.clockDrift || 0) * 1000 * -1,
        );
      }

      setUser(user);
      setTmpUser(null);
      setValidateLogin(false);
    };

    if (isValidatingLogin) {
      void verifyCredentials();
    }

    return () => {
      didCancel = true;
    };
  }, [isValidatingLogin]);

  if (isValidatingLogin) return null;

  const isAuthRoute =
    location.pathname.startsWith('/login') ||
    location.pathname.startsWith('/register') ||
    location.pathname.startsWith('/verify') ||
    location.pathname.startsWith('/forgot-password') ||
    location.pathname.startsWith('/setup-newpassword') ||
    location.pathname.startsWith('/setup-user-details');

  if (isAuthRoute && isLoggedIn) {
    /**
     * We are logged in, but are on a auth route.
     * Redirect to the dashboard, or the page they originated from
     * before they went through the auth process.
     *
     * E.g. arrive at /tasks?officeId=null (but are unauthorised) -> /login -> /tasks?officeId=null
     */
    void navigate(location.state?.from ?? '/-/', { replace: true }).then(() => {
      ensureLogin();
    });

    return null;
  }

  if (!isAuthRoute && !isLoggedIn) {
    /**
     * The user is not logged in and not on an auth route, we redirect them to /login
     *
     * We set the from state to redirect back to after the login
     * has happened. Except when we are on the logout route.
     * Cause endless loop.
     */
    const state = location.pathname.startsWith('/logout')
      ? {}
      : { from: location.pathname + location.search };

    void navigate('/login', {
      replace: true,
      state,
    });
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: user != null,
        user: tmpUser,
        setTmpUser,
        tmpUser,
        ensureLogin,
        isDHAdmin:
          user
            ?.getSignInUserSession()
            ?.getAccessToken()
            .payload['cognito:groups']?.includes('DH_CROCS_ADMIN') || false,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default Auth;
