import { NetworkStatus } from '@apollo/client';

export const isInitialLoadStatus = (networkStatus: number): boolean => {
  switch (networkStatus) {
    case NetworkStatus.loading:
    case NetworkStatus.setVariables:
      return true;
  }

  return false;
};

export const DATA_READY = 7;
