import { SessionHydrationOfficeFieldsFragment } from '~/graphql/types';
import useOfficeLookup from '../useOfficeLookup';

const useOffice = (
  officeId: string | null | undefined,
  onlyExistingOffices?: boolean,
): SessionHydrationOfficeFieldsFragment | null => {
  const offices = useOfficeLookup(onlyExistingOffices);

  if (!officeId || !offices || !offices[officeId]) return null;
  return offices[officeId];
};

export default useOffice;
