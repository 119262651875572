import { atom } from 'recoil';

export type SectionId =
  | 'settings'
  | 'widget-settings'
  | 'design'
  | 'issues'
  | 'events';

export const asideExpandedState = atom<SectionId | null>({
  key: 'formBuilder/asideExpandedState',
  default: null,
});
