import React from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import StatusPage from '../components/StatusPageWrapper';
import TEST_ID from './index.testid';

export const text = {
  pageTitle: 'Geblokkeerd',
  title: 'Account geblokkeerd',
  explanation:
    'Dit account is geblokkeerd. Neem contact op met onze helpdesk om toegang te herstellen.  Wij zijn te bereiken via de chat, op contact@dathuis.nl of per telefoon op 020 - 222 35 71.',
};

const AccountLocked: React.FCC<RouteComponentProps> = () => (
  <StatusPage
    dataTestId={TEST_ID.CONTAINER}
    pageTitle={text.pageTitle}
    title={text.title}
    explanation={text.explanation}
  />
);
export default AccountLocked;
