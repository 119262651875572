import type { FlowPath } from '../getFieldsByPath';
import type {
  Flow___Argument_Flow___Argument_Pointer_Fragment,
  Flow___SubjectId,
} from '~/graphql/types';
import type { InstanceMap } from '../getInstance';
import type { SubjectMap } from '../getSubject';
import type { DirectoryMap } from '../getDirectory';
import getPathForPointer from '../getPathForPointer';

const getPathForArgument = (
  argument: Flow___Argument_Flow___Argument_Pointer_Fragment,
  {
    argumentType,
    instanceMap,
    subjectMap,
    directoryMap,
  }: {
    argumentType: Flow___SubjectId;
    instanceMap: InstanceMap;
    subjectMap: SubjectMap;
    directoryMap: DirectoryMap;
  },
): { result: FlowPath; error?: undefined } | { error: string } =>
  getPathForPointer(argument.pointer, {
    instanceMap,
    subjectMap,
    validTypes: argumentType ? [argumentType] : undefined,
    directoryMap,
  });
export default getPathForArgument;
