import { memoize } from 'underscore';
import type {
  ContactListWithLastActivityFieldsFragment,
  ContactStatus,
  EmailStatusType,
  GetContactsV2QueryVariables,
  PermissionFragment,
} from '~/graphql/types';

import { generateConcatenatedNonNullString } from '~/util/textConversion';
import { convertServerDateStringToDate } from '~/util/date';
import { mapNotNull } from '~/util/array';
import markedForDeletionContactList from '~/components/page/Contacts/util/markedForDeletionContactList';
import cleanedFilename from '~/util/cleanedFilename';
import { reporter } from '~/hooks/useErrorReporter';
import { extractHandledActivities } from '~/graphql/types.client';

export const isDeleted = (contact: {
  status?: ContactStatus | null;
  id: string;
}): boolean => {
  const markedForDeletionContactIds =
    markedForDeletionContactList.getMarkedForDeletionContacts();

  return (
    (contact.status && contact.status === 'DELETING') ||
    markedForDeletionContactIds.includes(contact.id)
  );
};
export type ContactListData = {
  id: string;
  name: { name: string; emailStatus: EmailStatusType | null | undefined };
  address: string;
  createdDate: Date | null;
  tags: Array<string>;
  leadScore: number | null;
  leadActivity: string;
  lastLeadActivity: Date | null;
  permission?: PermissionFragment | null;
};

const composeContactListData = (
  items: Array<ContactListWithLastActivityFieldsFragment> | null | undefined,
  // We need to add these params in order to refresh the list even though we don't use them
  _variables: GetContactsV2QueryVariables,
  _version: string,
): Array<ContactListData> => {
  if (items == null) return [];

  return mapNotNull(items, item => {
    const {
      name,
      LeadScore,
      createdDate,
      tags,
      id,
      leadActivity,
      address,
      emailStatus,
    } = item;

    if (isDeleted(item)) {
      return null;
    }

    const handledLeadActivity = leadActivity
      ? extractHandledActivities([leadActivity])[0]
      : null;

    const shouldScramble =
      item.permission?.__typename === 'Permission_AccessDenied_Plan';

    const addressStr = generateConcatenatedNonNullString(
      [address?.street, address?.city],
      ', ',
    );

    return {
      id,
      name: { name: shouldScramble ? scrambleString(name) : name, emailStatus },
      tags: tags || [],
      address: shouldScramble ? scrambleString(addressStr) : addressStr,
      leadActivity: handledLeadActivity ? handledLeadActivity.summary : '',
      lastLeadActivity: convertServerDateStringToDate(
        handledLeadActivity?.createdDate,
      ),
      createdDate: convertServerDateStringToDate(createdDate),
      leadScore: convertServerLeadScoreToFrontendLeadScore(LeadScore),
      permission: item.permission,
    };
  });
};

export const convertServerLeadScoreToFrontendLeadScore = (
  leadScore?: number | null,
): number => {
  if (
    leadScore == null ||
    !Number.isInteger(leadScore) ||
    leadScore < 0 ||
    leadScore > 99
  ) {
    reporter.captureException(
      new Error(
        `${cleanedFilename(__filename)}
      Invalid lead score from the server: ${
        leadScore ? leadScore : 'null or undefined'
      }`,
      ),
    );

    if (leadScore != null && leadScore > 99) return 99;

    return 0;
  }
  return leadScore;
};

const scrambleString = (str: string): string => {
  const charArray = str.toLowerCase().split('');
  if (charArray.length === 0) return str;

  // Fisher-Yates Shuffle Algorithm
  // https://www.wikiwand.com/en/Fisher%E2%80%93Yates_shuffle
  for (let i = charArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [charArray[i], charArray[j]] = [charArray[j], charArray[i]];
  }

  const [firstChar, ...rest] = charArray;

  return `${firstChar.toUpperCase()}${rest.join('')}`;
};

const memoizedComposeContactListData = memoize(
  composeContactListData,
  (items, variables: GetContactsV2QueryVariables, version: string) => {
    const result = `${version}*@@*${items.length}*@@*${
      variables.query || ''
    }*@@*${JSON.stringify(variables.filters, null, 2)}*@@*${
      variables.sortBy == null
        ? ''
        : variables.sortBy.field + variables.sortBy.direction
    }*@@*${variables.page || ''}`;

    return result;
  },
);

export default memoizedComposeContactListData;
