import React from 'react';
import styled from 'styled-components';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';

export enum VideoMime {
  MP4 = 'video/mp4',
}

export type VideoJSOptions = {
  loop?: boolean;
  muted?: boolean;
  autoplay: boolean;
  controls: boolean;
  responsive: boolean;
  fluid: boolean;
  sources: Array<{
    src: string;
    type: VideoMime.MP4;
  }>;
};

export type Props = {
  className?: string;
  onReady?: (player: Player) => void;
  options?: VideoJSOptions;
};

export const VideoJS: React.FC<Props> = ({
  options = {
    autoplay: false,
    controls: false,
    responsive: true,
    fluid: true,
    loop: false,
    muted: true,
  },
  onReady,
  className,
}) => {
  const videoRef = React.useRef<HTMLDivElement>(null);
  const playerRef = React.useRef<Player | null>(null);

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.loop(options.loop ?? false);
      player.src(options.sources);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <Container data-vjs-player className={className}>
      <div ref={videoRef} />
    </Container>
  );
};

const Container = styled.div<{}>``;

export default VideoJS;
