import {
  WidgetSettingsAppearance,
  WidgetSettingsPositionV2,
} from '~/graphql/types';
import TEST_ID from './index.testid';

export const positionOptionTexts = {
  [WidgetSettingsPositionV2.TopRight]: 'Rechts boven',
  [WidgetSettingsPositionV2.TopLeft]: 'Links boven',
  [WidgetSettingsPositionV2.BottomRight]: 'Rechts onder',
  [WidgetSettingsPositionV2.BottomLeft]: 'Links onder',
  [WidgetSettingsPositionV2.CenterLeft]: 'Midden links',
  [WidgetSettingsPositionV2.CenterRight]: 'Midden rechts',
};

export const widgetAppearanceOptions = [
  {
    label: 'Klein',
    value: WidgetSettingsAppearance.Small,
    dataTestId: TEST_ID.APPEARANCE_OPTION_SMALL,
  },
  {
    label: 'Groot',
    value: WidgetSettingsAppearance.Large,
    dataTestId: TEST_ID.APPEARANCE_OPTION_LARGE,
  },
];
