import { Nav } from '~/components/template/MasterDetail/components/Master';

export const navBarItems: Array<Nav> = [
  {
    type: 'link',
    to: '',
    icon: 'gear',
    name: 'Algemeen',
  },
  { type: 'link', to: '/design', icon: 'layout', name: 'Ontwerp' },
  {
    type: 'link',
    to: '/address',
    icon: 'map-pin',
    name: 'Adres',
  },
  {
    type: 'link',
    to: '/living-area',
    icon: 'maximize',
    name: 'Woonoppervlakte',
  },
  {
    type: 'link',
    to: '/house-type',
    icon: 'home',
    name: 'Huistype',
  },
  {
    type: 'link',
    to: '/comparable-objects',
    icon: 'grid',
    name: 'Swipen',
  },
  {
    type: 'link',
    to: '/request-user-details',
    icon: 'file-text',
    name: 'Contactgegevens',
  },
  {
    type: 'link',
    to: '/finalised-report',
    icon: 'clipboard',
    name: 'Rapport',
  },
  {
    type: 'link',
    to: '/privacy',
    icon: 'key',
    name: 'Privacy',
  },
  {
    type: 'link',
    to: '/analytics',
    icon: 'bar-chart',
    name: 'Analytics',
  },
];
