import { sort, descend, prop } from 'ramda';

/**
 * Sorts an array bases on createdDate
 * @param {Array<{ createdDate: string }>} x - Any array containing objects that have a createdDate as a property
 * keywords: desc, createdDate
 */
const sortByCreatedDate = sort(
  descend<{ createdDate: string } & $Object>(prop('createdDate')),
);

export default sortByCreatedDate;
