import {
  ContactFiltersCommandFragment,
  ContactFiltersOptionTypeFragment,
  ContactFiltersSubjectFragment,
  ContactFiltersTopicFragment,
  ContactFiltersTypeFragment,
  useGetContactFiltersQuery,
} from '~/graphql/types';

import { useEffect, useState } from 'react';

import useCurrentAccount from '../useCurrentAccount';

type ReturnValue = {
  options: FilterOptions;
  loading: boolean;
};

export type FilterOptions = {
  subjects: Array<ContactFiltersSubjectFragment>;
  commands: Array<ContactFiltersCommandFragment>;
  topics: Array<ContactFiltersTopicFragment>;
  types: Array<ContactFiltersTypeFragment>;
  optionTypes: Array<ContactFiltersOptionTypeFragment>;
};

const INITIAL_FILTER_OPTIONS: FilterOptions = {
  subjects: [],
  commands: [],
  topics: [],
  types: [],

  optionTypes: [],
};
const useContactFilterOptions = (): ReturnValue => {
  const { id: accountId } = useCurrentAccount();
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(
    INITIAL_FILTER_OPTIONS,
  );

  const { data, loading } = useGetContactFiltersQuery({
    variables: { accountId },
  });

  useEffect(() => {
    let didCancel = false;

    if (!data?.getContactFilters) return;

    const { subjects, commands, topics, types, optionTypes } =
      data.getContactFilters;

    if (!didCancel) {
      setFilterOptions({
        subjects,
        topics,
        types,
        optionTypes,
        commands,
      });
    }

    return () => {
      didCancel = true;
    };
  }, [data]);
  return { options: filterOptions, loading };
};

export default useContactFilterOptions;
