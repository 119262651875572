import { groupBy, pluck, sort, uniq } from 'ramda';

import { formattedDateWithFullMonth, sortByDate } from '~/util/date';
import { GroupedActivities } from '~/state/activities';
import { ActivityV2 } from '../../types';
import hash from '~/util/hash';

const groupActivities = (events: Array<ActivityV2>): GroupedActivities => {
  // Sort the dates separately so that when there is a new action from the
  // subscription we make sure that new date is sorted as well
  const dates: Array<string> = pluck('sortingDate', events);
  const sortedDates = uniq(
    dates
      .sort(sortByDate)
      .map(date => formattedDateWithFullMonth(new Date(date))),
  );

  const groupedActivities = groupBy(
    activity => formattedDateWithFullMonth(new Date(activity.sortingDate)),
    events,
  );

  return sortedDates.reduce(
    (acc, curr) => {
      acc[curr] = sort((a, b) => {
        if (a.sortingDate === b.sortingDate) {
          if (a.createdDate === b.createdDate) return hash(b.id) - hash(a.id);
          return sortByDate(a.createdDate, b.createdDate);
        }

        return sortByDate(a.sortingDate, b.sortingDate);
      }, groupedActivities[curr] ?? []);

      return acc;
    },
    {} as Record<string, Array<ActivityV2>>,
  );
};

export default groupActivities;
