import { EmailEffectiveness } from '~/graphql/types';

export type Points = Array<{
  key: keyof Pick<
    EmailEffectiveness,
    'sent' | 'opened' | 'clicked' | 'unsubscribed'
  >;
  label: string;
}>;

export type TabsConfig = Array<{
  label: string;
  points: Points;
}>;

export const tabsConfig: TabsConfig = [
  {
    label: 'Geopend',
    points: [
      {
        key: 'opened',
        label: 'Aantal emails geopend',
      },
      {
        key: 'sent',
        label: 'Aantal emails verzonden',
      },
    ],
  },
  {
    label: 'Geklikt',
    points: [
      {
        key: 'clicked',
        label: 'Aantal emails geklikt',
      },
      {
        key: 'sent',
        label: 'Aantal emails verzonden',
      },
    ],
  },
  {
    label: 'Afgemeld',
    points: [
      {
        key: 'unsubscribed',
        label: 'Aantal afgemeld',
      },
      {
        key: 'sent',
        label: 'Aantal emails verzonden',
      },
    ],
  },
];
