import type { FlowV2_Action__Input } from '~/graphql/types';
import type { ClientFlowActionIfElse } from '~/graphql/types.client';
import generateConditionList from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/utils/generateConditionList';

/**
 *
 * @param {ClientFlowActionIfElse} action - IfElse client action
 * keywords:
 */
const serializeIfElseAction = (
  action: ClientFlowActionIfElse,
): FlowV2_Action__Input => {
  const {
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    trueChildId,
    falseChildId,
    condition,
  } = action;

  return {
    IfElse: {
      id,
      accountId,
      flowBlueprintId,
      parentIds,
      trueChildId,
      falseChildId,
      condition: generateConditionList(condition),
    },
  };
};

export default serializeIfElseAction;
