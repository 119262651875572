import { atom, selector } from 'recoil';
import isRecoilDefaultValue from '~/typeguards/isRecoilDefaultValue';

export type PalettePage = 'root' | 'open-flow' | 'open-contacts' | 'open-forms';

type PaletteState = {
  page: PalettePage;
  open: boolean;
  search: string;
};

export const paletteState = atom<PaletteState>({
  key: 'commandPalette/main',
  default: {
    open: false,
    search: '',
    page: 'root',
  },
});

export const pageSelector = selector<PalettePage>({
  key: 'commandPalette/pageSelector',
  get: ({ get }) => {
    const { page } = get(paletteState);
    return page;
  },
  set: ({ set }, newValue) => {
    if (isRecoilDefaultValue(newValue)) return;

    return set(paletteState, prev => ({
      ...prev,
      page: newValue,
      // When pages change we reset the search
      search: '',
    }));
  },
});

export const searchSelector = selector<string>({
  key: 'commandPalette/searchSelector',
  get: ({ get }) => {
    const { search } = get(paletteState);
    return search;
  },
  set: ({ set }, newValue) => {
    if (isRecoilDefaultValue(newValue)) return;

    return set(paletteState, prev => ({ ...prev, search: newValue }));
  },
});
