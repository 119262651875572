import React, { useState } from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import Input from '~/components/molecule/Input';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import TEST_ID from './index.testid';
import getFormCreatedDate from '~/components/page/Forms/utils/getFormCreatedDate';
import hasValue from '~/util/hasValue';
import type { FormTemplate } from '~/graphql/types';

export type Props = {
  onClose: () => false | void | undefined;
  loading?: boolean;
  onCreate: (name: string, description: string) => void;
  disableClosing?: boolean;
  template: FormTemplate;
} & RouteComponentProps;

const text = {
  header: 'Naam toevoegen',
  description:
    'Zodat je je formulier weer kunt herkennen in de lijst, is het verstandig een naam te geven die (gedeeltelijk) omschrijft wat het formulier doet.',
  formNameTitle: 'Formulier naam',
  formDescriptionTitle: 'Formulier omschrijving',
  cancelButton: 'Annuleren',
  goBackButton: 'Ga terug',
  createButton: 'Maak Formulier',
  newFormName: 'Nieuw formulier',
};

const DescribeForm: React.FCC<Props> = ({
  loading = false,
  onCreate,
  onClose,
  disableClosing,
  template,
}) => {
  const [name, setName] = useState<string>(
    `${template?.name ?? text.newFormName} gemaakt op ${getFormCreatedDate()}`,
  );
  const [description, setDescription] = useState<string>(
    template?.description || '',
  );

  return (
    <AppDetailsContainer
      header={text.header}
      icon="pencil"
      pageDescription={text.description}
      dataTestId={TEST_ID.CONTAINER}
    >
      <JustificationContainer width="100%" gap="base" direction="column">
        <Input
          width="100%"
          label={{ text: text.formNameTitle }}
          onChange={event => setName(event.target.value)}
          value={name}
          data-testid={TEST_ID.NAME_INPUT}
          required
        />

        <Input
          width="100%"
          label={{ text: text.formDescriptionTitle }}
          onChange={event => setDescription(event.target.value)}
          value={description}
          data-testid={TEST_ID.DESCRIPTION_INPUT}
          required
        />
      </JustificationContainer>

      <JustificationContainer
        justification="space-between"
        align="center"
        margin={['base', null, null, null]}
      >
        {!disableClosing && (
          <TextButton
            size="medium"
            label={text.cancelButton}
            onClick={onClose}
            padding={[null]}
            appearance="danger"
            dataTestId={TEST_ID.EXIT_WIZARD_BUTTON}
          />
        )}
        <JustificationContainer align="center">
          <TextButton
            size="medium"
            label={text.goBackButton}
            onClick={() => navigate(-1)}
            padding={[null]}
            appearance="primary"
            dataTestId={TEST_ID.GO_BACK_BUTTON}
            margin={[null, 'm', null, null]}
          />
          <Button
            icon="check"
            appearance="secondary"
            label={text.createButton}
            disabled={!hasValue(name) || !hasValue(description)}
            loading={loading} // When submitting get passed from parent
            onClick={() => {
              onCreate(name, description);
            }}
            size="medium"
            dataTestId={TEST_ID.CREATE_BUTTON}
          />
        </JustificationContainer>
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default DescribeForm;
