import type { ContactFiltersOptionTypeFragment } from '~/graphql/types';

export type OptionTypeMap = {
  [typeId in string]: ContactFiltersOptionTypeFragment;
};

export const generateOptionTypeMap = (
  optionTypes: Array<ContactFiltersOptionTypeFragment>,
): { optionTypeMap: OptionTypeMap } => {
  const optionTypeMap = optionTypes.reduce((prev, curr) => {
    prev[curr.typeId] = curr;

    return prev;
  }, {} as OptionTypeMap);

  return {
    optionTypeMap,
  };
};

export const getOptionType = (
  typeId: string,
  optionTypeMap: OptionTypeMap,
): ContactFiltersOptionTypeFragment | undefined => optionTypeMap[typeId];

export default getOptionType;
