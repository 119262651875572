import { GraphQLError } from 'graphql';
import { startsWith } from 'ramda';

/**
 * checks if there is a duplicated error message in the graphql errors array
 * @param {errors: readonly GraphQLError[] | undefined}
 * keywords: duplicated, token, realworks
 */

const isDuplicatedToken = (
  errors: ReadonlyArray<GraphQLError> | undefined,
): boolean => !!errors?.find(error => startsWith('Duplicated', error.message));

export default isDuplicatedToken;
