import React from 'react';

import { WithNavigationFrame as NotFound } from '~/components/page/404';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import DHRouter from '~/components/atom/DHRouter';
import NylasAuthoriseEndpointV1 from './components/NylasAuthoriseEndpointV1';
import NylasAuthoriseEndpointV2 from './components/NylasAuthoriseEndpointV2';

const Nylas: React.FCC<RouteComponentProps> = () => (
  <DHRouter>
    <NylasAuthoriseEndpointV1 path="/auth/v1" />
    <NylasAuthoriseEndpointV2 path="/auth/v2" />
    <NotFound default />
  </DHRouter>
);

export default Nylas;
