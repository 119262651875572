import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import type { AppStatus_Trustoo_Token, Office } from '~/graphql/types';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import TEST_ID from './index.testid';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import Dropdown, { SelectedOptionOf } from '~/components/molecule/Dropdown';
import FormContainer from '~/components/organism/ModalsV2/FormContainer';
import { withOverlay } from '~/components/organism/ModalsV2/Overlay';
import Input from '~/components/molecule/Input';

export type Props = {
  token: AppStatus_Trustoo_Token;
  onClose: () => void;
  onSave: (token: AppStatus_Trustoo_Token) => void;
  loading: boolean;
};

const text = {
  formHeader: 'Wijzigen eigenaar van dit Trustoo account',
  cancel: 'Afbreken',
  save: 'Opslaan',
  token: 'Trustoo token',
  changeOffice: 'Eigenaar van dit Trustoo account',
};

const EditTokenModal: React.FCC<Props> = ({
  dataTestId,
  token,
  onClose,
  onSave,
  loading,
  ...rest
}) => {
  const officeOptions = useOfficeOptions();

  const [newOfficeId, setOfficeId] = useState<string>(token.officeId);
  const saveIsDisabled = token.officeId === newOfficeId;

  return (
    <FormContainer
      data-testid={dataTestId}
      {...rest}
      header={text.formHeader}
      onClose={onClose}
    >
      <StyledInput
        label={{ text: text.token }}
        value={token.token}
        name="token"
        disabled={true}
      />
      <Dropdown
        label={text.changeOffice}
        dataTestId={TEST_ID.OFFICE_DROPDOWN}
        options={officeOptions}
        selectedOptionIdx={officeOptions.findIndex(
          office => office.payload?.id === newOfficeId,
        )}
        onChange={({ option }: SelectedOptionOf<Office>) =>
          setOfficeId(option.payload.id)
        }
      />

      <JustificationContainer
        justification="space-between"
        align="center"
        margin={['l', null, null]}
      >
        <TextButton
          label={text.cancel}
          onClick={onClose}
          padding={[null]}
          appearance="danger"
          type="button"
          dataTestId={TEST_ID.CANCEL}
        />

        <Button
          dataTestId={TEST_ID.SAVE}
          icon="check"
          label={text.save}
          type="submit"
          disabled={saveIsDisabled}
          appearance="secondary"
          loading={loading}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            const updatedToken = { ...token, officeId: newOfficeId };
            onSave(updatedToken);
          }}
        />
      </JustificationContainer>
    </FormContainer>
  );
};

const StyledInput = styled(Input)<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `,
);

export default withOverlay(EditTokenModal);
