import cleanedFilename from '~/util/cleanedFilename';

export default {
  INPUT: `${cleanedFilename(__filename)}-input`,
  ACTIVATE_BUTTON: `${cleanedFilename(__filename)}-activate-button`,
  CANCEL_BUTTON: `${cleanedFilename(__filename)}-cancel-button`,
  ERROR_MESSAGE: `${cleanedFilename(__filename)}-error-message`,
  PRICE_OFF_LABEL: `${cleanedFilename(__filename)}-price-off-label`,
  COUPON_LABEL: `${cleanedFilename(__filename)}-coupon-label`,
  COUPON_SUB_LABEL: `${cleanedFilename(__filename)}-coupon-sub-label`,
};
