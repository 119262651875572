import React from 'react';
import styled, { css, useTheme } from 'styled-components';

const WIDTH = '1rem';

const Sweep: React.FC = () => {
  const theme = useTheme();
  return (
    <Container
      width={WIDTH}
      height="28"
      viewBox="0 0 18 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-5.85397e-07 14.6077C-5.85397e-07 14.6077 4 14.5 11 10C18 5.5 18 0.499999 18 0.499999L18 28C18 28 17 22.5 11 18.5C5 14.5 -5.85397e-07 14.6077 -5.85397e-07 14.6077Z"
        fill={theme.color('primary', 'dark')}
      />
    </Container>
  );
};

const Container = styled.svg(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.space('m')};
    left: -${WIDTH};
  `,
);

export default Sweep;
