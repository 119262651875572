import { isEmpty } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './index.testid';
import Button from '~/components/atom/Button';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { ValidationFunction } from '~/util/getValidationErrors';
import useMeasure from '~/hooks/useMeasure';

export type Props = {
  onSave: () => void;
  onCancel: () => void;
  onChange: (event: any) => void;
  loading: boolean;
  value: string;
  validation: Array<ValidationFunction>;
  placeholder?: string;

  disabled?: boolean;
};

const checkIsEmpty = value =>
  !isEmpty(value) && value.trim().length ? true : 'Vul een waarde in';

const EditingSection: React.FCC<Props> = ({
  onSave,
  onCancel,
  onChange,
  loading,
  value,
  validation,
  placeholder = 'Typ hier een tekst',
  disabled = false,
  ...rest
}) => {
  const { bounds, ref } = useMeasure();

  return (
    <Container dataTestId={TEST_ID.CONTAINER} align="end" {...rest}>
      <StyledInput
        width="100%"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoFocus
        validation={[checkIsEmpty, ...validation]}
        dataTestId={TEST_ID.INPUT}
        $buttonSize={`${bounds.width}px`}
        disabled={disabled}
      >
        <ButtonsContainer ref={ref} align="center" justification="center">
          <InsetButton
            size="small"
            icon="close"
            ghost
            appearance="tertiary"
            onClick={onCancel}
            disabled={loading || disabled}
            dataTestId={TEST_ID.CANCEL_BUTTON}
          />
          <InsetButton
            size="small"
            icon="check"
            appearance="secondary"
            onClick={onSave}
            disabled={isEmpty(value)}
            loading={loading || disabled}
            dataTestId={TEST_ID.SUBMIT_BUTTON}
          />
        </ButtonsContainer>
      </StyledInput>
    </Container>
  );
};

const Container = styled(JustificationContainer)<{}>`
  width: 100%;
  position: relative;
`;

const StyledInput = styled(Input)<{ $buttonSize: string }>(
  ({ $buttonSize, theme }) => css`
    width: 100%;
    padding-right: calc(${$buttonSize} + ${theme.space('xxs')});
    position: relative;
  `,
);

const ButtonsContainer = styled(JustificationContainer)(
  () => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0.2rem 0.3rem 0.2rem 0.2rem;
    gap: 0.2rem;
  `,
);

const InsetButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.space('xxs')} ${theme.space('xxs')};
  `,
);

export default EditingSection;
