const boxShadow = {
  s: '0px 2px 16px rgba(0, 0, 0, 0.05), 0px 2px 8px rgba(0, 0, 0, 0.1)',
  base: '0 5px 15px 0 rgba(74, 74, 74, 0.15)',
  inverted: '0 -5px 15px 0 rgb(74, 74, 74, 0.15)',
  card: '0px 2px 4px 0px #EAEFF5',
  cardHover: '0px 5px 15px 0px rgba(164, 164, 164, 0.15)',
  around: '0 0 15px 0 rgba(74, 74, 74, 0.15)',
  aroundDanger: '0 0 15px 0 rgba(185, 2, 11, 1)',
  modal: '0px 2px 80px rgba(0, 0, 0, 0.1), 0px 2px 15px rgba(0, 0, 0, 0.1)',
  inset: 'inset 0px 4px 4px rgba(166, 171, 173, 0.22)',
  sleek:
    'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
};

export type SystemBoxShadowMap = typeof boxShadow;
export type SystemBoxShadow = keyof SystemBoxShadowMap;
export default boxShadow;
