import cleanedFilename from '~/util/cleanedFilename';

export default {
  NAME: `${cleanedFilename(__filename)}-name`,
  CREATED: `${cleanedFilename(__filename)}-created`,
  LEAD_SCORE: `${cleanedFilename(__filename)}-leadscore`,
  LEAD_ACTIVITY: `${cleanedFilename(__filename)}-lead-activity`,
  LAST_LEAD_ACTIVITY: `${cleanedFilename(
    __filename,
  )}-lead-activity-created-date`,
  ADDRESS: `${cleanedFilename(__filename)}-address`,
  TAGS: `${cleanedFilename(__filename)}-tags`,
  ACTION: `${cleanedFilename(__filename)}-action`,
  CHECKBOX: `${cleanedFilename(__filename)}-checkbox`,
};
