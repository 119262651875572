import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer, {
  Props as JustificationProps,
} from '~/components/atom/JustificationContainer';
import {
  levelToColorMapping,
  levelToIconNameMapping,
  type ComponentLevel,
} from '~/styles/constants';
import { BaseColor } from '~/theme/System/tokens';

export type Props = JustificationProps & {
  /** Status of the event */
  level?: ComponentLevel | null;

  /** Label to describe the status */
  label?: React.ReactNode;
};

/**
 * Used to display the status of various processes or events (e.g. EmailSyncStatus, EmailDeliveryStatus etc.)
 */
const StatusContainer: React.FCC<Props> = ({
  dataTestId,
  level = 'neutral',
  label,
  padding = ['xs'],
  ...rest
}) => {
  const color = level ? levelToColorMapping[level] : 'tertiary';
  const iconName = level ? levelToIconNameMapping[level] : null;

  return (
    <Container
      data-testid={dataTestId}
      data-level={level}
      width="100%"
      padding={padding}
      $color={color}
      {...rest}
    >
      <JustificationContainer padding={['xxxs', null]}>
        {iconName && <Icon name={iconName} margin={[null, 'xs', null, null]} />}
        {label && <div>{label}</div>}
      </JustificationContainer>
    </Container>
  );
};

const Container = styled(JustificationContainer)<{ $color: BaseColor }>(
  ({ theme, $color }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    color: ${theme.color($color, 'dark')};
    background-color: ${theme.color($color, 'translucent')};
  `,
);

export default StatusContainer;
