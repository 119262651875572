import React from 'react';
import { v4 as uuid } from 'uuid';
import { LinkObj, type ToastLevel } from '~/components/molecule/Toast';

export type FormattedToastMessage = {
  id: string;
  message: React.ReactNode;
  level: ToastLevel;
  link?: LinkObj;
};

/**
 * Formats a toast message ready to be put into the atom
 */
const formatToastMessage = (
  message: React.ReactNode,
  level: ToastLevel,
  link?: LinkObj,
): FormattedToastMessage => ({
  id: uuid(),
  message,
  level,
  link,
});

export default formatToastMessage;
