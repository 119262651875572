import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import RadioButton, {
  type Props as RadioButtonProps,
} from '~/components/molecule/RadioButton';

export type Props = Omit<RadioButtonProps, 'label'> & {
  dataTestId?: string;

  /** Label component */
  label: React.ReactNode;
};

/**
 * Used when we have a custom label component with a large container that overflows in height.
 * We want to align the label with the radio button in the center
 */
const RadioButtonWithCustomLabel: React.FCC<Props> = ({
  dataTestId,
  checked,
  onChange,
  label,
}) => (
  <JustificationContainer align="center">
    <RadioButton
      checked={checked}
      onChange={onChange}
      dataTestId={dataTestId}
    />

    {label}
  </JustificationContainer>
);

export default RadioButtonWithCustomLabel;
