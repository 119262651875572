import React, { useEffect } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import TEST_ID from './index.testid';
import { isNonEmptyString } from '~/util/Validation/String';
import { useGetFlowSettingsQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Loading from '~/components/atom/Loading';
import {
  brandSettingsCurrent,
  brandSettingsInitial,
  type BrandSettings,
} from '../../../../state';
import { useRecoilState } from 'recoil';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import type { DeepPartial } from 'utility-types';
import { Heading3 } from '~/components/atom/Typography';

export type FormData = {
  unsubscribeLinkText: string;
};

const text = {
  heading: 'Automation',
  unsubscribeLinkText: 'Uitschrijf link tekst',
  unsubscribeLinkTextInvalid: 'Deze tekst is verplicht',
};

export type Props = {
  /** Disables the input field */
  readOnly?: boolean;

  /** Partial update of the current changes state */
  onUpdate: (args: DeepPartial<BrandSettings>) => void;
};

const AutomationBlock: React.FCC<Props> = ({ onUpdate, readOnly = false }) => {
  const { id: accountId } = useCurrentAccount();
  const [, setInitialChanges] = useRecoilState(brandSettingsInitial);
  const [currentChanges, setCurrentChanges] =
    useRecoilState(brandSettingsCurrent);
  const { data, loading, error } = useGetFlowSettingsQuery({
    variables: { accountId },
  });

  useEffect(() => {
    if (!loading && data) {
      const unsubscribeLinkText = data.getFlowSettings.unsubscribeLinkText;
      setInitialChanges(prev => ({ ...prev, unsubscribeLinkText }));
      setCurrentChanges(prev => ({ ...prev, unsubscribeLinkText }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (loading) return <Loading />;

  return (
    <JustificationContainer direction="column" margin={['base', null]}>
      {error ? (
        <AppErrorScreen />
      ) : (
        <>
          <Heading3 size="m">{text.heading}</Heading3>
          <Input
            label={{ text: text.unsubscribeLinkText }}
            type="text"
            value={currentChanges.unsubscribeLinkText}
            onChange={e => {
              onUpdate({ unsubscribeLinkText: e.target.value });
            }}
            validation={[
              value => {
                if (!isNonEmptyString(value))
                  return text.unsubscribeLinkTextInvalid;
                return true;
              },
            ]}
            disabled={readOnly}
            width="100%"
            dataTestId={TEST_ID.UNSUBSCRIBE_TEXT_INPUT}
          />
        </>
      )}
    </JustificationContainer>
  );
};

export default AutomationBlock;
