import { Transforms } from 'slate';
import { DHEditor } from '~/components/organism/PluginsEditor/types';

/**
 * Inserts copy-pasted plain text without preserving the original style.
 * It excludes HTML formatting.
 * */

const withSimpleInsert = ({ editor }: { editor: DHEditor }): DHEditor => {
  const { insertData } = editor;

  editor.insertData = data => {
    const slateFragment = data.getData('application/x-slate-fragment');

    if (slateFragment) {
      const decoded = decodeURIComponent(window.atob(slateFragment));
      const parsed = JSON.parse(decoded);

      Transforms.insertFragment(editor, parsed);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export default withSimpleInsert;
