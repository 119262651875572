import React from 'react';
import LocaleIcon from '~/components/atom/Icon/components/LocaleIcon';
import type { Option } from '~/components/molecule/Dropdown';
import { FormBuilder_Locale } from '~/graphql/types';

const localeText = {
  NL: 'Nederlands',
  EN: 'English',
};

export type LocaleOption = Omit<Option, 'key'> & { key: FormBuilder_Locale };
type Args = {
  availableOptions?: Array<FormBuilder_Locale>;
};

const useLocaleOptions = ({
  availableOptions = [FormBuilder_Locale.Nl, FormBuilder_Locale.En],
}: Args = {}): Array<LocaleOption> => {
  const options = availableOptions.map(curr => ({
    key: curr,
    label: (
      <>
        {localeText[curr]} <LocaleIcon locale={curr} />
      </>
    ),
    payload: curr,
  }));

  return options;
};

export default useLocaleOptions;
