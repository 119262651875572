import React, { type MutableRefObject } from 'react';
import { append } from 'ramda';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import { getSignatureKey, setLocalStorageItem } from '~/util/localStorageKeys';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useSignatures, { ExtendedSignature } from '~/hooks/useSignatures';
import { OptionOf } from '~/components/molecule/Dropdown';
import useMainOffice from '~/hooks/useMainOffice';
import useCurrentUser from '~/hooks/useCurrentUser';
import sortSignatures from './utils/sortSignatures';
import { useRecoilState } from 'recoil';
import defaultSignatureKey from './state';

export const NO_SIGNATURE_ID = 'no-signature';

const text = {
  noSignature: 'Geen handtekening',
};
export type DropdownPosition = { top: number; left: number };
export const DROPDOWN_WIDTH = 300;
export type Props = {
  /** Is dropdown shown */
  isOpen: boolean;

  /** Callback for option change */
  onChange: (options: OptionOf<ExtendedSignature | null>) => void;

  /** Close the dropdown list */
  onClose: () => void;
};

const SignatureDropdownList = React.forwardRef<HTMLButtonElement | null, Props>(
  ({ onChange, isOpen, onClose }, ref) => {
    const { id: accountId } = useCurrentAccount();

    const signatures = useSignatures();
    const me = useCurrentUser();
    const mainOffice = useMainOffice(me.id);

    const [signatureKey, setSignatureKey] = useRecoilState(defaultSignatureKey);

    const sortedSignatures: Array<ExtendedSignature> = sortSignatures({
      signatures,
      mainOfficeId: mainOffice?.id,
    });

    const options = append(
      { key: NO_SIGNATURE_ID, payload: null, label: text.noSignature },
      sortedSignatures.map(
        item =>
          ({
            label: item.label,
            key: item.id,
            payload: item,
          }) as OptionOf<ExtendedSignature | null>,
      ),
    );

    return (
      <DropdownListContainer
        dropdownWidth={DROPDOWN_WIDTH}
        onClose={onClose}
        onClickOutside={onClose}
        dropdownListOpen={isOpen}
        onChange={({ option }) => {
          onChange(option);

          setLocalStorageItem(
            getSignatureKey(accountId),
            option.key.toString(),
          );
          setSignatureKey(option.key.toString());

          onClose();
        }}
        options={options}
        selectedOptionIdx={options.findIndex(({ key }) => key === signatureKey)}
        openerRef={ref as MutableRefObject<HTMLButtonElement>}
      />
    );
  },
);

export default SignatureDropdownList;
