import { addDays, isAfter } from 'date-fns';

import { TAPFILLIATE_REF, getLocalStorageItem } from '~/util/localStorageKeys';

const VALID_DAYS = 7;

/**
 * Retrieves value from local storage, if present it will check if code is still valid if so returns it otherwise it'll be null
 * If any errors happen they will be ignored and null will be returned
 * @returns string | null
 */
const getTapfillateRef = (): string | null => {
  const tapfillateRef = getLocalStorageItem(TAPFILLIATE_REF);
  if (!tapfillateRef) return null;

  try {
    const parsed: { date: number; ref: string } = JSON.parse(tapfillateRef);
    const ref = parsed.ref;
    const parsedDate = new Date(parsed.date);
    const today = new Date();
    if (!isAfter(today, addDays(parsedDate, VALID_DAYS))) {
      return ref;
    }

    return null;
  } catch {
    return null;
  }
};

export default getTapfillateRef;
