import { memoizeWith } from 'ramda';
import { TableHeaderDefinitionCellType, TableCell } from '../../types.flow';
import { BaseHeaderCellOptions } from './base';
import { v4 as uuidv4 } from 'uuid';
import { asBaseOptions } from './base';

type CustomHeaderCellOptions<T> = BaseHeaderCellOptions & {
  renderFunction?: (value: T, tableCell: TableCell<T>) => React.ReactNode;
  /**
   * Currently custom header cells need a version key to force the cells to rerender after navigating away and back to the table.
   * The version here triggers memoizeWith when sorting is clicked, so that it doesn't return the previous cached result.
   * We had some discussions about this and decided this is the best solution for now.
   */
  version?: string;
};
const asCustomTableHeaderCell = <T>({
  renderFunction,
  ...rest
}: CustomHeaderCellOptions<T>): TableHeaderDefinitionCellType<T> => {
  let options = asBaseOptions({
    ...rest,
  });

  if (renderFunction != null) {
    const memoizedRenderFunction = memoizeWith(createMemoKey, renderFunction);

    options = {
      ...options,
      Cell: tableCell =>
        memoizedRenderFunction(tableCell.cell.value, tableCell),
    };
  }

  return options;
};

const createMemoKey = () => uuidv4();

export default memoizeWith(createMemoKey, asCustomTableHeaderCell);
