import type { ContactHasNoteEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

const asHasNoteEventFilterInput = (
  filter: ContactHasNoteEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => {
  const { has } = filter;

  return {
    Note: {
      has: asHasFilterInput(has),
    },
  };
};

export default asHasNoteEventFilterInput;
