import {} from 'react';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useCurrentUser from '~/hooks/useCurrentUser';
import useMainOffice from '~/hooks/useMainOffice';

const fixedValues = {
  country: 'Nederland',
  discountCode: 'DHCONTAQT',
  affiliate: 'dathuis',
};

/**
 * This link is used in src/components/page/Apps/Contaqt/appConfig.ts
 * There we cannot set the dynamic values so only the fixed parameters are added.
 */
export const CONTAQT_URL = `https://checkout.contaqt.nl/checkout/contaqt?discount_code=${fixedValues.discountCode}&affiliate=${fixedValues.affiliate}`;

const useContaqtSignUpLink = (): string => {
  const currentUser = useCurrentUser();
  const { name: accountName } = useCurrentAccount();
  const mainOffice = useMainOffice(currentUser.id);

  const [firstName, ...rest] = currentUser.name.split(' ');

  const queryParams = new URLSearchParams({
    firstname: firstName,
    lastname: rest && rest.length > 0 ? rest.join(' ') : '',
    telephone: currentUser.phone,
    country: fixedValues.country,
    zipcode: mainOffice?.address?.postcode || '',
    housenumber: `${mainOffice?.address?.houseNumber || ''}`,
    email: currentUser.email,
    company: accountName,
  });

  const encodedQueryParams = queryParams.toString();

  return `${CONTAQT_URL}&${encodedQueryParams}`;
};

export default useContaqtSignUpLink;
