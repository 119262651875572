import React from 'react';
import {
  Handle,
  NodeProps,
  Position,
  getIncomers,
  useEdges,
  useNodes,
  type Node,
} from 'reactflow';
import { type CommonNodeData } from '../../..';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { menuState } from '../../../../../state';
import { START_NODE_ID } from '../../../constants';
import Button from '~/components/atom/Button';
import { copyScreenNodeState } from '../../../../../state/copyScreenNode';
import PasteContainer from '../../../components/PasteContainer';
import { isNil } from 'ramda';

type NodeData = CommonNodeData & {
  value: number;
};

const BoundingNode: React.FC<NodeProps<NodeData>> = ({
  isConnectable,
  id,
  xPos,
  yPos,
}) => {
  const nodes = useNodes();
  const edges = useEdges();
  const currentNode = nodes.find(
    node => node.id === id,
  ) as Node<CommonNodeData>;

  const setMenuState = useSetRecoilState(menuState);
  const copiedNodeValue = useRecoilValue(copyScreenNodeState);

  const x = xPos + 82;
  const y = yPos + 12;

  const [firstIncomer] = getIncomers(currentNode, nodes, edges);

  const isStartNode = currentNode.id === START_NODE_ID;
  const source = isStartNode ? START_NODE_ID : firstIncomer?.id;
  const target = isStartNode ? currentNode.data?.defaultNext || '' : id;

  return (
    <>
      {isStartNode ? (
        <Handle
          type="source"
          position={Position.Right}
          isConnectable={isConnectable}
        />
      ) : (
        <Handle
          type="target"
          position={Position.Left}
          isConnectable={isConnectable}
        />
      )}

      {!isNil(copiedNodeValue) ? (
        <PasteContainer source={source} target={target} />
      ) : (
        <Button
          icon="plus"
          onClick={() => {
            setMenuState({
              openedOnEdge: false,
              edgeId: id,
              targetX: x,
              targetY: y,
              sourceId: source,
              targetId: target,
            });
          }}
        />
      )}
    </>
  );
};

export default BoundingNode;
