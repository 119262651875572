import { AppType } from '~/graphql/types';
import { AppConfig } from '../types';

const description = `
## Omschrijving
Homekeur is dé specialist voor het uitvoeren van een bouwkundige keuring en het afgeven van een energielabel. Met een ruim en groeiend landelijk netwerk van eigen opgeleide en deskundige inspecteurs bent u als opdrachtgever gegarandeerd van een gemotiveerde en ambitieuze werkwijze en gedegen inspectie door de inspecteur.

Door deze app te activeren worden twee diensten in je widget toegevoegd: Bouwkundige keuring en Energielabel. Deze diensten worden via jouw widget direct besteld bij Homekeur. Vervolgens verdien jij een aantrekkelijke commissie van €42,98 exclusief btw (€52,- inclusief btw) per aangebrachte bouwkundige keuring en €30 exclusief btw (€36,30 inclusief btw) per energielabel. Commissies worden automatisch maandelijks gecrediteerd op jouw DatHuis factuur.

## Installatie
Nadat je de app hebt geactiveerd, worden de vier diensten automatisch getoond in de widget op je website. Dit kan worden aangepast in de widget instellingen.
`;

const termsOfUse = `
Door het activeren van deze app word je partner van Homekeur B.V. en geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van Homekeur B.V.. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Op de dienstverlening en het Partnerprogramma van Homekeur B.V. zijn de voorwaarden van Homekeur B.V. van toepassing. Meer informatie vind je op [https://www.homekeur.nl/](https://www.homekeur.nl/).
`;

export const homekeur: AppConfig = {
  __typename: 'AppStatus_Homekeur',
  appType: AppType.Homekeur,
  type: 'activate_only',
  slug: 'homekeur',
  name: 'Bouwkundige keuring & Energielabel',
  tags: ['Energielabel', 'Bouwkundige keuring', 'E-mailcampagne'],
  provider: 'Homekeur',
  logo: {
    src: 'https://dathuis-stash.imgix.net/homekeur.png',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/homekeur.png',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/8416836-over-de-bouwkundige-keuring-energielabel-app-van-homekeur',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'default',
};
