import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { FlowV2_ParameterMappingFragment } from '~/graphql/types';
import getPathForPointer from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getPathForPointer';
import { getPathRepresentation } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/utils/getLabelForRep';
import { assertNeverWithoutThrowing } from '~/util/assertion';
import TEST_ID from './index.testid';
import useRelativeMaps from '~/components/page/Automation/v2/components/Builder/hooks/useRelativeMaps';
import getArgumentValueAsText from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getArgumentValueAsText';
import ActionLabelAsText from '../../../../../ActionLabelAsText';

export type Props = {
  actionId: string;
  /** Mapping id */
  id: string;
  mappings: Array<FlowV2_ParameterMappingFragment>;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
};

const text = {
  noValue: 'Geen waarde',
};

const Variable = React.forwardRef<HTMLSpanElement, Props>(
  ({ mappings, id, actionId, onClick }, ref) => {
    const { instanceMap, subjectMap, directoryMap } = useRelativeMaps({
      actionId,
    });
    const currentMapping = mappings.find(({ mappingId }) => mappingId === id);

    if (!currentMapping || !currentMapping.mapping)
      return (
        <Container
          data-testid={TEST_ID.CONTAINER}
          $isValid={false}
          onClick={onClick}
          ref={ref}
        >
          {text.noValue}
        </Container>
      );

    switch (currentMapping.mapping.__typename) {
      case 'Flow___Argument_Pointer': {
        const path = getPathForPointer(currentMapping.mapping.pointer, {
          instanceMap,
          subjectMap,
          directoryMap,
        });

        if (path.error != null) {
          return (
            <Container
              data-testid={TEST_ID.CONTAINER}
              onClick={onClick}
              ref={ref}
            >
              {text.noValue}
            </Container>
          );
        }

        return (
          <Container
            data-testid={TEST_ID.CONTAINER}
            onClick={onClick}
            ref={ref}
          >
            <ActionLabelAsText
              str={getPathRepresentation(path.fullResult)}
              highlightLabel
            />
          </Container>
        );
      }
      case 'Flow___Argument_File':
        return null;

      case 'Flow___Argument_AWSDateTime':
      case 'Flow___Argument_Boolean':
      case 'Flow___Argument_Float':
      case 'Flow___Argument_Integer':
      case 'Flow___Argument_String':
        return (
          <Container
            data-testid={TEST_ID.CONTAINER}
            onClick={onClick}
            ref={ref}
          >
            {getArgumentValueAsText(currentMapping.mapping)}
          </Container>
        );

      default:
        assertNeverWithoutThrowing(currentMapping.mapping);
        return null;
    }
  },
);

export const Container = styled.span<{
  $isValid?: boolean;
  onClick?: Props['onClick'];
}>(
  ({ theme, onClick, $isValid = true }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    background-color: ${$isValid
      ? theme.color('primary', 'translucent')
      : theme.color('danger', 'translucent')};
    color: ${$isValid ? theme.color('primary') : theme.color('danger')};
    padding: ${theme.space('xxs')};
    display: inline-block;
    cursor: ${isNil(onClick) ? 'unset' : 'pointer'};
  `,
);

export default Variable;
