import React from 'react';
import type { JsonStructure, JsonStructureItem } from 'react-cmdk';
import { navigate } from '@gatsbyjs/reach-router';
import waitForElement from '~/util/waitForElement';
import { useTheme } from 'styled-components';
import Icon from '~/components/atom/Icon';

const useTasksItems = (): JsonStructure[number] => {
  const theme = useTheme();
  const iconColor = theme.color('secondary');

  const items: Array<JsonStructureItem> = [
    {
      id: 'tasks',
      children: 'Taken',
      icon: () => <Icon name="branches" color={iconColor} />,
      onClick: () => {
        void navigate('/-/tasks');
      },
      showType: false,
      keywords: ['tasks'],
    },
    {
      id: 'create-task',
      children: 'Nieuwe taak',
      icon: () => <Icon name="plus" color={iconColor} />,
      showType: false,
      onClick: async () => {
        void navigate('/-/tasks/');
        const createButton = await waitForElement<HTMLButtonElement>(
          '[data-testid="add-task-button"] button',
        );

        if (createButton) {
          createButton.click();
        }
      },
      keywords: ['new', 'task'],
    },
  ];

  return {
    heading: 'Taken',
    id: 'tasks',
    items: items,
  };
};

export default useTasksItems;
