import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { FlowAction } from '~/graphql/types';
import { getConnectorOperatorLabel } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/components/ConditionGroup/components/ConnectorComponent';
import Condition from '../Condition';
import { messages } from '~/components/page/Automation/v2/components/Builder/getIssuesForConditionExpression';
import type { ConditionGroup } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2';
import type { RelativeMaps } from '~/components/page/Automation/v2/components/Builder/utils/getRelativeMaps';
import Div from '~/components/atom/Div';

export type Props = {
  dataTestId?: string;
  conditionGroups: Array<ConditionGroup>;
  subExpressionIdx: number;
  moreConditionGroups: number;
  conditionMaps: RelativeMaps;
  actionType: FlowAction;
  subExpressionConnector: string;
};

const ConditionGroups: React.FCC<Props> = ({
  conditionGroups,
  subExpressionIdx,
  moreConditionGroups,
  conditionMaps,
  actionType,
  subExpressionConnector,
}) => (
  <Container
    backgroundColor={{ group: 'tertiary', variant: 'light' }}
    padding={['m']}
  >
    {conditionGroups.map((conditionGroup, conditionGroupIdx) => {
      const isLast = conditionGroupIdx === conditionGroups.length - 1;
      const conditionGroupConnector = getConnectorOperatorLabel(
        conditionGroup.connector,
      );

      const [conditions, moreConditions] = (() => {
        const SHOW_CONDITIONS = 1;
        const conditions = conditionGroup.conditions.slice(0, SHOW_CONDITIONS);
        const moreConditions =
          conditionGroup.conditions.length > SHOW_CONDITIONS
            ? conditionGroup.conditions.length - SHOW_CONDITIONS
            : 0;

        return [conditions, moreConditions];
      })();

      return (
        <React.Fragment key={`${subExpressionIdx}-${conditionGroupIdx}`}>
          <JustificationContainer
            padding={['s']}
            wrap="wrap"
            gap="xxs"
            backgroundColor={{ group: 'white' }}
            border={{
              color: { group: 'info' },
              width: 's',
              radius: 'base',
            }}
          >
            {conditions.map((condition, conditionIdx) => {
              const key = `${subExpressionIdx}-${conditionIdx}`;
              if (!condition) return <p key={key}>{messages.emptyCondition}</p>;

              const isLast =
                conditionIdx === conditionGroup.conditions.length - 1;
              const layout = conditionIdx === 0 ? 'complete' : 'onlyArguments';

              const type = (() => {
                switch (actionType) {
                  case FlowAction.Start:
                  case FlowAction.Wait:
                    return conditionGroupIdx === 0 ? 'trigger' : 'condition';

                  default:
                    return 'condition';
                }
              })();

              return (
                <React.Fragment key={key}>
                  <Condition
                    actionType={actionType}
                    condition={condition}
                    layout={layout}
                    maps={conditionMaps}
                    type={type}
                    isLast={isLast}
                  />
                  {!isLast && conditionGroupConnector}
                </React.Fragment>
              );
            })}
            {moreConditions != 0 && (
              <MoreContainer>{moreConditions} meer</MoreContainer>
            )}
          </JustificationContainer>
          {!isLast && subExpressionConnector}
          {moreConditionGroups != 0 && (
            <MoreConditionGroupsContainer>
              + {moreConditionGroups} meer
            </MoreConditionGroupsContainer>
          )}
        </React.Fragment>
      );
    })}
  </Container>
);

const MoreContainer = styled.span(
  ({ theme }) => css`
    font-style: italic;
    color: ${theme.color('secondary')};
    text-decoration: underline;
  `,
);

const MoreConditionGroupsContainer = styled(MoreContainer)(
  ({ theme }) => css`
    display: block;
    margin-top: ${theme.space('m')};
  `,
);

const Container = styled(Div)(
  ({ theme }) => css`
    border-bottom-left-radius: ${theme.getTokens().border.radius.base};
    border-bottom-right-radius: ${theme.getTokens().border.radius.base};
  `,
);

export default ConditionGroups;
