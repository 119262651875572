import { convertServerDateStringToDate, safeFormattedDate } from '~/util/date';

/**
 * Formats server date into event card date to be used in event body (without time)
 * Returned format is d-M-yyyy
 * @param {string} date - Server date
 * keywords: dateformat
 */
const formatDate = (date: string): string =>
  safeFormattedDate(convertServerDateStringToDate(date));

export default formatDate;
