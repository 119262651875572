import { atom, selector } from 'recoil';
import type { FormBuilderFragment, FormBuilderMode } from '~/graphql/types';
import type { CreateRelation } from '../components/AddNodeMenu';

export type MenuState = {
  openedOnEdge: boolean;
  edgeId: string;
  targetX: number;
  targetY: number;
  sourceId: string;
  targetId: string;
};

export type CreateScreenInteraction = {
  interactionType: 'create-node-screen';
  relation: CreateRelation;
};

export type EditNodeInteraction = {
  interactionType: 'edit-node-screen' | 'edit-node-event';
  subjectId: string | null;
};

export type MissingBrandSettingURLInteraction = {
  interactionType: 'prompt-url';
};

export type EditEventInteraction = {
  interactionType: 'edit-event';
  subjectId: string | null;
};

export type InteractionState =
  | CreateScreenInteraction
  | EditNodeInteraction
  | EditEventInteraction
  | MissingBrandSettingURLInteraction;

export type FormBuilder_Interaction = InteractionState['interactionType'];

export const formState = atom<FormBuilderFragment | null>({
  key: 'formBuilder/formState',
  default: null,
});

/**
 * Only set once when the form data is returned
 */
export const initialFormAtom = atom<FormBuilderFragment | null>({
  key: 'formBuilder/initialForm',
  default: null,
});

export const interactionState = atom<InteractionState | null>({
  key: 'formBuilder/formBuilderInteraction',
  default: null,
});

export const menuState = atom<MenuState | null>({
  key: 'formBuilder/formBuilderMenuState',
  default: null,
});

export const formModeSelector = selector<FormBuilderMode | null>({
  key: 'formBuilder/formBuilderFormModeState',
  get: ({ get }) => {
    const form = get(formState);
    if (!form) return null;

    return form.mode;
  },
});
