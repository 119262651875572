import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import AsideHeader from '../AsideHeader';
import { animated, useSpring } from 'react-spring';

import TEST_ID from './index.testid';
import useMeasure from '~/hooks/useMeasure';
import { useRecoilValue } from 'recoil';
import { issueState } from '../../../../state/issues';
import Issue from './components/Issue';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { SectionId } from '../../../../state/asideExplanded';

type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: (section: SectionId) => void;
};

const text = {
  heading: 'Foutherstel',
  noIssues: 'Geen fouten',
};

const Issues: React.FCC<Props> = ({
  dataTestId,
  expanded = false,
  onToggleExpand,
}) => {
  const issues = useRecoilValue(issueState);
  const { ref, bounds } = useMeasure();
  const theme = useTheme();

  const spring = useSpring({
    height: expanded ? bounds.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  const errorIssues = issues.filter(({ level }) => level === 'error');

  return (
    <Container data-testid={dataTestId}>
      <AsideHeader
        dataTestId={TEST_ID.ASIDE_HEADER}
        heading={text.heading}
        icon="exclamation"
        onClick={() => onToggleExpand('issues')}
        expanded={expanded}
        indicator={
          issues.length !== 0
            ? { appearance: errorIssues.length > 0 ? 'danger' : 'warning' }
            : undefined
        }
      />
      <OverflowContainer style={spring}>
        <Inner ref={ref}>
          <JustificationContainer direction="column" gap="m">
            {issues.length === 0 && <p>{text.noIssues}</p>}
            {issues.map((issue, index) => (
              <Issue
                key={index}
                {...issue}
                onClick={() => {
                  if (issue.sectionId) {
                    onToggleExpand(issue.sectionId);
                  }
                }}
              />
            ))}
          </JustificationContainer>
        </Inner>
      </OverflowContainer>
    </Container>
  );
};

const Container = styled.aside(
  ({ theme }) => css`
    z-index: ${theme.z('top')};
    user-select: none;
    width: 100%;
  `,
);

const Inner = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
  `,
);

const OverflowContainer = styled(animated.div)`
  overflow-x: hidden;
`;

export default Issues;
