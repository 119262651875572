import React from 'react';
import Dropdown, { OptionOf } from '~/components/molecule/Dropdown';
import {
  FlowData___PrimitiveListInputFragment,
  FlowData___PrimitiveListInputOption,
} from '~/graphql/types';
import { Flow___PrimitiveArgument } from '~/graphql/types.client';
import { assertNever } from '~/util/assertNever';
import getValueForPrimitiveListInput from '../../utils/getValueForPrimitiveListInput';
import getValueForArgument from '../../../../../Selector/utils/getValueForArgument';

export type Props = {
  input: FlowData___PrimitiveListInputFragment;
  value: Flow___PrimitiveArgument | null;
  onChange: (value: Flow___PrimitiveArgument) => void;
};

const PrimitiveListDropdown: React.FCC<Props> = ({
  input,
  value,
  onChange,
}) => (
  <Dropdown
    options={input.options.map(option => ({
      key: option.key,
      label: option.label,
      payload: option,
    }))}
    onChange={({
      option,
    }: {
      option: OptionOf<FlowData___PrimitiveListInputOption>;
    }) => {
      switch (option.payload.__typename) {
        case 'FlowData___PrimitiveListInputOption_Boolean':
          onChange({
            __typename: 'Flow___Argument_Boolean',
            value_boolean: option.payload.value_boolean,
          });
          return;
        case 'FlowData___PrimitiveListInputOption_Int':
          onChange({
            __typename: 'Flow___Argument_Integer',
            value_integer: option.payload.value_integer,
          });
          return;
        case 'FlowData___PrimitiveListInputOption_String':
          onChange({
            __typename: 'Flow___Argument_String',
            value_string: option.payload.value_string,
          });
          return;
        default:
          return assertNever(option.payload);
      }
    }}
    selectedOptionIdx={input.options.findIndex(
      opt => getValueForPrimitiveListInput(opt) === getValueForArgument(value),
    )}
  />
);

export default PrimitiveListDropdown;
