import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  FALLBACK_IMAGE_UPLOADER: `${cleanedFilename(
    __filename,
  )}-fallbackImage-uploader`,
  LOGO_IMAGE_UPLOADER: `${cleanedFilename(__filename)}-logoImage-uploader`,
  LOGO_LINK: `${cleanedFilename(__filename)}-logoLink`,
  PRIMARY: `${cleanedFilename(__filename)}-primary`,
  SECONDARY: `${cleanedFilename(__filename)}-secondary`,
  TERTIARY: `${cleanedFilename(__filename)}-tertiary`,
  QUARTERNARY: `${cleanedFilename(__filename)}-quarternary`,
};
