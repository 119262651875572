const lineHeight = {
  s: 1,
  base: 1.2,
  m: 1.5,
  l: 1.8,
};

export type SystemLineHeightMap = typeof lineHeight;
export type SystemLineHeight = keyof SystemLineHeightMap;
export default lineHeight;
