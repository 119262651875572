import React from 'react';
import getLabelParts from '~/components/page/Automation/v2/util/getLabelParts';
import isActionLabel from '../../utils/isActionLabel';
import { isNil } from 'ramda';
import ActionLabel from './components/ActionLabel';

export type Props = {
  dataTestId?: string;
  /**
   * A string that might include both label + text or just text
   * E.g. [[A1]] Some text
   */
  label: string | React.ReactNode | null;

  /**
   * Changes component's size
   */
  size?: 'small' | 'base';

  /**
   * If the outside container is hovered or clicked
   */
  active?: boolean;
};

const RepresentationLabel: React.FCC<Props> = ({
  label,
  size = 'base',
  active = false,
}) => {
  if (!label) return null;

  if (typeof label !== 'string') return <>{label}</>;

  const parts = getLabelParts(label);

  if (isNil(parts)) return null;

  return (
    <>
      {parts.map((part, index) => {
        if (isActionLabel(part)) {
          // Remove the brackets and pass the text to LabelContainer
          const cleanLabel = part.slice(2, -2);
          return (
            <ActionLabel
              key={index}
              size={size}
              active={active}
              label={cleanLabel}
            />
          );
        }

        return part;
      })}
    </>
  );
};

export default RepresentationLabel;
