import React from 'react';
import { Handle, Position } from 'reactflow';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '../../constants';
import type { PortalData } from '../../../../utils/getElementsFromActions/utils/getPortalCards';
import { handleStyle } from '../CardTemplate';
import PortalCard from '../PortalCard';

export type Props = {
  data: PortalData;
};

const text = {
  goTo: 'Ga naar',
};

const SourcePortalCard: React.FCC<Props> = React.memo(
  ({ data: { color, targetId, parentId, label } }) => (
    <Container>
      <Handle
        type="target"
        position={Position.Top}
        style={{ ...handleStyle, stroke: color }}
        isConnectable={false}
      />

      <JustificationContainer>
        <PortalCard
          type="source"
          color={color}
          sourceActionId={parentId}
          targetActionId={targetId}
          tooltipText={`${text.goTo} ${label}`}
        >
          Verbindt met <strong>{label}</strong>
        </PortalCard>
      </JustificationContainer>
    </Container>
  ),
);

const Container = styled.div<{}>(
  () => css`
    width: ${DEFAULT_WIDTH}px;
    height: ${DEFAULT_HEIGHT / 2}px;
    display: flex;
    justify-content: center;
  `,
);

export default SourcePortalCard;
