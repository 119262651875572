import { cloneDeep } from 'lodash';
import type {
  ContactFiltersTopicFragment,
  ContactFiltersSubjectFieldFragment,
  ContactFiltersCommandFragment,
} from '~/graphql/types';
import { OuterContactFilterField } from '../getOuterFieldsByPath';
import { assertNever } from '~/util/assertion';
import { getCommands, CommandMap, TypeIdToCommandsMap } from '../getCommand';
import getSubject, { SubjectMap } from '../getSubject';
import getTopic, { TopicMap } from '../getTopic';

export type InnerContactFilterField =
  | ContactFiltersCommandFragment
  | ContactFiltersTopicFragment
  | ContactFiltersSubjectFieldFragment;

export type InnerContactFilterFieldOutput =
  | $GetElementType<ContactFiltersTopicFragment['fields']>
  | ContactFiltersSubjectFieldFragment
  | ContactFiltersCommandFragment;

export type GetContactFilterSubFieldsOpts = {
  subjectMap: SubjectMap;
  topicMap: TopicMap;
  commandMap: CommandMap;
  typeIdToCommandsMap: TypeIdToCommandsMap;
};
const getInnerFieldsByPath = (
  path: Array<InnerContactFilterField>,
  basePath: Array<OuterContactFilterField>,
  opts: GetContactFilterSubFieldsOpts,
):
  | {
      result: Array<InnerContactFilterFieldOutput>;
      error?: undefined;
    }
  | { error: string } => {
  const { subjectMap, topicMap, typeIdToCommandsMap } = opts;

  const attributeSelection = basePath[basePath.length - 1];
  if (
    !attributeSelection ||
    attributeSelection.__typename !==
      'ContactFiltersSubjectInstanceAttributeAction'
  ) {
    return {
      error: `The last basePath needs to be an attribute action selection`,
    };
  }
  const subjectSelection = basePath[basePath.length - 2];
  if (
    !subjectSelection ||
    subjectSelection.__typename !== 'ContactFiltersSubject'
  ) {
    return { error: `The second to last basePath needs to be a subject` };
  }

  const subject = getSubject(subjectSelection, subjectMap);
  if (!subject) {
    return {
      error: `Could not find subject ${subjectSelection.subjectId}:${subjectSelection.eventId}`,
    };
  }

  /**
   * If we start anew, we straight up loop over the root directory
   */
  if (path.length === 0) {
    return {
      result: subject.instanceAttribute.fields,
    };
  }

  /**
   * Check every `path` entry for validity and combine all next fields
   * once we hit the last part of the path.
   */
  for (let pathIndex = 0; pathIndex < path.length; ++pathIndex) {
    const pathPart = path[pathIndex];
    const isLast = pathIndex === path.length - 1;

    switch (pathPart.__typename) {
      case 'ContactFiltersTopic':
      case 'ContactFiltersSubjectFieldTopic': {
        const topic = getTopic(pathPart.topicId, topicMap);
        if (!topic) {
          return { error: `Unable to find topic ${pathPart.topicId}` };
        }

        if (!isLast) continue;

        return { result: cloneDeep(topic.fields) };
      }
      case 'ContactFiltersSubjectFieldType': {
        if (!isLast) continue;

        return {
          result: cloneDeep(getCommands(pathPart.typeId, typeIdToCommandsMap)),
        };
      }
      case 'ContactFiltersCommand': {
        if (!isLast) {
          return {
            error: `ContactFiltersCommand must be the last in the path`,
          };
        }

        return {
          result: [],
        };
      }
      default:
        assertNever(pathPart);
    }
  }

  /**
   * @todo we should send this to bugsnag / sentry. If our
   * code is correct this should not occur. TS gets confused with
   * our `isLast` check.
   */
  return { error: 'Reached end of line without making an inner filter' };
};

export default getInnerFieldsByPath;
