import React from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import { isNil, map, omit, pluck } from 'ramda';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import Link from '~/components/molecule/Link';
import {
  useDeleteZapierEventMutation,
  useGetZapierEventsQuery,
  useInsertZapierEventMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import OverviewTemplate from '../OverviewTemplate';
import { LINK_PREFIX } from '../Wizard';
import TEST_ID from './index.testid';

export type Props = {} & RouteComponentProps;

const text = {
  header: 'Inkomende koppelingen',
  body: (
    <>
      Met een inkomende koppeling bepaal je welke data je kan ontvangen vanuit
      Zapier. Standaard zijn de velden NAWTE en tags beschikbaar. Deze hoef je
      niet zelf toe te voegen.
      <br />
      <br />
      Door extra velden toe te voegen kan je contacten met nieuwe data
      verrijken. Nieuwe data wordt in de tijdlijn van het contact getoond.
      <br />
      <br />
      Voeg een inkomende koppeling toe{' '}
      <Link
        dataTestId={TEST_ID.EVENTS_WIZARD_LINK}
        to={`${LINK_PREFIX}/add-zapier-event`}
      >
        vanuit een template.
      </Link>
    </>
  ),
  tableHeader: 'Inkomende koppelingen',
  noConnections: 'Je hebt nog geen inkomende koppelingen voor Zapier.',
  addButtonLabel: 'Koppeling toevoegen',
  tableHeaders: {
    name: 'Koppeling naam',
    fields: 'Velden',
    flows: 'In flows',
    zaps: 'In zaps',
  },
  tip: (
    <>
      Lees hoe je de koppeling moet instellen in onze{' '}
      <Link to="https://help.dathuis.nl/">kennisbank</Link>. Kom je er niet uit?
      &nbsp;
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
  failedToDelete:
    'Helaas is het verwijderen van de koppeling niet gelukt, probeer het later opnieuw.',
  failedToCopy:
    'Helaas is het kopiëren van de koppeling niet gelukt, probeer het later opnieuw.',
};

const Events: React.FCC<Props> = ({}) => {
  const addToast = useAddToast();
  const { id: accountId } = useCurrentAccount();
  const { data, updateQuery, loading } = useGetZapierEventsQuery({
    variables: {
      accountId,
    },
  });
  const [deleteEvent, { loading: deleteLoading }] =
    useDeleteZapierEventMutation();
  const [insertEvent, { loading: insertLoading }] =
    useInsertZapierEventMutation();

  const rows = isNil(data)
    ? []
    : data.getZapierEvents.map(({ name, fields, id }) => ({
        id,
        name,
        fields: pluck('label', fields).join(', '),
      }));

  const onFailure = (msg: string) => {
    addToast([formatToastMessage(msg, 'danger')]);
  };

  return (
    <OverviewTemplate
      data={rows}
      text={text}
      tipId="events-tip"
      loading={loading || insertLoading || deleteLoading}
      onNew={() => navigate('/-/apps/zapier/events/new')}
      onEdit={id => navigate(`/-/apps/zapier/events/${id}`)}
      onCopy={rowId => {
        const seed = data?.getZapierEvents.find(({ id }) => id === rowId);

        if (!seed) {
          return addToast([formatToastMessage(text.failedToCopy, 'danger')]);
        }

        return insertEvent({
          variables: {
            accountId,
            name: `Kopie ${seed.name}`,
            fields: map(omit(['__typename']), seed.fields),
          },
        }).then(({ data: update, errors }) => {
          if (isNil(update) || errors?.length) {
            return onFailure(text.failedToCopy);
          }

          return updateQuery(prev => ({
            ...prev,
            getZapierEvents: [
              ...(data?.getZapierEvents ?? []),
              update.insertZapierEvent,
            ],
          }));
        });
      }}
      onDelete={rowId =>
        deleteEvent({ variables: { accountId, id: rowId } }).then(
          ({ data: update, errors }) => {
            if (isNil(update) || errors?.length) {
              return onFailure(text.failedToCopy);
            }
            return updateQuery(prev => ({
              ...prev,
              getZapierEvents: prev.getZapierEvents.filter(
                ({ id }) => id !== rowId,
              ),
            }));
          },
        )
      }
    />
  );
};

export default Events;
