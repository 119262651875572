import React from 'react';
import {
  AppType,
  FlowTemplates,
  useGetFlowV2TemplatesQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import usePermissions from '~/hooks/usePermissions';

const GetFlowTemplatesForAppWrapper: React.FC<{
  appType: AppType;
  children: (flowTemplates: Array<FlowTemplates>) => JSX.Element;
}> = ({ appType, children }) => {
  const flowsPermission = usePermissions(['root.automation']);

  const { id: accountId } = useCurrentAccount();
  const { loading: flowTemplatesLoading, data: flowTemplatesData } =
    useGetFlowV2TemplatesQuery({
      variables: {
        accountId,
        appTypes: [appType],
      },
      skip: flowsPermission.allowed === false,
    });

  if (
    flowTemplatesLoading ||
    !flowTemplatesData?.getFlowV2Templates ||
    flowTemplatesData.getFlowV2Templates.length === 0
  )
    return <>{children([])}</>;

  return <>{children(flowTemplatesData.getFlowV2Templates)}</>;
};

export default GetFlowTemplatesForAppWrapper;
