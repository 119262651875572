import React from 'react';
import type { ClientFlowActionWait } from '~/graphql/types.client';
import { FlowAction, FlowV2_Update_Action } from '~/graphql/types';
import ConditionEditor, {
  text as conditionEditorText,
} from '../ConditionEditorV2';
import type { Props as FormProps } from '../ActionForm';
import RadioButton from '~/components/molecule/RadioButton';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { animated, useTransition } from 'react-spring';
import { useRecoilValue } from 'recoil';
import metadata from '~/components/page/Automation/v2/state/metadata';
import useBuilderContext from '../../../Builder/hooks/useBuilderContext';
import { text as metadataText } from '~/components/page/Automation/v2/components/Builder/components/nodeTypes/components/WaitForCard';
import TEST_ID from './index.testid';
import isMetadataRequired from './utils/isMetadataRequired';

export type Props = FormProps & {
  dataTestId?: string;
  action: ClientFlowActionWait;
};

const text = {
  ...conditionEditorText,
  ...metadataText,
  noop: 'Geen actie',
};

const WaitAction: React.FCC<Props> = ({
  action,
  outerContainer,
  opts,
  onChange,
}) => {
  const existingFlowMetadata = useRecoilValue(metadata).find(
    ({ flowBlueprintActionId }) => flowBlueprintActionId === action.id,
  );
  const { initialFlow } = useBuilderContext();

  const savedAction = initialFlow.actions.find(a => a.id === action.id);

  const converted: ClientFlowActionWait | null = savedAction
    ? (savedAction as ClientFlowActionWait)
    : null;

  const requiresMetadata = isMetadataRequired({
    before: converted?.condition,
    after: action.condition,
  });

  const selectedOption =
    action.metadataAction ??
    existingFlowMetadata?.action ??
    FlowV2_Update_Action.Noop;

  const transition = useTransition(requiresMetadata, {
    from: { opacity: 0, transform: 'translate3d(0,-30px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-30px,0)' },
  });

  return (
    <div>
      <Body margin={[null, null, 'm', null]}>
        Voeg één of meer triggers toe, waarop de flow wacht. Zodra deze aanwezig
        zijn, zal de flow verder gaan.
      </Body>

      <ConditionEditor
        actionId={action.id}
        action={FlowAction.Wait}
        hasTriggers
        conditionExpression={action.condition}
        opts={{
          ...opts,
          text: {
            newCondition: 'Conditie verfijnen',
            emptyCondition: 'Selecteer een conditie',
            newTrigger: 'Voeg een trigger toe',
            emptyTrigger: 'Stel de trigger in',
            addValue: '+ Waarde toevoegen',
          },
        }}
        outerContainer={outerContainer}
        onChange={nextConditionExpression => {
          const requiresMetadata = isMetadataRequired({
            before: converted?.condition,
            after: nextConditionExpression,
          });

          return onChange({
            ...action,
            condition: nextConditionExpression,
            metadataAction: requiresMetadata ? selectedOption : null,
          });
        }}
      />

      <>
        {transition((style, item) => {
          if (!item) return null;
          return (
            <Container style={style} data-testid={TEST_ID.METADATA_CONTAINER}>
              <JustificationContainer
                align="center"
                margin={[null, null, 'base', null]}
              >
                <Icon
                  name="exclamation"
                  margin={[null, 'base', 'xxxs', null]}
                  strokeWidth={3}
                />
                <Heading4 variant={Variant.primary} margin={[null]}>
                  {text.confirmModalHeader}
                </Heading4>
              </JustificationContainer>
              <JustificationContainer direction="column" gap="base">
                <Body>{text.confirmModalDescription}</Body>
                <RadioButton
                  checked={selectedOption === FlowV2_Update_Action.Noop}
                  onChange={() =>
                    onChange({
                      ...action,
                      metadataAction: FlowV2_Update_Action.Noop,
                    })
                  }
                  label={text.noop}
                  dataTestId={FlowV2_Update_Action.Noop}
                />
                <RadioButton
                  checked={selectedOption === FlowV2_Update_Action.Continue}
                  onChange={() =>
                    onChange({
                      ...action,
                      metadataAction: FlowV2_Update_Action.Continue,
                    })
                  }
                  label={text.continue}
                  dataTestId={FlowV2_Update_Action.Continue}
                />
                <RadioButton
                  checked={selectedOption === FlowV2_Update_Action.Drop}
                  onChange={() =>
                    onChange({
                      ...action,
                      metadataAction: FlowV2_Update_Action.Drop,
                    })
                  }
                  label={text.drop}
                  dataTestId={FlowV2_Update_Action.Drop}
                />
              </JustificationContainer>
            </Container>
          );
        })}
      </>
    </div>
  );
};

const Container = styled(animated.div)(
  ({ theme }) => css`
    background-color: ${theme.color('primary', 'translucent')};
    border-radius: ${theme.getTokens().border.radius.base};
    margin-top: ${theme.space('xxl')};
    padding: ${theme.space('m')};
    font-size: ${theme.fontSize('base')};
  `,
);

export default WaitAction;
