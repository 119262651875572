import { complement, isNil, isEmpty, allPass } from 'ramda';

/**
 * Returns true if the value is not null, undefined or empty. Otherwise returns false
 * @param {T} value
 * keywords: isNil, isEmpty
 */
const hasValue = <T>(value: T): value is NonNullable<T> => {
  const notNil = complement(isNil);
  const notEmpty = complement(isEmpty);
  const hasValueRecursive = (val: any): boolean => {
    if (Array.isArray(val)) {
      return val.some(hasValueRecursive);
    }

    if (typeof val === 'object' && val !== null) {
      return Object.values(val).some(hasValueRecursive);
    }

    return allPass([notNil, notEmpty])(val);
  };

  return hasValueRecursive(value);
};

export default hasValue;
