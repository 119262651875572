import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { WidgetDescriptionItem, WidgetItemGroup } from '~/graphql/types';
import SelectBlock from '~/components/molecule/SelectBlock';
import TextButton from '~/components/atom/TextButton';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import { SelectedGroups } from '../..';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { difference } from 'ramda';
import Button from '~/components/atom/Button';

const text = {
  saveSelectionButtonLabel: 'Opslaan en terug',
  selectAll: 'Alles selecteren',
  deselectAll: 'Alles deselecteren',
  appsSelectedLabel: 'onderdelen geactiveerd',
  oneAppSelectedLabel: 'onderdeel geactiveerd',
};

export type Props = {
  dataTestId?: string;
  groupToShow: WidgetItemGroup;
  selectedGroupsMap: SelectedGroups;
  setSelectedGroupsMap: React.Dispatch<React.SetStateAction<SelectedGroups>>;
  onGoBack: () => void;
};

const WidgetItemsScreen: React.FCC<Props> = ({
  onGoBack,
  groupToShow,
  selectedGroupsMap,
  setSelectedGroupsMap,
}) => {
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const appsDifference = difference(
    groupToShow.items ?? [],
    selectedGroupsMap[groupToShow.type],
  );
  // How many apps selected in this group
  const appsSelected = groupToShow.items.length - appsDifference.length;

  useEffect(() => {
    if (selectedGroupsMap[groupToShow.type]) {
      if (
        groupToShow.items.length === selectedGroupsMap[groupToShow.type].length
      ) {
        setAllSelected(true);
      } else {
        setAllSelected(false);
      }
    }
  }, [selectedGroupsMap, groupToShow.type, groupToShow.items.length]);

  const toggleSelection = () => {
    setSelectedGroupsMap((prevState: SelectedGroups) => ({
      ...prevState,
      [groupToShow.type]: allSelected ? [] : groupToShow.items,
    }));
    setAllSelected(allSelected);
  };
  return (
    <>
      <TopBarContainer
        width="100%"
        align="center"
        justification="space-between"
        direction="column"
      >
        <JustificationContainer
          width="100%"
          align="center"
          justification="end"
          border={{ radius: 'base' }}
          backgroundColor={{ group: 'white' }}
        >
          <TextButton
            icon={allSelected ? 'check-square' : 'square'}
            size="medium"
            onClick={toggleSelection}
            label={allSelected ? text.deselectAll : text.selectAll}
          />
        </JustificationContainer>
      </TopBarContainer>
      <Title
        margin={['l', null, 'xxs', null]}
        align="center"
        variant={Variant.primary}
      >
        {groupToShow.name}
      </Title>
      <GridContainer>
        {groupToShow.items.map(item => {
          const type = groupToShow.type;

          const isSelected = selectedGroupsMap[type].some(
            (selectedItem: WidgetDescriptionItem) =>
              selectedItem.id === item.id,
          );

          const currentlySelectedItems: Array<WidgetDescriptionItem> =
            selectedGroupsMap[type];

          return (
            <StyledSelectBlock
              onClick={() => {
                setSelectedGroupsMap((prevState: SelectedGroups) => ({
                  ...prevState,
                  [type]: isSelected
                    ? currentlySelectedItems.filter(
                        selectedItem => selectedItem.id !== item.id,
                      )
                    : [...currentlySelectedItems, item],
                }));
              }}
              key={item.id}
              checkboxTitle={item.name}
              type="checkbox"
              checked={isSelected}
              data-checked={isSelected}
              maxCardWidth="100%"
            />
          );
        })}
      </GridContainer>

      <JustificationContainer
        align="center"
        width="100%"
        justification={appsSelected > 0 ? 'space-between' : 'end'}
        margin={['l', null, null, null]}
        padding={['s']}
        backgroundColor={{ group: 'white' }}
        border={{ radius: 'base' }}
      >
        {appsSelected > 0 && (
          <Body margin={[null]}>
            {appsSelected}{' '}
            {appsSelected === 1
              ? text.oneAppSelectedLabel
              : text.appsSelectedLabel}
          </Body>
        )}
        <Button
          onClick={onGoBack}
          label={text.saveSelectionButtonLabel}
          appearance="secondary"
        />
      </JustificationContainer>
    </>
  );
};

const Title = styled(Heading4)(
  ({}) => css`
    width: 100%;
  `,
);

const StyledSelectBlock = styled(SelectBlock)`
  width: 100%;
  margin: 0;
`;

const TopBarContainer = styled(JustificationContainer)`
  position: relative;
`;

const GridContainer = styled.div<{}>(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: ${theme.space('base')};
    width: 100%;
  `,
);
export default WidgetItemsScreen;
