import React from 'react';
import Loading from '~/components/atom/Loading';
import { Body } from '~/components/atom/Typography';
import { WizardStepProps } from '~/components/organism/Wizard/context/WizardContext';
import { useGetAccountQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import UpdateSignatureContainer from './components/UpdateSignatureContainer';
import AppErrorScreen from '~/components/template/AppErrorScreen';

export const id = 'UpdateSignature';
export const title = 'Handtekening toevoegen';

export type OutputType = {
  type: typeof id;
};

const text = {
  body: 'Plaats hier je e-mailhandtekening. Deze vind je in bijvoorbeeld je CRM systeem en wordt automatisch aan iedere uitgaande e-mail toegevoegd.',
  queryError: 'Handtekening kan niet worden weergegeven. Probeer het opnieuw.',
};

export const Component: React.FCC<WizardStepProps> = ({ step }) => {
  const { id: accountId } = useCurrentAccount();
  const { data, loading, refetch } = useGetAccountQuery({
    variables: { accountId },
  });

  if (loading) return <Loading />;

  if (!data || !data.getAccount || !data.getAccount.Signature)
    return (
      <Wrapper>
        <AppErrorScreen
          message={text.queryError}
          setBackgroundColor={false}
          tryAgain={{
            buttonLabel: 'Probeer opnieuw',
            onClick: () => refetch(),
          }}
        />
      </Wrapper>
    );

  return (
    <Wrapper>
      <UpdateSignatureContainer
        signature={data.getAccount.Signature}
        step={step}
      />
    </Wrapper>
  );
};

const Wrapper: React.FCC = ({ children }) => (
  <>
    <Body>{text.body}</Body>
    {children}
  </>
);

export default {
  id,
  title,
};
