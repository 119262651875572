import React, { forwardRef } from 'react';
import { ElementType } from 'react';
import { AnimatedComponent } from 'react-spring';
import styled, { css } from 'styled-components';
import { getSpinAnimation } from '~/styles/animations';
import { SystemColorPalette, SystemSize } from '~/theme';
import arrayToCss from '~/util/arrayToCss';
import icons from './components';

export type IconType = keyof typeof icons;

export type Props = React.HTMLAttributes<HTMLSpanElement> & {
  dataTestId?: string;
  name: IconType;
  color?: string;

  className?: string;

  /** Rotates the icon clockwise by a specific degree */
  clockwise?: number;

  /** Rotates the icon 180 degrees clockwise */
  flipX?: boolean;

  /** Gives the spin animation */
  spin?: boolean;

  inline?: boolean;

  strokeWidth?: number | string;

  /** Adds a circle background with the given color */
  background?: keyof SystemColorPalette;

  /** To use it as an animated component */
  as?: AnimatedComponent<ElementType>;

  margin?: Array<SystemSize | null>;

  /** Makes the icon skewed */
  skewed?: boolean;
  size?: SystemSize;
};

const Icon = forwardRef<HTMLSpanElement, Props>(
  (
    {
      name,
      color,
      strokeWidth,
      margin = [null, null, null, null],
      dataTestId,
      skewed,
      clockwise,
      spin,
      flipX,
      size,
      ...props
    },
    ref,
  ) => {
    const PickedIcon = icons[name];

    if (!PickedIcon) return null;

    return (
      <Container
        {...props}
        ref={ref}
        data-testid={dataTestId}
        $spin={name === 'spinner' || spin}
        $margin={margin}
        $strokeWidth={strokeWidth || 1.5}
        $skewed={skewed}
        $clockwise={clockwise}
        $flipX={flipX}
        $size={size}
      >
        <PickedIcon color={color || 'currentColor'} />
      </Container>
    );
  },
);

type ContainerProps = {
  $strokeWidth: Props['strokeWidth'];
  $margin: Array<SystemSize | null>;
  $skewed: Props['skewed'];
  $clockwise: Props['clockwise'];
  $spin: Props['spin'];
  $flipX: Props['flipX'];
  $size: Props['size'];
};
const Container = styled.span<Props & ContainerProps>(
  ({
    theme,
    $margin,
    $strokeWidth,
    $skewed,
    $size,
    background,
    $clockwise,
    $spin,
    $flipX,
  }) => css`
    display: inline-flex;
    align-items: center;
    margin: ${arrayToCss($margin, theme)};

    ${$size &&
    css`
      font-size: ${theme.fontSize($size)};
    `}

    /** This makes all chevrons transition */
    & > svg {
      height: 1em;
      width: 1em;
      transition: transform 0.3s ease-in-out;
    }

    & > * {
      /** Fix for safari. Children do not inherit the stroke-width from the parent so we pass it to them. */
      stroke-width: ${$strokeWidth};
    }

    ${$skewed &&
    css`
      transform: skewY(-5deg);
    `}

    ${($clockwise || $flipX) &&
    css`
      svg {
        transform: rotate(${$flipX ? 180 : $clockwise}deg);
      }
    `}

    ${$spin &&
    css`
      ${getSpinAnimation()};
    `}

    ${background &&
    css`
      margin: ${theme.space('xxxs')} 0 0 5px;
      position: relative;

      & > svg {
        z-index: 1;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        width: 1.3em;
        height: 1.3em;

        background-color: ${theme.color(background)};
        border-radius: ${theme.getTokens().border.radius.full};
      }
    `}
  `,
);

export default Icon;
