import { isNil } from 'ramda';
import { Option } from '../../';

/**
 * returns a type of the selected option
 * @param {Array<Option>} options
 * @param {number | null | undefined} selectedOptionIdx
 */

const getOptionType = (
  options: Array<Option>,
  selectedOptionIdx: number | null | undefined,
): Option['type'] => {
  const option =
    options.length && !isNil(selectedOptionIdx)
      ? options[selectedOptionIdx]
      : undefined;

  const optionType = option && 'type' in option ? option.type : undefined;
  return optionType;
};

export default getOptionType;
