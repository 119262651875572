import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  ENTITY_ACTIVITY_OVERVIEW: `${cleanedFilename(
    __filename,
  )}-entity-activity-overview`,
  EMAIL_EFFECTIVENESS: `${cleanedFilename(__filename)}-email-effectiveness`,
  CONTACT_COUNT_BY_LEADSCORE: `${cleanedFilename(
    __filename,
  )}-email-effectiveness`,
  OVERVIEW: `${cleanedFilename(__filename)}-overview`,
  CHANGE_IN_LEADSCORE: `${cleanedFilename(__filename)}-change-in-leadscore`,
  FLOW_EFFECTIVENESS: `${cleanedFilename(__filename)}-flow-effectiveness`,
  REVENUE_PER_SOURCE_GROUP: `${cleanedFilename(
    __filename,
  )}-revenue-per-source-group`,
};
