import { slice, take } from 'ramda';
import {
  FlowV2_Action_Zapier_TriggerFragment,
  ZapierField,
  ZapierTriggerFieldsFragment,
} from '~/graphql/types';

/**
 * returns an array of fields that we show in the card and a number of fields that we collapse
 * @param {ZapierTriggerFieldsFragment['fields'] | undefined} fields
 * @param {FlowV2_Action_Zapier_TriggerFragment['mappings']} mappings
 * keywords: zapierTrigger, zapierTriggerCard, zapier, card, formatted, formattedFields
 */

export type FormattedFieldsProps = {
  mappings: FlowV2_Action_Zapier_TriggerFragment['mappings'];
  fields: ZapierTriggerFieldsFragment['fields'] | undefined;
};

type VisibleAndCollapsedFields = {
  visibleFields: Array<ZapierField>;
  collapsedFields: Array<ZapierField>;
};

const NUMBER_OF_VISIBLE_FIELDS = 2;
const getVisibleAndCollapsedFields = ({
  mappings,
  fields,
}: FormattedFieldsProps): VisibleAndCollapsedFields => {
  const mappingIds = mappings.map(mapping => mapping.mappingId);
  const filteredFields =
    fields?.filter(field => mappingIds.includes(field.key)) ?? [];

  const visibleFields = take(NUMBER_OF_VISIBLE_FIELDS, filteredFields);
  const collapsedFields = slice(
    NUMBER_OF_VISIBLE_FIELDS,
    filteredFields.length,
    filteredFields,
  );

  return {
    visibleFields,
    collapsedFields,
  };
};

export default getVisibleAndCollapsedFields;
