import React from 'react';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import { IconType } from '~/components/atom/Icon';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import BaseButton from '../components/Base';
import { tooltipText } from '../text';
import {
  isBlockActive,
  toggleBlock,
} from '~/components/organism/PluginsEditor/commands/modify/block';
import { toggleList } from '~/components/organism/PluginsEditor/commands/modify/list';

export type Props = {
  editor: DHEditor;
  format: ELEMENTS.UL | ELEMENTS.OL | ELEMENTS.BLOCKQUOTE;
  icon: IconType;
};

const BlockButton: React.FCC<Props> = ({
  dataTestId,
  editor,
  format,
  icon,
}) => (
  <BaseButton
    dataTestId={dataTestId}
    icon={icon}
    onClick={e => {
      e.preventDefault();

      if (format === ELEMENTS.UL || format === ELEMENTS.OL)
        return toggleList(editor, format);

      toggleBlock({ editor, format });
    }}
    active={isBlockActive(editor, format)}
    tooltipMessage={tooltipText[format]}
  />
);

export default BlockButton;
