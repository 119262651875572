import * as React from 'react';

import PromoSection from './PromoSection';
import styled, { css } from 'styled-components';
import useIntercom from '~/hooks/useIntercom';

type Props = {
  children: React.ReactNode;
  bypassURLCheck?: boolean;
};
const AuthWrapperHOC: React.FCC<Props> = ({
  children,
  bypassURLCheck = false,
}) => {
  const intercom = useIntercom();
  intercom.boot();

  if (
    bypassURLCheck === false &&
    (window.location.pathname.startsWith('/-/') ||
      window.location.pathname.startsWith('/-'))
  ) {
    return <>{children}</>;
  }

  return (
    <AuthWrapper>
      <PromoSection />
      <FormSection>{children}</FormSection>
    </AuthWrapper>
  );
};

const AuthWrapper = styled.div<{}>`
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.color('white')};
  `};
`;

const FormSection = styled.main<{}>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;

    flex: 1 0 50%;
    transition: width 0.1s linear;
    padding: ${theme.space('m')};
    background: ${theme.gradient('page')};
    color: ${theme.color('white')};

    a,
    a:hover {
      color: ${theme.color('white')};
    }
  `,
);

export default AuthWrapperHOC;
