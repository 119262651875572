import React from 'react';

import {
  BillingCycle,
  GetBillingDetailsQuery,
  GetPlansQuery,
  PlanOptionFragment,
} from '~/graphql/types';

import Catalog from '~/Catalog';
import Loading from '~/components/atom/Loading';
import { Coupon } from '~/components/page/Settings/Subscription/utils/convertToCoupon';
import { isNil } from 'ramda';
import { ApolloError } from '@apollo/client';
import CancelSubscriptionStep from '../../steps/CancelSubscriptionStep';
import ChoosePlanStep from '../../steps/ChoosePlanStep';
import PlanOverviewStep from '../../steps/PlanOverviewStep';
import SetupPayment from '../../steps/SetupPayment';
import type { StepId } from '../..';

const text = {
  insertionErrorMsg: Catalog.genericUnknownErrorMessageShort,
  header: 'Kies je abonnement',
};

type Props = {
  loading: boolean;
  currentStep: StepId;
  data?: GetPlansQuery;
  billingDetailsData?: GetBillingDetailsQuery;
  setUpdateData: React.Dispatch<
    React.SetStateAction<{
      plan: null | PlanOptionFragment;
      billingCycle: BillingCycle;
      coupon: null | Coupon;
    }>
  >;
  setBillingInfo: React.Dispatch<
    React.SetStateAction<{
      name: string | null;
      email: string | null;
    }>
  >;
  setCurrentStep: React.Dispatch<React.SetStateAction<StepId>>;
  onConfirm: ({
    planId,
    paymentId,
    billingCycle,
    coupon,
    shouldUseUpdate,
  }: {
    planId: string;
    paymentId?: string | undefined;
    billingCycle: BillingCycle;
    coupon: null | Coupon;
    shouldUseUpdate?: boolean | undefined;
  }) => Promise<void>;
  onComplete: () => void;
  onClose?: () => void;
  couponCode: string | null | undefined;
  billingInfo: {
    name: string | null;
    email: string | null;
  };
  loadingMutation: boolean;
  mutationError: ApolloError | undefined;
  updateData: {
    plan: null | PlanOptionFragment;
    billingCycle: BillingCycle;
    coupon: null | Coupon;
  };
};

const StepComponent: React.FC<Props> = ({
  loading,
  currentStep,
  data,
  setUpdateData,
  updateData,
  billingDetailsData,
  setBillingInfo,
  billingInfo,
  setCurrentStep,
  onConfirm,
  onClose,
  onComplete,
  couponCode,
  mutationError,
  loadingMutation,
}) => {
  if (!data || loading) return <Loading />;

  switch (currentStep) {
    case 'choosePlan':
      return (
        <ChoosePlanStep
          header={text.header}
          currentPlan={data.getPlans.current}
          planOptions={data.getPlans.available}
          caveats={data.getPlans.caveats}
          onContinue={(plan, billingCycle) => {
            setUpdateData(prev => ({ ...prev, billingCycle, plan }));
            setCurrentStep('planOverview');
          }}
          onClose={onClose}
          onGoToUnsubscribe={
            !isNil(data.getPlans.current)
              ? () => {
                  setCurrentStep('cancelSubscription');
                }
              : null
          }
        />
      );

    case 'planOverview':
      return (
        <PlanOverviewStep
          selectedPlan={updateData.plan as PlanOptionFragment}
          initialSelectedBillingCycle={updateData.billingCycle}
          initialSelectedCoupon={updateData.coupon}
          initialCouponCode={couponCode}
          onContinue={(billingCycle, coupon) => {
            const currentPlan = updateData.plan;

            setUpdateData(prev => ({
              ...prev,
              coupon: coupon,
              billingCycle,
            }));

            if (
              updateData.plan?.requiresBilling === true &&
              isNil(billingDetailsData?.getBillingDetails)
            ) {
              return setCurrentStep('setupPayment');
            }

            if (currentPlan) {
              return onConfirm({
                planId: currentPlan.id,
                billingCycle,
                coupon,
                shouldUseUpdate: !isNil(data.getPlans.current),
              });
            }
          }}
          onGoBack={(billingCycle, coupon) => {
            setUpdateData(prev => ({
              ...prev,
              coupon,
              billingCycle,
            }));

            setCurrentStep('choosePlan');
          }}
          loading={loadingMutation}
        />
      );
    case 'setupPayment': {
      const price = updateData.plan?.price[updateData.billingCycle] ?? 0;
      const totalPrice =
        updateData.billingCycle === BillingCycle.Monthly ? price : price * 12;

      return (
        <SetupPayment
          onContinue={(paymentId: string) => {
            if (updateData.plan) {
              return onConfirm({
                planId: updateData.plan.id,
                billingCycle: updateData.billingCycle,
                coupon: updateData.coupon,
                paymentId,
                shouldUseUpdate: !isNil(data.getPlans.current),
              });
            }

            return;
          }}
          onGoBack={billingInfo => {
            setBillingInfo(billingInfo);
            setCurrentStep('planOverview');
          }}
          totalPriceToPay={
            totalPrice -
            (updateData.coupon != null
              ? updateData.coupon.calculateOff(totalPrice)
              : 0)
          }
          insertionLoading={loadingMutation}
          insertionError={mutationError == null ? null : text.insertionErrorMsg}
          initialEmail={billingInfo.email}
          initialName={billingInfo.name}
        />
      );
    }

    case 'cancelSubscription':
      return (
        <CancelSubscriptionStep
          onGoBack={() => setCurrentStep('choosePlan')}
          onSubscriptionCancelled={() => {
            onComplete();
          }}
        />
      );
  }
};

export default StepComponent;
