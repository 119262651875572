/**
 * format iban to have spaces and upper case letters
 * @param {string | undefined} value
 * keywords: iban, format
 */

// 18 characters of the Dutch iban + 4 spaces
const IBAN_LENGTH_WITH_SPACES = 22;

export const formatIBAN = (
  value: string | null | undefined,
): string | undefined =>
  value
    ? value
        .toUpperCase()
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim()
        .substring(0, IBAN_LENGTH_WITH_SPACES)
    : undefined;

export default formatIBAN;
