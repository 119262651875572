import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/atom/Button';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import FormContainer from '~/components/organism/ModalsV2/FormContainer';
import { withOverlay } from '~/components/organism/ModalsV2/Overlay';
import TextButton from '~/components/atom/TextButton';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import { isNonEmptyString } from '~/util/Validation/String';
import TEST_ID from './index.testid';
import HelpBlock from '../HelpBlock';
import useContaqtSignUpLink from '../../hooks/useContaqtSignUpLink';

export type Props = {
  loading?: boolean;
  dataTestId?: string;
  onAddToken: (updatedToken: string) => void;
  onClose: () => void;
};

const text = {
  tokenLabel: 'Contaqt API token invoeren',
  tokenPlaceholder: '12Ab345C78dE9FG',
  formHeader: 'Contaqt koppeling toevoegen',
  emptyFieldError: 'Veld mag niet leeg zijn',
  cancel: 'Afbreken',
  save: 'Opslaan',
};

const AddTokenModal: React.FCC<Props> = ({
  dataTestId,
  onClose,
  onAddToken,
  loading,
  ...rest
}) => {
  const [tokenId, setTokenId] = useState<string>('');
  const signUpLink = useContaqtSignUpLink();

  return (
    <FormContainer header={text.formHeader} onClose={onClose}>
      <Form data-testid={dataTestId} {...rest}>
        <JustificationContainer
          direction="column"
          justification="space-between"
          align="space-between"
          width="100%"
          gap="l"
        >
          <TipBanner id="contaqt-add-token" headerText="Hulp nodig?">
            <HelpBlock signUpLink={signUpLink} />
            <br />
            <br />
            Kom je er niet uit? <ChatLink linkText="Start een chat met ons." />
          </TipBanner>
          <StyledInput
            dataTestId={TEST_ID.ADD_TOKEN_INPUT}
            label={{ text: text.tokenLabel }}
            placeholder={text.tokenPlaceholder}
            value={tokenId}
            onChange={e => setTokenId(e.target.value)}
            validation={[
              (value: string) =>
                isNonEmptyString(value) ? true : text.emptyFieldError,
            ]}
            name="token"
            width="100%"
          />

          <JustificationContainer
            justification="space-between"
            align="center"
            margin={['l', null, null]}
            width="100%"
          >
            <TextButton
              label={text.cancel}
              onClick={onClose}
              padding={[null]}
              appearance="danger"
              type="button"
              dataTestId={TEST_ID.CANCEL}
            />

            <Button
              icon="check"
              label={text.save}
              type="submit"
              appearance="secondary"
              loading={loading}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                onAddToken(tokenId);
              }}
              disabled={isEmpty(tokenId)}
              dataTestId={TEST_ID.SAVE}
            />
          </JustificationContainer>
        </JustificationContainer>
      </Form>
    </FormContainer>
  );
};

const StyledInput = styled(Input)<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `,
);

const Form = styled.form<{}>``;

export default withOverlay(AddTokenModal);
