import React, { useRef } from 'react';
import { ConnectorOperator } from '~/graphql/types';
import { assertNever } from '~/util/assertion';
import styled, { css } from 'styled-components';
import Dropdown from '~/components/molecule/Dropdown';

type LabelOverride = {
  and?: string;
  or?: string;
};

type Props = {
  /** Current operator */
  connector: ConnectorOperator;
  /** On change */
  onChange: (nextValue: ConnectorOperator) => void;
  /** Renders this component as editable - will be ignored if opts.active is false */
  editable: boolean;
  /** Allows overriding of the default labels */
  label?: LabelOverride;
};

const ConnectorComponent: React.FC<Props> = ({
  connector,
  editable,
  onChange,
  label,
}) => {
  const operatorOptions = useRef(
    Object.values(ConnectorOperator).map(operator => ({
      label: getConnectorOperatorLabel(operator, label),
      payload: operator,
      key: operator,
    })),
  );

  return (
    <StyledDropdown
      $editable={editable}
      options={operatorOptions.current}
      selectedOptionIdx={operatorOptions.current.findIndex(
        ({ payload }) => payload === connector,
      )}
      onChange={({ option: { payload } }) => {
        onChange(payload);
        return payload;
      }}
      size="small"
      width="max-content"
    />
  );
};

const StyledDropdown = styled(Dropdown)<{ $editable: boolean }>(
  ({ $editable }) => css`
    transition: border 100ms ease-out;

    ${!$editable &&
    css`
      border: 1px solid transparent;
    `}
  `,
);

export const getConnectorOperatorLabel = (
  operator: ConnectorOperator /** Allows overriding of the default labels */,
  label?: LabelOverride,
): string => {
  const { and, or } = {
    and: 'en',
    or: 'of',
    ...label,
  };

  switch (operator) {
    case ConnectorOperator.And:
      return and;
    case ConnectorOperator.Or:
      return or;
    default:
      return assertNever(operator);
  }
};

export default ConnectorComponent;
