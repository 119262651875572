import React from 'react';
import styled from 'styled-components';
import { StrapiComponent } from '~/components/template/ConversionPage/types';

import TipBanner from '../TipBanner';
import UpgradePlanCta from '../UpgradePlanCTA';
import getMediaURLPrefix from './utils/getMediaURLPrefix';
import JustificationContainer from '~/components/atom/JustificationContainer';
import ChatLink from '../TipBanner/ChatLink';

import VideoPlayer from './components/VideoPlayer';
import TwoColumn from './components/TwoColumn';
import TwoColumnVideo from './components/TwoColumnVideo';
import getMarkdownOptions from './utils/getMarkdownOptions';
import Divider from '~/components/atom/Divider';
import Streamer from './components/Streamer';
import SumUp from './components/SumUp';
import CopyCode from '~/components/atom/CopyCode';
import Markdown from '~/components/molecule/Markdown';
import Screenshot from '~/components/molecule/Screenshot';

export type Props = {
  components: Array<StrapiComponent>;
};

const StrapiDynamicZone: React.FCC<Props> = ({
  dataTestId,
  components,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    {components.map(attrs => (
      <JustificationContainer
        key={attrs.__typename + attrs.id}
        margin={[null, null, 'xxxl', null]}
      >
        <div style={{ width: '100%' }}>
          <DynamicZoneComponent {...attrs} />
        </div>
      </JustificationContainer>
    ))}
  </Container>
);

const DynamicZoneComponent: React.FC<StrapiComponent> = componentProps => {
  switch (componentProps.__typename) {
    case 'ComponentUiCodeBlock':
      return <CopyCode code={componentProps.code} />;
    case 'ComponentUiScreenshot':
      return (
        <Screenshot
          src={`${getMediaURLPrefix()}${
            componentProps.image.data.attributes.url
          }`}
          alt={componentProps.alt}
          withoutShadow={!componentProps.withShadow}
        />
      );
    case 'ComponentUiTipBanner':
      return (
        <TipBanner
          id={componentProps.__typename + componentProps.id}
          headerText={componentProps.header}
          dismissible={false}
        >
          <Markdown
            value={componentProps.body}
            options={getMarkdownOptions()}
          />
          <ChatLink linkText="Start een chat met ons." />
        </TipBanner>
      );

    case 'ComponentUiUpgradePlanCta':
      return (
        <UpgradePlanCta
          description={componentProps.description}
          header={componentProps.header}
          icon={componentProps.icon.name}
          buttonIcon={componentProps.buttonIcon.name}
          buttonLabel={componentProps.buttonLabel}
        />
      );

    case 'ComponentUiTwoColumn':
      return <TwoColumn {...componentProps} />;

    case 'ComponentUiTwoColumnVideo':
      return <TwoColumnVideo {...componentProps} />;

    case 'ComponentUiVideoStreamer':
      return (
        <VideoPlayer
          autoPlay={componentProps.autoPlay}
          controls={componentProps.controls}
          loop={componentProps.loop}
          muted={componentProps.muted}
          src={`https://datcms.dathuis.nl${componentProps.video.data.attributes.url}`}
          mime={componentProps.video.data.attributes.mime}
        />
      );

    case 'ComponentUiDivider':
      return <Divider margin={[componentProps.margin, null]} />;

    case 'ComponentUiStreamer':
      return <Streamer {...componentProps} />;

    case 'ComponentUiSumup':
      return <SumUp {...componentProps} />;

    default:
      return (
        <>
          <h1>TODO Implement</h1>;
          <CopyCode code={JSON.stringify(componentProps, null, 2)} />
        </>
      );
  }
};

const Container = styled.div<{}>``;

export default StrapiDynamicZone;
