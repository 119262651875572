import React from 'react';
import styled, { css } from 'styled-components';
import {
  InteractiveAppearance,
  Size,
  interactiveAppearances,
  componentSizes,
} from '~/styles/constants';
import arrayToCss from '~/util/arrayToCss';
import JustificationContainer from '../../atom/JustificationContainer';
import TEST_ID from './index.testid';
import InputLabel from '~/components/atom/InputLabel';

export type Option = {
  /** Value to be returned when selected */
  value: any;

  /** Label for this option */
  label: string;

  /** Optional data-testid variable, for testing purposes */
  dataTestId?: string;
};

export type SelectedOption = {
  option?: Option;
  selectedOptionIdx?: number;
};

export type OnChangeFunction = (option: SelectedOption) => void;

type Props = {
  label?: string;

  appearance?: InteractiveAppearance;

  /**
   * The options to select, { value: any, label: string },
   * color changes the selected color fo this individual selection
   */
  options: Array<Option>;

  /** Initially selected option */
  selectedIndex: number;

  /** Callback, fired after an option was selected. */
  onChange: OnChangeFunction;

  size?: Size;

  /** Styled components classname */
  className?: string;
  helpLink?: React.ReactNode;

  disabled?: boolean;
};

const SelectGroup: React.FCC<Props> = ({
  options,
  selectedIndex,
  size = 'medium',
  appearance = 'secondary',
  onChange,
  dataTestId,
  label,
  className,
  disabled,
  helpLink = null,
  ...rest
}) => {
  const onSelect = (option: Option, index: number) => {
    onChange({ option, selectedOptionIdx: index });
  };

  const selectOptions = options.map((option, index) => {
    const isSelected: boolean = selectedIndex === index;
    return (
      <SelectGroupEntryLabel
        data-testid={option.dataTestId}
        key={option.label}
        data-selected={isSelected.toString()}
        isSelected={isSelected}
        onClick={() => onSelect(option, index)}
        $size={size}
        $appearance={appearance}
        $disabled={disabled}
        {...rest}
      >
        {option.label}
      </SelectGroupEntryLabel>
    );
  });

  return (
    <JustificationContainer direction="column">
      {label && (
        <InputLabel
          size={size}
          label={
            <>
              {label}
              {helpLink}
            </>
          }
        />
      )}
      <SelectGroupContainer
        data-testid={dataTestId || TEST_ID.SELECT_GROUP_CONRAINER}
        className={className}
      >
        {selectOptions}
      </SelectGroupContainer>
    </JustificationContainer>
  );
};

const SelectGroupContainer = styled.div<{}>`
  display: inline-block;
  white-space: nowrap;
  box-shadow: ${({ theme }) => theme.getTokens().boxShadow.base};
  border-radius: ${({ theme }) => theme.getTokens().border.radius.s};

  > :nth-child(1) {
    border-top-left-radius: ${({ theme }) => theme.getTokens().border.radius.s};
    border-bottom-left-radius: ${({ theme }) =>
      theme.getTokens().border.radius.s};
    border-right: 0;
  }

  > :last-child {
    border-top-right-radius: ${({ theme }) =>
      theme.getTokens().border.radius.s};
    border-bottom-right-radius: ${({ theme }) =>
      theme.getTokens().border.radius.s};
  }
`;

type SelectGroupEntryLabelProps = {
  isSelected: boolean;
  $disabled?: boolean;
  $appearance: InteractiveAppearance;
  $size: Size;
};

const SelectGroupEntryLabel = styled.label<SelectGroupEntryLabelProps>(
  ({ theme, isSelected, $appearance, $disabled, $size }) => {
    const { group, variant } = interactiveAppearances[$appearance];
    return css`
      display: inline-block;
      cursor: pointer;
      transition: all 0.2s ease-out;

      padding: ${arrayToCss(componentSizes[$size].padding, theme)};
      background-color: ${isSelected
        ? theme.color(group, variant)
        : theme.color('white')};
      color: ${isSelected ? theme.color('white') : theme.color('text')};

      ${!isSelected &&
      css`
        &:hover {
          background-color: ${theme.color(group, variant)};
          opacity: 0.8;
          color: ${theme.color('white')};
        }
      `}

      ${$disabled &&
      css`
        background: ${theme.color('white', 'dark')};
        color: ${theme.color('tertiary', 'dark')};
        pointer-events: none;
      `}
    `;
  },
);

export default SelectGroup;
