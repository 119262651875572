import { dissocPath } from 'ramda';
import type { BrandSettings } from '../../state';
import findDifference from '~/util/findDifference';

/**
 * Returns the changed fields for brand settings
 * @param {BrandSettings} initial
 * @param {BrandSettings} updated
 */
const getDifferenceCount = ({
  initial,
  updated,
}: {
  initial: BrandSettings;
  updated: BrandSettings;
}): { changedPaths: Array<Array<string>>; differenceCount: number } =>
  // We track the logo changes with logo.s3key so we disregard logo.url
  findDifference(
    dissocPath(['logo', 'url'], initial),
    dissocPath(['logo', 'url'], updated),
  );

export default getDifferenceCount;
