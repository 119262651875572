import getIbanValidationOutput from '~/components/molecule/IBANInput/utils/getIbanValidationOutput';
import forceBEFormat from '~/components/molecule/IBANInput/utils/forceBEFormat';
import { ibanErrors } from '~/components/molecule/IBANInput';
import Catalog from '~/Catalog';
import Validation from '~/util/Validation';

const text = {
  noEmail: Catalog.noEmail,
  invalidEmail: Catalog.invalidEmail,
  invalidAccountName: 'Het veld kan niet leeg zijn',
};

type FormData = {
  email: string | null;
  accountName: string | null;
  iban: string | null;
};

type Errors = {
  email: string | undefined;
  accountName: string | undefined;
  iban: string | undefined;
};

/**
 * Validates iban input field in a form
 * @param {string | null} email - email
 * @param {string | null} accountName - account name
 * @param {string | null} iban - Iban
 * keywords: validateIban, ibanValidation
 */
const validateWalletFields = ({
  email,
  accountName,
  iban,
}: FormData): Errors => {
  const errors: Errors = {
    email: undefined,
    accountName: undefined,
    iban: undefined,
  };

  if (Validation.String.isEmpty(email)) {
    errors.email = text.noEmail;
  }
  if (!Validation.Email.isValid(email)) {
    errors.email = text.invalidEmail;
  }
  if (!Validation.String.isNonEmptyString(accountName)) {
    errors.accountName = text.invalidAccountName;
  }

  const validationOutput = getIbanValidationOutput(iban);

  // When IBAN includes `*` the user hasn't touched it
  if (validationOutput.error !== null && !iban?.includes('*')) {
    errors.iban = ibanErrors[validationOutput.error];
  }

  if (iban && forceBEFormat(iban).length < 18) {
    errors.iban = ibanErrors.length;
  }

  if (!iban) {
    errors.iban = ibanErrors.empty;
  }

  return errors;
};

export default validateWalletFields;
