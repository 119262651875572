import React from 'react';
import styled from 'styled-components';
import HeadingContainer, {
  Props as HeadingProps,
} from '~/components/organism/HeadingContainer';
import { DateRangeInput, SortFieldAdvanced } from '~/graphql/types';

import TEST_ID from './index.testid';
import Dropdown, { OptionOf } from '~/components/molecule/Dropdown';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  selectedOptionIndex: number;
  options: Array<OptionOf<DateRangeInput | SortFieldAdvanced | number | null>>;
  onOptionChange: ({
    selectedOptionIdx,
  }: {
    selectedOptionIdx: number;
  }) => void;
} & HeadingProps;

const CardHeader: React.FCC<Props> = ({
  dataTestId,
  selectedOptionIndex,
  options,
  onOptionChange,
  ...rest
}) => (
  <JustificationContainer
    data-testid={`${dataTestId}-${TEST_ID.CONTAINER}`}
    {...rest}
    justification="space-between"
    margin={[null, null, 'l', null]}
  >
    <div>
      <HeadingContainer {...rest} />
    </div>
    <DropdownRestriction>
      <Dropdown
        dataTestId={`${dataTestId}-${TEST_ID.DROPDOWN}`}
        options={options}
        selectedOptionIdx={selectedOptionIndex}
        onChange={onOptionChange}
      />
    </DropdownRestriction>
  </JustificationContainer>
);

const DropdownRestriction = styled.div<{}>`
  flex: 0 1 50%;
`;

export default CardHeader;
