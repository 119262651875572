import type { FlowV2_Action__Input } from '~/graphql/types';
import type { ClientFlowAction } from '~/graphql/types.client';
import type { IssueMap } from '../../state/flowIssues';
import { isNil } from 'ramda';
import serializeAction from '../../components/Builder/utils/serializeAction';
import getIssuesForAction, {
  IssueOptions,
} from '../../components/UpdateAction/utils/getIssuesForAction';
import getRelativeMaps, {
  RelativeMaps,
} from '../../components/Builder/utils/getRelativeMaps';

/**
 * Takes an array of ClientFlowAction, serializes them and filters from null values.
 * Returns an array of serializedActions and the issueMap collected from all the actions.
 * @param {Array<ClientFlowAction>}: actions
 * keywords: issueMap, serializedActions, actions, serialized
 */
type ReturnType =
  | {
      serializedActions?: undefined;
      issueMap: IssueMap;
    }
  | {
      serializedActions: Array<FlowV2_Action__Input>;
      issueMap?: undefined;
    };
const getSerializedActions = (
  actions: Array<ClientFlowAction>,
  relativeMaps: RelativeMaps,
  opts?: IssueOptions,
): ReturnType => {
  const issueMap: IssueMap = {};

  const serializedActions: Array<FlowV2_Action__Input> = actions
    .map(action => {
      const issues = getIssuesForAction({
        action,
        opts,
        relativeMaps: getRelativeMaps({
          action,
          actions,
          maps: relativeMaps,
        }),
      });

      if (issues.length !== 0) {
        issueMap[action.id] = issues;
        return;
      }

      return serializeAction(action);
    })
    .filter((action): action is FlowV2_Action__Input => !isNil(action));

  if (Object.keys(issueMap).length !== 0) return { issueMap };

  return { serializedActions };
};

export default getSerializedActions;
