/**
 * A hook to count down from a given action time. It will give the user:
 * [0] - remaining seconds as a string
 *
 * It expects:
 *   - last action time. Should be in convertDateToServerDateString(new Date()) format
 *   - seconds to count from
 */
import { useState, useEffect } from 'react';
import { differenceInSeconds } from 'date-fns';

type ReturnProps = [number | null];

const useCountdown = (
  lastActionTime: string | null | undefined,
  seconds: number,
): ReturnProps => {
  const [remainingSeconds, setRemainingSeconds] = useState<number | null>(
    getTimeLeft(lastActionTime, seconds),
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const counterTime = getTimeLeft(lastActionTime, seconds);
      setRemainingSeconds(counterTime);

      if (counterTime != null && counterTime < 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      if (timer != null) {
        clearInterval(timer);
      }
    };
  }, [lastActionTime, seconds]);

  return [remainingSeconds];
};

export const getTimeLeft = (
  lastActionTime: string | null | undefined,
  seconds: number,
): number | null => {
  if (lastActionTime == null) return null;
  const currentTime = new Date();
  const invitationTime = new Date(lastActionTime);
  const timeDifference = differenceInSeconds(currentTime, invitationTime);

  if (seconds - timeDifference <= 0) return null;
  return seconds - timeDifference;
};

export default useCountdown;
