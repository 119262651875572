/**
 *
 * Provides our client side phone validation
 */

import StringValidation from './String';

/**
 * Uses same regex as backend!
 * Make sure it consistent if going to change it
 */
const PHONE_REGEX = /(\d.*){9}/;

export const isValid = (phone: string | null | undefined) => {
  if (phone === null || !StringValidation.isString(phone)) return false;

  return PHONE_REGEX.test(phone);
};

export default {
  isValid,
};
