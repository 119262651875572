import React from 'react';
import { isEmpty } from '~/util/Validation/String';
import { isNil } from 'ramda';
import InputLabel from '~/components/atom/InputLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';
import styled, { css } from 'styled-components';

type Ref = HTMLTextAreaElement;

type Props = {
  name: string;
  value: string | null;
  onChange: (value: string | null, name: string) => void;
  /** Error, this replaces the label if its set. */
  error?: string | null | undefined;

  /** Disable the input */
  disabled?: boolean;

  /** If the border should be shown or not */
  borderless?: boolean;

  /** testId for test */

  /** Makes the text area uneditable */
  uneditable?: boolean;

  /** Makes the text area resizable */
  resizable?: boolean;
  label?: React.ReactNode;

  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  required?: boolean;
};
const Textarea = React.forwardRef<Ref, Props>(
  (
    {
      disabled,
      error,
      value,
      dataTestId,
      borderless,
      uneditable,
      label,
      onChange,
      name,
      autoFocus = false,
      resizable = false,
      required,
      ...rest
    },
    ref,
  ) => (
    <JustificationContainer width="100%" direction="column">
      {!isNil(label) && !isEmpty(label) && (
        <InputLabel
          required={required}
          error={error}
          label={label}
          size="medium"
        />
      )}
      <TextareaElement
        ref={ref}
        disabled={disabled}
        data-testid={dataTestId || 'test-textarea'}
        value={value || ''}
        $error={error}
        data-error={error}
        rows={4}
        $borderless={borderless}
        $uneditable={uneditable}
        $resizable={resizable}
        autoFocus={autoFocus}
        onChange={e => {
          if (e.target != null) {
            const { value: newValue } = e.currentTarget;

            if (newValue == null || newValue == '') {
              if (value !== null) {
                onChange(null, name);
              }
            } else {
              onChange(newValue, name);
            }
          }
        }}
        {...rest}
      />
    </JustificationContainer>
  ),
);

export const TEXTAREA_ELEMENT_BORDER = '1px';
type StyledTextareaProps = {
  $disabled?: boolean;
  $error?: string | null;
  $borderless?: Props['borderless'];
  $uneditable?: Props['uneditable'];
  $resizable?: Props['resizable'];
} & React.HtmlHTMLAttributes<HTMLTextAreaElement>;

const TextareaElement = styled.textarea<StyledTextareaProps>`
  resize: ${({ $resizable }) => ($resizable ? 'both' : 'none')};
  width: 100%;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.getTokens().border.radius.base};

  padding: ${({ theme }) => theme.space('s')};

  ${({ theme, $disabled }) =>
    $disabled &&
    css`
      background: ${theme.color('white', 'dark')};
      color: ${theme.color('tertiary', 'dark')};
      pointer-events: none;
    `}

  ${({ $uneditable }) =>
    $uneditable &&
    css`
      cursor: default;
      user-select: none;
    `};

  &:hover,
  &:focus {
    ${({ theme, $disabled, $borderless, $uneditable }) => {
      if ($uneditable) {
        return css`
          border: ${TEXTAREA_ELEMENT_BORDER} solid ${theme.color('tertiary')};
        `;
      } else if (!$disabled && !$borderless) {
        return css`
          border: ${TEXTAREA_ELEMENT_BORDER} solid
            ${theme.color('primary', 'light')};
        `;
      }

      return null;
    }}
  }

  ${({ $error, $borderless, theme }) => {
    if (!isNil($error)) {
      return css`
        &,
        &:hover,
        &:focus {
          border: ${TEXTAREA_ELEMENT_BORDER} solid ${theme.color('danger')};
        }
      `;
    }

    if ($borderless) {
      return css`
        border: 0;
      `;
    } else {
      return css`
        border: ${TEXTAREA_ELEMENT_BORDER} solid ${theme.color('tertiary')};
      `;
    }
  }};
`;

export default Textarea;
