import type { FlowData___InstanceConditionFragment } from '~/graphql/types';
import type { Flow___SubjectIdentifier } from '../getSubject';

export type ConditionMap = {
  [type in string]: FlowData___InstanceConditionFragment;
};

export type SubjectToConditionMap = {
  [type in string]: {
    [typeId in string]: [FlowData___InstanceConditionFragment];
  };
};

/**
 * Generate the condition map
 */
export const generateConditionMap = (
  conditions: Array<FlowData___InstanceConditionFragment>,
): {
  conditionMap: ConditionMap;
  subjectToConditionMap: SubjectToConditionMap;
} => {
  const conditionMap: ConditionMap = {};
  const subjectToConditionMap = conditions.reduce((prev, curr) => {
    conditionMap[curr.type] = curr;

    for (const input of curr.input) {
      if (prev[input.type] == null) prev[input.type] = {};
      if (prev[input.type][input.typeId ?? ''] == null) {
        prev[input.type][input.typeId ?? ''] = [curr];
        return prev;
      }

      prev[input.type][input.typeId ?? ''].push(curr);
    }

    return prev;
  }, {} as SubjectToConditionMap);

  return {
    conditionMap,
    subjectToConditionMap,
  };
};

const getConditions = (
  { type, typeId }: Flow___SubjectIdentifier,
  subjectToConditionMap: SubjectToConditionMap,
): [FlowData___InstanceConditionFragment] =>
  subjectToConditionMap[type]?.[typeId ?? ''] ?? [];

export const getCondition = (
  type: string,
  conditionMap: ConditionMap,
): FlowData___InstanceConditionFragment | undefined => conditionMap[type];

export default getConditions;
