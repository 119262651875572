import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  UPDATE_BILLING_TRIGGER: `${cleanedFilename(
    __filename,
  )}-update-billing-trigger`,
  BILLING_ADDRESS: `${cleanedFilename(__filename)}-billing-address`,
  BILLING_EMAIL: `${cleanedFilename(__filename)}-billing-email`,
  GO_INVOICES: `${cleanedFilename(__filename)}-go-invoices`,
};
