import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  CREATE_BUTTON: `${cleanedFilename(__filename)}-create-button`,
  NAME_INPUT: `${cleanedFilename(__filename)}-name-input`,
  DESCRIPTION_INPUT: `${cleanedFilename(__filename)}-description-input`,
  GO_BACK_BUTTON: `${cleanedFilename(__filename)}-go-back-button`,
  EXIT_WIZARD_BUTTON: `${cleanedFilename(__filename)}-exit-wizard-button`,
};
