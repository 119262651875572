import React, { useContext } from 'react';
import { FlowV2_ActionFragment } from '~/graphql/types';
import useErrorReporter from '~/hooks/useErrorReporter';

type ActionContextType = {
  actionId: string;
  actionType: FlowV2_ActionFragment['actionType'];
};

export const ActionContext = React.createContext<ActionContextType | null>(
  null,
);

const useActionContext = (): ActionContextType => {
  const reporter = useErrorReporter();
  const context = useContext(ActionContext);

  if (context === null) {
    const err = new Error(
      `Action context should be set before using useActionContext but it wasn't`,
    );
    reporter.captureException(err, 'fatal');

    throw err;
  }

  return context;
};

export default useActionContext;
