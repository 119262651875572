import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap';
import { useEffect } from 'react';

/**
 * adds a bindGlobal method to Mousetrap that allows you to
 * bind specific keyboard shortcuts that will still work
 * inside a text input field
 *
 * usage:
 * Mousetrap.bindGlobal('ctrl+s', _saveChanges);
 *
 * taken from: https://github.com/ccampbell/mousetrap/blob/master/plugins/global-bind/mousetrap-global-bind.js
 */

interface MousetrapStatic extends Mousetrap.MousetrapStatic {
  bindGlobal(
    keyArray: string | Array<string>,
    callback: (e: ExtendedKeyboardEvent, combo: string) => any,
    action?: string,
  ): void;
  unbindGlobal(keys: string | Array<string>, action?: string): void;
  init(): void;
}

(function globalMousetrap(Mousetrap) {
  if (!Mousetrap || !Mousetrap.prototype || !Mousetrap.prototype.stopCallback) {
    return;
  }
  const _globalCallbacks = {};
  const _originalStopCallback = Mousetrap.prototype.stopCallback;

  Mousetrap.prototype.stopCallback = function (e, element, combo, sequence) {
    const self = this;

    if (self.paused) {
      return true;
    }

    if (_globalCallbacks[combo] || _globalCallbacks[sequence]) {
      return false;
    }

    return _originalStopCallback.call(self, e, element, combo);
  };

  Mousetrap.prototype.bindGlobal = function (keys, callback, action) {
    const self = this;
    self.bind(keys, callback, action);

    if (keys instanceof Array) {
      for (const key of keys) {
        _globalCallbacks[key] = true;
      }
      return;
    }

    _globalCallbacks[keys] = true;
  };

  Mousetrap.prototype.unbindGlobal = function (keys, action) {
    const self = this;
    self.unbind(keys, action);

    if (keys instanceof Array) {
      for (const key of keys) {
        _globalCallbacks[key] = false;
      }
      return;
    }

    _globalCallbacks[keys] = false;
  };

  (Mousetrap as MousetrapStatic).init();
})(Mousetrap);

type Action = 'keypress' | 'keydown' | 'keyup';
const useGlobalKeyBinding = ({
  keys,
  callback,
  action,
  enabled,
}: {
  /*
   * The key combo e.g. ['ctrl+s', 'command+s'] or 'ctrl+s'
   * For exact syntax of key combinations check https://craig.is/killing/mice
   */
  keys: string | Array<string>;

  /*
   * The function you want run when keys get triggered
   */
  callback: (event: ExtendedKeyboardEvent, triggeredKey: string) => void;
  /*
   * Specify the type of action it should fire on, if non is provided all will be watched
   */
  action?: Action;
  /**
   * Enable keybinding only when a condition is provided e.g when a field is focused
   */
  enabled?: boolean;
}) => {
  useEffect(() => {
    if (!enabled) return;

    (Mousetrap as MousetrapStatic).bindGlobal(keys, callback, action);

    return () => {
      (Mousetrap as MousetrapStatic).unbindGlobal(keys);
    };
  }, [action, callback, keys, enabled]);
};

export default useGlobalKeyBinding;
