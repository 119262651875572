import React from 'react';
import styled, { css } from 'styled-components';

import { Helmet as MetaTags } from 'react-helmet';
import { navigate } from '@gatsbyjs/reach-router';
import FullPageInformation from '~/components/template/FullPageInformation';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import TEST_ID from './index.testid';
import { APPROXIMATE_HEADER_HEIGHT } from '~/styles/constants';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { FOOTER_HEIGHT } from '~/components/organism/NavigationFrame/components/Footer';
import createPageTitle from '~/util/createPageTitle';

export const text = {
  pageTitle: 'Pagina niet gevonden!',
  title: 'Pagina niet gevonden!',
  explanation:
    'Helaas kunnen we deze pagina niet vinden, mocht het nogmaals gebeuren laat het ons weten. Dan gaan wij op zoek naar de verloren pagina.',
  buttonText: 'Terug naar dashboard',
};

type Props = {
  pageTitle?: string;
  title?: string;
  explanation?: string;
  buttonText?: string;
  redirectTo?: string;
};

export const NotFound: React.FCC<RouteComponentProps & Props> = ({
  pageTitle = text.pageTitle,
  title = text.title,
  explanation = text.explanation,
  buttonText = text.buttonText,
  redirectTo = '/-/',
}) => (
  <MainContainer>
    <ContentContainer data-testid={TEST_ID.PAGE_CONTAINER} height="100vh">
      <MetaTags>
        <title>{createPageTitle(pageTitle)}</title>
      </MetaTags>
      <FullPageInformation
        title={title}
        explanation={explanation}
        button={{ onClick: () => void navigate(redirectTo), label: buttonText }}
        dataTestId={TEST_ID.NOT_FOUND_PAGE}
      />
    </ContentContainer>
  </MainContainer>
);

export const WithNavigationFrame: React.FCC<RouteComponentProps> = () => (
  <>
    <MetaTags>
      <title>{createPageTitle(text.pageTitle)}</title>
    </MetaTags>
    <FullPageInformation
      title={text.title}
      explanation={text.explanation}
      button={{ onClick: () => void navigate('/-/'), label: text.buttonText }}
      dataTestId={TEST_ID.NOT_FOUND_PAGE}
    />
  </>
);

const MainContainer = styled(JustificationContainer)<{}>`
  margin-top: ${APPROXIMATE_HEADER_HEIGHT}px;
  height: calc(100vh - ${APPROXIMATE_HEADER_HEIGHT * 2 + FOOTER_HEIGHT}px);
`;

const ContentContainer = styled(JustificationContainer)<{}>`
  max-width: 100vw;

  ${({ theme }) => css`
    background-color: ${theme.color('white', 'dark')};
  `}
`;

export default NotFound;
