import type { ToolbarComponent } from '.';

export const FONT_BASE_BUTTONS: Array<ToolbarComponent> = [
  { type: 'button', name: 'bold' },
  { type: 'button', name: 'italic' },
  { type: 'button', name: 'underline' },
];

export const LIST_BUTTONS: Array<ToolbarComponent> = [
  { type: 'button', name: 'ordered-list' },
  { type: 'button', name: 'unordered-list' },
];

export const BLOCK_ALIGN_BUTTONS: Array<ToolbarComponent> = [
  { type: 'button', name: 'indent' },
  { type: 'button', name: 'outdent' },
  { type: 'button', name: 'align' },
];

export const FONT_STYLE_BUTTONS: Array<ToolbarComponent> = [
  { type: 'button', name: 'color' },
  { type: 'button', name: 'background-color' },
  { type: 'button', name: 'font-size' },
  { type: 'button', name: 'font-family' },
];

export const MISC_BUTTONS: Array<ToolbarComponent> = [
  { type: 'button', name: 'undo' },
  { type: 'button', name: 'redo' },
  { type: 'button', name: 'full-screen' },
  { type: 'button', name: 'debug' },
];

export const TOOLBAR_DIVIDER: ToolbarComponent = { type: 'divider' };
