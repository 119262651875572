import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React, { useEffect, useState } from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import ContentContainerDefault, {
  MAX_CONTAINER_WIDTH,
} from '~/components/molecule/ContentContainer';
import { Heading2 } from '~/components/atom/Typography';
import Loading from '~/components/atom/Loading';
import useApp from '~/hooks/useApp';
import MasterDetail from '~/components/template/MasterDetail';
import AppDetails from './components/AppDetails';
import {
  useUpdateAppStatusMutation,
  AppStatus_StellaxAi__Input,
  type AppStatusFields_AppStatus_StellaxAi_Fragment,
} from '~/graphql/types';
import SaveBar from '~/components/organism/NewSaveBar';
import { isNil, keys } from 'ramda';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import objectDiff from '~/util/objectDiff';
import appStatusToInput from './utils/appStatusToInput';
import { fieldMapping } from './components/AppDetails/constants';
import type { SaveBarMessage } from '~/components/organism/NewSaveBar/components/MessagesContainer';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import type { ExtendedAppConfig } from '~/hooks/useApps';
import Catalog from '~/Catalog';
import createPageTitle from '~/util/createPageTitle';

type Props = RouteComponentProps & {};

export const text = {
  appTitle: 'Stellax - Huuranalyse & WWS-check',
  successToastMessage: Catalog.genericSuccessMessage,
};

const StellaxAi: React.FCC<Props> = () => {
  const { app, loading } = useApp('AppStatus_StellaxAi');

  if (loading) return <Loading />;
  if (
    !app ||
    !app?.appStatus.enabled ||
    app.appStatus.__typename !== 'AppStatus_StellaxAi'
  )
    return <AppErrorScreen />;

  return <Child app={app} />;
};

const Child: React.FCC<Props & { app: ExtendedAppConfig }> = ({ app }) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const [updateApp, { loading: loadingUpdate }] = useUpdateAppStatusMutation();
  const [initialValues, setInitialValues] =
    useState<NonNullable<AppStatus_StellaxAi__Input> | null>(null);
  const [update, setUpdate] =
    useState<NonNullable<AppStatus_StellaxAi__Input> | null>(null);

  const [errorMessages, setErrorMessages] = useState<Array<SaveBarMessage>>([]);
  const [logoUrl, setLogoUrl] = useState(
    (app.appStatus as AppStatusFields_AppStatus_StellaxAi_Fragment).logo?.url ??
      null,
  );

  // Init effect, should only produce side effects on initial render
  useEffect(() => {
    // When we get the app status we get set the initials
    if (
      app &&
      app.appStatus &&
      app.appStatus.__typename === 'AppStatus_StellaxAi'
    ) {
      setInitialValues(appStatusToInput(app.appStatus));
      setUpdate(appStatusToInput(app.appStatus));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Validation effect
  useEffect(() => {
    const requiredFieldKeys = keys(fieldMapping).filter(
      key => fieldMapping[key].required,
    );

    if (update) {
      const validationMessages: Array<SaveBarMessage> = requiredFieldKeys
        .map((key): SaveBarMessage | null => {
          const message = fieldMapping[key].validation(update[key]);
          if (message === true) return null;

          return {
            key,
            message,
            type: 'danger',
          };
        })
        .filter(
          (message: SaveBarMessage | null): message is SaveBarMessage =>
            !isNil(message),
        );
      setErrorMessages(validationMessages);
    }
  }, [update]);

  if (initialValues === null || update === null) return <Loading />;

  const changes = objectDiff(initialValues, update);
  const changeCount = changes ? Object.keys(changes).length : 0;

  const onSave = async () => {
    await updateApp({
      variables: {
        accountId,
        update: {
          AppStatus_StellaxAi: update,
        },
      },
    }).then(({ errors, data }) => {
      if (errors && errors?.length > 0) {
        return addToast([formatToastMessage(errors[0].message, 'danger')]);
      }

      if (data && data.updateAppStatus.__typename === 'AppStatus_StellaxAi') {
        setInitialValues(appStatusToInput(data.updateAppStatus));
        setUpdate(appStatusToInput(data.updateAppStatus));
        addToast([formatToastMessage(text.successToastMessage, 'success')]);
        return;
      }
    });
  };

  const onCancel = () => {
    // Reset to initial values
    if (app.appStatus && app.appStatus.__typename === 'AppStatus_StellaxAi') {
      setUpdate(appStatusToInput(app.appStatus));
      setLogoUrl(app.appStatus?.logo?.url ?? null);
    }
  };

  return (
    <ContentContainerDefault
      maxContentWidth={MAX_CONTAINER_WIDTH}
      breadcrumbs={[
        {
          to: '/-/apps',
          label: 'Apps',
        },
        { label: text.appTitle },
      ]}
    >
      <MetaTags>
        <title>{createPageTitle(text.appTitle)}</title>
      </MetaTags>
      <Heading2 color={{ group: 'primary' }}>{text.appTitle}</Heading2>

      <SaveBar
        loading={loadingUpdate}
        changes={changeCount}
        onSave={onSave}
        onCancel={onCancel}
        messages={errorMessages}
        disabled={loadingUpdate || changeCount === 0}
        margin={[null, null, 'm', null]}
      />

      <MasterDetail
        basePath={'/-/'}
        navbar={[
          {
            type: 'link',
            to: 'apps/stellax-ai',
            icon: 'edit',
            name: 'Rapport instellingen',
          },
        ]}
      >
        <AppDetails
          path="/*"
          default
          onChange={updatedValues => {
            setUpdate(prev => {
              if (!prev) return prev;
              return { ...prev, ...updatedValues };
            });
          }}
          values={update}
          logoUrl={logoUrl}
          setLogoUrl={setLogoUrl}
        />
      </MasterDetail>
    </ContentContainerDefault>
  );
};

export default StellaxAi;
