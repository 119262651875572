import { useRecoilValue } from 'recoil';
import { formModeSelector } from '../../state';
import { FormBuilderMode } from '~/graphql/types';

const useIsBasicForm = () => {
  const formType = useRecoilValue(formModeSelector);

  return formType === FormBuilderMode.Basic;
};

export default useIsBasicForm;
