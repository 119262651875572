import { useState } from 'react';
import useDebounce from '~/hooks/useDebounce';

type Props = {};
type Return = [boolean, { onMouseOver: () => void; onMouseOut: () => void }];

/**
 * Allows you to get notified once an element is hovered on.
 *
 * returns [hover, { props to attach to the element in question }]
 *
 * E.g.:
 *
 * const [isHovered, hoverProps] = useHover()
 *
 * return (
 *   <div {...hoverProps}>{isHovered}<div>
 * )
 *
 */
const useHover = ({}: Props = {}): Return => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const debouncedShowTooltip = useDebounce(showTooltip, 300);

  const props = {
    onMouseOver: () => setShowTooltip(true),
    onMouseOut: () => setShowTooltip(false),
  };

  return [debouncedShowTooltip, props];
};

export default useHover;
