import React, { useState } from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { UserRole, useGetAccountQuery } from '~/graphql/types';
import Loading from '~/components/atom/Loading';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import withErrorBoundary from '~/ErrorBoundary';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import AccountComponent from './components/AccountComponent';
import convertMailboxes from '~/util/converters/convertMailboxes';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useSessionHydration from '~/hooks/useSessionHydration';
import createPageTitle from '~/util/createPageTitle';
import hasValue from '~/util/hasValue';
import { Body } from '~/components/atom/Typography';

type Props = RouteComponentProps;

const text = {
  pageTitle: 'Account',
};

const Account: React.FCC<Props> = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [, refetchSessionHydration] = useSessionHydration();
  const { id: accountId } = useCurrentAccount();
  const { data, networkStatus, refetch, startPolling, stopPolling } =
    useGetAccountQuery({ variables: { accountId } });

  if (isInitialLoadStatus(networkStatus))
    return (
      <ContentContainerDefault>
        <MetaTags>
          <title>{createPageTitle(text.pageTitle)}</title>
        </MetaTags>
        <Loading />
      </ContentContainerDefault>
    );

  if (!data || !data.getAccount)
    return (
      <ContentContainerDefault>
        <MetaTags>
          <title>{createPageTitle(text.pageTitle)}</title>
        </MetaTags>
        <AppErrorScreen />
      </ContentContainerDefault>
    );

  const account = data.getAccount;
  const userIsAdmin = account.relation.role === UserRole.Admin;
  const mailboxes = account.Mailboxes;
  const signature = account.Signature ?? null;

  const refetchAccount = () =>
    Promise.all([refetch(), refetchSessionHydration()]);

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{createPageTitle(text.pageTitle)}</title>
      </MetaTags>

      {hasValue(errorMsg) && (
        <Body color={{ group: 'danger' }}>{errorMsg}</Body>
      )}

      <AccountComponent
        accountId={account.id}
        accountName={account.name}
        mayEdit={userIsAdmin}
        mailboxes={convertMailboxes(mailboxes)}
        refetch={refetchAccount}
        setErrorMsg={(msg: string) => setErrorMsg(msg)}
        startPolling={startPolling}
        stopPolling={stopPolling}
        signature={signature}
      />
    </ContentContainerDefault>
  );
};

export default withErrorBoundary(Account);
