import { all } from 'ramda';
import { ReturnType } from '../getIbanValidationOutput';

/**
 * Validates iban by the standard format of International Organization for Standardization.
 * Currently we only support Dutch ibans
 * - 2 letters ISO country code ("NL" for the Netherlands)
   - 2 digits IBAN check digits
   - 4 characters SWIFT/BIC code
   - 10 digits account number
   
 * @param {string} iban
 */

const validateIbanISO = (iban: string): ReturnType['error'] => {
  const checkDigit = /\d/;
  const checkLetter = /[A-Z]/;

  const currentPosition = iban.length;

  const countryCodeStartPosition = 1;
  const checkDigitStartPosition = 2;
  const bankCodeStartPosition = 4;
  const accountNumberStartPosition = 8;

  const isDigit = string => checkDigit.test(string);
  const isLetter = string => checkLetter.test(string);

  const getCheckedValues = (
    startPosition: number,
    endPosition?: number,
  ): Array<string> => iban.slice(startPosition, endPosition).split('');

  const validCountryCode = iban.startsWith('NL');
  const validCheckDigit = all(
    isDigit,
    getCheckedValues(checkDigitStartPosition, bankCodeStartPosition),
  );
  const validBankCode = all(
    isLetter,
    getCheckedValues(bankCodeStartPosition, accountNumberStartPosition),
  );
  const validAccountNumber = all(
    isDigit,
    getCheckedValues(accountNumberStartPosition),
  );

  if (currentPosition > countryCodeStartPosition && !validCountryCode) {
    return 'countryCode';
  }

  if (currentPosition > checkDigitStartPosition) {
    if (!validCheckDigit || !validBankCode || !validAccountNumber) {
      return 'invalid';
    }
  }

  return null;
};

export default validateIbanISO;
