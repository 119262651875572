import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { MarkStyles } from '~/components/organism/PluginsEditor/components/Buttons/Mark';
import { DHEditor } from '~/components/organism/PluginsEditor/types';

export const toggleMark = (editor: DHEditor, format: MarkStyles) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    /**
     * There is a bug in Slate. Marks don't get removed when mark button is clicked
     * unless you type in the editor again. removeMark gets called, there are no marks left but
     * Leaf element does not get rerendered so the placeholder is still wrapped with the
     * Mark element. This could be related to memoizing Leaf element in PluginsEditor, I do not want to
     * change that right now because that might cause unnecessary rerenders.
     *
     * Skipping this because it is not crucial.
     */
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }

  ReactEditor.focus(editor);
};

export const isMarkActive = (editor: DHEditor, format: MarkStyles): boolean => {
  if (!editor.selection) return false;

  const marks = Editor.marks(editor);

  if (!marks) return false;

  return marks[format];
};
