import { uniq } from 'ramda';

type Value = string | number | $Object | null | undefined;

export type ValidationFunction = (value: Value) => string | true;

/**
 * Return error messages as an array of string
 *
 * @param {Array<ValidationFunction>} validationFunctions - Array of functions that return the error messages
 * @param {Value} value - Any value that is being validated
 * keywords: validate
 */
const getValidationErrors = (
  validationFunctions: Array<ValidationFunction>,
  value: Value,
): Array<string> =>
  validationFunctions.reduce<Array<string>>((errors, fn) => {
    const result = fn(value);
    if (result === true) return errors;
    errors.push(result);
    return uniq(errors);
  }, []);

export default getValidationErrors;
