import type { FieldFunctionOptions, FieldReadFunction } from '@apollo/client';
import { uniq } from 'ramda';

type PaginatedObj<TItem> = {
  nextToken: string | null;
  items: Array<TItem> | undefined;
  __typename: string;
};

export const mergeInfinitePaginatedItems = <TItem>(
  existing: PaginatedObj<TItem>,
  incoming: PaginatedObj<TItem>,
): PaginatedObj<TItem> => {
  const items = existing ? [...(existing.items || [])] : [];

  return {
    nextToken: incoming.nextToken,
    items: uniq([...items, ...(incoming.items || [])]),
    __typename: incoming.__typename,
  };
};

type Custom<TItem> = (
  __typename: string, // __typename of the paginated item
) => FieldReadFunction<
  PaginatedObj<TItem>,
  PaginatedObj<TItem>,
  FieldFunctionOptions<Record<string, any>, Record<string, any>>
>;

export const readInfinitePaginatedItems =
  <TItem>(__typename: string): ReturnType<Custom<TItem>> =>
  existing => {
    if (existing && existing.items) {
      return {
        nextToken: existing.nextToken,
        items: Object.values(existing.items),
        __typename: existing.__typename,
      };
    }

    return {
      nextToken: null,
      items: undefined,
      __typename,
    };
  };
