import React, { useState } from 'react';
import {
  RealworksTokenStatus,
  useGetAppStatusRealworksQuery,
  AppStatus_Realworks_RealworksTokenContainer,
  useSendContactToRealworksMutation,
} from '~/graphql/types';

import FoldableCardBlock from '../FoldableCardBlock';
import { useEffect } from 'react';
import Button from '~/components/atom/Button';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import findClosestActiveRealworksTokenContainer from '~/components/page/Apps/Realworks/findClosestActiveRealworksTokenContainer';
import Dropdown from '~/components/molecule/Dropdown';
import TEST_ID from './index.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';

export const text = {
  sendContactToRealworksDropdownLabel: 'Koppelingen',
  sendContactToRealworks: 'Verstuur naar Realworks',
  sendContactToRealworksSubmitLabel: 'Versturen',
  sendContactToRealworksSuccess: 'Contact verzonden',
  sendContactToRealworksFailure: 'Verzenden mislukt',
};
type Props = {
  officeId: string | null | undefined;
  userId: string | null | undefined;
  contactId: string;
};
const RealworksSendContactBlock: React.FCC<Props> = ({
  contactId,
  officeId,
  userId,
}) => {
  const addToast = useAddToast();
  const account = useCurrentAccount();

  const [selectedTokenContainerId, setSelectedTokenContainerId] = useState<
    string | null
  >(null);

  const {
    data: getAppStatusRealworksData,
    loading: getAppStatusRealworksLoading,
    error: getAppStatusRealworksError,
  } = useGetAppStatusRealworksQuery({
    variables: { accountId: account.id },
  });

  const [activeRealworksWonenTokens, setActiveRealworksWonenTokens] = useState<
    Array<AppStatus_Realworks_RealworksTokenContainer>
  >([]);

  const [
    sendContactToRealworks,
    {
      error: sendContactToRealworksError,
      loading: sendContactToRealworksLoading,
    },
  ] = useSendContactToRealworksMutation({
    onCompleted: data => {
      if (!data) return;
      addToast([
        formatToastMessage(text.sendContactToRealworksSuccess, 'success'),
      ]);
    },
  });
  useEffect(() => {
    if (!sendContactToRealworksError) return;
    addToast([
      formatToastMessage(text.sendContactToRealworksFailure, 'danger'),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendContactToRealworksError]);

  const realworksStatus = getAppStatusRealworksData?.getAppStatusRealworks;

  useEffect(() => {
    if (!realworksStatus?.enabled) return;
    const activeTokens = realworksStatus.tokens.filter(
      token => token.wonen?.status === RealworksTokenStatus.Active,
    );
    setActiveRealworksWonenTokens(activeTokens);
  }, [realworksStatus]);

  useEffect(() => {
    if (!realworksStatus?.enabled) return;

    if (selectedTokenContainerId == null) {
      const closestActiveTokenContainer =
        findClosestActiveRealworksTokenContainer(realworksStatus.tokens ?? [], {
          accountId: account.id,
          officeId,
          userId,
        });
      if (closestActiveTokenContainer) {
        setSelectedTokenContainerId(closestActiveTokenContainer.id);
      }
    }
  }, [account.id, realworksStatus, officeId, selectedTokenContainerId, userId]);

  const activeTokensOptions = activeRealworksWonenTokens.map(activeToken => ({
    key: activeToken.id,
    label: activeToken.name,
    payload: activeToken.id,
  }));

  const selectedActiveTokenOptionKey = selectedTokenContainerId ?? null;
  const selectedActiveTokenOption = activeTokensOptions.findIndex(
    option => option.payload === selectedActiveTokenOptionKey,
  );

  if (
    !getAppStatusRealworksLoading &&
    (!realworksStatus?.enabled || activeRealworksWonenTokens.length === 0)
  ) {
    return null;
  }

  return (
    <FoldableCardBlock
      icon="gear"
      title={text.sendContactToRealworks}
      loading={getAppStatusRealworksLoading}
      dataTestId="card-block-sendcontact-to-realworks"
    >
      <Dropdown
        margin={[null, null, 'base', null]}
        loading={getAppStatusRealworksLoading}
        options={activeTokensOptions}
        onChange={({ option }) => {
          setSelectedTokenContainerId(option.payload);
        }}
        selectedOptionIdx={selectedActiveTokenOption}
        error={getAppStatusRealworksError?.message ?? ''}
        disabled={!!getAppStatusRealworksError || sendContactToRealworksLoading}
        dataTestId={TEST_ID.REALWORKS_ACTIVE_TOKENS_DROPDOWN}
        label={text.sendContactToRealworksDropdownLabel}
      />

      <Button
        size="medium"
        onClick={() =>
          sendContactToRealworks({
            variables: {
              accountId: account.id,
              contactId,
              // since the button is disabled when selectedTokenContainerId == null, this will be a string at this point
              tokenContainerId: selectedTokenContainerId as string,
            },
          })
        }
        appearance="secondary"
        loading={sendContactToRealworksLoading}
        disabled={
          sendContactToRealworksLoading || selectedTokenContainerId == null
        }
        data-testid="send-contact-to-realworks-submit"
        label={text.sendContactToRealworksSubmitLabel}
      />
    </FoldableCardBlock>
  );
};

export default RealworksSendContactBlock;
