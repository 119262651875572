import React from 'react';
import Dropdown from '~/components/molecule/Dropdown';
import type { FormBuilder_Locale } from '~/graphql/types';
import useLocaleOptions from '~/components/page/Forms/components/Builder/hooks/useLocaleOptions';
import { useRecoilValue } from 'recoil';
import { formState } from '~/components/page/Forms/components/Builder/state';

export type Props = {
  currentLocale: FormBuilder_Locale;
  onChange: (locale: FormBuilder_Locale) => void;
};

const LanguageSwitcher: React.FCC<Props> = ({ currentLocale, onChange }) => {
  const state = useRecoilValue(formState);
  const localeOptions = useLocaleOptions({
    availableOptions: state?.style.availableLocale,
  });
  if (localeOptions.length <= 1) return null;

  return (
    <Dropdown
      size="small"
      width="max-content"
      options={localeOptions}
      selectedOptionIdx={localeOptions.findIndex(
        curr => curr.key === currentLocale,
      )}
      onChange={option => onChange(option.option.key as FormBuilder_Locale)}
    />
  );
};

export default LanguageSwitcher;
