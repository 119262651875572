import React, { useState } from 'react';
import type { DoContactAction_AddTags__Input } from '~/graphql/types';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import BasePopover from '../../components/BasePopover';
import TagListInput from '~/components/molecule/TagListInput';
import type { ActionModalProps } from '../../constants';

export type Props = {} & ActionModalProps;

const text = {
  title: 'Tag toevoegen',
  confirm: 'Bevestigen',
  label: 'Nieuwe tag toevoegen',
};

const AddTags: React.FCC<Props> = ({
  lockContactsVariables,
  selectedContactAmount,
  onComplete,
  onCancel,
}) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => setShowModal(false);
  const [tagList, setTagList] = useState<Array<string>>([]);

  return (
    <>
      {showModal ? (
        <ConfirmActionModal
          actionType="AddTags"
          lockContactsVariables={lockContactsVariables}
          onComplete={() => {
            hideModal();
            onComplete();
          }}
          onCancel={() => {
            hideModal();
            onCancel();
          }}
          selectedContactAmount={selectedContactAmount}
          actionInput={{
            AddTags: { tags: tagList } as DoContactAction_AddTags__Input,
          }}
        />
      ) : (
        <BasePopover
          title={text.title}
          handleAction={confirm => {
            if (confirm) {
              setShowModal(true);
            } else {
              onCancel();
            }
          }}
          confirmText={text.confirm}
          disabled={tagList.length === 0}
        >
          <TagListInput
            selectedValues={tagList}
            onAdded={newTag => {
              setTagList(prevList => [...prevList, newTag]);
            }}
            onRemoved={removedTag => {
              setTagList(prevList =>
                prevList.filter(tag => tag !== removedTag),
              );
            }}
          />
        </BasePopover>
      )}
    </>
  );
};

export default AddTags;
