import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  DUE_DATE_DATE: `${cleanedFilename(__filename)}-dueDateDate`,
  DUE_DATE_TIME: `${cleanedFilename(__filename)}-dueDateTime`,
  DESCRIPTION_TEXTAREA_FIELD: `${cleanedFilename(
    __filename,
  )}-description-textarea-field`,
  INSERT_ACTIVITY_BUTTON: `${cleanedFilename(
    __filename,
  )}-insert-activity-button`,
  TYPE_FIELD: `${cleanedFilename(__filename)}-type-field`,
  PHONE_CALL_OPTION: `${cleanedFilename(__filename)}-phone-call-option`,
  NOTE_OPTION: `${cleanedFilename(__filename)}-note-option`,
};
