import React from 'react';
import styled, { css } from 'styled-components';

import { Theme } from '~/theme';

type Props = {
  /** for tests */

  /** when clicked */
  onClick?: () => void;

  /** Card content */
  children: React.ReactNode;
};

const Card: React.FCC<Props> = ({ dataTestId, ...props }) => (
  <CardElement data-testid={dataTestId} {...props} />
);

export const getCardPadding = (theme: Theme) => theme.space('l');

type CardElementProps = {
  onClick?: () => void;
};
const CardElement = styled.div<CardElementProps>`
  position: relative;
  transition: box-shadow 0.2s linear;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.m};
    border: 1px solid ${theme.color('tertiary', 'light')};
    background-color: ${theme.color('white')};
    box-shadow: ${theme.boxShadow('card')};
    padding: ${theme.space('l')} ${getCardPadding(theme)};
    margin-bottom: ${theme.space('m')};
  `};

  ${({ onClick }) => {
    if (onClick) {
      return css`
        cursor: pointer;
      `;
    }
    return ``;
  }}
`;

export default Card;
