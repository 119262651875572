import React, { useEffect } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  WizardStepProps,
  type OutputFieldInvisible,
} from '~/components/organism/Wizard/context/WizardContext';
import useWizardStep from '~/hooks/useWizardStep';
import { animated, useSpring } from 'react-spring';
import { ANIMATION_CONFIG } from '~/styles/constants';
import { PRODUCT_OPTIONS } from '~/components/page/Apps/StellaxAI/appConfig';
import SelectProduct from '~/components/template/AppSetupModal/components/SelectProduct';
import { Body } from '~/components/atom/Typography';
import Divider from '~/components/atom/Divider';
import type { StellaxAiReportType } from '~/graphql/types';
import { equals } from 'ramda';

export const id = 'OnboardingStellaxAiSelectProduct';
export const title = 'Kies een Stellax product';

const text = {
  description:
    'Stellax levert meerdere producten, hieronder kan jij kiezen welk product jij graag terug ziet in de widget.',
  noOverview:
    'Momenteel is er geen overzicht beschikbaar. Ga door met de wizard.',
  enabledAppsCategory: 'Geactiveerde apps',
};

export type OutputType = {
  type: typeof id;
  reportType: OutputFieldInvisible<StellaxAiReportType>;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const [, api] = useWizardStep(step.id);
  const animation = useSpring(ANIMATION_CONFIG);
  const currentStepOutput =
    outputMap.OnboardingStellaxAiSelectProduct as OutputType;

  useEffect(() => {
    api.freeGoBack();
    if (currentStepOutput.reportType) {
      api.free(currentStepOutput);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepOutput]);

  return (
    <animated.section style={animation}>
      <Body>{text.description}</Body>
      <Divider />
      <JustificationContainer direction="column" gap="l">
        <SelectProduct
          options={PRODUCT_OPTIONS}
          selectedOption={PRODUCT_OPTIONS.find(({ payload }) =>
            equals(payload, currentStepOutput?.reportType.value),
          )}
          onSelect={option => {
            api.free({
              type: id,
              reportType: {
                type: 'invisible',
                value: option.payload as StellaxAiReportType,
              },
            });
          }}
        />
      </JustificationContainer>
    </animated.section>
  );
};

export default {
  id,
  title,
};
