import type { Reducer } from 'react';
import type { ClientFlowActionContactDetails } from '~/graphql/types.client';
import getActiveField from '../utils/getActiveField';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';

export type FieldName = Exclude<
  keyof ClientFlowActionContactDetails['field'],
  '__typename' | 'phoneValue' | 'nameValue'
>;
export type State = {
  selectedField: FieldName;
  action: ClientFlowActionContactDetails;
};

type UpdateNameAction = {
  type: 'updateName';
  payload: EditorValue;
};

type UpdatePhoneAction = {
  type: 'updatePhone';
  payload: EditorValue;
};

export type UpdateValueAction = UpdateNameAction | UpdatePhoneAction;

export type SelectFieldAction = {
  type: 'selectField';
  payload: FieldName;
};

export type ReducerAction = UpdateValueAction | SelectFieldAction;

export const reducer: Reducer<State, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'selectField': {
      // Because we don't allow users to set `unsubscribeEmail` to false
      // when they select the field we set it to true for them.
      // This way it can only ever be true or undefined
      if (action.payload === 'unsubscribeEmail') {
        return {
          ...state,
          selectedField: action.payload,
          action: {
            ...state.action,
            field: {
              ...state.action.field,
              unsubscribeEmail: true,

              // Reset other fields
              phoneValue: [],
              nameValue: [],
            },
          },
        };
      }

      return {
        ...state,
        selectedField: action.payload,

        action: {
          ...state.action,

          // Reset the fields
          field: {
            ...state.action.field,
            phoneValue: [],
            nameValue: [],
            unsubscribeEmail: undefined,
          },
        },
      };
    }

    case 'updateName': {
      return {
        ...state,
        action: {
          ...state.action,
          field: {
            ...state.action.field,
            nameValue: action.payload,

            // Reset other fields
            phoneValue: [],
            unsubscribeEmail: undefined,
          },
        },
      };
    }
    case 'updatePhone': {
      return {
        ...state,
        action: {
          ...state.action,
          field: {
            ...state.action.field,
            phoneValue: action.payload,

            // Reset other fields
            nameValue: [],
            unsubscribeEmail: undefined,
          },
        },
      };
    }

    default:
      return state;
  }
};

export const createInitialState = (state: State): State => ({
  ...state,
  selectedField: getActiveField(state.action),
});
