import React, { useState } from 'react';
import CopyCode from '~/components/atom/CopyCode';
import { Variant, Label, Body } from '~/components/atom/Typography';
import { widgetScript, type Args } from '~/components/page/Widget/constants';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TEST_ID from './index.testid';
import ToggleCheckbox from '../ToggleCheckbox';
import JustificationContainer from '~/components/atom/JustificationContainer';
import withIconTooltip from '~/hocs/withIconTooltip';
import {
  WIDGET_PREVENT_DEFAULT_OPENING,
  getLocalStorageItem,
  setLocalStorageItem,
} from '~/util/localStorageKeys';
import Link from '~/components/molecule/Link';

const text = {
  copyCode: 'Plaats deze code op je website',
  checkboxLabel: 'Widget verbergen nadat deze is geladen',
  widgetScriptUpdatedText:
    'De widget code is aangepast. Plaats onderstaande code op je website om de wijzigingen toe te passen.',
  tooltipMessage: (
    <>
      Als deze optie aanstaat, dan wordt de widget standaard niet getoond,
      tenzij deze direct wordt aangeroepen door een URL of het &quot;open&quot;
      commando.
      <br />
      <Link
        style={{ color: '#ffff' }}
        to="https://help.dathuis.nl/nl/articles/6133047-widget-installatie-en-integratie"
        target="_blank"
      >
        Meer in ons kenniscentrum.
      </Link>
    </>
  ),
};

type Props = {
  customWidgetCode?: ({ accountId, preventDefaultOpening }: Args) => string;
  label?: string;
};

const WidgetScript: React.FCC<Props> = ({
  customWidgetCode,
  label = text.copyCode,
}) => {
  const { id: accountId } = useCurrentAccount();
  const [valueHasChanged, setHasChanged] = useState<boolean>(false);

  const localStorageValue = getLocalStorageItem(WIDGET_PREVENT_DEFAULT_OPENING);
  const defaultValue =
    localStorageValue !== undefined && localStorageValue !== null
      ? JSON.parse(localStorageValue)
      : false;
  const [preventDefaultOpening, setPreventDefaultOpening] =
    useState<boolean>(defaultValue);

  return (
    <>
      <JustificationContainer
        width="100%"
        padding={['s']}
        border={{ radius: 'base' }}
        backgroundColor={{ group: 'primary', variant: 'translucent' }}
        margin={['xxs', null]}
        direction="column"
      >
        <JustificationContainer
          width="100%"
          gap="s"
          align="center"
          justification="space-between"
        >
          <LabelWithTooltip
            showOnClick
            containerProps={{ gap: 'xxxs' }}
            margin={[null]}
            fontWeight="semiBold"
            variant={Variant.primary}
            size="base"
            tooltipMessage={text.tooltipMessage}
          >
            {text.checkboxLabel}
          </LabelWithTooltip>
          <ToggleCheckbox
            size="small"
            value={preventDefaultOpening}
            onChange={() => {
              setPreventDefaultOpening(prev => !prev);
              setLocalStorageItem(
                WIDGET_PREVENT_DEFAULT_OPENING,
                JSON.stringify(!preventDefaultOpening),
              );
              setHasChanged(true);
            }}
          />
        </JustificationContainer>
        {valueHasChanged && (
          <Body
            margin={[null]}
            size="base"
            color={{ group: 'warning', variant: 'dark' }}
          >
            {text.widgetScriptUpdatedText}
          </Body>
        )}
      </JustificationContainer>

      <CopyCode
        dataTestId={TEST_ID.CODE_SNIPPET}
        label={
          <Label
            margin={[null]}
            fontWeight="semiBold"
            variant={Variant.primary}
            size="m"
          >
            {label}
          </Label>
        }
        code={
          customWidgetCode
            ? customWidgetCode({ accountId, preventDefaultOpening })
            : widgetScript({ accountId, preventDefaultOpening })
        }
      />
    </>
  );
};

const LabelWithTooltip = withIconTooltip(Label);

export default WidgetScript;
