import React from 'react';

type Props = {
  name?: string | null | undefined;
  email: string;
};
const NamedEmailLabel: React.FCC<Props> = ({ name, email }) => {
  if (name) {
    return <span>{`${name} (${email})`}</span>;
  } else {
    return <span>{email}</span>;
  }
};

export default NamedEmailLabel;
