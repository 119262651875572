import type { ContactHasBBWaardecheckAppraisalRequestEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

const asHasBBWaardecheckAppraisalRequestEventFilterInput = (
  filter: ContactHasBBWaardecheckAppraisalRequestEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => ({
  App: {
    BBWaardecheck: {
      AppraisalRequest: {
        has: asHasFilterInput(filter.has),
      },
    },
  },
});

export default asHasBBWaardecheckAppraisalRequestEventFilterInput;
