import { Auth as _Auth, Amplify, Storage as _Storage } from 'aws-amplify';
import { getAmplifyConfig } from './getConfig';

let Auth = _Auth;
let Storage = _Storage;

export enum AUTH_TYPE {
  NONE = 'NONE',
  API_KEY = 'API_KEY',
  AWS_IAM = 'AWS_IAM',
  AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS',
  OPENID_CONNECT = 'OPENID_CONNECT',
}

// @ts-ignore
if (global.window?.useDHMockAuth === true) {
  // Fake authentication
  Auth = require('./__tests__/FakeAuth').default;
  Storage = require('./__tests__/FakeStorage').default;
} else {
  Amplify.configure(
    getAmplifyConfig(
      process.env.API_ENDPOINT,
      AUTH_TYPE.AWS_IAM,
      process.env.AWS_COGNITO_IDENTITYPOOL_ID,
      process.env.AWS_COGNITO_USERPOOL_ID,
      process.env.AWS_COGNITO_USERPOOL_WEBCLIENT_ID,
      process.env.AWS_REGION,
      process.env.AWS_STORAGE_BUCKET,
    ),
  );

  Storage.configure({ level: 'protected' });
}

export default {
  Auth,
  Storage,
};
