import { ibanErrors } from '../..';
import mod97 from '../mod97';
import validateIbanISO from '../validateIbanISO';

/**
 * Validates the iban and returns either an error or a bankCode
 * @param {string} input
 * keywords: iban, bankCode, bank code, ibanError
 */

export type ReturnType = {
  error: keyof typeof ibanErrors | null;
  bankCode?: string;
};

// We only support Dutch banks for now
const nlIbanRegex = /^([A-Z]{2})(\d{2})([A-Z]{4})([0-9]{10})$/;

const getIbanValidationOutput = (
  input: string | null | undefined,
): ReturnType => {
  if (!input) return { error: null };

  const iban = input.replace(/(\s+)/g, '');

  // The output of the code is an array of:
  // code[0] - full IBAN, code[1] - country code, code[2] - checksum digits,
  // code[3] - bank code, code[4] - account number
  const code = iban.match(nlIbanRegex);

  if (!code) {
    return { error: validateIbanISO(iban) };
  }

  const toDigit = letter => (letter.charCodeAt(0) - 55).toString();
  const digits = (code[3] + code[4] + code[1] + code[2]).replace(
    /[A-Z]/g,
    toDigit,
  );

  if (mod97(digits) !== 1) {
    return { error: 'invalid' };
  }

  return { bankCode: code[3], error: null };
};

export default getIbanValidationOutput;
