import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Container as TooltipContainer } from '../molecule/Tooltip';
import { tooltipLayerState } from '~/hooks/useTooltipLayer';
import { useRecoilState } from 'recoil';
import { debounce } from 'lodash';
import styled, { css } from 'styled-components';
import type { SizeMap, Theme } from '~/theme';

/**
 * Space to keep the tooltip slightly away from the edge
 */
const EXTRA_SPACE = 30;

/** Height of the triangle */
const POINTER_HEIGHT = 20;

/** Width of the triangle */
const POINTER_WIDTH = 20;

type PointerPosition = 'top' | 'bottom';

export type Props = {};

const TooltipLayer: React.FCC<Props> = ({}) => {
  const pointerPosition = useRef<PointerPosition>('top');

  const [transform, setTransform] = useState<{ x: number; y: number }>({
    x: -100,
    y: -100,
  });

  const [state, setState] = useRecoilState(tooltipLayerState);

  const tooltipRef = useRef<HTMLDivElement>(null);

  const { clientWidth, clientHeight } = document.documentElement || {};
  const windowWidth = global.window.innerWidth || clientWidth;
  const windowHeight = global.window.innerHeight || clientHeight;

  const recalculate = useCallback(
    ({ mousePosition }) => {
      if (!tooltipRef.current) return;

      pointerPosition.current = 'top';

      const initial = mousePosition;

      const { width, height } = tooltipRef.current.getBoundingClientRect();

      let newX = initial.x - width / 2;
      let newY = initial.y + POINTER_HEIGHT;

      // Ensure the div stays within the window boundaries
      // Check horizontal boundaries
      if (newX < 0) {
        newX = 0;
      } else if (mousePosition.clientX + width > windowWidth) {
        newX = windowWidth - width - POINTER_WIDTH / 2;
      }

      // Check vertical boundaries
      if (newY < 0) {
        newY = 0;
      } else if (mousePosition.clientY + height > windowHeight - EXTRA_SPACE) {
        // Move it above the cursor
        newY = initial.y - height - EXTRA_SPACE;
        pointerPosition.current = 'bottom';
      }

      setTransform({ x: newX, y: newY });
    },
    [windowWidth, windowHeight],
  );

  useLayoutEffect(() => {
    if (state.mousePosition)
      recalculate({ mousePosition: state.mousePosition });
  }, [recalculate, state.mousePosition]);

  if (!state) return null;
  const { mousePosition, tooltip } = state;

  // If the tooltip is stuck for some reason, this will allow us to remove it
  const onMouseLeave = debounce(() => {
    setState({ mousePosition: null, tooltip: null });
  }, 100);

  return (
    <>
      {tooltip && mousePosition && (
        <StyledTooltipContainer
          ref={tooltipRef}
          $translateX={transform.x}
          $translateY={transform.y}
          $padding={['base']}
          onMouseLeave={onMouseLeave}
          $pointer={{
            offsetX: mousePosition.x - transform.x,
            position: pointerPosition.current,
            size: 'base',
          }}
        >
          {tooltip.message}
        </StyledTooltipContainer>
      )}
    </>
  );
};

const StyledTooltipContainer = styled(TooltipContainer)<{
  $pointer: { position: PointerPosition; offsetX: number; size: 'base' };
}>(
  ({ theme, $pointer }) => css`
    // Must be the highest element on the page at all times
    z-index: ${theme.z('tooltipLayer')};
    max-width: 50vw;

    &::after {
      content: '';
      position: absolute;
      display: block;
      left: ${$pointer.offsetX}px;
      z-index: -1;

      width: ${theme.space($pointer.size)};
      height: ${theme.space($pointer.size)};
      background-color: ${theme.color('secondary')};
      transform: translate3d(-50%, 0, 0px) rotate(45deg);
      pointer-events: none;

      ${getPointerPosition({
        theme,
        position: $pointer.position,
        size: $pointer.size,
      })}
    }

    @media (max-width: ${theme.bp('mobile')}) {
      max-width: 80vw;
    }
  `,
);

const getPointerPosition = ({
  theme,
  position,
  size,
}: {
  theme: Theme;
  position: PointerPosition;
  size: keyof SizeMap;
}) => {
  if (position === 'top') {
    return css`
      top: calc(-${theme.space(size)} / 2);
    `;
  }
  if (position === 'bottom') {
    return css`
      bottom: calc(-${theme.space(size)} / 2);
    `;
  }

  return css``;
};

export default TooltipLayer;
