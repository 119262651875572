import { isValidURL } from '~/util/Validation/URL';

/** Starts with mailto: or tel: and is not empty after the colon */
const MAILTO_TEL_REGEX = /^(mailto:|tel:)[^:]+$/;

/**
 * Returns true if the passed string is allowed as a link in the text editor
 * @param {string} str - string
 * keywords: link validation
 */
const isValidLink = (str: string): boolean =>
  isValidURL(str) || MAILTO_TEL_REGEX.test(str);

export default isValidLink;
