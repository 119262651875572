import React from 'react';
import Link from '~/components/molecule/Link';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import TEST_ID from './index.testid';

export type Props = {};

const text = {
  title: 'Overeenkomsten',
  description:
    'Hieronder vind je alle overeenkomsten die van kracht zijn op je huidige abonnement.',
  termsAndConditionsPdf: 'Algemene voorwaarden',
  processingAgreementPdf: '(Verwerkers)overeenkomst',
};

const Agreements: React.FCC<Props> = ({ dataTestId, ...rest }) => (
  <JustificationContainer data-testid={dataTestId} {...rest} direction="column">
    <Heading4 variant={Variant.primary}>{text.title}</Heading4>
    <Body size="base">{text.description}</Body>
    <JustificationContainer direction="column" gap="s">
      <Link
        to="https://dhstash.s3-eu-west-1.amazonaws.com/DatHuis+(verwerkers)overeenkomst+v20201125.pdf"
        target="_blank"
        download="DatHuis verwerkers overeenkomst.pdf"
        data-testid={TEST_ID.PDF_LINK_PROCESSING}
      >
        {text.processingAgreementPdf}
      </Link>
      <Link
        to="https://dhstash.s3-eu-west-1.amazonaws.com/Nederland+ICT+Voorwaarden+2014+-+NL.pdf"
        target="_blank"
        download="NederlandICTVoorwaarden.pdf"
        data-testid={TEST_ID.PDF_LINK}
      >
        {text.termsAndConditionsPdf}
      </Link>
    </JustificationContainer>
  </JustificationContainer>
);

export default Agreements;
