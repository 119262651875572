import type { User } from '~/hooks/useUserLookup';

/**
 * Returns a label for a user regardless of their status
 * @param {User} user - The user you need a label for
 * keywords:
 */
const getUserLabel = (user: User): string => {
  if (user.__typename === 'DeletedUser') return user.name;
  if (user.__typename === 'PendingUser') return user.email;

  return `${user.name}(${user.email})`;
};

export default getUserLabel;
