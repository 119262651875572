import React from 'react';
import styled, { css } from 'styled-components';
import Settings from './components/Settings';
import TEST_ID from './index.testid';
import DesignSettings from './components/DesignSettings';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import Issues from './components/Issues';
import Events from './components/Events';
import InAdvancedForm from '../InAdvancedForm';
import { useRecoilState } from 'recoil';
import { asideExpandedState, type SectionId } from '../../state/asideExplanded';
import WidgetSettings from './components/WidgetSettings';

export type Props = {
  dataTestId?: string;
  isFullScreen?: boolean;
};

const AsideContainer: React.FCC<Props> = ({
  dataTestId,
  isFullScreen = false,
  ...rest
}) => {
  const [expanded, setExpanded] = useRecoilState(asideExpandedState);

  const onChange = (sectionName: SectionId) => {
    setExpanded(prev => (prev === sectionName ? null : sectionName));
  };

  return (
    <Container data-testid={dataTestId} isFullScreen={isFullScreen} {...rest}>
      <Content>
        <Settings
          dataTestId={TEST_ID.SETTINGS_MENU}
          expanded={expanded === 'settings'}
          onToggleExpand={() => onChange('settings')}
        />
        <WidgetSettings
          dataTestId={TEST_ID.SETTINGS_MENU}
          expanded={expanded === 'widget-settings'}
          onToggleExpand={() => onChange('widget-settings')}
        />
        <DesignSettings
          expanded={expanded === 'design'}
          onToggleExpand={() => onChange('design')}
        />
        <InAdvancedForm>
          <Events
            expanded={expanded === 'events'}
            onToggleExpand={() => onChange('events')}
          />
        </InAdvancedForm>
        <Issues expanded={expanded === 'issues'} onToggleExpand={onChange} />
      </Content>
    </Container>
  );
};
const CONTAINER_WIDTH = 350;

const Container = styled.div<{ isFullScreen: boolean }>(
  ({ theme, isFullScreen }) => css`
    max-width: ${CONTAINER_WIDTH}px;
    background-color: ${theme.color('white')};
    position: absolute;
    margin-top: ${isFullScreen ? theme.space('xxl') : theme.space('base')};
    width: 100%;
    z-index: ${theme.z('footer') - 1};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('tertiary', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};
    overflow: hidden;
  `,
);

const Content = styled.div(
  ({ theme }) => css`
    height: 100%;
    max-height: 86vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ${scrollBarStyles}

    & > *:not(:last-child) {
      position: relative;
    }

    & > *:not(:last-child)::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: ${theme.space('m')};
      right: ${theme.space('m')};
      border-bottom: 0.5px solid ${theme.color('tertiary')};
      border-radius: ${theme.getTokens().border.radius.s};
    }
  `,
);

export default AsideContainer;
