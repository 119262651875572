import { title as ValueReportGeneralImageSettingsTitle } from './ValueReportGeneralImageSettings';
import { title as ValueReportColorSettingsTitle } from './ValueReportColorSettings';
import { WizardFlow } from '~/graphql/types.client';

const typename = 'AppStatus_ValuationReport';
const valueReportEnableSteps: WizardFlow = {
  id: 'enable-value-report',
  steps: [
    {
      title: ValueReportGeneralImageSettingsTitle,
      id: 'ValueReportGeneralImageSettings',
      metadata: { typename },
    },
    {
      title: ValueReportColorSettingsTitle,
      id: 'ValueReportColorSettings',
      metadata: { typename },
    },
  ],
};

export default valueReportEnableSteps;
