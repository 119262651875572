import React, { useEffect, useState } from 'react';

import {
  BillingCycle,
  PlanOptionFragment,
  useGetBillingDetailsQuery,
  useGetPlansQuery,
  useSignupMutation,
  useUpdatePlanMutation,
} from '~/graphql/types';

import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useCurrentUser from '~/hooks/useCurrentUser';
import useSignupParams from '~/hooks/useSignupParams';
import { Coupon } from '~/components/page/Settings/Subscription/utils/convertToCoupon';
import { isNil } from 'ramda';
import { animated, useTransition } from 'react-spring';
import getTapfillateRef from './utils/getTapfillateRef';
import useFireTrackingEvent from '~/hooks/useFireTrackingEvent';
import getStoredUTMParams from '~/util/getStoredUTMParams';
import getSignupId from './utils/getSignupId';
import StepComponent from './components/StepComponent';

type Props = {
  onComplete: () => void;
  onClose?: () => void;
};

export type StepId =
  | 'choosePlan'
  | 'planOverview'
  | 'setupPayment'
  | 'cancelSubscription';
export const SUBSCRIPTION_MODAL_WIDTH = '890px';

const ChangeOrUpdateSubscription: React.FC<Props> = ({
  onComplete,
  onClose,
}) => {
  const fireTrackingEvent = useFireTrackingEvent();
  const account = useCurrentAccount();
  const me = useCurrentUser();

  const {
    data: billingDetailsData,
    loading: loadingBillingDetails,
    refetch: refetchBillingDetails,
  } = useGetBillingDetailsQuery({
    variables: {
      accountId: account.id,
    },
  });

  const {
    data,
    loading: loadingPlans,
    refetch: refetchPlans,
  } = useGetPlansQuery({
    variables: {
      accountId: account.id,
      signupId: getSignupId(),
    },
  });
  const refetchAll = () => Promise.all([refetchPlans, refetchBillingDetails]);

  const { couponCode } = useSignupParams();
  const [signupMutation, { error: signupError, loading: loadingSignup }] =
    useSignupMutation();
  const [updatePlanMutation, { error: updateError, loading: loadingUpdate }] =
    useUpdatePlanMutation();

  const loading = loadingSignup || loadingUpdate;
  const error = signupError || updateError;

  const [updateData, setUpdateData] = useState<{
    plan: null | PlanOptionFragment;
    billingCycle: BillingCycle;
    coupon: null | Coupon;
  }>({
    plan: null,
    billingCycle: BillingCycle.Yearly,
    /* Initial coupon will be set later by PlanOverviewStep/CouponInput component if couponCode is a valid coupon.
     * We are doing this because
     *   1 - The data is flowing both ways in the existing design. (Which is not ideal)
     *   2 - Separate the coupon logic and contain it in CouponInput
     * We will revisit this when we work on the setup wizard.
     */
    coupon: null,
  });

  useEffect(() => {
    if (updateData.plan === null) {
      const currentPlanOption = data?.getPlans.available.find(
        ({ id }) => id === data.getPlans.current?.id,
      );

      const currentCycle =
        data?.getPlans.current?.billingCycle ?? BillingCycle.Yearly;

      setUpdateData(prev => ({
        ...prev,
        billingCycle: currentCycle,
        plan: currentPlanOption ?? null,
      }));
    }
  }, [data, updateData.plan]);

  const [billingInfo, setBillingInfo] = useState<{
    name: string | null;
    email: string | null;
  }>({
    name: me.name,
    email: me.email,
  });

  const [currentStep, setCurrentStep] = useState<StepId>('choosePlan');
  const transitions = useTransition(currentStep, {
    from: { opacity: 0, transform: 'translate3d(-40px,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0px,0,0)' },
    leave: {
      opacity: 0,
      transform: 'translate3d(-40px,0,0)',
      position: 'absolute',
    },
  });

  const onConfirm = async ({
    planId,
    paymentId,
    billingCycle,
    coupon,
    shouldUseUpdate = false,
  }: {
    planId: string;
    paymentId?: string;
    billingCycle: BillingCycle;
    coupon: null | Coupon;
    shouldUseUpdate?: boolean;
  }) => {
    const mutation = shouldUseUpdate ? updatePlanMutation : signupMutation;
    const referralCode = getTapfillateRef();

    return mutation({
      variables: {
        accountId: account.id,
        paymentId,
        planId,
        billingCycle,
        coupon: coupon?.id ?? undefined,
        referralCode: shouldUseUpdate ? undefined : referralCode,
        signupId: getSignupId(),
      },
    }).then(result => {
      if (!isNil(result.data)) {
        void refetchAll();

        // First time selecting a plan means signup
        // this is when we fire our conversion event
        if (!shouldUseUpdate) {
          const utm = getStoredUTMParams();

          fireTrackingEvent({
            event: 'completeRegistration',
            planId,
            ...utm,
          });
        }

        // if successful
        onComplete();
      }
    });
  };

  return (
    <Overlay onClose={onClose}>
      <Modal maxWidth={SUBSCRIPTION_MODAL_WIDTH} onClose={onComplete}>
        {transitions(
          (style, item, { key }) =>
            item === currentStep && (
              <animated.div key={key} style={style}>
                <StepComponent
                  billingDetailsData={billingDetailsData}
                  billingInfo={billingInfo}
                  couponCode={couponCode}
                  currentStep={currentStep}
                  data={data}
                  loading={loadingPlans || loadingBillingDetails}
                  onClose={onClose}
                  onComplete={onComplete}
                  onConfirm={onConfirm}
                  setBillingInfo={setBillingInfo}
                  setCurrentStep={setCurrentStep}
                  setUpdateData={setUpdateData}
                  loadingMutation={loading}
                  mutationError={error}
                  updateData={updateData}
                />
              </animated.div>
            ),
        )}
      </Modal>
    </Overlay>
  );
};

export default ChangeOrUpdateSubscription;
