import { isNonEmptyString } from '~/util/Validation/String';
import type { Issue } from '../../../../state/issues';
import type {
  FormBuilder_Locale,
  FormBuilder_NodeFragment,
  FormBuilder_OptionListFragment,
  FormBuilder_ScreenNode_BlockFragment,
} from '~/graphql/types';
import { isNil } from 'ramda';

/**
 * Returns issues for options list
 */
const getIssuesForOptionsList = ({
  optionsList,
  node,
  block,
  availableLocale,
  messages,
}: {
  optionsList?: FormBuilder_OptionListFragment;
  node: FormBuilder_NodeFragment;
  block: FormBuilder_ScreenNode_BlockFragment;
  availableLocale: Array<FormBuilder_Locale>;
  messages: { [key: string]: any };
}): Array<Issue> => {
  if (!optionsList) {
    return [
      {
        level: 'error',
        message: messages.missingOptions,
        nodeId: node.id,
        blockKey: block.key,
      } as Issue,
    ];
  }

  if (optionsList.options.length === 0) {
    return [
      {
        level: 'error',
        message: messages.missingOptions,
        nodeId: node.id,
        blockKey: block.key,
      } as Issue,
    ];
  }

  if (optionsList.options) {
    return optionsList.options.flatMap(option =>
      availableLocale
        .map((locale): Issue | null => {
          if (isNonEmptyString(option.label[locale.toLowerCase()])) {
            return null;
          }

          return {
            level: 'error',
            message: messages.missingLabelForLocale(locale),
            nodeId: node.id,
            blockKey: block.key,
            localeKey: locale,
            optionKey: option.key,
          } as Issue;
        })
        .filter((item): item is Issue => !isNil(item)),
    );
  }

  return [];
};

export default getIssuesForOptionsList;
