import { flatten, isNil, prop, uniqBy, values } from 'ramda';
import generateActivityV2 from '../generateActivityV2';
import { GroupedActivities } from '~/state/activities';
import { ActivityFieldsFragment } from '~/graphql/types';
import { QueriedActivity, ActivityV2 } from '../../types';

/**
 *
 * Returns a new list of activities, checks for the latest version of the newActivity
 * and adds it to the list (replaces it if necessary)
 *
 * @param {GroupedActivities} prevActivities - Activities
 * @param {ActivityFieldsFragment} newActivity - New activity inserted on the backend
 * keywords: last activities
 */
const getLatestActivities = ({
  prevActivities,
  newActivity,
}: {
  prevActivities: GroupedActivities;
  newActivity: ActivityFieldsFragment;
}): Array<ActivityV2> => {
  const activities = flatten(values({ ...prevActivities }));

  const previousActivity = activities.filter(
    activity => activity?.id === newActivity.id,
  );

  // get the latest version of the activity
  const sameActivities = [...previousActivity, newActivity];
  const latestVersion = sameActivities
    .filter((activity): activity is ActivityV2 => !isNil(activity))
    .sort((a, b) => b._v - a._v)[0];

  // if it's still v1 take the last passed activity (in backend shape) so it can be converted into v2 shape
  const _newActivity =
    latestVersion?._v === newActivity._v ? newActivity : latestVersion;

  const newActivities = [
    ...activities.filter(item => item?.id !== newActivity.id),
    generateActivityV2(_newActivity as QueriedActivity),
  ].filter((activity): activity is ActivityV2 => !isNil(activity));

  return uniqBy(prop('id'), newActivities);
};
export default getLatestActivities;
