import { title as connectTitle } from './AddContaqtKoppeling';
import { WizardFlow } from '~/graphql/types.client';

const typename = 'AppStatus_Contaqt';
const contaqtEnablementFlow: WizardFlow = {
  id: 'enable-contaqt',
  steps: [
    {
      title: connectTitle,
      id: 'AddContaqtKoppeling',
      metadata: { typename },
    },
  ],
};

export default contaqtEnablementFlow;
