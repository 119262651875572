import React from 'react';
import { Form, Field, EmailField } from '~/components/organism/Forms';
import FormUtils from '~/components/organism/FormUtils';
import Button from '~/components/atom/Button';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';
import type { FormData } from '../../index';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body } from '~/components/atom/Typography';
import usePasswordValidation, {
  type PasswordArgs,
  type PasswordErrors,
} from '~/hooks/usePasswordValidation';
import Input from '~/components/molecule/Input';
import PasswordInputGroup from '../../../components/PasswordInputGroup';

type Props = {
  onFormSubmit: (data: FormData) => void;
  initialValues: FormData;
  loading: boolean;
  disabled: boolean;
};
const defaultValues: FormData = {
  name: null,
  email: null,
  password: null,
  passwordRepeat: null,
  phone: null,
};

const text = {
  emailLabel: Catalog.emailLabel,
  nameLabel: 'Naam',
  passwordLabel: 'Wachtwoord',
  passwordRepeatLabel: 'Wachtwoord bevestigen',
  updateAccountButton: 'Opslaan',
  noName: 'Voer een naam in',
  phoneLabel: Catalog.phoneLabel,
  noPhone: Catalog.noPhone,
  invalidPhone: Catalog.invalidPhone,
  noPassword: 'Voer een wachtwoord in',
  noPasswordRepeat: 'Voer je wachtwoord nogmaals in',
  passwordsDoNotMatch: 'De wachtwoorden moeten overeenkomen',
  invalidPassword: 'Het wachtwoord voldoet niet aan de regels',
};
const SetupUserDetailsForm = (props: Props) => {
  const { onFormSubmit, initialValues, loading, disabled } = props;

  const { validatePassword, passwordConditions } = usePasswordValidation();

  return (
    <Form
      initialValues={{ ...defaultValues, ...initialValues }}
      onSubmit={onFormSubmit}
      validate={(fields: FormData) =>
        validate(fields, () =>
          validatePassword({
            password: fields.password,
            passwordRepeat: fields.passwordRepeat,
          }),
        )
      }
    >
      {({ handleSubmit, submitError, submitting, pristine }) => {
        const disabledInput = submitting || disabled || loading;

        return (
          <form
            id="setup-user"
            onSubmit={handleSubmit}
            data-testid="setup-user-form"
          >
            {submitError ? (
              <Body
                size="base"
                margin={[null]}
                color={{ group: 'danger', variant: 'light' }}
                data-testid="setup-submit-error-message"
              >
                {submitError}
              </Body>
            ) : null}

            <JustificationContainer margin={['m', null]}>
              <EmailField name="email">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    {...input}
                    width="100%"
                    size="large"
                    label={{ text: text.emailLabel, color: { group: 'white' } }}
                    disabled={true}
                    externalErrors={
                      error && touched
                        ? [FormUtils.showError(error, touched)]
                        : undefined
                    }
                  />
                )}
              </EmailField>
            </JustificationContainer>

            <JustificationContainer margin={['m', null]}>
              <Field name="name">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    {...input}
                    autoFocus
                    size="large"
                    width="100%"
                    label={{ text: text.nameLabel, color: { group: 'white' } }}
                    disabled={disabledInput}
                    externalErrors={
                      error && touched
                        ? [FormUtils.showError(error, touched)]
                        : undefined
                    }
                  />
                )}
              </Field>
            </JustificationContainer>
            <JustificationContainer margin={['m', null]}>
              <Field name="phone">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    {...input}
                    size="large"
                    width="100%"
                    label={{ text: text.phoneLabel, color: { group: 'white' } }}
                    disabled={disabledInput}
                    externalErrors={
                      error && touched
                        ? [FormUtils.showError(error, touched)]
                        : undefined
                    }
                  />
                )}
              </Field>
            </JustificationContainer>
            <PasswordInputGroup
              passwordText={text.passwordLabel}
              repeatText={text.passwordRepeatLabel}
              submitting={submitting}
              passwordConditions={passwordConditions}
              disabled={disabledInput}
              labelColor={{ group: 'white' }}
            />
            <JustificationContainer align="center" justification="end">
              <Button
                appearance="secondary"
                size="medium"
                data-testid="submit-setup-user-details"
                loading={loading}
                type="submit"
                disabled={disabledInput || pristine || loading}
                label={text.updateAccountButton}
              />
            </JustificationContainer>
          </form>
        );
      }}
    </Form>
  );
};

const validate = (
  { name, phone, password, passwordRepeat }: FormData,
  onValidatePassword: (args: PasswordArgs) => PasswordErrors,
) => {
  const errors: {
    name: string | undefined;
    phone: string | undefined;
    password: string | undefined;
    passwordRepeat: string | undefined;
  } = {
    name: undefined,
    phone: undefined,
    password: undefined,
    passwordRepeat: undefined,
  };
  if (Validation.String.isEmpty(name)) {
    errors.name = text.noName;
  }

  if (Validation.String.isEmpty(phone)) {
    errors.phone = text.noPhone;
  }

  if (!Validation.Phone.isValid(phone)) {
    errors.phone = text.invalidPhone;
  }

  const passwordErrors = onValidatePassword({ password, passwordRepeat });

  return { ...errors, ...passwordErrors };
};

export default SetupUserDetailsForm;
