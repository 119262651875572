import { atom, selector } from 'recoil';

export type FlowSettings = {
  enabled: boolean;
  maximumFlowRun: number | null;
  flowName: string;
  flowDescription: string | null;
};
const flowSettings = atom<FlowSettings>({
  key: 'flowSettings',
  default: {
    enabled: false,
    maximumFlowRun: null,
    flowName: '',
    flowDescription: null,
  },
});

export default flowSettings;

export const flowNameSelector = selector({
  key: 'flowNameSelector',
  get: ({ get }) => {
    const { flowName } = get(flowSettings);
    return flowName;
  },
});
