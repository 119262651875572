import cleanedFilename from '~/util/cleanedFilename';

export default {
  COMPLETED_AMOUNT_LABEL: `${cleanedFilename(
    __filename,
  )}-completed-amount-label`,
  NON_COMPLETED_AMOUNT_LABEL: `${cleanedFilename(
    __filename,
  )}-non-completed-amount-label`,
  PROGRESS_BAR: `${cleanedFilename(__filename)}-progress-bar`,
};
