import { UserRole } from '~/graphql/types';

export type Category =
  | 'Apps'
  | 'Users'
  | 'Offices'
  | 'Office'
  | 'Invoices'
  | 'Subscription'
  | 'Wallet';

type Entity = 'office' | 'account';
export type Action = 'create' | 'view' | 'update' | 'delete';

type AuthorizationMatrix = {
  [c in Category]: {
    [a in Action]: {
      [e in Entity]: {
        [r in UserRole]: boolean;
      };
    };
  };
};

const onlyAccountAdmin = {
  account: { Admin: true, User: false },
  office: { Admin: false, User: false },
};

const bothAccountAndOfficeAdmin = {
  account: { Admin: true, User: false },
  office: { Admin: true, User: false },
};

const allUsers = {
  account: { Admin: true, User: true },
  office: { Admin: true, User: true },
};

const authorizationMatrix: AuthorizationMatrix = {
  Offices: {
    update: bothAccountAndOfficeAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },
  Office: {
    update: bothAccountAndOfficeAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },
  Users: {
    update: onlyAccountAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },
  Apps: {
    update: onlyAccountAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },

  // Settings
  Invoices: {
    update: onlyAccountAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: onlyAccountAdmin,
  },
  Subscription: {
    update: onlyAccountAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: onlyAccountAdmin,
  },
  Wallet: {
    update: onlyAccountAdmin,
    create: onlyAccountAdmin,
    delete: onlyAccountAdmin,
    view: allUsers,
  },
};

export default authorizationMatrix;
