import formatIBAN from '../formatIBAN';

const forceIBANFormat = (
  event: React.ChangeEvent<HTMLInputElement>,
): string => {
  const countSpaces = value => {
    if (!value) return 0;

    const spaces = value.match(/(\s+)/g);
    return spaces ? spaces.length : 0;
  };

  const position =
    event.target.selectionEnd === null
      ? event.target.value.length
      : event.target.selectionEnd;
  const previousValue = event.target.value;
  event.target.value = formatIBAN(event.target.value) ?? '';

  if (position !== event.target.value.length) {
    const beforeCaret = previousValue.slice(0, position);
    const countPrevious = countSpaces(beforeCaret);
    const countCurrent = countSpaces(formatIBAN(beforeCaret));
    event.target.selectionEnd = position + (countCurrent - countPrevious);
  }

  return event.target.value;
};

export default forceIBANFormat;
