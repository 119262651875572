import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

export type Props = {};

const Loading: React.FCC<Props> = ({ dataTestId, ...rest }) => (
  <Container data-testid={dataTestId} {...rest}>
    <ContentLoader
      speed={0.5}
      width="100%"
      height={300}
      viewBox="0 0 380 200"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect y="0" rx="3" ry="3" x="0" width="100%" height="100%" />
    </ContentLoader>
  </Container>
);

const Container = styled.div<{}>`
  width: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
`;

export default Loading;
