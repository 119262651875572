import React, { type CSSProperties } from 'react';
import styled, { useTheme } from 'styled-components';
import illustrations from './components';
import type { SystemSize } from '~/theme';
import type { DefaultTheme } from 'styled-components/dist/types';

export type IllustrationType = keyof typeof illustrations;

export type Props = React.HTMLAttributes<HTMLSpanElement> & {
  name: IllustrationType;
  color?: string;
  secondaryColor?: string;
  strokeWidth?: number | string;
  className?: string;
  skewed?: boolean;

  size?: SystemSize;
};

const Illustration: React.FCC<Props> = ({
  name,
  color,
  secondaryColor,
  skewed = false,
  ...props
}) => {
  const theme = useTheme();
  const PickedIllustration = illustrations[name];

  return (
    <Container {...props}>
      <PickedIllustration
        color={color || 'currentColor'}
        strokeWidth={props.strokeWidth || 2}
        secondaryColor={secondaryColor}
        style={getStyleForProps({ name, skewed, ...props }, theme)}
      />
    </Container>
  );
};

const getStyleForProps = (
  { skewed, size, style }: Props,
  theme: DefaultTheme,
): CSSProperties => {
  const calculated: CSSProperties = { ...style };
  if (skewed) {
    calculated.transform = style?.transform
      ? // Keep other transforms and add the skew
        `${style.transform} skewY(-5deg)`
      : 'skewY(-5deg)';
  }

  if (size) {
    calculated.width = theme.space(size);
    calculated.height = theme.space(size);
  }

  return calculated;
};

const Container = styled.span<{}>``;

export default Illustration;
