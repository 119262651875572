import React from 'react';
import { FormSpy } from 'react-final-form';
import FooterSaveBar from '../FooterSaveBar';

type Props = { disableSave?: boolean };

const FormFooterSaveBar: React.FCC<Props> = ({ disableSave }) => (
  <FormSpy>
    {({ form }) => {
      const formState = form.getState();
      const dirtyFields: $Object | null | undefined = formState.dirtyFields;
      const numberOfChanges = dirtyFields ? Object.keys(dirtyFields).length : 0;

      if (numberOfChanges === 0) return null;

      return (
        <FooterSaveBar
          disableSave={!!formState.submitting || !!disableSave}
          numberOfChanges={numberOfChanges}
          onSave={() => {
            void form.submit();
          }}
          onCancel={() => {
            // https://github.com/final-form/final-form/issues/151
            form.setConfig('keepDirtyOnReinitialize', false);
            form.reset();
            form.setConfig('keepDirtyOnReinitialize', true);
          }}
        />
      );
    }}
  </FormSpy>
);

export default FormFooterSaveBar;
