import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OutputFieldPlain,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import useWizard from '~/hooks/useWizard';
import useWizardStep from '~/hooks/useWizardStep';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { useGetAccountQuery } from '~/graphql/types';
import ChooseEmailSyncType from '~/components/molecule/ChooseEmailSyncType';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/components/NylasAuthoriseEndpointV1/types';
import { StepId } from '../..';
import { isNil } from 'ramda';
import { Body } from '~/components/atom/Typography';
import SuccessDisplay from './components/SuccessDisplay';
import styled, { css } from 'styled-components';
import {
  id as updateSignatureStepId,
  title as signatureStepTitle,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/UpdateSignature';
import Loading from '~/components/atom/Loading';
import Link from '~/components/molecule/Link';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';

export const id = 'SynchronizeEmail';
export const title = 'Koppel je e-mail';

export type OutputType = {
  type: typeof id;
  synchronisedEmail: OutputFieldPlain<string | null>;
};

const text = {
  body: (
    <>
      De relatie met jouw klant centraal. Door je e-mailadres te koppelen, kan
      je via DatHuis persoonlijk communiceren met jouw relaties.
      <br />
      <br />
      Wil je meer weten?{' '}
      <Link
        target="_blank"
        to="https://help.dathuis.nl/nl/articles/3966638-koppel-een-e-mailadres"
      >
        Lees hier verder
      </Link>
      .
    </>
  ),
  tip: (
    <>
      Kom je er niet uit? &nbsp;
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
  loading: 'Gekoppelde e-mailadressen ophalen...',
  noMailbox: 'Er bestaan nog geen gekoppelde e-mailadressen',
  category: 'Gekoppelde e-mail',
};

export const Component: React.FCC<WizardStepProps> = ({ step }) => {
  const wizard = useWizard();
  const { id: accountId } = useCurrentAccount();
  const [isPolling, setIsPolling] = useState(false);

  const { data, loading, startPolling, stopPolling, refetch } =
    useGetAccountQuery({
      variables: { accountId },
    });

  const _stopPolling = () => {
    stopPolling();
    setIsPolling(false);
  };

  const mailbox = data && data.getAccount ? data.getAccount.Mailboxes[0] : null;

  useEffect(() => {
    const email = mailbox ? mailbox.email.email : null;
    if (!loading && !isPolling) {
      api.free({
        type: id,
        synchronisedEmail: {
          value: email,
          category: text.category,
          type: 'plain',
        },
      });
      api.freeGoBack();
    } else {
      api.lock({
        type: id,
        synchronisedEmail: {
          value: mailbox ? mailbox.email.email : '',
          category: text.category,
          type: 'plain',
        },
      });
      api.lockGoBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, mailbox, isPolling]);

  useEffect(() => {
    if (mailbox) _stopPolling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailbox]);

  const onBeforeNext = useCallback(
    async outputMap => {
      if (mailbox) {
        wizard.addStep({
          step: { id: updateSignatureStepId, title: signatureStepTitle },
          addAfter: id as StepId,
        });
      }

      return outputMap[id] as OutputType;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, mailbox],
  );

  const stepOptions = useMemo(() => ({ onBeforeNext }), [onBeforeNext]);

  const [, api] = useWizardStep(step.id, stepOptions);

  if (loading) {
    return <Loading />;
  }

  if (isNil(mailbox)) {
    return (
      <Wrapper>
        <ChooseEmailSyncType
          entityId={accountId}
          accountId={accountId}
          entityType={SynchroniseEntityType.Account}
          refetch={refetch}
          redirectPath="/auth/v2/"
          stateStr={encodeURIComponent(accountId)}
          startPolling={interval => {
            startPolling(interval);
            setIsPolling(true);
          }}
          stopPolling={() => _stopPolling()}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <SuccessDisplay email={mailbox.email.email} />
    </Wrapper>
  );
};

const Wrapper: React.FCC<{}> = ({ children }) => (
  <>
    <Body>{text.body}</Body>
    <TipBanner
      margin={[null, null, 'xxs', null]}
      width="100%"
      id="sync-email-step"
      dismissible={false}
    >
      {text.tip}
    </TipBanner>
    <ChildrenContainer>{children}</ChildrenContainer>
  </>
);

const ChildrenContainer = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('xs')} 0;
  `,
);

export default {
  id,
  title,
};
