/**
 * Slate adds new lines as <div></div> but email clients do not render this as an empty line.
 * Therefore we add the <br> tag inside of the empty divs when we are serializing editor values.
 * When we get the initial html back, we need to remove this <br> tag so that Slate can show the
 * amount of empty lines correctly.
 *
 * @param {string} html - html as string
 * keywords: line break, empty lines, slate, editor
 */
const removeLineBreaksFromEmptyLines = (html: string): string =>
  html.replace(REGEX, '<div></div>');

export default removeLineBreaksFromEmptyLines;

const REGEX = /(<div><br[\s\/]*><\/div>)/g;
