import React from 'react';
import DatePicker from '~/components/organism/DatePicker';
import type { Props as InputProps } from '~/components/molecule/Input';
import Field from './Field';
import { Label, Variant } from '~/components/atom/Typography';

/**
 * In the ecosystem of the forms we handle date as an ISO string.
 * This is also what is sent back to the server.
 */
type DateFieldProps = {
  name: string;
  value: string;
  onChange: (newDate: string) => void;
  error?: string;
  label?: string;
  inputComponentProps?: InputProps;
};
const DateField: React.FC<DateFieldProps> = ({ error, label, ...props }) => (
  <div>
    <Label
      size="base"
      margin={[null, null, 'xxxs', null]}
      variant={Variant.primary}
      color={{ group: error ? 'danger' : 'primary' }}
    >
      {error || label || ''}
    </Label>
    <Field {...props} component={DateFieldComponent} />
  </div>
);

const DateFieldComponent = ({
  input: { name, value, onChange },
  ...rest
}: any) => (
  <DatePicker
    name={name}
    size={10}
    value={value}
    onChange={onChange}
    {...rest}
  />
);

export default DateField;
