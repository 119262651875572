import { atom } from 'recoil';
import { ClientFlowAction } from '~/graphql/types.client';
import type { PathType } from '../components/Builder/components/nodeTypes/components/IfElseCard/types';

export type DialogConfirm = {
  type: 'dialogConfirm';
  onConfirm: () => void;
  onCancel: () => void;
  modalHeader: string;
  modalDescription: string;
};

export type DialogDeleteIfElseAction = {
  type: 'dialogDeleteIfElseAction';
  action: ClientFlowAction;
  onConfirm: (pathType: PathType | null) => void;
  onCancel: () => void;
};

export type DialogSelectIfElsePathOnPaste = {
  type: 'dialogSelectIfElsePathOnPaste';
  source: string;
  target: string;
  newAction: ClientFlowAction;
};

export type Dialog =
  | DialogConfirm
  | DialogSelectIfElsePathOnPaste
  | DialogDeleteIfElseAction;

const dialogs = atom<Dialog | null>({
  key: 'flowBuilderDialog',
  default: null,
});

export default dialogs;
