import getCommonWizardSteps from '~/components/organism/WizardSteps/utils/getCommonWizardSteps';
import getWizardFlow from '~/components/organism/WizardSteps/utils/getWizardFlow';
import { WizardFlow } from '~/graphql/types.client';
import { ExtendedAppConfig } from '~/hooks/useApps';

/**
 * Creates enablement flow for app
 * @param {ExtendedAppConfig} app
 * @param {boolean} hasFlows
 * keywords: enablementFlow, wizard
 */

const getEnablementFlow = (
  app: ExtendedAppConfig,
  hasFlows?: boolean,
): WizardFlow | undefined => {
  const { __typename } = app;
  const wizardFlow = getWizardFlow(app);
  const enablementFlow: WizardFlow | undefined = wizardFlow
    ? {
        ...wizardFlow,
        steps: [
          ...wizardFlow.steps,
          ...getCommonWizardSteps({
            typename: __typename,
            withFlows: hasFlows ?? false,
          }),
        ],
      }
    : undefined;

  return enablementFlow;
};

export default getEnablementFlow;
