import React, { useEffect, useState } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { OptionOf } from '~/components/molecule/Dropdown';
import Dropdown from '~/components/molecule/Dropdown';
import {
  type FormBuilder_ScreenNode_Block,
  FormBuilder_Ui_Alignment,
  FormData_TypographyType,
  type FormData_Ui_Typography,
} from '~/graphql/types';
import { values } from 'ramda';
import BlockLayout from '../BlockLayout';
import LocalesInput from '../LocalesInput';
import BlockAlignment from '../BlockAlignment';

const text = {
  selectTypography: 'Selecteer tekst-optie',
};

const typographyOptions: Array<OptionOf<FormData_TypographyType>> = values(
  FormData_TypographyType,
).map(option => ({
  key: option,
  label: option,
  payload: option,
}));

export type Props = {
  onChange: (block: FormBuilder_ScreenNode_Block) => void;
  nodeId: string;
  block: FormData_Ui_Typography;
};

const SelectTypography: React.FCC<Props> = ({ onChange, nodeId, block }) => {
  const [selectedTypography, setSelectedTypography] =
    useState<FormData_TypographyType | null>(block.type ?? null);

  useEffect(() => {
    if (selectedTypography) {
      onChange({
        ...block,
        key: block.key,
        type: selectedTypography,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypography]);

  return (
    <BlockLayout blockKey={block.key} nodeId={nodeId}>
      <JustificationContainer width="100%" direction="column" gap="m">
        <Dropdown
          selectedOptionIdx={typographyOptions.findIndex(
            ({ key }) => key === selectedTypography,
          )}
          label={text.selectTypography}
          options={typographyOptions}
          onChange={({ option }) => {
            setSelectedTypography(option.payload);
            onChange({ ...block, type: option.payload });
          }}
        />
        <BlockAlignment
          alignment={block.alignment ?? FormBuilder_Ui_Alignment.Left}
          onChange={alignment => {
            onChange({
              ...block,
              alignment,
            });
          }}
        />
        {selectedTypography && (
          <LocalesInput
            block={block}
            subject="content"
            onChange={onChange}
            blockKey={block.key}
            nodeId={nodeId}
          />
        )}
      </JustificationContainer>
    </BlockLayout>
  );
};

export default SelectTypography;
