import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import AsideHeader from '../AsideHeader';
import { animated, useSpring } from 'react-spring';
import { useRecoilState } from 'recoil';
import { Heading5, Variant } from '~/components/atom/Typography';
import TEST_ID from './index.testid';
import useMeasure from '~/hooks/useMeasure';
import ColorInput from '~/components/molecule/ColorInput';
import type {
  WidgetSettingsStyleConfigColorPair,
  WidgetSettingsStyles,
} from '~/graphql/types';
import { themeState as themeStateAtom } from '../../../../state/theme';
import useImageUpload from '~/hooks/useImageUpload';
import { layoutState as layoutStateAtom } from '../../../../state/layout';
import ImageUploadComponent from '~/components/organism/ImageUploadComponent';

type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const text = {
  heading: 'Ontwerp',
  colors: {
    primary: {
      color: 'Primaire tekstkleur',
      background: 'Primaire kleur',
    },
    secondary: {
      color: 'Secondaire tekstkleur',
      background: 'Secondaire kleur',
    },
  },
  backgroundColor: 'Achtergrond kleur',
  backgroundImage: 'Achtergrond afbeelding',
};

const DesignSettings: React.FCC<Props> = ({
  dataTestId,
  expanded = false,
  onToggleExpand,
}) => {
  const [themeState, setThemeState] = useRecoilState(themeStateAtom);
  const [layoutState, setLayoutState] = useRecoilState(layoutStateAtom);

  const { ref, bounds } = useMeasure();
  const theme = useTheme();

  const spring = useSpring({
    height: expanded ? bounds.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  const onColorChange = ({
    variant,
    key,
    value,
  }: {
    variant: keyof Omit<WidgetSettingsStyles, '__typename'>;
    key: keyof Omit<WidgetSettingsStyleConfigColorPair, '__typename'>;
    value: string;
  }) =>
    setThemeState(prev =>
      prev
        ? {
            ...prev,
            colors: {
              ...prev.colors,
              [variant]: {
                ...prev.colors[variant],
                [key]: value,
              },
            },
          }
        : prev,
    );

  const backgroundImage = useImageUpload({
    initialUrl: layoutState.backgroundImage?.url,
    s3Key: layoutState.backgroundImage?.s3key,
    onUpload: ({ s3Key, url }) => {
      setLayoutState(prev =>
        prev
          ? {
              ...prev,
              backgroundImage: { __typename: 'DHImage', s3key: s3Key, url },
            }
          : prev,
      );
    },
    onClear: () =>
      setLayoutState(prev =>
        prev
          ? {
              ...prev,
              backgroundImage: undefined,
            }
          : prev,
      ),
  });

  return (
    <Container data-testid={dataTestId}>
      <AsideHeader
        dataTestId={TEST_ID.ASIDE_HEADER}
        heading={text.heading}
        icon="layout"
        onClick={onToggleExpand}
        expanded={expanded}
      />
      <OverflowContainer style={spring}>
        <Inner ref={ref}>
          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.colors.primary.background}
            </Heading5>
            <ColorInput
              value={themeState.colors.primary.background}
              onChange={color =>
                onColorChange({
                  variant: 'primary',
                  key: 'background',
                  value: color,
                })
              }
            />
          </InputContainer>

          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.colors.primary.color}
            </Heading5>
            <ColorInput
              value={themeState.colors.primary.color}
              onChange={color =>
                onColorChange({
                  variant: 'primary',
                  key: 'color',
                  value: color,
                })
              }
            />
          </InputContainer>

          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.colors.secondary.background}
            </Heading5>
            <ColorInput
              value={themeState.colors.secondary.background}
              onChange={color =>
                onColorChange({
                  variant: 'secondary',
                  key: 'background',
                  value: color,
                })
              }
            />
          </InputContainer>

          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.colors.secondary.color}
            </Heading5>
            <ColorInput
              value={themeState.colors.secondary.color}
              onChange={color =>
                onColorChange({
                  variant: 'secondary',
                  key: 'color',
                  value: color,
                })
              }
            />
          </InputContainer>

          <InputContainer>
            <InputContainer>
              <Heading5 variant={Variant.primary}>
                {text.backgroundColor}
              </Heading5>
              <ColorInput
                value={layoutState.backgroundColor}
                onChange={color => {
                  setLayoutState(prev =>
                    prev ? { ...prev, backgroundColor: color } : prev,
                  );
                }}
              />
            </InputContainer>

            <Heading5 variant={Variant.primary}>
              {text.backgroundImage}
            </Heading5>
            <ImageUploadComponent
              onDelete={() => backgroundImage.clear()}
              uploading={backgroundImage.uploading}
              imageUrl={
                backgroundImage.s3Key != null ? backgroundImage.url : null
              }
              onUpload={file =>
                void backgroundImage.upload({
                  file: file[0],
                  filename: 'default_layout_background_image',
                })
              }
              direction="column"
            />
          </InputContainer>
        </Inner>
      </OverflowContainer>
    </Container>
  );
};

const Container = styled.aside(
  ({ theme }) => css`
    z-index: ${theme.z('top')};
    user-select: none;
    width: 100%;
  `,
);

const Inner = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
  `,
);

const InputContainer = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.space('xxs')};
  `,
);

const OverflowContainer = styled(animated.div)`
  overflow-x: hidden;
`;

export default DesignSettings;
