import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Footer from './components/Footer';
import TEST_ID from './index.testid';
import OverflowScrollWrapper, {
  scrollBarStyles,
} from '~/components/molecule/OverflowScrollWrapper';
import Navigation from '~/components/organism/Navigation';
import { SIDE_MIN_WIDTH } from '~/components/organism/Navigation/constants';
import Top from './components/Top';
import { CALCULATED_MIN_HEIGHT, TRIANGLE_HEIGHT } from './constants';

type Props = {
  children?: React.ReactNode;
  hidden?: boolean;
};

const NavigationFrame = forwardRef<HTMLDivElement, Props>(
  ({ children, hidden = false }, ref) => (
    <Container>
      {!hidden && (
        <>
          <Top />
          <Navigation />
        </>
      )}
      <Main ref={ref}>
        <ContentContainer data-testid={TEST_ID.PAGE_CONTAINER}>
          {children}
        </ContentContainer>
        <Footer />
      </Main>
    </Container>
  ),
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: ${SIDE_MIN_WIDTH};
`;

const Main = styled(
  forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
    (props, ref) => <OverflowScrollWrapper {...props} ref={ref} />,
  ),
)(
  () => css`
    padding-top: ${TRIANGLE_HEIGHT};
    height: 100vh;
    width: 100%;
    flex: 0 1 100%;
    ${scrollBarStyles}
  `,
);

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: ${CALCULATED_MIN_HEIGHT};
`;

export default NavigationFrame;
