import React from 'react';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay, {
  Props as OverlayProps,
} from '~/components/organism/ModalsV2/Overlay';

const withModalOverlay = <
  ComponentProps extends OverlayProps & { dataTestId?: string },
>(
  WrappedComponent: React.ComponentType<ComponentProps>,
  opts?: {
    maxWidth?: string;
  },
) => {
  const InModalOverlay: React.FC<ComponentProps> = props => (
    <Overlay
      onClose={() => {
        if (props.onClose) {
          props.onClose();
        } else if (props.onComplete) {
          props.onComplete();
        }
      }}
    >
      <Modal
        maxWidth={opts?.maxWidth ?? '890px'}
        onClose={props.onComplete}
        dataTestId={props.dataTestId}
      >
        <WrappedComponent {...props} />
      </Modal>
    </Overlay>
  );

  return InModalOverlay;
};

export default withModalOverlay;
