import { RenderElementProps } from 'slate-react';
import Image from './Image';
import Div from './DIVelement';
import BlockQuote from './BlockQuote';
import OrderedList from './OrderedList';
import UnorderedList from './UnorderedList';
import ListItem from './ListItem';
import LinkElement from './LinkElement';
import { CustomElement } from '../../types';
import Span from './Span';
import GenericHtmlElement from './GenericHtmlElement';
import VariableElement from './VariableElement';
import Paragraph from './Paragraph';
import ELEMENTS from './elementsEnum';
import SignatureElement from './SignatureElement';
import ImmutableHtmlElement from './ImmutableHtmlElement';
import HeadingOne from './HeadingOne';
import HeadingTwo from './HeadingTwo';
import HeadingThree from './HeadingThree';
import HeadingFour from './HeadingFour';
import HeadingFive from './HeadingFive';
import HeadingSix from './HeadingSix';

type ElementProps = {
  renderComponent: (props: RenderElementProps) => JSX.Element;
  nodeName?: string;
  serialize: (node?: CustomElement, children?: any) => string;
  deserialize?: (el?: Node) => void;
};

/**
 * To make sure that customElements are rendered inside the editor, make sure to pass
 * them to convertHtmlToSlateFragment
 *
 * If you are adding a custom node name like <dhvariable>, <dhsignature> make sure to add
 * them to the allow list in `src/components/PluginsEditor/constants.ts`. Otherwise they
 * might be removed when sanitizing the html
 */
const getElements = (customElements: Array<ELEMENTS> = []) => {
  const obj: {
    [key in ELEMENTS]?: ElementProps;
  } = {
    [ELEMENTS.BLOCKQUOTE]: BlockQuote,
    [ELEMENTS.DIV]: Div,
    [ELEMENTS.PARAGRAPH]: Paragraph,
    [ELEMENTS.GENERIC_HTML_ELEMENT]: GenericHtmlElement,
    [ELEMENTS.IMMUTABLE_HTML_ELEMENT]: ImmutableHtmlElement,
    [ELEMENTS.LI]: ListItem,
    [ELEMENTS.LINK]: LinkElement,
    [ELEMENTS.OL]: OrderedList,
    [ELEMENTS.UL]: UnorderedList,
    [ELEMENTS.SPAN]: Span,
  };

  /** Add custom elements that you want to render in the editor */
  if (customElements.length > 0) {
    customElements.forEach(elName => {
      switch (elName) {
        case ELEMENTS.VARIABLE:
          obj[ELEMENTS.VARIABLE] = VariableElement;
          break;
        case ELEMENTS.IMAGE:
          obj[ELEMENTS.IMAGE] = Image;
          break;
        case ELEMENTS.DH_IMAGE:
          obj[ELEMENTS.DH_IMAGE] = Image;
          break;
        case ELEMENTS.SIGNATURE:
          obj[ELEMENTS.SIGNATURE] = SignatureElement;
          break;
        case ELEMENTS.H1:
          obj[ELEMENTS.H1] = HeadingOne;
          break;
        case ELEMENTS.H2:
          obj[ELEMENTS.H2] = HeadingTwo;
          break;
        case ELEMENTS.H3:
          obj[ELEMENTS.H3] = HeadingThree;
          break;
        case ELEMENTS.H4:
          obj[ELEMENTS.H4] = HeadingFour;
          break;
        case ELEMENTS.H5:
          obj[ELEMENTS.H5] = HeadingFive;
          break;
        case ELEMENTS.H6:
          obj[ELEMENTS.H6] = HeadingSix;
          break;
        default:
          return;
      }
    });
  }

  return obj;
};

export default getElements;
