import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionContactAddTag } from '~/graphql/types.client';

/**
 *
 * @param {ClientFlowActionContactAddTag} action - ContactAddTag client action
 * keywords:
 */
const serializeContactAddTagAction = (
  action: ClientFlowActionContactAddTag,
): FlowV2_Action__Input => {
  const { id, accountId, flowBlueprintId, parentIds, name } = action;

  return {
    Contact: {
      AddTag: {
        id,
        accountId,
        flowBlueprintId,
        parentIds,
        name,
      },
    },
  };
};
export default serializeContactAddTagAction;
