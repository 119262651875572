import React, { useState } from 'react';

import { Heading1 } from '~/components/atom/Typography';
import type { EditProps } from '../..';
import {
  useUpdateAccountMutation,
  type FormBuilder_ScreenNode,
} from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Button from '~/components/atom/Button';
import Catalog from '~/Catalog';
import TextButton from '~/components/atom/TextButton';
import Input from '~/components/molecule/Input';
import TipBanner from '~/components/organism/TipBanner';
import { useRecoilState } from 'recoil';
import { interactionState } from '../../../../state';
import webSiteURLValidation from '~/util/websiteURLValidation';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

export type ScreenFormState = FormBuilder_ScreenNode;
export type Props = EditProps<ScreenFormState>;

const text = {
  header: 'Website URL opgeven',
  submitLabel: 'URL opslaan',
  inputLabel: 'Adres van je website',
  inputPlaceholder: 'bijv. https://dathuis.nl',

  tipHeader: 'Waarom is de website URL nodig?',
  tipBody: (
    <>
      Wanneer jij het formulier live wilt bekijken zullen wij deze openen in de
      Webwinkel widget op jou website. Zonder website kunnen wij dus geen
      voorbeeld laten zien.
      <br />
      <br />
      De website URL is altijd aan te passen via de Huisstijl instellingen.
    </>
  ),
  updateBrandSettingsURLSuccess: 'Website URL opgeslagen!',
};

const PromptWidgetURL: React.FC<Props> = ({ onCancel }) => {
  // Not using the passed onSave as that would trigger some undesired behavior
  const account = useCurrentAccount();
  const addToast = useAddToast();
  const [interaction, setInteraction] = useRecoilState(interactionState);
  const [updateAccount] = useUpdateAccountMutation();
  const [url, setURL] = useState('');

  const onSave = async () => {
    // Will always be true otherwise we wouldn't see this screen but Typescript doesn't know that
    if (interaction?.interactionType === 'prompt-url') {
      try {
        await updateAccount({
          variables: {
            accountId: account.id,
            update: {
              settings: {
                url,
              },
            },
          },
        });

        addToast([
          formatToastMessage(text.updateBrandSettingsURLSuccess, 'success'),
        ]);
        return setInteraction(null);
      } catch {
        return addToast([
          formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
        ]);
      }
    }
  };

  return (
    <JustificationContainer
      direction="column"
      gap="m"
      padding={['xl']}
      style={{ flexGrow: 1 }}
      width="100%"
    >
      <Heading1 margin={[null]}>{text.header}</Heading1>
      <TipBanner
        dismissible={false}
        headerText={text.tipHeader}
        id="name-screen-tip"
        margin={[null]}
      >
        {text.tipBody}
      </TipBanner>
      <Input
        width="100%"
        label={{ text: text.inputLabel }}
        placeholder={text.inputPlaceholder}
        value={url}
        validation={[webSiteURLValidation]}
        onChange={event => {
          setURL(event.target.value);
        }}
      />

      <JustificationContainer justification="space-between" width="100%">
        <TextButton
          label={Catalog.cancel}
          appearance="danger"
          onClick={onCancel}
        />
        <Button
          label={text.submitLabel}
          appearance="primary"
          onClick={onSave}
        />
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default PromptWidgetURL;
