import React, { useEffect } from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import TEST_ID from './index.testid';
import StatusPage from '../components/StatusPageWrapper';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { useGetSessionHydrationQuery } from '~/graphql/types';
import { isNil } from 'ramda';

export const text = {
  pageTitle: 'Account wordt gewijzigd',
  title: 'Account wordt gewijzigd',
  explanation:
    'Dit account wordt momenteel op een zodanige manier gewijzigd dat we geen interacties kunnen toestaan. Kom over een paar minuten terug om het account te gebruiken.',
};

const AccountModification: React.FCC<RouteComponentProps> = () => {
  const { id: accountId } = useCurrentAccount();
  const { data, startPolling, stopPolling } = useGetSessionHydrationQuery({
    variables: { accountId },
  });

  const ongoingModification =
    data?.getSessionHydration.account.ongoingAccountModification;

  useEffect(() => {
    if (!isNil(ongoingModification)) {
      startPolling(3000);
    } else {
      stopPolling();
    }

    return () => stopPolling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ongoingModification]);

  return (
    <StatusPage
      withAccountSwitcher={false}
      dataTestId={TEST_ID.CONTAINER}
      pageTitle={text.pageTitle}
      title={text.title}
      explanation={text.explanation}
    />
  );
};

export default AccountModification;
