import React from 'react';
import styled from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';

const AuthFormWrapper: React.FCC = ({ children }) => (
  <Container justification="center" direction="column" width="100%">
    {children}
  </Container>
);

const Container = styled(JustificationContainer)`
  flex: 1;
  margin: 0 auto;
  max-width: 550px;

  form {
    width: 100%;
  }
`;
export default AuthFormWrapper;
