import React from 'react';
import styled from 'styled-components';
import TEST_ID from './index.testid';
import Validation from '~/util/Validation';

export type PasswordConditions = {
  charNumberValid: boolean;
  uppercaseValid: boolean;
  lowercaseValid: boolean;
  specialCharValid: boolean;
  numberValid: boolean;
};

export type Props = { passwordConditions: PasswordConditions };

const PasswordRules: React.FCC<Props> = ({ passwordConditions }) => {
  const {
    charNumberValid,
    uppercaseValid,
    lowercaseValid,
    specialCharValid,
    numberValid,
  } = passwordConditions;

  const charNumberValidClass = charNumberValid ? 'valid' : 'invalid';
  const uppercaseValidClass = uppercaseValid ? 'valid' : 'invalid';
  const lowercaseValidClass = lowercaseValid ? 'valid' : 'invalid';
  const specialCharValidClass = specialCharValid ? 'valid' : 'invalid';
  const numberValidClass = numberValid ? 'valid' : 'invalid';
  return (
    <Container>
      Je wachtwoord moet minimaal bestaan{' '}
      <span
        id="charNumberHint"
        data-testid={TEST_ID.CHAR_NUMBERTOKENS_VALIDATION}
        className={charNumberValidClass}
      >
        uit {Validation.Password.MINIMUM_PASSWORD_LENGTH} tekens
      </span>
      ,{' '}
      <span
        id="upperCaseHint"
        data-testid={TEST_ID.CHAR_UPPERCASE_VALIDATION}
        className={uppercaseValidClass}
      >
        een hoofdletter
      </span>
      ,{' '}
      <span
        id="lowerCaseHint"
        data-testid={TEST_ID.CHAR_LOWERCASE_VALIDATION}
        className={lowercaseValidClass}
      >
        een kleine letter
      </span>
      ,{' '}
      <span
        id="numberHint"
        data-testid={TEST_ID.CHAR_NUMBER_VALIDATION}
        className={numberValidClass}
      >
        een nummer
      </span>{' '}
      en{' '}
      <span
        id="specialCharHint"
        data-testid={TEST_ID.CHAR_SPECIALCHAR_VALIDATION}
        className={specialCharValidClass}
      >
        een speciaal teken
      </span>
      .
    </Container>
  );
};

const Container = styled.p<{}>`
  font-style: italic;

  ${({ theme }) => `
  font-size: ${theme.fontSize('s')};

  .invalid {
    color: ${theme.color('danger')};
  }

  .valid {
    color: ${theme.color('success')};
  }
`};
`;

export default PasswordRules;
