import type { ContactHasBBWaardecheckReportEventFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

const asHasBBWaardecheckReportEventFilterInput = (
  filter: ContactHasBBWaardecheckReportEventFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => ({
  App: {
    BBWaardecheck: {
      Report: {
        has: asHasFilterInput(filter.has),
      },
    },
  },
});

export default asHasBBWaardecheckReportEventFilterInput;
