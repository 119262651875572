import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  HEADER: `${cleanedFilename(__filename)}-header`,
  CHANGE_SUBSCRIPTION_BUTTON: `${cleanedFilename(
    __filename,
  )}-change-subscription-button`,
  NEXT_PLAN: `${cleanedFilename(__filename)}-next-plan`,
};
