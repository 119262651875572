import { omit } from 'ramda';
import { Descendant, Element } from 'slate';
import removePropertiesFromStyleString from '../removePropertiesFromStyleString';
import type { EditorValue } from '../../../types';

/**
 * @param {EditorValue} arr - Array of slate elements
 */
const removeFontFamilyFromSlateElements = (
  arr: EditorValue,
): Omit<Descendant, 'fontFamily'> =>
  arr.map(el => {
    if (Element.isElement(el)) {
      if (el.children) {
        const res = removeFontFamilyFromSlateElements(el.children);
        const newAttrs = {
          attributes: {
            ...el.attributes,
            ...(el.attributes?.style
              ? { style: omit(['fontFamily'], el.attributes.style) }
              : {}),
          },
        };

        const updatedStyle = el.attrsToPassThrough?.style
          ? {
              style: removePropertiesFromStyleString(
                el.attrsToPassThrough.style,
                ['fontFamily'],
              ),
            }
          : {};

        const newAttrsToPassThrough = el.attrsToPassThrough
          ? {
              attrsToPassThrough: {
                ...el.attrsToPassThrough,
                ...updatedStyle,
              },
            }
          : {};

        return {
          // @ts-ignore
          ...omit(['fontFamily'], el),
          ...(el.attributes ? newAttrs : {}),
          ...(el.attrsToPassThrough ? newAttrsToPassThrough : {}),
          children: res,
        };
      }
    }

    // @ts-ignore
    return omit(['fontFamily'], el);
  });

export default removeFontFamilyFromSlateElements;
