import type {
  FormBuilder_OptionListFragment,
  FormBuilderFragment,
} from '~/graphql/types';
import type { Issue } from '../../state/issues';
import { isNonEmptyString } from '~/util/Validation/String';
import { isNil } from 'ramda';
import getIssuesForOptionsList from './utils/getIssuesForOptionsList';

const messages = {
  missingLabelForLocale: (locale: string) =>
    `Label kan niet leeg zijn voor ${locale}`,

  missingAltForLocale: (locale: string) =>
    `Alt tekst is verplicht voor afbeeldingen en mist voor ${locale}`,

  missingContentForLocale: (locale: string) =>
    `Er mist content voor ${locale}. Dit zal een leeg element op de pagina plaatsen`,

  missingEventId: 'Geen gebeurtenis verbonden met deze stap',
  missingMapping: (fieldName: string) =>
    `De waarde voor veld ${fieldName} mist nog`,
  missingOptions: 'Opties kan niet leeg zijn',

  imageS3Key:
    'Er mist een afbeelding in het blok. Verwijder het blok of selecteer een afbeelding',
  imageWidth: 'Plaatjes kunnen niet kleiner zijn dan 0px',
};

const getIssuesForNodes = (form: FormBuilderFragment): Array<Issue> => {
  const optionListsMap = form.optionLists.reduce(
    (acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    },
    {} as Record<string, FormBuilder_OptionListFragment>,
  );
  return form.nodes
    .map((node): Array<Issue | null> => {
      if (node.__typename === 'FormBuilder_ScreenNode') {
        const blockIssues = node.blocks
          .map((block): Array<Issue | null> => {
            const availableLocale = form.style.availableLocale;
            switch (block.__typename) {
              case 'FormData_Input_Email':
              case 'FormData_Input_Integer':
              case 'FormData_Input_Multiline':
              case 'FormData_Input_Text': {
                const fieldIssues = availableLocale.map(
                  (locale): Issue | null => {
                    if (isNonEmptyString(block.label[locale.toLowerCase()])) {
                      return null;
                    }

                    return {
                      level: 'error',
                      message: messages.missingLabelForLocale(locale),
                      nodeIds: [node.id],
                      blockKey: block.key,
                      localeKey: locale,
                    };
                  },
                );

                return fieldIssues;
              }

              case 'FormData_Select_Dropdown':
              case 'FormData_Select_MultiButton':
              case 'FormData_Select_Radio': {
                const labelIssues = availableLocale.map(
                  (locale): Issue | null => {
                    if (isNonEmptyString(block.label[locale.toLowerCase()])) {
                      return null;
                    }

                    return {
                      level: 'error',
                      message: messages.missingLabelForLocale(locale),
                      nodeIds: [node.id],
                      localeKey: locale,
                      blockKey: block.key,
                    };
                  },
                );

                const optionsList = optionListsMap[block.optionListId];

                const optionsIssues = getIssuesForOptionsList({
                  optionsList,
                  node,
                  block,
                  availableLocale,
                  messages,
                });

                return [...labelIssues, ...optionsIssues];
              }
              case 'FormData_UI_Image': {
                return availableLocale.map((locale): Issue | null => {
                  if (!isNonEmptyString(block.image.s3key)) {
                    return {
                      level: 'error',
                      message: messages.imageS3Key,
                      nodeIds: [node.id],
                      blockKey: block.key,
                      localeKey: locale,
                    };
                  }

                  if (isNonEmptyString(block.alt[locale.toLowerCase()])) {
                    return null;
                  }

                  if (
                    isNonEmptyString(block.width) ||
                    (block.width ?? 800) < 0
                  ) {
                    return {
                      level: 'error',
                      message: messages.imageWidth,
                      nodeIds: [node.id],
                      blockKey: block.key,
                      localeKey: locale,
                    };
                  }

                  return {
                    level: 'error',
                    message: messages.missingAltForLocale(locale),
                    nodeIds: [node.id],
                    blockKey: block.key,
                    localeKey: locale,
                  };
                });
              }
              case 'FormData_UI_RichText': {
                return availableLocale.map((locale): Issue | null => {
                  const isEmptyEditor =
                    block.content[locale.toLowerCase()] === '<div><br/></div>';

                  if (!isEmptyEditor) return null;

                  return {
                    level: 'error',
                    message: messages.missingContentForLocale(locale),
                    nodeIds: [node.id],
                    blockKey: block.key,
                  };
                });
              }
              case 'FormData_UI_Typography': {
                return availableLocale.map((locale): Issue | null => {
                  if (isNonEmptyString(block.content[locale.toLowerCase()])) {
                    return null;
                  }

                  return {
                    level: 'warning',
                    message: messages.missingContentForLocale(locale),
                    nodeIds: [node.id],
                    blockKey: block.key,
                    localeKey: locale,
                  };
                });
              }

              default:
                return [];
            }
          })
          .flatMap(c => c)
          .filter((entry): entry is Issue => !isNil(entry));

        return blockIssues;
      }

      if (node.__typename === 'FormBuilder_EventNode') {
        const eventIssues: Array<Issue> = [];
        if (!node.formBuilderEventId) {
          eventIssues.push({
            level: 'error',
            message: messages.missingEventId,
            nodeIds: [node.id],
          });
        }

        if (node.formBuilderEventId) {
          const event = form.events.find(
            ({ id }) => id === node.formBuilderEventId,
          );

          event?.fields.forEach(field => {
            const mapped = node.mapping.find(({ key }) => key === field.key);

            // Missing mapping
            if (isNil(mapped)) {
              eventIssues.push({
                level: 'error',
                message: messages.missingMapping(field.name),
                nodeIds: [node.id],
                blockKey: field.key,
              });
            }
          });
        }

        return eventIssues;
      }

      return [null];
    })
    .flatMap(c => c)
    .filter((entry): entry is Issue => !isNil(entry));
};

export default getIssuesForNodes;
