import {
  useUpdateAppBbWaardecheckMutation,
  useUpdateAppVboWaardecheckMutation,
} from '~/graphql/types';
import { AppStatus__typename } from '~/graphql/types.client';

const useUpdateAppWaardecheckMutation = (typename?: AppStatus__typename) => {
  if (!typename) {
    throw new Error('Missing typename in metadata');
  }

  return typename === 'AppStatus_VBOWaardecheck'
    ? useUpdateAppVboWaardecheckMutation
    : useUpdateAppBbWaardecheckMutation;
};

export default useUpdateAppWaardecheckMutation;
