import type { Theme } from '~/theme';

type State = 'checked' | 'unchecked' | 'disabled' | 'circle';

/**
 * Returns background colors for certain states and elements in ToggleCheckbox
 *
 * @param {Theme} theme
 * @param {State} state
 */
const getBackgroundColor = (theme: Theme, state: State) => {
  switch (state) {
    case 'checked':
      return theme.color('primary', 'light');
    case 'unchecked':
      return theme.color('tertiary', 'dark');
    case 'disabled':
      return theme.color('tertiary', 'light');
    case 'circle':
      return theme.color('white');
    default:
      return theme.color('tertiary');
  }
};

export default getBackgroundColor;
