import type { DirectoryEntry, DirectoryMap } from '../../getDirectory';
import type { FlowPathEntry, GetFieldsByPathOpts } from '..';
import { cloneDeep } from 'lodash';
import filterFields from '../filterFields';
import hydrateField from '../../hydrateField';
import getConditions from '../../getConditions';

const generateNextForDirectory = (
  directory: DirectoryEntry | DirectoryMap,
  opts: GetFieldsByPathOpts & {
    usedSubjectMap: Record<string, number | undefined>;
  },
): Array<FlowPathEntry> => {
  const result: Array<FlowPathEntry> = [];
  const dirEntries = Object.values(directory.children);

  result.push(...dirEntries);

  for (const subject of directory.subjects) {
    result.push(...subject.fields.map(field => hydrateField(field, subject)));
  }

  if (directory.instance) {
    const { instance, subject } = directory.instance;

    result.push(
      ...getConditions(instance.instanceType, opts.subjectToConditionMap),
      ...subject.instanceFields.map(field =>
        hydrateField(field, subject, instance),
      ),
    );
  }

  return cloneDeep(filterFields(result, opts));
};

export default generateNextForDirectory;
