import { or } from 'ramda';
import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { SystemSize } from '~/theme';
import Div, { Props as DivProps } from '~/components/atom/Div';

export type Props = HTMLAttributes<HTMLDivElement> &
  DivProps & {
    justification?: Justification;
    align?: Justification;
    gap?: SystemSize | null;
    wrap?: Wrap;
    direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    className?: string;
    nonResponsive?: boolean;
    css?: string | null;
  };

const JustificationContainer = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      justification = 'start',
      align = 'start',
      nonResponsive = false,
      direction = 'row',
      wrap = 'unset',
      gap,
      css,
      dataTestId,
      ...rest
    },
    ref,
  ) => (
    <Container
      data-testid={dataTestId}
      $justification={justification}
      $align={align}
      $nonResponsive={nonResponsive}
      $direction={direction}
      $wrap={wrap}
      $gap={gap}
      css={css}
      ref={ref}
      {...rest}
    >
      {children}
    </Container>
  ),
);

export type Justification =
  | 'start'
  | 'end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'stretch';
type FlexName =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'stretch';
type Wrap = 'wrap' | 'nowrap' | 'wrap-reverse' | 'unset';

type StartOrEnd = 'start' | 'end';

const isStart = (justification: Justification): justification is 'start' =>
  justification === 'start';
const isEnd = (justification: Justification): justification is 'end' =>
  justification === 'end';
const isStartOrEnd = (
  justification: Justification,
): justification is StartOrEnd =>
  or(isStart(justification), isEnd(justification));

const justificationToFlexName = (direction: Justification): FlexName => {
  if (isStartOrEnd(direction)) return `flex-${direction}` as FlexName;

  return direction;
};

type ContainerProps = {
  $justification: Justification;
  $align: Justification;
  $wrap: Wrap;
  $gap?: SystemSize | null;
  $nonResponsive: boolean;
  $direction: Props['direction'];
  className?: string;
};

const Container = styled(Div)<ContainerProps>(
  ({
    $justification,
    $align,
    $wrap,
    $gap,
    $nonResponsive,
    $direction = 'row',
    theme,
  }) => css`
    display: flex;
    justify-content: ${justificationToFlexName($justification)};
    align-items: ${justificationToFlexName($align)};
    flex-direction: ${$direction};
    flex-wrap: ${$wrap};
    gap: ${$gap ? theme.space($gap) : 'unset'};

    ${$nonResponsive === false
      ? theme.mq.lessThan('mobile')`
      flex-direction: ${$direction};
    `
      : ''}
  `,
);

export default JustificationContainer;
