import React from 'react';
import { RenderLeafProps } from 'slate-react';
import TEST_ID from './index.testid';

/** This is the lowest level element. It is not the same as other Custom Elements. */

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return (
    <span {...attributes} data-testid={TEST_ID.CONTAINER}>
      {children}
    </span>
  );
};

export default Leaf;
