import React from 'react';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import Input from '~/components/molecule/Input';
import { pointerSelector } from '~/components/page/Forms/components/Builder/state/nodesAndEvents';

type Props = { pointer: Array<string>; onFocus: () => void };

const PointerRenderer: React.FCC<Props> = ({ pointer, onFocus }) => {
  const resolvedPointerName = useRecoilValue(
    pointerSelector([pointer[0], pointer[1]]),
  );

  return (
    <StyledInput
      type="text"
      width="100%"
      value={resolvedPointerName}
      onFocus={onFocus}
      readOnly
      appearance="primary"
    />
  );
};

const StyledInput = styled(Input)(
  ({ theme }) => css`
    cursor: pointer;
    margin-bottom: ${theme.space('base')};
    width: 300px;
    max-width: 100%;

    & > * {
      text-overflow: ellipsis;
      color: ${theme.color('primary', 'light')};
    }

    &:hover {
      border-color: ${theme.color('primary', 'dark')};
      background-color: ${theme.color('primary', 'dark')};

      & > input {
        background-color: ${theme.color('primary', 'dark')};
        color: ${theme.color('white')};
        cursor: pointer;
      }
    }
  `,
);

export default PointerRenderer;
