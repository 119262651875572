import type { EditorValue } from '~/components/organism/PluginsEditor/types';
import { reporter } from '~/hooks/useErrorReporter';
import { EMPTY_EDITOR_VALUE } from '~/components/organism/PluginsEditor/constants';
import parseAndDeserialize from '~/components/organism/PluginsEditor/utils/parseAndDeserialize';
import { SUPPORTED_ELEMENTS } from '../../constants';

/**
 * Returns Slate value from content string
 *
 * @param {string} str - String that contains html and possibly markdown
 */
const deserializeContent = (str: string): EditorValue => {
  if (str === '') return EMPTY_EDITOR_VALUE;

  if (typeof str !== 'string') {
    reporter.captureException(
      new Error('Received invalid value for RichText'),
      'debug',
    );
  }

  const parsed = parseAndDeserialize({
    html: str,
    customElements: SUPPORTED_ELEMENTS,
  });

  if (parsed.length === 0) return EMPTY_EDITOR_VALUE;

  return parsed;
};

export default deserializeContent;
