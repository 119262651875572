import { AppsAndItemsToEnable, SelectedGroups } from '../../../..';

// removes AP_ prefix
export const appTypeToTypename = (appType: string): string =>
  appType.substring(3);

/**
 * @param (SelectedGroupsMap | undefined): selectedGroupsMap
 * @returns a SelectedGroupMap where items are associated with the app type
 */

const getWidgetGroupsMapOutput = (
  selectedGroupsMap: SelectedGroups | undefined,
): AppsAndItemsToEnable | undefined => {
  if (!selectedGroupsMap) return;

  const output = Object.keys(selectedGroupsMap).reduce((acc, curr) => {
    if (!acc[curr] && selectedGroupsMap[curr].length > 0)
      acc[appTypeToTypename(curr)] = selectedGroupsMap[curr];

    return acc;
  }, {} as AppsAndItemsToEnable);

  return output;
};

export default getWidgetGroupsMapOutput;
