import React from 'react';
import TEST_ID from './DetailsActionList.testid';
import Dropdown, { Option } from '~/components/molecule/Dropdown';
import type { IconType } from '~/components/atom/Icon';

const text = {
  label: 'Opties',
};
type DetailsActionListOption = {
  icon?: { name: IconType };
  label: string;
  onClick: () => void;
  key: string;
  type?: 'DELETE' | null;
};
type Props = {
  options: Array<DetailsActionListOption>;
};
const DetailsActionList: React.FCC<Props> = ({ options }) => {
  const dropdownOptions: Array<Option> = options.map(option => ({
    icon: option.icon,
    label: option.label,
    key: option.key,
    payload: option.onClick,
    type: option.type === 'DELETE' ? 'DANGER' : null,
  }));

  return (
    <div>
      <Dropdown
        dataTestId={TEST_ID.TOGGLE_BUTTON}
        withDefaultFirstOption={false}
        placeholder={text.label}
        appearance="outline"
        options={dropdownOptions}
        onChange={({ option }) => {
          option.payload();
        }}
      />
    </div>
  );
};

export default DetailsActionList;
