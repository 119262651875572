import { isNil, keys } from 'ramda';
import Catalog from '~/Catalog';
import type { SaveBarMessage } from '~/components/organism/NewSaveBar/components/MessagesContainer';
import { isValidURL } from '~/util/Validation/URL';
import type { BrandSettings } from '../../state';
import { isNonEmptyString } from '~/util/Validation/String';

const text = {
  urlInvalid: Catalog.invalidURL,
  urlRequired: 'Adres van je website is een verplicht veld',
  unsubscribeLinkTextEmpty: 'Uitschrijf link tekst is verplicht',
};

/**
 * Validates brand settings and returns an array of error messages if any.
 * @param {BrandSettings} changes - The current changes to validate.
 * @returns {Array<SaveBarMessage>} An array of validation error messages.
 */
const getValidationMessages = ({
  changes,
  flowsAllowed,
}: {
  changes: BrandSettings;
  flowsAllowed: boolean;
}): Array<SaveBarMessage> =>
  keys(validationMapping).flatMap(
    key =>
      validationMapping[key]
        .map(rule => {
          // Don't validate unsubscribeLinkText if flows are not allowed (free account case)
          if (key === 'unsubscribeLinkText' && flowsAllowed === false) {
            return null;
          }
          const isValid = changes.hasOwnProperty(key)
            ? rule.isValid(changes[key])
            : true;
          return isValid ? null : rule.message;
        })
        .filter(x => !isNil(x)) as Array<SaveBarMessage>,
  );

const validationMapping = {
  url: [
    {
      message: {
        key: 'urlRequired',
        message: text.urlRequired,
        type: 'danger',
      },
      isValid: val => isNonEmptyString(val),
    },
    {
      message: {
        key: 'invalidURL',
        message: text.urlInvalid,
        type: 'danger',
      },
      isValid: val => isValidURL(val),
    },
  ],
  unsubscribeLinkText: [
    {
      message: {
        key: 'unsubscribeLinkTextRequired',
        message: text.unsubscribeLinkTextEmpty,
        type: 'danger',
      },
      isValid: val => isNonEmptyString(val),
    },
  ],
};

export default getValidationMessages;
