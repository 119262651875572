import cleanedFilename from '~/util/cleanedFilename';

export default {
  INVOICE_NUMBER: `${cleanedFilename(__filename)}-invoice-number`,
  DUE_DATE: `${cleanedFilename(__filename)}-due-date`,
  AMOUNT: `${cleanedFilename(__filename)}-amount`,
  STATUS: `${cleanedFilename(__filename)}-status`,
  HOSTED_INVOICE_URL: `${cleanedFilename(__filename)}-hosted-invoice-url`,
  INVOICE_PDF: `${cleanedFilename(__filename)}-invoice-pdf`,
};
