import React from 'react';
import { useRecoilState } from 'recoil';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import { Body } from '~/components/atom/Typography';
import PasteIfElseDialog from './components/PasteIfElseDialog';
import dialogs from '../../../../state/dialogs';
import { isNil } from 'ramda';
import DeleteIfElseDialog from './components/DeleteIfElseDialog';
import Catalog from '~/Catalog';

export type Props = {};

const Dialogs: React.FCC<Props> = React.memo(({}) => {
  const [dialog, setDialog] = useRecoilState(dialogs);

  if (isNil(dialog)) return null;

  switch (dialog.type) {
    case 'dialogConfirm': {
      return (
        <Overlay
          onClose={() => {
            dialog.onCancel();
            setDialog(null);
          }}
        >
          <Dialog
            header={dialog.modalHeader}
            body={<Body>{dialog.modalDescription}</Body>}
            buttons={[
              {
                label: Catalog.confirm,
                onClick: () => {
                  dialog.onConfirm();
                  setDialog(null);
                },
                dataTestId: 'modal-confirm-button',
              },
            ]}
          />
        </Overlay>
      );
    }
    case 'dialogSelectIfElsePathOnPaste': {
      return <PasteIfElseDialog dialog={dialog} />;
    }

    case 'dialogDeleteIfElseAction': {
      return <DeleteIfElseDialog dialog={dialog} />;
    }

    default: {
      return null;
    }
  }
});

export default Dialogs;
