// List of common Dutch infixes
const infixes = [
  'de',
  'van',
  'von',
  'der',
  'het',
  'ten',
  'te',
  'ter',
  'op',
  'aan',
  'bij',
  'in',
  'uit',
  'voor',
];

const getInitialsFromAccountName = (accountName: string): string => {
  const words = accountName.trim().split(/\s+/);

  // Filter out infixes that are not the first word
  const filteredWords = words.filter(
    (word, index) => index === 0 || !infixes.includes(word.toLowerCase()),
  );

  // If there's only one word, take the first two characters
  if (filteredWords.length === 1) {
    return filteredWords[0].substring(0, 2).toUpperCase();
  }

  // Get the initials from the first and last significant word
  const firstInitial = filteredWords[0][0].toUpperCase();
  const lastInitial = filteredWords[filteredWords.length - 1][0].toUpperCase();

  return `${firstInitial}${lastInitial}`;
};

export default getInitialsFromAccountName;
