import React from 'react';
import Input, { Props as InputProps } from '~/components/molecule/Input';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import {
  FlowData___InputAppearance,
  FlowData___PrimitiveListInputFragment,
} from '~/graphql/types';
import { Flow___PrimitiveArgument } from '~/graphql/types.client';
import { assertNever } from '~/util/assertNever';
import { format, isValid } from '~/util/Validation/Tag';
import getValueForArgument from '../../../../../Selector/utils/getValueForArgument';
import { Props as PrimitiveInputProps } from '../../index';

export type Props = Exclude<
  PrimitiveInputProps['input'],
  FlowData___PrimitiveListInputFragment
> & {
  value: Flow___PrimitiveArgument | null;
  onChange: (val: Flow___PrimitiveArgument) => void;
  name: string;
  onError?: InputProps['onError'];
  label: InputProps['label'];
};

const text = {
  invalidNumber: 'Waarde moet positief zijn en mag niet 0 zijn',
  invalidTag:
    'Waarde mag niet leeg zijn en alleen kleine letters, cijfers en (-) zijn toegestaan',
};

const InputForAppearance: React.FCC<Props> = ({
  inputAppearance,
  onChange,
  value: initialValue,
  ...rest
}) => {
  const value = getValueForArgument(initialValue);

  if (typeof value === 'boolean') {
    return (
      <ToggleCheckbox
        value={value}
        onChange={() => {
          onChange({
            __typename: 'Flow___Argument_Boolean',
            value_boolean: !value,
          });
        }}
        label={value === true ? 'Ja' : 'Nee'}
      />
    );
  }

  switch (inputAppearance) {
    case FlowData___InputAppearance.Text:
      return (
        <Input
          {...rest}
          autoFocus
          allowKeybinding={true}
          value={value}
          onChange={event =>
            onChange({
              __typename: 'Flow___Argument_String',
              value_string: event.target.value,
            })
          }
          type="text"
        />
      );
    case FlowData___InputAppearance.Float:
      return (
        <Input
          {...rest}
          allowKeybinding={true}
          autoFocus
          value={value}
          onChange={event =>
            onChange({
              __typename: 'Flow___Argument_Float',
              value_float: event.target.value
                ? parseFloat(event.target.value)
                : 0.1,
            })
          }
          type="text"
        />
      );
    case FlowData___InputAppearance.Integer:
      const isValidNumber =
        value !== null && parseInt(value.toString(), 10) > 0;

      return (
        <Input
          {...rest}
          allowKeybinding={true}
          defaultValue={value ?? ''}
          autoFocus
          externalErrors={isValidNumber ? undefined : [text.invalidNumber]}
          onChange={event =>
            onChange({
              __typename: 'Flow___Argument_Integer',
              value_integer: event.target.value
                ? parseInt(event.target.value, 10)
                : 1,
            })
          }
          type="number"
        />
      );
    case FlowData___InputAppearance.Tag:
      return (
        <Input
          {...rest}
          allowKeybinding={true}
          value={value}
          autoFocus
          validation={[
            value => {
              if (isValid(value)) return true;
              return text.invalidTag;
            },
          ]}
          onChange={event =>
            onChange({
              __typename: 'Flow___Argument_String',
              value_string: format(event.target.value),
            })
          }
          type="text"
        />
      );
    case FlowData___InputAppearance.File:
      return <p>Missing implementation</p>;

    default:
      return assertNever(inputAppearance);
  }
};

export default InputForAppearance;
