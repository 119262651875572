import type { EmptyNodeData } from '../../components/nodeTypes';
import { isNil, flatten } from 'ramda';
import { Node, Edge, MarkerType } from 'reactflow';
import type {
  ClientFlowAction,
  ClientFlowActionIfElse,
} from '~/graphql/types.client';
import type { EdgeData } from '../../components/edgeTypes/components/DropEdge';
import type { PortalEdgeData } from '../../components/edgeTypes/components/PortalEdge';
import { HandlerId } from '../../components/nodeTypes/components/IfElseCard/types';
import addPortalEdges from './utils/addPortalEdges';
import getPortalCards from './utils/getPortalCards';

export type NodeData = {
  action: ClientFlowAction;
};

const getElementsFromActions = ({
  actions,
  portalColors,
}: {
  actions: Array<ClientFlowAction>;
  portalColors: Array<string>;
}): {
  nodes: Array<Node>;
  edges: Array<Edge>;
} => {
  const allParentIds = flatten(
    actions
      .filter(action => 'parentIds' in action)
      .map(action => {
        if ('parentIds' in action) {
          return action.parentIds.filter(parentId => !isNil(parentId));
        }
        return [];
      }),
  );

  // End nodes won't have any child nodes aka no node has this ID as a parent ID
  const lastConnectedNodes = actions.filter(
    ({ id }) => !allParentIds.includes(id),
  );

  const nodes = actions.map(
    ({ id, ...action }): Node<NodeData> => ({
      id,
      type: action.actionType,
      data: {
        action: {
          id,
          ...action,
        },
      },
      position: { x: 0, y: 0 },
    }),
  );

  const portalCards = getPortalCards({ actions, portalColors });

  const edges = flatten(
    actions.reduce(
      (prev, { id, ...action }) => {
        if ('parentIds' in action) {
          /** Make sure that the parent actions are returned in the same order as in the parentIds array */
          const parentActions = action.parentIds?.map(id =>
            actions.find(action => action.id === id),
          );

          parentActions.forEach((parentNode, idx) => {
            if (!parentNode) return;

            const parentId = parentNode.id;

            const edgeData: EdgeData = {
              action: {
                id,
                ...action,
              },
              id,
              parentId,
              parentNode: { ...parentNode, id: parentId },
            };

            const isPrimaryParent = idx === 0;
            if (!isPrimaryParent) {
              return addPortalEdges({
                parentId,
                id,
                parentNode,
                portalCards,
                prev,
              });
            }

            if (parentNode.__typename === 'FlowV2_Action_IfElse') {
              const isSecondaryParent = action.parentIds?.[0] !== parentId;
              if (isSecondaryParent) return;

              const edge: Edge<EdgeData> = {
                id: `${id}-to-${parentId}`,
                source: parentId,
                target: id,
                data: edgeData,
                style: { strokeWidth: 2 },
                type: 'ifElse',
                markerEnd: { type: MarkerType.Arrow },
                sourceHandle:
                  parentNode.trueChildId === id
                    ? HandlerId.trueChild
                    : HandlerId.falseChild,
              };

              prev.push(edge);
              return;
            }

            const edge: Edge<EdgeData> = {
              id: `${id}-to-${parentId}`,
              source: parentId,
              target: id,
              data: edgeData,
              style: { strokeWidth: 2 },
              markerEnd: { type: MarkerType.Arrow },
              type: 'dropEdge',
            };

            prev.push(edge);
            return;
          });
        }

        return prev;
      },
      [] as Array<Edge<EdgeData | PortalEdgeData>>,
    ),
  );

  const emptyCards = lastConnectedNodes
    .filter(node => !isNil(node) && node.__typename !== 'FlowV2_Action_IfElse')
    .map(
      (node, index): Node<EmptyNodeData> => ({
        id: 'empty_node_' + index,
        type: 'empty',
        data: {
          id: 'empty_node_' + index,
          parentId: node.id,
        },
        position: { x: 0, y: 0 },
      }),
    );

  const ifElseActions: Array<ClientFlowActionIfElse> = actions.filter(
    (action): action is ClientFlowActionIfElse => {
      if (action.__typename === 'FlowV2_Action_IfElse') {
        return action.falseChildId === null || action.trueChildId === null;
      }
      return false;
    },
  );

  const emptyIfElseCards = ifElseActions.reduce(
    (acc, action: ClientFlowActionIfElse, index) => {
      // We will need edges for both paths
      if (action.falseChildId === null) {
        const id = 'empty_node_if_else_false_' + index;
        acc.push({
          id,
          type: 'empty',
          data: {
            id,
            parentId: action.id,
            parentActionType: action.actionType,
            childIdKey: 'falseChildId',
          },
          position: { x: 0, y: 0 },
        });
      }
      if (action.trueChildId === null) {
        const id = 'empty_node_if_else_true_' + index;
        acc.push({
          id,
          type: 'empty',
          data: {
            id,
            parentId: action.id,
            parentActionType: action.actionType,
            childIdKey: 'trueChildId',
          },
          position: { x: 0, y: 0 },
        });
      }

      return acc;
    },
    [] as Array<Node<EmptyNodeData>>,
  );

  const emptyIfElseEdges = ifElseActions.reduce(
    (acc, action, index) => {
      const baseEdge: Edge<any> = {
        id: 'WILL BE OVERWRITTEN',
        target: 'WILL BE OVERWRITTEN',
        source: action.id,
        type: 'baseEdge',
        markerEnd: { type: MarkerType.Arrow },
        hidden: false,
        data: {},
      };
      // We will need edges for both paths
      if (action.falseChildId === null) {
        const id = `${action.id}-to-empty_${index}_false`;
        const target = 'empty_node_if_else_false_' + index;
        acc.push({
          ...baseEdge,
          id,
          target,
          sourceHandle: HandlerId.falseChild,
          style: {
            strokeWidth: 2,
            pointerEvents: 'none',
          },
        });
      }
      if (action.trueChildId === null) {
        const id = `${action.id}-to-empty_${index}_true`;
        const target = 'empty_node_if_else_true_' + index;
        acc.push({
          ...baseEdge,
          id,
          target,
          sourceHandle: HandlerId.trueChild,
          style: {
            strokeWidth: 2,
            pointerEvents: 'none',
          },
        });
      }

      return acc;
    },
    [] as Array<Edge<EdgeData>>,
  );

  const elements = {
    nodes: [...emptyIfElseCards, ...emptyCards, ...nodes, ...portalCards],
    edges: [
      ...lastConnectedNodes
        .filter(
          node => !isNil(node) && node.__typename !== 'FlowV2_Action_IfElse',
        )
        .map(({ id, actionType }, index) => ({
          id: `${id}-to-empty_${index}`,
          source: id,
          target: 'empty_node_' + index,
          style: { strokeWidth: 2 },
          type: 'baseEdge',
          markerEnd: { type: MarkerType.Arrow },
          isHidden: false,
          data: {
            parentActionType: actionType,
          },
        })),
      ...emptyIfElseEdges,
      ...edges,
    ],
  };

  return elements;
};

export default getElementsFromActions;
