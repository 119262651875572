import { useCallback, useEffect, useState } from 'react';
import { TipId } from '../../index';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '~/util/localStorageKeys';

const useTipBanner = (id: TipId): [showTip: boolean, onDismiss: () => void] => {
  const [showTip, setShowTip] = useState<boolean>(false);

  const onDismiss = useCallback(() => {
    setShowTip(false);
    setLocalStorageItem(id, 'hidden');
  }, [id]);

  useEffect(() => {
    const tipInStorage = getLocalStorageItem(id);
    setShowTip(!tipInStorage);
  }, [id]);

  return [showTip, onDismiss];
};

export default useTipBanner;
