import { isNil } from 'ramda';
import useOffice from '~/hooks/useOffice';
import useUser from '~/hooks/useUser';
import { UserOptions } from '~/hooks/useUserOptions';

export type Params = {
  /** Selected user id */
  userId: string | null | undefined;

  /** Selected office id */
  officeId: string | null | undefined;

  /** User options for the selected office */
  userOptions: UserOptions;
};

type ReturnType = {
  userError?: string;
  officeError?: string;
};

/** Returns validation errors for entity dropdowns */
const useEntityValidationForInputs = ({
  userId,
  officeId,
  userOptions,
}: Params): ReturnType => {
  const currentUser = useUser(userId);
  const currentOffice = useOffice(officeId);

  // Validate user selection if userId is provided
  if (!isNil(userId) && !currentUser) {
    return {
      userError: `Selecteer een gebruiker.`,
    };
  }

  // Validate office selection if officeId is provided
  if (!isNil(officeId) && !currentOffice) {
    return {
      officeError: `Selecteer een vestiging.`,
    };
  }

  // Check if the selected user has been deleted from the account
  const userDeletedFromAccount = currentUser?.__typename === 'DeletedUser';
  if (userDeletedFromAccount) {
    return {
      userError: `Gebruiker "${currentUser?.name}" is verwijderd van het account. Selecteer een andere gebruiker.`,
    };
  }

  // Check if the selected user does not belong to the selected office
  const userDeletedFromOffice =
    !isNil(userId) && !userOptions.find(user => user.key === userId);
  if (userDeletedFromOffice) {
    return {
      userError: `Gebruiker "${currentUser?.email}" behoort niet tot de geselecteerde vestiging. Selecteer een andere gebruiker.`,
    };
  }

  // Check if the selected office has been deleted
  const selectedOfficeDeleted = currentOffice?.deleted;
  if (selectedOfficeDeleted) {
    return {
      officeError: `Vestiging "${currentOffice?.name}" is verwijderd. Selecteer een andere vestiging.`,
    };
  }

  return {};
};

export default useEntityValidationForInputs;
