import React from 'react';
import styled, { css } from 'styled-components';
import Label from '~/components/template/EventTimelineV2/components/Block/components/Data/components/Label';
import { MapsBlock } from '~/graphql/types';
import { BLUR_STYLES } from '../styles';

export type Props = Omit<MapsBlock, '__typename'> & {
  dataTestId?: string;
};

const MapsBlockComponent: React.FC<Props> = ({
  label,
  mapValue,
  css,
  blurred,
}) => (
  <>
    {label && <Label>{label}</Label>}
    <MapsImage
      src={`https://maps.googleapis.com/maps/api/streetview?size=720x400&location=${mapValue.location.lat},${mapValue.location.lng}&fov=100&heading=70&pitch=0&key=${process.env.GOOGLE_MAPS_API_KEY}`}
      alt="street view"
      css={css}
      $blurred={blurred || false}
    />
  </>
);

const MapsImage = styled.img<{ $blurred: boolean }>(
  ({ $blurred }) => css`
    width: 100%;
    max-width: 100%;

    ${$blurred && BLUR_STYLES};
  `,
);

export default MapsBlockComponent;
