import React from 'react';
import { RenderElementProps } from 'slate-react';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { BlockquoteElement } from '~/components/organism/PluginsEditor/types';
import serialize from './serialize';
import BlockQuoteComp from '~/components/atom/BlockQuote';

export type Props = RenderElementProps & {
  element: BlockquoteElement;
};

const BlockQuote: React.FCC<Props> = ({
  dataTestId,
  attributes,
  element,
  ...rest
}) => (
  <BlockQuoteComp
    data-testid={dataTestId}
    {...attributes}
    {...element.attributes}
  >
    {rest.children}
  </BlockQuoteComp>
);

export default {
  nodeName: 'BLOCKQUOTE',
  renderComponent: props => <BlockQuote {...props} />,
  deserialize: () => ({ type: ELEMENTS.BLOCKQUOTE }),
  serialize,
};
