import React from 'react';
import { animated, useSpring } from 'react-spring';
import { useTheme } from 'styled-components';
import {
  componentSizes,
  levelToColorMapping,
  levelToIconNameMapping,
} from '~/styles/constants';
import type { ComponentLevel, Size } from '~/styles/constants';
import Icon from '~/components/atom/Icon';

export type Props = {
  size?: Size;
  level: ComponentLevel;
};

const Indicator: React.FCC<Props> = ({ size = 'medium', level }) => {
  const theme = useTheme();

  const styleProps = useSpring({
    from: { transform: 'translateX(-10px)', opacity: 0, scale: 0 },
    to: { transform: 'translateX(0px)', opacity: 1, scale: 1 },
    config: {
      tension: 300,
      friction: 20,
    },
  });

  return (
    <Icon
      name={levelToIconNameMapping[level]}
      background={levelToColorMapping[level]}
      color={theme.color('white')}
      as={animated.span}
      spin={level === 'loading'}
      style={{
        /**
         * Fixes: Type 'SpringValue<number>' is not assignable to type 'Opacity | undefined'.
         * P.S. When you remove 'as any' it seems fine but if you run the linter you will get this error.
         *
         * Related issue: https://github.com/pmndrs/react-spring/issues/1645
         * Temporary solution: https://github.com/pmndrs/react-spring/issues/1102#issuecomment-803208159
         */
        ...(styleProps as any),
        position: 'absolute',
        bottom: theme.space(componentSizes[size].padding[0]),
        right: theme.space(componentSizes[size].padding[1]),
      }}
      dataTestId={`${level}-icon`}
    />
  );
};

export default Indicator;
