import { path, sortBy, type Path } from 'ramda';

/**
 * Sorts an array of objects based on the value at a given path.
 * @param pathArr - The path to the value used for sorting.
 * @returns A function that takes an array of objects and returns a new array sorted based on the specified path.
 */
const sortByPath = <T>(pathArr: Path) =>
  sortBy(path(pathArr) as (obj: T) => any);

export default sortByPath;
