import { ImgDefinition } from '~/graphql/types.client.flow';
import type { ListItemForDeletion } from '../../../components/DeleteEntityModals/components/ListItemCard';

export const createOfficeItemForModal = (
  offices: Array<{
    id: string;
    name: string;
    img?: ImgDefinition | null | undefined;
  }>,
  officeId: string,
): ListItemForDeletion => {
  const office = offices.find(office => office.id === officeId);

  if (office == null) {
    throw new Error('Office does not exist');
  }
  const { id, name, img } = office;

  return {
    id,
    name,
    img,
  };
};
