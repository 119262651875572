/**
 * A hook to ensure that you only call a certain function once. It will give the user:
 * [0] - the function that can be safely called, it will only be run the first time it is called
 *
 * It expects:
 *  func - The function to run
 */

import { useRef } from 'react';

type ReturnProps = [() => void];

const useRunOnce = (func: () => void): ReturnProps => {
  const hasRun = useRef(false);

  const returnFunc = () => {
    if (!hasRun.current) {
      hasRun.current = true;

      func();
    }
  };

  return [returnFunc];
};

export default useRunOnce;
