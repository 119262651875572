import React, { forwardRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import DraggableItem from '~/components/atom/DraggableItem';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading5, Variant } from '~/components/atom/Typography';
import { blocksMap, DND_SOURCES } from '../../../../constants';
import SquareIcon from '../../../../../../../../../../../../atom/Icon/components/SquareIcon';
import TextButton from '~/components/atom/TextButton';
import type { FormBuilder_ScreenNode_BlockFragment } from '~/graphql/types';

export type Props = {
  index: number;
  focused?: boolean;
  hasIssues?: boolean;
  block: FormBuilder_ScreenNode_BlockFragment;
  children: React.ReactNode;
  onDelete: () => void;
  onClick: () => void;
  onArrowDown?: () => void;
  onArrowUp?: () => void;
};

const DraggableBlockWrapper = forwardRef<HTMLDivElement, Props>(
  (
    {
      dataTestId,
      children,
      index,
      onDelete,
      onClick,
      focused,
      onArrowDown,
      onArrowUp,
      hasIssues,
      block,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();

    const typename = block.__typename;
    const icon = blocksMap[typename].icon;
    const header = blocksMap[typename].label;
    const color = blocksMap[typename].color;

    return (
      <DraggableItem
        itemId={block.key}
        index={index}
        style={{ width: '100%' }}
        source={DND_SOURCES.blocksArea}
      >
        <Container
          ref={ref}
          onClick={onClick}
          data-testid={dataTestId}
          {...rest}
          border={{
            style: `${focused ? 'solid' : 'dashed'}`,
            width: 'base',
            color: hasIssues
              ? { group: 'danger', variant: 'light' }
              : focused
                ? { group: 'primary', variant: 'light' }
                : { group: 'tertiary' },
            radius: 'base',
          }}
          padding={['m']}
          width="100%"
          gap="l"
          align="center"
          $focused={focused ?? false}
          backgroundColor={{ group: 'white' }}
          $hasIssues={hasIssues}
        >
          <JustificationContainer direction="column" align="center" gap="base">
            {onArrowUp && (
              <ArrowIcon
                onClick={onArrowUp}
                name="arrow"
                color={theme.color('tertiary')}
              />
            )}
            <DragNDropIcon name="drag-n-drop" color={theme.color('tertiary')} />
            {onArrowDown && (
              <ArrowIcon
                onClick={onArrowDown}
                name="arrow-down"
                color={theme.color('tertiary')}
              />
            )}
          </JustificationContainer>
          <JustificationContainer direction="column" gap="m" width="100%">
            <CardHeader
              align="center"
              gap="base"
              justification="space-between"
              width="100%"
              padding={[null, null, 'xxs', null]}
            >
              <JustificationContainer align="center" gap="base">
                <SquareIcon name={icon} color={color} />
                <Heading5
                  margin={[null]}
                  variant={Variant.primary}
                  color={{ group: 'primary', variant: 'dark' }}
                >
                  {header}
                </Heading5>
              </JustificationContainer>
              <TextButton
                size="medium"
                appearance="danger"
                onClick={e => {
                  e.stopPropagation();
                  onDelete();
                }}
                icon="trashcan"
              />
            </CardHeader>
            {children}
          </JustificationContainer>
        </Container>
      </DraggableItem>
    );
  },
);

const ArrowIcon = styled(Icon)(
  ({ theme }) => css`
    font-size: ${theme.fontSize('l')};
    transition: all 0.3s ease-out;

    &:hover {
      svg {
        stroke: ${theme.color('primary')};
      }
    }
  `,
);

const CardHeader = styled(JustificationContainer)(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.color('tertiary', 'light')};
  `,
);

const DragNDropIcon = styled(Icon)(
  ({ theme }) => css`
    font-size: ${theme.fontSize('l')};
    cursor: pointer;

    &:hover {
      svg > path {
        fill: ${theme.color('primary')};
      }
    }
  `,
);

const Container = styled(JustificationContainer)<{
  $focused: boolean;
  $hasIssues?: boolean;
}>(
  ({ $focused, theme, $hasIssues }) => css`
    cursor: pointer;
    transition: all 0.2s ease-out;
    transform: translate(0px, 0px);

    ${$hasIssues &&
    css`
      box-shadow: ${theme.boxShadow('aroundDanger')};
    `}

    ${$focused &&
    css`
      box-shadow: ${$hasIssues
        ? theme.boxShadow('aroundDanger')
        : theme.boxShadow('base')};
    `}

    &:hover {
      box-shadow: ${theme.boxShadow('cardHover')};
      transform: translate(0, -2px);
    }
  `,
);

export default DraggableBlockWrapper;
