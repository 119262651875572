import { v4 } from 'uuid';
import Validation from '~/util/Validation';
import type { EmailOption } from '~/components/organism/SendEmailBlock/components/MessageHeader/hooks/useGetEmailOptions';

/**
 * Converts inserted string into a dropdown option
 * @param {string} value - Typed value
 * @param {string} dropdownLabel - Label to be shown in the dropdown option. If not passed, value is used as label
 */
const convertToEmailOption = ({
  value,
  dropdownLabel,
}: {
  value: string;
  dropdownLabel?: string;
}): EmailOption => ({
  key: v4(),
  label: dropdownLabel || value,
  payload: {
    namedEmail: {
      email: value,
    },
    filterLabel: value,
    tagLabel: value,
  },
  isValid: Validation.Email.isValid(value),
});

export default convertToEmailOption;
