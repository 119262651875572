import Validation from '~/util/Validation';
import Catalog from '~/Catalog';
import type { FormData } from '~/components/page/Auth/ForgotPassword/index';

export const text = {
  noEmail: Catalog.noEmail,
  invalidEmail: Catalog.invalidEmail,
};

/**
 * Validates forgot-password form
 * @param {string | null} email - Email value
 */
const validate = ({ email }: FormData) => {
  const errors: {
    email: string | undefined;
  } = {
    email: undefined,
  };

  if (Validation.String.isEmpty(email)) {
    errors.email = text.noEmail;
  } else if (!Validation.Email.isValid(email)) {
    errors.email = text.invalidEmail;
  }

  return errors;
};

export default validate;
