import React from 'react';

import FoldableCardBlock from '../FoldableCardBlock';
import JustificationContainer from '~/components/atom/JustificationContainer';
import BlockMarketingToggle from './components/BlockMarketingToggle';
import FlowInstances from './components/FlowInstances';
import { Heading5, Variant } from '~/components/atom/Typography';

const text = {
  title: 'Automation',
  activeFlowsHeading: 'Actieve flows',
};

type Props = {
  contactId: string;
  blockMarketing: boolean | null | undefined;
};
const AutomationBlock: React.FCC<Props> = ({ contactId, blockMarketing }) => (
  <>
    <FoldableCardBlock
      dataTestId="card-block-contact-flowinstances"
      icon="gear"
      title={text.title}
    >
      <JustificationContainer width="100%" direction="column" gap="l">
        <BlockMarketingToggle
          contactId={contactId}
          blockMarketing={blockMarketing}
        />
        <JustificationContainer direction="column" width="100%">
          <Heading5 variant={Variant.primary}>
            {text.activeFlowsHeading}
          </Heading5>
          <FlowInstances contactId={contactId} />
        </JustificationContainer>
      </JustificationContainer>
    </FoldableCardBlock>
  </>
);

export default AutomationBlock;
