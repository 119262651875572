import {} from 'react';
import convertToDropdownOption, {
  type ToAddressOption,
} from '../../utils/convertToDropdownOption';
import getEmailTargetOptions from '../../utils/getEmailTargetOptions ';
import useUsers from '~/hooks/useUsers';
import useOffices from '~/hooks/useOffices';
import { UserStatus } from '~/graphql/types';

const useSendNotificationTargetOptions = ({
  action,
}): {
  selectedOptions: Array<ToAddressOption>;
  allOptions: Array<ToAddressOption>;
} => {
  const offices = useOffices({});
  const users = useUsers({ statuses: [UserStatus.Active] });

  return {
    selectedOptions: action.to
      .map(to => convertToDropdownOption({ toAddress: to, offices, users }))
      .filter(a => a != null),
    allOptions: getEmailTargetOptions({ activeUsers: users, offices }),
  };
};

export default useSendNotificationTargetOptions;
