import { isEmpty } from 'ramda';
import { useRef, useState } from 'react';
import type { ChangeEvent, FocusEventHandler, ForwardedRef } from 'react';
import cloneWithPrototype from '~/util/cloneWithPrototype';
import getValidationErrors from '~/util/getValidationErrors';

type Params = {
  /** Used to get the validation errors */
  value?: string | number | null;

  /** Input type */
  type?: string;

  /** Validation to be applied to value */
  validation?: Array<(value: string | number | null) => string | true>;

  /**
   * External errors to be shown.
   * If defaultValue is passed without value, use this instead of the validation array
   */
  externalErrors?: Array<string>;

  /** onChange handler */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;

  /** What to do after input is focused */
  onFocus?: FocusEventHandler<HTMLInputElement>;

  /** What to do after input is blurred */
  onBlur?: FocusEventHandler<HTMLInputElement>;

  /** If passed, this becomes the inputRef. Otherwise another ref is created. */
  ref?: ForwardedRef<HTMLInputElement>;
};

const useInputValue = ({
  ref,
  value,
  externalErrors,
  validation,
  type,
  onChange,
  onFocus,
  onBlur,
}: Params) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const validationErrors: Array<string> = (() => {
    if (externalErrors && !isEmpty(externalErrors)) return externalErrors;
    if (!hasChanged) return [];
    if (!validation) return [];

    return getValidationErrors(validation, value);
  })();

  const hasError = validationErrors.length !== 0 ? true : undefined;

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHasChanged(true);
    const inputValue = e.target.value;
    const lowercasedValue =
      type === 'email' ? inputValue.toLowerCase() : inputValue;

    const modifiedEvent = cloneWithPrototype(e);
    modifiedEvent.target = { ...e.target, value: lowercasedValue };

    if (onChange) onChange(modifiedEvent);
  };

  const onFocusChange = ({
    e,
    focus,
  }: {
    e: React.FocusEvent<HTMLInputElement, Element>;
    focus: boolean;
  }) => {
    setHasFocus(focus);
    if (focus === true && onFocus) onFocus(e);
    if (focus === false && onBlur) onBlur(e);
  };

  return {
    validationErrors,
    hasError,
    hasFocus,
    hasChanged,
    onFocusChange,
    onValueChange,
    inputRef: (ref || inputRef) as React.MutableRefObject<HTMLInputElement>,
  };
};

export default useInputValue;
