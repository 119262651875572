import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  large?: boolean;
};

const InputGroupDivider: React.FCC<Props> = ({
  dataTestId,
  large = false,
  ...rest
}) =>
  large ? (
    <ContainerLarge data-testid={dataTestId} {...rest} />
  ) : (
    <Container data-testid={dataTestId} {...rest} />
  );

const Container = styled.div<{}>(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.color('white', 'dark')};
    margin: ${theme.space('l')} 0;
  `,
);

const ContainerLarge = styled.div<{}>(
  ({ theme }) => css`
    border-bottom: 40px solid ${theme.color('tertiary', 'translucent')};
    margin: ${theme.space('l')} -${theme.space('xxl')};
  `,
);

export default InputGroupDivider;
