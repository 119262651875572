import React, { useState } from 'react';
import { navigate, RouteComponentProps } from '@gatsbyjs/reach-router';
import Input from '~/components/molecule/Input';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import TEST_ID from './index.testid';
import { FlowTemplates } from '~/graphql/types';
import getFlowCreatedDate from '~/components/page/Automation/v2/util/getFlowCreatedDate';

export type Props = {
  onClose: () => false | void | undefined;
  loading?: boolean;
  onCreate: (name: string, description: string) => void;
  disableClosing?: boolean;
  template: FlowTemplates | null;
} & RouteComponentProps;

const text = {
  header: 'Naam toevoegen',
  description:
    'Zodat je je flow weer kunt herkennen in de lijst, is het verstandig een naam te geven die (gedeeltelijk) omschrijft wat de flow doet.',
  flowNameTitle: 'Flow naam',
  flowDescriptionTitle: 'Flow omschrijving',
  cancelButton: 'Annuleren',
  goBackButton: 'Ga terug',
  createButton: 'Maak flow',
  newFlowName: 'Nieuwe flow',
};

const DescribeFlow: React.FCC<Props> = ({
  loading = false,
  onCreate,
  onClose,
  disableClosing,
  template,
}) => {
  const [name, setName] = useState<string>(
    `${template?.name ?? text.newFlowName} gemaakt op ${getFlowCreatedDate()}`,
  );
  const [description, setDescription] = useState<string>(
    template?.description || '',
  );

  return (
    <AppDetailsContainer
      header={text.header}
      icon="pencil"
      pageDescription={text.description}
      dataTestId={TEST_ID.CONTAINER}
    >
      <AppEntryItemHeader>{text.flowNameTitle}</AppEntryItemHeader>
      <Input
        onChange={event => setName(event.target.value)}
        value={name}
        data-testid={TEST_ID.NAME_INPUT}
      />

      <AppEntryItemHeader>{text.flowDescriptionTitle}</AppEntryItemHeader>
      <Input
        onChange={event => setDescription(event.target.value)}
        value={description}
        data-testid={TEST_ID.DESCRIPTION_INPUT}
      />

      <JustificationContainer
        justification="space-between"
        align="center"
        margin={['base', null, null, null]}
      >
        {!disableClosing && (
          <TextButton
            size="medium"
            label={text.cancelButton}
            onClick={onClose}
            padding={[null]}
            appearance="danger"
            dataTestId={TEST_ID.EXIT_WIZARD_BUTTON}
          />
        )}
        <JustificationContainer align="center">
          <TextButton
            size="medium"
            label={text.goBackButton}
            onClick={() => navigate(-1)}
            padding={[null]}
            appearance="primary"
            dataTestId={TEST_ID.GO_BACK_BUTTON}
            margin={[null, 'm', null, null]}
          />
          <Button
            icon="check"
            appearance="secondary"
            label={text.createButton}
            disabled={name === '' || name === null}
            loading={loading} // When submitting get passed from parent
            onClick={() => {
              onCreate(name, description);
            }}
            size="medium"
            dataTestId={TEST_ID.CREATE_BUTTON}
          />
        </JustificationContainer>
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default DescribeFlow;
