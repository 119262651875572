import type { SystemSize } from '~/theme';

export type Size = 'small' | 'base';
type Sizes = { [key in Size]: SizeChart };
type SizeChart = {
  circle: SystemSize;
  container: {
    height: SystemSize;
    maxWidth: SystemSize;
    borderRadius: SystemSize;
    padding: SystemSize;
  };
};

export const sizes: Sizes = {
  base: {
    circle: 'l',
    container: {
      padding: 'xs',
      height: 'xl',
      maxWidth: 'xxxxl',
      borderRadius: 'xl',
    },
  },
  small: {
    circle: 'base',
    container: {
      padding: 'xxs',
      height: 'l',
      maxWidth: 'xxl',
      borderRadius: 'l',
    },
  },
};
