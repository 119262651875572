import { v4 as uuid } from 'uuid';
import { Descendant, Element, Text, Transforms } from 'slate';
import {
  CustomText,
  DHEditor,
  VariableElement,
  type EditorValue,
} from '~/components/organism/PluginsEditor/types';
import deserialize from '../utils/deserialize';
import deserializeAsPlainText from '../utils/deserializeAsPlainText';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';

import { removeNewLineChar } from '~/components/organism/PluginsEditor/utils/serializeAsPlainText';
import { defaultSanitizeOptions } from '../utils/flows/convertTemplateStringToSlate';
import sanitize from 'sanitize-html';
import { pipe } from 'ramda';
import removeFontFamilyFromSlateElements from './utils/removeFontFamilyFromSlateElements';
import removeStyleProperties from '../utils/parseAndDeserialize/utils/removeStyleProperties';

/**
 * Converts and inserts the copy-pasted html into the editor.
 * Handles pasting both internal and external html.
 * Keep in mind that this also handles copy-pasting an image into the editor
 * */

const withHtml = ({
  editor,
  customElements,
  /** If the editor only allows text and variables */
  simpleEditor = false,
}: {
  editor: DHEditor;
  customElements: Array<ELEMENTS>;
  simpleEditor?: boolean;
}): DHEditor => {
  const { insertData } = editor;

  editor.insertData = data => {
    const externalHtml = data.getData('text/html');
    const slateFragment = data.getData('application/x-slate-fragment');

    if (slateFragment) {
      const decoded = decodeURIComponent(window.atob(slateFragment));
      const parsed = JSON.parse(decoded);

      const fragmentWithPendingElements = pipe(
        removeFontFamilyFromSlateElements,
        markElementsAsPending,
      )(parsed);

      if (simpleEditor) {
        Transforms.insertNodes(
          editor,
          getOnlyTextAndVariables(fragmentWithPendingElements),
        );
        return;
      }

      Transforms.insertFragment(editor, fragmentWithPendingElements);
    } else if (externalHtml) {
      /**
       * This is to enable pasting images in Safari, we do not get the 'application/x-slate-fragment'
       * data type from the DataTransfer object in the ClipboardEvent so we need to convert the slate fragment string manually
       */
      const el = document.createElement('div');
      el.innerHTML = externalHtml;
      const fragmentStrings = getFragmentStrings(Array.from(el.children));

      if (fragmentStrings && fragmentStrings.length > 0) {
        const fragmentString = fragmentStrings[0];
        if (fragmentString) {
          const decoded = decodeURIComponent(window.atob(fragmentString));
          const parsed = JSON.parse(decoded);

          if (
            editor.selection != null &&
            editor.selection.focus.path.length <= 2
          ) {
            // We do this at the top level
            editor.insertNode(parsed);
          } else {
            // When we're in some nested structure, e.g., a list, this
            // seems to work much better.
            editor.insertFragment(parsed);
          }

          return;
        }
      }

      const htmlToDoc = pipe(
        externalHtml => sanitize(externalHtml, defaultSanitizeOptions),
        sanitized => new DOMParser().parseFromString(sanitized, 'text/html'),
        doc => removeStyleProperties(doc, ['font-family']),
      );

      const doc = htmlToDoc(externalHtml);

      /** When we copy paste from an external editor */
      if (simpleEditor) {
        const textFragment = deserializeAsPlainText(doc.body);
        Transforms.insertNodes(editor, textFragment);
        return;
      }

      const fragment = deserialize({
        el: doc.body,
        markAsPendingImage: true,
        customElements,
        initialValue: externalHtml,
      });
      Transforms.insertNodes(editor, fragment);
    } else {
      insertData(data);
    }
  };

  return editor;
};

/** Passes unique ids to Variable and Image elements */
const markElementsAsPending = (arr: EditorValue) =>
  arr.map(el => {
    if (Element.isElement(el)) {
      const isImageElement =
        el.type === ELEMENTS.DH_IMAGE || el.type === ELEMENTS.IMAGE;

      if (el.children) {
        const res = markElementsAsPending(el.children);
        if (isImageElement) {
          return {
            ...el,
            // Make sure they get unique inlineId's when we copy-paste them to
            // prevent having duplicate inlineIds. Beware they are still ImageType(not DH image) here.
            // We will update the type in the useEffect of the Image element later
            inlineId: uuid(),
            pending: true,
            children: res,
          };
        }

        const isVariableElement = el.type === ELEMENTS.VARIABLE;
        if (isVariableElement) {
          return {
            ...el,
            mappingId: uuid().replace(/-/g, ''),
          };
        }

        return { ...el, children: res };
      }

      if (isImageElement) return { ...el, pending: true };
    }

    return el;
  });

const getFragmentStrings = (arr): Array<string> => {
  const res: Array<string> = [];

  const getFragments = arr => {
    if (arr.length > 0) {
      return arr.find(el => {
        const fragmentString = el.getAttribute('data-slate-fragment');
        if (fragmentString) res.push(fragmentString);

        if (el.children) return getFragments(Array.from(el.children));

        return;
      });
    }
  };

  getFragments(arr);

  return res;
};

const getOnlyTextAndVariables = (
  arr: EditorValue,
): Array<CustomText | VariableElement> => {
  if (arr.length === 0) return [];

  const newArr: Array<{ text: string } | VariableElement> = [];

  const addElement = (el: Descendant) => {
    if (Text.isText(el)) {
      const text = removeNewLineChar(el.text);
      newArr.push({ text });
      return;
    }

    const children = el.children?.map(n => addElement(n));

    if (el.type === ELEMENTS.VARIABLE) {
      newArr.push(el);
      return;
    }

    return children;
  };

  arr.forEach(element => {
    addElement(element);
  });

  return newArr;
};

export default withHtml;
