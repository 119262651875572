import React from 'react';
import styled, { css } from 'styled-components';
import Catalog from '~/Catalog';

export type Props = {
  /** Any error returned from the parent */
  hasError: boolean;

  /** Message to be displayed. If not passed, generic error message is used */
  errorMessage?: string;

  /** If there are broken images in the editor */
  hasNonUploadedImagesError?: boolean;

  /** If there are uploading images in the editor */
  isUploading?: boolean;
};

const text = {
  genericErrorMessage: Catalog.genericUnknownErrorMessageShort,
  stillUploadingErrorMessage: 'Er worden nog afbeeldingen geladen',
  badImagesErrorMessage:
    '1 of meer van de afbeeldingen in deze email kunnen niet verzonden worden.',
};

const EmailErrorMessage: React.FCC<Props> = ({
  hasError,
  hasNonUploadedImagesError,
  isUploading,
  errorMessage,
}) => {
  if (hasError)
    return <Container>{errorMessage || text.genericErrorMessage}</Container>;

  if (hasNonUploadedImagesError) {
    return <Container>{text.badImagesErrorMessage}</Container>;
  }

  if (isUploading)
    return <Container>{text.stillUploadingErrorMessage}</Container>;

  return null;
};

const Container = styled.div<{}>`
  margin-right: auto;

  ${({ theme }) => css`
    color: ${theme.color('danger')};
  `};
`;

export default EmailErrorMessage;
