import { WizardFlow } from '~/graphql/types.client';

/**
 * Filters out all steps of an enablement flow that don&#x27;t require user input
 */
const getActionableStepsForEnablementFlow = (
  enablementFlow: WizardFlow | undefined,
): WizardFlow['steps'] => {
  if (!enablementFlow) return [];
  return enablementFlow.steps.filter(
    ({ id }) => id !== 'AppStatusOverview' && id !== 'GenericStart',
  );
};

export default getActionableStepsForEnablementFlow;
