import Color from 'color';

export type ColorVariant = {
  base: string;
  text: string;
  light: string;
  dark: string;
  translucent: string;
};

export type IColorPalette<T> = {
  text: T;
  white: T;
  primary: T;
  secondary: T;
  tertiary: T;
  danger: T;
  warning: T;
  info: T;
  success: T;
  accent: T;
};
export type SystemColorPalette = IColorPalette<ColorVariant>;
export type BaseColor = keyof SystemColorPalette;
export type BaseColorVariant = keyof ColorVariant;
export type ThemeColor = {
  group: BaseColor;
  variant?: BaseColorVariant;
};

/** If you change anything, always make sure that Colours documentation is inline with this object,
 *  see `docs/2 Colours.stories.mdx` */
const colorPalette: SystemColorPalette = {
  text: {
    base: '#1E2647',
    text: '#1E2647',
    light: '#213C80',
    dark: '#010103',
    translucent: '#CFD9E8',
  },

  white: {
    light: '#fff',
    text: '#1E2647',
    base: '#fff',
    dark: '#EAEFF5',
    translucent: '#fff',
  },

  primary: {
    light: '#213C80',
    base: '#1E2647',
    text: '#fff',
    dark: '#010103',
    translucent: '#EAEFF5',
  },

  secondary: {
    light: '#FFD6A8',
    base: '#ff6900',
    text: '#fff',
    dark: Color('#ff6900').darken(0.1).hex(),
    translucent: '#FFD6A825',
  },

  tertiary: {
    light: '#EAEFF5',
    base: '#CFD9E8',
    text: '#1E2647',
    dark: '#BDCADE',
    translucent: '#F7F9FC',
  },
  danger: {
    base: '#C6020B',
    text: '#fff',
    light: Color('#C6020B').lighten(0.1).hex(),
    dark: Color('#C6020B').darken(0.1).hex(),
    translucent: '#C6020B15',
  },
  warning: {
    base: '#FFB464',
    text: '#1E2647',
    light: Color('#FFB464').lighten(0.1).hex(),
    dark: Color('#FFB464').darken(0.1).hex(),
    translucent: '#FFD6A825',
  },
  success: {
    base: '#018827',
    text: '#fff',
    light: Color('#018827').lighten(0.1).hex(),
    dark: Color('#018827').darken(0.1).hex(),
    translucent: '#01882715',
  },
  info: {
    base: '#5C7FD6',
    text: '#fff',
    light: '#D5E1FF',
    dark: '#455E9D',
    translucent: '#5C7FD615',
  },
  accent: {
    base: '#FF9F5B',
    text: '#fff',
    light: Color('#FF9F5B').lighten(0.1).hex(),
    dark: Color('#FF9F5B').darken(0.1).hex(),
    translucent: '#FF9F5B15',
  },
};

export default colorPalette;
