import type { OutputType as OnboardingStellaxAiSelectProductOutput } from '~/components/organism/WizardSteps/OnboardingWizardSteps/OnboardingStellaxAiSelectProduct';
import type { OutputType as InstallStellaxAiOutput } from '~/components/organism/WizardSteps/Apps/StellaxAi/AppDetails';
import type { ExtendedAppConfig } from '~/hooks/useApps';
import type { PriceWithInterval } from '~/util/getPriceWithInterval';
import { PRODUCT_OPTIONS } from '../../appConfig';
import type { OutputMap, StepId } from '~/components/organism/WizardSteps';

export type Args = {
  app?: ExtendedAppConfig | null;
  outputMap: OutputMap;
  stepIDs?: Array<StepId>;
};

/**
 * Retrieves the price with interval based on the setup flow of the app.
 *
 * @param {Object} args - The arguments object containing outputMap and app
 * @param {Object} args.outputMap - The output map object
 * @param {Object} args.app - The app object
 * @returns {PriceWithInterval | null} The price with interval or null
 */
const getAppPriceBySetupFlow = ({
  outputMap,
  app,
  stepIDs,
}: Args): PriceWithInterval | null => {
  if (!app) return null;

  const appPrice: PriceWithInterval | null = (() => {
    if (app.setupFlow !== 'selectProduct') return app.price ?? null;

    const specialStepOutput = (() => {
      if (!stepIDs)
        return outputMap[
          'OnboardingStellaxAiSelectProduct'
        ] as OnboardingStellaxAiSelectProductOutput;
      if (stepIDs && stepIDs.includes('OnboardingStellaxAiSelectProduct'))
        return outputMap[
          'OnboardingStellaxAiSelectProduct'
        ] as OnboardingStellaxAiSelectProductOutput;

      return outputMap['StellaxInstall'] as InstallStellaxAiOutput;
    })();

    const productIndex = PRODUCT_OPTIONS.findIndex(
      ({ payload }) => specialStepOutput.reportType.value === payload,
    );

    return app.price[productIndex] ?? null;
  })();

  return appPrice;
};

export default getAppPriceBySetupFlow;
