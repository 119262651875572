import React from 'react';
import useAddToast from '~/hooks/useAddToast';

const withAddToastMessage =
  (BaseComponent: typeof React.Component | React.FC) => props => {
    const addToast = useAddToast();
    return <BaseComponent {...props} addToast={addToast} />;
  };

export default withAddToastMessage;
