import React from 'react';
import { OfficeInList } from '~/components/page/Settings/Profile/components/FormContainer';
import { Field, EmailField } from '~/components/organism/Forms';
import FormUtils from '~/components/organism/FormUtils';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import useCurrentUser from '~/hooks/useCurrentUser';
import useUserRights from '~/hooks/useUserRights';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import Dropdown from '~/components/molecule/Dropdown';

type Props = {
  officesList: Array<OfficeInList>;
  readOnly: boolean | null | undefined;
  mayEdit: boolean;
  onFormChange: (name: string, value: string) => any;

  /** users/userId needs this prop*/
  userId?: string;
};

const text = {
  selectMainOffice: 'Selecteer hoofdvestiging',
  office: Catalog.officeLabel,
  phone: Catalog.phoneLabel,
  email: Catalog.emailLabel,
};
const FormFields: React.FCC<Props> = ({
  officesList,
  readOnly,
  mayEdit,
  onFormChange,
}) => {
  const { update: mayUpdate } = useUserRights({
    category: 'Users',
  });

  const viewingModeProps = useViewingModeProps();

  const me = useCurrentUser();

  if (me == null) {
    throw new Error(
      `${cleanedFilename(__filename)} | Should not occur | User is null`,
    );
  }

  const sortedOfficesList = officesList.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return (
    <JustificationContainer width="100%" direction="column" gap="base">
      <EmailField name="email">
        {({ input, meta: { error, touched } }) => (
          <Input
            width="100%"
            size="large"
            label={{ text: text.email }}
            externalErrors={
              FormUtils.showError(error, touched)
                ? [FormUtils.showError(error, touched)]
                : []
            }
            disabled
            {...input}
            dataTestId="user-email-input"
            {...viewingModeProps}
          />
        )}
      </EmailField>

      <Field name="phone">
        {({ input, meta: { error, touched, submitting } }) => (
          <Input
            width="100%"
            size="large"
            label={{ text: text.phone }}
            type="tel"
            externalErrors={
              FormUtils.showError(error, touched)
                ? [FormUtils.showError(error, touched)]
                : []
            }
            disabled={submitting || readOnly}
            {...input}
            dataTestId="user-phone-input"
            {...viewingModeProps}
          />
        )}
      </Field>

      <Field name="selectedMainOfficeId">
        {({ input }) => (
          <Dropdown
            {...input}
            width="100%"
            size="large"
            label={text.selectMainOffice}
            options={sortedOfficesList.map(office => ({
              payload: office.id,
              label: office.name,
              key: office.id,
            }))}
            onChange={({ option }) =>
              onFormChange('selectedMainOfficeId', option.payload)
            }
            disabled={readOnly || !(mayUpdate || mayEdit)}
            selectedOptionIdx={sortedOfficesList.findIndex(
              ({ id }) => id === input.value,
            )}
            dataTestId="main-office-dropdown"
            {...viewingModeProps}
          />
        )}
      </Field>
    </JustificationContainer>
  );
};

export default FormFields;
