import styled, { css } from 'styled-components';
import TextButton, { Props as TextButtonProps } from '../TextButton';
import type { Size } from '~/styles/constants';
import type { SizeMap } from '~/theme';

const hitAreaMap: { [key in Size]: string } = {
  small: '0.4rem',
  medium: '0.5rem',
  large: '0.6rem',
};

const fontSizeMap: { [key in Size]: keyof SizeMap } = {
  small: 'xs',
  medium: 's',
  large: 'base',
};

export type Props = TextButtonProps;

/**
 * This component shall be used sparingly. It removes the padding
 * from the TextButton and gives it a larger click area on hover.
 *
 * Only use this when you do not want the button to take up space
 * e.g. when it is used in an input or dropdown
 */
const NoSpaceTextButton = styled(TextButton)<Props>(({
  theme,
  appearance = 'primary',
  size = 'medium',
  disabled,
}) => {
  const hitAreaExpansion = hitAreaMap[size];
  const fontSize = fontSizeMap[size];

  return css`
    padding: 0;
    min-width: max-content;
    position: relative;
    z-index: 1;
    color: ${theme.color('white')};
    font-size: ${theme.fontSize(fontSize)};

    /* Increase the click area of the button */
    &::before {
      content: '';
      position: absolute;
      top: -${hitAreaExpansion};
      left: -${hitAreaExpansion};
      right: -${hitAreaExpansion};
      bottom: -${hitAreaExpansion};
      border-radius: ${theme.getTokens().border.radius.s};
      z-index: -1;
      background-color: ${disabled
        ? theme.color('tertiary')
        : theme.color(appearance)};
    }

    &:hover {
      color: ${theme.color('white')};
      &::before {
        background-color: ${theme.color(appearance, 'dark')};
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  `;
});

export default NoSpaceTextButton;
