import { Transforms } from 'slate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import {
  BlockElement,
  DHEditor,
} from '~/components/organism/PluginsEditor/types';
import convertHtmlToSlateFragment from '~/components/organism/PluginsEditor/utils/signatures/convertHtmlToSlateFragment';
import { getElementWithType, getFullRange } from '../..';
import { Editor } from 'slate';
import removeWhiteSpaceInHtml from '../html/util/removeWhiteSpaceInHtml';
import { isNil } from 'ramda';
import { Attachment } from '~/graphql/types';
import { SIGNATURE_CONTAINER_ID } from '~/components/organism/PluginsEditor/plugins/withSignature';
import { reporter } from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';

const customElements = [ELEMENTS.DH_IMAGE, ELEMENTS.IMAGE, ELEMENTS.SIGNATURE];

const selectSignature = ({
  editor,
  html,
  attachments,
}: {
  editor: DHEditor;
  html: string | null;
  attachments: Array<Attachment>;
}) => {
  const whiteSpaceRemoved = removeWhiteSpaceInHtml(html || '');

  const fragment = convertHtmlToSlateFragment({
    html: whiteSpaceRemoved,
    markAsPendingImage: false,
    customElements,
    attachments: attachments || [],
  });

  const fullRange = getFullRange(editor);
  const sigEl = getElementWithType({
    editor,
    type: ELEMENTS.SIGNATURE,
    at: fullRange,
  });

  if (!sigEl) {
    reporter.captureException(
      new Error(
        `Signature element is not found in ${cleanedFilename(__filename)}`,
      ),
      'warning',
    );
    return;
  }

  /** Path where we edit signature element's content */
  const childPath = [...sigEl.path, 0];

  Editor.withoutNormalizing(editor, () => {
    /**
     * In Slate we cannot use Transforms.setNodes to assign new children to an element.
     * Therefore we remove the existing signature content and replace it with new chldren.
     *
     * We never remove the SignatureElement itself so it can be edited at the same location
     * it was, at all times.
     *
     */
    Transforms.removeNodes(editor, {
      at: childPath,
    });

    Transforms.insertNodes(
      editor,
      [
        {
          type: ELEMENTS.DIV,
          attributes: {
            id: SIGNATURE_CONTAINER_ID,
          },
          children: isNil(html)
            ? [{ text: '' }]
            : (fragment as Array<BlockElement>),
        },
      ],
      { at: childPath },
    );
  });

  return;
};

export default selectSignature;
