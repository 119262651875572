import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import styled from 'styled-components';
import useIsMounted from '~/hooks/useIsMounted';
import { navigate } from '@gatsbyjs/reach-router';
import AuthFormWrapper from '../components/AuthFormWrapper';
import CompanyDetails from '../components/SetupAccountWizard';
import createPageTitle from '~/util/createPageTitle';

type Props = {
  refetchAccounts: () => Promise<any>;
};

const SetupAccount: React.FCC<Props> = ({ refetchAccounts }) => {
  const isMounted = useIsMounted();
  const title = 'Account Details';

  return (
    <>
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />
        <title>{createPageTitle(title)}</title>
      </MetaTags>

      <AuthFormWrapper>
        <RegisterFormFieldSet data-testid="register-details-form">
          <CompanyDetails
            afterSubmit={async () => {
              await refetchAccounts();

              if (isMounted()) void navigate('/-/');
            }}
          />
        </RegisterFormFieldSet>
      </AuthFormWrapper>
    </>
  );
};

const RegisterFormFieldSet = styled.div<{}>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  min-height: 100%;
  & > div {
    width: 100%;
  }
`;

export default SetupAccount;
