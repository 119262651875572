import React from 'react';
import styled, { css } from 'styled-components';
import { useTable, useSortBy, useFlexLayout } from 'react-table';

import { TableColumnDefinitions } from '~/components/bad/DataTables/types.flow';
import { InvoiceListData } from '../util/composeInvoiceListData';

import {
  Body,
  Header,
  DataTableContainer,
} from '~/components/bad/DataTables/styling';
import { HeaderCell, RowCell } from '~/components/bad/DataTables';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';

import Catalog from '~/Catalog';

import TEST_ID from './InvoiceListTable.testid';
import Loading from '~/components/atom/Loading';
import NoResultSection from '~/components/page/NoResultSection';
import Row from '~/components/bad/DataTables/styling/Row';

const text = {
  noSearchResults: Catalog.noResults,
};
type Props = {
  columns: TableColumnDefinitions;
  data: Array<InvoiceListData>;
  networkStatus: number;
  loading: boolean;
};

const InvoiceListTable: React.FCC<Props> = ({
  columns,
  data,
  networkStatus,
  loading,
  dataTestId,
  ...rest
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        disableMultiSort: true,
        autoResetSortBy: false,
        autoResetHiddenColumns: false,
        getRowId: row => row.id,
        manualSortBy: true,
        initialState: {
          sortBy: [{ id: 'createdDate', desc: true }],
        },
      },
      useFlexLayout,
      useSortBy,
    );

  let bodyComponent;
  if (isInitialLoadStatus(networkStatus) || loading) {
    bodyComponent = <Loading />;
  } else if (data.length === 0) {
    bodyComponent = (
      <StyledNoResultSection>{text.noSearchResults}</StyledNoResultSection>
    );
  } else {
    bodyComponent = (
      <Body.Standard.Inner {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);

          const rowProps = { ...row.getRowProps() };
          const invoiceId = row.id;
          const isDeleted = row.original && row.original.isDeleted === true;

          return (
            <Row
              {...rowProps}
              $isSelected={row.isSelected}
              $isDeleted={isDeleted}
              key={rowProps.key}
              dataTestId={TEST_ID.LIST_ITEM}
              data-objectid={invoiceId}
              data-deleted={isDeleted}
            >
              {row.cells.map((cell, idx) => {
                const isLastColumn = idx === row.cells.length - 1;

                return (
                  <RowCell
                    key={`cell-${idx}`}
                    cell={cell}
                    isLastColumn={isLastColumn}
                  />
                );
              })}
            </Row>
          );
        })}
      </Body.Standard.Inner>
    );
  }

  return (
    <TableContainer data-testid={dataTestId}>
      <DataTableContainer.Standard
        {...rest}
        {...getTableProps()}
        data-testid={TEST_ID.CONTAINER}
      >
        <div>
          {headerGroups.map(headerGroup => {
            const headerGroupProps = {
              ...headerGroup.getHeaderGroupProps(),
            };
            return (
              <Header.Standard key={headerGroupProps.key}>
                {headerGroup.headers.map((column, idx) => {
                  const isLastColumn = idx === headerGroup.headers.length - 1;
                  const headerCellKey = `header-${idx}`;

                  return (
                    <StyledHeaderCell
                      key={headerCellKey}
                      column={column}
                      isLastColumn={isLastColumn}
                    />
                  );
                })}
              </Header.Standard>
            );
          })}
        </div>
        <Body.Standard.Outer>{bodyComponent}</Body.Standard.Outer>
      </DataTableContainer.Standard>
    </TableContainer>
  );
};

const TableContainer = styled(DataTableContainer.StandardOuter)(
  ({ theme }) => css`
    padding: ${theme.space('s')};
  `,
);

const StyledHeaderCell = styled(HeaderCell)<{}>(
  ({ theme }) => css`
    padding-bottom: ${theme.space('l')};
  `,
);

const defaultColumn = {
  minWidth: 20,
  width: 120,
  maxWidth: 200,
};

const StyledNoResultSection = styled(NoResultSection)<{}>`
  max-width: 800px;
`;

export default InvoiceListTable;
