import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import useTooltipLayer from '~/hooks/useTooltipLayer';

const text = {
  tooltip:
    'Hiermee wordt de app verborgen in de widgetlijst, maar blijft deze nog steeds bereikbaar via de deeplink',
};

export type Props = {
  hidden: boolean;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};

const ToggleHiddenFlag: React.FCC<Props> = ({ hidden, onClick, disabled }) => {
  const tooltipProps = useTooltipLayer({
    tooltipMessage: text.tooltip,
  });

  return (
    <HiddenIcon
      {...tooltipProps}
      strokeWidth={2}
      dataTestId="toggle-hidden-widget"
      data-name={hidden ? 'eye-closed' : 'eye'}
      name={hidden ? 'eye-closed' : 'eye'}
      onClick={onClick}
      $disabled={disabled}
    />
  );
};

const HiddenIcon = styled(Icon)<{ $disabled?: boolean }>(
  ({ theme, $disabled = false }) => css`
    svg {
      stroke: ${$disabled ? theme.color('tertiary') : theme.color('primary')};
    }

    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    transition: all 0.2s ease;

    ${$disabled && `pointer-events: none`};

    &:hover {
      transform: scale(1.2);
    }
  `,
);

export default ToggleHiddenFlag;
