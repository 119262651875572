import { Flow___PrimitiveArgument } from '~/graphql/types.client';

const isPrimitiveArgument = (
  argument: any,
): argument is Flow___PrimitiveArgument => {
  if (!argument) return false;
  if (
    argument.__typename !== 'Flow___Argument_Pointer' &&
    argument.__typename !== 'Flow___Argument_File'
  )
    return true;
  return false;
};

export default isPrimitiveArgument;
