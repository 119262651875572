import { ApolloClient, InMemoryCache } from '@apollo/client';
import { useRef } from 'react';

const useStrapiClient = () => {
  const client = useRef(
    new ApolloClient({
      uri: process.env.STRAPI_API_ENDPOINT,
      headers: {
        Authorization: `Bearer ${process.env.STRAPI_API_ACCESS_KEY}`,
      },
      cache: new InMemoryCache(),
    }),
  );

  return client.current;
};

export default useStrapiClient;
