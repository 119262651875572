import hasValue from '~/util/hasValue';

const text = {
  emptyError: 'Waarde kan niet leeg zijn',
  invalidInteger: 'Waarde moet een geldig geheel getal zijn',
  outsideRange:
    'De waarde moet binnen het bereik van -2147483648 tot 2147483647 liggen',
};
const validateZIndex = (value: number): true | string => {
  // Regular expression to match positive or negative integers
  const regex = /^-?\d+$/;

  if (!hasValue(value)) {
    return text.emptyError;
  }
  // Convert value to string and check if it matches the regular expression
  const strValue = String(value);
  if (!regex.test(strValue)) {
    return text.invalidInteger;
  }

  const zIndex = parseInt(strValue);

  // Check if zIndex is within the acceptable range
  if (zIndex < -2147483648 || zIndex > 2147483647) {
    return text.outsideRange;
  }

  return true;
};

export default validateZIndex;
