import type { Geometry } from 'geojson';
import type { Geometry__Input } from '~/graphql/types';
import { reporter } from '~/hooks/useErrorReporter';

const getGeometryFromClientGeometry = (
  geometry: Geometry,
): Geometry__Input | null => {
  switch (geometry.type) {
    case 'Polygon':
      return { Geometry_Polygon: { multiCoordinates: geometry.coordinates } };
    case 'LineString':
      return { Geometry_LineString: { coordinates: geometry.coordinates } };
    case 'MultiLineString':
      return {
        Geometry_MultiLineString: { multiCoordinates: geometry.coordinates },
      };
    case 'MultiPoint':
      return { Geometry_MultiPoint: { coordinates: geometry.coordinates } };
    case 'MultiPolygon':
      return {
        Geometry_MultiPolygon: { poliCoordinates: geometry.coordinates },
      };
    case 'Point':
      return { Geometry_Point: { position: geometry.coordinates } };
    default:
      reporter.captureMessage(
        `Unknown geometry type: ${geometry.type}`,
        'fatal',
      );
      return null;
  }
};

export default getGeometryFromClientGeometry;
