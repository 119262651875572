/**  A helper function that generates a range of numbers
 *
 *   range(1, 5); // returns [1, 2, 3, 4, 5]
 */
const range = (from: number, to: number, step: number = 1): Array<number> => {
  let i = from;
  const range: Array<number> = [];

  if (step <= 0) return [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export default range;
