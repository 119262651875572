import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { SystemColorPalette } from '~/theme';
import getPercentage from '~/util/getPercentage';

export type Props = {
  total: number;
  currentValue: number;
  color?: keyof SystemColorPalette;
  visiblePercentage?: boolean;
};

const PercentageBar: React.FCC<Props> = ({
  dataTestId,
  currentValue,
  total,
  color,
  visiblePercentage = false,
  ...rest
}) => {
  const percentage = getPercentage(currentValue, total);
  const over = percentage - 99;

  const barColor = (() => {
    if (color) return color;

    return over > 0 ? 'danger' : 'success';
  })();

  const cappedPercentage = Math.min(100, percentage);

  return (
    <Container
      data-testid={dataTestId}
      $color={barColor}
      $percentage={percentage}
      {...rest}
    >
      {visiblePercentage && <Percentage>{`${cappedPercentage}%`}</Percentage>}
      <Progress $width={cappedPercentage} $color={barColor} />
    </Container>
  );
};

const getAnimation = (width: number) => keyframes`
  from { width: 0%; }
  to { width: ${width}%; }
`;
const Progress = styled.div<{
  $width: number;
  $color: keyof SystemColorPalette;
}>(
  ({ $width, $color, theme }) => css`
    text-align: center;
    font-size: ${theme.space('s')};
    height: 100%;
    width: ${$width}%;
    background-color: ${theme.color($color)};
    color: ${theme.color($color, 'text')};
    border-top-right-radius: ${theme.getTokens().border.radius.base};
    border-bottom-right-radius: ${theme.getTokens().border.radius.base};
    animation: ${getAnimation($width)} forwards ease-in 1s;
  `,
);

const Percentage = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
`;

const Container = styled.div<{
  $color: keyof SystemColorPalette;
  $percentage: number;
}>(
  ({ theme, $color, $percentage }) => css`
    width: 100%;
    height: ${theme.space('base')};
    border: 1px solid ${theme.color($color)};
    border-radius: ${theme.getTokens().border.radius.base};
    position: relative;
    overflow: hidden;
    user-select: none;
    display: flex;
    font-size: ${theme.fontSize('xs')};

    ${Percentage} {
      color: ${$percentage >= 50
        ? theme.color($color, 'text')
        : theme.color('text')};
    }
  `,
);

export default PercentageBar;
