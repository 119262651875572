import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  CARD_HEADER: `${cleanedFilename(__filename)}-card-header`,
  NO_CONTACTS: `${cleanedFilename(__filename)}-no-contacts`,
  NO_CONTACTS_WITH_LEADSCORE: `${cleanedFilename(
    __filename,
  )}-no-contacts-with-leadscore`,
  TABLE: `${cleanedFilename(__filename)}-table`,
};
