import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import styled, { css } from 'styled-components';

import { FormTemplatesCategory } from '../../index';
import TEST_ID from './index.testid';
import SelectBlock from '~/components/molecule/SelectBlock';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import type { FormTemplate } from '~/graphql/types';
import { sort } from 'ramda';

type Props = RouteComponentProps<{ slug: string }> & {
  formCategories: FormTemplatesCategory;
  onSelect: (selectedTemplate: FormTemplate | null) => void;
  templateToken?: string | null;
};

const TemplateDetails: React.FCC<Props> = ({
  slug,
  formCategories,
  templateToken,
  onSelect,
}) => {
  const currentCategory: FormTemplatesCategory['key'] = !slug
    ? formCategories['meest-gebruikt']
    : formCategories[slug];

  const sortedTemplates = currentCategory?.templates
    ? sort(({ name: a, weight: wA = 0 }, { name: b, weight: wB = 0 }) => {
        if (wA === wB) return a.localeCompare(b);
        return wB - wA;
      }, currentCategory.templates)
    : [];

  return (
    <Container>
      {sortedTemplates.map(template => (
        <SelectBlock
          type="radio"
          key={template.token}
          checked={templateToken === template.token}
          onClick={() => onSelect(template)}
          description={template.description}
          checkboxTitle={template.name}
          dataTestId={TEST_ID.TEMPLATE}
        />
      ))}
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    padding: ${theme.space('s')} ${theme.space('l')};

    /* Magic number 63 to show 4 full entries (most of the time) */
    height: 63vh;
    overform-y: auto;
    width: 100%;

    ${scrollBarStyles}
  `,
);

export default TemplateDetails;
