import React from 'react';

import FoldableCardBlock from '../FoldableCardBlock';
import TEST_ID from './index.testid';
import { convertServerLeadScoreToFrontendLeadScore } from '../../util/composeContactListData';
import JustificationContainer from '~/components/atom/JustificationContainer';
import LeadScoreGaugeV2 from '~/components/organism/Charts/LeadScoreGaugeV2';

type Props = {
  leadScore: number;
};

const text = {
  title: 'Leadscore',
};

const ContactLeadScoreBlock: React.FCC<Props> = ({ leadScore }) => (
  <FoldableCardBlock
    icon="lead-score"
    title={text.title}
    dataTestId={TEST_ID.CONTAINER}
  >
    <JustificationContainer
      align="center"
      justification="center"
      width="100%"
      margin={['m', null, null, null]}
    >
      <LeadScoreGaugeV2
        leadScore={convertServerLeadScoreToFrontendLeadScore(leadScore)}
      />
    </JustificationContainer>
  </FoldableCardBlock>
);

export default ContactLeadScoreBlock;
