/**
 * Removes all open and closing tags from html string. Regex is not 100% reliable to strip html so it should be used sparingly
 * (maybe for performance reasons).
 *
 * Regex matches every character that starts with '<' and ends with '>'
 *
 * E.g. <p>Hallo</p><p>Joe</p> => HalloJoe
 *
 * @see https://thewebdev.info/2021/05/15/how-to-remove-html-tags-in-javascript-with-regex/
 */
const stripHtmlWithRegex = (html: string): string =>
  html.replace(/(<([^>]+)>)/gi, '');

export default stripHtmlWithRegex;
