import { groupBy } from 'ramda';
import type { AttachmentWithKey } from '~/components/page/Automation/v2/components/UpdateAction/hooks/useEmailFileAttachments';

/**
 * Groups attachments into file and pointer attachments depending on the typename
 * @param {Array<AttachmentWithKey>} attachments - Email attachments
 * keywords: file attachment, pointer attachment
 */
const groupAttachments = (
  attachments: Array<AttachmentWithKey>,
): Partial<
  Record<'file' | 'pointer' | 'unknown', Array<AttachmentWithKey>>
> => {
  if (!attachments || attachments.length === 0) {
    return { file: [], pointer: [] };
  }

  return groupBy(({ file }) => {
    if (file.__typename === 'Flow___Argument_File') return 'file';
    if (file.__typename === 'Flow___Argument_Pointer') return 'pointer';
    return 'unknown';
  }, attachments);
};

export default groupAttachments;
