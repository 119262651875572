import type { FormBuilder_Locale } from '~/graphql/types';

export type LocaleMessagePayload = {
  availableLocale: Array<FormBuilder_Locale>;
};

const formatLocale = (
  input: Array<FormBuilder_Locale>,
): LocaleMessagePayload => ({
  availableLocale: input,
});

export default formatLocale;
