import type { Option } from '~/components/molecule/Dropdown';
import { StellaxAiReportType, type AppStatus__Input } from '~/graphql/types';
import type { ExtendedAppConfig } from '~/hooks/useApps';
import getWidgetAppConfigurationUpdate from '~/util/getWidgetAppConfigurationUpdate';

const getAppStatusUpdate = (
  app: ExtendedAppConfig,
  forceCompleted?: boolean,
  options?: { selectedProduct: Option },
): AppStatus__Input => {
  if (app.__typename === 'AppStatus_Trustoo') {
    return {
      [app.__typename]: {
        ...getWidgetAppConfigurationUpdate(app.appStatus),
        tokens: [],
        enabled: !app.appStatus.enabled,
        metadata: {
          setup: {
            addedFlows: [],
            addedTokens: [],
            completed: forceCompleted ?? false,
          },
        },
      },
    };
  }
  if (app.__typename === 'AppStatus_Contaqt') {
    return {
      [app.__typename]: {
        enabled: !app.appStatus.enabled,
        metadata: {
          setup: {
            addedFlows: [],
            completed: forceCompleted ?? false,
            addedToken: null,
          },
        },
      },
    };
  }

  if (app.__typename === 'AppStatus_Realworks') {
    return {
      [app.__typename]: {
        enabled: !app.appStatus.enabled,
        usageAgreement: true,
        metadata: {
          setup: {
            addedFlows: [],
            addedTokens: [],
            completed: forceCompleted ?? false,
          },
        },
      },
    };
  }

  if (app.__typename === 'AppStatus_StellaxAi') {
    return {
      [app.__typename]: {
        enabled: !app.appStatus.enabled,
        reportType: options?.selectedProduct.payload ?? StellaxAiReportType.B,
        metadata: {
          setup: {
            addedFlows: [],
            completed: forceCompleted ?? false,
          },
        },
      },
    };
  }

  return {
    [app.__typename]: {
      ...getWidgetAppConfigurationUpdate(app.appStatus),
      enabled: !app.appStatus.enabled,
      metadata: {
        setup: {
          addedFlows: [],
          completed: forceCompleted ?? false,
        },
      },
    },
  };
};

export default getAppStatusUpdate;
