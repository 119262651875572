import React, { useEffect } from 'react';
import styled from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Loading from '~/components/atom/Loading';
import { Body } from '~/components/atom/Typography';
import { WizardStepProps } from '~/components/organism/Wizard/context/WizardContext';
import { useGetZapierApiKeysQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ApiKeySuccess from './components/ApiKeySuccess';
import GetApiKey from './components/GetApiKey';
import useWizardStep from '~/hooks/useWizardStep';
import CountdownTimer from '~/components/molecule/CountdownTimer';

export const text = {
  header: 'Verbinden met Zapier',
  pageDescription:
    'Koppel jouw Zapier account aan DatHuis. Heb je nog geen Zapier account? Maak deze dan eerst aan op Zapier.com en kom hier terug.',
  codeLabel: '1. Kopieer onze API key',
  secondStep: '2. Vul deze in bij Zapier',
  path: ['Ga naar My Apps', 'Klik “Add connection”', 'Zoek op “DatHuis”'],
  pathLabel: 'Ga naar “My Apps” in Zapier',
  link: 'https://zapier.com/app/connections',
  exitButton: 'Afbreken',
  nextButton: 'Gegevens versturen',
  validateButton: 'API key bevestigen',
  errorMessage:
    'De API key wordt gecontroleerd. Ververs deze pagina om te controleren of deze is geaccepteerd.',
  timerPostfix: 'seconden tot volgende check..',
};

export const id = 'ConnectZapier';
export const title = text.header;

export type OutputType = {
  type: typeof id;
};

const POLLING_INTERVAL = 5000;

export const Component: React.FCC<WizardStepProps> = props => {
  const [, api] = useWizardStep(props.step.id);

  const { id: accountId } = useCurrentAccount();
  const { data, loading, stopPolling, startPolling } = useGetZapierApiKeysQuery(
    {
      variables: {
        accountId: accountId,
      },
      pollInterval: POLLING_INTERVAL,
    },
  );

  const zapierApiKey = data?.getZapierApiKeys[0]?.apiKey;
  const isConnected = data?.getZapierApiKeys[0]?.lastConnectedAt;

  useEffect(() => {
    if (isConnected) {
      api.free({ type: id });
    } else {
      api.lock({ type: id });
      startPolling(POLLING_INTERVAL);
    }

    return () => {
      stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  if (loading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <>
      <Body margin={[null, null, 'l', null]}>{text.pageDescription}</Body>

      {isConnected ? (
        <ApiKeySuccess />
      ) : (
        <>
          <GetApiKey zapierApiKey={zapierApiKey ?? text.errorMessage} />
          <JustificationContainer justification="end" align="center">
            <CountdownTimer
              duration={POLLING_INTERVAL / 1000}
              loop
              postfix={text.timerPostfix}
            />
          </JustificationContainer>
        </>
      )}
    </>
  );
};

const LoadingContainer = styled.div<{}>`
  display: flex;
`;

export default {
  id,
  title,
};
