import React from 'react';
import InputWithLabel from '../InputWithLabel';
import Textarea from '~/components/molecule/Textarea';

export type Props = {
  dataTestId?: string;
  label: string;
  value: string | undefined | null;
  onUpdate: (val: string | null) => void;
};

const TextAreaWithLabel: React.FCC<Props> = ({
  label,
  value,
  onUpdate,
  dataTestId,
}) => (
  <InputWithLabel label={label}>
    <Textarea
      name="text"
      value={value !== undefined ? value : null}
      onChange={value => onUpdate(value)}
      dataTestId={dataTestId}
    />
  </InputWithLabel>
);

export default TextAreaWithLabel;
