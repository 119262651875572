import type { NamedEmail } from '~/util/namedEmailFormatter';
import type { Reducer } from 'react';
import type { OptionOf } from '~/components/molecule/Dropdown';
import { assertNever } from '~/util/assertion';
import type { EmailOption } from '../components/MessageHeader/hooks/useGetEmailOptions';
import type { EditorValue } from '../../PluginsEditor/types';

export type ReducerAction<T, P extends object> = {
  type: T;
  payload: P;
};

export type Action =
  | ReducerAction<'setKey', { key: string; value: string | number }>
  | ReducerAction<'addToList', { key: string; value: EmailOption }>
  | ReducerAction<'removeFromList', { key: string; removedValue: EmailOption }>
  | ReducerAction<'reset', { value: EmailReducerState }>;

export type EmailReducerState = {
  subject: string;
  body: EditorValue;
  senderOptions: Array<OptionOf<NamedEmail>>;
  selectedSenderIdx: number | null;
  senderValue?: NamedEmail | null;
  selectedCCs: Array<EmailOption>;
  selectedBCCs: Array<EmailOption>;
};

export type ReducerType = Reducer<EmailReducerState, Action>;

const sendEmail = (state: EmailReducerState, action: Action) => {
  switch (action.type) {
    case 'reset':
      return action.payload.value;

    case 'setKey': {
      const { key, value } = action.payload;

      return {
        ...state,
        [key]: value,
      };
    }

    case 'addToList': {
      const { key, value } = action.payload;

      return {
        ...state,
        [key]: [...state[key], value],
      };
    }

    case 'removeFromList': {
      const { key, removedValue } = action.payload;

      return {
        ...state,
        [key]: state[key].filter(
          selectedValue =>
            selectedValue.payload.namedEmail.email !==
            removedValue.payload.namedEmail.email,
        ),
      };
    }

    default: {
      return assertNever(action, 'sendEmailReducer');
    }
  }
};

export default sendEmail;
