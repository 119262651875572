import cleanedFilename from '~/util/cleanedFilename';

export default {
  GO_TO_NEXT_CONTAINER: `${cleanedFilename(__filename)}-go-to-next-container`,
  GO_TO_START_BUTTON: `${cleanedFilename(__filename)}-go-to-start-button`,
  GO_TO_END_BUTTON: `${cleanedFilename(__filename)}-go-to-end-button`,
  GO_TO_PREVIOUS_BUTTON: `${cleanedFilename(__filename)}-go-to-previous-button`,
  GO_TO_NEXT_BUTTON: `${cleanedFilename(__filename)}-go-to-next-button`,
  NUMBER_BUTTON: (number: number) =>
    `${cleanedFilename(__filename)}-number-button-page-${number}`,
};
