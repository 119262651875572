import React, { SVGAttributes, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';

export type Props = SVGAttributes<SVGPathElement>;

const AnimatedPath: React.FCC<Props> = React.memo(props => {
  const [length, setLength] = useState<number>(1000);
  const [toggle, setToggle] = useState(false);

  const spring = useSpring({
    strokeDashoffset: toggle ? 0 : length,
    strokeDasharray: length,
  });

  useEffect(() => {
    setToggle(true);
  }, []);

  return (
    <animated.path
      {...props}
      ref={ref => {
        if (ref) {
          setLength(ref.getTotalLength());
        }
      }}
      style={{ ...props.style, ...spring }}
    />
  );
});

export default AnimatedPath;
