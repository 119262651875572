/* https://www.ietf.org/rfc/rfc2396

  Within a query component, the characters ";", "/", "?", ":", "@",
   "&", "=", "+", ",", and "$" are reserved. */
const QUERY_VALUE_REGEX = /^[^;/?:@&=+,$]+$/;
export const isValidQueryValue = (value: any): boolean => {
  if (typeof value !== 'string') return false;

  return QUERY_VALUE_REGEX.test(value);
};

/** Only accepts url beginning with https and http */
// @TODO: Do not allow // in the path for an AWSURL https://docs.aws.amazon.com/appsync/latest/devguide/scalars.html
// Also do not allow whitespace in the middle and at the end
const URL_REGEX =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9\+]{1,256}\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,6})?\b([-a-zA-Z0-9()@:%_\+.]*)/;
export const isValidURL = (value: any): boolean => {
  if (typeof value !== 'string') return false;

  return URL_REGEX.test(value);
};

export default {
  isValidQueryValue,
  isValidURL,
};
