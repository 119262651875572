import React from 'react';
import { useSpring, animated } from 'react-spring';
import Button from '~/components/atom/Button';
import StatusContainer, {
  Props as StatusContainerProps,
} from '~/components/organism/StatusContainer';
import { ANIMATION_CONFIG } from '~/styles/constants';

const text = {
  buttonLabel: 'Controleren',
  pollingLoading: 'Controleren op gekoppelde e-mails...',
  stoppedPolling:
    'Er zijn nog geen gekoppelde e-mails. Klik hier om de status van je e-mailkoppeling te controleren.',
};

// 5 minutes in milliseconds
const RETRY_TIMEOUT = 5 * 60 * 1000;

export type Props = {
  isOpen: boolean;
  isPolling: boolean;
  onStartPolling: (timeout: number) => void;
};

const SyncStatusBar: React.FC<Props> = ({
  isOpen,
  isPolling,
  onStartPolling,
}) => {
  if (!isOpen) return null;

  if (isPolling)
    return <Wrapper level="loading">{text.pollingLoading}</Wrapper>;

  return (
    <Wrapper justification="space-between">
      {text.stoppedPolling}
      <Button
        label={text.buttonLabel}
        onClick={() => onStartPolling(RETRY_TIMEOUT)}
      />
    </Wrapper>
  );
};

const Wrapper: React.FCC<StatusContainerProps> = ({ children, ...rest }) => {
  const spring = useSpring({
    from: { opacity: 0, transform: 'translate3d(-0px, -40px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0px, 0px, 0)' },
    config: { ...ANIMATION_CONFIG.config, clamp: true },
  });

  return (
    <animated.div style={spring}>
      <StatusContainer
        align="center"
        margin={['m', null, null, null]}
        padding={['base']}
        {...rest}
      >
        {children}
      </StatusContainer>
    </animated.div>
  );
};

export default SyncStatusBar;
