import { useMemo } from 'react';
import AddNodeEdge from './components/AddNodeEdge';
import BaseEdge from './components/BaseEdge';

const useEdgeTypes = () => {
  const edgeTypes = useMemo(
    () => ({ addNode: AddNodeEdge, base: BaseEdge }),
    [],
  );

  return edgeTypes;
};

export default useEdgeTypes;
