/**
 * A hook to manage row selection in a table. It will give the user:
 * itemsSelected | A list of all selected rows
 * allSelected | A boolean value that shows if all rows are selected or not.
 * selectAllItemsOnPage | A function to toggle all rows, if there is at least one row selected it will firstly deselect all and then select all
 * toggleRowSelect | A function to toggle one row selection
 * checkIfSelected | A function that checks if a row is selected by its id
 * selectAllItems | A function that toggles selection on all pages
 * deselectAllItems | A function that removes all selection
 *
 * It expects:
 *   - no parameters
 */

import { useState } from 'react';
import { deduplicated } from '~/util/array';

type selectAllFunction = (
  selectedItemIds: Array<string>,
  setSelected: boolean,
) => void;
type selectFunction = (id: string) => void;

export type ReturnProps = {
  itemsSelected: Array<string>;
  allSelected: boolean;
  selectAllItemsOnPage: selectAllFunction;
  toggleRowSelect: selectFunction;
  checkIfSelected: (id: string) => boolean;
  selectAllItems: (items: Array<string>) => void;
  deselectAllItems: () => void;
};

const useRowSelect = (): ReturnProps => {
  const [itemsSelected, setItemsSelected] = useState<Array<string>>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const toggleRowSelect = (id: string): void => {
    let newArr;
    !checkIfSelected(id)
      ? (newArr = [...itemsSelected, id])
      : (newArr = itemsSelected.filter(item => item !== id));

    setItemsSelected(newArr);
  };

  const selectAllItemsOnPage = (
    ids: Array<string>,
    shouldBeSelected: boolean,
  ): void => {
    let newArr;
    shouldBeSelected
      ? (newArr = [...itemsSelected, ...ids])
      : (newArr = itemsSelected.filter(item => !ids.includes(item)));

    setItemsSelected(newArr);
  };

  const selectAllItems = (ids: Array<string>): void => {
    setAllSelected(!allSelected);

    if (allSelected) {
      setItemsSelected([]);
    } else {
      const newArr = [...itemsSelected, ...ids];
      const duplicatesRemoved = deduplicated(newArr, id => id);
      setItemsSelected(duplicatesRemoved);
    }
  };

  const checkIfSelected = (id: string): boolean => itemsSelected.includes(id);

  const deselectAllItems = () => {
    setItemsSelected([]);
    setAllSelected(false);
  };

  return {
    itemsSelected,
    allSelected,
    selectAllItemsOnPage,
    toggleRowSelect,
    checkIfSelected,
    selectAllItems,
    deselectAllItems,
  };
};

export default useRowSelect;
