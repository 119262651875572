import { Editor, Element, Range, Transforms } from 'slate';
import ELEMENTS from '../components/elements/elementsEnum';
import { getFullRange, getElementWithType } from '../commands';
import { equals } from 'ramda';

/**
 * Used to replace the content of the signature element.
 * SignatureElement can never be removed from the editor
 * but its content can be removed & reinserted
 */
export const SIGNATURE_CONTAINER_ID = 'dh-signature-container';

const withSignature = (editor: Editor) => {
  const { deleteBackward, deleteForward, normalizeNode, apply, insertText } =
    editor;

  editor.insertText = text => {
    const fullRange = getFullRange(editor);
    const sigEl = getElementWithType({
      editor,
      type: ELEMENTS.SIGNATURE,
      at: fullRange,
    });

    if (!editor.selection || !sigEl) return insertText(text);

    const includesSignature = sigEl
      ? Range.includes(editor.selection, sigEl.path)
      : null;

    // prevent typing inside Signature container when there is selection outside of the container
    if (includesSignature) {
      const previous = Editor.previous(editor, {
        mode: 'lowest',
        at: sigEl.path,
      });
      const next = Editor.next(editor, {
        mode: 'lowest',
        at: sigEl.path,
      });

      const selectedElementsBefore =
        previous && Range.includes(editor.selection, previous[1]);
      const selectedElementsAfter =
        next && Range.includes(editor.selection, next[1]);

      if (selectedElementsBefore) {
        Transforms.setSelection(editor, Editor.range(editor, previous[1]));
      } else if (selectedElementsAfter) {
        Transforms.setSelection(editor, Editor.range(editor, next[1]));
      }
    }

    return insertText(text);
  };

  editor.apply = operation => {
    // prevent deletion when signature element is selected (with selectAll etc) and
    // a key is pressed or another element is inserted from the toolbar
    if (
      operation.type === 'remove_node' &&
      Element.isElement(operation.node) &&
      operation.node.type === ELEMENTS.SIGNATURE
    ) {
      return;
    }

    // signature element and its first container cannot be duplicated when pressing Enter key
    if (operation.type === 'split_node' && 'type' in operation.properties) {
      if (operation.properties.type === ELEMENTS.SIGNATURE) return;
      if (operation.properties.attributes?.id === SIGNATURE_CONTAINER_ID)
        return;
    }

    return apply(operation);
  };

  editor.deleteBackward = unit => {
    const signatureEl = getElementWithType({
      editor,
      type: ELEMENTS.SIGNATURE,
      mode: 'all',
    });

    // signature element's last child cannot be deleted
    if (signatureEl && signatureEl.element) {
      const isEmpty = Editor.isEmpty(editor, signatureEl.element);
      const isOnlyChildEmpty =
        signatureEl.element.children &&
        signatureEl.element.children.length === 1 &&
        Element.isElement(signatureEl.element.children[0]) &&
        Editor.isEmpty(editor, signatureEl.element.children[0]);

      if (isEmpty || isOnlyChildEmpty) {
        return;
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = unit => {
    const signatureEl = getElementWithType({
      editor,
      type: ELEMENTS.SIGNATURE,
      mode: 'all',
      at: getFullRange(editor),
    });

    if (editor.selection && signatureEl) {
      const focus = editor.selection.focus;

      // get last offset of the line that has the cursor
      const lastPointInLine = Editor.end(
        editor,
        editor.selection.anchor.path.slice(0, -1),
      );

      // cursor is at the end of the line
      const onEndOfLine = equals(focus, lastPointInLine);

      const nextPathIsSignature =
        signatureEl.path?.[0] === focus?.path?.[0] + 1;

      if (onEndOfLine && nextPathIsSignature) {
        return;
      }
    }

    deleteForward(unit);
  };

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === ELEMENTS.SIGNATURE) {
      const isEmpty = Editor.isEmpty(editor, node);

      // Always have the div inside Signature container that helps replace the signature's content
      if (isEmpty) {
        // first insert the container
        Transforms.wrapNodes(
          editor,
          {
            type: ELEMENTS.DIV,
            // it will get an empty text node as a child
            children: [],
            attributes: {
              id: SIGNATURE_CONTAINER_ID,
            },
          },
          { at: [...path, 0] },
        );

        // then wrap its children with a div
        Transforms.wrapNodes(
          editor,
          {
            type: ELEMENTS.DIV,
            children: [],
          },
          { at: [...path, 0, 0] },
        );
      }

      // Make sure that there is always empty space before the signature element
      const previous = Editor.previous(editor, {
        at: path,
        mode: 'all',
      });

      if (!previous) {
        Transforms.insertNodes(
          editor,
          [{ type: ELEMENTS.DIV, children: [{ text: '' }] }],
          { at: [path[0] - 1] },
        );
      }
    }

    return normalizeNode(entry);
  };

  return editor;
};

export default withSignature;
