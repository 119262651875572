import Catalog from '~/Catalog';
import { OptionOf } from '~/components/molecule/Dropdown';
import usePermissions from '~/hooks/usePermissions';
import useUserRights from '~/hooks/useUserRights';
import canSeeRoute from '~/util/canSeeRoute';

const useMenuOptions = () => {
  const { view: mayViewInvoices } = useUserRights({ category: 'Invoices' });
  const { view: mayViewSubscription } = useUserRights({
    category: 'Subscription',
  });
  const mayViewAutomation = usePermissions(['root.automation']).allowed;

  return allMenuOptions.filter(({ payload }) =>
    canSeeRoute(
      { mayViewInvoices, mayViewSubscription, mayViewAutomation },
      payload,
    ),
  );
};

export default useMenuOptions;

const HELPDESK_LINK = 'https://help.dathuis.nl';

const text = {
  myProfileLabel: 'Mijn profiel',
  officesLabel: Catalog.officesLabel,
  usersLabel: Catalog.usersLabel,
  accountLabel: Catalog.accountLabel,
  subscriptionLabel: Catalog.subscriptionLabel,
  invoicesLabel: Catalog.invoicesLabel,
  helpLabel: 'Help',
  automationLabel: Catalog.automationLabel,
  widgetLabel: Catalog.widgetLabel,
  brandLabel: Catalog.brandLabel,
};

const allMenuOptions: Array<OptionOf<string>> = [
  {
    label: text.myProfileLabel,
    icon: { name: 'user' },
    payload: '/-/settings/profile',
    key: 'nav-link-settings-dropdown-profile',
  },
  {
    label: text.officesLabel,
    icon: { name: 'office' },
    payload: '/-/settings/offices',
    key: 'nav-link-settings-dropdown-offices',
  },
  {
    label: text.usersLabel,
    icon: { name: 'users' },
    payload: '/-/settings/users',
    key: 'nav-link-settings-dropdown-users',
  },
  {
    label: text.accountLabel,
    icon: { name: 'briefcase' },
    payload: '/-/settings/account',
    key: 'nav-link-settings-dropdown-account',
  },
  {
    label: text.brandLabel,
    icon: { name: 'star' },
    payload: '/-/settings/brand',
    key: 'nav-link-settings-dropdown-brand',
  },
  {
    label: text.subscriptionLabel,
    icon: { name: 'file-text' },
    payload: '/-/settings/subscription',
    key: 'nav-link-settings-dropdown-subscription',
  },
  {
    label: text.invoicesLabel,
    icon: { name: 'file' },
    payload: '/-/settings/invoices',
    key: 'nav-link-settings-dropdown-invoices',
  },
  {
    label: Catalog.walletLabel,
    icon: { name: 'credit-card' },
    payload: '/-/settings/wallet',
    key: 'nav-link-settings-dropdown-wallet',
  },
  {
    label: text.helpLabel,
    icon: { name: 'question-mark' },
    payload: HELPDESK_LINK,
    key: 'nav-link-settings-dropdown-help',
  },
];
