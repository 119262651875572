import React, { useCallback, useContext } from 'react';
import {
  LinkProps as ReachLinkProps,
  Link as ReachLink,
  LinkGetProps,
} from '@gatsbyjs/reach-router';
import styled, { css } from 'styled-components';
import RouterTransitionContext from '~/contexts/RouterTransitionContext';
import { useLocation } from '@gatsbyjs/reach-router';
import { isString } from 'lodash';
import { omit } from 'ramda';

export type Props = {
  'data-testid'?: string;

  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  to?: string;
  /**
   * Used to determine if links should match partially or completely for active styles
   */
  partiallyActive?: boolean;
  disabled?: boolean;

  className?: string;
} & ReachLinkProps<any>;

const isExternalURL = (url: string | undefined, currentHost: string) => {
  // Return as plain anchor
  if (!url) return true;
  if (!isString(url)) return false;
  if (url.startsWith('http')) return new URL(url).host !== currentHost;

  return false;
};

export const ExternalLink: React.FCC<Omit<Props, 'to'> & { to?: string }> = ({
  onClick,
  dataTestId,
  to,
  children,
  ...props
}) => (
  <Anchor
    href={to}
    {...omit(['ref'], props)}
    data-testid={dataTestId}
    onClick={onClick}
  >
    {children}
  </Anchor>
);

const isPartiallyActive =
  (partiallyActive: boolean) =>
  ({ isPartiallyCurrent, isCurrent }: LinkGetProps) =>
    (partiallyActive ? isPartiallyCurrent : isCurrent)
      ? { ['data-active']: true }
      : {};

const InternalLink: React.FCC<Props> = ({
  onClick,
  dataTestId,
  to,
  children,
  partiallyActive = false,
  ...props
}) => (
  <StyledReachLink
    to={to}
    data-testid={dataTestId ?? props['data-testid']}
    getProps={isPartiallyActive(partiallyActive)}
    onClick={onClick}
    {...omit(['ref'], props)}
  >
    {children}
  </StyledReachLink>
);

export const Link: React.FCC<Props> = ({ onClick, to, ...props }) => {
  const { host } = useLocation();
  const { canLeave } = useContext(RouterTransitionContext);
  const clickHandler = useCallback(
    event => {
      if (!canLeave) {
        const userConfirmedLeave = global.window.confirm(
          'Let op: openstaande wijzigingen',
        );
        if (!userConfirmedLeave) event.preventDefault();
      }
      if (onClick) return onClick(event);
    },
    [canLeave, onClick],
  );

  const C = isExternalURL(to, host) ? ExternalLink : InternalLink;

  return <C {...props} to={to} onClick={clickHandler} />;
};

export const linkStyles = ({ theme }) => css`
  color: ${theme.color('secondary')};
  text-decoration: underline;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    color: ${theme.color('secondary', 'dark')};
  }
`;

// @ts-ignore
const StyledReachLink = styled(props => <ReachLink {...props} />)<{}>(
  linkStyles,
);
const Anchor = styled.a<{}>`
  ${linkStyles}
`;

export default Link;
