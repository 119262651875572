import React from 'react';
import styled, { css } from 'styled-components';
import { atom, atomFamily, selector } from 'recoil';
import { AppBbWaardecheck__Input } from '~/graphql/types';
import { Link } from '@gatsbyjs/reach-router';
import { StateId } from '..';

export const appWaardecheckImageStash = atom<{ [s3Key: string]: string }>({
  key: `appWaardecheckImageStash`,
  default: {},
});

export const appWaardecheckState = atomFamily<
  AppBbWaardecheck__Input | null,
  StateId
>({
  key: `appWaardecheckState`,
  default: null,
});

export const appWaardecheckError = atom<{
  [id: string]: { link: string; error: string };
}>({
  key: `appWaardecheckError`,
  default: {},
});

export const appWaardecheckErrorDerived = atom<{
  errorsByPath: { [path: string]: number };
  errorCount: number;
  errorDescription?: JSX.Element;
}>({
  key: `appWaardecheckErrorDerived`,
  default: {
    errorsByPath: {},
    errorCount: 0,
  },
});

export const appWaardecheckErrorSelector = selector<{
  [id: string]: { link: string; error: string };
}>({
  key: 'something',
  get: ({ get }) => get(appWaardecheckError),
  set: ({ set }, newValue) => {
    const errors = Object.values(newValue);

    const errorsByPath = errors.reduce<{ [path: string]: number }>(
      (prev, { link }) => {
        const idPosition = link.indexOf('#');
        const appPath = link.includes('matrixian')
          ? '/-/apps/matrixian-waardecheck'
          : '/-/apps/bb-waardecheck';
        let path: string = link.substring(appPath.length, idPosition) || '';

        if (path.length !== 1 && path.endsWith('/')) {
          // Remove trailing "/"" to have consistent error reporting
          path = path.substr(0, path.length - 1);
        }

        if (prev[path] == null) prev[path] = 0;
        prev[path]++;

        return prev;
      },
      {},
    );

    let errorDescription: JSX.Element | undefined = undefined;

    if (errors.length !== 0) {
      const message = errors.length === 1 ? 'fout' : 'fouten';
      const { link } = errors[0];

      errorDescription = (
        <ErrorDescription>
          <ErrorLink to={link}>
            {errors.length} {message}
          </ErrorLink>
        </ErrorDescription>
      );
    }

    set(appWaardecheckError, newValue);
    set(appWaardecheckErrorDerived, {
      errorsByPath,
      errorCount: errors.length,
      errorDescription,
    });
  },
});

const ErrorLink = styled(Link)<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);

const ErrorDescription = styled.span<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);
