import React from 'react';
import styled, { css } from 'styled-components';
import Icon, { type Props as IconProps } from '../../../../atom/Icon';
import { Label } from '~/components/atom/Typography';
import Link from '~/components/molecule/Link';
import { animated, useTransition } from 'react-spring';
import JustificationContainer from '~/components/atom/JustificationContainer';
import LimitedIcon from '~/components/molecule/LimitedIcon';
import usePermissions from '~/hooks/usePermissions';
import type { PermissionId } from '~/graphql/types';

export type Props = {
  icon: IconProps;
  label: React.ReactNode;
  to?: string;
  permissionId?: PermissionId;
  onClick?: () => void;
  expanded?: boolean;
};

const NavigationItem: React.FCC<Props> = ({
  dataTestId,
  icon,
  label,
  to,
  expanded = false,
  permissionId,
  onClick,
}) => {
  const allowed = usePermissions(permissionId ? [permissionId] : []).allowed;

  const transitions = useTransition(expanded, {
    from: { transform: `translateX(-40px)`, opacity: 0 },
    enter: { transform: `translateX(0px)`, opacity: 1 },
    leave: { transform: `translateX(-40px)`, opacity: 0 },
  });

  return (
    <Container
      data-testid={dataTestId}
      to={to ?? '#'}
      $expanded={expanded}
      onClick={onClick}
      // Everything extends from `/-/` aka dashboard
      // So that one need to match exact. The rest can be partial
      partiallyActive={to !== '/-/'}
    >
      <JustificationContainer width="100%" gap="m" align="center" height="100%">
        <IconContainer>
          <Icon {...icon} size="l" /> {!allowed && <LimitedIcon size="base" />}
        </IconContainer>
        {transitions(
          (style, item) =>
            item && (
              <animated.div style={style}>
                <Label
                  margin={[null]}
                  color={{ group: 'primary', variant: 'text' }}
                  whiteSpace="nowrap"
                  fontWeight="extraLight"
                  size="base"
                >
                  {label}
                </Label>
              </animated.div>
            ),
        )}
      </JustificationContainer>
    </Container>
  );
};

const Container = styled(Link)<{ $expanded: boolean }>(
  ({ theme, $expanded }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${$expanded ? '100%' : theme.space('xxl')};
    height: ${theme.space('xxl')};

    background: transparent;
    border-radius: ${theme.getTokens().border.radius.base};

    padding-left: ${theme.space('xxs')};

    color: ${theme.color('primary', 'text')};
    font-weight: ${theme.fontWeight('extraLight')};
    text-decoration: none;

    transition: all 0.2s ease-out;

    &:hover,
    &[data-active] {
      color: ${theme.color('primary', 'text')};
      background: ${theme.color('primary', 'light')};
    }
  `,
);

const IconContainer = styled.div<{}>(
  () => css`
    position: relative;
  `,
);

export default NavigationItem;
