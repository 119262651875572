import { ApolloQueryResult } from '@apollo/client';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import AddAPIBlock from '~/components/organism/AddAPIBlock';
import useConfirmModal from '~/hooks/useConfirmModal';
import APIBlock, {
  Variant as APIVariant,
} from '~/components/organism/APIBlock';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { Heading5, Variant } from '~/components/atom/Typography';
import {
  AppStatus_Realworks_RealworksTokenContainer,
  Exact,
  GetAppStatusRealworksQuery,
  RealworksTokenStatus,
  RealworksTokenType,
  useUpdateRealworksAppStatusMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';

import formatToastMessage from '~/util/formatToastMessage';
import AddTokenModal from './components/AddTokenModal';
import AssignContactWithSave from './components/AssignContactWithSave';
import TEST_ID from './index.testid';
import EditableText from '~/components/organism/EditableText';
import { isNil } from 'ramda';
import useErrorReporter from '~/hooks/useErrorReporter';

export type Props = {
  refreshAppStatus: (
    variables?:
      | Partial<
          Exact<{
            accountId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetAppStatusRealworksQuery>>;
} & Omit<AppStatus_Realworks_RealworksTokenContainer, '__typename'>;

const text = {
  deleteConnectionModal: {
    title: 'Koppeling verwijderen',
    message: 'Weet je zeker dat je deze koppeling wilt verwijderen?',
  },
  deleteTokenModal: {
    title: 'Token verwijderen',
    message: 'Weet je zeker dat je dit token wilt verwijderen?',
  },
  confirm: 'Verwijderen',
};

const TokenContainer: React.FCC<Props> = ({
  officeId,
  userId,
  wonen,
  id,
  name,
  refreshAppStatus,
  relatie,
  ...rest
}) => {
  const errorReporter = useErrorReporter();
  const addToast = useAddToast();
  const [addToken, setAddToken] = useState<RealworksTokenType | null>(null);
  const { id: accountId } = useCurrentAccount();
  const [updateRealworksAppStatus, { loading }] =
    useUpdateRealworksAppStatusMutation({});

  const {
    setShowModal: setDeleteConnectionConfirmModal,
    modal: deleteConnectionConfirmModal,
  } = useConfirmModal({
    level: 'danger',
    labels: text.deleteConnectionModal,
    buttons: [
      {
        onClick: async () =>
          updateRealworksAppStatus({
            variables: {
              accountId,
              deleteTokenContainer: { tokenContainerId: id },
            },
          }).then(() => refreshAppStatus()),
        label: text.confirm,
      },
    ],
  });

  const [tokenTypeToDelete, setTokenTypeToDelete] =
    useState<RealworksTokenType | null>(null);
  const {
    setShowModal: setDeleteTokenConfirmModal,
    modal: deleteTokenConfirmModal,
  } = useConfirmModal({
    level: 'danger',
    labels: text.deleteTokenModal,
    buttons: [
      {
        label: text.confirm,
        onClick: async () => {
          if (isNil(tokenTypeToDelete)) {
            return errorReporter.captureException(
              new Error('tokenTypeToDelete is null in Realworks'),
            );
          }

          void updateRealworksAppStatus({
            variables: {
              accountId,
              deleteToken: {
                tokenContainerId: id,
                type: tokenTypeToDelete,
              },
            },
          }).then(onUpdateCompleted);
        },
      },
    ],
  });

  const onUpdateCompleted = async ({ errors }) => {
    if (errors && errors.length !== 0) {
      return addToast([
        formatToastMessage(
          'Er is iets fout gegaan bij het verwijderen van het token, probeer het later opnieuw.',
          'danger',
        ),
      ]);
    }

    return refreshAppStatus();
  };

  return (
    <>
      {deleteTokenConfirmModal}
      {deleteConnectionConfirmModal}

      <Container data-testid={TEST_ID.CONTAINER} {...rest}>
        <JustificationContainer
          justification="space-between"
          align="end"
          margin={[null, null, 's']}
          nonResponsive
        >
          <StyledEditableText
            text={name}
            size="m"
            iconSize="base"
            onSave={updatedName => {
              if (name !== updatedName) {
                void updateRealworksAppStatus({
                  variables: {
                    accountId,
                    updateTokenContainer: {
                      tokenContainerId: id,
                      officeId,
                      userId,
                      name: updatedName,
                    },
                  },
                }).then(async ({ errors }) => {
                  if (errors && errors.length !== 0) {
                    return addToast([
                      formatToastMessage(
                        'Er is iets fout gegaan bij het opslaan, probeer het later opnieuw.',
                        'danger',
                      ),
                    ]);
                  }
                  return refreshAppStatus();
                });
              }
            }}
            dataTestId={TEST_ID.NAME}
          />
          <TextButton
            padding={[null]}
            disabled={loading}
            onClick={() => setDeleteConnectionConfirmModal(true)}
            label="Koppeling verwijderen"
            appearance="danger"
            dataTestId={TEST_ID.DELETE_CONTAINER}
          />
        </JustificationContainer>

        <AssignContactWithSave
          userId={userId}
          officeId={officeId}
          loading={loading}
          onSave={({ officeId, userId }) => {
            void updateRealworksAppStatus({
              variables: {
                accountId,
                updateTokenContainer: {
                  tokenContainerId: id,
                  officeId,
                  userId,
                  name,
                },
              },
            }).then(async ({ errors }) => {
              if (errors && errors.length !== 0) {
                return addToast([
                  formatToastMessage(
                    'Er is iets fout gegaan bij het opslaan, probeer het later opnieuw.',
                    'danger',
                  ),
                ]);
              }

              return refreshAppStatus();
            });
          }}
        />

        <Heading5 margin={['m', null, null, null]} variant={Variant.primary}>
          API koppelingen
        </Heading5>
        <BlockContainer>
          {relatie ? (
            <APIBlock
              heading="Relaties"
              cancelText="Relatie API toegang verwijderen"
              disabled={loading}
              description={apiStatusToDescription(relatie.status)}
              variant={apiStatusToVariant(relatie.status)}
              token={relatie.token}
              onDelete={() => {
                setTokenTypeToDelete(RealworksTokenType.Relatie);
                setDeleteTokenConfirmModal(true);
              }}
            />
          ) : (
            <AddAPIBlock
              heading="Relaties"
              label="Toevoegen"
              dataTestId={TEST_ID.ADD_RELATIE}
              description={
                <>
                  <span>Met deze API haal je contacten uit Realworks.</span>
                  <br />
                  <span>Deze API is nog niet geconfigureerd.</span>
                </>
              }
              onAdd={() => setAddToken(RealworksTokenType.Relatie)}
            />
          )}

          {wonen ? (
            <APIBlock
              heading="Wonen"
              cancelText="Wonen API toegang verwijderen"
              disabled={loading}
              description={apiStatusToDescription(wonen.status)}
              variant={apiStatusToVariant(wonen.status)}
              token={wonen.token}
              onDelete={() => {
                setTokenTypeToDelete(RealworksTokenType.Wonen);
                setDeleteTokenConfirmModal(true);
              }}
            />
          ) : (
            <AddAPIBlock
              heading="Wonen"
              label="Toevoegen"
              dataTestId={TEST_ID.ADD_WONEN}
              disabled={loading}
              description={
                <>
                  <span>Met deze API stuur je contacten naar Realworks.</span>
                  <br />
                  <span>Deze API is nog niet geconfigureerd.</span>
                </>
              }
              onAdd={() => setAddToken(RealworksTokenType.Wonen)}
            />
          )}
        </BlockContainer>
      </Container>
      {addToken !== null && (
        <AddTokenModal
          containerId={id}
          tokenType={addToken}
          onClose={() => {
            setAddToken(null);
            void refreshAppStatus();
          }}
        />
      )}
    </>
  );
};

export const apiStatusToVariant = (
  status: RealworksTokenStatus | undefined | 'skipped',
): APIVariant => {
  switch (status) {
    case RealworksTokenStatus.Active:
      return 'success';
    case RealworksTokenStatus.Pending:
      return 'primary';
    case RealworksTokenStatus.Error:
    case 'skipped':
      return 'danger';
    default:
      return 'primary';
  }
};

export const apiStatusToDescription = (
  status: RealworksTokenStatus | undefined | 'skipped',
): React.ReactNode => {
  switch (status) {
    case RealworksTokenStatus.Pending:
      return 'De koppeling is in afwachting van goedkeuring.';
    case RealworksTokenStatus.Error:
      return 'Er is iets misgegaan met het opzetten van de koppeling voor deze vestiging. Klik hieronder om het opnieuw te proberen.';
    case 'skipped':
      return 'Deze koppeling is overgeslagen';
    case RealworksTokenStatus.Active:
    default:
      return null;
  }
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('base')};
  `,
);

const StyledEditableText = styled(EditableText)<{}>`
  width: 60%;
`;

export const BlockContainer = styled.div<{}>(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${theme.space('l')};
    margin-bottom: ${theme.space('l')};
  `,
);

export default TokenContainer;
