import React from 'react';
import styled, { css } from 'styled-components';

import HideableComponent from '~/components/atom/HidableComponent';
import Button from '~/components/atom/Button';
import TEST_ID from './index.testid';

export type Props = {
  title: string;
  confirmText: string;
  handleAction: (confirm: boolean) => void;
  disabled?: boolean;
};

const BasePopover: React.FCC<Props> = ({
  handleAction,
  children,
  confirmText,
  title,
  disabled = false,
}) => (
  <HideableComponent onClickOutside={() => handleAction(false)} allowBubble>
    {ref => (
      <ModalPopover ref={ref} data-testid={TEST_ID.CONTAINER}>
        <TopContainer>
          <SubHeader>{title}</SubHeader>
          <ChildrenContainer>{children}</ChildrenContainer>
        </TopContainer>

        <BottomContainer>
          <SubmitButton
            size="medium"
            onClick={() => handleAction(true)}
            data-testid={TEST_ID.SUBMIT_BUTTON}
            disabled={disabled}
            label={confirmText}
          />
        </BottomContainer>
      </ModalPopover>
    )}
  </HideableComponent>
);

const SubmitButton = styled(Button)<{}>`
  width: 100%;
  justify-content: flex-start;
  ${({ theme }) => css`
    padding-left: ${theme.space('m')};
  `}
`;

const SubHeader = styled.span<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    color: ${theme.color('primary')};
    margin: ${theme.space('xl')} 0;
  `,
);

const TopContainer = styled.div<{}>`
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.space('m')};
  `}
`;

const BottomContainer = styled.div<{}>`
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.color('tertiary', 'light')};
    padding: ${theme.space('m')};
  `}
`;

const ChildrenContainer = styled.div<{}>(
  ({ theme }) => css`
    padding-top: ${theme.space('m')};
    word-break: break-word;
  `,
);

const MODAL_POPOVER_WIDTH = 300;
const ModalPopover = styled.div<{}>`
  display: flex;
  flex-direction: column;
  position: absolute;

  ${({ theme }) => css`
    z-index: ${theme.getTokens().zIndex.top};
    width: ${MODAL_POPOVER_WIDTH}px;
    border-radius: ${theme.getTokens().border.radius.s};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('tertiary', 'light')};
    background-color: ${theme.color('white')};
    box-shadow: ${theme.getTokens().boxShadow.base};
    margin-top: ${theme.space('xxs')};
  `}
`;

export default BasePopover;
