import type { ContactAssignedToOfficeFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Contact_Details__Input } from '~/graphql/types';

import asAssignedToFilterInput from '../../Primitive/AssignedTo/asAssignedToFilterInput';

const asAssignedToOfficeFilterInput = (
  filter: ContactAssignedToOfficeFilter,
): Node_NodeContainer_Leaf_Contact_Details__Input | null => {
  const assignedToFilter = asAssignedToFilterInput(filter.assignedFilter);

  if (assignedToFilter == null) {
    return null;
  }

  return {
    officeId: assignedToFilter,
  };
};

export default asAssignedToOfficeFilterInput;
