/**
 * pads a string on the left hand side
 * @param {number} amount - How much should to total length be
 * @param {string} str - The string you want to pad
 * @param {string} char - What character should be used to pad
 */
const leftPad = (
  amount: number,
  str?: string | null,
  char?: string,
): string => {
  if (!str) return '';

  const strLength = str.length;
  const diff = amount - strLength;
  const padding = new Array(diff).fill(char ?? '*').join('');

  return `${padding}${str}`;
};
export default leftPad;
