import React from 'react';
import { AppBbWaardecheckImage__Input } from '~/graphql/types';
import ImageUploadComponent from '~/components/organism/ImageUploadComponent';
import useImageUpload from '~/hooks/useImageUpload';
import { useSetRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import Description from '../Description';
import { appWaardecheckImageStash } from '../../state';

export type Props = {
  initialUrl?: string;
  title?: string;
  description?: string;
  s3Key?: string;
  filename: string;
  onChange: (value?: AppBbWaardecheckImage__Input, url?: string) => void;
};

const ImageInput: React.FCC<Props> = ({
  dataTestId,
  initialUrl,
  title,
  description,
  s3Key,
  filename,
  onChange,
}) => {
  const imageStash = useSetRecoilState(appWaardecheckImageStash);

  const fallbackImage = useImageUpload({
    initialUrl,
    s3Key,
    onUpload: ({ s3Key, url }) => {
      imageStash(prev => ({ ...prev, [s3Key]: url }));
      if (onChange) onChange({ s3key: s3Key });
    },
    onClear: () => onChange && onChange(),
  });

  return (
    <Container>
      {title && <AppEntryItemHeader>{title}</AppEntryItemHeader>}
      {description && <Description>{description}</Description>}
      <ImageUploadComponent
        onDelete={() => fallbackImage.clear()}
        uploading={fallbackImage.uploading}
        imageUrl={fallbackImage.s3Key != null ? fallbackImage.url : null}
        onUpload={file =>
          void fallbackImage.upload({ file: file[0], filename })
        }
        dataTestId={dataTestId}
      />
    </Container>
  );
};

const Container = styled.div<{}>(() => css``);

export default ImageInput;
