import React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import {
  SpanElement,
  type HeadingTwoElement,
} from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import serializeHeading from '../../../utils/serialize/serializeHeading';

export type Props = RenderElementProps & {
  element: SpanElement;
};

const HeadingTwo: React.FCC<Props> = ({ attributes, element, children }) => (
  <Container {...attributes} {...element.attributes}>
    {children}
  </Container>
);

const Container = styled.h2<{}>`
  margin: 0;
`;

export default {
  renderComponent: props => <HeadingTwo {...props} />,
  nodeName: 'H2',
  deserialize: () => ({
    type: ELEMENTS.H2,
  }),
  serialize: (node: HeadingTwoElement, children) =>
    serializeHeading('h2', children),
};
