import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import ErrorMessages from '~/ErrorMessages';

import FullPageInformation from '~/components/template/FullPageInformation';
import { useStartEmailSyncMutation } from '~/graphql/types';
import { getErrorMessages } from '~/util/errorHandling';
import mutationVariablesForState from '../NylasAuthoriseEndpointV1/utils/mutationVariablesForState';
import { NylasState } from '../NylasAuthoriseEndpointV1/types';
import Loading from '~/components/atom/Loading';
import useTimeout from '~/hooks/useTimeout';

const text = {
  alreadySynced: 'Al gesynchroniseerd',
  alreadySyncedExplanation: (email: string | null) =>
    `Het e-mail account ${email || ''} is al gesynchroniseerd in het systeem.`,
  invalidStateTitle: 'Oeps!',
  invalidState: (email: string): string =>
    `Er is iets misgegaan bij het synchroniseren van het e-mail account ${email}. Probeer het nog eens.`,
  invalidStateNoEmail:
    'Er is iets misgegaan bij het synchroniseren van het e-mail account. Probeer het nog eens.',
  successTitle: 'E-mail gekoppeld',
  successExplanation:
    'Het synchroniseren van je e-mail account is gestart. Vanaf nu zullen alle e-mails van en naar contacten automatisch bij het contact getoond worden. Dit pop-up venster wordt over 5 seconden gesloten.',
  buttonText: 'Voltooien',
  onButtonClickErrorText: 'Probeer opnieuw',
};

export type Props = {
  state: NylasState;
  code: string;
};

const StartEmailSyncComponent: React.FCC<Props> = ({ state, code }) => {
  const mutationVariables = mutationVariablesForState(state, code);

  const [startEmailSync, { data, error, loading }] =
    useStartEmailSyncMutation();

  const email = data?.startEmailSync.email ?? null;

  const mutationError = getErrorMessages(error);

  useEffect(() => {
    if (mutationVariables) {
      void startEmailSync({
        variables: mutationVariables,
      });

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closePopup = () => window.close();

  if (mutationError.length !== 0) {
    if (
      mutationError.some(err =>
        err.startsWith(ErrorMessages.emailAlreadySynchronised),
      )
    ) {
      return (
        <FullPageInformation
          dataTestId="email-sync-error"
          title={text.alreadySynced}
          explanation={text.alreadySyncedExplanation(email)}
          button={{ onClick: closePopup, label: text.buttonText }}
        />
      );
    }

    return (
      <FullPageInformation
        dataTestId="email-sync-error"
        title={text.invalidStateTitle}
        explanation={
          typeof email === 'string'
            ? text.invalidState(email)
            : text.invalidStateNoEmail
        }
        button={{ onClick: closePopup, label: text.buttonText }}
      />
    );
  }

  if (loading) {
    return (
      <Container data-testid="email-sync-loading">
        <Loading />
      </Container>
    );
  }

  return <SuccessComponent />;
};

const SuccessComponent = () => {
  useTimeout(() => window.close(), 5000);

  return (
    <FullPageInformation
      title={text.successTitle}
      explanation={text.successExplanation}
      dataTestId="email-sync-success"
      button={{ onClick: () => window.close(), label: text.buttonText }}
    />
  );
};

const Container = styled.div<{}>`
  position: relative;
  min-height: 100%;

  ${({ theme }) => css`
    background-color: ${theme.color('white', 'dark')};
  `};
`;

export default StartEmailSyncComponent;
