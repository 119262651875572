import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  App_Hypotheekbond_SettingsFragment,
  App_Hypotheekbond__Input,
  UpdateAppHypotheekbondMutation,
  useUpdateAppHypotheekbondMutation,
} from '~/graphql/types';

import InputGroup from '~/components/page/Apps/components/InputGroup';
import TEST_ID from './index.testid';
import Description from '~/components/page/Apps/components/WaardecheckTemplate/components/Description';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import AppSectionHeader from '~/components/page/Apps/components/AppSectionHeader';
import ColorInput from '~/components/molecule/ColorInput';
import findDifference from '~/util/findDifference';
import { omit } from 'ramda';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Explanation from '~/components/page/Apps/components/WaardecheckTemplate/components/Explanation';
import BrandSettingsCTA from '~/components/organism/BrandSettingsCTA';
import brandValuesToHypotheekbondValues from './utils/brandValuesToHypotheekbondValues';
import FooterSaveBar from '~/components/organism/SaveBar/components/FooterSaveBar';

export type Props = RouteComponentProps & {
  settings: App_Hypotheekbond_SettingsFragment;
  updateQuery: (
    data: UpdateAppHypotheekbondMutation | null | undefined,
  ) => void;
};

export type ColorProperty = Exclude<
  keyof App_Hypotheekbond_SettingsFragment,
  '__typename'
>;
export const colorProperties: Array<ColorProperty> = [
  'primaryColor',
  'primaryFontColor',
  'secondaryColor',
  'secondaryFontColor',

  'successColor',
  'failureColor',

  'backgroundColor',
  'buttonColor',
  'borderColor',
  'cardColor',
  'highlightColor',
];

const translationsMap: Record<ColorProperty, string> = {
  backgroundColor: 'Achtergrond kleur',
  borderColor: 'Border kleur',
  buttonColor: 'Knop kleur',
  cardColor: 'Kaart kleur',
  failureColor: 'Fout kleur',
  highlightColor: 'Uitlicht kleur',
  primaryColor: 'Primaire kleur',
  primaryFontColor: 'Primaire tekst kleur',
  secondaryColor: 'Secondaire kleur',
  secondaryFontColor: 'Secondaire tekst kleur',
  successColor: 'Succes kleur',
};

const text = {
  explanation: (
    <>
      Pas hier de kleuren van de app aan, alleen als deze moeten afwijken van de
      instellingen in de widget. Wil je voor alle apps de kleuren instellen? Ga
      dan naar de widget en wijzig deze daar.
      <br />
      <br />
      Tip: gebruik de hoofdkleuren van je website om een consistente huisstijl
      uit te stralen.
    </>
  ),
  description: 'Personaliseer de waardecheck met jouw huisstijlkleuren.',
  appSelectionHeader: 'Huisstijl',
  successMessage: 'Kleurinstellingen succesvol opgeslagen',
  errorMessage:
    'Er is iets mis gegaan bij het opslaan, probeer het later nog eens',
};

const Settings: React.FCC<Props> = ({ settings, updateQuery }) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const [updatedSettings, setUpdatedSettings] =
    useState<App_Hypotheekbond_SettingsFragment>(settings);

  const [updateHypotheekbond, { loading }] =
    useUpdateAppHypotheekbondMutation();

  const updateValue = (key: ColorProperty, value: string) => {
    setUpdatedSettings(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const changeCount = findDifference(updatedSettings, settings).differenceCount;

  return (
    <>
      <AppDetailsContainer
        header="Algemeen"
        icon="gear"
        dataTestId={TEST_ID.CONTAINER}
      >
        <Explanation>{text.explanation}</Explanation>
        <AppSectionHeader>{text.appSelectionHeader}</AppSectionHeader>
        <Description>{text.description}</Description>
        <BrandSettingsCTA
          subjects={['colors']}
          onConfirm={values => {
            const updatedValues: Record<ColorProperty, string> | null =
              brandValuesToHypotheekbondValues(values.colors);
            if (!updatedValues) return;

            Object.keys(updatedValues).map(
              (key: ColorProperty) => void updateValue(key, updatedValues[key]),
            );
          }}
        />

        <SettingsContainer>
          {colorProperties.map(property => (
            <InputGroup key={property}>
              <ColorInput
                value={updatedSettings[property] ?? '#fff'}
                onChange={value => {
                  updateValue(property, value);
                }}
                label={translationsMap[property]}
                dataTestId={`${TEST_ID.COLOR_INPUT}-${property}`}
              />
            </InputGroup>
          ))}
        </SettingsContainer>
      </AppDetailsContainer>
      {changeCount !== 0 && (
        <FooterSaveBar
          disableSave={false}
          loading={loading}
          onSave={async () => {
            const inputFormatted = transformToInput(updatedSettings);
            await updateHypotheekbond({
              variables: {
                accountId,
                update: inputFormatted,
              },
            })
              .then(({ data }) => {
                updateQuery(data);
                addToast([formatToastMessage(text.successMessage, 'success')]);
              })
              .catch(() =>
                addToast([formatToastMessage(text.errorMessage, 'danger')]),
              );
          }}
          onCancel={() => {
            setUpdatedSettings(settings);
          }}
          numberOfChanges={changeCount}
        />
      )}
    </>
  );
};

const transformToInput = (
  settings: App_Hypotheekbond_SettingsFragment,
): App_Hypotheekbond__Input => ({
  settings: omit(['__typename'], settings),
});

const SettingsContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${theme.space('base')};
  `,
);

export default Settings;
