import React, { useContext } from 'react';
import styled from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TaskModalContext, {
  TaskModalContextProps,
} from '~/components/page/Tasks/context/TaskModalContext';
import ContactSearch from '../ContactSearch';
import ContactInTask from './components/ContactInTask';

type Props = {
  error?: string;
  onResetError: () => void;
};

const ContactInformation = ({ error, onResetError }: Props) => {
  const { taskDetails } = useContext<TaskModalContextProps>(TaskModalContext);

  return (
    <Container
      padding={[null, 'l', null, 'l']}
      width="100%"
      data-testid="contact-information"
    >
      {taskDetails !== null && taskDetails?.contactId ? (
        <ContactInTask contactId={taskDetails.contactId} />
      ) : (
        <ContactSearch error={error} onResetError={onResetError} />
      )}
    </Container>
  );
};

const Container = styled(JustificationContainer)<{}>`
  min-height: 100%;
  position: relative;
  overflow-wrap: anywhere;
`;

export default ContactInformation;
