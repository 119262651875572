import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  RESEND_INVITATION_BUTTON: `${cleanedFilename(
    __filename,
  )}-resend-invitation-button`,
  DELETE_USER_BUTTON: `${cleanedFilename(__filename)}-delete-user-button`,
  USER_ROLE_DROPDOWN: `${cleanedFilename(__filename)}-user-role-droppdown`,
};
