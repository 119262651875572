import React from 'react';
import { RenderElementProps, useFocused, useSelected } from 'slate-react';
import { GenericHtmlElement as GenericHtmlElementType } from '~/components/organism/PluginsEditor/types';
import serialize from './serialize';
import { useTheme } from 'styled-components';

export type Props = RenderElementProps & {
  element: GenericHtmlElementType;
};

const selfClosingTags = [
  'area',
  'hr',
  'col',
  'embed',
  'hr',
  'input',
  'param',
  'source',
  'track',
];

const GenericHtmlElement: React.FCC<Props> = ({
  dataTestId,
  attributes,
  children,
  element,
}) => {
  const isVoidElement = selfClosingTags.includes(element.name);
  const theme = useTheme();

  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  if (isVoidElement) {
    return (
      <GenericVoidElement attributes={attributes} element={element}>
        {children}
      </GenericVoidElement>
    );
  }

  const prevStyle = element.attributes?.style;

  const style =
    active && element.name === 'table'
      ? {
          ...prevStyle,
          boxShadow: `0 0 0 3px ${theme.color('tertiary', 'light')}`,
        }
      : prevStyle;

  return React.createElement(
    element.name,
    {
      ['data-testid']: dataTestId,
      ...attributes,
      ...element.attributes,
      style,
    },
    children,
  );
};

export default {
  renderComponent: props => <GenericHtmlElement {...props} />,
  serialize,
};

const GenericVoidElement = ({ attributes, element, children }) => {
  const Element = element.name;

  return (
    <span {...attributes}>
      <Element {...element.attributes} />
      {children}
    </span>
  );
};
