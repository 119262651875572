import React, { useState } from 'react';
import ChangeOrUpdateSubscription from '~/components/page/Settings/Subscription/components/ChangeOrUpdateSubscription';
import useSessionHydration from '../useSessionHydration';

type Args = {
  onComplete?: () => void;
};

const useOnUpgradeSubscription = ({ onComplete }: Args) => {
  const [, refetchSessionHydration] = useSessionHydration();
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => setShowModal(false);

  return {
    onUpgrade: () => setShowModal(true),
    modal: showModal ? (
      <ChangeOrUpdateSubscription
        onClose={hideModal}
        onComplete={() => {
          hideModal();
          if (onComplete) onComplete();

          return refetchSessionHydration();
        }}
      />
    ) : null,
  };
};

export default useOnUpgradeSubscription;
