import React from 'react';
import JustificationContainer, {
  Props as JustificationProps,
} from '~/components/atom/JustificationContainer';

export type Props = JustificationProps;

/** Container with primary background color */
const SectionContainer: React.FCC<Props> = ({ children, ...rest }) => (
  <JustificationContainer
    align="center"
    width="100%"
    backgroundColor={{ group: 'primary', variant: 'translucent' }}
    padding={['xxs', 'base']}
    border={{ radius: 'base' }}
    justification={rest.justification || 'space-between'}
    {...rest}
  >
    {children}
  </JustificationContainer>
);

export default SectionContainer;
