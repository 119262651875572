import type { Node_Condition_AppType__Input } from '~/graphql/types';
import type { AppTypeFilter } from './types.flow';

const asAppTypeFilterInput = (
  filter: AppTypeFilter,
): Node_Condition_AppType__Input => {
  const { eq, negate } = filter;
  return {
    negate,
    eq,
  };
};

export default asAppTypeFilterInput;
