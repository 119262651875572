import React, { useState } from 'react';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import {
  Body,
  Code,
  Heading3,
  Heading4,
  Label,
  Variant,
} from '~/components/atom/Typography';

import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  dataTestId?: string;
};

const WarningMessage: React.FCC<Props> = ({}) => {
  const [open, setOpen] = useState(true);

  return (
    <CollapsibleChevronBlock
      header={
        <JustificationContainer justification="center" gap="xs">
          <Heading3 size="m" margin={[null]} color={{ group: 'warning' }}>
            Instructies - Lees dit eerst
          </Heading3>
        </JustificationContainer>
      }
      isOpen={open}
      variant="warning"
      onToggle={() => setOpen(prev => !prev)}
    >
      <JustificationContainer direction="column" gap="s">
        <Label variant={Variant.primary}>
          <JustificationContainer justification="center" gap="xs">
            <Icon name="exclamation" />
            Deze functie is alleen voor ervaren gebruikers
          </JustificationContainer>
        </Label>
        <Body>
          Hier kun je de HTML van je e-mail aanpassen. Gebruik deze functie niet
          om nieuwe HTML toe te voegen. Gebruik daarvoor de knop &quot;HTML
          invoegen&quot;.
        </Body>
        <Body>
          Bij het invoegen van externe HTML , kunnen onbekende CSS-attributen
          worden toegevoegd. Onze editor behoudt deze om consistentie te
          garanderen en geeft je de vrijheid om verschillende elementen en
          attributen te gebruiken. Deze CSS-attributen kunnen echter onverwachte
          effecten in je e-mails veroorzaken. Je kunt ze met deze modal
          verwijderen of wijzigen.
        </Body>

        <div>
          <Heading4 size="m">Voorwaarden voor gebruik</Heading4>
          <Body>
            <ul>
              <li>
                Als je niet bekend bent met HTML en CSS, vraag dan hulp aan een
                ontwikkelaar om problemen te voorkomen.
              </li>
              <li>
                Zorg ervoor dat alle afbeeldingen correct zijn ingevoegd en
                opgeslagen voordat je deze editor gebruikt om missende
                afbeeldingen te voorkomen.
              </li>
              <li>
                Lettertypes kunnen hier niet worden toegevoegd; deze stel je in
                bij de huisstijl instellingen.
              </li>
              <li>
                Vermijd het wijzigen van variabelen tussen dubbele accolades
                (bijv.&nbsp;
                <Code inline size="m">
                  {`{{ variable }}`}
                </Code>
                ). Het aanpassen van deze variabelen kan ervoor zorgen dat ze
                niet goed werken.
              </li>
            </ul>
          </Body>
        </div>

        <div>
          <Heading4 size="base">Lege Regels Toevoegen</Heading4>
          <Body>
            Om een lege regel toe te voegen, gebruik&nbsp;
            <Code inline size="m">
              {'<div><br/><div>'}
            </Code>
            op het hoogste niveau. Dit is de beste manier om lege regels toe te
            voegen in deze editor.
          </Body>
        </div>

        <div>
          <Heading4 size="base">Afbeeldingen</Heading4>
          <Body>
            Voeg geen afbeeldingen toe in deze editor. Gebruik daarvoor de knop
            &quot;Afbeelding invoegen&quot;. Voor geüploade afbeeldingen,
            verander de volgende attributen niet:
            <ul>
              <li>
                <Code inline size="m">
                  src
                </Code>
              </li>
              <li>
                Alle attributen die beginnen met&nbsp;
                <Code inline size="m">
                  data-
                </Code>
              </li>
            </ul>
            Het wijzigen van deze attributen kan de afbeelding breken. Andere
            attributen kun je indien nodig aanpassen.
          </Body>
        </div>
      </JustificationContainer>
    </CollapsibleChevronBlock>
  );
};

export default WarningMessage;
