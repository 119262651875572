/**
 *
 * HouseRejected Icon
 */
import { Props } from '..';

import React from 'react';

const HouseRejected: React.FCC<Props> = ({
  color = 'currentColor',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1.5em"
    height="1.5em"
    {...props}
  >
    <g fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round">
      <path d="M19.768 20.9c0 .7-.9 1.5-2.1 1.5h-14.6c-1.2 0-2.1-.9-2.1-2.1V8.9l9.4-7.3 9.4 7.3v1.3" />
      <path d="M5.168 22.4V12h6.3v10.4M16.468 12l6.6 6.6M16.468 18.6l6.6-6.6" />
    </g>
  </svg>
);

export default HouseRejected;
