import type { OptionOf } from '~/components/molecule/Dropdown';
import { SessionHydrationOfficeFieldsFragment } from '~/graphql/types';
import useOffices from '../useOffices';

type Filter = {
  userId?: string | null;
  withAllOfficesOption?: boolean;
};

export type OfficeOptions = Array<
  OptionOf<SessionHydrationOfficeFieldsFragment | null>
>;

const allOfficesOption = [
  { label: 'Alle vestigingen', key: 'no-selection', payload: null },
];

const addAllOfficesOption = (options: OfficeOptions) => [
  ...allOfficesOption,
  ...options,
];

const useOfficeOptions = ({
  userId = null,
  withAllOfficesOption = false,
}: Filter = {}): OfficeOptions => {
  const officesForUser = useOffices({ userIds: [userId] });
  const allOffices = useOffices({ userIds: [] });

  const optionsFromOfficesToUsers = officesForUser.map(office => ({
    key: office.id,
    label: office.name,
    payload: office,
  }));

  const optionsFromAllOffices = allOffices.map(office => ({
    key: office.id,
    label: office.name,
    payload: office,
  }));

  if (userId && optionsFromOfficesToUsers.length > 0)
    return withAllOfficesOption
      ? addAllOfficesOption(optionsFromOfficesToUsers)
      : optionsFromOfficesToUsers;

  return withAllOfficesOption
    ? addAllOfficesOption(optionsFromAllOffices)
    : optionsFromAllOffices;
};

export default useOfficeOptions;
