import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import useStrapiPage from './hooks/useStrapiPage';
import ContentContainerDefault, {
  MAX_CONTAINER_WIDTH,
} from '~/components/molecule/ContentContainer';
import StrapiDynamicZone from '~/components/organism/StrapiDynamicZone';
import createPageTitle from '~/util/createPageTitle';

export type Props = {
  slug: string;
};

const ConversionPage: React.FCC<Props> = ({ dataTestId, slug, ...rest }) => {
  const pageData = useStrapiPage(slug);

  return (
    <>
      <MetaTags>
        <title>{createPageTitle(pageData?.attributes.title)}</title>
      </MetaTags>
      <ContentContainerDefault
        maxContentWidth={MAX_CONTAINER_WIDTH}
        data-testid={dataTestId}
        {...rest}
      >
        {pageData && (
          <StrapiDynamicZone components={pageData.attributes.dynamicZone} />
        )}
      </ContentContainerDefault>
    </>
  );
};

export default ConversionPage;
