import { selector } from 'recoil';
import { nodesSelector } from './nodesAndEvents';
import {
  FormBuilderMode,
  type FormBuilder_NodeFragment,
} from '~/graphql/types';
import sortNodes from '../utils/sortNodes';
import { formModeSelector } from '.';

/**
 * This returns the submit screen in basic form. In basic form there is only 1 event node.
 * This is the screen node that comes after that event node. When this is deleted
 * the event node gets deleted as well. When it gets inserted an event node is inserted before it.
 */
export const submitScreenState = selector<FormBuilder_NodeFragment | null>({
  key: 'formBuilder/submitScreenState',
  get: ({ get }) => {
    const nodes = get(nodesSelector);
    const formType = get(formModeSelector);
    if (!nodes || formType !== FormBuilderMode.Basic) return null;

    const sortedNodes = sortNodes({ nodes });
    const lastEventNode = sortedNodes.findLast(
      node => node.__typename === 'FormBuilder_EventNode',
    );
    const precedingScreenNode = lastEventNode?.defaultNext?.targetNodeId
      ? sortedNodes.find(n => n.id === lastEventNode?.defaultNext?.targetNodeId)
      : null;

    return precedingScreenNode || null;
  },
});

/**
 * Array of node ids starting from and including the submit screen id
 */
export const nodesSubmitAndAfterState = selector<Array<string>>({
  key: 'formBuilder/nodesSubmitAndAfter',
  get: ({ get }) => {
    const nodes = get(nodesSelector);
    if (!nodes) return [];

    const submitScreen = get(submitScreenState);
    if (!submitScreen) return [];

    const ids = [submitScreen.id];

    let nextId: string | null = submitScreen.defaultNext?.targetNodeId || null;

    nodes.forEach(node => {
      if (nextId && node.id === nextId) {
        ids.push(node.id);
        nextId = node.defaultNext?.targetNodeId || null;
      }
    });

    return ids;
  },
});
