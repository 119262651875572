import { filter } from 'ramda';
import { Warnings } from '~/hooks/useEntityWarnings';
import getSnoozedWarnings from '../getSnoozedWarnings';
import SnoozedWarningType from '../types';
import { isPast, addMinutes } from 'date-fns';

export const SNOOZED_WARNING_DURATION_MINUTES = 15;

const filterWarnings = (warnings: Warnings): Warnings => {
  const snoozedWarningHashes: SnoozedWarningType = getSnoozedWarnings();
  return filter(warning => {
    const snoozedAt = snoozedWarningHashes[warning.hash];

    if (
      snoozedAt &&
      !isPast(addMinutes(new Date(snoozedAt), SNOOZED_WARNING_DURATION_MINUTES))
    ) {
      return false;
    }

    return true;
  }, warnings);
};

export default filterWarnings;
