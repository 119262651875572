import UUID_REGEX from './Uuid';
import String from './String';
export const ID_PREFIX = {
  CONTACT: 'c---',
};

const CONTACT_ID_REGEX = new RegExp(`^${ID_PREFIX.CONTACT}${UUID_REGEX}$`);
const isValid = (id: any): id is string =>
  String.isNonEmptyString(id) && CONTACT_ID_REGEX.test(id);
export default {
  isValid,
};
