/**
 * Returns the closest scrollable parent element of the given child element.
 * @param element The child element whose scrollable parent we're looking for.
 * @returns Closest scrollable parent element or the document.
 */
const getClosestScrollableParent = (
  element: HTMLElement | null,
): Document | HTMLElement | null => {
  if (!element) return null;

  let currentElement: HTMLElement | null = element;

  while (currentElement && currentElement !== document.body) {
    const style = window.getComputedStyle(currentElement);

    if (
      style.overflowY === 'scroll' ||
      style.overflowY === 'auto' ||
      style.overflow === 'auto'
    ) {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return global.window.document;
};

export default getClosestScrollableParent;
