import React, { useContext } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useLocation, navigate } from '@gatsbyjs/reach-router';
import Button from '~/components/atom/Button';
import Icon from '~/components/atom/Icon';
import LeadScoreDisplayer from '~/components/molecule/LeadScoreDisplayer';
import useOffice from '~/hooks/useOffice';
import getUserName from '~/util/getUserName';
import useUser from '~/hooks/useUser';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import {
  Heading3,
  Heading4,
  Heading5,
  Variant,
} from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TaskModalContext from '~/components/page/Tasks/context/TaskModalContext';
import { useSpring, animated } from 'react-spring';
import { ANIMATION_CONFIG } from '~/styles/constants';

type BaseContact = {
  id: string;
  name: string;
  leadScore: number | null;
};

type ContactInNewTaskDetail = BaseContact & {
  phone?: string | null | undefined;
  email: string;
  officeId?: string | null | undefined;
  userId?: string | null | undefined;
};

type Props = {
  contact: ContactInNewTaskDetail;
};

const text = {
  headline: 'Bekijk profiel',
  subsectionTitle: 'Contactgegevens',
  subsectionTitle2: 'Eigenaar',
  officeDefault: 'Er is geen vestiging toegewezen',
  userDefault: 'Er is geen gebruiker toegewezen',
  phoneDefault: 'Er is geen telefoonnummer ingevuld',
};

const ContactDetails = ({ contact }: Props) => {
  const { closeModal } = useContext(TaskModalContext);
  const viewingModeProps = useViewingModeProps();
  const theme = useTheme();
  const location = useLocation();
  const animation = useSpring(ANIMATION_CONFIG);

  const officeName = useOffice(contact.officeId)?.name;

  const user = useUser(contact.userId);
  const userName = user ? getUserName(user) : null;

  return (
    <animated.section
      style={{ ...animation, width: '100%' }}
      data-testid="task-modal-contact-details"
    >
      <JustificationContainer width="100%" gap="base">
        <Heading3 fontWeight="regular" {...viewingModeProps}>
          {contact.name}
        </Heading3>
        <LeadScoreDisplayer leadScore={contact.leadScore || 0} />
      </JustificationContainer>

      <Button
        ghost
        onClick={() => {
          const path = `/-/contacts/${contact.id}`;

          if (location.pathname === path) {
            closeModal();
          } else {
            void navigate(path);
          }
        }}
        label={text.headline}
      />
      <JustificationContainer
        margin={['base', null, null, null]}
        width="100%"
        direction="column"
      >
        <SubsectionTitle
          margin={[null, null, 's', null]}
          fontWeight="regular"
          variant={Variant.primary}
          padding={['m', null, null, null]}
        >
          {text.subsectionTitle}
        </SubsectionTitle>
        <JustificationContainer margin={[null, null, 'xxs', null]} gap="xs">
          <Icon name="phone" color={theme.color('success')} />
          {contact.phone ? (
            <Heading5
              variant={Variant.primary}
              fontWeight="regular"
              margin={[null]}
              {...viewingModeProps}
            >
              {contact.phone}
            </Heading5>
          ) : (
            <Heading5
              variant={Variant.primary}
              fontWeight="regular"
              margin={[null]}
            >
              {text.phoneDefault}
            </Heading5>
          )}
        </JustificationContainer>
        <JustificationContainer margin={[null, null, 'xxs', null]} gap="xs">
          <Icon name="mail" color={theme.color('success')} />
          <Heading5
            variant={Variant.primary}
            fontWeight="regular"
            margin={[null]}
            {...viewingModeProps}
          >
            {contact.email}
          </Heading5>
        </JustificationContainer>
        <SubsectionTitle
          margin={[null, null, 's', null]}
          fontWeight="regular"
          variant={Variant.primary}
          padding={['m', null, null, null]}
        >
          {text.subsectionTitle2}
        </SubsectionTitle>
        <JustificationContainer margin={[null, null, 'xxs', null]} gap="xs">
          <Icon name="office" color={theme.color('primary')} strokeWidth={1} />
          {officeName ? (
            officeName
          ) : (
            <Heading5
              variant={Variant.primary}
              fontWeight="regular"
              margin={[null]}
            >
              {text.officeDefault}
            </Heading5>
          )}
        </JustificationContainer>
        <JustificationContainer gap="xs">
          <Icon name="user" color={theme.color('primary')} />
          {userName ? (
            userName
          ) : (
            <Heading5
              variant={Variant.primary}
              fontWeight="regular"
              margin={[null]}
            >
              {text.userDefault}
            </Heading5>
          )}
        </JustificationContainer>
      </JustificationContainer>
    </animated.section>
  );
};

const SubsectionTitle = styled(Heading4)<{}>`
  ${({ theme }) => css`
    width: 100%;
    border-bottom: solid 1px ${theme.color('tertiary')};
  `};
`;

export default ContactDetails;
