import React, { useEffect } from 'react';
import { Body } from '~/components/atom/Typography';
import { WizardStepProps } from '~/components/organism/Wizard/context/WizardContext';
import useWizardStep from '~/hooks/useWizardStep';

export const id = 'WelcomeScreen';
export const title = 'Welkom bij DatHuis';

const text = {
  body: (
    <>
      Gefeliciteerd met het aanmaken van een nieuw account!
      <br />
      <br />
      In deze wizard gaan we in stappen jouw account inrichten. Dit neemt
      ongeveer 10 minuten in beslag. Na het voltooien van deze wizard, heb je
      een belangrijke stap gezet richting het realiseren van meer loyale klanten
      en het boosten van je serviceniveau.
    </>
  ),
};

export type OutputType = {
  type: typeof id;
};

export const Component: React.FCC<WizardStepProps> = props => {
  const [, api] = useWizardStep(props.step.id);

  useEffect(() => {
    api.free({ type: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Body>{text.body}</Body>;
};

export default {
  id,
  title,
};
