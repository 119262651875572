/** Intercom z-index is set to `2147483000`, thefore to make chat always be accessible we make our indexes lowere than that */

const zIndex = {
  toasts: 2147482999,
  top: 2147482998,
  tooltipLayer: 2147483098,
  dropdown: 7000,
  footer: 900,
};

export type SystemZIndexMap = typeof zIndex;
export type SystemZIndex = keyof SystemZIndexMap;
export default zIndex;
