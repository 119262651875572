import React, { useState, useEffect } from 'react';

import styled, { css } from 'styled-components';

import TEST_ID from './index.testid';
import useDebounce from '~/hooks/useDebounce';
import Icon from '~/components/atom/Icon';
import InputContainer from '~/components/atom/InputContainer';
import InputElement from '~/components/atom/InputElement';
import useInputValue from '~/hooks/useInputValue';
import { isNil } from 'ramda';
import type { Size } from '~/styles/constants';

export type Props = {
  className?: string;

  /** Input's disabled state */
  disabled?: boolean;

  /** Initial value of the input */
  initialValue?: string;

  /** Input placeholder */
  placeholder?: string;

  /** Size of the input */
  size?: Size;

  /** get access to input value */
  onFilterChange: (text: string) => void;
};

const text = {
  placeholder: 'Zoek...',
  search: 'Zoek',
};

const SearchInput: React.FCC<Props> = ({
  disabled,
  initialValue = '',
  placeholder,
  className,
  size,
  onFilterChange,
}) => {
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value, 300);

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      onFilterChange(debouncedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const { hasFocus, inputRef, onValueChange, onFocusChange } = useInputValue({
    value,
    onChange: (event: React.SyntheticEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement;
      setValue(value);
    },
  });

  return (
    <InputContainer
      className={className}
      disabled={disabled === true}
      inputRef={inputRef}
      hasFocus={hasFocus}
      size={size}
    >
      <StyledSearchIcon name="search" />
      <InputElement
        ref={inputRef}
        name="search"
        value={value || ''}
        type="search"
        placeholder={placeholder ?? text.placeholder}
        disabled={disabled}
        onChange={onValueChange}
        onFocus={e => onFocusChange({ e, focus: true })}
        onBlur={e => onFocusChange({ e, focus: false })}
        dataTestId={TEST_ID.INPUT}
        size={size}
      />
    </InputContainer>
  );
};

const StyledSearchIcon = styled(Icon)<{}>`
  ${({ theme }) => css`
    color: ${theme.color('primary', 'base')};
    & > svg {
      font-size: ${theme.fontSize('m')};
    }
  `};
`;

export default SearchInput;
