import React from 'react';
import styled, { css } from 'styled-components';
// import AddBlockMenu from '../AddBlockMenu/Fixed';
import { Expanded } from '../ControlComponents';
import Issues from '../Issues/Fixed';
import SettingsMenu from '../SettingsMenu';
import TEST_ID from './index.testid';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import { CONTROLS_LAYER } from '../../constants/zIndexes';

export type Props = {
  dataTestId?: string;
  onToggleExpand: (expanded: Expanded) => () => void;
  expanded: Expanded | null;
  isFullScreen?: boolean;
};

const AsideContainer: React.FCC<Props> = ({
  dataTestId,
  onToggleExpand,
  expanded,
  isFullScreen,
  ...rest
}) => (
  <Container data-testid={dataTestId} isFullScreen={isFullScreen} {...rest}>
    <Content>
      {/* Disabled due to lack of time */}
      {/* <AddBlockMenu
      expanded={expanded === 'blocks'}
      onToggleExpand={onToggleExpand('blocks')}
    /> */}
      <SettingsMenu
        dataTestId={TEST_ID.SETTINGS_MENU}
        expanded={expanded === 'settings'}
        onToggleExpand={onToggleExpand('settings')}
      />
      <Issues
        dataTestId={TEST_ID.ISSUES_CONTAINER}
        expanded={expanded === 'issues'}
        onToggleExpand={onToggleExpand('issues')}
      />
    </Content>
  </Container>
);
const CONTAINER_WIDTH = 240;

const Container = styled.div<{ isFullScreen?: boolean }>(
  ({ theme, isFullScreen = false }) => css`
    position: absolute;
    max-width: ${CONTAINER_WIDTH}px;
    width: 100%;
    z-index: ${CONTROLS_LAYER};
    margin-top: ${isFullScreen ? theme.space('xxl') : theme.space('base')};
    background-color: ${theme.color('white')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('tertiary', 'light')};
    border-radius: ${theme.getTokens().border.radius.m};
    overflow: hidden;
  `,
);

const Content = styled.div(
  ({ theme }) => css`
    height: 100%;
    /* Perfectly fits between toolbar and footer */
    max-height: 77vh;

    overflow: auto;
    ${scrollBarStyles}

    & > *:not(:last-child) {
      position: relative;
    }

    & > *:not(:last-child)::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: ${theme.space('m')};
      right: ${theme.space('m')};
      border-bottom: 0.5px solid ${theme.color('tertiary')};
      border-radius: ${theme.getTokens().border.radius.s};
    }
  `,
);

export default AsideContainer;
