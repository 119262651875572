import React from 'react';
import { Props } from '..';

const ChevronEnd: React.FCC<Props> = ({ color = 'currentColor', ...props }) => (
  <svg
    width="1rem"
    height="1rem"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.9999 6L10.9999 12L16.9999 18"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.00012 18L7.00012 6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronEnd;
