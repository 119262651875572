import {} from 'react';
import { SessionHydrationAccountFieldsFragment } from '~/graphql/types';
import useSessionHydration from '../useSessionHydration';

const useCurrentAccount = (): SessionHydrationAccountFieldsFragment => {
  const [{ account }] = useSessionHydration();
  return account;
};

export default useCurrentAccount;
