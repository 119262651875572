import React from 'react';
import styled, { css } from 'styled-components';

import { TableHeaderCellType } from '~/components/bad/DataTables/types.flow';

import { headerProps } from '../util/propGetters';
import ColumnResizer from './ColumnResizer';
import getNextSortDirection from '../util/getNextSortDirection';

import TEST_ID from './HeaderCell.testid';
import { isNil } from 'ramda';
import SortToggle from '~/components/molecule/SortToggle';
import { SortDirection } from '~/graphql/types';

type Props = {
  column: TableHeaderCellType<any>;
  isLastColumn: boolean;
  deselectAllItems?: () => void;
};
const HeaderCell: React.FCC<Props> = ({
  column,
  isLastColumn,
  deselectAllItems,
  ...rest
}) => {
  const styledThProps = column.getHeaderProps({
    ...headerProps,
  });

  const sortByToggleProps = {
    ...column.getSortByToggleProps({
      title: '',
    }),
  };

  const onSortClickFunction = (...args) => {
    if (deselectAllItems) deselectAllItems();

    if (!isNil(sortByToggleProps.onClick)) {
      sortByToggleProps.onClick(...args);
    }

    const onSort = column.onSort;
    if (onSort) {
      onSort(getNextSortDirection(column.isSorted, column.isSortedDesc));
    }
  };

  const sortable = !column.disableSortBy;
  const resizable = column.canResize && !isLastColumn;

  return (
    <Container
      {...styledThProps}
      {...rest}
      data-testid={column.dataTestId}
      data-sortable={sortable}
      data-sorted={
        column.isSorted
          ? column.isSortedDesc
            ? SortDirection.Desc
            : SortDirection.Asc
          : false
      }
      data-resizable={resizable}
      key={styledThProps.key}
    >
      <ContentContainer>
        <OverflowHandlerContainer
          {...sortByToggleProps}
          onClick={onSortClickFunction}
          data-testid={
            column.dataTestId && TEST_ID.LABEL_CONTAINER(column.dataTestId)
          }
        >
          {column.render('Header')}
        </OverflowHandlerContainer>
        {sortable && (
          <StyledSortToggle
            {...sortByToggleProps}
            data-testid={
              column.dataTestId && TEST_ID.SORT_ICON(column.dataTestId)
            }
            onClick={onSortClickFunction}
            direction={
              column.isSorted
                ? column.isSortedDesc
                  ? SortDirection.Desc
                  : SortDirection.Asc
                : null
            }
          />
        )}
        {resizable && <ColumnResizer column={column} />}
      </ContentContainer>
    </Container>
  );
};

const ContentContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Container = styled.div<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    padding-left: ${theme.space('xxs')};
  `,
);

const OverflowHandlerContainer = styled.div<{}>`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledSortToggle = styled(SortToggle)<{}>`
  ${({ theme }) => css`
    padding-left: ${theme.space('xxs')};
  `}
`;

export default HeaderCell;
