import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  CHAR_NUMBERTOKENS_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-numbertokens-validation`,

  CHAR_UPPERCASE_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-uppercase-validation`,
  CHAR_LOWERCASE_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-lowercase-validation`,
  CHAR_NUMBER_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-number-validation`,
  CHAR_SPECIALCHAR_VALIDATION: `${cleanedFilename(
    __filename,
  )}-char-specialchar-validation`,
};
