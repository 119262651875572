import React, { useEffect } from 'react';
import Link from '~/components/molecule/Link';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import { Body } from '~/components/atom/Typography';
import { WizardStepProps } from '~/components/organism/Wizard/context/WizardContext';
import useWizardStep from '~/hooks/useWizardStep';
import TEST_ID from './index.testid';

const text = {
  title: 'Installatie',
  body: (
    <>
      Loop door deze wizard om de app te installeren. In enkele stappen zet je
      de app aan en stel je de automatische opvolging (flows) in.
    </>
  ),
};

export const title = text.title;
export const id = 'GenericStart';

export type OutputType = {
  type: typeof id;
};
export const Component: React.FCC<WizardStepProps> = props => {
  const [, api] = useWizardStep(props.step.id);

  useEffect(() => {
    api.free({ type: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Body>{text.body}</Body>
      <TipBanner
        dataTestId={TEST_ID.TIP_BANNER}
        id="generic-wizard"
        margin={['l', null, null, null]}
      >
        <Body margin={[null]}>
          Je kunt meer lezen over hoe je de koppeling moet instellen in onze{' '}
          <Link to="https://help.dathuis.nl/">kennisbank</Link>. Kom je er niet
          uit?{' '}
          <ChatLink
            text="Ik heb hulp nodig"
            linkText="Start een chat met ons."
          />
        </Body>
      </TipBanner>
    </>
  );
};

export default {
  title,
};
