import { isEmpty, isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import CopyCode from '~/components/atom/CopyCode';
import GoToPath from '~/components/organism/GoToPath';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Screenshot from '~/components/molecule/Screenshot';
import { Body, Label, Variant } from '~/components/atom/Typography';
import {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import useWizardStep from '~/hooks/useWizardStep';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import { isNonEmptyString } from '~/util/Validation/String';
import RealworksHelpBlock from '../components/RealworksHelpBlock';
import TEST_ID from './index.testid';
import SkipStepButtonBlock from '~/components/organism/WizardSteps/components/SkipStepButtonBlock';
import InputWithIndicator from '~/components/molecule/InputWithIndicator';

export const exportContactsScreenshotLink =
  'https://dathuis-stash.imgix.net/realworks_export_contacts.png';

const text = {
  header: 'Contacten exporteren',
  description:
    'Om contact te exporteren naar Realworks gebruiken we de Wonen API. Vul de API Manager in zoals op de onderstaande screenshot. Het is belangrijk dat de instellingen exact overeenkomen.',
  code: 'eb92a256-b27d-4747-80c4-4d4b45485cc8',
  firstAppEntryHeader: '1. Ga in RealWorks naar',
  secondAppEntryHeader: '2. Plak onze Developer ID in Realworks',
  thirdAppEntryHeader: '3. Kopieer de “token” en plak deze hier',
  inputPlaceHolder: 'Bijv. "Vestiging Amsterdam"',
  skipStep: 'Stap overslaan',
  skipStepDescription: 'Wil je geen contacten naar RealWorks exporteren?',
};

// Make it looks the same size with the screenshot on the prev page
const SCREENSHOT_MAX_WIDTH = 720;

export const id = 'RealworksExportContacts';
export const title = text.header;

export type OutputType = {
  type: typeof id;
  wonenToken?: OutputFieldInvisible<
    UpdateRealworksAppStatus_AddTokenContainer__Input['wonen']
  >;
};

const validate = (
  value: string | null | undefined,
  relatieToken: string | null | undefined,
) => {
  if (isNil(value) || isEmpty(value)) return 'Vul een waarde in';
  if (value === relatieToken)
    return 'Kan niet hetzelfde zijn als de relatie token';
  return true;
};

export const Component: React.FCC<WizardStepProps> = ({ outputMap, step }) => {
  const [, api] = useWizardStep(step.id);

  const previousToken =
    outputMap[id] && 'wonenToken' in outputMap[id]
      ? outputMap[id]?.wonenToken?.value ?? null
      : null;

  const [wonen, setWonen] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input['wonen']>(
      previousToken,
    );

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const relatieToken =
    'relatieToken' in outputMap.RealworksImportContacts
      ? outputMap.RealworksImportContacts?.relatieToken?.value ?? null
      : null;

  useEffect(() => {
    if (relatieToken && relatieToken !== wonen && isNonEmptyString(wonen)) {
      api.free({
        type: id,
        wonenToken: {
          type: 'invisible',
          value: wonen,
        },
      });
    } else {
      api.free({
        type: id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wonen]);

  return (
    <>
      <Body>{text.description}</Body>
      <SkipStepButtonBlock
        description={text.skipStepDescription}
        dataTestId={TEST_ID.NOTIFICATION_BUTTON}
        margin={['m', null, null]}
      />
      <RealworksHelpBlock tipId="export-contacts-step-tip" />
      <form onSubmit={onSubmit} data-testid={TEST_ID.FORM}>
        <StyledAppEntryItemHeader>
          {text.firstAppEntryHeader}
        </StyledAppEntryItemHeader>

        <GoToPath
          path={[
            'Servicedesk',
            'Marketplace',
            "API's",
            'Wonen API',
            'Bestellen',
          ]}
          label="Ga naar marketplace"
          link="https://crm.realworks.nl/servlets/objects/admin.marketplace/overview"
        />
        <JustificationContainer
          justification="start"
          margin={['s', null, null, null]}
        >
          <CopyCode
            label={
              <Label
                margin={[null]}
                fontWeight="semiBold"
                variant={Variant.primary}
                size="m"
              >
                {text.secondAppEntryHeader}
              </Label>
            }
            code={text.code}
            style={{ width: '100%' }}
          />
        </JustificationContainer>

        <JustificationContainer justification="center" margin={['s', null]}>
          <Screenshot
            maxWidth={SCREENSHOT_MAX_WIDTH}
            src={exportContactsScreenshotLink}
            alt="screenshot"
            withoutShadow
          />
        </JustificationContainer>

        <AppEntryItemHeader>{text.thirdAppEntryHeader}</AppEntryItemHeader>
        <InputWithIndicator
          appearance="primary"
          validation={[(value: string) => validate(value, relatieToken)]}
          value={wonen ?? ''}
          onChange={e => setWonen(e.target.value)}
          dataTestId={TEST_ID.TOKEN_INPUT}
        />
      </form>
    </>
  );
};

const StyledAppEntryItemHeader = styled(AppEntryItemHeader)<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('l')};
  `,
);

export default {
  id,
  title,
};
