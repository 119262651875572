import type { ClientFlowAction } from '~/graphql/types.client';
import type {
  FlowData___FlowInstanceFragment,
  FlowData___SubjectFragment,
} from '~/graphql/types';
import type { SubjectMap } from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import type {
  ConditionMap,
  SubjectToConditionMap,
} from '../../../UpdateAction/components/Selector/utils/getConditions';

import {
  DirectoryMap,
  generateDirectoryMap,
} from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getDirectory';
import {
  generateInstanceMap,
  InstanceMap,
} from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getInstance';
import type { PrimitiveInputMap } from '../../../UpdateAction/components/Selector/utils/getPrimitiveInput';
import getParentInstances from '../../getParentInstances';
import { isNil } from 'ramda';
import convertTemplateStringToLabel from '../convertTemplateStringToLabel';

export type RelativeMaps = {
  instanceMap: InstanceMap;
  subjectMap: SubjectMap;
  directoryMap: DirectoryMap;
  primitiveInputMap: PrimitiveInputMap;
  conditionMap: ConditionMap;
  subjectToConditionMap: SubjectToConditionMap;
  /** Initial instances retrieved from the BE */
  instances: Array<FlowData___FlowInstanceFragment>;
  subjects: Array<FlowData___SubjectFragment>;
};

type GetRelativeMapsArgs = {
  /** The action until which the instances need to be collected */
  action?: ClientFlowAction;

  actions: Array<ClientFlowAction>;

  maps: RelativeMaps;
};

const getRelativeMaps = ({
  action,
  actions,
  maps,
}: GetRelativeMapsArgs): RelativeMaps => {
  const {
    subjectMap,
    instanceMap,
    primitiveInputMap,
    conditionMap,
    subjectToConditionMap,
    subjects,
    instances,
  } = maps;

  // instances with optional extraDescription field
  const actionInstances = getParentInstances({
    action,
    allActions: actions,
    subjectMap,
  });

  const { updatedCompleteInstanceMap, updatedRelativeDirectoryMap } = (() => {
    const onlyInstances = actionInstances.map(i => i.instance);

    // first get the initial relative maps. Once we have access to relative maps we can determine the path for pointers
    const relativeInstanceMap = generateInstanceMap(onlyInstances);
    const completeInstanceMap: InstanceMap = {
      ...instanceMap,
      ...relativeInstanceMap,
    };
    const relativeDirectoryMap: DirectoryMap = generateDirectoryMap(
      subjects,
      [...onlyInstances, ...instances],
      subjectMap,
    );

    // use extraDescription field to create labels for pointers and then throw extraDescription
    // as it is not needed anymore
    const updatedInstancesWithPointerLabels = actionInstances.map(a => ({
      ...a.instance,
      dir: a.instance.dir.map((d, index) => {
        // inserted to the first directory item
        if (
          index === 0 &&
          !isNil(a.extraDescription) &&
          a.extraDescription.template
        ) {
          const addition = convertTemplateStringToLabel({
            templateString: a.extraDescription.template,
            mappings: a.extraDescription.mappings,
            directoryMap: relativeDirectoryMap,
            instanceMap: completeInstanceMap,
            subjectMap,
          });

          return { ...d, label: d.label + ' ' + addition };
        }

        return d;
      }),
    }));

    return {
      updatedCompleteInstanceMap: {
        ...instanceMap,
        ...generateInstanceMap(updatedInstancesWithPointerLabels),
      },
      updatedRelativeDirectoryMap: generateDirectoryMap(
        subjects,
        [...updatedInstancesWithPointerLabels, ...instances],
        subjectMap,
      ),
    };
  })();

  return {
    instanceMap: updatedCompleteInstanceMap,
    directoryMap: updatedRelativeDirectoryMap,
    subjectMap,
    primitiveInputMap,
    conditionMap,
    subjectToConditionMap,
    instances,
    subjects,
  };
};

export default getRelativeMaps;
