import { append } from 'ramda';
import { useRecoilState, useSetRecoilState } from 'recoil';
import isDeleted from '~/components/template/EventTimelineV2/utils/isDeleted';
import getLatestActivities from '~/components/template/EventTimelineV2/utils/getLatestActivities';
import groupActivities from '~/components/template/EventTimelineV2/utils/groupActivities';
import removeActivityFromList from '~/components/template/EventTimelineV2/utils/removeActivityFromList';
import {
  ActivityFields_Event_Contact_Email_Fragment,
  ActivityFields_Event_Contact_Generic_Fragment,
  ActivityFields_Task_Contact_Fragment,
} from '~/graphql/types';
import { activitiesByContactId, deletedActivities } from '~/state/activities';

const useUpdateTimelineFn = (contactId: string | null) => {
  const [groupedActivities, setGroupedActivities] = useRecoilState(
    activitiesByContactId(contactId),
  );
  const setDeletedActivities = useSetRecoilState(deletedActivities);

  const updateTimelineFn = (
    newActivity:
      | ActivityFields_Event_Contact_Generic_Fragment
      | ActivityFields_Event_Contact_Email_Fragment
      | ActivityFields_Task_Contact_Fragment,
  ) => {
    if (isDeleted(newActivity)) {
      const newActivities = removeActivityFromList({
        groupedActivities,
        deletedActivity: newActivity,
      });

      setDeletedActivities(prev => append(newActivity.id, prev));

      setGroupedActivities(newActivities);
      return;
    }

    setGroupedActivities(prev => {
      const latestActivities = getLatestActivities({
        prevActivities: prev,
        newActivity,
      });

      return groupActivities(latestActivities);
    });
  };

  return contactId ? updateTimelineFn : () => {};
};

export default useUpdateTimelineFn;
