import React from 'react';

import { FieldProps } from 'react-final-form';

import Field from './Field';
import passthrough from '~/util/passthroughFunction';
import { parsePostalCode } from '~/util/Validation/Postcode';

export default ({ name, format, parse, ...rest }: FieldProps<any, any>) => (
  <Field
    name={name}
    {...rest}
    format={format ? passthrough(formatPostalCode, format) : formatPostalCode}
    parse={parse ? passthrough(parsePostalCode, parse) : parsePostalCode}
  />
);

const formatPostalCode = (value: string | null): string | null => value;
