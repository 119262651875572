import React from 'react';
import MultiSelectInput, {
  type Props as MultiSelectInputProps,
} from '~/components/molecule/MultiSelectInput';
import convertToEmailOption from './utils/convertToEmailOption';

export type Props = MultiSelectInputProps;

const text = {
  placeholder:
    'Typ hier om te zoeken of druk op enter om een e-mailadres in te voeren',
};

/**
 * Uses custom convert util
 */
const MultiSelectEmailInput: React.FCC<Props> = ({
  selectedOptions = [],
  onSelected,
  onRemoved,
  disabled,
  onAsyncSearch,
  loading,
  ...rest
}) => (
  <MultiSelectInput
    convertCustomEntry={convertToEmailOption}
    disabled={disabled}
    selectedOptions={selectedOptions}
    onSelected={onSelected}
    onRemoved={onRemoved}
    onAsyncSearch={onAsyncSearch}
    loading={loading}
    placeholder={text.placeholder}
    {...rest}
  />
);

export default MultiSelectEmailInput;
