import type { DeepNonNullable } from 'utility-types';
import useSessionHydration from '../useSessionHydration';
import type { DhImage, WidgetSettingsStyles } from '~/graphql/types';
import removeTypenames, { type WithoutTypename } from '~/util/removeTypenames';

export type BrandSettings = WithoutTypename<{
  colors?: WidgetSettingsStyles | null;
  logo?: DhImage | null;
  url?: string | null;
}>;

export type NonNullableBrandSettings = DeepNonNullable<BrandSettings>;

export type BrandSettingsField = keyof BrandSettings;

const useBrandSettings = (): BrandSettings | null => {
  const [data] = useSessionHydration();

  if (!data) {
    throw new Error(
      `An error occured in 'useBrandSettings' hook, data from 'useSessionHydration' is undefined. You likely forgot to wrap it in SessionHydrationContext`,
    );
  }

  const accountSettings = data.account.AccountSettings;
  if (!accountSettings) return null;

  const { colors, url, logo } = accountSettings;
  const brandSettings = {
    colors: {
      primary: {
        color: colors?.primary.color ?? '#000',
        background: colors?.primary.background ?? '#fff',
      },
      secondary: {
        color: colors?.secondary.color ?? '#000',
        background: colors?.secondary.background ?? '#fff',
      },
    },
    url,
    logo,
  };

  return removeTypenames(brandSettings);
};

export default useBrandSettings;
