import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type {
  FormBuilder_ScreenNode,
  FormBuilder_ScreenNode_Block,
  Maybe,
} from '~/graphql/types';
import { blocksMap } from '../../constants';
import { Heading5, Variant } from '~/components/atom/Typography';
import EditableBlock from './components/EditableBlock';
import SquareIcon from '../../../../../../../../../../atom/Icon/components/SquareIcon';
import styled from 'styled-components';

type Props = {
  node: Maybe<FormBuilder_ScreenNode>;
  id: string;
  focusedBlock: Maybe<FormBuilder_ScreenNode_Block>;
  onBlockChange: (update: FormBuilder_ScreenNode_Block) => void;
  onBlockFocus: (block: FormBuilder_ScreenNode_Block) => void;
};

const EditBlockArea: React.FC<Props> = ({
  onBlockChange,
  id,
  focusedBlock,
  onBlockFocus,
}) => {
  if (!focusedBlock) return null;

  return (
    <Container
      direction="column"
      width="100%"
      backgroundColor={{ group: 'primary', variant: 'translucent' }}
      border={{ radius: 'base' }}
      padding={['base']}
      gap="base"
    >
      <JustificationContainer
        align="center"
        gap="base"
        onClick={() => onBlockFocus(focusedBlock)}
      >
        <SquareIcon
          name={blocksMap[focusedBlock.__typename].icon}
          color={blocksMap[focusedBlock.__typename].color}
        />
        <Heading5 margin={[null]} variant={Variant.primary}>
          {blocksMap[focusedBlock.__typename].label}
        </Heading5>
      </JustificationContainer>
      <EditableBlock
        key={focusedBlock.key}
        block={focusedBlock}
        nodeId={id}
        onChange={onBlockChange}
      />
    </Container>
  );
};

const Container = styled(JustificationContainer)`
  cursor: pointer;
`;

export default EditBlockArea;
