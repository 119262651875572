import React from 'react';
import { Props } from '..';

/** Custom icon */
const HtmlSquare: React.FCC<Props> = ({ color = 'currentColor', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    {/* Outer square */}
    <rect x="1" y="1" width="22" height="22" rx="2" ry="2" />
    {/* HTML Tags */}
    <path
      d="M9 16 5 12 9 8M15 16 19 12 15 8"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HtmlSquare;
