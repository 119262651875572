import { AppType } from '~/graphql/types';
import type { AppConfig } from '../types';

const description = `## Omschrijving
Ben je een vastgoedprofessional op zoek naar de beste leadgenerator? Maak dan gebruik van de Waardecheck Powered by Matrixian. In samenwerking met Matrixian Group, heeft DatHuis deze app ontwikkeld.

De Waardecheck gebruikt de meest recente transactiegegevens om de waarde van een huis vast te stellen. Het rapport bevat recente transacties, inclusief geïndexeerde koopsommen. Een grensverleggende manier om leads te genereren. Nieuwe leads verwerk je heel makkelijk op een persoonlijke manier met flows. Gebruik hiervoor de beschikbare templates of bouw je eigen flow.

## Installatie en kosten
Activeer de app pas het waarderapport aan je eigen huisstijl. Gebruik de beschikbare flow templates voor de juiste opvolging.

Kom je er niet uit? Lees het artikel in de kennisbank of stuur ons een chatbericht.`;

const termsOfUse =
  'Door het activeren van deze app geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van Matrixian Group. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Voor het gebruik van het Matrixian data platform zijn de gebruiksvoorwaarden van Matrixian van toepassing. Meer informatie vind je op [https://matrixian.com/algemene-voorwaarden/](https://matrixian.com/algemene-voorwaarden/).';

export const matrixian: AppConfig = {
  __typename: 'AppStatus_VBOWaardecheck',
  appType: AppType.VboWaardecheck,
  type: 'with_config',
  slug: 'matrixian-waardecheck',
  name: 'Waardecheck - Powered by Matrixian',
  tags: ['Leadgeneratie', 'E-mailcampagne', 'Informatievoorziening'],
  provider: 'DatHuis B.V.',
  logo: {
    src: 'https://dathuis-stash.imgix.net/matrixian_logo.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/matrixian_logo.svg',
  },
  price: {
    interval: {
      amount: 1,
      duration: 'month',
    },
    amount: 2400,
    currency: 'EUR',
  },
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/5577905-over-de-matrixian-waardecheck',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'custom',
};
