import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import TEST_ID from '../index.testid';
import Row from './Row';

export type Props = {
  rowAmount: number;
  getTableBodyProps: () => Record<string, unknown>;
};

const FakeColumn = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={20}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" width="100%" rx="0" ry="0" height="40" />
  </ContentLoader>
);

const LoadingBody: React.FCC<Props> = ({ rowAmount, getTableBodyProps }) => {
  const fakeRows = Array(rowAmount || 10).fill({});

  return (
    <BodyContainer {...getTableBodyProps()} data-testid={TEST_ID.LOADING_BODY}>
      {fakeRows.map((_, rowIndex) => (
        <FakeRow key={rowIndex}>
          <FakeColumn />
        </FakeRow>
      ))}
    </BodyContainer>
  );
};

const FakeRow = styled(props => <Row {...props} to="/-/" />)`
  cursor: wait;
  pointer-events: none;

  &:hover {
    background-color: ${({ theme }) => theme.color('white')};
  }
`;

const BodyContainer = styled.div<{}>`
  flex-grow: 1;
  align-self: start;
`;

export default LoadingBody;
