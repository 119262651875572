import React from 'react';
import { Form, Field } from '~/components/organism/Forms';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';
import useConfirmModal from '~/hooks/useConfirmModal';
import DetailsActionList from '~/components/page/DetailsPage/DetailsActionList';
import cleanedFilename from '~/util/cleanedFilename';
import URLContainer from './UrlContainer';
import EditableText from '~/components/organism/EditableText';
import { Heading4, Variant } from '~/components/atom/Typography';
import FormUtils from '~/components/organism/FormUtils';
import Checkbox from '~/components/molecule/Checkbox';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import FormFooterSaveBar from '~/components/organism/SaveBar/components/FormFooterSaveBar';

const text = {
  reportListTitle: 'Waarderapporten',
  deleteLabel: 'Verwijder',
  algasIdLabel: Catalog.valueReports.algasIdHeader,
  noAlgasId: Catalog.valueReports.noAlgasId,
  settingsHeader: 'Instellingen',
  sendContactEmailLabel: Catalog.valueReports.sendContactEmailLabel,
  sendAgentEmailLabel: Catalog.valueReports.sendAgentEmailLabel,
  deleteWarningLabels: {
    title: 'Waarschuwing',
    message:
      'Door het verwijderen van dit waarderapport komen er geen nieuwe leads meer binnen.',
    buttonConfirmTitle: 'Verwijder',
  },
};
export type PremiumValueReportDetailsFields = {
  name: string;
  algasId: string;
  sendContactEmail: boolean;
  sendAgentEmail: boolean;
  domain: string | null;
};
type FormFields = {
  algasId: string | null;
  sendContactEmail: boolean;
  sendAgentEmail: boolean;
  domain: string | null;
};
type Props = {
  report: PremiumValueReportDetailsFields;

  /** If an update is running, so disable fields */
  updating: boolean;
  updateFn: (update: PremiumValueReportDetailsFields) => void;
  onDelete: () => void;
};
const PremiumValueReportDetailsComponent: React.FCC<Props> = ({
  report,
  updateFn,
  updating,
  onDelete,
}) => {
  const { name } = report;

  const { setShowModal, modal } = useConfirmModal({
    level: 'danger',
    labels: text.deleteWarningLabels,
    buttons: [
      {
        onClick: onDelete,
        label: text.deleteWarningLabels.buttonConfirmTitle,
      },
    ],
  });

  return (
    <>
      {modal}
      <ContentContainerDefault
        breadcrumbs={[
          {
            to: '/-/apps/value-report',
            label: text.reportListTitle,
          },
          {
            label: name,
          },
        ]}
      >
        <JustificationContainer
          width="100%"
          justification="space-between"
          align="center"
          margin={[null, null, 'xl', null]}
        >
          <EditableText
            margin={[null, 'xl', null, null]}
            text={name}
            onSave={newName => updateFn({ ...report, name: newName })}
          />
          <DetailsActionList
            options={[
              {
                label: text.deleteLabel,
                onClick: () => setShowModal(true),
                type: 'DELETE',
                key: 'delete-premium-value-report',
              },
            ]}
          />
        </JustificationContainer>
        <Form
          onSubmit={newFields => {
            if (newFields.algasId == null) {
              throw Error(
                `${cleanedFilename(
                  __filename,
                )} | Should not occur | algasId == null`,
              );
            }

            updateFn({
              ...report,
              algasId: newFields.algasId,
              sendContactEmail: newFields.sendContactEmail,
              sendAgentEmail: newFields.sendAgentEmail,
              domain:
                newFields.domain === '' ? report.domain : newFields.domain,
            });
          }}
          validate={validate}
          initialValues={asFormFields(report)}
        >
          {() => (
            <JustificationContainer
              width="100%"
              direction="column"
              padding={['l']}
              border={{ radius: 'm' }}
              backgroundColor={{ group: 'white' }}
            >
              <JustificationContainer
                width="100%"
                direction="column"
                gap="base"
              >
                <Field name="algasId">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      disabled
                      width="100%"
                      size="large"
                      label={{ text: text.algasIdLabel }}
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [FormUtils.showError(error, touched)]
                          : []
                      }
                      {...input}
                    />
                  )}
                </Field>

                <Field name="domain">
                  {({ input }) => (
                    <URLContainer
                      route={input.value}
                      onChange={value => input.onChange(value)}
                    />
                  )}
                </Field>
              </JustificationContainer>

              <Heading4
                margin={['xl', null, 's', null]}
                variant={Variant.primary}
              >
                {text.settingsHeader}
              </Heading4>

              <JustificationContainer
                width="100%"
                direction="column"
                gap="base"
              >
                <Field name="sendContactEmail">
                  {({ input }) => {
                    const { value, onChange } = input;
                    return (
                      <Checkbox
                        onChange={() => {
                          onChange(!value);
                        }}
                        value={value}
                        label={text.sendContactEmailLabel}
                        name="sendContactEmail"
                      />
                    );
                  }}
                </Field>
                <Field name="sendAgentEmail">
                  {({ input }) => {
                    const { value, onChange } = input;
                    return (
                      <Checkbox
                        onChange={() => {
                          onChange(!value);
                        }}
                        value={value}
                        label={text.sendAgentEmailLabel}
                        name="sendAgentEmail"
                      />
                    );
                  }}
                </Field>
              </JustificationContainer>
              <FormFooterSaveBar disableSave={updating} />
            </JustificationContainer>
          )}
        </Form>
      </ContentContainerDefault>
    </>
  );
};

const validate = (fields: FormFields) => {
  const errors: {
    algasId: string | undefined;
  } = {
    algasId: undefined,
  };
  if (Validation.String.isEmpty(fields.algasId)) {
    errors.algasId = text.noAlgasId;
  }

  return errors;
};

const asFormFields = (report: PremiumValueReportDetailsFields): FormFields => {
  const { algasId, sendContactEmail, sendAgentEmail, domain } = report;
  return { algasId, sendContactEmail, sendAgentEmail, domain };
};

export default PremiumValueReportDetailsComponent;
