import React from 'react';
import styled from 'styled-components';

import { TaskType } from '~/graphql/types';

import Icon from '~/components/atom/Icon';
import type { Option } from '~/components/molecule/Dropdown';

export const iconForTypeInHeader = (type: TaskType): React.ReactNode => {
  switch (type) {
    case TaskType.Email:
      return <StyledIcon name="mail" />;
    case TaskType.Call:
      return <StyledIcon name="phone" />;
    default:
      return <StyledIcon name="check" />;
  }
};

const StyledIcon = styled(Icon)`
  margin-top: 1px;
`;

export const taskTypeOptions: Array<Option> = [
  {
    key: TaskType.Call,
    payload: TaskType.Call,
    label: 'Bellen',
    icon: { name: 'phone' },
  },
  {
    key: TaskType.Email,
    payload: TaskType.Email,
    label: 'Mailen',
    icon: { name: 'mail' },
  },
  {
    key: TaskType.ToDo,
    payload: TaskType.ToDo,
    label: 'Te doen',
    icon: { name: 'check' },
  },
];
