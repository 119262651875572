import React from 'react';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body } from '~/components/atom/Typography';
import TEST_ID from './index.testid';
import styled from 'styled-components';

export type Props = {
  onClose: () => void;
  onAddMore: () => void;
};

const text = {
  importMoreButtonLabel: 'Nog een bestand importeren',
  closeButtonLabel: 'Ok',
  importSuccessText:
    'De contacten worden nu geïmporteerd, zodra alle contacten in het systeem staan zal er een e-mail gestuurd worden naar jouw e-mailadres',
};

const WIDTH = '600px';

const ImportContactsSuccess: React.FC<Props> = ({ onClose, onAddMore }) => (
  <JustificationContainer
    direction="column"
    align="center"
    justification="center"
    margin={['m', null, null, null]}
    width={WIDTH}
  >
    <SuccessText data-testid={TEST_ID.MULTIPLE_SUCCESS}>
      {text.importSuccessText}
    </SuccessText>
    <JustificationContainer
      justification="center"
      gap="m"
      margin={['m', null, null, null]}
    >
      <Button
        size="medium"
        data-testid={TEST_ID.IMPORT_MORE_BUTTON}
        ghost
        onClick={onAddMore}
        label={text.importMoreButtonLabel}
      />
      <Button
        size="medium"
        data-testid={TEST_ID.CLOSE_BUTTON}
        onClick={onClose}
        label={text.closeButtonLabel}
      />
    </JustificationContainer>
  </JustificationContainer>
);

const SuccessText = styled(Body)`
  text-align: center;
`;

export default ImportContactsSuccess;
