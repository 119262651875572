import { isEmpty, isNil } from 'ramda';
import type { ClientFlowActionContactDetails } from '~/graphql/types.client';
import isTemplateString from '~/typeguards/isTemplateString';
import type { FieldName } from '../../reducer';
import type { FlowV2_TemplateString } from '~/graphql/types';

const hasTemplateStringValue = (field: FlowV2_TemplateString) =>
  field && field.template && !isEmpty(field.template);

const getActiveField = (action: ClientFlowActionContactDetails): FieldName => {
  if (
    isTemplateString(action.field.name) &&
    hasTemplateStringValue(action.field.name)
  )
    return 'name';
  if (
    isTemplateString(action.field.phone) &&
    hasTemplateStringValue(action.field.phone)
  )
    return 'phone';

  if (!isNil(action.field.unsubscribeEmail)) {
    return 'unsubscribeEmail';
  }

  // Default to name as we always did
  return 'name';
};

export default getActiveField;
