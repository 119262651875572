import { replace } from 'ramda';
import { Text } from 'slate';
import getElements from '../../components/elements';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { EditorValue } from '../../types';
/**
 * Returns the text in the html
 * @param {EditorValue} fragment - Value in slate format
 * @param {Array<ELEMENTS>} customElements - Additional elements you want to serialize
 * keywords:
 */
const serializeAsPlainText = ({
  fragment,
  customElements = [],
}: {
  fragment: EditorValue;
  customElements?: Array<ELEMENTS>;
}) => {
  if (!fragment) return '';

  return fragment
    ?.map(node => {
      let customElementsStr = '';

      const serialize = (n): string => {
        if (Text.isText(n)) {
          const text = removeNewLineChar(n.text);
          customElementsStr += text;
          return text;
        }

        const children = n.children?.map(n => serialize(n)).join('');

        if (customElements.length > 0) {
          const elements = getElements(customElements);

          for (const elName of customElements) {
            if (n.type === elName)
              customElementsStr += elements[elName]?.serialize(n, children);
          }

          return customElementsStr;
        }

        return children;
      };

      return serialize(node);
    })
    .join('');
};

export const removeNewLineChar = (str: string): string =>
  replace(/\n/g, '', str);

export default serializeAsPlainText;
