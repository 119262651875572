/**
 * Extracts the block key from the pointer
 * @param {[string, string]} pointer - Form builder pointer
 */
const getBlockKeyFromPointer = (pointer: [string, string]): string =>
  // UUID v4 has 32 characters plus 4 "-" which is 36 characters
  // We have custom prefixes of length 4 and we add another "-" so 41 characters to get to the actual ID.
  pointer[1].slice(41);

export default getBlockKeyFromPointer;
