import React from 'react';
import type { Flow___PrimitiveArgument } from '~/graphql/types.client';
import useErrorReporter from '~/hooks/useErrorReporter';
import getArgumentSubjectIdentifier from '../../../../../Selector/utils/getArgumentSubjectIdentifier';
import { FlowPath } from '../../../../../Selector/utils/getFieldsByPath';
import getPathForArgument from '../../../../../Selector/utils/getPathForArgument';
import getPathForCondition from '../../../../../Selector/utils/getPathForCondition';
import type { Flow___SubjectIdentifier } from '../../../../../Selector/utils/getSubject';
import Selector from '../../../../../Selector';
import { SelectorContainerProps } from '../..';

const DefaultSelectorContainer: React.FCC<SelectorContainerProps> = ({
  opts,
  condition,
  instanceMap,
  variable,
  pointerOffset,
  pointerLocation,
  ...rest
}) => {
  const reporter = useErrorReporter();

  const isCondition = variable === 0;

  /** By default we open at root level */
  let path: { result: FlowPath; error?: undefined } | { error: string } = {
    result: [],
  };
  let argument: Flow___PrimitiveArgument | undefined = undefined;

  let limitToInstanceSubjects: Array<Flow___SubjectIdentifier> | undefined =
    undefined;

  if (condition) {
    /** A condition is set, check if we open the condition */

    if (isCondition) {
      path = getPathForCondition(condition, { ...opts, instanceMap });
    } else if (variable == null) {
      rest.onClose();
      return null;
    } else {
      const subjectId = getArgumentSubjectIdentifier(
        { condition, argumentIndex: variable - 1 },
        opts,
      );

      if (!subjectId) {
        rest.onClose();
        return null;
      }

      limitToInstanceSubjects = [subjectId];

      /**
       * We are working with an argument, check if its already set
       * so we should open at the argument level already.
       */
      const setArgument = condition.args[variable - 1];

      if (setArgument != null) {
        if (setArgument.__typename === 'Flow___Argument_Pointer') {
          path = getPathForArgument(setArgument, {
            argumentType: { ...subjectId, __typename: 'Flow___SubjectId' },
            instanceMap,
            subjectMap: opts.subjectMap,
            directoryMap: opts.directoryMap,
          });
        } else {
          if (setArgument.__typename !== 'Flow___Argument_File') {
            argument = setArgument;
          }
        }
      }
    }
  }

  if (path.error != null) {
    reporter.captureMessage(path.error, 'debug');
  }

  const initialPath: FlowPath = path.error == null ? path.result : [];

  return (
    <Selector
      pointerOffset={pointerOffset}
      pointerLocation={pointerLocation}
      opts={{ ...opts, limitToInstanceSubjects }}
      initialPath={initialPath}
      argument={argument}
      {...rest}
    />
  );
};

export default DefaultSelectorContainer;
