type GenerateConditionInstanceKey_Args = {
  type: string;
  typeId?: string | null;
};
const generateConditionInstanceKey = ({
  type,
  typeId,
}: GenerateConditionInstanceKey_Args): string => {
  if (typeId == null) return type;
  return `${type}#${typeId}`;
};

export default generateConditionInstanceKey;
