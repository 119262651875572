import React from 'react';
import styled from 'styled-components';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';
import Checkbox from '~/components/molecule/Checkbox';

export type Props = {
  checkboxValue: boolean;
  label: string;
  isOpen: boolean;
  onToggle: () => void;
  onChange: () => void;
};

const CollapsibleBlockWithCheckbox: React.FCC<Props> = ({
  label,
  onToggle,
  isOpen,
  checkboxValue,
  children,
  onChange,
}) => (
  <CollapsibleChevronBlock
    header={
      <JustificationContainer width="100%" align="center">
        <StyledChekbox
          onChange={onChange}
          value={checkboxValue}
          label={
            <Heading4 margin={[null]} variant={Variant.primary}>
              {label}
            </Heading4>
          }
        />
      </JustificationContainer>
    }
    isOpen={isOpen}
    onToggle={onToggle}
  >
    {children}
  </CollapsibleChevronBlock>
);

const StyledChekbox = styled(Checkbox)`
  width: max-content;
  align-items: center;
`;

export default CollapsibleBlockWithCheckbox;
