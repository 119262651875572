import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '../JustificationContainer';
import { Label } from '../Typography';

const text = {
  active: 'Actief',
  inactive: 'Inactief',
};

export type Props = {
  value: boolean;
};

const EnabledStatusIndicator: React.FCC<Props> = ({ value }) => (
  <JustificationContainer align="center">
    <Indicator $active={value} />
    <Label size="base" margin={[null]}>
      {value ? text.active : text.inactive}
    </Label>
  </JustificationContainer>
);

type IndicatorProps = {
  $active: boolean;
};

const Indicator = styled.div<IndicatorProps>(
  ({ theme, $active }) => css`
    border-radius: ${theme.getTokens().border.radius.full};
    display: inline-block;
    background-color: ${$active
      ? theme.color('success')
      : theme.color('tertiary')};
    width: ${theme.space('m')};
    min-width: ${theme.space('m')};
    height: ${theme.space('m')};
    margin-right: ${theme.space('xxs')};
  `,
);

export default EnabledStatusIndicator;
