import React from 'react';
import ColorInput from '../ColorInput';

type Entry = {
  label: string;
  value: string;
};
export type Props = {
  pair: [Entry, Entry];
};

const ColorPair: React.FCC<Props> = ({ dataTestId, pair, ...rest }) => (
  <>
    {pair.map((color, index) => (
      <ColorInput
        {...rest}
        dataTestId={dataTestId}
        readOnly
        key={`${color.value} + ${index}`}
        label={color.label}
        value={color.value}
      />
    ))}
  </>
);

export default ColorPair;
