import cleanedFilename from '~/util/cleanedFilename';

export default {
  SYNC_WITH_CUSTOM_PROVIDER_BUTTON: `${cleanedFilename(
    __filename,
  )}-sync-with-custom-provider-button`,
  SYNC_WITH_CUSTOM_PROVIDER_ERROR: `${cleanedFilename(
    __filename,
  )}-sync-with-custom-provider-error`,
  SYNC_WITH_CUSTOM_PROVIDER_FORM: `${cleanedFilename(
    __filename,
  )}-sync-with-custom-provider-form`,
  SYNC_WITH_CUSTOM_PROVIDER_HEADER: `${cleanedFilename(
    __filename,
  )}-sync-with-custom-provider-header`,
  SYNC_EMAIL_INPUT_FIELD: `${cleanedFilename(
    __filename,
  )}-sync-email-input-field`,
  SYNC_PASSWORD_INPUT_FIELD: `${cleanedFilename(
    __filename,
  )}-sync-password-input-field`,
  LOGIN_HELP_LINK: `${cleanedFilename(__filename)}-login-help-link`,
};
