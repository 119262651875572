import { navigate } from '@gatsbyjs/reach-router';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NoResultSection from '~/components/page/NoResultSection';
import Button from '~/components/atom/Button';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import {
  useDeleteZapierApiKeyMutation,
  useGetZapierApiKeysQuery,
  ZapierApiKey,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import { LINK_PREFIX } from '../../../Wizard';
import AddApiKeyModal from './components/AddApiKeyModal';
import ApiKeysContainer from './components/ApiKeysContainer';
import TEST_ID from './index.testid';

const text = {
  heading: 'API keys',
  description: 'Hier kun je je instellingen voor Zapier beheren.',
  apiKeysDescription:
    'Je kunt een nieuwe API key toevoegen of één verwijderen. Let op: Als je een API key verwijdert en een nieuwe toevoegt, moet je dit ook in Zapier aanpassen. Anders werkt de app niet meer.',
  addApiKey: 'API key toevoegen',

  deletionErrorMessage:
    'Er is iets fout gegaan bij het verwijderen van de api key, probeer het later opnieuw.',
  deletionSuccessMessage: 'Api key succesvol verwijderd.',
  noResultErrorMessage:
    'Er is iets fout gegaan bij het ophalen van de API keys, probeer het later opnieuw.',
};

export type Props = {};

const Connections: React.FCC<Props> = ({ dataTestId, ...rest }) => {
  const addToast = useAddToast();
  const { id: accountId } = useCurrentAccount();
  const [showAddApiKeyModal, setShowAddApiKeyModal] = useState<boolean>(false);

  const { data, loading, updateQuery, error } = useGetZapierApiKeysQuery({
    variables: { accountId },
  });

  const [deleteZapierApiKey] = useDeleteZapierApiKeyMutation();
  useEffect(() => {
    const isConnectedKey = data?.getZapierApiKeys.some(
      key => !isNil(key.lastConnectedAt),
    );
    if (!loading && !error && !isConnectedKey) {
      void navigate(`${LINK_PREFIX}/`);
    }
  }, [data?.getZapierApiKeys, error, loading]);

  const onDelete = async (key: string): Promise<void> => {
    const { data: mutationData, errors } = await deleteZapierApiKey({
      variables: {
        accountId,
        apiKey: key,
      },
    });

    if (errors && errors.length > 0) {
      addToast([formatToastMessage(text.deletionErrorMessage, 'danger')]);
      return;
    }

    if (mutationData?.deleteZapierApiKey.success) {
      addToast([formatToastMessage(text.deletionSuccessMessage, 'success')]);

      return updateQuery(prevResult => ({
        ...prevResult,
        getZapierApiKeys: prevResult?.getZapierApiKeys.filter(
          item => item.apiKey !== key,
        ),
      }));
    }

    return;
  };

  if (error || !data?.getZapierApiKeys) {
    return <NoResultSection>{text.noResultErrorMessage}</NoResultSection>;
  }

  const lastApiKey = data.getZapierApiKeys.length === 1;

  return (
    <Container data-testid={dataTestId} {...rest}>
      <Body>{text.description}</Body>
      <Heading4 margin={['xl', null, null, null]} variant={Variant.primary}>
        {text.heading}
      </Heading4>
      <Body margin={[null, null, 'm', null]}>{text.apiKeysDescription}</Body>

      <ApiKeysContainer
        apiKeys={data.getZapierApiKeys}
        onDelete={onDelete}
        dataTestId={TEST_ID.API_KEYS_CONTAINER}
        disabled={lastApiKey}
      />

      <StyledButton
        label={text.addApiKey}
        ghost
        size="large"
        appearance="secondary"
        icon="plus"
        onClick={() => setShowAddApiKeyModal(true)}
        dataTestId={TEST_ID.ADD_API_KEY_BUTTON}
      />

      {showAddApiKeyModal && (
        <AddApiKeyModal
          onClose={() => setShowAddApiKeyModal(false)}
          onAddKey={(apiKey: ZapierApiKey) => {
            if (!apiKey) return;

            return updateQuery(prevResult => ({
              ...prevResult,
              getZapierApiKeys: [...prevResult?.getZapierApiKeys, apiKey],
            }));
          }}
          accountId={accountId}
        />
      )}
    </Container>
  );
};

const Container = styled.div<{}>``;

const StyledButton = styled(Button)<{}>`
  width: 100%;
  justify-content: flex-start;
`;

export default Connections;
