/**
 *
 * Made by Juliette Moulen
 */
import { Props } from '..';

import React from 'react';

const ProfilePlaceholder = (props: Props) => (
  <svg viewBox="0 0 43 43" width="100%" height="100%" {...props}>
    <title>{'User Image'}</title>
    <defs>
      <circle id="prefix__a" cx={21.5} cy={21.5} r={21.5} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b" fill="#fff">
        <use xlinkHref="#prefix__a" />
      </mask>
      <use fill="#EDEFF2" xlinkHref="#prefix__a" />
      <circle
        stroke="#4A4A4A"
        strokeWidth={2}
        fillRule="nonzero"
        mask="url(#prefix__b)"
        cx={22}
        cy={19}
        r={8}
      />
      <circle
        stroke="#4A4A4A"
        strokeWidth={2}
        fillRule="nonzero"
        mask="url(#prefix__b)"
        cx={22}
        cy={43}
        r={12}
      />
    </g>
  </svg>
);

export default ProfilePlaceholder;
