import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRecoilValue } from 'recoil';

import EmptyStateComponent from '~/components/template/EmptyStateComponent';
import { Task as TaskDetailProps } from '~/components/page/Tasks/types';
import TaskModal from '../TaskModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TEST_ID from './index.testid';

import useMainOffice from '~/hooks/useMainOffice';
import useCurrentUser from '~/hooks/useCurrentUser';
import groupedTasks from '~/components/page/Tasks/state';
import InfiniteScroll from '~/components/molecule/InfiniteScroll';
import CardGroup from '~/components/template/EventTimelineV2/components/CardGroup';

const text = {
  noResults: 'Je hebt nog geen taken',
  emptyStateDescription:
    'Maak een taak voor jezelf of een collega voor een goede opvolging van leads en relaties.',
  emptyStateButtonLabel: 'Voeg je eerste taak toe',
};

export type Props = {
  dataTestId?: string;
  hasMore: boolean;
  showModal: boolean;
  initialTaskForModal: TaskDetailProps | null;
  defaultOfficeId: string | null;
  defaultUserId: string | null;
  onClick: (task: TaskDetailProps | null) => void;
  onAddNewTask: () => void;
  loadMore: () => Promise<any>;
  showTaskModal: (task?: TaskDetailProps) => any;
  closeModal: () => void;
};

const TaskList: React.FC<Props> = ({
  dataTestId,
  onClick,
  onAddNewTask,
  loadMore,
  hasMore,
  showModal,
  closeModal,
  initialTaskForModal,
  defaultOfficeId,
  defaultUserId,
  ...rest
}) => {
  const tasks = useRecoilValue(groupedTasks);
  const account = useCurrentAccount();

  const me = useCurrentUser();
  const mainOffice = useMainOffice(me.id);

  const [lastTaskElementHeight, setHeight] = useState<number>(0);
  const lastTaskRef = useRef<HTMLDivElement | null>(null);
  const calculateHeightOfLastElement = useCallback(() => {
    if (lastTaskRef.current) {
      const lastChild = lastTaskRef.current.lastChild as HTMLElement;
      const lastChildHeight = lastChild.offsetHeight;

      setHeight(lastChildHeight);
    }
  }, []);
  useEffect(() => {
    calculateHeightOfLastElement();
  }, [calculateHeightOfLastElement, tasks]);

  return (
    <>
      {showModal && (
        <TaskModal
          selectedInFilterOfficeId={defaultOfficeId || mainOffice?.id || ''}
          selectedInFilterUserId={defaultUserId || me.id || ''}
          account={account}
          initialTaskDetails={initialTaskForModal}
          onClose={closeModal}
        />
      )}
      <Container data-testid={dataTestId} {...rest}>
        {Object.keys(tasks).length === 0 && (
          <EmptyStateComponent
            header={text.noResults}
            buttonLabel={text.emptyStateButtonLabel}
            description={text.emptyStateDescription}
            illustration="list"
            onButtonClick={onAddNewTask}
            dataTestId={TEST_ID.EMPTY_STATE}
          />
        )}
        <TaskContainer $borderHeight={lastTaskElementHeight}>
          <InfiniteScroll fetchMoreFn={loadMore} hasMore={hasMore}>
            {Object.keys(tasks).map((date, index) => (
              <CardGroup
                ref={
                  index === Object.keys(tasks).length - 1 ? lastTaskRef : null
                }
                onOpenTaskModal={onClick}
                date={date}
                items={tasks[date] ?? []}
                key={date}
              />
            ))}
          </InfiniteScroll>
        </TaskContainer>
      </Container>
    </>
  );
};

const TaskContainer = styled.div<{ $borderHeight: number }>(
  ({ theme, $borderHeight }) => css`
    position: relative;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      /** Account for the height of the FIRST icon */
      top: 100px;
      left: ${theme.space('l')};
      /** Account for the height of the LAST icon */
      bottom: ${$borderHeight}px;
      border-left: 1px dashed ${theme.color('tertiary')};
    }
  `,
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    position: relative;
    margin-top: ${theme.space('xxxl')};
  `,
);
export default TaskList;
