import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Color from 'color';
import generateColorScale from './utils/generateColorScale';
import useMeasure from '~/hooks/useMeasure';
import getContrastingColor from '~/util/getContrastingColor';
import { animated, useSpring } from 'react-spring';

export type Props = {
  leadScore: number;
};
const leadScoreRange = [...new Array(100)];

const LeadScoreGaugeV2: React.FCC<Props> = React.memo(
  ({ dataTestId, leadScore, ...rest }) => {
    const { ref, bounds } = useMeasure();
    const theme = useTheme();
    const tokens = theme.getTokens();

    const colors = [
      tokens.colors.leadScore['lt30'],
      tokens.colors.leadScore['gte30'],
      tokens.colors.leadScore['gte50'],
      tokens.colors.leadScore['gte70'],
      tokens.colors.leadScore['gte90'],
    ];

    // 25 steps generates 100 color values.
    const colorScale = generateColorScale(colors, 25);
    const barWidth = bounds.width / 100;

    const props = useSpring({
      from: { number: 0 },
      to: { number: leadScore },
      config: { duration: 1000 },
    });

    const backgroundColor = colorScale[leadScore];
    const offsetLeft = (bounds.width / 100) * leadScore;

    return (
      <Container
        data-testid={dataTestId}
        ref={ref}
        $barWidth={Math.round(barWidth)}
        {...rest}
      >
        {leadScoreRange.map((_, index) =>
          // Only show half of the bars so they can't get squashed by rendering
          index % 2 === 0 ? (
            <Bar
              key={index}
              $color={colorScale[index]}
              $width={Math.round(barWidth)}
            />
          ) : null,
        )}
        <CurrentValue
          style={{
            transform: `translateX(calc(${offsetLeft === 0 ? LABEL_OVERFLOW : offsetLeft}px - ${LABEL_OVERFLOW}))`,
            backgroundColor,
            color: getContrastingColor(backgroundColor, theme),
            /* Custom inset box shadow used no where else */
            boxShadow: `inset 0px 1px 4px ${Color(backgroundColor)
              .darken(0.1)
              .hex()}`,
          }}
        >
          <animated.span>{props.number.to(n => n.toFixed(0))}</animated.span>
        </CurrentValue>
      </Container>
    );
  },
);

// We need to inset everything slightly to avoid overflow hidden
const INSET_VALUE = '1rem';
const Container = styled.div<{ $barWidth: number }>(
  ({ $barWidth }) => css`
    display: flex;
    justify-content: space-evenly;
    gap: ${$barWidth}px;
    width: 100%;
    position: relative;
    padding: 0 ${INSET_VALUE};
  `,
);

const Bar = styled.span<{ $color: string; $width: number }>(
  ({ $color, $width }) => css`
    height: 2rem;
    width: ${$width}px;
    flex: 0 0 ${$width}px;
    background-color: ${$color};
  `,
);

const LABEL_WIDTH = '2.44rem';
const LABEL_OVERFLOW = '0.22rem'; // Half of the LABEL_WIDTH decimal

const CurrentValue = styled(animated.span)(
  ({ theme }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: ${theme.fontWeight('medium')};

    width: ${LABEL_WIDTH};
    height: ${LABEL_WIDTH};

    top: -${LABEL_OVERFLOW};
    left: 0;
    bottom: -${LABEL_OVERFLOW};

    transform: translateX(0px);

    transition-property: color, background-color, transform;
    transition-duration: 1s;
    transition-timing-function: ease-out;

    background-color: ${theme.getTokens().colors.leadScore.lt30};

    border-radius: ${theme.getTokens().border.radius.base};

    font-size: ${theme.fs('m')};
  `,
);

export default LeadScoreGaugeV2;
