import React from 'react';
import styled, { css, type DefaultTheme } from 'styled-components';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import Button, { type Props as ButtonProps } from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Div from '~/components/atom/Div';

export type Props = {
  sentiment: 'positive' | 'negative';
  selected?: boolean;
  title: React.ReactNode;
  description?: React.ReactNode;
  buttons?: Array<ButtonProps>;
  onClick?: () => void;
};

const EntryCard: React.FCC<Props> = ({
  dataTestId,
  sentiment,
  selected = false,
  title,
  description,
  buttons,
  onClick,
}) => (
  <Container
    data-testid={dataTestId}
    $sentiment={sentiment}
    $selected={selected}
    direction="row"
    justification="space-between"
    onClick={onClick}
  >
    <Div>
      <Heading4 variant={Variant.primary}>{title}</Heading4>
      {description && <Body>{description}</Body>}
    </Div>
    {buttons && buttons.length !== 0 && (
      <JustificationContainer gap="s">
        {buttons.map((buttonProps, index) => (
          <Button {...buttonProps} key={index} />
        ))}
      </JustificationContainer>
    )}
  </Container>
);

const Container = styled(JustificationContainer)<{
  $sentiment: Props['sentiment'];
  $selected: Props['selected'];
}>(
  ({ theme, $sentiment, $selected }) => css`
    position: relative;
    width: 100%;
    box-shadow: ${theme.boxShadow('around')};
    padding: ${theme.space('m')};
    border-radius: ${theme.getTokens().border.radius.s};
    outline: ${$selected ? '2px dashed' : 'none'};
    flex-shrink: 0;

    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 6px;
      background-color: ${getColorForSentiment($sentiment, theme)};
    }
  `,
);

const getColorForSentiment = (
  sentiment: Props['sentiment'],
  theme: DefaultTheme,
) => {
  switch (sentiment) {
    case 'positive':
      return theme.color('success');

    case 'negative':
      return theme.color('danger');
    default:
      return theme.color('primary');
  }
};

export default EntryCard;
