import cleanedFilename from '~/util/cleanedFilename';

const downloadFileWithSrc = async (
  src: string,
  onSuccess: (file: File | null) => void,
  fileType?: string,
): Promise<void> => {
  try {
    const response = await fetch(src.replace(/&amp;/g, '&'), {
      /**
       * Setting our cache policy to 'no-cache' to prevent CORS issues
       * when trying to download an already cached file in chromium.
       *
       * You can read more about the underlying issue here:
       * @see https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req/856948#856948
       */
      cache: 'no-cache',
    });

    if (!response.ok) {
      throw new Error(
        `${cleanedFilename(__filename)} | Error response ${
          response.statusText
        }`,
      );
    }

    const blob = await response.blob();
    return onSuccess(
      new File([blob], 'download-file', { type: fileType || blob.type }),
    );
  } catch (e) {
    return onSuccess(null);
  }
};

export default downloadFileWithSrc;
