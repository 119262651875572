import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useLocation } from '@gatsbyjs/reach-router';

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location.pathname, location.search]);
};

export default useAnalytics;
