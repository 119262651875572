import type { CssPropertiesKebab } from '~/theme/GlobalStyle/types';

/**
 * Removes specified style properties from all elements within an HTML document.
 * Directly modifies the provided HTML Document object.
 *
 * @param {Document} doc - parsed html string. Any result from DomParser
 * @param {Array<string>} attrsToRemove - Attributes to remove from the style ['font-family']
 */
const removeStyleProperties = (
  doc: Document,
  attrsToRemove: Array<keyof CssPropertiesKebab>,
): Document => {
  const allElementsWithStyle = doc.querySelectorAll('[style]');

  [...allElementsWithStyle].forEach((element: HTMLElement) => {
    const htmlElement = element;

    if (htmlElement.style) {
      attrsToRemove.forEach(attr => {
        htmlElement.style.removeProperty(attr);
      });
    }
  });

  return doc;
};
export default removeStyleProperties;
