import type { FlowV2_Action__Input } from '~/graphql/types';
import type { ClientFlowActionStart } from '~/graphql/types.client';
import generateConditionList from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2/utils/generateConditionList';

/**
 *
 * @param {ClientFlowActionStart} action - Start client action
 * keywords:
 */
const serializeStartAction = (
  action: ClientFlowActionStart,
): FlowV2_Action__Input => {
  const { id, accountId, flowBlueprintId, condition } = action;

  return {
    Start: {
      id,
      accountId,
      flowBlueprintId,
      condition: generateConditionList(condition),
    },
  };
};

export default serializeStartAction;
