import {
  FlowData___InputAppearance,
  FlowData___PrimitiveInputFragment,
  FlowData___PrimitiveListInputFragment,
} from '~/graphql/types';
import {
  BasePrimitive,
  Flow___PrimitiveArgument,
} from '~/graphql/types.client';
import { assertNever } from '~/util/assertNever';
import { format } from '~/util/Validation/Tag';

type GetInitialArgumentArgs = {
  input:
    | FlowData___PrimitiveInputFragment
    | FlowData___PrimitiveListInputFragment;
  initialValue: BasePrimitive;
};
const getInitialPrimitiveInputArgument = ({
  input,
  initialValue,
}: GetInitialArgumentArgs): Flow___PrimitiveArgument | null => {
  if (input.__typename === 'FlowData___PrimitiveListInput') {
    const [first] = input.options;

    switch (first.__typename) {
      case 'FlowData___PrimitiveListInputOption_Boolean':
        return {
          __typename: 'Flow___Argument_Boolean',
          value_boolean:
            initialValue != null ? Boolean(initialValue) : first.value_boolean,
        };
      case 'FlowData___PrimitiveListInputOption_Int':
        return {
          __typename: 'Flow___Argument_Integer',
          value_integer:
            initialValue != null
              ? parseInt(initialValue.toString() ?? '0', 10)
              : first.value_integer,
        };
      case 'FlowData___PrimitiveListInputOption_String':
        const initialSelection = input.options.find(option => {
          if (
            option.__typename !== 'FlowData___PrimitiveListInputOption_String'
          ) {
            return false;
          }

          return option.value_string == initialValue;
        });

        if (
          !initialSelection ||
          initialSelection.__typename !==
            'FlowData___PrimitiveListInputOption_String'
        ) {
          return {
            __typename: 'Flow___Argument_String',
            value_string: first.value_string,
          };
        }

        return {
          __typename: 'Flow___Argument_String',
          value_string: initialSelection.value_string,
        };
    }
  }

  switch (input.inputAppearance) {
    case FlowData___InputAppearance.File: {
      return null;
    }
    case FlowData___InputAppearance.Float: {
      const value = parseFloat(initialValue?.toString() ?? '0.1');
      return {
        __typename: 'Flow___Argument_Float',
        value_float: isNaN(value) ? 0.1 : value,
      };
    }
    case FlowData___InputAppearance.Integer: {
      const value = parseInt(initialValue?.toString() ?? '1', 10);
      return {
        __typename: 'Flow___Argument_Integer',
        value_integer: isNaN(value) ? 1 : value,
      };
    }
    case FlowData___InputAppearance.Tag: {
      return {
        __typename: 'Flow___Argument_String',
        value_string: format(initialValue?.toString() ?? ''),
      };
    }
    case FlowData___InputAppearance.Text: {
      return {
        __typename: 'Flow___Argument_String',
        value_string: initialValue?.toString() ?? '',
      };
    }

    default:
      return assertNever(input);
  }
};

export default getInitialPrimitiveInputArgument;
