import {
  getLocalStorageItem,
  SIGNUP_PARAMS,
  setLocalStorageItem,
} from '~/util/localStorageKeys';
import { BillingCycle, Plan } from '~/graphql/types';
import { where } from 'ramda';
import qs from 'query-string';
import { useLocation } from '@gatsbyjs/reach-router';
import cleanedFilename from '~/util/cleanedFilename';
import Validation from '~/util/Validation';
import { reporter } from '../useErrorReporter';

export type SignupParams = {
  plan: Plan;
  billingCycle: BillingCycle;
  couponCode?: string;
};

const isNotSetOrIsString = (value: any): value is string | null | undefined =>
  value == null || Validation.String.isString(value);

export const isValidSignupParams = (value: any): value is SignupParams =>
  where(
    {
      couponCode: isNotSetOrIsString,
    },
    value,
  );

const useSignupParams = (): {
  couponCode?: string | null;
} => {
  const location = useLocation();
  const parsedFromQuery = qs.parse(location.search);

  const SignupParamsInURL = {
    couponCode: parsedFromQuery.couponCode,
  };

  if (isValidSignupParams(SignupParamsInURL)) {
    setLocalStorageItem(SIGNUP_PARAMS, JSON.stringify(SignupParamsInURL));
  }
  const localStorageItem = getLocalStorageItem(SIGNUP_PARAMS);
  if (localStorageItem == null) {
    return {
      couponCode: null,
    };
  }

  try {
    const deserializedLocalStorageEntry = JSON.parse(localStorageItem);
    if (!isValidSignupParams(deserializedLocalStorageEntry)) {
      return {
        couponCode: null,
      };
    }

    return deserializedLocalStorageEntry as SignupParams;
  } catch (error) {
    reporter.captureException(
      new Error(
        `${cleanedFilename(
          __filename,
        )} | Malformed signup params in localstorage: ${localStorageItem}.`,
      ),
    );
  }
  return {
    couponCode: null,
  };
};

export default useSignupParams;
