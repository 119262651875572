import { ArgumentId } from '../..';
import isActiveConditionGroup from '../isActiveConditionGroup';

const isActiveCondition = ({
  activeSelector,
  argumentId,
}: {
  activeSelector?: ArgumentId | null;
  argumentId: ArgumentId;
}): boolean =>
  activeSelector != null &&
  isActiveConditionGroup({ activeSelector, argumentId }) &&
  activeSelector.conditionIdx != null &&
  activeSelector.conditionIdx === argumentId.conditionIdx;

export default isActiveCondition;
