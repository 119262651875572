import React from 'react';

import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import HelpButton from '~/components/molecule/HelpButton';

export type Props = {
  help: {
    link: string;
  };
};

const withHelpLink = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  args?: P,
) => {
  const ComponentWithHelpLink: React.FCC<Props & P> = ({
    dataTestId,
    help,
    ...rest
  }) => (
    <JustificationContainer>
      <WrappedComponent dataTestId={dataTestId} {...args} {...(rest as P)} />
      <StyledHelpButton link={{ link: help.link }} />
    </JustificationContainer>
  );

  return ComponentWithHelpLink;
};

const StyledHelpButton = styled(HelpButton)<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('xxs')};
    vertical-align: top;
  `,
);

export default withHelpLink;
