import {
  EmailProvider__Input,
  StartEmailSyncWithCustomProviderMutationVariables,
} from '~/graphql/types';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/components/NylasAuthoriseEndpointV1/types';
import { assertNever } from '~/util/assertion';

/**
 * Returns variables for startEmailSyncWithCustomProvider mutation
 */
const getVariablesForMutation = ({
  entityType,
  accountId,
  entityId,
  providerInfo,
}: {
  entityType: SynchroniseEntityType;
  accountId: string;
  entityId: string;
  providerInfo: EmailProvider__Input;
}): StartEmailSyncWithCustomProviderMutationVariables => {
  switch (entityType) {
    case SynchroniseEntityType.Account:
      return {
        accountId,
        account: {
          accountId,
        },
        provider: providerInfo,
      };
    case SynchroniseEntityType.Office:
      return {
        accountId,
        office: {
          accountId,
          officeId: entityId,
        },
        provider: providerInfo,
      };
    case SynchroniseEntityType.User:
      return {
        accountId,
        user: {
          accountId,
        },
        provider: providerInfo,
      };

    default:
      return assertNever(entityType);
  }
};

export default getVariablesForMutation;
