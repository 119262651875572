import React, { useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import ReactMapGl, { MapProvider, type MapRef } from 'react-map-gl';
import mapboxglSupported from '@mapbox/mapbox-gl-supported';
import MapBoxContext from '~/contexts/MapBoxContext';
import { Draw } from './components/Draw';
import type ExtendedMapboxDraw from './components/Draw/ExtendedMapboxDraw';
import mapboxgl from 'mapbox-gl';
import GeocodeSearchInput from '../GeocodeSearchInput';
import isValidCoordinate from '../../utils/isValidCoordinate';
import useErrorReporter from '~/hooks/useErrorReporter';
import type { Client_WorkingArea } from '../../utils/getClientWorkingAreas';

export type Client_Feature = Client_WorkingArea['feature'];
type Client_Features = Array<Client_Feature>;

export type LoadArgs = { draw: ExtendedMapboxDraw; mapboxMap: MapRef };

export type Props = {
  dataTestId?: string;
  // Used for error reporting
  officeId: string;

  height: number;
  width: number;
  features: Client_Features;

  onCreate?: (features: Client_Features) => void;
  onUpdate?: (features: Client_Features) => void;
  onDelete?: (features: Client_Features) => void;
  onSelect?: (features: Client_Features) => void;

  onLoaded?: (args: LoadArgs) => void;
};

const MapsContainer: React.FC<Props> = ({
  dataTestId,
  officeId,

  // Main props
  features,
  width,
  height,

  // Interaction handlers
  onCreate,
  onUpdate,
  onDelete,
  onSelect,
  onLoaded,
}) => {
  const mapRef = useRef<MapRef>(null);
  const mapboxApiAccessToken = useContext(MapBoxContext);
  const errorReporter = useErrorReporter();

  useEffect(() => {
    if (mapRef.current) {
      // Watch for changes to the bounding rect and resize if needed
      mapRef.current.resize();
    }
  }, [height, width]);

  if (!mapboxglSupported.supported()) {
    return null;
  }

  const onLoad = () => {
    if (features.length === 0) return;

    // Fit existing features into view
    let allCoordinates: Array<[number, number]> = [];
    try {
      allCoordinates = features
        .reduce((prev, c) => {
          // Only fit the features aka Polygons in view
          if (c.geometry.type === 'Polygon')
            return [...prev, ...c.geometry.coordinates];
          return prev;
        }, [])
        // Make sure c.geometry.coordinates is Position[][]
        .flat(1) as Array<[number, number]>;

      const hasInvalidCoordinates = allCoordinates.some(
        coord => !isValidCoordinate(coord),
      );

      if (hasInvalidCoordinates) {
        errorReporter.captureException(
          new Error(`Office ${officeId} has invalid coordinates`),
          'fatal',
        );
      }

      allCoordinates = allCoordinates.filter(isValidCoordinate);
    } catch (error) {
      return errorReporter.captureException(error);
    }

    if (allCoordinates.length === 0) return;

    // Create a 'LngLatBounds' with both corners at the first coordinate.
    const bounds = new mapboxgl.LngLatBounds(
      allCoordinates[0],
      allCoordinates[0],
    );

    // Add the rest of the coordinates
    for (const coordinate of allCoordinates) {
      bounds.extend(coordinate);
    }

    mapRef.current?.fitBounds(bounds, {
      padding: 30,
      maxZoom: 10,
      // Center it between the left side and Aside
      offset: [-100, 0],
    });
  };

  return (
    <>
      <Helmet>
        <link
          href="https://api.tiles.mapbox.com/mapbox-gl-js/v3.1.0/mapbox-gl.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.4.3/mapbox-gl-draw.css"
          type="text/css"
        />
        {/* Feather icons icon font CDN */}
        <link
          rel="stylesheet"
          type="text/css"
          href="//at.alicdn.com/t/font_o5hd5vvqpoqiwwmi.css"
        />
      </Helmet>
      <Container data-testid={dataTestId}>
        <MapProvider>
          <ReactMapGl
            ref={mapRef}
            initialViewState={
              features.length === 0
                ? {
                    latitude: 52.090736,
                    longitude: 5.12142,
                    zoom: 8,
                  }
                : undefined
            }
            style={{
              width,
              height,
            }}
            attributionControl={false}
            mapStyle="mapbox://styles/mapbox/light-v10"
            mapboxAccessToken={mapboxApiAccessToken}
            onLoad={onLoad}
          >
            <Draw
              features={features}
              onCreate={onCreate}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onSelect={onSelect}
              onLoaded={onLoaded}
            />
            <GeocodeSearchInput mapboxAccessToken={mapboxApiAccessToken} />
          </ReactMapGl>
        </MapProvider>
      </Container>
    </>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100%;

    /* Remove the Mapbox logo  */
    .mapboxgl-ctrl-logo {
      display: none;
    }

    & > div {
      cursor: auto !important;
    }

    /*
     * Styles for icons
     * we're not using fw values from the theme here
     * because the icon font is non standard so our system values won't work for it.
     */
    .inclusive-icon {
      color: ${theme.color('success')};
      font-weight: 900;
    }
    .exclusive-icon {
      color: ${theme.color('danger')};
      font-weight: 900;
    }
  `,
);

export default MapsContainer;
