import React from 'react';
import styled from 'styled-components';
import { linkStyles } from '~/components/molecule/Link';
import Label from '../components/Label';
import { LinkBlock, LinkBlockType } from '~/graphql/types';

export type Props = Omit<LinkBlock, '__typename'> & {
  dataTestId?: string;
};

const LinkBlockComponent: React.FC<Props> = ({ label, linkValue, css }) => {
  const prefix = getPrefixForLink(linkValue);

  return (
    <>
      {label && <Label>{label}</Label>}
      <Anchor href={`${prefix}${linkValue.href}`} css={css}>
        {linkValue.label ?? linkValue.href}
      </Anchor>
    </>
  );
};

export default LinkBlockComponent;

const Anchor = styled.a(linkStyles);

const getPrefixForLink = (value: Props['linkValue']): string => {
  switch (value.type) {
    case LinkBlockType.Email:
      return 'mailto:';
    case LinkBlockType.Phone:
      return 'tel:';

    default:
      return '';
  }
};
