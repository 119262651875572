import React from 'react';
import styled, { css } from 'styled-components';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import { Heading3 } from '~/components/atom/Typography';
import Modal from '../Modal';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  header: React.ReactNode;
  /* Method provided by an Overlay component */
  onClose?: () => void;
  children?: React.ReactNode;
};

const MAX_DIALOG_WIDTH = 760;
const Form = React.forwardRef<HTMLDivElement, Props>(
  ({ dataTestId, header, onClose, children, ...rest }, ref) => (
    <Modal maxWidth={`${MAX_DIALOG_WIDTH}px`} onClose={onClose}>
      <Container
        direction="column"
        data-testid={dataTestId}
        {...rest}
        ref={ref}
        padding={['xxxxl', 'xxxxl', 'xxl', 'xxxxl']}
        width="100%"
      >
        <Heading3>{header}</Heading3>

        <Inner>
          {React.Children.map(
            children,
            child =>
              React.isValidElement(child) &&
              React.cloneElement(child, {
                // Pass down the onClose handler to any child component
                onClose,
                ...child.props,
              }),
          )}
        </Inner>
      </Container>
    </Modal>
  ),
);

const Container = styled(JustificationContainer)<{}>(
  ({}) => css`
    min-height: 50%;
    margin: 0 auto;
    overflow-y: scroll;

    ${scrollBarStyles}
  `,
);

const Inner = styled.div<{}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default Form;
