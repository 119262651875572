import type { Geometry } from 'geojson';
import type { GeoJsonFeature } from '~/graphql/types';

const getClientGeometry = (geometry: GeoJsonFeature['geometry']): Geometry => {
  switch (geometry.__typename) {
    case 'Geometry_Polygon':
      return { type: 'Polygon', coordinates: geometry.multiCoordinates };
    case 'Geometry_LineString':
      return { type: 'LineString', coordinates: geometry.coordinates };
    case 'Geometry_MultiLineString':
      return {
        type: 'MultiLineString',
        coordinates: geometry.multiCoordinates,
      };
    case 'Geometry_MultiPoint':
      return { type: 'MultiPoint', coordinates: geometry.coordinates };
    case 'Geometry_MultiPolygon':
      return { type: 'MultiPolygon', coordinates: geometry.poliCoordinates };
    case 'Geometry_Point':
      return { type: 'Point', coordinates: geometry.position };
  }
};

export default getClientGeometry;
