import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { nodesSelector } from '../../../../state/nodesAndEvents';
import { copyScreenNodeState } from '../../../../state/copyScreenNode';
import type { FormBuilder_Node_DefaultNext } from '~/graphql/types';
import insertNode from '../../../../utils/insertNode';
import Icon from '~/components/atom/Icon';
import { Body } from '~/components/atom/Typography';
import useIsBasicForm from '../../../../hooks/useIsBasicForm';
import { optionListsState } from '../../../../state/optionList';
import getDuplicatedOptionLists from './utils/getDuplicatedOptionLists';
import copyScreenNode from './utils/copyScreenNode';
import { TRAILING_NODE_ID } from '../../constants';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

export type Props = {
  source: string;
  target: string;
};

const text = {
  addNode: 'Pagina invoegen',
  pasteError:
    'Er is iets misgegaan bij het invoegen van de pagina. Probeer het opnieuw. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};

const PasteContainer: React.FCC<Props> = ({ source, target }) => {
  const addToast = useAddToast();

  const [, setNodesState] = useRecoilState(nodesSelector);
  const copyState = useRecoilValue(copyScreenNodeState);
  const isBasicForm = useIsBasicForm();
  const [optionLists, setOptionsLists] = useRecoilState(optionListsState);

  const onPaste = () => {
    if (!copyState) return;

    const { updatedOptionLists, optionListForBlockLookup } =
      getDuplicatedOptionLists({
        copiedNode: copyState.copiedNode,
        isBasicForm,
        optionLists,
      });

    // First update the option lists
    setOptionsLists(updatedOptionLists);

    // Then create the new node
    try {
      const newNode = copyScreenNode({
        node: copyState.copiedNode,
        optionListForBlockLookup,
        isBasicForm,
      });

      const nodeToInsert = {
        ...newNode,
        defaultNext:
          target === TRAILING_NODE_ID
            ? null
            : ({
                __typename: 'FormBuilder_Node_DefaultNext',
                targetNodeId: target,
              } as FormBuilder_Node_DefaultNext),
      };

      setNodesState(prev =>
        insertNode({
          createType: 'screen',
          relation: {
            source,
            target,
          },
          prevNodes: prev,
          nodeToInsert,
        }),
      );
    } catch (error) {
      addToast([formatToastMessage(text.pasteError, 'danger')]);
    }
  };

  return (
    <Container
      align="center"
      justification="center"
      padding={['xxs']}
      gap="xxxs"
      onClick={onPaste}
    >
      <Icon name="clipboard" strokeWidth={3} />
      <Body size="xs" margin={[null]}>
        {text.addNode}
      </Body>
    </Container>
  );
};

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    border: 2px dashed ${theme.color('primary', 'light')};
    border-radius: ${theme.getTokens().border.radius.s};
    cursor: pointer;
  `,
);

export default PasteContainer;
