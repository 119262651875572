import React from 'react';
import styled, { css } from 'styled-components';
import { Container, type ContainerProps } from '../InputContainer';
import type { Props as JustificationProps } from '../JustificationContainer';

/**
 * InputContainer with extra height.
 * Can be used for PluginsEditor, TextArea etc.
 */
const TextEditorContainer: React.FCC<ContainerProps & JustificationProps> = ({
  dataTestId,
  children,
  ...rest
}) => (
  <StyledContainer data-testid={dataTestId} {...rest}>
    {children}
  </StyledContainer>
);

const StyledContainer = styled(Container)<{}>(
  ({ theme }) => css`
    width: 100%;
    padding: ${theme.space('xxs')};
    /** allow it to grow in height */
    max-height: unset;
  `,
);

export default TextEditorContainer;
