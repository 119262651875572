import { useCallback, useEffect, useContext } from 'react';
import RouterTransitionContext from '~/contexts/RouterTransitionContext';

const useBeforeUnload = (value: ((evt: BeforeUnloadEvent) => any) | string) => {
  const { canLeave } = useContext(RouterTransitionContext);
  const handleBeforeunload = useCallback(
    (evt: BeforeUnloadEvent) => {
      if (!canLeave) {
        let returnValue: string;
        if (typeof value === 'function') {
          returnValue = value(evt);
        } else {
          returnValue = value;
        }
        if (returnValue) {
          evt.preventDefault();
          evt.returnValue = returnValue;
        }
        return returnValue;
      }
      return null;
    },
    [value, canLeave],
  );

  useEffect(() => {
    global.window.addEventListener('beforeunload', handleBeforeunload);
    return () =>
      global.window.removeEventListener('beforeunload', handleBeforeunload);
  }, [handleBeforeunload]);
};

export default useBeforeUnload;
