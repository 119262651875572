import { FlowTemplates } from '~/graphql/types';
import { FlowTemplatesCategory } from '../..';
import slugify from 'slugify';

/**
 * Receive flow templates and group them by categories
 * keywords: flow, templates, group, category
 */

const groupByCategory = (
  templates: Array<FlowTemplates> | undefined,
): FlowTemplatesCategory | undefined =>
  templates?.reduce((acc, item) => {
    item.categories.forEach(category => {
      const slug = slugify(category).toLowerCase();
      if (acc[slug]) {
        acc[slug] = {
          ...acc[slug],
          templates: [...acc[slug].templates, item],
        };
      } else {
        acc[slug] = {
          slug,
          name: category,
          templates: [item],
        };
      }
    });

    return acc;
  }, {});

export default groupByCategory;
