import React from 'react';
import { Field as ReactFinalFormField } from 'react-final-form';

import { FieldProps } from 'react-final-form';

import passthrough from '~/util/passthroughFunction';
import checkInputValue from './checkInputValue';

export default ({
  name,
  format,
  parse,
  children,
  component,
  ...rest
}: FieldProps<any, any>) => {
  let parseFunction;

  if (typeof parse === 'function') {
    parseFunction = passthrough(convertEmptyOrUndefinedToNull, parse);
  } else {
    parseFunction = convertEmptyOrUndefinedToNull;
  }

  let formatFunction;
  if (typeof format === 'function') {
    formatFunction = passthrough(defaultFormat, format);
  } else {
    formatFunction = defaultFormat;
  }

  // If we use a special component, do not send children
  if (component !== undefined) {
    return (
      <ReactFinalFormField
        {...rest}
        name={name}
        format={formatFunction}
        parse={parseFunction}
        allowNull={true}
        component={component}
      />
    );
  }

  return (
    <ReactFinalFormField
      {...rest}
      name={name}
      format={formatFunction}
      parse={parseFunction}
      allowNull={true}
    >
      {({ input, ...rest }) => {
        // See src/components/Forms/Form.md for more information
        checkInputValue(name, input.value);

        // If the input does not specify a type then do not overwrite it. See https://gitlab.dathuis.nl/dh/sfa-app/issues/251
        if (input.type === undefined) {
          delete input.type;
        }

        if (!children) return null;
        if (typeof children === 'function') {
          return children({ input, ...rest });
        } else {
          return children;
        }
      }}
    </ReactFinalFormField>
  );
};

export type BaseFieldType = string | number | boolean | null;

/**
 * Format: Field component => HTML input element
 *
 * We overwrite the default format and parse functions of react-final-form.
 * We do not want any undefined variables and the default implementation is to convert empty strings to undefined
 *
 * See also: https://github.com/final-form/react-final-form#format-value-any-name-string--any--null
 */
const defaultFormat = (value: BaseFieldType) => value;

/**
 * Parse: HTML input element => Field component
 *
 * When reading the field data convert undefined or empty strings to null (exactly like on the server)
 */
export const convertEmptyOrUndefinedToNull = <T extends BaseFieldType>(
  value: T | void | undefined,
): T | null => (value === undefined || value === '' ? null : value);
