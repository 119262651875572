import { OverviewStatisticUnit } from '~/graphql/types';

const getFormatOptions = (
  unit: OverviewStatisticUnit,
): Intl.NumberFormatOptions => {
  switch (unit) {
    case OverviewStatisticUnit.Currency:
      return {
        currency: 'EUR',
        style: 'currency',
      };
    case OverviewStatisticUnit.Percent:
      return {
        minimumFractionDigits: 0,
        style: 'percent',
      };

    case OverviewStatisticUnit.Decimal:
    default:
      return {
        minimumFractionDigits: 0,
        style: 'decimal',
      };
  }
};

export default getFormatOptions;
