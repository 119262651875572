import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import Connections from './components/Connections';

export type Props = {} & RouteComponentProps;

const text = {
  header: 'Instellingen',
};

const Settings: React.FCC<Props> = ({ dataTestId }) => (
  <AppDetailsContainer header={text.header} icon="gear" dataTestId={dataTestId}>
    <Connections />
  </AppDetailsContainer>
);

export default Settings;
