import { Transforms } from 'slate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import {
  DHEditor,
  LinkElement,
} from '~/components/organism/PluginsEditor/types';

export const insertLink = (editor: DHEditor, url: string, text: string) => {
  const linkEl: LinkElement = {
    type: ELEMENTS.LINK,
    url,
    children: [{ text }],
  };

  if (!text) {
    Transforms.wrapNodes(editor, linkEl);
    return;
  }

  Transforms.insertNodes(editor, linkEl);
};
