import React from 'react';
import { FlowAction } from '~/graphql/types';

import getCardHeading from '../../../../utils/getCardHeading';
import TagActionCard, {
  Props as TemplateProps,
} from './components/TagCardTemplate';

type Props = Omit<TemplateProps, 'heading' | 'actionType'>;
export const AddTagCard: React.FCC<Props> = React.memo(props => (
  <TagActionCard
    heading={getCardHeading(FlowAction.ContactAddTag)}
    actionType={FlowAction.ContactAddTag}
    dataTestId="AddTagActionCard"
    {...props}
  />
));

export const DeleteTagCard: React.FCC<Props> = React.memo(props => (
  <TagActionCard
    heading={getCardHeading(FlowAction.ContactDeleteTag)}
    actionType={FlowAction.ContactDeleteTag}
    dataTestId="DeleteTagActionCard"
    {...props}
  />
));
