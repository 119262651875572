import type {
  ContactFiltersLeaf__Input,
  ContactFiltersSubjectFragment,
  ContactFiltersTopicFragment,
  ContactFiltersSubjectFieldFragment,
  ContactFiltersLeafCommands__Input,
} from '~/graphql/types';
import { cloneDeep } from 'lodash';
import { assertNever } from '~/util/assertion';
import { getCommand, CommandMap } from '../getCommand';
import { getTopic, TopicMap } from '../getTopic';
import getSubject, { SubjectMap } from '../getSubject';
import getOuterFieldsByPath, {
  OuterContactFilterField,
} from '../getOuterFieldsByPath';
import getInnerFieldsByPath, {
  InnerContactFilterFieldOutput,
} from '../getInnerFieldsByPath';

export type GetContactFilterFieldsOpts = {
  subjectMap: SubjectMap;
  commandMap: CommandMap;
  topicMap: TopicMap;
};
const getPathForLeaf = (
  leaf: ContactFiltersLeaf__Input,
  opts: GetContactFilterFieldsOpts,
):
  | {
      error?: undefined;
      result: {
        basePath: ReturnType<typeof getOuterFieldsByPath>;
        commands: Array<ReturnType<typeof getInnerFieldsByPath>>;
        /**
         * Set if there is any error within the basePath / commands.
         * This is first come, first serve, if there are multiple errors
         * only one will be returned.
         */
        error?: string;
      };
    }
  | {
      /**
       * Set if there is any error within the leaf in itself.
       * Malformed or else.
       */
      error: string;
    } => {
  const { topicMap, subjectMap, commandMap } = opts;
  const { attribute, instanceAttribute } = leaf;

  if (attribute != null && instanceAttribute != null) {
    return {
      error: `attribute and instanceAttribute are both set?!`,
    };
  }

  if (attribute != null) {
    const subject = getSubject(attribute, subjectMap);
    if (!subject) {
      const error = `Missing subject ${attribute.subjectId}:${attribute.eventId}`;
      return {
        result: {
          basePath: {
            error,
          },
          commands: [],
          error,
        },
      };
    }

    const action = subject.actions.find(act => act.key === attribute.actionKey);
    if (!action) {
      const error = `Unknown action for subject: ${attribute.subjectId}:${attribute.eventId} actionKey: ${attribute.actionKey}`;
      return {
        result: {
          basePath: {
            error,
          },
          commands: [],
          error,
        },
      };
    }

    return cloneDeep({
      result: {
        basePath: {
          result: getBasePath(subject, {
            __typename: 'ContactFiltersSubjectAttributeAction',
            ...attribute,
            key: action.key,
            label: action.label,
            representation: action.representation,
          }),
        },
        commands: [],
      },
    });
  } else if (instanceAttribute != null) {
    const subject = getSubject(instanceAttribute, subjectMap);

    if (!subject) {
      const error = `Missing subject ${instanceAttribute.subjectId}:${instanceAttribute.eventId}`;
      return {
        result: {
          basePath: {
            error,
          },
          commands: [],
          error,
        },
      };
    }

    const basePath = getBasePath(subject, {
      __typename: 'ContactFiltersSubjectInstanceAttributeAction',
      ...instanceAttribute,
      representation: subject.instanceAttribute.representation,
      label: subject.instanceAttribute.label,
    });
    let error: string | undefined = undefined;
    const commands = instanceAttribute.commands.map(command => {
      const path = getPathForCommand(command, subject, {
        commandMap,
        topicMap,
      });
      if (path.error) error = path.error;

      return path;
    });

    return cloneDeep({
      result: {
        error,
        basePath: {
          result: basePath,
        },
        commands,
      },
    });
  } else {
    return {
      error: `Neither, attribute and instanceAttribute are set?!`,
    };
  }
};

export const getPathForCommand = (
  command: Omit<ContactFiltersLeafCommands__Input, 'args'>,
  subject: ContactFiltersSubjectFragment | null,
  { commandMap, topicMap }: { commandMap: CommandMap; topicMap: TopicMap },
):
  | { error?: undefined; result: Array<InnerContactFilterFieldOutput> }
  | { error: string } => {
  const commandBlock = getCommand(command.commandId, commandMap);
  if (!commandBlock) {
    return { error: `Missing command ${command.commandId}` };
  }

  const [firstKey, ...rest] = command.path;
  let field:
    | ContactFiltersTopicFragment
    | ContactFiltersSubjectFieldFragment
    | undefined = subject?.instanceAttribute.fields.find(
    field => field.key === firstKey,
  );

  if (!field) {
    return { error: `Unable to find field with key ${firstKey}` };
  }

  const path: Array<InnerContactFilterFieldOutput> = [field];
  for (let pathIndex = 0; pathIndex < rest.length; ++pathIndex) {
    const pathKey = rest[pathIndex];
    const isLast = pathIndex === rest.length - 1;

    switch (field.__typename) {
      case 'ContactFiltersSubjectFieldTopic': {
        const topic = getTopic(field.topicId, topicMap);
        if (!topic) {
          return { error: `Unable to find topic ${field.topicId}` };
        }

        field = topic.fields.find(({ key }) => pathKey === key);
        if (!field) {
          return { error: `Unable to find field with key ${pathKey}` };
        }

        if (isLast && field.__typename === 'ContactFiltersSubjectFieldTopic') {
          return {
            error: `ContactFiltersSubjectFieldTopic can not be the last entry`,
          };
        }

        path.push(field);
        continue;
      }
      case 'ContactFiltersSubjectFieldType': {
        if (!isLast) {
          return {
            error: `ContactFiltersSubjectFieldType can only be the last entry`,
          };
        }

        continue;
      }

      default:
        assertNever(field);
    }
  }

  return { result: path };
};

const getBasePath = (
  subject: ContactFiltersSubjectFragment,
  attributeOrInstanceAttribute,
): Array<OuterContactFilterField> => {
  const { __typename, eventId, subjectId, label } = subject;
  return [
    ...subject.dir,
    { __typename, eventId, subjectId, label },
    attributeOrInstanceAttribute,
  ];
};

export default getPathForLeaf;
