import React from 'react';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body } from '~/components/atom/Typography';
import Link from '~/components/molecule/Link';

type Props = {};

const text = {
  explanation:
    'Er is geen e-mail account ingesteld om e-mails vanuit te sturen.',
  userLabel: 'Stel een persoonlijk e-mail account in',
  accountLabel: 'Stel een gedeeld e-mail account in',
};

const NoSyncedEmailCTA: React.FCC<Props> = () => (
  <JustificationContainer
    dataTestId={TEST_ID.CONTAINER}
    direction="column"
    justification="center"
    align="center"
    padding={['base']}
  >
    <Body margin={[null, null, 'base', null]}>{text.explanation}</Body>
    <JustificationContainer justification="space-around" width="100%">
      <Link
        dataTestId={TEST_ID.SETUP_PERSONAL_ACCOUNT_LINK}
        to="/-/settings/profile"
      >
        {text.userLabel}
      </Link>
      <Link
        dataTestId={TEST_ID.SETUP_OPEN_ACCOUNT_LINK}
        to="/-/settings/account"
      >
        {text.accountLabel}
      </Link>
    </JustificationContainer>
  </JustificationContainer>
);

export default NoSyncedEmailCTA;
