import { isNil } from 'ramda';
import { useEffect, useRef } from 'react';
import { useResetRecoilState } from 'recoil';
import { tooltipLayerState } from '~/hooks/useTooltipLayer';

/**
 * Resets tooltip layer state on scroll.
 *
 * It listens for scroll events on the lowest nested div with `overflow-y: scroll`
 * and resets the tooltip layer state when a scroll event occurs. It is designed
 * to ensure tooltips are dismissed when the content inside a scrollable container is scrolled.
 *
 * Key considerations:
 * - It optimistically assumes the last div found with `overflow-y: scroll` during a full DOM scan
 *   is the lowest relevant container. This assumption might not hold if nested divs or dynamically
 *   modified DOM structures are involved.
 * - The primary element for event listening defaults to the document if no qualifying div is found.
 * - This setup is meant to handle common scenarios but may not be foolproof in complex layouts where
 *   scrollable divs are nested or dynamically added after initial render.
 * - Fallback behavior is non-critical: if the hook fails to correctly bind to the intended element,
 *   tooltip might not reset on scroll, but it will surely be reset on the scroll end.
 *
 */
const useCleanUpTooltipLayerState = () => {
  const reset = useResetRecoilState(tooltipLayerState);
  const scrollContainer = useRef<HTMLElement | Document | null>(null);

  useEffect(() => {
    const getScrollableElement = () => {
      const elements = document.querySelectorAll('div');
      let foundElement: HTMLDivElement | null = null;

      elements.forEach(el => {
        if (window.getComputedStyle(el).overflowY === 'scroll') {
          // this assumes the last found will be the lowest in the DOM tree
          foundElement = el;
        }
      });

      return !isNil(foundElement) ? foundElement : global.window.document;
    };

    const scrollableElement = getScrollableElement();
    scrollContainer.current = scrollableElement;

    if (!scrollContainer.current) return;

    const onReset = () => reset();

    scrollContainer.current.addEventListener('scroll', onReset);

    return () => {
      if (scrollContainer.current) {
        scrollContainer.current.removeEventListener('scroll', onReset);
        scrollContainer.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useCleanUpTooltipLayerState;
