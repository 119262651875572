import { ClientFlowAction } from '~/graphql/types.client';

/**
 * Gives unique labels to actions to identify them
 * @param {Array<ClientFlowAction>} actions - Client flow actions
 * keywords: pass labels to actions
 */

export type LabelInfo = { letterIdx: number; number: number };

const getLabelledActions = (actions: Array<ClientFlowAction>) => {
  const startAction = actions.find(a => a.__typename === 'FlowV2_Action_Start');

  if (!startAction) return actions;

  const actionsWithoutStart = actions.filter(
    a => a.__typename !== 'FlowV2_Action_Start',
  );

  const prevActions: Array<ClientFlowAction> = [
    {
      ...startAction,
      label: {
        letterIdx: 0,
        number: 1,
      },
    },
    ...actionsWithoutStart,
  ];

  let lastUsedLetterIdx = 0;
  const addActionLabel = (currentAction: ClientFlowAction) => {
    const labelledAction = prevActions.find(a => a.id === currentAction.id);
    const nextChildren = actions.filter(
      a => 'parentIds' in a && a.parentIds?.[0] === currentAction.id,
    );

    for (const child of nextChildren) {
      if (!child) return;

      const letterIdx = labelledAction?.label?.letterIdx || 0;
      const number = labelledAction?.label?.number || 0;
      const isTrueChild =
        currentAction.__typename === 'FlowV2_Action_IfElse' &&
        currentAction.trueChildId === child.id;

      const nextLetterIdx = lastUsedLetterIdx + 1;

      const childActionIdx = prevActions.findIndex(a => a.id === child.id);
      if (isTrueChild || nextChildren.length === 1) {
        prevActions[childActionIdx] = {
          ...child,
          label: {
            letterIdx,
            number: number + 1,
          },
        };
      } else {
        prevActions[childActionIdx] = {
          ...child,
          label: {
            letterIdx: nextLetterIdx,
            number: 1,
          },
        };

        lastUsedLetterIdx = nextLetterIdx;
      }

      addActionLabel(prevActions[childActionIdx]);
    }

    return;
  };

  addActionLabel(prevActions[0]);

  return prevActions;
};

export default getLabelledActions;
