import cleanedFilename from '~/util/cleanedFilename';

export default {
  ROUTE: `${cleanedFilename(__filename)}-container`,
  PRIVACY_STATEMENT: `${cleanedFilename(__filename)}-privacy-statement`,
  COOKIE_STATEMENT: `${cleanedFilename(__filename)}-cookie-statement`,
  GOOGLE_ANALYTICS: `${cleanedFilename(__filename)}-google-analytics`,
  GOOGLE_ANALYTICS_4: `${cleanedFilename(__filename)}-google-analytics-4`,
  FACEBOOK_PIXEL: `${cleanedFilename(__filename)}-facebook-pixel`,
  CUSTOM_HTML: `${cleanedFilename(__filename)}-custom-html`,
};
