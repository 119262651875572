import React from 'react';
import styled from 'styled-components';
import { AppBbWaardecheckContainer__Input } from '~/graphql/types';
import InputGroup from '~/components/page/Apps/components/InputGroup';
import ColorInput from '~/components/molecule/ColorInput';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';

export type Props = {
  title?: string;
  label?: [string, string];
  value: AppBbWaardecheckContainer__Input;
  onChange: (value: AppBbWaardecheckContainer__Input) => void;
  disabled?: boolean;
};

const text = {
  labelBackground: 'Primaire kleur',
  labelColor: 'Primaire tekstkleur',
};

const ColorInputComponent: React.FCC<Props> = ({
  dataTestId,
  title,
  value,
  disabled,
  label = [],
  onChange,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    {title && <AppEntryItemHeader>{title}</AppEntryItemHeader>}
    <InputGroup>
      <ColorInput
        value={value.background}
        onChange={background => onChange({ ...value, background })}
        label={label[0] ?? text.labelBackground}
        disabled={disabled}
        dataTestId="backgroundColor"
      />
      <ColorInput
        value={value.color}
        onChange={color => onChange({ ...value, color })}
        label={label[1] ?? text.labelColor}
        disabled={disabled}
        dataTestId="color"
      />
    </InputGroup>
  </Container>
);

const Container = styled.div<{}>`
  width: 100%;
`;

export default ColorInputComponent;
