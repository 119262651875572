import { isNil, pluck, prop, uniqBy } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddTriggerDiagram from '~/components/molecule/Diagrams/AddZapierTrigger';
import Link from '~/components/molecule/Link';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import { Body, Variant, Heading4 } from '~/components/atom/Typography';
import type {
  OutputFieldList,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import type { OutputMap } from '~/components/organism/WizardSteps';
import {
  AppStatus_Zapier_Metadata,
  useInsertZapierTriggerMutation,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useApp from '~/hooks/useApp';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import formatToastMessage from '~/util/formatToastMessage';
import TriggerTemplatesContainer from './components/TriggerTemplatesContainer';
import zapierTriggerTemplates, {
  ZapierTriggerTemplates,
} from './components/TriggerTemplatesContainer/zapierTriggerTemplates';
import useErrorReporter from '~/hooks/useErrorReporter';
import cleanedFilename from '~/util/cleanedFilename';

const text = {
  header: 'Uitgaande koppelingen',
  body: 'DatHuis kan vanuit een flow gegevens sturen naar Zapier, die vervolgens kunnen worden doorgestuurd naar andere diensten. Om gegevens naar Zapier te sturen, dien je een uitgaande koppeling in te stellen. Vervolgens kan je de verstuurde gegevens in een ‘zap’ in Zapier gebruiken.',
  exampleHeader: 'Een voorbeeld hoe een flow gegevens naar Zapier stuurt',
  chooseExampleHeader: 'Kies uitgaande koppelingen',
  chooseConnectionBody:
    'Met een uitgaande koppeling bepaal je welke gegevens je verstuurt naar Zapier. We hebben we een aantal uitgaande koppelingen voor je klaargezet. Deze zijn gemaakt voor de meest voorkomende toepassingen. Na het afronden van deze wizard is het mogelijk om zelf een koppeling te maken.',
  tip: (
    <>
      Lees hoe je de koppeling moet instellen in onze{' '}
      <Link to="https://help.dathuis.nl/">kennisbank</Link>. Kom je er niet uit?
      &nbsp;
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
};

export const id = 'AddZapierTrigger';
export const title = text.header;

const triggersOutputCategory = 'Uitgaande koppelingen';

export type OutputType = {
  type: typeof id;
  selectedTriggers: OutputFieldList<ZapierTriggerTemplates[0]>;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const reporter = useErrorReporter();
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const [selectedTriggers, setSelectedTriggers] =
    useState<ZapierTriggerTemplates>(
      'selectedTriggers' in outputMap[id]
        ? outputMap[id].selectedTriggers.value ?? []
        : [],
    );

  const [insertZapierTrigger] = useInsertZapierTriggerMutation();
  const [updateAppStatus] = useUpdateAppStatusMutation();
  const { app } = useApp('AppStatus_Zapier');
  const zapierMetadata = (
    app?.appStatus && 'metadata' in app?.appStatus
      ? app?.appStatus.metadata
      : undefined
  ) as AppStatus_Zapier_Metadata | undefined;

  const previouslyEnabledTemplates = useMemo(
    () => zapierMetadata?.setup.addedTriggers ?? [],
    [zapierMetadata],
  );

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      if ('selectedTriggers' in outputMap.AddZapierTrigger) {
        const data = await Promise.all([
          ...outputMap.AddZapierTrigger.selectedTriggers.value.map(
            async (trigger, index) => {
              if (!isNil(trigger.insertedId)) {
                return Promise.resolve(); // resolve with empty promise;
              }
              const { data, errors } = await insertZapierTrigger({
                variables: {
                  accountId,
                  name: trigger.name,
                  fields: trigger.fields,
                },
              });
              if (errors && errors.length > 0) {
                reporter.captureException(
                  new Error(
                    `${cleanedFilename(
                      __filename,
                    )} | An error occured in insertZapierTrigger)`,
                  ),
                );
                addToast([
                  formatToastMessage(
                    `Er is iets fout gegaan bij het toevoegen van "${trigger.name}" uitgaande koppeling, probeer het later opnieuw.`,
                    'danger',
                  ),
                ]);
              }
              const _updatedTriggers = [
                ...('selectedTriggers' in outputMap.AddZapierTrigger
                  ? outputMap.AddZapierTrigger.selectedTriggers.value
                  : []),
              ];
              _updatedTriggers[index].insertedId = data?.insertZapierTrigger.id;
              setSelectedTriggers(_updatedTriggers);
              return { data };
            },
          ),
          updateAppStatus({
            variables: {
              accountId,
              update: {
                AppStatus_Zapier: {
                  enabled: true,
                  metadata: {
                    setup: {
                      completed: false,
                      addedTriggers: pluck(
                        'id',
                        outputMap.AddZapierTrigger.selectedTriggers.value,
                      ),
                    },
                  },
                },
              },
            },
          }).catch(() => {
            reporter.captureException(
              new Error(
                `${cleanedFilename(
                  __filename,
                )} | An error occured in updateAppStatus after insertZapierTrigger`,
              ),
            );
          }),
        ]);

        const successResult = data.filter(
          x => x && !isNil(x.data) && 'insertZapierTrigger' in x.data,
        );

        if (successResult.length > 0) {
          addToast([
            formatToastMessage(
              `${successResult.length} uitgaande koppeling(en) succesvol toegevoegd.`,
              'success',
            ),
          ]);
        }
      }

      return outputMap[id];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, addToast, insertZapierTrigger, updateAppStatus],
  );

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );
  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    const output: OutputType = {
      type: 'AddZapierTrigger',
      selectedTriggers: {
        category: triggersOutputCategory,
        type: 'list',
        value: selectedTriggers.map(trigger => ({
          ...trigger,
          label: trigger.name,
        })),
      },
    };
    // Step ready to go from mount so free it up
    api.free(output);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTriggers]);

  useEffect(() => {
    setSelectedTriggers(prev => {
      const _previouslyEnabledTemplates = zapierTriggerTemplates.filter(
        ({ id }) => previouslyEnabledTemplates.includes(id),
      );

      return uniqBy(prop('id'), [...prev, ..._previouslyEnabledTemplates]);
    });
  }, [previouslyEnabledTemplates]);

  return (
    <>
      <Body margin={[null]}>{text.body}</Body>

      <Heading4 margin={['xl', null, 'm', null]} variant={Variant.primary}>
        {text.exampleHeader}
      </Heading4>

      <AddTriggerDiagram />

      <Heading4 margin={['xl', null, 'm', null]} variant={Variant.primary}>
        {text.chooseExampleHeader}
      </Heading4>

      <Body>{text.chooseConnectionBody}</Body>

      <TriggerTemplatesContainer
        onChange={triggers => setSelectedTriggers(triggers)}
        previouslyEnabledTriggers={previouslyEnabledTemplates}
        selectedTriggers={selectedTriggers}
      />
      <TipBanner id="add-trigger-tip">{text.tip}</TipBanner>
    </>
  );
};

export default {
  id,
  title,
};
