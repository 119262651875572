import { ClientFlowAction } from '~/graphql/types.client';

const getChildActions = (
  subjectAction: ClientFlowAction,
  actions: Array<ClientFlowAction>,
): Array<ClientFlowAction> => {
  const childIds: Array<string> = [];
  const hashMap = {};

  for (const action of actions) {
    if ('parentIds' in action) {
      action.parentIds.forEach(parentId => {
        if (!hashMap[parentId]) hashMap[parentId] = [];
        hashMap[parentId].push(action.id);
      });
    }
  }

  const handleParent = (id: string) => {
    const children = hashMap[id];
    if (!children) return;
    for (const childId of children) {
      childIds.push(childId);
      handleParent(childId);
    }
  };

  handleParent(subjectAction.id);

  return actions.filter(({ id }) => childIds.includes(id));
};

export default getChildActions;
