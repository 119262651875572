import React, { ReactElement, useState, useRef } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

import Amplify from '~/amplify';
import TEST_ID from './index.testid';
import Button from '~/components/atom/Button';

import cleanedFilename from '~/util/cleanedFilename';
import { FileType } from '~/util/constants';
import useCurrentUser from '~/hooks/useCurrentUser';
import { tmpPrivate } from '~/amplify/getConfig';
import FileUploadBar from '~/components/molecule/FileUploadBar';

const text = {
  uploadError: 'Er is iets misgegaan met het uploaden van het bestand',
};

export type UploadedFileDetails = {
  fileId: string;
  filename: string;
  fileType: FileType;

  /* File size in bytes */
  contentLength: number;
};
type S3UploadDetails = {
  filename?: string;
};

type UploadButtonProps = {
  onClick: () => void;
};

export type UploadSuccessResponse = {
  fileId: string;
  key: string;
  filename: string;
  Body: Blob;
  ContentLength: number;
  ContentType: string;
};

type AmplifyDownloadResponse = {
  Body: Blob;
  ContentLength: number;
  ContentType: string;
};

export type Props = {
  fileDetails: UploadedFileDetails | null;
  setUploadingFile: (setUploadingFile: UploadedFileDetails) => void;
  onUploadSucceeded: (x: UploadSuccessResponse) => void;
  onFileRemoved: () => void;
  allowedFileExtensions: Array<FileType>;
  s3UploadDetails?: S3UploadDetails;
  uploadButton?: null | ((props: UploadButtonProps) => JSX.Element);
  uploadButtonLabel: string;
  className?: string;
};
const S3FileUpload: React.FCC<Props> = ({
  dataTestId,
  allowedFileExtensions,
  onUploadSucceeded,
  s3UploadDetails = {},
  uploadButton = null,
  uploadButtonLabel,
  onFileRemoved,
  fileDetails,
  setUploadingFile,
  ...rest
}) => {
  const me = useCurrentUser();
  const [isUploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  if (
    fileDetails == null &&
    inputRef.current != null &&
    inputRef.current.value != ''
  ) {
    inputRef.current.value = '';
  }

  let renderedComponent: ReactElement | null = null;
  if (fileDetails != null) {
    renderedComponent = (
      <FileUploadBar
        error={error}
        filename={fileDetails.filename}
        fileType={fileDetails.fileType}
        onRemove={() => {
          onFileRemoved();
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        }}
        isUploading={isUploading}
      />
    );
  } else {
    const onClick = () => {
      if (inputRef.current == null) {
        throw Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | inputRef.current is null`,
        );
      } else {
        inputRef.current.click();
      }
    };
    renderedComponent = (uploadButton && uploadButton({ onClick })) || (
      <StyledButton size="medium" onClick={onClick} label={uploadButtonLabel} />
    );
  }

  return (
    <Container {...rest} data-testid={dataTestId}>
      {renderedComponent}
      <input
        ref={inputRef}
        hidden
        type="file"
        data-testid={TEST_ID.INPUT}
        accept={allowedFileExtensions.join(',')}
        onChange={e => {
          const file = (e.target.files as FileList)[0];

          const filename = (
            'filename' in s3UploadDetails ? s3UploadDetails.filename : file.name
          ) as string;

          const fileId = v4();
          setUploading(true);
          setUploadingFile({
            fileId,
            filename: file.name,
            fileType: file.type as FileType,
            contentLength: file.size,
          });

          return Amplify.Auth.currentCredentials().then(result => {
            const { identityId } = result;
            const uploadKey = `${tmpPrivate}${identityId}/${me.id}/${fileId}`;
            Amplify.Storage.put(`${me.id}/${fileId}`, file, {
              contentType: file.type,
              level: 'private',
            })
              .then(({ key }: { key: string }) =>
                Amplify.Storage.get(key, {
                  level: 'private',
                  download: true,
                }).then(({ Body, ContentType }: AmplifyDownloadResponse) => {
                  onUploadSucceeded({
                    fileId,
                    filename,
                    key: uploadKey,
                    Body,
                    ContentLength: Body.size,
                    ContentType,
                  });
                  setUploading(false);
                }),
              )
              .catch(() => {
                setError(text.uploadError);
                setUploading(false);
              });
          });
        }}
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
  display: flex;
`;

const StyledButton = styled(Button)<{}>`
  margin-left: auto;
  margin-right: auto;
`;

export default S3FileUpload;
