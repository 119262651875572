import { useLocation } from '@gatsbyjs/reach-router';
import { useEffect } from 'react';

/**
 * A workaround for unwanted scrolling when navigating to a new page.
 *
 * reach/router adds tabindex="-1" to the Router component so that it is focusable but not reachable via sequential keyboard navigation.
 * In this case it is not serving us because we get some random scrolling especially on the Dashboard page.
 *
 * We can remove the tabIndex it by using primary={false} to the DHRouter component but this also removes other accessibility features so it is not ideal.
 * What we can do instead is scroll to the top of the page seamlessly, whenever the pathname changes.
 *
 * Solution and explanation: https://stackoverflow.com/questions/53058110/stop-reach-router-scrolling-down-the-page-after-navigating-to-new-page/56996986#56996986
 * Reach Router implementation: https://github.com/reach/router/blob/master/src/index.js
 * A related issue in reach/router (that is currently open): https://github.com/reach/router/issues/446#issuecomment-1383139518
 */
const ScrollToTop: React.FC<{
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ containerRef }) => {
  const location = useLocation();

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [containerRef, location.pathname]);

  return null;
};

export default ScrollToTop;
