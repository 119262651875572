import React from 'react';
import { TableCell } from '~/components/bad/DataTables/types.flow';
import ActionColumnCell from '~/components/bad/DataTables/components/ActionColumnCell';
import markedForDeletionContactList from '~/components/page/Contacts/util/markedForDeletionContactList';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useConfirmModal from '~/hooks/useConfirmModal';
import { useMarkContactForDeletionMutation } from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

const text = {
  deleteLabel: 'Verwijderen',
  deleteErrorTitle: 'Oeps!',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van het contact. Probeer het nog eens.',
  confirmDeleteTitle: 'Contact verwijderen?',
  confirmDeleteMessage: 'Weet je zeker dat je dit contact wilt verwijderen?',
  confirmDeleteButton: 'Verwijderen',
  deleteSuccess: 'Contact is verwijderd',
};
const confirmDeleteModalText = {
  title: text.confirmDeleteTitle,
  message: text.confirmDeleteMessage,
  buttonConfirmTitle: text.confirmDeleteButton,
};

type Props = {
  contactId: string;
  cell: TableCell<any>;
  onSuccessfulDelete: () => void;
};
const ContactActionColumnCell: React.FCC<Props> = ({
  contactId,
  cell,
  onSuccessfulDelete,
}) => {
  const addToast = useAddToast();

  const account = useCurrentAccount();

  const [markContactForDeletion, { loading }] =
    useMarkContactForDeletionMutation({});

  const deleteFn = async e => {
    e.stopPropagation();
    const mutationResult = await markContactForDeletion({
      variables: {
        accountId: account.id,
        id: contactId,
      },
    });
    if (isErroredDeletionResult(mutationResult)) {
      addToast([formatToastMessage(text.deleteErrorMessage, 'danger')]);
    } else {
      markedForDeletionContactList.writeMarkedForDeletionContact(contactId);

      addToast([formatToastMessage(text.deleteSuccess, 'success')]);
      onSuccessfulDelete();
    }
  };

  const { setShowModal, modal } = useConfirmModal({
    labels: confirmDeleteModalText,
    buttons: [
      {
        label: confirmDeleteModalText.buttonConfirmTitle,
        onClick: deleteFn,
        loading,
      },
    ],
    level: 'danger',
  });

  return (
    <>
      <ActionColumnCell
        key={'contact-action-column-cell'}
        loading={loading}
        options={[
          {
            label: text.deleteLabel,
            onClick: () => setShowModal(true),
            key: 'delete',
            type: 'DELETE',
          },
        ]}
        cell={cell}
      />
      {modal}
    </>
  );
};

const isErroredDeletionResult = mutationResult =>
  !mutationResult ||
  !mutationResult.data ||
  mutationResult.data.markContactForDeletion.success !== true;

export default ContactActionColumnCell;
