import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormApi } from 'final-form';
import Validation from '~/util/Validation';
import Button from '~/components/atom/Button';
import { Form, Field } from '~/components/organism/Forms';
import Catalog from '~/Catalog';
import useIntercom from '~/hooks/useIntercom';
import TEST_ID from './AddPremiumValueReportSidebar.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TextButton from '~/components/atom/TextButton';
import Drawer from '~/components/organism/Drawer';
import FormUtils from '~/components/organism/FormUtils';
import Checkbox from '~/components/molecule/Checkbox';
import { useInsertAppPremiumValuationReportMutation } from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import { Body } from '~/components/atom/Typography';

type Props = {
  onAdded: () => void;
  isShowing: boolean;
  hide: () => void;
};

type FormFields = {
  name: string | null | undefined;
  algasId: string | null | undefined;
};

const text = {
  nameLabel: Catalog.valueReports.nameLabel,
  algasIdLabel: Catalog.valueReports.algasIdHeader,
  sendContactEmailLabel: Catalog.valueReports.sendContactEmailLabel,
  sendAgentEmailLabel: Catalog.valueReports.sendAgentEmailLabel,
  submit: 'Toevoegen',
  drawerTitle: 'Waarderapport koppelen',
  noName: Catalog.valueReports.noName,
  noAlgasId: Catalog.valueReports.noAlgasId,
  submitErrorMsg:
    'Er is geen waarderapport gevonden met het gegeven identificatienummer. Controleer het identificatienummer of neem contact met ons op.',
  helpText: 'Voer hier het identificatienummer van het waarderapport in. ',
  helpLinkText: 'Ik heb geen identificatienummer.',
  initialHelpMessage:
    'Ik probeer een waarderapport te maken maar weet mijn identificatienummer niet. Kunnen jullie mij helpen?',
};

const validate = (fields: FormFields) => {
  const errors: FormFields = {
    name: undefined,
    algasId: undefined,
  };
  if (Validation.String.isEmpty(fields.name)) {
    errors.name = text.noName;
  }

  if (Validation.String.isEmpty(fields.algasId)) {
    errors.algasId = text.noAlgasId;
  }

  return errors;
};

const AddPremiumValueReportSidebar: React.FCC<Props> = ({
  isShowing,
  hide,
  onAdded,
}) => {
  const account = useCurrentAccount();
  const [sendAgentEmail, setSendAgentEmail] = useState(true);
  const [sendContactEmail, setSendContactEmail] = useState(true);
  const intercom = useIntercom();

  const [insertPremiumValuationReportApp, { error, loading }] =
    useInsertAppPremiumValuationReportMutation();

  const handleSubmit = (fields: FormFields, form: FormApi<FormFields>) => {
    const { name, algasId } = fields;

    if (name == null)
      throw Error(
        'Should not occur: Validation should have caught that name cannot be null',
      );

    if (algasId == null)
      throw Error(
        'Should not occur: Validation should have caught that identification number cannot be null',
      );

    return insertPremiumValuationReportApp({
      variables: {
        accountId: account.id,
        app: {
          accountId: account.id,
          name,
          algasId,
          sendAgentEmail,
          sendContactEmail,
        },
      },
    }).then(result => {
      // result is undefined if an error occured, so don't reset then
      if (result) {
        setTimeout(form.reset, 0);
        onAdded();
      }
    });
  };

  return (
    <Drawer active={isShowing} hideSidebar={hide} title={text.drawerTitle}>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={{
          name: null,
          algasId: null,
        }}
      >
        {form => {
          const { handleSubmit, submitting, pristine } = form;

          return (
            <form onSubmit={handleSubmit}>
              {error ? <ErrorMsg>{text.submitErrorMsg}</ErrorMsg> : null}

              <JustificationContainer
                margin={['m', null, null, null]}
                width="100%"
                direction="column"
                gap="base"
              >
                <Field name="name">
                  {({ input, meta: { error, touched } }) => (
                    <Input
                      width="100%"
                      size="large"
                      label={{ text: text.nameLabel }}
                      type="text"
                      externalErrors={
                        FormUtils.showError(error, touched)
                          ? [FormUtils.showError(error, touched)]
                          : []
                      }
                      {...input}
                      disabled={loading}
                    />
                  )}
                </Field>
                <JustificationContainer direction="column" width="100%">
                  <Field name="algasId">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        width="100%"
                        size="large"
                        label={{ text: text.algasIdLabel }}
                        type="text"
                        externalErrors={
                          FormUtils.showError(error, touched)
                            ? [FormUtils.showError(error, touched)]
                            : []
                        }
                        {...input}
                        disabled={loading}
                      />
                    )}
                  </Field>
                  <HelpText size="s" margin={['xxs', null, 'base', null]}>
                    {text.helpText}
                    <TextButton
                      margin={[null]}
                      onClick={() => intercom.openChat(text.initialHelpMessage)}
                      label={text.helpLinkText}
                    />
                  </HelpText>
                </JustificationContainer>
                <Checkbox
                  onChange={() => {
                    setSendContactEmail(!sendContactEmail);
                  }}
                  value={sendContactEmail}
                  label={text.sendContactEmailLabel}
                  name="sendContactEmail"
                />

                <Checkbox
                  onChange={() => {
                    setSendAgentEmail(!sendAgentEmail);
                  }}
                  value={sendAgentEmail}
                  label={text.sendAgentEmailLabel}
                  name="sendAgentEmail"
                />
              </JustificationContainer>

              <ActionWrapper>
                <Button
                  onClick={handleSubmit}
                  appearance="secondary"
                  size="medium"
                  type="submit"
                  disabled={submitting || pristine || loading}
                  data-testid={TEST_ID.ADD_BUTTON}
                  label={text.submit}
                />
              </ActionWrapper>
            </form>
          );
        }}
      </Form>
    </Drawer>
  );
};

const HelpText = styled(Body)<{}>`
  * {
    font-style: italic;
  }
`;

const ActionWrapper = styled.div<{}>`
  display: flex;
  align-items: center;
  padding-top: 1em;

  & button {
    margin-left: auto;
  }
`;

const ErrorMsg = styled.div<{}>`
  ${({ theme }) => css`
    color: ${theme.color('danger')};
  `};
`;

export default AddPremiumValueReportSidebar;
