import { Editor, Element, Transforms, Node, Text, Range } from 'slate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import {
  DHEditor,
  SpanElement,
} from '~/components/organism/PluginsEditor/types';
import { getUnhungRange, isElementOfType } from '../..';

export const setTextStyle = (
  editor: DHEditor,
  format: 'color' | 'backgroundColor' | 'fontSize' | 'fontFamily',
  value: string,
) => {
  if (!editor.selection) return;

  // Anchor and focus are at the same point so there is no text selection
  const selectionIsCollapsed = Range.isCollapsed(editor.selection);
  if (selectionIsCollapsed) return;

  /**  Wrap text node with span element if it does not already have a parent span */
  const spanEl: SpanElement = { type: ELEMENTS.SPAN, children: [{ text: '' }] };
  Transforms.wrapNodes(editor, spanEl, {
    at: getUnhungRange(editor),
    match: (n, path) => {
      if (Element.isElement(n) || Editor.isEditor(n)) return false;

      const parentEl = Node.parent(editor, path);
      if (isElementOfType(parentEl, ELEMENTS.SPAN)) return false;

      if (Text.isText(n)) return true;

      return false;
    },
    mode: 'lowest',
  });

  Transforms.setNodes(
    editor,
    { [format]: value },
    {
      at: getUnhungRange(editor),
      match: node => isElementOfType(node, ELEMENTS.SPAN),
      split: true,
      mode: 'lowest',
    },
  );
};
