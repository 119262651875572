import React from 'react';
import { Props } from '..';

const SingleChoice: React.FCC<Props> = ({ color = 'currentColor' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 23 14"
    fill="none"
  >
    <path
      d="M22 5H9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 1H9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 9H9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 13H9"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_15326_21189)">
      <path
        d="M3 5.5C4.38071 5.5 5.5 4.38071 5.5 3C5.5 1.61929 4.38071 0.5 3 0.5C1.61929 0.5 0.5 1.61929 0.5 3C0.5 4.38071 1.61929 5.5 3 5.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 3.75C3.41421 3.75 3.75 3.41421 3.75 3C3.75 2.58579 3.41421 2.25 3 2.25C2.58579 2.25 2.25 2.58579 2.25 3C2.25 3.41421 2.58579 3.75 3 3.75Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g clipPath="url(#clip1_15326_21189)">
      <path
        d="M3 13.5C4.38071 13.5 5.5 12.3807 5.5 11C5.5 9.61929 4.38071 8.5 3 8.5C1.61929 8.5 0.5 9.61929 0.5 11C0.5 12.3807 1.61929 13.5 3 13.5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_15326_21189">
        <rect width="6" height="6" fill="white" />
      </clipPath>
      <clipPath id="clip1_15326_21189">
        <rect width="6" height="6" fill="white" transform="translate(0 8)" />
      </clipPath>
    </defs>
  </svg>
);

export default SingleChoice;
