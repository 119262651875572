import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { Heading4, Variant } from '~/components/atom/Typography';
import CallToActionBlock from '~/components/organism/CallToActionBlock';
import ToggleAccordion, {
  type AccordionGroup,
  type GroupLookup,
} from '~/components/organism/ToggleAccordion';
import ExternalField from '~/components/page/Widget/components/Apps/components/ExternalField';
import type { PinnedAppsMap } from '~/components/page/Widget/state/pinnedApps';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import scrollToIdFromLocationHash from '~/util/scrollToIdFromLocationHash';
import ShareDeepLinkModal from './components/ShareDeepLinkModal';
import hasValue from '~/util/hasValue';

const text = {
  CTADescription:
    'Je moet sommige apps inschakelen om ze in de widget te kunnen tonen',
  CTAButton: 'Ga naar apps',
  enableApps: 'Weergave van apps in de widget',
  title: 'Apps',
};

export type ShareAppArgs = {
  slug: string | null;
  name: string;
};

export type Props = {
  appsGroupLookup: GroupLookup;
  onChange: (args: {
    groupId: string;
    groupLookup: {
      items: Array<AccordionGroup> | null;
      mainChecked: boolean;
    };
  }) => void;
  pinnedApps: PinnedAppsMap;
  appsAreEnabled: boolean;
} & RouteComponentProps;

const Apps: React.FCC<Props> = ({
  dataTestId,
  appsAreEnabled,
  pinnedApps,
  appsGroupLookup,
  onChange,
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);
  const [shareAppArgs, setShareAppArgs] = useState<{
    slug: string;
    name: string;
  }>({
    name: '',
    slug: '',
  });
  const location = useLocation();

  useEffect(() => {
    // We need to wait a little bit for animation to be completed and scroll to the right place
    setTimeout(() => scrollToIdFromLocationHash(location), 700);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShare = ({ slug, name }: ShareAppArgs) => {
    if (!hasValue(slug)) return;

    setShareAppArgs(prev => ({ ...prev, slug, name }));
    setShowModal(true);
  };

  return (
    <>
      {showModal && (
        <ShareDeepLinkModal
          {...shareAppArgs}
          onClose={() => setShowModal(false)}
        />
      )}
      <AppDetailsContainer
        {...rest}
        data-testid={dataTestId}
        header={text.title}
        icon="grid"
      >
        <Heading4 variant={Variant.primary}>{text.enableApps}</Heading4>
        {appsAreEnabled ? (
          <JustificationContainer>
            <Field name="apps.appsGroupLookup">
              {({ input }) => (
                <ToggleAccordion
                  onShare={onShare}
                  groupLookup={appsGroupLookup}
                  onChange={value => {
                    input.onChange({
                      ...appsGroupLookup,
                      ...input.value,
                      [value.groupId]: value.groupLookup,
                    });

                    return onChange(value);
                  }}
                  dataTestId={TEST_ID.APPS_CONTAINER}
                />
              )}
            </Field>
            <ExternalField name="apps.pinnedApps" value={pinnedApps} />
          </JustificationContainer>
        ) : (
          <CallToActionBlock
            align="center"
            description={text.CTADescription}
            icon={{
              name: 'triangle',
              background: 'warning',
            }}
            button={{
              appearance: 'primary',
              label: text.CTAButton,
              icon: 'arrowRight',
              onClick: () => navigate('/-/apps'),
            }}
          />
        )}
      </AppDetailsContainer>
    </>
  );
};

export default Apps;
