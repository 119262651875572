import { groupBy, keys, prop } from 'ramda';

import {
  AccountIssueFieldsFragment,
  AccountIssue_MissingPayment,
  AccountStatus,
  NotificationLevel,
} from '~/graphql/types';
import { Warning } from '~/hooks/useEntityWarnings';
import { reporter } from '~/hooks/useErrorReporter';

import cleanedFilename from '~/util/cleanedFilename';
import {
  convertServerDateStringToDate,
  formattedDateWithFullMonth,
} from '~/util/date';
import { generateHash } from '~/util/generateHash';

const errorMessagesForAccountIssues = (
  accountIssues: Array<AccountIssueFieldsFragment>,
): Array<Warning> => {
  const groupedByIssueType = groupBy(prop('__typename'), accountIssues);
  const issueTypes = keys(groupedByIssueType) as Array<
    AccountIssueFieldsFragment['__typename']
  >;

  const result: Array<Warning> = issueTypes.reduce((acc, issueType) => {
    const issues = groupedByIssueType[issueType];
    if (!issues) return acc;

    switch (issueType) {
      case 'AccountIssue_MissingPayment': {
        const error = `${errorMessageForAccountIssueType(
          issueType,
        )} ${getTransitionString(issues[0].transitions)}${issues.map(
          (issue: AccountIssue_MissingPayment) =>
            ` <a href="${
              issue.hostedInvoiceUrl || '/-/settings/invoices'
            }" target="${
              issue.hostedInvoiceUrl == null ? '_self' : '_blank'
            }" rel="noreferrer">Bekijk factuur</a>`,
        )}`;

        const warning: Warning = {
          item: {
            __typename: 'NotificationListItem',
            level: NotificationLevel.Error,
            body: {
              __typename: 'NotificationMessage',
              htmlBody: error,
            },
          },
          hash: generateHash(error),
        };

        return [...acc, warning];
      }
      case 'AccountIssue_UnsignedDocument': {
        const warnings: Array<Warning> = issues.map(issue => {
          const error = `${errorMessageForAccountIssueType(
            issue.__typename,
          )} ${getTransitionString(issue.transitions)}`;

          const warning: Warning = {
            item: {
              __typename: 'NotificationListItem',
              level: NotificationLevel.Error,
              body: {
                __typename: 'NotificationMessage',
                htmlBody: error,
              },
            },
            hash: generateHash(error),
          };

          return warning;
        });

        return [...acc, ...warnings];
      }
      default:
        return acc;
    }
  }, []);

  return result;
};

export const errorMessageForAccountIssueType = (type: string): string => {
  switch (type) {
    case 'AccountIssue_MissingPayment': {
      return 'Er is een betalingsprobleem met één of meer van de facturen.';
    }
    case 'AccountIssue_UnsignedDocument': {
      return 'De gebruikersovereenkomst die we hebben gemaild is nog niet ondertekend.';
    }
    default: {
      reporter.captureException(
        new Error(
          `${cleanedFilename(__filename)} | Unknown issue type: (${type})`,
        ),
        'debug',
      );
      return '';
    }
  }
};

const getTransitionString = (
  transitions: Array<{ date: string; to: AccountStatus }>,
): string => {
  const transitionsWithValidDate: Array<{ date: Date; to: AccountStatus }> = [];
  transitions.forEach(transition => {
    const date = convertServerDateStringToDate(transition.date);

    if (date != null) {
      transitionsWithValidDate.push({ date, to: transition.to });
    }
  });

  if (transitionsWithValidDate.length === 0) {
    return '';
  }

  transitionsWithValidDate.sort((a, b) => {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  });

  let str = 'Als er geen actie wordt ondernomen zal het account ';
  let isFirst = true;
  transitionsWithValidDate.forEach(transition => {
    if (!isFirst) {
      str = str + ' en ';
    }

    str =
      str +
      `per ${formattedDateWithFullMonth(
        transition.date,
      )} ${labelForAccountStatus(transition.to)}`;
    isFirst = false;
  });

  return str + '.';
};

const labelForAccountStatus = (status: AccountStatus): string => {
  switch (status) {
    case 'Cancelled': {
      return 'worden opgezegd';
    }
    case 'Deleted': {
      return 'worden verwijderd';
    }
    case 'Locked': {
      return 'worden geblokkeerd';
    }
    case 'Paused': {
      return 'worden gedeactiveerd';
    }
    default: {
      reporter.captureException(
        new Error(
          `${cleanedFilename(
            __filename,
          )} | Should not occur | unexpected status (${status})`,
        ),
        'debug',
      );
      return '';
    }
  }
};

export default errorMessagesForAccountIssues;
