import { keys } from 'ramda';
import { useEffect, useState } from 'react';
import breakpoints from '~/theme/System/tokens/breakpoints';
import useViewportSize from '../useViewportSize';

const breakpointKeys = keys(breakpoints);
export type BreakpointKeys = keyof typeof breakpoints;

const getBreakpoint = () =>
  breakpointKeys.find(
    key => window.matchMedia(`(max-width: ${breakpoints[key]})`).matches,
  ) ?? breakpointKeys[breakpointKeys.length - 1];

const useCurrentBreakpoint = () => {
  const { width } = useViewportSize({ delay: 200 });

  const [currentBreakpoint, setCurrentBreakpoint] =
    useState<keyof typeof breakpoints>(getBreakpoint());

  useEffect(() => {
    setCurrentBreakpoint(getBreakpoint());
  }, [width, setCurrentBreakpoint]);

  return currentBreakpoint;
};

export default useCurrentBreakpoint;
