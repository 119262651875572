import type {
  BrandSettingsField,
  BrandSettings,
} from '~/hooks/useBrandSettings';
import hasValue from '~/util/hasValue';

/**
 * Filter out the valid subjects from the given array based on the provided brand settings.
 * @param {Array<BrandSettingsField>} subjects - The array of subjects to filter.
 * @param {BrandSettings} brandSettings - The brand settings object used for validation.
 * @returns {Array<BrandSettingsField>} - An array containing only the valid subjects.
 */
const getValidSubjects = (
  subjects: Array<BrandSettingsField>,
  brandSettings?: BrandSettings | null,
): Array<BrandSettingsField> =>
  brandSettings
    ? // Filter the subjects array to keep only those subjects that have a value defined in the brand settings.
      subjects.filter(key => hasValue(brandSettings[key]))
    : [];

export default getValidSubjects;
