import styled, { css } from 'styled-components';

export const Header = styled.header<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    margin-bottom: ${theme.space('xxl')};

    ${theme.mq.lessThan('tabletLandscape')`
      flex-direction: column;
    `}
  `};
`;

export const ActionButtons = styled.div<{}>`
  margin-left: auto;
  order: 2;

  ${({ theme }) => css`
    ${theme.mq.lessThan('tabletLandscape')`
      order: 1;
    `}
  `};
`;

export const SectionContainer = styled.section<{}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;
