import { useRecoilValue } from 'recoil';

import React from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './index.testid';
import { useTransition, animated } from 'react-spring';
import { allMessages } from '~/state/toasts';
import Toast from '~/components/molecule/Toast';
import { ANIMATION_CONFIG } from '~/styles/constants';

const INTERCOM_ICON_HEIGHT = 60;
const INTERCOM_POSITION = 20;

type Props = {
  /** Image source URI */
  message?: string;

  /** Show Upload button or not */
  level?: 'danger' | 'info' | 'success';

  /** Optional link to show */
  link?: string;
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    max-width: 23rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: ${INTERCOM_ICON_HEIGHT +
    INTERCOM_POSITION}px; /* Position above intercom logo */
    right: 0;
    padding-right: ${theme.space('l')};
    font-weight: ${theme.fontWeight('medium')};
    z-index: ${theme.z('toasts')};
  `,
);

const ToastsContainer: React.FCC<Props> = () => {
  const toasts = useRecoilValue(allMessages);
  const transitions = useTransition(toasts, {
    from: { opacity: 0, transform: 'translate3d(40px,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(40px,0,0)' },
    config: { ...ANIMATION_CONFIG.config },
  });

  return (
    <Container data-testid={TEST_ID.CONTAINER}>
      {transitions((style, { id, ...toast }) => (
        <animated.div key={id} style={style}>
          <Toast id={id} {...toast} />
        </animated.div>
      ))}
    </Container>
  );
};

export default ToastsContainer;
