import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import styled from 'styled-components';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import AccountsTable from './components/AccountsTable';
import createPageTitle from '~/util/createPageTitle';

type Props = RouteComponentProps & { dataTestId?: string };

const AccountsList: React.FCC<Props> = ({ dataTestId, ...rest }) => (
  <Container data-testid={dataTestId} {...rest}>
    <MetaTags>
      <title>{createPageTitle('Accounts')}</title>
    </MetaTags>

    <>
      <ContentContainerDefault>
        <OverviewListHeader title={'Accounts'} />
        <AccountsTable />
      </ContentContainerDefault>
    </>
  </Container>
);

const Container = styled.div<{}>``;

export default AccountsList;
