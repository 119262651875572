import { ActivityV2 } from '~/components/template/EventTimelineV2/types';
import { TaskListType } from '../../types';
import { GroupedActivities } from '../../state';
import sortAndGroupTasks from '../sortAndGroupTasks';
import inListType from '../inListType';

type Args = {
  /** userId from useCurrentUser */
  me: string;
  tasks: Array<ActivityV2>;
  selectedListType: TaskListType;
};

/** Taking following parameters
 * @param (Array of ActivityV2) - tasks
 * @param (string) - me
 * @param (TaskListType) - selectedListType
 *
 * this util filters the tasks and then grouped them by 'dueDate' for opened tasks
 * or by 'closedDate' for closed tasks
 *
 * When All offices filter selected, we display tasks that belong to ME or are not assign to anyone
 */
const processMyTasks = ({
  me,
  tasks,
  selectedListType,
}: Args): GroupedActivities => {
  const filtered = tasks.filter(
    task =>
      'userId' in task &&
      (task.userId === me || !task.userId) &&
      inListType(task, selectedListType),
  );
  const sortedAndGroupedTasks = sortAndGroupTasks(filtered, selectedListType);

  return sortedAndGroupedTasks;
};

export default processMyTasks;
