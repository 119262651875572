import type { ContactSourceFilter } from './types.flow';
import { Type } from './types.flow';
import type { Node_NodeContainer_Leaf_Contact_Details__Input } from '~/graphql/types';

import asAppTypeFilterInput from '../../Primitive/AppType/asAppTypeFilterInput';
import asIDFilterInput from '../../Primitive/ID/asIDFilterInput';
import { isBoolean } from 'underscore';
import { assertNever } from '~/util/assertion';

const asContactSourceFilterInput = (
  contactSource: ContactSourceFilter,
): Node_NodeContainer_Leaf_Contact_Details__Input => {
  switch (contactSource.type) {
    case Type.FILTER_CONTACT_SOURCE__APP_TYPE:
      return {
        source: {
          App: { appType: asAppTypeFilterInput(contactSource.appSource) },
        },
      };
    case Type.FILTER_CONTACT_SOURCE__USER:
      if (isBoolean(contactSource.userSource)) {
        return {
          source: {
            User: {
              has: contactSource.userSource,
            },
          },
        };
      }

      return {
        source: {
          User: { id: asIDFilterInput(contactSource.userSource) },
        },
      };

    default: {
      return assertNever(contactSource);
    }
  }
};

export default asContactSourceFilterInput;
