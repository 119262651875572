/**
 * Checks if the cursor's position is inside a given boundary
 * @param {DOMRect} boundary - x, y, width, height of a container
 * @param {MouseEvent} event - Mouse event
 * keywords: isInsideComponent
 */
const isCursorInBoundary = ({
  boundary,
  event,
}: {
  boundary: DOMRect;
  event: {
    clientX: number;
    clientY: number;
  };
}): boolean => {
  const { x, y, width, height } = boundary;
  const { clientX, clientY } = event;

  const insideX = clientX > x && clientX < x + width;
  const insideY = clientY > y && clientY < y + height;

  return insideX && insideY;
};

export default isCursorInBoundary;
