import {
  AppValuationReport_Update,
  GetAppValuationReportQuery,
  type UpdateAppValuationReportMutation,
} from '~/graphql/types';
import { reporter } from '~/hooks/useErrorReporter';

export type UpdateData = AppValuationReport_Update & {
  name: string;
  route: string;
};

/**
 *
 * @param {GetAppValuationReportQuery} data - Query result from GetAppValuationReportQuery
 * keywords: ValuationReportInput
 */
const convertQueryToUpdate = (
  data: GetAppValuationReportQuery | UpdateAppValuationReportMutation,
): UpdateData | null => {
  const valueReportData =
    'getAppValuationReport' in data
      ? data.getAppValuationReport
      : 'updateAppValuationReport' in data
        ? data.updateAppValuationReport
        : null;

  if (
    !valueReportData ||
    valueReportData.__typename !== 'App_ValuationReport'
  ) {
    reporter.captureException(
      new Error('No valueReportData in convertQueryToUpdate'),
    );
    return null;
  }

  const {
    general,
    address,
    livingArea,
    houseType,
    comparableProperties,
    userData,
    report,
    route,
  } = valueReportData;

  return {
    name: valueReportData.name,
    route,
    general: {
      logoImage: general.logoImage,
      logoPosition: general.logoPosition,
      logoLink: general.logoLink,
      backgroundImage: general.backgroundImage,
      mainColor: general.mainColor,
      headerTextColor: general.headerTextColor,
      accentColor: general.accentColor,
      nextButton: {
        backgroundColor: general.nextButton.backgroundColor,
        mainText: {
          text: general.nextButton.mainText.text,
          color: general.nextButton.mainText.color,
        },
      },
      step: {
        color: general.step.color,
        backgroundColor: general.step.backgroundColor,
      },
      backgroundColor: general.backgroundColor,
      navbarBackgroundColor: general.navbarBackgroundColor,
      cookiePolicy: general.cookiePolicy,
      privacyPolicy: general.privacyPolicy,
      googleAnalytics: general.googleAnalytics,
      facebookPixel: general.facebookPixel,
    },
    address: {
      mainHeader: {
        text: address.mainHeader.text,
      },
      subHeader: {
        text: address.subHeader.text,
      },
      why: {
        text: address.why.text,
      },
      when: {
        text: address.when.text,
      },
    },
    livingArea: {
      mainHeader: {
        text: livingArea.mainHeader.text,
      },
      subHeader: {
        text: livingArea.subHeader.text,
      },
    },
    houseType: {
      mainHeader: {
        text: houseType.mainHeader.text,
      },
      subHeader: {
        text: houseType.subHeader.text,
      },
    },
    comparableProperties: {
      mainHeader: {
        text: comparableProperties.mainHeader.text,
      },
    },
    userData: {
      mainHeader: {
        text: userData.mainHeader.text,
      },
      subHeader: {
        text: userData.subHeader.text,
      },
      formSubtext: {
        text: userData.formSubtext.text,
        color: userData.formSubtext.color,
      },
    },
    report: {
      contactButton: {
        mainText: {
          text: report.contactButton.mainText.text,
          color: report.contactButton.mainText.color,
        },
        subText: {
          text: report.contactButton.subText.text,
          color: report.contactButton.subText.color,
        },
        backgroundColor: report.contactButton.backgroundColor,
      },
      contactButtonClickedText: {
        text: report.contactButtonClickedText.text,
        color: report.contactButtonClickedText.color,
      },
      formSubtext: {
        text: report.formSubtext.text,
        color: report.formSubtext.color,
      },
    },
  };
};
export default convertQueryToUpdate;
