import { atom } from 'recoil';
import { ActivityV2 } from '~/components/template/EventTimelineV2/types';

export type GroupedActivities = Partial<Record<string, Array<ActivityV2>>>;
const groupedTasks = atom<GroupedActivities>({
  key: 'groupedTasks',
  default: {},
});

export default groupedTasks;

/**
 * IDs of deleted tasks used for filtering when updating the tasks.
 */
export const deletedTaskIds = atom<Array<string>>({
  key: 'deletedTaskIds',
  default: [],
});
