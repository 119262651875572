import React from 'react';
import styled, { css } from 'styled-components';
import { animated, useTransition } from 'react-spring';

import TEST_ID from './index.testid';
import getProgressPercentage from '~/util/getProgressPercentage';

const text = {
  completed: amount => `${amount >= 0 ? amount : 0} voltooid`,
  nonCompleted: amount => `${amount >= 0 ? amount : 0} in flow`,
};

export type Props = {
  completedAmount: number;
  nonCompletedAmount: number;
};

const StatisticsProgressBar: React.FCC<Props> = ({
  completedAmount,
  nonCompletedAmount,
}) => {
  const completedPercentage = getProgressPercentage(
    completedAmount,
    nonCompletedAmount,
  );

  const transitions = useTransition(true, {
    from: { width: '0%' },
    enter: { width: `${completedPercentage}%` },
    leave: { width: '0%' },
    config: { mass: 1, tension: 300, friction: 40, clamp: true },
  });

  return (
    <Container>
      <Label data-testid={TEST_ID.COMPLETED_AMOUNT_LABEL}>
        {text.completed(completedAmount)}
      </Label>
      <ProgressContainer>
        {transitions(
          (style, item) =>
            item &&
            completedAmount > 0 && (
              <Progress style={style} data-testid={TEST_ID.PROGRESS_BAR} />
            ),
        )}
      </ProgressContainer>
      <Label data-testid={TEST_ID.NON_COMPLETED_AMOUNT_LABEL}>
        {text.nonCompleted(nonCompletedAmount)}
      </Label>
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
  display: flex;
  align-items: center;
`;

const BAR_HEIGHT = '8px';

const ProgressContainer = styled.div<{}>`
  width: 80px;
  height: ${BAR_HEIGHT};
  overflow: hidden;

  ${({ theme }) => css`
    border-radius: ${BAR_HEIGHT};
    background-color: ${theme.color('tertiary')};
    margin: 0 ${theme.space('xxs')};
  `}
`;

const Progress = styled(animated.div)<{}>`
  height: ${BAR_HEIGHT};
  width: 0%;

  ${({ theme }) => css`
    border-radius: ${BAR_HEIGHT};
    background-color: ${theme.color('primary', 'light')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('primary', 'light')};
  `}
`;

const Label = styled.div<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('s')};
    color: ${theme.color('text', 'light')};
    text-overflow: ellipsis;
    overflow: hidden;
    width: 70px;
  `,
);

export default StatisticsProgressBar;
