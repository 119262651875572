import {} from 'react';
import { SessionHydrationUserFields_User_Fragment } from '~/graphql/types';
import useSessionHydration from '../useSessionHydration';
import useUserLookup from '../useUserLookup/index';

const useCurrentUser = (): SessionHydrationUserFields_User_Fragment => {
  const [{ me }] = useSessionHydration();
  const userLookup = useUserLookup(['User']) as {
    [userId: string]: SessionHydrationUserFields_User_Fragment;
  };

  return userLookup?.[me.id];
};

export default useCurrentUser;
