import { RouteComponentProps, navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import { useResetRecoilState } from 'recoil';
import Button from '~/components/atom/Button';
import ContentContainerDefault, {
  MAX_CONTAINER_WIDTH,
} from '~/components/molecule/ContentContainer';
import { OptionOf, SelectedOptionOf } from '~/components/molecule/Dropdown';
import Dropdown from '~/components/molecule/Dropdown';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import {
  SessionHydrationUserFields_User_Fragment,
  SessionHydrationOfficeFieldsFragment,
} from '~/graphql/types';
import useCurrentUser from '~/hooks/useCurrentUser';
import useMainOffice from '~/hooks/useMainOffice';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import {
  setLocalStorageItem,
  TASK_LIST_FILTER_USER_ID,
  TASK_LIST_FILTER_OFFICE_ID,
  TASK_LIST_FILTER_LIST_TYPE,
} from '~/util/localStorageKeys';
import getTaskFilters from './utils/getTaskFilters';
import { Task, TaskListType } from './types';
import TaskList from './components/TaskList';
import withErrorBoundary from '~/ErrorBoundary';
import useUserOptions from '~/hooks/useUserOptions';
import TaskWithSubscriptionGetter from './components/TaskWithSubscriptionGetter';
import groupedTasks from './state';
import createPageTitle from '~/util/createPageTitle';

export const TASK_LIST_LIMIT = 20;

const text = {
  listTitle: 'Taken',
  pageTitle: 'Taken',
  noOffice: 'Alle vestigingen',
  noUser: 'Alle gebruikers',
  addTaskButtonLabel: 'Nieuwe taak',
  taskFilter: 'Taken filter',
  userFilter: 'Gebruiker filter',
  officeFilter: 'Vestiging filter',
};

type Props = RouteComponentProps;

const Tasks: React.FC<Props> = () => {
  const me = useCurrentUser();

  const resetTasksList = useResetRecoilState(groupedTasks);

  const [initialTaskForModal, setInitialTaskForModal] = useState<Task | null>(
    null,
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const mainOffice = useMainOffice(me.id);

  const { filteredListType, filteredOfficeId, filteredUserId } =
    getTaskFilters(mainOffice);

  /** The default is either the the id from the localStorage or main office id */
  const [selectedOfficeId, setSelectedOfficeId] = useState<string | null>(
    filteredOfficeId,
  );

  /** The default is either the the id from the localStorage or me.id if no office selected, otherwise it is null */
  const [selectedUserId, setSelectedUserId] = useState<string | null>(
    filteredUserId,
  );

  /** Open tasks by default */
  const [selectedListType, setSelectedListType] =
    useState<TaskListType>(filteredListType);

  const officeFilterOptions = useOfficeOptions({
    userId: me.id,
    withAllOfficesOption: true,
  });

  const userFilterOptions = useUserOptions({
    officeId: selectedOfficeId,
    withAllUsersOption: true,
  });

  useEffect(
    () => () => {
      // Reset tasks recoil state when navigating away
      resetTasksList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate],
  );

  /** Check the location to see if a specific filter needs to be used there */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('officeId') != null) {
      if (params.get('officeId') === 'null') {
        setSelectedOfficeId(null);
        setSelectedUserId(me.id);
        return;
      }

      setSelectedOfficeId(params.get('officeId'));
    }
    if (params.get('userId') != null) {
      setSelectedUserId(params.get('userId'));
    }
    if (params.get('status') != null) {
      setSelectedListType(params.get('status') as TaskListType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onShowModal = (shouldShowModal: boolean) => {
    if (!shouldShowModal) {
      setInitialTaskForModal(null);
    }

    setShowModal(shouldShowModal);
  };
  const onOpenTask = (task: Task | null) => {
    setInitialTaskForModal(task);
    onShowModal(true);
  };

  const onOfficeChange = (
    selectedOption: SelectedOptionOf<SessionHydrationOfficeFieldsFragment | null>,
  ) => {
    if (selectedOfficeId && selectedOption.option.key !== selectedOfficeId) {
      resetTasksList();
    }

    if (selectedOption.option.key === 'no-selection') {
      setSelectedOfficeId(null);
      setLocalStorageItem(TASK_LIST_FILTER_OFFICE_ID, null);
      setLocalStorageItem(TASK_LIST_FILTER_USER_ID, me.id);
      setSelectedUserId(me.id);
    } else {
      setSelectedOfficeId(selectedOption.option.payload?.id || null);
      setSelectedUserId(null);
      setLocalStorageItem(
        TASK_LIST_FILTER_OFFICE_ID,
        selectedOption.option.payload?.id ?? null,
      );
      setLocalStorageItem(TASK_LIST_FILTER_USER_ID, null);
    }
  };

  const onTaskListTypeChange = (
    selectedOption: SelectedOptionOf<TaskListType>,
  ) => {
    if (selectedOption.option.key !== selectedListType) {
      resetTasksList();
    }

    setSelectedListType(selectedOption.option.payload);
    setLocalStorageItem(
      TASK_LIST_FILTER_LIST_TYPE,
      selectedOption.option.payload,
    );
  };

  const onUserChange = (
    selectedOption: SelectedOptionOf<SessionHydrationUserFields_User_Fragment | null>,
  ) => {
    if (selectedUserId && selectedOption.option.key !== selectedUserId) {
      resetTasksList();
    }

    setSelectedUserId(selectedOption.option.payload?.id || null);
    setLocalStorageItem(
      TASK_LIST_FILTER_USER_ID,
      selectedOption.option.payload?.id ?? null,
    );
  };

  const isMyTaskList = selectedOfficeId === null;
  const selectedOfficeIndex = officeFilterOptions.findIndex(
    option => option.payload?.id === selectedOfficeId,
  );
  const selectedUserIndex = userFilterOptions.findIndex(
    option => option.payload?.id === selectedUserId,
  );

  return (
    <ContentContainerDefault maxContentWidth={MAX_CONTAINER_WIDTH}>
      <MetaTags>
        <title>{createPageTitle(text.pageTitle)}</title>
      </MetaTags>

      <OverviewListHeader
        title={text.listTitle}
        buttons={[
          {
            node: (
              <Button
                onClick={() => onShowModal(true)}
                dataTestId="add-task-button"
                label={text.addTaskButtonLabel}
                size="medium"
              />
            ),
            key: 'add-task-button',
          },
          {
            key: 'task-filter-bar-task',
            node: (
              <Dropdown
                placeholder={text.taskFilter}
                options={taskListTypeFilterOptions}
                selectedOptionIdx={taskListTypeFilterOptions.findIndex(
                  option => option.payload === selectedListType,
                )}
                onChange={onTaskListTypeChange}
              />
            ),
          },
          {
            key: 'task-filter-bar-office',
            node: (
              <Dropdown
                placeholder={text.officeFilter}
                options={officeFilterOptions}
                selectedOptionIdx={
                  selectedOfficeIndex !== -1 ? selectedOfficeIndex : 0
                }
                onChange={onOfficeChange}
              />
            ),
          },
          {
            key: 'task-filter-bar-user',
            node: (
              <Dropdown
                placeholder={text.userFilter}
                options={userFilterOptions}
                selectedOptionIdx={
                  selectedUserIndex !== -1 ? selectedUserIndex : 0
                }
                onChange={onUserChange}
                disabled={isMyTaskList}
              />
            ),
          },
        ]}
        dataTestId="tasks-header"
      />
      <TaskWithSubscriptionGetter
        selectedUserId={selectedUserId}
        selectedOfficeId={selectedOfficeId}
        selectedListType={selectedListType}
      >
        {({ loadMore, hasMore }) => (
          <TaskList
            onClick={task => onOpenTask(task)}
            loadMore={loadMore}
            hasMore={hasMore}
            onAddNewTask={() => onShowModal(true)}
            showModal={showModal}
            defaultOfficeId={selectedOfficeId}
            defaultUserId={selectedUserId}
            initialTaskForModal={initialTaskForModal}
            closeModal={() => onShowModal(false)}
            showTaskModal={(task?: Task) => {
              if (task != null) {
                onOpenTask(task);
              } else {
                onOpenTask(null);
              }
            }}
          />
        )}
      </TaskWithSubscriptionGetter>
    </ContentContainerDefault>
  );
};

const taskListTypeFilterOptions: Array<OptionOf<TaskListType>> = [
  {
    label: 'Openstaande taken',
    payload: 'Open',
    key: 'Open',
  },
  {
    label: 'Voltooide taken',
    payload: 'Closed',
    key: 'Closed',
  },
];

export default withErrorBoundary(Tasks);
