import Analytics from './Analytics';
import BrainbayLogo from './Brainbay';
import Browser from './Browser';
import DatHuisLogo from './DatHuis';
import FundaLogo from './Funda';
import ZapierLogo from './Zapier';
import RealworksLogo from './Realworks';
import Hypotheekbond from './Hypotheekbond';
import ARXGroepLogo from './ARXGroepLogo';
import ARXGroepEmblem from './ARXGroepEmblem';
import Trustoo from './Trustoo';
import TrustooEmblem from './TrustooEmblem';
import Duurzaamheidsprofiel from './Duurzaamheidsprofiel';
import DatHuisEmblemXBNDL from './DatHuisEmblemXBNDL';

export type Props = React.SVGAttributes<SVGElement> & {
  color?: string;
  secondary?: boolean;
};

const logos = {
  brainbay: BrainbayLogo,
  funda: FundaLogo,
  dathuis: DatHuisLogo,
  dathuisEmblemXBNDL: DatHuisEmblemXBNDL,
  browser: Browser,
  analytics: Analytics,
  zapier: ZapierLogo,
  realworks: RealworksLogo,
  hypotheekbond: Hypotheekbond,
  arxGroep: ARXGroepLogo,
  arxGroepEmblem: ARXGroepEmblem,
  trustoo: Trustoo,
  trustooEmblem: TrustooEmblem,
  susReport: Duurzaamheidsprofiel,
};

export default logos;
