import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label, Variant } from '~/components/atom/Typography';

type Props = {
  children: string;
};
const FieldLabel = ({ children }: Props) => (
  <JustificationContainer width="100%">
    <Label size="base" variant={Variant.primary} margin={[null]}>
      {children}
    </Label>
  </JustificationContainer>
);

export default FieldLabel;
