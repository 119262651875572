import { useEffect } from 'react';
import useEditorStates from '~/components/organism/PluginsEditor/hooks/useEditorStates';
import useRelativeMaps from '~/components/page/Automation/v2/components/Builder/hooks/useRelativeMaps';
import convertTemplateStringToSlateFragment from '~/components/organism/PluginsEditor/utils/flows/convertTemplateStringToSlate';
import type { FlowV2_ParameterMappingFragment } from '~/graphql/types';
import type ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import useDebounce from '~/hooks/useDebounce';
import type { Client_FlowV2_EmailAttachment } from '../../components/types';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';

type Params = {
  /** Action id */
  actionId: string;

  /** Initial html */
  html: string;

  /** Initial mappings */
  mappings: Array<FlowV2_ParameterMappingFragment>;

  /** Initial attachments */
  attachments?: Array<Client_FlowV2_EmailAttachment>;

  /** Custom elements that are allowed in the editor */
  customElements?: Array<ELEMENTS>;

  /** Callback that updates the action */
  updaterFn: (updated: EditorValue) => void;
};

/**
 * Helps to manage the DHEditor states in UpdateAction form.
 * Use this when you have a field of type Flow_Parameter__TemplateString
 */
const useDHEditorInFlowBuilder = ({
  actionId,
  html,
  mappings,
  attachments,
  customElements = [],
  updaterFn,
}: Params) => {
  const maps = useRelativeMaps({ actionId });

  const initialValue = convertTemplateStringToSlateFragment({
    html,
    mappings,
    attachments,
    customElements,
    ...maps,
  });

  const { hasChanges, value, key, onChange, resetEditor } = useEditorStates({
    initialValue,
  });

  const debouncedChanges = useDebounce(value, 200);

  useEffect(() => {
    if (hasChanges) {
      updaterFn(debouncedChanges);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedChanges, hasChanges]);

  return {
    key,
    value,
    onChange,
    resetEditor,
  };
};

export default useDHEditorInFlowBuilder;
