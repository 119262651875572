import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useCollapseAnimation from '~/hooks/useCollapseAnimation';
import { animated } from 'react-spring';
import useScrollIntoView from '~/hooks/useScrollIntoView';
import { ExpandableLayout } from '~/graphql/types';
import { BLUR_STYLES } from '../../Data/styles';

export type Props = Omit<ExpandableLayout, '__typename'> & {
  dataTestId?: string;
};

const Expandable: React.FCC<Props> = ({
  children,
  title,
  margin,
  padding,
  blurred,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);
  const [scrollToContainer, containerRef] = useScrollIntoView({});

  const { innerRef, outer, inner } = useCollapseAnimation({
    isOpen: expanded,
  });

  return (
    <JustificationContainer
      ref={containerRef}
      width="100%"
      direction="column"
      margin={margin ?? undefined}
      padding={padding ?? undefined}
      {...rest}
    >
      <ShowMoreContainer
        align="center"
        onClick={() => {
          setExpanded(prev => !prev);
          if (!expanded) scrollToContainer();
        }}
        width="100%"
        padding={['base']}
      >
        <Icon name={expanded ? 'chevron-up' : 'chevron'} margin={[null, 's']} />

        <>{expanded ? title?.expanded : title?.collapsed}</>
      </ShowMoreContainer>

      {/* @ts-ignore */}
      <animated.div
        style={{
          opacity: outer.opacity,
          height: outer.height,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        <ChildrenContainer
          $blurred={blurred || false}
          ref={innerRef}
          style={{ y: inner.y, width: '100%' }}
        >
          {children}
        </ChildrenContainer>
      </animated.div>
    </JustificationContainer>
  );
};

const ShowMoreContainer = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    color: ${theme.color('text', 'light')};
    cursor: pointer;

    &:hover {
      color: ${theme.color('primary', 'light')};
      transition: all 0.2s ease-in;
    }
  `,
);

const ChildrenContainer = styled(animated.div)<{ $blurred: boolean }>(
  ({ $blurred }) => css`
    ${$blurred && BLUR_STYLES};
  `,
);

export default Expandable;
