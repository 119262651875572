const waitForElement = <T extends Element>(
  selector: string,
  timeout: number = 2000,
): Promise<T> =>
  new Promise((resolve, reject) => {
    const pollInterval = 100; // Polling interval in milliseconds

    // Set a timeout to reject the promise if the element isn't found within the specified time
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId); // Clear the polling interval
      reject(
        new Error(
          `Element with selector "${selector}" not found within ${timeout}ms`,
        ),
      );
    }, timeout);

    // Start polling every pollInterval milliseconds
    const intervalId = setInterval(() => {
      const element = document.querySelector(selector) as T; // Cast the element to the desired type
      if (element) {
        clearInterval(intervalId); // Stop polling once the element is found
        clearTimeout(timeoutId); // Clear the timeout
        resolve(element); // Resolve the promise with the found element
      }
    }, pollInterval);
  });

export default waitForElement;
