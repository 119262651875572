/**
 * serializes the Signature element into html element
 * @param {SignatureElement} node - Signature element
 * @param {string} children - Children
 */
const serialize = (_, children: string): string => {
  if (children === '<div></div>') return '';

  return `${children}`;
};

export default serialize;
