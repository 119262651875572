import { ClientFlowAction } from '~/graphql/types.client';

/** Returns all parent actions (primary & secondary) */
const getParentActions = (
  subjectAction: ClientFlowAction,
  actions: Array<ClientFlowAction>,
): Array<ClientFlowAction> => {
  const parentActionIds: Array<string> =
    'parentIds' in subjectAction ? [...subjectAction.parentIds] : [];
  const hashMap = {};

  for (const action of actions) {
    if (!hashMap[action.id]) hashMap[action.id] = [];
    if ('parentIds' in action) {
      hashMap[action.id].push(...action.parentIds);
    }
  }

  const handleParent = (id: string) => {
    const parents = hashMap[id];
    if (!parents) return;
    for (const parentId of parents) {
      if (!parentId) return;
      parentActionIds.push(parentId);
      handleParent(parentId);
    }
  };

  handleParent(subjectAction.id);

  return actions.filter(({ id }) => parentActionIds.includes(id));
};

export default getParentActions;
