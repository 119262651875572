import React from 'react';
import styled, { css } from 'styled-components';
import { Heading4, Variant } from '~/components/atom/Typography';

import InstructionBlock, { Instruction } from './components/InstructionBlock';

export type Props = {
  header: string;
  instructions: Array<Instruction>;
};

const Instructions: React.FCC<Props> = ({
  dataTestId,
  header,
  instructions,
  ...rest
}) => (
  <Container data-testid={dataTestId} {...rest}>
    <Heading4 margin={['xl', null, 'l', null]} variant={Variant.primary}>
      {header}
    </Heading4>
    <InstructionsWrapper>
      {instructions.map((instruction, key) => (
        <InstructionBlock key={key} instruction={instruction} />
      ))}
    </InstructionsWrapper>
  </Container>
);

const Container = styled.div<{}>``;

const InstructionsWrapper = styled.div<{}>(
  ({ theme }) => css`
    background: ${theme.color('primary', 'translucent')};
    padding: ${theme.space('m')};
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);
export default Instructions;
