import styled, { css } from 'styled-components';

const StandardOuter = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    background-color: ${theme.color('white')};
    border-top: 1px solid ${theme.color('tertiary')};
    border-bottom-left-radius: ${theme.getTokens().border.radius.m};
    border-bottom-right-radius: ${theme.getTokens().border.radius.m};
  `}
`;

const StandardInner = styled.div<{}>(
  ({ theme }) => css`
    flex-grow: 1;
    align-self: start;
    border-bottom-left-radius: ${theme.getTokens().border.radius.m};
    border-bottom-right-radius: ${theme.getTokens().border.radius.m};
  `,
);

export default {
  Standard: {
    Inner: StandardInner,
    Outer: StandardOuter,
  },
};
