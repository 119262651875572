import {
  FlowAction,
  FlowFieldsV2Fragment,
  FlowV2_ActionFragment,
} from '~/graphql/types';
import type {
  ClientFlowAction,
  ClientFlowActionIfElse,
  ClientFlowActionStart,
  ClientFlowActionWait,
  ClientFlowActionContactAddTag,
  ClientFlowActionZapierTrigger,
  ClientFlowActionTaskCreate,
  ClientFlowActionSendEmailPlain,
  ClientFlowActionContactDetails,
  ClientFlowActionContactDeleteTag,
  ClientFlowActionContactAssign,
  ClientFlowActionRealworksSendContact,
  ClientFlowActionSendNotification,
} from '~/graphql/types.client';

import { assertNeverWithoutThrowing } from '~/util/assertion';
import generateConditionExpression from '../../components/UpdateAction/components/ConditionEditorV2/utils/generateConditionExpression';

export const getFlowActionToClientAction = (
  action: FlowV2_ActionFragment,
): ClientFlowAction | null => {
  switch (action.__typename) {
    case 'FlowV2_Action_IfElse': {
      const clientAction: ClientFlowActionIfElse = {
        ...action,
        actionType: FlowAction.IfElse,
        condition: generateConditionExpression(action.condition),
      };

      return clientAction;
    }

    case 'FlowV2_Action_Wait': {
      const clientAction: ClientFlowActionWait = {
        ...action,
        actionType: FlowAction.Wait,
        condition: generateConditionExpression(action.condition),
      };

      return clientAction;
    }

    case 'FlowV2_Action_Start': {
      const condition = generateConditionExpression(
        action.conditionList ?? {
          __typename: 'FlowV2_ConditionList',
          exp: '([0])',
          conditions: [],
        },
      );
      const clientAction: ClientFlowActionStart = {
        ...action,
        actionType: FlowAction.Start,
        condition,
      };

      return clientAction;
    }

    case 'FlowV2_Action_Contact_AddTag':
      const clientAction: ClientFlowActionContactAddTag = {
        ...action,
        actionType: FlowAction.ContactAddTag,
      };

      return clientAction;
    case 'FlowV2_Action_Contact_Assign': {
      const clientAction: ClientFlowActionContactAssign = {
        ...action,
        actionType: FlowAction.ContactAssign,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Contact_DeleteTag': {
      const clientAction: ClientFlowActionContactDeleteTag = {
        ...action,
        actionType: FlowAction.ContactDeleteTag,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Contact_Details': {
      const clientAction: ClientFlowActionContactDetails = {
        ...action,
        actionType: FlowAction.ContactDetails,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Realworks_SendContact': {
      const clientAction: ClientFlowActionRealworksSendContact = {
        ...action,
        actionType: FlowAction.RealworksSendContact,
      };
      return clientAction;
    }
    case 'FlowV2_Action_SendEmail_Plain': {
      const clientAction: ClientFlowActionSendEmailPlain = {
        ...action,
        actionType: FlowAction.SendEmailPlain,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Task_Create': {
      const clientAction: ClientFlowActionTaskCreate = {
        ...action,
        actionType: FlowAction.TaskCreate,
      };
      return clientAction;
    }
    case 'FlowV2_Action_Zapier_Trigger': {
      const clientAction: ClientFlowActionZapierTrigger = {
        ...action,
        actionType: FlowAction.ZapierTrigger,
      };

      return clientAction;
    }
    case 'FlowV2_Action_SendNotification': {
      const clientAction: ClientFlowActionSendNotification = {
        ...action,
        actionType: FlowAction.SendNotification,
      };

      return clientAction;
    }

    default: {
      assertNeverWithoutThrowing(action, '');
      return null;
    }
  }
};

const getFlowActionsToClientActions = (
  actions: FlowFieldsV2Fragment['Actions'],
): Array<ClientFlowAction> => {
  const clientActions = actions
    .map(getFlowActionToClientAction)
    .filter((action): action is ClientFlowAction => action !== null);

  return clientActions;
};

export default getFlowActionsToClientActions;
