import { v1 as uuidv1 } from 'uuid';
import namedEmailFormatter, { NamedEmail } from '~/util/namedEmailFormatter';
import { EmailListProps } from '../..';
import Validation from '~/util/Validation';
import type { EmailOption } from '../../components/MessageHeader/hooks/useGetEmailOptions';

/**
 *
 * @param {EmailListProps} prop - EmailListProps
 * keywords: namedEmailList, cc, bcc
 */
const getNamedEmailListFromProp = (
  prop: EmailListProps,
): Array<EmailOption> => {
  const mapFunction = (namedEmail: NamedEmail) => ({
    label: namedEmailFormatter(namedEmail),
    payload: {
      namedEmail,
      filterLabel: namedEmail.name || '',
      tagLabel: namedEmailFormatter(namedEmail),
    },
    isValid: Validation.Email.isValid(namedEmail.email),
    key: uuidv1(),
  });

  return prop.namedEmailList && prop.namedEmailList.length > 0
    ? prop.namedEmailList.map(mapFunction)
    : [];
};

export default getNamedEmailListFromProp;
