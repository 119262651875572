import React from 'react';
import styled from 'styled-components';
import Divider from '~/components/atom/Divider';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Features from '~/components/organism/PriceCard/components/Features';
import TextButton from '~/components/atom/TextButton';
import { Heading4, Variant } from '~/components/atom/Typography';
import {
  BillingCycle,
  NextPlanFragment,
  SelectedPlanFragment,
} from '~/graphql/types';
import useOnUpgradeSubscription from '~/hooks/useOnUpgradeSubscription';
import { convertServerDateStringToDate, safeFormattedDate } from '~/util/date';
import TEST_ID from './index.testid';

export type Props = {
  currentPlan: SelectedPlanFragment;
  nextPlan?: NextPlanFragment | null;
  onPlanChange?: () => void;
};

const text = {
  currentPlanHeader: 'Huidige abonnement:',
  nextPlanHeader: 'Volgende abonnement:',
  nextPlanStartsAt: 'begint op:',
  updateSubscription: 'Wijzig abonnement',
  billingCycleLabels: {
    [BillingCycle.Yearly]: 'Jaarlijks',
    [BillingCycle.Monthly]: 'Maandelijks',
  },
};

const SubscriptionDetails: React.FCC<Props> = ({
  currentPlan,
  nextPlan,
  onPlanChange,
  ...rest
}) => {
  const { onUpgrade, modal } = useOnUpgradeSubscription({
    onComplete: onPlanChange,
  });

  return (
    <>
      {modal}
      <Container data-testid={TEST_ID.CONTAINER} {...rest}>
        <JustificationContainer key={currentPlan.id} direction="column">
          <Heading4
            variant={Variant.primary}
            dataTestId={TEST_ID.HEADER}
            data-plan={currentPlan.id}
            data-billingcycle={currentPlan.billingCycle}
          >
            {text.currentPlanHeader}&nbsp;{currentPlan.name}{' '}
            {text.billingCycleLabels[currentPlan.billingCycle]}
          </Heading4>
          <Features items={currentPlan.features} showCaveats={false} />
        </JustificationContainer>

        {nextPlan?.plan && (
          <>
            <Divider />
            <JustificationContainer key={nextPlan.plan.id} direction="column">
              <Heading4
                variant={Variant.primary}
                dataTestId={TEST_ID.NEXT_PLAN}
                data-plan={nextPlan.plan.id}
                data-billingcycle={nextPlan.plan.billingCycle}
              >
                {text.nextPlanHeader}&nbsp;
                {nextPlan.plan.name}{' '}
                {text.billingCycleLabels[nextPlan.plan.billingCycle]}
                &nbsp;
                <small>
                  {text.nextPlanStartsAt}&nbsp;
                  {safeFormattedDate(
                    convertServerDateStringToDate(nextPlan.changeDate),
                  )}
                </small>
              </Heading4>
              <Features items={nextPlan.plan.features} showCaveats={false} />
            </JustificationContainer>
          </>
        )}
        <JustificationContainer justification="end" width="100%">
          <TextButton
            size="medium"
            onClick={onUpgrade}
            label={text.updateSubscription}
            dataTestId={TEST_ID.CHANGE_SUBSCRIPTION_BUTTON}
          />
        </JustificationContainer>
      </Container>
    </>
  );
};

const Container = styled.div<{}>``;

export default SubscriptionDetails;
