import React from 'react';
import Pin from '../Pin';
import SelectableRow from '../SelectableRow';
import {
  PinnedApp,
  PinnedAppsMap,
} from '~/components/page/Widget/state/pinnedApps';
import { AccordionGroup } from '../..';
import generatePinnedItem from '../../utils/generatePinnedItem';
import { pinOptions } from '../../__fixtures__';
import { PinAction } from '../Group';
import ToggleHiddenFlag from '../ToggleHiddenFlag';
import ShareIcon from '../ShareIcon';
import type { ShareAppArgs } from '~/components/page/Widget/components/Apps';

export type Props = {
  items: Array<AccordionGroup>;

  /** Checked state of the group */
  checked: boolean;

  /** Removes checkboxes to only display text */
  selectable: boolean;

  pinnedApps: PinnedAppsMap;

  onShare: (args: ShareAppArgs) => void;
  onPin: (type: PinAction, item: PinnedApp) => void;
  onItemChange: (
    id: string,
    field: 'checked' | 'hidden',
    value: boolean,
  ) => void;
};

const GroupItems: React.FCC<Props> = ({
  pinnedApps,
  items,
  onPin,
  selectable,
  checked: mainChecked,
  onItemChange,
  onShare,
}) => (
  <>
    {items.map(({ name, id, checked, hidden, slug }) => {
      const pinnedItem = pinnedApps[id]
        ? { id, pinned: pinnedApps[id] }
        : undefined;

      const selectedOption = pinOptions.find(
        option => option.value === pinnedItem?.pinned.weight,
      );

      const disabled = !mainChecked || !checked;

      return (
        <SelectableRow
          row={{
            name,
            id,
            checked,
          }}
          checkboxTitle="App"
          key={id}
          onChange={value => onItemChange(id, 'checked', value)}
          selectable={selectable}
          disabled={!mainChecked}
        >
          <Pin
            disabled={disabled}
            selectedOption={selectedOption}
            options={pinOptions}
            onClick={(type, weight) => {
              const itemToPin = generatePinnedItem({ id, weight });
              onPin(type, itemToPin);
            }}
          />

          <ToggleHiddenFlag
            disabled={disabled}
            hidden={hidden ?? false}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              return onItemChange(id, 'hidden', !hidden);
            }}
          />

          <ShareIcon
            disabled={disabled}
            onClick={() => onShare({ slug, name })}
          />
        </SelectableRow>
      );
    })}
  </>
);

export default GroupItems;
