import { omit } from 'ramda';
import { OutputMap } from '~/components/organism/WizardSteps';
import {
  AppStatus_Contaqt__Input,
  AppStatus_Realworks__Input,
  AppStatus_Trustoo__Input,
  AppStatus__Input,
} from '~/graphql/types';
import { AppStatus, AppStatus__typename } from '~/graphql/types.client';
import getMetadataForAppStatus from '../getMetadataForAppStatus';
import { reporter } from '~/hooks/useErrorReporter';
import getWidgetAppConfigurationUpdate from '~/util/getWidgetAppConfigurationUpdate';

const getEnableFlowsUpdate = (
  outputMap: OutputMap,
  typename: AppStatus__typename,
  currentAppStatus?: AppStatus,
): AppStatus__Input | null | undefined => {
  if (!currentAppStatus) return null;

  if (
    !('metadata' in currentAppStatus) ||
    currentAppStatus.metadata?.setup == null
  ) {
    // Make sure to add the metadata of a new app in AppStatusFields otherwise it will fall into this case
    reporter.captureException(
      new Error(
        `Could not find metadata in passed appStatus: ${currentAppStatus}`,
      ),
      'error',
    );
    return;
  }

  if (typename == 'AppStatus_Realworks') {
    const update: AppStatus_Realworks__Input = {
      enabled: true,
      usageAgreement: true,
      metadata: getMetadataForAppStatus(currentAppStatus, outputMap),
    };
    return { AppStatus_Realworks: update };
  }

  if (typename === 'AppStatus_Trustoo') {
    const tokens =
      'tokens' in currentAppStatus
        ? currentAppStatus.tokens.map(token =>
            omit(['__typename', 'TokenInfo'], token),
          )
        : [];

    const update: AppStatus_Trustoo__Input = {
      ...getWidgetAppConfigurationUpdate(currentAppStatus),
      enabled: true,
      tokens,
      metadata: getMetadataForAppStatus(currentAppStatus, outputMap),
    };

    return { AppStatus_Trustoo: update };
  }

  if (typename === 'AppStatus_Contaqt') {
    const token = 'token' in currentAppStatus ? currentAppStatus.token : null;

    const update: AppStatus_Contaqt__Input = {
      enabled: true,
      token: token,
      metadata: getMetadataForAppStatus(currentAppStatus, outputMap),
    };

    return { AppStatus_Contaqt: update };
  }

  const update = {
    ...getWidgetAppConfigurationUpdate(currentAppStatus),
    enabled: true,
    metadata: getMetadataForAppStatus(currentAppStatus, outputMap),
  };

  return {
    [typename]: update,
  };
};

export default getEnableFlowsUpdate;
