import type { Reducer } from 'react';
import type { ToAddressOption } from '../utils/convertToDropdownOption';
import type { FlowV2_Action_SendNotificationFragment } from '~/graphql/types';
import { uniq } from 'ramda';
import type { Client_FlowV2_EmailAttachment } from '../../types';
import Validation from '~/util/Validation';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';

/**
 * Temporary value to insert/delete the options easily.
 *
 * It is replaced later on with the `to` field when submitting the update action form
 * See: src/components/page/Automation/v2/components/UpdateAction/utils/serializeEditorValues/index.ts
 */
type ToOptions = { toOptions: Array<ToAddressOption> };
export type State = Omit<
  FlowV2_Action_SendNotificationFragment,
  | '__typename'
  | 'id'
  | '_v'
  | 'accountId'
  | 'parentIds'
  | 'flowBlueprintId'
  | 'actionType'
> &
  ToOptions;

/** Offices in the session hydration that have email addresses */
export type OfficesWithEmails = {
  [officeId: string]: { name: string; email: string };
};

type UpdateSubjectValue = {
  type: 'updateSubjectValue';
  payload: EditorValue;
};

type UpdateBodyValue = {
  type: 'updateBodyValue';
  payload: EditorValue;
};

type UpdateAttachments = {
  type: 'updateAttachments';
  payload: Array<Client_FlowV2_EmailAttachment>;
};

type AddTargetOption = {
  type: 'addTargetOption';
  payload: ToAddressOption;
};

type RemoveTargetOption = {
  type: 'removeTargetOption';
  payload: ToAddressOption;
};

type UpdateOfficeEmailState = {
  type: 'updateOfficeEmailState';
  payload: {
    officeEmails: OfficesWithEmails;
  };
};

export type UpdateValueAction =
  | AddTargetOption
  | RemoveTargetOption
  | UpdateSubjectValue
  | UpdateBodyValue
  | UpdateAttachments
  | UpdateOfficeEmailState;

export type ReducerAction = UpdateValueAction;

export const reducer: Reducer<State, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'updateSubjectValue': {
      return {
        ...state,
        subjectValue: action.payload,
      };
    }

    case 'updateBodyValue': {
      return {
        ...state,
        bodyValue: action.payload,
      };
    }

    case 'updateAttachments': {
      return {
        ...state,
        attachments: uniq([...(action.payload || [])]),
      };
    }

    case 'addTargetOption': {
      return {
        ...state,
        toOptions: [...(state.toOptions || []), action.payload],
      };
    }

    case 'removeTargetOption': {
      return {
        ...state,
        toOptions: state.toOptions
          ? state.toOptions.filter(option => option.key !== action.payload.key)
          : [],
      };
    }

    case 'updateOfficeEmailState': {
      return {
        ...state,
        toOptions: state.toOptions.map(option => {
          if (!option.payload.value.officeId) return option;

          const officeToUpdate =
            action.payload.officeEmails[option.payload.value.officeId];

          if (officeToUpdate && option.isValid === false) {
            const { name, email } = officeToUpdate;
            const label = `${name} (${email || ''})`;

            return {
              ...option,
              payload: {
                ...option.payload,
                filterLabel: `${name} ${email || ''}`,
                tagLabel: label,
              },
              label,
              isValid: Validation.Email.isValid(email),
            };
          }

          return option;
        }),
      };
    }

    default:
      return state;
  }
};
