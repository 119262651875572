/**
 * Separates label (inside double brackets) from the text and returns both of them.
 * Make sure to add the label before the text.
 *
 * @param {string} str - string
 * keywords: action label, pointer label
 */

/** Matches every character inside double brackets */
const BRACKETS_REGEX = /(\[\[.*?\]\])/g;

const getLabelParts = (str: string | null): Array<string> | null => {
  if (!str) return null;

  const parts = str.split(BRACKETS_REGEX);
  return parts;
};

export default getLabelParts;
