import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  GROUP_CONTAINER: `${cleanedFilename(__filename)}-group-container`,
  SUB_GROUP_CONTAINER: `${cleanedFilename(__filename)}-sub-group-container`,
  COLLAPSED_ROW: `${cleanedFilename(__filename)}-collapsed-row`,
  ADD_GROUP_BUTTON: `${cleanedFilename(__filename)}-add-group-button`,
  ADD_SUB_GROUP_BUTTON: `${cleanedFilename(__filename)}-add-sub-group-button`,
  GROUP_OPERATOR: `${cleanedFilename(__filename)}-group-operator`,
  SUB_GROUP_OPERATOR: `${cleanedFilename(__filename)}-sub-group-operator`,
};
