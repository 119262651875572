import React from 'react';
import Validation from '~/util/Validation';
import MultiValueInput, {
  type Props as MultiValueInputProps,
} from '../MultiValueInput';

const text = {
  tagFieldLabel: 'Typ een tag en klik op de plusknop',
  invalidTag: 'Ongeldige tag',
  addTagButton: 'Tag invoeren',
};

export type Props = Omit<MultiValueInputProps, 'label' | 'addButton'> & {
  /** Make it controlled from the parent using these two props */
  inputValue?: string;

  onChange?: (value: string) => void;
};

const TagListInput: React.FCC<Props> = ({
  inputValue,
  onChange,
  selectedValues,
  ...rest
}) => (
  <MultiValueInput
    onChange={onChange || undefined}
    inputValue={inputValue || undefined}
    label={text.tagFieldLabel}
    selectedValues={selectedValues}
    addButton={{ label: text.addTagButton }}
    formatFunction={value => Validation.Tag.format(value)}
    {...rest}
  />
);

export default TagListInput;
