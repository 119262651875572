import { useEffect, useRef } from 'react';

const useNonNullablePrevious = <T>(value: T): T => {
  const ref = useRef<any>(value);

  useEffect(() => {
    if (value == null) return;
    ref.current = value;
  });

  return ref.current;
};

export default useNonNullablePrevious;
