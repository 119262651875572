import type { FormBuilder_NodeFragment } from '~/graphql/types';

const getNewlyAddedNodes = <T extends FormBuilder_NodeFragment>(
  original: Array<T>,
  updated: Array<T>,
): Array<T> => {
  const firstArrayIds = new Set(original.map(item => item.id));
  return updated.filter(item => !firstArrayIds.has(item.id));
};

export default getNewlyAddedNodes;
