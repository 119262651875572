import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { AppBbWaardecheck__Input } from '~/graphql/types';
import { Page } from '../Preview';
import useDebounce from '~/hooks/useDebounce';

import { useRecoilValue } from 'recoil';
import { WaardecheckApp } from '../..';
import mergeWithDefault from '../Preview/utils/mergeWithDefault';
import { useLocation } from '@gatsbyjs/reach-router';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { appWaardecheckImageStash } from '../../state';

export type Device = 'mobile' | 'tablet' | 'desktop';
export type Story = {
  id: 'components-propertycard--backface-story';
  page?: Page;
  height?: number;
};

export type Props = {
  data: AppBbWaardecheck__Input;
  story: Story;
};

const StoryPreview: React.FCC<Props> = ({ data: oData, story, ...rest }) => {
  const imageStash = useRecoilValue(appWaardecheckImageStash);
  const previewContainer = useRef<HTMLDivElement>(null);
  const { id, page = Page['/report'], height } = story;

  const data = useDebounce(oData, 500);

  const { name: accountName } = useCurrentAccount();
  const { pathname } = useLocation();
  const appType = pathname.includes('matrixian')
    ? WaardecheckApp.VBO
    : WaardecheckApp.BB;

  const [{ styleConfig, contentConfig }, setConfig] = useState({
    styleConfig: '{}',
    contentConfig: '{}',
  });

  useEffect(() => {
    const styleConfigJSON = JSON.stringify({
      primary: data.general.primary,
      secondary: data.general.secondary,
      tertiary: data.general.tertiary,
      quaternary: data.general.quaternary,
    });

    const styleConfig = encodeURIComponent(styleConfigJSON);

    const contentConfigJSON = JSON.stringify(
      mergeWithDefault({ input: data, imageStash, page, accountName, appType }),
    );

    const contentConfig = encodeURIComponent(contentConfigJSON);

    setConfig({ styleConfig, contentConfig });
  }, [data, imageStash, page, accountName, appType]);

  const url = `https://dk1sxy5hmbelh.cloudfront.net/iframe.html?id=${id}&viewMode=story&styleConfig=${styleConfig}&contentConfig=${contentConfig}`;

  return (
    <Container ref={previewContainer} {...rest}>
      <PreviewContainer
        height={height ? `${height}px` : '100%'}
        width={'100%'}
        style={{ transform: `scale(1)` }}
        src={url}
      />
    </Container>
  );
};

const PreviewContainer = styled.iframe<{}>(
  () => css`
    transform: scale(0.1);
    transform-origin: 0 0;
    border: none;
  `,
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    border-top-left-radius: ${theme.getTokens().border.radius.base};
    border-top-right-radius: ${theme.getTokens().border.radius.base};
    display: inline-block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: ${theme.color('white')};

    position: relative;

    /* Block off all pointer to storybook iframe, this will cause errors. */
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: all;
    }
  `,
);

export default StoryPreview;
