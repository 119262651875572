import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import TEST_ID from './index.testid';
import LabelEllipsis from './utils/LabelEllipsis';
import { ChartDataPoint } from '../..';
import Link from '~/components/molecule/Link';
import { ANIMATION_CONFIG } from '~/styles/constants';

export type Props<ValueType> = {
  payload?: { index: number; tickCoord: number; value: string };
  x?: number;
  y?: number;
  height?: number;
  width?: number;
  data: Array<ChartDataPoint<ValueType>>;
  visibleTicksCount?: number; // From recharts
  verticalAnchor?: unknown; // From recharts
  tickFormatter?: unknown; // From recharts
};
const Tick = <ValueType,>({
  payload,
  x,
  y,
  width,
  height,
  data,
  visibleTicksCount,
  verticalAnchor,
  tickFormatter,
  ...props
}: Props<ValueType>) => {
  // We do not want to pass along x,y and width. We disable @typescript-eslint/no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _voidedVariables = {
    x,
    y,
    height,
    width,
    visibleTicksCount,
    verticalAnchor,
    tickFormatter,
  };

  const ref = useRef<HTMLDivElement | null>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (ref.current === null) return;

    const { width, height } = ref.current.getBoundingClientRect();
    if (width !== size.width || height !== size.height) {
      setSize(() => ({ width, height }));
    }
  }, [ref, size]);

  const spring = useSpring({
    from: { opacity: 0, transform: 'translate3d(-40px, 0, 0)' },
    to: { opacity: 1, transform: 'translate3d(0px, 0, 0)' },
    config: { ...ANIMATION_CONFIG.config, clamp: true },
  });

  if (!payload) return null;

  const link = data[payload.index].link;

  return (
    <foreignObject
      // foreignObject in Firefox need to have a correct width and height in order to be visible
      width={size.width}
      height={size.height}
      y={payload.tickCoord}
      x={0}
      style={{ overflow: 'visible' }}
      {...props}
    >
      <AlignmentContainer>
        <LabelContainer style={spring} ref={ref}>
          {link !== undefined ? (
            <Link
              to={link}
              dataTestId={`${TEST_ID.TICK}-${data[payload.index].id}`}
              title={payload.value}
            >
              <LabelEllipsis
                value={payload.value}
                totalItemAmount={data.length}
              />
            </Link>
          ) : (
            <LabelEllipsis
              value={payload.value}
              totalItemAmount={data.length}
            />
          )}
        </LabelContainer>
      </AlignmentContainer>
    </foreignObject>
  );
};

const AlignmentContainer = styled.div<{}>`
  display: flex;
  flex: 1;
  flex-basis: 100%;
  align-items: center;
  height: 100%;
  width: 160px;
`;

const LabelContainer = styled(animated.span)`
  &:hover {
    color: ${({ theme }) => theme.color('primary')};
  }
`;

export default Tick;
