import { uniqBy, unnest } from 'ramda';
import { Element, Text } from 'slate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { Client_FlowV2_EmailAttachment } from '~/components/page/Automation/v2/components/UpdateAction/components/types';
import type { EditorValue } from '../../../types';

/**
 * Returns an array with all the images as Email attachments
 * @param {EditorValue} nodes - Slate value
 * keywords: get Images from editor
 */
const getImages = (
  nodes: EditorValue,
): Array<Client_FlowV2_EmailAttachment> => {
  const res = nodes.reduce((acc, node) => {
    if (Element.isElement(node) && node.type === ELEMENTS.DH_IMAGE) {
      const {
        s3key,
        contentLength,
        contentType,
        filename,
        inlineId,
        pending,
        hasError,
      } = node;

      if (
        !s3key ||
        !inlineId ||
        !filename ||
        !contentLength ||
        pending ||
        hasError ||
        !contentType?.startsWith('image')
      ) {
        return acc;
      }

      return [
        ...acc,
        {
          __typename: 'FlowV2_EmailAttachment' as const,
          file: {
            __typename: 'Flow___Argument_File' as const,
            value_file: {
              __typename: 'Flow___File' as const,
              contentLength,
              contentType,
              filename,
              s3key,
            },
          },
          url: node.url,
          inlineId,
        },
      ];
    }

    /** Get images in nested elements */
    if (!Text.isText(node) && node.children) {
      const childNode = unnest(getImages(node.children));

      if (childNode.length > 0) return [...acc, ...childNode];
    }

    return acc;
  }, []);

  return res;
};

/** Make sure that there are no duplicate attachments */
const getImagesAsFlowAttachments = value =>
  uniqBy(item => item.inlineId, getImages(value));

export default getImagesAsFlowAttachments;
