import { NamedEmail } from '~/util/namedEmailFormatter';
import namedEmailFormatter from '~/util/namedEmailFormatter';
import { minElement } from '~/util/array';
import { AUTHORISE_LEVEL } from '~/util/constants';
import { reporter } from '~/hooks/useErrorReporter';
import { OptionOf } from '~/components/molecule/Dropdown';
import type { SendableMailbox } from '~/contexts/util/buildAvailableEmailsToSendFrom';

/**
 * Returns sendableMailboxes for the user as dropdown options
 * @param {Array<SendableMailbox>} availableEmailsToSendFrom - Available emails for the user
 * @param {NamedEmail} initiallySelectedEmail - If there is an email already selected
 * keywords: sendableMailbox, emailDropdown
 */
const getDropdownOptions = (
  availableEmailsToSendFrom: Array<SendableMailbox>,
  initiallySelectedEmail?: NamedEmail,
): {
  options: Array<OptionOf<NamedEmail>>;
  initialSelectedIdx: number | null;
} => {
  const options = availableEmailsToSendFrom.map((availableEmail, idx) =>
    emailAsDropdownOption(availableEmail.namedEmail, idx),
  );

  let initialSelectedIdx: number | null = null;
  if (initiallySelectedEmail) {
    const foundIndex = options.findIndex(
      option => option.payload.email === initiallySelectedEmail.email,
    );

    if (foundIndex >= 0) {
      initialSelectedIdx = foundIndex;
    }
  } else {
    const { index } = minElement(
      availableEmailsToSendFrom,
      (sendableMailbox: SendableMailbox) => {
        switch (sendableMailbox.authoriseLevel) {
          case AUTHORISE_LEVEL.ACCOUNT:
            return 3;
          case AUTHORISE_LEVEL.OFFICE:
            return 2;
          case AUTHORISE_LEVEL.USER:
            return 1;
          default: // Safely continue with any other value
            reporter.captureException(
              new Error(
                `Unknown authorise level ${sendableMailbox.authoriseLevel} found!`,
              ),
            );

            return null;
        }
      },
    );

    initialSelectedIdx = index;
  }

  return { options, initialSelectedIdx };
};

const emailAsDropdownOption = (
  namedEmail: NamedEmail,
  idx: number,
): OptionOf<NamedEmail> => ({
  label: namedEmailFormatter(namedEmail),
  payload: namedEmail,
  key: `${idx}-${namedEmail.email}`,
});

export default getDropdownOptions;
