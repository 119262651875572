import React from 'react';
import styled, { css } from 'styled-components';
import SelectBlock from '~/components/molecule/SelectBlock';
import MasonryGrid from '~/components/organism/MasonryGrid';
import eventTemplates, { EventTemplates } from './eventTemplates';
import TEST_ID from './index.testid';
import type { AppStatus_Zapier_Metadata } from '~/graphql/types';
import useApp from '~/hooks/useApp';

export type Props = {
  selectedTemplates: EventTemplates;
  onChange: (templates: EventTemplates) => void;
};

const EventTemplatesContainer: React.FCC<Props> = ({
  dataTestId,
  onChange,
  selectedTemplates = [],
  ...rest
}) => {
  const { app } = useApp('AppStatus_Zapier');
  const zapierMetadata = (
    app?.appStatus && 'metadata' in app?.appStatus
      ? app?.appStatus.metadata
      : undefined
  ) as AppStatus_Zapier_Metadata | undefined;

  const previouslyEnabledTemplates = zapierMetadata?.setup.addEvents ?? [];

  return (
    <MasonryGridContainer data-testid={dataTestId} {...rest}>
      {eventTemplates.map(template => {
        const wasPreviouslyEnabled = previouslyEnabledTemplates.includes(
          template.id,
        );
        const isEventSelected =
          selectedTemplates.some(selected => selected.id === template.id) ||
          wasPreviouslyEnabled;

        return (
          <SelectBlock
            type="checkbox"
            checked={isEventSelected || wasPreviouslyEnabled}
            disabled={wasPreviouslyEnabled}
            data-checked={isEventSelected}
            checkboxTitle={template.name}
            description={template.description}
            dataTestId={TEST_ID.TEMPLATE}
            key={template.id}
            onClick={() => {
              if (isEventSelected) {
                onChange(
                  selectedTemplates.filter(
                    selectedTrigger => selectedTrigger.id !== template.id,
                  ),
                );
              } else {
                onChange([...selectedTemplates, template]);
              }
            }}
          />
        );
      })}
    </MasonryGridContainer>
  );
};

const MasonryGridContainer = styled(MasonryGrid)<{}>`
  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
    margin-bottom: ${theme.space('base')};
    column-gap: ${theme.space('l')};
  `}
`;

export default EventTemplatesContainer;
