import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import type { OutputMap, StepId } from '~/components/organism/WizardSteps';
import getAppPriceBySetupFlow from '~/components/page/Apps/StellaxAI/utils/getAppPriceBySetupFlow';
import { AppStatus__typename } from '~/graphql/types.client';
import useApp from '~/hooks/useApp';
import { reporter } from '~/hooks/useErrorReporter';
import getPriceWithInterval from '~/util/getPriceWithInterval';

export type Props = {
  typename: AppStatus__typename;
  outputMap: OutputMap;
  stepIDs: Array<StepId>;
};

const text = {
  header: 'Kosten',
};

const AppTypeCosts: React.FCC<Props> = ({
  dataTestId,
  typename,
  outputMap,
  stepIDs,
  ...rest
}) => {
  const { app } = useApp(typename);
  if (!app) {
    reporter.captureException(
      new Error(`Unable to render price for app with typename: "${typename}"`),
    );

    return null;
  }

  const appPrice = getAppPriceBySetupFlow({ app, outputMap, stepIDs });

  return (
    <JustificationContainer
      dataTestId={dataTestId}
      direction="column"
      {...rest}
    >
      <Heading4 variant={Variant.primary}>{text.header}</Heading4>
      <Body>{getPriceWithInterval(appPrice)}</Body>
    </JustificationContainer>
  );
};

export default AppTypeCosts;
