import { reporter } from '~/hooks/useErrorReporter';
import { LabelInfo } from '~/components/page/Automation/v2/util/getLabelledActions';

/**
 * Create a label for the action card
 *
 * Returns labels in this format as the letterIdx increases:
 * A1 ... Z1
 * AA1 ... AZ1
 * ABA1 ... ABZ1
 * ABCA1 ... ABCZ1
 *
 * @param {number} letterIdx - letter index
 * @param {number} number - number to add at the end
 * keywords: card label
 */
const LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; // 26 letters

const getCardLabel = (label?: LabelInfo): string => {
  if (!label) {
    reporter.captureMessage('Label is undefined in `getCardLabel`', 'debug');
    return '';
  }

  const { letterIdx, number } = label;
  const lettersLength = LETTERS.length;

  const letterIndexes: Array<number> = [];
  const addLetterIdx = (l: number, i: number) => {
    if (l - lettersLength >= 0) {
      letterIndexes.push(i);
      addLetterIdx(l - lettersLength, i + 1);
    } else {
      letterIndexes.push(l);
    }
  };

  addLetterIdx(letterIdx, 0);

  let str = '';
  letterIndexes.forEach(i => {
    str += LETTERS[i];
  });

  return str + number;
};

export default getCardLabel;
