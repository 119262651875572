import { isNil } from 'ramda';

const hasNullPrototype = (obj: any) => Object.getPrototypeOf(obj) === null;

/**
 * Clones an object including the prototype
 * @param {object} obj - The object of which you want a true clone
 * keywords: proto, prototype, clone, object
 */
const cloneWithPrototype = <T extends $Object>(obj: T): T => {
  if (isNil(obj) || hasNullPrototype(obj)) return obj;

  // Get descriptors of the prototype object
  const prototypeDescriptors = Object.getOwnPropertyDescriptors(
    Object.getPrototypeOf(obj),
  );

  // Create a clone of the prototype object with the same descriptors
  const protoClone = Object.create(null, prototypeDescriptors);

  // Create a clone of the original object with the prototype chain preserved
  const objClone = Object.create(
    protoClone,
    Object.getOwnPropertyDescriptors(obj),
  );

  return objClone; // Return the cloned object
};

export default cloneWithPrototype;
