import { DATE_FNS_FORMAT, format } from '../date';

export default {
  dateFormat: function (date) {
    return format(date, DATE_FNS_FORMAT);
  },
  ifEquals: function (arg1, arg2, options) {
    return arg1 == arg2 ? options.fn(this) : options.inverse(this);
  },
  lowercase: function (string) {
    return string.toLowerCase();
  },
  eq: function (arg1, arg2, options) {
    return arg1 == arg2 ? options.fn(this) : options.inverse(this);
  },
  in: function (value, ...args) {
    const options = args.pop();

    if (args.includes(value)) {
      return options.fn(this);
    }

    return options.inverse(this);
  },
  isTrigger: function (options) {
    return options.data.root.ctx.type === 'trigger'
      ? options.fn(this)
      : options.inverse(this);
  },
  isCondition: function (options) {
    return options.data.root.ctx.type === 'condition'
      ? options.fn(this)
      : options.inverse(this);
  },
};
