import React from 'react';
import styled, { css } from 'styled-components';
import {
  DhImageElement as DhImageElementType,
  ImageElement as ImageElementType,
} from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';

export type Props = {
  element: DhImageElementType | ImageElementType;
  type: ELEMENTS.DH_IMAGE | ELEMENTS.IMAGE;
  width?: number | string;
  src?: string;
  $loading: boolean;
  $active: boolean;
  $hasError: boolean;
  onDragStart: () => void;
  onDragEnd: () => void;
  style: React.CSSProperties;
};

const ImageElement: React.FCC<Props> = ({
  dataTestId,
  type,
  element,
  style,
  ...rest
}) => {
  const initialAttrs = { ...element.attributes, style };

  if (type === ELEMENTS.DH_IMAGE && 'inlineId' in element)
    return (
      <StyledImage
        {...initialAttrs}
        {...rest}
        data-dhinlineid={element.inlineId}
        data-s3key={element.s3key}
        data-dhcontenttype={element.contentType}
        data-dhfilename={element.filename}
        data-dhcontentlength={element.contentLength}
        data-testid={dataTestId}
      />
    );

  if (type === ELEMENTS.IMAGE) {
    return <StyledImage {...initialAttrs} {...rest} data-testid={dataTestId} />;
  }

  return null;
};

const StyledImage = styled.img<{
  $active: boolean;
  $loading: boolean;
  $hasError: boolean;
}>`
  ${({ $active, $loading, $hasError, theme }) => css`
    box-shadow: ${$active ? '0 0 0 3px #B4D5FF' : 'none'};
    opacity: ${$loading || $hasError ? '40%' : '100%'};

    ${$hasError &&
    css`
      box-shadow: 0 0 0 3px ${theme.color('danger')};
    `}
  `}
`;

export default ImageElement;
