/**
 * This function strips all html tags. It is not opinionated on the
 * output, html tags will just be stripped.
 *
 * E.g. <p>Hallo</p><p>Joe</p> => HalloJoe
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/DOMParser/parseFromString
 */
const stripHtml = (html: string): string =>
  new DOMParser().parseFromString(html, 'text/html').body.textContent ?? '';

export default stripHtml;
