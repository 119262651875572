import React, { useState } from 'react';
import Catalog from '~/Catalog';
import { Body } from '~/components/atom/Typography';
import {
  FlowV2InstanceStatus,
  useGetFlowV2InstancesQuery,
  useStopFlowV2InstanceMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useConfirmModal from '~/hooks/useConfirmModal';
import useErrorReporter from '~/hooks/useErrorReporter';
import formatToastMessage from '~/util/formatToastMessage';
import FlowInstanceList from './components/FlowInstanceList';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Loading from '~/components/atom/Loading';
import AppErrorScreen from '~/components/template/AppErrorScreen';

const text = {
  title: 'Automation',
  confirmModalTitle: 'Bevestiging',
  confirmModalMessage:
    'Weet je zeker dat je deze flow wil stoppen voor dit contact?',
  confirmModalConfirmButtonText: 'Bevestigen',
  noFlowInstances: 'Er zijn geen actieve flows voor dit contact',
};
const confirmModalText = {
  title: text.confirmModalTitle,
  message: text.confirmModalMessage,
  buttonConfirmTitle: text.confirmModalConfirmButtonText,
};

export type Props = {
  contactId: string;
};

const FlowInstances: React.FCC<Props> = ({ contactId }) => {
  const { id: accountId } = useCurrentAccount();

  const addToast = useAddToast();
  const errorReporter = useErrorReporter();

  const { data, loading, error, refetch } = useGetFlowV2InstancesQuery({
    variables: {
      accountId,
      contactId,
      statusFilter: [
        FlowV2InstanceStatus.Running,
        FlowV2InstanceStatus.Waiting,
      ],
    },
  });

  const [stopFlowV2Instance, { loading: stopFlowInstanceLoading }] =
    useStopFlowV2InstanceMutation({
      onCompleted: () => refetch(),
    });

  const [itemId, setItemId] = useState<string | null>(null);
  const { setShowModal, modal: stopFlowModal } = useConfirmModal({
    level: 'warning',
    labels: confirmModalText,
    buttons: [
      {
        label: confirmModalText.buttonConfirmTitle,
        onClick: async () => {
          if (!itemId) {
            return errorReporter.captureException(
              new Error('itemId is null in stopFlowV2Instance'),
              'debug',
            );
          }

          void stopFlowV2Instance({
            variables: {
              accountId,
              id: itemId,
            },
          }).then(({ data, errors }) => {
            if (data) {
              return addToast([
                formatToastMessage(Catalog.genericSuccessMessage, 'success'),
              ]);
            }

            if (errors && errors?.length > 0) {
              return addToast([
                formatToastMessage(
                  Catalog.genericUnknownErrorMessage,
                  'danger',
                ),
              ]);
            }
          });
        },
        loading: stopFlowInstanceLoading,
      },
    ],
    onCancel: () => setItemId(null),
  });

  const flowv2Instances = data?.getFlowV2Instances.items;

  if (loading) return <Loading />;

  if (error || !flowv2Instances) {
    return <AppErrorScreen inline />;
  }

  if (flowv2Instances.length === 0)
    return <Body size="base">{text.noFlowInstances}</Body>;

  return (
    <>
      {stopFlowModal}

      <FlowInstanceList
        value={flowv2Instances}
        onClick={item => {
          setItemId(item.id);
          setShowModal(true);
        }}
      />
    </>
  );
};

export default FlowInstances;
