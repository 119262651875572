import type {
  FormBuilder_NodeFragment,
  FormBuilder_EventNode,
} from '~/graphql/types';

const isEventUsed = (
  nodes: Array<FormBuilder_NodeFragment>,
  eventId: string,
): boolean => {
  const eventNodes = nodes.filter(
    (node): node is FormBuilder_EventNode =>
      node.__typename === 'FormBuilder_EventNode',
  );

  const nodesUsingThisEvent = eventNodes.filter(
    eventNode => eventNode.formBuilderEventId === eventId,
  );

  return nodesUsingThisEvent.length !== 0;
};

export default isEventUsed;
