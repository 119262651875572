import React, { useState } from 'react';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import usePasteAction from '~/components/page/Automation/v2/components/Builder/hooks/usePasteAction';
import dialogs, {
  type DialogSelectIfElsePathOnPaste,
} from '~/components/page/Automation/v2/state/dialogs';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Body, Label, Variant } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TrueOrFalseLabel from '../../../TrueOrFalseLabel';
import { PathType } from '../../../nodeTypes/components/IfElseCard/types';
import TEST_ID from './index.testid';
import RadioButtonWithCustomLabel from '~/components/molecule/RadioButtonWithCustomLabel';
import interactions from '~/components/page/Automation/v2/state/interactions';
import { isNil } from 'ramda';
import ActionHeadingWithLabel from '../../../ActionHeadingWithLabel';
import styled from 'styled-components';
import Catalog from '~/Catalog';

export type Props = {
  dialog: DialogSelectIfElsePathOnPaste;
};

const text = {
  trueChild: 'Waar',
  falseChild: 'Niet waar',
  header: 'Verbind onderstaande acties',
};

const LABEL_WIDTH = '110px';

const PasteIfElseDialog: React.FCC<Props> = ({ dialog }) => {
  const setDialog = useSetRecoilState(dialogs);
  const [path, setPath] = useState<PathType | null>(null);

  const { onConfirm } = usePasteAction({
    source: dialog.source,
    target: dialog.target,
  });

  const interaction = useRecoilValue(interactions);
  const copiedAction =
    interaction?.type === 'copyAction' ? interaction.copiedAction : null;

  if (isNil(copiedAction)) return null;

  return (
    <Overlay onClose={() => setDialog(null)}>
      <Dialog
        header={text.header}
        body={
          <JustificationContainer direction="column" gap="m">
            <Body lineHeight="l">
              De
              <LabelContainer
                border={{
                  width: 's',
                  color: { group: 'tertiary', variant: 'light' },
                  radius: 's',
                }}
                align="center"
                padding={[null, 'xxs', null, null]}
                margin={[null, 'xxs']}
              >
                <ActionHeadingWithLabel action={copiedAction} />
              </LabelContainer>
              actie bevat een&nbsp;
              <Label size="m" inline variant={Variant.primary}>
                {text.trueChild}
              </Label>
              &nbsp; en&nbsp;
              <Label size="m" inline variant={Variant.primary}>
                {text.falseChild}
              </Label>
              &nbsp; pad. Aan welk pad wil je onderstaande acties verbinden?
            </Body>

            <RadioButtonWithCustomLabel
              checked={path === PathType.True}
              onChange={() => setPath(PathType.True)}
              label={
                <TrueOrFalseLabel
                  type="true"
                  minWidth={LABEL_WIDTH}
                  onClick={() => setPath(PathType.True)}
                />
              }
              dataTestId="true-path"
            />

            <RadioButtonWithCustomLabel
              checked={path === PathType.False}
              onChange={() => setPath(PathType.False)}
              label={
                <TrueOrFalseLabel
                  type="false"
                  minWidth={LABEL_WIDTH}
                  onClick={() => setPath(PathType.False)}
                />
              }
              dataTestId="false-path"
            />
          </JustificationContainer>
        }
        buttons={[
          {
            dataTestId: 'modal-confirm-button',
            label: Catalog.confirm,
            onClick: () => {
              if (path && dialog?.type === 'dialogSelectIfElsePathOnPaste')
                onConfirm({
                  path,
                  newAction: dialog?.newAction,
                });

              setDialog(null);
            },
          },
        ]}
        dataTestId={TEST_ID.CONTAINER}
      />
    </Overlay>
  );
};

const LabelContainer = styled(JustificationContainer)<{}>`
  display: inline-flex;
`;

export default PasteIfElseDialog;
