import { AppType } from '~/graphql/types';
import type { AppConfig } from '../types';

const description = `## Omschrijving
Trustoo is een van de meest toonaangevende portals voor het vinden van dienstverleners. Dagelijks gebruiken duizenden consumenten Trustoo om in contact te komen met een dienstverlener.

Deze app biedt twee mogelijkheden.
  1. Begeleid jouw klant of websitebezoeker naar een passende dienstverlener.

Voeg in jouw widget dienstverleners toe zodat bezoekers van jouw website offertes kunnen opvragen en zo dienstverleners kunnen vergelijken. In een paar klikken vraag je bij aannemers, loodgieters, schilders en verhuizers offertes op. Dit kan ook bij isolatie-experts en zonnepaneel-installateurs, belangrijk bij het verduurzamen.

Voor iedere gegenereerde lead wordt een commissie uitbetaald. [Bekijk de commissies hier.](https://drive.google.com/file/d/14SKXCUqcWgEdn4phwPqMgIUgidWahlyz/view)

  2. Laat leads die je ontvangt via Trustoo.nl binnenkomen in je DatHuis account.

Deze koppeling zorgt ervoor dat al je Trustoo leads in jouw DatHuis account binnenkomen en automatisch worden opgevolgd. Zo mis je geen enkele lead. Daarnaast stuur je leads makkelijk door naar Realworks of ieder ander CRM via onze Zapier integratie.
`;

const termsOfUse = `Door het activeren van deze app geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van Trustoo. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Op de dienstverlening van Trustoo zijn de gebruiksvoorwaarden van Trustoo van toepassing. meer informatie vind je op [https://www.trustoo.nl](https://www.trustoo.nl).`;

export const trustoo: AppConfig = {
  __typename: 'AppStatus_Trustoo',
  appType: AppType.Trustoo,
  type: 'with_config',
  slug: 'trustoo',
  name: 'Trustoo',
  tags: ['Leadgeneratie', 'E-mailcampagne', 'Commissie ontvangen'],
  provider: 'Trustoo',
  logo: {
    src: 'https://dathuis-stash.imgix.net/trustoo.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/trustoo.svg',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/6435229-over-de-trustoo-app',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'custom',
};
