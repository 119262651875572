import cleanedFilename from '~/util/cleanedFilename';

export default {
  CC_ROW: `${cleanedFilename(__filename)}-cc-row`,
  BCC_ROW: `${cleanedFilename(__filename)}-bcc-row`,
  ADD_CC_BUTTON: `${cleanedFilename(__filename)}-add-cc`,
  ADD_BCC_BUTTON: `${cleanedFilename(__filename)}-add-bcc`,
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  RECEIVER: `${cleanedFilename(__filename)}-receiver`,
  SUBJECT: `${cleanedFilename(__filename)}-subject`,
};
