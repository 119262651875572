import { useEffect } from 'react';
import { useKeyPress } from 'reactflow';
import { useRecoilState } from 'recoil';
import interactions from '~/components/page/Automation/v2/state/interactions';

const useBuilderBindings = () => {
  const [activeInteraction, setActiveInteraction] =
    useRecoilState(interactions);

  const escKeyPressed = useKeyPress('Escape');

  useEffect(() => {
    if (escKeyPressed) {
      if (
        activeInteraction?.type === 'connectAction' ||
        activeInteraction?.type === 'copyAction'
      )
        setActiveInteraction(null);
    }
  }, [escKeyPressed, activeInteraction?.type, setActiveInteraction]);
};

export default useBuilderBindings;
