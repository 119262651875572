import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from '~/components/molecule/Link';
import { Helmet as MetaTags } from 'react-helmet';

import type { ImgDefinition } from '~/graphql/types.client.flow';
import { OfficeInList } from '~/components/page/Settings/Profile/components/FormContainer';
import UserDetailsForm from '~/components/organism/UserDetailsForm';
import Button from '~/components/atom/Button';
import cleanedFilename from '~/util/cleanedFilename';
import ContentContainerDefault from '~/components/molecule/ContentContainer';
import DetailsActionList from '~/components/page/DetailsPage/DetailsActionList';
import {
  ActionButtons,
  Header,
  SectionContainer,
} from '~/components/page/Settings/Profile/Profile.style';
import { convertOfficeInListToListItemForDeletion } from '~/components/page/Settings/Users/utils';
import {
  Signature,
  useUpdateUserMutation,
  type NotificationSettingsFieldsFragment,
} from '~/graphql/types';
import SignatureContainer from '~/components/organism/SignatureContainer';
import useCurrentUser from '~/hooks/useCurrentUser';
import EmailNotificationsBlock, {
  EmailNotificationsFormData,
} from '~/components/organism/EmailNotificationsBlock';
import { emailNotificationsFormDataToMutation } from '~/components/organism/EmailNotificationsBlock/util';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import useUserRights from '~/hooks/useUserRights';
import UserProfileHeader from '~/components/organism/ProfileHeader/components/UserProfileHeader';
import createPageTitle from '~/util/createPageTitle';
import type { ListItemForDeletion } from '../components/DeleteEntityModals/components/ListItemCard';
import DeleteUserFromAccountModal from '../components/DeleteEntityModals/DeleteUserFromAccount';

type Props = {
  user: {
    name: string;
    id: string;
    img?: ImgDefinition | null | undefined;
  };
  officesList: Array<OfficeInList>;
  initialValue: { email: string; phone: string };
  refetch: () => Promise<any>;
  signature: Signature | null;
  notificationSettings?: NotificationSettingsFieldsFragment;
};

const text = {
  pageTitle: 'Gebruiker',
  editYourProfile: 'Wijzig je gegevens',
  userListBreadcrumbTitle: 'Gebruikerslijst',
  deleteUser: 'Verwijder gebruiker',
  signature: 'Handtekening gebruiker',
};

const UserDetailsComponent: React.FCC<Props> = ({
  user,
  initialValue,
  officesList,
  refetch,
  signature,
  notificationSettings,
}) => {
  const { update: mayUpdate, delete: mayDelete } = useUserRights({
    category: 'Users',
  });

  const { id: accountId } = useCurrentAccount();
  const me = useCurrentUser();
  const addToast = useAddToast();

  const isItMe = user.id === me.id;
  const mayEdit = isItMe || mayUpdate;

  const [showDeleteUserFromAccountModal, setShowDeleteUserFromAccountModal] =
    useState<Array<ListItemForDeletion> | null>(null);

  const [updateUser] = useUpdateUserMutation({
    onCompleted: _update => {
      if (_update) return refetch();
      return;
    },
  });

  const onFormSubmit = (newFormData: EmailNotificationsFormData) =>
    updateUser({
      variables: {
        accountId,
        userId: user.id,
        update: emailNotificationsFormDataToMutation(newFormData),
      },
    }).then(({ errors }) => {
      if (errors && errors.length !== 0) {
        addToast([
          formatToastMessage(
            'Er is iets fout gegaan bij het updaten van notificatie instellingen, probeer het later opnieuw.',
            'danger',
          ),
        ]);
        return;
      }
    });

  const handleDeleteUserFromAccountModalAction = (
    officesList: Array<ListItemForDeletion> | null,
  ) => {
    setShowDeleteUserFromAccountModal(officesList);
  };

  const offices = officesList.map(convertOfficeInListToListItemForDeletion);

  return (
    <ContentContainerDefault
      data-testid="user-detail-page"
      breadcrumbs={[
        {
          to: '/-/settings/users',
          label: text.userListBreadcrumbTitle,
        },
        {
          label: user.name,
        },
      ]}
    >
      <MetaTags>
        <title>{createPageTitle(user.name)}</title>
      </MetaTags>
      <Header>
        <UserProfileHeader
          edit={isItMe}
          user={user}
          refetchUser={() => {}}
          onImageUploadError={() => {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | onImageUploadError was called`,
            );
          }}
          updateMyName={() => {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | updateMyName was called`,
            );
          }}
        />

        {isItMe && (
          <ProfileLink to={'/-/settings/profile'}>
            <Button
              ghost
              onClick={() => {}}
              data-testid="edit-profile-link"
              label={text.editYourProfile}
            />
          </ProfileLink>
        )}

        {mayDelete && (
          <ActionButtons>
            <DetailsActionList
              options={[
                {
                  label: text.deleteUser,
                  onClick: () => {
                    handleDeleteUserFromAccountModalAction(offices);
                  },
                  type: 'DELETE',
                  key: 'delete-user',
                },
              ]}
            />
          </ActionButtons>
        )}
      </Header>

      <SectionContainer>
        <div>
          <UserDetailsForm
            officesList={officesList}
            readOnly
            userId={user.id}
            initialValues={initialValue}
          />
          <SignatureContainer
            title={text.signature}
            signature={signature}
            entityTypeForMutation={{ userId: user.id }}
            refetch={refetch}
            readOnly={!mayEdit}
          />
        </div>

        {mayEdit && notificationSettings && (
          <EmailNotificationsBlock
            onFormSubmit={onFormSubmit}
            notificationSettings={notificationSettings}
          />
        )}
      </SectionContainer>

      {showDeleteUserFromAccountModal && (
        <DeleteUserFromAccountModal
          list={showDeleteUserFromAccountModal}
          onCancel={() => handleDeleteUserFromAccountModalAction(null)}
          onClose={() => handleDeleteUserFromAccountModalAction(null)}
          userId={user.id}
        />
      )}
    </ContentContainerDefault>
  );
};

const ProfileLink = styled(Link)<{}>(
  ({ theme }) => css`
    margin-left: auto;
    order: 2;
    text-decoration: none;

    ${theme.mq.lessThan('tabletLandscape')`
       order: 2;
    `}

    button {
      width: max-content;
    }
  `,
);

export default UserDetailsComponent;
