import getAppsGroupLookup from '~/components/page/Widget/utils/getAppsGroupLookup';
import getPinnedApps from '~/components/page/Widget/utils/getPinnedApps';
import type { GetWidgetSettingsQuery } from '~/graphql/types';
import type { WidgetValues } from '../../components/WidgetContent';

const dataToWidgetValues = ({
  data,
}: {
  data: GetWidgetSettingsQuery['getWidgetSettings'];
}): WidgetValues => {
  const defaultGroups = getAppsGroupLookup(data.appsConfiguration);
  const pinnedApps = getPinnedApps(data.appsConfiguration);

  const widgetValues: WidgetValues = {
    settings: {
      googleAnalytics4: data.googleAnalytics4,
    },
    design: {
      style: data.style,
      positions: data.positions,
      appearances: data.appearances,
      zIndex: data.zIndex,
      logo: data.logo,
      highlightImage: data.highlightImage,
    },
    apps: {
      appsGroupLookup: defaultGroups,
      pinnedApps,
    },
  };

  return widgetValues;
};

export default dataToWidgetValues;
