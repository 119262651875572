import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4 } from '~/components/atom/Typography';
import Toolbar from '~/components/molecule/Toolbar';
import Button from '~/components/atom/Button';
import TEST_ID from './index.testid';

const text = {
  stopCopying: 'Klaar met kopiëren',
  cancel: 'Annuleren',
};

export type Props = {
  /** Are there copy changes */
  hasChanges: boolean;

  /** Label on the left side of the copied item label */
  label: string;

  /** Name for the item that is copied */
  copiedItemLabel: React.ReactNode;

  /** Called when copying is stopped */
  onClose: () => void;

  /** Called when copying is cancelled */
  onCancel: () => void;
};

const CopyStateToolbar: React.FCC<Props> = ({
  hasChanges,
  label,
  copiedItemLabel,
  onClose,
  onCancel,
  ...rest
}) => (
  <StyledToolbar dataTestId={TEST_ID.CONTAINER} {...rest}>
    <JustificationContainer width="100%" align="end" gap="m" padding={['base']}>
      <Heading4
        margin={[null]}
        size="m"
        color={{ group: 'primary', variant: 'light' }}
      >
        {label}
      </Heading4>

      {copiedItemLabel}
    </JustificationContainer>

    <Button
      size="medium"
      label={text.cancel}
      ghost
      margin={[null, 'base', null, null]}
      onClick={onCancel}
      disabled={!hasChanges}
      dataTestId={TEST_ID.STOP_COPYING_BUTTON}
    />
    <Button size="medium" label={text.stopCopying} onClick={onClose} />
  </StyledToolbar>
);

const StyledToolbar = styled(Toolbar)(
  ({ theme }) => css`
    border: 2px solid ${theme.color('primary', 'light')};
  `,
);

export default CopyStateToolbar;
