import React, { useState } from 'react';
import Loading from '~/components/atom/Loading';
import JustificationContainer, {
  Props as JustificationProps,
} from '~/components/atom/JustificationContainer';
import { Body } from '~/components/atom/Typography';
import { SystemSize } from '~/theme';
import { animated, useSpring } from 'react-spring';
import { ANIMATION_CONFIG } from '~/styles/constants';

export type Props = {
  tips: Array<string>;
} & JustificationProps;

const LoadingWithDynamicTips: React.FCC<Props> = React.memo(
  ({
    dataTestId,
    tips,
    margin = ['base', null] as Array<SystemSize | null>,
    ...rest
  }) => {
    const [currentTip, setCurrentTip] = useState(0);

    const props = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      reset: true,
      config: ANIMATION_CONFIG.config,
      onRest: () => {
        setTimeout(() => {
          setCurrentTip(prevTip => (prevTip + 1) % tips.length);
        }, 2000);
      },
    });

    return (
      <JustificationContainer
        {...rest}
        margin={margin}
        width="100%"
        direction="column"
        align="center"
        gap="xl"
        data-testid={dataTestId}
      >
        <Loading />
        <animated.div style={props}>
          <Body size="m" color={{ group: 'primary' }} fontWeight="semiBold">
            {tips[currentTip]}
          </Body>
        </animated.div>
      </JustificationContainer>
    );
  },
);

export default LoadingWithDynamicTips;
