import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from '@gatsbyjs/reach-router';

import TEST_ID from './index.testid';
import Icon from '~/components/atom/Icon';

type LinkProps = {
  link: string;
};
type Props = {
  link: LinkProps;
};
const HelpButton: React.FCC<Props> = ({ link, ...rest }) => (
  <StyledQuestionMark
    name="question-mark"
    {...rest}
    data-testid={TEST_ID.BUTTON}
    onClick={() => {
      const linkToGoTo = link.link;

      if (linkToGoTo.startsWith('http')) {
        global.window.open(linkToGoTo, '_blank');
      } else {
        void navigate(linkToGoTo);
      }
    }}
  />
);

const StyledQuestionMark = styled(Icon)<{}>`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
  `};
`;

export default HelpButton;
