import React, { useState } from 'react';
import Button from '~/components/atom/Button';
import { Props as WizardProps } from '../../index';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import useWizard from '~/hooks/useWizard';
import { OutputMap } from '~/components/organism/WizardSteps';
import TEST_ID from './index.testid';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import Loading from '~/components/atom/Loading';

export type Props = {
  completeButton?: WizardProps['completeButton'];
  onComplete?: () => void;
  outputMap: OutputMap;
  canCloseWizard?: boolean;
};

const text = {
  defaultCompleteLabel: 'Voltooien',
  defaultNextLabel: 'Volgende stap',
  defaultPreviousLabel: 'Ga een stap terug',
  defaultCancelLabel: 'Afbreken',
  skipThisStep: 'Sla deze stap over',
};

const NavigationControls: React.FCC<Props> = ({
  dataTestId,
  completeButton = {},
  onComplete,
  outputMap,
  canCloseWizard,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);
  const wizard = useWizard();
  const step = wizard.getCurrentStep();
  const addToast = useAddToast();

  const onBeforeNext = async e => {
    e.preventDefault();
    if (step && step.options && step.options.onBeforeNext) {
      try {
        setLoading(true);
        const stepOutput = await step.options.onBeforeNext(outputMap);
        // Update the output map with the returned value
        const nextOutputMap = { ...outputMap, [step.id]: stepOutput };

        wizard.setOutput(nextOutputMap);
        return Promise.resolve(outputMap);
      } catch (error) {
        if ('message' in error) {
          addToast([
            formatToastMessage(
              <div>
                {error.message} <br />
                <ChatLink color={{ group: 'white' }} />
              </div>,
              'danger',
            ),
          ]);
        }
        // Don't move to next
        return Promise.reject(error);
      } finally {
        setLoading(false);
      }
    }
    return Promise.resolve(outputMap);
  };

  if (!step) return <Loading />;

  return (
    <JustificationContainer
      justification="space-between"
      margin={['m', null, null, null]}
      width="100%"
    >
      <JustificationContainer align="center">
        {canCloseWizard === true && (
          <TextButton
            size="medium"
            type="button"
            style={{ marginLeft: '-0.8rem' }}
            appearance="danger"
            dataTestId={TEST_ID.CANCEL_BUTTON}
            label={
              step.cancelButton && step.cancelButton.label
                ? step.cancelButton.label
                : text.defaultCancelLabel
            }
            onClick={e => {
              e.preventDefault();
              wizard.hide();
            }}
          />
        )}
        {wizard.hasPrevious() && step.canGoBack !== false && (
          <TextButton
            size="medium"
            type="button"
            icon="arrowLeft"
            appearance="primary"
            dataTestId={TEST_ID.PREVIOUS_BUTTON}
            onClick={async e => {
              e.preventDefault();
              if (step.options?.onBeforePrevious) {
                await step.options.onBeforePrevious(outputMap);
              }
              wizard.previous();
            }}
            {...{
              label: text.defaultPreviousLabel,
              ...step.previousButton,
            }}
          />
        )}
      </JustificationContainer>
      <JustificationContainer
        data-testid={dataTestId}
        gap="base"
        {...rest}
        align="center"
      >
        {wizard.hasNext() && step.skippable === true && (
          <TextButton
            size="medium"
            type="button"
            appearance="primary"
            dataTestId={TEST_ID.PREVIOUS_BUTTON}
            onClick={async e => {
              e.preventDefault();
              wizard.next();
            }}
            label={text.skipThisStep}
          />
        )}
        {wizard.hasNext() ? (
          <Button
            direction="rtl"
            icon="arrowRight"
            size="medium"
            loading={loading ? loading : undefined}
            type="button"
            appearance="primary"
            disabled={step.isFree !== true}
            dataTestId={TEST_ID.NEXT_BUTTON}
            onClick={async e => {
              try {
                await onBeforeNext(e).then(() => {
                  wizard.next();
                });
              } catch {
                // We don't do anything in here, everything is handled inside onBeforeNext fn
              }
            }}
            {...{
              label: text.defaultNextLabel,
              ...step.nextButton,
            }}
          />
        ) : (
          <Button
            size="medium"
            type="button"
            appearance="secondary"
            dataTestId={TEST_ID.COMPLETE_BUTTON}
            disabled={step.isFree !== true}
            onClick={async e => {
              await onBeforeNext(e).then(() => {
                wizard.hide();
                return wizard.clear();
              });
              if (onComplete) onComplete();
            }}
            {...{
              label: text.defaultCompleteLabel,
              icon: 'check',
              ...completeButton,
            }}
          />
        )}
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default NavigationControls;
