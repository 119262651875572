export const paths = [
  '/apps/arx-groep',
  '/apps/bb-wc',
  '/apps/m-wc',
  '/apps/kidwk',
  '/apps/looptijdrente',
  '/apps/max-mortgage',
  '/apps/savings-check',
  '/apps/sus-report',
  '/apps/trustoo-aannemer',
  '/apps/trustoo-airco-installateur',
  '/apps/trustoo-badkamer-renovatie',
  '/apps/trustoo-cv-verwarmingsinstallateur',
  '/apps/trustoo-dakdekker',
  '/apps/trustoo-elektricien',
  '/apps/trustoo-financieel-adviseur',
  '/apps/trustoo-fotograaf',
  '/apps/trustoo-gevelreiniging',
  '/apps/trustoo-gevelrenovatie',
  '/apps/trustoo-glaszetter',
  '/apps/trustoo-hovenier',
  '/apps/trustoo-hypotheekadviseur',
  '/apps/trustoo-isolatie',
  '/apps/trustoo-keukenrenovatie',
  '/apps/trustoo-kozijnen',
  '/apps/trustoo-loodgieter',
  '/apps/trustoo-notaris',
  '/apps/trustoo-ongediertebestrijder',
  '/apps/trustoo-schilder',
  '/apps/trustoo-schoonmaakbedrijf',
  '/apps/trustoo-stukadoor',
  '/apps/trustoo-timmerman',
  '/apps/trustoo-verhuisbedrijf',
  '/apps/trustoo-vloerlegger',
  '/apps/trustoo-vochtbestrijding',
  '/apps/trustoo-zonnepanelen',
  '/apps/trustoo-zonwerin',
  '/apps/Homekeur-energy-energyLabel',
  '/apps/Homekeur-energy-bouwkundige-keuring',
  '/apps/kadastrale-kaart-met-maatvoering',
  '/apps/vergunningen',
  '/apps/luchtfoto-historie',
  '/apps/kadastrale-kaart-omgevingskaart-a4',
  '/apps/stellax-ai-report',
];

export const groups = [
  { id: 'group:house-valuation', name: 'House valuation' },
];
