import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { ToolbarComponent } from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons';
import { TOOLBAR_DIVIDER } from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons/buttonDefinitions';

export const RICH_TEXT_EDITOR_BUTTONS: Array<ToolbarComponent> = [
  { type: 'button', name: 'bold' },
  { type: 'button', name: 'italic' },
  TOOLBAR_DIVIDER,
  { type: 'button', name: 'typography' },
  { type: 'button', name: 'link' },
  TOOLBAR_DIVIDER,
  { type: 'button', name: 'undo' },
  { type: 'button', name: 'redo' },
];

export const SUPPORTED_ELEMENTS = [
  ELEMENTS.H1,
  ELEMENTS.H2,
  ELEMENTS.H3,
  ELEMENTS.H4,
  ELEMENTS.H5,
  ELEMENTS.H6,
];
