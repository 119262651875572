import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import Color from 'color';
import BaseButton from '../components/Base';
import { tooltipText } from '../text';
import { IconType } from '~/components/atom/Icon';
import { setTextStyle } from '~/components/organism/PluginsEditor/commands/modify/text';
import ResponsivePopup from '~/components/molecule/ResponsivePopup';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import { getElementWithType } from '~/components/organism/PluginsEditor/commands';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';

export type Props = {
  format: 'color' | 'backgroundColor';
  icon: IconType;
  editor: DHEditor;
};

type RGBA = { alpha?: number | undefined } & { [key: string]: number };

const DEFAULT_COLOR = { r: 221, g: 221, b: 221 };

const ColorButton: React.FCC<Props> = ({
  dataTestId,
  editor,
  icon,
  format,
}) => {
  const [toggle, setToggle] = useState(false);
  const selectedSpan = getElementWithType({
    editor,
    type: ELEMENTS.SPAN,
    mode: 'lowest',
  });

  const [initialColor, setInitialColor] = useState<RGBA | null>(null);
  const [updatedColor, setUpdatedColor] = useState<RGBA | null>(null);

  const baseButtonRef = useRef(null);

  const updateInitialColor = () => {
    if (selectedSpan?.element[format]) {
      const spanColor = selectedSpan.element[format];
      const activeColor = spanColor?.startsWith('rgb')
        ? Color(spanColor).object()
        : Color(spanColor).rgb().object();

      setInitialColor(activeColor);
    } else {
      setInitialColor(null);
      setUpdatedColor(null);
    }
  };
  return (
    <Container data-testid={dataTestId}>
      <BaseButton
        icon={icon}
        onClick={() => {
          setToggle(!toggle);
          updateInitialColor();
        }}
        tooltipMessage={toggle ? '' : tooltipText[format]}
        ref={baseButtonRef}
      />

      <ResponsivePopup
        onClickOutside={() => {
          setToggle(false);
        }}
        isVisible={toggle}
        elementToPlaceUnder={baseButtonRef.current}
      >
        <ChromePicker
          color={updatedColor || initialColor || DEFAULT_COLOR}
          onChange={color => setUpdatedColor(color.rgb)}
          onChangeComplete={color => {
            const newColor = sanitiseColor(Color(color.hex).hex());
            setTextStyle(editor, format, newColor);
          }}
          data-testid="color-picker-element"
          disableAlpha
        />
      </ResponsivePopup>
    </Container>
  );
};

const sanitiseColor = (color: string): string => color.toLocaleLowerCase();

const Container = styled.div<{}>`
  position: relative;
`;

export default ColorButton;
