import React from 'react';
import { isNil } from 'ramda';
import styled, { css } from 'styled-components';
import Link, { Props as LinkProps } from '~/components/molecule/Link';

export type Props = {
  dataTestId?: string;
};

const Row: React.FCC<Props> = ({ dataTestId, children, ...rest }) => (
  <StyledLink data-testid={dataTestId} {...rest}>
    {children}
  </StyledLink>
);

type StyledTrProps = LinkProps & {
  $isSelected: boolean;
  $isDeleted: boolean;
};

const StyledLink: React.FCC<Props> = styled(Link)<StyledTrProps>(
  ({ theme, $isSelected, $isDeleted, to }) => css`
    display: flex;
    cursor: ${isNil(to) ? 'default' : 'pointer'};
    padding: ${theme.space('s')} ${theme.space('m')};
    background-color: ${$isSelected
      ? theme.color('white', 'dark')
      : theme.color('white')};
    font-weight: ${$isSelected
      ? theme.fontWeight('medium')
      : theme.fontWeight('regular')};

    border-top: 1px solid ${theme.color('tertiary')};
    height: ${$isDeleted ? 0 : 'auto'};
    overflow-y: ${$isDeleted ? 'hidden' : 'unset'};

    width: 100%;
    text-decoration: none;
    color: inherit;

    &:hover {
      background-color: ${theme.color('tertiary', 'light')};
    }

    &:first-of-type {
      border-top: none;
    }

    &:last-of-type {
      border-bottom-left-radius: ${theme.getTokens().border.radius.m};
      border-bottom-right-radius: ${theme.getTokens().border.radius.m};
    }
  `,
);

export default Row;
