import { useContext } from 'react';
import { MarkerIoContext } from '~/contexts/MarkerIOContext';

export const useMarkerIo = () => {
  const context = useContext(MarkerIoContext);

  if (!context) {
    throw new Error('useMarkerIo must be used within a MarkerIoProvider');
  }

  return context.widget;
};

export default useMarkerIo;
