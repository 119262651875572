import { useQuery } from '@apollo/client';
import useStrapiClient from '~/hooks/useStrapiClient';
import { StrapiPage } from '../../types';
import QUERY from './query';

const useStrapiPage = (slug: string) => {
  const client = useStrapiClient();
  const { loading, data } = useQuery(QUERY, {
    client,
    variables: {
      slug,
    },
  });

  if (loading || data?.pages?.data?.length === 0) return null;

  return data.pages.data[0] as StrapiPage;
};

export default useStrapiPage;
