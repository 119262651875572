export type NamedEmail = {
  name?: string | null | undefined;
  email: string;
  emailToShow?: string;
};

const namedEmailFormatter = (namedEmail: NamedEmail): string => {
  const { name, email, emailToShow } = namedEmail;

  const visibleEmail = emailToShow ? emailToShow : email;

  if (name) {
    return `${name} (${visibleEmail})`;
  }

  return visibleEmail;
};

export default namedEmailFormatter;
