import { useEffect, useRef, type MouseEventHandler } from 'react';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';

export const tooltipLayerState = atom<LayerState>({
  key: 'tooltipLayerState',
  default: {
    mousePosition: null,
    tooltip: null,
  },
});

type LocalMouseEvent = MouseEventHandler<HTMLElement>;

type TooltipPosition = {
  x: number;
  y: number;
  clientX: number;
  clientY: number;
};

type LayerState = {
  mousePosition: TooltipPosition | null;
  tooltip: {
    message: string | null | React.ReactNode;
  } | null;
};

const useTooltipLayer = ({
  tooltipMessage,
}: {
  /** In some places that this hook is used the tooltip message might be optional */
  tooltipMessage?: React.ReactNode;
}): {
  onMouseMove: LocalMouseEvent;
  onMouseLeave: LocalMouseEvent;
} => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [state, setState] = useRecoilState(tooltipLayerState);
  const reset = useResetRecoilState(tooltipLayerState);

  useEffect(() => {
    const onReset = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      reset();
    };

    global.window.addEventListener('resize', onReset);
    global.window.addEventListener('scroll', onReset);
    return () => {
      global.window.removeEventListener('resize', onReset);
      global.window.removeEventListener('scroll', onReset);
      onReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMouseMove: LocalMouseEvent = e => {
    if (!tooltipMessage) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setState({
        tooltip: { message: tooltipMessage },
        mousePosition: {
          x: e.pageX,
          y: e.pageY,
          clientX: e.clientX,
          clientY: e.clientY,
        },
      });
    }, 300);
  };

  const onMouseLeave: LocalMouseEvent = () => {
    if (!tooltipMessage) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (state.tooltip) reset();
  };

  return {
    onMouseMove,
    onMouseLeave,
  };
};

export default useTooltipLayer;
