import { ListItemElement } from '~/components/organism/PluginsEditor/types';
import getUpdatedBlockAttrs from '~/components/organism/PluginsEditor/utils/serialize/getUpdatedBlockAttrs';

/**
 * serializes the ListItem element into html element
 * @param {ListItemElement} node - ListItem element
 */
const serialize = (node: ListItemElement, children: string): string => {
  const attrs = getUpdatedBlockAttrs(node);

  return `<li${attrs ? ` ${attrs}` : ''}>${children}</li>`;
};

export default serialize;
