import React, { useState } from 'react';
import { UserRole } from '~/graphql/types';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import useUserOptions from '~/hooks/useUserOptions';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useConfirmModal from '~/hooks/useConfirmModal';
import { isNil } from 'ramda';
import useErrorReporter from '~/hooks/useErrorReporter';

type Props = {
  handleToggleDrawer: (open: boolean) => void;
  updateUserRole: (arg0: { role: UserRole; userId: string }) => void;
  isAdmin: boolean;
  loading: boolean;
};

const text = {
  inputLabel: 'Gebruiker',
  linkButton: 'Administrator toevoegen',
  h3: 'Account administrators',
  modalTitle: 'Administrator verwijderen',
  modalDescription:
    'Weet je het zeker dat je deze gebruiker geen beheerdersrechten meer wilt geven?',
  confirmButtonTitle: 'Bevestigen',
};

const AccountAdministrators: React.FC<Props> = ({
  handleToggleDrawer,
  updateUserRole,
  isAdmin,
  loading,
}) => {
  const errorReporter = useErrorReporter();
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const { setShowModal, modal } = useConfirmModal({
    labels: {
      title: text.modalTitle,
      message: text.modalDescription,
    },
    buttons: [
      {
        label: text.confirmButtonTitle,
        onClick: () => {
          if (isNil(selectedId)) {
            return errorReporter.captureException(
              new Error('selectedId is null in update account role'),
            );
          }

          updateUserRole({
            role: UserRole.User,
            userId: selectedId,
          });
        },
      },
    ],
  });

  const admins = useUserOptions().filter(
    user => user.payload?.role === UserRole.Admin,
  );

  const showDeleteButton = isAdmin && admins.length > 1;

  return (
    <>
      {modal}
      <AnimatedBlock title={text.h3} dataTestId="admin-user-container">
        {admins.map(admin => (
          <JustificationContainer
            key={admin.payload?.id}
            align="end"
            width="100%"
            margin={['m', null]}
            gap="l"
          >
            <Input
              width="100%"
              size="large"
              label={{ text: text.inputLabel }}
              type="text"
              disabled={true}
              // We are sure that the label here is the name of the user
              value={admin.label as string}
              data-testid={`admin-input-${admin.payload?.id}`}
              name={`admin-input-${admin.label}`}
            />
            {showDeleteButton && (
              <Button
                ghost
                appearance="danger"
                icon="trashcan"
                size="large"
                dataTestId={`admin-user-delete-button-${admin.payload?.id}`}
                disabled={loading}
                onClick={() => {
                  setSelectedId(admin.payload?.id || null);
                  setShowModal(true);
                }}
              />
            )}
          </JustificationContainer>
        ))}
        {isAdmin && (
          <TextButton
            margin={['l', null, null, null]}
            padding={[null]}
            size="medium"
            dataTestId="add-new-admin-link"
            disabled={loading}
            onClick={() => handleToggleDrawer(true)}
            label={text.linkButton}
          />
        )}
      </AnimatedBlock>
    </>
  );
};

export default AccountAdministrators;
