import { WidgetSettingsAppConfigurationItem } from '~/graphql/types';
import { AppStatus } from '~/graphql/types.client';

/**
 * Returns app items if AppStatus has umbrella widget app configuration items
 * @param {AppStatus} appStatus
 * keywords: umbrellaWidgetAppConfiguration, appStatus
 */
const getUWAppConfigurationItems = (
  appStatus?: AppStatus,
): Array<WidgetSettingsAppConfigurationItem> => {
  if (!appStatus) return [];

  if ('widgetAppConfigurationItems' in appStatus) {
    const { widgetAppConfigurationItems } = appStatus;
    if (widgetAppConfigurationItems?.length) return widgetAppConfigurationItems;
  }

  return [];
};

export default getUWAppConfigurationItems;
