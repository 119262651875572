import type { SubjectMap } from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import {
  FlowData___DirectoryFragment,
  FlowData___FlowInstanceFragment,
} from '~/graphql/types';
import { Flow___ConditionPartial } from '~/components/page/Automation/v2/components/UpdateAction/components/ConditionEditorV2';

import getSubject from '~/components/page/Automation/v2/components/UpdateAction/components/Selector/utils/getSubject';
import {} from '~/graphql/types.client';
import generateInstanceV2 from '../generateInstanceV2';

type ExtractInstanceForCondition_Args = {
  condition: Flow___ConditionPartial;
  actionId: string;
  conditionId: string;
  dirs: Array<Pick<FlowData___DirectoryFragment, 'key' | 'label' | 'weight'>>;
};
const extractInstanceForCondition = (
  { condition, actionId, conditionId, dirs }: ExtractInstanceForCondition_Args,
  { subjectMap }: { subjectMap: SubjectMap },
): FlowData___FlowInstanceFragment | null => {
  if (condition.__typename === 'Flow___SubjectFieldCondition') {
    const subject = getSubject(
      {
        type: condition.type,
        typeId: condition.typeId,
      },
      subjectMap,
    );

    const creates = subject?.fields.find(({ key }) => {
      if (condition?.__typename === 'Flow___SubjectFieldCondition') {
        return condition.field === key;
      }

      return false;
    })?.creates;

    if (creates != null) {
      const createsSubject = getSubject(creates, subjectMap);

      if (createsSubject) {
        return generateInstanceV2({
          instanceKey: [actionId, conditionId],
          instanceType: {
            type: createsSubject.type,
            typeId: createsSubject.typeId ?? undefined,
          },
          dirs,
        });
      }
    }
  }

  return null;
};

export default extractInstanceForCondition;
