import React, { useContext } from 'react';

type EdgeContextType = {
  right?: number;
};

export const EdgeDetectionContext = React.createContext<EdgeContextType | null>(
  null,
);

const useEdgeContext = (): EdgeContextType | null => {
  const context = useContext(EdgeDetectionContext);

  if (context === null) {
    // eslint-disable-next-line no-console
    console.warn('Unable to detect edge, likely the provider is missing');
    return null;
  }

  return context;
};

export default useEdgeContext;
