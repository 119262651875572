import React from 'react';
import InputLabel, {
  type Props as InputLabelProps,
} from '~/components/atom/InputLabel';
import withHelpLink from '~/hocs/withHelpLink';

export type Props = InputLabelProps & {
  help: {
    link: string;
  };
};

/**
 * Input label that has the help icon
 */
const InputLabelWithHelpLink: React.FCC<Props> = ({ help, ...rest }) => {
  const WrappedComponent = withHelpLink(InputLabel);

  return <WrappedComponent help={help} {...rest} />;
};

export default InputLabelWithHelpLink;
