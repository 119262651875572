import React from 'react';
import { FormSpy } from 'react-final-form';
import SaveBar from '../..';

type Props = {
  disableSave?: boolean;
  inBlockComponent?: boolean;
  onCancel?: () => void;

  /** If some changes are not controlled by form, we can count them externally */
  externalChanges?: number;
};
const FormSaveBar: React.FCC<Props> = ({
  disableSave,
  inBlockComponent,
  onCancel,
  externalChanges = 0,
}) => (
  <FormSpy>
    {({ form }) => {
      const formState = form.getState();
      const dirtyFields: $Object | null | undefined = formState.dirtyFields;

      const numberOfChanges =
        (dirtyFields ? Object.keys(dirtyFields).length : 0) + externalChanges;
      if (numberOfChanges === 0) return null;

      return (
        <SaveBar
          inBlockComponent={inBlockComponent}
          disableSave={!!formState.submitting || !!disableSave}
          numberOfChanges={numberOfChanges}
          onSave={async () => {
            await form.submit();
          }}
          onCancel={() => {
            // https://github.com/final-form/final-form/issues/151
            form.setConfig('keepDirtyOnReinitialize', false);
            form.reset();
            form.setConfig('keepDirtyOnReinitialize', true);
            onCancel && onCancel();
          }}
        />
      );
    }}
  </FormSpy>
);

export default FormSaveBar;
