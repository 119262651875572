import Validation from '~/util/Validation';
import { FormData } from '~/components/page/Auth/Verify/index';

export const text = { noCode: 'Voer je verificatienummer in' };

/**
 * Validates verify form
 * @param {string} code - Verify code value
 */
const validate = ({ code }: FormData) => {
  const errors: {
    code: string | undefined;
  } = {
    code: undefined,
  };

  if (Validation.String.isEmpty(code)) errors.code = text.noCode;

  return errors;
};

export default validate;
