export const isValidTime = (value: string): boolean => {
  if (value.length != 5) return false;

  const tokens = value.split(':');
  if (tokens.length != 2) return false;

  const hour = tokens[0];
  const minutes = tokens[1];
  if (hour.length !== 2 || minutes.length !== 2) return false;

  const hourInt = parseInt(hour);
  if (hourInt == null || !isValidHour(hourInt)) return false;

  const minutesInt = parseInt(minutes);
  if (minutesInt == null || !isValidMinute(minutesInt)) return false;

  return true;
};

/**
 * Will return the current time with only the hours and minutes set
 */
export const currentTimeHourMinuteString = (): string =>
  new Date().toLocaleTimeString('nl-NL', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

export const convertToFullTimeString = (
  hour: string | number,
  minutes: string | number,
): string =>
  hour
    .toString()
    .padStart(2, '0')
    .concat(':')
    .concat(minutes.toString().padStart(2, '0'));

export const asFullTimeField = (
  value: string | null | undefined,
): string | null => {
  if (!value) return null;
  const errorReturn = value;
  if (value.length > 5) return errorReturn;

  const tokens = value.split(':');
  if (tokens.length === 1) {
    // No : entered, try to determine correct time
    if (value.length === 1) {
      // single number, we see that as the hour
      return convertToFullTimeString(tokens[0], '');
    } else if (value.length === 2) {
      // 2 numbers, hour if < 24, minutes if between 24 and 60, otherwise we do not know
      const intvalue = parseInt(tokens[0]);

      if (intvalue > 23 && intvalue < 59)
        return convertToFullTimeString('', tokens[0]);
      else if (intvalue < 59) return convertToFullTimeString(tokens[0], '');
      else return errorReturn;
    } else if (value.length === 3) {
      // 3 numbers, read first number as hour, rest as minutes
      const hourString = tokens[0].slice(0, 1);
      const minuteString = tokens[0].slice(1, 3);

      if (isValidHourString(hourString) && isValidMinuteString(minuteString))
        return convertToFullTimeString(hourString, minuteString);
      else return errorReturn;
    } else {
      // 4 numbers, insert the : at the middle spot. Check if valid though
      const hourString = tokens[0].slice(0, 2);
      const minuteString = tokens[0].slice(2, 4);

      if (isValidHourString(hourString) && isValidMinuteString(minuteString))
        return convertToFullTimeString(hourString, minuteString);
      else return errorReturn;
    }
  } else if (tokens.length === 2) {
    // check if valid hour and minutes and left pad them with a 0
    const hour = parseInt(tokens[0]);
    const minutes = parseInt(tokens[1]);

    if (!hour || !minutes) return errorReturn;
    if (!isValidHour(hour) || !isValidMinute(minutes)) return errorReturn;

    return convertToFullTimeString(tokens[0], tokens[1]);
  }

  return errorReturn;
};

/* ====== Hour functions ====== */
export const asValidHourOrNull = (hour: string | number): number | null => {
  if (typeof hour === 'string' && hour.length > 2) return null;

  const hourNumber = parseInt(`${hour}`, 10);

  if (isValidHour(hourNumber)) {
    return hourNumber;
  } else {
    return null;
  }
};

const isValidHourString = (str: string): boolean => isValidHour(parseInt(str));
const isValidHour = (num: number): boolean => num >= 0 && num <= 23;

/* ====== Minute functions ====== */
export const asValidMinuteOrNull = (minute: string | number): number | null => {
  if (typeof minute === 'string' && minute.length > 2) return null;

  const minuteNumber = parseInt(`${minute}`);

  if (isValidMinute(minuteNumber)) {
    return minuteNumber;
  } else {
    return null;
  }
};

const isValidMinuteString = (str: string): boolean =>
  isValidMinute(parseInt(str));

const isValidMinute = (num: number): boolean => num >= 0 && num < 60;
