import { isNil } from 'ramda';
import React, { useRef, type CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import type { IllustrationType } from '~/components/atom/Illustration';
import Avatar from '~/components/molecule/Avatar';

export type Props = {
  onUpload: (files: FileList) => void;
  onDelete?: () => void;
  edit: boolean | null | undefined;
  imageUrl: string | null | undefined;
  simpleImage?: boolean;
  dataTestId?: string;
  className?: string;
  imagePlaceholderText?: string;
  showDeleteButton?: boolean;
  loading?: boolean;
  illustration?: IllustrationType;
  style?: CSSProperties;
};

export const AVATAR_SIZE = 90;

const AvatarWithUploader: React.FC<Props> = ({
  onUpload,
  edit = false,
  imageUrl,
  dataTestId,
  className,
  loading,
  simpleImage,
  illustration,
  ...rest
}) => {
  const fileUploader = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (fileUploader.current) fileUploader.current.click();
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!isNil(target) && target.files?.length) {
      onUpload(target.files);
    }
  };

  return (
    <AvatarWrapper
      data-testid={dataTestId || 'image-uploader'}
      className={className}
      $edit={edit ?? false}
    >
      {edit && (
        <input
          hidden
          ref={fileUploader}
          type="file"
          accept="image/*"
          onChange={handleFileUpload}
          name="image-upload-input"
          data-testid="image-upload-input"
        />
      )}
      <Avatar
        illustration={illustration}
        loading={loading}
        src={imageUrl}
        withUpload={!!edit}
        onClick={handleClick}
        simpleImage={simpleImage}
        edit={edit ?? false}
        {...rest}
      />
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled.div<{ $edit: boolean }>(
  ({ $edit }) => css`
    margin-right: 0.75em;
    min-height: ${AVATAR_SIZE}px;
    flex: 0 0 ${AVATAR_SIZE}px;

    pointer-events: ${$edit === false ? 'none' : 'unset'};
  `,
);

export default AvatarWithUploader;
