import React from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { TaskStatus, UpdateTaskMutationVariables } from '~/graphql/types';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  accountId: string;

  /** task id */
  id: string;

  /** There is a mutation or query loading */
  loading: boolean;

  /** Task status */
  status: TaskStatus;

  /** Function to update task */
  onUpdate: (variables: UpdateTaskMutationVariables) => void;

  /** Function to delete a task */
  onDelete: () => void;
};

const text = {
  contactLabel: 'Contact',
  reopen: 'Zet terug',
  complete: 'Voltooien',
  delete: 'Verwijderen',
};

const BottomBar: React.FC<Props> = ({
  onUpdate,
  accountId,
  id,
  loading,
  status,
  onDelete,
}) => {
  const isOpen = status === TaskStatus.Open;
  const onTaskUpdate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    taskStatus: TaskStatus,
  ) => {
    e.stopPropagation();
    onUpdate({
      accountId,
      id,
      update: { status: taskStatus },
    });
  };

  return (
    <Container
      justification="end"
      gap={isOpen ? 'xs' : undefined}
      padding={['xxs', isOpen ? 'm' : null, 'xxs', 'm']}
    >
      <TextButton
        dataTestId={TEST_ID.DELETE_TASK_BUTTON}
        size="medium"
        label={text.delete}
        icon="trashcan"
        loading={loading}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onDelete();
        }}
      />

      {isOpen ? (
        <Button
          icon="check"
          appearance="secondary"
          loading={loading}
          disabled={loading}
          size="medium"
          dataTestId={TEST_ID.COMPLETE_TASK_BUTTON}
          onClick={e => onTaskUpdate(e, TaskStatus.Closed)}
          label={text.complete}
        />
      ) : (
        <TextButton
          icon="redo"
          loading={loading}
          disabled={loading}
          size="medium"
          dataTestId={TEST_ID.REOPEN_TASK_BUTTON}
          onClick={e => onTaskUpdate(e, TaskStatus.Open)}
          label={text.reopen}
        />
      )}
    </Container>
  );
};

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.color('tertiary', 'light')};
  `,
);

export default BottomBar;
