type Opts = {
  url: string;
  formId: string;
};

/**
 * Generates or composes a widget URL to preview an app on the users website based on brand settings
 */
const composeWidgetUrl = ({ formId, url }: Opts): string =>
  `${url}?dhuwParams=%7B%22path%22%3A%22%2Fapps%2F${formId}%22%2C%22appParams%22%3A%22%22%7D`;

export default composeWidgetUrl;
