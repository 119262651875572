import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';

import { Label } from '~/components/atom/Typography';
import stripHtml from '~/util/stripHtml';
import useUser from '~/hooks/useUser';
import useOffice from '~/hooks/useOffice';
import getUserName from '~/util/getUserName';
import { isNil } from 'ramda';
import DividerBlock from '~/components/template/EventTimelineV2/components/Block/components/Data/Divider';
import Item from './components/Item';
import { LayoutSize } from '~/graphql/types';
import TEST_ID from './index.testid';

export type Props = {
  dataTestId?: string;
  description?: string | null;
  dueDate: string;
  userId?: string | null;
  officeId?: string | null;
  contactName?: string;
};

const text = {
  contactLabel: 'Contact',
  dateLabel: 'Deadline',
  assignedLabel: 'Toegewezen',
  noUserValue: 'Alle gebruikers',
  noOfficeValue: 'Alle vestigingen',
  complete: 'Voltooien',
  description: 'Omschrijving:',
};

const OpenTask: React.FC<Props> = ({
  description,
  dueDate,
  userId,
  officeId,
  contactName,
}) => {
  const user = useUser(userId);
  const userName = user ? getUserName(user) : null;
  const officeName = useOffice(officeId)?.name;

  const _description = isNil(description) ? null : stripHtml(description);

  return (
    <JustificationContainer
      dataTestId={TEST_ID.CONTAINER}
      direction="column"
      padding={[null]}
    >
      <JustificationContainer width="100%" gap="base">
        <Item label={text.contactLabel} value={contactName} />
        <Item label={text.dateLabel} value={dueDate} />
        <Item
          label={text.assignedLabel}
          value={
            <>
              {officeName || text.noOfficeValue} &nbsp;-&nbsp;
              {userName || text.noUserValue}
            </>
          }
        />
      </JustificationContainer>

      {_description && (
        <>
          <DividerBlock margin={[LayoutSize.S, null, LayoutSize.Base, null]} />

          <DescriptionContainer data-testid="task-in-overview-description">
            <Label color={{ group: 'text' }}>{text.description}</Label>
            {_description}
          </DescriptionContainer>
        </>
      )}
    </JustificationContainer>
  );
};

const DescriptionContainer = styled.div<{}>(
  () => css`
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `,
);

export default OpenTask;
