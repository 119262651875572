import type { ContactValuationReportFilter } from './types.flow';
import type { Node_NodeContainer_Leaf_Activity_Event_Contact__Input } from '~/graphql/types';

import asHasFilterInput from '../../Primitive/Has/asHasFilterInput';

export const asValuationReportFilterInput = (
  filter: ContactValuationReportFilter,
): Node_NodeContainer_Leaf_Activity_Event_Contact__Input | null => ({
  App: {
    ValuationReport: {
      has: asHasFilterInput(filter.has),
    },
  },
});
