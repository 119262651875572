import { FlowV2_Action__Input } from '~/graphql/types';
import { ClientFlowActionSendEmailPlain } from '~/graphql/types.client';
import removeTypenames from '~/util/removeTypenames';

/**
 *
 * @param {ClientFlowActionSendEmailPlain} action - SendEmailPlain cient action
 */
const serializeSendEmailPlainAction = (
  action: ClientFlowActionSendEmailPlain,
): FlowV2_Action__Input => {
  const {
    id,
    accountId,
    flowBlueprintId,
    parentIds,
    from,
    signature,
    subject,
    body,
    attachments,
    isTransactional,
  } = action;

  const attachmentInput = attachments.map(({ file, inlineId }) => {
    if (file.__typename === 'Flow___Argument_Pointer') {
      return {
        file: {
          pointer: {
            path: file.pointer.path,
          },
        },
        inlineId: null,
      };
    }

    const { contentLength, filename, s3key } = file.value_file;

    return {
      file: {
        value_file: {
          contentLength,
          filename,
          s3key,
        },
      },
      inlineId: inlineId || null,
    };
  });

  return {
    SendEmail: {
      Plain: removeTypenames({
        id,
        accountId,
        flowBlueprintId,
        parentIds,
        from,
        signature,
        subject,
        body,
        attachments: attachmentInput,
        isTransactional,
      }),
    },
  };
};
export default serializeSendEmailPlainAction;
