import type { WindowLocation } from '@reach/router';

/**
 * Checks for the location.hash, extracts id and scrolls to the element with this id
 * @param {WindowLocation} location
 * keywords: scrollTo, scrollToId, hash, location
 */
const scrollToIdFromLocationHash = (location: WindowLocation) => {
  const hash = location.hash;

  // Removes # from the beginning of the hash
  const id = hash.substring(1);
  const el = document.getElementById(`${id}`);

  if (el) {
    window.scrollTo({
      behavior: 'smooth',
      top: el.offsetTop,
    });
  }
};

export default scrollToIdFromLocationHash;
