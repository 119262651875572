import React from 'react';
import { RouteComponentProps, navigate } from '@gatsbyjs/reach-router';
import useIntercom from '~/hooks/useIntercom';
import useIsMounted from '~/hooks/useIsMounted';
import useSessionHydration from '~/hooks/useSessionHydration';
import ChangeOrUpdateSubscription from '../ChangeOrUpdateSubscription';

const SetupSubscriptionPage: React.FCC<RouteComponentProps> = () => {
  const isMounted = useIsMounted();
  const [, refetchSessionHydration] = useSessionHydration();
  const intercom = useIntercom();

  return (
    <ChangeOrUpdateSubscription
      onComplete={async () => {
        intercom.update({
          first_admin: true,
          signed_up_at: new Date().getTime() * 1000,
        });

        await refetchSessionHydration();
        if (isMounted()) {
          void navigate('/-/onboarding');
        }
      }}
    />
  );
};

export default SetupSubscriptionPage;
