import { addMilliseconds } from 'date-fns';

/**
 * Currently applied clock skew in ms
 */
let _clockSkew = 0;

/**
 * Get the clock skew adjusted date
 */
const getAdjustedDate = () => addMilliseconds(new Date(), _clockSkew);

/**
 * Get the current clock skew in ms
 */
const getClockSkew = () => _clockSkew;

/**
 * Set the clock skew in ms
 */
const setClockSkew = (clockSkewMS: number) => (_clockSkew = clockSkewMS);

const ClockSkew = {
  getAdjustedDate,
  getClockSkew,
  setClockSkew,
};

export default ClockSkew;
