import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import { InvoiceItemFields_TieredInvoiceItem_Fragment } from '~/graphql/types';

import { asPriceElement } from '~/util/money';

export type Props = {
  item: InvoiceItemFields_TieredInvoiceItem_Fragment;
};

const TieredInvoiceItem: React.FCC<Props> = ({ item }) => {
  const totalAmount = item.tiers.reduce((acc, curr) => {
    acc = +curr.amount;
    return acc;
  }, 0);

  // When all items amount to 0 we don't need to render the row
  if (totalAmount === 0) return null;

  return (
    <JustificationContainer direction="column" width="100%">
      <Heading4 variant={Variant.primary} margin={[null]}>
        {item.name}
      </Heading4>
      <JustificationContainer
        direction="column"
        padding={[null, null, null, 's']}
        width="100%"
      >
        {item.tiers.map(({ __typename, description, amount }, index) => (
          <JustificationContainer
            key={__typename + index}
            justification="space-between"
            width="100%"
          >
            <Body size="base" margin={[null]}>
              {description}
            </Body>
            <Body size="base" margin={[null]} fontWeight="semiBold">
              {asPriceElement(amount)}
            </Body>
          </JustificationContainer>
        ))}
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default TieredInvoiceItem;
