import React from 'react';

import {
  TableHeaderDefinitionCellType,
  TableCell,
  LinkRenderFunction,
  Link,
} from '../../types.flow';
import { BaseHeaderCellOptions } from './base';

import { asBaseOptions } from './base';

type LinkHeaderCellOptions = BaseHeaderCellOptions & {
  renderFunction?: LinkRenderFunction;
};
const asStringTableHeaderCell = ({
  renderFunction,
  ...rest
}: LinkHeaderCellOptions): TableHeaderDefinitionCellType<Link | null> => {
  let options = asBaseOptions({
    ...rest,
  });

  options = {
    ...options,
    Cell: (tableCell: TableCell<Link | null>) =>
      renderFunction == null
        ? defaultLinkRenderFunction(tableCell.cell.value, tableCell)
        : renderFunction(tableCell.cell.value, tableCell),
  };

  return options;
};

const defaultLinkRenderFunction = (
  value: Link | null,
  _tableCell: TableCell<Link | null>,
): React.ReactNode => {
  if (value == null) return null;

  return (
    <a href={value.link} target="_blank" rel="noopener noreferrer">
      {value.label}
    </a>
  );
};

export default asStringTableHeaderCell;
