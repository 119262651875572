import { WizardStep } from '~/components/organism/Wizard/context/WizardContext';

import {
  title as StyleWidgetTitle,
  id as StyleWidgetId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/WidgetStyleConfiguration';
import {
  title as AddWidgetScriptTitle,
  id as AddWidgetScriptId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/AddWidgetScript';
import {
  title as SelectAppsForWidgetTitle,
  id as SelectAppsForWidgetId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/SelectAppsForWidget';

import {
  title as WelcomeScreenTitle,
  id as WelcomeScreenId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/WelcomeScreen';

import {
  title as ConfirmAppsEnablementTitle,
  id as ConfirmAppsEnablementId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/ConfirmAppsEnablement';

import {
  title as EmailSyncTitle,
  id as EmailSyncId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/SynchronizeEmail';

import {
  title as LoadingScreenTitle,
  id as LoadingScreenId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/LoadingScreen';
import {
  title as EnabledAppsOverviewTitle,
  id as EnabledAppsOverviewId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/EnabledAppsOverview';
import {
  title as OverviewTitle,
  id as OverviewId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/OnboardingOverviewStep';
import {
  title as WalletSettingsTitle,
  id as WalletSettingsId,
} from '~/components/organism/WizardSteps/OnboardingWizardSteps/WalletSettings';

import usePermissionMap from '../usePermissionMap';
import { StepId } from '~/components/organism/WizardSteps';
import { WizardFlow } from '~/graphql/types.client';
import useWizard from '../useWizard';
import { useMemo } from 'react';

const onboardingWizardSteps: Array<WizardStep> = [
  {
    title: WelcomeScreenTitle,
    id: WelcomeScreenId as StepId,
  },
  {
    title: StyleWidgetTitle,
    id: StyleWidgetId as StepId,
  },
  { title: SelectAppsForWidgetTitle, id: SelectAppsForWidgetId as StepId },
  {
    title: ConfirmAppsEnablementTitle,
    id: ConfirmAppsEnablementId as StepId,
  },
  {
    title: LoadingScreenTitle,
    id: LoadingScreenId as StepId,
  },
  {
    title: EnabledAppsOverviewTitle,
    id: EnabledAppsOverviewId as StepId,
  },
  {
    title: AddWidgetScriptTitle,
    id: AddWidgetScriptId as StepId,
  },
  {
    title: EmailSyncTitle,
    id: EmailSyncId as StepId,
    permissions: ['root.email'],
  },
  {
    title: WalletSettingsTitle,
    id: WalletSettingsId as StepId,
  },
  {
    title: OverviewTitle,
    id: OverviewId as StepId,
  },
];

const text = {
  header: 'Inrichten account',
};

const id = 'onboarding-wizard';

type ReturnType = {
  onboardingWizardFlow: WizardFlow;
  showOnboardingWizard: () => void;
};

const useOnboardingWizard = (): ReturnType => {
  const wizardApi = useWizard();

  const permissionsMap = usePermissionMap();

  const steps = useMemo(
    () =>
      onboardingWizardSteps.reduce((acc, curr) => {
        if (!acc.find(step => step.id === curr.id)) {
          // If no permissions array, the step is allowed in every case and gets added to the flow
          if (!curr.permissions || curr.permissions.length === 0) {
            acc.push(curr);
          } else {
            // Check if there is at least one AccessDenied permission
            const permissionAccessDenied = curr.permissions.find(
              id =>
                permissionsMap[id] &&
                permissionsMap[id].__typename ===
                  'Permission_AccessDenied_Plan',
            );
            // If none was found, step gets added to the flow
            if (!permissionAccessDenied) {
              acc.push(curr);
            }
          }
        }

        return acc;
      }, [] as Array<WizardStep>),
    [permissionsMap],
  );

  const wizardFlow: WizardFlow = {
    id,
    steps,
  };

  return {
    onboardingWizardFlow: wizardFlow,
    showOnboardingWizard: () =>
      wizardApi.show({ ...wizardFlow, header: text.header, canClose: false }),
  };
};

export default useOnboardingWizard;
