import React from 'react';

export type RouterTransitionContextProps = {
  canLeave: boolean;
  leavePageConfirmDialogOpen: boolean;
  setLeavePageConfirmDialogOpen: (isOpen: boolean, cb?: () => void) => void;
  nextRoute: string | null | undefined;
  setNextRoute: (nextRoute: string) => void;
  leaveHandler: (confirm: boolean, cb?: () => void) => void;
};

export const RouterTransitionContext =
  React.createContext<RouterTransitionContextProps>({
    canLeave: true,
    leavePageConfirmDialogOpen: false,
    setLeavePageConfirmDialogOpen: () => {},
    nextRoute: null,
    setNextRoute: () => {},
    leaveHandler: () => {},
  });

export const withRouterTransitionContext = <T extends React.ComponentType<any>>(
  Component: T,
) => {
  const ConnectedComponent: React.FC<$Props<T>> = props => (
    <RouterTransitionContext.Consumer>
      {(routerTransitionContext: RouterTransitionContextProps) => (
        <Component {...props} routerProps={routerTransitionContext} />
      )}
    </RouterTransitionContext.Consumer>
  );

  return ConnectedComponent;
};

export default RouterTransitionContext;
