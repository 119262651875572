import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  TASK_TYPE_DROPDOWN: `${cleanedFilename(__filename)}-task-type-dropdown`,
  TITLE: `${cleanedFilename(__filename)}-title`,
  DESCRIPTION: `${cleanedFilename(__filename)}-description`,
  ASSIGNED_OFFICE: `${cleanedFilename(__filename)}-assigned-office`,
  ASSIGNED_USER: `${cleanedFilename(__filename)}-assigned-user`,
};
