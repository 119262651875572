import type { FormBuilder_Node, FormBuilder_ScreenNode } from '~/graphql/types';
import isBlockWithOutput from '../typeguards/isBlockWithOutput';

/**
 * Returns the amount of input and select blocks
 * @param {Array<FormBuilder_Node>} nodes - Nodes
 */
const getOutputBlocksCount = (nodes: Array<FormBuilder_Node>): number =>
  nodes
    .filter(
      (n): n is FormBuilder_ScreenNode =>
        n.__typename === 'FormBuilder_ScreenNode',
    )
    .reduce(
      (count, { blocks }) => count + blocks.filter(isBlockWithOutput).length,
      0,
    );

export default getOutputBlocksCount;
