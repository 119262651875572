interface HTMLDragEvent extends DragEvent {
  target: HTMLInputElement & EventTarget;
}

export const _preventDefault = (event: any) => {
  event.preventDefault();
  event.stopPropagation();
};

export const isEventWithFiles = (event: HTMLDragEvent): boolean => {
  if (!event.dataTransfer) {
    return !!event.target && !!event.target.files;
  }
  // https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/types
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Recommended_drag_types#file
  return [...event.dataTransfer.types].some.call(
    event.dataTransfer.types,
    type => type === 'Files' || type === 'application/x-moz-file',
  );
};

export const allFilesAccepted = (
  files: FileList,
  acceptedFileTypes: Array<string>,
): boolean => {
  const allAcceptable = Object.values(files).every(
    file =>
      file.type === 'application/x-moz-file' ||
      acceptedFileTypes.includes(file.type),
  );

  return allAcceptable;
};
