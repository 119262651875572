import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import CopyCode from '~/components/atom/CopyCode';
import { Heading3, Label, Variant } from '~/components/atom/Typography';
import Input from '~/components/molecule/Input';
import CallToActionBlock from '~/components/organism/CallToActionBlock';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import generateDeepLink from '~/components/page/Widget/utils/generateDeepLink';
import useBrandSettings from '~/hooks/useBrandSettings';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Button from '~/components/atom/Button';
import hasValue from '~/util/hasValue';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  WIDGET_ALTERNATIVE_PATH,
} from '~/util/localStorageKeys';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Catalog from '~/Catalog';
import webSiteURLValidation from '~/util/websiteURLValidation';

const text = {
  header: 'Link delen naar',
  missingBrandURL: 'De URL van uw website ontbreekt',
  CTADescriotion: 'Ga naar de merkinstellingen en geef uw URL op',
  CTALabel: 'Ga naar merkinstellingen',
  usingBrandURL: 'Indien de widget actief is op jouw homepage',
  usingAlternativeURL: 'Indien de widget actief is op een andere pagina',
  saveAndGenerate: 'Opslaan en genereren',
  placeholder: 'https://jouw-website.nl/pagina-waar-de-widget-actief-is/',
};

export type Props = {
  slug: string;
  name: string;
  onClose: () => void;
};

const ShareDeepLinkModal: React.FCC<Props> = ({ onClose, name, slug }) => {
  const addToast = useAddToast();
  const websiteUrl = useBrandSettings()?.url;
  const [collapsedState, setCollapsedState] = useState({
    brandUrl: true,
    customUrl: false,
  });

  const [hasError, setHasError] = useState(false);

  const [currentAlternativeUrl, setCurrentAlternativeUrl] =
    useState<string>('');
  const [updatedAlternativeUrl, setUpdatedAlternativeUrl] = useState<string>(
    currentAlternativeUrl,
  );
  const [alternativeDeepLink, setAlternativeDeeplink] = useState<string | null>(
    null,
  );

  useEffect(() => {
    const savedAlternativePath = getLocalStorageItem(WIDGET_ALTERNATIVE_PATH);

    if (savedAlternativePath) {
      setCurrentAlternativeUrl(savedAlternativePath);
      setUpdatedAlternativeUrl(savedAlternativePath);
      const deepLink = generateDeepLink(savedAlternativePath, slug);
      setAlternativeDeeplink(deepLink);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!hasValue(updatedAlternativeUrl)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [updatedAlternativeUrl]);

  const saveAlternativePath = () => {
    setLocalStorageItem(WIDGET_ALTERNATIVE_PATH, updatedAlternativeUrl);
    setCurrentAlternativeUrl(updatedAlternativeUrl);

    return addToast([
      formatToastMessage(Catalog.genericSuccessMessage, 'success'),
    ]);
  };

  const onToggle = (key: 'brandUrl' | 'customUrl') => {
    setCollapsedState(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const hasChanges = currentAlternativeUrl !== updatedAlternativeUrl;

  return (
    <Overlay onClose={onClose}>
      <Modal onClose={onClose} maxWidth="800px">
        <JustificationContainer
          direction="column"
          width="100%"
          padding={['xl']}
        >
          <Heading3>{`${text.header} ${name}`}</Heading3>
          <CollapsibleChevronBlock
            header={
              <Label size="m" margin={[null]} variant={Variant.primary}>
                {text.usingBrandURL}
              </Label>
            }
            isOpen={collapsedState.brandUrl}
            onToggle={() => onToggle('brandUrl')}
          >
            {!websiteUrl ? (
              <CallToActionBlock
                icon={{ background: 'warning' }}
                header={text.missingBrandURL}
                description={text.CTADescriotion}
                button={{
                  label: text.CTALabel,
                  appearance: 'primary',
                  onClick: () => navigate('/-/settings/brand'),
                }}
              />
            ) : (
              <CopyCode
                width="100%"
                code={generateDeepLink(websiteUrl, slug)}
              />
            )}
          </CollapsibleChevronBlock>

          <CollapsibleChevronBlock
            header={
              <Label size="m" margin={[null]} variant={Variant.primary}>
                {text.usingAlternativeURL}
              </Label>
            }
            onToggle={() => onToggle('customUrl')}
            isOpen={collapsedState.customUrl}
          >
            <JustificationContainer width="100%" align="end" gap="base">
              <Input
                placeholder={text.placeholder}
                value={updatedAlternativeUrl}
                onChange={value => setUpdatedAlternativeUrl(value.target.value)}
                validation={[
                  (value: string) => {
                    const validationResult = webSiteURLValidation(value);
                    if (validationResult !== true) {
                      setHasError(true);
                      return validationResult;
                    }
                    return validationResult;
                  },
                ]}
                width="100%"
              />
              <Button
                label={text.saveAndGenerate}
                appearance="secondary"
                size="medium"
                disabled={hasError || !hasChanges}
                onClick={() => {
                  const deepLink = generateDeepLink(
                    updatedAlternativeUrl,
                    slug,
                  );
                  setAlternativeDeeplink(deepLink);
                  saveAlternativePath();
                }}
              />
            </JustificationContainer>
            {alternativeDeepLink && (
              <CopyCode
                margin={['base', null, null, null]}
                width="100%"
                code={alternativeDeepLink}
              />
            )}
          </CollapsibleChevronBlock>
        </JustificationContainer>
      </Modal>
    </Overlay>
  );
};

export default ShareDeepLinkModal;
