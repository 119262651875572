import * as React from 'react';
import { Props } from '..';

// https://www.iconfinder.com/iconsets/waving-world-flags
const NLFlag: React.FC<Props> = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
  >
    <path
      d="M0.662235 9.66467V10.6166H0V15.513C7.33302 18.4462 14.6669 12.5799 22 15.513V10.6208H21.9959V10.6139H21.9945V10.5477H21.9434V9.66467H0.662235Z"
      fill="#21468B"
    />
    <path
      d="M2.21711 4.82764V5.73407H0V10.6236C7.33302 13.5567 14.6669 7.6904 22 10.6236V5.73407H21.9931V4.82764H2.21711Z"
      fill="white"
    />
    <path
      d="M0.000366211 0.846734C7.33343 3.77991 14.6664 -2.08644 21.9995 0.846734C21.9995 2.47627 21.9995 4.10581 21.9995 5.73535C14.6664 2.80218 7.33338 8.66852 0.000366211 5.73535C0.000366211 4.10581 0.000366211 2.47627 0.000366211 0.846734Z"
      fill="#AE1C28"
    />
  </svg>
);

export default NLFlag;
