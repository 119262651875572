import type { MarkerSdk } from '@marker.io/browser';
import markerSDK from '@marker.io/browser';
import React, { createContext, useEffect, useState } from 'react';
import { isLiveStage } from '~/util';
import getVersion from './utils/getVersion';

type MarkerIoContextType = {
  widget: MarkerSdk | null;
};

export const MarkerIoContext = createContext<MarkerIoContextType | undefined>(
  undefined,
);

const MarkerIoProvider: React.FCC = ({ children }) => {
  const [widget, setWidget] = useState<MarkerSdk | null>(null);

  useEffect(() => {
    let didCancel = false;

    const loadWidget = async () => {
      if (!didCancel && !isLiveStage()) {
        const versionInfo = getVersion();
        const _widget = await markerSDK.loadWidget({
          // ---
          // REPLACE THIS ID TO SWITCH DEFAULT PROJECT
          // should be rare, therefore hardcoded is fine
          // ---
          project: '66fbf2e90185fca6fd43e8bc',
          customData: {
            ...versionInfo,
          },
        });
        const originalSetCustomData = _widget.setCustomData;
        _widget.setCustomData = (obj: Record<string, string>) => {
          originalSetCustomData({ ...versionInfo, ...obj });
        };

        setWidget(_widget);
      }
    };

    void loadWidget();

    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <MarkerIoContext.Provider value={{ widget }}>
      {children}
    </MarkerIoContext.Provider>
  );
};

export default MarkerIoProvider;
