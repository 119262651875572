import { useState } from 'react';
import { SortDirection } from '~/graphql/types';
import { setLocalStorageItem } from '~/util/localStorageKeys';
import getSortSettings, { formatParsed } from './utils/getSortSettings';

export type SortSettingsFor =
  | 'contactSortSettings'
  | 'flowSortSettings'
  | 'formSortSettings';

export type SortSettings<T extends any> = {
  id: T;
  desc: boolean;
};

export type ParsedSettings<T extends any> = {
  sortField: T;
  sortDirection: SortDirection;
};

type ReturnType<T extends any> = [
  SortSettings<T>,
  (settings: ParsedSettings<T>) => void,
];

const useSortSettings = <T extends any>(
  sortSettingsFor: SortSettingsFor,
): ReturnType<T> => {
  const [sortSettings, setSortSettings] = useState<SortSettings<T>>(
    getSortSettings(sortSettingsFor),
  );

  const updateSortSettings = (parsed: ParsedSettings<T>) => {
    // Set sort settings to update the UI
    setSortSettings(formatParsed(parsed, sortSettingsFor));

    // Can be async because the UI has already updated.
    setLocalStorageItem(sortSettingsFor, JSON.stringify(parsed));
  };

  return [sortSettings, updateSortSettings];
};

export default useSortSettings;
