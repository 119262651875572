import React from 'react';
import { Link } from '~/components/molecule/Link';

const text = {
  description:
    'Om Contaqt te koppelen dien je een Contaqt account te hebben. Heb je nog geen account? ',
  createContaqtAccount:
    'Maak hier vrijblijvend een account aan voor slechts €1.',
  noAccount: 'Heb je al een Contaqt account? ',
  createToken: 'Dan maak je hier een API token aan',
  createToken2: ' die je hieronder kan invoeren.',
};

export type Props = {
  dataTestId?: string;

  /** Sign up link for Contaqt with the discount code */
  signUpLink: string;
};

const HelpBlock: React.FCC<Props> = ({ signUpLink }) => (
  <>
    {text.description}
    <Link to={signUpLink} target="_blank">
      {text.createContaqtAccount}
    </Link>
    <br />
    <br />
    {text.noAccount}
    <Link to="https://app.contaqt.marketing/dashboard/api-key" target="_blank">
      {text.createToken}
    </Link>
    {text.createToken2}
  </>
);

export default HelpBlock;
