import { Warning as WarningType } from '../..';
import { generateHash } from '~/util/generateHash';
import { NotificationListItem } from '~/graphql/types';

const errorMessagesForWarningItems = (
  warningList: Array<NotificationListItem>,
): Array<WarningType> => warningList.map(warning => createWarning(warning));

const createWarning = (warning: NotificationListItem): WarningType => ({
  item: warning,
  hash: generateHash(warning.body.htmlBody),
});

export default errorMessagesForWarningItems;
