/**
 * returns rows for email effectiveness
 * @param {Data} data -flowsV2
 * @param {Points} points
 * @param {link} string - to builder-v2
 * keywords: emailEffectiveness, rows
 */

import { take } from 'ramda';
import { DataType } from '~/components/organism/Charts/GroupedBarChart';
import { GetEmailEffectivenessGroupedByFlowV2BlueprintQuery } from '~/graphql/types';
import { Points } from '../../constants/tabsConfig';

type Data =
  | GetEmailEffectivenessGroupedByFlowV2BlueprintQuery['getEmailEffectivenessGroupedByFlowV2Blueprint']
  | undefined;

type Args = {
  data: Data;
  points: Points;
  link: string;
};

const NUMBER_OF_ROWS = 5;

const getRows = ({ data, points, link }: Args): DataType => {
  const rows: DataType =
    take(NUMBER_OF_ROWS, data || []).map(
      ({ flowBlueprintId: id, name, ...metrics }) => ({
        id,
        name,
        link: `${link}${id}`,
        value: points.reduce((acc, { key, label }) => {
          acc[label] = metrics[key];
          return acc;
        }, {}),
      }),
    ) || [];

  return rows;
};

export default getRows;
