import React from 'react';
import hasValue from '../hasValue';

/**
 * Checks if the react node has children
 * @param {React.ReactNode} children - Example argument
 * keywords: children
 */
const hasChildren = (children: React.ReactNode) => {
  if (React.isValidElement(children)) {
    return hasValue(children.props.children);
  }

  return false;
};

export default hasChildren;
