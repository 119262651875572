import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import Button from '~/components/atom/Button';
import EmptyStateComponent from '~/components/template/EmptyStateComponent';
import Loading from '~/components/atom/Loading';
import JustificationContainer from '~/components/atom/JustificationContainer';
import styled from 'styled-components';
import {
  AppStatus_Trustoo_Token,
  useGetTrustooTokensQuery,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ErrorScreen from '~/components/page/ErrorScreen';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import { isNil, omit } from 'ramda';
import TEST_ID from './index.testid';
import isDuplicatedToken from '~/components/page/Apps/Realworks/utils/isDuplicatedToken';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import EditTokenModal from '../EditTokenModal';
import TokensList from '../TokensList';
import AddTokenModal, { TokenPair } from '../AddTokenModal';

type Props = RouteComponentProps & {};

export const text = {
  appTitle: 'Trustoo',
  addToken: 'Koppeling toevoegen',
  noTokens: 'Voeg je eerste Trustoo koppeling toe',
  emptyStateDescription:
    'Met behulp van een token voeg je een koppeling toe. De token vind je in jouw Trustoo account.',
  errorMessage:
    'Er is iets misgegaan tijdens het toevoegen van de token. Zorg ervoor dat je een valide token invoert.',
  duplicatedErrorMessage: 'Dit token bestaat al, probeer een andere token.',
  success: 'Token is opgeslagen',
  successfullyDeleted: 'Token is verwijderd',
};
const Tokens: React.FCC<Props> = ({ ...rest }) => {
  const account = useCurrentAccount();
  const addToast = useAddToast();
  const { data, error, loading, updateQuery } = useGetTrustooTokensQuery({
    variables: { accountId: account.id },
  });

  const [showModal, setShowModal] = useState<'create' | 'update' | null>(null);

  const [tokenToEdit, setTokenToEdit] =
    useState<AppStatus_Trustoo_Token | null>(null);

  const [updateAppStatus, { loading: updateLoading }] =
    useUpdateAppStatusMutation();

  if (loading) return <Loading />;
  if (!data || error) {
    return <ErrorScreen />;
  }

  const allTokens = data.getAppStatusTrustoo.tokens;
  const updateAppStatusFn = async ({
    updatedTokens,
    toastMsg,
  }: {
    updatedTokens: Array<AppStatus_Trustoo_Token>;
    toastMsg: string;
  }) => {
    const formattedTokens = updatedTokens.map(
      (token: AppStatus_Trustoo_Token) =>
        omit(['__typename', 'TokenInfo'], token),
    );
    await updateAppStatus({
      variables: {
        accountId: account.id,
        update: {
          AppStatus_Trustoo: {
            enabled: data.getAppStatusTrustoo.enabled,
            tokens: formattedTokens,
          },
        },
      },
    }).then(({ errors }) => {
      if (errors && errors?.length) {
        let errorMessage: string;

        if (isDuplicatedToken(errors)) {
          errorMessage = text.duplicatedErrorMessage;
        } else {
          errorMessage = text.errorMessage;
        }

        return addToast([formatToastMessage(errorMessage, 'danger')]);
      }

      if (data) {
        addToast([formatToastMessage(toastMsg, 'success')]);

        updateQuery(prevResult => ({
          ...prevResult,
          getAppStatusTrustoo: {
            ...prevResult.getAppStatusTrustoo,
            tokens: updatedTokens,
          },
        }));

        return setShowModal(null);
      }
    });
  };

  const updateToken = async (updatedToken: AppStatus_Trustoo_Token) => {
    const existingTokenIdx = allTokens.findIndex(
      token =>
        token.token === updatedToken.token &&
        token.officeId !== updatedToken.officeId,
    );
    const updatedTokens: Array<AppStatus_Trustoo_Token> = [...allTokens];

    if (existingTokenIdx >= 0) {
      updatedTokens[existingTokenIdx] = {
        ...updatedTokens[existingTokenIdx],
        officeId: updatedToken.officeId,
      };
    } else {
      updatedTokens.push(updatedToken);
    }

    return await updateAppStatusFn({
      updatedTokens,
      toastMsg: text.success,
    });
  };

  const onAddToken = ({ tokenId, officeId }: TokenPair) =>
    updateToken({
      token: tokenId,
      officeId,
      __typename: 'AppStatus_Trustoo_Token',
    });

  const onDelete = (tokenId: string) => {
    const updatedTokens = allTokens.filter(token => token.token !== tokenId);
    return updateAppStatusFn({
      updatedTokens,
      toastMsg: text.successfullyDeleted,
    });
  };

  const onEdit = (tokenId: string) => {
    const token = allTokens.find(token => token.token === tokenId);

    if (!token) {
      return;
    }
    setTokenToEdit(token);
    setShowModal('update');
  };

  return (
    <AppDetailsContainer
      header="Inkomende koppeling instellen"
      {...rest}
      data-testid={TEST_ID.CONTAINER}
    >
      {allTokens.length === 0 ? (
        <EmptyStateComponent
          illustration="list"
          header={text.noTokens}
          description={text.emptyStateDescription}
          buttonLabel={text.addToken}
          onButtonClick={() => setShowModal('create')}
          dataTestId={TEST_ID.EMPTY_STATE}
        />
      ) : (
        <JustificationContainer
          direction="column"
          margin={['base', null, null, null]}
          gap="s"
        >
          <StyledButton
            size="medium"
            ghost
            appearance="secondary"
            label={text.addToken}
            icon="plus"
            onClick={() => setShowModal('create')}
            dataTestId={TEST_ID.ADD_TOKEN_BUTTON}
          />
          <TokensList
            onDelete={onDelete}
            onClick={onEdit}
            loading={loading}
            tokens={allTokens}
          />
        </JustificationContainer>
      )}

      {showModal === 'create' && (
        <AddTokenModal
          dataTestId={TEST_ID.ADD_TOKEN_MODAL}
          loading={updateLoading}
          onClose={() => setShowModal(null)}
          onAddToken={onAddToken}
        />
      )}

      {showModal === 'update' && !isNil(tokenToEdit) && (
        <EditTokenModal
          dataTestId={TEST_ID.EDIT_TOKEN_MODAL}
          loading={updateLoading}
          token={tokenToEdit}
          onClose={() => setShowModal(null)}
          onSave={updateToken}
        />
      )}
    </AppDetailsContainer>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default Tokens;
