import {
  StellaxAiReportType,
  type AppStatusFields_AppStatus_StellaxAi_Fragment,
  type AppStatus_StellaxAi__Input,
} from '~/graphql/types';

const appStatusToInput = (
  appStatus: AppStatusFields_AppStatus_StellaxAi_Fragment,
): AppStatus_StellaxAi__Input => ({
  email: appStatus.email,
  name: appStatus.name,
  website: appStatus.website,
  logo: appStatus.logo
    ? {
        s3key: appStatus.logo.s3key,
      }
    : appStatus.logo,
  reportType: appStatus.reportType ?? StellaxAiReportType.B,
  enabled: true,
});

export default appStatusToInput;
