import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  OutputFieldAccordions,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import useWizardStep from '~/hooks/useWizardStep';
import {
  id as confirmAppsEnablementId,
  OutputType as ConfirmAppsEnablementOutputType,
} from '../ConfirmAppsEnablement';
import {
  AppType,
  FlowTemplates,
  InsertedTemplateId,
  WidgetDescriptionItem,
  useGetFlowV2TemplatesQuery,
  useInsertFlowBlueprintsByTemplateIdsMutation,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useApps from '~/hooks/useApps';
import getUpdateForAppStatus from './utils/getUpdateForAppStatus';
import { AppStatus__typename } from '~/graphql/types.client';
import useWizard from '~/hooks/useWizard';
import useErrorReporter from '~/hooks/useErrorReporter';

import usePermissions from '~/hooks/usePermissions';
import { isNil, prop, uniqBy } from 'ramda';
import ProcessOAuthComponent, {
  OAuthState,
  bbWcAppStatusKey,
} from './components/ProcessOAuthComponent';
import { ReturnValue } from '~/components/organism/WizardSteps/utils/getOutputsForOverview';
import convertOutputToAccordionOutput from './utils/convertOutputToAccordionOutput';
import LoadingWithDynamicTips from '~/components/molecule/LoadingWithDynamicTips';

export const id = 'LoadingScreen';
export const title = 'Activeren van apps';

export type EnabledApps = {
  typename: AppStatus__typename;
  insertedFlows: Array<InsertedTemplateId>;
  widgetItems: Array<WidgetDescriptionItem>;
};
export type FailedApps = {
  typename: AppStatus__typename;
  widgetItems: Array<WidgetDescriptionItem>;
};

type FlowTemplatesMap = {
  [key in AppType]: Array<FlowTemplates>;
};

export type OutputType = {
  type: typeof id;
  enabledAppsOutput: OutputFieldAccordions<ReturnValue>;
  failedAppsOutput: OutputFieldAccordions<ReturnValue>;
};

export enum EnabledState {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
}

export const ENABLED_TITLE = 'Geactiveerde apps';
export const FAILED_TITLE = 'Niet geactiveerde apps';

const tips = ['Een moment geduld aub ...'];

export const Component: React.FCC<WizardStepProps> = ({ outputMap, step }) => {
  const { id: accountId } = useCurrentAccount();
  const errorReporter = useErrorReporter();
  const flowsPermission = usePermissions(['root.automation']).allowed;

  // Wizard operations
  const [, api] = useWizardStep(step.id);
  const wizardApi = useWizard();
  const confirmAppsEnablementOutput = outputMap[
    confirmAppsEnablementId
  ] as ConfirmAppsEnablementOutputType;
  const appsToEnable = confirmAppsEnablementOutput.appsToEnable.value;

  // Step related states
  const [loading, setLoading] = useState<boolean>(true);
  const [enabledApps, setEnabledApps] = useState<Array<EnabledApps>>([]);
  const [failedApps, setFailedApps] = useState<Array<FailedApps>>([]);

  // Mutations and queries
  const { apps, loading: appsLoading } = useApps();
  const [updateAppStatus, { loading: updateAppStatusLoading }] =
    useUpdateAppStatusMutation();
  const { data: flowTemplatesData, loading: flowTemplatesLoading } =
    useGetFlowV2TemplatesQuery({
      variables: {
        accountId,
      },
      skip: flowsPermission === false,
    });
  const [insertByTemplateIds, { loading: insertTemplatesLoading }] =
    useInsertFlowBlueprintsByTemplateIdsMutation({
      variables: {
        accountId,
        templateIds: [],
      },
    });

  // Create [AppType]: [Array of flow templates] Map
  const flowTemplatesMap: FlowTemplatesMap | undefined = useMemo(
    () =>
      flowTemplatesData?.getFlowV2Templates.reduce((acc, curr) => {
        const appType = curr.appTypes[0];
        if (!acc[appType]) {
          acc[appType] = [curr];
        } else {
          acc[appType] = [...acc[appType], curr];
        }

        return acc;
      }, {} as FlowTemplatesMap),
    [flowTemplatesData?.getFlowV2Templates],
  );

  // oAuth related
  const hasOAuthApp =
    !!appsToEnable && Object.keys(appsToEnable).includes(bbWcAppStatusKey);
  const isBBWCEnabled = apps[bbWcAppStatusKey]?.appStatus.enabled ?? false;

  const [oAuthState, setOAuthState] = useState<OAuthState>(
    hasOAuthApp && !isBBWCEnabled ? OAuthState.Pending : OAuthState.NotRequired,
  );

  const handleAppAfterEnabling = async ({
    appStatusType,
    appType,
  }: {
    appStatusType: AppStatus__typename;
    appType: AppType;
  }) => {
    if (!appsToEnable) return;
    const currentApp = apps[appStatusType];

    if (!currentApp) return;

    if (
      flowsPermission === true &&
      flowTemplatesMap &&
      flowTemplatesMap[appType]
    ) {
      const { data: insertedFlowsData, errors: insertedFlowsErrors } =
        await insertByTemplateIds({
          variables: {
            accountId,
            templateIds: flowTemplatesMap[appType].map(
              (template: FlowTemplates) => template.id,
            ),
          },
        });

      // Handle errors during insertion
      if (insertedFlowsData && !insertedFlowsErrors) {
        // Add app to the output with inserted templates
        const newEntry: EnabledApps = {
          typename: appStatusType,
          insertedFlows: insertedFlowsData.insertFlowBlueprintsByTemplateIds,
          widgetItems: appsToEnable[appStatusType],
        };

        setEnabledApps(prev => uniqBy(prop('typename'), [newEntry, ...prev]));

        const update = getUpdateForAppStatus({
          app: currentApp,
          widgetItems: appsToEnable[appStatusType],
          addedFlows: insertedFlowsData.insertFlowBlueprintsByTemplateIds,
          outputMap,
        });

        // Later this will be handled by BE, follow this ticket - https://app.shortcut.com/dathuis/story/10882/make-updateappstatus-insert-flow-blueprints-from-the-addedflows-metadata
        await updateAppStatus({
          variables: {
            accountId,
            update,
          },
        }).then(({ errors }) => {
          if (errors && errors.length > 0) {
            errorReporter.captureMessage(
              `Error in handleAppAfterEnabling in ${title} wizard step: ${errors[0].message}`,
              'error',
            );
          }
        });
      }
    } else {
      // Add app to the output with empty flow templates
      const newEntry: EnabledApps = {
        typename: appStatusType,
        insertedFlows: [],
        widgetItems: appsToEnable[appStatusType],
      };
      setEnabledApps(prev => uniqBy(prop('typename'), [newEntry, ...prev]));
    }
  };

  const enablementState = useRef<EnabledState>(EnabledState.NotStarted);
  const isOAuthHandled =
    oAuthState === OAuthState.Completed ||
    oAuthState === OAuthState.NotRequired;

  const isReadyToEnableApps =
    isOAuthHandled &&
    enablementState.current === EnabledState.NotStarted &&
    !flowTemplatesLoading;

  // Start enabling apps
  useEffect(() => {
    // Only start updating the rest of the apps if oAuth flow has finished or is not required
    if (isReadyToEnableApps) {
      const enableAppsSequentially = async () => {
        enablementState.current = EnabledState.InProgress;

        if (!appsToEnable) {
          return setLoading(false);
        }

        // In case the step was reopened due to the network interruption or anything,
        // we should filter the apps which are already enabled
        const appsPendingEnablement = Object.keys(appsToEnable).filter(
          (appKey: AppStatus__typename) =>
            apps[appKey]?.appStatus.enabled !== true &&
            apps[appKey]?.setupFlow !== 'oAuth',
        );

        for (const key of appsPendingEnablement as Array<AppStatus__typename>) {
          try {
            const currentApp = apps[key];
            if (isNil(currentApp)) return;

            // Update app status
            const update = getUpdateForAppStatus({
              app: currentApp,
              widgetItems: appsToEnable[key],
              outputMap,
            });
            const { data, errors } = await updateAppStatus({
              variables: {
                accountId,
                update,
              },
            });

            // Handle errors during update
            if (errors && errors.length > 0) {
              // Capture error and add app to failedApps
              errorReporter.captureMessage(
                `Error enabling ${key} app in ${title} wizard step: ${errors[0].message}`,
                'error',
              );

              const newEntry: FailedApps = {
                typename: key,
                widgetItems: appsToEnable[key],
              };
              setFailedApps(prev =>
                uniqBy(prop('typename'), [newEntry, ...prev]),
              );
              continue; // Continue to the next iteration
            }

            // If update is successful, continue with additional steps
            if (data && !errors) {
              const appType = currentApp.appType;

              // If app has flow templates and plan allows flows, insert them
              await handleAppAfterEnabling({
                appType,
                appStatusType: key,
              });
            }
          } catch (error) {
            continue; // Continue to the next iteration
          }
        }

        // Clear appsToEnable so the mutations won't be triggered again if the component rerenders
        wizardApi.setOutput({
          ...outputMap,
          ConfirmAppsEnablement: {
            type: 'ConfirmAppsEnablement',
            appsToEnable: {
              type: 'invisible',
              value: null,
            },
          },
        });

        enablementState.current = EnabledState.Completed;
        setLoading(false);
      };

      void enableAppsSequentially();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyToEnableApps]);

  const queryOrMutationLoading =
    appsLoading || flowTemplatesLoading || insertTemplatesLoading;

  const processingOAuth =
    hasOAuthApp &&
    (oAuthState === OAuthState.Pending || oAuthState === OAuthState.Running);

  const loadingState =
    queryOrMutationLoading ||
    loading ||
    processingOAuth ||
    updateAppStatusLoading;

  useEffect(() => {
    const output: OutputType = {
      type: id,
      enabledAppsOutput: convertOutputToAccordionOutput({
        categoryName: ENABLED_TITLE,
        values: enabledApps,
        appsMap: apps,
        outputMap,
      }),
      failedAppsOutput: convertOutputToAccordionOutput({
        categoryName: FAILED_TITLE,
        values: failedApps,
        appsMap: apps,
        outputMap,
      }),
    };

    api.lock(output);
    api.lockGoBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledApps, failedApps]);

  useEffect(() => {
    const output: OutputType = {
      type: id,
      enabledAppsOutput: convertOutputToAccordionOutput({
        categoryName: ENABLED_TITLE,
        values: enabledApps,
        appsMap: apps,
        outputMap,
      }),
      failedAppsOutput: convertOutputToAccordionOutput({
        categoryName: FAILED_TITLE,
        values: failedApps,
        appsMap: apps,
        outputMap,
      }),
    };
    if (!appsToEnable && !loadingState) {
      if (enabledApps.length > 0) {
        const timeout = setTimeout(() => {
          api.free(output);
          wizardApi.next();
        }, 1500);

        return () => clearTimeout(timeout);
      } else {
        api.free(output);
        wizardApi.next();
      }
    }

    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appsToEnable, loadingState]);

  return (
    <>
      {hasOAuthApp && !isBBWCEnabled && (
        <ProcessOAuthComponent
          appsToEnable={appsToEnable}
          oAuthState={oAuthState}
          setOAuthState={setOAuthState}
          setFailedApps={setFailedApps}
          handleAppAfterEnabling={handleAppAfterEnabling}
        />
      )}
      <LoadingWithDynamicTips tips={tips} />
    </>
  );
};

export default {
  id,
  title,
};
