import React from 'react';
import { Props } from '..';

const DragAndDrop: React.FCC<Props> = ({ color = 'currentColor' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 17 25"
    fill="none"
  >
    <path
      d="M6 0H1C0.447715 0 0 0.447715 0 1V6C0 6.55228 0.447715 7 1 7H6C6.55228 7 7 6.55228 7 6V1C7 0.447715 6.55228 0 6 0Z"
      fill={color}
    />
    <path
      d="M16 0H11C10.4477 0 10 0.447715 10 1V6C10 6.55228 10.4477 7 11 7H16C16.5523 7 17 6.55228 17 6V1C17 0.447715 16.5523 0 16 0Z"
      fill={color}
    />
    <path
      d="M16 9H11C10.4477 9 10 9.44772 10 10V15C10 15.5523 10.4477 16 11 16H16C16.5523 16 17 15.5523 17 15V10C17 9.44772 16.5523 9 16 9Z"
      fill={color}
    />
    <path
      d="M6 9H1C0.447715 9 0 9.44772 0 10V15C0 15.5523 0.447715 16 1 16H6C6.55228 16 7 15.5523 7 15V10C7 9.44772 6.55228 9 6 9Z"
      fill={color}
    />
    <path
      d="M16 18H11C10.4477 18 10 18.4477 10 19V24C10 24.5523 10.4477 25 11 25H16C16.5523 25 17 24.5523 17 24V19C17 18.4477 16.5523 18 16 18Z"
      fill={color}
    />
    <path
      d="M6 18H1C0.447715 18 0 18.4477 0 19V24C0 24.5523 0.447715 25 1 25H6C6.55228 25 7 24.5523 7 24V19C7 18.4477 6.55228 18 6 18Z"
      fill={color}
    />
  </svg>
);

export default DragAndDrop;
