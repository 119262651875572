import cleanedFilename from '~/util/cleanedFilename';

export default {
  ADD_EMAIL_SYNCHRONISATION: 'add-email-synchronisation',
  GET_MAILBOX_INPUT: (value: string) =>
    `${cleanedFilename(__filename)}-c-${value}`,
  GET_MAILBOX_DELETE_BUTTON: (value: string) =>
    `${cleanedFilename(__filename)}-admin-user-mailbox-delete-button-${value}`,
  GET_MAILBOX_RESYNC_BUTTON: (value: string) =>
    `${cleanedFilename(__filename)}-admin-user-resync-mailbox_button-${value}`,
  ADD_ALIAS_BUTTON: `${cleanedFilename(__filename)}-add-alias-button`,
};
