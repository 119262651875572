import React from 'react';
import styled, { css } from 'styled-components';
import InputLabel, { Props as LabelProps } from '../InputLabel';
import AnimatedLabel, {
  Props as AnimatedLabelProps,
} from '~/components/atom/AnimatedLabel';

export type OnChangeCallback = (value: string | null) => void;

export type Props = LabelProps & {
  /** Placeholder text */
  placeholder?: string;
  /** Renders a prefix inside the input */
  prefix?: string;
  /** The value of the input field */
  value?: string | null;
  /** Callback once the input has changed */
  onChange?: OnChangeCallback;
  statuses?: AnimatedLabelProps['options'];
  statusIndex?: AnimatedLabelProps['position'];
  disabled?: boolean;
  name?: string;
  onFocus?: () => void;
};

const Input: React.FCC<Props> = ({
  dataTestId,
  label,
  help,
  prefix,
  value,
  error,
  onChange,
  reset,
  onReset,
  id,
  statuses,
  statusIndex,
  disabled = false,
  ...rest
}) => {
  const hasError = error != null;
  const prefixElement = prefix ? (
    <PrefixContainer hasError={hasError}>{prefix}</PrefixContainer>
  ) : null;

  return (
    <InputLabel
      id={id}
      label={label}
      help={help}
      error={error}
      reset={reset}
      onReset={onReset}
    >
      <Container hasError={hasError} $disabled={disabled}>
        {prefixElement}
        <Spacing $disabled={disabled}>
          <InputContainer
            {...rest}
            disabled={disabled}
            data-testid={dataTestId}
            value={value || ''}
            onChange={e => {
              if (!onChange) return;

              onChange(e.target.value || null);
            }}
          />
          {statuses && (
            <AnimatedLabel options={statuses} position={statusIndex} />
          )}
        </Spacing>
      </Container>
    </InputLabel>
  );
};

type HasError = { hasError?: boolean };
const Container = styled.div<HasError & { $disabled: boolean }>(
  ({ theme, hasError, $disabled }) => css`
    display: flex;
    border: ${theme.getTokens().border.width.s} solid
      ${hasError ? theme.color('danger') : theme.color('tertiary')};
    overflow: hidden;
    border-radius: ${theme.getTokens().border.radius.base};

    background-color: ${$disabled
      ? theme.color('white', 'dark')
      : 'transparent'};

    ${$disabled &&
    css`
      cursor: not-allowed;
    `}

    ${!$disabled &&
    css`
      &:hover,
      &:focus,
      &:focus-within {
        border: ${theme.getTokens().border.width.s} solid ${theme.color('text')};
      }
    `}
  `,
);

const PrefixContainer = styled.div<HasError>(
  ({ theme, hasError }) => css`
    background-color: ${hasError
      ? theme.color('danger', 'translucent')
      : theme.color('tertiary', 'light')};
    padding: ${theme.space('s')};
  `,
);

const Spacing = styled.div<{ $disabled?: boolean }>(
  ({ theme, $disabled }) => css`
    display: flex;
    flex-grow: 1;
    padding: ${theme.space('s')};
    background-color: ${$disabled
      ? theme.color('white', 'dark')
      : theme.color('white')};
  `,
);
const InputContainer = styled.input<{}>(
  ({ theme }) => css`
    flex-grow: 1;
    width: 100%;
    border: none;

    &:disabled {
      background-color: ${theme.color('white', 'dark')};
    }
  `,
);

export default Input;
