import { isEmpty, omit } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Loading from '~/components/atom/Loading';
import { Body } from '~/components/atom/Typography';
import {
  OutputFieldColor,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import { OutputMap } from '~/components/organism/WizardSteps';
import {
  App_Hypotheekbond_Settings__Input,
  useGetAppHypotheekbondQuery,
  useUpdateAppHypotheekbondMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import ColorInput from '~/components/molecule/ColorInput';
import formatToastMessage from '~/util/formatToastMessage';
import TEST_ID from './index.testid';

export type ColorSettings = Pick<
  App_Hypotheekbond_Settings__Input,
  'highlightColor' | 'primaryColor' | 'primaryFontColor'
>;

type ColorProperty = keyof ColorSettings;
type ColorPair = Record<ColorProperty, string>;

export const id = 'HypotheekColorSettings';
export const title = 'Huisstijl instellingen';

export type OutputType = {
  type: typeof id;
  highlightColor: OutputFieldColor;
  primaryColor: OutputFieldColor;
  primaryFontColor: OutputFieldColor;
};

export const colorsMap: ColorPair = {
  highlightColor: 'Uitlicht kleur',
  primaryColor: 'Primaire kleur',
  primaryFontColor: 'Primaire tekst kleur',
};

export const colorProperties: Array<ColorProperty> = [
  'highlightColor',
  'primaryColor',
  'primaryFontColor',
];

export const initialColors = {
  primaryColor: '#4185de',
  highlightColor: '#96c11f',
  primaryFontColor: '#808080',
};

export const initialHypotheekColorSettingsState: OutputType = {
  type: id,
  highlightColor: {
    category: 'Kleuren',
    value: initialColors.highlightColor,
    type: 'color',
    label: 'Uitlicht kleur',
  },
  primaryColor: {
    category: 'Kleuren',
    value: initialColors.primaryColor,
    type: 'color',
    label: 'Primaire kleur',
  },
  primaryFontColor: {
    category: 'Kleuren',
    value: initialColors.primaryFontColor,
    type: 'color',
    label: 'Primaire tekst kleur',
  },
};

const text = {
  category: 'Kleuren',
  pageDescription: (
    <>
      Pas hier de kleuren van de app aan, alleen als deze moeten afwijken van de
      instellingen in de widget. Wil je voor alle apps de kleuren instellen? Ga
      dan naar de widget en wijzig deze daar.
      <br />
      <br />
      Tip: gebruik de hoofdkleuren van je website om een consistente huisstijl
      uit te stralen.
      <br />
      <br />
      Personaliseer de waardecheck met jouw huisstijlkleuren.
    </>
  ),
  successToastMessage: 'Kleurinstellingen succesvol opgeslagen',
  errorToastMessage:
    'Er is iets mis gegaan bij het opslaan, probeer het later nog eens',
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const { id: accountId } = useCurrentAccount();
  const addToast = useAddToast();
  const { data, loading } = useGetAppHypotheekbondQuery({
    variables: {
      accountId,
    },
  });
  const [currentOutput, setCurrentOutput] = useState(
    outputMap[id] as OutputType,
  );
  const defaultColorSettings = data?.getAppHypotheekbond?.settings;
  const [updateHypotheekbond] = useUpdateAppHypotheekbondMutation();
  const getColorsInput = (output: OutputType): ColorSettings =>
    colorProperties.reduce(
      (acc, curr) => {
        if (curr in output && !isEmpty(output[curr])) {
          acc[curr] = output[curr].value;
        } else {
          acc[curr] = initialColors[curr];
        }
        return acc;
      },
      { primaryColor: '', primaryFontColor: '', highlightColor: '' },
    );

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const updatedColors = outputMap[id] as OutputType;

      if (defaultColorSettings) {
        const defaultColorSettingsAsInput = omit(
          ['__typename'],
          defaultColorSettings,
        );

        const colorsInput = getColorsInput(updatedColors);
        const settings: App_Hypotheekbond_Settings__Input = {
          ...defaultColorSettingsAsInput,
          ...colorsInput,
        };

        // For future developers;
        // The changes to the hypotheekbond endpoint won't actually be persisted on dev.
        // These changes are updated at Hypotheekbond in PROD which is something we cannot do for dev
        // So requests get accepted but the updated values will no be returned.
        // To verify this update works correctly we only need to verify what we POST to BE
        // See: https://discord.com/channels/778915897027854377/1029358903126937640
        await updateHypotheekbond({
          variables: {
            accountId,
            update: {
              settings,
            },
          },
        }).then(({ errors }) => {
          if (errors && errors.length) {
            return addToast([
              formatToastMessage(text.errorToastMessage, 'danger'),
            ]);
          } else {
            return addToast([
              formatToastMessage(text.successToastMessage, 'success'),
            ]);
          }
        });
      }

      const output: OutputType = {
        type: id,
        primaryColor: {
          category: text.category,
          type: 'color',
          value: updatedColors.primaryColor.value,
          label: colorsMap['primaryColor'],
        },
        primaryFontColor: {
          category: text.category,
          type: 'color',
          value: updatedColors.primaryFontColor.value,
          label: colorsMap['primaryFontColor'],
        },
        highlightColor: {
          category: text.category,
          type: 'color',
          value: updatedColors.highlightColor.value,
          label: colorsMap['highlightColor'],
        },
      };

      return output;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountId, defaultColorSettings, updateHypotheekbond],
  );

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );

  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    // Step is free to go on mount
    api.free(currentOutput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOutput]);

  if (loading) {
    <LoadingContainer>
      <Loading />
    </LoadingContainer>;
  }

  return (
    <>
      <Body>{text.pageDescription}</Body>
      <SettingsContainer>
        {colorProperties.map(property => (
          <ColorInput
            key={property}
            value={currentOutput[property].value}
            onChange={(value: string) => {
              setCurrentOutput(prevOutput => ({
                ...prevOutput,
                [property]: {
                  category: text.category,
                  type: 'color',
                  value: value,
                  label: colorsMap[property],
                },
              }));
            }}
            label={colorsMap[property]}
            dataTestId={`${TEST_ID.COLOR_INPUT}-${property}`}
          />
        ))}
      </SettingsContainer>
    </>
  );
};

const LoadingContainer = styled.div<{}>`
  display: flex;
`;

const SettingsContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${theme.space('base')};
    grid-row-gap: ${theme.space('s')};
  `,
);

export default {
  id,
  title,
};
