import type { FormBuilder_Node } from '~/graphql/types';
import type { Issue } from '../../state/issues';
import getOutputBlocksCount from '../getOutputBlocksCount';
import getScreenNodeBlocksUsageLookup from '../getScreenNodeBlocksUsageLookup';

const text = {
  contactPhoneMultiple:
    'Je verzamelt in meerdere stappen het telefoonnummer van het contact. Kies degene die je wil behouden en verwijder de andere.',
  emailMultiple:
    'Je verzamelt in meerdere stappen het e-mailadres van het contact. Kies degene die je wil behouden en verwijder de andere.',
  nameMultiple:
    'Je verzamelt in meerdere stappen de naam van het contact. Kies degene die je wil behouden en verwijder de andere.',
  contactEmailMissing:
    "Er moet een contact e-mailadres in het formulier voorkomen, voeg het blok toe in een van de pagina's.",
};

/**
 * Returns miscellaneous issues (anything that does not belong in the form or node fields)
 * @param {Array<FormBuilder_Node>} nodes - nodes
 * @param {boolean} contactEmailMissing - contact email block is not used
 */
const getMiscIssues = ({
  nodes,
  contactEmailMissing,
}: {
  nodes: Array<FormBuilder_Node>;
  contactEmailMissing: boolean;
}): Array<Issue> => {
  const outputBlocksCount = getOutputBlocksCount(nodes);

  const issues: Array<Issue> = [];

  if (outputBlocksCount > 0 && contactEmailMissing) {
    issues.push({
      level: 'error',
      message: text.contactEmailMissing,
    });
  }

  const blockUsageLookup = getScreenNodeBlocksUsageLookup({ nodes });

  if (
    blockUsageLookup.FormData_Input_Contact_Phone &&
    blockUsageLookup.FormData_Input_Contact_Phone?.nodeIds.length > 1
  ) {
    issues.push({
      level: 'error',
      message: text.contactPhoneMultiple,
      nodeIds: blockUsageLookup.FormData_Input_Contact_Phone.nodeIds,
    });
  }

  if (
    blockUsageLookup.FormData_Input_Contact_Email &&
    blockUsageLookup.FormData_Input_Contact_Email?.nodeIds.length > 1
  ) {
    issues.push({
      level: 'error',
      message: text.emailMultiple,
      nodeIds: blockUsageLookup.FormData_Input_Contact_Email.nodeIds,
    });
  }

  if (
    blockUsageLookup.FormData_Input_Contact_Name &&
    blockUsageLookup.FormData_Input_Contact_Name?.nodeIds.length > 1
  ) {
    issues.push({
      level: 'error',
      message: text.nameMultiple,
      nodeIds: blockUsageLookup.FormData_Input_Contact_Name.nodeIds,
    });
  }

  return issues;
};

export default getMiscIssues;
