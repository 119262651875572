import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import DeletableItem from '~/components/molecule/DeletableItem';
import { useTransition, type UseTransitionProps } from 'react-spring';
import type { SelectOption } from '../..';

export type Props = {
  selectedOptions: Array<SelectOption>;
  disabled?: boolean;
  onRemoved: (val: SelectOption) => void;
};

const SelectedOptions: React.FCC<Props> = ({
  selectedOptions,
  disabled,
  onRemoved,
  ...rest
}) => {
  const transitionProps: UseTransitionProps = {
    config: { mass: 1, tension: 200, friction: 20 },
    from: { opacity: 0, transform: 'translateX(-10px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
    leave: { opacity: 0, transform: 'translateX(-10px)' },
    keys: item => item.key,
  };

  const transitions = useTransition(selectedOptions, transitionProps);

  if (selectedOptions.length === 0) return null;

  return (
    <JustificationContainer
      wrap="wrap"
      gap="xxs"
      margin={[null, null, 'xxs', null]}
      {...rest}
    >
      {transitions((style, item) => {
        const { key, payload, isValid } = item;

        return (
          <DeletableItem
            key={key}
            onDelete={() => onRemoved(item)}
            label={payload.tagLabel}
            appearance={isValid === false ? 'danger' : 'secondary'}
            style={style}
            disabled={disabled}
          />
        );
      })}
    </JustificationContainer>
  );
};

export default SelectedOptions;
