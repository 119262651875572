import { useState } from 'react';
import useDebounce from '../useDebounce';

/**
 * Creates a debounced state. The value will only be updated
 * if there has been no additional change within `delay` ms.
 */
const useDebouncedState = <T>(
  value: T,
  { delay }: { delay: number } = { delay: 200 },
): [T, (next: T) => void] => {
  const [latestValue, setLatestValue] = useState(value);
  const debouncedValue = useDebounce(latestValue, delay);

  return [debouncedValue, setLatestValue];
};

export default useDebouncedState;
