import React, { useEffect, useRef, useState } from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import styled, { css } from 'styled-components';
import { Form, Field } from '~/components/organism/Forms';
import Amplify from '~/amplify';
import FormUtils from '~/components/organism/FormUtils';

import Button from '~/components/atom/Button';
import { reporter } from '~/hooks/useErrorReporter';
import { Body, Heading1, Heading3 } from '~/components/atom/Typography';
import {
  navigate,
  RouteComponentProps,
  useLocation,
  WindowLocation,
} from '@gatsbyjs/reach-router';
import TextButton from '~/components/atom/TextButton';
import { isNil } from 'ramda';
import ErrorTypes from '~/ErrorTypes';
import validate from './utils/validate';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AuthFormWrapper from '../components/AuthFormWrapper';
import Input from '~/components/molecule/Input';
import createPageTitle from '~/util/createPageTitle';

export type FormData = {
  code: string;
};

type Props = RouteComponentProps<{
  location?: RouteComponentProps['location'] & {
    state: {
      email?: string;
    };
  };
}>;

export const text = {
  verifyError:
    'Er is iets misgegaan met het opvragen van je e-mailadres. Probeer opnieuw.',
  emailSent: 'Er is een verificatienummer verstuurd naar ',
  emailSentTo: email =>
    `Er is opnieuw een verificatienummer verstuurd naar ${email}`,
  successMessage:
    'Je e-mailadres is succesvol geverifieerd. Je kan nu inloggen.',
  newCodeSent: 'Er is een nieuw verificatienummer verstuurd',
  resendCodeButton: 'Ik heb geen e-mail ontvangen',
  codeLabel: 'Verificatienummer',
  verifyButton: 'Verifieer',
  pageTitle: 'Verificatie',
  invalidCode:
    'Het verificatienummer lijkt niet te kloppen. Probeer het nog eens.',
  alreadyVerified: 'Het e-mailadres is al geverifieerd.',
  codeExpired: 'Het verificatienummer is verlopen.',
};
const Verify: React.FCC<Props> = () => {
  const location = useLocation() as WindowLocation<any>;

  const [verifyError, setVerifyError] = useState<string | null>(null);
  const [verifyInfo, setVerifyInfo] = useState<string | null>(null);

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const disableResendTimeout = ((): number | null => {
    const disableResend = localStorage.getItem('disableResend');
    const timeToDisableRepeat =
      disableResend == null ? null : parseInt(disableResend);
    const currentTime = new Date().getTime();

    if (!timeToDisableRepeat || currentTime >= timeToDisableRepeat) return null;

    const disableTime = timeToDisableRepeat - currentTime;

    return disableTime;
  })();

  const disableRepeatedSending = (timeToDisable: number) => {
    const disableTime = new Date().getTime() + timeToDisable;

    setDisableToStorage(disableTime);
    timer.current = setTimeout(() => {
      removeDisableFromStorage();
      if (timer.current) clearTimeout(timer.current);
    }, timeToDisable);

    setResendDisabled(true);
  };

  useEffect(() => {
    if (disableResendTimeout) disableRepeatedSending(disableResendTimeout);
  }, [disableResendTimeout]);

  const [resendDisabled, setResendDisabled] = useState(
    !isNil(disableResendTimeout),
  );

  useEffect(() => () => {
    if (timer.current) clearTimeout(timer.current);
  });

  const resendCode = () => {
    const email = location?.state.email;

    const oneMinute = 60000;
    disableRepeatedSending(oneMinute);

    if (!email) {
      return reporter.captureException(
        new Error('Unable to resent code due to email missing'),
      );
    }

    const loweredEmail = email.toLowerCase();
    return Amplify.Auth.resendSignUp(loweredEmail)
      .then(() => {
        setVerifyInfo(text.emailSentTo(email));
        setVerifyError(null);
        return;
      })
      .catch(err =>
        handleCognitoException(err, errorMessage => {
          setVerifyError(errorMessage);
          setVerifyInfo(null);
        }),
      );
  };

  const onFormSubmit = ({ code }: FormData) => {
    const email = location?.state.email;

    const loweredEmail = email?.toLowerCase();
    if (!loweredEmail) {
      return reporter.captureException(
        new Error('Unable to submit verify form because of missing email'),
      );
    }

    return Amplify.Auth.confirmSignUp(loweredEmail, code)
      .then(() => {
        void navigate('/login', {
          state: {
            message: text.successMessage,
            email: loweredEmail,
          },
        });
      })
      .catch(err =>
        handleCognitoException(err, errorMessage => {
          setVerifyError(errorMessage);
          setVerifyInfo(null);
        }),
      );
  };

  const title = text.pageTitle;
  const emailMissed = !location?.state || !location.state.email;
  const email = !emailMissed ? location.state.email : null;

  return (
    <>
      <MetaTags>
        <title>{createPageTitle(title)}</title>
      </MetaTags>

      <AuthFormWrapper>
        <Heading1
          lineHeight="base"
          margin={['l', null]}
          color={{ group: 'accent' }}
          size="xxxxl"
          skewed
        >
          {title}
        </Heading1>

        {emailMissed && (
          <Heading3 fontWeight="regular">
            <Body
              margin={[null]}
              color={{ group: 'danger', variant: 'light' }}
              size="base"
            >
              {verifyError}
            </Body>
          </Heading3>
        )}

        {!emailMissed && (
          <React.Fragment>
            <Body color={{ group: 'white' }} inline>
              {text.emailSent}
              <EmailLabel>{email}</EmailLabel>
            </Body>

            {verifyInfo && (
              <Body
                margin={[null]}
                color={{ group: 'info' }}
                size="base"
                data-testid={TEST_ID.VERIFY_INFO}
              >
                {verifyInfo}
              </Body>
            )}
            {verifyError && (
              <Body
                margin={[null]}
                color={{ group: 'danger', variant: 'light' }}
                size="base"
              >
                {verifyError}
              </Body>
            )}
            <Form
              onSubmit={onFormSubmit}
              validate={validate}
              initialValues={{ code: null }}
            >
              {({ handleSubmit, submitting, pristine }) => (
                <form
                  id="verify"
                  onSubmit={handleSubmit}
                  data-testid={TEST_ID.VERIFY_FORM}
                >
                  <Field name="code">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        autoFocus
                        size="large"
                        label={{
                          text: text.codeLabel,
                          color: { group: 'white' },
                        }}
                        type="text"
                        externalErrors={
                          error && touched
                            ? [FormUtils.showError(error, touched)]
                            : undefined
                        }
                        disabled={submitting}
                        {...input}
                      />
                    )}
                  </Field>

                  <JustificationContainer
                    align="center"
                    justification="end"
                    margin={['base', null, null, null]}
                  >
                    {!resendDisabled && (
                      <TextButton
                        padding={[null]}
                        size="medium"
                        onClick={resendCode}
                        dataTestId={TEST_ID.RESEND_CODE_BUTTON}
                        label={text.resendCodeButton}
                        appearance="accent"
                      />
                    )}

                    <VerifyButton
                      appearance="primary"
                      size="medium"
                      type="submit"
                      disabled={submitting || pristine}
                      loading={submitting}
                      label={text.verifyButton}
                      dataTestId={TEST_ID.SUBMIT_BUTTON}
                    />
                  </JustificationContainer>
                </form>
              )}
            </Form>
          </React.Fragment>
        )}
      </AuthFormWrapper>
    </>
  );
};

const EmailLabel = styled.span(
  ({ theme }) => css`
    color: ${theme.color('secondary')};
  `,
);

const VerifyButton = styled(Button)<{}>`
  margin-left: auto;
`;

const setDisableToStorage = (disableTime: number) => {
  localStorage.setItem('disableResend', disableTime.toString());
};

const removeDisableFromStorage = () => {
  localStorage.removeItem('disableResend');
};

// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html
const handleCognitoException = (
  err: { code: string; message: string },
  setError: (errorMessage: string) => void,
) => {
  let errorMessage;
  switch (err.code) {
    case 'CodeMismatchException':
      errorMessage = text.invalidCode;
      break;
    case ErrorTypes.notAuthorized:
      // The full error message we get from Cognito is: 'User cannot be confirmed. Current status is CONFIRMED'
      if (err.message.includes('Current status is CONFIRMED')) {
        errorMessage = text.alreadyVerified;
      } else {
        /**
         * There could be a variety of error messages in this case, such as 'Username and password do not match' or
         * 'User does not exist', so we return the error message we get from Cognito
         */
        errorMessage = err.message;
      }
      break;
    case ErrorTypes.expiredCodeException:
      errorMessage = text.codeExpired;
      break;
    default:
      errorMessage = err.message;
  }
  setError(errorMessage);
};

export default Verify;
