import React from 'react';

import { AppStatus__Input, FlowTemplates } from '~/graphql/types';
import AppSetupModal, {
  Props as AppSetupModalProps,
} from '~/components/template/AppSetupModal';
import { ExtendedAppConfig } from '~/hooks/useApps';
import getEnablementFlow from '~/components/organism/Wizard/utils/getEnablementFlow';
import getActionableStepsForEnablementFlow from '~/util/getActionableStepsForEnablementFlow';
import getAppStatusUpdate from '../../../../utils/getAppStatusUpdate';
import AppSetupModalWithOAuthFlow from '../AppSetupModalWithOAuthFlow';
import { RouteComponentProps } from '@reach/router';
import { isNil } from 'ramda';
import StrapiModal from '~/components/molecule/StrapiModal';
import { AppStatus__typename, WizardFlow } from '~/graphql/types.client';
import GetFlowTemplatesForAppWrapper from '../GetFlowTemplatesForAppWrapper';
import type { Option } from '~/components/molecule/Dropdown';

type AppModalProps = {
  app: ExtendedAppConfig | null | undefined;
  onClose: () => void;
  updateAppStatus: (args: {
    type: AppStatus__typename;
    update: AppStatus__Input;
    enablementFlow: WizardFlow | undefined;
  }) => Promise<void>;
  refetch: () => Promise<void>;
};

const AppModal: React.FC<AppModalProps & RouteComponentProps> = ({
  app,
  onClose,
  ...rest
}) => {
  if (isNil(app)) return null;

  if (app.appStatus.permission?.__typename === 'Permission_AccessDenied_Plan') {
    return (
      <StrapiModal
        slug={app.appStatus.permission.reason.id}
        onClose={onClose}
        path={`setup/${app.slug}`}
      />
    );
  }

  return (
    <GetFlowTemplatesForAppWrapper appType={app.appType}>
      {(flowTemplates: Array<FlowTemplates>) => (
        <AppModalAccessGranted
          flowTemplates={flowTemplates}
          app={app}
          onClose={onClose}
          {...rest}
        />
      )}
    </GetFlowTemplatesForAppWrapper>
  );
};

const AppModalAccessGranted: React.FC<
  Omit<AppModalProps, 'app'> & {
    app: ExtendedAppConfig;
    flowTemplates: Array<FlowTemplates>;
  }
> = ({ app, onClose, updateAppStatus, refetch, flowTemplates }) => {
  const hasFlows = flowTemplates.length > 0;
  const onChangeAppState = (options?: { selectedProduct: Option }) => {
    const enablementFlow = getEnablementFlow(app, hasFlows);
    const update = getAppStatusUpdate(
      app,
      getActionableStepsForEnablementFlow(enablementFlow).length === 0,
      options,
    );

    return updateAppStatus({ type: app.__typename, update, enablementFlow });
  };

  const props: AppSetupModalProps = {
    onClose,
    app,
    onChangeAppState,
    path: `setup/${app.slug}`,
    refetch,
    hasFlows,
  };

  if (app.setupFlow === 'oAuth') {
    return <AppSetupModalWithOAuthFlow key={app.__typename} {...props} />;
  }

  return <AppSetupModal key={app.__typename} {...props} />;
};

export default AppModal;
